import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import PropTypes from 'prop-types';
import BulletList from '../../../../../components/BulletList/BulletList';
import styles from './PropertyRentalLabel.module.css';

const cx = classNames.bind(styles);

export default class PropertyRentalLabel extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    home: PropTypes.object.isRequired,
  };

  render() {
    const {
      property,
      home: {
        basicInfo: { unitNumber },
      },
    } = this.props;

    const {
      address: { streetAddress },
    } = property;

    const listItems = [streetAddress];

    if (unitNumber) {
      listItems.push(`Unit ${unitNumber}`);
    }

    return (
      <div className={cx('property-rental-label')}>
        <Label color={LABEL_COLOR_TYPES.WHITE}>
          <BulletList listItems={listItems} />
        </Label>
      </div>
    );
  }
}
