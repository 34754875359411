import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import ButtonBase from '../../corecomponents/ButtonBase/ButtonBase';
import { MediaType } from '../../models/enums';
import BDate, { DATE_TYPES } from '../BDate/BDate';
import Icon, { ICONS } from '../Icon/Icon';
import styles from './DocumentCard.module.css';

const cx = classNames.bind(styles);

const SIZES = {
  SMALL: 'small',
  DEFAULT: 'default',
};

class DocumentCard extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
    uploadDate: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    bordered: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(SIZES)),
  };

  static defaultProps = {
    uploadDate: null,
    bordered: true,
    size: SIZES.DEFAULT,
  };

  getDocumentIcon() {
    const { type } = this.props;

    switch (type) {
      case MediaType.PDF:
        return ICONS.DOCUMENT.PDF;
      default:
        return ICONS.DOCUMENT.DEFAULT;
    }
  }

  render() {
    const { label, uploadDate, onClick, size, bordered } = this.props;
    return (
      <ButtonBase className={cx('document-card')} onClick={onClick}>
        <div className={cx('icon', size, { bordered })}>
          <Icon icon={this.getDocumentIcon()} />
        </div>
        {React.isValidElement(label) ? label : <div className={cx('label')}>{label}</div>}
        {uploadDate && (
          <div className={cx('date')}>
            <BDate time={uploadDate} formatType={DATE_TYPES.DOTTED_MEDIUM} />
          </div>
        )}
      </ButtonBase>
    );
  }
}

DocumentCard.propTypes = {};
DocumentCard.SIZES = SIZES;

export default DocumentCard;
