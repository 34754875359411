export const QUERY_PARAMS = {
  CALENDLY_DATE: 'calendly-date',
  CALENDLY_SUCCESS: 'calendly-success',
};

export const STRINGS = {
  SUCCESS_TOAST:
    "Confirmed on {datetime}! We'll still reach out to you in the meantime. And to make the next steps smoother, let's learn more about your home.",
  FAILURE_TOAST:
    "Unfortunately, scheduling isn't working. We'll reach out to you soon. In the meantime, let's learn more about your home.",
};
