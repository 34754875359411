import { differenceInDays, isSameDay, lastDayOfMonth } from 'date-fns';
import { RentalStatus, AvailableForRentOptions, MoveOutOptions } from 'models/enums';

export const getIsSpecificDateInLessThan1Month = (onboardingInfo = {}) => {
  const today = new Date();
  const occupied = onboardingInfo.rentalStatus !== RentalStatus.Vacant;

  let specificDate;
  if (occupied) {
    specificDate = new Date(onboardingInfo.moveoutOn);
  } else if (onboardingInfo.availableForRentOption === AvailableForRentOptions.OnSpecificDate) {
    specificDate = new Date(onboardingInfo.availableForRentOn);
  }

  return !!specificDate && differenceInDays(specificDate, today) <= 30;
};

export const plansHaveChangedPronoun = {
  [RentalStatus.OwnerOccupied]: 'I',
  [RentalStatus.FamilyOccupied]: 'They',
  [RentalStatus.Vacant]: 'It',
};

export const plansHaveChangedVerb = {
  [RentalStatus.OwnerOccupied]: 'am',
  [RentalStatus.FamilyOccupied]: 'are',
  [RentalStatus.Vacant]: 'will',
};

export const getIsDayInThePast = (onboardingInfo = {}) => {
  const today = new Date();
  const occupied = onboardingInfo.rentalStatus !== RentalStatus.Vacant;

  let date;
  if (occupied) {
    if (onboardingInfo.moveoutOption === MoveOutOptions.OnSpecificDate) {
      date = new Date(onboardingInfo.moveoutOn);
    } else if (onboardingInfo.moveoutOption === MoveOutOptions.InDateRange) {
      date = new Date(onboardingInfo.moveoutEndOn);
    }
  } else {
    if (onboardingInfo.availableForRentOption === AvailableForRentOptions.OnSpecificDate) {
      date = new Date(onboardingInfo.availableForRentOn);
    } else if (onboardingInfo.availableForRentOption === AvailableForRentOptions.InCertainMonth) {
      // Using second day of month to not deal with timezone issues with first day of the month.
      const secondDayOfMonth = new Date(onboardingInfo.availableForRentOn);
      secondDayOfMonth.setDate(secondDayOfMonth.getDate() + 1);
      date = lastDayOfMonth(secondDayOfMonth);
    }
  }

  return today > date && !isSameDay(today, date);
};
