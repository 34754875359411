import { useMemo } from 'react';
import { Calendar } from '@belong/ui';

export function CalendarFormAdapter({ input, meta, ignoreError, onChangeCustom, ...rest }) {
  const selectedDay = useMemo(() => input.value, [input.value]);

  return (
    <div className="flex flex-wrap md:flex-nowrap lg:flex-wrap xl:flex-nowrap ml-0 sm:-ml-lg relative">
      <Calendar
        {...input}
        {...rest}
        error={!ignoreError ? meta.error : false}
        selected={selectedDay}
        onSelect={(key, object) => {
          input.onChange(key, object);

          if (onChangeCustom) {
            onChangeCustom(key, object);
          }
        }}
      />
    </div>
  );
}
