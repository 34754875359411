import React, { Component } from 'react';
import Webcam from 'react-webcam';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import GeneralIcon, { COLORS, GENERAL_ICONS } from '../GeneralIcon/GeneralIcon';
import styles from './TakePicture.module.css';
import { SIZE, SIZE_STRINGS, TYPES } from './pictureTypes';

const cx = classNames.bind(styles);

export const PICTURE_TYPES = TYPES;
export const PICTURE_SIZE = SIZE_STRINGS;

export default class TakePicture extends Component {
  static propTypes = {
    type: PropTypes.string,
    videoSize: PropTypes.string,
    onClickCamera: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: TYPES.DOCUMENT,
    videoSize: 'MEDIUM',
  };

  constructor(props) {
    super(props);

    this.state = {
      imageCaptured: false,
      webcamNotAvailable: false,
    };
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  handleClickCamera = () => {
    const { onClickCamera } = this.props;
    const imageSource = this.webcam.getScreenshot();
    this.capturedImage = <img src={imageSource} alt="Webcam capture" />;
    onClickCamera(imageSource);
    this.setState({
      imageCaptured: true,
    });
  };

  handleClickRetake = () => {
    this.capturedImage = null;
    this.setState({
      imageCaptured: false,
    });
  };

  handleUserMediaError = () => {
    this.setState({
      webcamNotAvailable: true,
    });
  };

  renderWebcamStream() {
    const { videoSize, type, ...rest } = this.props;
    const sizeObject = SIZE[videoSize];
    const { imageCaptured, webcamNotAvailable } = this.state;

    if (imageCaptured) {
      return null;
    }

    if (webcamNotAvailable) {
      return <div className={cx('webcam-not-available')}>Camera not available!</div>;
    }

    return (
      <>
        <Webcam
          audio={false}
          ref={this.setRef}
          screenshotFormat="image/jpeg"
          onUserMediaError={this.handleUserMediaError}
          {...sizeObject}
          {...rest}
        />
        <div
          className={cx(
            { 'selfie-outline': type === TYPES.SELFIE },
            { hidden: type !== TYPES.SELFIE },
            { medium: videoSize === 'MEDIUM' },
            // This always biometric information, so we always hide from fulstory
            'fs-exclude'
          )}
        />
        <div
          className={cx(
            'control-button-wrapper',
            { medium: videoSize === 'MEDIUM' },
            { document: type === TYPES.DOCUMENT },
            { selfie: type === TYPES.SELFIE }
          )}
        >
          <Button size="fluid" onClick={this.handleClickCamera}>
            <GeneralIcon scale={1.5} icon={GENERAL_ICONS.PHOTO} color={COLORS.WHITE} />
          </Button>
        </div>
      </>
    );
  }

  renderCapturedImage() {
    const { imageCaptured } = this.state;

    if (!imageCaptured) {
      return null;
    }

    return (
      <div className={cx('captured-image-wrapper')}>
        {this.capturedImage}
        <div className={cx('control-button-wrapper', 'retake')}>
          <Button size="fluid" onClick={this.handleClickRetake}>
            Retake
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { videoSize } = this.props;

    return (
      <div className={cx('webcam-window-wrapper', { medium: videoSize === 'MEDIUM' })}>
        {this.renderWebcamStream()}
        {this.renderCapturedImage()}
      </div>
    );
  }
}
