import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalV2, { MODAL_TYPES } from '../../../components/Modal/ModalV2/ModalV2';
import GalleryCarousel from '../../../pages/HousePage/Gallery/GalleryCarousel/GalleryCarousel';

class CarouselModal extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    index: PropTypes.number,
    ignoreModalExitAndEnterFunctions: PropTypes.bool,
    carouselV2: PropTypes.bool,
    fullPage: PropTypes.bool,
    autoplay: PropTypes.bool,
  };

  static defaultProps = {
    index: 0,
    ignoreModalExitAndEnterFunctions: false,
    carouselV2: false,
    fullPage: false,
    autoplay: false,
  };

  /* Hack to make the carousel read dynamic height */
  componentDidMount() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  render() {
    const { carouselV2, images, index, ignoreModalExitAndEnterFunctions, fullPage, autoplay, ...rest } = this.props;
    return (
      <ModalV2
        {...rest}
        ignoreModalExitAndEnterFunctions
        noPadding
        galleryModalCustomStyling
        mobileTopImportant={false}
        carouselV2={carouselV2}
        type={fullPage ? MODAL_TYPES.FULL_PAGE : MODAL_TYPES.STANDARD}
        noFullPageHeader={fullPage}
      >
        <GalleryCarousel
          images={images}
          index={index}
          onHide={rest.onHide}
          carouselV2={carouselV2}
          fullPage={fullPage}
          autoplay={autoplay}
        />
      </ModalV2>
    );
  }
}

export default CarouselModal;
