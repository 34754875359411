export const SECTION_NAMES = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
};

export const HOA_DOCUMENTS = {
  UploadNow: 'UploadNow',
  RemindMeLater: 'RemindMeLater',
  AskMyHoaForIt: 'AskMyHoaForIt',
}

export const HOA_DOCUMENTS_OPTIONS = [
  { label: `Upload Now`, key: false },
  { label: 'I don’t have it. <span>Remind me later.</span>', key: true },
];
