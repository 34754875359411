import { getApiActionTypesValues } from '../redux_utils';

export const fetchInitialPrice = (unitId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_INITIAL_PRICE'),
    promise: ({ client }) => client.get(`/initial-price/${unitId}`),
    auth: true,
  });

export const updateInitialPriceFeedback = (pricingEstimateId, data) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_INITIAL_PRICE_FEEDBACK'),
    promise: ({ client }) => client.put(`/initial-price/${pricingEstimateId}/feedback`, data),
    auth: true,
  });
