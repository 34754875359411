import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { setPassword } from 'utils/validation';
import styles from './SetPasswordField.module.css';

const cx = classNames.bind(styles);

export default class SetPasswordField extends Component {
  state = {
    passwordStrength: '', // Note: Currently strength is being calculated based on length only
  };

  handleChange = (value) => {
    // if (value) {
    //   this.setState({
    //     hasLowerCase: /[a-z]/.test(value),
    //     hasUpperCase: /[A-Z]/.test(value),
    //     hasDigits: /[\d]/.test(value),
    //   });
    // }
    // Password strength - weak
    if (value && value.length <= 6) {
      this.setState({
        // length: value.length,
        passwordStrength: 'Weak',
      });
      // Password strength - fair
    } else if (value && value.length > 6 && value.length <= 8) {
      this.setState({
        // length: value.length,
        passwordStrength: 'Fair',
      });
      // Password strength - strong
    } else if (value && value.length > 8) {
      this.setState({
        // length: value.length,
        passwordStrength: 'Strong',
      });
      // Password field set to null
    } else {
      this.setState({
        // hasUpperCase: false,
        // hasLowerCase: false,
        // hasDigits: false,
        // length: 0,
        passwordStrength: '',
      });
    }
  };

  render() {
    const {
      // length,
      passwordStrength,
      // hasDigits,
      // hasLowerCase,
      // hasUpperCase
    } = this.state;

    return (
      <>
        <Field
          name="password"
          type="password"
          component={InputFinalFormAdapter}
          placeholder="Password"
          validate={setPassword}
          onChangeCustom={this.handleChange}
        />
        {/* <div className={cx('password-instructions')}>
          <span className={cx({ bold: hasUpperCase })}>1 uppercase,</span>
          &nbsp;
          <span className={cx({ bold: hasLowerCase })}>1 lowercase,</span>
          &nbsp;
          <span className={cx({ bold: hasDigits })}>1 digit,</span>
          &nbsp;
          <span className={cx({ bold: length > 6 })}>6-18 characters</span>
        </div> */}
        <div className={cx(['password-strength-status', `password-strength-${passwordStrength}`])}>
          {passwordStrength}
        </div>
      </>
    );
  }
}
