import { useState } from 'react';
import { Calendar } from '@belong/ui';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getImprovementsSpecialist } from '../utils';

interface StepResource {
  homeListingDetailsModel: {
    owner: {
      firstName: string;
    };
    basicInfo: {
      timeZone?: string;
    };
    employeeAssignments: {
      [key: string]: {
        employeeAssignment: {
          assignmentType: string;
        };
        employee: {
          bannerImageUrl: string;
        };
        user: {
          profileImageUrl: string;
        };
      };
    };
  };
}

interface Props {
  stepResource: StepResource;
  onSaveAndNext: (data: { estimatedHomeownerFixesCompletionDate: string }) => void;
}

const HomeownerFixes: React.FC<Props> = ({ stepResource, onSaveAndNext }) => {
  const [completionDate, setCompletionDate] = useState(null);
  const { homeListingDetailsModel } = stepResource;

  const formattedAssessedBy = getImprovementsSpecialist(homeListingDetailsModel);

  const handleSubmit = () => {
    onSaveAndNext({
      estimatedHomeownerFixesCompletionDate: completionDate.toISOString().split('T')[0],
    });
  };

  return (
    <PostInspectionContentLayout
      title={formatString(POST_INSPECTION_FLOW_STRINGS['homeowner-fixes.title'], {
        firstName: homeListingDetailsModel.owner.firstName,
      })}
      employee={formattedAssessedBy}
      showCityBackground={undefined}
      topFiller={undefined}
      stepConfigs={undefined}
      stepConfig={undefined}
      noMinHeight={undefined}
      noOverlay={undefined}
      hasCustomFixedPageHeader={undefined}
    >
      <Calendar
        rootClassName="m-0 w-full md:p-0"
        selected={completionDate}
        disabledDays={[{ before: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) }]}
        onSelect={(date) => {
          setCompletionDate(date);
        }}
      />

      <div className="flex justify-center mb-2xl mt-2xl">
        <PostInspectionContentLayout.CtaButton onClick={() => handleSubmit()} disabled={!completionDate}>
          Save
        </PostInspectionContentLayout.CtaButton>
      </div>
    </PostInspectionContentLayout>
  );
};

export default HomeownerFixes;
