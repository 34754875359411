import React from 'react';
import classNames from 'classnames/bind';
import Image from 'components/Image/Image';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import styles from './DisplayPhase.module.css';

const cx = classNames.bind(styles);

export const types = {
  COVER: 'cover',
  CARD: 'card',
};

const DisplayPhase = ({
  title,
  subtitle,
  bottom,
  ilustration,
  background,
  mobileBackground,
  containerClassName,
  type,
}) => (
  <div className={cx('container', containerClassName)}>
    <div className={cx('background-container-desktop', type)}>
      <Image src={background} />
    </div>
    <div className={cx('background-container-mobile', type)}>
      <Image src={mobileBackground} />
    </div>

    <div className={cx('phase', type)}>
      <div className={cx('phase-content')}>
        <div className={cx('phase-text', type)}>
          <Text fontSize="body">{title}</Text>
          <Text
            fontSize={type === types.COVER ? 'h2' : 'h3'}
            fontWeight="semibold"
            fontFamily="caveat"
            className={cx('subtitle')}
          >
            {subtitle}
          </Text>
        </div>
        <Image className={cx('illustration')} src={ilustration} />
      </div>
      {bottom}
    </div>
  </div>
);

DisplayPhase.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  background: PropTypes.string,
  mobileBackground: PropTypes.string,
  bottom: PropTypes.node,
  type: PropTypes.oneOf(Object.values(types)),
  ilustration: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
};

DisplayPhase.defaultProps = {
  bottom: '',
  type: types.CARD,
  background: '/illustrations/ho_account_confetti.svg',
  mobileBackground: '/illustrations/ho_account_confetti_m.svg',
  containerClassName: '',
};

DisplayPhase.Cover = (props) => <DisplayPhase type={types.COVER} {...props} />;

export default DisplayPhase;
