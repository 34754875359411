import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const HTMLRenderer = ({ className, html, intersectionOptions, querySelector, onClickListener, onIntersection }) => {
  const sanitizedHtml = html?.match(/<body[\s\S]*<\/body>/)?.[0];

  useEffect(() => {
    if (onIntersection) {
      const elements = document.querySelectorAll(querySelector);
      let observer;
      if (elements?.length) {
        observer = new IntersectionObserver(onIntersection, intersectionOptions);

        elements.forEach((element) => {
          if (onClickListener) {
            element.addEventListener('click', onClickListener);
          }
          observer.observe(element);
        });
      }

      return () => {
        if (elements?.length) {
          elements.forEach((element) => {
            if (onClickListener) {
              element.removeEventListener('click', onClickListener);
            }
            if (onIntersection) {
              observer.unobserve(element);
            }
          });
        }
      };
    }
  }, [intersectionOptions.root, onIntersection, onClickListener, html]);

  return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

HTMLRenderer.propTypes = {
  className: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  intersectionOptions: PropTypes.object,
  querySelector: PropTypes.string,
  onClickListener: PropTypes.func,
  onIntersection: PropTypes.func,
};

HTMLRenderer.defaultProps = {
  intersectionOptions: {
    rootMargin: '0px',
    threshold: 1.0,
  },
  querySelector: null,
  onClickListener: null,
  onIntersection: null,
};

export default HTMLRenderer;
