/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames/bind';
import MultiSelectInput from 'components/MultiSelectAutoComplete/MultiSelectComponents/MultiSelectInput/MultiSelectInput';
import Downshift from 'downshift';
import DropDownItem from '../Dropdown/DropDownItem/DropDownItem';
import styles from './MultiSelectAutoComplete.module.css';
import MultiSelectMenu from './MultiSelectComponents/MultiSelectMenu/MultiSelectMenu';

/*
  Many of the functionalities in this component have been stripped down to make editing and understanding it easier.
  As this is a fairly complicated component and not modular yet, there will likely be some issues.
  Use the standard input if possible as it's more stable, however, this will be the choice for all multiselect.
*/
const cx = classNames.bind(styles);

const renderInput = (inputProps) => {
  const { menuIsOpen, error, InputProps, placeholder, tagsToShow, onRemoveItem, availableItems } = inputProps;
  const allItemSelected = availableItems.length === 0;
  return (
    <MultiSelectInput
      {...InputProps}
      error={error}
      menuIsOpen={menuIsOpen}
      placeholder={placeholder}
      tags={tagsToShow}
      disabled={allItemSelected}
      onRemoveTag={onRemoveItem}
    />
  );
};

const renderSuggestion = (params) => {
  const { item, index, itemProps, highlightedIndex, selectedItem } = params;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem.indexOf(item.value) > -1;

  return (
    !isSelected && (
      <DropDownItem {...itemProps} key={item.key} isSelected={isHighlighted} component="div">
        {item.value}
      </DropDownItem>
    )
  );
};

const getSuggestions = (inputValue, itemList) =>
  itemList.filter((item) => {
    if (item.value) {
      return item.value.toLowerCase().includes(inputValue.toLowerCase());
    }

    return false;
  });
export const MultiSelectAutoComplete = ({
  customOnInputValueChange,
  customInputValue,
  tagsToShow,
  placeholder,
  availableItems,
  onRemoveItem,
  error,
  ...rest
}) => (
  <Downshift {...rest}>
    {({ getInputProps, getItemProps, inputValue, selectedItem, highlightedIndex, toggleMenu, isOpen }) => (
      <div className={cx('relative')}>
        {renderInput({
          onRemoveItem: rest.onChange,
          selectedItem,
          availableItems,
          error,
          tagsToShow,
          menuIsOpen: isOpen,
          placeholder,
          InputProps: {
            ...getInputProps({
              onClick: () => toggleMenu(),
            }),
          },
        })}
        {isOpen && (
          <MultiSelectMenu>
            {getSuggestions(inputValue, availableItems).map((item, index) =>
              renderSuggestion({
                item,
                index,
                itemProps: getItemProps({
                  item: item.value,
                }),
                highlightedIndex,
                selectedItem,
              })
            )}
          </MultiSelectMenu>
        )}
      </div>
    )}
  </Downshift>
);

export const MultiSelectAutoCompleteFormAdapter = ({
  selectedItem,
  input,
  meta,
  onChangeCustom,
  ignoreError,
  selectedKey,
  ...rest
}) => {
  const controlledProps = {};

  if (!ignoreError) {
    controlledProps.error = !!meta?.error;
  }

  return (
    <MultiSelectAutoComplete
      {...input}
      {...rest}
      selectedItem={selectedItem}
      selectedKey={input.value || selectedKey}
      onChange={(inputValue) => {
        if (onChangeCustom) {
          onChangeCustom(inputValue, input);
        }
      }}
      {...controlledProps}
    />
  );
};
