import { useState } from 'react';
import { Accordion } from '@belong/ui';
import { defaultItems, renewalItems } from 'lease-signing-flow/constants/deposit';

type DepositFAQsProps = {
  isRenewal: boolean;
};

export const DepositFAQs = ({ isRenewal }: DepositFAQsProps) => {
  const [openFAQIndex, setOpenFAQIndex] = useState<number | null>(0);

  const handleChange = (index: number) => setOpenFAQIndex(openFAQIndex === index ? null : index);
  return <Accordion index={openFAQIndex} items={isRenewal ? renewalItems : defaultItems} onChange={handleChange} />;
};
