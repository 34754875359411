import React from 'react';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { InputFinalFormAdapter } from 'components/Input/Input';
import Field from 'components/Field/Field';

export const AdditionalInfoSection = ({ name }) => (
  <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.moveoutAdditionalInfo}>
    <Field
      component={InputFinalFormAdapter}
      name={`${name}.moveoutComments`}
      placeholder={RENTAL_STATUS_TEXT.moveoutAdditionalInfoPlaceholder}
      textarea
    />
  </FormLayout.Section>
);

AdditionalInfoSection.propTypes = {
  name: PropTypes.string.isRequired,
};
