import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

const Condition = ({
  children,
  hasValue = false,
  ignore = '',
  includes = '',
  is = null,
  isIncluded = [],
  isNot = null,
  notHasValue = false,
  when,
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      if (ignore && ignore === value) {
        return null;
      }

      if (hasValue && value) {
        return children;
      }

      if (notHasValue && !value) {
        return children;
      }

      if (isNot && value !== isNot) {
        return children;
      }

      if (value === is) {
        return children;
      }

      if (includes && value.includes(includes)) {
        return children;
      }

      if (isIncluded && isIncluded.includes(value)) {
        return children;
      }

      return null;
    }}
  </Field>
);

Condition.propTypes = {
  children: PropTypes.node.isRequired,
  hasValue: PropTypes.bool,
  ignore: PropTypes.string,
  includes: PropTypes.string,
  is: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool, // For checkbox input fields
  ]),
  isIncluded: PropTypes.array,
  isNot: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  notHasValue: PropTypes.bool,
  when: PropTypes.string.isRequired,
};

export default Condition;
