import React, { Component } from 'react';
import PropTypes from 'prop-types';
import String from 'components/String/String';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import ModalLayout from '../ModalLayout';

export default class ModalLayoutErrorScreen extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    ctaLabel: PropTypes.string.isRequired,
    onCtaClick: PropTypes.func.isRequired,
  };

  render() {
    const { title, subtitle, ctaLabel, onCtaClick } = this.props;

    return (
      <ModalLayout>
        <ModalLayout.Content>
          <ModalLayout.Title className="error">
            <String string={title} />
          </ModalLayout.Title>
          <Space value={SPACE_TYPES.XS} />
          <ModalLayout.SubTitle noPaddingBottom>
            <String string={subtitle} />
          </ModalLayout.SubTitle>
          <ModalLayout.BottomBar
            fixedBottom
            ctaProps={{
              label: ctaLabel,
              onClick: onCtaClick,
            }}
          />
        </ModalLayout.Content>
      </ModalLayout>
    );
  }
}
