import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './SelectorCardLayout.module.css';

const cx = classNames.bind(styles);

const SelectorCardLayout = ({ className, icon, label, mobileOrientation }) => (
  <div className={cx('icon-label', className, mobileOrientation)}>
    <div className={cx('icon-container')}>{icon}</div>
    <div className={cx('label')}>{label}</div>
  </div>
);

SelectorCardLayout.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  mobileOrientation: PropTypes.oneOf(['vertical', 'horizontal']),
};

SelectorCardLayout.defaultProps = {
  className: null,
  mobileOrientation: 'horizontal',
};

export default SelectorCardLayout;
