import { Component } from 'react';
import { connect } from 'react-redux';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import { Toast } from '@belong/ui';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskMMDDYYYY, unmaskMMDDYYYY } from 'components/Input/masks';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import Spinner from 'components/Spinner/Spinner';
import AddressField from 'fields/StandardFields/AddressField/AddressField';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEmpty, isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import { PATHS } from 'routes/paths';
import {
  fetchApplicantInfo,
  updateApplicationFlowStarted,
  updateLegalIdentityInformation,
} from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationApplicantType,
  selectResidentApplicationCreditCheck,
  selectResidentApplicationId,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import { selectUser } from 'store/redux/user/selectors';
import { getString } from 'strings';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { converDateTimeServerResponseToDateTimeObject, parseDateTimeInputString } from 'utils/dateTimeUtils';
import { dateOfBirthValidation, required } from 'utils/validation';
import { ApplicantStatus } from '../../../../models/enums';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './CreditCheck.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.credit_check;
const { FormLayoutHeader } = FormLayout;

// Move to constants
const IS_LEGAL_NAME = {
  YES: 'yes',
  NO: 'no',
};
const INVALID_USER_FOR_APPLICATION = 'INVALID_USER_FOR_APPLICATION';

class CreditCheck extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired,
    creditCheck: PropTypes.object,
    fetchApplicantInfo: PropTypes.func.isRequired,
    updateLegalIdentityInformation: PropTypes.func.isRequired,
    applicationId: PropTypes.string.isRequired,
    houseId: PropTypes.string.isRequired,
    updateApplicationFlowStarted: PropTypes.func.isRequired,
  };

  static defaultProps = {
    creditCheck: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      awaitingFetchComplete: true,
      formData: {},
      outsideUsCurrentAddress: false,
      isErrorToastVisible: false,
      errorText: '',
    };
  }

  async componentDidMount() {
    const {
      history,
      applicationId,
      fetchApplicantInfo: fetchApplicantInfoAction,
      updateApplicationFlowStarted: updateApplicationFlowStartedAction,
    } = this.props;

    try {
      const applicant = await fetchApplicantInfoAction();

      if (applicant.basicInfo.status === ApplicantStatus.Invited) {
        updateApplicationFlowStartedAction(applicationId);
      }
    } catch (e) {
      const hasEmailMismatchError = !!e?.filter((err) => err.errorCode === INVALID_USER_FOR_APPLICATION).length;
      if (hasEmailMismatchError) {
        history.push(PATHS.SUPPORT);
      }
      console.error(e);
    }

    this.setupInitialValues();
  }

  setupInitialValues() {
    const { creditCheck } = this.props;
    const updatedState = {
      formData: {
        ...creditCheck,
      },
    };
    const hasLegalInfo = creditCheck.legalFirstName && creditCheck.legalFirstName !== '';
    let isSameUserAndLegalName = false;

    updatedState.outsideUsCurrentAddress = creditCheck?.currentAddress?.outsideUS;

    if (!isNil(creditCheck.legalFirstName) && creditCheck.legalFirstName !== '') {
      isSameUserAndLegalName = this.compareUserAndLegalName();
    }

    if (hasLegalInfo && isSameUserAndLegalName) {
      updatedState.formData.isLegalName = IS_LEGAL_NAME.YES;
    } else if (hasLegalInfo && !isSameUserAndLegalName) {
      updatedState.formData.isLegalName = IS_LEGAL_NAME.NO;
    }

    if (!isNil(creditCheck.dateOfBirth) && creditCheck.dateOfBirth !== '') {
      const dateOfBirthDateObject = converDateTimeServerResponseToDateTimeObject(creditCheck.dateOfBirth);
      // To-Do: Move format to a constant
      updatedState.formData.dateOfBirth = formatDateTime({
        dateTime: legacyParse(dateOfBirthDateObject),
        format: DATE_FORMATS.STANDARD,
      });
    }

    this.setState({
      awaitingFetchComplete: false,
      ...updatedState,
    });
  }

  compareUserAndLegalName() {
    const { user, creditCheck } = this.props;
    let isSame = true;

    if (user.firstName !== creditCheck.legalFirstName) {
      isSame = false;
    } else if (user.lastName !== creditCheck.legalLastName) {
      isSame = false;
    } else if (creditCheck.legalMiddleName && creditCheck.legalMiddleName !== '') {
      isSame = false;
    }

    return isSame;
  }

  handleGoogleAutoCompleteAddress = (address) => {
    if (!isEmpty(address)) {
      const { formData } = this.state;
      const updatedFormData = { ...formData, ...address };

      this.setState({
        formData: updatedFormData,
      });
    }
  };

  handleSubmit = async (values) => {
    const {
      history: { push },
      updateLegalIdentityInformation: updateLegalIdentityInformationAction,
      applicationId,
      houseId,
      user,
    } = this.props;

    const { outsideUsCurrentAddress } = this.state;

    const dateOfBirthUserString = values.dateOfBirth;
    const dateOfBirthServerString = parseDateTimeInputString(dateOfBirthUserString);

    // values.dateOfBirth = dateOfBirthServerString;

    const userLegalIdentityInformation = {
      dateOfBirth: dateOfBirthServerString,
    };

    this.setState({
      awaitingFetchComplete: true,
      isErrorToastVisible: false,
    });

    if (values.isLegalName === IS_LEGAL_NAME.NO) {
      userLegalIdentityInformation.legalFirstName = values.legalFirstName;
      userLegalIdentityInformation.legalMiddleName = values.legalMiddleName;
      userLegalIdentityInformation.legalLastName = values.legalLastName;
    } else if (values.isLegalName === IS_LEGAL_NAME.YES) {
      userLegalIdentityInformation.legalFirstName = user.firstName;
      userLegalIdentityInformation.legalLastName = user.lastName;
    }

    const userCurrentAddress = { ...values.currentAddress };
    userCurrentAddress.outsideUS = outsideUsCurrentAddress;

    const serverObject = {
      userInfo: { ...userLegalIdentityInformation },
      currentAddress: { ...userCurrentAddress },
    };

    try {
      await updateLegalIdentityInformationAction(serverObject);

      push(getResidentApplicationStepPath(STEPS_CONFIG.EMPLOYMENT_INFO, { houseId, applicationId }));
    } catch (error) {
      console.error(error);

      if (error?.[0]?.errorCode === 'INVALID_ADDRESS') {
        this.setState({
          isErrorToastVisible: true,
          errorText: error?.[0]?.message,
          awaitingFetchComplete: false,
        });
      }
    }
  };

  handleOutsideUsAddressCallback = (outsideUs) => {
    this.setState({ outsideUsCurrentAddress: outsideUs });
  };

  handleToastClose = () => {
    this.setState({ isErrorToastVisible: false });
  };

  render() {
    const { user } = this.props;
    const { awaitingFetchComplete, formData, outsideUsCurrentAddress } = this.state;

    return (
      <>
        <FormLayout className="relative">
          <FormLayoutHeader title={getString(RAS.title, { firstName: user.firstName })} subtitle={RAS.subTitle} />
          <Form
            initialValues={formData}
            onSubmit={this.handleSubmit}
            getFormBottomBar={(formProps, nextButtonProps) => (
              <FormLayout.BottomBar
                ctaProps={{
                  label: RAS.next_button_text,
                }}
                nextButtonWrapperProps={nextButtonProps}
              />
            )}
            getForm={({ handleSubmit, form }) => (
              <div className={cx('form-wrapper')}>
                <form onSubmit={handleSubmit}>
                  <FormLayout.Section
                    sectionTitle={getString(RAS.is_name_legal_question, {
                      firstName: user.firstName,
                      lastName: user.lastName,
                    })}
                  >
                    <Row>
                      <Col md={12}>
                        <div className={cx('is-legal-name-selector-wrapper')}>
                          <Field
                            name="isLegalName"
                            component={SelectorFinalFormAdapter}
                            validate={required}
                            buttons={[
                              {
                                label: RAS.is_name_legal_yes,
                                key: IS_LEGAL_NAME.YES,
                              },
                              {
                                label: RAS.is_name_legal_no,
                                key: IS_LEGAL_NAME.NO,
                              },
                            ]}
                            type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Condition when="isLegalName" is={IS_LEGAL_NAME.NO}>
                      <Row>
                        <Col md={4}>
                          <Field
                            name="legalFirstName"
                            component={InputFinalFormAdapter}
                            validate={required}
                            placeholder={RAS.legal_first_name}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            name="legalMiddleName"
                            component={InputFinalFormAdapter}
                            placeholder={RAS.legal_middle_name}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            name="legalLastName"
                            component={InputFinalFormAdapter}
                            validate={required}
                            placeholder={RAS.legal_last_name}
                          />
                        </Col>
                      </Row>
                    </Condition>
                    <Row>
                      <Col md={6}>
                        <Field
                          name="dateOfBirth"
                          component={InputFinalFormAdapter}
                          placeholder={RAS.date_of_birth}
                          validate={dateOfBirthValidation}
                          mask={maskMMDDYYYY}
                          unmask={unmaskMMDDYYYY}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <AddressField
                          form={form}
                          name="currentAddress"
                          allowOutsideUs
                          outsideUs={outsideUsCurrentAddress}
                          outsideUsAddressCallback={this.handleOutsideUsAddressCallback}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className={cx('legal-disc-text')}>{RAS.legal_disc}</div>
                      </Col>
                    </Row>
                  </FormLayout.Section>
                </form>
              </div>
            )}
          />
        </FormLayout>
        <Toast isVisible={this.state.isErrorToastVisible} onClose={this.handleToastClose} variant="danger">
          {this.state.errorText}
        </Toast>
        {awaitingFetchComplete && (
          <Row className="w-full absolute top-0">
            <Col md={12}>
              <div className={cx('spinner')}>
                <Spinner />
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    user: selectUser(state),
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    creditCheck: selectResidentApplicationCreditCheck(state),
    applicantType: selectResidentApplicationApplicantType(state),
  }),
  {
    fetchApplicantInfo,
    updateLegalIdentityInformation,
    updateApplicationFlowStarted,
  }
)(CreditCheck);
