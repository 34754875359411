import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PATHS } from '@belong/common';
import { Tabs } from '@belong/ui';
import { useGetCurrentResidentFromLease } from 'accounts/hooks/leases';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import GrayBorderedBox from 'components/GrayBorderedBox/GrayBorderedBox';
import String from 'components/String/String';
import PaymentListModal from 'containercomponents/Modals/PaymentListModal/PaymentListModal';
import PaymentsModal, { PAYMENT_SCREENS } from 'containercomponents/Modals/PaymentsModal/PaymentsModal';
import PaymentMethodContainer from 'containers/PaymentMethodContainer/PaymentMethodContainer';
import { PaymentPreference, ResidentType } from 'models/enums';
import { BillType } from 'models/enums/index';
import PaymentListTile from 'pages/Accounts/Components/PaymentListTile/PaymentListTile';
import { PayoutBillDetailModal } from 'pages/Accounts/Components/payout-bill-detail-modal/payout-bill-detail-modal';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import PropTypes from 'prop-types';
import { fetchUserBills, fetchUserBillsById, createBillPaymentWithPaymentId } from 'store/redux/accounts/actions';
import { selectBills } from 'store/redux/accounts/selectors';
import { selectLeases } from 'store/redux/renter-accounts/selectors';
import { fetchPaymentMethods, updatePaymentMethod, fetchResidentLeases } from 'store/redux/user/actions';
import { selectFullUser } from 'store/redux/user/selectors';
import { RESIDENT_PAYMENTS } from 'strings/accounts/rent';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import AccountPageSection from '../../AccountPage/AccountPageSection/AccountPageSection';
import Payments from './Payments';

const TRANSACTIONS_TABS = [
  { key: 'homeowners-transactions-montly', text: 'Monthly' },
  { key: 'homeowners-transactions-receipts', text: 'Receipts' },
];

const getHouseValue = (obj) => {
  const value = Object.values(obj);

  if (value.length === 0) {
    return '';
  } else {
    return value[0];
  }
};

export const getPaymentListTileModel = (getAddressToDisplay, bill) => {
  let descriptionObject;

  if (typeof getAddressToDisplay === 'string') {
    descriptionObject = {
      ...descriptionObject,
      address: getAddressToDisplay?.length ? getAddressToDisplay : '',
    };
  } else {
    descriptionObject = {
      ...descriptionObject,
      address: getAddressToDisplay || '',
    };
  }

  descriptionObject = {
    ...descriptionObject,
    lastUpdateDate: <BDate time={bill.billInfo.lastUpdatedOn} formatType={DATE_TYPES.FULL_MONTH_DAY_YEAR} />,
    transfers: bill.transfers,
    amount: bill.billInfo.total,
    remainingAmount: bill.billInfo.remainingAmount,
    paidAmount: bill.billInfo.paidAmount,
    type: bill.billInfo.type,
    status: bill.billInfo.status,
    description: bill.billInfo.description,
  };

  return descriptionObject;
};

function selectLease(leases) {
  if (leases) {
    const current = leases.find((lease) => lease.leaseInfo.basicInfo.isCurrent);
    if (current) return current;

    const future = leases.find((lease) => lease.leaseInfo.basicInfo.isFuture);
    if (future) return future;

    const past = leases.find((lease) => lease.leaseInfo.basicInfo.isConcluded);
    if (past) return past;

    return leases[0];
  }
}

function Rent(props) {
  const { lease, userClaims } = props;
  const { isReferral } = userClaims;
  const [showPaymentListModal, setShowPaymentListModal] = useState(false);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [passInTaskToModal, setPassInTaskToModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedBillType, setSelectedBillType] = useState(null);
  const [_selectedTabIndex, setSelectedTabIndex] = useState(0);

  const currentLeaseResident = useGetCurrentResidentFromLease(lease.residents);
  const isPrimary = currentLeaseResident?.leaseInfo?.residentType === ResidentType.Primary;

  useEffect(() => {
    const fetchUserData = async () => {
      const {
        fetchUserBills: fetchUserBillsAction,
        fetchPaymentMethods: fetchPaymentMethodsAction,
        fetchUserBillsById: fetchUserBillsByIdAction,
        fetchResidentLeases: fetchResidentLeasesAction,
        match,
      } = props;

      try {
        const requests = [fetchUserBillsAction(), fetchPaymentMethodsAction(), fetchResidentLeasesAction()];

        if (match.params.propertyId) {
          requests.unshift(fetchUserBillsByIdAction(match.params.propertyId));
          const [bill] = await Promise.all(requests);

          setShowPaymentListModal(true);
          setPassInTaskToModal(match.params.propertyId);
          setSelectedBillType(bill?.billInfo?.type);
        } else {
          await Promise.all(requests);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleClosePaymentModal = () => {
    const { history } = props;
    const passInTaskToModalVar = null;

    setShowPaymentListModal(false);
    setPassInTaskToModal(passInTaskToModalVar);
    setSelectedBillType(null);

    history.push(PATHS.RESIDENTS_ACCOUNT_PAYMENTS);
  };

  function onTabIndexChange(index) {
    setSelectedTabIndex(index);
  }

  const handleOnSuccessClosePaymentModal = async () => {
    const { fetchUserBills: fetchUserBillsAction } = props;
    try {
      await fetchUserBillsAction();
    } catch (err) {
      console.error(err);
    }
    const passInTaskToModalVar = null;
    setShowPaymentListModal(false);
    setPassInTaskToModal(passInTaskToModalVar);
    setSelectedBillType(null);
  };

  const handleManualPaymentsSelection = async (value) => {
    const {
      // TODO: handle manual payments (no default payment method selected)
      // bills,
      createBillPaymentWithPaymentId: createBillPaymentWithPaymentIdAction,
    } = props;
    // task.billInfo.id
    // const currentBill = find(bills, (bill) => passInTaskToModal === bill.billInfo.id);
    setLoading(true);
    try {
      await createBillPaymentWithPaymentIdAction(passInTaskToModal, {
        ...value,
        // amount: currentBill.billInfo.total,
      });
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  const handleShowPaymentsModal = () => {
    setShowPaymentsModal(true);
  };

  const handleHideShowPaymentModal = () => {
    setShowPaymentsModal(false);
  };

  const handleShowPaymentModal = (task) => async () => {
    const { history, fetchUserBillsById: fetchUserBillsByIdAction } = props;
    const passInTaskToModalVar = task.billInfo.id;
    try {
      await fetchUserBillsByIdAction(task.billInfo.id, task.billInfo.type);
    } catch (err) {
      console.error(err);
    }

    setShowPaymentListModal(true);
    setPassInTaskToModal(passInTaskToModalVar);
    setSelectedBillType(task.billInfo.type);

    history.push(`${PATHS.RESIDENTS_ACCOUNT_PAYMENTS}/${passInTaskToModalVar}`);
  };

  const getPaymentList = () => {
    const { bills } = props;

    return !bills.length ? (
      <GrayBorderedBox>{RESIDENT_PAYMENTS['section.transaction.no_history']}</GrayBorderedBox>
    ) : (
      bills.map((bill, index) => {
        const objOfAddress = {};
        // eslint-disable-next-line no-unused-expressions
        bill.items?.forEach((item) => {
          if (!objOfAddress[item.address.addressId]) {
            objOfAddress[item.address.addressId] = item.address;
          }
        });

        const getAddressToDisplay = getHouseValue(objOfAddress);
        const paymentListObject = getPaymentListTileModel(getAddressToDisplay, bill);

        return <PaymentListTile key={index} {...paymentListObject} isResident onClick={handleShowPaymentModal(bill)} />;
      })
    );
  };

  return (
    <AccountPage
      loading={loading}
      headerProps={{
        headerText: 'Payments',
      }}
    >
      <div>
        <Tabs hasBlurOnScroll={false} index={_selectedTabIndex} onChange={onTabIndexChange}>
          <Tabs.TabList className="mx-auto w-fit">
            {TRANSACTIONS_TABS.map((tab, index) => {
              return (
                <Tabs.TabItem key={tab.key} index={index}>
                  <p>{tab.text}</p>
                </Tabs.TabItem>
              );
            })}
          </Tabs.TabList>
          <Tabs.PanelItem index={0} className="pt-xl">
            <Payments />
            {(isPrimary || isReferral) && (
              <AccountPageSection
                title={RESIDENT_PAYMENTS.PAYMENT_METHOD}
                subtitle={!isReferral ? <String string={RESIDENT_PAYMENTS.subtext} /> : null}
                content={
                  <PaymentMethodContainer
                    paymentType={PaymentPreference.Rent}
                    isRenter={!isReferral}
                    isReferral={isReferral}
                  />
                }
              />
            )}
          </Tabs.PanelItem>
          <Tabs.PanelItem index={1} className="pt-xl">
            <AccountPageSection title={RESIDENT_PAYMENTS.RECENT_PAYMENTS} content={getPaymentList()} />
          </Tabs.PanelItem>
        </Tabs>
      </div>

      <PaymentsModal
        screen={PAYMENT_SCREENS.PAYMENT_SELECTION}
        show={showPaymentsModal}
        onHide={handleHideShowPaymentModal}
        onPaymentSelection={handleManualPaymentsSelection}
        ctaLabel="Pay Now"
        errorScreenProps={{
          title: FEEDBACK_STRINGS.set_payment_method.error.title,
          ctaLabel: FEEDBACK_STRINGS.set_payment_method.error.ctaLabel,
        }}
        successScreenProps={{
          title: FEEDBACK_STRINGS.set_payment_method.success.title,
          ctaLabel: FEEDBACK_STRINGS.set_payment_method.success.ctaLabel,
        }}
      />
      {selectedBillType === BillType.Payment && passInTaskToModal && (
        <PaymentListModal
          show={showPaymentListModal}
          onHide={handleClosePaymentModal}
          onManualPayment={handleShowPaymentsModal}
          onSuccessOrFailHide={handleOnSuccessClosePaymentModal}
          paymentId={passInTaskToModal}
        />
      )}
      {selectedBillType === BillType.Payout && passInTaskToModal && (
        <PayoutBillDetailModal show={showPaymentListModal} onHide={handleClosePaymentModal} />
      )}
    </AccountPage>
  );
}

Rent.propTypes = {
  fetchUserBills: PropTypes.func.isRequired,
  fetchUserBillsById: PropTypes.func.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  createBillPaymentWithPaymentId: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchResidentLeases: PropTypes.func,
  lease: PropTypes.object,
  bills: PropTypes.array.isRequired,
  userClaims: PropTypes.object,
};

function mapStateProps(state) {
  return {
    bills: selectBills(state),
    fullUser: selectFullUser(state),
    lease: selectLease(selectLeases(state)),
  };
}
const mapDispatchToProps = {
  fetchUserBills,
  fetchUserBillsById,
  updatePaymentMethod,
  fetchPaymentMethods,
  createBillPaymentWithPaymentId,
  fetchResidentLeases,
};

export default connect(mapStateProps, mapDispatchToProps)(Rent);
