import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import LinkBase from '../../corecomponents/LinkBase/LinkBase';
import styles from './Thumbnails.module.css';

const cx = classNames.bind(styles);

const ITEMS_LIMIT = 7;

class Thumbnails extends Component {
  static propTypes = {
    unitId: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    items: [],
    unitId: '',
  };

  render() {
    const { items, unitId } = this.props;
    const moreImages = items.length > 7;
    const limitedItems = moreImages ? items.slice(0, ITEMS_LIMIT - 1) : items;
    const moreImagesCount = items.length - ITEMS_LIMIT;
    const ThumbnailComponent = unitId ? LinkBase : 'div';

    return (
      <div className={cx('thumbnails')}>
        <div className={cx('opacity')} />
        {limitedItems.map((item, index) => (
          <ThumbnailComponent
            className={cx('thumbnail', { hasLink: unitId })}
            style={{
              backgroundImage: `url('${item.imageUrl}')`,
            }}
            to={`${BASE_PATHS.HOME}/${unitId}`}
            key={index}
          />
        ))}
        {moreImages && (
          <ThumbnailComponent
            to={`${BASE_PATHS.HOME}/${unitId}`}
            className={cx('thumbnail', { hasLink: unitId }, 'more')}
            style={{
              backgroundImage: `url('${items[ITEMS_LIMIT - 1].imageUrl}')`,
            }}
          >
            <div className={cx('text')}>
              <span>{`+ ${moreImagesCount}`}</span>
              <span>MORE</span>
            </div>
          </ThumbnailComponent>
        )}
      </div>
    );
  }
}

export default Thumbnails;
