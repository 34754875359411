import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { maskMMYY, unmaskMMYY } from 'components/Input/masks';
import { MoveOutOptions } from 'models/enums';
import { useForm } from 'react-final-form';
import { getDaysInMonth } from 'date-fns';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { composeValidators, dateValidationMMYY, required } from 'utils/validation';
import { DateInputFinalFormAdapter } from 'components/DateInput/DateInput';
import Field from 'components/Field/Field';
import Condition from 'formcomponents/Condition/Condition';

export const MoveOutDateRangeFields = ({ extraValidation = () => {}, name }) => {
  const form = useForm();

  return (
    <Condition is={MoveOutOptions.InDateRange} when={`${name}.moveoutOption`}>
      <Row>
        <Col xs={12} sm={6}>
          <Field
            component={DateInputFinalFormAdapter}
            name={`${name}.moveOutInAMonth`}
            placeholder={RENTAL_STATUS_TEXT.moveOutInAMonth}
            validate={composeValidators(required, dateValidationMMYY, extraValidation)}
            mask={maskMMYY}
            unmask={unmaskMMYY}
            onChangeCustom={(value) => {
              if (value?.length === 5) {
                const [mm, yy] = value.split('/');

                // Random date in the middle of the month so we don't deal with timezone issues.
                const formattedValue = new Date(`20${yy}-${mm}-15`);
                const daysInMonth = getDaysInMonth(formattedValue);
                form.change(`${name}.moveoutStartOn`, `20${yy}-${mm}-01`);
                form.change(`${name}.moveoutEndOn`, `20${yy}-${mm}-${daysInMonth}`);
              }
            }}
          />
        </Col>
      </Row>
    </Condition>
  );
};

MoveOutDateRangeFields.propTypes = {
  extraValidation: PropTypes.func,
  name: PropTypes.string.isRequired,
};
