import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import Button, { BUTTON_SIZES } from 'components/Button/Button';
import { Flex } from 'design-system';
import { ContentLayout } from 'layouts/SingleColumnFlowLayout/ContentLayout/ContentLayout';
import { RentalStatus, MoveOutOptions } from 'models/enums';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { BASE_PATHS } from 'routes/paths';
import { confirmHomeAvailability } from 'store/redux/homeowner-accounts/actions';
import { formatString } from 'strings';
import { STRINGS } from 'strings/occupancy-standalone.strings';
import { getIsSpecificDateInLessThan1Month } from '../consts';

const getTitle = (plansHaveChanged, onboardingInfo = {}) => {
  if (getIsSpecificDateInLessThan1Month(onboardingInfo)) {
    return STRINGS['success.title.concrete_date'];
  }

  const occupied = onboardingInfo.rentalStatus !== RentalStatus.Vacant;

  let month;
  if (occupied) {
    if (
      onboardingInfo.moveoutOption === MoveOutOptions.OnSpecificDate ||
      onboardingInfo.moveoutOption === MoveOutOptions.AlreadyDid
    ) {
      const specificDate = new Date(onboardingInfo.moveoutOn);
      month = formatDateTime({ dateTime: specificDate, format: DATE_FORMATS.MONTH });
    } else {
      const inMonthDate = new Date(onboardingInfo.moveoutStartOn);
      month = formatDateTime({ dateTime: inMonthDate, format: DATE_FORMATS.MONTH });
    }
  } else if (onboardingInfo.availableForRentOn) {
    const inMonthDate = new Date(onboardingInfo.availableForRentOn);
    month = formatDateTime({ dateTime: inMonthDate, format: DATE_FORMATS.MONTH });
  }

  if (plansHaveChanged) {
    return formatString(STRINGS['success.title.something_changed'], { month });
  } else {
    return formatString(STRINGS['success.title.nothing_changed'], { month });
  }
};

export const OccupancyStandaloneSuccess = ({ location, employee, unit, property }) => {
  const dispatch = useDispatch();
  const params = queryString.parse(location.search);
  const plansHaveChangedFromParams = params?.plansHaveChanged === '1';
  const isSpecificDateInLessThan1Month = getIsSpecificDateInLessThan1Month(unit?.basicInfo?.onboardingInfo);

  useEffect(() => {
    const fetchData = () => {
      try {
        dispatch(confirmHomeAvailability(unit.basicInfo.unitId));
      } catch (e) {
        console.error(e);
      }
    };

    // Only confirm if plans haven't changed
    if (!plansHaveChangedFromParams) {
      fetchData();
    }
  }, []);

  return (
    <ContentLayout
      noOverlay
      title={getTitle(plansHaveChangedFromParams, unit?.basicInfo?.onboardingInfo)}
      employee={employee}
    >
      <Flex justifyContent="center" mb={['2xl', '2xl', '3xl']}>
        <Button
          to={{
            pathname: isSpecificDateInLessThan1Month
              ? `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${property?.basicInfo.propertyId}`
              : BASE_PATHS.ACCOUNTS,
          }}
          label={isSpecificDateInLessThan1Month ? 'Get Started' : 'Done'}
          size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
        />
      </Flex>
    </ContentLayout>
  );
};

OccupancyStandaloneSuccess.propTypes = {
  location: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};
