import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PATHS } from '@belong/common';

type ReferrerPaymentsProps = {
  userClaims: any;
  history: RouteComponentProps['history'];
};

export default function ReferrerPayments({ userClaims, history }: ReferrerPaymentsProps): JSX.Element {
  const { isHomeOwner, isResident, isReferral } = userClaims;

  if (isHomeOwner) {
    history.push(PATHS.HOMEOWNER_ACCOUNT_EARNINGS);
  } else if (isResident || isReferral) {
    history.push(PATHS.RESIDENTS_ACCOUNT_PAYMENTS);
  }

  return null;
}
