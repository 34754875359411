import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const BELONGHOME_TITLE = 'Belong';

export default function MetaTitle({ title }) {
  const fullTitle = title ? `${title} | ${BELONGHOME_TITLE}` : BELONGHOME_TITLE;
  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
      <meta name="twitter:title" content={fullTitle} />
    </Helmet>
  );
}

MetaTitle.propTypes = {
  title: PropTypes.string,
};

MetaTitle.defaultProps = {
  title: '',
};
