import { parse, isValid, isFuture, differenceInYears } from 'date-fns';

function isEmpty(value) {
  return value === undefined || value === null || value === '' || Array.isArray(value) && value.length === 0 || typeof value === 'object' && Object.keys(value).length === 0;
}
var isFunction = function (value) {
  return !isEmpty(value) && typeof value === 'function';
};
// All the error messages are not real and we need to find a way to pass error messages from the outside
function composeValidators() {
  var validators = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    validators[_i] = arguments[_i];
  }
  return function (value) {
    return validators.reduce(function (error, validator) {
      return error || validator(value);
    }, undefined);
  };
}
function isEmail(value) {
  if (isEmpty(value) || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]*[^.]([a-zA-Z0-9.-]*[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i.test(value)) {
    return 'Invalid email address';
  }
}
function isEmailIfExist(value) {
  if (!isEmpty(value) && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]*[^.]([a-zA-Z0-9.-]*[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i.test(value)) {
    return 'Invalid email address';
  }
}
function isRequired(value) {
  if (isEmpty(value)) {
    return 'Required';
  }
}
function isChecked(value) {
  if (typeof value === 'boolean' && value) {
    return;
  }
  return 'Unchecked';
}
/** Use it when we need to overwrite the default required message */
function isRequiredWithCustomMessage(message) {
  return function (params) {
    return isRequired(params) ? message : undefined;
  };
}
function isPhone(value) {
  // stolen from: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
  var phoneRegex = /^\d{10}$/;
  if (isEmpty(value)) {
    return;
  }
  if (!phoneRegex.test(value)) {
    return 'Invalid phone number';
  }
}
function minLength(len) {
  return function (value) {
    if (isEmpty(value)) {
      return;
    }
    if (isEmpty(value) || (value === null || value === void 0 ? void 0 : value.length) < len) {
      return 'Invalid string';
    }
  };
}
function maxLength(len) {
  return function (value) {
    if (value.length > len) {
      return 'Invalid string';
    }
  };
}
var isValidAddress = function (value) {
  if (isEmpty(value.city)) {
    return 'Invalid';
  } else if (isEmpty(value.streetAddress)) {
    return 'Invalid';
  } else if (isEmpty(value.zipcode)) {
    return 'Invalid';
  }
};
function isInteger(value) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}
function isCreditCard(value) {
  if (isEmpty(value)) {
    return 'Invalid';
  }
  var isAmex = /^34/.test(value) || /^37/.test(value);
  var creditCardNumberLength = isAmex ? 15 : 16;
  var isInvalid = isInteger(value) || minLength(creditCardNumberLength)(value) || maxLength(creditCardNumberLength)(value);
  if (isInvalid) {
    return 'Invalid';
  }
}
function isCreditCardCVC(value, creditCardNumber) {
  if (isEmpty(value)) {
    return 'Invalid';
  }
  var isAmex = /^34/.test(creditCardNumber) || /^37/.test(creditCardNumber);
  var creditCardNumberLength = isAmex ? 4 : 3;
  var isInvalid = isInteger(value) || minLength(creditCardNumberLength)(value.trim()) || maxLength(creditCardNumberLength)(value.trim());
  if (isInvalid) {
    return 'Invalid';
  }
}
function isDateMMDDYY(value) {
  if (isEmpty(value)) {
    return 'Invalid';
  }
  var splittedValue = value.split('/');
  if (value.split('/').length === 3) {
    var mm = splittedValue[0],
      dd = splittedValue[1],
      yy = splittedValue[2];
    if (yy.length === 2) {
      var dateObj = parse("20".concat(yy, "/").concat(mm, "/").concat(dd), 'yyyy/MM/dd', new Date());
      if (!isValid(dateObj)) {
        return 'Invalid Date';
      }
    } else {
      return 'Invalid';
    }
  } else {
    return 'Invalid';
  }
}
function isFutureDateMMDDYY(value) {
  var _a = value.split('/'),
    mm = _a[0],
    dd = _a[1],
    yy = _a[2];
  var dateObj = parse("20".concat(yy, "/").concat(mm, "/").concat(dd), 'yyyy/MM/dd', new Date());
  if (!isFuture(dateObj)) {
    return 'The date is not in the future';
  }
}
function isDateMMYY(value) {
  if (isEmpty(value)) {
    return 'Invalid';
  }
  var splittedValue = value.split('/');
  if (value.split('/').length === 2) {
    var mm = splittedValue[0],
      yy = splittedValue[1];
    if (yy.length === 2) {
      var dateObj = parse("20".concat(yy, "/").concat(mm, "/01"), 'yyyy/MM/dd', new Date());
      if (!isValid(dateObj)) {
        return 'Invalid Date';
      }
    } else {
      return 'Invalid';
    }
  } else {
    return 'Invalid';
  }
}
function isDateMMYYYY(value) {
  if (isEmpty(value)) {
    return 'Invalid';
  }
  var splittedValue = value.split('/');
  if (value.split('/').length === 2) {
    var mm = splittedValue[0],
      yyyy = splittedValue[1];
    if (yyyy.length === 4) {
      var dateObj = parse("".concat(yyyy, "/").concat(mm, "/01"), 'yyyy/MM/dd', new Date());
      if (!isValid(dateObj)) {
        return 'Invalid Date';
      }
    } else {
      return 'Invalid';
    }
  } else {
    return 'Invalid';
  }
}
var isFutureDateMMYY = function (value) {
  var _a = value.split('/'),
    mm = _a[0],
    yy = _a[1];
  var dateObj = parse("20".concat(yy, "/").concat(mm, "/01"), 'yyyy/MM/dd', new Date());
  if (!isFuture(dateObj)) {
    return 'The date is not in the future';
  }
};
function isZipCode(value) {
  if (isEmpty(value)) {
    return 'Invalid';
  }
  var isInvalid = Boolean(isInteger(value)) || value.length !== 5;
  if (isInvalid) {
    return 'Invalid';
  }
}
function isGreaterThan(comparisonValue) {
  return function (value) {
    if (!Number.isNaN(value)) {
      if (Number(value) <= comparisonValue) {
        return 'The value is lower than expected.';
      }
    }
  };
}
function isSmallerThan(comparisonValue) {
  return function (value) {
    if (!Number.isNaN(value)) {
      if (Number(value) >= comparisonValue) {
        return 'The value is higher than expected.';
      }
    }
  };
}
var dateValidationMMYY = function (value) {
  if (!isEmpty(value) && value.toString().split('/').length === 2) {
    var _a = value.toString().split('/'),
      mm = _a[0],
      yy = _a[1];
    if (yy.length === 2) {
      var dateObj = new Date("20".concat(yy, "/").concat(mm, "/01"));
      if (!isValid(dateObj)) {
        return 'Invalid Date';
      }
    } else {
      return 'Invalid';
    }
  } else {
    return 'Invalid';
  }
};
function isFutureDateMMDDYYYY(value) {
  var dateObj = parse(value, 'MM/dd/yyyy', new Date());
  if (!isFuture(dateObj)) {
    return 'The date is not in the future';
  }
}
function isPastDateMMDDYYYY(value) {
  var dateObj = parse(value, 'MM/dd/yyyy', new Date());
  if (isFuture(dateObj)) {
    return 'Invalid Date';
  }
}
function isKid(value) {
  var dateObj = parse(value, 'MM/dd/yyyy', new Date());
  var age = differenceInYears(new Date(), dateObj);
  if (age >= 18) {
    return 'Invalid Date';
  }
}
function isSSN(value) {
  var ssnRegex = /^\d{9}$/;
  if (!ssnRegex.test(value)) {
    return 'Invalid SSN';
  }
}
function isSSNWithLastDigits(value) {
  if (Boolean(value) && !/^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/i.test(value) && !/^XXXXX\-?[0-9]{4}$/i.test(value)) {
    return 'Invalid SSN';
  }
}

export { composeValidators, dateValidationMMYY, isChecked, isCreditCard, isCreditCardCVC, isDateMMDDYY, isDateMMYY, isDateMMYYYY, isEmail, isEmailIfExist, isEmpty, isFunction, isFutureDateMMDDYY, isFutureDateMMDDYYYY, isFutureDateMMYY, isGreaterThan, isInteger, isKid, isPastDateMMDDYYYY, isPhone, isRequired, isRequiredWithCustomMessage, isSSN, isSSNWithLastDigits, isSmallerThan, isValidAddress, isZipCode, maxLength, minLength };
