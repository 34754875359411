import React, { Component } from 'react';
import classNames from 'classnames/bind';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import PropTypes from 'prop-types';
import LinkBase from '../../corecomponents/LinkBase/LinkBase';
import GeneralIcon, { GENERAL_ICONS, COLORS } from '../GeneralIcon/GeneralIcon';
import styles from './IconButton.module.css';

const cx = classNames.bind(styles);

export const ALIGN_TYPES = {
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  NONE: 'none',
};

export const TEXT_TYPES = {
  UPPERCASE: 'uppercase',
  NORMAL: 'normal',
};

export default class IconButton extends Component {
  static propTypes = {
    paddingCustomClassName: PropTypes.string,
    // Icon added for prop
    icon: PropTypes.string.isRequired,
    // GeneralIcon component props. (color & scale)
    generalIconProps: PropTypes.object,
    // alternative to typing a child, but takes priority. It doesn't matter here,
    // but it's to remain consistent with other parts of the code base.
    label: PropTypes.string,
    // choose to align left or right. A boolean value can work too, but I think this is more semantic
    align: PropTypes.oneOf(Object.values(ALIGN_TYPES)),
    // Makes the button disabled
    disabled: PropTypes.bool,
    // Icon if the button is disabled
    disabledIcon: PropTypes.node,
    // alternative to typing a child, but takes priority. It doesn't matter here,
    // Works like label
    children: PropTypes.node,
    // If the button is a link
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    // External link
    href: PropTypes.string,
    wrapText: PropTypes.bool,

    textstyle: PropTypes.oneOf(Object.values(TEXT_TYPES)),
    style: PropTypes.object,
  };

  static defaultProps = {
    label: '',
    align: ALIGN_TYPES.RIGHT,
    disabled: false,
    children: '',
    disabledIcon: null,
    to: null,
    href: '',
    textstyle: TEXT_TYPES.NORMAL,
    generalIconProps: {},
    wrapText: false,
    paddingCustomClassName: '',
    style: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      focused: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  handleBlur = () => {
    this.setState({
      focused: false,
    });
  };

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  };

  renderIcon() {
    const { icon, align, disabled, generalIconProps, style } = this.props;
    const { hover, focused } = this.state;
    const generalIconPropsCopy = { ...generalIconProps };

    if (disabled) {
      generalIconPropsCopy.color = COLORS.GRAY;
    }

    if ((!disabled && hover) || (!disabled && focused)) {
      generalIconPropsCopy.color = generalIconProps.hoverColor || COLORS.DARK_NAVY;
    }

    return (
      <span
        className={cx('icon', {
          left: align === ALIGN_TYPES.LEFT,
          right: align === ALIGN_TYPES.RIGHT,
        })}
        style={style}
      >
        <GeneralIcon {...generalIconPropsCopy} icon={icon} className={cx({ bottom: align === ALIGN_TYPES.BOTTOM })} />
      </span>
    );
  }

  render() {
    const {
      label,
      disabledIcon,
      align,
      children,
      disabled,
      to,
      href,
      textstyle,
      generalIconProps,
      wrapText,
      paddingCustomClassName,
      ...other
    } = this.props;

    const { focused } = this.state;

    const ButtonComponent = to || href ? LinkBase : ButtonBase;

    const buttonProps = {};

    if (to) {
      buttonProps.to = to;
    }

    if (href) {
      buttonProps.href = href;
    }

    return (
      <ButtonComponent
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        disabled={disabled}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...buttonProps}
        {...other}
      >
        <div
          className={cx(
            'iconButton',
            { focused },
            paddingCustomClassName,
            { wrapText },
            { disabled, [textstyle]: textstyle },
            { inlineBlock: align === ALIGN_TYPES.BOTTOM }
          )}
        >
          {align === ALIGN_TYPES.LEFT && (
            <>
              <span>{children || label}</span>
              {this.renderIcon()}
            </>
          )}
          {align === ALIGN_TYPES.RIGHT && (
            <>
              {this.renderIcon()}
              <span>{children || label}</span>
            </>
          )}
          {align === ALIGN_TYPES.NONE && (
            <>
              <span>{children || label}</span>
              {this.renderIcon()}
            </>
          )}
          {align === ALIGN_TYPES.BOTTOM && (
            <>
              <span>{children || label}</span>
              {this.renderIcon()}
            </>
          )}
        </div>
      </ButtonComponent>
    );
  }
}

IconButton.GENERAL_ICONS = GENERAL_ICONS;
