/* eslint-disable react/prop-types */
import React from 'react';
import { PaymentMethod } from 'models/enums';
import { paymentMethodSubStringConverter, getFeeFromPaymentMethod } from 'models/utilities/paymentMethods';

const upperCaseFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const PaymentMethodNamingDisplay = ({
  paymentMethod,
  accountLastDigits,
  referenceNumber,
  bankName,
  otherPaymentType,
  accountType,
  lengthOfAccountName,
  showFee = false,
  hasFee = false,
}) => {
  if (paymentMethod === PaymentMethod.Ach) {
    return accountLastDigits ? (
      <div>
        <p>{paymentMethodSubStringConverter(bankName, lengthOfAccountName)}</p>
        <p className="text-p1 font-regular">
          {accountType ? upperCaseFirstLetter(accountType) : ''} ending in {accountLastDigits} {showFee && <>&#183;</>}{' '}
          {showFee && <span className="text-green">{getFeeFromPaymentMethod(paymentMethod, hasFee)}</span>}
        </p>
      </div>
    ) : (
      'Add Bank Account'
    );
  } else if (paymentMethod === PaymentMethod.Check) {
    return `Check #${referenceNumber}`;
  } else if (paymentMethod === PaymentMethod.CreditCard) {
    return (
      <div>
        <p>{paymentMethodSubStringConverter(bankName, lengthOfAccountName)}</p>
        <p className="text-p1 font-regular">
          Card ending in {accountLastDigits} {showFee && <>&#183;</>}{' '}
          {showFee && <span className="text-green">{getFeeFromPaymentMethod(paymentMethod, hasFee)}</span>}
        </p>
      </div>
    );
  } else if (paymentMethod === PaymentMethod.WireTransfer) {
    return `Wire Transfer #${referenceNumber}`;
  } else if (paymentMethod === PaymentMethod.Paypal) {
    return `Paypal Transfer #${referenceNumber}`;
  } else if (paymentMethod === PaymentMethod.Other) {
    return `${otherPaymentType || paymentMethod} ${referenceNumber ? ` #${referenceNumber}` : ''}`;
  }
};

export default PaymentMethodNamingDisplay;
