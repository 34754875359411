import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IconBank } from '@belong/icons';
import { Money } from '@belong/ui';
import { LazyLottieAnimation } from 'animations/LazyLottieAnimation/LazyLottieAnimation';
import classNames from 'classnames/bind';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import { Text } from 'design-system';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { PayoutPlanTypes } from 'models/enums';
import AgreementFlowFormLayout from 'pages/AgreementFlow/AgreementFlowFormLayout/AgreementFlowFormLayout';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { selectHomeFeesSettings } from 'store/redux/experiments';
import { formatString } from 'strings';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import styles from './Plan.module.css';

const cx = classNames.bind(styles);
const lottieStyles = {
  margin: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  pointerEvents: 'none',
  zIndex: 10002,
};

const Plan = ({ currentStepFormData, onSubmit, setSidebarTip, setMakeGreen, history, homeFeesSettings, ...rest }) => {
  const [isLastStep, setIsLastStep] = useState(false);
  const [isMaxVisible, setIsMaxVisible] = useState(true);
  const multipleHomes = currentStepFormData?.homeAgreements?.length > 1;

  const handleChangePlanType = (value) => {
    if (value === PayoutPlanTypes.Monthly) {
      setMakeGreen(true);
      setIsMaxVisible(true);
      setSidebarTip({
        descriptions: AGREEMENT_FLOW_STRINGS['plan.tips.guaranteed.title'],
      });
    } else if (value === PayoutPlanTypes.Yearly) {
      setMakeGreen(true);
      setIsMaxVisible(false);
      setSidebarTip({
        descriptions: AGREEMENT_FLOW_STRINGS['plan.tips.upfront.title'],
      });
    }
  };

  const handleChangeLetsDiscuss = (value, form) => {
    if (value) {
      form.change('payoutPlan', null);
      setSidebarTip({
        descriptions: AGREEMENT_FLOW_STRINGS['plan.tips.lets-discuss.title'],
      });
      setMakeGreen(true);
      setIsLastStep(true);
    } else {
      setIsLastStep(false);
    }
  };

  const handleSubmit = (values) => {
    const { homeAgreements } = currentStepFormData;

    if (values.letsDiscuss) {
      history.push(BASE_PATHS.ACCOUNTS);
      return;
    }

    homeAgreements.forEach((ha) => {
      ha.payoutPlan = values.payoutPlan;
    });
    onSubmit({ homeAgreements });
  };

  const getInitialValues = () => {
    if (!currentStepFormData || !currentStepFormData?.homeAgreements?.length) return;

    const { homeAgreements } = currentStepFormData;
    const initialValues = { ...homeAgreements[0] };

    return initialValues;
  };

  useEffect(() => {
    const initialValues = getInitialValues();

    if (initialValues?.payoutPlan) {
      handleChangePlanType(initialValues.payoutPlan);
    }
  }, [currentStepFormData]);

  const validateForm = (values) => {
    if (!values.letsDiscuss && !values.payoutPlan) {
      return { payoutPlan: 'invalid' };
    }
  };

  let guaranteedManagementFee = homeFeesSettings.ManagementFeesMonthly;
  let upfrontManagementFee = homeFeesSettings.ManagementFeesYearly;
  const minimum = isMaxVisible ? homeFeesSettings.MinimumMonthly : homeFeesSettings.MinimumYearly;
  const maximumMonthly = homeFeesSettings?.MaximumMonthly;

  const agreementValues = getInitialValues();
  if (agreementValues?.payoutPlan) {
    if (agreementValues.payoutPlan === PayoutPlanTypes.Monthly) {
      guaranteedManagementFee = agreementValues.managementFeePercentage || guaranteedManagementFee;
    } else if (agreementValues.payoutPlan === PayoutPlanTypes.Yearly) {
      upfrontManagementFee = agreementValues.managementFeePercentage || upfrontManagementFee;
    }
  }

  const additionalProps = {};
  additionalProps.isLastStep = isLastStep;

  return (
    <AgreementFlowFormLayout
      {...rest}
      onSubmit={handleSubmit}
      title={multipleHomes ? AGREEMENT_FLOW_STRINGS['plan.title-plural'] : AGREEMENT_FLOW_STRINGS['plan.title']}
      initialValues={getInitialValues()}
      customValidation={validateForm}
      {...additionalProps}
      getForm={({ form }) => (
        <>
          <LazyLottieAnimation name="agreement_plan_heart" style={lottieStyles} styleMobile={lottieStyles} />
          <FormLayout.Section
            firstSection
            sectionTitle={AGREEMENT_FLOW_STRINGS['plan.which-plan.section-title']}
            sectionSubTitle={
              multipleHomes
                ? AGREEMENT_FLOW_STRINGS['plan.which-plan.section-subtitle.plural']
                : AGREEMENT_FLOW_STRINGS['plan.which-plan.section-subtitle']
            }
          >
            <Field
              name="payoutPlan"
              component={SelectorFinalFormAdapter}
              type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
              fluid
              onChangeCustom={(value) => {
                form.change('letsDiscuss', false);
                setIsLastStep(false);
                handleChangePlanType(value);
              }}
              buttons={[
                {
                  label: AGREEMENT_FLOW_STRINGS['plan.selector.guaranteed'],
                  subLabel: formatString(AGREEMENT_FLOW_STRINGS['plan.selector.guaranteed.subLabel'], {
                    guaranteedManagementFee,
                  }),
                  key: PayoutPlanTypes.Monthly,
                  centerText: true,
                },
                {
                  label: AGREEMENT_FLOW_STRINGS['plan.selector.upfront'],
                  subLabel: formatString(AGREEMENT_FLOW_STRINGS['plan.selector.upfront.subLabel'], {
                    upfrontManagementFee,
                  }),
                  key: PayoutPlanTypes.Yearly,
                  centerText: true,
                },
              ]}
            />
            <div className={cx('fees-wrapper')}>
              <Text fontSize="p1">
                <Money value={minimum} as="span" variant="p1" />
                /mo. minimum
                {isMaxVisible && maximumMonthly && (
                  <>
                    {' - '}
                    <Money value={maximumMonthly} as="span" variant="p1" />
                    /mo. maximum
                  </>
                )}
              </Text>
            </div>
            <div className={cx('bottom-wrapper-desktop')}>
              <div className={cx('lets-discuss-section')}>
                <Field
                  name="letsDiscuss"
                  component={CheckboxFinalFormAdapter}
                  label={AGREEMENT_FLOW_STRINGS['plan.lets-discuss.label']}
                  alignWithField={false}
                  useOnChangeFormToHandle
                  onClickCustom={(value) => handleChangeLetsDiscuss(value, form)}
                />
              </div>
              <div className="flex items-end gap-xs flex-nowrap justify-end">
                <IconBank className="mb-2xs h-sm" />
                <div className={cx('tax-deductible-text')}>{AGREEMENT_FLOW_STRINGS['plan.tax-deductible.text']}</div>
              </div>
            </div>
            <div className={cx('bottom-wrapper-mobile')}>
              <div className={cx('tax-deductible-section', 'flex gap-xs flex-nowrap')}>
                <IconBank className="h-sm" style={{ marginBottom: 2 }} />
                <div className={cx('tax-deductible-text')}>{AGREEMENT_FLOW_STRINGS['plan.tax-deductible.text']}</div>
              </div>
              <div className={cx('tax-deductible-lets-discuss-field')}>
                <Field
                  name="letsDiscuss"
                  component={CheckboxFinalFormAdapter}
                  label={AGREEMENT_FLOW_STRINGS['plan.lets-discuss.label']}
                  alignWithField={false}
                  onClickCustom={(value) => handleChangeLetsDiscuss(value, form)}
                />
              </div>
            </div>
          </FormLayout.Section>
        </>
      )}
    />
  );
};

Plan.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currentStepFormData: PropTypes.object,
  homeFeesSettings: PropTypes.object.isRequired,
  setSidebarTip: PropTypes.func.isRequired,
  setMakeGreen: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

Plan.defaultProps = {
  currentStepFormData: null,
};

const mapStateToProps = (state, props) => {
  const { coverageRegionId } = props.currentMappedStep?.property?.basicInfo || {};
  return {
    homeFeesSettings: selectHomeFeesSettings(state, coverageRegionId),
  };
};

export default connect(mapStateToProps)(Plan);
