import { MaintenanceModel, MaintenancePaymentModel } from 'api/models';
import Collapsible from 'components/Collapsible/Collapsible';
import UserActionMaintenance from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance';

const getUserFromPayment = (task: MaintenanceModel, payment) => {
  if (payment.paidBy === 'Homeowner') {
    const newTask = {
      ...task.homeowner,
      reportedBy: 'Homeowner',
    };
    return newTask;
  }

  if (payment.paidBy === 'Resident') {
    const newTask = {
      ...task.resident,
      ...payment.paidByUser,
      reportedBy: 'Resident',
    };
    return newTask;
  }

  return {};
};

const getTextFromConsentStatus = (approval) => {
  if (approval === 'Approved') {
    return 'Approved By';
  }

  if (approval === 'Rejected') {
    return 'Not Approved By';
  }
};

type MaintenanceApprovalAndResponsibility = {
  task: MaintenanceModel;
  payments: MaintenancePaymentModel[];
  collapsable?: boolean;
  title: string;
};

export const MaintenanceApprovalAndResponsibility = ({
  task,
  payments,
  collapsable = true,
  title,
}: MaintenanceApprovalAndResponsibility) => {
  if (collapsable) {
    return (
      <Collapsible
        title={title}
        collapsed={false}
        inner={
          <>
            {payments.map((payment) => {
              const user = getUserFromPayment(task, payment);

              return (
                <UserActionMaintenance
                  key={user.userId}
                  reportedBy={user.reportedBy ? user.reportedBy : 'Belong'}
                  name={user.name}
                  time={payment.consentOn}
                  profileImage={user.profileImageUrl}
                  userId={user.userId}
                  taskType={getTextFromConsentStatus(payment.consentStatus)}
                />
              );
            })}
          </>
        }
      />
    );
  }

  return (
    <>
      {payments.map((payment) => {
        const user = getUserFromPayment(task, payment);

        return (
          <UserActionMaintenance
            key={user.userId}
            reportedBy={user.reportedBy ? user.reportedBy : 'Belong'}
            name={user.name}
            time={payment.consentOn}
            profileImage={user.profileImageUrl}
            userId={user.userId}
            taskType={getTextFromConsentStatus(payment.consentStatus)}
          />
        );
      })}
    </>
  );
};
