import { Button, Text } from '@belong/ui';
import { InspectionDatePicker } from 'accounts/components/inspection-date-picker/inspection-date-picker';
import { STRINGS } from 'accounts/constants/strings/reschedule-appointment';
import { useRescheduleAppointmentContext } from 'accounts/hooks/reschedule-appointment';
import type { RescheduleAppointmentOptionsFormSchema } from 'accounts/types';
import { getSubmitButtonDisabled } from 'accounts/utils/reschedule-appointment';
import Field from 'components/Field/Field';
import LegacyForm from 'components/Form/Form';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import styles from './reschedule-inspection-appointment.module.css';

const Form = LegacyForm;

type Props = {
  onSubmit: (values: RescheduleAppointmentOptionsFormSchema) => Promise<void>;
};

export function RescheduleInspectionAppointmentOptionsForm({ onSubmit }: Props) {
  const { timeZone, unit } = useRescheduleAppointmentContext();

  return (
    <Form
      initialValues={{}}
      onSubmit={onSubmit}
      getForm={({ values, form }) => {
        const { date, time } = values as RescheduleAppointmentOptionsFormSchema;

        const isSubmitButtonDisabled = getSubmitButtonDisabled({
          date,
          time,
        });

        return (
          <div className="w-full">
            <div className="px-2sm sm:px-0">
              <div className="mb-xs px-0 sm:px-sm">
                <Text fontWeight="semibold" variant="h3">
                  {STRINGS['step.options.reschedule.title']}
                </Text>
              </div>
              <div className="mb-xl px-0 sm:px-sm">
                <Text className="text-dark-gray">{STRINGS['step.options.reschedule.subtitle']}</Text>
              </div>
              <div className="lg:px-2sm mb-2xl">
                <Field
                  name="date"
                  withMarginTop={false}
                  component={InspectionDatePicker}
                  timeZone={timeZone}
                  form={form}
                  unit={unit}
                  onChangeCustom={() => {
                    form.change('time', undefined);
                  }}
                  sideInformationWrapperClassName="sm:px-sm lg:px-0 xl:pl-sm w-full"
                  wrapperClassName={styles.wrapper}
                  calendarRootClassName="sm:px-xs lg:px-0 xl:pr-2sm"
                  timePickerWrapperClassName="w-full"
                  demandWrapperClassName="md:px-sm lg:px-0"
                />
              </div>
              <QuestionsOrConcernsModalFooter>
                <div className="w-full sm:w-button-default">
                  <Button disabled={isSubmitButtonDisabled} type="submit">
                    <Text fontWeight="semibold">{STRINGS['step.options.option.form.title']}</Text>
                  </Button>
                </div>
              </QuestionsOrConcernsModalFooter>
            </div>
          </div>
        );
      }}
    />
  );
}
