import { formatDateTime, DATE_FORMATS } from '@belong/common';
import { IdVerificationDocuments } from '../../models/enums';

export const getIdVerificationInitialValues = (identityVerification) => {
  const { user, idFront: idFrontFile, idBack: idBackFile, selfie: selfieFile } = identityVerification || {};

  if (!user.idType) {
    return {};
  }

  const idVerificationInitialValues = {
    type: user.idType,
    imageSelfie: {
      availableMedia: selfieFile ? [{ ...selfieFile }] : [],
      mediaToDisplay: selfieFile ? [{ ...selfieFile }] : [],
    },
    imagePassport: {
      availableMedia: user.idType === IdVerificationDocuments.PASS ? [{ ...idFrontFile }] : [],
      mediaToDisplay: user.idType === IdVerificationDocuments.PASS ? [{ ...idFrontFile }] : [],
    },
    imageIdFront: {
      availableMedia: user.idType !== IdVerificationDocuments.PASS ? [{ ...idFrontFile }] : [],
      mediaToDisplay: user.idType !== IdVerificationDocuments.PASS ? [{ ...idFrontFile }] : [],
    },
    imageIdBack: {
      availableMedia: user.idType !== IdVerificationDocuments.PASS ? [{ ...idBackFile }] : [],
      mediaToDisplay: user.idType !== IdVerificationDocuments.PASS ? [{ ...idBackFile }] : [],
    },
  };

  if (user.dateOfBirth)
    idVerificationInitialValues.dateOfBirth = formatDateTime({
      dateTime: user.dateOfBirth,
      format: DATE_FORMATS.STANDARD,
    });

  return idVerificationInitialValues;
};
