function range(start, end, step) {
  if (end === undefined) {
    end = start;
    start = 0;
  }
  if (step === undefined) {
    step = start < end ? 1 : -1;
  }
  var index = -1;
  var length = Math.max(Math.ceil((end - start) / (step || 1)), 0) || 0;
  var result = Array(length);
  while (length--) {
    result[++index] = start;
    start += step;
  }
  return result;
}

export { range };
