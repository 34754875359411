/* eslint-disable max-len */

export const RESIDENT_ONBOARDING_STRINGS = {
  personal_info: {
    title: "Let's get to know you better, {firstName}.",
    subTitle: 'In order to set up your account to pay rent, we first need some personal info.',
    is_name_legal_question: "Is '{firstName} {lastName}' your legal name?",
    is_name_legal_yes: "Yes, that's correct.",
    is_name_legal_no: "No, it's different.",
    legal_first_name: 'Legal first name',
    legal_middle_name: 'Legal middle name',
    legal_last_name: 'Legal last name',
    date_of_birth: 'Date of Birth (MM/DD/YYYY)',
    full_ssn_itin: 'Full SSN or ITIN',
    current_address: 'Current address',
    unit_number: 'Unit',
    same_mailing_address: 'This is also my mailing address.',
    where_to_send_title: 'Where can we send you stuff?',
    mailing_address: 'Mailing address',
    next_button_text: 'Next',
  },
  success_page: {
    title: 'Thanks!',
    subTitle: "Now, let's set up your payment accounts to get you paid.",
    to_do_title: "To Do's",
    to_do: {
      one: 'ADD A PAYMENT METHOD FOR RENT',
      // two: 'ADD A PAYMENT METHOD FOR MAINTENANCE',
      two: 'COMPLETE YOUR PROFILE',
    },
    next_button_text: 'Go to Accounts',
  },
};
