import React from 'react';
import classNames from 'classnames/bind';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import Tooltip from 'components/Tooltip/Tooltip';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { Flex, Text } from 'design-system';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import styles from './PreventativeBundleProgress.module.css';

const cx = classNames.bind(styles);

const PreventativeBundleProgress = ({ approvedItems, totalEstimatedCostPrevented }) => {
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const tooltipMobileProps = isMobile
    ? {
        contentStyle: {
          width: 200,
        },
        position: 'bottom center',
      }
    : {};

  const currentEstimatedCostPrevented = approvedItems.reduce((totalCost, currentApprovedItem) => {
    const currentApprovedItemCost = currentApprovedItem.maintenance.estimatedCostPrevented || 0;

    return totalCost + currentApprovedItemCost;
  }, 0);

  const estimatedCostPreventedPercentage = (currentEstimatedCostPrevented / totalEstimatedCostPrevented) * 100;

  return (
    <Flex flexDirection="column" marginTop="sm">
      <div className={cx('dashed-separator')} />
      <Flex marginBottom={['xs', 0]} marginTop={['xs', 'xs']} paddingY="xs">
        <Text fontSize="p1" fontWeight="semibold">
          {POST_INSPECTION_FLOW_STRINGS['approvals.preventative.tooltip.title']}
        </Text>
        <Tooltip
          className={cx('tooltip-icon-wrapper')}
          iconProps={{ className: cx('tooltip-icon') }}
          position="right center"
          {...tooltipMobileProps}
        >
          <p>{POST_INSPECTION_FLOW_STRINGS['approvals.preventative.tooltip.detail']}</p>
        </Tooltip>
      </Flex>
      <Flex alignItems="center">
        <Flex alignItems="center" flexShrink="0" justifyContent="flex-start" marginRight="xs" maxWidth="72px">
          <Text fontSize="p1">
            <Money cash={0} format={MONEY_FORMAT.BASIC} />
          </Text>
        </Flex>
        <div className={cx('bar')}>
          <div
            className={cx('bar-filling', {
              'danger-bar-filling': estimatedCostPreventedPercentage < 40,
              'warning-bar-filling': estimatedCostPreventedPercentage >= 40 && estimatedCostPreventedPercentage < 70,
              'success-bar-filling': estimatedCostPreventedPercentage >= 70,
            })}
            style={{ width: `${estimatedCostPreventedPercentage}%` }}
          />
        </div>
        <Flex alignItems="center" flexShrink="0" justifyContent="flex-end" marginLeft="xs" maxWidth="72px">
          <Text fontSize="p1">
            <Money cash={totalEstimatedCostPrevented} format={MONEY_FORMAT.BASIC} />
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

PreventativeBundleProgress.propTypes = {
  approvedItems: PropTypes.object.isRequired,
  totalEstimatedCostPrevented: PropTypes.number.isRequired,
};

export { PreventativeBundleProgress };
