export const maintenanceCostStrings = {
  titleReceipt: 'Receipt',
  titleEstimated: 'Proposal',
  tableItem: 'Item',
  tableAmount: 'Amount',
  tableTotal: 'Total',
  tableSubtotal: 'Subtotal',
  tableEstTotal: 'Est. Total',
  tableTooltipTitle:
    'With all Belong Pro Services, we strive to charge at market rate for high quality, reliable work.',
  tableTooltipListTitle: 'There are 3 contributors to the final cost:',
  tableTooltipListItems: [
    'Maintenance Labor',
    'Sourcing and purchasing parts and materials',
    'Belong operations, scheduling, and coordination fee',
  ],
  tableUserRoleHomeowner: 'Homeowner',
  tableUserRoleResident: 'Resident',
  tableResponsiblePercentage: 'Your Portion',
  tableResponsiblePercentageZero: 'Your Portion (0%)',
  tableResponsiblePercentageZeroValue: 'Free',
  tableDueUpfront: 'Due Upfront',
  responsibilityConsentApprovalSuccess: 'You approved this work!',
  responsibilityConsentRejectionSuccess: 'You did not approve this work.',
  responsibilityConsentRejectionSuccessSchedule:
    'You did not approve this work. You can schedule a call with your Homecare Specialist to discuss further.',
  responsibilityConsentFail: 'There was an error submitting your response. Please try again.',
};
