import {useEffect} from 'react';
import { useParams, useHistory } from 'react-router';

import { BASE_PATHS } from 'routes/paths';

export function useCheckPropertyIdParam() {
  const { propertyId } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    if (!propertyId || propertyId === "undefined") {
      history.push(BASE_PATHS.ACCOUNTS)
    }
  }, [history, propertyId])
}