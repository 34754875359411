import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Image } from '@belong/ui';

export const PaymentHeroImage = () => {
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  const src = isMobile
    ? 'improvement-reports/payment-modal-hero-mobile.jpg'
    : 'improvement-reports/payment-modal-hero.jpg';

  return (
    <Image
      alt="payment-modal-hero"
      className="
        bg-cover bg-center h-[300px] border-b sm:border-x-0 border-solid border-b-dark-gray rounded-r rounded-t
        sm:rounded-r-none sm:rounded-t sm:border-b
      "
      src={src}
    />
  );
};
