export const mockFlow = {
  id: 1095,
  uniqueId: '149620ac1daf123f',
  userId: 1999,
  flowType: 'HomeownerSignUp',
  status: 'Created',
  steps: [
    {
      id: 11980,
      uniqueId: '4b9e496e65063528',
      flowId: 1095,
      stepName: 'HomeownerMaintenanceInvolvement',
      dataType: 'User',
      dataId: 1999,
      dataUniqueId: 'a79d1fbc3b513e03',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T16:13:56.6049334+00:00',
        createdBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIIk=',
      },
    },
    {
      id: 11981,
      uniqueId: '65a9afd8aa108ccc',
      flowId: 1095,
      stepName: 'HomeownerMaintenancePreferences',
      dataType: 'User',
      dataId: 1999,
      dataUniqueId: 'a79d1fbc3b513e03',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T16:13:56.6049334+00:00',
        createdBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIIo=',
      },
    },
    {
      id: 11982,
      uniqueId: '47d93cb76f45b0ff',
      flowId: 1095,
      stepName: 'PropertyBasics',
      dataType: 'Property',
      dataId: 1182,
      dataUniqueId: '818d981fd80081f7',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T16:22:42.9998818+00:00',
        createdBy: 'Pradeep Eagle1',
        lastModifiedOn: '2021-05-08T17:02:44.769233+00:00',
        lastModifiedBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPE=',
      },
    },
    {
      id: 11983,
      uniqueId: '467cf2bee00aac4e',
      flowId: 1095,
      stepName: 'UnitBasics',
      dataType: 'Home',
      dataId: 1492,
      dataUniqueId: '1ae529a3a3533e53',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T16:22:42.9998818+00:00',
        createdBy: 'Pradeep Eagle1',
        lastModifiedOn: '2021-05-08T17:02:44.769233+00:00',
        lastModifiedBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPI=',
      },
    },
    {
      id: 11984,
      uniqueId: '3916da95387b8359',
      flowId: 1095,
      stepName: 'UnitConditions',
      dataType: 'Home',
      dataId: 1492,
      dataUniqueId: '1ae529a3a3533e53',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T16:22:42.9998818+00:00',
        createdBy: 'Pradeep Eagle1',
        lastModifiedOn: '2021-05-08T17:02:44.769233+00:00',
        lastModifiedBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPM=',
      },
    },
    {
      id: 11985,
      uniqueId: '91f2efaee605a877',
      flowId: 1095,
      stepName: 'UnitOccupancy',
      dataType: 'Home',
      dataId: 1492,
      dataUniqueId: '1ae529a3a3533e53',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T16:22:42.9998818+00:00',
        createdBy: 'Pradeep Eagle1',
        lastModifiedOn: '2021-05-08T17:02:44.769233+00:00',
        lastModifiedBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPQ=',
      },
    },
    {
      id: 11986,
      uniqueId: 'd456aab3010dd2c3',
      flowId: 1095,
      stepName: 'UnitMaintenanceSpend',
      dataType: 'Home',
      dataId: 1492,
      dataUniqueId: '1ae529a3a3533e53',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T16:22:42.9998818+00:00',
        createdBy: 'Pradeep Eagle1',
        lastModifiedOn: '2021-05-08T17:02:44.769233+00:00',
        lastModifiedBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPU=',
      },
    },
    {
      id: 11987,
      uniqueId: 'c2e327b8625b5411',
      flowId: 1095,
      stepName: 'PropertyBasics',
      dataType: 'Property',
      dataId: 1183,
      dataUniqueId: '352588b528189d9d',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T17:02:44.7784428+00:00',
        createdBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPY=',
      },
    },
    {
      id: 11988,
      uniqueId: '38713e01cdc5d077',
      flowId: 1095,
      stepName: 'UnitBasics',
      dataType: 'Home',
      dataId: 1493,
      dataUniqueId: '455dcd2d097bd41b',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T17:02:44.7784428+00:00',
        createdBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPc=',
      },
    },
    {
      id: 11989,
      uniqueId: '647d5fa473d3ae85',
      flowId: 1095,
      stepName: 'UnitConditions',
      dataType: 'Home',
      dataId: 1493,
      dataUniqueId: '455dcd2d097bd41b',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T17:02:44.7784428+00:00',
        createdBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPg=',
      },
    },
    {
      id: 11990,
      uniqueId: 'b9c7b74963a34cd5',
      flowId: 1095,
      stepName: 'UnitOccupancy',
      dataType: 'Home',
      dataId: 1493,
      dataUniqueId: '455dcd2d097bd41b',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T17:02:44.7784428+00:00',
        createdBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPk=',
      },
    },
    {
      id: 11991,
      uniqueId: '19904b64b2198a71',
      flowId: 1095,
      stepName: 'UnitMaintenanceSpend',
      dataType: 'Home',
      dataId: 1493,
      dataUniqueId: '455dcd2d097bd41b',
      status: 'Created',
      isHidden: false,
      auditDetails: {
        createdOn: '2021-05-08T17:02:44.7784428+00:00',
        createdBy: 'Pradeep Eagle1',
        dataVersion: 'AAAAAAAQIPo=',
      },
    },
  ],
  version: 0,
  auditDetails: {
    createdOn: '2021-05-08T16:13:56.6049334+00:00',
    createdBy: 'Pradeep Eagle1',
    dataVersion: 'AAAAAAAQIIM=',
  },
  isActive: true,
};
