import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Image from '../../Image/Image';
import styles from './Cover.module.css';

const cx = classNames.bind(styles);

const Cover = ({
  src,
  mobileSrc,
  containerClassName,
  className,
  imageClassName,
  opacity,
  overlay,
  children,
  decorations,
}) => (
  <div className={cx('cover-container', containerClassName)}>
    <div className={cx('image-container', className)}>
      <Image src={src} className={cx(imageClassName)} />
    </div>
    <div className={cx('mobile-image-container', className)}>
      <Image src={mobileSrc || src} className={cx(imageClassName)} />
    </div>

    {opacity && <div className={cx('cover-overlay')} style={{ opacity }} />}
    {overlay}

    {children && <div className={cx('cover-content')}>{children}</div>}
    {decorations}
  </div>
);

Cover.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  overlay: PropTypes.node,
  src: PropTypes.string.isRequired,
  mobileSrc: PropTypes.string,
  children: PropTypes.node,
  opacity: PropTypes.number,
  decorations: PropTypes.node,
};

Cover.defaultProps = {
  className: '',
  imageClassName: '',
  containerClassName: '',
  overlay: null,
  mobileSrc: null,
  children: null,
  opacity: null,
  decorations: null,
};

export default Cover;
