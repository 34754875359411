// Fork of imgix/js-core library. Ignore for coding standards purposes.
const getQuery = (params) => {
  return Object.fromEntries(params.entries());
};

const hasProtocol = (url) => {
  url.startsWith('http://') || url.startsWith('https://');
};

const Base64 = {
  encodeURI(value) {
    return value;
  },
};

// package version used in the ix-lib parameter
const VERSION = '3.6.0';
// regex pattern used to determine if a domain is valid
const DOMAIN_REGEX =
  /^(?:[a-z\d\-_]{1,62}\.){0,125}(?:[a-z\d](?:\-(?=\-*[a-z\d])|[a-z]|\d){0,62}\.)[a-z\d]{1,63}$/i;
// minimum generated srcset width
const MIN_SRCSET_WIDTH = 100;
// maximum generated srcset width
const MAX_SRCSET_WIDTH = 8192;
// default tolerable percent difference between srcset pair widths
const DEFAULT_SRCSET_WIDTH_TOLERANCE = 0.08;

// default quality parameter values mapped by each dpr srcset entry
const DPR_QUALITIES = {
  1: 75,
  2: 50,
  3: 35,
  4: 23,
  5: 20,
};

const DEFAULT_DPR = [1, 2, 3, 4, 5];

const DEFAULT_OPTIONS = {
  domain: null,
  useHTTPS: true,
  includeLibraryParam: true,
  urlPrefix: 'https://',
  secureURLToken: null,
};

/**
 * `extractUrl()` extracts URL components from a source URL string.
 * It does this by matching the URL against regular expressions. The irrelevant
 * (entire URL) matches are removed and the rest stored as their corresponding
 * URL components.
 *
 * `url` can be a partial, full URL, or full proxy URL. `useHttps` boolean
 * defaults to false.
 *
 * @returns {Object} `{ protocol, auth, host, pathname, search, hash }`
 * extracted from the URL.
 */
function extractUrl({ url = '', useHttps = false }) {
  const defaultProto = useHttps ? 'https://' : 'http://';
  if (!hasProtocol(url)) {
    return extractUrl({ url: defaultProto + url });
  }

  /**
   * Regex are hard to parse. Leaving this breakdown here for reference.
   * - `protocol`: ([^:/]+:)? - all not `:` or `/` & preceded by `:`, 0-1 times
   * - `auth`: ([^/@]+@)? - all not `/` or `@` & preceded by `@`, 0-1 times
   * - `domainAndPath`: (.*) /) -  all except line breaks
   * - `domain`: `([^/]*)` - all before a `/` token
   */
  return new URL(url);
}

function validateAndDestructureOptions(options) {
  let widthTolerance;
  if (options.widthTolerance !== undefined) {
    validateWidthTolerance(options.widthTolerance);
    widthTolerance = options.widthTolerance;
  } else {
    widthTolerance = DEFAULT_SRCSET_WIDTH_TOLERANCE;
  }

  const minWidth = options.minWidth === undefined ? MIN_SRCSET_WIDTH : options.minWidth;
  const maxWidth = options.maxWidth === undefined ? MAX_SRCSET_WIDTH : options.maxWidth;

  // Validate the range unless we're using defaults for both
  if (minWidth != MIN_SRCSET_WIDTH || maxWidth != MAX_SRCSET_WIDTH) {
    validateRange(minWidth, maxWidth);
  }

  return [widthTolerance, minWidth, maxWidth];
}

function validateRange(min, max) {
  if (!(Number.isInteger(min) && Number.isInteger(max)) || min <= 0 || max <= 0 || min > max) {
    throw new Error(
      `The min and max srcset widths can only be passed positive Number values, and min must be less than max. Found min: ${min} and max: ${max}.`
    );
  }
}

function validateWidthTolerance(widthTolerance) {
  if (typeof widthTolerance != 'number' || widthTolerance < 0.01) {
    throw new Error('The srcset widthTolerance must be a number greater than or equal to 0.01');
  }
}

function validateWidths(customWidths) {
  if (!Array.isArray(customWidths) || !customWidths.length) {
    throw new Error('The widths argument can only be passed a valid non-empty array of integers');
  } else {
    const allPositiveIntegers = customWidths.every(function (width) {
      return Number.isInteger(width) && width > 0;
    });
    if (!allPositiveIntegers) {
      throw new Error('A custom widths argument can only contain positive integer values');
    }
  }
}

function validateVariableQuality(disableVariableQuality) {
  if (typeof disableVariableQuality != 'boolean') {
    throw new Error('The disableVariableQuality argument can only be passed a Boolean value');
  }
}

function validateDevicePixelRatios(devicePixelRatios) {
  if (!Array.isArray(devicePixelRatios) || !devicePixelRatios.length) {
    throw new Error('The devicePixelRatios argument can only be passed a valid non-empty array of integers');
  } else {
    const allValidDPR = devicePixelRatios.every(function (dpr) {
      return typeof dpr === 'number' && dpr >= 1 && dpr <= 5;
    });

    if (!allValidDPR) {
      throw new Error('The devicePixelRatios argument can only contain positive integer values between 1 and 5');
    }
  }
}

function validateVariableQualities(variableQualities) {
  if (typeof variableQualities !== 'object') {
    throw new Error('The variableQualities argument can only be an object');
  }
}

class ImgixClient {
  constructor(opts = {}) {
    this.settings = { ...DEFAULT_OPTIONS, ...opts };
    // a cache to store memoized srcset width-pairs
    this.targetWidthsCache = {};
    if (typeof this.settings.domain != 'string') {
      throw new Error('ImgixClient must be passed a valid string domain');
    }

    if (DOMAIN_REGEX.exec(this.settings.domain) == null) {
      throw new Error(
        'Domain must be passed in as fully-qualified ' +
          'domain name and should not include a protocol or any path ' +
          'element, i.e. "example.imgix.net".'
      );
    }

    if (this.settings.includeLibraryParam) {
      this.settings.libraryParam = 'js-' + ImgixClient.version();
    }

    this.settings.urlPrefix = this.settings.useHTTPS ? 'https://' : 'http://';
  }

  static version() {
    return VERSION;
  }

  buildURL(rawPath = '', params = {}, options = {}) {
    const path = this._sanitizePath(rawPath, {
      encode: !options.disablePathEncoding,
    });

    let finalParams = this._buildParams(params);
    if (!!this.settings.secureURLToken) {
      finalParams = this._signParams(path, finalParams);
    }
    return this.settings.urlPrefix + this.settings.domain + path + finalParams;
  }

  /**
   *`_buildURL` static method allows full URLs to be formatted for use with
   * imgix.
   *
   * - If the source URL has included parameters, they are merged with
   * the `params` passed in as an argument.
   * - URL must match `{host}/{pathname}?{query}` otherwise an error is thrown.
   *
   * @param {String} url - full source URL path string, required
   * @param {Object} params - imgix params object, optional
   * @param {Object} options - imgix client options, optional
   *
   * @returns URL string formatted to imgix specifications.
   *
   * @example
   * const client = ImgixClient
   * const params = { w: 100 }
   * const opts = { useHttps: true }
   * const src = "sdk-test.imgix.net/amsterdam.jpg?h=100"
   * const url = client._buildURL(src, params, opts)
   * console.log(url)
   * // => "https://sdk-test.imgix.net/amsterdam.jpg?h=100&w=100"
   */
  static _buildURL(url, params = {}, options = {}) {
    if (url == null) {
      return '';
    }

    const { host, pathname, searchParams } = extractUrl({
      url,
      useHTTPS: options.useHTTPS,
    });
    // merge source URL parameters with options parameters
    const combinedParams = { ...getQuery(searchParams), ...params };

    // throw error if no host or no pathname present
    if (!host.length || !pathname.length) {
      throw new Error('_buildURL: URL must match {host}/{pathname}?{query}');
    }

    const client = new ImgixClient({ domain: host, ...options });

    return client.buildURL(pathname, combinedParams);
  }

  _buildParams(params = {}) {
    const queryParams = [
      // Set the libraryParam if applicable.
      ...(this.settings.libraryParam ? [`ixlib=${this.settings.libraryParam}`] : []),

      // Map over the key-value pairs in params while applying applicable encoding.
      ...Object.entries(params).reduce((prev, [key, value]) => {
        if (value == null) {
          return prev;
        }
        const encodedKey = encodeURIComponent(key);
        const encodedValue = key.substr(-2) === '64' ? Base64.encodeURI(value) : encodeURIComponent(value);
        prev.push(`${encodedKey}=${encodedValue}`);
        return prev;
      }, []),
    ];

    return `${queryParams.length > 0 ? '?' : ''}${queryParams.join('&')}`;
  }

  _signParams(path, queryParams) {
    const signatureBase = this.settings.secureURLToken + path + queryParams;
    const signature = signatureBase;

    return queryParams.length > 0 ? queryParams + '&s=' + signature : '?s=' + signature;
  }

  /**
   * "Sanitize" the path of the image URL.
   * Ensures that the path has a leading slash, and that the path is correctly
   * encoded. If it's a proxy path (begins with http/https), then encode the
   * whole path as a URI component, otherwise only encode specific characters.
   * @param {string} path The URL path of the image
   * @param {Object} options Sanitization options
   * @param {boolean} options.encode Whether to encode the path, default true
   * @returns {string} The sanitized path
   */
  _sanitizePath(path, options = {}) {
    // Strip leading slash first (we'll re-add after encoding)
    let _path = path.replace(/^\//, '');

    if (!(options.encode === false)) {
      if (/^https?:\/\//.test(_path)) {
        // Use de/encodeURIComponent to ensure *all* characters are handled,
        // since it's being used as a path
        _path = encodeURIComponent(_path);
      } else {
        // Use de/encodeURI if we think the path is just a path,
        // so it leaves legal characters like '/' and '@' alone
        _path = encodeURI(_path).replace(/[#?:+]/g, encodeURIComponent);
      }
    }

    return '/' + _path;
  }

  buildSrcSet(path, params = {}, options = {}) {
    const { w, h } = params;

    if (w || h) {
      return this._buildDPRSrcSet(path, params, options);
    } else {
      return this._buildSrcSetPairs(path, params, options);
    }
  }

  /**
   * _buildSrcSet static method allows full URLs to be used when generating
   * imgix formatted `srcset` string values.
   *
   * - If the source URL has included parameters, they are merged with
   * the `params` passed in as an argument.
   * - URL must match `{host}/{pathname}?{query}` otherwise an error is thrown.
   *
   * @param {String} url - full source URL path string, required
   * @param {Object} params - imgix params object, optional
   * @param {Object} srcsetModifiers - srcset modifiers, optional
   * @param {Object} clientOptions - imgix client options, optional
   * @returns imgix `srcset` for full URLs.
   */
  static _buildSrcSet(url, params = {}, srcsetModifiers = {}, clientOptions = {}) {
    if (url == null) {
      return '';
    }

    const { host, pathname, searchParams } = extractUrl({
      url,
      useHTTPS: clientOptions.useHTTPS,
    });
    // merge source URL parameters with options parameters
    const combinedParams = { ...getQuery(searchParams), ...params };

    // throw error if no host or no pathname present
    if (!host.length || !pathname.length) {
      throw new Error('_buildOneStepURL: URL must match {host}/{pathname}?{query}');
    }

    const client = new ImgixClient({ domain: host, ...clientOptions });
    return client.buildSrcSet(pathname, combinedParams, srcsetModifiers);
  }

  // returns an array of width values used during srcset generation
  static targetWidths(minWidth = 100, maxWidth = 8192, widthTolerance = 0.08, cache = {}) {
    const minW = Math.floor(minWidth);
    const maxW = Math.floor(maxWidth);
    validateRange(minWidth, maxWidth);
    validateWidthTolerance(widthTolerance);
    const cacheKey = widthTolerance + '/' + minW + '/' + maxW;

    // First, check the cache.
    if (cacheKey in cache) {
      return cache[cacheKey];
    }

    if (minW === maxW) {
      return [minW];
    }

    const resolutions = [];
    let currentWidth = minW;
    while (currentWidth < maxW) {
      // While the currentWidth is less than the maxW, push the rounded
      // width onto the list of resolutions.
      resolutions.push(Math.round(currentWidth));
      currentWidth *= 1 + widthTolerance * 2;
    }

    // At this point, the last width in resolutions is less than the
    // currentWidth that caused the loop to terminate. This terminating
    // currentWidth is greater than or equal to the maxW. We want to
    // to stop at maxW, so we make sure our maxW is larger than the last
    // width in resolutions before pushing it (if it's equal we're done).
    if (resolutions[resolutions.length - 1] < maxW) {
      resolutions.push(maxW);
    }

    cache[cacheKey] = resolutions;

    return resolutions;
  }

  _buildSrcSetPairs(path, params = {}, options = {}) {
    const [widthTolerance, minWidth, maxWidth] = validateAndDestructureOptions(options);

    let targetWidthValues;
    if (options.widths) {
      validateWidths(options.widths);
      targetWidthValues = [...options.widths];
    } else {
      targetWidthValues = ImgixClient.targetWidths(minWidth, maxWidth, widthTolerance, this.targetWidthsCache);
    }

    const srcset = targetWidthValues.map(
      (w) => `${this.buildURL(path, { ...params, w }, { disablePathEncoding: options.disablePathEncoding })} ${w}w`
    );

    return srcset.join(',\n');
  }

  _buildDPRSrcSet(path, params = {}, options = {}) {
    if (options.devicePixelRatios) {
      validateDevicePixelRatios(options.devicePixelRatios);
    }

    const targetRatios = options.devicePixelRatios || DEFAULT_DPR;

    const disableVariableQuality = options.disableVariableQuality || false;

    if (!disableVariableQuality) {
      validateVariableQuality(disableVariableQuality);
    }

    if (options.variableQualities) {
      validateVariableQualities(options.variableQualities);
    }

    const qualities = { ...DPR_QUALITIES, ...options.variableQualities };

    const withQuality = (path, params, dpr) => {
      return `${this.buildURL(
        path,
        {
          ...params,
          dpr: dpr,
          q: params.q || qualities[dpr] || qualities[Math.floor(dpr)],
        },
        { disablePathEncoding: options.disablePathEncoding }
      )} ${dpr}x`;
    };

    const srcset = disableVariableQuality
      ? targetRatios.map(
          (dpr) =>
            `${this.buildURL(path, { ...params, dpr }, { disablePathEncoding: options.disablePathEncoding })} ${dpr}x`
        )
      : targetRatios.map((dpr) => withQuality(path, params, dpr));

    return srcset.join(',\n');
  }
}

export { DEFAULT_DPR, DEFAULT_OPTIONS, DEFAULT_SRCSET_WIDTH_TOLERANCE, DOMAIN_REGEX, DPR_QUALITIES, ImgixClient, MAX_SRCSET_WIDTH, MIN_SRCSET_WIDTH, VERSION, extractUrl, validateAndDestructureOptions, validateDevicePixelRatios, validateRange, validateVariableQualities, validateVariableQuality, validateWidthTolerance, validateWidths };
