// import PropTypes from 'prop-types';

export const USERACTIONMAINTENANCE_TYPES = {
  REPORTED: 'Reported',
  ASSESSED: 'Assessed',
  MAINTENANCE: 'Pro Services',
  ASSESSMENT: 'Assessment',
  // group
  APPROVED: 'Approved',
};
