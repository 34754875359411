export const STRINGS = {
  'landing_page.finalizing_plans': 'Alright! When will your home be vacant and available?',
  'landing_page.plans_changed': 'We get it, {name}, things change! Please update any plans that may have changed.',
  'landing_page.move_out.same_day_or_after': 'Are {pronoun} still planning to move out {dateOrToday}, {name}?',
  'landing_page.move_out.day_in_the_past': 'Did {pronoun} happen to move out on the {date}, {name}?',
  'landing_page.move_out.month_in_the_future': 'Are {pronoun} still planning to move out in {month}, {name}?',
  'landing_page.move_out.same_month': 'Are {pronoun} still planning to move out this month, {name}?',
  'landing_page.move_out.month_in_the_past': 'Did {pronoun} happen to move out already, {name}?',

  'landing_page.available_on.same_day_or_after': 'Will your home still be ready to go {dateOrToday}, {name}?',
  'landing_page.available_on.day_in_the_past': 'Is your home available as of the {date}, {name}?',
  'landing_page.available_on.month_in_the_future': 'Will your home still be ready to go in {month}, {name}?',
  'landing_page.available_on.same_month': 'Will your home still be ready to go this month, {name}?',
  'landing_page.available_on.month_in_the_past': 'Did your home happen to become available, {name}?',

  'no-longer-interested.title':
    'Sorry to hear that, {name}! We understand things change. May we ask why you no longer want to <span>move forward?</span>',

  'success.title.concrete_date': 'Alright! Looks like it’s time for us to get started!',
  'success.title.something_changed': 'Thanks for letting us know! Looking forward to getting starting in {month}.',
  'success.title.nothing_changed': 'Great! Looking forward to getting starting in {month}!',
};
