import { Carousel } from '@belong/ui';

import { TermsAndConditions } from 'lease-signing-flow/components/terms-and-conditions/terms-and-conditions';
import { WalletFeatureItem } from 'lease-signing-flow/components/wallet-feature-item/wallet-feature-item';
import { features } from 'lease-signing-flow/constants/wallet';

export const WalletFeatureCarousel = () => (
  <div className="mt-xl">
    <Carousel>
      {features.map((feature, index) => (
        <div className="min-w-full flex justify-center" key={index}>
          <div>
            <WalletFeatureItem description={feature.description} image={feature.image} title={feature.title} />
          </div>
        </div>
      ))}
    </Carousel>
    <div className="md:hidden mt-xl text-center">
      <TermsAndConditions />
    </div>
  </div>
);
