var breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};
var spacing = {
  auto: 'auto',
  '0': '0px',
  '2xs': '4px',
  xs: '10px',
  '2sm': '15px',
  sm: '20px',
  md: '24px',
  lg: '30px',
  xl: '40px',
  '2xl': '60px',
  '3xl': '100px',
  '4xl': '130px',
  '5xl': '160px',
  '6xl': '200px',
  '7xl': '250px'
};
var widths = {
  // buttons
  'button-default': '185px',
  'button-small': '150px',
  'button-large': '240px',
  'button-fluid': '100%',
  // containers
  'flow-lg': '730px',
  'container-md': '780px',
  'container-lg': '1000px',
  'container-xl': '1210px',
  // modals
  'large-modal-lg': '920px',
  'large-modal-md': '730px',
  'default-modal': '730px',
  'small-modal': '540px',
  // others
  checkbox: '18px',
  'icons-96x96': '96px',
  'page-upper-limit': '2500px',
  '1/2': '50%',
  '1/3': '33.33%'
};
var heights = {
  'labeled-fields': '80px',
  checkbox: '18px',
  header: '60px',
  button: '56px'
};
// Not sure if needed yet.
var spacingWithNegatives = {
  '2xs': '4px',
  '-2xs': '-4px',
  xs: '10px',
  '-xs': '-10px',
  '2sm': '15px',
  '-2sm': '-15px',
  sm: '20px',
  '-sm': '-20px',
  md: '24px',
  '-md': '-24px',
  lg: '30px',
  '-lg': '-30px',
  xl: '40px',
  '-xl': '-40px',
  '2xl': '60px',
  '-2xl': '-60px',
  '3xl': '100px',
  '-3xl': '-100px',
  '4xl': '130px',
  '-4xl': '-130px',
  '5xl': '160px',
  '-5xl': '-160px',
  '6xl': '200px',
  '-6xl': '-200px',
  '7xl': '250px',
  '-7xl': '-250px'
};
var palette = {
  black: '#000000',
  'light-dark-navy': '#1b3b4f',
  navy: '#325e77',
  'dark-navy': '#0f2532',
  'darker-navy': '#06131c',
  'lighter-green': '#c2f7e4',
  'light-green': '#5bbe9b',
  green: '#3ee4a9',
  'green-light-bg': '#f3fdfa',
  'dark-green': '#3aa680',
  'light-gray': '#fafbfc',
  gray: '#d6e1e4',
  'dark-gray': '#8ea0a4',
  red: '#ff5661',
  'red-translucent': 'rgba(255, 86, 97, 0.4)',
  'red-translucent-light': 'rgba(255, 86, 97, 0.03)',
  'dark-red': '#c71d28',
  'green-translucent': 'rgba(62, 228, 169, 0.4)',
  'green-translucent-light': 'rgba(62, 228, 169, 0.03)',
  'green-translucent-medium': 'rgba(62,228,169,0.4)',
  'navy-translucent-light': 'rgba(50, 94, 119, 0.06)',
  'dark-navy-translucent': 'rgba(15, 37, 50, 0.9)',
  'white-translucent': 'rgba(255, 255, 255, 0.9)',
  yellow: '#fabd1f',
  orange: '#ff9c00',
  blue: '#1890ff',
  white: '#fff',
  transparent: 'transparent',
  'facebook-button': '#1877F2',
  'facebook-button-active': '#0f66d7'
};
var fontWeight = {
  regular: 400,
  semibold: 600
};
var letterSpacing = {
  lg: '0.5px',
  sm: '0.3px'
};
var fontSize = {
  'h1-xl': '54px',
  'h1-md': '42px',
  'h1-sm': '32px',
  'h2-xl': '36px',
  'h2-md': '28px',
  'h2-sm': '26px',
  'h3-xl': '24px',
  'h3-md': '22px',
  'h3-sm': '20px',
  body: '16px',
  p1: '12px',
  p2: '10px'
};
var lineHeight = {
  'h1-xl': '62px',
  'h1-md': '50px',
  'h1-sm': '38px',
  'h2-xl': '44px',
  'h2-md': '32px',
  'h2-sm': '32px',
  'h3-xl': '32px',
  'h3-md': '30px',
  'h3-sm': '28px',
  body: '24px',
  p1: '20px',
  p2: '14px'
};
var boxShadow = {
  DEFAULT: '0px 1px 6px 0px rgba(50, 94, 119, 0.26)',
  card: '0 2px 30px 0 rgba(0, 0, 0, 0.4)',
  button: '0 5px 10px rgba(62, 228, 169, 0.3)',
  picture: '0 6px 10px 0 rgba(0,0,0,0.1)',
  marker: '0 0 4px rgba(0,0,0,0.25)'
};
var borderWidth = {
  '3': '3px',
  '4': '4px',
  '6': '6px'
};
var borderRadius = {
  none: '0',
  small: '4px',
  DEFAULT: '8px',
  full: '9999px'
};
var transitionDuration = {
  hover: '400ms'
};
var dropShadow = {
  DEFAULT: '0px 4px 16px rgba(0, 0, 0, 0.16)',
  lg: '0px 8px 20px rgba(0, 0, 0, 0.2)'
};

export { borderRadius, borderWidth, boxShadow, breakpoints, dropShadow, fontSize, fontWeight, heights, letterSpacing, lineHeight, palette, spacing, spacingWithNegatives, transitionDuration, widths };
