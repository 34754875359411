import React from 'react';
import PropTypes from 'prop-types';
import { OwnerOccupiedMoveOutSection } from '../OwnerOccupiedMoveOutSection/OwnerOccupiedMoveOutSection';
import { AdditionalInfoSection } from '../AdditionalInfoSection/AdditionalInfoSection';
import { RentedBeforeSection } from '../RentedBeforeSection/RentedBeforeSection';
import { RentingPlansSection } from '../RentingPlansSection/RentingPlansSection';
import { RentYourNextHomeFields } from '../RentYourNextHomeFields/RentYourNextHomeFields';

export const OwnerOccupiedForm = ({ changeFormValue, name }) => (
  <>
    <OwnerOccupiedMoveOutSection name={name} />
    <RentYourNextHomeFields name={name} />
    <AdditionalInfoSection name={name} />
    <RentedBeforeSection changeFormValue={changeFormValue} name={name} />
    <RentingPlansSection name={name} />
  </>
);

OwnerOccupiedForm.propTypes = {
  changeFormValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
