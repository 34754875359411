import { ACTIONS } from './constants';

export const USER_REDUCER = 'user';

const initialState = {
  user: {},
  dwollaToken: '',
  claims: null,
  paymentMethods: null,
  paymentMethodConfigurations: null,
  users: {},
  // Checks to see if User Details Were Called
  userDetailsCalled: false,
  todos: [],
  reports: [],
  flows: [],
  devices: [],
  autoSplitEnabled: false,
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.USER_FETCH_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.result },
      };
    case ACTIONS.USER_SET_SESSION:
      return {
        ...state,
        user: { ...state.user, ...action.result.user },
      };
    case ACTIONS.USER_FETCH_DWOLLA_TOKEN_SUCCESS:
      return {
        ...state,
        dwollaToken: action.result,
      };
    case ACTIONS.USER_FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: action.result,
      };
    case ACTIONS.FETCH_USER_AUTO_SPLIT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        autoSplitEnabled: action.result.autoSplitEnabled,
      };
    case ACTIONS.FETCH_USER_PAYMENT_METHOD_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        paymentMethodConfigurations: action.result.allowedPaymentMethodPreference.paymentMethodPreferences,
      };
    case ACTIONS.FETCH_IDENTITY_VERIFICATION_INFO_SUCCESS:
      return {
        ...state,
        identityVerificationInfo: { ...action.result },
      };
    case ACTIONS.FETCH_USER_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: { ...action.result },
      };
    case ACTIONS.FETCH_USER_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.result.userId]: action.result,
        },
        // Checks to see if User Details Were Called
      };
    case ACTIONS.FETCH_CURRENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.result },
        // Checks to see if User Details Were Called
        userDetailsCalled: true,
      };
    case ACTIONS.FETCH_USER_TODOS_SUCCESS:
      return {
        ...state,
        todos: [...action.result],
      };
    case ACTIONS.FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        reports: [...action.result],
      };
    case ACTIONS.FETCH_FLOWS_SUCCESS:
      return {
        ...state,
        flows: [...action.result],
      };
    case ACTIONS.FETCH_USER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: [...action.result],
      };
    case ACTIONS.USER_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
