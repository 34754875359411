import { getApiActionTypes } from '../redux_utils';

export const ACTIONS = {
  ...getApiActionTypes('FETCH_ACCOUNT_PROPERTIES'),
  ...getApiActionTypes('FETCH_ACCOUNT_UNIT'),
  ...getApiActionTypes('FETCH_ACCOUNT_DOCUMENTS'),
  ...getApiActionTypes('DELETE_HOMEOWNER_MEDIA_TO_MAINTENANCE_TASK'),
  ...getApiActionTypes('CREATE_HOMEOWNER_MEDIA_TO_MAINTENANCE_TASK'),
  ...getApiActionTypes('FETCH_HOME_TIMELINE'),
  ...getApiActionTypes('FETCH_ACCOUNT_AGREEMENTS'),
  ...getApiActionTypes('FETCH_UPCOMING_APPOINTMENTS'),
  ...getApiActionTypes('FETCH_HOME_ACCESS_INSTRUCTIONS'),
  ...getApiActionTypes('FETCH_WEALTH'),
  CURRENT_TASK_CLEAR: 'CURRENT_TASK_CLEAR',
  CLEAR_ACCOUNT_UNITS: 'CLEAR_ACCOUNT_UNITS',
};
