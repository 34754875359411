import React from 'react';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { RentalStatus } from 'models/enums/index';
import PropTypes from 'prop-types';
import { rentalStatusSelectors, RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { required } from 'utils/validation';
import { FamilyOccupiedForm } from './FamilyOccupiedForm/FamilyOccupiedForm';
import { OwnerOccupiedForm } from './OwnerOccupiedForm/OwnerOccupiedForm';
import { RenterOccupiedForm } from './RenterOccupiedForm/RenterOccupiedForm';
import { VacantForm } from './VacantForm/VacantForm';

const changeFormValue = (values, form) => {
  form.batch(() => {
    Object.values(values).forEach((item) => {
      if (typeof item === 'object') {
        form.change(item.name, item.defaultValue);
      } else {
        form.change(item, null);
      }
    });
  });
};

const RentalStatusInfo = ({ first, form, name, sectionTitle }) => (
  <>
    <FormLayout.Section first={first} sectionTitle={sectionTitle}>
      <Field
        buttons={rentalStatusSelectors}
        component={SelectorFinalFormAdapter}
        fluid
        name={`${name}.rentalStatus`}
        onChangeCustom={() => {
          changeFormValue(
            {
              // renter-occupied fields
              [`${name}.residentsRelationship`]: `${name}.residentsRelationship`,
              [`${name}.residentsRelationshipComments`]: `${name}.residentsRelationshipComments`,
              [`${name}.rentalManagement`]: `${name}.rentalManagement`,
              [`${name}.moveinOn`]: `${name}.moveinOn`,
              [`${name}.currentRentAmount`]: `${name}.currentRentAmount`,
              [`${name}.currentLeaseEndOn`]: `${name}.currentLeaseEndOn`,

              // owner-occupied fields
              [`${name}.moveoutPlan`]: `${name}.moveoutPlan`,
              [`${name}.moveoutDestination`]: `${name}.moveoutDestination`,
              [`${name}.moveoutOwningStatus`]: `${name}.moveoutOwningStatus`,
              [`${name}.moveoutRentingStatus`]: `${name}.moveoutRentingStatus`,

              // owner/family-occupied fields
              [`${name}.moveoutOption`]: `${name}.moveoutOption`,
              [`${name}.moveoutOn`]: `${name}.moveoutOn`,
              [`${name}.moveOutInAMonth`]: `${name}.moveOutInAMonth`,
              [`${name}.moveoutStartOn`]: `${name}.moveoutStartOn`,
              [`${name}.moveoutEndOn`]: `${name}.moveoutEndOn`,
              [`${name}.moveoutComments`]: `${name}.moveoutComments`,

              // vacant fields
              [`${name}.availableToRent`]: `${name}.availableToRent`,
              [`${name}.availableForRentOn`]: `${name}.availableForRentOn`,
              [`${name}.availableForRentOption`]: `${name}.availableForRentOption`,
              [`${name}.availableInAMonth`]: `${name}.availableInAMonth`,
              [`${name}.availableForRentComments`]: `${name}.availableForRentComments`,

              // owner/family-occupied/vacant fields
              [`${name}.rentedBefore`]: `${name}.rentedBefore`,
              [`${name}.previousLeaseEndOn`]: `${name}.previousLeaseEndOn`,
              [`${name}.previousRentAmount`]: `${name}.previousRentAmount`,

              // common fields
              [`${name}.rentalPlan`]: `${name}.rentalPlan`,
            },
            form
          );
        }}
        type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
        validate={required}
      />
    </FormLayout.Section>
    <Condition is={RentalStatus.RenterOccupied} when={`${name}.rentalStatus`}>
      <RenterOccupiedForm form={form} name={name} />
    </Condition>
    <Condition is={RentalStatus.OwnerOccupied} when={`${name}.rentalStatus`}>
      <OwnerOccupiedForm changeFormValue={changeFormValue} name={name} />
    </Condition>
    <Condition is={RentalStatus.FamilyOccupied} when={`${name}.rentalStatus`}>
      <FamilyOccupiedForm changeFormValue={changeFormValue} name={name} />
    </Condition>
    <Condition is={RentalStatus.Vacant} when={`${name}.rentalStatus`}>
      <VacantForm changeFormValue={changeFormValue} form={form} name={name} />
    </Condition>
    <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.otherOptions}>
      <Field
        name={`${name}.otherOptionsBeingConsideredBesidesRenting`}
        component={InputFinalFormAdapter}
        textarea
        placeholder={RENTAL_STATUS_TEXT.otherOptionsPlaceholder}
      />
    </FormLayout.Section>
    <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.primaryConcerns}>
      <Field
        name={`${name}.primaryConcernsWhenPickingSomeoneToManageHome`}
        component={InputFinalFormAdapter}
        textarea
        placeholder={RENTAL_STATUS_TEXT.primaryConcernsPlaceholder}
      />
    </FormLayout.Section>
  </>
);

RentalStatusInfo.propTypes = {
  first: PropTypes.bool,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  sectionTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

RentalStatusInfo.defaultProps = {
  first: false,
  sectionTitle: null,
};

export default RentalStatusInfo;
