import { MaintenancePaymentAgreementModel } from 'api/models';

export const percentages = {
  zero: 0,
  fifty: 50,
  oneHundred: 100,
};
export const result = {
  approved: 'approved',
  rejected: 'rejected',
  shared: 'shared',
};
export type ResponsibilityResult = typeof result[keyof typeof result];
export type FormValues = MaintenancePaymentAgreementModel & { result: ResponsibilityResult };
export const initialValues: FormValues = {
  result: result.approved,
  isApproved: true,
  percentage: percentages.oneHundred,
  notes: null,
  rejectionReason: null,
};
