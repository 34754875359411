import { __assign } from '../node_modules/tslib/tslib.es6.mjs.js';

var defaultOptions = {
  path: '/'
};
var day = 60 * 60 * 24;
var COOKIES_CONFIG = {
  JWT_TOKEN: {
    name: 'jwt-token',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 30 * day
    })
  },
  REFRESH_TOKEN: {
    name: 'refresh-token',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 365 * day
    })
  },
  TRACKING_ID: {
    name: 'tracking_id_v2',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 365 * day
    })
  },
  LEGACY_TRACKING_ID: {
    name: 'tracking_id',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 365 * day
    })
  },
  HAS_ACCOUNT: {
    name: 'has_account',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 365 * day
    })
  },
  EXPERIMENTS: {
    name: 'experiments',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 30 * day
    })
  },
  REFERRAL_USER: {
    name: 'referral_user',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 60 * 60 * 2
    })
  },
  REFERRAL_ID: {
    name: 'referral_id',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 60 * 60 * 2
    })
  },
  // To consume same cookie as redux persist on belong-web from belong-next
  REGION: {
    name: 'persist%3Aregion',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 365 * day
    })
  },
  MOBILE_APP: {
    name: 'mobile_app',
    options: __assign({}, defaultOptions)
  },
  PARTNER_KEY: {
    name: 'partner_key',
    options: __assign(__assign({}, defaultOptions), {
      maxAge: 30 * day
    })
  }
};

export { COOKIES_CONFIG };
