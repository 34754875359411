import React, { Component } from 'react';
import { Form } from 'react-final-form';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import styles from './ForgotPasswordPasswordUpdateConfirmation.module.css';

const cx = classNames.bind(styles);

class ForgotPasswordPasswordUpdateConfirmation extends Component {
  static title = 'Password successfully updated.';

  static closeButton = true;

  static propTypes = {
    handleForgotPasswordPasswordUpdateConfirmationSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className={cx('fp-update-password-content-wrapper')}>
        <Form
          onSubmit={this.props.handleForgotPasswordPasswordUpdateConfirmationSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div className={cx('back-to-sign-in-button')}>
                <Button type="submit" label="Back to Sign in" disabled={invalid} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default ForgotPasswordPasswordUpdateConfirmation;
