export const PAYMENT_VERFIY = {
  'modal.title': 'Verify Your Account',
  'modal.description':
    "You'll receive two micro-deposits in your account within 1-2 business days. " +
    'Once you have, please enter the two amounts below to verify your bank account. ' +
    'These micro-deposits will expire after <span>10 days.</span>',
  'modal.input.deposit1.placeholder': 'Deposit 1 (Less than $0.1)',
  'modal.input.deposit2.placeholder': 'Deposit 2 (Less than $0.1)',
};

export const PAYMENT_LIST = {
  MODAL_SUCCESS_PAYMENT: {
    title: 'Your payment is <span>being processed!</span>',
    subtitle: "We'll send you a confirmation email when your payment <span>goes through.</span>",
    ctaLabel: 'Close',
  },
  MODAL_ERROR_PAYMENT: {
    title: 'Hmm... something <span>went wrong!</span>',
    subtitle: 'If this issue persists, please contact <span>us below.</span>',
    ctaLabel: 'Close',
  },
  MODAL_HANDLE_FAILED_PAYMENTS: {
    retry: 'Retry',
    changepayment: 'Change Payment',
    ctaLabel: 'Retry',
  },
  MODAL_NO_DEFAULT_HANDLE_FAILED_PAYMENTS: {
    addpayment: 'Add a <span>Payment Method</span>',
    ctaLabel: 'Retry',
  },
  MODAL_PAYMENT_PENDING: {
    ctaLabel: 'Pay',
  },
};
