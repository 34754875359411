// TODO: Regions: Fix it.
import { getOfficeAddress } from 'consts/address';
import { SetupDataTypes, HomeownerSetupStepNames } from 'models/enums';

export const HOME_OWNER_PAPER_WORK_STRINGS = {
  'userProfile.title': 'Your Member Profile',
  'userProfile.subTitle':
    "Belong is a community, filled with Members such as yourself. Let's help your future Residents get to know you!",
  'userProfile.hometown': 'Hometown',
  'userProfile.about_me': 'Tell us about yourself',

  'landing_page.title': 'Let’s get your home loved',
  'landing_page.title_2': 'Keep going!',
  'landing_page.accounts_button': 'Done',
  'landing_page.subtitle':
    "In order to best serve you, we'll ask you a series of questions. We'll only need to ask these once, but you can always let us know in the future if something changes on your end.",

  'landing_page.all_about_you.title': 'Take your Profile to the Next Level',
  'landing_page.all_about_you.subtitle': 'What you need',
  'landing_page.all_about_you.ID': 'Valid ID',

  'landing_page.get_paid.title': 'Unlock Payments',
  'landing_page.get_paid.subtitle': "Here's what you'll need...",
  'landing_page.get_paid.info': 'Tax Info',

  'landing_page.around_the_property.title': 'Unlock your Professional Listing',
  'landing_page.around_the_property.subtitle': "Here's what you'll need...",
  'landing_page.around_the_property.HOA_AGREEMENT': 'HOA Agreement',
  'landing_page.around_the_property.HOA_CONTACT_INFO': 'HOA Contact Info',
  'landing_page.around_the_property.HOMEOWNERS_INSURANCE': "Proof of Homeowner's Insurance",

  'landing_page.on_the_inside.single_family': 'Home Info',
  'landing_page.on_the_inside.multi_family': 'Unit {homeNumber} Info',

  'landing_page.on_the_inside.title_for_single_family': 'Unlock your Complimentary Inspection  ',
  'landing_page.on_the_inside.title_for_multiunit': 'On the Inside · Unit {homeNumber}',
  'landing_page.on_the_inside.subtitle': "Here's what you'll need...",
  'landing_page.on_the_inside.CURRENT_LEASE': 'Current Lease (If Applicable)',
  'landing_page.on_the_inside.RESIDENT_INFO': 'Resident Contact Info',

  'landing_page.far_off.move_out_on_day': 'Moving out on <span>{date}</span>',
  'landing_page.far_off.move_out_on_month': 'Moving out in <span>{month}</span>',
  'landing_page.far_off.vacant_on_day': 'Available on <span>{date}</span>',
  'landing_page.far_off.vacant_on_month': 'Available in <span>{month}</span>',

  'around_the_property.title': "Let's make sure we play by the rules.",
  'around_the_property.hasHoa.title': 'Do you have an HOA at {propertyStreetAddress}?',
  'around_the_property.hasHoa.subtitle':
    'Or something similar? If so, we’ll make sure we comply with any and all rules set by your homeowner or neighborhood association.',
  'around_the_property.whoIsHoaContact.title': 'Who is your HOA’s primary contact?',
  'around_the_property.whoIsHoaContact.donthave': "I don't know. Remind me later!",
  'around_the_property.notifiedHoa.title': 'Have you notified your HOA that Belong will be managing your home?',

  'around_the_property.notifiedHoa.falseText':
    'Ok, please do so ASAP and cc us on that email if possible so we can take over the relationship. They may require written authorization as well for us to manage your home.',

  'around_the_property.uploadHoa.title': 'Upload your HOA Agreement (CC&Rs).',

  'around_the_property.uploadHoa.donthave': "I don't have it. Remind me later!",

  'around_the_property.uploadHoa.relationship': 'What’s your relationship like with your HOA?',

  'insurance.title': 'Do you have landlord insurance yet?',
  'insurance.liability.title':
    'Are you covered for at least $500k in personal liability, and for property damage up to the full value of the home?',
  'insurance.liability.no_condition_title':
    'Belong requires our homeowners be insured for carry and pay for the following:',
  'insurance.liability.no_condition_sections': [
    'Personal liability insurance in an amount of no less than $500,000.',
    'Property damage insurance equal to the full replacement cost of the Property and all of Owner’s personal property thereon, to protect the interests of Owner and Belong',
    'Additional coverage for rent loss should the Property become uninhabitable.',
    'Any employees or contractors you employ separately from Belong, worker’s compensation insurance adequate to protect the interests of Owner and Belong.',
  ],
  'insurance.liability.interested_party.title':
    'Great! When you get a chance, please add Belong to your policy as an <span>Additional Insured Party.</span>',
  'insurance.liability.interested_party.custom_option_1': 'I already did!',
  'insurance.liability.interested_party.custom_option_2': "Ok, I'll do that.",
  'insurance.upload_pdf': 'Please provide us with proof of insurance.',
  'insurance.not_added_belong': `Thanks in advance! Please inform your insurance company, and add <span>BELONG HOME INC.</span> as an Additional Insured Party. Our corporate address is ${getOfficeAddress()}. Once you've done that, please send us the <span>certificate of insurance.</span>`,

  'around_the_property.anythingelse.title': 'Anything we should know about them or the relationship?',
  'around_the_property.anythingelse.placeholder': 'Anything we should know? (OPTIONAL)',

  'propertyFeatures.title': 'Any communal features in or around the property to inspect?',
  'propertyFeatures.subtitle':
    "These are just some popular shared features. When we inspect, we'll add every feature we find.",
  'propertyFeatures.title_inspection_passed': 'Last time we checked, we found these communal features on the property.',
  'propertyFeatures.missing_features.title': 'Something missing? Let us know!',
  'propertyFeatures.other_features.title': 'Something else? Let us know!',
  'propertyFeatures.missing_features.placeholder': 'Missing Features (OPTIONAL)',

  'homeOccupancy.title': `Let's meet your renters!`,
  'homeOccupancy.last_time_we_checked.month_to_month':
    'Last time we checked, their lease is month-to-month, and they have not given notice.',
  'homeOccupancy.last_time_we_checked.specific_date': 'Last time we checked, their lease {endsOrEnded} {date}',
  'homeOccupancy.subtitle':
    "We'll reach out to introduce ourselves, and coordinate with them anytime we come <span>to visit.</span>",
  'homeOccupancy.relationship.month_checkbox': 'Month-to-month',
  'homeOccupancy.relationship.phone_unknown': "I don't know it.",
  'homeOccupancy.relationship.email_unknown': "I don't know it.",
  'homeOccupancy.relationship.email_or_phone_required':
    'Note: we need at least one contact method for your current residents.',
  'homeOccupancy.relationship.title': "What's your relationship like with them?",

  'homeOccupancy.plans_changed': 'Ok, please update the details of the lease.',
  'homeOccupancy.gave_notice': 'Ok, when are they planning to move out?',
  'homeOccupancy.already_moved_out.title':
    'Ok, time for us to get to work finding someone new to love your <span>home then!</span>',
  'homeOccupancy.already_moved_out.subtitle': 'Mind if we ask, when did they <span>move out?</span>',

  'homeOccupancy.relationship.upload_lease': 'Upload lease',
  'homeOccupancy.relationship.upload_lease_checkbox': "I don't have it.",
  'homeOccupancy.otherInfo.title': 'Anything we should know about them or the relationship?',
  'homeOccupancy.otherInfo.placeholder': 'Additional Details (OPTIONAL)',

  'homeFeatures.title': 'Time to gush <span>about your home.</span>',
  'homeFeatures.subtitle': "We'll come verify this...",
  'homeFeatures.beds': 'Beds',
  'homeFeatures.baths': 'Full Baths',
  'homeFeatures.half_baths': 'Half Baths',
  'homeFeatures.banner_image.section_title': 'Have a great photo of the front of your home?',
  'homeFeatures.banner_image.section_subtitle':
    "Ideally, this would capture the whole face of your home in frame. We'll use this prior to your upcoming <span>professional photoshoot.</span>",
  'homeFeatures.features.section_title': 'Any features we <span>should highlight?</span>',
  'homeFeatures.select_all_that_apply.section_title': 'Select any that apply. (OPTIONAL)',
  'homeFeatures.features.section_subtitle':
    "These are just some popular ones. When we inspect, we'll add every feature <span>we find.</span>",
  'homeFeatures.supported_feature.heat': 'Heat',
  'homeFeatures.supported_feature.ac': 'A/C',
  'homeFeatures.supported_feature.laundry': 'Laundry',
  'homeFeatures.supported_feature.parking': 'Parking',
  'homeFeatures.supported_feature.pool': 'Pool',
  'homeFeatures.supported_feature.lawn': 'Lawn',
  'homeFeatures.supported_feature.garden': 'Garden',
  'homeFeatures.supported_feature.trees_landscaping': 'Trees / Landscaping',
  'homeFeatures.supported_feature.storage': 'Storage',
  'homeFeatures.ac_type.central_ac': 'Central AC',
  'homeFeatures.ac_type.window_units': 'Window-Units',
  'homeFeatures.parking_type.garage': 'Garage',
  'homeFeatures.parking_type.lot': 'Lot',
  'homeFeatures.parking_type.carport': 'Carport',
  'homeFeatures.parking_type.driveway': 'Driveway',
  'homeFeatures.parking_type.on_street_parking': 'On-Street Parking',
  'homeFeatures.parking_type.off_street_parking': 'Off-Street Parking',
  'homeFeatures.home_level_type.ground_level': 'Ground Level',
  'homeFeatures.home_level_type.basement_level': 'Basement Level',
  'homeFeatures.something_missing.section_title': 'Something missing? Let us know!',
  'homeFeatures.something_else.section_title': 'Something else? Let us know!',
  'homeFeatures.something_missing.placeholder': 'Other features (OPTIONAL)',
  'homeFeatures.love_about_home.section_title': 'What do you love about your home? What makes <span>it unique?</span>',
  'homeFeatures.love_about_home.placeholder': 'Home qualities and uniqueness',
  'homeFeatures.quirks.section_title':
    'What might take some getting used to about living in your home? <span>Any quirks?</span>',
  'homeFeatures.quirks.placeholder': 'Quirks',
  'homeFeatures.neighborhood.section_title': 'What do you love about <span>your neighborhood?</span>',
  'homeFeatures.neighborhood.placeholder': 'Neighborhood qualities and highlights',
  'homeFeatures.about_neighbors.section_title': "What's your relationship like with <span>your neighbors?</span>",
  'homeFeatures.about_neighbors.placeholder': 'Anything we should know? (OPTIONAL)',

  'homeFurnitures.title': 'Belong Homes come unfurnished. <span>They rent</span> much faster <span>that way.</span>',
  'homeFurnitures.furnished.title': 'Is your home free of all furniture <span>and belongings?</span>',
  'homeFurnitures.furnished.no': 'No, I left stuff.',
  'homeFurnitures.furnished.yes': "Yes, it's empty.",
  'homeFurnitures.furnished.belong_recommendation':
    "On average, furnished homes take 4-5 times as long to rent compared to unfurnished ones. Plus, by law, most damage to furniture is considered normal wear and tear, and therefore cannot be recouped. To get Homeowners such as yourself the most for their home, and protect their furniture, we require all our homes be free of personal items. We'll discuss this with you, and help coordinate anything that needs to <span>be removed.</span>",
  'homeFurnitures.not_furnished.disclaimer':
    "Great! Please note that we'll dispose of any items left behind to make sure the home is clean for your <span>new Residents.</span>",
  'homeFurnitures.furnitures.title': 'What was left in the home?',
  'homeFurnitures.furnitures.item': 'Item',
  'homeFurnitures.furnitures.valuable': 'This item is highly valuable.',

  'homeUtilities.title': 'Talk to us about utilities...',
  'homeUtilities.Water': 'Water',
  'homeUtilities.Gas': 'Gas',
  'homeUtilities.Electricity': 'Electricity',
  'homeUtilities.Trash': 'Trash',
  'homeUtilities.Sewage': 'Sewage',
  'homeUtilities.Cable': 'Cable',
  'homeUtilities.Internet': 'Internet',
  'homeUtilities.Cleaning': 'Cleaning',
  'homeUtilities.Storage': 'Storage',
  'homeUtilities.Parking': 'Parking',
  'homeUtilities.Landscaping': 'Landscaping',
  'homeUtilities.PoolServicing': 'Pool',
  'homeUtilities.billing_managed_by': 'Who is managing the billing?',
  'homeUtilities.responsibility.resident': "Resident's responsibility",
  'homeUtilities.responsibility.rent': 'Included in rent',
  'homeUtilities.responsibility.purchase': 'Available for purchase',
  'homeUtilities.responsibility.hoa': 'Provided <span>by my HOA</span>',
  'homeUtilities.responsibility.fee': 'Additional required fee',
  'homeUtilities.billing_hoa_type.included': 'Included in my HOA fees',
  'homeUtilities.billing_hoa_type.separately': 'Billed separately',
  'homeUtilities.billing_cycle.monthly': 'Monthly',
  'homeUtilities.billing_cycle.one_time': 'One-Time',
  'homeUtilities.billing_cycle.quarterly': 'Quarterly',
  'homeUtilities.billing_cycle.annually': 'Annually',
  'homeUtilities.trash_title': 'When is trash day?',
  'homeUtilities.trash_location_title': 'Where is the trash located?',
  'homeUtilities.every_2_weeks': 'Every 2 Weeks',

  'inspectionPreferences.title': `Let's come take a look!`,
  'inspectionPreferences.subtitle': `Every new Belong home gets one of our signature inspections. These take around 3 hours, during which, we'll
  capture every feature of your home, and document any improvements we recommend. After, we'll send you a full
  report to review together.`,
  'inspectionPreferences.presence_preference.title': `First, would you prefer to <span>be there?</span>`,
  'inspectionPreferences.presence_preference.subtitle': `Happy to meet you there, but you certainly do not need to be. Up to you!`,
  'inspectionPreferences.presence_preference.true.first_line': `I want to be there.`,
  'inspectionPreferences.presence_preference.true.second_line': `(To meet the Inspector)`,
  'inspectionPreferences.presence_preference.false.first_line': `I don't need to be there.`,
  'inspectionPreferences.presence_preference.false.second_line': `(Just let me know when!)`,
  'inspectionPreferences.availability_time.title': `Great! What times work best <span>for you?</span>`,
  'inspectionPreferences.availability_time.subtitle': `While we cannot guarantee a time slot at this time, we'll do our best to accommodate one of the slots you selected. Once we finalize a time, we'll send you a confirmation.`,
  'inspectionPreferences.specific_requests.title': `And is there anything you want us to know or take a look at specifically?`,
  'inspectionPreferences.specific_requests.subtitle': `For example, any upgrades or renovations you'd like help with?`,
  'inspectionPreferences.specific_requests.placeholder': `Specific Requests`,

  'homeKeys.title': 'Keys, please!',
  'homeKeys.subtitle':
    'What keys or remotes will we need for your home? This includes mailbox keys, gate keys, storage areas, etc. ',
  'homeKeys.home_keys.HouseKey': 'House Key',
  'homeKeys.home_keys.FrontDoor': 'Front Door',
  'homeKeys.home_keys.BackDoor': 'Back Door',
  'homeKeys.home_keys.SideDoor': 'Side Door',
  'homeKeys.home_keys.LobbyMainEnterance': 'Lobby',
  'homeKeys.home_keys.Gate': 'Gate',
  'homeKeys.home_keys.Garage': 'Garage',
  'homeKeys.home_keys.Mailbox': 'Mailbox',
  'homeKeys.home_keys.Mailroom': 'Mailroom',
  'homeKeys.home_keys.Storage': 'Storage',
  'homeKeys.home_keys.Other': 'Other',
  'homeKeys.key_types.key': 'Key',
  'homeKeys.key_types.access_code': 'Access Code',
  'homeKeys.key_types.key_fob': 'Key fob',
  'homeKeys.key_types.remote': 'Remote',
  'homeKeys.key_types.open_access': 'Open Access',
  'homeKeys.key_delivery.title': 'How would you like to get them to us?',
  'homeKeys.key_delivery.mail': 'Mail them',
  'homeKeys.key_delivery.dropoff': 'Drop them off',
  'homeKeys.key_delivery.dropoff_subtext': '(in our office)',
  'homeKeys.key_delivery.uber': 'Uber them',
  'homeKeys.key_delivery.uber_subtext': '(Free)',
  'homeKeys.key_delivery.inspection': 'Hand it',
  'homeKeys.key_delivery.inspection_subtext': '(at the inspection)',
  'homeKeys.key_delivery.inspection_text_body': 'Great! We’ll reach out to schedule a time that works for you!',
  'homeKeys.key_delivery.lockbox': 'Lockbox',
  'homeKeys.lockbox.title': 'How do we access this lockbox?',
  'homeKeys.lockbox.access_instructions': 'Lockbox Location',
  'homeKeys.lockbox.code': 'Lockbox Code',
  'homeKeys.mailbox.title': 'Tell us about your mailbox...',
  'homeKeys.mailbox.access_instructions': 'Mailbox Location',
  'homeKeys.mailbox.number': 'Mailbox Number',
  'homeKeys.mailbox_issue.title': 'Any issues receiving mail or packages at your home?',
  'homeKeys.mailbox_issue.yes': 'Yes, there are issues',
  'homeKeys.mailbox_issue.no': 'No issues',
  'homeKeys.mailbox_issue.details': 'Describe the mail issues',
  'homeKeys.key_delivery.mail_text_body': 'Great! Please send them to',
  'homeKeys.key_delivery.office_address': (region) => getOfficeAddress(region),
  'homeKeys.key_delivery.dropoff_text_body_1':
    'Great! Please drop by anytime during business hours <span>(M-F 9AM - 7PM)</span>',
  'homeKeys.key_delivery.dropoff_text_body_2': 'We can’t wait to see you!',
  'homeKeys.key_delivery.uber_text_body': 'Great! We’ll reach out to schedule a time that works for you!',
  'homeKeys.key_form.name': 'Key Location',
  'homeKeys.key_form.type': 'Key Type',
  'homeKeys.key_form.key_details_key': '# of Keys',
  'homeKeys.key_form.has_key_key': "I don't have it",
  'homeKeys.key_form.key_details_access': 'Access Code',
  'homeKeys.key_form.has_key_access': "I don't know it",
  'homeKeys.key_form.key_details_fob': '# of Fobs',
  'homeKeys.key_form.has_key_fob': "I don't have it",
  'homeKeys.key_form.key_details_remote': '# of Remotes',
  'homeKeys.key_form.has_key_remote': "I don't have it.",

  homeownerSetupTips: {
    [SetupDataTypes.User]: {
      [HomeownerSetupStepNames.UserPersonal]: {
        // title: 'Change of Address?',
        descriptions: [
          'We take the security of your personal information seriously. It stays between us; we’ll never give <span>it out.</span>',
        ],
      },
      [HomeownerSetupStepNames.UserIdentity]: {
        // title: 'Fighting Rental Fraud',
        descriptions: [
          'To protect homeowners such as yourself against rental fraud, we verify the identity of each homeowner in our network and match them against the individual on title and payment <span>methods added.</span>',
        ],
      },
      [HomeownerSetupStepNames.UserProfile]: {
        // title: 'Fostering Human Connection',
        descriptions: [
          'At Belong, we believe in the importance of fostering <span>human connection.</span>',
          "While you won't interact with them directly, we'll use this information to introduce your future Residents <span>to you.</span> Putting a face to the name creates good will and encourages <span>better behavior.</span>",
        ],
      },
    },
    [SetupDataTypes.UserPayment]: {
      [HomeownerSetupStepNames.UserTaxes]: {
        // title: '',
        descriptions: [
          "Each year, we'll send you a 1099 form and an earnings statement, so you can easily prepare your taxes and claim all allowed deductions.",
        ],
      },
      [HomeownerSetupStepNames.UserPaymentMethods]: {
        // title: 'Fostering Human Connection',
        descriptions: [
          'To ensure the security of your financial information, we’ve integrated with Plaid, the indsustry-leading payment provider. Your banking and login information is completely encrypted; all we know is where to send <span>your payments.</span>',
        ],
      },
    },
    [SetupDataTypes.Property]: {
      [HomeownerSetupStepNames.PropertyHoa]: {
        // title: 'Managing Relationships',
        descriptions: [
          'HOAs can play an important role when it comes time to do work on your home and provide a great experience for <span>your Residents.</span>',
          "Accordingly, we'll build a positive working relationship with them, so they see Belong as a trusted part of <span>the team.</span>",
        ],
      },
      [HomeownerSetupStepNames.PropertyInsurance]: {
        // title: '',
        descriptions: [
          "We hate the word <em>landlord</em>... but in this case, we'll make an exception. Landlord insurance covers you in similar ways to homeowners insurance, but provides more tailored protection against the unique aspects of renting.",
        ],
      },
      [HomeownerSetupStepNames.PropertyFeatures]: {
        // title: 'During Your Inspection',
        descriptions: [
          "During your inspection, we'll send a professional photographer to capture these amenities, and highlight them to your <span>future Residents.</span>",
        ],
      },
    },
    [SetupDataTypes.Home]: {
      [HomeownerSetupStepNames.UnitOccupancy]: {
        // title: 'Everyone Gets The <span>Belong Experience</span>',
        descriptions: [
          "Everyone deserves the Belong Experience. If you have existing renters, we'll make sure they feel like life is getting <span>an upgrade.</span>",
          "For anyone else living in the home that may be on their way out, we'll make sure to work around their schedules while we get the home ready <span>to show.</span>",
        ],
      },
      [HomeownerSetupStepNames.UnitFeatures]: {
        // title: 'During Your Inspection',
        descriptions: [
          "During your inspection, we'll send a Belong Pro and a professional photographer to capture everything your home has to offer, and add any additional features <span>we find.</span>",
          "For now, we'll use this information to give our Members a Coming Soon sneak peek at your home <span>on our site.</span>",
        ],
      },
      [HomeownerSetupStepNames.UnitPreferencesPets]: {
        // title: 'Being Fur Friendly',
        descriptions: [
          "Consider being fur friendly. Homes that allow pets attract significantly more applications, generate additional monthly income ($75/pet), and result in happier residents that stay longer. But it's <span>up to you!</span>",
        ],
      },
      [HomeownerSetupStepNames.UnitPreferencesFurnishing]: {
        // title: 'Need Help With Storage?',
        descriptions: [
          'Need help with storage? We can help arrange pickup and storage for any belongings left in the home. Just let <span>us know!</span>',
        ],
      },
      [HomeownerSetupStepNames.UnitPreferencesUtilities]: {
        // title: 'Transferring Utilities',
        descriptions: [
          'If your home is vacant, please keep all utilities active and in your name.',
          "Once we find someone to love your home, we'll make sure all the correct utilities are transferred to their name.",
        ],
      },
      [HomeownerSetupStepNames.UnitKeys]: {
        // title: 'Key Tip!',
        descriptions: [
          "Here's a key tip! Once we receive your keys, we'll place a lockbox at your home (if possible) and store duplicates securely in <span>our office.</span>",
          'For security purposes, we also rekey all Belong homes when we take possession, ensuring no keys out in the wild can be used to compromise <span>your home.</span>',
        ],
      },
      [HomeownerSetupStepNames.UnitInspectionPreferences]: {
        // title: 'Why take the time?',
        descriptions: [
          `So why take the time? Belong inspections are thorough, for two reasons.`,
          `First, we'll look for anything in the home that may be a risk to you in the future, such as code violations.`,
          `Second, we'll make recommendations that help rent your home faster, for as much as possible.`,
        ],
      },
      [HomeownerSetupStepNames.UnitDisclosures]: {
        // title: 'Key Tip!',
        descriptions: [
          'We’ll include this information in the lease for your future Residents. These disclosures are required by law or regulation, and may help protect you from liability down <span>the road.</span>',
        ],
      },
    },
    landingPage: {
      // title: 'Go, Go, Go!',
      descriptions: [
        'Go, go, go! The sooner you complete this, the sooner we can get to work and get <span>you paid.</span>',
      ],
    },
    completedPage: {
      // title: "Now that that's over...",
      descriptions: [
        "Now that that's over, we'll be sending a Pro out to thoroughly inspect your home. By the end, we'll know every nook and cranny.",
      ],
    },
  },

  personal_info: {
    title: "Let's get to know you better, {firstName}.",
    subTitle:
      'Please confirm your <span>personal information</span> so that we can generate <span>tax forms</span> and send you documents <span>in the future.</span>',
    is_name_legal_question: "Is '{firstName} {lastName}' your legal name?",
    is_name_legal_yes: "Yes, that's correct.",
    is_name_legal_no: "No, it's different.",
    legal_first_name: 'Legal first name',
    legal_middle_name: 'Legal middle name',
    legal_last_name: 'Legal last name',
    current_address: 'Current address',
    unit_number: 'Unit',
    same_mailing_address: 'This is also my mailing address.',
    where_to_send_title: 'Where can we send you important documents?',
    mailing_address: 'Mailing address',
    next_button_text: 'Next',
  },
  success_page: {
    title_line_0: "Great! We've got ",
    title_line_1: 'everything we need!',
    sections: {
      whats_next: {
        title: "What's next?",
        listitems: [
          'Complete your account setup.',
          'Get your {home} inspected by a Belong Pro.',
          'Have the Pro Team get your {home} ready.',
          `We'll find someone to love your {home}.`,
        ],
      },
      cant_wait_that_long: {
        title: "Can't wait that long?",
        subtitle: "We're available to chat anytime between 9am-6pm PST. We'd love to hear from you!",
        chatcta: 'CHAT WITH US',
      },
    },
    buttonLabel: 'View Account',
  },
  'identity_verification.title': "Let's make sure <span>you're you.</span>",
  'identity_verification.subtitle':
    'Upload a <span>valid form of ID</span> and <span>take a selfie</span> to <span>verify your identity.</span>',
  'identity_verification.date_of_birth': 'Date of Birth (MM/DD/YYYY)',
  'identity_verification.next_button_text': 'Next',

  'pet_preferences.title': "Let's talk about some leasing preferences, <span>shall we?</span>",
  'pet_preferences.pets_allowed.section_title': 'May your residents <span>have pets?</span>',
  'pet_preferences.pets_allowed.section_sub_title':
    "Note: We're required by law to allow service and emotional support animals. Where allowed, we automatically charge Residents an additional pet security deposit to cover any pet-related wear and tear.",
  'pet_preferences.pets_allowed.any_pets': 'Any (Legal) Pets',
  'pet_preferences.pets_allowed.dogs_only': 'Dogs Only',
  'pet_preferences.pets_allowed.cats_only': 'Cats Only',
  'pet_preferences.pets_allowed.no_pets': 'No Pets',
  'pet_preferences.how_many_pets.section_title': 'Up to how many {lowerCaseType} may <span>they have?</span>',
  // 'pet_preferences.how_many_pets.label': '# of {lowerCaseType}',
  'pet_preferences.how_many_pets.all_shapes_checkbox': 'All shapes and sizes <span>are welcome.</span>',
  'pet_preferences.select_that_apply.title': 'Any other restrictions?',
  'pet_preferences.weight_restrictions.label': 'Only {lowerCaseType} under a <span>certain weight.</span>',
  'pet_preferences.weight.label': 'Weight Limit (lb)',
  'pet_preferences.breed_restrictions.label': 'My HOA or insurance restricts <span>certain breeds.</span>',
  'pet_preferences.hoa_restrictions.label': 'Restricted Breed(s)',
  'pet_preferences.next_button': 'Done',

  'pet_preferences.pets_allowed.no_pets.confirmation.title': 'Three Reasons to Reconsider',
  'pet_preferences.pets_allowed.no_pets.confirmation.bold': [
    'Increase the market for your home.',
    'Worried about wear and tear?',
    'Happier Residents. Happier Home.',
  ],
  'pet_preferences.pets_allowed.no_pets.confirmation.text': [
    'The majority of residents have at least one pet. Opening up to pets increases competition for your home.',
    'Residents pay an additional fee for each pet, on top of the security deposit. Should scuffs or stains occur, we’ll handle any repairs ourselves. Plus, we’ll ask about pets when we do a reference check with your Residents’ previous landlord to make sure they were on their best behavior.',
    'Last but not least, pets make people happy, generating good will that will translate to better care for your home.',
  ],

  disclosures: {
    title: 'Anything important we need to tell future renters about?',
    subtitle: 'Select all legally required disclosures that you know about at <strong>{address}</strong>.',
    describe: 'Could you please expand?',
  },
  disclosures_documentation: {
    title: "We've prepared your <span>disclosures form.</span>",
    subtitle: "As required by law or regulation, we'll include this in your future <span>Residents' lease.</span>",
  },
  user_payment_w9: {
    individual_title: 'We’ve prepared a W-9 <span>for you to sign.</span>',
    business_title: 'We’ve prepared a W-9 for <div>{businessName}.</div>',
    subtitle: 'Based on this, we will send you a 1099 <span>come tax season.</span>',
  },
  user_payment_methods: {
    title: "Let's get you paid!",
    subtitle:
      'Belong will send you rent for this home, less any fees, via the payment method <span>of your choice.</span>',
  },

  'banner.get_inspected': `As soon as you complete, we'll schedule the appointment, with slots as early <span>as tomorrow.</span>`,
  'banner.get_inspected_date': `As soon as you complete, we'll schedule the appointment, with slots as early <span>as {date}.</span>`,

  'unlock_animation.multiple_units.inspection': `Congratulations! You've unlocked the inspection for {address}!`,
  'unlock_animation.multiple_units.listing': `Alright! You've unlocked the listing for {address}!`,
  'unlock_animation.multiple_units.payment': `Hooray! You've unlocked rent payments for {address}!`,
  'unlock_animation.single_unit.inspection': `Congratulations! You've unlocked your home's inspection!`,
  'unlock_animation.single_unit.listing': `Alright! You've unlocked your home's listing!`,
  'unlock_animation.single_unit.payment': `Hooray! You've unlocked rent payments!`,
};

// Increase the market for your home.
// The majority of residents have at least one pet. Opening up to pets increases competition for your home.

// Worried about wear and tear?
// Residents pay an additional fee for each pet, on top of the security deposit. Should scuffs or stains occur, we’ll handle any repairs ourselves. Plus, we’ll ask about pets when we do a reference check with your Residents’ previous landlord to make sure they were on their best behavior.

// Happier Residents. Happier Home.
// Last but not least, pets make people happy, generating good will that will translate to better care for your home.
