import React from 'react';
import classNames from 'classnames/bind';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import PropTypes from 'prop-types';
import styles from './ListingUnitCardLink.module.css';

const cx = classNames.bind(styles);

const ListingUnitCardLink = ({ action, to, children }) => (
  <LinkBase className={cx('listing-link-wrapper')} action={action} to={to}>
    {children}
  </LinkBase>
);

ListingUnitCardLink.propTypes = {
  action: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ListingUnitCardLink;
