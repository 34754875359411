/* eslint-disable react/prop-types */
// React imports
import React, { Component } from 'react';
import classNames from 'classnames/bind';
// React final form imports
import Field from 'components/Field/Field';
// Component imports
import { SelectableFormPanelFinalFormAdapter, SIZES } from 'components/SelectableFormPanel/SelectableFormPanel';
import PropTypes from 'prop-types';
// SCSS imports
import styles from './MultiSelectable.module.css';

const cx = classNames.bind(styles);

const TYPES = {
  DOUBLES: 'doubles',
  FULL_WIDTH: 'full-width',
};
export default class MultiSelectable extends Component {
  static propTypes = {
    options: PropTypes.array,
    type: PropTypes.oneOf(Object.values(TYPES)),
  };

  static defaultProps = {
    options: [],
    type: TYPES.FULL_WIDTH,
  };

  render() {
    const { name, options, form, meta, newFieldComponent, ignoreError, type, ...rest } = this.props;

    const controlledProps = {};

    if (!ignoreError) {
      if (newFieldComponent) {
        controlledProps.error = meta.error;
      } else {
        controlledProps.error = meta.touched ? meta.error : {};
      }
    }

    return (
      <div className={cx('container')}>
        {options.map((option, index) => {
          const error = controlledProps.error && controlledProps.error[option.name];
          return (
            <div className={cx('item', type)} key={index}>
              <Field
                nestedField
                component={SelectableFormPanelFinalFormAdapter}
                size={SIZES.SMALL}
                showCheckmark
                {...rest}
                {...option}
                name={`${name}.${option.name}`}
                form={form}
                error={!!error}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export const MultiSelectableFinalFormAdapter = ({ input, options, ...rest }) => (
  <MultiSelectable {...input} options={options} {...rest} />
);
