import React, { Component } from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import './Money.module.css';
import { MONEY_TYPES } from './moneyTypes';

export const MONEY_FORMAT = MONEY_TYPES;

export const formatMoney = (cash, format = MONEY_TYPES.BASIC) => numeral(cash).format(format);
export const formatMoneyDollarCents = (cash) => formatMoney(cash, MONEY_TYPES.DOLLARS_CENTS);

class Money extends Component {
  static propTypes = {
    // Amount of cash being passed in. I want to change it to amount.
    cash: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    // Uses the formatting from http://numeraljs.com/
    format: PropTypes.oneOf(Object.values(MONEY_FORMAT)),
  };

  static defaultProps = {
    format: MONEY_TYPES.BASIC,
  };

  render() {
    const { cash, format } = this.props;
    return formatMoney(cash, format);
  }
}

Money.DOLLAR_CENTS = (props) => <Money format={MONEY_TYPES.DOLLARS_CENTS} {...props} />;

export default Money;
