// Use getstring function in index.js to communicate with items in {}

export const RESIDENT_APPLICATION_STRINGS = {
  next_button: 'Next',

  pa_landing_page: {
    title: 'Your new Belong home\nis\u00a0only\u00a0a\u00a0few\u00a0steps\u00a0away.',
    // This updates the Resident coapplicant landing page strings as well
    what_you_need_section: {
      title: 'To qualify, we suggest . . . ',
      requirements: [
        'Credit score of\u00a0650+',
        'Income documents to verify recurring income of 3X the monthly\u00a0rent',
        'History free of evictions, bankruptcies, multiple late payments, and\u00a0charge\u2011offs',
        //
        // The below item is reference by index! Make sure to update code if changing the order of this array.
        //
        'Positive reference from your most recent\u00a0landlord',
        'Where allowed by law, a clean background\u00a0check',
      ],
    },
    enjoy_section: {
      title: "Belong Residents have it\u00a0better.",
      stress_free: 'A stress free experience with dedicated 24/7\u00a0support.',
      on_time: 'By paying rent on time, you build towards\u00a0homeownership.',
      belong_app: 'Manage your home on the go with the Belong\u00a0app.',
    },
    key_factors_section: {
      title:
        'While we believe everyone should get to live in a Belong home, these key factors are used to approve applications.',
      items: [
        {
          title: 'Credit',
          body: 'Credit scores are important, but not the only thing we look at. We review each credit factor individually. Belong requires a score of 700+, but lower scores may be considered with an increased security deposit.',
        },
        {
          title: 'References',
          body: "Belong requires a positive reference from your most recent landlord. We'll ask you to connect us with them at the end of the application process.",
        },
        {
          title: 'Steady Income',
          body: "As part of this application, you'll be asked to verify your income. Combined income should be at least 3x monthly rent to cover rent and life's expenses.",
        },
        {
          title: 'Background Checks',
          body: 'We check public records and require a history free of evictions, bankruptcies, multiple late payments, and criminal records (where allowed).',
        },
      ],
      credit: {
        title: 'Credit Score Details',
        subtitle: 'Our typical credit requirements are:',
        bulletPoints: [
          '2+ Years Of Credit History ',
          '2+ Open Accounts',
          'Credit Utilization At Or Below 35%',
          'No Current Delinquent Accounts',
          'No Severe Delinquencies (Bankruptcies, Charge-Offs, Payments More Than 90 Days Late, etc.)',
          'No Late Payments In The Last 12 Months', 
          'No More Than 5 Late Payments In The Last 36 Months',
          'Debt to income ratio less than 43%',
        ],
        openModal: 'Learn More',
        closeModal: 'Got it!',
      },
    },
    previous_applications_section: {
      title_one: 'Looks like you have an existing application...',
      title_multiple: 'Looks like you have some existing applications...',
    },
    use_existing: 'Use Existing Application',
    start_new: 'START A NEW APPLICATION',
    start_new_not_logged_in: 'Start',
    back_to_house_page: 'VIEW HOME',
    tip: {
      description:
        'I have the honor of finding someone to love this home. Could that be you? This application will only take a few minutes. Please make sure all residents over the age of 18 complete an application.',
    },
  },
  ca_landing_page: {
    co_applicant_title: '{firstName} is applying to live in this home with you.',
    co_signer_title: '{firstName} is applying to live in this home, and listed you as a cosigner.',
    co_applicant_subtitle: 'Want to live with {firstName}?',
    co_signer_subtitle: 'Will you vouch for {firstName}?',
  },
  move_in_date: {
    title: 'First, when would you like the lease to start?',
    subTitle: 'The earlier the start date, the greater your chances of getting the home.',
    dateStrings: {
      ASAP: 'ASAP',
      other: 'Custom',
    },
    tip: {
      title: 'Feel at home on Day 1',
      description:
        "We'll coordinate with you and/or your movers, and do our part to make sure all your utilities are set up upon arrival. Start unpacking, or throw on Netflix. Your choice.",
    },
  },
  how_long: {
    title: 'How long would you like to lease this home?',
    subtitle: 'By taking a longer lease, you lock in the same price for more months.',
    duration: 'Lease Duration',
    are_you_sure:
      'Are you sure? Rent prices tend to go up over time. You may miss an opportunity to lock in this lower rate.',
    great_choice: 'Great choice! You have locked in the same rate for longer.',
  },
  people_and_pets: {
    pretitle: 'Hi, {firstName}!',
    title: 'Who will you be sharing the home with?',
    just_me: 'Just me',
    others_and_me: 'Others & Me',
    any_kids: 'Any kids?',
    any_pets: 'Any pets?',
    any_other_adults: 'Any other adults in the room?',
    any_other_adults_yes: 'Yup',
    any_other_adults_nope: 'Nope',
    lets_meet_them: 'We’d love to meet them!',
    background_check_disclaimer: `Each adult above the age of 18 will be responsible for the lease and must pass a background and credit check.`,
    kids_selector_label: 'Kids',
    kids_name_label: "Kid's Name",
    kids_date_of_birth_label: 'Date Of Birth (MM/DD/YYYY)',
    kids_date_of_birth_error: 'Please add anyone over the age of 18 as an adult above.',
    kids_tell_us_about: "To better tailor your experience, we'd love to know a bit more info about your kids.",
    pets_selector_label: 'Pets',
    pets_name_label: "Pet's Name",
    pets_tell_us_about: "Ok, let's talk about your critters!",
    pets_type_label: 'Type',
    pets_weight_label: 'Weight (lb)',
    pets_is_service_animal: 'This is a service or emotional support animal.',
    pets_lives_in_cage: 'This animal lives full time in a cage or aquarium.',
    pets_service_animal_info:
      '{note}: Having a service or emotional support animal will not effect your application, and you will not incur any fees or additional deposits for this animal.',
    pets_deposit: '{money} Additional Deposit Required',
    pets_document: 'Please provide service or emotional support animal documentation.',

    // warning notes
    pets_not_allowed_notes: '{note}: This home currently does not allow pets.',
    pets_limit_notes: '{note}: This home has a limit of {maxAllowedPets}.',
    pets_type_notes: '{note}: We love {petType} here at Belong, but this home currently does not allow them.',
    pets_breed_weight_notes:
      '{note}: We love all breeds here at Belong, but this particular breed is not currently permitted at this home, and there is a {weight} lb weight limit.',
    pets_weight_notes: '{note}: This home has a {weight} lb weight limit for pets.',
    pets_breed_notes:
      '{note}: We love all breeds here at Belong, but this particular breed is not currently permitted at this home.',
    pet_restriction_notes:
      'While this home’s pet policy may affect your chances, you’re welcome to continue your application; if we can’t make accommodations, we’ll be happy to refund your application fees.',

    next_button_text: 'Next',
    other_adults: {
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      relationship: 'Relationship',
      is_included_on_lease: 'Include them on the Lease',
      who_earns_income_subtitle: 'Who earns an income in this relationship?',
      income_earner: {
        you: 'You',
        both: 'Both',
      },
      primary_applicant_email_error:
        'Please enter a unique email for your co-applicant, so that they may access their account as well.',
      coapplicant_email_error:
        'Please enter a unique email for this co-applicant, so that they may access their account as well.',
    },
    tip: {
      title: 'All are welcome.',
      description:
        'Belong evaluates each person applying above the age of 18; families with kids of all ages are welcome. And while some homes have pet restrictions for various reasons, we love all pets here at Belong, and reserve the right to send birthday cards and chew toys.',
    },
  },

  credit_check: {
    title: "Let's get to know you better, {firstName}.",
    subTitle:
      'We perform a soft credit check for each adult applying to live in the home. This will not impact your credit score.',
    is_name_legal_question: "Is '{firstName} {lastName}' your legal name? No middle name or anything?",
    is_name_legal_yes: 'Yup',
    is_name_legal_no: 'No',
    legal_first_name: 'Legal First Name',
    legal_middle_name: 'Legal Middle Name',
    legal_last_name: 'Legal Last Name',
    date_of_birth: 'Date Of Birth (MM/DD/YYYY)',
    current_address: 'Current Address',
    legal_disc:
      "I understand that by clicking on the I AGREE button immediately following this notice, I am providing 'written instructions' to Belong under the Fair Credit Reporting Act authorizing Belong to obtain information from my personal credit profile or other information from credit bureaus. I authorize Belong to obtain such information solely to approve me to rent a home.",
    next_button_text: 'I Agree',
    tip: {
      description:
        "Your credit report contains information about how you manage your finances, including rental defaults, which we use to build your profile and better understand your history. Over time, we'll rely less on this, and more on your history with Belong should you choose to want to live in another Belong Home in the future.",
    },
    emailMismatchModalTitle: 'Looks like you prefer a different email?',
    emailMismatchModalSubtitle: 'Contact us to have that changed on your application.',
  },

  application_fee: {
    title: 'Pay your application fee.',
    // TODO: make a global constants file that updates frontend and backend for Application Fee and Application Days
    subTitle: `This {fee} fee covers the cost of a credit and background check, and is valid for up to 60 days.`,
    how_to_pay: {
      section_title: 'How would you like to pay?',
      account_label: 'Use Account',
      account_label_account_number: 'Ending {lastDigits}',
      account_subLabel: '(FREE)',
      cc_label: 'Credit Card',
      cc_label_cc_number: 'Ending {lastDigits}',
      cc_subLabel: `({fee} Processing fee)`,
    },
    credit_card_modal_title: 'Paying With Credit Card',
    next_button_text: 'Next',
    next_button_credit_card: `Pay {fee}`,
    next_button_text_paid: 'Next (Paid)',
    tip: {
      description:
        "Once your application is accepted and you move in, you'll become a Belong Member. Going forward, we'll only need to look at your history as a Resident to determine suitability for future Belong Homes you may want to live in. No more applications for you!",
    },
    tip_cosigner: {
      description:
        "By the way, we'd love to have you as a Belong Member some day as well! We hope you've found this application to be a breeze so far. Renting a Belong Home would be just the same.",
    },
  },

  employment_info: {
    title: 'No one likes talking finances. But we have to ask.',
    employment_status_section_title: 'Please tell us about your current and future sources of income.',
    est_annual_income: 'Est. Annual Income ($ Pre-Tax)',
    next_button_text: 'Next',
    employments: {
      job: {
        title: 'Salaried Job',
        company_title: 'What company do you work for?',
        ending_soon: 'I am planning to leave soon.',
        future_start: 'I started here in the last 2 months.',
      },
      contract: {
        title: 'Contracting / <span>Self-Employment</span>',
        company_title: 'What type of contracting or self-employed work do you do?',
      },
      additional: {
        title: 'Additional Income Source',
        company_title: 'Where does this additional source of income come from?',
      },
      unemployed: 'I do not have any current or future sources of income.',
    },
    tip: {
      description:
        "Work isn't everything. Understanding the sources of income you'll use to pay rent will give us a better picture of whether you can afford to do so.  Next, we'll ask you to verify these income streams.",
    },
    tip_cosigner: {
      description:
        "Work isn't everything. Understanding the sources of income you'll use to guarantee this lease will give us a better picture of whether you can afford to do so.  Next, we'll ask you to verify these income streams.",
    },
  },

  income_verification: {
    title_income: "Great! Now let's verify your annual income of ~{income}.",
    title_savings: "Great! Now let's verify your savings.",
    add_another_account: 'Have another account you use for income or savings?',
    security: 'about our security practices',
    paystub_title: 'Your last four paystubs from <b>{source}</b>.',
    primary_account:
      'Your last four monthly bank statements for the primary account where you receive income.',
    additional_account:
      'Your last four monthly bank statements for the {account} account where you receive income.',
    add_another_manual_account: 'Have another account where you collect income?',
    subTitle_income:
      'Connect to your payroll provider or bank account(s) you use for income to complete the verification process.',
    employment_status_section_title: "What's your current employment status?",
    skip: 'SKIP AND SEND DOCUMENTS',
    skip_title: 'For best results, verify your income automatically now.',
    skip_content:
      'We aim to process income verification documents in 1-2 business days from time of receipt. Note that we can only secure your place in line for this home once the application is completely verified.',
    verification_options_selector: {
      previous_account: 'ACH Ending {lastDigits}',
      add_account: 'Connect Bank',
      upload_document: 'Upload Manually',
      add_cosigner: 'Add A Cosigner',
      instant: 'Instant',
      two_business_days: '1-2 business days',
      invite_pending: 'Send Invite',
    },
    document_upload_modal: {
      title: 'Please upload or drag-and-drop 1 or more income or asset statements.',
      description: '(W2s, Employment offer letters, Paystubs, Bank statements, or tax returns)',
      save_button: 'Save',
      document_type_label: 'Document Type',
    },
    add_cosigner_modal: {
      title: 'Let’s add a cosigner!',
      description:
        "We'll send them an invite to apply with you, so you can improve your application and they can guarantee your lease.",
      first_name: 'Cosigner First Name',
      last_name: 'Cosigner Last Name',
      email: 'Cosigner Email Address',
      relationship: 'Relationship',
      save_button: 'Save',
    },
    section_titles: {
      cosigners: 'Your Cosigners',
      accounts: 'Accounts Added',
      documents: 'Upload Documents',
    },
    next_button_text: 'Next',
    tip: {
      description: `We take the security of your information very seriously. Any documents added manually are reviewed by full-time, background-checked employees; we'll never share your information with 3rd parties.`,
    },
    failed_attempts:
      'We weren’t able to successfully verify your income. Please continue to next step and we’ll contact you again.',
  },

  identity_verification: {
    title: "Let's make sure you're you.",
    subTitle:
      "In order to verify your identity, you'll be asked to take a picture of your ID and take a selfie.",
    choose_id_type: 'What type of ID are you uploading?',
    id_title: 'Upload your ID.',
    id_front_title: 'Upload the front of your ID.',
    id_front_sub_title:
      'Place your ID on a flat, blank surface with all edges in frame. Please make sure your camera is in focus so we can read the fine print!',
    id_front_uploaded_title: 'Upload the front of your ID.',
    passport_title: 'Upload the ID page of your Passport.',
    passport_sub_title:
      'Place your ID on a flat, blank surface with all edges in frame. Please make sure your camera is in focus so we can read the fine print!',
    passport_uploaded_title: 'Upload the ID page of your Passport.',
    id_back_title: 'Now, flip it over and upload the back.',
    id_back_sub_title:
      'Place your ID on a flat, blank surface with all edges in frame. Please make sure your camera is in focus so we can read the fine print!',
    id_back_uploaded_title: 'Upload the back of your ID.',
    selfie_title: 'Lastly, take a selfie!',
    selfie_sub_title:
      "Position your face in the middle of the frame, and make sure you are well lit so we can get a good look. We'll use this to make sure your ID matches the person uploading it.",
    selfie_uploaded_title: 'Wow, you look great!',
    id_types: {
      dl_id: "Driver's License/ ID Card",
      passport: 'Passport',
      // n_id: 'National ID Card',
      // other: 'Other'
    },
    id_modal_title: 'Can we see some ID?',
    id_modal_subTitle:
      'Place your ID on a flat, blank surface and align the edges with the green brackets. Make sure the camera is in focus so we can read the fine print!',
    selfie_modal_title: 'Take a selfie!',
    selfie_modal_subTitle:
      'Position your face in the green outline and make sure it is well lit so we can get a good look.',
    picture_modal_cta_label: 'Save',
    verify_me: 'Verify My Identity',
    next_button_text: 'Apply',
    tip: {
      description:
        "As a Verified Member, you'll have access to the entire Belong Community, including special events, discounts and more.",
    },
    tip_cosigner: {
      description:
        "While you may not be living in a Belong Home, as a Verified Member, you'll have access to the entire Belong Community in the future, including special events, discounts and more.",
    },
    verification_loader: 'Looking good!<div>Please wait while we verify you.</div>',
    verification_fail: 'Hmm, we were not able to verify your ID. Please try again!',
    tips: [
      'Valid, Non-Expired ID',
      'Blank, Dark Background',
      'All Edges In Frame',
      'Legible, In-Focus Text',
      'Turn Off Your Camera’s Flash',
    ],
  },

  processing_verification_modal: {
    title: `Hi {firstName}, please wait while we process your application. This may take a minute.`,
  },

  reference_check: {
    last_rented: 'Ok, where was the last apartment you rented?',
    preTitle: 'When it comes to renting...',
    title: "We're looking for someone to sing your praises!",
    currently_renting_section_title: 'Are you currently renting {streetAddress}?',
    currently_renting_section_title_international: 'Are you currently renting?',
    currently_renting_selector_yes: "Yes, I'm renting.",
    currently_renting_selector_no: 'No, not renting.',
    previous_rented_section_title: 'Ok, where was the last home you rented?',
    previous_rented_address_input: 'Address',
    never_rented_checkbox: 'I have never rented before.',
    landlord_info_section_title: 'Who is the homeowner, landlord or property manager there?',
    landlord_info_section_subTitle: "We'll reach out to do a reference check.",
    landlord_name: 'Landlord Name',
    landlord_email: 'Landlord Email',
    landlord_phone: 'Landlord Phone',
    recent_housemate_section_title: 'Who have you lived with most recently?',
    recent_housemate_section_subTitle: "We'll reach out to do a reference check.",
    recent_housemate_name: 'Reference Name',
    recent_housemate_email: 'Reference Email',
    recent_housemate_phone: 'Reference Phone',
    not_informed_landlord_checkbox: "I have not informed my landlord I'm planning to move out yet.",
    not_informed_landlord_text:
      "Don't worry, we'll hold off on contacting your landlord until you give us the go ahead.",
    income_verified: 'Your income information was successfully verified!',
    next_button_text: 'Next',
    tip: {
      description:
        "Speaking of landlords, this is the last time you'll hear us use that word. At Belong, there are only Homeowners and Residents. And everyone is a Member who can rely on Belong to take care of everything.",
    },
  },

  background_check: {
    title: 'Everyone applying to a Belong Home must pass a background check.',
    subtitle: 'All personal information is handled completely securely and confidentially.',
    ssn_input: 'SSN',
    no_ssn_checkbox: "I don't have an SSN.",
    no_ssn_section_title: "Don't have a social security number? On a student visa?",
    no_ssn_section_text:
      "No problem. We'll ask you for additional documentation later in order to verify your history.",
    background_pull_auth:
      'I authorize Belong to do a full background check, including a criminal database check.',
    next_button_text: 'Next',
    tip: {
      description:
        "Speeding ticket? Don't worry about that. Belong's criminal background check looks into federal, state and local criminal databases for felonies and misdemeanors. But seriously, try not to speed.",
    },
  },

  add_cosigner: {
    title: 'To strengthen your application, we recommend you add a cosigner.',
    subTitle:
      "Should you choose to add one, we'll send them an invite to apply with you and guarantee your lease.",
    first_name: 'Cosigner First Name',
    last_name: 'Cosigner Last Name',
    email: 'Cosigner Email Address',
    relationship: 'Relationship',
    relationship_other: 'Other Relationship',
    save_button: 'Save',
    next_button_text: 'Next',
    tip: {
      description:
        'In order to qualify as someone that can guarantee your lease, your cosigner will need an annual income of at least {minIncome}, and established credit history.',
    },
  },

  'success_page.title_qualified_not_toured':
    'Hi {firstName}, your application is in! Now, would you like to come see it in person?',
  'success_page.title_qualified_someone_toured_or_cosigner':
    'Hi {firstName}, your application is in! You are currently {placeInLine} in line for this home.',
  // Reason for changing: https://belonghome.slack.com/archives/C03SR4TQMHT/p1660686048423039
  'success_page.title_not_qualified_not_toured':
    "I'll get right to work reviewing your application, {firstName}! I will also reach out to collect documents to verify your household income. In the meantime, would you like to see this home in person?",
  'success_page.title_not_qualified_someone_toured_or_cosigner':
    "I'll get right to work reviewing your application, {firstName}! In the meantime, feel free to contact me anytime.",

  'success_page.done_cta': 'Done',
  'success_page.tour_cta': 'Book A Tour',
  'success_page.accounts_cta': 'Go To Account',
  'success_page.timeline_title': `What's Next?`,

  success_page: {
    title_line_0: "Great! We've got ",
    title_line_1: 'everything we need!',
    sections: {
      whats_next: {
        title: "What's next?",
        list_items: [
          'Submit Your Application',
          "Receive {firstName}'s Application",
          'Get To Know Us And Help Us Get To Know You',
          'Reference Call With {firstName}',
          'Get Approved',
          'Sign And Pay The Deposit',
          'Schedule The Move-in',
        ],
      },
      cant_wait_that_long: {
        title: "Can't wait that long?",
        subtitle: "We're available to chat anytime between 9am-6pm PST. We'd love to hear from you!",
        chatcta: 'CHAT WITH US',
      },
    },
    buttonLabel: 'View Account',
  },
  shareableInfo: {
    bannerTitle: 'Enjoy being in the 21st century with Belong?',
    bannerMessage: 'Refer a homeowner. Earn $1,000.',
    shareableMessage:
      'I just applied to a Belong home, and it was super easy and headache free. If you own a rental home and want to find well qualified residents fast, check them out.',
  },

  id_methods: {
    title: 'Get Your ID ready',
    passport: 'US Passport',
    license: 'US Driver License',
    visa: 'US Visa',
    permanent: 'US Permanent Resident Card',
    state: 'US State ID Card',
    ead: 'US EAD',
    completed: 'Completed!',
  },
};
