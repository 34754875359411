import { isPast, isFuture, isSameDay, isValid, isBefore, isExists } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { pluralizeWithoutValue } from 'utils/pluralize';

export const isEmpty = (value) =>
  value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);

const join = (rules) => (value, data, params) =>
  rules.map((rule) => rule(value, data, params)).filter((error) => !!error)[0];

export const email = (value) => {
  if (
    !isEmpty(value) &&
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]*[^.]([a-zA-Z0-9.-]*[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i.test(value)
  ) {
    return 'Invalid email address';
  }
};

export const required = (value) => {
  if (isEmpty(value)) {
    return 'Required';
  }
};

export const requireTrue = (value) => {
  if (value !== true) {
    return 'Required true';
  }
};

export const validMMYY = (value) => {
  if (value.length < 5 || typeof value === 'object') {
    return 'Valid Date Required';
  }

  const [mm, yy] = value.split('/');

  if (Number(mm) > 12) {
    return 'Valid Month';
  }

  if (Number(yy) > 50) {
    return 'Please choose a year before 2050';
  }
};

export const minLength = (min) => (value) => {
  if (!isEmpty(value) && value.length < min) {
    return `Must be at least ${min} characters`;
  }
};

export const maxLength = (max) => (value) => {
  if (!isEmpty(value) && value.length > max) {
    return `Must be no more than ${max} characters`;
  }
};

export const integer = (value) => {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
};

export const minimumOneInteger = (value) => {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }

  if (Number(value) < 1) {
    return 'Value must be greater than one';
  }
};

export const oneOf = (enumeration) => (value) => {
  if (!enumeration.includes(value)) {
    return `Must be one of: ${enumeration.join(', ')}`;
  }
};

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

export const createValidator =
  (rules, params) =>
  (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data, { key, ...params });

      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };

/*
  Set password validation method.
  Add regex here to check password string
  @params:
    value - string
      password field value
  @return:
    error string
*/
export const setPassword = (value) => {
  if (isEmpty(value)) {
    return 'Invalid password';
  }

  // const hasLowerCase = /[a-z]/.test(value);
  // const hasUpperCase = /[A-Z]/.test(value);
  // const hasDigits = /[\d]/.test(value);
  const hasLowerCase = true;
  const hasUpperCase = true;
  const hasDigits = true;
  const hasValidLength = value.length >= 6;

  if (hasDigits && hasUpperCase && hasLowerCase && hasValidLength) {
    return undefined;
  }

  return 'Invalid password';
};

/*
  Validate SSN input by regex matching
  @params:
    value - string
  @return:
    error string
*/
export function ssnValidation(value) {
  if (!isEmpty(value) && !/^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/i.test(value) && !/^xxx\-xx\-?[0-9]{4}$/i.test(value)) {
    return 'Invalid SSN';
  }
}

export function ssnValidationFirstTime(value) {
  if (!isEmpty(value) && !/^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/i.test(value)) {
    return 'invalid SSN';
  }
}

export const einValidation = (value) => {
  if (!isEmpty(value) && !/^[0-9]{2}\-?[0-9]{7}/i.test(value)) {
    return 'Invalid EIN';
  }
};

/*
  Validate date input by parsing the input and creating a new Date
  @params:
    value - string
      mm/dd/yyyy (To-Do Akash: Add support for multiple date format validation)
  @return:
    error string
*/
export const dateValidation = (value) => {
  if (!isEmpty(value)) {
    if (value.toString().split('/').length === 3) {
      const [mm, dd, yyyy] = value.toString().split('/');

      if (yyyy.length === 4) {
        const dateObj = legacyParse(`${yyyy}/${mm}/${dd}`);

        if (!isValid(legacyParse(dateObj))) {
          return 'Invalid Date';
        }
      } else {
        return 'Invalid';
      }
    } else {
      return 'Invalid';
    }
  }
};

export const isPresentOrFutureDateMMDDYYYY = (value) => {
  if (!isEmpty(value)) {
    if (value.toString().split('/').length === 3) {
      const [mm, dd, yyyy] = value.toString().split('/');

      if (yyyy.length === 4) {
        const dateObj = legacyParse(`${yyyy}/${mm}/${dd}`);

        if (!isValid(legacyParse(dateObj))) {
          return 'Invalid Date';
        }

        if (isPast(dateObj) && !isSameDay(dateObj, Date.now())) {
          return 'Invalid Date';
        }
      } else {
        return 'Invalid';
      }
    } else {
      return 'Invalid';
    }
  }
};

export const dateValidationMMDDYY = (value) => {
  if (value.length > 9) {
    const isDateObj = legacyParse(value);
    if (!isValid(isDateObj)) {
      return 'Invalid Date';
    }
  } else if (!isEmpty(value) && value.toString().split('/').length === 3) {
    const [mm, dd, yy] = value.toString().split('/');

    if (yy.length === 2) {
      const dateObj = legacyParse(`20${yy}/${mm}/${dd}`);
      if (!isValid(dateObj)) {
        return 'Invalid Date';
      }
    } else {
      return 'Invalid';
    }
  } else {
    return 'Invalid';
  }
};

export const dateValidationMMYY = (value) => {
  if (!isEmpty(value) && value.toString().split('/').length === 2) {
    const [mm, yy] = value.toString().split('/');

    if (yy.length === 2) {
      const dateObj = legacyParse(`20${yy}/${mm}/01`);

      if (!isValid(dateObj)) {
        return 'Invalid Date';
      }
    } else {
      return 'Invalid';
    }
  } else {
    return 'Invalid';
  }
};

export const isFutureDateMMYY = (value) => {
  const [mm, yy] = value.toString().split('/');
  const dateObj = legacyParse(`20${yy}/${mm}/01`);

  if (!isFuture(dateObj)) {
    return 'Is not in the future';
  }
};

export const isFutureDateMMDDYY = (value) => {
  if (!isFuture(legacyParse(new Date(value)))) {
    return 'Is not in the future';
  }
};

export const validPastYYYYString = (value) => {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }

  const currentYear = new Date().getFullYear();
  if ((value && value.length < 4) || Number(value) > currentYear) {
    return 'Must be a valid year';
  }
};

export const validPastOrTodayMMDDYYDate = (value) => {
  const dateObj = legacyParse(new Date(value));

  if (isFuture(dateObj) && !isSameDay(dateObj, Date.now())) {
    return 'Is not in the past or today';
  }
};

export const validFutureOrTodayMMDDYYDate = (value) => {
  const dateObj = legacyParse(new Date(value));

  if (isBefore(dateObj, Date.now()) && !isSameDay(dateObj, Date.now())) {
    return 'Current date is in the past, please select a future date from today.';
  }
};

export const validFutureOrTodayMMYYDate = (value) => {
  const [mm, yy] = value.toString().split('/');
  const today = new Date();
  const dateObj = legacyParse(`20${yy}/${mm}/${today.getDate()}`);
  const isToday = isSameDay(dateObj, today);

  if (!isFuture(dateObj) && !isToday) {
    return 'Current date is in the past, please select a future date from today.';
  }
};

export const dateOfBirthValidation = (value) => {
  if (!isEmpty(value) && value.toString().split('/').length === 3) {
    const [mm, dd, yyyy] = value.toString().split('/');

    if (yyyy.length === 4) {
      const dateObj = legacyParse(`${yyyy}/${mm}/${dd}`);

      if (!isValid(dateObj)) {
        return 'Invalid Date';
      } else if (!isPast(dateObj)) {
        return 'Invalid Date';
      }
    } else {
      return 'Invalid';
    }
  } else {
    return 'Invalid';
  }
};

export const isExistentDateMMDDYY = (date) => {
  if (!isEmpty(date)) {
    const splittedDate = date.toString().split('/');

    if (splittedDate.length === 3) {
      const [mm, dd, yy] = splittedDate;

      if (!isExists(Number(`20${yy}`), Number(mm) - 1, Number(dd))) {
        return 'Date does not exist';
      }
    } else {
      return 'Date format is too short';
    }
  } else {
    return 'Date is empty';
  }
};

export function isWeekday(date) {
  const day = new Date(date).getDay();
  if (day === 0 || day === 6) {
    return 'Cannot be weekend';
  }
}

// Can't select next day if the current day is after noon/Can't select today
export const isNextDayAfterNoonOrToday = (timeZone) => (value) => {
  if (isValid(new Date(value))) {
    const dateObj = new Date(value);
    const today = utcToZonedTime(new Date(), timeZone);
    const isTodayAfterNoon = today.getHours() > 12;
    const isSelectedTomorrow = dateObj.getDate() === today.getDate() + 1;
    const isSelectedToday = dateObj.getDate() === today.getDate();

    if (isSelectedToday) {
      return 'Cannot be today';
    }
    if (isTodayAfterNoon && isSelectedTomorrow) {
      return 'Cannon select next day after noon';
    }
  }
};

export const phoneValidation = (value) => {
  // stolen from: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
  const phoneRegex = /^\d{10}$/;

  if (!isEmpty(value) && !phoneRegex.test(value)) {
    return 'Invalid';
  }
};

export const creditCard = (value) => {
  if (isEmpty(value)) {
    return 'invalid';
  }

  const isAmex = /^34/.test(value) || /^37/.test(value);

  // condition Amex
  if (isAmex) {
    return integer(value) || minLength(15)(value) || maxLength(15)(value);
  }

  return integer(value) || minLength(16)(value) || maxLength(16)(value);
};

export const zipcode = (value) => required(value) || integer(value) || minLength(5)(value) || maxLength(5)(value);

export const creditCardCvc = (value, creditCardNumber) => {
  if (isEmpty(value)) {
    return 'invalid';
  }

  const isAmex = /^34/.test(creditCardNumber) || /^37/.test(creditCardNumber);

  // condition Amex
  if (isAmex) {
    return integer(value) || minLength(4)(value.trim()) || maxLength(4)(value.trim());
  }

  return integer(value) || minLength(3)(value.trim()) || maxLength(3)(value.trim());
};

// Used to add multiple validators together
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const checkIfCurrentStatusIsSame = (requestedBy, isRenter) => {
  if (requestedBy === 'Resident' && isRenter === true) {
    return true;
  } else if (requestedBy === 'Homeowner' && !isRenter) {
    return true;
  }

  return false;
};

export const getStatusOfTask = (status) => {
  if (status === 'New') {
    return true;
  }

  return false;
};

export const validateAddress = (value) => {
  const { addressOutsideUs } = value;

  if (addressOutsideUs) {
    if (isEmpty(value.completeAddress)) {
      return 'Invalid';
    }
  } else if (isEmpty(value.city)) {
    return 'Invalid';
  } else if (isEmpty(value.streetAddress?.trim())) {
    return 'Invalid';
  }
};

export const requiredChecked = (value) => (value === true ? null : 'Must be checked');

// Since we allow error messages on DateSlector, return true to show error border, but no message.
export const requiredDateSelector = (values) => {
  if (!values?.some((value) => !!value?.times?.length)) {
    return true;
  }
};

// Pass the minimum number of days required to pass the validation.
export const dateSelectorAtLeastNDaysSelected = (n) => (values) => {
  const daysSelected = values?.filter((value) => !!value?.times?.length);
  if (daysSelected.length < n) {
    return `Please select at least ${n} different ${pluralizeWithoutValue(n, 'day')}`;
  }
};
