import { SCREENS } from '../../../containercomponents/Modals/LoginModal/login-modal.consts';
import { getMediaTypeFromFile } from '../../../utils/file';
import { uploadDocumentToS3 } from '../common/uploadDocumentToS3';
import { uploadMaintenanceMedia } from '../homeowner-accounts/actions';
import { getApiActionTypesValues } from '../redux_utils';
import { ACTIONS } from './constants';

export const fetchResidentPayments = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_PAYMENTS'),
    promise: ({ client }) => client.get('/residents/payments'),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchResidentPaymentWithId = (id) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_PAYMENT_BY_ID'),
    promise: ({ client }) => client.get(`/residents/payments/${id}`),
    auth: true,
    redirectToHomeOnHide: true,
    handleError: true,
    loginModalScreen: SCREENS.LOGIN_SCREEN,
  });

export const createPaymentByPaymentAccountId = (id, paymentObject) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('CREATE_PAYMENT_BY_PAYMENT_ACCOUNT_ID'),
    promise: ({ client }) => client.post(`/residents/payments/${id}`, paymentObject),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchResidentLeases =
  ({ includeAllResidents } = { includeAllResidents: false }) =>
  (dispatch) =>
    dispatch({
      types: getApiActionTypesValues('FETCH_LEASES'),
      promise: ({ client }) => client.get(`/residents/summary?includeAllResidents=${includeAllResidents}`),
      auth: true,
      redirectToHomeOnHide: true,
    });

export const fetchResidentBookmarks = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_BOOKMARKS'),
    promise: ({ client }) => client.get('/residents/tours-apps'),
    auth: true,
  });

export const postResidentBookmarks = (tourId) => async (dispatch) => {
  await dispatch({
    promise: ({ client }) =>
      client.post('/residents/tours', {
        tourId,
      }),
    auth: true,
    redirectToHomeOnHide: true,
  });

  await dispatch(fetchResidentBookmarks());
};

export const rescheduleResidentTour = (tourAttendeeId, newTourId) => async (dispatch) => {
  await dispatch({
    promise: ({ client }) =>
      client.post('/tours/reschedule', {
        tourAttendeeUniqueId: tourAttendeeId,
        newTourUniqueId: newTourId,
      }),
    auth: true,
    redirectToHomeOnHide: true,
  });
};

export const updateCancelResidentBookmarkById = (id) => async (dispatch) => {
  await dispatch({
    promise: ({ client }) => client.put(`/residents/tours/${id}/cancel`),
    auth: true,
  });

  await dispatch(fetchResidentBookmarks());
};

export const cancelPrivateTour = (tourUniqueId) => ({
  promise: ({ client }) => client.put(`/tours/cancel/private-tour/${tourUniqueId}`),
  auth: true,
});

// self tour

export const getSelfTourTimes = (homeUniqueId) => ({
  promise: ({ client }) => client.get(`/homes/${homeUniqueId}/tours/SelfTour`),
  auth: true,
});

export const postSelfTour = (tourInfo) => ({
  promise: ({ client }) => client.post(`/residents/tours/self-tour`, tourInfo),
  auth: true,
});

export const createTourCustomRequest = (request) => ({
  promise: ({ client }) => client.post(`/residents/tours/custom-request`, request),
  auth: true,
});

export const updateSelfTour = (tourUniqueId, tourInfo) => ({
  promise: ({ client }) => client.put(`/residents/tours/self-tour/${tourUniqueId}/reschedule`, tourInfo),
  auth: true,
});

export const cancelSelfTour = (tourUniqueId) => ({
  promise: ({ client }) => client.put(`/residents/tours/self-tour/${tourUniqueId}/cancel`),
  auth: true,
});

export const updatePaymentId = (tourUniqueId, paymentAccountId) => ({
  promise: ({ client }) => client.put(`/residents/tours/self-tour/${tourUniqueId}/payment`, { paymentAccountId }),
  auth: true,
});

export const updateTourInterest = (homeId, noLongerInMarket) => ({
  promise: ({ client }) =>
    client.put(`/residents/home/${homeId}/interest-level`, { noLongerInMarket, interestLevel: 'NotInterested' }),
  auth: true,
});

export const fetchResidentAccountDocuments = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RESIDENT_ACCOUNT_DOCUMENTS'),
    promise: ({ client }) => client.get('/users/documents'),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const getDocumentUrl = (documentId) => ({
  promise: ({ client }) => client.get(`/residents/accounts/documents/${documentId}/downloadurl`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const updateDocuments = (name, id, mediaType) => ({
  promise: ({ client }) =>
    client.post('/residents/accounts/documents', {
      name,
      id,
      mediaType,
    }),
  auth: true,
  redirectToHomeOnHide: true,
});

export const getDocumentUploadUrl = (mediaType) => ({
  promise: ({ client }) => client.get(`/residents/accounts/documents/uploadurl/${mediaType}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const uploadDocument = (file) => async (dispatch) => {
  const mediaType = getMediaTypeFromFile(file);
  const response = await dispatch(getDocumentUploadUrl(mediaType));
  const { headers, uploadUrl, uniqueId } = response;

  await uploadDocumentToS3(dispatch, file, headers, uploadUrl);

  await dispatch(updateDocuments(file.name, uniqueId, mediaType));

  await dispatch(fetchResidentAccountDocuments());
};

export const fetchCurrentUnitWithLeaseId = (unitId, leaseId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_CURRENT_UNIT_WITH_LEASE'),
    promise: ({ client }) => client.get(`/residents/units/${unitId}?leaseId=${leaseId}`),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const updateRenterMaintenanceTask = (maintenance, maintenanceId) => async (dispatch) => {
  const maintenanceDetails = {
    ...maintenance,
  };

  await dispatch({
    types: getApiActionTypesValues('UPDATE_MAINTENANCE_TASK'),
    promise: ({ client }) => client.put(`/residents/maintenance/${maintenanceId}`, maintenanceDetails),
    auth: true,
    redirectToHomeOnHide: true,
  });
};

export const createResidentMediaToMaintenanceTask = (maintenanceId, media) => ({
  types: getApiActionTypesValues('CREATE_RESIDENT_MEDIA_TO_MAINTENANCE_TASK'),
  promise: ({ client }) => client.post(`/residents/maintenance/${maintenanceId}/media`, media),
  auth: true,
});

export const createRenterMaintenanceTask = (maintenance) => async (dispatch) => {
  const maintenanceDetails = {
    ...maintenance,
  };

  const newMaintenance = await dispatch({
    types: getApiActionTypesValues('CREATE_MAINTENANCE_TASK'),
    promise: ({ client }) => client.post('/residents/maintenance', maintenanceDetails),
    auth: true,
  });

  if (maintenance.attachments?.availableMedia?.length) {
    const uploadPromises = [];
    maintenance.attachments.availableMedia.forEach(async (file) => {
      uploadPromises.push(dispatch(uploadMaintenanceMedia(file)));
    });
    const attachments = await Promise.all(uploadPromises);

    const createPromises = [];
    attachments.forEach(async (media) => {
      createPromises.push(dispatch(createResidentMediaToMaintenanceTask(newMaintenance.uniqueId, media)));
    });
    await Promise.all(createPromises);
  }
};

export const updateResidentPaymentAgreement = (taskId, updatedObject) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_RESIDENT_PAYMENT_AGREEMENT'),
    promise: ({ client }) => client.put(`/residents/maintenance/${taskId}/consent`, updatedObject),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const deleteResidentMediaToMaintenanceTask = (media, maintenanceId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('DELETE_RESIDENT_MEDIA_TO_MAINTENANCE_TASK'),
    promise: ({ client }) => client.delete(`/residents/maintenance/${maintenanceId}/media`, { data: media }),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const renterCurrentActionClear = () => ({
  type: ACTIONS.CURRENT_TASK_CLEAR,
});

export const fetchFavoriteHomes = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_FAVORITE_HOMES'),
    promise: ({ client }) => client.get(`/residents/favorites`),
    auth: true,
  });

export const updateFavoriteHome = (homeId, isFavorite) => async (dispatch) => {
  try {
    await dispatch({
      promise: ({ client }) => client.put(`/residents/${homeId}/favorite`, { isFavorite }),
      auth: true,
    });

    await dispatch(fetchFavoriteHomes());
    // used to toggle the animations
    return true;
  } catch (e) {
    console.warn(e);
  }
};

export const fetchFavoriteAccountsHomes = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_FAVORITE_ACCOUNT_HOMES'),
    promise: ({ client }) => client.get(`/residents/favorites`),
    auth: true,
  });

export const fetchResidentFlows = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RESIDENT_FLOWS'),
    promise: ({ client }) => client.get(`/residents/flows`),
    auth: true,
  });

export const fetchResidentOccupiedTours = (leaseUniqueId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RESIDENT_OCCUPIED_TOURS'),
    promise: ({ client }) => client.get(`/residents/tours/occupied/${leaseUniqueId}`),
    auth: true,
  });

export const cancelResidentOccupiedTours = (tourUniqueId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('CANCEL_RESIDENT_OCCUPIED_TOURS'),
    promise: ({ client }) => client.put(`/residents/tours/${tourUniqueId}/cancel-occupied`),
    auth: true,
  });

export const fetchTourRequests = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_TOUR_REQUESTS'),
    promise: async ({ client }) => {
      const tourRequests = await client.get(`/tour-requests`);

      const tourRequestsWithHomeDetails = await Promise.all(
        tourRequests.map(async (tourRequest) => ({
          homeDetails: await client.get(`/homes/${tourRequest.homeUniqueId}`),
          ...tourRequest,
        }))
      );

      return tourRequestsWithHomeDetails;
    },
    auth: true,
  });
