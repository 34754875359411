import { ASSETS_LOCATION } from './consts.js';

// TODO: CACHE INVALIDATE OR SESSION CACHE?
export const animationsCache = {
  __cache: {},
  async preload(animationName) {
    const animationSrc = ASSETS_LOCATION[animationName];
    if (typeof animationSrc === 'string') {
      const _animation = await fetch(animationSrc).then((res) => res.json());
      this.__cache[animationName] = _animation;
    } else {
      const [_animation, _mobileAnimation] = await Promise.all([
        fetch(animationSrc.DESKTOP).then((res) => res.json()),
        fetch(animationSrc.MOBILE).then((res) => res.json()),
      ]);
      this.__cache[animationName] = {
        DESKTOP: _animation,
        MOBILE: _mobileAnimation,
      };
    }
  },
  async read(animationName) {
    if (!this.__cache[animationName]) {
      await this.preload(animationName);
    }
    return this.__cache[animationName];
  },
};
