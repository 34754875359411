import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSettings } from 'store/redux/settings';

type UseFastMaintenanceSettingsResult = {
  isEnabled: boolean;
};

export function useFastMaintenanceSettings(): UseFastMaintenanceSettingsResult {
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    async function getSettings() {
      try {
        const { commerceSettings } = await dispatch(fetchSettings());
        const isDailyBillingEnabled = commerceSettings?.featureFlagsSettings?.isDailyBillingEnabled;
        setIsEnabled(isDailyBillingEnabled);
      } catch (err) {
        console.error(err);
      }
    }

    getSettings();
  }, [dispatch]);

  return {
    isEnabled,
  };
}
