import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AccessInstructionsModel } from 'api/models';
import { fetchHomeAccessInstructions } from 'store/redux/homeowner-accounts/actions';
import { selectHomeAccessInstructions } from 'store/redux/homeowner-accounts/selectors';

type UseHomeAccessInstructionsParams = {
  unitId: string;
};

export function useHomeAccessInstructions({ unitId }: UseHomeAccessInstructionsParams) {
  const [loading, setLoading] = useState(true);
  const homeAccessInstructions = useSelector((state) =>
    selectHomeAccessInstructions(state, unitId)
  ) as AccessInstructionsModel;
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);

        if (unitId) {
          await dispatch(fetchHomeAccessInstructions(unitId));
        }
      } finally {
        setLoading(false);
      }
    }

    if (unitId) {
      init();
    } else {
      setLoading(false);
    }
  }, [dispatch, unitId]);

  return {
    homeAccessInstructions,
    loading,
  };
}
