import { useState, useCallback } from 'react';
import { useEventListener } from 'hooks/useEventListener';
import { useDeferRender } from 'hooks/useDeferRender';

import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';

export const useShowScrollBorder = ({ enable = true, threshold = 1, mobileThreshold = 1, initialMode = false }) => {
  const [showScrollBorder, setShowScrollBorder] = useState(initialMode);
  const isClientReady = useDeferRender();

  const handleScroll = useCallback(() => {
    if (!enable) return;

    const currentThreshold = window.innerWidth <= BREAKPOINTS_WIDTHS.SM ? mobileThreshold : threshold;
    if (Array.isArray(currentThreshold)) {
      // min max
      if (window.scrollY > currentThreshold[0] && window.scrollY < currentThreshold[1]) {
        return !showScrollBorder && setShowScrollBorder(true);
      } else {
        return showScrollBorder && setShowScrollBorder(false);
      }
    } else if (window.scrollY > currentThreshold) {
      return !showScrollBorder && setShowScrollBorder(true);
    } else {
      return showScrollBorder && setShowScrollBorder(false);
    }
  }, [showScrollBorder, threshold, mobileThreshold]);

  useEventListener('scroll', handleScroll);

  return isClientReady
    ? [showScrollBorder, setShowScrollBorder, window.scrollY > 1]
    : [showScrollBorder, setShowScrollBorder, true];
};
