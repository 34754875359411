import { ACTIONS as USER_ACTIONS } from '../user/constants';
import { ACTIONS } from './constants';

export const RENTAL_ACCOUNT_REDUCER = 'renterAccounts';

const initialState = {
  units: {},
  leases: [],
  documents: [],
  bookmarks: null,
  payments: [],
  paymentDetails: {},
  favoriteHomes: [],
  favoriteAccountHomes: [],
  flows: [],
  occupiedTours: [],
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_RESIDENT_ACCOUNT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: [...action.result],
      };
    case ACTIONS.FETCH_LEASES_SUCCESS:
      return {
        ...state,
        leases: [...action.result.leases],
      };
    case ACTIONS.FETCH_CURRENT_UNIT_WITH_LEASE_SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          [action.result.basicInfo.unitId]: action.result,
        },
      };
    case ACTIONS.FETCH_BOOKMARKS_SUCCESS:
      return {
        ...state,
        bookmarks: [...(action.result.bookmarks || [])],
      };
    case ACTIONS.FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: [...action.result.payments],
      };
    case ACTIONS.FETCH_PAYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          [action.result.paymentInfo.paymentId]: action.result,
        },
      };
    case USER_ACTIONS.USER_DATA_CLEAR:
      return {
        ...initialState,
      };
    case ACTIONS.FETCH_TASK_BY_RENTER_ID_SUCCESS:
      return {
        ...state,
        currentTask: action.result,
      };
    case ACTIONS.FETCH_FAVORITE_HOMES_SUCCESS:
      return {
        ...state,
        favoriteHomes: action.result,
      };
    case ACTIONS.FETCH_FAVORITE_ACCOUNT_HOMES_SUCCESS:
      return {
        ...state,
        favoriteAccountHomes: action.result,
      };
    case ACTIONS.FETCH_RESIDENT_FLOWS_SUCCESS:
      return {
        ...state,
        flows: action.result,
      };
    case ACTIONS.FETCH_RESIDENT_OCCUPIED_TOURS_SUCCESS:
      return {
        ...state,
        occupiedTours: action.result,
      };
    case ACTIONS.FETCH_TOUR_REQUESTS_SUCCESS:
      return {
        ...state,
        tourRequests: action.result,
      };
    default:
      return state;
  }
}
