import { AddressModel, BillInfoDetailModel, BillInfoModel } from 'api/models';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import { BillItem, BillItemProps } from './bill-item/bill-item';

type BillProps = Partial<BillInfoDetailModel> & {
  onClick: () => void;
  label: string;
  showAddress: boolean;
  pending?: boolean;
};
type Addresses = Record<string, AddressModel>;

export function Bill({ items, label, billInfo, pending = false, showAddress, onClick }: BillProps) {
  const addresses = items?.reduce((acc, curr) => {
    if (curr.address && !acc[curr.address?.addressId]) {
      acc[curr.address.addressId] = curr.address;
    }

    return acc;
  }, {} as Addresses);

  const getAddressToDisplay = getHouseValue(addresses);
  const paymentListObject = getPaymentListTileModel(getAddressToDisplay, billInfo, pending);

  return <BillItem {...paymentListObject} label={label} showAddress={showAddress} onClick={onClick} />;
}

function getHouseValue(addresses: Addresses) {
  const value = Object.values(addresses);

  if (value.length === 0) {
    return '';
  } else if (value.length === 1) {
    return value[0];
  }

  return `${value.length} Homes`;
}

function getPaymentListTileModel(getAddressToDisplay: AddressModel | string, bill: BillInfoModel, pending: boolean) {
  const descriptionObject: BillItemProps = {
    address: getAddressToDisplay,
    lastUpdateDate: (
      <BDate time={pending ? bill.dueDate : bill.lastUpdatedOn} formatType={DATE_TYPES.FULL_MONTH_DAY_YEAR} />
    ),
    amount: bill.total,
    type: bill.type,
    status: bill.status,
    description: bill.description,
    remainingAmount: bill.remainingAmount,
    paidAmount: bill.paidAmount,
  } as BillItemProps;

  return descriptionObject;
}
