import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllFlows } from 'store/redux/user/actions';

export function useFlows() {
  const dispatch = useDispatch();
  const [flows, setFlows] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const fetchFlows = useCallback(async () => {
    setLoading(true);

      const flowsResponse = await dispatch(fetchAllFlows());

      setFlows(flowsResponse);
      setLoading(false);
  }, [dispatch])

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  return { flows, fetchFlows, loading };
}
