export default class Media {
  constructor(data = {}) {
    this.mediaType = data.mediaType;
    this.mediaId = data.mediaId;
    this.name = data.name;
    this.url = data.url;
    this.sortOrder = data.sortOrder;
    this.ownerId = data.ownerId;
    this.ownerType = data.ownerType;
  }
}
