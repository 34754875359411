import { useCallback, useEffect, useState } from 'react';
import scriptLoader from 'react-async-script-loader';
import { connect, useDispatch } from 'react-redux';
import { ButtonSelector, Image } from '@belong/ui';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import Spinner from 'components/Spinner/Spinner';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import arrayMutators from 'final-form-arrays';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { find } from 'lodash-es';
import { ApplicantType } from 'models/enums/index';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  fetchAllApplicationsForUser,
  fetchApplicantInfo,
  fetchEmploymentInformation,
  fetchIncomeVerification,
  updateIncomeVerification,
} from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationApplicantInfo,
  selectResidentApplicationApplicantType,
  selectResidentApplicationBasicInfo,
  selectResidentApplicationEmploymentInformation,
  selectResidentApplicationId,
  selectResidentApplicationIncomeDocuments,
  selectResidentApplicationIncomeVerification,
  selectResidentApplicationsForUser,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import {
  addAchInstantPaymentMethod,
  createUserIncomeLink,
  createVerification,
  getAllowRetryIncome,
  updateUserIncome,
} from 'store/redux/user/actions';
import { getString } from 'strings';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { MONEY_TYPES } from '../../../../components/Money/moneyTypes';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './IncomeVerification.module.css';
import { usePlaidIncomeVerification, useUserIncomes } from './hooks/income-verification';
import { convertKeysToPascalCase } from './utils/snakeToPascal';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.income_verification;
const { FormLayoutHeader } = FormLayout;

let handler;

const reportStatus = ['ReportReadyForPulling', 'ReportPulled', 'FlowComplete'];
function IncomeVerification({
  fetchIncomeVerification: fetchIncomeVerificationAction,
  fetchApplicantInfo: fetchApplicantInfoAction,
  fetchAllApplicationsForUser: fetchAllApplicationsForUserAction,
  fetchEmploymentInformation: fetchEmploymentInformationAction,
  incomeVerification: { incomeSources },
  history: { push },
  houseId,
  applicationId,
  applicantInfo,
  applicantInfo: { reportedAnnualIncome },
}) {
  const dispatch = useDispatch();

  const [userCanRetry, setUserCanRetry] = useState(false);
  const [loadingPlaid, setLoadingPlaid] = useState(false);
  const [skipStep, setSkipStep] = useState(false);

  const { userIncomes, isRetrievingUserIncomes, refetch } = useUserIncomes();
  const incomeInformation = usePlaidIncomeVerification(userIncomes);

  async function handleSubmit() {
    const { applicantType } = applicantInfo;

    if (skipStep || !userCanRetry) {
      await dispatch(createVerification('Income/PlaidAssets'));
    }

    if (applicantType === ApplicantType.CoSigner) {
      push(getResidentApplicationStepPath(STEPS_CONFIG.BACKGROUND_CHECK, { houseId, applicationId }));
    } else {
      push(getResidentApplicationStepPath(STEPS_CONFIG.REFERENCE_CHECK, { houseId, applicationId }));
    }
  }

  async function allowRetryIncome() {
    try {
      const canRetry = await dispatch(getAllowRetryIncome());
      setUserCanRetry(canRetry);
    } catch (error) {
      console.error(error);
    }
  }

  async function initData() {
    try {
      await Promise.all([
        fetchEmploymentInformationAction(),
        fetchIncomeVerificationAction(),
        fetchApplicantInfoAction(),
        fetchAllApplicationsForUserAction(),
        allowRetryIncome(),
      ]);
    } catch (err) {
      console.error(err);
    }
  }

  async function updateIncome(eventData, metadata, linkTokenConfig, isOnSuccess = true) {
    const events = eventData.map((event) => {
      const eventMeta = event.metadata;

      return {
        eventName: event.event_name,
        metadata: {
          RequesterId: eventMeta.request_id,
          LinkSessionId: eventMeta.link_session_id,
          ViewName: eventMeta.view_name,
          Timestamp: eventMeta.timestamp,
        },
      };
    });

    await dispatch(
      updateUserIncome(linkTokenConfig.uniqueId, {
        details: convertKeysToPascalCase(metadata),
        plaidEvents: events,
        flowComplete: isOnSuccess,
        inactive: !isOnSuccess,
      })
    );
  }

  const onLinkExit = useCallback(async (eventData, metadata, linkTokenConfig) => {
    await updateIncome(eventData, metadata, linkTokenConfig, false);
    handler.exit();
    setLoadingPlaid(false);
    allowRetryIncome();
  }, []);

  const openLinkWithToken = useCallback(
    (linkTokenConfig, linkTokenType) => {
      const eventData = [];

      setLoadingPlaid(true);
      handler = window.Plaid.create({
        token: linkTokenConfig.linkToken,
        onSuccess: async (link, metadata) => {
          if (linkTokenType === 'Bank') {
            const paymentMethodData = [
              {
                accountId: metadata.account_id,
                institution: metadata.institution.name,
              },
            ];
            dispatch(addAchInstantPaymentMethod(link, paymentMethodData, true, false));
          }

          await updateIncome(eventData, metadata, linkTokenConfig);
          // The backend sometimes takes a couple seconds to update the income status
          // If we refetch right away, the status can be the same as before, which leaves the verification step incomplete
          // And asks to re-enter the verification data, leading to weird behavior
          setTimeout(() => {
            refetch();
          }, 2500);
          setLoadingPlaid(false);
          initData();
        },
        onEvent: (event_name, metadata) => {
          eventData.push({ event_name, metadata });
        },
        onExit: (_err, metadata) => onLinkExit(eventData, metadata, linkTokenConfig),
      });

      handler.open();
    },
    [addAchInstantPaymentMethod, onLinkExit, updateUserIncome]
  );

  async function handleIncomeVerificationSelection(type) {
    setLoadingPlaid(true);
    try {
      if (type === 'payroll') {
        const linkTokenConfig = await dispatch(
          createUserIncomeLink({
            linkTokenType: 'Payroll',
            enableDocumentUpload: true,
          })
        );

        if (linkTokenConfig) {
          openLinkWithToken(linkTokenConfig, 'Payroll');
        }
      } else {
        const linkTokenConfig = await dispatch(
          createUserIncomeLink({
            linkTokenType: 'Bank',
          })
        );

        if (linkTokenConfig) {
          openLinkWithToken(linkTokenConfig, 'Bank');
        }
      }
    } catch (error) {
      setLoadingPlaid(false);
      // Set maximum tries as default
      console.error(error);
    }
  }

  useEffect(() => {
    initData();
  }, []);

  if (loadingPlaid) {
    return <div className="absolute overflow-hidden h-screen w-screen bg-white z-fullscreen -top-3xl" />;
  }

  const isLoading = isRetrievingUserIncomes || incomeInformation?.isLoading;

  const hasReachedMaximumTries = !userCanRetry;

  if (isLoading) {
    return (
      <Row>
        <Col md={12}>
          <div className={cx('spinner')}>
            <Spinner />
          </div>
        </Col>
      </Row>
    );
  }

  function isPlaidConnected() {
    if (typeof incomeInformation !== 'undefined') {
      return true;
    }
    if (userIncomes && userIncomes.linkTokenType === 'Bank' && reportStatus.includes(userIncomes.status)) {
      return true;
    }
    if (userIncomes && userIncomes.linkTokenType === 'Payroll' && reportStatus.includes(userIncomes.status)) {
      return true;
    }
    return false;
  }

  function isDisabled() {
    if (hasReachedMaximumTries) {
      return false;
    }

    if (skipStep) {
      return false;
    }
    if (isPlaidConnected()) {
      return false;
    }
    return true;
  }

  function showSelector() {
    if (hasReachedMaximumTries) return false;

    if (isPlaidConnected()) {
      return false;
    }
    return true;
  }

  return (
    <div>
      <FormLayout>
        <FormLayoutHeader
          title={getString(RAS.title_income, {
            income: numeral(reportedAnnualIncome).format(MONEY_TYPES.DOLLARS),
          })}
        />
        <Form
          initialValues={{}}
          onSubmit={handleSubmit}
          mutators={{
            ...arrayMutators,
          }}
          getFormBottomBar={(_, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: RAS.next_button_text,
                disabled: isDisabled(),
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          getForm={({ handleSubmit: handleFormSubmit }) => {
            const hasSelectedIncomeSource = !!find(incomeSources, { selected: true });
            return (
              <div className={cx('form-wrapper')}>
                <form onSubmit={handleFormSubmit}>
                  <Text className="text-dark-gray">{RAS.subTitle_income}</Text>
                  <Space value={SPACE_TYPES.XS} />

                  <div className="mt-xl lg:mt-2xl">
                    <ButtonSelector
                      itemContainerClassName="mb-2sm lg:max-w-[250px]"
                      itemHeight="extra-large"
                      options={[
                        {
                          value: 'payroll',
                          disabled: !showSelector(),
                          content: (
                            <div className="relative flex items-center md:justify-end justify-start h-full px-sm w-full lg:flex-col">
                              <div
                                className={clsx(
                                  'rounded absolute -top-sm p-2xs right-sm md:right-0',
                                  !showSelector() ? 'bg-lighter-green' : 'bg-green',
                                  styles.bestResultsTag
                                )}
                                style={{
                                  width: 100,
                                }}
                              >
                                <Text fontSize="p1" fontWeight="semibold" className="text-white">
                                  Best Results
                                </Text>
                              </div>
                              <div
                                className={clsx(
                                  'flex items-center md:justify-center h-full lg:flex-col relative gap-sm',
                                  !showSelector() && 'opacity-50'
                                )}
                              >
                                <div style={{ height: 72 }}>
                                  <Image className="object-cover" src="/residents/income_upload_or_connect.svg" />
                                </div>
                                <Text>
                                  Connect Or <br className="hidden lg:flex" /> Upload Payroll
                                </Text>
                              </div>
                            </div>
                          ),
                        },
                        {
                          value: 'bankAccount',
                          disabled: !showSelector(),
                          content: (
                            <div
                              className={clsx(
                                'flex items-center md:justify-center h-full lg:flex-col relative w-full px-sm gap-sm',
                                !showSelector() && 'opacity-50'
                              )}
                            >
                              <div style={{ height: 72 }}>
                                <Image src="/residents/income_bank_account.svg" />
                              </div>

                              <Text>
                                Connect Bank <br className="hidden lg:flex" /> Account
                              </Text>
                            </div>
                          ),
                        },
                      ]}
                      onChange={handleIncomeVerificationSelection}
                      itemWidth="fluid"
                      containerClassName="grid md:grid-cols-2 md:flex gap-xs md:gap-auto"
                    />

                    {hasReachedMaximumTries && (
                      <div
                        className="mt-xl flex flex-col border border-solid border-red rounded p-sm"
                        style={{
                          backgroundColor: 'rgba(255, 86, 97, 0.02)',
                        }}
                      >
                        <Text>{RAS.failed_attempts}</Text>
                      </div>
                    )}
                    {showSelector() && (
                      <>
                        <div className="mt-sm flex justify-end lg:justify-start">
                          <Field
                            key="hasNoIncomeSource"
                            name="hasNoIncomeSource"
                            disabled={hasSelectedIncomeSource}
                            component={CheckboxFinalFormAdapter}
                            onChangeCustom={() => setSkipStep((prev) => !prev)}
                            onClickCustom={() => setSkipStep((prev) => !prev)}
                            alignWithField={false}
                            label={RAS.skip}
                          />
                        </div>
                      </>
                    )}

                    {skipStep && (
                      <div className="mt-xl flex flex-col border border-solid border-red rounded p-sm bg-red-translucent-light">
                        <Text fontWeight="semibold">{RAS.skip_title}</Text>
                        <Text>{RAS.skip_content}</Text>
                      </div>
                    )}

                    {userIncomes &&
                      userIncomes.linkTokenType === 'Payroll' &&
                      reportStatus.includes(userIncomes.status) && (
                        <div className="flex mt-2xl justify-between">
                          <div>
                            <Text>Payroll</Text>
                          </div>
                          <div>
                            <Text className="text-green" fontWeight="semibold">
                              Added
                            </Text>
                          </div>
                        </div>
                      )}

                    {userIncomes &&
                      userIncomes.linkTokenType === 'Bank' &&
                      reportStatus.includes(userIncomes.status) && (
                        <div className="flex mt-2xl justify-between">
                          <div>
                            <Text>Bank</Text>
                          </div>
                          <div>
                            <Text className="text-green" fontWeight="semibold">
                              Added
                            </Text>
                          </div>
                        </div>
                      )}

                    {incomeInformation && (
                      <>
                        {incomeInformation?.accounts?.map((source, index) => (
                          <div className="flex mt-2xl justify-between" key={index}>
                            <div>
                              <Text>{source?.incomeSourceName}</Text>
                            </div>
                            <div>
                              <Text className="text-green" fontWeight="semibold">
                                Added
                              </Text>
                            </div>
                          </div>
                        ))}
                        {incomeInformation?.accounts.length === 0 &&
                          incomeInformation.passed === 'Passed' &&
                          incomeInformation.payroll.length > 0 && (
                            <div className="flex mt-2xl justify-between">
                              <div>
                                <Text>Payroll</Text>
                              </div>
                              <div>
                                <Text className="text-green" fontWeight="semibold">
                                  Added
                                </Text>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            );
          }}
        />
      </FormLayout>
    </div>
  );
}

IncomeVerification.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  applicantInfo: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  houseId: PropTypes.string.isRequired,
  incomeVerification: PropTypes.object,
  fetchIncomeVerification: PropTypes.func.isRequired,
  applicantType: PropTypes.string.isRequired,
  fetchApplicantInfo: PropTypes.func.isRequired,
  fetchAllApplicationsForUser: PropTypes.func.isRequired,
  fetchEmploymentInformation: PropTypes.func.isRequired,
};

IncomeVerification.defaultProps = {
  incomeVerification: {},
};

export default scriptLoader(['https://cdn.plaid.com/link/v2/stable/link-initialize.js'])(
  connect(
    (state) => ({
      incomeVerification: selectResidentApplicationIncomeVerification(state),
      files: selectResidentApplicationIncomeDocuments(state),
      employmentInformation: selectResidentApplicationEmploymentInformation(state),
      applicantInfo: selectResidentApplicationApplicantInfo(state),
      applicationId: selectResidentApplicationId(state),
      houseId: selectResidentApplyForHouseId(state),
      basicInfo: selectResidentApplicationBasicInfo(state),
      existingApplications: selectResidentApplicationsForUser(state),
      applicantType: selectResidentApplicationApplicantType(state),
    }),
    {
      fetchIncomeVerification,
      updateIncomeVerification,
      fetchApplicantInfo,
      fetchAllApplicationsForUser,
      fetchEmploymentInformation,
    }
  )(IncomeVerification)
);
