import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { DATE_FORMATS, formatDateTime } from '@belong/common';
import { Button, Text, Money } from '@belong/ui';
import cn from 'classnames';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { Tag } from 'components/HomeTag/HomeTag';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import TabBar from 'components/TabBar/TabBar';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { fetchFinancePlan, fetchFinancePlanPayments } from 'store/redux/accounts/actions';
import {
  selectBalancesByMonth,
  selectFinancePlanDetails,
  selectFinancePlanPayments,
} from 'store/redux/accounts/selectors';
import { selectSettings } from 'store/redux/settings';
import { EARNINGS_STRINGS } from '../earnings.strings';

const items = (hasFailed = false, hasPayments = false) => [
  {
    text: 'Items',
    key: 1,
  },
  {
    text: 'Terms',
    key: 2,
  },
  hasPayments
    ? {
        component: (
          <p className="flex">
            Payments {hasFailed && <GeneralIcon icon={GENERAL_ICONS.WARNING} className="ml-2xs mt-[2px]" />}
          </p>
        ),
        key: 3,
      }
    : {},
];

function FinanceDetailsModal({
  id,
  onHide,
  fetchFinancePlan: fetchFinancePlanAction,
  fetchFinancePlanPayments: fetchFinancePlanPaymentsAction,
  financePlan,
  financePlanPayments,
  handleUngroupModal,
  financeUniqueId,
  handleShowBalanceDetailsModal,
  home,
  ...rest
}) {
  const [financeDetailsLoaded, setFinanceDetailsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);

  const settings = useSelector(selectSettings);
  const interestRateValue = settings?.commerceSettings?.splitItSettings?.interestRateValue;

  function handleHide() {
    if (onHide) onHide();
    setFinanceDetailsLoaded(false);
  }

  function navigateUngroupModal(uniqueId) {
    handleUngroupModal(uniqueId);
  }

  useEffect(() => {
    const getFinancePlan = async () => {
      setLoading(true);
      try {
        await fetchFinancePlanAction(financeUniqueId);
        await fetchFinancePlanPaymentsAction(financeUniqueId);
        setFinanceDetailsLoaded(true);
        setLoading(false);
      } catch (err) {
        console.error(err);
        if (onHide) onHide();
        setLoading(false);
      }
    };

    if (rest.show && !financeDetailsLoaded && !loading) getFinancePlan();
  }, [rest]);

  if (Object.keys(financePlan).length === 0) return null;

  const monthlyInterestAmount = financePlan?.finance.monthlyInterestAmount;
  const remainingAmount = financePlan?.finance.principalRemainingBalance;
  const principalAmount = financePlan?.finance.principalAmount;
  const status = financePlan?.finance.status;
  const bills = financePlanPayments?.bills || [];

  const failedBils = bills.findIndex((bill) => bill.status === 'Failed');

  return (
    <ModalV2 {...rest} noPadding showSpinner={loading} onHide={handleHide} className="">
      <ModalLayout fixedBottom>
        <ModalLayout.Content className="p-0">
          <ModalLayout.Section>
            <ModalLayout.Title>{financePlan?.summary.description}</ModalLayout.Title>
            <ModalLayout.SubTitle>
              <Text fontWeight="semibold" className="text-navy">
                <Money
                  fontWeight="semibold"
                  format="DOLLARS_WITH_CENTS"
                  className="inline-flex"
                  value={financePlan?.finance?.monthlyAmount + financePlan?.finance?.monthlyInterestAmount}
                />
                /mo.
              </Text>
              <div className="flex justify-center mt-2sm">
                <Tag relative className={cn(status === 'Completed' ? 'bg-green' : 'bg-[#1890FF]')}>
                  {status}
                </Tag>
              </div>
            </ModalLayout.SubTitle>
            <div className="mt-xl">
              <TabBar
                name="financeDetailsTab"
                // Show only first item if no lease.
                items={items(failedBils > -1, financePlanPayments?.totalPaidCount > 0)}
                onSelect={(e) => setSelectedTab(e.key)}
                selected={selectedTab}
              />
              <div className="flex p-0 md:p-lg">
                {selectedTab === 1 && (
                  <table className="w-full mt-xl md:mt-sm mb-2xl md:mb-xs">
                    <thead>
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text className="text-[14px] md:text-h3-sm" fontWeight="semibold">
                              Item
                            </Text>
                          </div>
                        </th>
                        <th>
                          <div className="text-right">
                            <Text fontWeight="semibold" className="text-[14px] md:text-body">
                              Opening
                            </Text>
                          </div>
                        </th>
                        <th>
                          <div className="text-right">
                            <Text fontWeight="semibold" className="text-[14px] md:text-body">
                              Remaining
                            </Text>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="h-2sm" colSpan="2" />
                      {financePlan?.items?.map((item, index) => (
                        <tr
                          key={`${item.sourceKey}-${index}`}
                          onClick={() => {
                            handleShowBalanceDetailsModal(item, home, null, true);
                          }}
                        >
                          <th className="cursor-pointer">
                            <div className="text-left mt-xs flex">
                              <Text className="text-[14px] md:text-body">{item.description}</Text>
                              <svg
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-2sm relative top-[4px]"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14 6.49793C13.991 6.26565 13.8983 5.99629 13.7423 5.82775L8.65158 0.302087C8.30686 -0.0138488 7.64503 -0.144391 7.25921 0.220882C6.87969 0.582171 6.89109 1.28146 7.26881 1.64275L10.8489 5.52254L0.955254 5.52224C0.428124 5.52254 0.000301023 5.95952 5.68429e-07 6.49793C0.000300929 7.03634 0.428124 7.47332 0.955254 7.47362L10.8489 7.47332L7.26881 11.3531C6.94299 11.6859 6.88328 12.41 7.2592 12.775C7.63543 13.1403 8.31976 13.0229 8.65158 12.6938L13.7423 7.16811C13.916 6.97904 14.0015 6.75748 14 6.49793Z"
                                  fill="#325D77"
                                />
                              </svg>
                            </div>
                          </th>
                          <td>
                            <div className="text-right">
                              <Money
                                format="DOLLARS_WITH_CENTS"
                                className="text-[14px] md:text-body"
                                value={item.opening}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="text-right">
                              <Money
                                format="DOLLARS_WITH_CENTS"
                                className="text-[14px] md:text-body"
                                fontWeight="semibold"
                                value={item.remaining}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}

                      <tr className="h-2sm border-0 border-b border-gray border-dashed" colSpan="2" />
                      <tr className="h-2sm " colSpan="2" />
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text fontWeight="semibold" className="text-[14px] md:text-body">
                              Total
                            </Text>
                          </div>
                        </th>
                        <td>
                          <div className="text-right">
                            <Money
                              format="DOLLARS_WITH_CENTS"
                              className="text-[14px] md:text-body"
                              fontWeight="semibold"
                              value={principalAmount}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="text-right">
                            <Money
                              format="DOLLARS_WITH_CENTS"
                              className="text-[14px] md:text-body"
                              fontWeight="semibold"
                              value={remainingAmount}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {selectedTab === 2 && (
                  <section className="w-full mt-xl md:mt-sm mb-2xl md:mb-xs">
                    <article className="flex flex-col md:flex-row">
                      <div className="md:flex-1">
                        <div className="mb-xs">
                          <Text as="span" className="mr-2xs">
                            {EARNINGS_STRINGS['plan_starting.label']} :
                          </Text>
                          <Text as="span" fontWeight="semibold" className="ml-auto md:ml-0">
                            {formatDateTime({
                              dateTime: financePlan?.finance?.startDate,
                              format: DATE_FORMATS.DAY_MONTH_YEAR,
                            })}
                          </Text>
                        </div>
                        <div className="flex mb-xs">
                          <Text as="span" className="mr-2xs">
                            {EARNINGS_STRINGS['amount_financed.label']} :
                          </Text>
                          <Money
                            format="DOLLARS_WITH_CENTS"
                            fontWeight="semibold"
                            value={financePlan?.finance?.principalAmount}
                            className="ml-auto md:ml-0"
                          />
                        </div>
                        <div className="flex">
                          <Text as="span" className="mr-2xs">
                            {EARNINGS_STRINGS['number_of_installments.label']} :
                          </Text>
                          <Text as="span" fontWeight="semibold" className="ml-auto md:ml-0">
                            {financePlan?.finance?.installments}
                          </Text>
                        </div>
                      </div>
                      <div className="mt-xs md:mt-0 md:flex-1 md:pl-md md:border-dashed md:border-gray md:border-l-[1px] md:border-0">
                        <div className="flex mb-xs">
                          <Text as="span" className="mr-2xs">
                            {financePlan?.finance?.interestRateType} :
                          </Text>
                          <Text as="span" fontWeight="semibold" className="ml-auto md:ml-0">
                            {financePlan?.finance?.interestRateValue}%
                          </Text>
                        </div>
                        <div className="flex mb-xs">
                          <Text as="span" className="mr-2xs">
                            {EARNINGS_STRINGS['monthly_convenience_fee.label']} :
                          </Text>
                          <Money
                            format="DOLLARS_WITH_CENTS"
                            fontWeight="semibold"
                            value={financePlan?.finance?.monthlyInterestAmount}
                            className="ml-auto md:ml-0"
                          />
                        </div>
                        <div className="flex flex-wrap">
                          <Text as="span" className="mr-2xs">
                            {EARNINGS_STRINGS['total_monthly_payments.label']} :
                          </Text>
                          <div className="flex ml-auto md:ml-0">
                            <Money
                              format="DOLLARS_WITH_CENTS"
                              fontWeight="semibold"
                              value={financePlan?.finance?.monthlyAmount + financePlan?.finance?.monthlyInterestAmount}
                            />
                            <Text as="span" fontWeight="semibold">
                              /mo.
                            </Text>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="w-full text-right mt-xl">
                      <Button
                        variant="text"
                        buttonType="primary"
                        onClick={() => window.open(financePlan?.finance.downloadUrl, '_blank')}
                      >
                        {EARNINGS_STRINGS['view_truth_lending_disclosures.label']}
                      </Button>
                    </article>
                  </section>
                )}
                {selectedTab === 3 && (
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text variant="h3" fontWeight="semibold">
                              Payments
                            </Text>
                          </div>
                        </th>

                        <th>
                          <div className="text-right">
                            <Text fontWeight="semibold">
                              {financePlanPayments?.totalPaidCount} of {financePlanPayments?.totalInstallments} Paid
                            </Text>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="h-sm" colSpan="2" />
                      {bills.map((bill) => {
                        return (
                          <tr key={bill.id}>
                            <th>
                              <div className="text-left mt-xs flex">
                                <Text>{bill.description}</Text>
                                <svg
                                  width="14"
                                  height="13"
                                  viewBox="0 0 14 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ml-2sm relative top-[4px]"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14 6.49793C13.991 6.26565 13.8983 5.99629 13.7423 5.82775L8.65158 0.302087C8.30686 -0.0138488 7.64503 -0.144391 7.25921 0.220882C6.87969 0.582171 6.89109 1.28146 7.26881 1.64275L10.8489 5.52254L0.955254 5.52224C0.428124 5.52254 0.000301023 5.95952 5.68429e-07 6.49793C0.000300929 7.03634 0.428124 7.47332 0.955254 7.47362L10.8489 7.47332L7.26881 11.3531C6.94299 11.6859 6.88328 12.41 7.2592 12.775C7.63543 13.1403 8.31976 13.0229 8.65158 12.6938L13.7423 7.16811C13.916 6.97904 14.0015 6.75748 14 6.49793Z"
                                    fill="#325D77"
                                  />
                                </svg>
                              </div>
                            </th>

                            <td>
                              <div className="text-right">
                                <Money format="DOLLARS_WITH_CENTS" value={bill.paidAmount} fontWeight="semibold" />
                                <div>
                                  <Text
                                    variant="p1"
                                    className={cn(
                                      bill.status === 'Failed' ? 'text-red' : bill.status === 'Paid' ? 'text-green' : ''
                                    )}
                                  >
                                    <strong>{bill.status}</strong>{' '}
                                    {formatDateTime({
                                      dateTime: bill.lastUpdatedOn,
                                      format: DATE_FORMATS.STANDARD_SHORT,
                                    })}
                                  </Text>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                      <tr className="h-2sm border-0 border-b border-gray border-dashed" colSpan="2" />
                      <tr className="h-2sm " colSpan="2" />
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text fontWeight="semibold">Total</Text>
                          </div>
                        </th>
                        <td>
                          <div className="text-right">
                            <Money
                              format="DOLLARS_WITH_CENTS"
                              fontWeight="semibold"
                              value={financePlanPayments?.totalPaidAmount}
                            />
                            <Text variant="p1">
                              Including {interestRateValue}% APR (${monthlyInterestAmount}/mo.) • No Additional Fees
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </ModalLayout.Section>

          {status === 'Open' && (
            <ModalLayout.BottomBar
              className="w-full m-0 mt-lg"
              rightNode={
                <Button
                  size="large"
                  variant="solid"
                  buttonType="primary"
                  className="md:px-2sm w-full md:w-auto"
                  onClick={() => navigateUngroupModal(financePlan.finance.uniqueId)}
                >
                  <Text variant="body" fontWeight="semibold" className="px-2sm" tex>
                    Close Financing Plan...
                  </Text>
                </Button>
              }
              fixedBottom
            />
          )}
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
}

FinanceDetailsModal.propTypes = {
  id: PropTypes.string,
  onHide: PropTypes.func,
  financePlan: PropTypes.object,
  financePlanPayments: PropTypes.object,
  fetchFinancePlan: PropTypes.func,
  fetchFinancePlanPayments: PropTypes.func,
  handleUngroupModal: PropTypes.func,
  financeUniqueId: PropTypes.string,
  handleShowBalanceDetailsModal: PropTypes.func,
  home: PropTypes.object,
};

function mapStateProps(state) {
  return {
    balances: selectBalancesByMonth(state),
    financePlan: selectFinancePlanDetails(state),
    financePlanPayments: selectFinancePlanPayments(state),
  };
}
const mapDispatchToProps = {
  fetchFinancePlan,
  fetchFinancePlanPayments,
};

export default connect(mapStateProps, mapDispatchToProps)(FinanceDetailsModal);
