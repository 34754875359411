import { LeaseStatus } from 'models/enums';

export const getMostRecentLease = (accountLeases, homeId, includeExecuted?) => {
  let mostRecentLeaseForHome;

  if (accountLeases.leases?.length) {
    const filteredAccountLeases = accountLeases.leases.filter(
      (l) =>
        l.leaseInfo.basicInfo.status === LeaseStatus.Offered ||
        l.leaseInfo.basicInfo.status === LeaseStatus.ResidentsSigned ||
        (includeExecuted && l.leaseInfo.basicInfo.status === LeaseStatus.Executed)
    );

    filteredAccountLeases.forEach((l) => {
      if (l.unitInfo.unitId === homeId) {
        if (!mostRecentLeaseForHome) mostRecentLeaseForHome = l;
        if (mostRecentLeaseForHome.leaseInfo.basicInfo.renewalNumber < l.leaseInfo.basicInfo.renewalNumber)
          mostRecentLeaseForHome = l;
      }
    });
  }

  return mostRecentLeaseForHome;
};
