import { Text } from '@belong/ui';
import clsx from 'clsx';
import { STRINGS } from 'maintenance/strings';
import type { RecurringProServiceStatus } from 'maintenance/types';
import { STYLES_BY_STATUS } from './recurring-pro-service-status-tag.constants';

type Props = {
  status: RecurringProServiceStatus;
};

export function RecurringProServiceStatusTag({ status }: Props) {
  return (
    <div
      className={clsx('px-xs rounded border-solid border flex items-center justify-center', STYLES_BY_STATUS[status])}
    >
      <Text as="span" className="text-white" fontWeight="semibold" variant="p1">
        {STRINGS[status]}
      </Text>
    </div>
  );
}
