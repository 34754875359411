import { useSelector } from 'react-redux';
import { formatUtcDateTimeToTimeZone, useModal } from '@belong/common';
import { Text, Button } from '@belong/ui';
import { RescheduleAppointmentModal } from 'accounts/components/reschedule-appointment-modal/reschedule-appointment-modal';
import { SECTIONS_STRINGS } from 'accounts/constants/strings/sections.strings';
import { useUpcomingAppointments } from 'accounts/hooks/homeowners/use-upcoming-appointments';
import type { UnitSummaryModel } from 'api/models';
import { getMinutes, differenceInHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { selectUpcomingAppointments } from 'store/redux/homeowner-accounts/selectors';

type Props = {
  coverageRegionId: number;
  unit: UnitSummaryModel;
  timeZone: string;
};

export function UpcomingAppointmentsSection({ coverageRegionId, timeZone, unit }: Props) {
  const [isRescheduleAppointmentModalOpen, openRescheduleAppointmentModal, closeRescheduleAppoinmentModal] = useModal();
  const { loading } = useUpcomingAppointments(unit.basicInfo.unitId);
  const upcomingAppointments = useSelector(selectUpcomingAppointments);

  if (loading || !upcomingAppointments[unit.basicInfo.unitId]?.[0]) {
    return null;
  }

  const { appointmentStatus, startDate, endDate } = upcomingAppointments[unit.basicInfo.unitId][0];

  if (!startDate || !endDate) {
    window.DD_RUM?.addError?.(new Error(`Upcoming Appointment without start/end date`), {
      validation_error: 'true',
    });

    return null;
  }

  const canBeRescheduled =
    ['Scheduled', 'Reserved'].includes(appointmentStatus) && differenceInHours(new Date(endDate), new Date()) > 24;

  const getSlotFormat = (date) => {
    const zonedDate = utcToZonedTime(date, timeZone);
    const zonedDateMinutes = getMinutes(zonedDate);

    return zonedDateMinutes ? 'h:mma' : 'ha';
  };

  const slot = `${formatUtcDateTimeToTimeZone({
    dateTime: startDate,
    format: getSlotFormat(startDate),
    timeZone,
  })} - ${formatUtcDateTimeToTimeZone({ dateTime: endDate, format: `${getSlotFormat(endDate)} z`, timeZone })}`;

  const handleRescheduleClick = () => openRescheduleAppointmentModal();

  const handleRescheduleAppointmentModalDismiss = () => closeRescheduleAppoinmentModal();

  return (
    <>
      <div className="mt-xl sm:mt-0">
        <Text variant="h3" fontWeight="semibold">
          {SECTIONS_STRINGS['upcoming_appointments.title']}
        </Text>
        <div className="w-full flex flex-col sm:flex-row">
          <div className="pl-xs mt-xs sm:mt-sm mb-xl sm:mb-2xl border-0 border-l-3 border-solid border-blue flex flex-col justify-between items-start shrink-0">
            <Text>
              {SECTIONS_STRINGS['upcoming_appointments.inspection']} {appointmentStatus}
            </Text>
            <Text fontWeight="semibold" className="mt-xs" variant="h3">
              {`${formatUtcDateTimeToTimeZone({ dateTime: startDate, format: 'PPP', timeZone })} ${slot}`}
            </Text>
          </div>
          {canBeRescheduled && (
            <div className="flex justify-end w-full mb-2xl sm:mt-sm">
              <Button onClick={handleRescheduleClick} variant="text">
                {SECTIONS_STRINGS['upcoming_appointments.reschedule']}
              </Button>
            </div>
          )}
        </div>
      </div>
      <RescheduleAppointmentModal
        coverageRegionId={coverageRegionId}
        isOpen={isRescheduleAppointmentModalOpen}
        onDismiss={handleRescheduleAppointmentModalDismiss}
        timeZone={timeZone}
        unit={unit}
        unitId={unit.basicInfo.unitId}
      />
    </>
  );
}
