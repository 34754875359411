import React from 'react';
import { Image } from '@belong/ui';
import classNames from 'classnames/bind';
import { getHomeListPrice } from 'utils/mapUtils';
import styles from './rented-marker.module.css';

const cx = classNames.bind(styles);
const selectedMarkerSrc = '/icons/map/selected_off_market_home_marker.png';
const markerSrc = '/icons/map/off_market_home_marker.png';

type RentedMarkerProps = {
  isHover: boolean;
  home: any;
};

export function RentedMarker({ isHover, home }: RentedMarkerProps) {
  return (
    <div>
      <div className={cx('rented-marker', { hover: isHover })}>
        <Image src={isHover ? selectedMarkerSrc : markerSrc} className={cx('listing-icon')} alt="" />
      </div>
      <div className={cx('recently-off-market-wrapper', { hover: isHover })}>
        Recently <div>Off Market</div>
      </div>
      <div className={cx('rented-text-wrapper', { hover: isHover })}>{getHomeListPrice(home.rentAmount)}</div>
    </div>
  );
}
