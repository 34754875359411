import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import Money from 'components/Money/Money';
import Spinner from 'components/Spinner/Spinner';
import TransactionHistory from 'containercomponents/TransactionHistory/TransactionHistory';
import PaymentMethodSelectorContainer from 'containers/PaymentMethodSelectorContainer/PaymentMethodSelectorContainer';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import ModalLayoutErrorScreen from 'layouts/ModalLayout/ModalLayoutErrorScreen/ModalLayoutErrorScreen';
import ModalLayoutSuccessScreen from 'layouts/ModalLayout/ModalLayoutSuccessScreen/ModalLayoutSuccessScreen';
import PaymentMethods from 'models/common/PaymentMethods';
import { BillStatus, PaymentPreference } from 'models/enums/index';
import BillDetailModel from 'models/payments/BillDetailModel';
import PropTypes from 'prop-types';
import { createBillPaymentWithPaymentId } from 'store/redux/accounts/actions';
import { selectCurrentBill } from 'store/redux/accounts/selectors';
import { selectPaymentMethodsFullObject } from 'store/redux/user/selectors';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import { PAYMENT_LIST_MODAL } from 'strings/payment-list-modal';
import { PAYMENT_LIST } from 'strings/payments/payments.strings';
import { PAYMENT_SCREENS } from '../PaymentsModal/PaymentsModal';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import PaymentFoldSuccess from './PaymentFoldSuccess/PaymentFoldSuccess';
import styles from './PaymentListModal.module.css';

const cx = classNames.bind(styles);

const findFailedorPendingPayment = (payment, isHomeowner) => {
  if (isHomeowner) {
    return true;
  }

  if (payment.billInfo.remainingAmount > 0) {
    return false;
  }

  return true;
};

const PLM = PAYMENT_LIST_MODAL.paymentmethod;

class PaymentListModal extends Component {
  state = {
    success: false,
    failed: false,
    loading: false,
    isVerified: false,
    currentFeeToUse: '',
    selectedPaymentMethodId: null,
    selectedProServicePaymentMethodId: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {
      ...prevState,
    };

    if (!nextProps.isHomeowner && !prevState.selectedPaymentMethodId) {
      newState.isVerified = nextProps.methods?.rent?.Primary?.isVerified;
      newState.selectedProServicePaymentMethodId = nextProps.methods?.paymentMethods?.find((paymentMethod) => {
        return paymentMethod?.preferences[0]?.paymentType === PaymentPreference.Maintenance;
      })?.accountId;
      newState.selectedPaymentMethodId = nextProps.methods?.rent?.Primary?.accountId;

      if (newState.selectedPaymentMethodId) {
        newState.currentFeeToUse = nextProps.methods.findObjectBasedOnAccountId(newState?.selectedPaymentMethodId);
      }
    }

    return newState;
  }

  handlePaymentWithDefaultMethod = async () => {
    const { payment, createBillPaymentWithPaymentId: createBillPaymentWithPaymentIdActions, paymentId } = this.props;
    const { selectedPaymentMethodId } = this.state;
    this.setState({ loading: true });

    try {
      await createBillPaymentWithPaymentIdActions(paymentId, {
        accountId: selectedPaymentMethodId,
        amount: payment.billInfo.remainingAmount,
      });

      this.setState({ loading: false });
      this.handleSuccessfulTrue();
    } catch (error) {
      this.handleFailTrue(error);
    }
  };

  handlePaymentWithoutDefaultMethod = () => {
    const { onManualPayment } = this.props;
    onManualPayment();
  };

  handleSuccessfulTrue = () => this.setState({ success: true });

  handleSuccesfulFalse = () => this.setState({ success: false });

  handleFailTrue = (errors) => this.setState({ failed: true, errors });

  handleFailedFalse = () => this.setState({ failed: false });

  renderErrorModal() {
    const { onSuccessOrFailHide, show } = this.props;
    const { errors } = this.state;
    let errorModalProps = PAYMENT_LIST.MODAL_ERROR_PAYMENT;

    if (errors) {
      const error = errors[0];
      const { errorCode } = error;

      if (errorCode === 'ACH_INSUFFICIENT_FUNDS') {
        errorModalProps = FEEDBACK_STRINGS.payments_errors.ACH_INSUFFICIENT_FUNDS;
      }

      if (errorCode === 'UNVERIFIED_USER_LIMIT_EXCEEDS') {
        errorModalProps = FEEDBACK_STRINGS.payments_errors.UNVERIFIED_USER_LIMIT_EXCEEDS;
      }
    }

    return (
      <ModalV2 onHide={onSuccessOrFailHide} noPadding show={show}>
        <ModalLayoutErrorScreen {...errorModalProps} onCtaClick={onSuccessOrFailHide} />
      </ModalV2>
    );
  }

  render() {
    const { isHomeowner, onHide, methods, onSuccessOrFailHide, show, payment } = this.props;
    const { success, failed, selectedPaymentMethodId, currentFeeToUse, isVerified, loading } = this.state;
    let renderModal;

    // conditional check so it doesn't crash on mounting.
    const status = payment.billInfo?.status;

    const billPaymentMethod = payment?.paymentMethod?.accountId ?? selectedPaymentMethodId;

    if (status) {
      renderModal = (
        <ModalLayout fixedBottom>
          <ModalLayout.Content>
            <PaymentFoldSuccess
              amount={payment.billInfo.remainingAmount ? payment.billInfo.remainingAmount : payment.billInfo.total}
              description={payment.billInfo.description}
              isHomeowner={isHomeowner}
              item={payment.items[0]}
              status={status}
              success={findFailedorPendingPayment(payment, isHomeowner)}
            />

            <ModalLayout.Section>
              <PaymentDetails
                items={payment.items}
                task={payment}
                paymentId={payment.billInfo.displayId}
                amount={payment.billInfo.total}
                transfers={payment.transfers}
                status={status}
                isHomeowner={isHomeowner}
                currentFeeToUse={currentFeeToUse}
              />

              {!findFailedorPendingPayment(payment, isHomeowner) && (
                <div>
                  <ModalLayout.SectionTitle label={PLM.titlesuccess} />
                  <PaymentMethodSelectorContainer
                    needsPaymentMethod
                    selectedPaymentMethodId={billPaymentMethod}
                    onSelectPaymentMethod={(id, paymentObject) => {
                      const currentFee = methods?.findObjectBasedOnAccountId(id) || paymentObject;
                      const verified = methods?.findObjectBasedOnAccountId(id)?.isVerified || paymentObject?.isVerified;

                      this.setState({
                        selectedPaymentMethodId: id,
                        currentFeeToUse: currentFee,
                        isVerified: verified,
                      });
                    }}
                    paymentModalProps={{
                      screen: PAYMENT_SCREENS.PAYMENT_SELECTION,
                      errorScreenProps: {
                        title: FEEDBACK_STRINGS.set_payment_method.error.title,
                        ctaLabel: FEEDBACK_STRINGS.set_payment_method.error.ctaLabel,
                      },
                      successScreenProps: {
                        title: FEEDBACK_STRINGS.set_payment_method.success.title,
                        ctaLabel: FEEDBACK_STRINGS.set_payment_method.success.ctaLabel,
                      },
                    }}
                  />
                </div>
              )}

              {payment.transfers.length > 0 && (
                <>
                  <Space value={SPACE_TYPES.XL} />
                  <TransactionHistory billInfo={payment.billInfo} transfers={payment.transfers} />
                </>
              )}

              {!isHomeowner && payment.billInfo.remainingAmount > 0 && status !== BillStatus.Failed && (
                <Form
                  onSubmit={
                    selectedPaymentMethodId
                      ? this.handlePaymentWithDefaultMethod
                      : this.handlePaymentWithoutDefaultMethod
                  }
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <ModalLayout.BottomBar
                        ctaProps={{
                          children: (
                            <div>
                              {' '}
                              {PAYMENT_LIST.MODAL_PAYMENT_PENDING.ctaLabel}&nbsp;
                              <Money
                                cash={
                                  payment.getFeeFromItem(currentFeeToUse || '')?.remainingAmountWithFee ||
                                  payment.billInfo.remainingAmount
                                }
                              />
                            </div>
                          ),
                          disabled: !isVerified,
                        }}
                        fixedBottom
                      />
                    </form>
                  )}
                />
              )}
              {findFailedorPendingPayment(payment, isHomeowner) && <ModalLayout.BottomBar noButton fixedBottom />}
            </ModalLayout.Section>
          </ModalLayout.Content>
        </ModalLayout>
      );
    } else {
      renderModal = (
        <div className={cx('height')}>
          <Spinner />
        </div>
      );
    }

    const renderSuccess = (
      <ModalLayoutSuccessScreen
        title={PAYMENT_LIST.MODAL_SUCCESS_PAYMENT.title}
        subtitle={PAYMENT_LIST.MODAL_SUCCESS_PAYMENT.subtitle}
        ctaLabel={PAYMENT_LIST.MODAL_SUCCESS_PAYMENT.ctaLabel}
        onCtaClick={onSuccessOrFailHide}
      />
    );

    return (
      <>
        {
          // Should Refactor into a function
          !success && !failed && (
            <ModalV2 onHide={onHide} noPadding show={show} overflow showSpinner={loading}>
              {renderModal}
            </ModalV2>
          )
        }
        {success && (
          <ModalV2 onHide={onSuccessOrFailHide} noPadding show={show} showSpinner={loading}>
            {renderSuccess}
          </ModalV2>
        )}
        {failed && this.renderErrorModal()}
      </>
    );
  }
}

PaymentListModal.propTypes = {
  paymentId: PropTypes.string.isRequired,
  createBillPaymentWithPaymentId: PropTypes.func.isRequired,
  methods: PropTypes.instanceOf(PaymentMethods).isRequired,
  onManualPayment: PropTypes.func.isRequired,
  onSuccessOrFailHide: PropTypes.func.isRequired,
  onFailHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  payment: PropTypes.instanceOf(BillDetailModel),
  // temporary prop
  isHomeowner: PropTypes.bool,
};

PaymentListModal.defaultProps = {
  payment: false,
  isHomeowner: false,
};

const mapStateToProps = (state, ownProps) => ({
  methods: selectPaymentMethodsFullObject(state),
  payment: selectCurrentBill(state, ownProps.paymentId),
});

const mapDispatchToProps = {
  createBillPaymentWithPaymentId,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentListModal);
