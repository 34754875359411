import { createTableTree } from './create-table-tree';

export function balancesDataMapper(months) {
  const data = months?.map((month) => ({
    ...month,
    homesDetails: month.homesDetails.map((home) => {
      const {
        groupedBalancesPerCategory: { expenses, revenues, all },
      } = home;

      const groups = { ...home, groupedBalancesPerCategory: {} };

      if (expenses) {
        groups.groupedBalancesPerCategory.expenses = {
          ...expenses,
          groupedBalancesInfo: createTableTree({
            data: expenses.groupedBalancesInfo,
            accessor: 'groupBalanceItemId',
            link: 'parentGroupBalanceItemId',
          }),
        };
      }

      if (revenues) {
        groups.groupedBalancesPerCategory.revenues = {
          ...revenues,
          groupedBalancesInfo: createTableTree({
            data: revenues.groupedBalancesInfo,
            accessor: 'groupBalanceItemId',
            link: 'parentGroupBalanceItemId',
          }),
        };
      }

      if (all) {
        groups.groupedBalancesPerCategory.all = {
          ...all,
          groupedBalancesInfo: createTableTree({
            data: all.groupedBalancesInfo,
            accessor: 'groupBalanceItemId',
            link: 'parentGroupBalanceItemId',
          }),
        };
      }

      return groups;
    }),
  }));

  return data;
}

export function underReviewDataMapper(balances) {
  return balances?.map((balance) => ({
    ...balance,
    groupedBalancesInfo: createTableTree({
      data: balance.groupedBalancesInfo,
      accessor: 'groupBalanceItemId',
      link: 'parentGroupBalanceItemId',
    }),
  }));
}
