import { PayoutPlan } from 'models/enums';
import { LAPricingExperiment } from '../../../experiments/experiment-configs/la-pricing.experiment';
import { selectPayoutPlansGroupedByRegion } from '../settings';

const defaultSettings = {
  ManagementFeesMonthly: 8,
  PlacementFeesMonthly: 50,
  ManagementFeesYearly: 12,
  PlacementFeesYearly: 50,
  MinimumMonthly: 200,
  MaximumMonthly: 599,
  MinimumYearly: 200,
};

const getSettingsObjectFromPayoutPlans = (payoutPlans) => {
  const monthly = payoutPlans.find((payoutPlan) => payoutPlan.plan === PayoutPlan.Monthly);
  const yearly = payoutPlans.find((payoutPlan) => payoutPlan.plan === PayoutPlan.Yearly);

  const settings = { ...defaultSettings };

  if (monthly) {
    settings.ManagementFeesMonthly = monthly.managementFeePercentage;
    settings.PlacementFeesMonthly = monthly.placementFeePercentage;
    settings.MinimumMonthly = monthly.minimumManagementFeeAmount;
    settings.MaximumMonthly = monthly.maximumManagementFeeAmount;
  }

  if (yearly) {
    settings.ManagementFeesYearly = yearly.managementFeePercentage;
    settings.PlacementFeesYearly = yearly.placementFeePercentage;
    settings.MinimumYearly = yearly.minimumManagementFeeAmount;
  }

  return settings;
};

export const ACTIONS = {
  UPDATE_EXPERIMENTS: 'UPDATE_EXPERIMENTS',
};

const initialState = {};
export const EXPERIMENTS_REDUCER = 'experiments';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.UPDATE_EXPERIMENTS:
      return {
        ...state,
        ...action.experiments,
      };
    default:
      return state;
  }
}

export const updateExperiments = (experiments) => ({
  type: ACTIONS.UPDATE_EXPERIMENTS,
  experiments,
});

export const selectExperiments = (state) => state[EXPERIMENTS_REDUCER];

export const selectHomeFeesSettings = (state, regionId) => {
  const experiments = selectExperiments(state);
  const laPricingVariant = experiments[LAPricingExperiment.name];

  // This is probably changing, maybe I will take this approach for the rest of conditinos, but still have questions.
  if (regionId) {
    const payoutPlans = selectPayoutPlansGroupedByRegion(state);
    const currentPayoutPlan = payoutPlans[regionId];

    if (!currentPayoutPlan) return defaultSettings;

    if (laPricingVariant && currentPayoutPlan[laPricingVariant]) {
      return getSettingsObjectFromPayoutPlans(currentPayoutPlan[laPricingVariant]);
    }

    return getSettingsObjectFromPayoutPlans(currentPayoutPlan.DEFAULT);
  }

  return defaultSettings;
};
