import { createNote, updateNote } from '../common/notes';
import { getApiActionTypesValues } from '../redux_utils';

export const fetchProDetailsById = (id) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_PRO_DETAILS_BY_ID'),
    promise: ({ client }) => client.get(`/pros/${id}`),
  });

export const fetchAvailableProsByDate = (props) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_AVAILABLE_PROS_BY_DATE'),
    auth: true,
    promise: ({ client }) => client.post('/pros/events', props),
  });

export const fetchRecurringProServicePresets = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RECURRING_PRO_SERVICE_PRESETS'),
    auth: true,
    promise: ({ client }) => client.get('/client-configurations/by-type/MAINTENANCE_RECURRENCE_PRESETS'),
  });

const createRecurring = (data) => ({
  types: getApiActionTypesValues('CREATE_RECURRING_PRO_SERVICE'),
  promise: ({ client }) =>
    client.post('/recurrence', data, {
      headers: {
        sourceType: 'Web',
      },
    }),
});

export const createRecurringProService = (data) => async (dispatch) => {
  const recurring = await dispatch(createRecurring(data));
  const { description, media } = data;

  if (description || media) {
    await dispatch(
      createNote({
        sourceType: 'MaintenanceRecurrence',
        sourceId: recurring.id,
        body: description,
        media,
      })
    );
  }
};

const updateRecurring = ({ recurringTask, unitId, frequency }) => ({
  types: getApiActionTypesValues('UPDATE_RECURRING_PRO_SERVICE'),
  auth: true,
  promise: ({ client }) => client.put(`/recurrence/${recurringTask}/${unitId}?frequency=${frequency}`),
});

export const updateRecurringProService = (data) => async (dispatch) => {
  const recurring = await dispatch(updateRecurring(data));
  const { noteId, description, media } = data;

  if (data.noteId) {
    await dispatch(
      updateNote(noteId, {
        body: description || '',
        media,
      })
    );
  } else if (description || media) {
    await dispatch(
      createNote({
        sourceType: 'MaintenanceRecurrence',
        sourceId: recurring.id,
        body: description,
        media,
      })
    );
  }
};

export const cancelRecurringProService =
  ({ recurringTask, unitId }) =>
  (dispatch) =>
    dispatch({
      types: getApiActionTypesValues('CANCEL_RECURRING_PRO_SERVICE'),
      auth: true,
      promise: ({ client }) => client.put(`/recurrence/${recurringTask}/${unitId}/cancel`),
    });

export const cancelRecurringProServiceVisit =
  ({ maintenanceUniqueId, resultReason }) =>
  (dispatch) =>
    dispatch({
      types: getApiActionTypesValues('CANCEL_RECURRING_PRO_SERVICE_VISIT'),
      auth: true,
      promise: ({ client }) => client.put(`/recurrence/${maintenanceUniqueId}/cancel?resultReason=${resultReason}`),
    });
