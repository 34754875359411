import { connect } from 'react-redux';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import { useModal } from 'hooks/useModal';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { ListingApplicationStatus } from 'models/enums';
import PropTypes from 'prop-types';
import { fetchResidentBookmarks } from 'store/redux/renter-accounts/actions';
import { updateApplicationStatus } from 'store/redux/resident-application/actions';

const WithdrawModalComponent = ({
  onHide,
  show,
  applicationId,
  unitId,
  updateApplicationStatusAction,
  fetchResidentBookmarksAction,
}) => {
  const [loading, startLoading, stopLoading] = useModal();

  const handleClick = async (e) => {
    e.stopPropagation();
    try {
      startLoading();
      await updateApplicationStatusAction(applicationId, unitId, { status: ListingApplicationStatus.Withdrawn });
      await fetchResidentBookmarksAction();
    } catch (err) {
      console.error(err);
    }
    stopLoading();
    onHide();
  };

  return (
    <ModalV2 onHide={onHide} showSpinner={loading} show={show} noPadding>
      <ModalLayout>
        <ModalLayout.Title>Are you sure you want to withdraw this application?</ModalLayout.Title>

        <ModalLayout.Content>
          <ModalLayout.BottomBar
            fixedBottom
            reverseOrderOnMobile
            ctaProps={{
              label: 'Withdraw',
              mobileFixed: true,
              onClick: handleClick,
            }}
          />
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
};

WithdrawModalComponent.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  applicationId: PropTypes.string.isRequired,
  unitId: PropTypes.string.isRequired,
  updateApplicationStatusAction: PropTypes.func.isRequired,
  fetchResidentBookmarksAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  updateApplicationStatusAction: updateApplicationStatus,
  fetchResidentBookmarksAction: fetchResidentBookmarks,
};

export const WithdrawModal = connect(null, mapDispatchToProps)(WithdrawModalComponent);
