import { getApiActionTypes } from '../redux_utils';

export const ACCOUNTS_CONST = {
  FETCH_USER_BILLS: 'FETCH_USER_BILLS',
  FETCH_USER_BILLS_BY_ID: 'FETCH_USER_BILLS_BY_ID',
  FETCH_USER_FEES_BY_ID: 'FETCH_USER_FEES_BY_ID',
  PAY_BILL: 'PAY_BILL',
  PAY_BILLS: 'PAY_BILLS',
  FETCH_USER_GROUP_BALANCES: 'FETCH_USER_GROUP_BALANCES',
  FETCH_USER_GROUP_BALANCES_BY_MONTH: 'FETCH_USER_GROUP_BALANCES_BY_MONTH',
  FETCH_USER_BALANCES_PAST_MONTHS: 'FETCH_USER_BALANCES_PAST_MONTHS',
  FETCH_USER_BALANCES_UNDER_REVIEW: 'FETCH_USER_BALANCES_UNDER_REVIEW',
  SIMULATE_SPLIT_PAYMENT: 'SIMULATE_SPLIT_PAYMENT',
  FETCH_FINANCE_PLAN: 'FETCH_FINANCE_PLAN',
  FETCH_FINANCE_PLAN_PAYMENTS: 'FETCH_FINANCE_PLAN_PAYMENTS',
  CREATE_FINANCE_PLAN: 'CREATE_FINANCE_PLAN',
  CLOSE_FINANCE_PLAN: 'CLOSE_FINANCE_PLAN',
  FETCH_USER_PENDING_BILLS: 'FETCH_USER_PENDING_BILLS',
  FETCH_USER_PAST_BILLS: 'FETCH_USER_PAST_BILLS',
  FETCH_FINANCE_PLAN_DISCLOSURE: 'FETCH_FINANCE_PLAN_DISCLOSURE',
  RESCHEDULE_APPOINTMENT: 'RESCHEDULE_APPOINTMENT',
};

export const ACTIONS = {
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_BILLS),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_BILLS_BY_ID),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_FEES_BY_ID),
  ...getApiActionTypes(ACCOUNTS_CONST.PAY_BILL),
  ...getApiActionTypes(ACCOUNTS_CONST.PAY_BILLS),
  CURRENT_BILL_CLEAR: 'CURRENT_BILL_CLEAR',
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_GROUP_BALANCES),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_GROUP_BALANCES_BY_MONTH),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_BALANCES_PAST_MONTHS),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_BALANCES_UNDER_REVIEW),
  ...getApiActionTypes(ACCOUNTS_CONST.SIMULATE_SPLIT_PAYMENT),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_FINANCE_PLAN),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_FINANCE_PLAN_PAYMENTS),
  ...getApiActionTypes(ACCOUNTS_CONST.CREATE_FINANCE_PLAN),
  ...getApiActionTypes(ACCOUNTS_CONST.CLOSE_FINANCE_PLAN),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_PENDING_BILLS),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_USER_PAST_BILLS),
  ...getApiActionTypes(ACCOUNTS_CONST.FETCH_FINANCE_PLAN_DISCLOSURE),
  ...getApiActionTypes(ACCOUNTS_CONST.RESCHEDULE_APPOINTMENT),
};
