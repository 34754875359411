import React from 'react';
import { Button, Text } from '@belong/ui';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { EARNINGS_STRINGS } from '../earnings.strings';

function FinanceSuccessModal({ onHide, ...rest }) {
  function handleHide() {
    if (onHide) onHide();
  }
  return (
    <ModalV2 {...rest} noPadding showSpinner={false} onHide={handleHide} className="">
      <ModalLayout fixedBottom>
        <ModalLayout.Content className="p-0">
          <ModalLayout.Section>
            <ModalLayout.Title>{EARNINGS_STRINGS['finance_success_modal.title']}</ModalLayout.Title>
            <ModalLayout.SubTitle>{EARNINGS_STRINGS['finance_success_modal.sub_title']}</ModalLayout.SubTitle>
            <div className="flex justify-center py-xl mt-lg">
              <Button size="fluid-long-responsive" variant="solid" buttonType="primary" onClick={handleHide}>
                <Text variant="body" fontWeight="semibold" className="px-2sm w-7xl">
                  {EARNINGS_STRINGS['finance_success_modal.done_cta']}
                </Text>
              </Button>
            </div>
          </ModalLayout.Section>
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
}

FinanceSuccessModal.propTypes = {
  onHide: PropTypes.func,
};

export default FinanceSuccessModal;
