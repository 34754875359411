export const listOfFurnitures = [
  {
    value: 'Chair',
    key: 'Chair',
  },
  {
    value: 'Lift chair',
    key: 'Lift chair',
  },
  {
    value: 'Bean bag',
    key: 'Bean bag',
  },
  {
    value: 'Chaise lounge',
    key: 'Chaise lounge',
  },
  {
    value: 'Fauteuil',
    key: 'Fauteuil',
  },
  {
    value: 'Recliner',
    key: 'Recliner',
  },
  {
    value: 'Stool',
    key: 'Stool',
  },
  {
    value: 'Bar stool',
    key: 'Bar stool',
  },
  {
    value: 'Footstool',
    key: 'Footstool',
  },
  {
    value: 'Tuffet',
    key: 'Tuffet',
  },
  {
    value: 'Fainting couch',
    key: 'Fainting couch',
  },
  {
    value: 'Rocking chair',
    key: 'Rocking chair',
  },
  {
    value: 'Bar chair',
    key: 'Bar chair',
  },
  {
    value: 'Bench',
    key: 'Bench',
  },
  {
    value: 'Couch/Sofa',
    key: 'Couch/ Sofa',
  },
  {
    value: 'Accubita',
    key: 'Accubita',
  },
  {
    value: 'Canapé',
    key: 'Canapé',
  },
  {
    value: 'Davenport',
    key: 'Davenport',
  },
  {
    value: 'Klinai',
    key: 'Klinai',
  },
  {
    value: 'Divan',
    key: 'Divan',
  },
  {
    value: 'Love seat',
    key: 'Love seat',
  },
  {
    value: 'Chesterfield',
    key: 'Chesterfield',
  },
  {
    value: 'Bed',
    key: 'Bed',
  },
  {
    value: 'Bunk bed',
    key: 'Bunk bed',
  },
  {
    value: 'Canopy bed',
    key: 'Canopy bed',
  },
  {
    value: 'Four-poster bed',
    key: 'Four-poster bed',
  },
  {
    value: 'Murphy bed',
    key: 'Murphy bed',
  },
  {
    value: 'Platform bed',
    key: 'Platform bed',
  },
  {
    value: 'Sleigh bed',
    key: 'Sleigh bed',
  },
  {
    value: 'Waterbed',
    key: 'Waterbed',
  },
  {
    value: 'Daybed',
    key: 'Daybed',
  },
  {
    value: 'Futon',
    key: 'Futon',
  },
  {
    value: 'Hammock',
    key: 'Hammock',
  },
  {
    value: 'Headboard',
    key: 'Headboard',
  },
  {
    value: 'Crib',
    key: 'Crib',
  },
  {
    value: 'Cradle',
    key: 'Cradle',
  },
  {
    value: 'Mattress',
    key: 'Mattress',
  },
  {
    value: 'Sofa bed',
    key: 'Sofa bed',
  },
  {
    value: 'Billiard table',
    key: 'Billiard table',
  },
  {
    value: 'Chess table',
    key: 'Chess table',
  },
  {
    value: 'Entertainment center',
    key: 'Entertainment center',
  },
  {
    value: 'Gramophone',
    key: 'Gramophone',
  },
  {
    value: 'Hi-fi',
    key: 'Hi-fi',
  },
  {
    value: 'Jukebox',
    key: 'Jukebox',
  },
  {
    value: 'Pinball machine',
    key: 'Pinball machine',
  },
  {
    value: 'Radiogram',
    key: 'Radiogram',
  },
  {
    value: 'Home bar',
    key: 'Home bar',
  },
  {
    value: 'Television set',
    key: 'Television set',
  },
  {
    value: 'Radio receiver',
    key: 'Radio receiver',
  },
  {
    value: 'Piano',
    key: 'Piano',
  },
  {
    value: 'Countertop',
    key: 'Countertop',
  },
  {
    value: 'Chabudai',
    key: 'Chabudai',
  },
  {
    value: 'Changing table',
    key: 'Changing table',
  },
  {
    value: 'Desk',
    key: 'Desk',
  },
  {
    value: 'Davenport desk',
    key: 'Davenport desk',
  },
  {
    value: 'Drawing board',
    key: 'Drawing board',
  },
  {
    value: 'Computer desk',
    key: 'Computer desk',
  },
  {
    value: 'Writing desk',
    key: 'Writing desk',
  },
  {
    value: 'Kotatsu',
    key: 'Kotatsu',
  },
  {
    value: 'Korsi',
    key: 'Korsi',
  },
  {
    value: 'Lowboy',
    key: 'Lowboy',
  },
  {
    value: 'Monks bench',
    key: 'Monks bench',
  },
  {
    value: 'Pedestal',
    key: 'Pedestal',
  },
  {
    value: 'Table',
    key: 'Table',
  },
  {
    value: 'Game table',
    key: 'Game table',
  },
  {
    value: 'Coffee table',
    key: 'Coffee table',
  },
  {
    value: 'Dining table',
    key: 'Dining table',
  },
  {
    value: 'Refectory table',
    key: 'Refectory table',
  },
  {
    value: 'Drop-leaf table',
    key: 'Drop-leaf table',
  },
  {
    value: 'End table',
    key: 'End table',
  },
  {
    value: 'Folding table',
    key: 'Folding table',
  },
  {
    value: 'Gateleg table',
    key: 'Gateleg table',
  },
  {
    value: 'Poker table',
    key: 'Poker table',
  },
  {
    value: 'Trestle table',
    key: 'Trestle table',
  },
  {
    value: 'TV tray table',
    key: 'TV tray table',
  },
  {
    value: 'Wine table',
    key: 'Wine table',
  },
  {
    value: 'Washstand',
    key: 'Washstand',
  },
  {
    value: 'Workbench',
    key: 'Workbench',
  },
  {
    value: "Baker's rack",
    key: "Baker's rack",
  },
  {
    value: 'Bookcase',
    key: 'Bookcase',
  },
  {
    value: 'Cabinetry',
    key: 'Cabinetry',
  },
  {
    value: 'Bathroom cabinet',
    key: 'Bathroom cabinet',
  },
  {
    value: 'Chifforobe',
    key: 'Chifforobe',
  },
  {
    value: 'Closet',
    key: 'Closet',
  },
  {
    value: 'Cupboard',
    key: 'Cupboard',
  },
  {
    value: 'Curio cabinet',
    key: 'Curio cabinet',
  },
  {
    value: 'Gun cabinet',
    key: 'Gun cabinet',
  },
  {
    value: 'Hutch',
    key: 'Hutch',
  },
  {
    value: 'Hoosier cabinet',
    key: 'Hoosier cabinet',
  },
  {
    value: 'Kitchen cabinet',
    key: 'Kitchen cabinet',
  },
  {
    value: 'Liquor cabinet',
    key: 'Liquor cabinet',
  },
  {
    value: 'Pie safe',
    key: 'Pie safe',
  },
  {
    value: 'Chest of drawers or dresser',
    key: 'Chest of drawers or dresser',
  },
  {
    value: 'Drawers',
    key: 'Drawers',
  },
  {
    value: 'Dresser',
    key: 'Dresser',
  },
  {
    value: 'Chest',
    key: 'Chest',
  },
  {
    value: 'Cellarette',
    key: 'Cellarette',
  },
  {
    value: 'Hope chest',
    key: 'Hope chest',
  },
  {
    value: 'Coat rack',
    key: 'Coat rack',
  },
  {
    value: 'Hall tree',
    key: 'Hall tree',
  },
  {
    value: 'Hatstand',
    key: 'Hatstand',
  },
  {
    value: 'Bar cabinet',
    key: 'Bar cabinet',
  },
  {
    value: 'Filing cabinet',
    key: 'Filing cabinet',
  },
  {
    value: 'Floating shelf',
    key: 'Floating shelf',
  },
  {
    value: 'Nightstand',
    key: 'Nightstand',
  },
  {
    value: 'Ottoman',
    key: 'Ottoman',
  },
  {
    value: 'Plan chest',
    key: 'Plan chest',
  },
  {
    value: 'Plant stand',
    key: 'Plant stand',
  },
  {
    value: 'Shelving',
    key: 'Shelving',
  },
  {
    value: 'Sideboard',
    key: 'Sideboard',
  },
  {
    value: 'Buffet',
    key: 'Buffet',
  },
  {
    value: 'Safe',
    key: 'Safe',
  },
  {
    value: 'Umbrella stand',
    key: 'Umbrella stand',
  },
  {
    value: 'Wardrobe',
    key: 'Wardrobe',
  },
  {
    value: 'Armoire',
    key: 'Armoire',
  },
  {
    value: 'Wine rack',
    key: 'Wine rack',
  },
  {
    value: 'Dinette',
    key: 'Dinette',
  },
  {
    value: 'Dining set',
    key: 'Dining set',
  },
  {
    value: 'Vanity set',
    key: 'Vanity set',
  },
  {
    value: 'Portable Lamps',
    key: 'Portable Lamps',
  },
  {
    value: 'Patio set',
    key: 'Patio set',
  },
  {
    value: 'Painting',
    key: 'Painting',
  },
  {
    value: 'Art',
    key: 'Art',
  },
  {
    value: 'Statue',
    key: 'Statue',
  },
  {
    value: 'Sculpture',
    key: 'Sculpture',
  },
].sort((furnitureA, furnitureB) => furnitureA.value.localeCompare(furnitureB.value));
