import { ACTIONS } from './constants';

export const INITIAL_PRICE_REDUCER = 'initial-price';

const initialState = {
  initialPrice: {},
  feedback: null,
};

const InitialPriceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.FETCH_INITIAL_PRICE_SUCCESS:
      return {
        ...state,
        initialPrice: { ...action.result },
      };
    case ACTIONS.UPDATE_INITIAL_PRICE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: { ...action.result },
      };
    default:
      return state;
  }
};

export default InitialPriceReducer;
