import { Text } from '@belong/ui';
import clsx from 'clsx';
import { ContactUs } from 'containers/ContactUs/ContactUs';

type MaintenanceRecurrenceResidentNoticeProps = {
  frequency: string;
  isAccessRequired?: boolean;
  isSubtask?: boolean;
  conciergeName?: string;
  homeownerName?: string;
  isRequestedByHomeowner?: boolean;
};

export const MaintenanceRecurrenceResidentNotice = ({
  frequency,
  isAccessRequired = false,
  isSubtask = false,
  conciergeName,
  homeownerName,
  isRequestedByHomeowner = false,
}: MaintenanceRecurrenceResidentNoticeProps) => {
  return (
    <div className="bg-green-translucent-light p-sm rounded border border-solid border-green mt-sm">
      {isRequestedByHomeowner && (
        <Text>
          {!isAccessRequired && (
            <span role="img" aria-label="microphone emoji">
              🎙
            </span>
          )}{' '}
          {`Good news! Your homeowner${
            homeownerName ? `, ${homeownerName},` : ''
          } signed up for this service to happen ${frequency.toLowerCase()} to keep your home in good
        condition.`}
        </Text>
      )}
      {isSubtask && (
        <div className={clsx('flex flex-col items-stretch', { 'mt-sm': isRequestedByHomeowner })}>
          {isAccessRequired ? (
            <Text>
              <span role="img" aria-label="warning emoji">
                ⚠️
              </span>{' '}
              To complete this, <strong>we need to enter the home</strong>. If you have any concerns or need to
              reschedule,{' '}
              <span>
                {conciergeName ? (
                  <span>
                    <strong>{`contact ${conciergeName}`}</strong>, your Member Success Lead.
                  </span>
                ) : (
                  <strong>contact your Member Success Lead.</strong>
                )}
              </span>
            </Text>
          ) : (
            <Text>
              It’s totally up to you, but you don’t need to be at there. If you have any concerns or wish reschedule,{' '}
              <span>
                {conciergeName ? (
                  <span>
                    <strong>{`contact ${conciergeName}`}</strong>, your Member Success Lead.
                  </span>
                ) : (
                  <strong>contact your Member Success Lead.</strong>
                )}
              </span>
            </Text>
          )}
          <ContactUs disableChat={false} label="CONTACT US" buttonType="text" className="mt-sm pb-0 self-end" />
        </div>
      )}
    </div>
  );
};
