/* eslint-disable */
export const palette = {
  black: '#000000',
  navy: '#325e77',
  'dark-navy': '#0f2532',
  'lighter-green': '#c2f7e4',
  'light-green': '#5bbe9b',
  green: '#3ee4a9',
  'dark-green': '#3aa680',
  'light-gray': '#fafbfc',
  gray: '#d6e1e4',
  'dark-gray': '#8ea0a4',
  red: '#ff5661',
  'dark-red': '#c71d28',
  'green-translucent': 'rgba(62, 228, 169, 0.8)',
  'dark-navy-translucent': 'rgba(15, 37, 50, 0.9)',
  orange: '#ff9c00',
  white: '#fff',
};
