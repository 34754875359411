export const HOME_INSPECTION_STRINGS = {
  title: 'Prepare for your inspection',
  sub_title: "Inspections take around 3 hours. Once completed, we'll send you a full report to review together.",
  title_schedule: 'Let’s get an inspection scheduled',
  sub_title_schedule: 'Choose a date and a time that works best for you and we will be there.',
  isYourHomeEmpty:
    'Is your home vacant and ready to inspect? It must be free of personal items, have no active construction, and have water, electricity, and gas turned on.',
  isYourHomeEmptyTitle: 'Is your home vacant and ready to inspect?',
  isYourHomeEmptySubtitle:
    'It must be free of personal items, have no active construction, and have water, electricity, and gas turned on.',
  utilities: {
    title: 'Are the utilities turned on at your\u00a0home?',
    yes: 'Yes',
    no: 'No',
  },
  constructions: {
    title: 'Is there active construction at your\u00a0home?',
    yes: 'Yes',
    no: 'No',
  },
  coliving: {
    title: 'Is someone currently living in your\u00a0home?',
    yes: 'Yes',
    no: 'No',
  },
  furniture: {
    title: 'Does your home have furniture and personal belongings?',
    yes: 'Yes',
    no: 'No',
  },
  furnished: {
    yes: 'Yes, it is.',
    no: 'Not yet.',
    disclaimerNo: {
      regularString:
        'Need help? Your Homeowner Advisor can assist in choosing an affordable moving service, and book your inspection in one go. By having a successful inspection you can ',
      boldString: 'avoid being charged $220 fee if an inspector arrives and cannot complete the job.',
    },
    disclaimerNoHelp:
      'Need help? Your Homeowner Growth Associate can assist you in choosing an affordable moving service, and book your inspection in one go. They’ll even manage the whole process.  By having a successful inspection you can avoid being charged $220 fee if an inspector arrives and cannot complete the job.',
    disclaimerYes: {
      boldString: 'Home not ready? Need to cancel within 24 hours? ',
      regularString: 'A $220 cancel fee may be charged.',
      inspectify: "Great! So you know, this home on the left isn't ready. In that case, ",
      'inspectify.bold': "we will charge $220 if the Pro arrives and the home isn't inspection ready.",
    },
    itemPlaceholder: 'Item',
    highlyValuable: 'This items is highly valuable.',
  },
  presencePreference: {
    title: 'Would you prefer to be there?',
    attendInspection: 'Would you like to attend the inspection?',
    letUsKnow: 'Let us know if you’re planning on attending, and we will make sure the inspector knows.',
    yesLabel: 'I want to be there.',
    yesDescription: '(To meet the Inspector)',
    noLabel: "I don't need to be there.",
    noDescription: '(Fastest way to get it done)',
    letMePick: '(Just let me pick the day)',
    disclaimer: 'Great! We will reach out to  schedule a time.',
  },
  whatWasLeft: {
    title: 'What is left in the home?',
  },
  yourHomeIsAvailable: {
    title: "Let's get an inspection scheduled for your home. ",
    onHoldTitle: 'Your home is available on {date}. Your inspection is set for\n {inspectionDate}.',
    onHoldTitleLoading: 'Your home is available on {date}.',
    subtitle: 'Choose a date and a time that works best for you and we will be there.',
    quickest: 'Quickest',
  },
  'specific_requests.title': 'Is there anything we should know or take a look <span>at specifically?</span>',
  'specific.request.title': 'And is there anything you want us to\u00a0know?',
  'specific_requests.subtitle': `For example, any upgrades or renovations you'd like help with?`,
  'specific_requests.placeholder': `Specific Requests (OPTIONAL)`,
  'furnitures.item': 'Item',
  'furnitures.valuable': 'This item is <span>highly valuable.</span>',
  slotNotAvailable: 'Whoops! That inspection day and time is no longer available. Please pick another day and time.',
  'home.not.available':
    'Need help? Your Homeowner Advisor can assist in choosing an affordable moving service, and book your inspection in one go. By having a successful inspection you can',
  'home.not.available.bold': 'avoid being charged $220 fee if an inspector arrives and cannot complete the job.',
};
