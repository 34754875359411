import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import Label from 'components/Label/Label';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import PropTypes from 'prop-types';
import UserProfilePopoutContainer from '../../containers/UserProfilePopoutContainer/UserProfilePopoutContainer';
import { selectOtherProById } from '../../store/redux/pros/selectors';
import { selectOtherUserById } from '../../store/redux/user/selectors';
import { isEmpty } from '../../utils/validation';
import GeneralIcon, { GENERAL_ICONS } from '../GeneralIcon/GeneralIcon';
import styles from './ProfileTile.module.css';

const cx = classNames.bind(styles);

export const PROFILE_TILE_SPACING = {
  DEFAULT_SPACING: 'spacing',
  LG_SPACING: 'LGspacing',
  NO_SPACING: 'NOspacing',
};

const MODES = {
  COLUMN: 'column',
  DEFAULT: 'default',
};

class ProfileTile extends Component {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.object),
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitles: PropTypes.arrayOf(PropTypes.node),
    profile: PropTypes.string,
    customEmptyProfileIcon: PropTypes.string,
    spacing: PropTypes.oneOf(Object.values(PROFILE_TILE_SPACING)),
    text: PropTypes.array,
    userId: PropTypes.string,
    belongPro: PropTypes.bool,
    // Non-pro employees like Pricing Expert
    belongEmployee: PropTypes.bool,
    alignProfileItems: PropTypes.string,
    scale: PropTypes.number,
    thumbnail: PropTypes.bool,
    emptyImageContainer: PropTypes.string,
    classNameToResizeImage: PropTypes.string,
    // I need to see what Info container does.
    maintenance: PropTypes.bool,
    relative: PropTypes.bool,
    absoluteMobileOverlay: PropTypes.bool,
    profileObject: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(Object.values(MODES)),
    memberProfile: PropTypes.object,
    proProfile: PropTypes.object,
  };

  static defaultProps = {
    labels: [],
    imageUrl: '',
    subtitles: [],
    profile: '',
    text: [],
    spacing: PROFILE_TILE_SPACING.DEFAULT_SPACING,
    customEmptyProfileIcon: GENERAL_ICONS.MALE_PROFILE,
    userId: null,
    belongPro: false,
    belongEmployee: false,
    alignProfileItems: null,
    scale: 1,
    thumbnail: false,
    // special styling when empty images.
    emptyImageContainer: '',
    // special styling when images,
    classNameToResizeImage: '',
    maintenance: false,
    relative: false,
    absoluteMobileOverlay: false,
    mode: MODES.DEFAULT,
  };

  state = {
    showProfilePopup: false,
    hover: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleHideProfileModal, false);
  }

  onMouseEnterHandler = () => {
    this.setState({ hover: true });
  };

  onMouseLeaveHandler = () => {
    this.setState({ hover: false });
  };

  handleShowProfileModal = () => {
    this.setState({
      showProfilePopup: true,
    });
  };

  handleHideProfileModal = () => {
    this.setState({
      showProfilePopup: false,
      hover: false,
    });
  };

  renderLabels() {
    const { title, subtitles, labels, maintenance, mode } = this.props;
    return (
      <div className={cx('info-container', { maintenance }, mode)}>
        <div className={cx('labels')}>
          {labels.map((label, index) => (
            <span key={index} className={cx('label')}>
              <Label {...label} />
            </span>
          ))}
        </div>
        <div className={cx('title')}>{title}</div>
        {subtitles.map((subtitle, index) => (
          <div className={cx('subtitle')} key={index}>
            {subtitle}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      imageUrl,
      customEmptyProfileIcon,
      spacing,
      profile,
      profileObject,
      text,
      alignProfileItems,
      userId,
      belongPro,
      scale,
      emptyImageContainer,
      thumbnail,
      relative,
      absoluteMobileOverlay,
      belongEmployee,
      memberProfile,
      proProfile,
      mode,
    } = this.props;

    const emptyImageContainerSyling = `${emptyImageContainer} ${cx('empty-image', { thumbnail })}`;
    // const classNameToResizeImageSyling = `${customEmptyProfileIcon} ${cx('image', { thumbnail })}`;

    const { showProfilePopup, hover } = this.state;

    let displayButton = false;

    if (belongPro && !isEmpty(proProfile)) {
      displayButton = true;
    } else if (!isEmpty(memberProfile)) {
      displayButton = true;
    }

    const ProfileComponent = displayButton ? Button : 'div';
    const profileComponentProps = displayButton
      ? {
          buttonType: BUTTON_TYPES.HIGHLIGHT,
          className: cx('hide-on-mobile'),
          onMouseEnter: this.onMouseEnterHandler,
          onMouseLeave: this.onMouseLeaveHandler,
          onClick: this.handleShowProfileModal,
        }
      : {
          className: cx('profile-block'),
        };

    return (
      <>
        {userId && (
          <UserProfilePopoutContainer
            key={userId}
            absoluteMobileOverlay={absoluteMobileOverlay}
            relative={relative}
            show={showProfilePopup}
            alignProfileItems={alignProfileItems}
            userId={userId}
            profile={profileObject}
            belongPro={belongPro}
            belongEmployee={belongEmployee}
            profileImage={imageUrl}
            hideProfileModal={this.handleHideProfileModal}
          />
        )}
        <div className={cx('profile-section', `${spacing}`)}>
          <div className={cx('profile-tile', mode)}>
            {userId ? (
              <ProfileComponent {...profileComponentProps}>
                <div className={cx('showOnHover', { thumbnail }, { hover })}>
                  {/* needs to not be hard coded,
                  but need to rethink how we handle General Icons as they are impossible to rescale without bugs
                  My preference is us being able to control the svg sheet instead of our hero designer Randy.
                  */}
                  <GeneralIcon icon={thumbnail ? GENERAL_ICONS.PROFILE_CARD_SCALED : GENERAL_ICONS.PROFILE_CARD} />
                </div>
                {imageUrl ? (
                  <div className={cx('profile-image-wrapper', { thumbnail })}>
                    <ProfileImage
                      profileImage={imageUrl}
                      scale={scale}
                      customEmptyProfileIcon={customEmptyProfileIcon}
                      emptyImageContainer={emptyImageContainerSyling}
                      classNameToResizeImage={cx('image', { thumbnail }, { isPro: !!belongPro || !!belongEmployee })}
                    />
                  </div>
                ) : (
                  <ProfileImage
                    profileImage={imageUrl}
                    scale={scale}
                    customEmptyProfileIcon={customEmptyProfileIcon}
                    emptyImageContainer={emptyImageContainerSyling}
                    classNameToResizeImage={cx('image', { thumbnail }, { isPro: !!belongPro || !!belongEmployee })}
                  />
                )}
                {this.renderLabels()}
              </ProfileComponent>
            ) : (
              <>
                <ProfileImage
                  profileImage={imageUrl}
                  scale={scale}
                  customEmptyProfileIcon={customEmptyProfileIcon}
                  emptyImageContainer={emptyImageContainerSyling}
                  classNameToResizeImage={cx('image', { thumbnail })}
                />
                {this.renderLabels()}
              </>
            )}
          </div>
          {profile && (
            <div className={cx('profile')}>
              <span className={cx('icon')}>
                <GeneralIcon icon={GENERAL_ICONS.QUOTATIONS} scale={0.75} />
              </span>
              <div className={cx('text')}>{profile}</div>
            </div>
          )}
          {!!text.length && (
            <div className={cx('text')}>
              <Space value={SPACE_TYPES.XS} />
              {text.map((texts, index) => (
                <div className={cx('text')} key={index}>
                  {texts}
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}

ProfileTile.MODES = MODES;

const mapStateToProps = (state, { userId }) => ({
  memberProfile: selectOtherUserById(state, userId),
  proProfile: selectOtherProById(state, userId),
});

export default connect(mapStateToProps)(ProfileTile);
