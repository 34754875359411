import { Text } from '@belong/ui';
import clsx from 'clsx';
import Collapsible from 'components/Collapsible/Collapsible';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import { DATE_FORMATS, formatDate } from 'utils/formatDate';

export const MaintenanceRecurrenceApprovalResponsibility = ({ user, isActive = true, date, userRole }) => {
  return (
    <Collapsible
      defaultClassName="block"
      title="Responsibility"
      collapsed
      inner={
        <div className="flex items-center mt-sm justify-between">
          <div>
            <Text className={clsx('text-red block', { 'text-green': isActive })} fontWeight="semibold">
              {isActive ? 'Activated' : 'Turned Off'}
            </Text>
            <Text className="block">{formatDate(date, DATE_FORMATS.DAY_MONTH_YEAR)}</Text>
          </div>
          <div>
            <div className="flex items-center">
              <div className="flex flex-col items-end pr-2sm">
                <Text
                  className="text-right overflow-hidden whitespace-nowrap text-ellipsis max-w-[164px] sm:max-w-none"
                  fontWeight="semibold"
                >
                  {user?.name}
                </Text>
                <Text className="text-right">{userRole}</Text>
              </div>
              <ProfileImage
                profileImage={user?.profileImageUrl}
                classNameToResizeImage="w-xl h-xl object-cover object-center rounded-full border border-solid border-navy"
                scale={0.6}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
