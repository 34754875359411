import React from 'react';
import { Image } from '@belong/ui';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './CurrentHomeMarker.module.css';

const cx = classNames.bind(styles);

const CurrentHomeMarker = ({ isHover }) => {
  return (
    <div>
      <div className={cx('current-home-marker', { hover: isHover })}>
        <Image src="/pricing-flow/new_mappin_home.svg" alt="" />
      </div>
    </div>
  );
};

CurrentHomeMarker.propTypes = {
  isHover: PropTypes.bool.isRequired,
};

export default CurrentHomeMarker;
