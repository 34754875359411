import React, { Component } from 'react';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import PropTypes from 'prop-types';
import HomeInfo from '../../components/display/Home/HomeInfo/HomeInfo';
import AccountUnitDetail from '../../containercomponents/AccountUnitDetail/AccountUnitDetail';
import RenterLeaseDetail from '../../models/renterAccounts/RenterLeaseDetail';

export default class RenterAccountUnitDetailContainer extends Component {
  static propTypes = {
    renterAccountUnitDetail: PropTypes.instanceOf(RenterLeaseDetail).isRequired,
  };

  render() {
    const {
      renterAccountUnitDetail: {
        unitInfo,
        unitInfo: { managedSince, unitId },
        media,
      },
    } = this.props;

    const infoList = [];

    infoList.push(<HomeInfo unitBasic={unitInfo} />);
    infoList.push(
      <span>
        Managed Since <BDate date={managedSince} formatType={DATE_TYPES.SHORT_MONTH_YEAR} />
      </span>
    );

    const thumbnailsItems = media?.map((m) => ({ imageUrl: m.url }));

    return <AccountUnitDetail infoList={infoList} thumbnailsItems={thumbnailsItems} unitId={unitId} doNotShowListing />;
  }
}
