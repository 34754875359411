import { ACTIONS } from './constants';

export const LISTING_PAGE_REDUCER = 'listingPage';

const initialState = {
  listingData: {
    units: [],
    listingsPerRegion: {},
    availableCitiesAndStates: [],
  },
  unitsList: {},
  units: null,
  availableCitiesAndStates: [],
  pageIndex: 1,
  nextPageIndex: 1,
  loading: false,
  valueHovered: null,
};

export default function info(state = initialState, action = {}) {
  let newListingData;
  switch (action.type) {
    case ACTIONS.FETCH_CITIES_STATES_SUCCESS:
      return {
        ...state,
        availableCitiesAndStates: action.result,
      };
    case ACTIONS.LOAD_LISTING_PAGE_DATA:
      newListingData = {
        availableCitiesAndStates: action.result.availableCitiesAndStates,
        units: [],
        listingsPerRegion: { ...state.listingData.listingsPerRegion, ...action.result.listingsPerRegion },
      };
      Object.keys(action.result.listingsPerRegion).forEach((region) => {
        newListingData.units = newListingData.units.concat(action.result.listingsPerRegion[region].items);
      });

      return {
        ...state,
        loading: false,
        listingData: newListingData,
      };
    case ACTIONS.FETCH_LISTING_QUERIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.FETCH_LISTING_QUERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        pageIndex: action.result.pageIndex,
        nextPageIndex: action.result.pageIndex,
        units:
          action.result.pageIndex === state.pageIndex || action.result.pageIndex === 1
            ? [...action.result.items]
            : [...state.units, ...action.result.items],
        unitsList: { ...action.result },
      };
    case ACTIONS.UPDATE_NEXT_PAGE_INDEX:
      return {
        ...state,
        nextPageIndex: action.nextPageIndex,
      };
    case ACTIONS.CLEAR_LISTING_HOMES:
      return {
        ...state,
        unitsList: {},
        units: null,
        pageIndex: 1,
        nextPageIndex: 1,
        loading: false,
      };
    case ACTIONS.SET_HOVERED_VALUE:
      return {
        ...state,
        valueHovered: action.payload,
      };
    default:
      return state;
  }
}
