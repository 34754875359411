import { useEffect, useState } from 'react';

export const useMaintenanceRecurrenceTaskDetails = ({ homeUniqueId, recurrenceType, fetchTask }) => {
  const [taskDetails, setTaskDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchMaintenanceDetails = async () => {
      const response = await fetchTask(recurrenceType, homeUniqueId);
      setTaskDetails(response);
    };
    setIsLoading(true);
    fetchMaintenanceDetails()
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, [recurrenceType, homeUniqueId, fetchTask]);

  return { taskDetails, isLoading };
};

export const useMaintenanceRecurrenceSubtaskDetails = ({ subtaskId, fetchTask }) => {
  const [subtaskDetails, setSubtaskDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchMaintenanceSubtaskDetails = async () => {
      if (subtaskId !== 'default') {
        const response = await fetchTask(subtaskId);
        setSubtaskDetails(response);
      }
    };
    setIsLoading(true);
    fetchMaintenanceSubtaskDetails()
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, [fetchTask, subtaskId]);

  return { subtaskDetails, isLoading };
};
