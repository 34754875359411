import { Fragment, useEffect, useState } from 'react';
import { useModal } from '@belong/common';
import { Toast } from '@belong/ui';
import { CollapseFlowSection } from 'accounts/components/collapse-flow-section/collapse-flow-section';
import { HomeAccessInstructionsSection } from 'accounts/components/home-access-instructions-section/home-access-instructions-section';
import { UpcomingAppointmentsSection } from 'accounts/components/upcoming-appointments-section/upcoming-appointments-section';
import Address from 'models/common/Address';
import UnitLease from 'models/common/UnitLeaseDetail';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { HomeImprovementsTracking } from './home-improvements-tracking/home-improvements-tracking';

type SetupFlowSectionsProps = {
  properties: [HomeownerAccountProperty];
  propertySection: {
    sectionTitle: string;
    unitId: string;
    timeZone: string;
    subSections: {
      name: string;
      isCompleted: boolean;
    }[];
  }[];
  accountSection: {
    sectionTitle: string;
    unitId: string;
    timeZone: string;
    subSections: {
      name: string;
      isCompleted: boolean;
    }[];
  };
  shouldShowCommonSteps: boolean;
  openInitialModal: (unit: UnitLease, address: Address) => void;
};

export function SetupFlowSections({
  properties,
  propertySection,
  accountSection,
  shouldShowCommonSteps,
  openInitialModal,
}: SetupFlowSectionsProps) {
  const [isToastOpen, openToast, closeToast] = useModal();
  const urlParams = new URLSearchParams(window.location.search);
  const hoFixingDate = urlParams.get('hoFixingDate');
  const [memoizedFixingDate] = useState<string | null>(hoFixingDate);

  useEffect(() => {
    if (hoFixingDate) {
      openToast();
    }
  }, [openToast]);

  const handleToastClose = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('hoFixingDate');
    window.history.replaceState({}, '', url.toString());
    closeToast();
  };

  return (
    <>
      {propertySection.map((section) => {
        const unitProperty = properties?.find((property) =>
          property.units.some((unit) => unit?.basicInfo?.unitId === section?.unitId)
        );

        const selectedUnit = unitProperty?.units?.find((unit) => unit?.basicInfo?.unitId === section?.unitId);

        const inspectionScheduleSubSection = section.subSections.find(
          (subSection) => subSection.name === 'InspectionSchedule'
        );

        return (
          <Fragment key={section.unitId}>
            <UpcomingAppointmentsSection
              coverageRegionId={unitProperty.basicInfo.coverageRegionId}
              timeZone={section.timeZone}
              unit={selectedUnit}
            />
            {inspectionScheduleSubSection?.isCompleted && <HomeAccessInstructionsSection unit={selectedUnit} />}

            <HomeImprovementsTracking section={section} />

            <CollapseFlowSection
              properties={properties}
              key={section.sectionTitle}
              section={section}
              shouldShowCommonSteps={shouldShowCommonSteps}
              openInitialModal={openInitialModal}
            />
          </Fragment>
        );
      })}
      {shouldShowCommonSteps && accountSection && (
        <div className="flex flex-col mb-xl">
          <CollapseFlowSection
            properties={properties}
            section={accountSection}
            shouldShowCommonSteps={shouldShowCommonSteps}
            openInitialModal={openInitialModal}
          />
        </div>
      )}
      <Toast isVisible={isToastOpen} onClose={handleToastClose} variant="default">
        {memoizedFixingDate === 'completed'
          ? `Great, We'll confirm the work so we can prepare your home to be\u00a0loved!`
          : `Success! You confirmed that you are on track to finish ${memoizedFixingDate}`}
      </Toast>
    </>
  );
}
