const stringMap = {
  bed: { s: 'bed', p: 'beds' },
  step: { s: 'step', p: 'steps' },
  'full bath': { s: 'full bath', p: 'full baths' },
  bath: { s: 'bath', p: 'baths' },
  unit: { s: 'unit', p: 'units' },
  renter: { s: 'renter', p: 'renters' },
  resident: { s: 'resident', p: 'residents' },
  home: { s: 'home', p: 'homes' },
  these: { s: 'this', p: 'these' },
  Improvement: { s: 'Improvement', p: 'Improvements' },
  // pets
  day: { s: 'day', p: 'days' },
  fish: { s: 'fish', p: 'fishes' },
  goldfish: { s: 'goldfish', p: 'goldfishes' },
  canary: { s: 'canary', p: 'canaries' },
  'sydney silky': { s: 'sydney silky', p: 'sydney silkies' },
  'selkirk rex': { s: 'selkirk rex', p: 'selkirk rexes' },
  sphynx: { s: 'sphynx', p: 'sphynxes' },
  'bouvier des flandres': { s: 'bouvier des flandres', p: 'bouvier des flandres' },
  'bouledogue francais': { s: 'bouledogue francais', p: 'bouledogue francais' },
  mouse: { s: 'mouse', p: 'mice' },
  pony: { s: 'pony', p: 'ponies' },
  ostrich: { s: 'ostrich', p: 'ostriches' },
  goose: { s: 'goose', p: 'geese' },
  is: { s: 'is', p: 'are' },
  matter: { s: 'matters', p: 'matter' },
  Way: { s: 'Way', p: 'Ways' },
};

const noFormat = (s) => s;

export const pluralize = (value, text, format = noFormat) => {
  if (value && Number(value) === 1) {
    return `${value} ${stringMap[text] ? format(stringMap[text].s) : text}`;
  }

  return `${value} ${stringMap[text] ? format(stringMap[text].p) : `${text}s`}`;
};

export const pluralizeWithoutValue = (value, text, format = noFormat) => {
  if (value && Number(value) === 1) {
    return `${stringMap[text] ? format(stringMap[text].s) : text}`;
  }

  return `${stringMap[text] ? format(stringMap[text].p) : `${text}s`}`;
};

export const pluralizeRaw = (text, format = noFormat) => {
  return `${stringMap[text] ? format(stringMap[text].p) : `${text}s`}`;
};
