import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './DownshiftItem.module.css';

const cx = classNames.bind(styles);

export default class DownshiftItem extends React.PureComponent {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool,
    innerRef: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    const { isActive, isSelected, innerRef, className, ...other } = this.props;

    return <li {...other} ref={innerRef} className={cx('li', { isActive, isSelected }, className)} />;
  }
}
