import React from 'react';
import { DATE_FORMATS, formatDateTime, formatMoney } from '@belong/common';
import { IconBranch } from '@belong/icons';
import { Button, Text } from '@belong/ui';
import { useFastMaintenanceSettings } from 'hooks/useFastMaintenanceSettings';
import { formatString } from 'strings';
import { ACCOUNTS_EARNINGS } from 'strings/accounts/earnings';

type SplitPaymentsBannerProps = {
  navigateToSplitPayments: () => void;
  billGenerationOn: string;
  withPlanItButton?: boolean;
  maxCashBack?: number;
  handleBillsPayment?: () => void;
  message?: string | undefined;
  showPayButton?: boolean;
  showAutoSplitLabel?: boolean;
};

export function SplitPaymentsBanner({
  navigateToSplitPayments,
  billGenerationOn,
  withPlanItButton = true,
  maxCashBack = 0,
  handleBillsPayment,
  message,
  showPayButton = false,
  showAutoSplitLabel = false,
}: SplitPaymentsBannerProps) {
  const { isEnabled } = useFastMaintenanceSettings();
  const date = formatDateTime({ dateTime: billGenerationOn, format: DATE_FORMATS.MONTH_DAY });

  return (
    <div className="splitBanner flex w-full flex-col my-sm md:flex-row">
      <div className="flex w-full items-center">
        <div className="mr-2sm rounded-full p-xs bg-lighter-green flex items-center">
          <IconBranch height={14} className="text-green" />
          <Text variant="p1" fontWeight="semibold" className="whitespace-nowrap ml-2xs text-dark-green">
            {`${ACCOUNTS_EARNINGS['section.split.action']} ···`}
          </Text>
        </div>
        <div>
          {maxCashBack === 0 ? (
            <>
              {showAutoSplitLabel ? (
                <>
                  <Text variant="body" className="mr-sm text-p1 md:text-body">
                    {formatString(ACCOUNTS_EARNINGS['section.split.auto'])}
                  </Text>
                </>
              ) : (
                <>
                  <Text variant="body" className="mr-sm text-p1 md:text-body">
                    {formatString(ACCOUNTS_EARNINGS['section.split.zero'])}
                    {formatString(ACCOUNTS_EARNINGS['section.split.zeroSub'](date))}
                  </Text>
                </>
              )}
            </>
          ) : (
            <>
              {showAutoSplitLabel ? (
                <>
                  <Text variant="body" className="mr-sm text-p1 md:text-body">
                    {formatString(ACCOUNTS_EARNINGS['section.split.auto'])}
                  </Text>
                </>
              ) : (
                <>
                  <Text variant="body" className="mr-sm text-p1 md:text-body">
                    {formatString(
                      ACCOUNTS_EARNINGS['section.split.title'](formatMoney(maxCashBack, 'DOLLARS_NO_CENTS'))
                    )}{' '}
                    {formatString(ACCOUNTS_EARNINGS['section.split.subtitle']())}
                  </Text>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {withPlanItButton && (
        <div>
          {/* {isEnabled && (
            <>
              <div className="flex justify-end mt-lg md:mt-0">
                <Button size="small" variant="outline" buttonType="primary" onClick={navigateToSplitPayments}>
                  <div className="flex items-center">
                    <Text variant="body" fontWeight="semibold" className="mr-xs">
                      {ACCOUNTS_EARNINGS['section.split.action']}
                    </Text>
                  </div>
                </Button>
                {showPayButton && (
                  <div className="ml-2sm ">
                    <Button size="small" variant="solid" buttonType="primary" onClick={handleBillsPayment}>
                      <div className="flex items-center">
                        <Text variant="body" fontWeight="semibold" className="mr-xs">
                          {ACCOUNTS_EARNINGS['section.split.actionPay']}
                        </Text>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          {!isEnabled && (
            <>
              <div className="flex justify-end mt-lg md:mt-0">
                <Button variant="solid" buttonType="primary" onClick={navigateToSplitPayments}>
                  <div className="flex items-center">
                    <Text variant="body" fontWeight="semibold" className="mr-xs">
                      {ACCOUNTS_EARNINGS['section.split.action']}
                    </Text>
                  </div>
                </Button>
              </div>
            </>
          )} */}
          <div className="flex justify-end mt-lg md:mt-0">
            <Button variant="solid" buttonType="primary" onClick={navigateToSplitPayments}>
              <div className="flex items-center">
                <Text variant="body" fontWeight="semibold" className="mr-xs">
                  {ACCOUNTS_EARNINGS['section.split.action']}
                </Text>
              </div>
            </Button>
          </div>

          {typeof message !== 'undefined' ? <p className="mt-xs">{message}</p> : null}
        </div>
      )}
    </div>
  );
}
