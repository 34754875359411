import SpendingPreferenceModel from 'models/homeownerOnboarding/SpendingPreferenceModel';
import MaintenancePreferenceModel from 'models/homeownerOnboarding/MaintenancePreferenceModel';

export default class HomeownerOnboardingInfoModel {
  constructor(data = {}) {
    this.question = data.question;
    this.additionalComments = data.additionalComments;
    this.involvementLevel = data.involvementLevel;
    this.maintenancePreferences = data.maintenancePreferences
      ? data.maintenancePreferences.map((maintenancePrefs) => new MaintenancePreferenceModel(maintenancePrefs))
      : [];
    this.spendingPreferences = data.spendingPreferences
      ? data.spendingPreferences.map((spendingPrefs) => new SpendingPreferenceModel(spendingPrefs))
      : [];
  }
}
