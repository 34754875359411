import classNames from 'classnames/bind';
import { Text } from 'design-system';
import styles from './UnitAddressTitle.module.css';

type UnitTitleProps = {
  address: string;
};

const cx = classNames.bind(styles);

export function UnitAddressTitle({ address }: UnitTitleProps): JSX.Element {
  return (
    <div className={cx('unit-address-title')}>
      <Text fontWeight="semibold" className={cx('unit-address-title__text')}>
        {address}
      </Text>
    </div>
  );
}
