export const HOMEOWNER_SELL_STRINGS = {
  title: 'Looking to sell your home?',
  brokerage_fee: 'Apply management fees paid to date towards our 6% brokerage fee.',
  inquire_now: 'Inquire Now',
  contact: 'Inquire with Owen Savir, Belong’s president',
  email: 'president@belonghome.com',
  subtitle: 'The same extraordinary Belong service, <span>now for selling.</span>',
  subject: 'Inquire about selling with Belong',
  information: [
    'Prepping your home <span>for amazing showings</span>',
    'Market-based pricing <span>and analysis</span>',
    'Listings everywhere buyers <span>are looking</span>',
    'Agent-hosted open homes <span>and tours</span>',
    'Buyer relations <span>& management</span>',
    'Closing the sale',
  ],
};
