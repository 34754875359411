import { useSelector } from 'react-redux';
import { getStepByName } from 'accounts/utils/flow-steps';
import { getUnitsFromProperties } from 'accounts/utils/units';
import { groupBy } from 'lodash-es';
import { FlowType, SetupDataTypes } from 'models/enums';
import { selectUser } from 'store/redux/user/selectors';

export function useSteps({ properties, setupFlows }) {
  const user = useSelector(selectUser);

  if (!properties || !setupFlows?.length) {
    return {
      steps: null,
      allStepsByGate: null,
    };
  }

  const units = getUnitsFromProperties(properties);
  let steps = [];

  for (const setupFlow of setupFlows) {
    steps = steps.concat(
      setupFlow?.steps
        .filter((step) => setupFlow.flowType !== FlowType.HomeownerSetup || step.dataType !== SetupDataTypes.Home)
        ?.map((step) => {
          const result = {
            ...step,
            ...getStepByName(step.stepName),
            user,
            flowType: setupFlow.flowType,
            // UserId | PropertyId | HomeId
            dataRelId: user?.userId,
            flowId: setupFlow.uniqueId,
            flowStatus: setupFlow.status,
          };

          if (step.dataType === SetupDataTypes.Property) {
            const propertyData = properties.find((property) => property.basicInfo.id === step.dataId);

            result.property = propertyData;
            result.dataRelId = propertyData?.basicInfo.propertyId;
          }

          if (step.dataType === SetupDataTypes.Home) {
            const unitData = units.find((unit) => unit.basicInfo.id === step.dataId);
            const propertyData = properties.find((property) =>
              property.units.some((unit) => unit.basicInfo.id === unitData?.basicInfo.id)
            );

            result.unit = unitData;
            result.property = propertyData;
            result.dataRelId = unitData?.basicInfo.unitId;
          }

          return result;
        })
    );
  }

  return {
    steps,
    allStepsByGate: groupBy(steps, (step) => step.gate),
  };
}
