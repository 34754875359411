import { pascalCase } from '@belong/common';

export function convertKeysToPascalCase(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToPascalCase(item));
  }

  const result: { [key: string]: any } = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const pascalCaseKey = pascalCase(key);
      result[pascalCaseKey] = convertKeysToPascalCase(obj[key]);
    }
  }

  return result;
}
