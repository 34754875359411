import { Text } from '@belong/ui';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { OTHER } from 'accounts/constants/home-keys';
import { HOME_INSPECTION_STRINGS } from 'accounts/constants/strings/home-inspection.strings';
import { HOME_KEYS_STRINGS } from 'accounts/constants/strings/home-keys.strings';
import { useHomeKeys } from 'accounts/hooks/homeowners/use-home-keys';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { getKeyFeaturesList, getKeyFormName, extractUnit } from 'accounts/utils/home-keys';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import { FeaturesComponentFinalFormAdapter } from 'components/FeaturesComponent/FeaturesComponent';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import Icon, { ICONS } from 'components/Icon/Icon';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskRemoveWhiteSpace, unmaskRemoveWhiteSpace } from 'components/Input/masks';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import { format } from 'date-fns';
import { camelCase } from 'es-toolkit';
import AddressField from 'fields/StandardFields/AddressField/AddressField';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEmpty } from 'lodash-es';
import { KeysDeliveredBy, StepStatus } from 'models/enums';
import { formatString } from 'strings';
import { required } from 'utils/validation';

export function HomeKeys() {
  const { currentStep, selectedUnit, currentStepFormData, handleSaveAndNext, goPreviousStep } = useSetupFlowStep();

  const isAdoptedResident = selectedUnit?.isAdoptedAgreement;

  const { deliveryMethods, hasHoa, isSingleFamilyTypeProperty, isUnitInspectionCompleted } = useHomeKeys({
    currentStep,
    isAdoptedResident,
  });

  const featuresList = getKeyFeaturesList({ hasHoa });

  const showInspectionDeliveryMethod = false;

  function handleSubmit(values) {
    const keysList = [];

    Object.keys(values.keys.keysList).forEach((k) => {
      const keyObject = values.keys.keysList[k];

      if (Array.isArray(keyObject)) {
        keyObject.forEach((otherKeyObj) => {
          if (otherKeyObj.selected) {
            const keyServerObject = {
              ...otherKeyObj,
              name: otherKeyObj.keyName,
            };
            keyServerObject.hasNoKey = !!keyServerObject.keyDetail?.checkboxValue;
            keyServerObject.keyDetails = keyServerObject.keyDetail?.inputValue;

            delete keyServerObject.keyDetail;

            keysList.push(keyServerObject);
          }
        });
      } else if (keyObject.selected) {
        const keyServerObject = {
          ...keyObject,
          name: keyObject.keyName,
        };

        const keyObjectName = HOME_KEYS_STRINGS[`features.${camelCase(k)}`];

        keyServerObject.name = keyObjectName;
        keyServerObject.hasNoKey = !!keyServerObject.keyDetail?.checkboxValue;
        keyServerObject.keyDetails = keyServerObject.keyDetail?.inputValue;

        delete keyServerObject.keyDetail;

        keysList.push(keyServerObject);
      }
    });

    let submitValues = { ...values };

    submitValues = {
      ...values,
      keyUpdateModel: {
        keys: {
          ...(values.keys.addressModel && {
            addressModel: values.keys.addressModel,
          }),
          keysHandsOff: values.keys.keysHandsOff,
          keysList: [...keysList],
        },
        mailBox: values.mailBox ?? {},
      },
      inspectionPreferencesModel: values.inspectionPreferences,
    };

    delete submitValues.keys;
    delete submitValues.mailBox;
    delete submitValues.inspectionPreferences;

    handleSaveAndNext(submitValues);
  }

  function getInitialValues() {
    let initialValues: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

    if (isEmpty(currentStepFormData) || (!currentStepFormData.keys && !currentStepFormData.keysModel?.keys)) {
      initialValues = {
        keys: {
          keysList: [],
        },
      };
    } else {
      initialValues = structuredClone(currentStepFormData);

      initialValues.keys = initialValues.keysModel?.keys;
    }

    const deliveredBy = currentStepFormData?.keysModel?.keys?.keysHandsOff?.deliveredBy;

    if (deliveredBy === KeysDeliveredBy.Inspection) {
      if (!showInspectionDeliveryMethod) {
        initialValues.keysModel.keysHandsOff = {
          ...initialValues.keys.keysHandsOff,
          deliveredBy: null,
        };
      }
    }

    if (deliveredBy === KeysDeliveredBy.BelongLockbox) {
      if (currentStep.status === StepStatus.Created) {
        initialValues.keysModel.keysHandsOff.requestedDate = null;
      } else {
        const requested = new Date(initialValues.keysModel.keys.keysHandsOff.requestedDate);
        initialValues.keysModel.keys.keysHandsOff.requestedDate = format(
          new Date(requested.valueOf() + requested.getTimezoneOffset() * 60 * 1000),
          'MM/dd/yy'
        );
      }
    }

    const keysList = currentStepFormData?.keysModel?.keys?.keysList;

    const formKeysList = {};
    const otherKeys = [];
    const serverKeysList = Array.isArray(keysList) ? structuredClone(keysList) : [];

    serverKeysList.forEach((key) => {
      const keyFormName = getKeyFormName(key.name);

      if (keyFormName === OTHER) {
        otherKeys.push({
          ...key,
          keyName: key.name,
          keyDetail: {
            inputValue: key.keyDetails,
            checkboxValue: key.hasNoKey,
          },
          selected: true,
        });
      } else {
        const keyFormObject = { ...key };
        delete keyFormObject.name;
        formKeysList[keyFormName] = {
          ...keyFormObject,
          keyDetail: {
            inputValue: keyFormObject.keyDetails,
            checkboxValue: keyFormObject.hasNoKey,
          },
          selected: true,
        };
      }
    });

    initialValues.keys.keysList = formKeysList;

    if (otherKeys.length) {
      initialValues.keys.keysList.Other = [...otherKeys];
    } else {
      initialValues.keys.keysList.Other = [{}];
    }

    initialValues.keys.keysHandsOff = initialValues.keysModel?.keys?.keysHandsOff;
    initialValues.mailBox = initialValues.keysModel?.mailBox;

    const {
      streetAddress: mailingStreetAddress,
      city: mailingCity,
      state: mailingState,
      outsideUS,
    } = currentStepFormData?.homeownerMailingAddress ?? {};

    initialValues.homeownerMailingAddress = {
      ...currentStepFormData?.homeownerMailingAddress,
      completeAddress: `${mailingStreetAddress} ${mailingCity} ${mailingState}`,
    };

    if (
      initialValues.keys?.keysHandsOff?.deliveredBy === 'BelongLockbox' &&
      initialValues.keys?.keysHandsOff?.deliveryTo
    ) {
      const [streetAddress, city, stateWithZIPCode] = initialValues.keys.keysHandsOff.deliveryTo.split(',');
      const [state, zipcode] = stateWithZIPCode.trim().split(' ');

      const unit = extractUnit(streetAddress);

      const { unitNumber: mailingUnitNumber, zipcode: mailingZipCode } =
        currentStepFormData?.homeownerMailingAddress ?? {};

      const isSameStateAndZipcode = state === mailingState && zipcode === mailingZipCode;

      if (unit) {
        const unitNumber = unit.replace('Unit', '').replace('#', '').trim();
        const addressWithoutUnit = streetAddress.replace(` ${unit}`, '').trim();

        initialValues.keys.addressModel = {
          unitNumber,
          completeAddress: `${addressWithoutUnit}, ${city}, ${state}`,
          streetAddress: addressWithoutUnit,
        };

        if (
          isSameStateAndZipcode &&
          addressWithoutUnit === mailingStreetAddress &&
          unitNumber === mailingUnitNumber &&
          !outsideUS
        ) {
          initialValues.sameAsMailingAddress = true;
        }
      } else {
        initialValues.keys.addressModel = {
          completeAddress: `${streetAddress}, ${city}, ${state}`,
          streetAddress,
        };

        if (isSameStateAndZipcode && streetAddress === mailingStreetAddress && !outsideUS) {
          initialValues.sameAsMailingAddress = true;
        }
      }

      initialValues.keys.addressModel = {
        ...initialValues.keys.addressModel,
        city: city.trim(),
        state,
        zipcode,
        country: 'US',
      };

      if (!initialValues.sameAsMailingAddress) {
        initialValues.backupAddress = { ...initialValues.keys.addressModel };
      }
    } else if (!outsideUS) {
      initialValues.keys.addressModel = { ...initialValues.homeownerMailingAddress };
      initialValues.sameAsMailingAddress = true;
    }

    return { ...initialValues };
  }

  function handleValidate(fields) {
    const hasSelected = Object.keys(fields).find((key) => {
      if (Array.isArray(fields[key])) {
        // Other is an array.
        return Boolean(fields[key].find((multiKey) => multiKey.selected));
      }
      return fields[key]?.selected;
    });
    if (hasSelected) {
      return undefined;
    } else {
      const allFields = Object.values(featuresList).reduce(
        (a, b) => ({
          ...a,
          [b.name]:
            b.name === OTHER ? [{ keyName: 'Required', type: 'Required' }] : { selected: false, type: 'Required' },
        }),
        {}
      );
      return allFields;
    }
  }

  return (
    <StepFormLayout
      handleBackStep={goPreviousStep}
      onSubmit={handleSubmit}
      title={HOME_KEYS_STRINGS.title}
      subTitle={HOME_KEYS_STRINGS.sub_title_new}
      initialValues={getInitialValues()}
      doneLabel={HOME_KEYS_STRINGS.done_label}
      customError="keysHandsOff.requestedDate"
      disableProgressBar
      getForm={({ form, values }) => {
        return (
          <>
            <FormLayout.Section>
              <Field
                name="keys.keysList"
                component={FeaturesComponentFinalFormAdapter}
                validate={handleValidate}
                featuresList={featuresList}
                showTrashButton={false}
                showCheckmark
                form={form}
              />
            </FormLayout.Section>
            {isAdoptedResident ? null : (
              <FormLayout.Section
                sectionTitle={HOME_INSPECTION_STRINGS.presencePreference.attendInspection}
                sectionSubTitle={HOME_INSPECTION_STRINGS.presencePreference.letUsKnow}
              >
                <Field
                  name="inspectionPreferences.presencePreference"
                  component={SelectorFinalFormAdapter}
                  validate={required}
                  fluid
                  buttons={[
                    {
                      icon: <Icon icon={ICONS.INSPECTION_DELIVERY.DEFAULT} responsive className="mt-sm" />,
                      iconSelected: <Icon icon={ICONS.INSPECTION_DELIVERY.INVERSE} responsive className="mt-sm" />,
                      subLabel: (
                        <div className="absolute left-0 top-0 m-xs px-2xs rounded-small bg-green text-white">
                          Fastest
                        </div>
                      ),
                      label: (
                        <>
                          <p className="text-body leading-p1">
                            {formatString(HOME_INSPECTION_STRINGS.presencePreference.noLabel)}
                          </p>
                          <p className="text-p1 leading-p1">
                            {formatString(HOME_INSPECTION_STRINGS.presencePreference.letMePick)}
                          </p>
                        </>
                      ),
                      key: false,
                    },
                    {
                      icon: <Icon icon={ICONS.INSPECTION_PRESENT.DEFAULT} responsive className="sm:mt-sm" />,
                      iconSelected: <Icon icon={ICONS.INSPECTION_PRESENT.INVERSE} responsive className="sm:mt-sm" />,
                      label: (
                        <>
                          <p className="text-body leading-p1">
                            {formatString(HOME_INSPECTION_STRINGS.presencePreference.yesLabel)}
                          </p>
                          <p className="text-p1 leading-p1">
                            {formatString(HOME_INSPECTION_STRINGS.presencePreference.yesDescription)}
                          </p>
                        </>
                      ),
                      key: true,
                    },
                  ]}
                />
              </FormLayout.Section>
            )}
            {!isUnitInspectionCompleted && (
              <>
                <FormLayout.Section sectionTitle={HOME_KEYS_STRINGS['delivery.keysTitle']}>
                  <Field
                    name="keys.keysHandsOff.deliveredBy"
                    component={SelectorFinalFormAdapter}
                    type={SELECTOR_TYPES.SELECTOR}
                    validate={required}
                    buttonClassName="w-button-default"
                    fluid={false}
                    buttons={deliveryMethods}
                    onChangeCustom={() => {
                      form.change('keys.keysHandsOff.lockBoxCode', null);
                      form.change('keys.keysHandsOff.accessInstructions', null);
                    }}
                  />
                  {values.keys?.keysHandsOff?.deliveredBy === KeysDeliveredBy.BelongLockbox && (
                    <GreenInfoBox>
                      <Text>{HOME_KEYS_STRINGS['delivery.belong.lockbox']}</Text>
                    </GreenInfoBox>
                  )}
                  {values.keys?.keysHandsOff?.deliveredBy === 'Other' && (
                    <GreenInfoBox>
                      <Text>{HOME_KEYS_STRINGS['delivery.other.banner']}</Text>
                    </GreenInfoBox>
                  )}
                </FormLayout.Section>
                {values.keys?.keysHandsOff?.deliveredBy === KeysDeliveredBy.Lockbox && (
                  <FormLayout.Section sectionTitle={HOME_KEYS_STRINGS['delivery.lockboxTitle']}>
                    <div className="w-full grid grid-cols-1 lg:grid-cols-4 lg:gap-sm">
                      <div className="contents lg:block lg:col-span-3">
                        <Field
                          name="keys.keysHandsOff.accessInstructions"
                          component={InputFinalFormAdapter}
                          placeholder={HOME_KEYS_STRINGS['delivery.lockboxAccessInstructions']}
                          validate={required}
                          textarea
                        />
                      </div>
                      <Field
                        name="keys.keysHandsOff.lockBoxCode"
                        component={InputFinalFormAdapter}
                        placeholder={HOME_KEYS_STRINGS['delivery.lockboxCode']}
                        mask={maskRemoveWhiteSpace}
                        unmask={unmaskRemoveWhiteSpace}
                        validate={required}
                      />
                    </div>
                  </FormLayout.Section>
                )}
                {values.keys?.keysHandsOff?.deliveredBy === KeysDeliveredBy.BelongLockbox && (
                  <>
                    <div className="mb-2xl">
                      <Text as="span" variant="h3" fontWeight="semibold">
                        Lockbox shipping address
                      </Text>
                      <div className="flex flex-col -ml-2sm">
                        <AddressField
                          disabled={values.sameAsMailingAddress}
                          placeholder="Address"
                          form={form}
                          name="keys.addressModel"
                          layout="SingleLine"
                          onSelectCallback={(newAddress) => {
                            form.change('backupAddress', newAddress);
                          }}
                        />
                      </div>
                      {!values?.homeownerMailingAddress?.outsideUS && (
                        <Field
                          name="sameAsMailingAddress"
                          component={CheckboxFinalFormAdapter}
                          label="SAME AS MAILING ADDRESS"
                          alignWithField={false}
                          alignCenter
                          onClickCustom={(newSameAsMailingAddress: boolean) => {
                            if (newSameAsMailingAddress) {
                              form.change('backupAddress', values.keys.addressModel);
                              form.change('keys.addressModel', values.homeownerMailingAddress);
                            } else {
                              form.change('keys.addressModel', values.backupAddress);
                            }
                          }}
                        />
                      )}
                    </div>
                    <FormLayout.Section sectionTitle={HOME_KEYS_STRINGS['delivery.mailboxIssueTitle']}>
                      <Field
                        name="mailBox.issuesReceivingPackages"
                        component={SelectorFinalFormAdapter}
                        type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                        validate={required}
                        buttons={[
                          {
                            key: false,
                            label: HOME_KEYS_STRINGS['delivery.mailboxIssueNo'],
                          },
                          {
                            key: true,
                            label: HOME_KEYS_STRINGS['delivery.mailboxIssueYes'],
                          },
                        ]}
                      />
                      {values.mailBox?.issuesReceivingPackages && (
                        <Field
                          name="mailBox.issuesReceivingPackagesDetails"
                          component={InputFinalFormAdapter}
                          placeholder={HOME_KEYS_STRINGS['delivery.mailboxIssueDetails']}
                          validate={required}
                          textarea
                        />
                      )}
                    </FormLayout.Section>
                  </>
                )}
                {values.keys?.keysHandsOff?.deliveredBy === 'Other' && (
                  <div className="-mt-sm mb-2xl">
                    <Field
                      name="keys.keysHandsOff.accessInstructions"
                      component={InputFinalFormAdapter}
                      placeholder="Any particular detail we should know about?"
                      textarea
                    />
                  </div>
                )}
              </>
            )}
            {!isSingleFamilyTypeProperty && (
              <FormLayout.Section sectionTitle={HOME_KEYS_STRINGS['delivery.mailboxTitle']}>
                <div className="w-full grid grid-cols-1 lg:grid-cols-4 lg:gap-sm">
                  <div className="contents lg:block lg:col-span-3">
                    <Field
                      name="mailBox.accessInstructions"
                      component={InputFinalFormAdapter}
                      placeholder={HOME_KEYS_STRINGS['delivery.mailboxAccessInstructions']}
                      validate={required}
                      textarea
                    />
                  </div>
                  <Field
                    name="mailBox.number"
                    component={InputFinalFormAdapter}
                    placeholder={HOME_KEYS_STRINGS['delivery.mailboxNumber']}
                  />
                </div>
              </FormLayout.Section>
            )}
          </>
        );
      }}
    />
  );
}
