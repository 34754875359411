import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './SuccessHeader.module.css';

const cx = classNames.bind(styles);

export default class SuccessHeader extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return <div className={cx('successHeader')}>{children}</div>;
  }
}
