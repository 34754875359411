import { withRouter } from 'react-router';
import { BASE_PATHS, BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Link, LinkBase, Text } from '@belong/ui';
import HeaderMain from 'components/HeaderMain/HeaderMain';
import { RouterHeaderNavLink } from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import { DEFAULT_LINKS } from 'consts/links';
import AccountHeaderContainer from 'containers/AccountHeaderContainer/AccountHeaderContainer';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import PropTypes from 'prop-types';

const HeaderContainer = ({ headerMainProps, location: { pathname }, ...rest }) => {
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINTS_WIDTHS.LG;

  return (
    <HeaderMain
      {...headerMainProps}
      navigationComponents={
        headerMainProps?.navigationComponents || [
          ...(!isMobile
            ? [
                <div className="flex mr-sm" key="homeowners">
                  <LinkBase href={BASE_PATHS.HOMEOWNER}>
                    <Text variant="p1" fontWeight="semibold">
                      FOR HOMEOWNERS
                    </Text>
                  </LinkBase>
                </div>,
                <div className="flex" key="residents">
                  <LinkBase href={BASE_PATHS.HOME}>
                    <Text variant="p1" fontWeight="semibold">
                      FOR RESIDENTS
                    </Text>
                  </LinkBase>
                </div>,

                <RouterHeaderNavLink
                  label={DEFAULT_LINKS.HOW_IT_WORKS.label}
                  to={`${pathname}?activeModal=HOW_IT_WORKS`}
                  selected={`${pathname}?activeModal=HOW_IT_WORKS` === pathname}
                  key={DEFAULT_LINKS.HOW_IT_WORKS.id}
                  theme={headerMainProps?.theme}
                  className={isMobile && 'font-semibold'}
                />,
              ]
            : [
                <div className="mb-xl" key="homeowners">
                  <Link href={BASE_PATHS.HOMEOWNER}>
                    <Text className="text-navy" fontWeight="semibold" variant="h3">
                      FOR HOMEOWNERS
                    </Text>
                  </Link>
                </div>,
                <div className="mb-xl" key="residents">
                  <Link href={BASE_PATHS.HOME}>
                    <Text className="text-navy" fontWeight="semibold" variant="h3">
                      FOR RESIDENTS
                    </Text>
                  </Link>
                </div>,
                <div key={DEFAULT_LINKS.HOW_IT_WORKS.id} className="mb-xl">
                  <Link href={`${pathname}?activeModal=HOW_IT_WORKS`}>
                    <Text className="text-navy" fontWeight="semibold" variant="h3">
                      {DEFAULT_LINKS.HOW_IT_WORKS.label}
                    </Text>
                  </Link>
                </div>,
              ]),
          <ContactUs key="ContactUs" dropdownMenu isMobile={isMobile} />,
          <AccountHeaderContainer key="AccountHeaderContainer" theme={headerMainProps?.theme} />,
        ]
      }
      {...rest}
    />
  );
};

HeaderContainer.propTypes = {
  headerMainProps: PropTypes.object,
  location: PropTypes.object.isRequired,
};

HeaderContainer.defaultProps = {
  headerMainProps: null,
};

export default withRouter(HeaderContainer);
