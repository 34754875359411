import React, { Component } from 'react';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import AccountUnitDetail from 'containercomponents/AccountUnitDetail/AccountUnitDetail';
import HomeownerAccountUnitDetail from 'models/homeownerAccounts/HomeownerAccountUnitDetail';
import PropTypes from 'prop-types';
import HomeInfo from '../../components/display/Home/HomeInfo/HomeInfo';

class AccountUnitDetailContainer extends Component {
  static propTypes = {
    accountUnitDetail: PropTypes.instanceOf(HomeownerAccountUnitDetail).isRequired,
  };

  render() {
    const {
      accountUnitDetail: {
        basicInfo,
        basicInfo: { managedSince, isPublished, unitId },
        media,
      },
    } = this.props;
    const infoList = [];

    infoList.push(<HomeInfo unitBasic={basicInfo} />);
    infoList.push(
      <span>
        Managed Since <BDate date={managedSince} formatType={DATE_TYPES.SHORT_MONTH_YEAR} />
      </span>
    );

    const thumbnailsItems = media.map((m) => ({ imageUrl: m.url }));

    return (
      <AccountUnitDetail
        unitId={unitId}
        infoList={infoList}
        thumbnailsItems={thumbnailsItems}
        doNotShowListing={!isPublished}
      />
    );
  }
}

export default AccountUnitDetailContainer;
