import { useSelector, useDispatch } from 'react-redux';
import { formatDateTime, DATE_FORMATS, BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { CalendarFormAdapter } from 'components/Calendar/calendar';
import Field from 'components/Field/Field';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { addMonths, isEqual } from 'date-fns';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { StepFormLayout } from 'lease-signing-flow/components/step-form-layout/step-form-layout';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
import { selectLeaseDuration, updateLeaseDuration } from 'lease-signing-flow/store/duration';
import { formatString } from 'strings';
import { required } from 'utils/validation';

type Props = {
  currentStep: any;
  onSubmit: () => void;
  lease: any;
  steps: any;
};

export function DurationStep({ currentStep, onSubmit, lease, steps }: Props) {
  const { leaseCurrentStartDate, leaseCurrentEndDate, leaseOfferedEndDate, listingLeaseMaxLength } =
    useSelector(selectLeaseDuration);
  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINTS_WIDTHS.SM;
  const dateFormat = width < BREAKPOINTS_WIDTHS.MD ? 'MMM d, yyyy' : 'MMM d yyyy';

  const dateOptions = {
    min: {
      serverDate: formatDateTime({
        dateTime: new Date(leaseOfferedEndDate),
        format: DATE_FORMATS.STRIPPED_ISO,
      }),
      displayDateMonthDate: formatDateTime({
        dateTime: new Date(leaseOfferedEndDate),
        format: dateFormat,
      }),
    },
    max: {
      serverDate: formatDateTime({
        dateTime: addMonths(new Date(leaseCurrentStartDate), listingLeaseMaxLength),
        format: DATE_FORMATS.STRIPPED_ISO,
      }),
      displayDateMonthDate: formatDateTime({
        dateTime: addMonths(new Date(leaseCurrentStartDate), listingLeaseMaxLength),
        format: dateFormat,
      }),
    },
  };

  async function handleSubmit({ leaseEndOnDate, leaseEndCustom }) {
    const body = { leaseEndOnDate };

    if (leaseEndOnDate === 'custom') {
      body.leaseEndOnDate = formatDateTime({ dateTime: leaseEndCustom, format: DATE_FORMATS.STRIPPED_ISO });
    }

    await dispatch(updateLeaseDuration(lease.leaseInfo.basicInfo.leaseId, body));

    onSubmit();
  }

  function getInitialValues() {
    if (isEqual(new Date(leaseCurrentEndDate), new Date(leaseOfferedEndDate))) {
      return { leaseEndOnDate: dateOptions.min.serverDate };
    }

    if (isEqual(new Date(leaseCurrentEndDate), addMonths(new Date(leaseCurrentStartDate), listingLeaseMaxLength))) {
      return { leaseEndOnDate: dateOptions.max.serverDate };
    }

    return { leaseEndOnDate: 'custom', leaseEndCustom: new Date(leaseCurrentEndDate) };
  }

  return (
    <StepFormLayout
      currentStep={currentStep}
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      steps={steps}
      title={LEASE_SIGNING_FLOW_STRINGS['duration.title']}
      getForm={({ initialValues }) => {
        return (
          <>
            <FormLayout.Section
              sectionTitle={LEASE_SIGNING_FLOW_STRINGS['duration.leaseEnd.title']}
              sectionSubTitle={formatString(LEASE_SIGNING_FLOW_STRINGS['duration.leaseEnd.subtitle'], {
                date: formatDateTime({
                  dateTime: leaseCurrentEndDate,
                  format: 'MMM d, yyyy',
                }),
              })}
              className="mt-2xl"
            >
              <Field
                buttons={[
                  {
                    label: dateOptions.min.displayDateMonthDate,
                    key: dateOptions.min.serverDate,
                  },
                  {
                    label: dateOptions.max.displayDateMonthDate,
                    key: dateOptions.max.serverDate,
                    tag: LEASE_SIGNING_FLOW_STRINGS['duration.recommended'],
                  },
                  {
                    label: LEASE_SIGNING_FLOW_STRINGS['duration.custom'],
                    subLabel: formatDateTime({
                      dateTime: initialValues.leaseEndCustom,
                      format: 'MMM d, yyyy',
                    }),
                    centerText: true,
                    key: 'custom',
                  },
                ]}
                component={SelectorFinalFormAdapter}
                name="leaseEndOnDate"
                type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                validate={required}
                buttonClassName="mt-sm px-xl"
                tagClassName="left-0 right-0 w-fit -top-2sm mx-auto my-0"
              />
            </FormLayout.Section>
            <Condition when="leaseEndOnDate" is="custom">
              <FormLayout.Section
                sectionTitle={LEASE_SIGNING_FLOW_STRINGS['duration.leaseEnd.date']}
                sectionTitleClassName={isMobile ? '' : '-mb-sm'}
              >
                <Field
                  component={CalendarFormAdapter}
                  fluid
                  name="leaseEndCustom"
                  validate={required}
                  disabledDays={[
                    {
                      before: new Date(leaseOfferedEndDate),
                    },
                    {
                      after: addMonths(new Date(leaseCurrentStartDate), listingLeaseMaxLength),
                    },
                  ]}
                  rootClassName={isMobile ? '-ml-2xs' : ''}
                  defaultMonth={new Date(leaseCurrentEndDate)}
                />
              </FormLayout.Section>
            </Condition>
          </>
        );
      }}
    />
  );
}
