import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { COOKIES_CONFIG } from '@belong/common';
import { animationsCache } from 'animations/animationsCache';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import MetaNoIndex from 'components/Metatags/MetaNoIndex';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import SingleColumnFlowLayout from 'layouts/SingleColumnFlowLayout/SingleColumnFlowLayout';
import { find, findIndex, flatten, isEmpty, isNil } from 'lodash-es';
import { PostInspectionFlowDataType } from 'models/enums';
import { parseCookies } from 'nookies';
import { ImprovementsReportSuccess } from 'post-inspection/pages/improvements-report-success/improvements-report-success';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { fetchAccountProperties, fetchAccountUnitListings } from 'store/redux/homeowner-accounts/actions';
import {
  fetchActiveImprovements,
  fetchBaseResources,
  fetchImprovementsFlowStep,
  updateImprovementsFlowStep,
  fetchPricingFlowStep,
  updatePricingFlowStep,
} from 'store/redux/post-inspection-flow/actions';
import { fetchPaymentMethods, fetchReports } from 'store/redux/user/actions';
import FullPageSpinner from '../../components/FullPageSpinner/FullPageSpinner';
import Spinner from '../../components/Spinner/Spinner';
import { extractAndSortStepsInGroup, getStepByName } from './step.consts';
import Success from './steps/Success/Success';
import { getStepPathFromConfig, POST_INSPECTION_STEPS_ROUTES } from './steps/routes';
import { getInspectionType, trackFullStoryEvent } from './utils';

const redux = connect(null, {
  fetchActiveImprovements,
  fetchReports,
  fetchAccountUnitListings,
  fetchImprovementsFlowStep,
  updateImprovementsFlowStep,
  fetchAccountProperties,
  fetchBaseResources,
  fetchPaymentMethods,
  fetchPricingFlowStep,
  updatePricingFlowStep,
});

const propTypes = {
  fetchActiveImprovements: PropTypes.func.isRequired,
  fetchImprovementsFlowStep: PropTypes.func.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  updateImprovementsFlowStep: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchBaseResources: PropTypes.func.isRequired,
  fetchPricingFlowStep: PropTypes.func.isRequired,
  updatePricingFlowStep: PropTypes.func.isRequired,
};

const PostInspectionFlow = ({
  fetchActiveImprovements: fetchActiveImprovementsAction,
  updateImprovementsFlowStep: updateImprovementsFlowStepAction,
  fetchImprovementsFlowStep: fetchImprovementsFlowStepAction,
  fetchBaseResources: fetchBaseResourcesAction,
  fetchPaymentMethods: fetchPaymentMethodsAction,
  fetchPricingFlowStep: fetchPricingFlowStepAction,
  updatePricingFlowStep: updatePricingFlowStepAction,
  match,
  history,
}) => {
  const [baseResourcesFetched, setBaseResourcesFetched] = useState(false);
  const [shouldGoNext, setShouldGoNext] = useState(false);
  const [shouldRefreshFlow, setShouldRefreshFlow] = useState(false);
  const [flow, setFlow] = useState(null);
  const [stepData, setStepData] = useState(null);
  const [stepConfigs, setStepConfigs] = useState([]);
  const [reports, setReports] = useState([]);
  const [listings, setListings] = useState([]);
  const [isAdoptedResident, setIsAdoptedResident] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const cookies = parseCookies();
  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  const isComingFromEmailLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('data');
  };

  const flowSource = isComingFromEmailLink() ? 'email' : comeFromTheMobileApp ? 'app' : 'placemat';

  const {
    params: { stepId, group },
    url,
  } = match;

  const isPricingFlow = group === 'pricing';

  const currentStepConfig = find(stepConfigs, { uniqueId: stepId });

  const fullStoryEventMetadata = {
    homeId: currentStepConfig?.home?.homeId,
    source: flowSource,
    flowType: flow?.flowType,
    flowSubType: flow?.configuration?.flowSubType,
    stepEnum: currentStepConfig?.key,
    stepDisplayName: currentStepConfig?.displayName,
  };

  const handleHomeOwnerComingFromEmail = () => {
    /* NOTE: If the user is coming from an email link, we need to redirect him to the next pending step */
    if (!stepConfigs.length) return;

    if (!isComingFromEmailLink()) return;

    setPageLoading(true);

    const currentStepIndex = findIndex(stepConfigs, { uniqueId: stepId });
    const isCurrentStepCompleted = currentStepConfig?.status === 'Completed';
    const nextStepWithCreatedStatusAndNonHidden = stepConfigs.find(
      (stepConfig, index) => index > currentStepIndex && stepConfig.status === 'Created' && !stepConfig.isHidden
    );

    /**
     * This means that the homeowner has not even completed the Intro step.
     */
    if (!isCurrentStepCompleted) {
      setPageLoading(false);

      return;
    }

    if (Object.keys(nextStepWithCreatedStatusAndNonHidden ?? {}).length) {
      history.push(
        getStepPathFromConfig({
          ...nextStepWithCreatedStatusAndNonHidden,
          id: nextStepWithCreatedStatusAndNonHidden.uniqueId,
        })
      );

      return;
    }

    /**
     * If for some reason the next step is not found, we show the Intro step.
     */
    setPageLoading(false);
  };

  useEffect(() => {
    handleHomeOwnerComingFromEmail();
  }, [stepConfigs, stepData]);

  useEffect(() => {
    animationsCache.preload('pricing_animation_adjustment');
    animationsCache.preload('pricing_animation_01');
    animationsCache.preload('pricing_animation_02');
  }, []);

  const getStepResource = (stepConfig) => {
    if (stepConfig?.dataType === PostInspectionFlowDataType.Report) {
      const report = reports.find((reportDetail) => {
        return reportDetail.report.uniqueId === stepConfig.dataUniqueId;
      });

      const listing = listings.find(
        (listingDetail) => listingDetail.homeListingDetailsModel.basicInfo.unitId === stepConfig.home.homeId
      );

      report.homeListingDetailsModel = listing.homeListingDetailsModel;
      return report;
    }

    if (stepConfig?.dataType === PostInspectionFlowDataType.Home) {
      return listings.find(
        (listingDetail) => listingDetail.homeListingDetailsModel.basicInfo.unitId === stepConfig.dataUniqueId
      );
    }

    return null;
  };

  const handleErrorTracking = () => {
    trackFullStoryEvent({
      eventName: 'flow-error',
      metadata: fullStoryEventMetadata,
    });
  };

  const handleSave = async (data, next) => {
    const action = isPricingFlow ? updatePricingFlowStepAction : updateImprovementsFlowStepAction;

    try {
      const updatedStepData = await action(flow.uniqueId, currentStepConfig.key, stepId, data);

      if (next) {
        setStepData(null);
      } else {
        setStepData(updatedStepData);
      }

      const isCurrentStepAlreadyCompleted = currentStepConfig.status === 'Completed';

      if (next && !isCurrentStepAlreadyCompleted) {
        trackFullStoryEvent({
          eventName: 'flow-step-completed',
          metadata: fullStoryEventMetadata,
        });
      }
    } catch (err) {
      handleErrorTracking();
      console.error(err);
    }
  };

  const handleNext = () => {
    setShouldRefreshFlow(true);
    setShouldGoNext(true);
  };

  const handlePrevious = () => {
    const currentHomeStepConfig = stepConfigs.filter(
      (stepConfig) => stepConfig.home.homeId === currentStepConfig.home.homeId
    );

    const currentGroupSteps = extractAndSortStepsInGroup(currentHomeStepConfig, currentStepConfig.group.key);
    const currentStepIndex = findIndex(currentGroupSteps, { uniqueId: stepId });

    const nextStepConfig = currentGroupSteps[currentStepIndex - 1];

    history.push(getStepPathFromConfig({ ...nextStepConfig, id: nextStepConfig.uniqueId }));
  };

  const handleSaveAndNext = async (data) => {
    setPageLoading(true);
    await handleSave(data, true);
    setPageLoading(false);

    handleNext();
  };

  const extractStepConfig = (step, configuration) => {
    const homeListing = find(
      listings,
      (listing) => listing.homeListingDetailsModel.basicInfo.unitId === configuration.homeUniqueId
    );
    const { homeListingDetailsModel: home } = homeListing;

    return {
      ...step,
      ...getStepByName(step.stepName),
      home: {
        homeId: home.basicInfo.unitId,
        propertyId: home.propertyInfo.propertyId,
        propertyType: home.propertyInfo.propertyType,
        employeeAssignments: home.employeeAssignments,
        address: {
          ...home.address,
          unitNumber: home.basicInfo.unitNumber,
        },
        homeUniqueId: home.basicInfo.unitId,
      },
    };
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchPaymentMethodsAction();

        const [listingsData, propertiesData] = await fetchBaseResourcesAction(stepId);

        const { properties } = propertiesData;
        const homes = flatten(
          properties.map((property) =>
            property.units.map((unit) => ({
              ...unit,
              address: property.address,
            }))
          )
        );

        const listingsDataWithAssignments = listingsData.map((listing) => {
          const home = find(homes, (h) => h.basicInfo.unitId === listing.homeListingDetailsModel.basicInfo.unitId);
          return {
            homeListingDetailsModel: {
              ...listing.homeListingDetailsModel,
              address: home.address,
              leases: home.leases,
              residents: home.residents,
              employeeAssignments: home.employeeAssignments,
              inspectionCompletedOn: home.inspectionCompletedOn,
              inspectionScheduledOn: home.inspectionScheduledOn,
              unitAdditional: home.unitAdditional,
            },
          };
        });

        setIsAdoptedResident(homes?.some((unit) => unit.isAdoptedAgreement));
        setListings(listingsDataWithAssignments);
        setBaseResourcesFetched(true);
      } catch (err) {
        handleErrorTracking();
        console.error(err);
      }
    })();
  }, []);

  const updateFlowData = async () => {
    setPageLoading(true);

    try {
      const [postInspectionFlow, reportsData] = await fetchActiveImprovementsAction(stepId);

      if (match.params.group === 'success') {
        setFlow(postInspectionFlow);
      }

      if (match.params.group !== 'success' && url !== `${BASE_PATHS.POST_INSPECTION_FLOW}/improvements/success`) {
        if (isNil(postInspectionFlow) || isEmpty(postInspectionFlow)) {
          history.push(BASE_PATHS.ACCOUNTS);

          return;
        }
      }

      setReports(reportsData);

      if (postInspectionFlow) {
        const { steps, ...flowData } = postInspectionFlow;
        const stepsWithHome = steps.map((step) => extractStepConfig(step, flowData.configuration));

        if (stepId) {
          const currentStepWithHome = find(stepsWithHome, { uniqueId: stepId });

          if (currentStepWithHome) {
            if (currentStepWithHome.stepDataNotRequired) {
              setStepData(null);
            } else {
              const stepAction = isPricingFlow ? fetchPricingFlowStepAction : fetchImprovementsFlowStepAction;
              const stepResponse = await stepAction(flowData.uniqueId, currentStepWithHome.key, stepId);

              setStepData(stepResponse);
            }
          }
        }

        setStepConfigs(stepsWithHome);
        setFlow(flowData);
      }

      setPageLoading(false);
    } catch (err) {
      handleErrorTracking();
      console.error(err);
    }
  };

  useEffect(() => {
    if (!baseResourcesFetched) {
      return;
    }

    updateFlowData();
  }, [stepId, baseResourcesFetched, shouldGoNext]);

  // useEfect for tracking FullStory events.
  useEffect(() => {
    if (!stepConfigs.length || !flow) return;

    const isCurrentStepTheFirstOne = stepConfigs[0].uniqueId === stepId;
    const isCurrentStepCompleted = currentStepConfig?.status === 'Completed';

    if (isCurrentStepTheFirstOne && !isCurrentStepCompleted) {
      return trackFullStoryEvent({
        eventName: 'flow-started',
        metadata: fullStoryEventMetadata,
      });
    }

    if (!isCurrentStepCompleted) {
      trackFullStoryEvent({
        eventName: 'flow-step-started',
        metadata: fullStoryEventMetadata,
      });
    }
  }, [stepConfigs, flow]);

  useEffect(() => {
    if (!shouldRefreshFlow) {
      return;
    }

    const refreshFlow = async () => {
      await updateFlowData();
      setShouldRefreshFlow(false);
    };

    refreshFlow();
  }, [shouldRefreshFlow]);

  useEffect(() => {
    if (!shouldGoNext || shouldRefreshFlow) return;

    const currentHomeStepConfig = stepConfigs.filter(
      (stepConfig) => stepConfig.home.homeId === currentStepConfig.home.homeId
    );

    let finalStepOfAHome = false;

    const currentGroupSteps = extractAndSortStepsInGroup(currentHomeStepConfig, currentStepConfig.group.key);
    const currentStepIndex = findIndex(currentGroupSteps, { uniqueId: stepId });

    const filteredStepConfigs = stepConfigs.filter((config) => {
      return config.home.homeUniqueId === currentStepConfig.home.homeUniqueId;
    });

    if (
      currentStepIndex === currentGroupSteps.length - 1 &&
      currentGroupSteps[currentGroupSteps.length - 1].group.key === 'pricing'
    ) {
      finalStepOfAHome = find(filteredStepConfigs, (stepDetails) => {
        return stepDetails.id === currentGroupSteps[currentGroupSteps.length - 1].id;
      });
    }

    if (currentStepIndex === currentGroupSteps.length - 1) {
      const inspectionType = getInspectionType(flow);

      trackFullStoryEvent({
        eventName: 'flow-completed',
        metadata: {
          ...fullStoryEventMetadata,
          stepEnum: 'success',
          stepDisplayName: 'Success',
        },
      });

      if (finalStepOfAHome && (inspectionType.isMoveOut || inspectionType.isPreMoveOut)) {
        history.push(`${BASE_PATHS.POST_INSPECTION_FLOW}/success/${currentStepConfig.home.homeUniqueId}`);
      } else if (finalStepOfAHome && isAdoptedResident) {
        history.push(BASE_PATHS.POST_INSPECTION_FLOW);
      } else {
        const destination =
          inspectionType.isMoveOut || isAdoptedResident
            ? BASE_PATHS.POST_INSPECTION_FLOW
            : `${BASE_PATHS.POST_INSPECTION_FLOW}/improvements/success`;

        history.push(destination, match.params.step === 'homeowner-fixes' && { from: 'ho-fixes-calendar' });
      }
    } else {
      const nextStepConfig = currentGroupSteps[currentStepIndex + 1];

      history.push(getStepPathFromConfig({ ...nextStepConfig, id: nextStepConfig.uniqueId }));
    }

    setShouldGoNext(false);
  }, [shouldGoNext, shouldRefreshFlow]);

  if (!stepConfigs.length) {
    return (
      <SingleColumnFlowLayout header={!comeFromTheMobileApp}>
        <MetaNoIndex />
        <FullPageSpinner />
      </SingleColumnFlowLayout>
    );
  }

  // we're going to deprecate this page. In order to keep things organized, a new if will be added
  if (match.params.group === 'success') {
    return <Success flow={flow} homeId={match.params.step} listings={listings} allReports={reports} />;
  }

  const inspectionType = getInspectionType(flow);

  if (url === `${BASE_PATHS.POST_INSPECTION_FLOW}/improvements/success`) {
    return <ImprovementsReportSuccess listings={listings} flow={flow} isPreMoveOut={inspectionType.isPreMoveOut} />;
  }

  const currentStepResource = getStepResource(currentStepConfig);

  if (stepId && !currentStepConfig) {
    return <Redirect to={BASE_PATHS.POST_INSPECTION_FLOW} />;
  }

  const headerLink =
    inspectionType?.isMoveOut || inspectionType?.isPreMoveOut || isAdoptedResident
      ? BASE_PATHS.POST_INSPECTION_FLOW
      : `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${currentStepConfig?.home?.propertyId}/setup-flow`;

  const onExitCallback = () => {
    trackFullStoryEvent({
      eventName: 'flow-exited',
      metadata: fullStoryEventMetadata,
    });
  };
  return (
    <SingleColumnFlowLayout
      headerMainProps={{
        fixed: true,
        navigationComponents: [
          <ContactUs key="ContactUs" />,
          <RouterHeaderNavLink key="save" label="SAVE & EXIT" to={headerLink} onClick={onExitCallback} />,
        ],
      }}
      header={!comeFromTheMobileApp}
    >
      <MetaNoIndex />
      {pageLoading && <Spinner fixed higherZindex />}
      {POST_INSPECTION_STEPS_ROUTES.map((StepRoute) => {
        // Sometimes we remove steps on the FE, but the data might still exists on the BE, we filter those.
        const filteredStepConfig = stepConfigs.filter((stepConfig) => getStepByName(stepConfig.name));

        const stepConfigWithLink = filteredStepConfig.map((stepConfig) => ({
          ...stepConfig,
          to: getStepPathFromConfig({ ...stepConfig, id: stepConfig.uniqueId }),
        }));

        return (
          <Route
            key={StepRoute.path}
            path={StepRoute.path}
            render={() => (
              <StepRoute.content
                flow={flow}
                stepConfig={currentStepConfig}
                stepConfigs={stepConfigWithLink}
                stepResource={currentStepResource}
                stepData={stepData}
                onSave={handleSave}
                onSaveAndNext={handleSaveAndNext}
                onNext={handleNext}
                onPrevious={handlePrevious}
                allReports={reports}
              />
            )}
          />
        );
      })}
    </SingleColumnFlowLayout>
  );
};

PostInspectionFlow.propTypes = propTypes;

export default redux(PostInspectionFlow);
