// React imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Date-fns helper imports
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
// Component imports
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskMMDDYYYY, unmaskMMDDYYYY } from 'components/Input/masks';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import Spinner from 'components/Spinner/Spinner';
import { SliderFinalFormAdapter } from 'components/v2/Slider/Slider';
import ListOfApplicationsModal from 'containercomponents/Modals/ListOfApplicationsModal/ListOfApplicationsModal';
import { addDays, addWeeks } from 'date-fns';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isNil, isEmpty } from 'lodash-es';
// Constant & String imports
import { ApplicantType } from 'models/enums/index';
import PropTypes from 'prop-types';
import { showModal } from 'store/redux/modals/actions';
// Redux store imports
import {
  createMoveInDate,
  createMoveInDateLead,
  fetchMoveInDate,
  updateMoveInDate,
  fetchApplicantInfo,
  fetchAllApplicationsForUser,
  fetchLeaseLength,
} from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationId,
  selectResidentApplyForHouseId,
  selectResidentApplicationMoveInDate,
  selectResidentApplicationStatus,
} from 'store/redux/resident-application/selectors';
import { selectUser } from 'store/redux/user/selectors';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import {
  converDateTimeServerResponseToDateTimeObject,
  parseDateTimeInputString,
  shallowCompareDateTimeObjects,
} from 'utils/dateTimeUtils';
import { required, dateValidation, composeValidators, isFutureDateMMDDYY } from 'utils/validation';
import { SCREENS } from '../../../../containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from '../../../../containercomponents/Modals/modal.consts';
import { DATE_SELECTOR_KEYS } from '../../constants';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import { getLeaseDurationOptions } from '../utils';
// SCSS imports
import styles from './MoveInDate.module.css';

const cx = classNames.bind(styles);
const leaseMonths = 12; // default number of months (to be changed)
const leasePreference = 'Longterm';

const { FormLayoutHeader } = FormLayout;

const APPLICATION_STATUS_ENUM = {
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
};

class MoveInDate extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    moveInDate: PropTypes.object,
    updateMoveInDate: PropTypes.func.isRequired,
    fetchMoveInDate: PropTypes.func.isRequired,
    createMoveInDate: PropTypes.func.isRequired,
    createMoveInDateLead: PropTypes.func.isRequired,
    fetchAllApplicationsForUser: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    applicationId: PropTypes.string,
    houseId: PropTypes.string.isRequired,
    applicationStatus: PropTypes.string,
    fetchApplicantInfo: PropTypes.func.isRequired,
    fetchLeaseLength: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  };

  static defaultProps = {
    moveInDate: {},
    applicationId: '',
    applicationStatus: '',
  };

  constructor(props) {
    super(props);

    this.moveInDateObject = {};

    this.state = {
      awaitingFetchComplete: true,
      isSaveAndExit: false,
      showListOfApplicationsModal: false,
      formValues: {},
      leaseDuration: {},
    };

    this.dates = {};
    this.generateDates();
  }

  // On component mount, check if applicationId is available. if it is then check if
  // moveInDate object in redux store is empty and call the fetch data from api action (if required)
  async componentDidMount() {
    const {
      moveInDate,
      applicationId,
      fetchMoveInDate: fetchMoveInDateAction,
      fetchLeaseLength: fetchLeaseLengthAction,
      houseId,
    } = this.props;

    const lease = await fetchLeaseLengthAction(houseId);

    this.setState({ leaseDuration: lease });

    if ((isEmpty(moveInDate) || isNil(moveInDate)) && applicationId !== '') {
      try {
        await fetchMoveInDateAction(applicationId);
      } catch (err) {
        console.error(err);
      }
    }

    this.setAwaitingFetchComplete();
  }

  setAwaitingFetchComplete() {
    this.setState({
      awaitingFetchComplete: false,
    });
  }

  // Returns an object that will be used as the initialValues for the final-form
  // component. The logic to transform the data from redux response to UI/final-form
  // should be placed here.
  getInitialValues() {
    const { moveInDate, applicationId } = this.props;
    const { leaseDuration } = this.state;

    if (applicationId === '' || isEmpty(moveInDate) || isNil(moveInDate)) {
      return {};
    }

    const moveInOnDateObject = converDateTimeServerResponseToDateTimeObject(moveInDate.moveInOn);

    const isAsap = shallowCompareDateTimeObjects(this.dates.ASAP.serverDate, moveInOnDateObject);
    const isNextWeek = shallowCompareDateTimeObjects(this.dates.nextWeek.serverDate, moveInOnDateObject);
    const isNextTwoWeeks = shallowCompareDateTimeObjects(this.dates.nextTwoWeeks.serverDate, moveInOnDateObject);
    const isOther = !(isAsap || isNextWeek || isNextTwoWeeks);
    const leaseLength = moveInDate?.leaseLength;

    const initialValues = {};

    if (isOther) {
      initialValues.moveInDateSelector = DATE_SELECTOR_KEYS.OTHER_DATE_STRING;
      initialValues.moveInDateText = formatDateTime({
        dateTime: legacyParse(moveInOnDateObject),
        format: DATE_FORMATS.STANDARD,
      });
    } else if (isAsap) {
      initialValues.moveInDateSelector = DATE_SELECTOR_KEYS.ASAP_DATE_STRING;
    } else if (isNextWeek) {
      initialValues.moveInDateSelector = DATE_SELECTOR_KEYS.NEXT_DATE_STRING;
    } else if (isNextTwoWeeks) {
      initialValues.moveInDateSelector = DATE_SELECTOR_KEYS.NEXT_NEXT_DATE_STRING;
    }

    if (leaseLength) {
      if (![12, leaseDuration.leaseMaxMonths].includes(leaseLength)) {
        initialValues.leaseLength = -1;
        initialValues.customLength = leaseLength;
      } else {
        initialValues.leaseLength = leaseLength;
      }
    }

    return initialValues;
  }

  // Form submit handler. Calls redux action to send data to the API
  handleSubmit = async (values) => {
    const {
      user: { userId },
    } = this.props;

    const { isSaveAndExit } = this.state;
    const userNotLoggedIn = isNil(userId);

    this.setState({
      awaitingFetchComplete: true,
    });

    if (isSaveAndExit) {
      this.sendRequestToServer(values);
      return;
    }

    if (userNotLoggedIn) {
      this.showUserLoginModal(values);
    } else {
      this.sendRequestToServer(values);
    }
  };

  sendRequestToServer = async (values, applicationIdFromModal) => {
    const {
      history: { push },
      user: { userId },
      applicationId: applicationIdFromProps,
      houseId,
      applicationStatus,
      createMoveInDate: createMoveInDateAction,
      updateMoveInDate: updateMoveInDateAction,
      fetchApplicantInfo: fetchApplicantInfoAction,
    } = this.props;

    const applicationId = !isNil(applicationIdFromModal) ? applicationIdFromModal : applicationIdFromProps;

    const { isSaveAndExit } = this.state;
    const userNotLoggedIn = isNil(userId);

    let serverFormatDate;

    if (values.moveInDateSelector && values.moveInDateSelector === DATE_SELECTOR_KEYS.OTHER_DATE_STRING) {
      serverFormatDate = parseDateTimeInputString(values.moveInDateText);
    } else if (values.moveInDateSelector && values.moveInDateSelector === DATE_SELECTOR_KEYS.ASAP_DATE_STRING) {
      serverFormatDate = this.dates.ASAP.serverDate;
    } else {
      serverFormatDate = this.dates[values.moveInDateSelector].serverDate;
    }

    this.moveInDateObject = {
      leaseMonths,
      leasePreference,
    };

    const moveInOn = formatDateTime({ dateTime: legacyParse(serverFormatDate), format: DATE_FORMATS.STRIPPED_ISO });

    const { leaseLength, customLength } = values;
    const leaseDuration = leaseLength === -1 ? customLength : leaseLength;

    try {
      if (isSaveAndExit && userNotLoggedIn) {
        this.renderProvideEmailModal();
      } else if (isSaveAndExit && !userNotLoggedIn) {
        await createMoveInDateAction(this.moveInDateObject, moveInOn, leaseDuration);
        push('/');
      } else if (!isSaveAndExit && applicationId !== '') {
        await updateMoveInDateAction(moveInOn, leaseDuration);
        const applicantInfo = (await fetchApplicantInfoAction(applicationId)) || {};

        if (applicationStatus === APPLICATION_STATUS_ENUM.COMPLETE) {
          push(getResidentApplicationStepPath(STEPS_CONFIG.SUCCESS_PAGE, { houseId }));
          return;
        }

        if (applicantInfo?.applicantType !== ApplicantType.MainApplicant) {
          push(getResidentApplicationStepPath(STEPS_CONFIG.CREDIT_CHECK, { houseId, applicationId }));
          return;
        }

        push(getResidentApplicationStepPath(STEPS_CONFIG.PEOPLE_AND_PETS, { houseId, applicationId }));
      } else {
        // this.setState({
        //   awaitingFetchComplete: false,
        // });
        const serverResponse = await createMoveInDateAction(this.moveInDateObject, moveInOn, leaseDuration);
        const newApplicationId = serverResponse.applicationId;
        push(
          getResidentApplicationStepPath(STEPS_CONFIG.PEOPLE_AND_PETS, { houseId, applicationId: newApplicationId })
        );
        // push(getResidentApplicationStepPath(STEPS_CONFIG.EMPLOYMENT_INFO, { applicationId: newApplicationId }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Show user login modal to get list of existing applications for the user
  showUserLoginModal = (values) => {
    const { showModal: showModalAction } = this.props;

    this.setState({
      awaitingFetchComplete: false,
    });

    showModalAction(MODALS.LOGIN, {
      afterSucessfulLogin: () => this.userLoginSuccessful(values),
    });
  };

  // Get list of applications for user
  userLoginSuccessful = async (values) => {
    const { fetchAllApplicationsForUser: fetchAllApplicationsForUserAction } = this.props;

    this.setState({
      awaitingFetchComplete: true,
    });

    let existingApplicationsForUser;

    try {
      await fetchAllApplicationsForUserAction();
    } catch (err) {
      console.error(err);
    }

    if (!existingApplicationsForUser || !existingApplicationsForUser.length) {
      this.sendRequestToServer(values);
      return;
    }

    this.setState({
      awaitingFetchComplete: false,
      showListOfApplicationsModal: true,
      formValues: values,
    });
  };

  // Submit lead information to backend when a user (not logged in) clicks
  // save & exit and provides their email using the modal
  // @param
  //   userEmail: User email string
  handleEnterEmailModalSubmit = async (userEmail) => {
    const {
      history: { push },
      createMoveInDateLead: createMoveInDateLeadAction,
    } = this.props;
    this.moveInDateObject.email = userEmail;
    try {
      await createMoveInDateLeadAction(this.moveInDateObject);
    } catch (err) {
      console.error(err);
    }
    push('/');
  };

  handleCloseListOfApplicationsModal = (applicationId) => {
    const { formValues } = this.state;
    this.setState({ awaitingFetchComplete: true });
    this.sendRequestToServer(formValues, applicationId);
  };

  // Helper function that generates dates for each selector choice.
  // ASAP: Current date and time
  // nextMonth: Current date + 1 month and current time
  // nextToNextMonth: Current date + 2 month and current time
  generateDates() {
    const currentDateObj = new Date();

    this.dates = {
      ASAP: {
        serverDate: formatDateTime({
          dateTime: legacyParse(addDays(currentDateObj, 3)),
          format: DATE_FORMATS.INTERNAL,
        }),
        displayDateMonthDate: formatDateTime({
          dateTime: legacyParse(addDays(currentDateObj, 3)),
          format: DATE_FORMATS.MONTH_DAY_YEAR,
        }),
      },
      nextWeek: {
        serverDate: formatDateTime({
          dateTime: legacyParse(addWeeks(currentDateObj, 1)),
          format: DATE_FORMATS.INTERNAL,
        }),
        displayDateMonthDate: formatDateTime({
          dateTime: legacyParse(addWeeks(currentDateObj, 1)),
          format: DATE_FORMATS.MONTH_DAY_YEAR,
        }),
      },
      nextTwoWeeks: {
        serverDate: formatDateTime({
          dateTime: legacyParse(addWeeks(currentDateObj, 2)),
          format: DATE_FORMATS.INTERNAL,
        }),
        displayDateMonthDate: formatDateTime({
          dateTime: legacyParse(addWeeks(currentDateObj, 2)),
          format: DATE_FORMATS.MONTH_DAY_YEAR,
        }),
      },
    };
  }

  renderProvideEmailModal() {
    const { showModal: showModalAction } = this.props;
    this.setState({
      awaitingFetchComplete: false,
    });
    // To-Do Akash: Move this to a common const file
    const modal = 'LOGIN';
    const props = {
      currentScreen: SCREENS.ENTER_EMAIL_TO_SAVE_SCREEN, // To-Do Akash: Move this to a common const file
      screenProps: {
        returnEmailToComponent: this.handleEnterEmailModalSubmit,
      },
    };
    showModalAction(modal, props);
  }

  render() {
    const { awaitingFetchComplete, showListOfApplicationsModal, leaseDuration } = this.state;
    const { home } = this.props;
    const isSeattle = home.propertyInfo.coverageRegionId === 5;
    const { leaseMinMonths, leaseMaxMonths } = leaseDuration;

    // Check if data is available for existing application. If not, then render nothing until the
    // fetch action is complete.
    // To-Do: Replace null with loading animation
    if (awaitingFetchComplete) {
      return (
        <Row>
          <Col md={12}>
            <div className={cx('spinner')}>
              <Spinner />
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <FormLayout>
        <FormLayoutHeader
          title={RESIDENT_APPLICATION_STRINGS.move_in_date.title}
          subtitle={RESIDENT_APPLICATION_STRINGS.move_in_date.subTitle}
        />
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: 'Next',
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          getForm={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={cx('move-in-date-selector-wrapper')}>
                  <Field
                    name="moveInDateSelector"
                    component={SelectorFinalFormAdapter}
                    labelClassName="px-lg"
                    buttons={[
                      {
                        className: cx('move-in-date-selector-buttons'),
                        label: this.dates.ASAP.displayDateMonthDate,
                        key: DATE_SELECTOR_KEYS.ASAP_DATE_STRING,
                      },
                      {
                        className: cx('move-in-date-selector-buttons'),
                        label: this.dates.nextWeek.displayDateMonthDate,
                        key: DATE_SELECTOR_KEYS.NEXT_DATE_STRING,
                      },
                      {
                        className: cx('move-in-date-selector-buttons'),
                        label: this.dates.nextTwoWeeks.displayDateMonthDate,
                        key: DATE_SELECTOR_KEYS.NEXT_NEXT_DATE_STRING,
                      },
                      {
                        className: cx('move-in-date-selector-buttons'),
                        label: RESIDENT_APPLICATION_STRINGS.move_in_date.dateStrings.other,
                        key: DATE_SELECTOR_KEYS.OTHER_DATE_STRING,
                      },
                    ]}
                    type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                    fluid
                    validate={required}
                  />
                </div>
                <Condition when="moveInDateSelector" is={DATE_SELECTOR_KEYS.OTHER_DATE_STRING}>
                  <Row>
                    <Col md={5}>
                      <Field
                        name="moveInDateText"
                        component={InputFinalFormAdapter}
                        placeholder="Move-in date (MM/DD/YYYY)"
                        validate={composeValidators(required, dateValidation, isFutureDateMMDDYY)}
                        mask={maskMMDDYYYY}
                        unmask={unmaskMMDDYYYY}
                      />
                    </Col>
                  </Row>
                </Condition>
                {!isSeattle && leaseMaxMonths > 12 && (
                  <Condition when="moveInDateSelector" hasValue>
                    <FormLayout.Section
                      sectionTitle={RESIDENT_APPLICATION_STRINGS.how_long.title}
                      sectionSubTitle={RESIDENT_APPLICATION_STRINGS.how_long.subtitle}
                      className="mt-2xl"
                    >
                      <Field
                        name="leaseLength"
                        component={SelectorFinalFormAdapter}
                        buttons={getLeaseDurationOptions(leaseMaxMonths)}
                        type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                        validate={required}
                        tagClassName="left-0 right-0 w-fit -top-2sm mx-auto my-0"
                      />
                    </FormLayout.Section>
                    <Condition when="leaseLength" is={-1}>
                      <FormLayout.Section
                        sectionTitle={RESIDENT_APPLICATION_STRINGS.how_long.duration}
                        sectionTitleClassName="mb-xl"
                      >
                        <Field
                          component={SliderFinalFormAdapter}
                          name="customLength"
                          min={12}
                          max={leaseMaxMonths}
                          defaultValue={Math.ceil((12 + leaseMaxMonths) / 2)}
                          renderLeft={() => {
                            return (
                              <Text variant="p1" className="mr-sm">
                                12 Months
                              </Text>
                            );
                          }}
                          renderRight={() => {
                            return (
                              <Text variant="p1" className="ml-sm">
                                {`${leaseMaxMonths} Months`}
                              </Text>
                            );
                          }}
                          renderThumbDetail={(val) => (
                            <Text
                              className="whitespace-nowrap text-h3-sm"
                              fontWeight="semibold"
                            >{`${val} Months`}</Text>
                          )}
                          thumbContainerClassName="-top-xl text-navy"
                        />
                      </FormLayout.Section>
                    </Condition>
                    <Condition when="leaseLength" hasValue>
                      <Condition when="leaseLength" is={leaseMinMonths}>
                        <div className="bg-green-translucent-light border-2 border-solid border-green rounded p-sm -mt-sm">
                          <Text>{RESIDENT_APPLICATION_STRINGS.how_long.are_you_sure}</Text>
                        </div>
                      </Condition>
                    </Condition>
                    <Condition when="leaseLength" hasValue>
                      <Condition when="leaseLength" is={leaseMaxMonths}>
                        <div className="bg-green-translucent-light border-2 border-solid border-green rounded p-sm -mt-sm">
                          <Text>{RESIDENT_APPLICATION_STRINGS.how_long.great_choice}</Text>
                        </div>
                      </Condition>
                    </Condition>
                  </Condition>
                )}
              </form>
            );
          }}
        />
        <ListOfApplicationsModal show={showListOfApplicationsModal} onClose={this.handleCloseListOfApplicationsModal} />
      </FormLayout>
    );
  }
}

export default connect(
  (state) => ({
    user: selectUser(state),
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    moveInDate: selectResidentApplicationMoveInDate(state),
    applicationStatus: selectResidentApplicationStatus(state),
  }),
  {
    updateMoveInDate,
    fetchMoveInDate,
    createMoveInDate,
    createMoveInDateLead,
    showModal,
    fetchAllApplicationsForUser,
    fetchApplicantInfo,
    fetchLeaseLength,
  }
)(MoveInDate);
