import { createContext, ReactNode } from 'react';

type SetupFlowContextProps = {
  loading: boolean;
  properties: any[];
  units: any[];
  unitIds: string[];
  steps: any;
  allStepsByGate: any;
}

type SetupFlowProviderProps = SetupFlowContextProps &  {
  children: ReactNode
}

export const SetupFlowContext = createContext<undefined | SetupFlowContextProps>(undefined);

export function SetupFlowProvider({ children, ...rest }: SetupFlowProviderProps) {
  return <SetupFlowContext.Provider value={rest}>{children}</SetupFlowContext.Provider>;
}
