import React, { useState } from 'react';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { MEDIA_LABEL } from 'components/NewMediaUploader/NewMediaUploader';
import { SkippableMediaUploaderAdapter, validateSkippableMedia } from 'formcomponents/skippable-media-uploader';
import PropTypes from 'prop-types';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import styles from './MaintenanceModalAttachmentFields.module.css';

const cx = classNames.bind(styles);

const MaintenanceModalAttachmentFields = ({ form }) => {
  const [showErrors, setShowErrors] = useState(false);

  const displayTooltip = (value) => {
    if (value === true) {
      setShowErrors(true);
      setTimeout(() => {
        setShowErrors(false);
      }, 5000);
    } else {
      setShowErrors(false);
    }
  };

  const isDisabled = () => {
    if (
      form.getState().values.attachments?.availableMedia.length >
      form.getState().values.attachments?.deletedMedia.length
    ) {
      return true;
    }

    if (form.getState().values.attachments?.stagingMedia?.length) {
      return true;
    }
  };

  return (
    <>
      <Field
        name="attachments"
        validate={validateSkippableMedia}
        component={SkippableMediaUploaderAdapter}
        mediaLabel={MEDIA_LABEL.PHOTO_OR_VIDEO}
        onChangeCustom={(value) => {
          if (value.stagingMedia.length) {
            form.change('attachments.later', false);
            setShowErrors(false);
          }
        }}
        wrapperSize="large"
        disableWebcam
        hideCheckboxWithValue={false}
        customCheckboxClassname={cx('maintenanceModalCheckbox')}
        checkboxProps={{
          label: ADD_TASK_MODAL_STRINGS.maintenance_upload_image_checkbox,
          onClickCustom: (value) => {
            displayTooltip(value);
          },
          disabled: isDisabled(),
        }}
        toolTip={
          <>
            <div
              className={cx(
                'maintenance-tooltip-wrapper',
                // Used to fix some rendering bug that would show the tooltip when uploading images
                // Probably there is a better way but this is a hotfix.
                { show: showErrors === true && !isDisabled() },
                { hide: showErrors === false }
              )}
            >
              <div className={cx('arrow')} />
              <div className={cx('general-icon-wrapper')}>
                <GeneralIcon scale={2} icon={GENERAL_ICONS.WARNING_LARGE} />
              </div>
              <div>{ADD_TASK_MODAL_STRINGS.maintenance_upload_image_tooltip}</div>
            </div>
          </>
        }
      />
    </>
  );
};

MaintenanceModalAttachmentFields.propTypes = {
  form: PropTypes.object.isRequired,
};
export default MaintenanceModalAttachmentFields;
