export const STRINGS = {
  title: 'We have $1,000 that belongs in your pocket!',
  subtitle: 'Refer a great homeowner and get paid when their lease starts.',
  ways: 'Ways to Refer',
  'ways.description':
    'Your link provides homeowners with expedited onboarding and support, so we can find the right residents, faster.',
  linkCta: 'Referral Link',
  messageCta: 'Text Message',
  messageBody:
    'Let’s get your home rented! My refferal link gives you expedited onboarding and support from the Belong team: {referralUrl}',
};
