const pleaseTryAgain = 'Please\u00a0try\u00a0again.';

export const FEEDBACK_STRINGS = {
  create_account:
    'Hmm... we were unable to create this account. Please sign in if you have an\u00a0existing\u00a0account.',
  something_went_wrong: 'Something went wrong. Please sign in if you have an\u00a0existing\u00a0account.',
  login: 'Invalid Email/Password',
  generic_something_went_wrong: 'Something went wrong!',
  fb_login_email_mismatch_line_1: 'Looks like your Facebook account\u00a0doesn’t\u00a0match.',
  fb_login_email_mismatch_line_2: 'Please create an account with the email\u00a0below.',
  google_login_third_party_error: 'Please allow 3rd party cookies to login with\u00a0Google.',
  reset_password_link_expired_line_1: 'This link has expired. Please',
  reset_password_link_expired_line_2: 'again.',
  reset_password_link_expired_button: 'reset your password',
  phone_update_failed: `Unable to update phone. ${pleaseTryAgain}`,
  phone_already_exists: 'That phone number is already in use by another user account!',
  credit_card_fail: `Invalid Card. ${pleaseTryAgain}`,
  credit_card_existing: `You have already registered this credit card. ${pleaseTryAgain}`,
  manual_ach_fail: `Unable to add your account. ${pleaseTryAgain}`,
  instant_ach_fail_title: 'Account Creation Failed',
  instant_ach_fail_description: `Sorry! We are unable to process your ACH account. ${pleaseTryAgain}`,
  manual_ach_verify_fail: `Unable to verify your account. ${pleaseTryAgain}`,
  set_payment_method: {
    error: {
      title: 'Update Failed',
      subtitle: `We were unable to update your {priority} payment method at this time. ${pleaseTryAgain}`,
      ctaLabel: 'Try Again',
    },
    success: {
      title: 'Successfully Updated',
      subtitle: 'Great! Your {priority} payment method has been updated.',
      ctaLabel: 'Done',
    },
  },
  pay_deposit: {
    error: {
      title: "Hmm... that didn't go through.",
      subtitle: `We were unable to process your payment that time. ${pleaseTryAgain}`,
      ctaLabel: 'Try Again',
    },
    success: {
      title: 'Woo! You paid your deposit!',
      subtitle:
        "You'll see this payment in your account, and will receive your deposit back at the end of your lease, pending a <span>move-out inspection.</span>",
      ctaLabel: 'Done',
    },
  },
  payments_errors: {
    ACH_INSUFFICIENT_FUNDS: {
      title: 'Insufficient Funds',
      subtitle: 'Please increase the balance of this account before paying, <span>or use another.</span>',
      ctaLabel: 'Try Again',
    },
    UNVERIFIED_USER_LIMIT_EXCEEDS: {
      title: 'Unverified user',
      subtitle: 'Your account is unverified. <span>Please contact us.</span>',
      ctaLabel: 'Try Again',
    },
  },
  general_error: {
    title: 'Something <span>went wrong!</span>',
    subtitle:
      'We are not able to process your request at this time. <br /> Please try again or go back to the [homepage](/).',
    ctaLabel: 'Try Again',
  },
  'add_payment.success.title': 'You successfully added <span>a payment!</span>',
  'add_payment.success.subtitle': "You'll be able to use it now to <span>make payments!</span>",
  'add_payment.success.ctaLabel': 'Payment Added',
  'add_payment.fail.title': 'Hmmm... something <span>went wrong!</span>',
  'add_payment.fail.subtitle': "Looks like you couldn't add <span>this payment.</span>",
  'add_payment.fail.ctaLabel': 'Go Back',
  // TODO: Duplicate key 'id_verification_fail'.
  //id_verification_fail: 'Please retake pictures',
  id_verification_fail: 'Hmm, we were not able to verify your ID. <span>Please try again!</span>',
};
