import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Text as RebassText } from 'rebass';
import { formatString } from 'strings';
import styles from './Text.module.css';

type TextProps = {
  as?: string;
  fontSize?: string;
  fontWeight?: string;
  className?: string;
  style?: Record<string, unknown>;
  tt?: string;
  ow?: string;
  width?: number;
  children?: ReactNode;
};

const cx = classNames.bind(styles);

const renderString = (node) => {
  if (typeof node === 'string') {
    return formatString(node);
  }
  return node;
};

export const Text = React.forwardRef<unknown, TextProps>(
  ({ className, tt, ow, width, style, children, fontSize, fontWeight, ...props }, ref) => {
    const textStyle: Record<string, unknown> = {
      ...style,
      textTransform: tt,
      overflowWrap: ow,
    };

    if (width) {
      textStyle.textOverflow = 'ellipsis';
      textStyle.overflow = 'hidden';
      textStyle.whiteSpace = 'pre';
      textStyle.width = width;
    }

    return (
      <RebassText ref={ref} {...props} style={textStyle} className={cx('text', fontSize, fontWeight, className)}>
        {renderString(children)}
      </RebassText>
    );
  }
);

Text.displayName = 'Text';

Text.defaultProps = {
  as: 'span',
};
