import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import { selectNoApprovalMaximumThresholdAmount } from 'store/redux/settings';
import { getString } from 'strings';
import styles from './AgreementRenderer.module.css';

const cx = classNames.bind(styles);

/* eslint-disable no-template-curly-in-string */
const dataKeyMap = {
  threshold: {
    descriptions: [
      "That's not to say we take anything under ${threshold} lightly! We'll make sure you're aware of every step we take. (regardless <span>of cost).</span>",
      "We do this to move fast and prevent smaller, less expensive problems from developing into hard to solve, costly issues. We're providing a great experience for your Residents, and saving you money in the <span>long run.</span>",
    ],
  },
  reserves: {
    descriptions: [
      "We won't need reserves often. There may be times, however, when we'll need to hold funds in our trust account in order to operate on <span>your behalf.</span>",
      'For example, if you approved a large remodel project for your home, we may need reserves in order to pay specialty vendors in a timely fashion upon completion <span>of work.</span>',
    ],
  },
  indemnification: {
    descriptions: [
      "We know this is scary legalese, even if common these days. Our intent here is simple: We're here for you, working on your behalf, looking out for your best interests. We do things strictly by the book, reducing your overall liability as <span>a homeowner.</span>",
    ],
  },
  'additional-disclosures': {
    descriptions: [
      "Later, we'll ask you a series of questions about these. We'll only disclose this information to your future Residents, as required by law or regulation, and doing so may help protect you from liability down <span>the road.</span>",
    ],
  },
};
/* eslint-enable no-template-curly-in-string */

const AgreementRenderer = ({ agreement, setTip, openTipModal, className, isIntersectionDisabled }) => {
  const [latestTipKey, setLatestTipKey] = useState(null);
  const noApprovalMaximumThresholdAmount = useSelector(selectNoApprovalMaximumThresholdAmount);
  const { width } = useWindowSize();

  const getTip = (key) => {
    if (key === 'threshold') {
      const [firstLine, ...rest] = dataKeyMap.threshold.descriptions;

      return {
        ...dataKeyMap.threshold,
        descriptions: [getString(firstLine, { threshold: noApprovalMaximumThresholdAmount }), rest],
      };
    }

    return dataKeyMap[key];
  };

  const handleClickListener = (event) => {
    const tipKey = event.target.closest('span').getAttribute('data-key');
    const tip = getTip(tipKey);

    setTip(tip);

    if (width < BREAKPOINTS_WIDTHS.XL) {
      openTipModal();
    }
  };

  const handleIntersection = (entries) => {
    /**
     * Without having the ability to interrup this function it goes into an endless loop
     * when two or more tips are intersecting.
     * Something to consider when migrating over to belong-next.
     */
    if (isIntersectionDisabled) {
      return;
    }

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const tipKey = entry.target.getAttribute('data-key');

        if (tipKey !== latestTipKey) {
          const tip = getTip(tipKey);
          setLatestTipKey(tipKey);
          setTip(tip);
        }
      }
    });
  };

  return (
    <HTMLRenderer
      querySelector=".tip"
      onClickListener={handleClickListener}
      onIntersection={handleIntersection}
      className={cx('agreement', 'overflow-y-hidden sm:overflow-y-auto', className)}
      html={agreement}
    />
  );
};

AgreementRenderer.propTypes = {
  agreement: PropTypes.string.isRequired,
  setTip: PropTypes.func.isRequired,
  openTipModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  isIntersectionDisabled: PropTypes.bool,
};

export default AgreementRenderer;
