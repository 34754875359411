import UnitBasicInfo from './UnitBasicInfo';
import LeaseResident from './LeaseResident';

// Doesn't match with backend
export default class Unit {
  constructor(data = {}) {
    this.basicInfo = new UnitBasicInfo(data.basicInfo);
    this.residents = data.residents ? data.residents.map((resident) => new LeaseResident(resident)) : [];
  }
}
