import { camelCase } from 'es-toolkit';
import { capitalize } from './capitalize.js';

function pascalCase(str) {
  if (str === void 0) {
    str = '';
  }
  return capitalize(camelCase(str));
}

export { pascalCase };
