import React from 'react';
import classNames from 'classnames/bind';
import { LazyLottieAnimation } from '../LazyLottieAnimation/LazyLottieAnimation';
import styles from './Confetti.module.css';

const cx = classNames.bind(styles);

export const Confetti = (props) => {
  return (
    <LazyLottieAnimation
      name="confetti"
      containerClassName={cx('background-container-desktop')}
      containerClassNameMobile={cx('background-container-mobile')}
      {...props}
    />
  );
};
