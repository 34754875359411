export default class LeaseDetailBasicInfo {
  constructor(data = {}) {
    this.depositAmount = data.depositAmount;
    this.isUtilityIncluded = data.isUtilityIncluded;
    this.leaseEndOn = data.leaseEndOn;
    this.leaseId = data.leaseId;
    this.isConcluded = data.isConcluded;
    this.isCurrent = data.isCurrent;
    this.isFuture = data.isFuture;
    this.leaseUrl = data.leaseUrl;
    this.leaseStartOn = data.leaseStartOn;
    this.leaseTerm = data.leaseTerm;
    this.rentAmount = data.rentAmount;
    this.petDepositOption = data.petDepositOption;
    this.petDepositAmount = data.petDepositAmount;
    this.petFeeOption = data.petFeeOption;
    this.petFeeAmount = data.petFeeAmount;
    this.totalPetFee = data.totalPetFee;
    this.listingApplicationUniqueId = data.listingApplicationUniqueId;
    this.status = data.status;
  }
}
