import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import { palette } from 'styles/palette';
import styles from './CircledNumber.module.css';

const cx = classNames.bind(styles);

export const SIZE = {
  DEFAULT: 'default',
  BIG: 'big',
};

const sizeMap = {
  default: GENERAL_ICONS.CIRCLE,
  big: GENERAL_ICONS.ICON_CIRCLE_BIG,
};

const CircledNumber = ({ children, color, size }) => {
  return (
    <div className={cx('circled-number', size)}>
      <GeneralIcon icon={sizeMap[size]} scale={1.5} color={color} />
      <div className={cx('number', size)} style={{ color: color === COLORS.DEFAULT ? palette.navy : palette[color] }}>
        {children}
      </div>
    </div>
  );
};

CircledNumber.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZE)),
};

CircledNumber.defaultProps = {
  size: SIZE.DEFAULT,
};

CircledNumber.SIZE = SIZE;

export default CircledNumber;
