export const HOME_UTILITIES_STRINGS = {
  title: 'Talk to us about utilities...',
  responsibility: {
    resident: "Resident's responsibility",
    rent: 'Included in rent',
    purchase: 'Available for purchase',
    hoa: 'Provided <span>by my HOA</span>',
    fee: 'Additional required fee',
  },
  billingHoaType: {
    included: 'Included in my HOA fees',
    separately: 'Billed separately',
  },
  billingCycle: {
    monthly: 'Monthly',
    oneTime: 'One-Time',
    quarterly: 'Quarterly',
    annually: 'Annually',
  },
  trashTitle: 'When is garbage day?',
  trashLocationTitle: 'Where is the trash located?',
  "trash_pickup_days.placeholder": "Day of Week",
  "trash_pickup_details_unknown.placeholder": "I don't know",
  "trash_location.placeholder": "Trash Location",
  "landscaping_pickup_days.placeholder": "Day of Week",
  "landscaping_details_unknown.placeholder": "I don't know",
  "fee.placeholder": "Fee ($)",
  "frequency.placeholder": "Frequency",
  every2Weeks: 'Every 2 Weeks',
  providerPlaceholder: "Provider (If Known)",
  billingManagedBy: 'Who is managing the billing?',
  landscapingTitle: "When does landscaping happen?"
}
