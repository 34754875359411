import { useState } from 'react';
import { IconCaretDown } from '@belong/icons';
import { ButtonBase, Text } from '@belong/ui';
import { BillInfoDetailModel } from 'api/models';
import cx from 'classnames';
import GrayBorderedBox from 'components/GrayBorderedBox/GrayBorderedBox';
import AccountPageSection from '../Pages/AccountPage/AccountPageSection/AccountPageSection';
import { EARNINGS_STRINGS } from '../Pages/YourPortfolio/Earnings/earnings.strings';
import { Bill } from './bill';

type PastEarningsSectionProps = {
  earnings: BillInfoDetailModel[];
  selectedHome: string;
  hasMorePages: boolean;
  hasManyHomes: boolean;
  handleShowEarningsModal: (transaction: BillInfoDetailModel) => () => void;
  loadMore: () => void;
};

export function PastEarningsSection({
  earnings,
  selectedHome,
  hasMorePages,
  hasManyHomes,
  handleShowEarningsModal,
  loadMore,
}: PastEarningsSectionProps) {
  const [showAllItems, setShowAllItems] = useState(false);
  const earningItems = earnings.slice(0, showAllItems ? earnings.length : 3);
  const showMoreOrLessLabel =
    (earningItems.length === 3 && earnings.length <= 3) || (earningItems.length === earnings.length && !hasMorePages)
      ? EARNINGS_STRINGS['show_less.label']
      : EARNINGS_STRINGS['show_more.label'];

  function handleToggleShowMore() {
    if (earningItems.length === 3 && earnings.length > 3) {
      setShowAllItems(true);
    } else {
      if (!hasMorePages) {
        setShowAllItems(false);
      } else {
        loadMore();
      }
    }
  }

  return (
    <AccountPageSection
      title={EARNINGS_STRINGS['section.past_earnings.title']}
      content={
        <div
          className={cx({
            'transition-all ease-linear duration-hover': true,
          })}
        >
          {!earnings?.length ? (
            <GrayBorderedBox>{EARNINGS_STRINGS['section.transaction.no_history']}</GrayBorderedBox>
          ) : (
            earningItems?.map((bill) => (
              <Bill
                key={`bill-${bill.billInfo?.id}`}
                {...bill}
                label={EARNINGS_STRINGS['paid.label']}
                onClick={handleShowEarningsModal(bill)}
                showAddress={selectedHome === 'all' && hasManyHomes}
              />
            ))
          )}
          {earnings?.length > 3 ? (
            <div className="text-right mb-sm">
              <ButtonBase onClick={handleToggleShowMore}>
                <footer className="flex justify-end mt-sm">
                  <Text fontWeight="semibold">{showMoreOrLessLabel}</Text>
                  <IconCaretDown
                    width={15}
                    className={cx('ml-xs transition-transform duration-hover ease-linear', {
                      'rotate-180': showMoreOrLessLabel === EARNINGS_STRINGS['show_less.label'],
                    })}
                  />
                </footer>
              </ButtonBase>
            </div>
          ) : null}
        </div>
      }
    />
  );
}
