import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import Image from 'components/Image/Image';
import Spinner from 'components/Spinner/Spinner';
import ListItem from 'components/SuccessPageComponents/ListItem/ListItem';
import ListingsUnitCard from 'containers/ListingsUnitCard/ListingsUnitCard';
import Space from 'corecomponents/Space/Space';
import { Col, Grid, Row } from 'forkedlibraries/react-bootstrap';
import Page from 'layouts/Page/Page';
import ListingPageUnit from 'models/listingPage/ListingPageUnit';
import PropTypes from 'prop-types';
import { fetchHome } from 'store/redux/homes/actions';
import { showModal } from 'store/redux/modals/actions';
import { fetchHomeIds, updateApplicationIdAppState } from 'store/redux/resident-application/actions';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import String from '../../components/String/String';
import { SCREENS } from '../../containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from '../../containercomponents/Modals/modal.consts';
import { ApplicantType } from '../../models/enums';
import { getString } from '../../strings';
import styles from './ResidentCoApplicantLandingPage.module.css';

const QueryString = require('query-string');

const RAS = RESIDENT_APPLICATION_STRINGS;
const cx = classNames.bind(styles);

class ResidentCoApplicantLandingPage extends Component {
  state = {
    awaitingFetchComplete: true,
  };

  componentDidMount() {
    const { location } = this.props;
    const queryJson = location.search ? QueryString.parse(location.search) : null;
    const applicationId = location.pathname.split('/')[2];

    if (queryJson) {
      const { email, fn, mainFn, type } = queryJson;
      this.setQueryParams(email, fn, mainFn, applicationId, type);
    }
  }

  setQueryParams(email, firstName, primaryApplicantFirstName, applicationId, type) {
    this.setState(
      {
        email,
        firstName,
        primaryApplicantFirstName,
        applicationId,
        type,
      },
      this.getUnitInfo
    );
  }

  getUnitInfo = async () => {
    const { email, applicationId } = this.state;
    const { fetchHomeIds: fetchHomeIdsAction, fetchHome: fetchHomeAction } = this.props;
    const homeDetails = [];

    try {
      const homeIds = await fetchHomeIdsAction(email, applicationId);

      const requests = [];
      for (let i = 0; i < homeIds.length; i += 1) {
        requests.push(fetchHomeAction(homeIds[i]));
      }

      const homeDetailsResponse = await Promise.all(requests);

      for (let i = 0; i < homeDetailsResponse.length; i += 1) {
        const response = homeDetailsResponse[i];
        homeDetails[i] = new ListingPageUnit({
          ...response,
          ...response?.listingInfo,
        });
      }

      this.setState({
        homeDetails,
        awaitingFetchComplete: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  handleClickApply = () => {
    const { showModal: showModalAction } = this.props;
    const { email } = this.state;

    showModalAction(MODALS.LOGIN, {
      currentScreen: SCREENS.REGISTER_SCREEN,
      screenProps: {
        disableEmailInput: true,
        emailAddress: email,
      },
      afterSucessfulLogin: this.afterLoginSuccess,
    });
  };

  afterLoginSuccess = async () => {
    const { applicationId, homeDetails } = this.state;
    const {
      history: { push },
      updateApplicationIdAppState: updateApplicationIdAppStateAction,
    } = this.props;

    updateApplicationIdAppStateAction(applicationId);
    push({
      pathname: `/resident/${homeDetails[0].basicInfo.unitId}/applications`,
      search: `?applicationId=${applicationId}&houseId=${homeDetails[0].basicInfo.unitId}&nextStep=CREDIT_CHECK&fromCoapplicantPage=true`,
    });
  };

  render() {
    const { awaitingFetchComplete, firstName, primaryApplicantFirstName, homeDetails, type } = this.state;

    if (awaitingFetchComplete) {
      return (
        <div className={cx('spinner-wrapper')}>
          <Spinner />
        </div>
      );
    }

    return (
      <Page
        headerMainProps={{
          fixed: true,
        }}
      >
        <div className={cx('center-elements-container')}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <div className={cx('pre-title')}>{`Welcome to Belong, ${firstName}!`}</div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className={cx('title-homes-list')}>
                  {(!type || type === ApplicantType.CoApplicant) && (
                    <String
                      string={getString(RAS.ca_landing_page.co_applicant_title, {
                        firstName: primaryApplicantFirstName,
                      })}
                    />
                  )}
                  {type === ApplicantType.CoSigner && (
                    <String
                      string={getString(RAS.ca_landing_page.co_signer_title, {
                        firstName: primaryApplicantFirstName,
                      })}
                    />
                  )}
                </div>
              </Col>
            </Row>
            {homeDetails.map((home, index) => (
              <React.Fragment key={index}>
                <div className={cx('homes-list-section')}>
                  <Row>
                    <Col smOffset={3} sm={6}>
                      <div className={cx('homes-list-image')}>
                        <ListingsUnitCard unit={home} centerText setMaxWidth />
                      </div>
                    </Col>
                  </Row>
                </div>
                <Space value="2xl" />
              </React.Fragment>
            ))}
            <Row>
              <Col md={12}>
                <div className={cx('title-want-to-live')}>
                  {(!type || type === ApplicantType.CoApplicant) && (
                    <String
                      string={getString(RAS.ca_landing_page.co_applicant_subtitle, {
                        firstName: primaryApplicantFirstName,
                      })}
                    />
                  )}
                  {type === ApplicantType.CoSigner && (
                    <String
                      string={getString(RAS.ca_landing_page.co_signer_subtitle, {
                        firstName: primaryApplicantFirstName,
                      })}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <GreenInfoBox cssClassNames={[cx('what-you-need-section-wrapper')]}>
                  <div className={cx('what-you-need-section-title')}>
                    {RAS.pa_landing_page.what_you_need_section.title}
                  </div>
                  <div className={cx('what-you-need-section-list')}>
                    {RAS.pa_landing_page.what_you_need_section.requirements
                      .filter((_, index) => {
                        // Filter landlord item if applicant is cosigner
                        if (index === 3 && type === ApplicantType.CoSigner) return false;
                        return true;
                      })
                      .map((requirement) => {
                        return (
                          <div key={requirement} className="mb-2sm last:mb-0">
                            <ListItem selectedTrue label={requirement} />
                          </div>
                        );
                      })}
                  </div>
                </GreenInfoBox>
              </Col>
            </Row>
            <Space value="xl" />
            <Row>
              <Col md={12}>
                <Button className={cx('apply-button')} onClick={this.handleClickApply}>
                  Apply
                </Button>
              </Col>
            </Row>
          </Grid>
        </div>
        <div className={cx('space-before-image')} />
        <div className={cx('belong-city-image')}>
          <Image src="/city_images/belong_city_illustration_03.svg" />
        </div>
      </Page>
    );
  }
}

ResidentCoApplicantLandingPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  fetchHomeIds: PropTypes.func.isRequired,
  fetchHome: PropTypes.func.isRequired,
  updateApplicationIdAppState: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    location: state.router.location,
  }),
  {
    showModal,
    fetchHomeIds,
    fetchHome,
    updateApplicationIdAppState,
  }
)(ResidentCoApplicantLandingPage);
