import AchAccountBasicInfo from './AchAccountBasicInfo';
import CreditCardBasicInfo from './CreditCardBasicInfo';
import { PaymentMethod as PaymentMethodEnum } from '../enums';
import { getPaymentMethodIcon, getPaymentMethodName } from '../utilities/paymentMethods';
import PaymentPreferences from './PaymentPreferences';

// [
//   {
//     "accountId": "string",
//     "paymentMethod": "Ach",
//     "lastDigits": "string",
//     "isVerified": true,
//     "achDetails": {
//       "accountType": "string",
//       "bankName": "string",
//       "microDepositStatus": "Initiated"
//     },
//     "creditCardDetails": {
//       "cardType": "Visa",
//       "expirationMonth": 0,
//       "expirationYear": 0
//     },
//     "preferences": [
//       {
//         "paymentType": "Maintenance",
//         "priority": "Primary"
//       }
//     ]
//   }
// ]

// isDefaultAccount missing.
//

export default class PaymentMethod {
  constructor(data = {}) {
    this.paymentMethod = data.paymentMethod;
    this.achDetails = data.achDetails ? new AchAccountBasicInfo(data.achDetails) : null;
    this.creditCardDetails = data.creditCardDetails ? new CreditCardBasicInfo(data.creditCardDetails) : null;
    this.isVerified = data.isVerified;
    this.lastDigits = data.lastDigits;
    this.isDomestic = data.isDomestic;
    this.isInternational = data.isInternational;
    this.accountId = data.accountId;
    this.referenceNumber = data.referenceNumber;
    this.otherPaymentType = data.otherPaymentType;
    this.preferences = data.preferences?.map((preference) => new PaymentPreferences(preference));
  }

  getAccountName = (lengthOfAccountName) =>
    getPaymentMethodName(
      {
        paymentMethod: this.paymentMethod,
        accountLastDigits: this.lastDigits,
        referenceNumber: this.referenceNumber,
        bankName: this.achDetails?.bankName,
        otherPaymentType: this.otherPaymentType,
      },
      lengthOfAccountName
    );

  getIcon = () => {
    if (this.paymentMethod === PaymentMethodEnum.Ach) {
      return getPaymentMethodIcon(this.paymentMethod);
    } else if (this.paymentMethod === PaymentMethodEnum.CreditCard) {
      return getPaymentMethodIcon(this.paymentMethod, this.creditCardDetails.cardType);
    }
  };

  getAccountType = () => {
    if (this.paymentMethod === PaymentMethodEnum.Ach) {
      return this.achDetails.accountType;
    } else if (this.paymentMethod === PaymentMethodEnum.CreditCard) {
      return this.creditCardDetails.cardType;
    }
  };
}
