import { IconBranch } from '@belong/icons';
import classnames from 'classnames/bind';
import styles from './balance-item-icon.module.css';

type BalanceItemIconProps = {
  isFinanceable: boolean;
  className?: string;
};

const cx = classnames.bind(styles);

export function BalanceItemIcon({ isFinanceable, className }: BalanceItemIconProps) {
  if (!isFinanceable) {
    return null;
  }

  return (
    <div className={cx('wrapper', 'flex items-center justify-center rounded-full', className)}>
      <IconBranch height={10} className="text-white" />
    </div>
  );
}
