import { Radio } from '@belong/ui';
import { MaintenanceResponsibility } from 'models/enums';

type Improvement = {
  maintenance: {
    proServiceResponsibility: string;
  };
};
interface Props {
  improvement: Improvement;
  onChange: (improvement: unknown) => void;
}

export const ImprovementResponsibilitySelector = ({ improvement, onChange }: Props) => {
  const handleResponsibilityChange = (proServiceResponsibility: string) => {
    if (improvement.maintenance.proServiceResponsibility !== proServiceResponsibility) {
      const payload = {
        ...improvement,
        maintenance: {
          ...improvement.maintenance,
          proServiceResponsibility,
        },
      };

      onChange(payload);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <Radio
        title="Improvement Responsibility"
        onChange={handleResponsibilityChange}
        defaultValue={improvement.maintenance.proServiceResponsibility}
        options={[
          { label: 'LET BELONG HANDLE IT', value: MaintenanceResponsibility.Belong },
          { label: 'DO IT MYSELF', value: MaintenanceResponsibility.Homeowner },
        ]}
      />
    </div>
  );
};
