import { MaintenanceGroupBundleModel, MaintenanceModel } from 'api/models';

const getMaintenanceSubtaskHasPendingQuoteApproval = (
  userRole: 'Resident' | 'Homeowner',
  bundle: MaintenanceGroupBundleModel
) => {
  return bundle.items?.some(
    (item) =>
      item.maintenancePayments.some((payment) => payment.consentStatus === 'Required' && payment.paidBy === userRole) &&
      !item.isTBD
  );
};

const getMaintenanceHasPendingQuoteApproval = (userRole: 'Resident' | 'Homeowner', maintenance?: MaintenanceModel) => {
  if (!maintenance) {
    return false;
  }

  const hasPendingQuoteApproval = maintenance.subtasks?.some((subtask) =>
    getMaintenanceSubtaskHasPendingQuoteApproval(userRole, subtask)
  );

  return hasPendingQuoteApproval;
};

export const useMaintenanceHasPendingQuoteApproval = (
  userRole: 'Resident' | 'Homeowner',
  maintenance?: MaintenanceModel
) => {
  return getMaintenanceHasPendingQuoteApproval(userRole, maintenance);
};
