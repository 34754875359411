import { ACTIONS } from './constants';
import { hasActiveModal } from './selectors';

export const showModal = (modal, props) => ({
  type: ACTIONS.SHOW_MODAL,
  activeModal: modal,
  props,
});

export const hideModal = () => (dispatch, getState) => {
  if (!hasActiveModal(getState())) {
    return;
  }
  return dispatch({
    type: ACTIONS.HIDE_MODAL,
  });
};
