/* eslint-disable */
import { useEffect } from 'react';
import config from 'config/config';
import ENV from 'config/environments';

export function GoogleTagManager() {
  const { env } = config;

  useEffect(() => {
    if (env === ENV.PRODUCTION) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l != 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-T84J732');
    }
  }, []);

  return null;
}
