import Label from 'components/Label/Label';
import { LabelItem } from 'components/Label/labelTypes';

interface MaintenanceTaskLabelsProps {
  maintenanceLabels: LabelItem[];
}

const MaintenanceTaskLabels = ({ maintenanceLabels }: MaintenanceTaskLabelsProps) => {
  return (
    <div className="lg:h-2xl flex flex-row justify-start md:items-center">
      {maintenanceLabels.map((label, index) => (
        <div className="mr-2xs md:mr-xs" key={index}>
          <Label text={label.text} color={label.color} />
        </div>
      ))}
    </div>
  );
};

export default MaintenanceTaskLabels;
