import { MaintenanceCostProps } from 'containercomponents/MaintenanceStatus/MaintenanceCost/MaintenanceCost';

export const useMaintenanceCostDisplayData = ({ task, userRole }: Pick<MaintenanceCostProps, 'task' | 'userRole'>) => {
  const { isLegacyInvoicing, financedPayment, payments } = task;

  const availableCost = task.estimatedCost || task.actualCost;
  const costItems = availableCost?.items?.filter((item) => !!item.description);
  const additionalFees = availableCost?.items?.filter((item) => !item.description);
  const subTotal = costItems?.reduce((prev, curr) => prev + curr.cost, 0);
  const quoteImages = task.media?.filter((media) => media.stage === 'Before' && media.subOwner === 'Cost');

  const renterResponsibility = payments?.find((payment) => payment.paidBy === 'Resident');
  const homeOwnerResponsibility = payments?.find((payment) => payment.paidBy === 'Homeowner');

  let displayResponsibleCost: number;
  let displayResponsiblePercentage: number;
  let otherResponsibleCost: number;
  let otherResponsiblePercentage: number;

  if (userRole === 'Resident') {
    if (renterResponsibility) {
      displayResponsiblePercentage = renterResponsibility.percentage;
      displayResponsibleCost =
        renterResponsibility.actualAdjustmentApplied || renterResponsibility.estimatedAdjustmentApplied;
    }
    if (homeOwnerResponsibility) {
      otherResponsiblePercentage = homeOwnerResponsibility.percentage;
      otherResponsibleCost =
        homeOwnerResponsibility.actualAdjustmentApplied || homeOwnerResponsibility.estimatedAdjustmentApplied;
    }
  }

  if (userRole === 'Homeowner') {
    if (homeOwnerResponsibility) {
      displayResponsiblePercentage = homeOwnerResponsibility.percentage;
      displayResponsibleCost =
        homeOwnerResponsibility.actualAdjustmentApplied || homeOwnerResponsibility.estimatedAdjustmentApplied;
    }
    if (renterResponsibility) {
      otherResponsiblePercentage = renterResponsibility.percentage;
      otherResponsibleCost =
        renterResponsibility.actualAdjustmentApplied || renterResponsibility.estimatedAdjustmentApplied;
    }
  }

  const balances = userRole === 'Resident' ? renterResponsibility?.balances : homeOwnerResponsibility?.balances;
  const financePlanId = balances?.find((balance) => !!balance.currentFinanceId)?.currentFinanceId;
  const hasEligibleOpenBalance = !!balances?.find((balance) => balance.isOpen && balance.isFinanceable);
  const showUpfront = userRole === 'Homeowner' && !task.actualCost && !isLegacyInvoicing && financedPayment?.dueNow > 0;

  return {
    availableCost,
    displayResponsibleCost,
    displayResponsiblePercentage,
    otherResponsibleCost,
    otherResponsiblePercentage,
    additionalFees,
    subTotal,
    financePlanId,
    hasEligibleOpenBalance,
    showUpfront,
    quoteImages,
    costItems,
  };
};
