import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import SetHeightAnimation from '../../animations/SetHeightAnimation/SetHeightAnimation';
import ButtonBase from '../../corecomponents/ButtonBase/ButtonBase';
import GeneralIcon, { GENERAL_ICONS } from '../GeneralIcon/GeneralIcon';
import styles from './AccountTimeline.module.css';

export const DOT_COLORS = {
  DEFAULT: '', // BLUE
  WHITE: 'bg-white',
};

export const OUTLINE_COLORS = {
  DEFAULT: '', // gray
  DARK_GRAY: 'outline-dark-gray',
  GREEN: 'outline-green',
  NONE: 'outline-color-none',
};

export const DOT_SIZE = {
  DEFAULT: '',
  SMALL: 'small',
  SMALLEST: 'smallest',
};

const cx = classNames.bind(styles);

// Custom Dot starts, no props will result in default Dot.
const Dot = ({ color, outline, size, disabled, onClick }) => {
  const Element = onClick ? `button` : `div`;
  return (
    <Element
      className={cx('dot', color, outline, size, { pointer: !!onClick })}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

Dot.propTypes = {
  color: PropTypes.oneOf(Object.values(DOT_COLORS)),
  outline: PropTypes.oneOf(Object.values(OUTLINE_COLORS)),
  size: PropTypes.oneOf(Object.values(DOT_SIZE)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Dot.defaultProps = {
  color: '',
  outline: OUTLINE_COLORS.NONE, // Looks like dafault is no outline on consumer
  size: DOT_SIZE.SMALLEST, // Looks like default is smallest on consumer
  disabled: false,
  onClick: null,
};

export const TAIL_STYLES = {
  DEFAULT: '', // SOLID
  DASHED: 'dashed',
};
// Custom Tail starts, no props will result in default Tail.
const Tail = ({ tailStyle, height, className }) => {
  return <div className={cx('tail', className, tailStyle)} style={{ minHeight: height }} />;
};

Tail.propTypes = {
  tailStyle: PropTypes.oneOf(Object.values(TAIL_STYLES)),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

Tail.defaultProps = {
  tailStyle: '',
  height: 84,
  className: '',
};

// Item component starts
const Item = ({ className, children, labels, actions, dotContainerClassName, dot, tail }) => {
  return (
    <li className={cx('timeline-item', 'actions-hover', className)}>
      {labels}
      <div className={cx('dot-container', dotContainerClassName)}>
        {dot || <Dot />}
        {tail || <Tail />}
      </div>
      <div className={cx('content-container', { mobileSpace: actions })}>
        {children && <div className={cx('content', { mobileSpace: actions })}>{children}</div>}
        {actions}
      </div>
    </li>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  dotContainerClassName: PropTypes.string,
  labels: PropTypes.node,
  children: PropTypes.node,
  actions: PropTypes.node,
  dot: PropTypes.node,
  tail: PropTypes.node,
};

Item.defaultProps = {
  className: '',
  dotContainerClassName: '',
  labels: null,
  children: null,
  actions: null,
  dot: null,
  tail: null,
};

// Timeline component starts
// Deprecate Children if we going with the measure.
const Timeline = ({ className, children, items, includesEndTail }) => {
  if (!items) {
    return (
      <ul className={cx('timeline', className)}>
        {children}
        {includesEndTail && <Timeline.Item dot={<div className={cx('tail-item')} />} />}
      </ul>
    );
  }
  const labelRef = useRef({});
  const [labelWidth, setLabelWidth] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    setLabelWidth(0);
    if (labelRef.current) {
      setLabelWidth(Math.max(...Object.values(labelRef.current)));
    }
  }, [items]);

  const actionsRef = useRef({});
  const [actionsWidth, setActionsWidth] = useState(0);

  useEffect(() => {
    setActionsWidth(0);
    if (actionsRef.current) {
      setActionsWidth(Math.max(...Object.values(actionsRef.current)));
    }
  }, [items]);

  return (
    <ul className={cx('timeline', className)}>
      {items.map(({ className: itemClassName, left, right, actions, dot, tail, dotContainerClassName }, index) => {
        const actionsElement = Array.isArray(actions) && (
          <div
            ref={(element) => {
              actionsRef.current[index] = element?.getBoundingClientRect().width;
            }}
            // In mobile, we want it to always take only the required space, not the max of all the actions.
            style={{ minWidth: width < BREAKPOINTS_WIDTHS.MD ? 0 : actionsWidth }}
            className={cx('actions-container')}
          >
            {actions.map(({ value, onClick, to }, innerIndex) => (
              <div key={innerIndex} className={cx('action-margin')}>
                <Button buttonType={BUTTON_TYPES.TEXT} to={to} onClick={onClick} className={cx('action')}>
                  {value}
                </Button>
              </div>
            ))}
          </div>
        );

        const labels = (
          <div
            ref={(element) => {
              labelRef.current[index] = element?.getBoundingClientRect().width;
            }}
            className={cx('labels-container')}
            style={{ minWidth: labelWidth }}
          >
            {left}
          </div>
        );

        const { color, outline, size, disabled, onClick } = dot || {};
        return (
          <Item
            key={index}
            className={itemClassName}
            dotContainerClassName={dotContainerClassName}
            tail={<Tail {...tail} />}
            dot={
              React.isValidElement(dot) ? (
                dot
              ) : (
                <Dot outline={outline} color={color} size={size} onClick={onClick} disabled={disabled} tail={tail} />
              )
            }
            actions={actionsElement}
            labels={labels}
          >
            {right}
          </Item>
        );
      })}
      {includesEndTail && (
        <Timeline.Item labels={<div style={{ minWidth: labelWidth }} />} dot={<div className={cx('tail-item')} />} />
      )}
    </ul>
  );
};

Timeline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  includesEndTail: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      left: PropTypes.node,
      right: PropTypes.node,
      actions: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            onClick: PropTypes.func,
          })
        ),
      ]),
      dot: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({
          color: PropTypes.oneOf(Object.values(DOT_COLORS)),
          outline: PropTypes.oneOf(Object.values(OUTLINE_COLORS)),
          disabled: PropTypes.bool,
          onClick: PropTypes.func,
        }),
      ]),
    })
  ),
};

Timeline.defaultProps = {
  className: '',
  children: null,
  items: null,
  includesEndTail: true,
};

Timeline.Item = Item;
Timeline.Dot = Dot;
Timeline.Tail = Tail;

const TimelineHeightAnimation = SetHeightAnimation(Timeline);

const AnimatedTimeline = (props) => {
  const { items } = props;
  const [open, setOpen] = useState(false);

  const toggleShowTimeline = () => {
    setOpen((openValue) => !openValue);
  };

  return (
    <>
      <TimelineHeightAnimation measureDefaultHeight {...props} show={open} items={open ? items : items.slice(0, 5)} />
      {items.length > 5 && (
        <div className={cx('more-button-container')}>
          <ButtonBase className={cx('more-button', { open })} onClick={toggleShowTimeline}>
            {open ? 'SHOW LESS' : 'SHOW MORE'}
            <div className={cx('caret-icon')}>
              <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
            </div>
          </ButtonBase>
        </div>
      )}
    </>
  );
};

AnimatedTimeline.propTypes = {
  items: PropTypes.array.isRequired,
};

export default AnimatedTimeline;
