import React, { Component } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { AutoCompleteFinalFormAdapter } from 'components/AutoComplete/AutoComplete';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import Icon, { ICONS } from 'components/Icon/Icon';
import { InputFinalFormAdapter } from 'components/Input/Input';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import String from 'components/String/String';
import MaintenanceModalAttachmentFields from 'containercomponents/Modals/AddTaskModal/MaintenanceModalAttachmentFields';
import {
  listOfAppliances,
  frequency,
  urgency,
} from 'containercomponents/Modals/ModalListOfItems/ApplianceList/listOfFormObjects';
import arrayMutators from 'final-form-arrays';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { filter, get } from 'lodash-es';
import { RequestType, HaulingTypes } from 'models/enums/index';
import HaulingForm from 'pages/Accounts/FormLayouts/HaulingForm/HaulingForm';
import KeysForm from 'pages/Accounts/FormLayouts/KeysForm/KeysForm';
import PropTypes from 'prop-types';
import { createMaintenanceTask, updateMaintenanceTask } from 'store/redux/maintenance/actions';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { required } from 'utils/validation';
import styles from './AddTaskModal.module.css';
import { calculateDays, convertValuesToDays, convertDaysToValues } from './util';

const cx = classNames.bind(styles);

const timesAmounts = [
  {
    value: 'Days',
    key: 'Days',
  },
  {
    value: 'Weeks',
    key: 'Weeks',
  },
  {
    value: 'Months',
    key: 'Months',
  },
  {
    value: 'Years',
    key: 'Years',
  },
];

export const PRO_SERVICE_TASKS = {
  REPAIR: {
    label: (
      <>
        <div className={cx('mobile-tasks')}>Repair </div>
        <div className={cx('mobile-tasks')}>Something</div>
      </>
    ),
    icon: <Icon icon={ICONS.REPAIR.DEFAULT} responsive />,
    iconSelected: <Icon icon={ICONS.REPAIR.INVERSE} responsive />,
    key: RequestType.Repair,
  },
  ADDITION: {
    label: (
      <>
        <div className={cx('mobile-tasks')}>Install </div>
        <div className={cx('mobile-tasks')}>Something</div>
      </>
    ),
    icon: <Icon icon={ICONS.ADDITION.DEFAULT} responsive />,
    iconSelected: <Icon icon={ICONS.ADDITION.INVERSE} responsive />,
    key: RequestType.Addition,
  },
  UPGRADE: {
    label: (
      <>
        <div className={cx('mobile-tasks')}>Upgrade </div>
        <div className={cx('mobile-tasks')}>Something</div>
      </>
    ),
    icon: <Icon icon={ICONS.UPGRADE.DEFAULT} responsive />,
    iconSelected: <Icon icon={ICONS.UPGRADE.INVERSE} responsive />,
    key: RequestType.Upgrade,
  },
  RECURRING: {
    label: (
      <>
        <div className={cx('mobile-tasks')}>Recurring </div>
        <div className={cx('mobile-tasks')}>Service</div>
      </>
    ),
    key: RequestType.Preventative,
    icon: <Icon icon={ICONS.RECURRING_MAINTENANCE.DEFAULT} responsive />,
    iconSelected: <Icon icon={ICONS.RECURRING_MAINTENANCE.INVERSE} responsive />,
  },

  HAULING_AND_STORAGE: {
    label: (
      <>
        <div className={cx('mobile-tasks')}>Haul/Store </div>
        <div className={cx('mobile-tasks')}>Something</div>
      </>
    ),
    key: RequestType.HaulingAndStorage,
    icon: <Icon icon={ICONS.STORAGE.DEFAULT} responsive />,
    iconSelected: <Icon icon={ICONS.STORAGE.INVERSE} responsive />,
  },
  KEYS_AND_ACCESS: {
    label: (
      <>
        <div className={cx('mobile-tasks')}>Keys </div>
        <div className={cx('mobile-tasks')}>Please!</div>
      </>
    ),
    key: RequestType.KeysAndAccess,
    icon: <Icon icon={ICONS.KEYS_AND_ACCESS.DEFAULT} responsive />,
    iconSelected: <Icon icon={ICONS.KEYS_AND_ACCESS.INVERSE} responsive />,
  },
};

export const selectorTasks = [
  {
    ...PRO_SERVICE_TASKS.REPAIR,
  },
  {
    ...PRO_SERVICE_TASKS.ADDITION,
  },
  {
    ...PRO_SERVICE_TASKS.UPGRADE,
  },
  {
    ...PRO_SERVICE_TASKS.HAULING_AND_STORAGE,
  },
  // {
  //   ...PRO_SERVICE_TASKS.RECURRING,
  // },
];

export const selectedUpgradeRepairAdditionTasks = [
  {
    ...PRO_SERVICE_TASKS.REPAIR,
  },
  {
    ...PRO_SERVICE_TASKS.ADDITION,
  },
  {
    ...PRO_SERVICE_TASKS.UPGRADE,
  },
  {
    ...PRO_SERVICE_TASKS.HAULING_AND_STORAGE,
  },
  {
    ...PRO_SERVICE_TASKS.RECURRING,
    disabled: true,
  },
];

export const selectedPreventative = [
  {
    ...PRO_SERVICE_TASKS.REPAIR,
    disabled: true,
  },
  {
    ...PRO_SERVICE_TASKS.ADDITION,
    disabled: true,
  },
  {
    ...PRO_SERVICE_TASKS.UPGRADE,
    disabled: true,
  },
  {
    ...PRO_SERVICE_TASKS.HAULING_AND_STORAGE,
    disabled: true,
  },
  {
    ...PRO_SERVICE_TASKS.RECURRING,
  },
];

const getButtons = (task) => {
  if (
    task.requestType === RequestType.Repair ||
    task.requestType === RequestType.Addition ||
    task.requestType === RequestType.Upgrade ||
    task.requestType === RequestType.HaulingAndStorage
  ) {
    return selectedUpgradeRepairAdditionTasks;
  }

  if (task.requestType === RequestType.Preventative && task.recurrence) {
    return selectedPreventative;
  }

  return selectorTasks;
};

class AddTaskModal extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    createMaintenanceTask: PropTypes.func.isRequired,
    onEdit: PropTypes.bool,
    task: PropTypes.object,
    updateMaintenanceTask: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onEdit: false,
    task: {},
  };

  state = {
    loading: false,
  };

  getInitialValues() {
    const { task, onEdit } = this.props;

    const { media } = task;
    const filteredMedia = filter(media, (m) => m.stage === 'Before' && !m.subOwner);

    let recurringFrequency = {};

    if (task.recurrence) {
      recurringFrequency = { ...convertDaysToValues(task.recurrence?.repeatFrequencyDays) };
    }

    if (task.status) {
      this.initValue = {
        ...task,
        ...recurringFrequency,
        description: task.description || '',
        attachments: {
          availableMedia: filteredMedia,
          mediaToDisplay: filteredMedia,
          deletedMedia: [],
          later: onEdit ? !filteredMedia?.length : false,
        },
        doesResidentClaimResponsibility: task.isResidentFault?.toString() || 'false',
      };
    }

    return (
      this.initValue || {
        attachments: {
          availableMedia: filteredMedia,
          deletedMedia: [],
        },
      }
    );
  }

  handleRegisterSubmit = async (values) => {
    const { closeModal, property, unit, onEdit, task } = this.props;

    let timePassedInDays;

    // used to calculate the numer of days.
    if (values.requestType === RequestType.Preventative) {
      timePassedInDays =
        calculateDays(values.otherFrequency, values.timeAmount) || convertValuesToDays(values.frequency);
    }

    const optionalObj = {};

    if (property?.basicInfo?.propertyId) {
      optionalObj.propertyId = property.basicInfo.propertyId;
    }

    if (unit?.unitId) {
      optionalObj.homeUniqueId = unit.unitId;
    }

    if (onEdit) {
      optionalObj.auditInfo = {
        dataVersion: task.dataVersion,
      };
    }

    const sendObjectToBackend = {
      ...values,
      ...optionalObj,
      repeatFrequencyDays: values.requestType === RequestType.Preventative ? timePassedInDays : 0,
    };

    this.setState({ loading: true });

    try {
      if (onEdit) {
        await this.props.updateMaintenanceTask(sendObjectToBackend, task.uniqueId);
      } else {
        await this.props.createMaintenanceTask(sendObjectToBackend);
      }
    } catch (err) {
      console.err(err);
    }

    this.setState({ loading: false });
    try {
      await closeModal();
    } catch (err) {
      console.err(err);
    }
  };

  changeFormValue = (value, form) => {
    const { otherFrequency, timeAmount } = value;

    form.batch(() => {
      form.change(otherFrequency, '');
      form.change(timeAmount, '');
    });
  };

  render() {
    const { show, closeModal, task } = this.props;
    const { loading } = this.state;

    return (
      <ModalV2
        show={show}
        onHide={closeModal}
        showSpinner={loading}
        noPadding
        ignoreModalExitAndEnterFunctions
        type={MODAL_TYPES.MAINTENANCE_MODAL}
        backdrop="static"
      >
        <div className={cx('maintenanceStatusModal')}>
          <ModalLayout fixedBottom>
            <ModalLayout.Title>
              <String string={ADD_TASK_MODAL_STRINGS.title} />
            </ModalLayout.Title>
            <ModalLayout.Content>
              <ModalLayout.Section>
                <Form
                  onSubmit={this.handleRegisterSubmit}
                  initialValues={this.getInitialValues()}
                  mutators={{
                    ...arrayMutators,
                  }}
                  getFormBottomBar={(formProps, nextButtonProps) => {
                    return (
                      <ModalLayout.BottomBar
                        ctaProps={{
                          label: 'Submit',
                        }}
                        nextButtonWrapperProps={nextButtonProps}
                        fixedBottom
                      />
                    );
                  }}
                  errorProps={{
                    errorClassNames: cx('transform-error-position'),
                    inline: false,
                  }}
                  bottomPosition="25px"
                  getForm={({ form, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <FormLayout>
                        <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS['addition.title']}>
                          <Field
                            name="requestType"
                            component={SelectorFinalFormAdapter}
                            validate={required}
                            buttons={getButtons(task)}
                            fluid
                            onChangeCustom={() => {
                              if (
                                form.getState().values.requestType === RequestType.HaulingAndStorage &&
                                !get(form.getState().values, 'requestTypeDetails.items')
                              ) {
                                form.change('requestTypeDetails.items', [{}]);
                              }
                            }}
                          />
                        </FormLayout.Section>
                        <Condition when="requestType" is={RequestType.Repair}>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.repair.title}>
                            <Field
                              name="summary"
                              allowUserInput
                              hideAutoCompleteIconOnMobile
                              component={AutoCompleteFinalFormAdapter}
                              items={listOfAppliances}
                              validate={required}
                              placeholder={ADD_TASK_MODAL_STRINGS.appliance_placeholder}
                            />
                          </FormLayout.Section>
                          <FormLayout.Section
                            sectionTitle={<String string={ADD_TASK_MODAL_STRINGS.repair.describe_issue_title} />}
                            sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS.repair.describe_issue_subtitle} />}
                          >
                            <Field
                              name="description"
                              component={InputFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS.repair.describe_issue_placeholder}
                              validate={required}
                              textarea
                            />
                          </FormLayout.Section>
                          <FormLayout.Section
                            sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
                            sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.repair']} />}
                          >
                            <MaintenanceModalAttachmentFields form={form} />
                          </FormLayout.Section>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.urgency}>
                            <Field
                              name="urgency"
                              component={SelectorFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS.urgency_placeholder}
                              buttons={urgency}
                              fluid
                              validate={required}
                              type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                            />
                          </FormLayout.Section>
                        </Condition>
                        {/* Finish When Task is Repair */}
                        {/* When Task is Addition */}
                        <Condition when="requestType" is={RequestType.Addition}>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.addition.title}>
                            <Field
                              name="summary"
                              allowUserInput
                              hideAutoCompleteIconOnMobile
                              validate={required}
                              component={AutoCompleteFinalFormAdapter}
                              items={listOfAppliances}
                              placeholder={ADD_TASK_MODAL_STRINGS.appliance_placeholder}
                            />
                          </FormLayout.Section>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.addition.describe_issue_title}>
                            <Field
                              name="description"
                              component={InputFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS.addition.describe_issue_placeholder}
                              validate={required}
                              textarea
                            />
                          </FormLayout.Section>
                          <FormLayout.Section
                            sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
                            sectionSubTitle={
                              <String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.upgrade_or_addition']} />
                            }
                          >
                            <MaintenanceModalAttachmentFields form={form} />
                          </FormLayout.Section>
                        </Condition>
                        {/* Finish When Task is Addition */}
                        <Condition when="requestType" is={RequestType.Upgrade}>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.upgrade.title}>
                            <Field
                              name="summary"
                              allowUserInput
                              hideAutoCompleteIconOnMobile
                              component={AutoCompleteFinalFormAdapter}
                              items={listOfAppliances}
                              validate={required}
                              placeholder={ADD_TASK_MODAL_STRINGS.appliance_placeholder}
                            />
                          </FormLayout.Section>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.upgrade.describe_issue_title}>
                            <Field
                              name="description"
                              component={InputFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS.upgrade.describe_issue_placeholder}
                              validate={required}
                              textarea
                            />
                          </FormLayout.Section>
                          <FormLayout.Section
                            sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
                            sectionSubTitle={
                              <String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.upgrade_or_addition']} />
                            }
                          >
                            <MaintenanceModalAttachmentFields form={form} />
                          </FormLayout.Section>
                        </Condition>
                        <Condition when="requestType" is={RequestType.Preventative}>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.preventative.title}>
                            <Field
                              name="summary"
                              allowUserInput
                              hideAutoCompleteIconOnMobile
                              component={AutoCompleteFinalFormAdapter}
                              items={listOfAppliances}
                              validate={required}
                              placeholder={ADD_TASK_MODAL_STRINGS.appliance_placeholder}
                            />
                          </FormLayout.Section>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.preventative.describe_issue_title}>
                            <Field
                              name="description"
                              component={InputFinalFormAdapter}
                              validate={required}
                              placeholder={ADD_TASK_MODAL_STRINGS.preventative.describe_issue_placeholder}
                              textarea
                            />
                          </FormLayout.Section>
                          <FormLayout.Section
                            sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
                            sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.recurring']} />}
                          >
                            <MaintenanceModalAttachmentFields form={form} />
                          </FormLayout.Section>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.preventative.frequency_title}>
                            <Field
                              name="frequency"
                              component={SelectorFinalFormAdapter}
                              validate={required}
                              placeholder={ADD_TASK_MODAL_STRINGS.preventative.frequency_placeholder}
                              buttons={frequency}
                              onChangeCustom={() => {
                                this.changeFormValue(
                                  {
                                    otherFrequency: 'otherFrequency',
                                    timeAmount: 'timeAmount',
                                  },
                                  form
                                );
                              }}
                              type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                              fluid
                            />
                            <Condition when="frequency" is="Other">
                              <div className={cx('displayFrequency')}>
                                <div className={cx('frequency-wrapper')}>
                                  <Field
                                    name="otherFrequency"
                                    component={InputFinalFormAdapter}
                                    placeholder="Every (#)"
                                    validate={required}
                                  />
                                </div>
                                <div className={cx('frequency-wrapper')}>
                                  <Field
                                    name="timeAmount"
                                    component={DropdownFinalFormAdapter}
                                    items={timesAmounts}
                                    placeholder="Frequency"
                                    label="Frequency"
                                    validate={required}
                                  />
                                </div>
                              </div>
                            </Condition>
                          </FormLayout.Section>
                        </Condition>
                        <OnChange name="requestType">
                          {(value, previous) => {
                            if (previous === RequestType.HaulingAndStorage) {
                              form.change('summary', '');
                            }
                            if (value === RequestType.HaulingAndStorage) {
                              let valuesToChange = get(form.getState().values, 'requestTypeDetails.type');
                              if (valuesToChange === HaulingTypes.Haul) {
                                valuesToChange = 'Item removal';
                              } else if (valuesToChange === HaulingTypes.Storage) {
                                valuesToChange = 'Item storage';
                              }
                              form.change('summary', valuesToChange);
                            }
                          }}
                        </OnChange>
                        <Condition when="requestType" is={RequestType.HaulingAndStorage}>
                          <HaulingForm form={form} />
                        </Condition>
                        <Condition when="requestType" is={RequestType.KeysAndAccess}>
                          <KeysForm form={form} />
                        </Condition>
                      </FormLayout>
                    </form>
                  )}
                />
              </ModalLayout.Section>
            </ModalLayout.Content>
          </ModalLayout>
        </div>
      </ModalV2>
    );
  }
}

const mapDispatchToProps = {
  updateMaintenanceTask,
  createMaintenanceTask,
};

export default connect(null, mapDispatchToProps)(AddTaskModal);
