import React from 'react';
import PropTypes from 'prop-types';
import { FamilyOccupiedMoveOutSection } from '../FamilyOccupiedMoveOutSection/FamilyOccupiedMoveOutSection';
import { AdditionalInfoSection } from '../AdditionalInfoSection/AdditionalInfoSection';
import { RentedBeforeSection } from '../RentedBeforeSection/RentedBeforeSection';
import { RentingPlansSection } from '../RentingPlansSection/RentingPlansSection';

export const FamilyOccupiedForm = ({ changeFormValue, name }) => (
  <>
    <FamilyOccupiedMoveOutSection name={name} />
    <AdditionalInfoSection name={name} />
    <RentedBeforeSection changeFormValue={changeFormValue} name={name} />
    <RentingPlansSection name={name} />
  </>
);

FamilyOccupiedForm.propTypes = {
  changeFormValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
