import { Field as ReactFinalFormField } from 'react-final-form';
import cx from 'classnames';
import { FormContext } from 'components/Form/Form';
import { FormLayoutContext } from 'layouts/FormLayout/FormLayout';

type FieldProps = {
  name: string;
  component: any;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  multiLineLabel?: boolean;
  textarea?: boolean;
  hidden?: boolean;
  nestedField?: boolean;
  fluid?: any;
  mode?: any;
  form?: any;
  featuresList?: unknown[];
  validate?: any;
  firstSection?: boolean;
  buttons?: any[];
  onChangeCustom?: any;
  useDatesFromValues?: boolean;
  withMarginTop?: boolean;
  applyDisabledStyle?: boolean;
  marginTop?: boolean;
  mask?: any;
  unmask?: any;
  grid?: boolean;
  gridSize?: any;
  verticalSpaceBetween?: any;
  type?: any;
  alignWithField?: any;
  options?: any;
  mediaLabel?: any;
  checkboxProps?: any;
  size?: any;
  isSingleMedia?: boolean;
  disableWebcam?: boolean;
  availableItems?: any;
  tagsToShow?: any;
  selectedItem?: any;
  onInputValueChange?: any;
  inputValue?: any;
  onRemoveItem?: any;
  useOnChangeFormToHandle?: any;
  alignCenter?: any;
  max?: any;
  infinityObject?: any;
  onClickCustom?: any;
  items?: any;
  marginOnDesktop?: any;
  inputProps?: any;
  showTrashButton?: boolean;
  showCheckmark?: boolean;
  noBorder?: boolean;
  allowUserInput?: boolean;
  hideAutoCompleteIconOnMobile?: boolean;
  openMenuOnFocus?: boolean;
  defaultFileName?: string;
  isMultiUnit?: boolean;
  titleClassName?: string;
  responsive?: boolean;
  horizontalOnDesktop?: boolean;
  buttonClassName?: string;
  availabilityDate?: Date;
  timeZone?: string;
  coverageRegionId?: number;
  allowSchedulingFrom?: Date;
  viewOnly?: boolean;
  cleanOnMediaChange?: boolean;
  employee?: any;
  unit?: any;
  appointments?: any;
  tagClassName?: string;
  min?: number;
  defaultValue?: any;
  renderLeft?: any;
  renderRight?: any;
  renderTop?: any;
  renderThumbDetail?: any;
  thumbContainerClassName?: string;
  disabledDays?: any;
  rootClassName?: string;
  defaultMonth?: any;
  timePickerWrapperClassName?: string;
  sideInformationWrapperClassName?: string;
  wrapperClassName?: string;
  calendarRootClassName?: string;
  demandWrapperClassName?: string;
  fieldWrapperClassName?: string;
};

export function Field({
  hidden = false,
  nestedField = false,
  firstSection = false,
  withMarginTop = true,
  fieldWrapperClassName,
  ...rest
}: FieldProps) {
  return (
    <FormContext.Consumer>
      {(showErrors) => {
        return (
          <FormLayoutContext.Consumer>
            {(isInFormLayout) => {
              if (isInFormLayout) {
                return (
                  <div
                    className={cx(
                      { 'pt-sm': withMarginTop, 'pt-0': hidden || nestedField || firstSection },
                      fieldWrapperClassName
                    )}
                  >
                    <ReactFinalFormField
                      {...rest}
                      name={rest.name}
                      ignoreError={!showErrors}
                      hidden={hidden}
                      newFieldComponent
                    />
                  </div>
                );
              }
              console.warn('All Fields should be in a FormLayout');
              return <ReactFinalFormField {...rest} name={rest.name} ignoreError={!showErrors} />;
            }}
          </FormLayoutContext.Consumer>
        );
      }}
    </FormContext.Consumer>
  );
}

export default Field;
