import React, { Component } from 'react';
import { formatUtcDateTimeToTimeZone, formatDateTime, DATE_FORMATS } from '@belong/common';
import PropTypes from 'prop-types';
import './BDate.module.css';

export const DATE_TYPES = DATE_FORMATS;

export default class BDate extends Component {
  static propTypes = {
    /**
     * 2018/09/05:30, TomG
     * gets the time, either via millisecond or
     * or a string via ISO 8601 Notation
     * https://en.wikipedia.org/wiki/ISO_8601
     */
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    className: PropTypes.string,
    // formats the date.
    formatType: PropTypes.string,
    // If true, date/time will be formatted in PST
    timeZone: PropTypes.string,
    withTimeZone: PropTypes.bool,
  };

  static defaultProps = {
    time: null,
    formatType: DATE_TYPES.STANDARD,
    timeZone: 'America/Los_Angeles',
    withTimeZone: false,
    className: '',
  };

  render() {
    const { time, formatType, className, timeZone, withTimeZone } = this.props;
    let getCurrentTime;

    if (!time) {
      getCurrentTime = new Date().getTime();
    }

    const returnDateStamp = withTimeZone ? (
      <time className={className}>
        {formatUtcDateTimeToTimeZone({ dateTime: time || getCurrentTime, format: formatType, timeZone })}
      </time>
    ) : (
      <time className={className}>{formatDateTime({ dateTime: time || getCurrentTime, format: formatType })}</time>
    );

    return returnDateStamp;
  }
}
