import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Modal, Button, Spinner } from '@belong/ui';
import { GroupBalanceInfoModel } from 'api/models';
import { HomeDetailInfoModel, MonthSummaryInfoModel, BillingInfoModel } from 'api/models.v2';
import { startOfMonth, endOfMonth, startOfDay, format, subMonths, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { MonthSummary } from 'pages/Accounts/Components/month-summary/month-summary';
import { getMonthName } from 'pages/Accounts/utils/get-month-name';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import { fetchUsersBalancesPdf } from 'store/redux/accounts/actions';
import { selectAccountPropertyByUnitId, selectAccountUnitByUnitId } from 'store/redux/homeowner-accounts/selectors';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';
import { EARNINGS_STRINGS } from '../earnings.strings';
import { Downloader } from './downloader';

type PastMonthModalProps = {
  address?: string;
  canDownload?: boolean;
  onHide: () => void;
  show?: boolean;
  homeDetail: HomeDetailInfoModel & { monthSummaryInfo: MonthSummaryInfoModel };
  handleFinancingModal?: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowFinanceSuccessModal?: () => void;
  handleShowBalanceDetailsModal?: (
    balance: GroupBalanceInfoModel,
    home: string,
    billingInfo?: BillingInfoModel
  ) => void;
};

export function PastMonthModal({
  onHide,
  canDownload = true,
  show,
  homeDetail,
  handleFinancingModal,
  handleShowFinanceSuccessModal,
  handleShowBalanceDetailsModal,
  address = '',
}: PastMonthModalProps) {
  const [blob, setBlob] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const property = useSelector(selectAccountPropertyByUnitId(homeDetail?.homeId));
  const unit = useSelector(selectAccountUnitByUnitId(homeDetail?.homeId));
  const homeAddress = address || formatString(addressAndUnit(property?.address, unit?.basicInfo.unitNumber));
  const monthName = getMonthName(homeDetail.monthSummaryInfo.month);
  const pdfAddress =
    address || addressAndUnit(property?.address, unit?.basicInfo.unitNumber).replace(/(<([^>]+)>)/gi, '');

  function getShouldShowPDFButton(year: number, month: number) {
    const date = subMonths(utcToZonedTime(new Date(), 'America/Los_Angeles'), 1);

    const otherDate = utcToZonedTime(new Date(), 'America/Los_Angeles');
    otherDate.setDate(7);
    otherDate.setMonth(month - 1);
    otherDate.setFullYear(year);

    return isAfter(date, startOfDay(otherDate));
  }

  function handleClose() {
    if (onHide) onHide();
  }

  async function downloadPDF() {
    try {
      setLoading(true);

      const from = startOfMonth(new Date().setMonth(homeDetail.monthSummaryInfo.month - 1));
      const until = endOfMonth(from);

      const pdfBlob = await dispatch(
        fetchUsersBalancesPdf({
          homeUniqueId: homeDetail.homeId,
          from: `${homeDetail.monthSummaryInfo.year}-${format(from, 'MM-dd')}T00:00:00.000Z`,
          until: `${homeDetail.monthSummaryInfo.year}-${format(until, 'MM-dd')}T23:59:59.999Z`,
          groupBalanceType: address ? 'Flat' : 'ExpensesAndRevenues',
        })
      );
      if (pdfBlob) {
        setBlob(pdfBlob);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={show} onDismiss={handleClose} name="past-months-modal" overlayClassName="z-toast">
      {loading && <Spinner />}
      <div className="p-xs">
        <Text variant="h2" fontWeight="semibold" className="mt-xl text-center">
          {EARNINGS_STRINGS['modal.past_month.title']}
        </Text>
        <div className="mx-md my-lg">
          <div className="flex justify-between mb-md">
            <Text className="text-h3-sm mb-xs">{homeAddress}</Text>
            <Text className="text-h3-sm mb-xs text-right">{`${monthName.slice(0, 3)} ${
              homeDetail.monthSummaryInfo.year
            }`}</Text>
          </div>
          <MonthSummary
            address={address}
            month={getMonthName(homeDetail.monthSummaryInfo.month)}
            homeDetail={homeDetail}
            handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
            handleFinancingModal={handleFinancingModal}
            handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
            onlyHome
            noBorder
            hideSplitIt
          />
        </div>
      </div>

      <QuestionsOrConcernsModalFooter>
        {getShouldShowPDFButton(homeDetail.monthSummaryInfo.year, homeDetail.monthSummaryInfo.month) && (
          <>
            {canDownload && (
              <Button variant="text" onClick={downloadPDF} buttonType="secondary">
                {EARNINGS_STRINGS['monthly_statement.download_pdf']}
              </Button>
            )}
          </>
        )}
      </QuestionsOrConcernsModalFooter>
      {blob && (
        <Downloader
          blob={blob}
          filename={`${monthName.slice(0, 3)} ${homeDetail.monthSummaryInfo.year} - ${pdfAddress}`}
          onDownload={() => setBlob(null)}
        />
      )}
    </Modal>
  );
}
