import clsx from 'clsx';
import { ALIGN_ITEMS } from '../MaintenanceStatus/UserActionMaintenance/UserProfilePopout/UserProfilePopout';
import ResidentTileWithStatus from '../ResidentTileWithStatus/ResidentTileWithStatus';

type Props = {
  residents: Record<string, unknown>[];
  orientation: 'horizontal' | 'vertical';
};

const ResidentTileListWithStatus = ({ residents, orientation = 'horizontal' }: Props) => {
  return (
    <div
      className={clsx('grid gap-md', {
        'grid-cols-1 justify-start': orientation === 'vertical',
        'grod-cols-1 md:grid-cols-2': orientation === 'horizontal',
      })}
    >
      {residents.map((resident, index) => (
        <div key={index} className="basis-full md:basis-1/2 flex-auto">
          <ResidentTileWithStatus alignProfileItems={ALIGN_ITEMS.ALIGN_LEFT} {...resident} />
        </div>
      ))}
    </div>
  );
};

export default ResidentTileListWithStatus;
