import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './TwoColumnLayout.module.css';

const cx = classNames.bind(styles);

const propTypes = {
  items: PropTypes.array.isRequired,
  padding: PropTypes.number.isRequired,
};

const TwoColumnLayout = ({ items, padding }) => {
  const colStyle = {
    width: `calc(50% - ${padding / 2}px`,
    margin: `${padding}px ${padding}px 0 0`,
  };

  return (
    <div className={cx('items')}>
      {items.map((item, index) => (
        <div key={index} className={cx('item')} style={colStyle}>
          {item}
        </div>
      ))}
    </div>
  );
};

TwoColumnLayout.propTypes = propTypes;

export default TwoColumnLayout;
