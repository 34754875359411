import { ACTIONS } from './constants';

export const HOMEOWNER_ONBARDING_LEADRS_REDUCER = 'homeownerOnboardingLeads';

const initialState = {
  addresses: [],
};

const homeownerOnboardingLeadsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.UPDATE_PROPERTY_ADDRESSES:
      return {
        ...state,
        addresses: [...action.addresses],
      };
    case ACTIONS.DELETE_PROPERTY_ADDRESSES:
      return {
        ...state,
        addresses: [],
      };

    default:
      return state;
  }
};

export default homeownerOnboardingLeadsReducer;
