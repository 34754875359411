import queryString from 'query-string';
import config from 'config/config';

const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap?';

// eslint-disable-next-line max-len
// Todo: https://stackoverflow.com/questions/55857921/google-maps-markers-not-supporting-ssl-from-aws-s3-bucket-i-need-to-use-http-or.
const propertyIconUrl = 'https://s3-us-west-2.amazonaws.com/belong-web-assets/home_mark_v1.png';

export const staticMapUrlGenerator = ({ iconUrl, streetAddress, city, state, zoom = 13 }) => {
  const location = [streetAddress, city, state].join(',').replace(' ', '+');
  const params = {
    center: location,
    zoom,
    size: '1200x800',
    style: 'feature:poi|visibility:off',
    markers: `anchor:bottom|icon:${iconUrl}|${location}`,

    // DISABLING
    key: config.googleMapsApiKey,
  };

  return `${baseUrl}${encodeURI(queryString.stringify(params, { encode: false }))}`;
};

export const propertyStaticMapImageUrl = (args) =>
  staticMapUrlGenerator({
    iconUrl: propertyIconUrl,
    ...args,
  });
