import { MaintenancePaymentPlan } from 'models/enums';
import type { HomeOwnerPayment, ReportPayment } from 'post-inspection/models/improvements-report';

type Params = {
  homeownerPayment: HomeOwnerPayment;
  isLegacyInvoicing: boolean;
  isMonthly: boolean;
  maximumMonthlyPaymentPlanInstallmentCount?: number;
  homeownerReportPayment: ReportPayment;
};

type Result = {
  totalCost: number;
};

export const useImprovementsTotalCost = ({
  homeownerPayment,
  isLegacyInvoicing,
  isMonthly,
  maximumMonthlyPaymentPlanInstallmentCount = 0,
  homeownerReportPayment,
}: Params): Result => {
  let monthlyCost = 0;
  let totalCost = 0;

  if (isLegacyInvoicing) {
    let months = maximumMonthlyPaymentPlanInstallmentCount;

    if (homeownerPayment.paymentPlan === MaintenancePaymentPlan.Monthly) {
      months = homeownerPayment.installmentCount;
    }

    if (homeownerPayment.paymentPlan === MaintenancePaymentPlan.OneTime) {
      months = 0;
    }

    if (months) {
      const installmentCostDetail = homeownerPayment.estimatedInstallmentBreakdown?.find(
        (estimatedInstallmentBreakdownItem) => estimatedInstallmentBreakdownItem.installmentCount === months
      );

      if (installmentCostDetail) {
        monthlyCost = installmentCostDetail.totalCost;
      }
    }

    totalCost = isMonthly ? monthlyCost : homeownerPayment.estimatedNetTotal;
  } else {
    totalCost = homeownerReportPayment.total;
  }

  return { totalCost };
};
