import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { PATHS } from '@belong/common';
import { datadogRum } from '@datadog/browser-rum';

type Props = {
  error: any;
};

export function Fallback({ error }: Props) {
  const history = useHistory();

  useEffect(() => {
    datadogRum.addError(error, {
      error_fallback: 'true',
    });

    history.push(PATHS.SUPPORT);
  });

  return <div>An error has occurred</div>;
}
