// React imports
import React, { Component } from 'react';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCreditCardNumber, maskMMYY, unmaskCreditCardNumber, unmaskMMYY } from 'components/Input/masks';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { PAYMENT_FLOW_STRINGS } from 'strings/payment-flow-modal.strings';
import {
  composeValidators,
  required,
  creditCard,
  creditCardCvc,
  dateValidationMMYY,
  zipcode,
  isFutureDateMMYY,
} from 'utils/validation';

const PFS_CC = PAYMENT_FLOW_STRINGS.credit_card;

export default class CreditCardPayment extends Component {
  static propTypes = {
    formValues: PropTypes.object.isRequired,
    disableFields: PropTypes.bool,
  };

  static defaultProps = {
    disableFields: false,
  };

  render() {
    const { formValues, disableFields } = this.props;

    return (
      <>
        <Row>
          <Col md={12}>
            <Field
              name="creditCardNumber"
              autocomplete="cc-number"
              placeholder={PFS_CC.card_number_label}
              component={InputFinalFormAdapter}
              mask={maskCreditCardNumber}
              unmask={unmaskCreditCardNumber}
              validate={disableFields ? () => {} : composeValidators(creditCard, required)}
              key={disableFields ? `creditCardNumber.inputValue0` : `creditCardNumber.inputValue1`}
              disabled={disableFields}
              applyDisabledStyle={disableFields}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Field
              name="expirationDate"
              autocomplete="cc-exp"
              placeholder={PFS_CC.exp_date_label}
              component={InputFinalFormAdapter}
              mask={maskMMYY}
              unmask={unmaskMMYY}
              validate={disableFields ? () => {} : composeValidators(dateValidationMMYY, isFutureDateMMYY, required)}
              disabled={disableFields}
              key={disableFields ? `expirationDate.inputValue0` : `expirationDate.inputValue1`}
              applyDisabledStyle={disableFields}
            />
          </Col>
          <Col md={4}>
            <Field
              name="billingZipCode"
              placeholder={PFS_CC.zip_label}
              component={InputFinalFormAdapter}
              validate={disableFields ? () => {} : composeValidators(required, zipcode)}
              disabled={disableFields}
              key={disableFields ? `billingZipCode.inputValue0` : `billingZipCode.inputValue1`}
              applyDisabledStyle={disableFields}
            />
          </Col>
          <Col md={4}>
            <Field
              name="cvc"
              autocomplete="cc-csc"
              placeholder={PFS_CC.cvc}
              component={InputFinalFormAdapter}
              validate={
                disableFields
                  ? () => {}
                  : composeValidators((value) => creditCardCvc(value, formValues.creditCardNumber), required)
              }
              key={disableFields ? `cvc.inputValue0` : `cvc.inputValue1`}
              disabled={disableFields}
              applyDisabledStyle={disableFields}
            />
          </Col>
        </Row>
      </>
    );
  }
}
