import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

export const timelineSteps = [
  {
    value: LEASE_SIGNING_FLOW_STRINGS['success.timeline.submit'],
    checked: true,
  },
  {
    value: LEASE_SIGNING_FLOW_STRINGS['success.timeline.approved'],
    checked: true,
  },
  {
    value: LEASE_SIGNING_FLOW_STRINGS['success.timeline.download'],
    checked: false,
    active: true,
  },
  {
    value: LEASE_SIGNING_FLOW_STRINGS['success.timeline.onboarding'],
    checked: false,
  },
  {
    value: LEASE_SIGNING_FLOW_STRINGS['success.timeline.move'],
    checked: false,
  },
];
