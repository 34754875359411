import React from 'react';
import classNames from 'classnames/bind';
import Image from 'components/Image/Image';
import PropTypes from 'prop-types';
import styles from './ProfileImageLarge.module.css';

const cx = classNames.bind(styles);

const ProfileImageLarge = ({ imageContainer, profileImage, withHover }) => {
  return (
    <div className={cx(imageContainer, 'large-profile-image-wrapper', { withHover })}>
      <Image className={cx('image')} src={profileImage} />
    </div>
  );
};

ProfileImageLarge.propTypes = {
  profileImage: PropTypes.string.isRequired,
  imageContainer: PropTypes.string,
  withHover: PropTypes.bool,
};

ProfileImageLarge.defaultProps = {
  imageContainer: '',
  withHover: false,
};

export default ProfileImageLarge;
