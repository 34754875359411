import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TourRequestModel, UnitListingDetailsModel } from 'api/models';
import TourAndAppsCard from 'containercomponents/TourAndAppsCard/TourAndAppsCard';
import { TourRequestCard } from 'containercomponents/TourAndAppsCard/TourRequestCard';
import { UserVerificationType } from 'models/enums';
import RenterAccountBookmark from 'models/renterAccounts/RenterAccountBookmark';
import RenterAccountLease from 'models/renterAccounts/RenterAccountLease';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import {
  selectListingApplicationBookmarks,
  selectOfferedAndSignedLeases,
  selectTourBookmarks,
  selectActiveTourRequests,
} from 'store/redux/renter-accounts/selectors';
import { fetchVerificationFor } from 'store/redux/user/actions';
import AccountPage from '../AccountPage/AccountPage';

type Props = {
  tours: RenterAccountBookmark[];
  listingApplications: RenterAccountBookmark[];
  leases: RenterAccountLease[];
  tourRequests: (TourRequestModel & { homeDetails: UnitListingDetailsModel })[];
  fetchVerification: (verificationType: string) => Promise<boolean>;
};

const ToursAndApps = ({
  tours = [],
  listingApplications = [],
  tourRequests = [],
  fetchVerification,
  leases,
}: Props) => {
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const getIsVerified = async () => {
      try {
        const _isVerified = await fetchVerification(UserVerificationType.Identity);
        setIsVerified(_isVerified);
      } catch (err) {
        console.error(err);
      }
    };
    getIsVerified();
  }, [fetchVerification]);

  // Tours where the user hasn't applied to that home yet.
  const standAloneTours = tours.filter((tourItem) => {
    const tourUnitId = tourItem.unit.basicInfo.id;
    return !listingApplications.find((listingApplication) => tourUnitId === listingApplication.unit.basicInfo.id);
  });

  // We want a single card per home, so here we join listing appications with leases (if any) and tours (if any)
  const listingApplicationsWithTourAndLeaseInfo = listingApplications.map((listingApplication) => {
    const lease = leases.find((leaseItem) => {
      const leaseListingApplicationId = leaseItem.leaseInfo.basicInfo.listingApplicationUniqueId;
      return leaseListingApplicationId === listingApplication.listingApplication?.uniqueId;
    });

    if (lease) {
      listingApplication.leaseInfo = lease.leaseInfo;
    }

    const tour = tours.find((tourItem) => tourItem.unit.basicInfo.id === listingApplication.unit.basicInfo.id);
    if (tour) {
      return {
        ...listingApplication,
        attendeeId: tour.attendeeId,
        attendeeStatus: tour.attendeeStatus,
        displayStatus: tour.displayStatus,
        hasPaymentHold: tour.hasPaymentHold,
        tourId: tour.tourId,
        tourType: tour.tourType,
        visitEndOn: tour.visitEndOn,
        visitStartOn: tour.visitStartOn,
      };
    }
    return listingApplication;
  });

  const content = (
    <div className="flex flex-col gap-lg">
      {[...standAloneTours, ...listingApplicationsWithTourAndLeaseInfo].map((bookmark) => (
        <TourAndAppsCard
          key={`tours-and-applications-${bookmark.applicationId}`}
          bookmark={bookmark}
          isVerified={isVerified}
        />
      ))}
      {tourRequests.map((tourRequest) => (
        <TourRequestCard key={`tour-request-${tourRequest.uniqueId}`} tourRequest={tourRequest} />
      ))}
    </div>
  );

  return (
    <AccountPage
      headerProps={{
        headerText: 'Tours and Apps',
      }}
    >
      <AccountPageSection content={content} />
    </AccountPage>
  );
};

function mapStateProps(state) {
  return {
    tours: selectTourBookmarks(state),
    listingApplications: selectListingApplicationBookmarks(state),
    leases: selectOfferedAndSignedLeases(state),
    tourRequests: selectActiveTourRequests(state),
  };
}
const mapDispatchToProps = {
  fetchVerification: fetchVerificationFor,
};

export default connect(mapStateProps, mapDispatchToProps)(ToursAndApps);
