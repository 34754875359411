import { Text } from '@belong/ui';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getHomeListPrice } from 'utils/mapUtils';
import css from './RentedMarker.module.css';

const RentedMarker = ({ isHover, isActive, home }) => (
  <div
    className={clsx(
      'absolute flex flex-col py-2xs bg-dark-gray items-center rounded-small cursor-pointer -translate-x-1/2',
      css.markerMap,
      {
        'z-10 bg-green': isHover || isActive,
        [css.hover]: isHover || isActive,
      }
    )}
  >
    <Text variant="p2" fontWeight="semibold" className="text-white">
      {`Off\u00a0Market`}
    </Text>
    <Text fontWeight="semibold" className="text-white">
      {getHomeListPrice(home.rentAmount)}
    </Text>
  </div>
);

RentedMarker.propTypes = {
  isHover: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  home: PropTypes.object.isRequired,
};

export default RentedMarker;
