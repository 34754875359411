import { PropertyFeaturesFormFields } from 'accounts/components/form-fields/property-features';

export const SUPPORTED_PROPERTY_FEATURES_NAMES = {
  Pool: {
    title: 'Communal Pool',
    name: 'Pool',
  },
  Jacuzzi: {
    title: 'Communal Hot Tub',
    name: 'Hot Tub/ Jacuzzi',
  },
  Grill: {
    title: 'Communal Grill/BBQ',
    name: 'Grill/ BBQ'
  },
  Gym: {
    title: 'Gym',
    name: 'Gym'
  },
  Basketball: {
    title: 'Basketball Court',
    name: 'Basketball Court'
  },
  Tennis: {
    title: 'Tennis Court',
    name: 'Tennis Court'
  },
  SteamRoom: {
    title: 'Steam Room or Sauna',
    name: 'Steam Room'
  },
  Laundry: {
    title: 'Communal Laundry Room',
    name: 'Laundry'
  },
  Mailroom: {
    title: 'Mail Room',
    name: 'Mailroom/ Package Room'
  },
  ChargingStation: {
    title: 'Charging Station',
    name: 'Charging Station'
  },
};

export const PROPERTY_FEATURE_CATEGORIES = {
  'Climate Control': 'Climate Control',
  Laundry: 'Laundry',
  Fitness: 'Fitness',
  Cooking: 'Cooking',
  Pool: 'Pool',
  Parking: 'Parking',
  Safety: 'Safety & Security',
};

export const PROPERTY_FEATURES_LIST = [
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Pool.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Pool.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Jacuzzi.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Jacuzzi.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Grill.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Grill.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Gym.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Gym.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Basketball.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Basketball.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Tennis.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Tennis.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.SteamRoom.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.SteamRoom.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Laundry.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Laundry.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.Mailroom.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.Mailroom.title,
      formFields: <PropertyFeaturesFormFields />,
    },
    {
      name: SUPPORTED_PROPERTY_FEATURES_NAMES.ChargingStation.name,
      title: SUPPORTED_PROPERTY_FEATURES_NAMES.ChargingStation.title,
      formFields: <PropertyFeaturesFormFields />,
    },
  ]