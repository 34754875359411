export default class ResidentLeaseInfo {
  constructor(data = {}) {
    this.residentType = data.residentType;
    this.depositAmount = data.depositAmount;
    this.moveInDate = data.moveInDate;
    this.moveOutDate = data.moveOutDate;
    this.rentAmount = data.rentAmount;
    this.signatureStatus = data.signatureStatus;
  }
}
