import { get } from 'lodash-es';

export function createTableTree({ data, accessor = 'employee.id', link = 'manager.id' }) {
  const map = {};
  const tree = [];
  let node;
  let i;

  for (i = 0; i < data.length; i += 1) {
    map[get(data[i], accessor)] = i; // initialize the map
    data[i].balances = []; // initialize the children
  }

  for (i = 0; i < data.length; i += 1) {
    node = {
      ...data[i],
      key: `${get(data[i], accessor)}`,
      balances: data[i].balances,
    };

    if (get(data[i], link) !== undefined) {
      // if you have dangling branches check that map[link] exists
      data[map[get(data[i], link)]].balances.push(node);
    } else {
      tree.push(node);
    }
  }

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach(
      (key) =>
        (key === 'balances' && obj[key].length === 0 && delete obj[key]) ||
        (obj[key] && typeof obj[key] === 'object' && removeEmpty(obj[key]))
    );
    return obj;
  };

  return tree.map((item) => removeEmpty(item));
}
