export const MOVEOUT_PLANS = {
  Correct: 'Correct',
  NotCorrect: 'NotCorrect',
  NoticeGiven: 'NoticeGiven',
  AlreadyMovedOut: 'AlreadyMovedOut',
};

export const RENTER_OCCUPIED_MOVEOUT_OPTIONS = [
  { label: `That's correct!`, key: MOVEOUT_PLANS.Correct },
  { label: 'Not quite.', key: MOVEOUT_PLANS.NotCorrect },
  { label: 'They gave notice.', key: MOVEOUT_PLANS.NoticeGiven },
  { label: 'They moved out.', key: MOVEOUT_PLANS.AlreadyMovedOut },
];

export const ONBOARDING_LEASE_FILES_OPTIONS = [
  { label: "Yes, let’s <span>upload now!</span>", key: false },
  { label: "No, remind <span>me later.</span>", key: true },
];
