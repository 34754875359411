import React from 'react';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { required } from 'utils/validation';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import Field from 'components/Field/Field';

export const AvailableForRentSection = ({ changeFormValue, form, name }) => (
  <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.availableQuestion}>
    <Field
      component={YesNoFinalFormAdapter}
      name={`${name}.availableToRent`}
      onChangeCustom={() => {
        changeFormValue(
          {
            [`${name}.availableForRentOn`]: `${name}.availableForRentOn`,
          },
          form
        );
      }}
      validate={required}
    />
  </FormLayout.Section>
);

AvailableForRentSection.propTypes = {
  changeFormValue: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
