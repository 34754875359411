export const STRINGS = {
  back: 'BACK',
  'bi-weekly': 'Bi-Weekly',
  'book-a-pro': 'BOOK A PRO',
  'disabled-services':
    'This service may be a requirement in your lease to keep your home in good condition. Contact your Member Success Lead with any questions about canceling.',
  'frequency-question': 'How frequently would you like us to perform this?',
  est: 'Est.',
  'estimated-date': 'Estimated Date',
  'extras-question': 'Is there anything we should know? (Optional)',
  less: 'Less',
  monthly: 'Monthly',
  more: 'More',
  'non-recurring-title': 'Repairs, Upgrades & More',
  'no-recurring-services': 'No recurring services',
  'no-tasks': 'No tasks',
  off: 'OFF',
  on: 'ON',
  'per-visit': 'per visit',
  'pricing-tbd': 'Pricing TBD',
  recommended: 'Recommended',
  'recurring-title': 'Recurring Services',
  'request-an-upgrade': 'REQUEST AN UPGRADE',
  'resident-banner': "You don't need to lift a finger. We will handle communication with the resident.",
  responsibility: 'Responsibility',
  'select-home': 'Select Home',
  'show-completed': 'SHOW COMPLETED',
  'show-turned-off': 'Show Turned Off',
  'starting-from': 'Starting From',
  show: 'Show',
  unit: 'Unit',
  'unique-needs': 'Unique Needs',
  weekly: 'Weekly',
  'which-home': 'Which home?',
  'add-title-homeowner': 'Increase ROI',
  'add-subtitle-homeowner': 'The 1% Rule',
  'add-body-homeowner':
    "Data suggests that to maximize home appreciation, you should spend around 1% of your home's value on annual upkeep.",
  'add-title-resident': 'Make Your Home Sparkle',
  'add-subtitle-resident': `Even More Magic In The\u00a0Belong App`,
  'add-body-resident': 'Download and use the app to submit and stay up to date on pro\u00a0service tickets.',
  'pristine-home': 'A Pristine Home',
  'get-settled': 'Get Settled In',
  'not-too-hot': 'Not Too Hot Or Too Cold',
  'latest-tech': 'The Latest Home Tech',
  preventative: 'Preventative',
  'home-services': 'Home Services',
  'all-visits': 'For All Visits',
  'special-requests': 'Special Requests for this Visit',
  delete: 'DELETE',
  add: 'ADD',
  save: 'SAVE',
  edit: 'EDIT',
  'submitted-pro-service': 'Successfully submitted Pro Service ticket',
  'updated-pro-service': 'Your pro service ticket was updated.',
  'turn-off-recurring': "Updated. You'll no longer receive this service.",
  'change-frequency': "Updated. We'll be in touch if there is any price adjustment at this frequency.",
  'appointment-too-soon':
    'Contact your Member Success Lead about cancelling because this visit is occurring in < 24 hrs.',
  'single-visit-cancelled': 'Cancelled the next visit.',
  'single-visit-cancelled-date': 'Cancelled the next visit. We will resume',
  around: 'around',
  'submit-request': 'Request A Pro Service',
  'create-request': 'Create Pro Service Request',
  'edit-request': 'Edit Pro Service Request',
};
