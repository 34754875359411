export default class CustomPaymentFees {
  constructor(data = {}) {
    this.fee = data.fee;
    this.feeAmount = data.feeAmount;
    this.feeType = data.feeType;
    this.paymentMethod = data.paymentMethod;
    this.remainingAmountWithFee = data.remainingAmountWithFee;
    this.totalWithFee = data.totalWithFee;
    this.isDomestic = data.isDomestic;
    this.isInternational = data.isInternational;
  }
}
