import { createContext, ReactNode } from 'react';

type SetupFlowStepContextProps = {
  loading: boolean;
  stepsConfig: undefined;
  currentStepFormData: any;
  currentStep: any;
  allStepsByGate: any;
  stepsByGate: any;
  setLoading: any;
  steps: any[];
  propertyId: string;
  unitId: string;
  units: any;
  selectedProperty: any;
  selectedUnit: any;
  properties: any[];
  propertySection: any;
  accountSection: any;
  isMultiUnit: boolean;
  getSidebarDescriptions: () => any;
  getSidebarTitleText: () => any;
  getSidebarImage: () => any;
  isValidUrl: () => boolean;
  handleSaveAndNext: (values: any) => Promise<any>;
  goPreviousStep: () => void;
  isLastStep: () => boolean;
  handleSave: (values: any) => Promise<any>;
  goNextStep: () => void;
  fetchProperties: () => void;
  getSidebarDescriptionImages: () => string[];
};

type SetupFlowStepProviderProps = SetupFlowStepContextProps & {
  children: ReactNode;
};

export const SetupFlowStepContext = createContext<undefined | SetupFlowStepContextProps>(undefined);

export function SetupFlowStepProvider({ children, ...rest }: SetupFlowStepProviderProps) {
  return <SetupFlowStepContext.Provider value={rest}>{children}</SetupFlowStepContext.Provider>;
}
