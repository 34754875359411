import React from 'react';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import styles from './ModalLayout.module.css';

const cx = classNames.bind(styles);

const ModalButton = ({ nextButtonWrapperProps, noMarginOrPaddingOnMobileForButton, mobileFixed, ...rest }) => (
  <div className={cx('full-width')} {...nextButtonWrapperProps}>
    <Button
      className={cx('mobile-cta', { 'mobile-fixed': mobileFixed }, { noMarginOrPaddingOnMobileForButton })}
      type="submit"
      {...rest}
    />
  </div>
);

ModalButton.propTypes = {
  nextButtonWrapperProps: PropTypes.object,
  noMarginOrPaddingOnMobileForButton: PropTypes.bool.isRequired,
  mobileFixed: PropTypes.bool,
};

ModalButton.defaultProps = {
  nextButtonWrapperProps: {},
};

export default ModalButton;
