import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLottieAnimation } from 'animations/LazyLottieAnimation/LazyLottieAnimation';

const SuccessBannerImage = ({ height, width, className }) => {
  const [reactLottieProps, setReactLottieProps] = useState({ height, width });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isMobile = window && window?.innerWidth < 576;

      if (isMobile) {
        setReactLottieProps({
          height: '400px',
          width: '300%',
          style: {
            marginLeft: '-103%',
          },
        });
      }
    }
  }, []);

  return <LazyLottieAnimation name="success_page_animation" containerClassName={className} {...reactLottieProps} />;
};

SuccessBannerImage.propTypes = {
  defaultOptions: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

SuccessBannerImage.defaultProps = {
  height: '',
  width: '',
  className: '',
};

export default SuccessBannerImage;
