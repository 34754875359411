import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

export const features = [
  {
    image: 'lease-signing-flow/karma-wallet.png',
    title: LEASE_SIGNING_FLOW_STRINGS['wallet.features.karma.title'],
    description: LEASE_SIGNING_FLOW_STRINGS['wallet.features.karma.description'],
  },
  {
    image: 'lease-signing-flow/approved.png',
    title: LEASE_SIGNING_FLOW_STRINGS['wallet.features.approved.title'],
    description: LEASE_SIGNING_FLOW_STRINGS['wallet.features.approved.description'],
  },
  {
    image: 'lease-signing-flow/hi-there.png',
    title: LEASE_SIGNING_FLOW_STRINGS['wallet.features.hi.title'],
    description: LEASE_SIGNING_FLOW_STRINGS['wallet.features.hi.description'],
  },
  {
    image: 'lease-signing-flow/congrats.png',
    title: LEASE_SIGNING_FLOW_STRINGS['wallet.features.congrats.title'],
    description: LEASE_SIGNING_FLOW_STRINGS['wallet.features.congrats.description'],
  },
];
