import Kid from '../common/Kid';
import LeaseResident from '../common/LeaseResident';
import Pet from '../common/Pet';
import UnitBasicInfo from '../common/UnitBasicInfo';

export default class HomeownerAccountUnit {
  constructor(data = {}) {
    this.basicInfo = new UnitBasicInfo(data.basicInfo);
    this.currentLease = data.currentLease;
    this.residents = data.residents.map((resident) => new LeaseResident(resident));
    this.employeeAssignments = data.employeeAssignments;
    this.pets = data.pets?.map((pet) => new Pet(pet));
    this.kids = data.kids?.map((kid) => new Kid(kid));
    this.inspectionScheduledOn = data.inspectionScheduledOn;
    this.isInitialPriceRangePublished = data.isInitialPriceRangePublished;
    this.isPublished = data.isPublished;
    this.homeStatus = data.homeStatus;
    this.isPrimaryOwner = data.isPrimaryOwner;
    this.isFarOffMoveout = data.isFarOffMoveout;
    this.isFarOffMoveout = data.isFarOffMoveout;
    this.unitAdditional = data.unitAdditional;
    this.isSetupFlowCompleted = data.isSetupFlowCompleted;
    this.isAdoptedAgreement = data.isAdoptedAgreement;
    this.hasImprovementsFlowOpen = data.hasImprovementsFlowOpen;
    this.hasLandlordFlowOpen = data.hasLandlordFlowOpen;
    this.hasPricingFlowOpen = data.hasPricingFlowOpen;
    this.hasHomeownerPreMoveOutFlowOpen = data.hasHomeownerPreMoveOutFlowOpen;
    this.homePlans = data.homePlans;
  }
}
