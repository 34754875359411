import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import Carousel from 'components/v2/Carousel/Carousel';
import PropTypes from 'prop-types';
import styles from './CarouselTabBar.module.css';

const cx = classNames.bind(styles);

const CarouselTabBar = ({ items, selected, onSelect }) => {
  const [selectedTab, setSelectedTab] = useState(selected);

  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  const handleSlideChange = (slideIndex) => {
    const currentSlide = items[slideIndex];
    setSelectedTab(currentSlide.index);

    if (onSelect) onSelect(currentSlide.index);

    if (currentSlide.onClick) currentSlide.onClick();
  };

  return (
    <Carousel
      renderBottomCenterControls={null}
      hideOnMobile={false}
      onSlideChange={handleSlideChange}
      slideIndex={
        items.findIndex((item) => item.index === selectedTab) < 0
          ? 0
          : items.findIndex((item) => item.index === selectedTab)
      }
    >
      {items.map((item, itemIndex) => (
        <div className={cx('tab-label')} key={itemIndex}>
          {item.text}
        </div>
      ))}
    </Carousel>
  );
};

CarouselTabBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      index: PropTypes.number,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
};

CarouselTabBar.defaultProps = {
  selected: '',
  onSelect: () => {},
};

export default CarouselTabBar;
