import { PATHS } from '@belong/common';
import { PageRedirectToBelongNext } from 'page-redirect-to-belong-next/PageRedirectToBelongNext';
import Favorites from './Favorites/Favorites';
import { Inbox } from './Inbox/inbox';
import Profile from './Profile/Profile';
import Referral from './Referral/Referral';
import ReferrerPayments from './ReferrerPayments/ReferrerPayments';
import ToursAndApps from './ToursAndApps/ToursAndApps';
import HomeDocuments from './YourHome/Documents/Documents';
import Lease from './YourHome/Leases/Lease/Lease';
import Leases from './YourHome/Leases/Leases';
import { ResidentMaintenance } from './YourHome/Maintenance/resident-maintenance';
import Rent from './YourHome/Rent/Rent';
import PortfolioDocuments from './YourPortfolio/Documents/Documents';
import Earnings from './YourPortfolio/Earnings/Earnings';
import PortfolioMaintenance from './YourPortfolio/Maintenance/Maintenance';
import Properties from './YourPortfolio/Properties/Properties';
import Property from './YourPortfolio/Properties/Property/Property';
import { SellHome } from './YourPortfolio/SellHome/SellHome';

export const PAGES_CONFIG = {
  PORTFOLIO_PROPERTIES: {
    path: PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES,
    isExact: true,
    component: Properties,
  },
  PORTFOLIO_PROPERTIES_PROPERTY: {
    path: `${PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES}/:propertyId/:unitId?`,
    component: Property,
  },
  PORTFOLIO_MAINTENANCE: {
    path: `${PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE}/:maintenanceId?`,
    component: PortfolioMaintenance,
  },
  EARNINGS: {
    path: PATHS.HOMEOWNER_ACCOUNT_EARNINGS,
    component: Earnings,
  },
  PORTFOLIO_DOCUMENTS: {
    path: PATHS.HOMEOWNER_ACCOUNT_DOCUMENTS,
    component: PortfolioDocuments,
  },
  SELL_HOME: {
    path: PATHS.HOMEOWNER_ACCOUNT_SELL,
    component: SellHome,
  },
  YOUR_HOME: {
    path: PATHS.RESIDENTS_ACCOUNT_PROPERTIES,
    isExact: true,
    component: Leases,
  },
  YOUR_HOME_LEASE: {
    path: `${PATHS.RESIDENTS_ACCOUNT_PROPERTIES}/:unitId/:leaseId`,
    component: Lease,
  },
  YOUR_HOME_MAINTENANCE: {
    path: `${PATHS.RESIDENTS_ACCOUNT_MAINTENANCE}/:maintenanceId?`,
    component: ResidentMaintenance,
  },
  PAYMENTS: {
    path: `${PATHS.RESIDENTS_ACCOUNT_PAYMENTS}/:billId?`,
    component: Rent,
  },
  PAYMENTS_MEMBER: {
    path: PATHS.ACCOUNTS_PAYMENTS,
    component: Rent,
  },
  YOUR_HOME_DOCUMENTS: {
    path: PATHS.RESIDENTS_ACCOUNT_DOCUMENTS,
    component: HomeDocuments,
  },
  TOURS_AND_APPS: {
    path: PATHS.RESIDENTS_ACCOUNT_TOURS_AND_APPS,
    component: ToursAndApps,
  },
  RESIDENT_INBOX: {
    path: PATHS.RESIDENTS_ACCOUNT_INBOX,
    component: Inbox,
  },
  FAVORITES: {
    path: PATHS.RESIDENTS_ACCOUNT_FAVORITES,
    component: Favorites,
  },
  PROFILE: {
    path: PATHS.ACCOUNTS_PROFILE,
    component: Profile,
  },
  REFERRAL: {
    path: PATHS.ACCOUNTS_REFFERAL,
    component: Referral,
  },
  REFERRER_PAYMENTS: {
    path: PATHS.ACCOUNTS_REFERRAL_PAYMENTS,
    component: ReferrerPayments,
  },
  INBOX: {
    path: PATHS.HOMEOWNER_ACCOUNT_INBOX,
    component: Inbox,
  },
  INSURANCE: {
    path: PATHS.HOMEOWNER_ACCOUNT_INSURANCE_SUMMARY,
    component: PageRedirectToBelongNext,
  },
  WEALTH: {
    path: PATHS.HOMEOWNER_ACCOUNT_WEALTH,
    component: PageRedirectToBelongNext,
  },
  WEALTH_FLOW: {
    path: PATHS.HOMEOWNER_ACCOUNT_WEALTH_ANALYSIS_FLOW,
    component: PageRedirectToBelongNext,
  },
  LISTING_PERFORMANCE: {
    path: PATHS.HOMEOWNER_ACCOUNT_LISTING_PERFORMANCE,
    component: PageRedirectToBelongNext,
  },
};

export const HOMEOWNER_PAGES = [
  PAGES_CONFIG.PORTFOLIO_PROPERTIES,
  PAGES_CONFIG.PORTFOLIO_MAINTENANCE,
  PAGES_CONFIG.EARNINGS,
  PAGES_CONFIG.PORTFOLIO_DOCUMENTS,
  PAGES_CONFIG.SELL_HOME,
  PAGES_CONFIG.WEALTH,
  PAGES_CONFIG.WEALTH_FLOW,
  PAGES_CONFIG.INSURANCE,
  PAGES_CONFIG.LISTING_PERFORMANCE,
  PAGES_CONFIG.INBOX,
];

export const PAGES = [
  PAGES_CONFIG.TOURS_AND_APPS,
  PAGES_CONFIG.FAVORITES,
  PAGES_CONFIG.PAYMENTS_MEMBER,
  PAGES_CONFIG.PROFILE,
  PAGES_CONFIG.REFERRAL,
  PAGES_CONFIG.REFERRER_PAYMENTS,
  PAGES_CONFIG.RESIDENT_INBOX,
];

export const RENTER_ACCOUNT_PAGES = [
  PAGES_CONFIG.YOUR_HOME,
  PAGES_CONFIG.YOUR_HOME_MAINTENANCE,
  PAGES_CONFIG.PAYMENTS,
  PAGES_CONFIG.YOUR_HOME_DOCUMENTS,
];
