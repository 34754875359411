import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchAccountUnits } from 'store/redux/homeowner-accounts/actions';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import useDeepCompareEffect from 'use-deep-compare-effect';

export function useUnits() {
  const dispatch = useDispatch();
  const { propertyId } = useParams<any>();
  const [units, setUnits] = useState<any>();
  const [unitIds, setUnitIds] = useState<any>();
  const properties = useSelector(selectAccountProperties);
  const selectedProperty = properties?.find((property) => property.basicInfo.propertyId === propertyId);
  const newUnitIds = selectedProperty?.getUnitsWithAgreements().map((unit) => unit.basicInfo.unitId);

  useDeepCompareEffect(() => {
    async function fetchUnits() {
      if (newUnitIds) {
        const [newUnits] = (await Promise.all([dispatch(fetchAccountUnits(newUnitIds))])) as any;

        setUnitIds(newUnitIds);
        setUnits(newUnits);
      }
    }

    if (newUnitIds) {
      fetchUnits();
    }
  }, [dispatch, newUnitIds, propertyId]);

  return { units, unitIds };
}
