export const ICONS = {
  SINGLE_PROPERTY: {
    DEFAULT: 'icon-96-0-0',
    INVERSE: 'icon-96-0-1',
    GRAY: 'icon-96-0-2',
    V2: 'icon-96-0-3',
  },
  FEW_PROPERTIES: {
    DEFAULT: 'icon-96-1-0',
    INVERSE: 'icon-96-1-1',
    V2: 'icon-96-1-3',
  },
  // Many properties is a guy with a monocle.
  MANY_PROPERTIES: {
    DEFAULT: 'icon-96-2-0',
    INVERSE: 'icon-96-2-1',
  },
  TOWN_HOUSE: {
    DEFAULT: 'icon-96-3-0',
    INVERSE: 'icon-96-3-1',
  },
  MULTI_UNIT: {
    DEFAULT: 'icon-96-4-0',
    INVERSE: 'icon-96-4-1',
    V2: 'icon-96-4-3',
  },
  VIDEO_PHONE: {
    DEFAULT: 'icon-96-5-0',
    INVERSE: 'icon-96-5-1',
  },
  PHONE: {
    DEFAULT: 'icon-96-6-0',
    INVERSE: 'icon-96-6-1',
  },
  HANDSHAKE: {
    DEFAULT: 'icon-96-7-0',
    INVERSE: 'icon-96-7-1',
  },
  UPLOAD_LARGE: {
    DEFAULT: 'icon-96-8-0',
    INVERSE: 'icon-96-8-1',
  },
  JUST_ME: {
    DEFAULT: 'icon-96-9-0',
    INVERSE: 'icon-96-9-1',
  },
  OTHERS_AND_ME: {
    DEFAULT: 'icon-96-10-0',
    INVERSE: 'icon-96-10-1',
  },
  BANK_ACCOUNT: {
    DEFAULT: 'icon-96-11-0',
    INVERSE: 'icon-96-11-1',
  },
  COSIGNER: {
    DEFAULT: 'icon-96-12-0',
    INVERSE: 'icon-96-12-1',
  },

  USELESS_ICON: {
    DEFAULT: 'icon-96-13-0',
    RED: 'icon-96-13-1',
  },
  DOCUMENT: {
    PDF: 'icon-96-14-0',
    DOC: 'icon-96-14-1',
    DEFAULT: 'icon-96-14-2',
  },
  RECURRING: {
    DEFAULT: 'icon-96-15-0',
  },
  COMMUNICATION: {
    DEFAULT: 'icon-96-17-0',
  },
  DUPLEX: {
    DEFAULT: 'icon-96-18-0',
    INVERSE: 'icon-96-18-1',
  },
  PARKING: {
    DEFAULT: 'icon-96-19-0',
  },
  AIR_CONDITION: {
    DEFAULT: 'icon-96-20-0',
  },
  PETS: {
    DEFAULT: 'icon-96-21-0',
  },
  HEAT: {
    DEFAULT: 'icon-96-22-0',
  },
  LAUNDRY: {
    DEFAULT: 'icon-96-23-0',
  },
  E_CHECK: {
    DEFAULT: 'icon-96-24-0',
    INVERSE: 'icon-96-24-1',
  },
  CREDIT_CARD: {
    DEFAULT: 'icon-96-25-0',
    INVERSE: 'icon-96-25-1',
  },
  KEYBOARD: {
    DEFAULT: 'icon-96-26-0',
    INVERSE: 'icon-96-26-1',
  },
  PET_DOG: {
    DEFAULT: 'icon-96-27-0',
    INVERSE: 'icon-96-27-1',
  },
  PET_CAT: {
    DEFAULT: 'icon-96-28-0',
    INVERSE: 'icon-96-28-1',
  },
  PET_OTHER: {
    DEFAULT: 'icon-96-29-0',
    INVERSE: 'icon-96-29-1',
  },
  NOTEPAD: {
    DEFAULT: 'icon-96-30-0',
    INVERSE: 'icon-96-30-1',
  },
  TOOLS: {
    DEFAULT: 'icon-96-31-0',
    INVERSE: 'icon-96-31-1',
  },
  MAIL: {
    DEFAULT: 'icon-96-32-0',
    INVERSE: 'icon-96-32-1',
  },
  TREEHOUSE: {
    DEFAULT: 'icon-96-33-0',
    INVERSE: 'icon-96-33-1',
  },
  INSTANT: {
    DEFAULT: 'icon-96-34-0',
    INVERSE: 'icon-96-34-1',
  },
  NO_PETS: {
    DEFAULT: 'icon-96-35-0',
  },
  CALL_NOW: {
    DEFAULT: 'icon-96-36-0',
    INVERSE: 'icon-96-36-1',
  },
  CAMERA: {
    DEFAULT: 'icon-96-37-0',
  },
  NO_SMOKING: {
    DEFAULT: 'icon-96-38-0',
  },
  SMOKING: {
    DEFAULT: 'icon-96-39-0',
  },
  BED: {
    DEFAULT: 'icon-96-40-0',
  },
  ANY_PET: {
    DEFAULT: 'icon-96-41-0',
    INVERSE: 'icon-96-41-1',
  },
  NO_PET: {
    DEFAULT: 'icon-96-42-0',
    INVERSE: 'icon-96-42-1',
  },
  PENTHOUSE: {
    DEFAULT: 'icon-96-43-0',
    INVERSE: 'icon-96-43-1',
  },
  GROUND_BASEMENT_LEVEL: {
    DEFAULT: 'icon-96-44-0',
    INVERSE: 'icon-96-44-1',
  },
  BABY: {
    DEFAULT: 'icon-96-45-0',
  },
  BALLOON: {
    DEFAULT: 'icon-96-46-0',
  },
  SCHOOL: {
    DEFAULT: 'icon-96-47-0',
  },
  BREIF_CASE: {
    DEFAULT: 'icon-96-48-0',
  },
  DROP_ZONE: {
    DEFAULT: 'icon-96-49-0',
  },
  HAPPY_FACE_BALLOON: {
    DEFAULT: 'icon-96-53-0',
    INVERSE: 'icon-96-53-1',
  },
  NEUTRAL_FACE_BALLOON: {
    DEFAULT: 'icon-96-54-0',
    INVERSE: 'icon-96-54-1',
  },
  UNHAPPY_FACE_BALLOON: {
    DEFAULT: 'icon-96-55-0',
    INVERSE: 'icon-96-55-1',
  },
  LOCKBOX: {
    DEFAULT: 'icon-96-56-0',
    INVERSE: 'icon-96-56-1',
  },
  ELEVATED_INCOME: {
    DEFAULT: 'icon-96-57-0',
  },
  ECSTATIC_RESIDENTS: {
    DEFAULT: 'icon-96-58-0',
  },
  EXQUISITE_CARE: {
    DEFAULT: 'icon-96-59-0',
  },
  EXCEPTIONAL_PRICING: {
    DEFAULT: 'icon-96-60-0',
  },
  EXTRAORDINARY_GUARANTEES: {
    DEFAULT: 'icon-96-61-0',
  },
  CALL_BAY_AREA: {
    DEFAULT: 'icon-96-62-0',
    INVERSE: 'icon-96-62-1',
  },
  CALL_LOS_ANGELES: {
    DEFAULT: 'icon-96-63-0',
    INVERSE: 'icon-96-63-1',
  },
  CALL_ORANGE_COUNTY: {
    DEFAULT: 'icon-96-15-0',
    INVERSE: 'icon-96-15-1',
  },
  CALL_SAN_DIEGO: {
    DEFAULT: 'icon-96-16-0',
    INVERSE: 'icon-96-16-1',
  },
  REPAIR: {
    DEFAULT: 'icon-96-64-0',
    INVERSE: 'icon-96-64-1',
  },

  KEYS_AND_ACCESS: {
    DEFAULT: 'icon-96-65-0',
    INVERSE: 'icon-96-65-1',
  },
  PREVENTATIVE: {
    DEFAULT: 'icon-96-66-0',
  },
  INVESTIGATION: {
    DEFAULT: 'icon-96-67-0',
  },
  INSPECTION: {
    DEFAULT: 'icon-96-68-0',
  },
  UPGRADE: {
    DEFAULT: 'icon-96-69-0',
    INVERSE: 'icon-96-69-1',
  },

  ADDITION: {
    DEFAULT: 'icon-96-70-0',
    INVERSE: 'icon-96-70-1',
  },

  STORAGE: {
    DEFAULT: 'icon-96-71-0',
    INVERSE: 'icon-96-71-1',
  },

  PICKUP_AND_DELIVERY: {
    DEFAULT: 'icon-96-72-0',
  },

  CLEANING: {
    DEFAULT: 'icon-96-73-0',
  },

  RECONDITION: {
    DEFAULT: 'icon-96-74-0',
  },

  PHOTOGRAPHY: {
    DEFAULT: 'icon-96-75-0',
  },

  ID_FRONT: {
    DEFAULT: 'icon-96-76-0',
  },
  ID_BACK: {
    DEFAULT: 'icon-96-77-0',
  },
  SELFIE: {
    DEFAULT: 'icon-96-78-0',
  },
  PASSPORT: {
    DEFAULT: 'icon-96-79-0',
  },
  PHOTO_OR_VIDEO: {
    DEFAULT: 'icon-96-80-0',
  },

  RECURRING_MAINTENANCE: {
    DEFAULT: 'icon-96-81-0',
    INVERSE: 'icon-96-81-1',
  },
  HOME_PHOTO: {
    DEFAULT: 'icon-96-82-0',
  },

  WATER: {
    DEFAULT: 'icon-96-83-0',
  },
  GAS: {
    DEFAULT: 'icon-96-84-0',
  },
  SEWAGE: {
    DEFAULT: 'icon-96-85-0',
  },
  CABLE: {
    DEFAULT: 'icon-96-86-0',
  },
  LANDSCAPING: {
    DEFAULT: 'icon-96-87-0',
  },
  POOLSERVICING: {
    DEFAULT: 'icon-96-88-0',
  },
  ELECTRICITY: {
    DEFAULT: 'icon-96-89-0',
  },
  TRASH: {
    DEFAULT: 'icon-96-90-0',
  },
  INTERNET: {
    DEFAULT: 'icon-96-91-0',
  },
  CLEANING_UTIL: {
    DEFAULT: 'icon-96-92-0',
  },
  INSPECTION_DELIVERY: {
    DEFAULT: 'icon-96-93-0',
    INVERSE: 'icon-96-93-1',
  },
  OPEN_HOME: {
    DEFAULT: 'icon-96-94-0',
    INVERSE: 'icon-96-94-1',
  },
  SELF_TOUR: {
    DEFAULT: 'icon-96-95-0',
    INVERSE: 'icon-96-95-1',
  },
  INSPECTION_PRESENT: {
    DEFAULT: 'icon-96-96-0',
    INVERSE: 'icon-96-96-1',
  },
  DEVIL: {
    DEFAULT: 'icon-96-97-0',
    INVERSE: 'icon-96-97-1',
  },
};

const ICONS_V2 = 'icon-96-2-';

export const ICONS_2 = {
  MAP: {
    DEFAULT: `${ICONS_V2}0-0`,
  },
  BABY_WRAPPED: {
    DEFAULT: `${ICONS_V2}1-0`,
  },
  PRICING: {
    DEFAULT: `${ICONS_V2}2-0`,
  },
  QUALITY_DOCUMENT: {
    DEFAULT: `${ICONS_V2}3-0`,
  },

  SAFE: { DEFAULT: `${ICONS_V2}4-0` },

  HOME_SCREEN: {
    DEFAULT: `${ICONS_V2}10-0`,
  },
  MOBILE_PAYMENT: {
    DEFAULT: `${ICONS_V2}12-0`,
  },
  FIND_HOME: {
    DEFAULT: `${ICONS_V2}13-0`,
  },
  ME: {
    DEFAULT: `${ICONS_V2}14-0`,
    INVERSE: `${ICONS_V2}14-1`,
  },
  ANOTHER_INDIVIDUAL: {
    DEFAULT: `${ICONS_V2}15-0`,
    INVERSE: `${ICONS_V2}15-1`,
  },
  ENTITY_TRUST: {
    DEFAULT: `${ICONS_V2}16-0`,
    INVERSE: `${ICONS_V2}16-1`,
  },
  FINANCIAL_PERFORMANCE: {
    DEFAULT: `${ICONS_V2}17-0`,
  },
  DESCRIPTION: {
    DEFAULT: `${ICONS_V2}18-0`,
  },
};
