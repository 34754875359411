import { MaintenanceModel, MaintenanceRecurrenceModel } from 'api/models';
import { createSelector } from 'reselect';
import { MAINTENANCE_REDUCER } from './reducer';

const _selectAccounts = (state) => state[MAINTENANCE_REDUCER];

const _selectCurrentTask = (state) => _selectAccounts(state).currentTask;

const _selectHomeownerTasks = (state): MaintenanceModel[] | undefined => _selectAccounts(state).homeTasks;

const _selectRentTasks = (state): MaintenanceModel[] | undefined => _selectAccounts(state).rentTasks;
const _selectImprovementsReport = (state) => _selectAccounts(state).improvementsReport;

export const selectHomeownerTasks = createSelector(_selectHomeownerTasks, (tasks) => tasks ?? []);

export const selectImprovementsReport = createSelector(_selectImprovementsReport, (report) => report || {});

export const selectRentTasks = createSelector(_selectRentTasks, (tasks) => (Array.isArray(tasks) ? tasks : []));

export const selectCurrentTask = createSelector(_selectCurrentTask, (task) => task ?? {});

const _selecteMaintenances = (state) => _selectAccounts(state).taskById;

export const selectMaintenanceById = (uniqueId: string) =>
  createSelector<any, any, MaintenanceModel | undefined>([_selecteMaintenances], (tasks) => tasks[uniqueId]);

export const selectRecurringTasks = (state): MaintenanceRecurrenceModel[] | undefined =>
  _selectAccounts(state).recurringTasks;
