import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './SuccessTimeline.module.css';

const cx = classNames.bind(styles);

export const LABEL_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

const SuccessTimeline = ({ steps }) => {
  return (
    <div className={cx('timeline-wrapper')}>
      {steps.map((step, index) => (
        <div key={index} className={cx('step', step.position, { selected: step.selected })}>
          <div className={cx('step-label', step.position, { selected: step.selected })}>{step.label}</div>
        </div>
      ))}
      <div className={cx('step', 'last-step-wrapper')} />
    </div>
  );
};

SuccessTimeline.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      position: PropTypes.oneOf(Object.values(LABEL_POSITION)).isRequired,
      selected: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default SuccessTimeline;
