export const constants = {
  SHOW_TOAST: 'SHOW_TOAST',
  CLEAR_TOASTS: 'CLEAR_TOASTS',
  DELETE_TOAST: 'DELETE_TOAST',
};

const initialState = {
  incomingToast: null,
  list: [],
  metadata: {
    autoDelete: true,
    autoDeleteTime: 8000,
  },
};

export default function reducer(state = initialState, action = {}) {
  let index;
  switch (action.type) {
    case constants.SHOW_TOAST:
      return {
        ...state,
        list: [action.payload, ...state.list],
        incomingToast: action.payload,
        metadata: action.metadata,
      };
    case constants.DELETE_TOAST:
      index = state.list.findIndex((t) => t.id === action.payload);
      return {
        ...state,
        list: index === -1 ? state.list : [...state.list.slice(0, index), ...state.list.slice(index + 1)],
      };
    case constants.CLEAR_TOASTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const TOAST_REDUCER = 'toast';

export const showToast = (newToast, metadata = initialState.metadata) => {
  if (!newToast || (!newToast.message && !newToast.component)) {
    throw new Error('Toast needs a message or component.');
  }
  return {
    type: constants.SHOW_TOAST,
    payload: {
      id: new Date().getTime(), // use creation time as id
      ...newToast,
    },
    metadata,
  };
};

export const clearToasts = () => ({
  type: constants.CLEAR_TOASTS,
});

export const deleteToast = (id) => ({
  type: constants.DELETE_TOAST,
  payload: id,
});

export const selectToast = (state) => state[TOAST_REDUCER];
