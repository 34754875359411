import { getApiActionTypesValues } from '../redux_utils';

const HOME_INSPECTION_FLOW = {
  FETCH_STEP_DATA: 'FETCH_INSPECTION_STEP_DATA',
  UPDATE_STEP_DATA: 'UPDATE_INSPECTION_STEP_DATA',
};

export const fetchStepData = (flowId, mappedStep) => ({
  types: getApiActionTypesValues(HOME_INSPECTION_FLOW.FETCH_STEP_DATA),
  promise: ({ client }) =>
    client.get(
      `/homeowners/home-inspection/${flowId}/${mappedStep?.group?.key}/${mappedStep?.key}/${mappedStep?.uniqueId}`
    ),
  auth: true,
});

export const updateStepData = (flowId, mappedStep, stepFormData) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues(HOME_INSPECTION_FLOW.UPDATE_STEP_DATA),
    promise: ({ client }) =>
      client.put(
        `/homeowners/home-inspection/${flowId}/${mappedStep.group.key}/${mappedStep.key}/${mappedStep.uniqueId}`,
        {
          ...stepFormData,
        }
      ),
    auth: true,
  });
