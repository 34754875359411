import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

export const features = [
  {
    image: 'lease-signing-flow/hang-your-frames.svg',
    title: LEASE_SIGNING_FLOW_STRINGS['wearAndTear.features.frames'],
  },
  {
    image: 'lease-signing-flow/mount-your-tv.svg',
    title: LEASE_SIGNING_FLOW_STRINGS['wearAndTear.features.tv'],
  },
  {
    image: 'lease-signing-flow/paint-your-walls.svg',
    title: LEASE_SIGNING_FLOW_STRINGS['wearAndTear.features.walls'],
  },
  {
    image: 'lease-signing-flow/plant-your-plants.svg',
    title: LEASE_SIGNING_FLOW_STRINGS['wearAndTear.features.plants'],
  },
];
