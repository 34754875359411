import { useSelector, useDispatch } from 'react-redux';
import { IconChat, IconCopy } from '@belong/icons';
import { Image, LinkBase, Text } from '@belong/ui';
import { PageViewTracker } from 'analytics';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import { compose } from 'redux';
import { showToast, clearToasts } from 'store/redux/toast';
import { selectUser } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import { STRINGS } from 'strings/referral';
import styles from './Referral.module.css';

const cx = classNames.bind(styles);

const backgroundElements = [
  {
    className: styles.bigLeftCloud,
    src: '/homeowner-lp/career_landing_cloud_01.svg',
    el: 'bigLeftCloud',
  },
  {
    className: cx('hidden lg:block', styles.smallLeftCloud),
    src: '/homeowner-lp/career_landing_cloud_01.svg',
    el: 'smallLeftCloud',
  },
  {
    className: cx('hidden lg:block', styles.bigRightCloud),
    src: '/homeowner-lp/career_landing_cloud_02.svg',
    el: 'bigRightCloud',
  },
  {
    className: cx('hidden lg:block', styles.smallRightCloud),
    src: '/homeowner-lp/career_landing_cloud_01.svg',
    el: 'smallRightCloud',
  },
  {
    className: cx('hidden lg:block', styles.balloon1Animation),
    src: '/homeowner-lp/career_landing_balloon_01.svg',
    el: 'balloon1Animation',
  },
  {
    className: cx('hidden lg:block', styles.plane),
    src: '/homeowner-lp/career_landing_plane.svg',
    el: 'plane',
  },
];

const toastTime = 4000;

const Referral = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const updateClipboard = (newClip) => {
    navigator.clipboard.writeText(newClip);
    dispatch(
      showToast({ message: 'Copied to clipboard', success: true }, { autoDelete: true, autoDeleteTime: toastTime })
    );
  };

  const copyText = () => {
    dispatch(clearToasts());
    updateClipboard(user.referralUrl);
  };

  return (
    <div>
      <div
        className={cx(
          'mb-lg md:mb-2xl md:pt-xl flex flex-col items-center text-center relative md:overflow-hidden rounded md:border md:border-solid',
          styles.mainBox
        )}
      >
        <div className="hidden md:block absolute top-0 bottom-0 z-10">
          <Image src="/referral/referral_money_rain_img.svg" alt="" />
        </div>
        <div className="relative pointer-events-none z-0 w-full">
          <div className="absolute top-0 inset-x-0 h-[900px] flex justify-center overflow-hidden">
            <div className="relative w-full mt-xl md:mt-0 lg:w-0">
              {/* mt in this div is a nice hacky solution for moving all bg-elements down */}
              {backgroundElements.map((el) => (
                <div key={el.idx} className={cx('absolute', el.className)}>
                  <Image src={el.src} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="absolute top-0 md:relative z-20">
          <Text variant="h2" fontWeight="semibold" className="max-w-[490px] mb-xs">
            {STRINGS.title}
          </Text>
          <Text variant="body" className="md:mb-2xl">
            {STRINGS.subtitle}
          </Text>
        </div>
        <div className={cx('flex justify-center md:block md:ml-2xl', styles.mainBanner)}>
          <Image
            className="h-auto md:h-full"
            src={isMobile ? '/referral/referral_landing_header_mobile.png' : '/referral/referral_landing_header.png'}
            alt=""
          />
        </div>
      </div>

      <div className="mb-xs">
        <Text variant="h3" fontWeight="semibold" className="mb-2xs">
          {STRINGS.ways}
        </Text>
      </div>

      <div className={cx('link-box', 'p-sm flex items-center mb-sm')}>
        <div className="flex flex-col w-[85%] md:w-auto">
          <Text fontWeight="semibold" className="mr-xs">
            {STRINGS.linkCta}
          </Text>
          <Text className="break-words md:break-normal" variant={isMobile ? 'p1' : 'body'}>
            {user.referralUrl}
          </Text>
        </div>
        <div className="flex w-[15%] md:w-auto md:flex-1 justify-end">
          {isMobile ? (
            <IconCopy className="text-green" onClick={copyText} width={24} height={24} />
          ) : (
            <Button onClick={copyText}>Copy</Button>
          )}
        </div>
      </div>

      <div className={cx('link-box', 'p-md flex items-center md:hidden mb-sm')}>
        <div className="flex flex-col md:flex-row max-w-[244px] md:max-w-none">
          <Text fontWeight="semibold" className="mr-xs">
            {STRINGS.messageCta}
          </Text>
        </div>
        <div className="flex flex-1 justify-end">
          <LinkBase
            className="h-md"
            href={`sms://?&body=${formatString(STRINGS.messageBody, { referralUrl: user.referralUrl })}`}
          >
            <IconChat className="text-green" width={24} height={24} />
          </LinkBase>
        </div>
      </div>

      <div className="mb-xs">
        <Text variant="body" className="mb-2xs">
          {STRINGS['ways.description']}
        </Text>
      </div>
    </div>
  );
};

export default compose(PageViewTracker)(Referral);
