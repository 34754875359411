import { useState, useEffect } from 'react';

var useWindowSize = function () {
  var isClient = typeof window === 'object';
  function getSize() {
    return {
      width: isClient ? window.innerWidth : NaN,
      height: isClient ? Math.min(window.screen.availHeight, window.innerHeight) : NaN
    };
  }
  var _a = useState(getSize),
    windowSize = _a[0],
    setWindowSize = _a[1];
  useEffect(function () {
    if (!isClient) {
      return;
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return function () {
      return window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return windowSize;
};

export { useWindowSize };
