import React from 'react';
import classNames from 'classnames/bind';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import { Grid } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import styles from './MobileTipModal.module.css';

const cx = classNames.bind(styles);

export const MobileTipModal = ({ show, onHide, children }) => {
  return (
    <ModalV2 noBorder noPadding onHide={onHide} show={show} type={MODAL_TYPES.TIPS_MODAL}>
      <Grid>
        <div className={cx('sidebar-with-text-bubble-modal-wrapper')}>{children}</div>
      </Grid>
    </ModalV2>
  );
};

MobileTipModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
