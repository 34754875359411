import React from 'react';
import classNames from 'classnames/bind';
import { DIVIDER_TYPES as TYPES } from 'components/Divider/dividerTypes';
import PropTypes from 'prop-types';
import styles from './Divider.module.css';

const cx = classNames.bind(styles);

export const DIVIDER_TYPES = TYPES;

const Divider = ({ label, dividerType, labelClassName }) => {
  return (
    <div className={cx('horizontal', dividerType)}>
      <div className={cx('side')} />
      {label && <div className={`${cx('label')} ${labelClassName}`}>{label}</div>}
      <div className={cx('side')} />
    </div>
  );
};

Divider.propTypes = {
  label: PropTypes.string,
  dividerType: PropTypes.oneOf(Object.values(TYPES)),
  labelClassName: PropTypes.string,
};

Divider.defaultProps = {
  label: null,
  dividerType: TYPES.DEFAULT,
  labelClassName: '',
};

Divider.Dashed = (props) => <Divider dividerType={TYPES.DASHED} {...props} />;

export default Divider;
