import React from 'react';
import classNames from 'classnames/bind';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import styles from './ErrorMessageBubble.module.css';

const cx = classNames.bind(styles);
export const ERROR_STATES = {
  SHOW_ERROR_MESSAGE: true,
  HIDE_ERROR_MESSAGE: false,
  NO_ERROR_MESSAGE: null,
};

const ErrorMessageBubble = ({
  errorClassNames,
  showErrorMessageBubble,
  inline,
  mobileFixed,
  bottomPosition,
  bottomPositionMobile,
  rightPosition,
  errorMessage,
  bubbleTriangleClassNames = '',
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  const errorBubbleClassNames = cx(
    `${errorClassNames}`,
    'error-message',
    { inline },
    { mobileFixed },
    { show: showErrorMessageBubble === ERROR_STATES.SHOW_ERROR_MESSAGE },
    { hide: showErrorMessageBubble === ERROR_STATES.HIDE_ERROR_MESSAGE }
  );
  return (
    <div
      className={errorBubbleClassNames}
      style={{ bottom: isMobile ? bottomPositionMobile : bottomPosition, right: rightPosition }}
    >
      <div className={cx('error-message-bubble')}>{errorMessage}</div>
      <div className={cx(['error-message-bubble-triangle', bubbleTriangleClassNames])} />
    </div>
  );
};

ErrorMessageBubble.propTypes = {
  // true = fire show animation
  // false =
  showErrorMessageBubble: PropTypes.oneOf(Object.values(ERROR_STATES)),
  errorMessage: PropTypes.string,
  bottomPosition: PropTypes.string,
  rightPosition: PropTypes.string,
  inline: PropTypes.bool,
  errorClassNames: PropTypes.string,
  bottomPositionMobile: PropTypes.string,
  bubbleTriangleClassNames: PropTypes.string,
  // Not all form layout bottom bars are fixed. Ex: Profile, so we opt out of fixed when bottomPositionMobile is set, see ErrorMessageBubble usage in Form
  mobileFixed: PropTypes.bool,
};

ErrorMessageBubble.defaultProps = {
  showErrorMessageBubble: null,
  errorMessage: 'Let’s complete all required fields!',
  inline: true,
  mobileFixed: true,
  errorClassNames: '',
};

export default ErrorMessageBubble;
