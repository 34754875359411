import React, { useState, useContext } from 'react';
import {
  Analytics,
  ANALYTICS_EVENT_NAMES,
  AnalyticsContext,
  AnalyticsProviderContext,
  ANALYTICS_TYPE,
  ANALYTICS_PROPERTIES,
} from 'analytics';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { useDeferRender } from 'hooks/useDeferRender';
import { useWindowSize } from 'hooks/useWindowSize';
import NukaCarousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import { ThemeContext } from 'themes/themes';
import styles from './Carousel.module.css';

const cx = classNames.bind(styles);

const Carousel = ({
  className,
  children,
  height,
  width,
  enableKeyboardControls,
  leftControlPosition,
  rightControlPosition,
  renderCenterLeftControls,
  renderCenterRightControls,
  renderBottomCenterControls,
  displayControlsBreakpoint,
  defaultBottomPosition,
  circularControlButtons,
  hideOnMobile,
  onSlideChange,
  pagingDotsClassName,
  controlsColor,
  ...rest
}) => {
  const analytics = useContext(AnalyticsProviderContext);
  const analyticsData = useContext(AnalyticsContext);

  const isClientReady = useDeferRender();
  const size = useWindowSize();
  const [hoveredLeftCaret, setHoveredLeftCaret] = useState(false);
  const [hoveredRightCaret, setHoveredRightCaret] = useState(false);
  return (
    isClientReady && (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={cx('carousel', { defaultBottomPosition }, className)}>
            <NukaCarousel
              speed={600}
              cellAlign="center"
              cellSpacing={20}
              afterSlide={(slideIndex) => {
                analytics.track(ANALYTICS_EVENT_NAMES.CAROUSEL_SLIDE, {
                  ...analyticsData,
                  slideIndex,
                });

                if (onSlideChange) onSlideChange(slideIndex);
              }}
              disableEdgeSwiping
              defaultControlsConfig={{ pagingDotsClassName: cx(theme, pagingDotsClassName) }}
              enableKeyboardControls={enableKeyboardControls}
              initialSlideHeight={height}
              {...(width ? { slideWidth: typeof width === 'string' ? width : `${width}px` } : {})}
              renderBottomCenterControls={renderBottomCenterControls}
              renderCenterLeftControls={
                renderCenterLeftControls ||
                (size?.width < displayControlsBreakpoint && hideOnMobile
                  ? () => null
                  : // eslint-disable-next-line react/prop-types
                    ({ previousSlide, currentSlide }) => (
                      <ButtonBase
                        style={leftControlPosition}
                        className={cx('control', { circularControlButtons })}
                        onMouseEnter={() => setHoveredLeftCaret(true)}
                        onMouseLeave={() => setHoveredLeftCaret(false)}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setHoveredLeftCaret(false);
                          previousSlide();
                        }}
                      >
                        <div className={cx('icon')}>
                          {hoveredLeftCaret && currentSlide !== 0 ? (
                            <GeneralIcon icon={GENERAL_ICONS.LEFT_CARET} scale={1.25} color={COLORS.DARK_NAVY} />
                          ) : (
                            <GeneralIcon
                              icon={GENERAL_ICONS.LEFT_CARET}
                              scale={1.25}
                              color={currentSlide !== 0 ? controlsColor : COLORS.GRAY}
                            />
                          )}
                        </div>
                      </ButtonBase>
                    ))
              }
              renderCenterRightControls={
                renderCenterRightControls ||
                (size?.width < displayControlsBreakpoint && hideOnMobile
                  ? () => null
                  : // eslint-disable-next-line react/prop-types
                    ({ nextSlide, currentSlide, slideCount }) => (
                      <ButtonBase
                        style={rightControlPosition}
                        className={cx('control', { circularControlButtons }, 'right')}
                        onMouseEnter={() => setHoveredRightCaret(true)}
                        onMouseLeave={() => setHoveredRightCaret(false)}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setHoveredRightCaret(false);
                          nextSlide();
                        }}
                      >
                        <div className={cx('icon-container')}>
                          {hoveredRightCaret && currentSlide !== slideCount - 1 ? (
                            <GeneralIcon icon={GENERAL_ICONS.RIGHT_CARET} scale={1.25} color={COLORS.DARK_NAVY} />
                          ) : (
                            <GeneralIcon
                              icon={GENERAL_ICONS.RIGHT_CARET}
                              scale={1.25}
                              color={currentSlide !== slideCount - 1 ? controlsColor : COLORS.GRAY}
                            />
                          )}
                        </div>
                      </ButtonBase>
                    ))
              }
              {...rest}
            >
              {children}
            </NukaCarousel>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  );
};

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  enableKeyboardControls: PropTypes.bool,
  leftControlPosition: PropTypes.object,
  rightControlPosition: PropTypes.object,
  renderCenterLeftControls: PropTypes.func,
  renderCenterRightControls: PropTypes.func,
  renderBottomCenterControls: PropTypes.func,
  displayControlsBreakpoint: PropTypes.oneOf(Object.values(BREAKPOINTS_WIDTHS)),
  defaultBottomPosition: PropTypes.bool,
  circularControlButtons: PropTypes.bool,
  hideOnMobile: PropTypes.bool, // false to show arrow buttons on mobile
  onSlideChange: PropTypes.func, // Called on slide change
  pagingDotsClassName: PropTypes.string,
  controlsColor: PropTypes.string,
};

Carousel.defaultProps = {
  className: '',
  enableKeyboardControls: true,
  leftControlPosition: {},
  rightControlPosition: {},
  displayControlsBreakpoint: BREAKPOINTS_WIDTHS.LG,
  defaultBottomPosition: true,
  circularControlButtons: false,
  hideOnMobile: true,
  onSlideChange: null,
  pagingDotsClassName: null,
  controlsColor: COLORS.DEFAULT,
};

// export default Analytics(Carousel);

export default Analytics((props) => ({
  [ANALYTICS_TYPE.Component]: 'Carousel',
  [ANALYTICS_PROPERTIES.name]: props.name || 'unknown',
}))(Carousel);
