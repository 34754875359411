import React from 'react';
import Popup from 'reactjs-popup';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.css';

const cx = classNames.bind(styles);

const Tooltip = ({ arrowStyle, children, className, contentStyle, iconProps, showIcon = true, ...rest }) => {
  return (
    <Popup
      contentStyle={{
        backgroundColor: '#0f2532',
        color: 'white',
        borderRadius: 16,
        padding: '20px',
        width: 300,
        whiteSpace: 'normal',
        ...contentStyle,
      }}
      arrowStyle={{ color: '#0f2532', ...arrowStyle }}
      trigger={
        <div className={cx('tooltip', className)}>
          {showIcon && <GeneralIcon icon={GENERAL_ICONS.TOOLTIP} {...iconProps} />}
        </div>
      }
      position="top center"
      on={['hover', 'click']}
      keepTooltipInside
      repositionOnResize
      {...rest}
    >
      <span>{children}</span>
    </Popup>
  );
};

Tooltip.propTypes = {
  arrowStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentStyle: PropTypes.object,
  iconProps: PropTypes.object,
  showIcon: PropTypes.bool,
};

Tooltip.defaultProps = {
  className: '',
  iconProps: {},
  contentStyle: {},
};

export default Tooltip;
