import { Button, Modal, Text } from '@belong/ui';
import { AppGIF } from 'lease-signing-flow/components/app-gif/app-gif';
import { AppQR } from 'lease-signing-flow/components/app-qr/app-qr';
import { AppStoreBadges } from 'lease-signing-flow/components/app-store-badges/app-store-badges';
import { DownloadAppButton } from 'lease-signing-flow/components/download-app-button/download-app-button';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const AppModal = ({ isOpen, onDismiss }: Props) => (
  <Modal
    contentClassName="flex"
    isOpen={isOpen}
    name="APP_MODAL"
    onDismiss={onDismiss}
    overlayClassName="z-[99999]"
    size="fullScreen"
  >
    <div className="container flex flex-col items-center pt-2xl">
      <div className="mb-xs sm:max-w-[539px] text-center">
        <Text fontWeight="semibold" variant="h3">
          {LEASE_SIGNING_FLOW_STRINGS['app.modal.title']}
        </Text>
      </div>
      <div className="sm:max-w-[565px] text-center">
        <Text>{LEASE_SIGNING_FLOW_STRINGS['app.modal.subtitle']}</Text>
      </div>
      <div className="flex justify-center mt-2xl w-full">
        <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justifiy-start md:ml-3xl w-full sm:w-auto">
          <AppGIF />
          <div className="mt-xl sm:hidden w-full">
            <DownloadAppButton />
            <div className="mt-xl text-center">
              <Button buttonType="primary" onClick={onDismiss} variant="text">
                {LEASE_SIGNING_FLOW_STRINGS['success.cta']}
              </Button>
            </div>
          </div>
          <div className="hidden md:block ml-2xl">
            <div className="flex flex-col justify-items-start pt-2xl">
              <AppQR />
              <div className="flex flex-col items-start justify-items-start mt-xl">
                <div className="mb-xl">
                  <AppStoreBadges />
                </div>
                <Button buttonType="primary" onClick={onDismiss} variant="text">
                  {LEASE_SIGNING_FLOW_STRINGS['success.cta']}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);
