import { Text } from '@belong/ui';
import { STRINGS } from 'maintenance/strings';

export function ResidentCommunicationsBanner() {
  return (
    <div className="border border-solid border-green rounded bg-green-translucent-light flex items-center justify-center p-sm">
      <Text>
        <span aria-label="sparks" role="img">
          ✨
        </span>{' '}
        {STRINGS['resident-banner']}
      </Text>
    </div>
  );
}
