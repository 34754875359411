export const GALLERY_MEDIA = [
  {
    mediaId: '2126-2463-f497bc0072a3148b',
    name: 'Outside10.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-f497bc0072a3148b',
    sortOrder: 0,
    ownerType: 'Space',
    ownerId: '2a9f3df8eb884e0c',
  },
  {
    mediaId: '2126-2463-af1881769905705f',
    name: 'BelongHome2.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-af1881769905705f',
    sortOrder: 2,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-8a5f8eae07cf5181',
    name: 'BelongHome4.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-8a5f8eae07cf5181',
    sortOrder: 3,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-c1cb40a1c04221f0',
    name: 'BelongHome5.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-c1cb40a1c04221f0',
    sortOrder: 4,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-7a8b2be9ab9e447a',
    name: 'BelongHome6.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-7a8b2be9ab9e447a',
    sortOrder: 5,
    ownerType: 'Space',
    ownerId: 'c120d1332a62df3',
  },
  {
    mediaId: '2126-2463-361484a80d2f9d24',
    name: 'BelongHome8.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-361484a80d2f9d24',
    sortOrder: 7,
    ownerType: 'Space',
    ownerId: 'a64d342843abe47',
  },
  {
    mediaId: '2126-2463-dd3971dee21aac21',
    name: 'BelongHome9.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-dd3971dee21aac21',
    sortOrder: 8,
    ownerType: 'Space',
    ownerId: 'a64d342843abe47',
  },
  {
    mediaId: '2126-2463-63720c938d60deaf',
    name: 'BelongHome10.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-63720c938d60deaf',
    sortOrder: 9,
    ownerType: 'Space',
    ownerId: 'a64d342843abe47',
  },
  {
    mediaId: '2126-2463-6ec90fa895675b28',
    name: 'BelongHome11.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-6ec90fa895675b28',
    sortOrder: 10,
    ownerType: 'Space',
    ownerId: 'c120d1332a62df3',
  },
  {
    mediaId: '2126-2463-e59a348f2d1fe95b',
    name: 'BelongHome12.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-e59a348f2d1fe95b',
    sortOrder: 11,
    ownerType: 'Space',
    ownerId: 'c120d1332a62df3',
  },
  {
    mediaId: '2126-2463-c36e6bee2449991f',
    name: 'BelongHome13.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-c36e6bee2449991f',
    sortOrder: 12,
    ownerType: 'Space',
    ownerId: 'ef2346b1db464c5e',
  },
  {
    mediaId: '2126-2463-deb9ab3054827756',
    name: 'BelongHome14.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-deb9ab3054827756',
    sortOrder: 13,
    ownerType: 'Space',
    ownerId: 'ef2346b1db464c5e',
  },
  {
    mediaId: '2126-2463-b3d76a98237999c7',
    name: 'BelongHome15.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-b3d76a98237999c7',
    sortOrder: 14,
    ownerType: 'Space',
    ownerId: 'ef2346b1db464c5e',
  },
  {
    mediaId: '2126-2463-4446838c1834cf31',
    name: 'BelongHome17.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-4446838c1834cf31',
    sortOrder: 16,
    ownerType: 'Space',
    ownerId: 'df76b40e1bc7095c',
  },
  {
    mediaId: '2126-2463-9fb92e85ae1eb613',
    name: 'BelongHome18.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-9fb92e85ae1eb613',
    sortOrder: 17,
    ownerType: 'Space',
    ownerId: 'df76b40e1bc7095c',
  },
  {
    mediaId: '2126-2463-78fab48ea2361ab2',
    name: 'BelongHome19.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-78fab48ea2361ab2',
    sortOrder: 18,
    ownerType: 'Space',
    ownerId: '5fa6240b265909c9',
  },
  {
    mediaId: '2126-2463-f5703e809069e5e8',
    name: 'BelongHome20.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-f5703e809069e5e8',
    sortOrder: 19,
    ownerType: 'Space',
    ownerId: '5fa6240b265909c9',
  },
  {
    mediaId: '2126-2463-d7ea1ebab4dba93',
    name: 'BelongHome21.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-d7ea1ebab4dba93',
    sortOrder: 20,
  },
  {
    mediaId: '2126-2463-442a6082274ce3f3',
    name: 'BelongHome22.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-442a6082274ce3f3',
    sortOrder: 21,
    ownerType: 'Space',
    ownerId: '71aa470947cd9324',
  },
  {
    mediaId: '2126-2463-2ec7762cbf93f7b8',
    name: 'BelongHome23.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-2ec7762cbf93f7b8',
    sortOrder: 22,
    ownerType: 'Space',
    ownerId: '71aa470947cd9324',
  },
  {
    mediaId: '2126-2463-3586a16dd669137c',
    name: 'BelongHome24.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-3586a16dd669137c',
    sortOrder: 23,
    ownerType: 'Space',
    ownerId: '71aa470947cd9324',
  },
  {
    mediaId: '2126-2463-501b38d26c92807e',
    name: 'BelongHome25.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-501b38d26c92807e',
    sortOrder: 24,
    ownerType: 'Space',
    ownerId: '32e32734ff0ba806',
  },
  {
    mediaId: '2126-2463-861434771320b2ca',
    name: 'BelongHome26.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-861434771320b2ca',
    sortOrder: 25,
    ownerType: 'Space',
    ownerId: '32e32734ff0ba806',
  },
  {
    mediaId: '2126-2463-61d274c6dfacdb89',
    name: 'BelongHome27.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-61d274c6dfacdb89',
    sortOrder: 26,
    ownerType: 'Space',
    ownerId: '32e32734ff0ba806',
  },
  {
    mediaId: '2126-2463-457cb1c69474fcf5',
    name: 'BelongHome28.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-457cb1c69474fcf5',
    sortOrder: 27,
    ownerType: 'Space',
    ownerId: '1d983dd2a6ba7980',
  },
  {
    mediaId: '2126-2463-756bb3d0c8494ace',
    name: 'BelongHome30.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-756bb3d0c8494ace',
    sortOrder: 29,
  },
  {
    mediaId: '2126-2463-77db9d2bbc92cc15',
    name: 'BelongHome31.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-77db9d2bbc92cc15',
    sortOrder: 30,
  },
  {
    mediaId: '2126-2463-8d53fe1a0287ba31',
    name: 'BelongHome32.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-8d53fe1a0287ba31',
    sortOrder: 31,
    ownerType: 'Space',
    ownerId: '96ba455ec58bac99',
  },
  {
    mediaId: '2126-2463-620ff1b3b697498b',
    name: 'BelongHome34.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-620ff1b3b697498b',
    sortOrder: 33,
    ownerType: 'Space',
    ownerId: '6a9f97897c8b881b',
  },
  {
    mediaId: '2126-2463-461ebf918d86be10',
    name: 'BelongHome35.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-461ebf918d86be10',
    sortOrder: 34,
    ownerType: 'Space',
    ownerId: '6a9f97897c8b881b',
  },
  {
    mediaId: '2126-2463-adcca8ef40570871',
    name: 'BelongHome36.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-adcca8ef40570871',
    sortOrder: 35,
    ownerType: 'Space',
    ownerId: '6a9f97897c8b881b',
  },
  {
    mediaId: '2126-2463-491cd9f89a513252',
    name: 'BelongHome37.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-491cd9f89a513252',
    sortOrder: 36,
    ownerType: 'Space',
    ownerId: '6a9f97897c8b881b',
  },
  {
    mediaId: '2126-2463-44d48fb6902b6f8f',
    name: 'BelongHome38.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-44d48fb6902b6f8f',
    sortOrder: 37,
    ownerType: 'Space',
    ownerId: '6a9f97897c8b881b',
  },
  {
    mediaId: '2126-2463-4a25488af8cbc3cf',
    name: 'BelongHome39.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-4a25488af8cbc3cf',
    sortOrder: 38,
    ownerType: 'Space',
    ownerId: '6a9f97897c8b881b',
  },
  {
    mediaId: '2126-2463-67e8085c06620e33',
    name: 'BelongHome40.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-67e8085c06620e33',
    sortOrder: 39,
    ownerType: 'Space',
    ownerId: '483f7b0be6018118',
  },
  {
    mediaId: '2126-2463-1da7b5b772809172',
    name: 'BelongHome42.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-1da7b5b772809172',
    sortOrder: 41,
  },
  {
    mediaId: '2126-2463-dc7713376003a736',
    name: 'BelongHome43.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-dc7713376003a736',
    sortOrder: 42,
    ownerType: 'Space',
    ownerId: '1869d4429cc8b431',
  },
  {
    mediaId: '2126-2463-7f1e6a4e00839a9f',
    name: 'BelongHome44.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-7f1e6a4e00839a9f',
    sortOrder: 43,
    ownerType: 'Space',
    ownerId: '1869d4429cc8b431',
  },
  {
    mediaId: '2126-2463-e5aea79c833012af',
    name: 'BelongHome45.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-e5aea79c833012af',
    sortOrder: 44,
    ownerType: 'Space',
    ownerId: '1869d4429cc8b431',
  },
  {
    mediaId: '2126-2463-84763a8060a36b5',
    name: 'BelongHome46.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-84763a8060a36b5',
    sortOrder: 45,
    ownerType: 'Space',
    ownerId: '1869d4429cc8b431',
  },
  {
    mediaId: '2126-2463-dcd4a15127884830',
    name: 'BelongHome47.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-dcd4a15127884830',
    sortOrder: 46,
    ownerType: 'Space',
    ownerId: '1869d4429cc8b431',
  },
  {
    mediaId: '2126-2463-e996cf1202bc243',
    name: 'BelongHome48.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-e996cf1202bc243',
    sortOrder: 47,
    ownerType: 'Space',
    ownerId: '5f695d8dca6d56c8',
  },
  {
    mediaId: '2126-2463-5f07c881715ea671',
    name: 'BelongHome51.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-5f07c881715ea671',
    sortOrder: 50,
    ownerType: 'Space',
    ownerId: '5f695d8dca6d56c8',
  },
  {
    mediaId: '2126-2463-3913877078e8deb',
    name: 'BelongHome52.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-3913877078e8deb',
    sortOrder: 51,
    ownerType: 'Space',
    ownerId: '5f695d8dca6d56c8',
  },
  {
    mediaId: '2126-2463-770dd86ab864c7c6',
    name: 'BelongHome53.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-770dd86ab864c7c6',
    sortOrder: 52,
    ownerType: 'Space',
    ownerId: '35bbda7ef959116b',
  },
  {
    mediaId: '2126-2463-c7ec453be2247668',
    name: 'BelongHome56.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-c7ec453be2247668',
    sortOrder: 55,
    ownerType: 'Space',
    ownerId: '35bbda7ef959116b',
  },
  {
    mediaId: '2126-2463-2d34ad3f63d63a5b',
    name: 'BelongHome57.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-2d34ad3f63d63a5b',
    sortOrder: 56,
    ownerType: 'Space',
    ownerId: '39ae10c7644efa8e',
  },
  {
    mediaId: '2126-2463-9a43a94821684411',
    name: 'BelongHome58.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-9a43a94821684411',
    sortOrder: 57,
    ownerType: 'Space',
    ownerId: '39ae10c7644efa8e',
  },
  {
    mediaId: '2126-2463-af22b80854eaeb03',
    name: 'BelongHome59.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-af22b80854eaeb03',
    sortOrder: 58,
    ownerType: 'Space',
    ownerId: '39ae10c7644efa8e',
  },
  {
    mediaId: '2126-2463-9d7f01e6414c2492',
    name: 'BelongHome60.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-9d7f01e6414c2492',
    sortOrder: 59,
    ownerType: 'Space',
    ownerId: '39ae10c7644efa8e',
  },
  {
    mediaId: '2126-2463-54144c0dfc693337',
    name: 'BelongHome61.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-54144c0dfc693337',
    sortOrder: 60,
    ownerType: 'Space',
    ownerId: '980221a73a7f7a12',
  },
  {
    mediaId: '2126-2463-694dc0411c223b62',
    name: 'BelongHome62.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-694dc0411c223b62',
    sortOrder: 61,
    ownerType: 'Space',
    ownerId: '980221a73a7f7a12',
  },
  {
    mediaId: '2126-2463-ac10fe051f852ea6',
    name: 'BelongHome63.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-ac10fe051f852ea6',
    sortOrder: 62,
    ownerType: 'Space',
    ownerId: '980221a73a7f7a12',
  },
  {
    mediaId: '2126-2463-163aee8fa292eed7',
    name: 'BelongHome64.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-163aee8fa292eed7',
    sortOrder: 63,
    ownerType: 'Space',
    ownerId: '980221a73a7f7a12',
  },
  {
    mediaId: '2126-2463-815718fbed0f6668',
    name: 'BelongHome65.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-815718fbed0f6668',
    sortOrder: 64,
    ownerType: 'Space',
    ownerId: '980221a73a7f7a12',
  },
  {
    mediaId: '2126-2463-f277ce477a3b5e14',
    name: 'BelongHome66.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-f277ce477a3b5e14',
    sortOrder: 65,
    ownerType: 'Space',
    ownerId: '2e132b6f5c8e43a7',
  },
  {
    mediaId: '2126-2463-2ab083031f971c9d',
    name: 'BelongHome67.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-2ab083031f971c9d',
    sortOrder: 66,
    ownerType: 'Space',
    ownerId: 'f1425c081dc4473f',
  },
  {
    mediaId: '2126-2463-8062fcc9dc5a1911',
    name: 'BelongHome68.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-8062fcc9dc5a1911',
    sortOrder: 67,
    ownerType: 'Space',
    ownerId: 'f1425c081dc4473f',
  },
  {
    mediaId: '2126-2463-e6ba6576f10e7a43',
    name: 'BelongHome69.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-e6ba6576f10e7a43',
    sortOrder: 68,
    ownerType: 'Space',
    ownerId: '78afc376ab1c4dba',
  },
  {
    mediaId: '2126-2463-c54cb38509d6b722',
    name: 'Gym4.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-c54cb38509d6b722',
    sortOrder: 69,
    ownerType: 'Space',
    ownerId: '74f211646b5ab925',
  },
  {
    mediaId: '2126-2463-3f8891f671882456',
    name: 'Backyard2.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-3f8891f671882456',
    sortOrder: 70,
    ownerType: 'Space',
    ownerId: 'f1425c081dc4473f',
  },
  {
    mediaId: '2126-2463-7cc4fdcff1db540e',
    name: 'Backyard11.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-7cc4fdcff1db540e',
    sortOrder: 71,
    ownerType: 'Space',
    ownerId: '2a9f3df8eb884e0c',
  },
  {
    mediaId: '2126-2463-e7af7bf2027cfcb6',
    name: 'Backyard13.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-e7af7bf2027cfcb6',
    sortOrder: 72,
    ownerType: 'Space',
    ownerId: '2a9f3df8eb884e0c',
  },
  {
    mediaId: '2126-2463-28ff8ba23d66077a',
    name: 'Backyard17.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-28ff8ba23d66077a',
    sortOrder: 73,
    ownerType: 'Space',
    ownerId: '2e132b6f5c8e43a7',
  },
  {
    mediaId: '2126-2463-f79099f54ce2682f',
    name: 'Outside1.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-f79099f54ce2682f',
    sortOrder: 74,
    ownerType: 'Space',
    ownerId: '2a9f3df8eb884e0c',
  },
  {
    mediaId: '2126-2463-619345bb434e6007',
    name: 'Outside2.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-619345bb434e6007',
    sortOrder: 75,
    ownerType: 'Space',
    ownerId: '2a9f3df8eb884e0c',
  },
  {
    mediaId: '2126-2463-d9e47636742ce6ad',
    name: 'Front6.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-d9e47636742ce6ad',
    sortOrder: 76,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-966fbbf167ac73d',
    name: 'Outside13.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-966fbbf167ac73d',
    sortOrder: 77,
    ownerType: 'Space',
    ownerId: 'f1425c081dc4473f',
  },
  {
    mediaId: '2126-2463-6c4bc93e94c4b248',
    name: 'Front1.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-6c4bc93e94c4b248',
    sortOrder: 78,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-fe3f75e234fb8749',
    name: 'Front2.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-fe3f75e234fb8749',
    sortOrder: 79,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-62c1ad65ca78b687',
    name: 'Front4.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-62c1ad65ca78b687',
    sortOrder: 80,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-e9c9341f9d454491',
    name: 'Front5.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-e9c9341f9d454491',
    sortOrder: 81,
    ownerType: 'Space',
    ownerId: 'fa8c2ebc1d224bf9',
  },
  {
    mediaId: '2126-2463-20d1c70a40696437',
    name: 'BelongHome3.jpg',
    mediaType: 'Image',
    url: 'https://s3-us-west-1.amazonaws.com/bln-prd-home-media/2126-2463-20d1c70a40696437',
    sortOrder: 82,
  },
];
