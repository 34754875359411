import Address from 'models/common/Address';

export default class AccountPaymentItemDescription {
  constructor(data = {}) {
    this.itemId = data.itemId;
    this.itemType = data.itemType;
    this.amount = data.amount;
    this.description = data.description;
    this.address = new Address(data.address);
    this.financeId = data.financeId;
  }
}
