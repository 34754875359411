import { getApiActionTypesValues } from '../redux_utils';
import { selectHomeById } from './selectors';

export const fetchHome =
  (homeId, cache = true) =>
  (dispatch, getState) => {
    const home = selectHomeById(getState(), homeId);

    if (cache && home) {
      return Promise.resolve(home);
    }

    return dispatch({
      types: getApiActionTypesValues('FETCH_HOME'),
      promise: ({ client }) => client.get(`/homes/${homeId}`),
    });
  };

export const fetchHomeTours = (homeId, visitType) => ({
  promise: ({ client }) => client.get(`/homes/${homeId}/tours/${visitType}`),
});
