import { useSelector } from 'react-redux';
import { GroupBalanceInfoModel } from 'api/models';
import { BillingInfoModel } from 'api/models.v2';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import Money from 'components/Money/Money';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { selectCurrentBill } from 'store/redux/accounts/selectors';
import { EARNINGS_STRINGS } from '../../Pages/YourPortfolio/Earnings/earnings.strings';
import TransactionDeposits from '../TransactionDeposits/TransactionDeposits';
import { PaymentBillDetail } from '../payment-bill-detail-modal/payment-bill-detail/payment-bill-detail';
import styles from './payout-bill-detail-modal.module.css';

const cx = classNames.bind(styles);

type PayoutBillDetailModalProps = {
  show: boolean;
  onHide?: () => void;
  home: string;
  handleFinancingModal: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowBalanceDetailsModal: (balance: GroupBalanceInfoModel, home: string, billingInfo?: BillingInfoModel) => void;
};

export function PayoutBillDetailModal({
  show,
  onHide,
  handleFinancingModal,
  handleShowBalanceDetailsModal,
  home,
}: PayoutBillDetailModalProps) {
  const bill = useSelector(selectCurrentBill);

  function handleHide() {
    onHide?.();
  }

  if (!bill) {
    return null;
  }

  function buildItems() {
    if (bill.items.length === 0) return {};
    const financingPlans = bill?.finances?.map((fin) => fin.id);
    const groupedItems = [];

    if (financingPlans) {
      financingPlans.forEach((plan) => {
        const filteredItems = bill.items.filter(
          //TODO: wait for backend to change financeId to integer, already asked Matias P
          // eslint-disable-next-line eqeqeq
          (item) => plan == item.financeId
        );
        groupedItems.push(filteredItems);
      });
    }

    const ungroupedItems = bill.items.filter((item) => typeof item.financeId === 'undefined');

    const financePlans = bill.items.filter((item) => item.sourceType === 'Finance');

    return {
      ungroupedItems,
      financePlans,
      groupedItems: groupedItems.flat(),
    };
  }

  return (
    <ModalV2 show={show} noPadding showSpinner={false} onHide={handleHide} className={cx('payout-bill-detail-modal')}>
      <ModalLayout>
        <ModalLayout.Title>{bill.billInfo.description}</ModalLayout.Title>
        <Space value={SPACE_TYPES.SM} />
        <div className={cx('amount')}>
          <Money cash={bill.billInfo.total} />
        </div>
        {bill.billInfo.postedOn && (
          <div className={cx('date')}>
            {EARNINGS_STRINGS['processed.label']}&nbsp;
            <BDate time={bill.billInfo.postedOn} formatType={DATE_TYPES.DAY_MONTH_YEAR} />
          </div>
        )}
        <ModalLayout.Content>
          <ModalLayout.Section>
            <PaymentBillDetail
              items={buildItems()}
              finances={bill.finances}
              task={bill}
              billId={bill.billInfo.displayId}
              handleFinancingModal={handleFinancingModal}
              handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
              isHomeowner
              home={home}
            />
          </ModalLayout.Section>
          {bill.transfers?.length ? (
            <ModalLayout.Section>
              <ModalLayout.SectionTitle>{EARNINGS_STRINGS['paid_from.label']}</ModalLayout.SectionTitle>
              <TransactionDeposits transactions={bill.transfers} />
            </ModalLayout.Section>
          ) : (
            <ModalLayout.Section>
              <ModalLayout.SectionTitle>{EARNINGS_STRINGS['no_transfers.label']}</ModalLayout.SectionTitle>
            </ModalLayout.Section>
          )}
          <ModalLayout.BottomBar fixedBottom noButton />
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
}
