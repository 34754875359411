import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { GoogleMapMobile } from 'components/GoogleMap/HomesMap/GoogleMapMobile/GoogleMapMobile';
import CurrentSupplyMap from 'components/GoogleMap/CurrentSupplyMap/CurrentSupplyMap';

const CurrentSupplyMapContainer = ({ homes }) => {
  const [active, setActive] = useState(null);
  const [hover, setShowHover] = useState(null);
  const [modal, setModal] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const distanceToMouse = ({ x, y }, { x: mouseX, y: mouseY }) => {
    return Math.max(Math.abs(mouseX - x), Math.abs(mouseY - y + 32));
  };

  const onChildClick = (childIndex) => {
    if (active !== childIndex) {
      setActive(childIndex);
    } else {
      setActive(null);
    }
  };

  const onMainMapChildClick = (childIndex) => {
    if (!isMobile) {
      if (active !== childIndex) {
        setActive(childIndex);
      } else {
        setActive(null);
      }
    } else {
      setModal(true);
      setHasBeenOpened(true);
    }
  };
  const mapRef = useRef();

  const setAndRemoveCorrectZoom = (bounds) => {
    mapRef.current.setOptions({ maxZoom: 17 });
    // eslint-disable-next-line
    google.maps.event.addListenerOnce(mapRef.current, 'idle', () => {
      mapRef.current.setOptions({ maxZoom: null });
    });

    mapRef.current.fitBounds(bounds);
  };

  const handleApiLoaded = (map) => {
    mapRef.current = map;
    if (isMobile) {
      mapRef.current.setOptions({ draggableCursor: 'pointer' });
    }

    if (homes?.length) {
      // eslint-disable-next-line
      const bounds = new google.maps.LatLngBounds();

      homes.forEach((home) => {
        bounds.extend(
          // eslint-disable-next-line
          new google.maps.LatLng(
            parseFloat(home.address.coordinates.latitude),
            parseFloat(home.address.coordinates.longitude)
          )
        );
      });
      setAndRemoveCorrectZoom(bounds);
    }
  };

  return (
    <>
      {isMobile && hasBeenOpened && (
        <GoogleMapMobile
          onHide={() => {
            setModal(false);
            setActive(null);
            setShowHover(null);
          }}
          visible={modal}
        >
          <CurrentSupplyMap
            hasPointer={isMobile}
            hover={hover}
            active={active}
            distanceToMouse={distanceToMouse}
            onChildMouseEnter={(childIndex) => {
              setShowHover(childIndex);
            }}
            onChildMouseLeave={() => {
              setShowHover(null);
            }}
            onChildClick={onChildClick}
            onClick={() => {
              if (isMobile) {
                setModal(true);
                setHasBeenOpened(true);
              }
              setActive(null);
            }}
            setActive={setActive}
            homes={homes}
            zoomControlPosition="RIGHT_TOP"
            handleApiLoaded={handleApiLoaded}
            hoverDistance={36}
          />
        </GoogleMapMobile>
      )}

      <CurrentSupplyMap
        hasPointer={isMobile}
        hover={hover}
        active={active}
        gesture={isMobile ? 'cooperative' : 'greedy'}
        distanceToMouse={distanceToMouse}
        onChildMouseEnter={(childIndex) => {
          if (!isMobile) {
            setShowHover(childIndex);
          }
        }}
        onChildMouseLeave={() => {
          setShowHover(null);
        }}
        onChildClick={onMainMapChildClick}
        onClick={() => {
          if (isMobile) {
            setModal(true);
            setHasBeenOpened(true);
          } else {
            setActive(null);
          }
        }}
        setActive={setActive}
        zoomControlPosition="RIGHT_BOTTOM"
        homes={homes}
        handleApiLoaded={handleApiLoaded}
        hoverDistance={36}
      />
    </>
  );
};

CurrentSupplyMapContainer.propTypes = {
  homes: PropTypes.arrayOf(PropTypes.object),
};

CurrentSupplyMapContainer.defaultProps = {
  homes: [],
};

export default CurrentSupplyMapContainer;
