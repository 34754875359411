import { Text, Image, Carousel } from '@belong/ui';
import { AGREEMENT_SUMARY } from 'pages/AgreementFlow/consts';

export const AgreementSummary = () => {
  return (
    <>
      <div className="md:hidden">
        <Carousel>
          {AGREEMENT_SUMARY.map((agreementSummaryItem) => (
            <div className="min-w-full" key={agreementSummaryItem.title}>
              <Image
                alt={agreementSummaryItem.title}
                className="w-2xl h-2xl mb-sm mx-auto"
                src={agreementSummaryItem.image}
              />
              <Text fontWeight="semibold" className="mb-2xs text-center">
                {agreementSummaryItem.title}
              </Text>
              <Text variant="p1">{agreementSummaryItem.description}</Text>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="hidden md:grid md:grid-cols-3 md:gap-xl">
        {AGREEMENT_SUMARY.map((agreementSummaryItem) => (
          <div key={agreementSummaryItem.title}>
            <Image alt={agreementSummaryItem.title} className="w-2xl h-2xl mb-sm" src={agreementSummaryItem.image} />
            <Text fontWeight="semibold" className="mb-2xs">
              {agreementSummaryItem.title}
            </Text>
            <Text variant="p1">{agreementSummaryItem.description}</Text>
          </div>
        ))}
      </div>
    </>
  );
};
