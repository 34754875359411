// React imports
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
// Component imports
import ItemDetail from 'components/ItemDetail/ItemDetail';
import PaymentStatusLabel from 'components/Payments/PaymentStatusLabel/PaymentStatusLabel';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import BillBasicInfo from 'models/payments/BillBasicInfo';
import { getPaymentMethodIcon, getPaymentMethodName } from 'models/utilities/paymentMethods';
import PropTypes from 'prop-types';
import { getString } from 'strings/index';
// Constant, Util & String imports
import { PAYMENT_LIST_MODAL } from 'strings/payment-list-modal';
import Money from '../../components/Money/Money';
// SCSS imports
import styles from './TransactionHistory.module.css';

const PLM = PAYMENT_LIST_MODAL.paymentdetails;
const cx = classNames.bind(styles);

const showTransaction = (number, max) => {
  if (!max) {
    return true;
  }

  if (max > number) {
    return true;
  }

  return false;
};

const showAmount = (billTotal, transactionTotal) => {
  if (!transactionTotal) {
    return false;
  }

  if (billTotal === transactionTotal) {
    return false;
  }

  return true;
};

export default class TransactionHistory extends Component {
  static propTypes = {
    transfers: PropTypes.array.isRequired,
    billInfo: PropTypes.instanceOf(BillBasicInfo).isRequired,
  };

  static defaultProps = {};

  state = {
    showMore: false,
    handleShowMoreAnimation: false,
    handleShowLessAnimation: false,
  };

  handleClick = () => {
    this.setState((st) => ({
      showMore: !st.showMore,
      handleShowMoreAnimation: !st.showMore,
      handleShowLessAnimation: !!st.showMore,
    }));
  };

  renderShowMore = () => {
    const { transfers } = this.props;
    const { showMore, handleShowMoreAnimation, handleShowLessAnimation } = this.state;
    return (
      <IconButton
        align={ALIGN_TYPES.LEFT}
        icon={GENERAL_ICONS.DOWN_CARET}
        label={showMore ? 'SHOW LESS' : `SHOW MORE (${transfers.length - 2})`}
        onClick={this.handleClick}
        onAnimationEnd={() => {
          this.setState({
            handleShowMoreAnimation: false,
            handleShowLessAnimation: false,
          });
        }}
        generalIconProps={{
          className: cx({ rotateIcon: showMore, handleShowMoreAnimation, handleShowLessAnimation }),
        }}
      />
    );
  };

  render() {
    const { transfers, billInfo } = this.props;

    const { showMore } = this.state;

    const maxNumber = showMore ? null : 2;
    return (
      <>
        <div className={cx('history-title')}>{PLM.historyTitle}</div>
        <Space value={SPACE_TYPES.SM} />
        {transfers.map((lastTransaction, i) => {
          if (showTransaction(i, maxNumber)) {
            return (
              <>
                <div className={cx('mobile')}>
                  <ItemDetail
                    key={lastTransaction.transactionId}
                    firstItem={
                      <div className={cx('paymentFlex')}>
                        <GeneralIcon
                          icon={
                            getPaymentMethodIcon(lastTransaction.paymentMethod, lastTransaction.accountType).DEFAULT
                          }
                        />
                        <div>
                          <div className={cx('string-mobile')}>
                            {getString(PLM.attempt, {
                              method: getPaymentMethodName({
                                bankName: lastTransaction.bankName,
                                paymentMethod: lastTransaction.paymentMethod,
                                accountLastDigits: lastTransaction.accountLastDigits,
                                referenceNumber: lastTransaction.referenceNumber,
                                otherPaymentType: lastTransaction.otherPaymentType,
                              }),
                            })}
                          </div>
                          <div className={cx('date-string-mobile')}>
                            {Date.now() <= lastTransaction.processedOn && <span>Scheduled&nbsp;</span>}
                            <BDate
                              time={lastTransaction.processedOn}
                              formatType={DATE_TYPES.DAY_MONTH_YEAR_TIME_TIMEZONE}
                            />{' '}
                            {showAmount(billInfo.total, lastTransaction.amount) && (
                              <span>
                                {' '}
                                • <Money cash={lastTransaction.amount} />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                    secondItem={<PaymentStatusLabel status={lastTransaction.status} />}
                  />
                </div>
              </>
            );
          }

          return null;
        })}
        {transfers.length > 2 && (
          <>
            <Space />
            <div className={cx('right-align')}>{this.renderShowMore()}</div>
          </>
        )}
      </>
    );
  }
}
