import { useSelector } from 'react-redux';
import { ResidentDetailsModel } from 'api/models';
import { selectUser } from 'store/redux/user/selectors';

export const useGetCurrentResidentFromLease = (residents: ResidentDetailsModel[]) => {
  const user = useSelector(selectUser);

  return residents?.find((resident) => resident?.basicInfo?.userInfo?.userId === user.userId);
};

/** Used to check if we should disable the lease sign options because
 * the current user is a roommate and the primary resident hasn't signed yet
 */
export const useIsRoommateWaitingOnPrimarySign = (residents: ResidentDetailsModel[]) => {
  const currentResident = useGetCurrentResidentFromLease(residents);
  const isCurrentUserRoommate = currentResident?.leaseInfo?.residentType === 'Roommate';

  const primaryResident = residents?.find((resident) => resident?.leaseInfo?.residentType === 'Primary');
  const primaryResidentDidntSign = primaryResident?.leaseInfo?.signatureStatus === 'Pending';

  return isCurrentUserRoommate && primaryResidentDidntSign;
};
