import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { required } from 'utils/validation';
import BulletList from 'components/BulletList/BulletList';
import { verifyAchMicrodepositPaymentMethod } from 'store/redux/user/actions';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import { PAYMENT_VERFIY } from 'strings/payments/payments.strings';
import String from 'components/String/String';

import Field from 'components/Field/Field';

class ManualAchVerificationModal extends Component {
  static propTypes = {
    paymentMethod: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    verifyAchMicrodepositPaymentMethod: PropTypes.func.isRequired,
  };

  static defaultProps = {
    paymentMethod: null,
  };

  state = {
    error: '',
    showSpinner: false,
  };

  showSpinner = () => {
    this.setState({ showSpinner: true });
  };

  hideSpinner = () => {
    this.setState({ showSpinner: false });
  };

  handleSubmit = async (values) => {
    const {
      onHide,
      paymentMethod: { accountId },
      verifyAchMicrodepositPaymentMethod: verifyAchMicrodepositPaymentMethodAction,
    } = this.props;

    const sendToBackend = {
      amount1: parseFloat(values.amount1),
      amount2: parseFloat(values.amount2),
    };

    this.showSpinner();

    try {
      await verifyAchMicrodepositPaymentMethodAction(accountId, sendToBackend);
      this.hideSpinner();
      onHide();
    } catch (error) {
      this.hideSpinner();
      // onFail(FEEDBACK_STRINGS.credit_card_fail);
      this.setState({ error: FEEDBACK_STRINGS.manual_ach_verify_fail });
    }
  };

  render() {
    const { paymentMethod } = this.props;
    const { error, showSpinner } = this.state;

    if (!paymentMethod) {
      return null;
    }

    const {
      name,
      achDetails: { bankName },
    } = paymentMethod;

    return (
      <ModalV2 noPadding showSpinner={showSpinner} {...this.props}>
        <ModalLayout>
          <ModalLayout.Title>
            <String string={PAYMENT_VERFIY['modal.title']} />
          </ModalLayout.Title>
          <ModalLayout.SubTitle noPadding withMarginTop>
            <String string={PAYMENT_VERFIY['modal.description']} />
          </ModalLayout.SubTitle>
          {error && (
            <ModalLayout.Error>
              <String string={error} />
            </ModalLayout.Error>
          )}
          <ModalLayout.Content>
            <ModalLayout.Section>
              <ModalLayout.SectionTitle>
                <BulletList listItems={[bankName, name]} />
              </ModalLayout.SectionTitle>
            </ModalLayout.Section>
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <FormLayout>
                    <Row>
                      <Col sm={12}>
                        <Field
                          name="amount1"
                          placeholder={PAYMENT_VERFIY['modal.input.deposit1.placeholder']}
                          component={InputFinalFormAdapter}
                          type="number"
                          validate={required}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Field
                          name="amount2"
                          placeholder={PAYMENT_VERFIY['modal.input.deposit2.placeholder']}
                          component={InputFinalFormAdapter}
                          type="number"
                          validate={required}
                        />
                      </Col>
                    </Row>
                    <ModalLayout.BottomBar
                      ctaProps={{
                        onClick: handleSubmit,
                        label: 'Verify',
                        disabled: invalid,
                      }}
                      fixedBottom
                    />
                  </FormLayout>
                </form>
              )}
            />
          </ModalLayout.Content>
        </ModalLayout>
      </ModalV2>
    );
  }
}

const mapDispatchToProps = {
  verifyAchMicrodepositPaymentMethod,
};

export default connect(null, mapDispatchToProps)(ManualAchVerificationModal);
