import { Text } from '@belong/ui';
import type { MaintenanceModel, MaintenanceRecurrenceModel } from 'api/models';
import { TaskList } from 'maintenance/components/task-list/task-list';
import type { ProServiceView } from 'maintenance/types';

type Props = {
  address: string;
  showMaintenanceModal: (task: MaintenanceModel | MaintenanceRecurrenceModel) => () => void;
  tasks: MaintenanceModel[];
  view?: ProServiceView;
};

export function UnitConsentPendingTasksSection({ address, showMaintenanceModal, tasks, view = 'homeOwner' }: Props) {
  return (
    <section>
      <header className="flex justify-between my-xs">
        <Text fontWeight="semibold">{address}</Text>
      </header>
      <div className="mb-2xl">
        <TaskList
          showMaintenanceModal={showMaintenanceModal}
          tasks={tasks}
          type="non-recurring"
          view={view}
          isConsentPendingList
        />
      </div>
    </section>
  );
}
