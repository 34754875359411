import React from 'react';
import PropTypes from 'prop-types';
import { RentalManagerSection } from '../RentalManagerSection/RentalManagerSection';
import { ResidentRelationshipSection } from '../ResidentRelationshipSection/ResidentRelationshipSection';
import { ResidentRelationshipCommentsSection } from '../ResidentRelationshipCommentsSection/ResidentRelationshipCommentsSection';
import { RentingPlansSection } from '../RentingPlansSection/RentingPlansSection';

export const RenterOccupiedForm = ({ form, name }) => (
  <>
    <ResidentRelationshipSection name={name} />
    <ResidentRelationshipCommentsSection name={name} />
    <RentalManagerSection form={form} name={name} />
    <RentingPlansSection name={name} />
  </>
);

RenterOccupiedForm.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
