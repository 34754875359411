import { Component } from 'react';
import { get, includes, isEqual, isNil, unset, every } from 'lodash-es';
import PropTypes from 'prop-types';

export const FIELD_CONDITION_STATES = {
  SHOW: 'SHOW',
  DISABLE: 'DISABLE',
};
export const FIELD_CONDITION_COMPARISON_TYPES = {
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
  IN: 'IN',
  NOT_IN: 'NOT_IN',
  NULL: 'NULL',
  NOT_NULL: 'NOT_NULL',
};

class FormCondition extends Component {
  static propTypes = {
    conditions: PropTypes.array.isRequired,
    formValues: PropTypes.object,
    fieldPrefix: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.node.isRequired,
    fieldConditionState: PropTypes.string,
  };

  static defaultProps = {
    formValues: {},
    fieldPrefix: '',
    name: '',
    fieldConditionState: '',
  };

  runCondition = (condition) => {
    const { formValues, fieldPrefix } = this.props;
    const { noPrefix } = condition;
    const fieldName = fieldPrefix && !noPrefix ? `${fieldPrefix}.${condition.fieldName}` : condition.fieldName;
    const currentValue = get(formValues, fieldName);
    const expectedValue = condition.fieldValue;

    switch (condition.compare) {
      case FIELD_CONDITION_COMPARISON_TYPES.EQUALS:
        return isEqual(currentValue, expectedValue);
      case FIELD_CONDITION_COMPARISON_TYPES.NOT_EQUALS:
        return !isEqual(currentValue, expectedValue);
      case FIELD_CONDITION_COMPARISON_TYPES.CONTAINS:
        return includes(currentValue, expectedValue);
      case FIELD_CONDITION_COMPARISON_TYPES.DOES_NOT_CONTAIN:
        return !includes(currentValue, expectedValue);
      case FIELD_CONDITION_COMPARISON_TYPES.IN:
        return includes(expectedValue, currentValue);
      case FIELD_CONDITION_COMPARISON_TYPES.NOT_IN:
        return !includes(expectedValue, currentValue);
      case FIELD_CONDITION_COMPARISON_TYPES.NULL:
        return isNil(currentValue);
      case FIELD_CONDITION_COMPARISON_TYPES.NOT_NULL:
        return !isNil(currentValue);
      default:
        return false;
    }
  };

  render() {
    const { conditions, children, formValues, fieldConditionState, name } = this.props;
    let shouldRender = false;

    conditions.forEach((andConditions) => {
      /* OR condition - so have to check the condition only if previous condition failed */
      if (!shouldRender) {
        shouldRender = every(andConditions, (condition) => {
          if (this.runCondition(condition)) {
            return true;
          }

          return false;
        });
      }
    });

    if (!shouldRender) {
      /* HACK: Remove the value from the formValues object instead of calling change */
      unset(formValues, name);

      if (fieldConditionState === FIELD_CONDITION_STATES.DISABLE) {
        return children({
          disabled: true,
        });
      }

      return null;
    }

    if (typeof children === 'function') {
      return children();
    }

    return children;
  }
}

FormCondition.propTypes = {};

export default FormCondition;
