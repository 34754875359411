import { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { getDescriptiveDay } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import String from 'components/String/String';
import { differenceInDays, addDays, isSaturday, isSunday, isBefore, startOfDay, endOfDay, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { getString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { padNumber } from 'utils/padNumber';
import { ApprovalContext } from '../ApprovalContext';
import styles from './EstimatedStateDate.module.css';

const cx = classNames.bind(styles);

const addBusinessDays = (date, days) => {
  const addedDays = addDays(date, days);

  if (isSaturday(addedDays)) {
    return addDays(addedDays, 2);
  }

  if (isSunday(addedDays)) {
    return addDays(addedDays, 1);
  }

  return addedDays;
};

export const MODES = {
  DEFAULT: 'default',
  DARK: 'dark',
};

const propTypes = {
  mode: PropTypes.oneOf(Object.values(MODES)),
};

const defaultProps = {
  mode: MODES.DEFAULT,
};

const EstimatedStateDate = ({ mode }) => {
  const { group, flow, inspectionType } = useContext(ApprovalContext);
  const [timerDate, setTimerDate] = useState('');
  const [estimatedDate, setEstimatedDate] = useState('');
  const { isPreMoveOut } = inspectionType || {};

  const { earlyEstimatedWorkStartDate } = group || {};
  const { createdOn } = flow?.auditInfo || {};

  if (!earlyEstimatedWorkStartDate) {
    return null;
  }

  const workStartTime = getDescriptiveDay(estimatedDate);
  const isWorkStartingTodayOrTomorrow = ['today', 'tomorrow'].includes(workStartTime.toLowerCase());

  const updateTimerDate = () => {
    const cutOffTime = addDays(startOfDay(parseISO(createdOn)), 2);
    const daysDiff = differenceInDays(new Date(), cutOffTime);
    const hoursDiff = isBefore(new Date(), new Date(cutOffTime));
    const calculatedStartDate = hoursDiff ? parseISO(earlyEstimatedWorkStartDate) : addBusinessDays(new Date(), 7);

    setTimerDate(addBusinessDays(endOfDay(new Date(cutOffTime)), daysDiff));
    setEstimatedDate(calculatedStartDate);
  };

  useEffect(updateTimerDate, []);

  if (!timerDate) {
    return null;
  }

  if (isPreMoveOut) {
    return (
      <div className={cx('px-0 md:px-xl', { [mode]: mode !== MODES.DEFAULT })}>
        {POST_INSPECTION_FLOW_STRINGS['approvals.paymentplan.subtitle.pre_move_out']}
      </div>
    );
  }

  return (
    <div className={cx('estimated-start-date', { [mode]: mode !== MODES.DEFAULT }, MODES.DEFAULT && 'text-center')}>
      <span className={cx('prefix')}>
        <Text as="span">{POST_INSPECTION_FLOW_STRINGS['approvals.paymentplan.subtitle.prefix']}</Text>
        &nbsp;
        <span className={cx('countdown')}>
          <Countdown
            daysInHours
            key={timerDate}
            date={timerDate}
            onComplete={updateTimerDate}
            renderer={({ days, hours, minutes, seconds }) => (
              <span className="body">
                {padNumber(days * 24 + hours)}:{padNumber(minutes)}:{padNumber(seconds)}
              </span>
            )}
          />
        </span>
        &nbsp;
      </span>
      <String
        className="body"
        as="span"
        string={getString(POST_INSPECTION_FLOW_STRINGS['approvals.paymentplan.subtitle.suffix'])}
      />
      &nbsp;
      {isWorkStartingTodayOrTomorrow && <br className="block lg:hidden" />}
      <String
        as="span"
        className="body"
        string={getString(POST_INSPECTION_FLOW_STRINGS['approvals.paymentplan.subtitle.date'], {
          date: workStartTime,
        })}
      />
    </div>
  );
};

EstimatedStateDate.propTypes = propTypes;
EstimatedStateDate.defaultProps = defaultProps;

export default EstimatedStateDate;
