import { useState, useLayoutEffect } from 'react';

export const useDeferRender = (timeout = 0) => {
  const [showChild, setShowChild] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setShowChild(true);
    }, timeout);
  }, []);

  return showChild;
};
