import React from 'react';
import { formatMoney } from '@belong/common';
import { Text, LinkBase, Link, Button, Tooltip, ConditionalWrap } from '@belong/ui';
import { Href } from '@belong/ui/src/LinkBase/LinkBase';
import cx from 'clsx';
import BulletList from 'components/BulletList/BulletList';
import { truncate } from 'lodash-es';
import UnitBasicInfo from 'models/common/UnitBasicInfo';
import { BASE_PATHS } from 'routes/paths';
import HomeInfo from '../../components/display/Home/HomeInfo/HomeInfo';
import styles from './TourAndAppsCard.module.css';

export const TourCardTitleWithDetails = ({
  title,
  basicInfo,
  rentAmount,
}: {
  title: string;
  basicInfo: UnitBasicInfo;
  rentAmount: number;
}) => (
  <>
    <Text variant="h3" fontWeight="semibold" className="mt-xs">
      {truncate(title, {
        length: 45,
        separator: /,? +/,
      })}
    </Text>
    <div className="text-p1 font-regular">
      <BulletList
        small
        flexWrap
        listItems={[
          <HomeInfo key="0" unitBasic={basicInfo} />,
          <span key="1">
            {formatMoney(rentAmount)}
            /m
          </span>,
        ]}
      />
    </div>
  </>
);

type TourCardAction = {
  label: string;
  href?: Href;
  onClick?: () => void;
  tooltipContent?: React.ReactNode;
  isCTA?: boolean;
  /** Use this prop if you want to render custom content instead of a button or link */
  customContent?: React.ReactNode;
};

export const TourCardActions = ({ actions }: { actions?: TourCardAction[] }) => (
  <div className="mt-sm px-2sm pb-sm md:p-0 flex flex-col-reverse md:flex-row md:justify-end items-end md:items-center gap-lg md:gap-sm text-center">
    {actions.map((action) => {
      if (action.customContent) {
        return action.customContent;
      }

      const commonProps = {
        key: action.label,
        variant: action.isCTA ? ('solid' as const) : ('text' as const),
        size: action.isCTA ? ({ _: 'fluid', md: 'default' } as const) : undefined,
      };

      return (
        <ConditionalWrap
          key={action.label}
          condition={!!action.tooltipContent}
          wrapper={(children) => (
            <Tooltip
              portal
              content={action.tooltipContent}
              arrowProps={{
                className: 'fill-gray',
              }}
            >
              {children}
            </Tooltip>
          )}
        >
          {action.href ? (
            <Link {...commonProps} href={action.href} onClick={(e) => e.stopPropagation()}>
              {action.label}
            </Link>
          ) : (
            <Button
              {...commonProps}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                action?.onClick?.();
              }}
            >
              {action.label}
            </Button>
          )}
        </ConditionalWrap>
      );
    })}
  </div>
);

export type TourCardProps = {
  homeUniqueId: string;
  imageUrl: string;
  topContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
};

export const TourCard = ({ homeUniqueId, imageUrl, topContent, bottomContent }: TourCardProps) => {
  return (
    <LinkBase href={`${BASE_PATHS.HOME}/${homeUniqueId}`}>
      <div className={cx(styles.container)}>
        <div className="flex flex-col md:flex-row relative">
          <div className={cx(styles.homeImage)} style={{ backgroundImage: `url(${imageUrl})` }} />
          <div className={cx(styles.possibleGrid)}>{topContent}</div>
        </div>
        {bottomContent}
      </div>
    </LinkBase>
  );
};
