export const SPACE_TYPES = {
  XXS: '2xs',
  XS: 'xs',
  SSM: '2sm',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: '2xl',
  XXXL: '3xl',
  XXXXL: '4xl',
  XXXXXL: '5xl',
  XXXXXXL: '6xl',
  XXXXXXXL: '7xl',
  NULL: null,
  HIDE: 'hide',
};
