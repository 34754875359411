import config from 'config/config';

const { cdnDomain } = config;
export const getOptimizedImageSrc = (src, width) => {
  if (!cdnDomain || !src) {
    return src;
  }

  const srcClean = src.replace('https://s3-us-west-1.amazonaws.com/', '');
  const bucket = srcClean.split('/')[0];
  const key = srcClean.split('/')[1];

  if (!key || !bucket) {
    return src;
  }

  const optimizedSrcOptions = {
    bucket,
    key,
  };

  if (width) {
    optimizedSrcOptions.edits = {
      resize: {
        width: 345,
        fit: 'contain',
      },
      rotate: null,
    };
  }

  return `${cdnDomain}/${btoa(JSON.stringify(optimizedSrcOptions))}`;
};

export const getSmallImageSrc = (src) => {
  return getOptimizedImageSrc(src, 200);
};
