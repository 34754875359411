import React from 'react';
import classNames from 'classnames/bind';
import { Box, Text } from 'design-system';
import PropTypes from 'prop-types';
import styles from './TextBubble.module.css';

const cx = classNames.bind(styles);

export const TextBubbleRadius = {
  Default: 'default-radius',
  Small: 'small-radius',
};

const TextBubble = ({
  animateTextChange = false,
  makeGreen = false,
  makeWhite = false,
  secondaryText = null,
  pointingTop = false,
  pointingDown = false,
  fontWeight = 'normal',
  radius = TextBubbleRadius.Default,
  secondaryAction = null,
  text,
  subText,
}) => {
  let wrapperDivClassName = 'text-bubble-wrapper';

  if (pointingTop && !makeGreen && !makeWhite) {
    wrapperDivClassName = 'text-bubble-wrapper-point-top';
  } else if (pointingTop && makeGreen) {
    wrapperDivClassName = 'text-bubble-wrapper-point-top-green';
  } else if (pointingTop && makeWhite) {
    wrapperDivClassName = 'text-bubble-wrapper-point-top-white';
  }

  if (pointingDown) {
    if (makeWhite) {
      wrapperDivClassName = 'text-bubble-wrapper-point-down-white';
    } else {
      if (makeGreen) {
        wrapperDivClassName = 'text-bubble-wrapper-point-down-green';
      } else {
        wrapperDivClassName = 'text-bubble-wrapper-point-down';
      }
    }
  }

  return (
    <>
      <div
        className={cx(wrapperDivClassName, {
          [radius]: radius !== TextBubbleRadius.Default,
        })}
      >
        <div key={Date.now()} className={cx('text-wrapper', { animateTextChange })}>
          <Text fontWeight={fontWeight}>{text}</Text>
        </div>
        {subText}
        {!!secondaryText && (
          <div className={cx('secondary-text', { makeGreen })}>
            <Text>{secondaryText}</Text>
          </div>
        )}
        {!!secondaryAction && <Box marginTop="sm">{secondaryAction}</Box>}
      </div>
    </>
  );
};

TextBubble.propTypes = {
  animateTextChange: PropTypes.bool,
  makeGreen: PropTypes.bool,
  makeWhite: PropTypes.bool,
  pointingTop: PropTypes.bool,
  pointingDown: PropTypes.bool,
  fontWeight: PropTypes.string,
  radius: PropTypes.oneOf(Object.values(TextBubbleRadius)),
  secondaryText: PropTypes.node,
  secondaryAction: PropTypes.node,
  text: PropTypes.node.isRequired,
  subText: PropTypes.node,
};

export default TextBubble;
