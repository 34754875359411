import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './HeaderBanner.module.css';

export const BannerTypes = {
  LA: 'LA',
  SEATTLE: 'SEATTLE',
  SAN_DIEGO: 'SAN_DIEGO',
};

const bannerMap = {};

const cx = classNames.bind(styles);

const HeaderBanner = ({ showMenu, type }) => (
  <div className={cx('header-banner', { [type]: type }, { showMenu })}>{bannerMap[type]}</div>
);

HeaderBanner.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(BannerTypes)).isRequired,
};

export default HeaderBanner;
