import React from 'react';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import classNames from 'classnames/bind';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import ResidentListContainer from 'containers/ResidentListContainer/ResidentListContainer';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { differenceInDays } from 'date-fns';
import { Text } from 'design-system';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import PropTypes from 'prop-types';
import styles from './DisplayLease.module.css';

const cx = classNames.bind(styles);

const DisplayLease = ({ lease }) => {
  const { residents = [], kids = [], pets = [], basicInfo } = lease || {};
  const allResidents = {
    residents,
    kids,
    pets,
  };

  const leaseStart = formatDateTime({
    dateTime: legacyParse(new Date(basicInfo.leaseStartOn)),
    format: DATE_FORMATS.FULL_MONTH_DAY_YEAR,
  });
  const leaseEnd = formatDateTime({
    dateTime: legacyParse(new Date(basicInfo.leaseEndOn)),
    format: DATE_FORMATS.FULL_MONTH_DAY_YEAR,
  });

  // Since we want to show leases that are not exactly 1 year but a couple of days less as 12months. I'm rounding.
  const leaseDuration = Math.round(
    (12 *
      differenceInDays(legacyParse(new Date(basicInfo.leaseEndOn)), legacyParse(new Date(basicInfo.leaseStartOn)))) /
      365
  );

  return (
    <div className={cx('display-lease-container')}>
      <Text fontWeight="semibold">Term</Text>
      <Space value={SPACE_TYPES.XS} />
      <div>
        <span className={cx('whitespace')}>{leaseStart}</span> - <span className={cx('whitespace')}>{leaseEnd}</span>{' '}
        <span className={cx('whitespace')}>({leaseDuration} Months)</span>
      </div>
      <Space value={SPACE_TYPES.XS} />
      <div>
        <Money format={MONEY_FORMAT.DOLLARS} cash={basicInfo.rentAmount} />
        /month &bull; <Money format={MONEY_FORMAT.DOLLARS} cash={basicInfo.depositAmount} /> Deposit
      </div>
      <Space value={SPACE_TYPES.LG} />
      <Text fontWeight="semibold">Belong Members</Text>
      <Space value={SPACE_TYPES.XS} />
      <div>
        <ResidentListContainer residents={allResidents} />
      </div>
    </div>
  );
};

DisplayLease.propTypes = {
  lease: PropTypes.object.isRequired,
};

export default DisplayLease;
