import { Component } from 'react';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Image from 'components/Image/Image';
import ProfileTile, { PROFILE_TILE_SPACING } from 'components/ProfileTile/ProfileTile';
import String from 'components/String/String';
import styles from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance.module.css';
import { USERACTIONMAINTENANCE_TYPES } from 'containercomponents/MaintenanceStatus/UserActionMaintenance/userActionMaintenanceTypes';
import Space from 'corecomponents/Space/Space';
import { isNil } from 'lodash-es';
import { HaulingTypes } from 'models/enums';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

export default class UserActionMaintenance extends Component {
  static propTypes = {
    profileImage: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    belongPro: PropTypes.bool,
    partner: PropTypes.bool,
    description: PropTypes.string,
    text: PropTypes.string,
    reportedBy: PropTypes.string,
    taskType: PropTypes.node,
    location: PropTypes.object,
    allDay: PropTypes.bool,
    withSpace: PropTypes.bool,
    requestHauling: PropTypes.object,
    belongEmployee: PropTypes.bool,
  };

  static defaultProps = {
    belongPro: false,
    belongEmployee: false,
    partner: false,
    text: null,
    reportedBy: null,
    description: null,
    taskType: USERACTIONMAINTENANCE_TYPES.REPORTED,
    location: {},
    allDay: false,
    withSpace: true,
    requestHauling: {
      items: [],
      type: null,
    },
  };

  getAsigneeType() {
    const { partner } = this.props;

    if (!partner) {
      return 'Belong Pro';
    }
  }

  getAsigneeName() {
    const { partner, name } = this.props;

    if (partner) {
      return 'Belong Partner';
    }

    return name;
  }

  render() {
    const {
      text,
      description,
      belongPro,
      reportedBy,
      time,
      profileImage,
      taskType,
      userId,
      location,
      allDay,
      withSpace,
      requestHauling,
      belongEmployee,
      partner,
    } = this.props;

    const { items, haulType } = requestHauling;
    return (
      <>
        <div className={cx('task-wrapper')}>
          <div>
            <div className={cx('bold')}>{taskType}</div>
            {!isNil(location?.isHome) && location.isHome === false && (
              <div>
                <String string={location.address.getFormattedAddress()} />
              </div>
            )}
            <div>
              {allDay ? (
                <span className={cx('small-text-on-mobile')}>
                  <BDate className={cx('small-text-on-mobile')} time={time} formatType={DATE_TYPES.DAY_MONTH_YEAR} />{' '}
                  (All Day)
                </span>
              ) : (
                <span className={cx('small-text-on-mobile')}>
                  <BDate time={time} formatType={DATE_TYPES.DAY_MONTH_YEAR_TIME} />{' '}
                </span>
              )}
            </div>
          </div>
          <div className={cx('profile-tile-container-wrapper')}>
            <div className={cx('align-right')}>
              <div className={cx('bold')}>{reportedBy !== 'Belong' ? this.getAsigneeName() : reportedBy}</div>
              {reportedBy !== 'Belong' && (
                <div className={cx('small-text-on-mobile')}>{reportedBy || this.getAsigneeType()}</div>
              )}
            </div>
            <div className={cx('profile-tile-wrapper')}>
              {partner ? (
                <Image src="/common/icon_partner.svg" alt="" />
              ) : (
                <ProfileTile
                  scale={0.6166}
                  imageUrl={profileImage}
                  userId={userId}
                  thumbnail
                  belongPro={belongPro}
                  maintenance
                  absoluteMobileOverlay
                  customEmptyProfileIcon={
                    reportedBy !== 'Belong' ? GENERAL_ICONS.EMPTY_PROFILE : GENERAL_ICONS.BELONG_PROFILE
                  }
                  spacing={PROFILE_TILE_SPACING.NO_SPACING}
                  emptyImageContainer={cx('transform-unknown-profile-image')}
                  classNameToResizeImage={cx('thumbnail-image')}
                  belongEmployee={belongEmployee}
                />
              )}
            </div>
          </div>
        </div>
        {withSpace && <Space />}
        <>
          {belongPro ? (
            description && (
              <div>
                <div className={cx('text-for-assessment-info')}>{text}</div>
                <div>
                  <pre>{description}</pre>
                </div>
              </div>
            )
          ) : (
            <div>
              <>
                {!!items?.length && (
                  <div>
                    <h3 className={cx('haul-title')}>
                      {haulType === HaulingTypes.Haul ? 'Items for removal' : 'Items for storage'}
                    </h3>
                    <ul>
                      {items.map((item) => {
                        return (
                          <li className={cx('display-inline')} key={item.item}>
                            {`${item.item}`}
                            {item.isValuable && ' (Highly valuable)'}
                          </li>
                        );
                      })}
                    </ul>
                    {description && <div className={cx('dotted-line')} />}
                  </div>
                )}
              </>
              {description && (
                <div className="w-full overflow-x-hidden">
                  <Text>"{description}"</Text>
                </div>
              )}
            </div>
          )}
        </>
      </>
    );
  }
}
