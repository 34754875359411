import { Image, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import styles from './MaintenanceTaskIconLabel.module.css';

const cx = classNames.bind(styles);

type MaintenanceTaskIconLabelProps = {
  maintenanceTaskImage: string;
  maintenanceTask?: string;
  maintenanceDate: string;
};

const MaintenanceTaskIconLabel = ({
  maintenanceTaskImage,
  maintenanceTask = '',
  maintenanceDate,
}: MaintenanceTaskIconLabelProps) => {
  return (
    <div className={cx('maintenanceTaskIconLabel')}>
      <div className={cx('maintenanceTaskImage', 'mr-sm')} style={{ minWidth: 50 }}>
        <Image alt="" className={styles.image} src={maintenanceTaskImage} />
      </div>
      <div className={cx('flex')}>
        <Text className="line-clamp-2" fontWeight="semibold">
          {maintenanceTask}
        </Text>
        <div className={cx('maintenanceTaskTime')}>
          <BDate time={maintenanceDate} formatType={DATE_TYPES.FULL_MONTH_DAY_YEAR} />
        </div>
      </div>
    </div>
  );
};

export default MaintenanceTaskIconLabel;
