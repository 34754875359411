import React, { useState } from 'react';
import { useIsClientReady } from '@belong/common';
import { Analytics } from 'analytics';
import classNames from 'classnames/bind';
import Grid, { gridPropsShape } from 'components/Grid/Grid';
import Logo from 'components/Logo/Logo';
import { Flex } from 'design-system';
import { Row } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import { useShowScrollBorder } from 'hooks/useScroll';
import PropTypes from 'prop-types';
import { THEME_CONSTS } from 'themes/themes';
import ButtonBase from '../../corecomponents/ButtonBase/ButtonBase';
import { getDocumentScrollTop, setDocumentScrollTop } from '../../utils/scrollTop';
import GeneralIcon, { GENERAL_ICONS, COLORS } from '../GeneralIcon/GeneralIcon';
import HeaderBanner, { BannerTypes } from './HeaderBanner/HeaderBanner';
import styles from './HeaderMain.module.css';

const cx = classNames.bind(styles);

export const MobileMenuContext = React.createContext({});

const HeaderMain = (props) => {
  const {
    theme,
    disableLogo,
    logoProps,
    borderOnScroll,
    scrollThreshold,
    mobileScrollThreshold,
    banner,
    transparent,
    navigationComponents,
    additionalNavigationComponents,
    customMobileMenu,
  } = props;

  const isClientReady = useIsClientReady();
  const [isMobileMenuOpen, openMobileMenu, hideMobileMenu, toggleMobileMenu] = useModal(false);
  // eslint-disable-next-line
  const [showScrollBorder, _, hasScrolled] = useShowScrollBorder({
    enable: borderOnScroll,
    threshold: scrollThreshold,
    mobileScroll: mobileScrollThreshold,
  });

  return (
    <MobileMenuContext.Provider value={{ isMobileMenuOpen, openMobileMenu, hideMobileMenu, toggleMobileMenu }}>
      {isClientReady ? (
        <HeaderMainWrapper
          {...props}
          // if border on scroll has been set and that we scrolled, we want the background to be white
          hasScrolled={borderOnScroll && hasScrolled}
          showScrollBorder={showScrollBorder}
          showMobileMenu={isMobileMenuOpen}
          theme={theme}
        >
          <nav className={cx('control-header')}>
            {/* Left header logo */}
            <HeaderLogo disableLink={disableLogo} isMobileMenuOpen={isMobileMenuOpen} theme={theme} {...logoProps} />
            {/* Right header links */}
            <HeaderNavigation
              navigationComponents={navigationComponents}
              transparent={transparent}
              showMobileMenu={isMobileMenuOpen}
              onShowMobileMenu={toggleMobileMenu}
              additionalNavigationComponents={additionalNavigationComponents}
              theme={theme}
              customMobileMenu={customMobileMenu}
            />
          </nav>
          {/* Mobile navigation menu */}
          {isMobileMenuOpen && (
            <Row>
              <ul className={cx('flex', { banner, belongX: true })}>{navigationComponents}</ul>
            </Row>
          )}
        </HeaderMainWrapper>
      ) : (
        <div />
      )}
    </MobileMenuContext.Provider>
  );
};

export const BANNER_POSITIONS = {
  ABOVE_HEADER: 'ABOVE_HEADER',
  BELOW_HEADER: 'BELOW_HEADER',
};

// Header building blocks
export const HeaderMainWrapper = ({
  hasborder,
  fixed,
  hasScrolled,
  showScrollBorder,
  hideBorderOnScrollDesktop,
  hideScrollBorderMobile,
  showMobileMenu,
  transparent,
  elevated,
  banner,
  bannerPosition,
  children,
  gridProps,
  theme,
}) => {
  const hideScrollBorderDesktop = !hasborder && showScrollBorder && hideBorderOnScrollDesktop;
  const getHeaderCustomBanner = () => {
    if (BannerTypes.LA === banner) {
      return { bannerSmall: !showMobileMenu };
    }
    return { banner: !showMobileMenu && banner };
  };

  return (
    <div
      className={cx(
        'header',
        { fixed },
        { hasScrolled },
        { grayborder: hasborder || showScrollBorder },
        { hideScrollBorderDesktop },
        { hideScrollBorderMobile }
      )}
    >
      <div
        className={cx(
          'header-content',
          getHeaderCustomBanner(),
          { transparent: transparent && !showMobileMenu },
          { elevated },
          { [theme]: !showMobileMenu } // Change to something?
        )}
      >
        <div className={cx('width-wrapper')}>
          {banner && bannerPosition === BANNER_POSITIONS.ABOVE_HEADER && (
            <HeaderBanner showMenu={showMobileMenu} type={banner} />
          )}
          <Grid {...gridProps}>{children}</Grid>
          {banner && bannerPosition === BANNER_POSITIONS.BELOW_HEADER && (
            <HeaderBanner showMenu={showMobileMenu} type={banner} />
          )}
        </div>
      </div>
      <div className={cx('fill')} />
    </div>
  );
};

export const HeaderNavigation = ({
  transparent,
  navigationComponents,
  showMobileMenu,
  onShowMobileMenu,
  theme,
  additionalNavigationComponents,
  customMobileMenu,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const showNavigationMobile = () => {
    const scrollTop = getDocumentScrollTop();

    if (!showMobileMenu) {
      setScrollPosition(scrollTop);
      document.body.style.cssText += `;top: -${scrollTop}px; position: fixed;`;
    } else {
      document.body.style.position = 'static';
      setDocumentScrollTop(scrollPosition);
    }

    onShowMobileMenu();
  };

  // Allow passing arrays or React elements.
  const validNavigationComponent = navigationComponents.length > 0 || React.isValidElement(navigationComponents);

  return (
    <div className={cx('navigation-links-wrapper', theme)}>
      <ul className={cx('showondesktop')}>
        {navigationComponents}
        {additionalNavigationComponents && <div className={cx('additional')}>{additionalNavigationComponents()}</div>}
      </ul>

      {showMobileMenu ? (
        <ul className={cx('showonmobile', { transparent })}>
          <>
            <ButtonBase onClick={showNavigationMobile}>
              <GeneralIcon icon={GENERAL_ICONS.CLOSE} />
            </ButtonBase>
          </>
        </ul>
      ) : (
        <ul className={cx('showonmobile', { transparent }, { hideborder: !validNavigationComponent })}>
          <>
            {additionalNavigationComponents && additionalNavigationComponents()}
            {validNavigationComponent &&
              (customMobileMenu || (
                <ButtonBase onClick={showNavigationMobile}>
                  <GeneralIcon
                    icon={GENERAL_ICONS.MENU}
                    color={theme === THEME_CONSTS.DARK ? COLORS.WHITE : COLORS.DEFAULT}
                  />
                </ButtonBase>
              ))}
          </>
        </ul>
      )}
    </div>
  );
};

export const HeaderLogo = ({ disableLink, theme, extra, isMobileMenuOpen }) => {
  return (
    <Flex alignItems="center">
      <Logo disableLogo={disableLink} theme={!isMobileMenuOpen && theme} />
      {extra}
    </Flex>
  );
};

HeaderMainWrapper.propTypes = {
  fixed: PropTypes.bool,
  hasborder: PropTypes.bool,
  hasScrolled: PropTypes.bool,
  hideBorderOnScrollDesktop: PropTypes.bool,
  hideScrollBorderMobile: PropTypes.bool,
  transparent: PropTypes.bool,
  elevated: PropTypes.bool,
  banner: PropTypes.string,
  bannerPosition: PropTypes.string,
  showScrollBorder: PropTypes.bool.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
  gridProps: PropTypes.shape(gridPropsShape),
  theme: PropTypes.string,
};

HeaderMainWrapper.defaultProps = {
  fixed: false,
  hasborder: false,
  hideBorderOnScrollDesktop: false,
  hideScrollBorderMobile: false,
  transparent: false,
  elevated: false,
  banner: null,
  bannerPosition: BANNER_POSITIONS.ABOVE_HEADER,
  fluid: false,
  gridProps: {},
  theme: THEME_CONSTS.DEFAULT,
};

HeaderLogo.propTypes = {
  disableLink: PropTypes.bool,
  isMobileMenuOpen: PropTypes.bool,
  theme: PropTypes.string,
  extra: PropTypes.node,
};

HeaderLogo.defaultProps = {
  disableLink: false,
  isMobileMenuOpen: false,
  theme: THEME_CONSTS.DEFAULT,
};

HeaderNavigation.propTypes = {
  navigationComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
  transparent: PropTypes.bool.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  onShowMobileMenu: PropTypes.func.isRequired,
  additionalNavigationComponents: PropTypes.func,
  theme: PropTypes.string,
  customMobileMenu: PropTypes.node,
};

HeaderNavigation.defaultProps = {
  additionalNavigationComponents: null,
  theme: THEME_CONSTS.DEFAULT,
};

HeaderMain.propTypes = {
  theme: PropTypes.string,
  navigationComponents: PropTypes.arrayOf(PropTypes.node),
  fixed: PropTypes.bool,
  hasborder: PropTypes.bool,
  borderOnScroll: PropTypes.bool,
  hideBorderOnScrollDesktop: PropTypes.bool,
  disableLogo: PropTypes.bool,
  transparent: PropTypes.bool,
  mobileScrollThreshold: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  scrollThreshold: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  banner: PropTypes.string,
  additionalNavigationComponents: PropTypes.func,
  logoProps: PropTypes.object,
  extra: PropTypes.node,
  customMobileMenu: PropTypes.node,
};

HeaderMain.defaultProps = {
  theme: THEME_CONSTS.DEFAULT,
  fixed: false,
  hasborder: false,
  disableLogo: false,
  hideBorderOnScrollDesktop: false,
  navigationComponents: null,
  borderOnScroll: true,
  transparent: false,
  banner: null,
  additionalNavigationComponents: null,
};

export default Analytics(() => ({
  page_component: 'header',
}))(HeaderMain);
