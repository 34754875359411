import React from 'react';
import classNames from 'classnames/bind';
import Collapsible from 'components/Collapsible/Collapsible';
import Money, { formatMoney } from 'components/Money/Money';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import styles from 'containercomponents/MaintenanceStatus/ModalMaintenanceBalance/ModalMaintenanceBalance.module.css';
import { Box } from 'design-system';
import { MaintenanceRequester, BalanceType } from 'models/enums';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const MaintenanceBalance = ({ task, isRenter }) => {
  const maintenancePayment = task.payments.find(
    (p) => p.paidBy === (isRenter ? MaintenanceRequester.Resident : MaintenanceRequester.Homeowner)
  );

  if (!maintenancePayment || !maintenancePayment.balances || !maintenancePayment.balances.length === 0) return null;

  const { balances } = maintenancePayment;

  const paidBalances = balances.filter((b) => b.processedAmount > 0);

  if (!paidBalances || paidBalances.length === 0) return null;
  return (
    <Collapsible
      collapsed={false}
      title="Payments"
      inner={
        <Box mt="sm">
          {paidBalances.map((balance, idx) => {
            const isBelongPaid = balance.balanceType === BalanceType.Payable;
            const paidAmount = `${isBelongPaid ? '-' : ''}${formatMoney(balance.processedAmount)}`;

            return (
              <NameValueTable
                key={idx}
                discountValue={isBelongPaid}
                boldValue={isBelongPaid}
                items={[
                  {
                    name: balance.description,
                    value: paidAmount,
                  },
                ]}
              />
            );
          })}
          <div className={cx('underline')} />
          <NameValueTable
            items={[
              {
                name: 'Total Paid',
                value: <Money cash={maintenancePayment.balancePaidAmount} />,
              },
            ]}
          />
          <NameValueTable
            items={[
              {
                name: 'Remaining Balance',
                value: <Money cash={maintenancePayment.balanceRemainingAmount} />,
              },
            ]}
          />
        </Box>
      }
    />
  );
};

MaintenanceBalance.propTypes = {
  task: PropTypes.object.isRequired,
  isRenter: PropTypes.bool.isRequired,
};

export { MaintenanceBalance };
