export const getFormattedAddress = (address) =>
  `${address.streetAddress}${address.unitNumber ? ` Unit ${address.unitNumber}` : ''},
  <span>${address.city}, ${address.state} ${address.zipcode}</span>`;

export const getRecurrenceActivatedByUser = (recurrence) => {
  if (recurrence.maintenanceRecurrence.requestedBy === 'Homeowner') {
    return recurrence.homeowner;
  } else if (recurrence.maintenanceRecurrence.requestedBy === 'Resident') {
    return recurrence.resident;
  }
};

export const getRecurrenceAuthorizationNeeded = (recurrenceType) => {
  return recurrenceType === 'Cleaning' || recurrenceType === 'MaintenancePackage' || recurrenceType === 'PestControl';
};
