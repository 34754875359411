import React, { Component } from 'react';
import Measure from 'react-measure';
import { ModalViewTracker, Analytics, ANALYTICS_PROPERTIES } from 'analytics';
import classNames from 'classnames/bind';
import { BREAKPOINTS } from 'consts/breakpoints';
import { Modal as BootstrapModal } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { getDocumentScrollTop, setDocumentScrollTop } from '../../utils/scrollTop';
import styles from './ModalBase.module.css';

const cx = classNames.bind(styles);

const overflowThreshold = 120;

class ModalBase extends Component {
  static propTypes = {
    children: PropTypes.node,
    backdrop: PropTypes.oneOf(['static', true, false]),
    backdropClassName: PropTypes.string,
    keyboard: PropTypes.bool,
    animation: PropTypes.bool,
    autoFocus: PropTypes.bool,
    enforceFocus: PropTypes.bool,
    restoreFocus: PropTypes.bool,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onEnter: PropTypes.func,
    onEntering: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExiting: PropTypes.func,
    onExited: PropTypes.func,
    className: PropTypes.string,
    dialogClassName: PropTypes.string,
    fullPage: PropTypes.bool,
    mobileTopImportant: PropTypes.bool,
    setTopBreakpoint: PropTypes.oneOf(Object.values(BREAKPOINTS)),
    ignoreMarginOnTablet: PropTypes.bool,
    ignoreModalExitAndEnterFunctions: PropTypes.bool,
    Shadow: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    children: '',
    dialogClassName: '',
    fullPage: false,
    Shadow: null,
    show: false,
    // TODO: replace all mobileTopImportant with setTopBreakpoint and then find a way
    // to not even need the prop.
    mobileTopImportant: true,
    setTopBreakpoint: null,
    ignoreMarginOnTablet: false,
    ignoreModalExitAndEnterFunctions: false,
    backdropClassName: '',
  };

  state = {
    modalHeight: 0,
  };

  componentWillUnmount() {
    this.resetScrollPosition();
  }

  handleEnter = () => {
    if (!this.props.ignoreModalExitAndEnterFunctions) {
      this.scrollPosition = getDocumentScrollTop();
      document.body.style.cssText += `;top: -${this.scrollPosition}px; position: fixed;`;
    }
  };

  handleExit = () => {
    this.resetScrollPosition();
  };

  resetScrollPosition = () => {
    if (!this.props.ignoreModalExitAndEnterFunctions) {
      document.body.style.position = 'static';

      if (this.scrollPosition) {
        setDocumentScrollTop(this.scrollPosition);
        this.scrollPosition = 0;
      }
    }
  };

  render() {
    const {
      children,
      className,
      dialogClassName,
      onHide,
      show,
      fullPage,
      mobileTopImportant,
      setTopBreakpoint,
      ignoreMarginOnTablet,
      Shadow,
      onExit,
      onEnter,
      backdrop,
      backdropClassName,
      keyboard,
      animation,
      autoFocus,
      enforceFocus,
      restoreFocus,
      onEntering,
      onEntered,
      onExiting,
      onExited,
    } = this.props;
    const { modalHeight } = this.state;

    return (
      <BootstrapModal
        onHide={onHide}
        show={show}
        className={cx('modal', className, { 'full-page': fullPage })}
        dialogClassName={dialogClassName}
        backdropClassName={backdropClassName}
        backdrop={backdrop || !fullPage}
        onEnter={onEnter || this.handleEnter}
        onExit={onExit || this.handleExit}
        keyboard={keyboard}
        animation={animation}
        autoFocus={autoFocus}
        enforceFocus={enforceFocus}
        restoreFocus={restoreFocus}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        <>
          {show && !!Shadow && Shadow}
          <BootstrapModal.Body>
            {fullPage ? (
              <div className={cx('modal-base', { 'full-page': fullPage })}>{children}</div>
            ) : (
              <Measure
                bounds
                onResize={({ bounds }) => {
                  this.setState({ modalHeight: bounds.height });
                }}
              >
                {({ measureRef }) => {
                  const windowHeight = window.innerHeight;
                  let overflow = false;

                  if (modalHeight >= windowHeight - overflowThreshold) {
                    overflow = true;
                  }
                  const style = overflow ? {} : { top: (windowHeight - modalHeight) / 2 };
                  return (
                    <div
                      ref={measureRef}
                      className={cx(
                        'modal-base',
                        { mobileTopImportant },
                        { [`setToTop-${setTopBreakpoint}`]: setTopBreakpoint },
                        { overflow },
                        { ignoreMarginOnTablet },
                        { 'full-page': fullPage }
                      )}
                      style={style}
                    >
                      {children}
                    </div>
                  );
                }}
              </Measure>
            )}
          </BootstrapModal.Body>
        </>
      </BootstrapModal>
    );
  }
}

export default Analytics((props) => ({
  [ANALYTICS_PROPERTIES.modal_name]: props.name || 'unknown',
  [ANALYTICS_PROPERTIES.screen]: props.screen || 'unknown',
}))(ModalViewTracker((props) => props.show && !props.untracked)(ModalBase));
