import { HOME_LISTING_STRINGS } from './strings/home-listing.strings';

export const BANNER_IMAGE_OPTIONS = [
  { label: 'Yes, let’s upload.', key: false },
  { label: 'Not right now.', key: true },
];

export const LEASE_DURATION_OPTIONS = [
  {
    label: HOME_LISTING_STRINGS['how_long.1_year'],
    key: 12,
  },
  {
    label: HOME_LISTING_STRINGS['how_long.2_years'],
    key: 24,
  },
  {
    label: HOME_LISTING_STRINGS['how_long.3_years'],
    key: 36,
    tag: 'Most Popular',
  },
  {
    label: HOME_LISTING_STRINGS['how_long.custom'],
    key: 'custom',
  },
];

export const HOME_EXAMPLES_AVAILABLE_MEDIA = [
  'https://belong-web-assets.s3.us-west-2.amazonaws.com/image_assets/home_examples/home_photo_example_01.jpg',
  'https://belong-web-assets.s3.us-west-2.amazonaws.com/image_assets/home_examples/home_photo_example_02.jpg',
  'https://belong-web-assets.s3.us-west-2.amazonaws.com/image_assets/home_examples/home_photo_example_03.jpg',
  'https://belong-web-assets.s3.us-west-2.amazonaws.com/image_assets/home_examples/home_photo_example_04.jpg',
];
