import { Money, Text } from '@belong/ui';
import { STRINGS } from 'maintenance/strings';

type Props = {
  price: string;
};

export function RecurringProServicePrice({ price }: Props) {
  return (
    <div className="flex flex-col items-start">
      <Text as="span" className="text-white sm:text-navy" fontWeight="semibold" variant="p1">
        {STRINGS['starting-from']}
      </Text>
      <div className="flex items-center justify-start">
        <Money as="span" className="text-green" fontWeight="semibold" format="DOLLARS_NO_CENTS" value={price} />
        &nbsp;
        <Text className="text-green" fontWeight="semibold">
          {STRINGS['per-visit']}
        </Text>
      </div>
    </div>
  );
}
