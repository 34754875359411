import { Text } from "@belong/ui";
import { LazyLottieAnimation } from "animations/LazyLottieAnimation/LazyLottieAnimation";
import GreenInfoBox from "components/GreenInfoBox/GreenInfoBox";
import { USER_PROFILE_STRINGS } from 'accounts/constants/strings/user-profile.strings';

type UserProfileBannerStepProps = {
  isAdoptedResident: boolean;
}

const lottieAnimationStyles = { width: '50px', height: '50px' };
const lottieAnimationOptions = { loop: true };

export function UserProfileBannerStep({ isAdoptedResident }: UserProfileBannerStepProps) {
  return (
    <GreenInfoBox>
      <div className="flex">
        <div className="mr-sm">
          <LazyLottieAnimation name="lock_unlock" options={lottieAnimationOptions} style={lottieAnimationStyles} />
        </div>
        <div className="flex flex-col justify-center">
          {isAdoptedResident ? (
            <Text as="span" fontWeight="semibold">
              {USER_PROFILE_STRINGS["banner_step.adopted_resident"]}
            </Text>
          ) : (
            <div>
              <Text as="span" fontWeight="semibold">
                {USER_PROFILE_STRINGS["banner_step.not_adopted_resident"]}
              </Text>
              <Text as="span" fontWeight="semibold" className="text-green">
                {USER_PROFILE_STRINGS["banner_step.not_adopted_resident.unlocked"]}
              </Text>
            </div>
          )}
          <Text variant="p1" fontWeight="semibold">
            {USER_PROFILE_STRINGS["banner_step.next_up"]}
          </Text>
        </div>
      </div>
    </GreenInfoBox>
  )
}
