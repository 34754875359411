export const USER_TAXES_STRINGS = {
  title: `<span style="font-family:'Caveat'; font-size: 4.2rem;">“I love doing taxes!”</span> <span>- No one ever</span>`,
  individual_title: 'For tax purposes, we need your <span>Tax ID Number.</span>',
  business_title: 'Talk to us about {businessName}...',
  full_ssn_itin: 'Full SSN (Or ITIN)',
  ein: 'EIN',
  business_entity_type: 'Entity Type',
  llc_tax_classification: 'LLC Tax Classification',
  business_as: 'Doing Business As (Optional)',
  verification: 'Lastly, for payment verification purpose, please provide us with your social security number.',
};
