export const RENTER_ACCOUNTS_STRINGS = {
  maintenance: {
    title: 'Pro Services',
    subtitle:
      "Something breaks? We'll fix it. Revolutionary. Request a fix or upgrade,\u00a0and a vendor will get right on it like paint\u00a0on\u00a0primer.",
    completed: 'Hide completed',
    icon_buttons: {
      more_button: 'VIEW MORE',
      add_task: 'BOOK A PRO',
      add_upgrade: 'REQUEST AN UPGRADE',
    },
    tasks_section_header: 'All Requests & Services',
    dropdown_tasks: {
      label: 'All Requests',
    },
    createRequest: 'Request A Pro Service',
    belongProServices: 'Belong Pro Services',
    poweredBySms: 'Powered by SMS Assist',
    smsBody:
      'As a Belong Member, you will have access to the SMS Assist platform where you will be able to submit and manage all maintenance requests. With 10,000+\u00a0maintenance pros in our network, we ensure all repairs are completed quickly and done right the first time.',
  },
};
