export const STRINGS = {
  'initial-pricing.title': 'Pricing Estimate',
  'initial-pricing.based':
    'Based on the {cityname} market and what we know about your home so far, we conducted an initial analysis.',
  'initial-pricing.estimated': 'Estimated Rental Price',
  'initial-pricing.cta': 'Next',
  'initial-pricing.important-title': 'Important Things to Know',
  'initial-pricing.important-one':
    'We’ll send a final price once we’ve seen your home and we’re closer to listing. That will incorporate the condition of your home. If you have done recent renovations or have features like solar panels, this final price will likely go up.',
  'initial-pricing.important-two':
    'This estimate is based on the market today. If you’ve heard about rental prices in your neighborhood from a few months ago, they could be out of date.',
  'initial-pricing.difference-higher':
    'This price is a bit higher than our initial estimate. Based on a fresh analysis of comps, we think you can command a bit more in the market.',
  'initial-pricing.difference-lower':
    'This price is a bit lower than our initial estimate. Based on a fresh analysis of comps, we think this reduced starting price will be necessary to see good traction.',
  'comparables.title': 'Current Comparable Homes for Rent',
  'comparables.address': '3801 Marquette Place, San Diego, CA 92106',
  'comparables.description': 'We reviewed rental comparables to determine the range for your home.',
  'comparables.tip-title': 'Pro Tip',
  'comparables.tip-description':
    'All of the homes still on the market are not rented yet for a reason, which usually has to do with a high price. To understand the ability for homes to rent at that price, pay attention to the Days Listed column. For instance, homes listed for 20+ days will have a more difficult time renting at their original price.',
  'comparables.placeholder': 'Any feedback for the team?',
  'comparables.feedback-cta': 'Save feedback',
  'comparables.not-qualified-cta': 'Save & Get Started',
  'comparables.not-signed-cta': 'Save & View Agreement',
  'comparables.signed-cta': 'Save & Close',
};
