import PropTypes from 'prop-types';

import DocumentSignModal from 'containercomponents/Modals/DocumentSignModal/DocumentSignModal';
import { formatString } from 'strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { HOME_DISCLOSURES_STRINGS } from 'accounts/constants/strings/home-disclosures.strings';

export function HomeDisclosuresDocumentation({
  showModal,
  onHide,
}) {
  const { currentStepFormData, loading, handleSaveAndNext } = useSetupFlowStep();

  if (!currentStepFormData || !currentStepFormData.document) {
    return null;
  }

  const { document } = currentStepFormData;

  function onSign() {
    document.isSigned = true;

    handleSaveAndNext({ document });
  }

  return (
    <DocumentSignModal
      show={showModal}
      onSign={onSign}
      onHide={onHide}
      title={formatString(HOME_DISCLOSURES_STRINGS['disclosures_documentation.title'])}
      subTitle={formatString(HOME_DISCLOSURES_STRINGS['disclosures_documentation.subtitle'])}
      loading={loading}
      file={document.documentUrl}
    />
  );
}

HomeDisclosuresDocumentation.propTypes = {
  showModal: PropTypes.any.isRequired,
  onHide: PropTypes.any.isRequired,
};

