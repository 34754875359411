import React, { useMemo } from 'react';
import DottedMenu from 'components/DottedMenu/DottedMenu';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import PaymentMethodDisplay from 'components/Payments/PaymentMethodDisplay/PaymentMethodDisplay';
import { PaymentPreference } from 'models/enums';

export const PaymentMethodPrimarySelectionContainer = ({
  onSelectPaymentMethod,
  paymentType,
  selectedPaymentMethod,
  paymentMethods,
  onAddPaymentClick,
  forcePaymentMethod,
  ...rest
}) => {
  const menuItems = useMemo(
    () =>
      paymentMethods
        .filter((paymentMethod) => {
          if (forcePaymentMethod) {
            return paymentMethod.paymentMethod === forcePaymentMethod;
          }

          return true;
        })
        .map((paymentMethod) => {
          const isCurrentPaymentMethod = paymentMethod.accountId === selectedPaymentMethod?.accountId;

          return {
            name: (
              <div className="flex align-center justify-between">
                <PaymentMethodDisplay
                  hasFee={!(paymentType === PaymentPreference.Payout)}
                  paymentMethod={paymentMethod}
                  paymentMethodType={paymentMethod.getAccountType()}
                />
                {isCurrentPaymentMethod && <GeneralIcon icon={GENERAL_ICONS.CHECKMARK} color={COLORS.GREEN} />}
              </div>
            ),
            paymentMethod,
            key: paymentMethod.accountId,
          };
        }),
    [paymentMethods, paymentType, selectedPaymentMethod, forcePaymentMethod]
  );

  const handleMenuActionClick = (key) => {
    if (key === 'ADD_PAYMENT') {
      onAddPaymentClick();
    }
  };

  return (
    <div className="mt-sm">
      <p className="text-h3-xl font-semibold mb-2sm">Payment Method</p>
      <DottedMenu
        fitToButton
        menuActions={menuActions}
        onMenuActionClick={handleMenuActionClick}
        menuItems={menuItems}
        onMenuClick={onSelectPaymentMethod}
        menuButtonLabel={
          <div className="flex align-center justify-between">
            <PaymentMethodDisplay
              hasFee={!(paymentType === PaymentPreference.Payout)}
              paymentMethod={selectedPaymentMethod}
              paymentMethodType={selectedPaymentMethod.getAccountType()}
            />
            <p className="font-semibold">CHANGE</p>
          </div>
        }
        {...rest}
      />
    </div>
  );
};

const menuActions = [
  {
    name: 'ADD PAYMENT METHOD',
    key: 'ADD_PAYMENT',
    icon: <GeneralIcon icon={GENERAL_ICONS.ADD_CARD.DEFAULT} />,
    iconSelected: <GeneralIcon icon={GENERAL_ICONS.ADD_CARD.INVERSE} />,
  },
];
