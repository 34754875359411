import { getApiActionTypes } from '../redux_utils';

// currenty only used to ... Please delete this note if it gets extended beyond this use.

export const IMAGE_CONSTS = {
  UPLOAD_FILES: 'UPLOAD_FILES',
};

export const ACTIONS = {
  ...getApiActionTypes(IMAGE_CONSTS.UPLOAD_FILES),
};
