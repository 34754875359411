import { ACTIONS } from './constants';

export const MAINTENANCE_REDUCER = 'maintenance';

const initialState = {
  homeTasks: [],
  rentTasks: [],
  currentTask: {},
  improvementReport: {},
  recurringTasks: [],
  taskById: {},
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_HOMEOWNER_MAINTENANCE_SUCCESS:
      return {
        ...state,
        homeTasks: [...action.result],
      };
    case ACTIONS.TASK_BY_ID_CLEAR:
      return {
        ...state,
        taskById: {},
      };
    case ACTIONS.FETCH_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        taskById: {
          ...state.taskById,
          [action.result.uniqueId]: action.result,
        },
      };
    case ACTIONS.FETCH_RECURRING_TASKS_SUCCESS:
      return {
        ...state,
        recurringTasks: [...action.result],
      };
    case ACTIONS.FETCH_HO_TASK_BY_TASK_ID_SUCCESS:
      return {
        ...state,
        currentTask: action.result,
      };
    case ACTIONS.FETCH_RESIDENT_MAINTENANCE_SUCCESS:
      return {
        ...state,
        rentTasks: [...action.result],
      };
    case ACTIONS.FETCH_TASK_BY_RENTER_ID_SUCCESS:
      return {
        ...state,
        currentTask: action.result,
      };
    case ACTIONS.FETCH_IMPROVEMENTS_REPORT_BY_TASK_ID_SUCCESS:
      return {
        ...state,
        improvementsReport: action.result,
      };
    case ACTIONS.CURRENT_TASK_CLEAR:
      return {
        ...state,
        currentTask: {},
      };
    default:
      return state;
  }
}
