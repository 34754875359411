import { useState } from 'react';
import { HomeAccountListingDetailsModel, MaintenanceModel, ReportDetailModel } from 'api/models';
import classNames from 'classnames/bind';
import Space from 'corecomponents/Space/Space';
import { Box, Text } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import { find, flatten } from 'lodash-es';
import {
  ConsentStatus,
  FeatureOwner,
  MaintenanceRequester,
  MaintenanceResponsibility,
  MaintenanceStatusDisplay,
} from 'models/enums';
import ImprovementCard from 'pages/PostInspectionFlow/ImprovementCard/ImprovementCard';
import ImprovementModal from 'pages/PostInspectionFlow/ImprovementModal/ImprovementModal';
import { SharedResponsibilityLabel, ResidentResponsibilityLabel } from 'pages/PostInspectionFlow/Labels/Labels';
import { getPayments } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import styles from './GroupImprovementCards.module.css';

const cx = classNames.bind(styles);

type GroupImprovementCardsProps = {
  report?: ReportDetailModel;
  groupTask: MaintenanceModel;
  listingsData: HomeAccountListingDetailsModel[];
  hasAppointments: boolean;
  isRenter: boolean;
};

export const GroupImprovementCards = ({
  report,
  groupTask,
  listingsData,
  hasAppointments,
  isRenter,
}: GroupImprovementCardsProps) => {
  const [showAll, setShowAll] = useState(false);
  const [currentImprovement, setCurrentImprovement] = useState(null);
  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const isHomeowner = !isRenter;

  const listing = find(
    listingsData,
    (listingDetail) => listingDetail.homeListingDetailsModel.basicInfo.unitId === report?.report?.homeId
  );

  let bundles;
  if (isRenter) {
    bundles =
      groupTask?.subtasks.filter((x) => {
        const breakdown = getPayments(x.items[0].maintenancePayments);
        return breakdown.resident?.percentage > 0;
      }) || [];
  } else {
    bundles = report.details?.bundles || [];
  }
  const { spaces = [] } = listing?.homeListingDetailsModel || {};
  const flatBundles = flatten(
    bundles.reduce((acc, bundle) => {
      acc.push(bundle.items);
      return acc;
    }, [])
  );

  const isInvoiced =
    !!groupTask?.requestTypeDetails?.hasPublishedInvoices ||
    !!report.details?.group?.requestTypeDetails?.hasPublishedInvoices;

  const groupPayment = groupTask?.payments || [];

  const improvementsGroupedByRoomOrSpace = flatBundles.reduce((acc, item) => {
    const { maintenance, ...rest } = item;
    const improvement = { ...maintenance, ...rest };
    let roomOrSpace;
    if (improvement?.ownerType === FeatureOwner.Space) {
      roomOrSpace = spaces.find((space) => space.id === improvement.ownerId);
    }

    const { type } = roomOrSpace || { type: 'Around The Home' };
    if (acc[type]) {
      acc[type].push(improvement);
    } else {
      acc[type] = [improvement];
    }

    return acc;
  }, {});

  const sortedGroupedImprovements = Object.entries(improvementsGroupedByRoomOrSpace).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  });

  let flattenedItems = [];
  sortedGroupedImprovements.forEach(([key, roomOrSpace]) => {
    roomOrSpace.forEach((r) => {
      r.key = key;
    });
    flattenedItems = [...flattenedItems, ...roomOrSpace];
  });

  if (isInvoiced) {
    // show only items approved by homeowner / resident
    flattenedItems = flattenedItems.filter((x) => {
      const homeownerPayment = x.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Homeowner);
      const residentPayment = x.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Resident);
      return (
        homeownerPayment?.consentStatus === ConsentStatus.Approved ||
        residentPayment?.consentStatus === ConsentStatus.Approved
      );
    });
  }

  const filteredItems = showAll ? flattenedItems : flattenedItems.slice(0, 4);
  const hasMore = Math.max(flattenedItems.length - filteredItems.length, 0);

  const renderStatusTag = (maintenance, defaultTagType) => {
    const homeownerPayment = maintenance.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Homeowner);
    let status;
    let tag = '';
    if (homeownerPayment?.consentStatus === ConsentStatus.Rejected) {
      status = 'Not Approved';
      tag = 'rejected';
    } else if (maintenance.proServiceResponsibility === MaintenanceResponsibility.Homeowner) {
      if (maintenance.status !== 'Closed') {
        status = 'Closed';
        tag = '';
      } else {
        status = 'Verification Required';
        tag = 'navy';
      }
    } else if (hasAppointments) {
      status = maintenance.status === 'Closed' ? MaintenanceStatusDisplay.Closed : MaintenanceStatusDisplay.InProgress;
      tag = maintenance.status !== 'Closed' ? defaultTagType || 'progress' : '';
    } else {
      status = MaintenanceStatusDisplay[maintenance.status];
      tag = maintenance.status !== 'Closed' ? defaultTagType || 'progress' : '';
    }

    return <div className={cx('top-left-tag', tag)}>{status}</div>;
  };

  const renderMaintenanceTag = (maintenance) => {
    const homeownerPayment = maintenance.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Homeowner);
    const residentPayment = maintenance.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Resident);
    const sharedResponsiblity = residentPayment?.percentage > 0 && homeownerPayment?.percentage > 0;
    const residentResponsiblity = residentPayment?.percentage === 100;

    return (
      <>
        {renderStatusTag(maintenance)}
        {sharedResponsiblity && <SharedResponsibilityLabel isHomeowner={isHomeowner} />}
        {isHomeowner && residentResponsiblity && <ResidentResponsibilityLabel isHomeowner={isHomeowner} />}
        <Box marginTop="2xs">
          <Text className={cx('image-text')} fontWeight="semibold">
            {maintenance.summary}
          </Text>
        </Box>
      </>
    );
  };
  return (
    <>
      <Text className="mt-xl" as="p" fontWeight="semibold" fontSize="h3">
        Related Issues
      </Text>
      <Row>
        {filteredItems.map(
          (
            {
              estimatedCostBreakdown,
              estimatedDisplayCost,
              estimatedDisplayDiscount,
              estimatedDisplayDiscountedCost,
              estimatedDisplayCostForAllParties,
              actualCostBreakdown,
              actualDisplayCost,
              actualDisplayDiscount,
              actualDisplayDiscountedCost,
              actualDisplayCostForAllParties,
              isTBD,
              ...item
            },
            i
          ) => {
            const fullImprovement = {
              maintenance: { ...item },
              maintenancePayments: item.payments,
              roomOrSpace: item.key,
              estimatedCostBreakdown,
              estimatedDisplayCost,
              estimatedDisplayDiscount,
              estimatedDisplayDiscountedCost,
              estimatedDisplayCostForAllParties,
              actualCostBreakdown,
              actualDisplayCost,
              actualDisplayDiscount,
              actualDisplayDiscountedCost,
              actualDisplayCostForAllParties,
              isTBD,
            };

            return (
              <Col key={i} sm={6} style={{ marginTop: 10, marginBottom: 10 }}>
                <ImprovementCard
                  height={180}
                  onClick={() => {
                    setModalOpen();
                    setCurrentImprovement(fullImprovement);
                  }}
                  customTopLeft={renderMaintenanceTag}
                  improvement={fullImprovement}
                  context="pro-service"
                />
              </Col>
            );
          }
        )}
      </Row>
      {!!hasMore && (
        <div onClick={() => setShowAll(true)} className={cx('load-more')}>
          + {hasMore} MORE
        </div>
      )}
      {!hasMore && filteredItems.length > 4 && (
        <div onClick={() => setShowAll(false)} className={cx('load-more')}>
          SHOW LESS
        </div>
      )}
      <Space />
      {currentImprovement && (
        <ImprovementModal
          show={isModalOpen}
          onHide={setModalClose}
          improvement={currentImprovement}
          isHomeowner={isHomeowner}
          groupPayment={groupPayment}
          isInvoiced={isInvoiced}
          extraTags={[renderStatusTag(currentImprovement.maintenance, 'navy')]}
          showPriceBreakdown
        />
      )}
    </>
  );
};
