import { useState } from 'react';
import classNames from 'classnames/bind';
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import styles from './lease-renderer.module.css';

const cx = classNames.bind(styles);

// todo: move this to constants
const dataKeyMap = {
  'rent-increase': {
    description: [
      'Planning to call this home for the foreseeable future? We’d love for you to renew! We’ll check in with you to see what your plans are for renewal.',
    ],
  },
  'rent-due': {
    description: [
      'Paying rent should never be a hassle. With Belong, you can say goodbye to checks and forgotten payments. Simply connect your bank account or credit card, and we’ll take care of the rest, seamlessly deducing rent on the 1st of the month automatically.',
    ],
  },
  'pay-by-check': {
    description: [
      'We love returning deposits to our Residents in full. At the same time, we want you to feel at home, and that means living life and adding your personal touch to make it feel homey. Don’t sweat it. Upon move-out, our Pro team will come in to inspect, and take care of any normal wear and tear. For anything beyond that (we know, things happen), we may need to deduct from the deposit, but we’ll be sure to let you know first.',
    ],
  },
  'security-deposit-trust-account': {
    description: [
      'We love returning deposits to our Residents in full. At the same time, we want you to feel at home, and that means living life and adding your personal touch to make it feel homey. Don’t sweat it. Upon move-out, our Pro team will come in to inspect, and take care of any normal wear and tear. For anything beyond that (we know, things happen), we may need to deduct from the deposit, but we’ll be sure to let you know first.',
    ],
  },
  'move-in-condition': {
    description: [
      'Our in-house team of Belong Pros takes meticulous care to ensure that your home is in tip-top shape when you move in. Your Member Success Team will also be here for you 24/7 in case anything breaks along the way. Just let us know if you need anything! In case we miss anything, we will proactively schedule a Pro visit 7 days after move-in to make sure everything is perfect.',
    ],
  },
  'maintenance-use-and-reporting': {
    description: [
      'We don’t sweat the small stuff like normal wear and tear. Your Belong home is meant to be lived in.',
    ],
  },
  'hoa-requirements': {
    description: [
      'Your homeowner will take care of all ordinary monthly HOA dues. Any additional services will be outlined in the HOA rules in the addendums tab.',
    ],
  },
  keys: {
    description: [
      'We typically provide each adult on the lease a full set of keys. We typically are able to accommodate additional key requests as a service to you (barring any HOA or other restrictions).',
    ],
  },
  'early-termination': {
    description: [
      'As a Belong member, you have 30 days to fall in love, so that you can move into your new home with confidence.',
    ],
  },
  'renters-insurance': {
    description: [
      "For your convenience, we'll provide you with liability coverage that fulfills this requirement, protecting you against liability for damage to the home and loss of personal belongings. If you would like additional coverage, you're also welcome to get your own renter's insurance policy. Each adult Resident living in a Belong home needs coverage (either on one policy or multiple).",
    ],
  },
};

type Props = {
  agreement: string;
  openTipModal: () => void;
  setTip: (tip: string) => void;
};

export const LeaseRenderer = ({ agreement, openTipModal, setTip }: Props) => {
  const [latestTip, setLatestTip] = useState(null);
  const { width } = useWindowSize();

  const handleClickListener = (event) => {
    setTip(dataKeyMap[event.target.getAttribute('data-key')]);

    if (width < BREAKPOINTS_WIDTHS.XL) {
      openTipModal();
    }
  };

  const handleIntersection = (entries) => {
    const intersectingEntries = entries.filter((e) => e.isIntersecting);

    if (intersectingEntries?.length) {
      const intersectingEntryToConsider = intersectingEntries[intersectingEntries.length - 1];
      const currentTip = dataKeyMap[intersectingEntryToConsider.target.getAttribute('data-key')];

      if (latestTip !== currentTip) {
        setLatestTip(currentTip);
        setTip(currentTip);
      }
    }
  };

  return (
    <HTMLRenderer
      className={cx('agreement')}
      html={agreement}
      onClickListener={handleClickListener}
      onIntersection={handleIntersection}
      querySelector=".tip"
    />
  );
};
