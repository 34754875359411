import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import ReactLottie from 'forkedlibraries/ReactLottie/ReactLottie';
import PropTypes from 'prop-types';
import styles from './FavoriteHeart.module.css';
import { favoriteHeart } from './favorite_icon_animation_01';
import { unFavoriteHeart } from './favorite_icon_animation_02';

const cx = classNames.bind(styles);

const HEART_ANIMATION = {
  HEART_FAVORITE: favoriteHeart,
  HEART_UNFAVORITE: unFavoriteHeart,
};

const FavoriteHeart = ({ value, height, width, showLabel, setThrottleHandleClick, ...rest }) => {
  const [stopAnimation, setStopAnimation] = useState(true);
  const [isFavorite, setIsFavorite] = useState(value);
  const [animationState, setAnimationState] = useState(value);

  useEffect(() => {
    if (stopAnimation) {
      setAnimationState(!animationState);
    }
  }, [stopAnimation]);

  useEffect(() => {
    if (isFavorite !== value) {
      setStopAnimation(false);
    }
  }, [value]);

  const label = <div className={cx('label')}>{isFavorite ? 'FAVORITED' : 'FAVORITE'}</div>;

  return (
    <div className={cx('icon-wrapper')}>
      <div className={cx('animation-wrapper')}>
        <ReactLottie
          options={{
            loop: false,
            autoplay: false,
            animationData: !animationState ? HEART_ANIMATION.HEART_UNFAVORITE : HEART_ANIMATION.HEART_FAVORITE,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={height}
          width={width}
          style={{
            margin: 'none',
          }}
          {...rest}
          isPaused={stopAnimation}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => {
                setIsFavorite(!value);
                setStopAnimation(true);
                setThrottleHandleClick(false);
              },
            },
          ]}
        />
      </div>
      {showLabel && label}
    </div>
  );
};

FavoriteHeart.propTypes = {
  value: PropTypes.bool,
  showLabel: PropTypes.bool,
  playAnimation: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  setThrottleHandleClick: PropTypes.func.isRequired,
};

FavoriteHeart.defaultProps = {
  value: false,
  showLabel: false,
  height: 43,
  width: 43,
  playAnimation: false,
};

export default FavoriteHeart;
