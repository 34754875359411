import { useTeamEmployees } from 'accounts/hooks/homeowners/use-team-employees';
import MultipleBelongEmployeeProfileView from 'components/MultipleBelongEmployeeProfileView/MultipleBelongEmployeeProfileView';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import FormLayout from 'layouts/FormLayout/FormLayout';
import Page from 'layouts/Page/Page';
import { Loading } from 'components/Loading/Loading';
import { useSetupFlow } from 'accounts/hooks/homeowners/use-setup-flow';

export function MeetYourTeamSection() {
  const { properties, units } = useSetupFlow();
  const { employees, loading } = useTeamEmployees({ properties, units });

  if (!loading && !employees?.length) {
    return null;
  }

  return (
    <section className="mb-2xl">
      <FormLayout.Title title="Meet Your Team" />
      <Page.OffsetGrid ignoreOffset={[SPACE_TYPES.MD, SPACE_TYPES.LG, SPACE_TYPES.XL]}>
        <div className="flex justify-center">
          {loading ? <Loading /> : <MultipleBelongEmployeeProfileView employees={employees} />}
        </div>
      </Page.OffsetGrid>
    </section>
  );
}

