import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CITIES } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import String from 'components/String/String';
import { INVALID_ZIPCODE } from 'consts/address';
import Space from 'corecomponents/Space/Space';
import { kebabCase } from 'es-toolkit';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import UserBasicInfo from 'models/common/UserBasicInfo';
import PropTypes from 'prop-types';
import { selectUser } from 'store/redux/user/selectors';
import { formatString, getString } from 'strings';
import { NOT_THERE_YET_STRINGS as STRINGS } from 'strings/not-there-yet-modal';
import styles from './NotThereYetModal.module.css';

const cx = classNames.bind(styles);
const BLG_PHONE = '(833) 313-BLNG';
const COVERAGE_IMAGE_BASE_PATH = 'https://belonghome.imgix.net/coverage_areas/v2/belong_coverage_area_map';
const ALL_COVERAGE_IMAGE_BASE_PATH = `${COVERAGE_IMAGE_BASE_PATH}_all_v5.svg`;

function getCoverageImagePath(property) {
  const foundCity = CITIES.find((city) => city.city.toLowerCase() === property?.address.city.toLowerCase());

  if (foundCity) {
    return `${COVERAGE_IMAGE_BASE_PATH}_${kebabCase(foundCity.region ?? '')}.jpg`;
  }

  return foundCity;
}

function NotThereYetModal({ user, multiple = false, cityName = null, onSubmit, property, onHide, ...rest }) {
  const [coverageImage, setCoverageImage] = useState(
    getCoverageImagePath(property) ? getCoverageImagePath(property) || '' : ALL_COVERAGE_IMAGE_BASE_PATH
  );

  cityName = cityName !== 'Washington' ? cityName : 'Washington DC';
  const noZipcode = property?.address?.zipcode === INVALID_ZIPCODE;

  function onCoverageImageError() {
    setCoverageImage(ALL_COVERAGE_IMAGE_BASE_PATH);
  }

  useEffect(() => {
    setCoverageImage(
      getCoverageImagePath(property) ? getCoverageImagePath(property) || '' : ALL_COVERAGE_IMAGE_BASE_PATH
    );
  }, [property]);

  return (
    <ModalV2 closeButton noPadding noDialogSidePadding onHide={onHide} {...rest}>
      <ModalLayout>
        <ModalLayout.PreTitle>
          <String string={STRINGS.thanks} />
        </ModalLayout.PreTitle>
        <div className={cx('modal-title')}>
          <Text variant="h2" fontWeight="semibold" className="text-center">
            {multiple
              ? formatString(STRINGS.preTitleMultipleCities)
              : noZipcode
              ? STRINGS['no_zip_code.preTitle']
              : getString(STRINGS.preTitle, { cityName })}
          </Text>
          <ModalLayout.Title noMarginTop>
            {noZipcode ? STRINGS['no_zip_code.title'] : STRINGS.title_x}
          </ModalLayout.Title>
        </div>

        <div className={cx('map-image-wrapper')}>
          <div
            className={cx('coverage-image')}
            style={{ backgroundImage: `url(${coverageImage})` }}
            onError={onCoverageImageError}
          />
        </div>
        <ModalLayout.Content>
          <div className={cx('padding-on-mobile')}>
            <ModalLayout.Section noMargin>
              <Button
                buttonType="text"
                size="fluid"
                label={`${STRINGS.phone_cta} ${BLG_PHONE}`}
                href={`tel:+1${BLG_PHONE}`}
              />
              <Space.XL />
            </ModalLayout.Section>
          </div>
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
}

NotThereYetModal.propTypes = {
  user: PropTypes.instanceOf(UserBasicInfo).isRequired,
  onSubmit: PropTypes.func.isRequired,
  cityName: PropTypes.string,
  property: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: selectUser(state) || {},
});

export default connect(mapStateToProps)(NotThereYetModal);
