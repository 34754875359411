import React from 'react';
import PropTypes from 'prop-types';
import { RentalStatus } from 'models/enums';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { validFutureOrTodayMMDDYYDate, validFutureOrTodayMMYYDate } from 'utils/validation';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { MoveOutOptionsFields } from '../MoveOutOptionsFields/MoveOutOptionsFields';
import { MoveOutSpecificDateFields } from '../MoveOutSpecificDateFields/MoveOutSpecificDateFields';
import { MoveOutDateRangeFields } from '../MoveOutDateRangeFields/MoveOutDateRangeFields';
import { MoveOutResidentsFoundFields } from '../MoveOutResidentsFoundFields/MoveOutResidentsFoundFields';
import { MoveOutNotSureFields } from '../MoveOutNotSureFields/MoveOutNotSureFields';

export const OwnerOccupiedMoveOutSection = ({ name }) => (
  <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.ownerOccupiedMoveout}>
    <MoveOutOptionsFields name={name} />
    <MoveOutSpecificDateFields extraValidation={validFutureOrTodayMMDDYYDate} name={name} />
    <MoveOutDateRangeFields extraValidation={validFutureOrTodayMMYYDate} name={name} />
    <MoveOutResidentsFoundFields name={name} rentalStatus={RentalStatus.OwnerOccupied} />
    <MoveOutNotSureFields name={name} />
  </FormLayout.Section>
);

OwnerOccupiedMoveOutSection.propTypes = {
  name: PropTypes.string.isRequired,
};
