// React imports
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import Image from 'components/Image/Image';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskMMDDYYYY, unmaskMMDDYYYY } from 'components/Input/masks';
import { NewMediaUploadAdapter, MEDIA_LABEL, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import String from 'components/String/String';
import Space from 'corecomponents/Space/Space';
import { Text, Flex } from 'design-system';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
// Component imports
import FormLayout from 'layouts/FormLayout/FormLayout';
// Lodash imports
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
// Constant & String imports
import { composeValidators, required, dateOfBirthValidation } from 'utils/validation';
// SCSS imports
import styles from './IdentityVerification.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.identity_verification;

export const SELECTOR_OPTIONS = {
  DL: 'Card',
  PASS: 'Passport',
  N_ID: 'NationalCard',
  OTHER: 'Other',
};

const TIPS_IMAGES = [
  '/resident_application/identity_verification_tips_0.svg',
  '/resident_application/identity_verification_tips_1.svg',
  '/resident_application/identity_verification_tips_2.svg',
  '/resident_application/identity_verification_tips_3.svg',
  '/resident_application/identity_verification_tips_4.svg',
];

function TipsBox(props) {
  const { tips } = props;
  return (
    <GreenInfoBox>
      <Text as="p" fontWeight="semibold">
        Tips
      </Text>
      <div className={cx('tips-box')}>
        {tips.map((tip, index) => (
          <Flex alignItems="center" my="2xs" key={tip}>
            <Image src={TIPS_IMAGES[index]} className={cx('tip-icon')} alt={tip} />
            <Text as="p">{tip}</Text>
          </Flex>
        ))}
      </div>
    </GreenInfoBox>
  );
}

TipsBox.propTypes = {
  // Array that contains all the tips
  tips: PropTypes.arrayOf(PropTypes.string),
};
export default class IdentityVerification extends Component {
  static propTypes = {
    // User first name for generating file name for webcam images
    userFirstName: PropTypes.string,
    // Submit function that returns the image files and idType
    onSubmit: PropTypes.func.isRequired,
    // Function that MUST return a button of type='submit'
    bottomBar: PropTypes.func,
    // Object containing files if identity verification step was previously
    // completed for the user
    initialValuesObject: PropTypes.object,
    getCtaProps: PropTypes.func,
    getFormLayoutBottomBarProps: PropTypes.func,
    bottomPosition: PropTypes.string,
    renderBottomBar: PropTypes.func,
    disableBottomBar: PropTypes.bool,
    showDateOfBirth: PropTypes.bool,
  };

  static defaultProps = {
    userFirstName: 'Image',
    initialValuesObject: {},
    bottomBar: null,
    getCtaProps: () => {},
    getFormLayoutBottomBarProps: () => ({}),
    bottomPosition: '',
    renderBottomBar: null,
    disableBottomBar: false,
    showDateOfBirth: false,
  };

  constructor(props) {
    super(props);

    const { initialValuesObject } = props;

    this.state = {
      idTypeSelected: !isEmpty(initialValuesObject),
      type: initialValuesObject.type || null,
      imagePassport: initialValuesObject.imagePassport || { mediaToDisplay: [] },
      imageIdFront: initialValuesObject.imageIdFront || { mediaToDisplay: [] },
      imageIdBack: initialValuesObject.imageIdBack || { mediaToDisplay: [] },
      imageSelfie: initialValuesObject.imageSelfie || { mediaToDisplay: [] },
      dateOfBirth: initialValuesObject.dateOfBirth || '',
    };
  }

  handleSubmit = async ({ type, imagePassport, imageIdFront, imageIdBack, imageSelfie, dateOfBirth }) => {
    const { onSubmit, showDateOfBirth } = this.props;

    const responseObject = {};

    if (type === SELECTOR_OPTIONS.PASS) {
      responseObject.imagePassport = imagePassport;
    } else {
      responseObject.imageIdFront = imageIdFront;
      responseObject.imageIdBack = imageIdBack;
    }

    responseObject.imageSelfie = imageSelfie;
    responseObject.type = type;
    if (showDateOfBirth) {
      responseObject.dateOfBirth = dateOfBirth;
    }

    onSubmit(responseObject);
  };

  handleChangeIdType = (value) => {
    this.setState({
      idTypeSelected: true,
      type: value,
      imagePassport: value === SELECTOR_OPTIONS.DL ? [] : this.state.imagePassport,
      imageIdFront: value === SELECTOR_OPTIONS.PASS ? [] : this.state.imageIdFront,
      imageIdBack: value === SELECTOR_OPTIONS.PASS ? [] : this.state.imageIdBack,
    });
  };

  handleShowDateOfBirthChange = (value) => {
    this.setState({ dateOfBirth: value });
  };

  // Can be removed after we update to the new NEXT button.
  checkIsValid = ({ type, imagePassport, imageIdFront, imageIdBack, imageSelfie }) => {
    const { idTypeSelected } = this.state;

    if (!idTypeSelected) {
      return true;
    }

    if (isEmpty(imageSelfie.mediaToDisplay)) {
      return true;
    }

    switch (type) {
      case SELECTOR_OPTIONS.PASS:
        return isEmpty(imagePassport.mediaToDisplay);
      default:
        return isEmpty(imageIdFront.mediaToDisplay) || isEmpty(imageIdBack.mediaToDisplay);
    }
  };

  renderUploadIdSection() {
    const { userFirstName } = this.props;
    const { idTypeSelected, imagePassport, imageIdFront, imageIdBack, imageSelfie } = this.state;
    const { tips } = RAS;

    if (!idTypeSelected) {
      return null;
    }

    return (
      <>
        <Condition when="type" is={SELECTOR_OPTIONS.PASS}>
          <div className={cx('passport-section-wrapper', 'section-wrapper')}>
            <FormLayout.Section
              sectionTitle={isEmpty(imagePassport.mediaToDisplay) ? RAS.passport_title : RAS.passport_uploaded_title}
              sectionSubTitle={RAS.passport_sub_title}
            >
              <div className={cx('document-upload-section-wrapper')}>
                <Row>
                  <Col md={12}>
                    <TipsBox tips={tips} />
                    <Space />
                    <Field
                      name="imagePassport"
                      validate={validateMedia}
                      onChangeCustom={(value) => {
                        this.setState({ imagePassport: { ...imagePassport, ...value } });
                      }}
                      component={NewMediaUploadAdapter}
                      mediaLabel={MEDIA_LABEL.PASSPORT}
                      defaultFileName={`${userFirstName}_passport`}
                      isSingleMedia
                    />
                  </Col>
                </Row>
              </div>
            </FormLayout.Section>
          </div>
        </Condition>
        <Condition when="type" isNot={SELECTOR_OPTIONS.PASS}>
          <div className={cx('id-front-section-wrapper', 'section-wrapper')}>
            <FormLayout.Section sectionTitle={RAS.id_title} sectionSubTitle={RAS.id_front_sub_title}>
              <Space />
              <TipsBox tips={tips} />
              <div className={cx('document-upload-section-wrapper')}>
                <Row>
                  <Col className={cx('col-flex')} md={12}>
                    <Field
                      name="imageIdFront"
                      validate={validateMedia}
                      component={NewMediaUploadAdapter}
                      onChangeCustom={(value) => this.setState({ imageIdFront: { ...imageIdFront, ...value } })}
                      mediaLabel={MEDIA_LABEL.ID_FRONT}
                      defaultFileName={`${userFirstName}_front`}
                      isSingleMedia
                    />
                    <span className={cx('hor-space')} />
                    <Field
                      name="imageIdBack"
                      validate={validateMedia}
                      component={NewMediaUploadAdapter}
                      onChangeCustom={(value) => this.setState({ imageIdBack: { ...imageIdBack, ...value } })}
                      mediaLabel={MEDIA_LABEL.ID_BACK}
                      defaultFileName={`${userFirstName}_back`}
                      isSingleMedia
                    />
                  </Col>
                </Row>
              </div>
            </FormLayout.Section>
          </div>
        </Condition>
        <div className={cx('selfie-section-wrapper', 'section-wrapper')}>
          <FormLayout.Section
            sectionTitle={isEmpty(imageSelfie.mediaToDisplay) ? RAS.selfie_title : RAS.selfie_uploaded_title}
            sectionSubTitle={RAS.selfie_sub_title}
          >
            <div className={cx('document-upload-section-wrapper')}>
              <Row>
                <Col md={12}>
                  <Space />
                  <Field
                    name="imageSelfie"
                    validate={validateMedia}
                    onChangeCustom={(value) => this.setState({ imageSelfie: { ...imageSelfie, ...value } })}
                    component={NewMediaUploadAdapter}
                    mediaLabel={MEDIA_LABEL.SELFIE}
                    defaultFileName={`${userFirstName}_selfie`}
                    isSingleMedia
                  />
                </Col>
              </Row>
            </div>
          </FormLayout.Section>
        </div>
      </>
    );
  }

  render() {
    const {
      getCtaProps,
      getFormLayoutBottomBarProps,
      bottomPosition,
      renderBottomBar,
      disableBottomBar,
      showDateOfBirth,
    } = this.props;
    const { type, imagePassport, imageIdFront, imageIdBack, imageSelfie, dateOfBirth } = this.state;

    const formData = {
      type,
      imagePassport,
      imageIdFront,
      imageIdBack,
      imageSelfie,
      dateOfBirth,
    };

    return (
      <Form
        initialValues={formData}
        onSubmit={this.handleSubmit}
        getFormBottomBar={(formProps, nextButtonProps) => {
          if (renderBottomBar) {
            return renderBottomBar({
              checkFormValidFunction: this.checkIsValid,
              formValues: formProps.values,
              nextButtonWrapperProps: nextButtonProps,
            });
          }
          if (!disableBottomBar) {
            return (
              <FormLayout.BottomBar
                ctaProps={{
                  ...getCtaProps(formProps),
                  type: 'submit',
                }}
                nextButtonWrapperProps={nextButtonProps}
                {...getFormLayoutBottomBarProps()}
              />
            );
          }
        }}
        bottomPosition={bottomPosition}
        getForm={({ handleSubmit }) => {
          return (
            <div className={cx('form-wrapper')}>
              <form onSubmit={handleSubmit}>
                <div className={cx('id-type-section-wrapper')}>
                  <FormLayout.Section firstSection sectionTitle={RAS.choose_id_type}>
                    <div className={cx('selector-buttons-wrapper')}>
                      <Row>
                        <Col md={12}>
                          <Field
                            name="type"
                            component={SelectorFinalFormAdapter}
                            onChangeCustom={this.handleChangeIdType}
                            buttons={[
                              {
                                children: (
                                  <div>
                                    <String string={RAS.id_types.dl_id} />
                                  </div>
                                ),
                                key: SELECTOR_OPTIONS.DL,
                              },
                              {
                                label: RAS.id_types.passport,
                                key: SELECTOR_OPTIONS.PASS,
                              },
                            ]}
                            type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                            validate={required}
                          />
                        </Col>
                      </Row>
                    </div>
                  </FormLayout.Section>
                </div>
                {this.renderUploadIdSection()}

                {showDateOfBirth && type && (
                  <FormLayout.Section>
                    <Row>
                      <Col md={6}>
                        <Field
                          name="dateOfBirth"
                          onChangeCustom={this.handleShowDateOfBirthChange}
                          component={InputFinalFormAdapter}
                          placeholder="Date of Birth (MM/DD/YYYY)"
                          validate={composeValidators(required, dateOfBirthValidation)}
                          mask={maskMMDDYYYY}
                          unmask={unmaskMMDDYYYY}
                        />
                      </Col>
                    </Row>
                  </FormLayout.Section>
                )}
              </form>
            </div>
          );
        }}
      />
    );
  }
}
