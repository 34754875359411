import { ACTIONS as USER_ACTIONS } from '../user/constants';
import { ACTIONS, ACCOUNTS_CONST } from './constants';

export const ACCOUNTS_REDUCER = 'accounts';

const initialState = {
  bills: null,
  balances: null,
  balancesByMonth: null,
  balancesUnderReview: null,
  balancesPastMonths: null,
  currentBill: {
    fees: [],
  },
  pendingBills: null,
  pastBills: null,
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_USER_BILLS_SUCCESS:
      return {
        ...state,
        bills: action.result,
      };

    case ACTIONS.FETCH_USER_BILLS_BY_ID_SUCCESS:
      return {
        ...state,
        currentBill: { ...action.result },
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_USER_FEES_BY_ID}_SUCCESS`]:
      return {
        ...state,
        currentBill: {
          ...state.currentBill,
          fees: [...action.result?.fees],
        },
      };

    case USER_ACTIONS.USER_DATA_CLEAR:
      return {
        ...initialState,
      };

    case ACTIONS.CURRENT_BILL_CLEAR:
      return {
        ...state,
        currentBill: {
          fees: [],
        },
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_USER_GROUP_BALANCES}_SUCCESS`]:
      return {
        ...state,
        balances: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_USER_GROUP_BALANCES_BY_MONTH}_SUCCESS`]:
      return {
        ...state,
        balancesByMonth: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_USER_BALANCES_PAST_MONTHS}_SUCCESS`]:
      return {
        ...state,
        balancesPastMonths: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_USER_BALANCES_UNDER_REVIEW}_SUCCESS`]:
      return {
        ...state,
        balancesUnderReview: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.SIMULATE_SPLIT_PAYMENT}_SUCCESS`]:
      return {
        ...state,
        splitPayments: { ...action.result },
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_FINANCE_PLAN}_SUCCESS`]:
      return {
        ...state,
        financePlan: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_FINANCE_PLAN_PAYMENTS}_SUCCESS`]:
      return {
        ...state,
        financePlanPayments: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.CREATE_FINANCE_PLAN}_SUCCESS`]:
      return {
        ...state,
        financePlan: { ...action.result },
      };

    case ACTIONS[`${ACCOUNTS_CONST.CLOSE_FINANCE_PLAN}_SUCCESS`]:
      return {
        ...state,
        financePlan: { ...action.result },
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_USER_PENDING_BILLS}_SUCCESS`]:
      return {
        ...state,
        pendingBills: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_USER_PAST_BILLS}_SUCCESS`]:
      return {
        ...state,
        pastBills: action.result,
      };

    case ACTIONS[`${ACCOUNTS_CONST.FETCH_FINANCE_PLAN_DISCLOSURE}_SUCCESS`]:
      return {
        ...state,
        disclosure: action.result,
      };
    default:
      return state;
  }
}
