import { Component } from 'react';
import { connect } from 'react-redux';
import { COOKIES_CONFIG, PATHS } from '@belong/common';
import classNames from 'classnames/bind';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import TabBar from 'components/TabBar/TabBar';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import { getDefaultHomeownerGrowth } from 'consts/employee-assignments';
import AccountPropertyBannerCard from 'containercomponents/AccountPropertyBannerCard/AccountPropertyBannerCard';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { Text } from 'design-system';
import { find, isNil, sortBy } from 'lodash-es';
import { EmployeeAssignmentTypes, FlowStatus, ReportType } from 'models/enums';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { parseCookies } from 'nookies';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import PropTypes from 'prop-types';
import {
  fetchAccountUnitListingsWithPros,
  fetchAccountUnits,
  fetchPropertyTimelines,
} from 'store/redux/homeowner-accounts/actions';
import {
  selectAccountPropertyById,
  selectAccountUnitsValues,
  selectTimelines,
} from 'store/redux/homeowner-accounts/selectors';
import { fetchDwollaToken, fetchReports } from 'store/redux/user/actions';
import { selectUser, selectUserFlows } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { arrayInsertIf } from 'utils/insertIf';
import AccountPageSection from '../../../AccountPage/AccountPageSection/AccountPageSection';
import Unit from '../Unit/Unit';
import styles from './Property.module.css';
import { findEmployeeByType, getBubbleText, FORMATTED_EMPLOYEES, betterRenewals } from './constants';

const cx = classNames.bind(styles);

class Property extends Component {
  state = {
    selectedUnitId: null,
    loading: false,
    listings: [],
    reports: [],
    comeFromTheMobileApp: false,
  };

  async componentDidMount() {
    this.fetchUnitsData();
    const cookies = parseCookies();

    const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

    this.setState({
      comeFromTheMobileApp,
    });
  }

  async componentDidUpdate(prevProps) {
    const { accountProperty } = this.props;

    if (prevProps.accountProperty.basicInfo.propertyId !== accountProperty.basicInfo.propertyId) {
      this.fetchUnitsData();
    }
  }

  fetchUnitsData = async () => {
    const {
      match,
      accountProperty,
      fetchAccountUnits: fetchAccountUnitsAction,
      fetchPropertyTimelinesAction,
      fetchAccountUnitListingsWithProsAction,
      fetchReportsAction,
    } = this.props;

    const unitId = match?.params?.unitId || accountProperty.getUnitsWithAgreements()[0]?.basicInfo.unitId;
    const unitIds = accountProperty.getUnitsWithAgreements().map((unit) => unit.basicInfo.unitId);

    this.setState({
      loading: true,
    });

    try {
      const [, , listings, reports] = await Promise.all([
        fetchAccountUnitsAction(unitIds),
        fetchPropertyTimelinesAction(unitIds),
        fetchAccountUnitListingsWithProsAction(),
        fetchReportsAction(),
      ]);
      if (listings) this.setState({ listings });
      if (reports) this.setState({ reports });
    } catch (err) {
      console.error(err);
    }

    this.setState({
      selectedUnitId: unitId,
      loading: false,
    });
  };

  handleAddPropertyClick = () => {
    const { history } = this.props;
    history.push(PATHS.HOMEOWNER_ONBOARDING_PROPERTY_ADDRESS);
  };

  calculateFlowProgress = (flow) => {
    if (!flow || !this) return 0;
    const missedPayments = flow.steps.filter((step) => step.stepName === 'MissedPayments');
    if (missedPayments.isHidden) return 0;
    const steps = flow.steps.filter((step) => !step.isHidden);
    const completedSteps = steps.filter((step) => step.status === FlowStatus.Completed);
    const progress = (completedSteps.length * 100) / steps.length;
    return progress;
  };

  filterRenewalFlows = (homeownerRenewalFlows) => {
    const allFlows = homeownerRenewalFlows.map((flow) => ({
      progressPercentage: this.calculateFlowProgress(flow),
      disabled: flow.status === FlowStatus.Completed || flow.status === FlowStatus.Cancelled,
      displayName: 'Review Renewal Offer',
      reportLink: `${PATHS.HO_BETTER_RENEWALS_FLOW}?flowId=${flow.uniqueId}`,
      unitId: flow?.configuration?.homeId,
    }));

    const flows = [];

    this.props.units.forEach(({ basicInfo }) => {
      const unitFlows = allFlows.filter((flow) => flow.unitId === basicInfo.id);

      if (unitFlows.length === 1) return flows.push(unitFlows[0]);
      const flow = unitFlows.find((f) => !f.disabled);

      if (flow) flows.push(flow);
    });

    return flows;
  };

  render() {
    const { accountProperty, units, timelines, match, userProfile, flows = [] } = this.props;
    const { loading, selectedUnitId, listings, reports, comeFromTheMobileApp } = this.state;

    const selectedUnit = find(units, (unit) => unit.basicInfo.unitId === selectedUnitId);
    const selectedTimeline = timelines?.[selectedUnitId];

    const selectedUnitWithEmployees = find(accountProperty.units, (unit) => unit.basicInfo.unitId === selectedUnitId);
    const selectedUnitPricingReport = reports.find(({ report }) => {
      return report.homeId === selectedUnitId && report.type === ReportType.Pricing;
    });

    const selectedUnitListing = listings.find(({ homeListingDetailsModel }) => {
      return homeListingDetailsModel.basicInfo.unitId === selectedUnitId;
    });

    const unitEmployees = selectedUnitWithEmployees?.employeeAssignments || [];
    const pricingReportEmployees = selectedUnitPricingReport?.employeeAssignments || [];

    const allEmployees = [...unitEmployees, ...pricingReportEmployees];
    const homeownerGrowth =
      findEmployeeByType(allEmployees, EmployeeAssignmentTypes.HomeownerGrowth) ||
      getDefaultHomeownerGrowth(accountProperty.basicInfo.coverageRegionId);

    const conciergeOrGrowth = findEmployeeByType(allEmployees, EmployeeAssignmentTypes.Concierge) || homeownerGrowth;

    let sortedEmployees = [];
    if (selectedUnit?.currentLease || selectedUnit?.nextLease) {
      sortedEmployees = [
        FORMATTED_EMPLOYEES.HEAD_OF_OPERATIONS,
        conciergeOrGrowth,
        pricingReportEmployees?.[0],
        FORMATTED_EMPLOYEES.HEAD_OF_EXPERIENCE,
      ];
    } else {
      sortedEmployees = [
        // Don't show if not assigned
        ...arrayInsertIf(selectedUnitListing?.homeListingDetailsModel?.inspectedBy, {
          ...selectedUnitListing?.homeListingDetailsModel?.inspectedBy?.employeeDetailModel,
          // Just to give it regular employee shape to format in the same way.
          employee: {
            ...selectedUnitListing?.homeListingDetailsModel?.inspectedBy?.employeeDetailModel?.employee,
            ...selectedUnitListing?.homeListingDetailsModel?.inspectedBy,
          },
          employeeAssignment: {
            assignmentType: EmployeeAssignmentTypes.Pro,
          },
        }),
        pricingReportEmployees?.[0],
        conciergeOrGrowth,
        findEmployeeByType(allEmployees, EmployeeAssignmentTypes.ResidentGrowth),
      ];
    }

    // Just in case find somehow doesn't find the item we have undefined elements
    sortedEmployees = sortedEmployees.filter((employee) => {
      return !isNil(employee);
    });

    const formattedEmployees = sortedEmployees.map(({ employee, employeeAssignment, user, ...rest }) => ({
      ...employeeAssignment,
      ...employee,
      ...user,
      profileImage: user?.profileImageUrl,
      bannerImage: employee?.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[employeeAssignment?.assignmentType],
      ...rest,
    }));

    const chatBubbleEmployee = {
      ...conciergeOrGrowth.employeeAssignment,
      ...conciergeOrGrowth.employee,
      ...conciergeOrGrowth.user,
      phone: getDefaultHomeownerGrowth(accountProperty.basicInfo.coverageRegionId).employee.phone,
      profileImage: conciergeOrGrowth.user?.profileImageUrl,
      bannerImage: conciergeOrGrowth.employee?.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[conciergeOrGrowth.employeeAssignment?.assignmentType],
    };

    const bubbleText = getBubbleText(selectedUnit, selectedTimeline?.timelineEvents, userProfile.firstName);
    const addHomeButton = comeFromTheMobileApp
      ? {}
      : {
          iconLabelText: 'ADD HOME',
          align: ALIGN_TYPES.RIGHT,
          icon: GENERAL_ICONS.PLUS,
          onClick: this.handleAddPropertyClick,
        };

    let renewalFlows = [];

    const homeownerRenewalFlows = flows
      .filter((flow) => flow.flowType === 'HomeownerRenewal')
      .sort((a, b) => {
        return new Date(b.auditInfo.createdOn) - new Date(a.auditInfo.createdOn);
      });

    const hasBetterRenewals = homeownerRenewalFlows.length > 0;

    if (hasBetterRenewals) {
      renewalFlows = this.filterRenewalFlows(homeownerRenewalFlows);
    }

    return (
      <>
        {hasBetterRenewals && (
          <div className="mb-3xl">
            <div className="mb-sm">
              <Text fontSize="h3" fontWeight="semibold">
                {betterRenewals.title}
              </Text>
            </div>
            {renewalFlows.map((flowSection) => {
              let unitName;

              if (renewalFlows.length > 1) {
                const currentUnit = units.find((unit) => unit.basicInfo.id === flowSection?.unitId);

                if (currentUnit) {
                  unitName = `Unit ${currentUnit.basicInfo.unitNumber}`;
                }
              }

              return (
                <div key={flowSection.uniqueId}>
                  <LinkBase
                    className={cx('flowCard', 'flex flex-wrap justify-between items-center relative', {
                      'cursor-pointer': !flowSection.disabled || flowSection.progressPercentage !== 100,
                      disabled: flowSection.disabled,
                    })}
                    {...(!flowSection.disabled && {
                      href: flowSection.reportLink,
                    })}
                  >
                    <div
                      className={cx('flex flex-col font-semibold text-body items-center mr-sm', {
                        'text-dark-gray': flowSection.disabled,
                        'text-navy': !flowSection.disabled,
                      })}
                    >
                      <h4 className="mr-xs">{formatString(flowSection.displayName)}</h4>
                      {unitName && (
                        <div className="mr-auto">
                          <Text fontSize="p1" fontWeight="semibold">
                            {unitName}
                          </Text>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center mt-sm w-full md:w-auto md:mt-0">
                      {flowSection.disabled ? (
                        <>
                          {flowSection.progressPercentage === 100 ? (
                            <>
                              <h4 className="text-green">100%&nbsp;</h4>
                              <h4 className="text-dark-gray">Complete</h4>
                            </>
                          ) : (
                            <h4 className="text-dark-gray">Not Ready</h4>
                          )}
                        </>
                      ) : (
                        <>
                          <h4
                            className={cx('mr-2xs', {
                              'text-green': flowSection.progressPercentage === 100,
                              'text-red': flowSection.progressPercentage === 0,
                              'text-blue': flowSection.progressPercentage > 0 && flowSection.progressPercentage < 100,
                            })}
                          >
                            {flowSection.progressPercentage.toFixed()}%
                          </h4>
                          <h4>Complete</h4>
                        </>
                      )}
                      {flowSection.progressPercentage !== 100 && (
                        <IconButton
                          disabled={flowSection.disabled}
                          align={ALIGN_TYPES.LEFT}
                          icon={GENERAL_ICONS.FRONT_ARROW}
                        />
                      )}
                    </div>
                  </LinkBase>
                </div>
              );
            })}
          </div>
        )}
        <AccountPage
          loading={loading}
          headerProps={{
            headerText: (
              <div className={cx('header-text')}>
                <Text fontSize="h3" fontWeight="semibold">
                  {accountProperty.getStreetAddress()}
                </Text>
              </div>
            ),
            ...addHomeButton,
            rowFlex: true,
          }}
        >
          {Boolean(bubbleText) && (
            <TextBubbleWithUserProfile text={bubbleText} employee={chatBubbleEmployee} showContact showCalendarLink />
          )}
          <div className={cx('section')}>
            <AccountPropertyBannerCard
              coordinates={accountProperty.address.coordinates}
              imageUrl={units[0]?.basicInfo.bannerImageUrl}
              staticMapImageUrl={accountProperty.staticMapImageUrl}
            />
          </div>

          {units.length > 1 && (
            <div className={cx('section', 'smallMargin', 'tabs')}>
              <TabBar
                name="portfolio"
                items={sortBy(
                  units.map((unit) => ({
                    text: `Unit ${unit.basicInfo.unitNumber}`,
                    key: unit.basicInfo.unitId,
                  })),
                  (item) => parseInt(item.text, 10) || item.text
                )}
                onSelect={(item) => {
                  this.setState({
                    selectedUnitId: item.key,
                  });
                  // Could not find another way to change the url without causing navigation blinks/scroll to top.
                  if (typeof window !== 'undefined') {
                    window.history.replaceState(
                      null,
                      null,
                      `${PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES}/${match.params.propertyId}/${item.key}`
                    );
                  }
                }}
                selected={selectedUnitId}
              />
            </div>
          )}

          <AccountPageSection
            content={<Unit unit={selectedUnit} employees={formattedEmployees} timeline={selectedTimeline} />}
          />
        </AccountPage>
      </>
    );
  }
}

Property.propTypes = {
  accountProperty: PropTypes.instanceOf(HomeownerAccountProperty).isRequired,
  fetchAccountUnits: PropTypes.func.isRequired,
  fetchPropertyTimelinesAction: PropTypes.func.isRequired,
  fetchAccountUnitListingsWithProsAction: PropTypes.func.isRequired,
  fetchReportsAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  timelines: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  flows: PropTypes.array,
};

function mapStateProps(state, props) {
  return {
    accountProperty: selectAccountPropertyById(state, props.match?.params?.propertyId),
    units: selectAccountUnitsValues(state),
    timelines: selectTimelines(state),
    userProfile: selectUser(state),
    flows: selectUserFlows(state),
  };
}
const mapDispatchToProps = {
  fetchAccountUnits,
  fetchDwollaToken,
  fetchPropertyTimelinesAction: fetchPropertyTimelines,
  fetchAccountUnitListingsWithProsAction: fetchAccountUnitListingsWithPros,
  fetchReportsAction: fetchReports,
};

export default connect(mapStateProps, mapDispatchToProps)(Property);
