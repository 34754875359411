import { useState } from 'react';
import { Spinner, Text, Wizard, Toast, Image } from '@belong/ui';
import { RescheduleInspectionAppointmentOptionsForm } from 'accounts/components/reschedule-inspection-appointment-options-form/reschedule-inspection-appointment-options-form';
import { STRINGS } from 'accounts/constants/strings/reschedule-appointment';
import { useRescheduleAppointment, useRescheduleAppointmentContext } from 'accounts/hooks/reschedule-appointment';
import type { RescheduleAppointmentOptionsFormSchema } from 'accounts/types';
import { format } from 'date-fns-tz';

const { useWizard } = Wizard;

export function RescheduleAppointmentOptionsStep() {
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const { nextStep } = useWizard();
  const { setFormattedAppointmentDate, timeZone, unitId } = useRescheduleAppointmentContext();
  const { isLoading: isReschedulingAppointment, rescheduleAppointment } = useRescheduleAppointment();

  function handleToastClose() {
    setIsErrorToastVisible(false);
  }

  async function handleSubmit(values: RescheduleAppointmentOptionsFormSchema) {
    try {
      setIsErrorToastVisible(false);

      const { date, time } = values;

      if (date && time) {
        const [hours, minutes] = time.split(':');

        date.setHours(Number(hours));
        date.setMinutes(Number(minutes));

        await rescheduleAppointment({
          start: format(date, "yyyy-MM-dd'T'HH:mm:ssxxx", {
            timeZone,
          }),
          unitId,
        });

        const formattedAppointmentDate = format(date, 'PPP h:mm a', {
          timeZone,
        });

        setFormattedAppointmentDate(formattedAppointmentDate);

        nextStep();

        return;
      }
    } catch {
      setIsErrorToastVisible(true);
    }
  }

  return (
    <>
      {isReschedulingAppointment && <Spinner />}
      <div className="w-full">
        <div className="max-w-[300px] sm:max-w-[400px] text-center m-auto mb-2xl">
          <Text variant="h2" fontWeight="semibold">
            {STRINGS['step.options.title']}
          </Text>
        </div>
        <RescheduleInspectionAppointmentOptionsForm onSubmit={handleSubmit} />
      </div>
      <Toast
        icon={<Image alt="" className="object-scale-down" src="icons/alert-white.svg" />}
        isVisible={isErrorToastVisible}
        onClose={handleToastClose}
        variant="danger"
      >
        {STRINGS['step.options.reschedule.error']}
      </Toast>
    </>
  );
}
