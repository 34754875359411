import { isToday, isTomorrow } from 'date-fns';
import { formatDateTime } from './format-date.js';

function futureUnixTimestamp(days) {
  // Get the current date
  var date = new Date();
  // Add the number of days to the current date
  date.setDate(date.getDate() + days);
  // Return the Unix timestamp in seconds
  return Math.floor(date.getTime() / 1000);
}
function getDescriptiveDay(dateTime) {
  var descriptiveDay = '';
  if (isToday(dateTime)) {
    descriptiveDay += 'Today, ';
  }
  if (isTomorrow(dateTime)) {
    descriptiveDay += 'Tomorrow, ';
  }
  descriptiveDay += formatDateTime({
    dateTime: dateTime,
    format: 'iiii, MMMM d'
  });
  return descriptiveDay;
}
// Gets Monday of the previous week
function getPreviousWeekMonday() {
  var today = new Date();
  var dayOfWeek = today.getDay();
  var difference = today.getDate() - dayOfWeek - 6;
  var monday = new Date(today.setDate(difference));
  monday.setHours(0, 0, 0, 0);
  return monday;
}
// Gets Sunday of the previous week
function getPreviousWeekSunday() {
  var previousMonday = getPreviousWeekMonday();
  var sunday = new Date(previousMonday);
  sunday.setDate(previousMonday.getDate() + 6);
  sunday.setHours(23, 59, 59, 999);
  return sunday;
}
function getNumberOfDaysFromTimeStamp(timeStamp) {
  if (!timeStamp) {
    return 0;
  }
  var date = new Date(timeStamp);
  var now = new Date();
  var diff = now.getTime() - date.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
}

export { futureUnixTimestamp, getDescriptiveDay, getNumberOfDaysFromTimeStamp, getPreviousWeekMonday, getPreviousWeekSunday };
