import React, { Component } from 'react';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Icon, { ICONS } from 'components/Icon/Icon';
import IconButton from 'components/IconButton/IconButton';
import MobileOverlay from 'components/MobileOverlay/MobileOverlay';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import Spinner from 'components/Spinner/Spinner';
import { ProfileIconSection } from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserProfilePopout/IconSection/IconSection';
import styles from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserProfilePopout/UserProfilePopout.module.css';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { startCase } from 'es-toolkit';
import { Row } from 'forkedlibraries/react-bootstrap';
import { compact } from 'lodash-es';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

export const ALIGN_ITEMS = {
  ALIGN_LEFT: 'alignLeft',
  ALIGN_RIGHT: 'alignRight',
  NO_TRANSFORM: 'noTransform',
};

export default class UserProfilePopout extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    hideProfileModal: PropTypes.func.isRequired,
    belongPro: PropTypes.bool,
    profile: PropTypes.object,
    profileImage: PropTypes.string,
    alignProfileItems: PropTypes.string,
    relative: PropTypes.bool,
    absoluteMobileOverlay: PropTypes.bool,
    belongEmployee: PropTypes.bool,
  };

  static defaultProps = {
    belongPro: false,
    profile: null,
    profileImage: '',
    relative: false,
    alignProfileItems: null,
    absoluteMobileOverlay: false,
    belongEmployee: false,
  };

  renderProfileImage(typeOfUser) {
    const { profile, profileImage, belongPro, belongEmployee } = this.props;

    return (
      <>
        {typeOfUser && (
          <div className={cx('attach-to-image')}>
            <GeneralIcon icon={GENERAL_ICONS.BALLOON_BELONG} />
          </div>
        )}
        <div className={cx('displayHeader')}>
          {belongPro || belongEmployee ? (
            <ProfileImage scale={profileImage === '' ? 1 : undefined} profileImage={profileImage} makeRound />
          ) : (
            <ProfileImage
              scale={1}
              profileImage={profileImage}
              classNameToResizeImage={cx('thumbnail-image', 'bigger-height')}
            />
          )}
          <div className={cx('text-wrapper')}>
            <div className={cx('bold')}>{profile.name}</div>
            {typeOfUser === 'belongpro' && (
              <div>
                Belong Pro since&nbsp;
                <BDate time={profile.memberSince} formatType={DATE_TYPES.MONTH_YEAR} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  renderBelongPro() {
    const { profile } = this.props;
    return (
      <>
        {this.renderProfileImage('belongpro')}
        <Space />

        {/* Part 2 */}
        <Row>
          <ProfileIconSection
            colSize={6}
            icon={<Icon scale={0.5} icon={ICONS.DROP_ZONE.DEFAULT} />}
            title="Coverage"
            text={profile.coverageRegion}
          />
          <Space value={SPACE_TYPES.HIDE} mobileValue={SPACE_TYPES.SM} />
          {profile.homeTown && (
            <ProfileIconSection
              colSize={6}
              icon={<Icon scale={0.5} icon={ICONS.BABY.DEFAULT} />}
              title="Born in"
              text={profile.homeTown}
            />
          )}
        </Row>
        <Space />
        <Row>
          <ProfileIconSection
            colSize={12}
            icon={<Icon scale={0.5} icon={ICONS.REPAIR.DEFAULT} />}
            title="Specializes in"
            text={compact(profile.specialities).map(startCase).join(' • ')}
          />
        </Row>
        <Space />
        {/* Part 3 */}
        <div>
          <div className={cx('bold')}>Favorite Quote</div>
          <Space value={SPACE_TYPES.XS} />
          <div className={cx('break-word')}>{profile.favoriteQuote}</div>
        </div>
      </>
    );
  }

  renderResident() {
    const { profile } = this.props;

    return (
      <>
        {this.renderProfileImage()}
        <Space />
        <Row>
          {profile.homeTown && (
            <>
              <ProfileIconSection
                colSize={6}
                icon={<Icon scale={0.5} icon={ICONS.BABY.DEFAULT} />}
                title="Born in"
                text={profile.homeTown}
              />
              <Space value={SPACE_TYPES.HIDE} mobileValue={SPACE_TYPES.SM} />
            </>
          )}

          <ProfileIconSection
            colSize={6}
            icon={<Icon scale={0.5} icon={ICONS.BALLOON.DEFAULT} />}
            title="Belong Member"
            text={
              <span>
                Since&nbsp;
                <BDate time={profile.memberSince} formatType={DATE_TYPES.SHORT_MONTH_YEAR} />
              </span>
            }
          />
        </Row>
        <Space />
        <div>
          <div className={cx('break-word')}>{profile.aboutMe}</div>
        </div>
      </>
    );
  }

  render() {
    const { show, belongPro, profile, hideProfileModal, alignProfileItems, relative, absoluteMobileOverlay } =
      this.props;

    let renderItem = (
      <div className={cx('makeSpinnerTakeAPadding')}>
        <Spinner />
      </div>
    );

    if (profile?.name) {
      renderItem = belongPro ? this.renderBelongPro() : this.renderResident();
    }

    return (
      <MobileOverlay
        show={show}
        absoluteMobileOverlay={absoluteMobileOverlay}
        relative={relative}
        onHide={hideProfileModal}
      >
        <div className={cx('display-popout', alignProfileItems)}>
          <IconButton onClick={hideProfileModal} icon={GENERAL_ICONS.CLOSE} className={cx('affix-closebutton')} />
          <Space />
          {renderItem}
          <Space />
        </div>
      </MobileOverlay>
    );
  }
}
