import { formatDateTime } from '@belong/common';
import { BillStatusInfo, GroupedBalancesInfoModel } from 'api/models.v2';
import { EARNINGS_STRINGS } from '../Pages/YourPortfolio/Earnings/earnings.strings';

const getDateAndHour = (value) => {
  const dateInput = value.replace(/\+0\d:00/, '');
  const date = formatDateTime({ dateTime: dateInput, format: 'MM/dd' });
  const hour = formatDateTime({
    dateTime: dateInput,
    format: 'h:mma',
  });

  return {
    date,
    hour,
  };
};

const hasFailedBill = (billStatusInfo: BillStatusInfo) => billStatusInfo?.status === 'Failed';
const hasPaidBill = (billStatusInfo: BillStatusInfo) => billStatusInfo?.status === 'Paid';
const hasRefundedBill = (billStatusInfo: BillStatusInfo) => billStatusInfo?.status === 'Refunded';
export const hasPartiallyRefundedBill = (billStatusInfo: BillStatusInfo) =>
  billStatusInfo?.status === 'PartiallyRefunded';

const hasPaymentScheduled = (billStatusInfo: BillStatusInfo) => billStatusInfo?.status === 'Scheduled';
const hasBalancePaymentProcessing = (billStatusInfo: BillStatusInfo) => billStatusInfo?.status === 'Processing';

const isExpense = (category: string) => category === 'Expenses';
const isRevenue = (category: string) => category === 'Revenues';

type BalanceSubtitleProps = Partial<GroupedBalancesInfoModel>;

export const getBalanceSubtitle = ({
  dueOn,
  cutOffOn,
  billStatusInfo,
  isFinanceable,
  canBePaid,
  category,
  isAutoSplittable,
  isDueDateAnEstimation,
  initiatedOn,
}: BalanceSubtitleProps) => {
  if (hasFailedBill(billStatusInfo)) {
    return `${EARNINGS_STRINGS['failed.label']} ${getDateAndHour(billStatusInfo?.statusUpdatedOn).date}`;
  } else if (hasPaidBill(billStatusInfo)) {
    return `${EARNINGS_STRINGS['paid_on.label']} ${getDateAndHour(billStatusInfo?.statusUpdatedOn).date}`;
  } else if (hasRefundedBill(billStatusInfo)) {
    return `${EARNINGS_STRINGS['refunded.label']}`;
  } else if (hasPartiallyRefundedBill(billStatusInfo)) {
    return `${EARNINGS_STRINGS['partially_refunded.label']}`;
  } else if (hasBalancePaymentProcessing(billStatusInfo)) {
    return EARNINGS_STRINGS['processing.label'];
  } else if (hasPaymentScheduled(billStatusInfo) && !canBePaid && dueOn) {
    return `${EARNINGS_STRINGS['scheduled.label']} ${getDateAndHour(dueOn).date}`;
  } else if (isRevenue(category) && initiatedOn && isDueDateAnEstimation) {
    return `${EARNINGS_STRINGS['initiated.label']} ${getDateAndHour(initiatedOn).date} | Expected By ${
      getDateAndHour(dueOn).date
    } In Your Bank Account`;
  } else if (isRevenue(category) && !initiatedOn && isDueDateAnEstimation) {
    return `Expected By ${getDateAndHour(dueOn).date} In Your Bank Account`;
  } else if (isRevenue(category) && dueOn) {
    return `${EARNINGS_STRINGS['paying_on.label']} ${getDateAndHour(dueOn).date}`;
  } else if (isExpense(category) && isFinanceable && cutOffOn && canBePaid && isAutoSplittable) {
    return `${EARNINGS_STRINGS['splitting_on.label']} ${getDateAndHour(cutOffOn).date}`;
  } else if (isExpense(category) && isFinanceable && cutOffOn && canBePaid) {
    return `${EARNINGS_STRINGS['split_by.label']} ${getDateAndHour(cutOffOn).date}`;
  } else if (isExpense(category) && canBePaid && dueOn) {
    return `${EARNINGS_STRINGS['charging.label']} ${getDateAndHour(dueOn).date}`;
  }
};

export const getBalanceSubtitleStyles = (billStatusInfo, isDelayed = false) => {
  if (hasFailedBill(billStatusInfo) || isDelayed) {
    return 'text-red';
  } else if (hasPaidBill(billStatusInfo)) {
    return 'text-green';
  } else if (hasBalancePaymentProcessing(billStatusInfo)) {
    return 'text-blue';
  }

  return 'text-dark-gray';
};
