import { IconInfo } from '@belong/icons';
import { Tooltip } from '@belong/ui';
import Label from 'components/Label/Label';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

export const SharedResponsibilityLabel = ({ showTooltip, isHomeowner, side }) => {
  const tooltipCopy = formatString(POST_INSPECTION_FLOW_STRINGS['improvements.shared_responsibility.tooltip'], {
    party: isHomeowner ? 'resident' : 'homeowner',
  });
  return (
    <Label>
      <div className="flex">
        {POST_INSPECTION_FLOW_STRINGS['improvements.shared_responsibility']}
        {showTooltip && (
          <Tooltip
            contentProps={{ side }}
            arrowProps={{ className: 'fill-bg-black' }}
            content={
              <div className="bg-black p-xs rounded" style={{ width: 300 }}>
                <p className="text-left p1 font-regular text-white whitespace-pre-line">{tooltipCopy}</p>
              </div>
            }
          >
            <button
              onClick={(e) => e.stopPropagation()}
              className="cursor-pointer fill-navy ml-xs"
              type="button"
              style={{ marginTop: '2px' }}
            >
              <IconInfo width={14} />
            </button>
          </Tooltip>
        )}
      </div>
    </Label>
  );
};

SharedResponsibilityLabel.propTypes = {
  showTooltip: PropTypes.bool,
  isHomeowner: PropTypes.bool,
  side: PropTypes.string,
};

SharedResponsibilityLabel.defaultProps = {
  showTooltip: true,
  isHomeowner: true,
  side: 'top',
};

export const ResidentResponsibilityLabel = ({ showTooltip, side }) => {
  return (
    <Label>
      <div className="flex">
        {POST_INSPECTION_FLOW_STRINGS['improvements.resident_responsibility']}
        {showTooltip && (
          <Tooltip
            contentProps={{ side }}
            arrowProps={{ className: 'fill-bg-black' }}
            content={
              <div className="bg-black p-xs rounded" style={{ width: 300 }}>
                <p className="text-left p1 font-regular text-white whitespace-pre-line">
                  {formatString(POST_INSPECTION_FLOW_STRINGS['improvements.resident_responsibility.tooltip'])}
                </p>
              </div>
            }
          >
            <button
              onClick={(e) => e.stopPropagation()}
              className="cursor-pointer fill-navy ml-xs"
              type="button"
              style={{ marginTop: '2px' }}
            >
              <IconInfo width={14} />
            </button>
          </Tooltip>
        )}
      </div>
    </Label>
  );
};

ResidentResponsibilityLabel.propTypes = {
  showTooltip: PropTypes.bool,
  side: PropTypes.string,
};

ResidentResponsibilityLabel.defaultProps = {
  showTooltip: true,
  side: 'top',
};
