export const USER_IDENTITY_STRINGS = {
  title: "Let's make sure <span>you're you.</span>",
  subtitle: "In order to verify your identity, you'll be asked to take a picture of your ID and take a selfie.</span>",
  date_of_birth: 'Date of Birth (MM/DD/YYYY)',
  banner_step: 'Congrats on upleveling your profile!',
  'banner_step.next_up': "Next up... let's get you paid!",
  tip_title: 'Photo Guidelines',
  id_title: 'Upload your ID.',
  id_front_title: 'Upload the front of your ID.',
  id_front_sub_title:
    'Place your ID on a flat, blank surface with all edges in frame. Please make sure your camera is in focus so we can read the fine print!',
  id_front_uploaded_title: 'Upload the front of your ID.',
  passport_title: 'Upload the ID page of your Passport.',
  passport_sub_title:
    'Place your ID on a flat, blank surface with all edges in frame. Please make sure your camera is in focus so we can read the fine print!',
  passport_uploaded_title: 'Upload the ID page of your Passport.',
  id_back_title: 'Now, flip it over and upload the back.',
  id_back_sub_title:
    'Place your ID on a flat, blank surface with all edges in frame. Please make sure your camera is in focus so we can read the fine print!',
  id_back_uploaded_title: 'Upload the back of your ID.',
  selfie_title: 'Lastly, take a selfie!',
  selfie_sub_title:
    'Position your face in the middle of the frame, and make sure you are well lit so we can get a good look.',
  selfie_uploaded_title: 'Wow, you look great!',
  id_types: {
    dl_id: "Driver's License/ <span>ID Card</span>",
    passport: 'Passport',
    // n_id: 'National ID Card',
    // other: 'Other'
  },
  id_modal_title: 'Can we see some ID?',
  id_modal_subTitle:
    'Place your ID on a flat, blank surface and align the edges with the green brackets. Make sure the camera is in focus so we can read the fine print!',
  selfie_modal_title: 'Take a selfie!',
  selfie_modal_subTitle:
    'Position your face in the green outline and make sure it is well lit so we can get a good look.',
  picture_modal_cta_label: 'Save',
  next_button_text: 'Next',
  tip: {
    description:
      "As a Verified Member, you'll have access to the entire Belong Community, including special events, discounts <span>and more.</span>",
  },
  tip_cosigner: {
    description:
      "While you may not be living in a Belong Home, as a Verified Member, you'll have access to the entire Belong Community in the future, including special events, discounts <span>and more.</span>",
  },
  verification_loader: 'Looking good!<div>Please wait while we verify you.</div>',
  verification_fail: 'Hmm, we were not able to verify your ID. <span>Please try again!</span>',
  tips: [
    'All edges in frame',
    'Clear and not over exposed',
    'Turn off camera flash',
    'Blank, dark background',
    'Valid ID',
  ],
};
