import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../../utils/formatNumber';

class Sqft extends Component {
  static propTypes = {
    sqft: PropTypes.number.isRequired,
  };

  render() {
    const { sqft } = this.props;

    return (
      <span>
        {formatNumber(sqft)} ft<sup>2</sup>
      </span>
    );
  }
}

Sqft.propTypes = {};

export default Sqft;
