import PropTypes from 'prop-types';

import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import { required } from 'utils/validation';

export function PropertyFeaturesFormFields({ name, disabled }) {
  return (
    <Row>
      <Col md={12}>
        <Field
          name={`${name}.attributes.whereIsLocated`}
          component={InputFinalFormAdapter}
          validate={required}
          placeholder="Where is it located and how do we get in?"
          disabled={disabled}
          applyDisabledStyle={disabled}
        />
      </Col>
    </Row>
  );
}

PropertyFeaturesFormFields.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

PropertyFeaturesFormFields.defaultProps = {
  name: '',
  disabled: false,
};
