import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/Field/Field';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import { required } from 'utils/validation';
import { AcFeatureTypes } from 'models/enums';
import { HOME_OWNER_PAPER_WORK_STRINGS } from 'strings/homeowner-paperwork.strings';

const AcFormFields = ({ name, disabled }) => (
  <Field
    name={`${name}.type`}
    fluid
    component={SelectorFinalFormAdapter}
    validate={required}
    type={SELECTOR_TYPES.SMALLTEXTBUTTON}
    buttons={[
      {
        label: HOME_OWNER_PAPER_WORK_STRINGS['homeFeatures.ac_type.central_ac'],
        key: AcFeatureTypes.CentralAc,
      },
      {
        label: HOME_OWNER_PAPER_WORK_STRINGS['homeFeatures.ac_type.window_units'],
        key: AcFeatureTypes.WindowUnits,
      },
    ]}
    disabled={disabled}
  />
);

AcFormFields.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

AcFormFields.defaultProps = {
  name: '',
  disabled: false,
};

export default AcFormFields;
