/*
 *
 * WARNING!: Please double check if you should add a new city or
 * consume from an endpoint.
 * We do not want to add more cities. It was a solution to get out of step.
 * Talk with Nico M. or Cesar G.
 *
 */
var CITIES = [{
  region: 'Chicago',
  city: 'Chicago'
}, {
  region: 'Chicago',
  city: 'Evanston'
}, {
  region: 'Chicago',
  city: 'Arlington Heights'
}, {
  region: 'Chicago',
  city: 'Schaumburg'
}, {
  region: 'Chicago',
  city: 'Downers Grove'
}, {
  region: 'Chicago',
  city: 'Naperville'
}, {
  region: 'Chicago',
  city: 'Auroroa'
}, {
  region: 'Chicago',
  city: 'Oswego'
}, {
  region: 'Chicago',
  city: 'Plainfield'
}, {
  region: 'Chicago',
  city: 'Bolingbrook'
}, {
  region: 'Chicago',
  city: 'Joliet'
}, {
  region: 'Chicago',
  city: 'Frankfort'
}, {
  region: 'Chicago',
  city: 'Orland Park'
}, {
  region: 'Chicago',
  city: 'Tinley Park'
}, {
  region: 'Chicago',
  city: 'Oak Lawn'
}, {
  region: 'Chicago',
  city: 'Hammond'
}, {
  region: 'Chicago',
  city: 'Merrillville'
}, {
  region: 'Chicago',
  city: 'Crown Point'
}, {
  region: 'Chicago',
  city: 'Portage'
}, {
  region: 'Chicago',
  city: 'Valparaiso'
}, {
  region: 'Chicago',
  city: 'Gary'
}, {
  region: 'Chicago',
  city: 'Westville'
}, {
  region: 'Chicago',
  city: 'Wanatah'
}, {
  region: 'Chicago',
  city: 'Michigan City'
}, {
  region: 'Chicago',
  city: 'New Buffalo'
}, {
  region: 'Chicago',
  city: 'La Porte'
}, {
  region: 'Washington',
  city: 'Washington'
}, {
  region: 'Washington',
  city: 'Arlington'
}, {
  region: 'Washington',
  city: 'Alexandria'
}, {
  region: 'Washington',
  city: 'Falls Church'
}, {
  region: 'Washington',
  city: 'Tysons'
}, {
  region: 'Washington',
  city: 'Fairfax'
}, {
  region: 'Washington',
  city: 'Reston'
}, {
  region: 'Washington',
  city: 'Springfield'
}, {
  region: 'Washington',
  city: 'Lorton'
}, {
  region: 'Washington',
  city: 'Gaithersburg'
}, {
  region: 'Washington',
  city: 'Potomac'
}, {
  region: 'Washington',
  city: 'Rockville'
}, {
  region: 'Washington',
  city: 'North Bethesda'
}, {
  region: 'Washington',
  city: 'Silver Spring'
}, {
  region: 'Washington',
  city: 'College Park'
}, {
  region: 'Washington',
  city: 'Hyattsville'
}, {
  region: 'Washington',
  city: 'Lanham'
}, {
  region: 'Washington',
  city: 'Bowie'
}, {
  region: 'Washington',
  city: 'Capitol Heights'
}, {
  region: 'Washington',
  city: 'Clinton'
}, {
  region: 'Atlanta',
  city: 'Atlanta'
}, {
  region: 'Atlanta',
  city: 'Adamsville'
}, {
  region: 'Atlanta',
  city: 'Princeton Lakes'
}, {
  region: 'Atlanta',
  city: 'Greenbriar'
}, {
  region: 'Atlanta',
  city: 'College Park'
}, {
  region: 'Atlanta',
  city: 'East Point'
}, {
  region: 'Atlanta',
  city: 'Mountain View'
}, {
  region: 'Atlanta',
  city: 'Forest Park'
}, {
  region: 'Atlanta',
  city: 'Lake City'
}, {
  region: 'Atlanta',
  city: 'Ellenwood'
}, {
  region: 'Atlanta',
  city: 'Conley'
}, {
  region: 'Atlanta',
  city: 'Constitution'
}, {
  region: 'Atlanta',
  city: 'Panthersville'
}, {
  region: 'Atlanta',
  city: 'Snapfinger'
}, {
  region: 'Atlanta',
  city: 'Candler-McAfee'
}, {
  region: 'Atlanta',
  city: 'Belvedere Park'
}, {
  region: 'Atlanta',
  city: 'Decatur'
}, {
  region: 'Atlanta',
  city: 'Druid Hills'
}, {
  region: 'Atlanta',
  city: 'North Decatur'
}, {
  region: 'Atlanta',
  city: 'North Druid Hills'
}, {
  region: 'Atlanta',
  city: 'Clarkston'
}, {
  region: 'Atlanta',
  city: 'Buckhead'
}, {
  region: 'Atlanta',
  city: 'Brookhaven'
}, {
  region: 'Boston',
  city: 'Boston'
}, {
  region: 'Boston',
  city: 'Wakefield'
}, {
  region: 'Boston',
  city: 'Clifton'
}, {
  region: 'Boston',
  city: 'Lynn'
}, {
  region: 'Boston',
  city: 'Nahant'
}, {
  region: 'Boston',
  city: 'Saugus'
}, {
  region: 'Boston',
  city: 'Stoneham'
}, {
  region: 'Boston',
  city: 'Woburn'
}, {
  region: 'Boston',
  city: 'Lexington'
}, {
  region: 'Boston',
  city: 'Arlington'
}, {
  region: 'Boston',
  city: 'Medford'
}, {
  region: 'Boston',
  city: 'Malden'
}, {
  region: 'Boston',
  city: 'Revere'
}, {
  region: 'Boston',
  city: 'Chelsea'
}, {
  region: 'Boston',
  city: 'Winthrop'
}, {
  region: 'Boston',
  city: 'Somerville'
}, {
  region: 'Boston',
  city: 'Cambridge'
}, {
  region: 'Boston',
  city: 'Watertown'
}, {
  region: 'Boston',
  city: 'Waltham'
}, {
  region: 'Boston',
  city: 'Newton'
}, {
  region: 'Boston',
  city: 'Needham'
}, {
  region: 'Boston',
  city: 'Dedham'
}, {
  region: 'Boston',
  city: 'Milton'
}, {
  region: 'Boston',
  city: 'Quincy'
}, {
  region: 'Boston',
  city: 'Braintree'
}, {
  region: 'Boston',
  city: 'Weymouth'
}, {
  region: 'Phoenix',
  city: 'Sun City West'
}, {
  region: 'Phoenix',
  city: 'Surprise'
}, {
  region: 'Phoenix',
  city: 'Waddell'
}, {
  region: 'Phoenix',
  city: 'Citrus Park'
}, {
  region: 'Phoenix',
  city: 'Litchfield Park'
}, {
  region: 'Phoenix',
  city: 'Goodyear'
}, {
  region: 'Phoenix',
  city: 'Sun City'
}, {
  region: 'Phoenix',
  city: 'Peoria'
}, {
  region: 'Phoenix',
  city: 'Tolleson'
}, {
  region: 'Phoenix',
  city: 'Glendale'
}, {
  region: 'Phoenix',
  city: 'Wild Flower'
}, {
  region: 'Phoenix',
  city: 'Phoenix'
}, {
  region: 'Phoenix',
  city: 'St Johns'
}, {
  region: 'Phoenix',
  city: 'Komatke'
}, {
  region: 'Phoenix',
  city: 'Windsong'
}, {
  region: 'Phoenix',
  city: 'Paradise Valley'
}, {
  region: 'Phoenix',
  city: 'Scottsdale'
}, {
  region: 'Phoenix',
  city: 'Tempe'
}, {
  region: 'Phoenix',
  city: 'Mesa'
}, {
  region: 'Phoenix',
  city: 'Gilber'
}, {
  region: 'Phoenix',
  city: 'Chandler'
}, {
  region: 'Phoenix',
  city: 'Sun Lakes'
}, {
  region: 'Denver',
  city: 'Denver'
}, {
  region: 'Denver',
  city: 'Broomfield'
}, {
  region: 'Denver',
  city: 'Henderson'
}, {
  region: 'Denver',
  city: 'Thornton'
}, {
  region: 'Denver',
  city: 'Westminster'
}, {
  region: 'Denver',
  city: 'Arvada'
}, {
  region: 'Denver',
  city: 'Golden'
}, {
  region: 'Denver',
  city: 'Lakewood'
}, {
  region: 'Denver',
  city: 'Morrison'
}, {
  region: 'Denver',
  city: 'Indian Hills'
}, {
  region: 'Denver',
  city: 'Littleton'
}, {
  region: 'Denver',
  city: 'Englewood'
}, {
  region: 'Denver',
  city: 'Highland Ranch'
}, {
  region: 'Denver',
  city: 'Centennial'
}, {
  region: 'Denver',
  city: 'Lone Tree'
}, {
  region: 'Denver',
  city: 'Aurora'
}, {
  region: 'Denver',
  city: 'Parker'
}, {
  region: 'Austin',
  city: 'Austin'
}, {
  region: 'Austin',
  city: 'Lost Creek'
}, {
  region: 'Austin',
  city: 'Sunset Valley'
}, {
  region: 'Austin',
  city: 'West Lake Hills'
}, {
  region: 'Austin',
  city: 'Bluff Springs'
}, {
  region: 'Austin',
  city: 'Hornsby Bend'
}, {
  region: 'Austin',
  city: 'Manor'
}, {
  region: 'Austin',
  city: 'Pflugerville'
}, {
  region: 'Las Vegas',
  city: 'Las Vegas'
}, {
  region: 'Las Vegas',
  city: 'Henderson'
}, {
  region: 'Detroit',
  city: 'Detroit'
}, {
  region: 'Detroit',
  city: 'Ann Arbor'
}, {
  region: 'Detroit',
  city: 'Ypsilanti'
}, {
  region: 'Detroit',
  city: 'Belleville'
}, {
  region: 'Detroit',
  city: 'Romulus'
}, {
  region: 'Detroit',
  city: 'Canton'
}, {
  region: 'Detroit',
  city: 'Plymouth'
}, {
  region: 'Detroit',
  city: 'Westland'
}, {
  region: 'Detroit',
  city: 'Novi'
}, {
  region: 'Detroit',
  city: 'Farmington Hills'
}, {
  region: 'Detroit',
  city: 'West Bloomfield Township'
}, {
  region: 'Detroit',
  city: 'Pontiac'
}, {
  region: 'Detroit',
  city: 'Bloomfield Hills'
}, {
  region: 'Detroit',
  city: 'Auburn Hills'
}, {
  region: 'Detroit',
  city: 'Rochester'
}, {
  region: 'Detroit',
  city: 'Rochester Hills'
}, {
  region: 'Detroit',
  city: 'Troy'
}, {
  region: 'Detroit',
  city: 'Shelby Twp'
}, {
  region: 'Detroit',
  city: 'Sterling Heights'
}, {
  region: 'Detroit',
  city: 'Clinton Twp'
}, {
  region: 'Detroit',
  city: 'Southfield'
}, {
  region: 'Detroit',
  city: 'Royal Oak'
}, {
  region: 'Detroit',
  city: 'Ferndal'
}, {
  region: 'Detroit',
  city: 'Warren'
}, {
  region: 'Detroit',
  city: 'St Clair Shoes'
}, {
  region: 'Detroit',
  city: 'Eastpointe'
}, {
  region: 'Detroit',
  city: 'Hamtramck'
}, {
  region: 'Detroit',
  city: 'Grosse Pointe'
}, {
  region: 'Detroit',
  city: 'Dearborn'
}, {
  region: 'Detroit',
  city: 'Allen Park'
}, {
  region: 'Detroit',
  city: 'Southgate'
}, {
  region: 'Detroit',
  city: 'Taylor'
}, {
  region: 'Philadelphia',
  city: 'Philadelphia'
}, {
  region: 'Philadelphia',
  city: 'Jenkintown'
}, {
  region: 'Philadelphia',
  city: 'Conshohocken'
}, {
  region: 'Philadelphia',
  city: 'Bryn Mawr'
}, {
  region: 'Philadelphia',
  city: 'Ardmore'
}, {
  region: 'Philadelphia',
  city: 'Bala Cynwyd'
}, {
  region: 'Philadelphia',
  city: 'Havertown'
}, {
  region: 'Philadelphia',
  city: 'Broomall'
}, {
  region: 'Philadelphia',
  city: 'Drexel Hill'
}, {
  region: 'Philadelphia',
  city: 'Springfield'
}, {
  region: 'Philadelphia',
  city: 'Swarthmore'
}, {
  region: 'Philadelphia',
  city: 'Elkins Park'
}, {
  region: 'Philadelphia',
  city: 'Andalusia'
}, {
  region: 'Portland',
  city: 'Hillsboro'
}, {
  region: 'Portland',
  city: 'Aloha'
}, {
  region: 'Portland',
  city: 'Beaverton'
}, {
  region: 'Portland',
  city: 'Tigard'
}, {
  region: 'Portland',
  city: 'King City'
}, {
  region: 'Portland',
  city: 'Tualatin'
}, {
  region: 'Portland',
  city: 'Lake Oswego'
}, {
  region: 'Portland',
  city: 'Milwaukie'
}, {
  region: 'Portland',
  city: 'West Linn'
}, {
  region: 'Portland',
  city: 'Oregon City'
}, {
  region: 'Portland',
  city: 'Gladstone'
}, {
  region: 'Portland',
  city: 'Clackamas'
}, {
  region: 'Portland',
  city: 'Sunnyside'
}, {
  region: 'Portland',
  city: 'Happy Valley'
}, {
  region: 'Portland',
  city: 'Damascus'
}, {
  region: 'Portland',
  city: 'Gresham'
}, {
  region: 'Portland',
  city: 'Troutdale'
}, {
  region: 'Portland',
  city: 'Fairview'
}, {
  region: 'Portland',
  city: 'Interlachen'
}, {
  region: 'Portland',
  city: 'Maywood Park'
}, {
  region: 'Salt Lake City',
  city: 'Salt Lake City'
}, {
  region: 'Salt Lake City',
  city: 'Bountiful'
}, {
  region: 'Salt Lake City',
  city: 'Woods Cross'
}, {
  region: 'Salt Lake City',
  city: 'North Salt Lake'
}, {
  region: 'Salt Lake City',
  city: 'Zions Parks'
}, {
  region: 'Salt Lake City',
  city: 'South Salt Lake'
}, {
  region: 'Salt Lake City',
  city: 'Alpine Meadows'
}, {
  region: 'Salt Lake City',
  city: 'West Valley City'
}, {
  region: 'Salt Lake City',
  city: 'Magna'
}, {
  region: 'Salt Lake City',
  city: 'Arthur'
}, {
  region: 'Salt Lake City',
  city: 'Kearns'
}, {
  region: 'Salt Lake City',
  city: 'Oquirrh'
}, {
  region: 'Salt Lake City',
  city: 'Taylorsville'
}, {
  region: 'Salt Lake City',
  city: 'Millcreek'
}, {
  region: 'Salt Lake City',
  city: 'Murray'
}, {
  region: 'Salt Lake City',
  city: 'Holladay'
}, {
  region: 'Salt Lake City',
  city: 'Wildwood'
}, {
  region: 'Salt Lake City',
  city: 'Cottownwood Heights'
}, {
  region: 'Salt Lake City',
  city: 'Midvale'
}, {
  region: 'Salt Lake City',
  city: 'West Jordan'
}, {
  region: 'Salt Lake City',
  city: 'Granite'
}, {
  region: 'Salt Lake City',
  city: 'Sandy'
}, {
  region: 'Salt Lake City',
  city: 'South Jordan'
}, {
  region: 'Salt Lake City',
  city: 'Copperton'
}, {
  region: 'Salt Lake City',
  city: 'Draper'
}, {
  region: 'Salt Lake City',
  city: 'Riverton'
}, {
  region: 'Salt Lake City',
  city: 'Herriman'
}, {
  region: 'Salt Lake City',
  city: 'Bluffdale'
}, {
  region: 'Jacksonville',
  city: 'Jacksonville'
}, {
  region: 'Jacksonville',
  city: 'Atlantic Beach'
}, {
  region: 'Jacksonville',
  city: 'Neptune Beach'
}, {
  region: 'Jacksonville',
  city: 'Jacksonville Beach'
}, {
  region: 'Jacksonville',
  city: 'Ponte Vedra Beach'
}, {
  region: 'Jacksonville',
  city: 'Palm Valley'
}, {
  region: 'Jacksonville',
  city: 'Nocatee'
}, {
  region: 'Jacksonville',
  city: 'Sampson'
}, {
  region: 'Jacksonville',
  city: 'St Johns'
}, {
  region: 'Jacksonville',
  city: 'Fleming Island'
}, {
  region: 'Jacksonville',
  city: 'Lakside'
}, {
  region: 'Jacksonville',
  city: 'Orange Park'
}, {
  region: 'Jacksonville',
  city: 'Middleburg'
}, {
  region: 'Jacksonville',
  city: 'Baldwin'
}, {
  region: 'Jacksonville',
  city: 'Nassau Village Ratliff'
}, {
  region: 'Sacramento',
  city: 'Sacremento'
}, {
  region: 'Sacramento',
  city: 'Carmichael'
}, {
  region: 'Sacramento',
  city: 'Citrus Heights'
}, {
  region: 'Sacramento',
  city: 'Courtland'
}, {
  region: 'Sacramento',
  city: 'Elk Grove'
}, {
  region: 'Sacramento',
  city: 'Elverta'
}, {
  region: 'Sacramento',
  city: 'Fair Oaks'
}, {
  region: 'Sacramento',
  city: 'Folsom'
}, {
  region: 'Sacramento',
  city: 'Galt'
}, {
  region: 'Sacramento',
  city: 'Herald'
}, {
  region: 'Sacramento',
  city: 'Isleton'
}, {
  region: 'Sacramento',
  city: 'McClellan'
}, {
  region: 'Sacramento',
  city: 'Mather'
}, {
  region: 'Sacramento',
  city: 'North Highlands'
}, {
  region: 'Sacramento',
  city: 'Orangevale'
}, {
  region: 'Sacramento',
  city: 'Rancho Cordova'
}, {
  region: 'Sacramento',
  city: 'Rio Linda'
}, {
  region: 'Sacramento',
  city: 'Sloughhouse'
}, {
  region: 'Sacramento',
  city: 'Walnut Grove'
}, {
  region: 'Sacramento',
  city: 'West Sacramento'
}, {
  region: 'Sacramento',
  city: 'Wilton'
}, {
  region: 'Sacramento',
  city: 'Antelope'
}, {
  region: 'Sacramento',
  city: 'Oakley'
}, {
  region: 'Sacramento',
  city: 'Rio Vista'
}, {
  region: 'Dallas',
  city: 'Dallas'
}, {
  region: 'Dallas',
  city: 'Irving'
}, {
  region: 'Dallas',
  city: 'Hutchins'
}, {
  region: 'Dallas',
  city: 'Lancaster'
}, {
  region: 'Dallas',
  city: 'Mesquite'
}, {
  region: 'Dallas',
  city: 'Garland'
}, {
  region: 'Dallas',
  city: 'Duncanville'
}, {
  region: 'Dallas',
  city: 'Grand Prairie'
}, {
  region: 'Dallas',
  city: 'Garland'
}, {
  region: 'Dallas',
  city: 'Balch Springs'
}, {
  region: 'Dallas',
  city: 'Addison'
}, {
  region: 'Dallas',
  city: 'Richardson'
}, {
  region: 'Dallas',
  city: 'Cedar Hill'
}, {
  region: 'Dallas',
  city: 'DeSoto'
}, {
  region: 'Dallas',
  city: 'Sunnyvale'
}, {
  region: 'Dallas',
  city: 'Fort Worth'
}, {
  region: 'Dallas',
  city: 'Carrollton'
}, {
  region: 'Dallas',
  city: 'Rowlett'
}, {
  region: 'Dallas',
  city: 'Coppell'
}, {
  region: 'Dallas',
  city: 'Plano'
}, {
  region: 'Dallas',
  city: 'Forney'
}, {
  region: 'Dallas',
  city: 'Euless'
}, {
  region: 'Dallas',
  city: 'Plano'
}, {
  region: 'Dallas',
  city: 'Seagoville'
}, {
  region: 'Dallas',
  city: 'Arlington'
}, {
  region: 'Dallas',
  city: 'Rockwall'
}, {
  region: 'Dallas',
  city: 'Hurst'
}, {
  region: 'Dallas',
  city: 'Flower Mound'
}, {
  region: 'Dallas',
  city: 'Wylie'
}, {
  region: 'Dallas',
  city: 'Lavon'
}, {
  region: 'Dallas',
  city: 'Keller'
}, {
  region: 'Dallas',
  city: 'Haltom City'
}, {
  region: 'Dallas',
  city: 'Roanoke'
}, {
  region: 'Dallas',
  city: 'Keller'
}, {
  region: 'Dallas',
  city: 'Haslet'
}, {
  region: 'Dallas',
  city: 'Justin'
}, {
  region: 'Dallas',
  city: 'Burleson'
}, {
  region: 'Dallas',
  city: 'Crowley'
}, {
  region: 'Dallas',
  city: 'Azle'
}, {
  region: 'Dallas',
  city: 'Aledo'
}, {
  region: 'Dallas',
  city: 'Brazos Bend'
}, {
  region: 'Houston',
  city: 'Houston'
}, {
  region: 'Houston',
  city: 'Pasadena'
}, {
  region: 'Houston',
  city: 'South Houston'
}, {
  region: 'Houston',
  city: 'Galena Park'
}, {
  region: 'Houston',
  city: 'Bellaire'
}, {
  region: 'Houston',
  city: 'Channelview'
}, {
  region: 'Houston',
  city: 'La Porte'
}, {
  region: 'Houston',
  city: 'Brookside VL'
}, {
  region: 'Houston',
  city: 'Humble'
}, {
  region: 'Houston',
  city: 'Deer Park'
}, {
  region: 'Houston',
  city: 'Stafford'
}, {
  region: 'Houston',
  city: 'Sugar Land'
}, {
  region: 'Houston',
  city: 'Webster'
}, {
  region: 'Houston',
  city: 'Missouri City'
}, {
  region: 'Houston',
  city: 'Friendswood'
}, {
  region: 'Houston',
  city: 'Kingwood'
}, {
  region: 'Houston',
  city: 'Richmond'
}, {
  region: 'Houston',
  city: 'Baytown'
}, {
  region: 'Houston',
  city: 'Crosby'
}, {
  region: 'Houston',
  city: 'League City'
}, {
  region: 'Houston',
  city: 'Spring'
}, {
  region: 'Houston',
  city: 'Baytown'
}, {
  region: 'Houston',
  city: 'Humble'
}, {
  region: 'Houston',
  city: 'New Caney'
}, {
  region: 'Houston',
  city: 'Sienna Plant'
}, {
  region: 'Houston',
  city: 'Porter'
}, {
  region: 'Houston',
  city: 'Katy'
}, {
  region: 'Houston',
  city: 'Clodine'
}, {
  region: 'Houston',
  city: 'OLD RVR-WNFRE'
}, {
  region: 'Houston',
  city: 'La Marque'
}, {
  region: 'Houston',
  city: 'Cypress'
}, {
  region: 'Houston',
  city: 'Rosenberg'
}, {
  region: 'Houston',
  city: 'San Leon'
}, {
  region: 'Houston',
  city: 'Cypress'
}, {
  region: 'Houston',
  city: 'Bacliff'
}, {
  region: 'Houston',
  city: 'Conroe'
}, {
  region: 'Houston',
  city: 'Alvin'
}, {
  region: 'Houston',
  city: 'Conroe'
}, {
  region: 'Houston',
  city: 'Tomball'
}, {
  region: 'Houston',
  city: 'Hockley'
}, {
  region: 'Houston',
  city: 'Tomball'
}, {
  region: 'Houston',
  city: 'Katy'
}, {
  region: 'Houston',
  city: 'Conroe'
}, {
  region: 'Houston',
  city: 'Waller'
}, {
  region: 'Houston',
  city: 'Huffman'
}, {
  region: 'Houston',
  city: 'Conroe'
}];

export { CITIES };
