import { ACTIONS } from './constants';

export const MODAL_REDUCER = 'modal';

const initialState = {
  activeModal: null,
  props: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SHOW_MODAL:
      return {
        ...state,
        activeModal: action.activeModal,
        props: action.props,
      };
    case ACTIONS.HIDE_MODAL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
