import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { ALIGN_ITEMS } from '../MaintenanceStatus/UserActionMaintenance/UserProfilePopout/UserProfilePopout';
import ResidentTile from '../ResidentTile/ResidentTile';
import styles from './ResidentTileList.module.css';

const cx = classNames.bind(styles);

class ResidentTileList extends Component {
  static propTypes = {
    residents: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  render() {
    const { residents } = this.props;
    return residents.map((resident, index) => (
      <Row className={cx('resident')} key={index}>
        {index > 0 && <Space mobileValue={SPACE_TYPES.HIDE} value={SPACE_TYPES.LG} />}
        <ResidentTile alignProfileItems={ALIGN_ITEMS.ALIGN_LEFT} {...resident[0]} />
        {resident[1] && <ResidentTile alignProfileItems={ALIGN_ITEMS.ALIGN_RIGHT} {...resident[1]} />}
      </Row>
    ));
  }
}

export default ResidentTileList;
