import formatStringByPattern from 'format-string-by-pattern';
import numeral from 'numeral';
import { MONEY_TYPES } from './format-money.js';
import { PERCENTAGE_TYPES } from './format-percentage.js';

var phoneNumberMask = function (value) {
  if (!value) return value;
  var onlyNumbers = value.replace(/[^\d]/g, '');
  // TODO: LENGHT GOING HIGHER THAN 10!
  if (!(onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length) || onlyNumbers.length > 10) {
    throw new Error('');
  }
  return formatStringByPattern('(999) 999 - 9999', onlyNumbers);
};
var phoneNumberUnmask = function (value) {
  if (!value) return value;
  return value.replace(/\D+/g, '');
};
var currencyMask = function (value) {
  if (!value) return value;
  var onlyNumbers = value.replace(/[^\d]/g, '');
  if (!(onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length)) throw new Error('');
  return numeral(onlyNumbers).format(MONEY_TYPES.DEFAULT);
};
var currencyUnmask = function (value) {
  if (!value) return value;
  return value.replace(/\D+/g, '');
};
var numericMask = function (maxLen) {
  return function (value) {
    if (!value) return value;
    var onlyNumbers = value.replace(/[^\d]/g, '');
    if (!(onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length)) throw new Error('');
    if (maxLen && onlyNumbers.length > maxLen) throw new Error('');
    return value;
  };
};
var numericUnmask = function (value) {
  return value;
};
var floatNumericMask = function (value) {
  if (!value) {
    return value;
  }
  var onlyNumbers = value.toString().replace(/[^\d]/g, '');
  return onlyNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
var unmaskFloatNumeric = function (value) {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '');
};
var creditCardNumberMask = function (value) {
  if (!value) {
    return value;
  }
  var onlyNumbers = value.replace(/[^\d]/g, '');
  if (!onlyNumbers) {
    return onlyNumbers;
  }
  var isAmex = /^34/.test(onlyNumbers) || /^37/.test(onlyNumbers);
  if (isAmex) {
    return formatStringByPattern('9999 999999 99999', onlyNumbers);
  }
  return formatStringByPattern('9999 9999 9999 9999', onlyNumbers);
};
var creditCardNumberUnmask = function (value) {
  if (!value) {
    return value;
  }
  return value.replace(/\s/g, '');
};
var MMYYMask = function (value) {
  if (!value || value.includes('/') && value.length < 5 || typeof value === 'object') {
    return value;
  }
  var onlyNumbers = value === null || value === void 0 ? void 0 : value.replace(/[^\d]/g, '');
  if (!onlyNumbers) {
    return onlyNumbers;
  }
  return formatStringByPattern('99/99', onlyNumbers);
};
var MMYYUnmask = function (value) {
  if (typeof value === 'object') {
    return value;
  }
  if (value.length > 5) {
    return value.substr(0, 4);
  }
  return value;
};
var maskYYYY = function (value) {
  if (!value) {
    return value;
  }
  var onlyNumbers = value.toString().replace(/[^\d]/g, '');
  return onlyNumbers;
};
var unmaskYYYY = function (value) {
  if (typeof value === 'string' && value.length > 4) {
    return value.substr(0, 4);
  }
  return value;
};
var MMYYYYMask = function (value) {
  if (!value || value.includes('/') && value.length < 7 || typeof value === 'object') {
    return value;
  }
  var onlyNumbers = value === null || value === void 0 ? void 0 : value.replace(/[^\d]/g, '');
  if (!onlyNumbers) {
    return onlyNumbers;
  }
  return formatStringByPattern('99/9999', onlyNumbers);
};
var MMYYYYUnmask = function (value) {
  if (typeof value === 'object') {
    return value;
  }
  if (value.length > 7) {
    return value.slice(0, 6);
  }
  return value;
};
var dateMMDDYYYYmask = function (value) {
  if (!value || value.includes('/') && value.length < 6) {
    return value;
  }
  var onlyNumbers = value.replace(/[^\d]/g, '');
  if (!(onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length) || onlyNumbers.length > 8) {
    throw new Error('');
  }
  return formatStringByPattern('99/99/9999', onlyNumbers);
};
var dateMMDDYYYYunmask = function (value) {
  if (value.length > 10) {
    return value.slice(0, 10);
  }
  return value;
};
var dateMMDDYYmask = function (value) {
  if (!value || value.includes('/') && value.length < 6) {
    return value;
  }
  var onlyNumbers = value.replace(/[^\d]/g, '');
  if (!(onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length) || onlyNumbers.length > 6) {
    throw new Error('');
  }
  return formatStringByPattern('99/99/99', onlyNumbers);
};
var dateMMDDYYunmask = function (value) {
  if (value.length > 8) {
    return value.slice(0, 8);
  }
  return value;
};
var percentageMask = function (value) {
  if (!value) return value;
  var onlyNumbers = value.replace(/[^\d]/g, '');
  if (!(onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length)) throw new Error('');
  numeral.options.scalePercentBy100 = false;
  return numeral(onlyNumbers).format(PERCENTAGE_TYPES.NO_DECIMAL);
};
var percentageUnmask = function (value) {
  if (!value) return value;
  return value.replace(/\D+/g, '');
};
var maskMMYY = function (value) {
  if (!value || value.includes('/') && value.length < 5 || typeof value === 'object') {
    return value;
  }
  var onlyNumbers = value === null || value === void 0 ? void 0 : value.replace(/[^\d]/g, '');
  if (!onlyNumbers) {
    return onlyNumbers;
  }
  return formatStringByPattern('99/99', onlyNumbers);
};
var unmaskMMYY = function (value) {
  if (typeof value === 'object') {
    return value;
  }
  if (value.length > 5) {
    return value.substr(0, 4);
  }
  return value;
};
var maskCreditCardNumber = function (value) {
  if (!value) {
    return value;
  }
  var onlyNumbers = value.replace(/[^\d]/g, '');
  if (!onlyNumbers) {
    return onlyNumbers;
  }
  var isAmex = /^34/.test(onlyNumbers) || /^37/.test(onlyNumbers);
  if (isAmex) {
    return formatStringByPattern('9999 999999 99999', onlyNumbers);
  }
  return formatStringByPattern('9999 9999 9999 9999', onlyNumbers);
};
var unmaskCreditCardNumber = function (value) {
  if (!value) {
    return value;
  }
  return value.replace(/\s/g, '');
};
var maskSSN = function (value) {
  if (!value || value.includes('-') && value.length < 8) {
    return value;
  }
  return formatStringByPattern('999-99-9999', value);
};
var unmaskSSN = function (value) {
  if (!value) {
    return value;
  }
  return value.replace(/\D+/g, '').slice(0, 9);
};
var maskEIN = function (value) {
  if (!value || value.includes('-') && value.length < 3) {
    return value;
  }
  return formatStringByPattern('99-9999999', value);
};
var unmaskEIN = function (value) {
  if (!value) {
    return value;
  }
  return value.replace(/\D+/g, '').slice(0, 9);
};

export { MMYYMask, MMYYUnmask, MMYYYYMask, MMYYYYUnmask, creditCardNumberMask, creditCardNumberUnmask, currencyMask, currencyUnmask, dateMMDDYYYYmask, dateMMDDYYYYunmask, dateMMDDYYmask, dateMMDDYYunmask, floatNumericMask, maskCreditCardNumber, maskEIN, maskMMYY, maskSSN, maskYYYY, numericMask, numericUnmask, percentageMask, percentageUnmask, phoneNumberMask, phoneNumberUnmask, unmaskCreditCardNumber, unmaskEIN, unmaskFloatNumeric, unmaskMMYY, unmaskSSN, unmaskYYYY };
