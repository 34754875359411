import { ReactNode } from 'react';
import { PATHS } from '@belong/common';
import { Text } from '@belong/ui';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { STRINGS } from 'post-inspection/constants/strings';

type Props = {
  children?: ReactNode;
};

export const QuestionsOrConcernsModalFooter = ({ children }: Props) => (
  <div
    className="
        w-full px-0 sm:px-2sm md:p-sm md:bg-light-gray md:flex md:justify-between md:border-0 md:border-t md:border-solid md:border-dark-gray rounded-b flex flex-col-reverse sm:flex-row
      "
  >
    <div className="flex shrink-0 sm:flex-row justify-center md:justify-start items-center mt-lg sm:mt-0 sm:mb-sm md:mb-0">
      <Text>{STRINGS['questions-or-concerns']}</Text>
      &nbsp;
      <div className="h-md lg:h-auto">
        <LinkBase href={PATHS.SUPPORT}>
          <Text fontWeight="semibold" className="hover:text-green uppercase">
            {STRINGS['lets-talk']}
          </Text>
        </LinkBase>
      </div>
    </div>
    {children}
  </div>
);
