export const STRINGS = {
  'step.options.title': 'We understand, plans change.',
  'step.options.option.form.title': 'Done',
  'step.options.option.form.reschedule': 'I want to reschedule',
  'step.options.option.form.cancel': 'Cancel',
  'step.options.reschedule.title': 'Pick a day and a time that works best for you.',
  'step.options.reschedule.subtitle': 'Lock in your inspection since slots fill up quickly.',
  'step.options.reschedule.quickest': 'Quickest',
  'step.options.reschedule.or': 'Or',
  'step.options.reschedule.time.label': 'Select Time',
  'step.options.reschedule.slot.booked': 'Fully Booked',
  'step.options.reschedule.slot.one.left': '1 slot left',
  'step.options.reschedule.timezone': 'All timezones in {timeZone}.',
  'step.options.reschedule.cancel.title': 'Are you sure you want to cancel?',
  'step.options.reschedule.cancel.description':
    'We know plans change from time to time. We are looking forward to inspecting your home so we can list quickly.',
  'step.options.reschedule.cancel.confirmation': 'Yes',
  'step.options.reschedule.cancel.reschedule': 'No, I actually want to reschedule',
  'step.options.reschedule.note.title':
    'We would love to understand how we can better serve you. What made you want to cancel your inspection?',
  'step.options.reschedule.note.placeholder': 'Let us know',
  'step.success.title': "You're all set!",
  'step.success.cancel.title': 'Successfully Cancelled',
  'step.success.description': 'Congrats! Complimentary Inspection rescheduled for',
  'step.success.cancel.description': 'Your Homeowner Advisor will reach out to follow up.',
  'step.success.confirmation': 'Done',
  'step.options.reschedule.slot.not.available':
    'Whoops! That inspection day and time is no longer available. Please pick another day and time.',
  'step.options.reschedule.cancel.error': "Whoops! We couln't cancel your inspection. Please try again.",
  'step.options.reschedule.error': "Whoops! We couln't reschedule your inspection. Please try again.",
};
