import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { required } from 'utils/validation';

export const VacantAvailabilitySection = ({ name }) => (
  <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.notCurrentlyVacantQuestion}>
    <Field
      component={InputFinalFormAdapter}
      name={`${name}.availableForRentComments`}
      placeholder={RENTAL_STATUS_TEXT.notCurrentlyVacantPlaceholder}
      textarea
      validate={required}
    />
  </FormLayout.Section>
);

VacantAvailabilitySection.propTypes = {
  name: PropTypes.string.isRequired,
};
