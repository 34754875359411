import { RefObject, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MediaGallery, Text, Toast, Tooltip } from '@belong/ui';
import { MaintenanceMediaModel, MaintenanceModel, MaintenancePaymentAgreementModel } from 'api/models';
import clsx from 'clsx';
import Money from 'components/Money/Money';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import { MaintenanceAccountResponsibility } from 'containercomponents/MaintenanceStatus/MaintenanceAccountResponsibility/MaintenanceAccountResponsibility';
import Space from 'corecomponents/Space/Space';
import { useRequestAccountResponsibility } from 'maintenance/hooks/useRequestAccountResponsibility';
import { getCostLabel } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/costs.utils';
import { updateMaintenanceConsent } from 'store/redux/maintenance/actions';
import { maintenanceCostStrings } from './maintenance-cost.constants';
import { useMaintenanceCostDisplayData } from './use-maintenance-cost-display-data';

export type MaintenanceCostProps = {
  task: MaintenanceModel;
  taskOptionNumber?: number;
  userRole?: 'Homeowner' | 'Resident';
  onConsentChange: () => Promise<void>;
  accountResponsibilityRef?: RefObject<HTMLDivElement>;
  showScheduleCall?: boolean;
  onScheduleHomecareSpecialistCall?: () => void;
  showItemPrices?: boolean;
  showQuoteImages?: boolean;
  showQuoteApprovalSection?: boolean;
  paddingHorizontal?: boolean;
  roundedTop?: boolean;
};

export const MaintenanceCost = ({
  task,
  taskOptionNumber,
  userRole = 'Homeowner',
  onConsentChange,
  accountResponsibilityRef,
  showScheduleCall,
  onScheduleHomecareSpecialistCall,
  showItemPrices = true,
  showQuoteImages = true,
  showQuoteApprovalSection = true,
  paddingHorizontal = true,
  roundedTop = false,
}: MaintenanceCostProps) => {
  const [isUpdateConsentSuccess, setIsUpdateConsentSuccess] = useState(false);
  const updateMaintenanceConsentMessage = useRef(maintenanceCostStrings.responsibilityConsentFail);
  const { financedPayment, isHandledBySmsAssist } = task;
  const {
    availableCost,
    displayResponsibleCost,
    displayResponsiblePercentage,
    otherResponsibleCost,
    otherResponsiblePercentage,
    additionalFees,
    subTotal,
    showUpfront,
    quoteImages,
    costItems,
  } = useMaintenanceCostDisplayData({ task, userRole });

  const repairRequestedByResident =
    task.requestType === 'Repair' && task.requestedBy === 'Resident' && !task.doesResidentClaimResponsibility;
  const shouldBeFullyPaidByHomeowner = task.requestedBy === 'Homeowner' || repairRequestedByResident;
  const hasPendingQuoteApproval = useRequestAccountResponsibility(userRole === 'Resident', task);
  const dispatch = useDispatch();

  // TODO: move to MaintenanceStatus on refactor
  const onAccountResponsibilitySubmit = async (values: MaintenancePaymentAgreementModel) => {
    setIsUpdateConsentSuccess(false);
    try {
      updateMaintenanceConsentMessage.current = values.isApproved
        ? maintenanceCostStrings.responsibilityConsentApprovalSuccess
        : showScheduleCall
        ? maintenanceCostStrings.responsibilityConsentRejectionSuccessSchedule
        : maintenanceCostStrings.responsibilityConsentRejectionSuccess;
      await dispatch(updateMaintenanceConsent(task.uniqueId, values));
      setIsUpdateConsentSuccess(true);
      await onConsentChange();
      if (!values.isApproved && onScheduleHomecareSpecialistCall) {
        onScheduleHomecareSpecialistCall();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div>
        {availableCost?.items.length > 0 && (
          <div>
            {!!availableCost.scopeOfWork && (
              <div className={clsx('flex flex-col gap-xs', paddingHorizontal && 'mx-sm')}>
                <Text className="whitespace-pre-wrap">{availableCost.scopeOfWork}</Text>
              </div>
            )}
            {showQuoteImages && quoteImages.length > 0 && (
              <div className={clsx('my-sm', paddingHorizontal && 'mx-sm')}>
                <MediaGallery<MaintenanceMediaModel> media={quoteImages} />
              </div>
            )}
            <div className={clsx('mb-lg relative', paddingHorizontal && 'mx-sm')}>
              <>
                {task.requestCategory !== 'InHomeRepairs' && (
                  <>
                    <NameValueTable
                      responsiveText={false}
                      boldValue
                      items={[
                        {
                          name: maintenanceCostStrings.tableItem,
                          value: maintenanceCostStrings.tableAmount,
                        },
                      ]}
                    />
                    <div className="border-0 border-b border-dashed my-sm border-b-gray" />
                  </>
                )}
                <NameValueTable
                  responsiveText={false}
                  noBold
                  items={costItems.map(({ cost, type, quantity, unitRate, description }) => {
                    let title = null;
                    let desc = description;
                    if (isHandledBySmsAssist) {
                      const descriptionParts = description.split('-');
                      title = descriptionParts.shift()?.trim();
                      desc = descriptionParts.join('-').trim();
                    }
                    return {
                      name:
                        type === 'Labor'
                          ? `${desc} (${quantity} ${quantity === 1 ? 'hour' : 'hours'} * $${unitRate}/hr)`
                          : desc,
                      value: showItemPrices ? <Money cash={cost} /> : undefined,
                      title,
                    };
                  })}
                />
              </>

              {additionalFees.length > 0 && !isHandledBySmsAssist && task.requestCategory !== 'InHomeRepairs' && (
                <>
                  <div className="border-0 border-b border-dashed my-sm border-b-gray" />
                  <NameValueTable
                    responsiveText={false}
                    boldValue
                    items={[
                      {
                        name: maintenanceCostStrings.tableSubtotal,
                        value: <Money cash={subTotal} />,
                      },
                    ]}
                  />
                  <NameValueTable
                    responsiveText={false}
                    noBold
                    items={additionalFees.map((cost) => ({
                      name: getCostLabel(cost.type),
                      value: showItemPrices ? <Money cash={cost.cost} /> : undefined,
                    }))}
                  />
                </>
              )}
              <div className="border-0 border-b border-dashed my-sm border-b-gray" />
              <NameValueTable
                responsiveText={false}
                boldValue
                items={[
                  {
                    name: task.actualCost ? (
                      <MaintenanceCostTotalTitle isInHomeRepair={task.requestCategory === 'InHomeRepairs'} />
                    ) : (
                      maintenanceCostStrings.tableEstTotal
                    ),
                    value: <Money cash={availableCost.totalCost} />,
                  },
                ]}
              />

              {otherResponsiblePercentage && (
                <NameValueTable
                  responsiveText={false}
                  boldValue
                  items={[
                    {
                      name: `${
                        userRole === 'Resident'
                          ? maintenanceCostStrings.tableUserRoleHomeowner
                          : maintenanceCostStrings.tableUserRoleResident
                      } Responsibility (${otherResponsiblePercentage}%)`,
                      value: <Money cash={otherResponsibleCost} />,
                    },
                  ]}
                />
              )}
              {!!displayResponsiblePercentage && displayResponsiblePercentage < 100 && (
                <NameValueTable
                  responsiveText={false}
                  boldValue
                  items={[
                    {
                      name: `${maintenanceCostStrings.tableResponsiblePercentage} (${displayResponsiblePercentage}%)`,
                      value: <Money cash={displayResponsibleCost} />,
                    },
                  ]}
                />
              )}
              {!displayResponsiblePercentage && (
                <NameValueTable
                  responsiveText={false}
                  boldValue
                  items={[
                    {
                      name: maintenanceCostStrings.tableResponsiblePercentageZero,
                      value: maintenanceCostStrings.tableResponsiblePercentageZeroValue,
                    },
                  ]}
                />
              )}
              {showUpfront && (
                <NameValueTable
                  responsiveText={false}
                  boldValue
                  items={[
                    {
                      name: maintenanceCostStrings.tableDueUpfront,
                      value: <Money cash={financedPayment?.dueNow} />,
                    },
                  ]}
                />
              )}
            </div>

            {hasPendingQuoteApproval && showQuoteApprovalSection && (
              <div ref={accountResponsibilityRef}>
                <MaintenanceAccountResponsibility
                  onSubmit={onAccountResponsibilitySubmit}
                  showScheduleCall={showScheduleCall}
                  shouldShowSharedExpenseOption={!shouldBeFullyPaidByHomeowner}
                  taskOptionNumber={taskOptionNumber}
                  roundedTop={roundedTop}
                />
              </div>
            )}
          </div>
        )}
        <Toast
          autocloseDuration={5000}
          isVisible={isUpdateConsentSuccess}
          onClose={() => setIsUpdateConsentSuccess(false)}
        >
          {updateMaintenanceConsentMessage.current}
        </Toast>
      </div>
    </>
  );
};

const MaintenanceCostTotalTitle = ({ isInHomeRepair }: { isInHomeRepair: boolean }) => (
  <div className="flex gap-2xs">
    <Text fontWeight="semibold">{maintenanceCostStrings.tableTotal}</Text>
    {!isInHomeRepair && (
      <Tooltip
        arrowProps={{ className: 'fill-dark-navy' }}
        contentProps={{ side: 'top', alignOffset: -48, align: 'start', sideOffset: 8 }}
        content={
          <div className="p-2sm rounded bg-dark-navy max-w-xl sm:max-w-4xl text-white">
            <Text className="whitespace-pre-wrap" fontWeight="semibold">
              {maintenanceCostStrings.tableTooltipTitle}
            </Text>
            <Space />
            <Text fontWeight="semibold">{maintenanceCostStrings.tableTooltipListTitle}</Text>
            <ul className="list-decimal pl-md">
              {maintenanceCostStrings.tableTooltipListItems.map((item: string, index: number) => (
                <li key={index}>
                  <Text>{item}</Text>
                </li>
              ))}
            </ul>
          </div>
        }
      />
    )}
  </div>
);
