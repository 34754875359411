import React, { Component } from 'react';
import { PAYMENTS_FEE_CONST } from '@belong/common';
import { Image, Spinner, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import Checkbox from 'components/Checkbox/Checkbox';
import Icon, { ICONS } from 'components/Icon/Icon';
import Selector from 'components/Selector/Selector';
import { AutoSplitNotice } from 'containercomponents/PaymentMethods/NewPaymentTypeSelector/components/AutoSplitNotice';
import { PaymentNotice } from 'containercomponents/PaymentMethods/NewPaymentTypeSelector/components/PaymentNotice';
import { PaymentMethodPrimarySelectionContainer } from 'containers/PaymentMethodPrimarySelectionContainer/PaymentMethodPrimarySelectionContainer';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { noop } from 'lodash-es';
import { PaymentMethod, PaymentType } from 'models/enums';
import PropTypes from 'prop-types';
import { PAYMENT_FLOW_STRINGS } from 'strings/payment-flow-modal.strings';
import String from '../../../components/String/String';
import styles from './NewPaymentTypeSelector.module.css';

const PFS = PAYMENT_FLOW_STRINGS;
const cx = classNames.bind(styles);

class NewPaymentTypeSelector extends Component {
  static propTypes = {
    selectedPaymentMethod: PropTypes.oneOf(Object.values(PaymentMethod)),
    forcePaymentMethod: PropTypes.oneOf(Object.values(PaymentMethod)),
    onAddAchInstantPaymentMethod: PropTypes.func.isRequired,
    onAddCreditCardPayment: PropTypes.func.isRequired,
    shouldShowAutoSplitPaymentMethod: PropTypes.bool,
    handleAutoSplitPaymentMethodSelection: PropTypes.func,
    handleAvailablePaymentMethodSelection: PropTypes.func,
    fullUser: PropTypes.object.isRequired,
  };

  static defaultProps = {
    selectedPaymentMethod: null,
    forcePaymentMethod: null,
    shouldShowAutoSplitPaymentMethod: false,
    handleAutoSplitPaymentMethodSelection: noop,
    handleAvailablePaymentMethodSelection: noop,
  };

  constructor(props) {
    super(props);

    function getDefaultSelected() {
      if (props.forcePaymentMethod) {
        return props.fullUser?.paymentMethods?.paymentMethods.find(
          (method) => method.paymentMethod === props.forcePaymentMethod
        );
      }

      return props.fullUser?.paymentMethods?.paymentMethods[0];
    }

    this.state = {
      showSpinner: false,
      selectedPaymentMethod: props.selectedPaymentMethod || props.forcePaymentMethod,
      paymentPartnerConsent: false,
      futurePaymentsConsent: false,
      selectedPaymentType: null,
      shouldShowPaymentsSelector: !props.shouldShowAutoSplitPaymentMethod,
      selectedAvailablePaymentMethod: getDefaultSelected() || null,
      shouldShowAvailablePaymentMethods:
        props.shouldShowAutoSplitPaymentMethod && props.fullUser.paymentMethods.paymentMethods.length > 0,
      autoSplitError: null,
    };
  }

  onPaymentMethodSelect = (key) => {
    const { onAddCreditCardPayment } = this.props;

    if (key === PaymentMethod.CreditCard) {
      this.setState({ selectedPaymentMethod: key }, onAddCreditCardPayment);
    } else {
      this.setState({ selectedPaymentMethod: key });
    }
  };

  onPaymentTypeSelect = (key) => {
    let stateProps = { selectedPaymentType: key, shouldShowPaymentsSelector: key === PaymentType.Payment };

    if (this.props.forcePaymentMethod === PaymentMethod.Ach) {
      stateProps = { ...stateProps, selectedPaymentMethod: null };
    }

    this.setState(stateProps);
  };

  onAvailablePaymentSelect = (accountId) => {
    const { fullUser, forcePaymentMethod } = this.props;
    const selectedAvailablePaymentMethod = fullUser.paymentMethods.paymentMethods.find((payment) => {
      if (forcePaymentMethod) {
        return payment.accountId === accountId && payment.paymentMethod === forcePaymentMethod;
      }

      return payment.accountId === accountId;
    });
    this.setState({ selectedAvailablePaymentMethod });
  };

  disableAgreeButton = () => {
    const { paymentPartnerConsent, futurePaymentsConsent } = this.state;

    if (paymentPartnerConsent && futurePaymentsConsent) {
      return false;
    }

    return true;
  };

  disableDoneButton = () => {
    const { selectedPaymentType, shouldShowAvailablePaymentMethods } = this.state;

    if (selectedPaymentType === PaymentType.AutoSplit) {
      return false;
    }

    if (selectedPaymentType === PaymentType.Payment && shouldShowAvailablePaymentMethods) {
      return false;
    }

    return true;
  };

  handleSelectPaymentPartnerConsent = (paymentPartnerConsent) => {
    this.setState({
      paymentPartnerConsent,
    });
  };

  handleSelectFuturePaymentsConsent = (futurePaymentsConsent) => {
    this.setState({
      futurePaymentsConsent,
    });
  };

  handleClickAgreeButton = () => {
    const { onAddAchInstantPaymentMethod } = this.props;

    onAddAchInstantPaymentMethod();
  };

  handleAddPaymentClick = () => {
    let stateProps = { shouldShowAvailablePaymentMethods: false };

    if (this.props.forcePaymentMethod === PaymentMethod.Ach) {
      stateProps = { ...stateProps, selectedPaymentMethod: PaymentMethod.Ach };
    }

    this.setState(stateProps);
  };

  handleSelectPrimaryMethod = async () => {
    const { handleAutoSplitPaymentMethodSelection, handleAvailablePaymentMethodSelection } = this.props;
    const { selectedAvailablePaymentMethod, selectedPaymentType } = this.state;
    if (this.state.selectedPaymentType === PaymentType.AutoSplit) {
      const addedPaymentMethod = await handleAutoSplitPaymentMethodSelection();

      if (Array.isArray(addedPaymentMethod)) {
        const error = addedPaymentMethod[0];
        if (error.message.includes('Yearly Payout Plan')) {
          this.setState({
            autoSplitError: 'Split It payment method can not be enabled because you are in a Yearly Payout Plan.',
          });
          setTimeout(() => {
            this.setState({ autoSplitError: null });
          }, 6000);
        }
      }
    } else if (selectedPaymentType === PaymentType.Payment && selectedAvailablePaymentMethod?.accountId) {
      handleAvailablePaymentMethodSelection(selectedAvailablePaymentMethod.accountId);
    }
  };

  renderSelectors() {
    const { selectedPaymentMethod, selectedAvailablePaymentMethod, shouldShowAvailablePaymentMethods } = this.state;
    const { forcePaymentMethod, fullUser } = this.props;

    if (shouldShowAvailablePaymentMethods) {
      return (
        <div className="mb-2xl">
          <PaymentMethodPrimarySelectionContainer
            paymentMethods={fullUser.paymentMethods.paymentMethods}
            selectedPaymentMethod={selectedAvailablePaymentMethod}
            onSelectPaymentMethod={this.onAvailablePaymentSelect}
            onAddPaymentClick={this.handleAddPaymentClick}
            forcePaymentMethod={forcePaymentMethod}
          />
        </div>
      );
    }

    return (
      <div className={cx(['payment-type-selector'])}>
        {!forcePaymentMethod && (
          <div>
            <div className={cx('title')}>How would you like to pay?</div>
            <Selector
              fluid
              selected={selectedPaymentMethod}
              onSelect={this.onPaymentMethodSelect}
              buttons={[
                {
                  label: (
                    <>
                      <div className={cx('mobile-tasks')}>
                        <String string="eCheck ACH" />
                      </div>
                      <div className={cx('small-label-text-wrapper')}>
                        <String string={`(${PAYMENTS_FEE_CONST.ACH_FEE} • 3-5 business days)`} />
                      </div>
                    </>
                  ),
                  icon: <Icon icon={ICONS.BANK_ACCOUNT.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.BANK_ACCOUNT.INVERSE} responsive />,
                  key: PaymentMethod.Ach,
                },
                {
                  label: (
                    <>
                      <div className={cx('mobile-tasks')}>
                        <String string="Credit card" />
                      </div>
                      <div className={cx('small-label-text-wrapper')}>
                        <String string={`(${PAYMENTS_FEE_CONST.CREDIT_CARD_FEE} Fee • Instant)`} />
                      </div>
                    </>
                  ),
                  icon: <Icon icon={ICONS.CREDIT_CARD.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.CREDIT_CARD.INVERSE} responsive />,
                  key: PaymentMethod.CreditCard,
                },
              ]}
            />
            <div className={cx('additional-fees-wrapper')}>
              <String string="Additional fees may apply for <span>credit cards</span> issued <span>outside the US.</span>" />
            </div>
          </div>
        )}
        {selectedPaymentMethod === PaymentMethod.Ach && (
          <div className={cx('consent-checkboxes-section')}>
            <div className={cx('belong-payment-partner-consent')}>
              <Checkbox onSelect={this.handleSelectPaymentPartnerConsent}>
                <span className={cx('belong-payment-partner-consent-text')}>
                  <span>{PFS.ach_type_selector_section.paymentPartnerConsent}&nbsp;</span>
                  <LinkBase target="_blank" a="https://www.dwolla.com/legal/tos/" className={cx('style-links')}>
                    Terms of Service
                  </LinkBase>
                  <span>&#32;and&#32;</span>
                  <LinkBase target="_blank" a="https://www.dwolla.com/legal/privacy/" className={cx('style-links')}>
                    Privacy Policy
                  </LinkBase>
                </span>
              </Checkbox>
            </div>
            <div className={cx('future-payments-consent')}>
              <Checkbox onSelect={this.handleSelectFuturePaymentsConsent}>
                <span className={cx('future-payments-consent-text')}>
                  {PFS.ach_type_selector_section.futurePaymentConsent}
                </span>
              </Checkbox>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderPaymentTypeSelector() {
    const { selectedPaymentType, autoSplitError } = this.state;
    const { forcePaymentMethod } = this.props;

    return (
      <div className={selectedPaymentType === null ? 'mt-sm md:mb-2xl mb-sm' : 'mt-sm'}>
        <Selector
          fluid
          selected={selectedPaymentType}
          onSelect={this.onPaymentTypeSelect}
          buttonClassName="md:flex-col flex-row items-center"
          iconClassName="self-center"
          labelClassName="text-center w-auto"
          mobileTag
          buttons={[
            {
              label: (
                <>
                  <p className="text-body font-semibold mt-xs md:mt-0 md:text-center text-left">Deducted From Rent</p>
                  <p className="text-p1 font-[400] mb-xs md:mb-0 md:text-center text-left">
                    Interest Applied · Installments
                  </p>
                </>
              ),
              icon: (
                <Image
                  src="homeowner-earnings/split-it-tile.svg"
                  alt="Split it"
                  className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                />
              ),
              iconSelected: (
                <Image
                  src="homeowner-earnings/split-it-tile-active.svg"
                  alt="Split it"
                  className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                />
              ),
              key: PaymentType.AutoSplit,
              tag: 'Recommended',
            },
            {
              label: (
                <>
                  <p className="text-body font-semibold">Upon Completion</p>
                  <p className="text-p1 font-[400] mb-xs md:mb-0 md:text-center text-left">FREE · In Full</p>
                </>
              ),
              icon: (
                <Image
                  src="homeowner-earnings/payment-tile.svg"
                  alt="Payment Methods"
                  className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                />
              ),
              iconSelected: (
                <Image
                  src="homeowner-earnings/payment-tile-active.svg"
                  alt="Payment Methods"
                  className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                />
              ),
              key: PaymentType.Payment,
            },
          ]}
        />
        {selectedPaymentType === PaymentType.Payment && <PaymentNotice forcePaymentMethod={forcePaymentMethod} />}
        {selectedPaymentType === PaymentType.AutoSplit && <AutoSplitNotice />}
        {autoSplitError && <Text className="text-red text-[14px] mb-sm">{autoSplitError}</Text>}
      </div>
    );
  }

  render() {
    const { shouldShowAutoSplitPaymentMethod } = this.props;
    const { shouldShowPaymentsSelector, showSpinner, selectedPaymentMethod } = this.state;

    return (
      <ModalLayout>
        <ModalLayout.Title>
          <Text variant="h2" fontWeight="semibold">
            {shouldShowAutoSplitPaymentMethod
              ? "Let's set up your Pro Services payment\u00a0method."
              : 'Adding a Payment\u00a0Method'}
          </Text>
        </ModalLayout.Title>
        {shouldShowAutoSplitPaymentMethod && (
          <ModalLayout.SubTitle>
            <div className="hidden md:visible">
              Choose the way you would like to pay for Pro Services.
              <br />
              You can change this at anytime.
            </div>
            <div className="md:hidden">
              Choose the way you would like to pay for Pro Services. You can change this at anytime.
            </div>
          </ModalLayout.SubTitle>
        )}
        <ModalLayout.Content>
          {showSpinner && <Spinner />}
          {shouldShowAutoSplitPaymentMethod && this.renderPaymentTypeSelector()}
          {shouldShowPaymentsSelector && this.renderSelectors()}

          <ModalLayout.BottomBar
            className="mt-0"
            fixedBottom
            ctaProps={{
              label: selectedPaymentMethod === PaymentMethod.Ach ? 'Agree & Continue' : 'Done',
              disabled:
                shouldShowAutoSplitPaymentMethod && selectedPaymentMethod !== PaymentMethod.Ach
                  ? this.disableDoneButton()
                  : this.disableAgreeButton(),
              onClick:
                selectedPaymentMethod === PaymentMethod.Ach
                  ? this.handleClickAgreeButton
                  : this.handleSelectPrimaryMethod,
            }}
          />
        </ModalLayout.Content>
      </ModalLayout>
    );
  }
}

export default NewPaymentTypeSelector;
