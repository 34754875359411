import Divider from 'components/Divider/Divider';
import { WearAndTearFeatureItem } from 'lease-signing-flow/components/wear-and-tear-feature-item/wear-and-tear-feature-item';
import { features } from 'lease-signing-flow/constants/wear-and-tear';

export const WearAndTearFeatureList = () => (
  <div className="flex flex-row mt-xl">
    {features.map((feature, index) => (
      <div className="flex flex-row flex-1 justify-around" key={feature.title}>
        <WearAndTearFeatureItem image={feature.image} title={feature.title} />
        {index !== features.length - 1 && (
          <div className="h-full relative w-[24px]">
            <div className="h-0 left-[-125px] absolute rotate-90 translate-x-[90px] translate-y-0 w-[96px] transform top-[104px]">
              <Divider />
            </div>
          </div>
        )}
      </div>
    ))}
  </div>
);
