import { InspectionModel } from 'api/models';
import { getApiActionTypes, getApiActionTypesValues } from 'store/redux/redux_utils';

/**
 * Action constants
 */
export const constants = {
  ...getApiActionTypes('FETCH_INSPECTIONS'),
};

/**
 * Actions
 */
export const fetchInspections = (involvedRole) => (dispatch) => {
  return dispatch({
    types: getApiActionTypesValues('FETCH_INSPECTIONS'),
    promise: async ({ client }) => client.get(`/inspections?involvedRole=${involvedRole}`),
    auth: true,
  });
};
export const fetchResidentInspections = () => async (dispatch) => {
  const data = await dispatch(fetchInspections('Resident'));
  return data;
};

export const fetchHomeownerInspections = () => async (dispatch) => {
  const data = await dispatch(fetchInspections('Homeowner'));
  return data;
};

/**
 * Reducer
 */
export const INSPECTION_REDUCER = 'inspection';

const initialState = {
  inspections: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.FETCH_INSPECTIONS_SUCCESS:
      return {
        ...state,
        inspections: action.result,
      };
    default:
      return state;
  }
}

/**
 * Selectors
 */
export const selectInspections = (state): InspectionModel[] => state[INSPECTION_REDUCER].inspections;
