import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { AvailableForRentOptions } from 'models/enums';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import Space from 'corecomponents/Space/Space';
import Condition from 'formcomponents/Condition/Condition';
import String from 'components/String/String';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';

export const AvailableForRentNotSureFields = ({ name }) => (
  <Condition is={AvailableForRentOptions.NotSure} when={`${name}.availableForRentOption`}>
    <Row>
      <Col xs={12}>
        <Space />
        <GreenInfoBox>
          <String string={RENTAL_STATUS_TEXT.notSureNote} />
        </GreenInfoBox>
      </Col>
    </Row>
  </Condition>
);

AvailableForRentNotSureFields.propTypes = {
  name: PropTypes.string.isRequired,
};
