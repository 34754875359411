import { __spreadArray } from '../node_modules/tslib/tslib.es6.mjs.js';

function getRandomSampleFromArray(_a) {
  var array = _a.array,
    sampleSize = _a.sampleSize;
  return __spreadArray([], array, true).sort(function () {
    return 0.5 - Math.random();
  }).slice(0, sampleSize);
}

export { getRandomSampleFromArray };
