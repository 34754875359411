import { __assign } from '../node_modules/tslib/tslib.es6.mjs.js';
import { extendTailwindMerge } from 'tailwind-merge';
import { spacing, widths, heights, palette, borderRadius, fontWeight, fontSize } from '../constants/theme.js';

var spacings = __assign(__assign(__assign({}, spacing), widths), heights);
var fontWeightKeys = Object.keys(fontWeight);
var fontSizeKeys = Object.keys(fontSize);
var twMerge = extendTailwindMerge({
  theme: {
    colors: Object.keys(palette),
    spacing: Object.keys(spacings),
    borderRadius: Object.keys(borderRadius)
  },
  classGroups: {
    'font-weight': [{
      font: fontWeightKeys
    }],
    'font-size': [{
      text: fontSizeKeys
    }]
  }
});

export { twMerge };
