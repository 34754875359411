import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { useDelayUnmount } from '@belong/common';
// import AskAQuestionModal from 'pages/HomeOwnerLP2019/AskAQuestionModal/AskAQuestionModal';
import ResumeModal from 'pages/HomeOwnerOnboarding/ResumeModal/ResumeModal';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { hideModal, showModal } from 'store/redux/modals/actions';
import { activeModalPropsSelector, activeModalSelector } from 'store/redux/modals/selectors';
import GenericErrorModal from './GenericErrorModal/GenericErrorModal';
import { HowItWorksModal } from './HowItWorksModal/HowItWorksModal';
import LightAccountCreationModal from './LightAccountCreationModal/LightAccountCreationModal';
import LoginModal from './LoginModal/LoginModal';
import ManualAchVerificationModal from './ManualAchVerificationModal/ManualAchVerificationModal';
import PaymentsModal from './PaymentsModal/PaymentsModal';
import SplitItInfoModal from './SplitItLearnMore/SplitItLearnMore';
// import TourModal from './TourModal/TourModal';
import { MODALS } from './modal.consts';

export const MODALS_MAP = {
  [MODALS.LOGIN]: LoginModal,
  [MODALS.GENERIC_ERROR]: GenericErrorModal,
  [MODALS.PAYMENT_MODAL]: PaymentsModal,
  [MODALS.PAYMENT_VERIFY_MODAL]: ManualAchVerificationModal,
  [MODALS.HOMEOWNER_ONBOARDING_RESUME_MODAL]: ResumeModal,
  [MODALS.LIGHT_ACCOUNT_CREATION]: LightAccountCreationModal,
  [MODALS.SPLIT_IT_LEARN_MORE]: SplitItInfoModal,
  [MODALS.HOW_IT_WORKS]: HowItWorksModal,

  // TODO: Marked for deletion! commented for smaller bundles
  //
  // [MODALS.TOUR_MODAL]: TourModal,
  // [MODALS.ASK_A_QUESTION_MODAL]: AskAQuestionModal,
};

function ModalManager({ history }) {
  // Have to use history from location, since router location is delayed by ReduxAsyncConnect
  const { location } = history;
  const dispatch = useDispatch();
  const activeModal = useSelector(activeModalSelector);
  const modalPropsFromRedux = useSelector(activeModalPropsSelector);

  const queryJson = qs.parse(location.search) || {};
  const shouldRenderChild = useDelayUnmount(MODALS[activeModal], 200);
  const lastActiveModal = useRef(activeModal);

  useEffect(() => {
    // If activeModal is in query params on load, set value in reducer to open modal.
    if (queryJson.activeModal) {
      const { activeModal: activeModalFromQuery, ...rest } = queryJson;
      const modalToDisplay = MODALS[activeModalFromQuery];
      dispatch(showModal(modalToDisplay, rest));
    }
  }, []);

  useEffect(() => {
    // This is to support changing routes inside a modal, since we close modal on route change,
    // we need to open again if still on query params.
    if (!activeModal && queryJson.activeModal) {
      const { activeModal: activeModalFromQuery, ...rest } = queryJson;
      const modalToDisplay = MODALS[activeModalFromQuery];

      dispatch(showModal(modalToDisplay, rest));
    }

    // Save last active modal in a ref.
    if (queryJson.activeModal || activeModal) {
      lastActiveModal.current = queryJson.activeModal || activeModal;
    }
  }, [queryJson.activeModal, activeModal]);

  function handleHide() {
    if (queryJson.activeModal === activeModal) {
      history.push({
        path: location.pathname,
      });
    } else {
      dispatch(hideModal(activeModal));
    }

    if (modalPropsFromRedux.onHide) {
      modalPropsFromRedux.onHide();
    }
  }

  const modalProps = {
    ...modalPropsFromRedux,
    history,
  };

  // Delay unmount to trigger the fade animation, which only happens when show changes.
  if (!shouldRenderChild) return null;

  const Modal = MODALS_MAP[activeModal || lastActiveModal.current];
  return <Modal show={activeModal} {...modalProps} onHide={handleHide} />;
}

ModalManager.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ModalManager);
