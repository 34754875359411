import { ACTIONS } from './constants';

export const PRO_REDUCER = 'pros';

const initialState = {
  pros: {},
  recurringPresets: [],
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_PRO_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        pros: {
          ...state.pros,
          [action.result.userId]: action.result,
        },
        // Checks to see if User Details Were Called
      };
    case 'FETCH_RECURRING_PRO_SERVICE_PRESETS_SUCCESS':
      return {
        ...state,
        recurringPresets: JSON.parse(action.result.attributes),
      };
    default:
      return state;
  }
}
