import { useRef, useState } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Text } from '@belong/ui';
import clsx from 'classnames';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import { GoogleMapMobile } from 'components/GoogleMap/HomesMap/GoogleMapMobile/GoogleMapMobile';
import googleMapReact from 'google-map-react';
import { formatString } from 'strings';
import { LastUpdated } from 'strings/common.strings';
import useSupercluster from 'use-supercluster';
import { ComparablesMap } from './comparables-map';
import styles from './comparables-step-map.module.css';

type ComparablesStepMapProps = {
  homes?: any[];
  lastUpdatedOn?: Date;
};

export function ComparablesStepMap({ homes, lastUpdatedOn }: ComparablesStepMapProps) {
  const [active, setActive] = useState(null);
  const [hover, setShowHover] = useState(null);
  const [modal, setModal] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const { width } = useWindowSize();
  const mapRef = useRef<any>();
  const [bounds, setBounds] = useState<number[]>([]);
  const [zoom, setZoom] = useState<number>(8);

  const points = homes.map((home, index) => ({
    type: 'Feature',
    properties: { cluster: false, category: 'homes', homeId: index, home },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(home?.address?.coordinates?.longitude || '0'),
        parseFloat(home?.address?.coordinates?.latitude || '0'),
      ],
    },
  }));
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const distanceToMouse = ({ x, y }, { x: mouseX, y: mouseY }) => {
    return Math.max(Math.abs(mouseX - x), Math.abs(mouseY - y + 32));
  };

  const onChildClick = (childIndex) => {
    if (active !== childIndex) {
      setActive(childIndex);
    } else {
      setActive(null);
    }
  };

  const onMainMapChildClick = (childIndex) => {
    if (!isMobile) {
      if (active !== childIndex) {
        setActive(childIndex);
      } else {
        setActive(null);
      }
    } else {
      setModal(true);
      setHasBeenOpened(true);
    }
  };

  const setAndRemoveCorrectZoom = (currentBounds) => {
    mapRef.current.setOptions({ maxZoom: 17 });
    // eslint-disable-next-line
    google.maps.event.addListenerOnce(mapRef.current, 'idle', () => {
      mapRef.current.setOptions({ maxZoom: null });
    });

    mapRef.current.fitBounds(currentBounds);
  };

  const handleApiLoaded = (map) => {
    mapRef.current = map;
    if (isMobile) {
      mapRef.current.setOptions({ draggableCursor: 'pointer' });
    }

    if (homes?.length) {
      // eslint-disable-next-line
      const bounds = new google.maps.LatLngBounds();

      homes.forEach((home) => {
        bounds.extend(
          // eslint-disable-next-line
          new google.maps.LatLng(
            parseFloat(home.address.coordinates?.latitude || 0),
            parseFloat(home.address.coordinates?.longitude || 0)
          )
        );
      });
      setAndRemoveCorrectZoom(bounds);
    }
  };

  function onChange(event: googleMapReact.ChangeEventValue) {
    setZoom(event.zoom);
    setBounds([event.bounds.nw.lng, event.bounds.se.lat, event.bounds.se.lng, event.bounds.nw.lat]);
  }

  function onClusterClick({ cluster, latitude, longitude }: any) {
    return () => {
      const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
      mapRef.current.setZoom(expansionZoom);
      mapRef.current.panTo({ lat: latitude, lng: longitude });
    };
  }

  return (
    <>
      <div
        className={clsx(
          'rounded border border-solid border-gray md:relative md:w-full',
          styles.comparablesStepMapContainer
        )}
      >
        {isMobile && hasBeenOpened && (
          <GoogleMapMobile
            onHide={() => {
              setModal(false);
              setActive(null);
              setShowHover(null);
            }}
            visible={modal}
          >
            <ComparablesMap
              hasPointer={isMobile}
              clusters={clusters}
              points={points}
              onClusterClick={onClusterClick}
              hover={hover}
              active={active}
              distanceToMouse={distanceToMouse}
              onChildMouseEnter={(childIndex) => {
                setShowHover(childIndex);
              }}
              onChildMouseLeave={() => {
                setShowHover(null);
              }}
              onChildClick={onChildClick}
              onClick={() => {
                if (isMobile) {
                  setModal(true);
                  setHasBeenOpened(true);
                }
                setActive(null);
              }}
              onChange={onChange}
              setActive={setActive}
              homes={homes}
              zoomControlPosition="RIGHT_TOP"
              handleApiLoaded={handleApiLoaded}
              hoverDistance={36}
            />
          </GoogleMapMobile>
        )}

        <ComparablesMap
          hasPointer={isMobile}
          clusters={clusters}
          points={points}
          onClusterClick={onClusterClick}
          hover={hover}
          active={active}
          gesture={isMobile ? 'cooperative' : 'greedy'}
          distanceToMouse={distanceToMouse}
          onChildMouseEnter={(childIndex) => {
            if (!isMobile) {
              setShowHover(childIndex);
            }
          }}
          onChildMouseLeave={() => {
            setShowHover(null);
          }}
          onChildClick={onMainMapChildClick}
          onClick={() => {
            if (isMobile) {
              setModal(true);
              setHasBeenOpened(true);
            } else {
              setActive(null);
            }
          }}
          onChange={onChange}
          setActive={setActive}
          zoomControlPosition="RIGHT_BOTTOM"
          homes={homes}
          handleApiLoaded={handleApiLoaded}
          hoverDistance={36}
        />
      </div>
      <div className="flex justify-end mt-xs">
        <Text fontWeight="semibold" className="text-dark-gray" variant="p1">
          {formatString(LastUpdated.last_updated_general, {
            date: <BDate time={lastUpdatedOn} formatType={DATE_TYPES.DOTTED_MEDIUM} />,
          })}
        </Text>
      </div>
    </>
  );
}
