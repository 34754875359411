import {
  PROPERTY_FEATURES_LIST,
  PROPERTY_FEATURE_CATEGORIES,
  SUPPORTED_PROPERTY_FEATURES_NAMES,
} from 'accounts/constants/property-features';

export function getPropertyFeaturesList(isInspectionPassed) {
  const featuresList = [...PROPERTY_FEATURES_LIST];

  if (isInspectionPassed) {
    return featuresList.map(feature => ({ ...feature, disabled: true }));
  }

  return featuresList;
}

export function getPropertyFeatureObject(feature) {
  const featureObject = {
    selected: true,
    ...feature,
  };

  return featureObject;
}

export function getPropertyFeatureAttributes(feature) {
  const featureProps: any = {
    isAvailable: true,
  };

  if (feature.uniqueId) {
    featureProps.uniqueId = feature.uniqueId;
  }

  if (feature.attributes) {
    featureProps.attributes = feature.attributes;
  }

  switch (feature.name) {
    case SUPPORTED_PROPERTY_FEATURES_NAMES.Pool.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = true;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Pool;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.Gym.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = false;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Fitness;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.Grill.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = false;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Cooking;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.Tennis.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = false;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Fitness;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.Basketball.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = false;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Fitness;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.Laundry.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = true;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Laundry;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.ChargingStation.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = false;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Parking;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.Jacuzzi.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = true;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Pool;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.Mailroom.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = false;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Safety;
      return { ...featureProps };
    }

    case SUPPORTED_PROPERTY_FEATURES_NAMES.SteamRoom.name: {
      featureProps.name = feature.name;
      featureProps.isAppliance = true;
      featureProps.category = PROPERTY_FEATURE_CATEGORIES.Pool;
      return { ...featureProps };
    }

    default:
  }
}
