import React from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { useWindowSize } from 'hooks/useWindowSize';
import { moveOutOptionSelectors } from 'strings/rental-status';
import { required } from 'utils/validation';
import Field from 'components/Field/Field';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';

export const MoveOutOptionsFields = ({
  name,
  buttons = moveOutOptionSelectors,
  type = SELECTOR_TYPES.MEDIUMTEXTBUTTON,
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  const buttonsWithoutBreakLineOnMobile = isMobile
    ? buttons.map((button) => ({
        key: button.key,
        label: button.label.replace('<br />', ''),
      }))
    : buttons;

  return (
    <Row>
      <Col xs={12}>
        <Field
          buttons={buttonsWithoutBreakLineOnMobile}
          component={SelectorFinalFormAdapter}
          fluid
          name={`${name}.moveoutOption`}
          type={type}
          validate={required}
        />
      </Col>
    </Row>
  );
};

MoveOutOptionsFields.propTypes = {
  buttons: PropTypes.array,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(SELECTOR_TYPES),
};
