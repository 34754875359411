import type { InspectionAvailableTime } from 'accounts/types';
import { addDays, isAfter, isThursday, eachDayOfInterval, differenceInCalendarDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';


type GetInspectionValidTimeItemsParams = {
  inspectionValidTimes: InspectionAvailableTime[];
  inspectionValidTimesThreeDaysFromNow: InspectionAvailableTime[];
  inspectionValidTimesTwoDaysFromNow: InspectionAvailableTime[];
  selectedDate: Date;
  timeZone: string;
};

/**
 * Get the inspection valid time items for a given date to be used in a dropdown.
 */
export function getInspectionValidTimeItems({
  inspectionValidTimes,
  inspectionValidTimesThreeDaysFromNow,
  inspectionValidTimesTwoDaysFromNow,
  selectedDate,
  timeZone,
}: GetInspectionValidTimeItemsParams): InspectionAvailableTime[] {
  const zonedToday = utcToZonedTime(new Date(), timeZone);

  if (isThursday(zonedToday) && differenceInCalendarDays(selectedDate, zonedToday) === 3) {
    return inspectionValidTimesThreeDaysFromNow;
  }

  return differenceInCalendarDays(selectedDate, zonedToday) === 2
    ? inspectionValidTimesTwoDaysFromNow
    : inspectionValidTimes;
}

type GetInspectionPreferredDatesParams = {
  availableDates: {
    [key: string]: { key: string; value: string }[];
  };
  timeZone: string;
};

/**
 * Get the inspection preferred dates in order to encourage the homeowner to inspect the home as soon as possible.
 * If the day after tomorrow has no available times (because is 6 P.M., for example),
 * we add one more day to the range because there always has to be 9 preferred days.
 * Following the same reasoning, if it is Thursday and there are no available times for Sunday
 * (note the 72-hour window on that day), we add one more day to the range to keep the same 9-day behaviour.
 */
export function getInspectionPreferredDates({ availableDates, timeZone }: GetInspectionPreferredDatesParams) {
  const zonedToday = utcToZonedTime(new Date(), timeZone);
  const tomorrow = addDays(zonedToday, 1);

  if (isThursday(zonedToday)) {
    const nextSundayDates = availableDates[addDays(zonedToday, 3).toISOString().split('T')[0]];
    const elevenOrNineDaysFromTomorrow = !!nextSundayDates ? addDays(tomorrow, 9) : addDays(tomorrow, 11);

    return eachDayOfInterval({ start: tomorrow, end: elevenOrNineDaysFromTomorrow });
  }

  const dayAfterTomorrow = availableDates[addDays(zonedToday, 2).toISOString().split('T')[0]];
  const nineOrTenDaysFromTomorrow = !!dayAfterTomorrow ? addDays(tomorrow, 9) : addDays(tomorrow, 10);

  return eachDayOfInterval({ start: tomorrow, end: nineOrTenDaysFromTomorrow });
}
