import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class MetaNoIndex extends Component {
  render() {
    return (
      <Helmet>
        <meta name="robots" content="noindex, nofollow, noarchive" />
      </Helmet>
    );
  }
}
