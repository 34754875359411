import React from 'react';
import PropTypes from 'prop-types';

export const ApiClientContext = React.createContext();

export function ApiClientProvider({ children, apiClient }) {
  return <ApiClientContext.Provider value={apiClient}>{children}</ApiClientContext.Provider>;
}

ApiClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
  apiClient: PropTypes.object.isRequired,
};
