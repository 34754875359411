import { Fragment } from 'react';

import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import { HEAT_FEATURE_STRINGS } from 'accounts/constants/strings/heat.strings';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { HeatFeatureTypes } from 'models/enums';
import PropTypes from 'prop-types';
import { required } from 'utils/validation';

export function HeatFormFields({ name, disabled }) {
  return (
    <Fragment>
      <div className="mt-sm mb-sm">
        <Field
          name={`${name}.attributes.heatLocation`}
          component={InputFinalFormAdapter}
          validate={required}
          placeholder={HEAT_FEATURE_STRINGS.inputPlaceholder}
          disabled={disabled}
          applyDisabledStyle={disabled}
        />
      </div>
      <FormLayout.Section firstSection sectionTitle={HEAT_FEATURE_STRINGS.heatherTitle} separatorType="none">
        <Row>
          <Col md={6}>
            <Field
              fluid
              name={`${name}.attributes.heatType`}
              component={SelectorFinalFormAdapter}
              validate={required}
              type={SELECTOR_TYPES.SMALLTEXTBUTTON}
              buttons={[
                {
                  label: HeatFeatureTypes.Electric,
                  key: HeatFeatureTypes.Electric,
                },
                {
                  label: HeatFeatureTypes.Gas,
                  key: HeatFeatureTypes.Gas,
                },
              ]}
              disabled={disabled}
            />
          </Col>
        </Row>
      </FormLayout.Section>
    </Fragment>
  );
}

HeatFormFields.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

HeatFormFields.defaultProps = {
  name: '',
  disabled: false,
};
