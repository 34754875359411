import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { PATHS } from '@belong/common';
import { Loading } from 'components/Loading/Loading';
import { MaintenanceRecurrentSubtask } from 'pages/Accounts/Components/maintenance-recurrence/maintenance-recurrence-subtask';
import {
  useMaintenanceRecurrenceSubtaskDetails,
  useMaintenanceRecurrenceTaskDetails,
} from 'pages/Accounts/Components/maintenance-recurrence/maintenance-recurrence.hooks';
import { fetchRecurrentMaintenanceByTaskId, fetchHOTaskByTaskId } from 'store/redux/maintenance/actions';

type MaintenanceRecurrenceSubtaskRouteParams = {
  homeUniqueId: string;
  recurrenceType: string;
  subtaskId: string;
};

type MaintenanceRecurrentSubtaskComponent = {
  fetchMasterTask: () => void;
  fetchTask: () => void;
  history: RouteComponentProps['history'];
};

export const MaintenanceRecurrentSubtaskComponent = ({
  fetchMasterTask,
  fetchTask,
  history,
}: MaintenanceRecurrentSubtaskComponent) => {
  const { homeUniqueId, recurrenceType, subtaskId } = useParams<MaintenanceRecurrenceSubtaskRouteParams>();
  const { taskDetails: masterTaskDetails, isLoading: isLoadingTaskDetails } = useMaintenanceRecurrenceTaskDetails({
    homeUniqueId,
    recurrenceType,
    fetchTask: fetchMasterTask,
  });
  const { subtaskDetails, isLoading: isLoadingSubtaskDetails } = useMaintenanceRecurrenceSubtaskDetails({
    subtaskId,
    fetchTask,
  });

  const navigateBack = useCallback(() => {
    history.push(`${PATHS.HOMEOWNER_ACCOUNT_RECURRING_MAINTENANCE}/${homeUniqueId}/${recurrenceType}`);
  }, [history, homeUniqueId, recurrenceType]);

  if (isLoadingTaskDetails || isLoadingSubtaskDetails) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <MaintenanceRecurrentSubtask subtask={subtaskDetails} masterTask={masterTaskDetails} onClosePress={navigateBack} />
  );
};

const mapDispatchToProps = {
  fetchMasterTask: fetchRecurrentMaintenanceByTaskId,
  fetchTask: fetchHOTaskByTaskId,
};

export default connect(null, mapDispatchToProps)(MaintenanceRecurrentSubtaskComponent);
