export const MODALS = {
  LOGIN: 'LOGIN',
  GENERIC_ERROR: 'GENERIC_ERROR',
  PAYMENT_MODAL: 'PAYMENT_MODAL',
  PAYMENT_VERIFY_MODAL: 'PAYMENT_VERIFY_MODAL',
  HOMEOWNER_ONBOARDING_RESUME_MODAL: 'HOMEOWNER_ONBOARDING_RESUME_MODAL',
  TOUR_MODAL: 'TOUR_MODAL',
  LIGHT_ACCOUNT_CREATION: 'LIGHT_ACCOUNT_CREATION',
  HOW_IT_WORKS: 'HOW_IT_WORKS',
  ASK_A_QUESTION_MODAL: 'ASK_A_QUESTION_MODAL',
  SPLIT_IT_LEARN_MORE: 'SPLIT_IT_LEARN_MORE',
};
