import { useState, Suspense, lazy } from 'react';
import { useIsClientReady } from '@belong/common';
import classNames from 'classnames/bind';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import { ConsentCheckbox } from 'components/PDFViewer/ConsentCheckbox';
import { DownloadLink } from 'components/PDFViewer/DownloadLink';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import styles from './DocumentSignModal.module.css';

const PDFViewer = lazy(() => import('components/PDFViewer/PDFViewer'));

const cx = classNames.bind(styles);

const DocumentSignModal = ({
  show,
  onHide,
  onSign,
  loading,
  title,
  subTitle,
  file,
  defaultConsent,
  htmlText,
  htmlTextOnDownloadClick,
}) => {
  const isClientReady = useIsClientReady();
  const [consent, setConsent] = useState(defaultConsent);
  const onConsent = (newConsent) => setConsent(newConsent);

  const { width, height } = useWindowSize();

  const vh = height * 0.01;
  const isMobile = width <= BREAKPOINTS_WIDTHS.LG;

  return (
    <ModalV2 onHide={onHide} showSpinner={loading} show={show} noPadding>
      <ModalLayout>
        <ModalLayout.Title>{title}</ModalLayout.Title>
        <ModalLayout.SubTitle>{subTitle}</ModalLayout.SubTitle>
        {htmlText ? <DownloadLink onClick={htmlTextOnDownloadClick} /> : <DownloadLink file={file} />}

        <ModalLayout.FullScreen style={isMobile ? { height: 100 * vh - 500 } : {}}>
          {htmlText ? (
            <div className={cx('document-wrapper')}>
              <div dangerouslySetInnerHTML={{ __html: htmlText?.match(new RegExp('<body(.*)>(.*)</body>'))[0] }} />
            </div>
          ) : (
            isClientReady && (
              <Suspense fallback={<div>Loading...</div>}>
                <PDFViewer file={file} />
              </Suspense>
            )
          )}
        </ModalLayout.FullScreen>

        <ModalLayout.Content>
          <ModalLayout.BottomBar
            leftSideNode={<ConsentCheckbox onSelect={onConsent} useOnChangeFormToHandle value={consent} />}
            fixedBottom
            reverseOrderOnMobile
            ctaProps={{
              label: 'Sign',
              disabled: !consent,
              mobileFixed: true,
              onClick: async () => {
                try {
                  await onSign();
                } catch (err) {
                  console.err(err);
                }
                setConsent(false);
              },
            }}
          />
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
};

DocumentSignModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSign: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  defaultConsent: PropTypes.bool,
  htmlText: PropTypes.string,
  htmlTextOnDownloadClick: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

DocumentSignModal.defaultProps = {
  defaultConsent: false,
  htmlText: '',
  htmlTextOnDownloadClick: () => {},
  file: null,
};

export default DocumentSignModal;
