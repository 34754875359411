import React from 'react';
import { Image } from '@belong/ui';
import classNames from 'classnames/bind';
import { getHomeListPrice } from 'utils/mapUtils';
import styles from './comparable-marker.module.css';

type ComparableMarkerProps = {
  home: any;
  isHover: boolean;
};

const cx = classNames.bind(styles);
const selectedMarkerSrc = '/icons/map/selected_home_marker.png';
const markerSrc = '/icons/map/home_marker.png';

export function ComparableMarker({ isHover, home }: ComparableMarkerProps) {
  return (
    <div className={cx({ hover: isHover })}>
      <div className={cx('comparable-home-marker', { hover: isHover })}>
        <Image
          src={isHover ? selectedMarkerSrc : markerSrc}
          className={cx('listing-icon', { hover: isHover })}
          alt=""
        />
        <div className={cx('comparable-home-text', { hover: isHover })}>{getHomeListPrice(home.rentAmount)}</div>
      </div>
    </div>
  );
}
