import React from 'react';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Money from 'components/Money/Money';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import Space from 'corecomponents/Space/Space';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import PropTypes from 'prop-types';

const NextLease = ({ nextLease }) => {
  if (!nextLease) {
    return null;
  }

  const nextLeaseInfo = nextLease.basicInfo;

  return (
    <AccountPageSection
      title="Upcoming Lease"
      content={
        <>
          <NameValueTable
            items={[
              {
                name: 'Rented',
                value: <BDate time={nextLeaseInfo.leaseStartOn} formatType={DATE_TYPES.DOTTED_MEDIUM} />,
              },
              { name: 'Lease Term', value: `${nextLeaseInfo.leaseTerm} months` },
              { name: 'Monthly Payment', value: <Money cash={nextLeaseInfo.rentAmount} /> },
            ]}
            noBold
          />
          {nextLeaseInfo.leaseUrl && (
            <>
              <Space />
              <div className="flex justify-end">
                <IconButton
                  href={nextLeaseInfo.leaseUrl}
                  target="_blank"
                  align={ALIGN_TYPES.LEFT}
                  label="VIEW LEASE"
                  icon={GENERAL_ICONS.FRONT_ARROW}
                />
              </div>
            </>
          )}
        </>
      }
    />
  );
};

NextLease.propTypes = {
  nextLease: PropTypes.object.isRequired,
};

export default NextLease;
