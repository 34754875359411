export const getApiActionTypes = (prefix) => ({
  [`${prefix}_REQUEST`]: `${prefix}_REQUEST`,
  [`${prefix}_SUCCESS`]: `${prefix}_SUCCESS`,
  [`${prefix}_FAILURE`]: `${prefix}_FAILURE`,
});

export const getApiActionTypesValues = (prefix) => [`${prefix}_REQUEST`, `${prefix}_SUCCESS`, `${prefix}_FAILURE`];

// Temp function to promisify actions that don't have endpoints
export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
