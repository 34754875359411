import React, { Component } from 'react';
import { IconCaretUp } from '@belong/icons';
import { Button, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import styles from './CollapsibleTitle.module.css';

const cx = classNames.bind(styles);

export const RightIcon = ({ isOpen }) => {
  return isOpen ? (
    <div className={cx('format-icon')}>
      <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
    </div>
  ) : (
    <div className={cx('rotate-icon')}>
      <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
    </div>
  );
};
RightIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default class CollapsibleTitle extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    titleText: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, titleText, handleClick } = this.props;

    return (
      <div className="w-full" onClick={handleClick}>
        <div className="flex justify-between items-center w-full">
          <Text className="capitalize" fontWeight="semibold" variant="h3">
            {titleText}
          </Text>
          <Button variant="text">
            {isOpen ? <IconCaretUp width={13} /> : <IconCaretUp className="rotate-180" width={13} />}
          </Button>
        </div>
      </div>
    );
  }
}
