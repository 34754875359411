import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Money from 'components/Money/Money';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { BillStatus } from 'models/enums/index';
import PropTypes from 'prop-types';
import { PAYMENT_LIST_MODAL } from 'strings/payment-list-modal';
import styles from './PaymentFoldSuccess.module.css';

const getTypeofItem = (itemType) => {
  if (itemType === 'Deposit') {
    return 'deposit';
  } else if (itemType === 'Rent') {
    return 'rent';
  }

  return 'default';
};

const cx = classNames.bind(styles);

const PLM = PAYMENT_LIST_MODAL.fold;

export default class PaymentFoldSuccess extends Component {
  static propTypes = {
    amount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    isHomeowner: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
  };

  render() {
    const { description, isHomeowner, amount, status, item } = this.props;

    const itemString = getTypeofItem(item.itemType);
    return (
      <div className={cx('paymentFoldSuccess')}>
        {status === BillStatus.Paid && <ModalLayout.Title label={PLM[itemString].success_title} />}
        {status === BillStatus.Refunded && <ModalLayout.Title label={description} />}
        {status === BillStatus.Initiated && <ModalLayout.Title label={PLM[itemString].initiated_title} />}
        {status === BillStatus.Unpaid && (
          <ModalLayout.Title
            label={isHomeowner ? PLM[itemString].pending_homeowner_title : PLM[itemString].pending_title}
          />
        )}
        {status === BillStatus.PartialPayment && (
          <ModalLayout.Title
            label={isHomeowner ? PLM[itemString].pending_homeowner_title : PLM[itemString].pending_title}
          />
        )}
        {status === BillStatus.Failed && (
          <>
            <ModalLayout.Title label={PLM[itemString].failure_title} />
          </>
        )}
        <Space value={SPACE_TYPES.XS} />

        <h1>
          <Money cash={amount} />
        </h1>

        <div className={cx('bold')}>{/* Payment Id: {paymentId} */}</div>

        {/*
          PENDING DATE INFO
          {success && <div className={cx('bold')}>
            {getString(
              PLM.duetext,
              { date: formatDate(date, DATE_FORMATS.DAY_MONTH) }
            )}
          </div>}
          {!success && <div className={cx('bold')}>
            {getString(
              PLM.duetextfailure,
              { date: formatDate(date, DATE_FORMATS.DAY_MONTH) }
            )}
          </div>} */}
      </div>
    );
  }
}
