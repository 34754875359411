import React, { useState } from 'react';
import { IconCaretDown } from '@belong/icons';
import { ButtonBase, Text } from '@belong/ui';
import { BillInfoDetailModel } from 'api/models';
import { MonthlyStatementDetailInfoModel } from 'api/models.v2';
import cx from 'classnames';
import GrayBorderedBox from 'components/GrayBorderedBox/GrayBorderedBox';
import AccountPageSection from '../Pages/AccountPage/AccountPageSection/AccountPageSection';
import { EARNINGS_STRINGS } from '../Pages/YourPortfolio/Earnings/earnings.strings';
import { PastMonth } from './past-month/past-month';

type PastMonthsSectionProps = {
  balances: MonthlyStatementDetailInfoModel[];
  selectedHome: string;
  hasManyHomes: boolean;
  handleShowPastMonthModal: (transaction: BillInfoDetailModel) => void;
  isResident?: boolean;
  address?: string;
};

type Ref = HTMLDivElement;

function getAllDetails(months, selectedHome) {
  const details = [];

  months?.forEach((month) => {
    let homeDetails = [];

    if (selectedHome === 'all') {
      homeDetails = [...month.homesDetails];
    } else {
      homeDetails.push(month.homesDetails[0]);
    }

    details.push(
      ...homeDetails.map((detail) => ({
        ...detail,
        monthSummaryInfo: month.monthSummaryInfo,
      }))
    );
  });

  details.sort((a, b) => {
    if (a.monthSummaryInfo.year < b.monthSummaryInfo.year) {
      return 1;
    }

    if (a.monthSummaryInfo.year > b.monthSummaryInfo.year) {
      return -1;
    }

    if (a.monthSummaryInfo.month < b.monthSummaryInfo.month) {
      return 1;
    }

    if (a.monthSummaryInfo.month > b.monthSummaryInfo.month) {
      return -1;
    }

    return 1;
  });

  return details;
}

export const PastMonthsSection = React.forwardRef<Ref, PastMonthsSectionProps>(function PastMonthsSection(
  { balances, selectedHome, hasManyHomes, handleShowPastMonthModal, address = '', isResident = false },
  ref
) {
  const details = getAllDetails(balances, selectedHome);

  const [showAllItems, setShowAllItems] = useState(false);
  const months = details.slice(0, showAllItems ? details.length : 3);
  const showMoreOrLessLabel =
    (months.length === 3 && details.length <= 3) || months.length === details.length
      ? EARNINGS_STRINGS['show_less.label']
      : EARNINGS_STRINGS['show_more.label'];

  function handleToggleShowMore() {
    if (months.length === 3 && details.length > 3) {
      setShowAllItems(true);
    } else {
      setShowAllItems(false);
    }
  }

  return (
    <AccountPageSection
      title={EARNINGS_STRINGS['section.past_months.title']}
      content={
        <div
          className={cx({
            'transition-all ease-linear duration-hover': true,
          })}
          ref={ref}
        >
          {!details?.length ? (
            <GrayBorderedBox>{EARNINGS_STRINGS['section.transaction.no_history']}</GrayBorderedBox>
          ) : (
            months.map((month) => (
              <PastMonth
                key={`${month.homeId}-${month.monthSummaryInfo.year}-${month.monthSummaryInfo.month}`}
                detail={month}
                handleShowPastMonthModal={handleShowPastMonthModal}
                showAddress={!isResident && hasManyHomes}
                address={address}
              />
            ))
          )}
          {details?.length > 3 ? (
            <div className="text-right mb-sm">
              <ButtonBase onClick={handleToggleShowMore}>
                <footer className="flex justify-end mt-sm">
                  <Text fontWeight="semibold">{showMoreOrLessLabel}</Text>
                  <IconCaretDown
                    width={15}
                    className={cx('ml-xs transition-transform duration-hover ease-linear', {
                      'rotate-180': showMoreOrLessLabel === EARNINGS_STRINGS['show_less.label'],
                    })}
                  />
                </footer>
              </ButtonBase>
            </div>
          ) : null}
        </div>
      }
    />
  );
});
