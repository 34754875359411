import { RescheduleAppointmentOptionsStep } from 'accounts/components/reschedule-appointment-options-step/reschedule-appointment-options-step';
import { RescheduleAppointmentSuccessStep } from 'accounts/components/reschedule-appointment-success-step/reschedule-appointment-success-step';
import type { RescheduleAppointmentStep, RescheduleAppointmentStepKeys } from 'accounts/types';

export const STEPS_KEYS: Record<RescheduleAppointmentStepKeys, RescheduleAppointmentStepKeys> = {
  'options-form': 'options-form',
  success: 'success',
};

export const STEPS: RescheduleAppointmentStep[] = [
  {
    id: 'options-form',
    Component: RescheduleAppointmentOptionsStep,
  },
  {
    id: 'success',
    Component: RescheduleAppointmentSuccessStep,
  },
];
