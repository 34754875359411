import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import * as Scroll from 'react-scroll';
import { BASE_PATHS } from '@belong/common';
import Icon, { ICONS } from 'components/Icon/Icon';
import Input from 'components/Input/Input';
import { LABEL_COLOR_TYPES } from 'components/Label/Label';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import FormLayout from 'layouts/FormLayout/FormLayout';
import Home from 'models/home/Home';
import HomeFeatures from 'pages/HousePage/HomeFeatures/HomeFeatures';
import { QUERY_PARAMS } from 'pages/PostInspectionFlow/utils';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getImprovementsSpecialist } from '../utils';

const { Element } = Scroll;

const propTypes = {
  stepResource: PropTypes.object.isRequired,
  stepData: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};

const Features = ({ stepResource, stepData, onSaveAndNext, stepConfigs, stepConfig }) => {
  const { homeListingDetailsModel } = stepResource;
  const inputRef = useRef(null);
  const [missedAnything, setMissedAnything] = useState(stepData?.neighborhoodDetails?.additionalFeaturesPostInspection);
  const history = useHistory();

  const areNonHomeowerFixesStepsCompleted = stepConfigs
    ?.filter((step) => step.key !== 'homeowner-fixes' && !step.isHidden)
    .every((step) => step.status === 'Completed');

  /**
   * The homeowner cannot go back to this step once the report is approved.
   */
  if (areNonHomeowerFixesStepsCompleted) {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${stepConfig.home.propertyId}/setup-flow?${QUERY_PARAMS.REPORT_APPROVED}=true`
    );

    return null;
  }

  useEffect(() => {
    setMissedAnything(stepData?.neighborhoodDetails?.additionalFeaturesPostInspection);
  }, [stepData]);

  const formattedAssessedBy = getImprovementsSpecialist(homeListingDetailsModel);

  const home = new Home(stepResource.homeListingDetailsModel);
  const { featuresMediaSectionNames = [], qualities = [] } = home;

  const uniqueQualities = qualities
    .filter((quality) => quality.level === 'Good')
    .map((quality) => ({
      text: quality.type,
      labelColor: LABEL_COLOR_TYPES.GREEN,
      labelText: formatString(POST_INSPECTION_FLOW_STRINGS['features.labeled_list.qualities.label_text']),
    }));

  const quirks = qualities
    .filter((quality) => quality.level === 'Bad')
    .map((quality) => ({
      text: quality.type,
      labelColor: LABEL_COLOR_TYPES.GRAY,
      labelText: formatString(POST_INSPECTION_FLOW_STRINGS['features.labeled_list.quirks.label_text']),
    }));

  const handleSubmit = (values) => {
    onSaveAndNext({
      ...values,
      neighborhoodDetails: { ...values?.neighborhoodDetails, additionalFeaturesPostInspection: missedAnything },
    });
  };

  const dividerItems = [
    {
      icon: <Icon icon={ICONS.GAS.DEFAULT} responsive />,
      title: String(featuresMediaSectionNames.length),
      subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['features.divider.features_and_appliances'], {
        br: <br />,
      }),
    },
  ];

  if (uniqueQualities?.length) {
    dividerItems.push({
      icon: <Icon icon={ICONS.QUALITY_DOCUMENT.DEFAULT} responsive />,
      title: String(uniqueQualities.length),
      subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['features.divider.unique_qualities'], {
        br: <br />,
      }),
    });
  }

  dividerItems.push({
    icon: <Icon icon={ICONS.SAFE.DEFAULT} responsive />,
    title: String(quirks.length),
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['features.divider.unique_quirks'], { br: <br /> }),
  });

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={formatString(POST_INSPECTION_FLOW_STRINGS['features.title'])}
      employee={formattedAssessedBy}
      isLogoVisible={false}
      showCityBackground
    >
      <div className="md:hidden overflow-hidden w-0 h-0">
        <PostInspectionContentLayout.CtaButton onClick={() => handleSubmit(stepData)} displayfixed>
          {formatString(POST_INSPECTION_FLOW_STRINGS['features.next'])}
        </PostInspectionContentLayout.CtaButton>
      </div>
      <div className="mt-lg md:mt-2xl">
        <HomeFeatures home={home} showMedia={false} />
      </div>

      <div className="mt-2xl">
        <FormLayout.Section sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['features.form_section.title'])}>
          <Space value={SPACE_TYPES.SM} />
          <Input
            inputRef={inputRef}
            name="missedAnything"
            placeholder={formatString(POST_INSPECTION_FLOW_STRINGS['features.form_section.placeholder'])}
            value={missedAnything}
            onChange={(event) => {
              const { value } = event.target;
              setMissedAnything(value);
            }}
          />
        </FormLayout.Section>
        <Element name="missedAnything" />
        <div className="items-center flex-col hidden md:flex">
          <PostInspectionContentLayout.CtaButton onClick={() => handleSubmit(stepData)}>
            {formatString(POST_INSPECTION_FLOW_STRINGS['features.next'])}
          </PostInspectionContentLayout.CtaButton>
        </div>
      </div>
    </PostInspectionContentLayout>
  );
};

Features.propTypes = propTypes;

export default Features;
