import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PropertyType } from 'models/enums';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';

export function useMultiUnit() {
  const dispatch = useDispatch();
  const { propertyId } = useParams<any>();
  const properties = useSelector(selectAccountProperties);
  const [isMultiUnit, setIsMultiUnit] = useState(false);

  useEffect(() => {
    if (properties?.length) {
      const selectedProperty = properties?.find((property) => property.basicInfo.propertyId === propertyId);
      const propertyMultiUnit = selectedProperty?.basicInfo.propertyType === PropertyType.MultiUnit;

      setIsMultiUnit(propertyMultiUnit);
    }
  }, [dispatch, properties, propertyId]);

  return { isMultiUnit };
}
