import React from 'react';
import { useSelector } from 'react-redux';
import { Image } from '@belong/ui';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Icon, { ICONS } from 'components/Icon/Icon';
import { selectSettings } from 'store/redux/settings';

export const AutoSplitNotice = () => {
  const settings = useSelector(selectSettings);
  const interestRateValue = settings?.commerceSettings?.splitItSettings?.interestRateValue;
  return (
    <div className="bg-green-translucent-light p-sm rounded border border-solid border-green mt-sm mb-2xl">
      <div className="flex flex-col sm:flex-row items-center mb-sm">
        <Image src="homeowner-earnings/split-it-badge.svg" alt="Split It" className="w-[83px] h-[36px]" />
        <div className="mt-sm sm:mt-0 sm:flex-1 sm:ml-sm">
          <p className="font-semibold text-p1 md:text-body text-center sm:text-left">Introducing Split It</p>
          <p className="text-p1 leading-p2 text-center sm:text-left">
            Split purchases instantly into monthly installments.
            <br />
            Pay only interest until the lease starts.
          </p>
        </div>
      </div>

      {autoSplitContent(interestRateValue).map((bullet, index) => (
        <div className="flex flex-nowrap items-start mb-[14px]" key={index}>
          <GeneralIcon icon={GENERAL_ICONS.CHECKMARK} color="green" className="mr-[16px] mt-2xs" />
          <p className="font-semibold flex-1">{bullet}</p>
        </div>
      ))}
    </div>
  );
};

const autoSplitContent = (value) => [
  'Deducted From Rent Over The Course Of Your Lease',
  'No Credit Check Required',
  'Simpler Than A Home Equity Line Of Credit',
  // eslint-disable-next-line no-irregular-whitespace
  `Lower Interest Than Your Credit Card (${value}% APR)`,
  'Close Out At Anytime With No Additional Fees',
  'Smoother Monthly Earnings',
];
