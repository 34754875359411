import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UtilityBasicForm } from 'accounts/components/form-fields/utilities';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { HOME_UTILITIES_STRINGS } from 'accounts/constants/strings/home-utilities.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import MultiStepFormSelector from 'components/v2/MultiStepFormSelector/MultiStepFormSelector';
import { isEmpty } from 'lodash-es';
import { updateStepData } from 'store/redux/home-listing-flow';

export function HomeUtilities() {
  const [updatedData, setUpdatedData] = useState(null);
  const { currentStep, currentStepFormData, handleSaveAndNext, goPreviousStep } = useSetupFlowStep();
  const dispatch = useDispatch();
  const formProps = {
    hasHoa: !!currentStep?.property?.basicInfo?.hasHoa,
  };
  const multiStepFormValues = updatedData?.utilitiesBilling?.utilities || getInitialValues();

  async function handleSubmit(values) {
    const submitValues = {
      utilitiesBilling: {
        utilities: { ...values },
      },
    };

    handleSaveAndNext(submitValues);
  }

  function getInitialValues() {
    const utilities = currentStepFormData?.utilitiesBilling?.utilities || {};
    const initialValues = { ...utilities };

    return initialValues;
  }

  async function handleSubmitEach(values) {
    const submitValues = {
      utilitiesBilling: {
        utilities: { ...values },
      },
    };

    const updatedDataResponse = await dispatch(updateStepData(currentStep.flowId, currentStep, submitValues));

    setUpdatedData(updatedDataResponse);
  }

  function customValidation(formValues) {
    const errorObject = {};

    Object.keys(formValues).forEach((key) => {
      if (isEmpty(formValues[key])) errorObject[key] = { error: 'error' };
    });

    return errorObject;
  }

  return (
    <StepFormLayout
      handleBackStep={goPreviousStep}
      onSubmit={handleSubmit}
      title={HOME_UTILITIES_STRINGS.title}
      initialValues={getInitialValues()}
      customValidation={customValidation}
      getForm={({ form }) => (
        <MultiStepFormSelector
          values={multiStepFormValues}
          form={form}
          formComponent={UtilityBasicForm}
          formProps={formProps}
          showSkipButton={false}
          onSubmit={handleSubmitEach}
        />
      )}
    />
  );
}
