import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PATHS } from '@belong/common';
import { Text } from '@belong/ui';
import clsx from 'clsx';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Money from 'components/Money/Money';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import ProfileTile, { PROFILE_TILE_SPACING } from 'components/ProfileTile/ProfileTile';
import AccountPropertyBannerCard from 'containercomponents/AccountPropertyBannerCard/AccountPropertyBannerCard';
import { ALIGN_ITEMS } from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserProfilePopout/UserProfilePopout';
import RenterAccountUnitDetailContainer from 'containers/RenterAccountUnitDetailContainer/RenterAccountUnitDetailContainer';
import ResidentListContainer from 'containers/ResidentListContainer/ResidentListContainer';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import Space from 'corecomponents/Space/Space';
import { FlowStatus, UnitPetFeeOption } from 'models/enums/index';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import { betterRenewals } from 'pages/Accounts/Pages/YourPortfolio/Properties/Property/constants';
import PropTypes from 'prop-types';
import {
  fetchCurrentUnitWithLeaseId,
  fetchResidentOccupiedTours,
  cancelResidentOccupiedTours,
  fetchResidentFlows,
} from 'store/redux/renter-accounts/actions';
import {
  selectAccountLeaseById,
  selectAccountUnitById,
  selectResidentOccupiedTours,
  selectResidentFlows,
} from 'store/redux/renter-accounts/selectors';
import { formatString } from 'strings';
import { getTimeForLeasingStatus } from 'utils/formateDate/getTimeForLeasingStatus';
import { Placemat } from '../../placemat/placemat';
import styles from './Lease.module.css';
import { OccupiedToursSection } from './components/occupied-tours-section/occupied-tours-section';

function getTitle(leaseDetail) {
  const { basicInfo } = leaseDetail;

  if (basicInfo.isConcluded) {
    return 'Previous Lease';
  }

  if (basicInfo.isFuture) {
    return 'Upcoming Lease';
  }

  return 'Current Lease';
}
function Lease({ match }) {
  const dispatch = useDispatch();

  // Extremeley deceiving param names, renamed to match what they actually are
  const { propertyId: homeId, unitId: leaseId } = match.params;

  const lease = useSelector((state) => selectAccountLeaseById(state, leaseId));
  const leaseDetail = useSelector((state) => selectAccountUnitById(state, homeId));
  const occupiedTours = useSelector(selectResidentOccupiedTours);
  const flows = useSelector(selectResidentFlows);
  const upcomingTours =
    occupiedTours?.filter(({ tour }) => tour.status === 'Scheduled' && new Date(tour.startOn) > new Date()) || [];
  const residentFlows = useSelector(selectResidentFlows);

  useEffect(() => {
    async function fetchData() {
      try {
        await Promise.all([
          dispatch(fetchCurrentUnitWithLeaseId(homeId, leaseId)),
          dispatch(fetchResidentOccupiedTours(leaseId)),
          dispatch(fetchResidentFlows()),
        ]);
      } catch (err) {
        console.error(err);
      }
    }

    if (homeId && leaseId) {
      fetchData();
    }
  }, [homeId, leaseId]);

  async function handleCancelOccupiedTour(tourId) {
    await dispatch(cancelResidentOccupiedTours(tourId));
    await dispatch(fetchResidentOccupiedTours(leaseId));
  }

  if (!lease || !leaseDetail) {
    return null;
  }

  const { owners = [], residents = [], kids = [], pets = [] } = leaseDetail;
  const { timeZone } = lease.unitInfo || {};

  const allResidents = {
    residents,
    kids,
    pets,
  };

  const title = getTitle(leaseDetail);

  const leaseDetails = leaseDetail.basicInfo;
  const itemsToRender = [
    {
      name: 'Rented',
      value: <BDate time={leaseDetails.leaseStartOn} formatType={DATE_TYPES.DOTTED_MEDIUM} />,
    },
    {
      name: 'Lease Ending',
      value: <BDate time={leaseDetails.leaseEndOn} formatType={DATE_TYPES.DOTTED_MEDIUM} />,
    },
    { name: 'Lease Term', value: `${leaseDetails.leaseTerm} months` },
    { name: 'Monthly Payment', value: <Money cash={`${leaseDetails.rentAmount}`} /> },
    { name: 'Deposit', value: <Money cash={`${leaseDetails.depositAmount}`} /> },
  ];

  if (leaseDetails.petDepositAmount > 0) {
    itemsToRender.push({
      name: 'Pet Deposit',
      value: (
        <span>
          <Money cash={leaseDetails.petDepositAmount} />
          {leaseDetails.petDepositOption === UnitPetFeeOption.PerPet && <span> per pet</span>}
        </span>
      ),
    });
  }

  if (leaseDetails.totalPetFee > 0) {
    itemsToRender.push({
      name: 'Pet Fee',
      value: (
        <span>
          <Money cash={leaseDetails?.totalPetFee} />
          /mo.
          {leaseDetails.petFeeOption === UnitPetFeeOption.PerPet && lease.pets.length > 1 && (
            <span>({lease.pets.length} Pets)</span>
          )}
        </span>
      ),
    });
  }

  function calculateFlowProgress(betterRenewalFlow) {
    if (!betterRenewalFlow) return 0;
    const steps = betterRenewalFlow.steps.filter((step) => !step.isHidden);
    const completedSteps = steps.filter((step) => step.status === FlowStatus.Completed);
    const progress = (completedSteps.length * 100) / steps.length;
    return progress;
  }

  const flowSection = {};

  const hasBetterRenewals = flows.find(
    (flow) =>
      flow.flowType === 'ResidentRenewal' &&
      flow.configuration.homeUniqueId === homeId &&
      flow.status !== FlowStatus.Completed
  );

  if (hasBetterRenewals) {
    flowSection.progressPercentage = calculateFlowProgress(hasBetterRenewals);
    flowSection.disabled =
      hasBetterRenewals.status === FlowStatus.Completed || hasBetterRenewals.status === FlowStatus.Cancelled;
    flowSection.displayName = 'Approve Renewal Terms';

    flowSection.reportLink = `${PATHS.RES_BETTER_RENEWALS_FLOW}?flowId=${hasBetterRenewals.uniqueId}`;
  }

  return (
    <>
      {hasBetterRenewals && (
        <div className="mb-3xl">
          <div className="mb-sm">
            <Text variant="h3" fontWeight="semibold">
              {betterRenewals.residentTitle}
            </Text>
          </div>
          <LinkBase
            className={clsx('flex flex-wrap justify-between items-center relative', styles.flowCard, {
              'cursor-pointer': !flowSection.disabled,
              disabled: flowSection.disabled,
            })}
            {...(!flowSection.disabled && {
              href: flowSection.reportLink,
            })}
            key={flowSection.displayName}
          >
            <div
              className={clsx('font-semibold text-body flex items-center mr-sm', {
                'text-dark-gray': flowSection.disabled,
                'text-navy': !flowSection.disabled,
              })}
            >
              <h4 className="mr-xs">{formatString(flowSection.displayName)}</h4>
            </div>
            <div className="flex items-center mt-sm w-full md:w-auto md:mt-0">
              {flowSection.disabled ? (
                <>
                  {flowSection.progressPercentage === 100 ? (
                    <>
                      <h4 className="text-green">100%&nbsp;</h4>
                      <h4 className="text-dark-gray">Complete</h4>
                    </>
                  ) : (
                    <h4 className="text-dark-gray">Not Ready</h4>
                  )}
                </>
              ) : (
                <>
                  <h4
                    className={clsx('mr-2xs', {
                      'text-green': flowSection.progressPercentage === 100,
                      'text-red': flowSection.progressPercentage === 0,
                      'text-blue': flowSection.progressPercentage > 0 && flowSection.progressPercentage < 100,
                    })}
                  >
                    {flowSection.progressPercentage.toFixed()}%
                  </h4>
                  <h4>Complete</h4>
                </>
              )}
              {flowSection.progressPercentage !== 100 && (
                <IconButton disabled={flowSection.disabled} align={ALIGN_TYPES.LEFT} icon={GENERAL_ICONS.FRONT_ARROW} />
              )}
            </div>
          </LinkBase>
        </div>
      )}
      <AccountPage
        headerProps={{
          headerText: 'My Home',
          align: ALIGN_TYPES.RIGHT,
        }}
      >
        {residentFlows?.length > 0 && <Placemat flows={residentFlows} />}
        <div className="mb-xs">
          <AccountPropertyBannerCard
            coordinates={lease.address?.coordinates}
            imageUrl={leaseDetail.unitInfo.bannerImageUrl}
            staticMapImageUrl={lease.staticMapImageUrl}
          />
        </div>
        <AccountPageSection
          pretitle={getTimeForLeasingStatus(leaseDetail)}
          title={
            <div>
              <span>
                {lease.address.streetAddress}
                {lease.address.unitNumber ? ` Unit ${lease.address.unitNumber}, ` : ', '}
              </span>
              <span className="whitespace-nowrap">
                {lease.address.city}, {lease.address.state} {lease.address.zipcode}
              </span>
            </div>
          }
          content={<RenterAccountUnitDetailContainer renterAccountUnitDetail={leaseDetail} />}
        />
        {upcomingTours.length > 0 && (
          <OccupiedToursSection
            occupiedTours={upcomingTours}
            timeZone={timeZone}
            onCancelOccupiedTour={handleCancelOccupiedTour}
            openHomeIncentive={lease.residentIncentive.openHomeIncentive}
            lease={lease}
          />
        )}
        <AccountPageSection
          title="Owned By"
          content={owners.map((owner, index) => (
            <ProfileTile
              key={index}
              relative
              alignProfileItems={ALIGN_ITEMS.ALIGN_LEFT}
              userId={owner.userId}
              imageUrl={owner.profileImageUrl}
              spacing={index === 0 ? PROFILE_TILE_SPACING.NO_SPACING : PROFILE_TILE_SPACING.LG_SPACING}
              title={`${owner.firstName} ${owner.lastName}`}
              subtitles={[
                <span key="0">
                  Belong Member{' '}
                  <span>
                    Since <BDate time={owner.memberSince} formatType={DATE_TYPES.SHORT_MONTH_YEAR} />
                  </span>
                </span>,
              ]}
              profile={owner.aboutMe}
            />
          ))}
        />
        <AccountPageSection
          title={title}
          content={
            <div>
              <NameValueTable doubleRowMargin noBold items={itemsToRender} />
              {leaseDetails.leaseUrl && (
                <>
                  <Space />
                  <div className="flex justify-end">
                    <IconButton
                      href={leaseDetails.leaseUrl}
                      target="_blank"
                      align={ALIGN_TYPES.LEFT}
                      label="VIEW LEASE"
                      icon={GENERAL_ICONS.FRONT_ARROW}
                    />
                  </div>
                </>
              )}
            </div>
          }
        />
        {leaseDetail?.residents?.length >= 1 && (
          <AccountPageSection title="Belong Members" content={<ResidentListContainer residents={allResidents} />} />
        )}
      </AccountPage>
    </>
  );
}

Lease.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default Lease;
