import { formatMoney } from '@belong/common';
import { Text } from '@belong/ui';
import { RelatedExternalListingModel } from 'api/models';
import clsx from 'clsx';
import BulletList from 'components/BulletList/BulletList';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import Sqft from 'components/display/General/Sqft/Sqft';
import { getBathRoomString, getFormattedBeds } from 'models/common/UnitBasicInfo';
import { formatFirstLine, formatSecondLine } from 'utils/formatAddress';

type ComparablesStepListProps = {
  homes?: RelatedExternalListingModel[];
};

export function ComparablesTable({ homes }: ComparablesStepListProps) {
  const _homesToSort = [...homes];
  _homesToSort.sort((a, b) => {
    return (a.rentAmount || a.rentEstimate) - (b.rentAmount || b.rentEstimate);
  });

  const pricePerSqft = (home) => {
    return home.rentAmount ? (
      <Text>
        {formatMoney(home.rentAmount / home.areaInSquareFeet)} Price/Ft<sup>2</sup>
      </Text>
    ) : null;
  };

  const getLabelText = (home) => {
    if (home.current) return 'MY HOME';
    if (home.overpriced) return 'OVERPRICED';
    if (home.recentlyOffMarket) return 'RECENTLY OFF MARKET';
  };

  const getLabelColor = (home) => {
    if (home.current) return LABEL_COLOR_TYPES.NAVY;
    if (home.overpriced) return LABEL_COLOR_TYPES.RED;
    if (home.recentlyOffMarket) return LABEL_COLOR_TYPES.GRAY;
  };

  return (
    <div className="flex flex-col gap-sm items-center relative px-2sm lg:px-0">
      {_homesToSort.map((home) => {
        const labelText = getLabelText(home);

        return (
          <div
            className={clsx(
              'flex flex-col lg:flex-row gap-lg lg:gap-0 w-full justify-between border border-solid border-gray rounded-small p-sm',
              home.current && 'border-green bg-green-light-bg',
              home.recentlyOffMarket && 'bg-light-gray'
            )}
            key={home.id}
          >
            <div className="flex flex-col">
              <Text fontWeight="semibold">{`${formatFirstLine(home.address, home.address.unitNumber)}`}</Text>
              <Text fontWeight="semibold">{formatSecondLine(home.address)}</Text>
              <Text className="mt-xs">
                <BulletList
                  flexWrap
                  small
                  listItems={[
                    getFormattedBeds(home, false),
                    getBathRoomString(home),
                    <Sqft key="2" sqft={home.areaInSquareFeet} />,
                    pricePerSqft(home),
                  ]}
                />
              </Text>
            </div>

            <div className="flex flex-col gap-xs lg:gap-md items-end">
              <div className="flex gap-xs">
                {labelText && <Label color={getLabelColor(home)}>{labelText}</Label>}
                {home.rentAmount && <Text fontWeight="semibold">{`${formatMoney(home.rentAmount)}/mo`}</Text>}
              </div>
              {!home.current && <Text>{home.daysListed || 0} Days Listed</Text>}
            </div>
          </div>
        );
      })}
    </div>
  );
}
