export const PaymentNotice = ({ forcePaymentMethod = false }) => {
  return (
    <div className="bg-green-translucent-light p-sm rounded border border-solid border-green mt-sm mb-2xl">
      <p className="font-semibold">Upon Completion</p>
      <p className="text-p1">{`Pay for Pro Services with ${
        !forcePaymentMethod ? ' a credit card' : ''
      } or\u00a0ACH.`}</p>
    </div>
  );
};
