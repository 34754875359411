import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import PropTypes from 'prop-types';

export const ConsentCheckbox = ({ label, onSelect, ...rest }) => {
  return <Checkbox label={label} onSelect={onSelect} {...rest} />;
};

ConsentCheckbox.propTypes = {
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

ConsentCheckbox.defaultProps = {
  label: 'I agree to electronically sign <span>this form.</span>',
};
