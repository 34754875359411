import React, { Component } from 'react';
import { Form } from 'react-final-form';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import styles from './ForgotPasswordEmailSentConfirmation.module.css';

const cx = classNames.bind(styles);

class ForgotPasswordEmailSentConfirmation extends Component {
  static title = 'No problem!';

  static description = "We'll send you an email to reset your password if this <span>Belong account exists.</span>";

  static closeButton = true;

  static propTypes = {
    handleForgotPasswordEmailSentConfirmationSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className={cx('fp-email-confirmation-content-wrapper')}>
        <Form
          onSubmit={this.props.handleForgotPasswordEmailSentConfirmationSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div className={cx('email-sent-confirmation-button')}>
                <Button type="submit" label="Done" disabled={invalid} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default ForgotPasswordEmailSentConfirmation;
