import { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Bundle from '../Bundle/Bundle';
import ProjectManagementBundle from '../Bundle/ProjectManagemenBundle';
import styles from './BundlesList.module.css';

const cx = classNames.bind(styles);

const propTypes = {
  bundles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isMonthly: PropTypes.bool,
  projectManagementFeeApplicable: PropTypes.bool,
  priceRange: PropTypes.object,
  isOnboarding: PropTypes.bool,
  isGTTLDisqualifiedDueHomeownerFixingItems: PropTypes.bool,
  displayPrice: PropTypes.bool,
};

const defaultProps = {};

const BundlesList = ({
  bundles,
  onChange,
  onClick,
  isMonthly,
  projectManagementFeeApplicable,
  priceRange,
  isOnboarding,
  displayPrice,
  isGTTLDisqualifiedDueHomeownerFixingItems,
}) => {
  const [selectedBundle, setSelectedBundle] = useState(bundles.find((b) => b.type === 'ProjectManagement')?.type);

  const handleToggle = (bundleType) => {
    if (selectedBundle === bundleType) {
      setSelectedBundle(null);
    } else {
      setSelectedBundle(bundleType);
    }
  };

  return (
    <div className={cx('bundles-list')}>
      {bundles.map((bundle) =>
        bundle.type !== 'ProjectManagement' ? (
          <div className={cx('bundle-container')} key={bundle.type}>
            <Bundle
              bundle={bundle}
              onChange={onChange}
              onToggle={handleToggle}
              open={bundle.type === selectedBundle}
              highlight={!selectedBundle}
              onClick={onClick}
              isMonthly={isMonthly}
              displayPrice={displayPrice}
              projectManagementFeeApplicable={projectManagementFeeApplicable}
              isOnboarding={isOnboarding}
              isGTTLDisqualifiedDueHomeownerFixingItems={isGTTLDisqualifiedDueHomeownerFixingItems}
            />
          </div>
        ) : (
          <div className={cx('bundle-container')} key={bundle.type}>
            <ProjectManagementBundle
              bundle={bundle}
              onToggle={handleToggle}
              open={bundle.type === selectedBundle}
              highlight={!selectedBundle}
              priceRange={priceRange}
            />
          </div>
        )
      )}
    </div>
  );
};

BundlesList.propTypes = propTypes;
BundlesList.defaultProps = defaultProps;

export default BundlesList;
