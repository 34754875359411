import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES } from 'components/Button/Button';
import FavoriteButton from 'components/FavoriteButton/FavoriteButton';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ListingsUnitCard from 'containers/ListingsUnitCard/ListingsUnitCard';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import ListingPageUnit from 'models/listingPage/ListingPageUnit';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { fetchFavoriteAccountsHomes } from 'store/redux/renter-accounts/actions';
import { selectFavoriteHomesAccounts } from 'store/redux/renter-accounts/selectors';
import { formatString } from 'strings';
import { FavoriteStrings } from 'strings/favorites';
import AccountPage from '../AccountPage/AccountPage';
import styles from './Favorites.module.css';

const cx = classNames.bind(styles);

const FavoriteHomes = ({ favoriteHomes }) => {
  return (
    <AccountPage
      headerProps={{
        headerText: FavoriteStrings['no-homes.title'],
      }}
    >
      <AccountPageSection
        content={
          <Row>
            {favoriteHomes?.map((home) => {
              return (
                <Col lg={4} sm={6} xs={12} key={home.basicInfo.unitId}>
                  <div className={cx('listing-unit-container')}>
                    <ListingsUnitCard unit={home} favoriteButton={<FavoriteButton homeId={home.basicInfo.unitId} />} />
                  </div>
                </Col>
              );
            })}
          </Row>
        }
      />
    </AccountPage>
  );
};

const FavoriteHomesNoLength = () => (
  <AccountPage
    headerProps={{
      headerText: FavoriteStrings['homes.title'],
    }}
  >
    <AccountPageSection
      content={
        <AccountPageSection.NoItems>
          <AccountPageSection.Pretext>{formatString(FavoriteStrings['no-homes.pretext'])}</AccountPageSection.Pretext>

          <Space value={SPACE_TYPES.XXS} />

          <AccountPageSection.Text fontSize="h3">
            {formatString(FavoriteStrings['no-homes.text'], {
              heartIcon: (
                <span className={cx('icon-wrapper')}>
                  <GeneralIcon icon={GENERAL_ICONS.ICON_HEART_EMPTY} />
                </span>
              ),
            })}
          </AccountPageSection.Text>

          <Space value={SPACE_TYPES.XL} />

          <Button
            size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
            label={FavoriteStrings['no-homes.button']}
            to={BASE_PATHS.HOME}
          />
        </AccountPageSection.NoItems>
      }
    />
  </AccountPage>
);

const Favorites = ({ favoriteHomes, fetchFavoriteAccountsHomesAction }) => {
  useEffect(() => {
    const fetchItem = async () => {
      try {
        await fetchFavoriteAccountsHomesAction();
      } catch (err) {
        console.error(err);
      }
    };

    fetchItem();
  }, []);

  return favoriteHomes?.length === 0 ? <FavoriteHomesNoLength /> : <FavoriteHomes favoriteHomes={favoriteHomes} />;
};

FavoriteHomes.propTypes = {
  favoriteHomes: PropTypes.arrayOf(PropTypes.instanceOf(ListingPageUnit)).isRequired,
};

Favorites.propTypes = {
  favoriteHomes: PropTypes.arrayOf(PropTypes.instanceOf(ListingPageUnit)).isRequired,
  fetchFavoriteAccountsHomesAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  favoriteHomes: selectFavoriteHomesAccounts(state),
});

const mapDispatchToProps = {
  fetchFavoriteAccountsHomesAction: fetchFavoriteAccountsHomes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
