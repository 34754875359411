import React from 'react';
import classNames from 'classnames/bind';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import PropTypes from 'prop-types';
import styles from './GoogleMapMobile.module.css';

const cx = classNames.bind(styles);

export const GoogleMapMobile = ({ children, onHide, visible, defaultHeader }) => {
  return visible ? (
    <ModalV2 noPadding onHide={onHide} show={visible} type={MODAL_TYPES.FULL_PAGE} defaultHeader={defaultHeader}>
      <div className={cx('full-height')}>{children}</div>
    </ModalV2>
  ) : null;
};

GoogleMapMobile.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  defaultHeader: PropTypes.bool,
};

GoogleMapMobile.defaultProps = {
  onHide: null,
};
