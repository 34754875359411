import React from 'react';
import { DateInputFinalFormAdapter } from 'components/DateInput/DateInput';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCurrency, maskMMDDYY, unmaskMMDDYY, unmaskNumber } from 'components/Input/masks';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { InputCheckboxAdapter } from 'formcomponents/checkbox-input';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT, rentalManagingSelectors } from 'strings/rental-status';
import {
  composeValidators,
  dateValidationMMDDYY,
  required,
  validPastOrTodayMMDDYYDate,
  validFutureOrTodayMMDDYYDate,
} from 'utils/validation';

export const RentalManagerSection = ({ form, name }) => (
  <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.managingQuestion}>
    <Field
      buttons={rentalManagingSelectors}
      component={SelectorFinalFormAdapter}
      name={`${name}.rentalManagement`}
      type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
      validate={required}
    />
    <Row>
      <Col xs={12} sm={6}>
        <Field
          component={DateInputFinalFormAdapter}
          mask={maskMMDDYY}
          name={`${name}.moveinOn`}
          placeholder={RENTAL_STATUS_TEXT.moveInDate}
          unmask={unmaskMMDDYY}
          validate={composeValidators(required, dateValidationMMDDYY, validPastOrTodayMMDDYYDate)}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={InputFinalFormAdapter}
          mask={maskCurrency}
          name={`${name}.currentRentAmount`}
          placeholder={RENTAL_STATUS_TEXT.currentRentPlaceHolder}
          unmask={unmaskNumber}
          validate={required}
        />
      </Col>
      <Col xs={12}>
        <Field
          checkboxProps={{
            label: 'Month-to-month',
          }}
          component={InputCheckboxAdapter}
          form={form}
          inputProps={{
            component: DateInputFinalFormAdapter,
            placeholder: RENTAL_STATUS_TEXT.leaseEndingMMYYPlaceholder,
            validate: composeValidators(required, dateValidationMMDDYY, validFutureOrTodayMMDDYYDate),
          }}
          name={`${name}.currentLeaseEndOn`}
        />
      </Col>
    </Row>
  </FormLayout.Section>
);

RentalManagerSection.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
