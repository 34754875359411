import { Money } from '@belong/ui';
import { MaintenanceModel } from 'api/models';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import Collapsible from 'components/Collapsible/Collapsible';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import { BackedByBelong } from 'containercomponents/MaintenanceStatus/MaintenanceCostDisplay/MaintenanceCostDisplay';
import GetInfoModal from 'containercomponents/Modals/GetInfoModal/GetInfoModal';
import Space from 'corecomponents/Space/Space';
import { Flex, Box, Collapsible as CollapsibleV2, Text } from 'design-system';
import { useModal } from 'hooks/useModal';
import { MaintenanceRequester } from 'models/enums';
import { COSTS_CONFIG } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/costs.utils';
import { ResponsibilityContent } from 'pages/PostInspectionFlow/steps/Improvements/Responsiblity/Responsiblity';
import { getPaymentBreakdown, getPayments } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import styles from './GroupResidentReceiptCosts.module.css';

const cx = classNames.bind(styles);

type GroupResidentReceiptCostsProps = {
  groupTask: MaintenanceModel;
};

// https://www.figma.com/file/52tR6ruj9iZDZNV6mx3usB/belong_resident_post_moveout_report?node-id=0%3A1
export const GroupResidentReceiptCosts = ({ groupTask }: GroupResidentReceiptCostsProps) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const [isResponsibilityModalOpen, openResponsibilityModal, closeResponsibilityModal] = useModal();

  if (!groupTask?.subtasks) return null;

  const validBundles = groupTask.subtasks.filter((x) => {
    const breakdown = getPayments(x.items[0].maintenancePayments);
    return breakdown.resident?.percentage > 0;
  });

  const residentPayment = groupTask.payments.find((p) => p.paidBy === MaintenanceRequester.Resident);
  if (!residentPayment) return null;

  const isInvoiced = groupTask.requestTypeDetails?.hasPublishedInvoices;
  let costs;
  if (isInvoiced) {
    costs = {
      bundleCost: 'actualCost',
      lineItemBreakdown: 'actualCostBreakdown',
      netTotal: 'actualDiscountApplied',
    };
  } else {
    costs = {
      bundleCost: 'estimatedCost',
      lineItemBreakdown: 'estimatedCostBreakdown',
      netTotal: 'estimatedDiscountApplied',
    };
  }

  let homeownerRepairTotalAmount = 0;
  return (
    <Collapsible
      createMarginAndPaddingClassName={cx('margin-padding')}
      collapsed={false}
      title={isInvoiced ? 'Receipt' : 'Estimated Pricing'}
      inner={
        <div className={cx('content')}>
          <BackedByBelong showModal={isModalOpen} onClick={setModalOpen} onHide={setModalClose} />
          <Space />
          <div className={cx('maintenanceCostDisplay')}>
            <NameValueTable
              boldValue
              items={[
                {
                  name: 'Item',
                  value: 'Amount',
                },
              ]}
            />

            {validBundles.length > 0 && <div className={cx('underline')} />}

            {validBundles.map((bundle, idx) => {
              const bundleItem = bundle.items[0];
              const itemPaymentsBreakdown = getPayments(bundleItem.maintenancePayments);
              const breakdown = getPaymentBreakdown(bundle);
              const item = bundleItem.maintenance;
              let name = item.summary;
              if (itemPaymentsBreakdown.resident.percentage < 100) {
                name += ` <span>(${itemPaymentsBreakdown.resident.percentage}% Responsibility)</span>`;
              }

              const _isTBD = bundleItem.isTBD && !isInvoiced;

              // TODO: CHANGE THIS AFTER TURNOVERS PHASE 2
              // If homeowner is fixing, we don't create invoices right now for those repair
              // So display the estimated cost.
              const isHomeownerFixing = item.proServiceResponsibility === 'Homeowner';
              const itemCost = isHomeownerFixing ? 'totalEstimatedCost' : costs.bundleCost;
              const itemLineBreakdown = isHomeownerFixing ? 'estimatedCostBreakdown' : costs.lineItemBreakdown;

              if (isHomeownerFixing) homeownerRepairTotalAmount += breakdown.resident[itemCost];

              return (
                <Box mb="xs" key={idx}>
                  <CollapsibleV2
                    // Disable if homeowner is fixing
                    disabled={isHomeownerFixing}
                    showArrow={!isHomeownerFixing}
                    leftContent={<Text variant="body-responsive">{name}</Text>}
                    rightContent={
                      <Box textAlign="right" width={[100, 150, 300]}>
                        <Text variant="body-responsive">
                          {_isTBD ? 'TBD' : <Money format="DOLLARS_NO_CENTS" value={breakdown.resident[itemCost]} />}
                        </Text>
                      </Box>
                    }
                    innerContent={(() => {
                      if (isHomeownerFixing) return null;
                      const lineItems = breakdown.resident[itemLineBreakdown].filter((x) => x.cost > 0);
                      const formattedLineItems = [];
                      COSTS_CONFIG.forEach((config) => {
                        const autoCosts = config.autoCosts?.map((x) => x.type) || [];
                        if (autoCosts.length > 0) {
                          const validAutoCosts = lineItems.filter((x) => autoCosts.includes(x.type));
                          if (validAutoCosts.length > 0) {
                            // push parent
                            formattedLineItems.push({ name: config.displayName });
                            //push children
                            validAutoCosts.forEach((autoCost) => {
                              formattedLineItems.push({
                                name: config.autoCosts.find((x) => x.type === autoCost.type)?.displayName,
                                cost: autoCost.cost,
                                indent: true,
                              });
                            });
                          }
                        } else {
                          const existingCost = lineItems.find((x) => config.type === x.type);
                          if (existingCost) {
                            formattedLineItems.push({
                              name: config.displayName,
                              cost: existingCost.cost,
                            });
                          }
                        }
                      });

                      return (
                        <Box mt="xs">
                          {formattedLineItems.map((lineItem) => {
                            return (
                              <Flex mb="xs" ml={lineItem.indent ? 'xl' : 'md'} key={lineItem.name}>
                                <Box flex={1}>
                                  <Text variant="body-responsive">{lineItem.name}</Text>
                                </Box>
                                <Text textAlign="right">
                                  {lineItem.cost > 0 && (
                                    <Text variant="body-responsive">
                                      <Money format="DOLLARS_NO_CENTS" value={lineItem.cost} />
                                    </Text>
                                  )}
                                </Text>
                              </Flex>
                            );
                          })}

                          <Flex ml="md" alignItems="center">
                            <Flex flex={1}>
                              <Text fontWeight="semibold" variant="body-responsive">
                                Resident Responsibility
                              </Text>
                              <Box mx={['xs', 'xs', 'md']}>
                                <Button
                                  smallOnMobile
                                  buttonType={BUTTON_TYPES.TEXT}
                                  label="LEARN MORE"
                                  onClick={openResponsibilityModal}
                                />
                              </Box>
                            </Flex>
                            <Text variant="body-responsive" fontWeight="semibold" textAlign="right">
                              {_isTBD ? (
                                'TBD'
                              ) : (
                                <Money format="DOLLARS_NO_CENTS" value={breakdown.resident[itemCost]} />
                              )}
                            </Text>
                          </Flex>

                          {idx < validBundles.length - 1 && <div className={cx('underline')} />}
                        </Box>
                      );
                    })()}
                  />
                </Box>
              );
            })}

            <div className={cx('underline')} />
            {/* Totals */}
            <NameValueTable
              boldValue
              items={[
                {
                  name: 'Total',
                  value: (
                    <Money
                      format="DOLLARS_NO_CENTS"
                      value={residentPayment[costs.netTotal] + homeownerRepairTotalAmount}
                    />
                  ),
                },
              ]}
            />
          </div>

          <GetInfoModal
            description={
              <Box bg="white" color="navy" textAlign="left" py="xl">
                <ResponsibilityContent />
              </Box>
            }
            closeButton
            show={isResponsibilityModalOpen}
            shadow
            closable={false}
            onHide={closeResponsibilityModal}
          />
        </div>
      }
    />
  );
};
