import React from 'react';
import classNames from 'classnames/bind';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import PropTypes from 'prop-types';
import styles from './SelectorCard.module.css';

const cx = classNames.bind(styles);

// TODO: Add support for Link.
const SelectorCard = ({
  children,
  mobileOrientation,
  isSelected,
  ignoreBoxShadow,
  active,
  completed,
  error,
  disabled,
  onClick,
}) => (
  <ButtonBase
    className={cx(
      'selector-card',
      `${mobileOrientation}`,
      { selected: isSelected },
      { ignoreBoxShadow },
      { disabled },
      { error },
      { active },
      { completed }
    )}
    onClick={onClick}
  >
    {children}
  </ButtonBase>
);

SelectorCard.propTypes = {
  children: PropTypes.node.isRequired,
  mobileOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  ignoreBoxShadow: PropTypes.bool,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectorCard.defaultProps = {
  mobileOrientation: 'horizontal',
  isSelected: false,
  onClick: null,
  ignoreBoxShadow: false,
  active: false,
  completed: false,
  error: false,
  disabled: false,
};

export default SelectorCard;
