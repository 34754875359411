import { PAYMENTS_FEE_CONST } from '@belong/common';

/* eslint-disable max-len */

export const PAYMENT_FLOW_STRINGS = {
  credit_card: {
    card_number_label: 'Card Number',
    exp_date_label: 'MM/YY',
    zip_label: 'ZIP',
    cvc: 'CVC',
  },
  ach_type_selector_section: {
    title: 'Ok, how would you like to add your account?',
    subTitle:
      'This is a one time verification. Login to your bank, or enter your account and routing details manually and we’ll send you two micro deposits to verify.',
    paymentPartnerConsent: "By checking this box, I agree to Belong's payment partner Dwolla's",
    futurePaymentConsent:
      "By checking this box, I agree that future payments to or from Belong will be processed by Dwolla's payment system from the selected account above. I consent to Belong automatically withdrawing any amounts due, such as rent and any related fees, on my monthly payment due date. If you wish to cancel this authorization in the future, you can do so in Belong's account settings.",
  },
  payment_method_fee_text: {
    ach: `(${PAYMENTS_FEE_CONST.ACH_FEE})`,
    credit_card: `(${PAYMENTS_FEE_CONST.CREDIT_CARD_FEE} processing fee)`,
  },
};
