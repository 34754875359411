import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

export const IMPROVEMENTS_BENEFITS = [
  {
    imageSrc: '/improvement-reports/quality-01.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.markup'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.markupDescription'],
  },
  {
    imageSrc: '/improvement-reports/quality-02.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.penny'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.pennyDescription'],
  },
  {
    imageSrc: '/improvement-reports/quality-03.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.guarantee'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.guaranteeDescription'],
  },
  {
    imageSrc: '/improvement-reports/quality-04.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.liability'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.liabilityDescription'],
  },
];
