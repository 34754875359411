import React from 'react';
import { Divider } from '@belong/ui';
import classNames from 'classnames/bind';
import ProfileImageLarge from 'components/ProfileImageLarge/ProfileImageLarge';
import PropTypes from 'prop-types';
import styles from './ProfileImageLargeWithLabel.module.css';

const cx = classNames.bind(styles);

const ProfileImageLargeWithLabel = ({ imageContainer, profileImage, title, subtitle, noLabel, showDivider }) => {
  return (
    <div className={cx('component-wrapper flex flex-column items-center')}>
      <ProfileImageLarge imageContainer={imageContainer} profileImage={profileImage} withHover />
      {showDivider && (
        <div className="w-full md:w-[412px]">
          <Divider />
        </div>
      )}
      {!noLabel && (
        <div className={cx('text-wrapper text-center')}>
          <div className={cx('title')}>{title}</div>
          <div className={cx('subtitle')}>{subtitle}</div>
        </div>
      )}
    </div>
  );
};

ProfileImageLargeWithLabel.propTypes = {
  profileImage: PropTypes.string.isRequired,
  imageContainer: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  noLabel: PropTypes.bool,
  showDivider: PropTypes.bool,
};

ProfileImageLargeWithLabel.defaultProps = {
  imageContainer: '',
  title: '',
  subtitle: '',
  noLabel: false,
  showDivider: false,
};

export default ProfileImageLargeWithLabel;
