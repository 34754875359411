/* eslint-disable react/prop-types */
import React, { forwardRef, createElement, useContext, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { AnalyticsContext } from './AnalyticsContext';
import { AnalyticsProviderContext } from './AnalyticsProviderContext';
import { ANALYTICS_EVENT_NAMES } from './analytics_event_names';
import { getRouteProperties } from './utils';

const PageViewTracker = (wrappedComponent) => {
  const PageViewTrackerWrapper = forwardRef((props, ref) => {
    const analytics = useContext(AnalyticsProviderContext);
    const analyticsData = useContext(AnalyticsContext);
    const contextAnalyticsData = useContext(AnalyticsContext);
    const previousAnalyticsData = useRef({});
    const {
      location: { pathname },
    } = props;

    const track = () => {
      const properties = {
        ...getRouteProperties(props),
        ...analyticsData,
        ...(props.route?.state || {}),
        ...(props.pageName ? { page: props.pageName } : {}),
      };
      analytics.track(ANALYTICS_EVENT_NAMES.PAGE_VIEW, properties);
      previousAnalyticsData.current = analyticsData;
    };

    useEffect(track, [pathname]);

    return (
      <AnalyticsContext.Provider
        value={{
          ...contextAnalyticsData,
          ...(props.route?.state || {}),
          ...(props.pageName ? { page: props.pageName } : {}),
        }}
      >
        {createElement(wrappedComponent, { ref, ...props })}
      </AnalyticsContext.Provider>
    );
  });

  return withRouter(PageViewTrackerWrapper);
};

export default PageViewTracker;
