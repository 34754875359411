import { Link } from 'react-router-dom';
import { BASE_PATHS } from '@belong/common';
import classNames from 'classnames/bind';
import Label from 'components/Label/Label';
import { LabelItem } from 'components/Label/labelTypes';
import styles from 'containercomponents/MaintenanceStatus/ModalMaintenanceHeader/ModalMaintenanceHeader.module.css';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';

const cx = classNames.bind(styles);

interface ModalMaintenanceHeaderProps {
  title: string;
  streetAddress: string;
  jobId: string;
  propertyUniqueId: string;
  labels?: LabelItem[];
}

const ModalMaintenanceHeader = ({
  title,
  streetAddress,
  jobId,
  propertyUniqueId,
  labels = [],
}: ModalMaintenanceHeaderProps) => {
  return (
    <>
      <div className="flex justify-center mb-sm">
        <Link to={`${BASE_PATHS.ACCOUNTS}/portfolio/properties/${propertyUniqueId}`} className="text-dark-gray">
          {streetAddress}
        </Link>
        <span className="text-dark-gray mx-2xs">/</span>
        <Link to={`${BASE_PATHS.ACCOUNTS}/portfolio/maintenance`} className="text-dark-gray">
          Pro Service
        </Link>
        <span className="text-dark-gray mx-2xs">/</span>
        <span className="font-semibold">{`#${jobId}`}</span>
      </div>
      <ModalLayout.Title label={title} className={cx('title', 'mt-0')} />

      {labels.length > 0 && (
        <div className={cx('label-holder')}>
          {labels.map((label, index) => (
            <Label text={label.text} color={label.color} key={index} />
          ))}
        </div>
      )}
      <Space value={SPACE_TYPES.SM} mobileValue={SPACE_TYPES.LG} />
    </>
  );
};

export default ModalMaintenanceHeader;
