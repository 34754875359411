const MONTH_NAME = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const getMonthName = (monthNumber: number) => {
  return MONTH_NAME[monthNumber];
};

export const getPastMonthName = (monthNumber: number) => {
  if (monthNumber === 1) {
    return MONTH_NAME[12];
  }

  return MONTH_NAME[Number(monthNumber) - 1];
};
