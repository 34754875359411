import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './ItemDetail.module.css';

const cx = classNames.bind(styles);

export default class ItemDetail extends Component {
  static propTypes = {
    firstItem: PropTypes.node.isRequired,
    secondItem: PropTypes.node.isRequired,
    className: PropTypes.string,
    animate: PropTypes.bool,
    paymentDetailsItemStyle: PropTypes.bool,
    alignTop: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    animate: false,
    alignTop: false,
    paymentDetailsItemStyle: false,
  };

  render() {
    const { firstItem, alignTop, secondItem, paymentDetailsItemStyle, className, animate } = this.props;
    return (
      <div className={cx('itemDetail', className, { alignTop }, { paymentDetailsItemStyle }, { animate })}>
        {firstItem}
        {secondItem}
      </div>
    );
  }
}
