import React, { Component } from 'react';
import classNames from 'classnames/bind';
import BulletList from 'components/BulletList/BulletList';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import styles from './PropertyCard.module.css';

const cx = classNames.bind(styles);

class PropertyCard extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    completed: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    home: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  state = {
    hover: false,
  };

  getPropertyCardColor = () => {
    const { completed, disabled } = this.props;
    const { hover } = this.state;
    if (completed) {
      return COLORS.GREEN;
    }
    if (disabled) {
      return COLORS.GRAY;
    }
    return hover ? COLORS.DARK_NAVY : COLORS.DEFAULT;
  };

  handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  render() {
    const {
      property: {
        basicInfo: { propertyId },
        address: { streetAddress },
      },
      home: {
        basicInfo: { unitNumber },
      },
      disabled,
      onClick,
      completed,
    } = this.props;

    const { hover } = this.state;

    const listItems = [streetAddress];

    if (unitNumber) {
      listItems.push(`Unit ${unitNumber}`);
    }

    return (
      <Button
        disabled={disabled}
        buttonType={BUTTON_TYPES.NOSTYLE}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => onClick(propertyId)}
        className={cx('property-card', { completed }, { hover }, { disabled })}
      >
        <div className={cx('card-layout')}>
          <div className={cx('content')}>
            <div className={cx('subtitle')}>{completed ? 'Completed' : 'Add Details'}</div>
            <div className={cx('title')}>
              <BulletList wrap listItems={listItems} />
            </div>
          </div>
          <div className={cx('icon')}>
            <GeneralIcon
              color={this.getPropertyCardColor()}
              icon={completed ? GENERAL_ICONS.HAND_CHECKBOX : GENERAL_ICONS.HAND_ARROW_RIGHT}
              scale={1.5}
            />
          </div>
        </div>
      </Button>
    );
  }
}

export default PropertyCard;
