import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames/bind';
import { isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import InputLabel from '../InputLabel/InputLabel';
import InputTag from '../TaggableInput/InputTag';
import styles from './Input.module.css';

const cx = classNames.bind(styles);

export default class Input extends Component {
  static propTypes = {
    // placeholder + label
    placeholder: PropTypes.string,
    // highlights the input field before focused.
    start: PropTypes.bool,
    // Error describes if an error has occured. Should eventually be refactored into an object of error messages.
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    hidden: PropTypes.bool,

    textarea: PropTypes.bool,
    mask: PropTypes.func,
    tags: PropTypes.array,
    transparent: PropTypes.bool,
    onRemoveTag: PropTypes.func,

    ctaButton: PropTypes.node,
    autoFocus: PropTypes.bool,

    inputRef: PropTypes.shape({ current: PropTypes.any }),
    applyDisabledStyle: PropTypes.bool,
    multiLineLabel: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: 'Enter',
    start: false,
    error: false,
    errorMessage: '',
    autoFocus: false,
    hidden: false,
    textarea: false,
    mask: null,
    tags: [],
    onRemoveTag: () => {},
    ctaButton: null,
    inputRef: null,
    transparent: false,
    applyDisabledStyle: false,
    multiLineLabel: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      // Checks if curently focused
      onFocus: false,
      // Checks if the state was ever focused on
      hasBeenFocusedOnce: true,
    };
  }

  // function that toggles focus on and off
  toggleFocus = () => {
    let { onFocus, hasBeenFocusedOnce } = this.state;
    hasBeenFocusedOnce = false;
    onFocus = !onFocus;
    this.setState({
      onFocus,
      hasBeenFocusedOnce,
    });
  };

  render() {
    let focusTrigger;
    const {
      error,
      errorMessage,
      start,
      placeholder,
      hidden,
      textarea,
      mask,
      tags,
      onRemoveTag,
      ctaButton,
      inputRef,
      applyDisabledStyle,
      transparent,
      multiLineLabel,
      autoFocus,
      ...rest
    } = this.props;
    const { onFocus, hasBeenFocusedOnce } = this.state;

    // Place here the props that we don't want to pass to the input html element.
    const { innerRef, ...other } = rest;

    delete other.newFieldComponent;
    delete other.dateFormat;

    //  Used to change all the colors in the input container
    focusTrigger = onFocus;

    // Checks if focus trigger should be overrun if a start prop is passed in.
    if (start && hasBeenFocusedOnce) {
      focusTrigger = true;
    }

    let headerLabel;

    // Prevent label from occuring if a value is entered.
    if ((isNil(other.value) || other.value === '') && !tags.length) {
      headerLabel = (
        <InputLabel
          multiLineLabel={multiLineLabel}
          label={placeholder}
          unAnimateText={onFocus}
          highlight={focusTrigger}
          error={error}
        />
      );
    } else {
      headerLabel = (
        <InputLabel multiLineLabel={multiLineLabel} label={placeholder} highlight={focusTrigger} error={error} />
      );
    }

    let showInput;

    // decides whther the input is a text area or an input
    if (textarea) {
      showInput = <Textarea className={cx('inputText', error, { textarea }, { transparent })} {...other} />;
    } else {
      showInput = (
        <input
          className={cx('inputText', error, { transparent })}
          ref={inputRef}
          autoFocus={autoFocus}
          {...other}
          value={mask ? mask(other.value) : other.value}
        />
      );
    }

    return (
      <>
        <div
          className={cx(
            'inputContainer',
            { multiLineLabel },
            { highlight: focusTrigger, hidden, textarea },
            { error },
            { applyDisabledStyle }
          )}
          onFocus={this.toggleFocus}
          onBlur={this.toggleFocus}
        >
          {headerLabel}
          <div className={cx('input-box')}>
            {tags && (
              <div className={cx('tags')}>
                {tags.map((tag) =>
                  tag.name ? (
                    <div className={cx('tag')} key={tag.name}>
                      <InputTag
                        key={tag.name}
                        name={tag.name}
                        onRemoveTag={(e) => {
                          e.preventDefault();
                          onRemoveTag(tag);
                        }}
                      />
                    </div>
                  ) : null
                )}
              </div>
            )}
            {showInput}
            {ctaButton && ctaButton}
          </div>
        </div>
        {error && errorMessage && !onFocus && <div className={cx('error-message')}>{errorMessage}</div>}
      </>
    );
  }
}

export const InputFinalFormAdapter = ({ ignoreError, input, meta, onChangeCustom, newFieldComponent, ...rest }) => {
  // Used to gray out Input when left.
  const controlledProps = {};
  const { unmask, mask } = rest;

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = !!meta.error;
    } else {
      controlledProps.error = meta.touched ? !!meta.error : false;
    }
  }

  delete rest.unmask;

  return (
    <Input
      {...input}
      {...rest}
      onChange={(event) => {
        let { value } = event.target;

        if (unmask) {
          value = unmask(mask(value));
        }

        input.onChange(value);

        if (onChangeCustom) {
          onChangeCustom(value);
        }
      }}
      {...controlledProps}
    />
  );
};

InputFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  ignoreError: PropTypes.bool,
  onChangeCustom: PropTypes.func,
  newFieldComponent: PropTypes.bool,
};

InputFinalFormAdapter.defaultProps = {
  ignoreError: false,
  newFieldComponent: false,
  onChangeCustom: () => {},
};
