import { MaintenanceAppointmentModel, MaintenanceModel } from 'api/models';

export const useMaintenanceAppointmentTask = (
  appointment: MaintenanceAppointmentModel,
  maintenance: MaintenanceModel
) => {
  const { proServiceIds } = appointment;

  if (!proServiceIds || proServiceIds.length === 0) {
    return undefined;
  }

  if (proServiceIds.includes(maintenance.id)) {
    return maintenance;
  }

  if (maintenance.subtasks) {
    for (const subtask of maintenance.subtasks) {
      for (const item of subtask.items) {
        if (proServiceIds.includes(Number(item.maintenance.jobId))) {
          return item;
        }
      }
    }
  }

  return undefined;
};
