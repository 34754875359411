import React from 'react';
import { IconDots } from '@belong/icons';
import { Image } from '@belong/ui';
import classNames from 'classnames/bind';
import { Tag } from 'components/HomeTag/HomeTag';
import styles from 'components/PaymentTile/PaymentTile.module.css';
import PaymentIconDisplay from 'components/Payments/PaymentIconDisplay/PaymentIconDisplay';
import Spinner from 'components/Spinner/Spinner';
import PaymentMenuSelectionContainer from 'containers/PaymentSelectionMenuContainer/PaymentMenuSelectionContainer';
import { PaymentPriority, PaymentPreference } from 'models/enums';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

function PaymentTile({
  showPaymentTileMarginOnMobile,
  menuProps,
  showSpinner,
  highlight,
  paymentType,
  paymentModalProps,
  showPriority,
  noCustomWidth,
  fullUser,
  hasLabels,
}) {
  return (
    <PaymentMenuSelectionContainer
      fitToButton
      fullUser={fullUser}
      paymentType={paymentType}
      paymentModalProps={paymentModalProps}
      menuButtonLabel={
        <>
          <div className={cx('payment-tile-wrapper', { highlight, spinner: showSpinner })}>
            <div className={cx('payment-tile', { showPaymentTileMarginOnMobile })}>
              {showSpinner && <Spinner />}
              <div className={cx('text')}>
                <PaymentIconDisplay
                  noCustomWidth={noCustomWidth}
                  icon={
                    <Image src="homeowner-earnings/payment-split-it.svg" alt="Split it" className="w-[46px] h-[30px]" />
                  }
                  title={
                    <>
                      <p>Split it</p>
                      <p className="text-p1 font-regular">Interest Applied • Deducted From Rent</p>
                    </>
                  }
                />
                {showPriority && (
                  <div className={cx('menu')}>
                    {hasLabels && <Tag className={cx('tag-maintenance')}>Pro Services</Tag>}
                    {hasLabels ? (
                      <div className={cx('dots-container')}>
                        <IconDots width={5} height={24} />
                      </div>
                    ) : (
                      <div className={cx('change')}>CHANGE</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      }
      {...menuProps}
    />
  );
}

PaymentTile.propTypes = {
  priority: PropTypes.string,
  showPaymentTileMarginOnMobile: PropTypes.bool,
  paymentType: PropTypes.oneOf(Object.values(PaymentPreference)).isRequired,
  paymentModalProps: PropTypes.object,
  menuProps: PropTypes.object,
  showSpinner: PropTypes.bool,
  highlight: PropTypes.bool,
  showPriority: PropTypes.bool,
  noCustomWidth: PropTypes.bool,
  fullUser: PropTypes.object,
  hasLabels: PropTypes.bool,
};

PaymentTile.defaultProps = {
  priority: PaymentPriority.Primary,
  paymentModalProps: {},
  menuProps: {},
  showPaymentTileMarginOnMobile: false,
  showSpinner: false,
  highlight: false,
  showPriority: true,
  noCustomWidth: false,
  hasLabels: true,
};

export default PaymentTile;
