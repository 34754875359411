import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Image from 'components/Image/Image';
import { useDeferRender } from 'hooks/useDeferRender';
import PropTypes from 'prop-types';
import styles from './ProfileImage.module.css';

const cx = classNames.bind(styles);

export default function ProfileImage({
  emptyImageContainer,
  customEmptyProfileIcon,
  classNameToResizeImage,
  imageContainer,
  scale,
  profileImage,
  makeRound,
  withHover,
  small,
  original,
  founders,
}) {
  const isClientReady = useDeferRender();
  if (!isClientReady) return null;

  return profileImage ? (
    <div className={cx(imageContainer, { makeRound, withHover, small })}>
      <Image
        className={cx(classNameToResizeImage, {
          'image-component': !original,
          'founders-image-component': founders,
        })}
        src={profileImage}
      />
    </div>
  ) : (
    <div className={cx(emptyImageContainer)}>
      <GeneralIcon icon={customEmptyProfileIcon} scale={scale} />
    </div>
  );
}

ProfileImage.propTypes = {
  profileImage: PropTypes.string,
  scale: PropTypes.number,
  classNameToResizeImage: PropTypes.string,
  imageContainer: PropTypes.string,
  emptyImageContainer: PropTypes.string,
  customEmptyProfileIcon: PropTypes.string,
  makeRound: PropTypes.bool,
  withHover: PropTypes.bool,
  small: PropTypes.bool,
  original: PropTypes.bool,
  founders: PropTypes.bool,
};

ProfileImage.defaultProps = {
  profileImage: null,
  scale: 0.5,
  classNameToResizeImage: 'image',
  imageContainer: '',
  emptyImageContainer: 'empty-icon',
  customEmptyProfileIcon: GENERAL_ICONS.EMPTY_PROFILE,
  makeRound: false,
  withHover: false,
  small: false,
  original: false,
  founders: false,
};
