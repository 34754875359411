import { Component } from 'react';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCurrency, unmaskNumber } from 'components/Input/masks';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import arrayMutators from 'final-form-arrays';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormCondition, { FIELD_CONDITION_COMPARISON_TYPES } from 'formcomponents/FormCondition/FormCondition';
import UnitBasicsForm from 'formcomponents/UnitBasicsForm/UnitBasicsForm';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { getString } from 'strings';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { required } from 'utils/validation';
import Space, { SPACE_TYPES } from '../../../../corecomponents/Space/Space';
import { PROPERTY_TYPES } from '../../constants';
import PropertyRentalLabel from '../components/PropertyRentalLabel/PropertyRentalLabel';

const { FormLayoutHeader } = FormLayout;
const HOB = HOMEOWNER_ONBOARDING_STRINGS.UnitBasics;

const Conditions = {
  IfMultiUnit: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: PROPERTY_TYPES.MULTI_UNIT,
  },
  IfOtherUnit: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: PROPERTY_TYPES.OTHER,
  },
  IfSingleFamily: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: PROPERTY_TYPES.SINGLE_FAMILY,
  },
  IfSingleUnit: {
    fieldName: `propertyBasics.unitsCount`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: 1,
  },
  IfNotSingleUnit: {
    fieldName: `propertyBasics.unitsCount`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.NOT_EQUALS,
    fieldValue: 1,
  },
  IfNotSingleFamily: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.NOT_EQUALS,
    fieldValue: PROPERTY_TYPES.SINGLE_FAMILY,
  },
  IfEntirePropertyOwned: {
    fieldName: `propertyBasics.isEntirePropertyOwnedBySingleOwner`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: true,
  },
  IfNotEntirePropertyOwned: {
    fieldName: `propertyBasics.isEntirePropertyOwnedBySingleOwner`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: false,
  },
  HasHOAFee: {
    fieldName: `propertyBasics.hasHoa`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: true,
  },
};

const getFlag = (value) => {
  if (value === 0) {
    return false;
  }
  if (value) {
    return true;
  }
};

class UnitBasics extends Component {
  static propTypes = {
    groups: PropTypes.object.isRequired,
    currentStepData: PropTypes.object.isRequired,
    currentStepResources: PropTypes.object.isRequired,
    onSaveAndNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  onSubmit = async (values) => {
    const { onSaveAndNext } = this.props;

    if (values.unitOwner.hasMortgage === false) {
      values.unitOwner.mortgage = 0;
    }

    if (values.unitOwner.hasHoaFee === false) {
      values.unitOwner.hoaFee = 0;
    }

    onSaveAndNext(values);
  };

  getInitialValues() {
    const {
      currentStepData,
      currentStepResources: { property },
    } = this.props;

    const { basicInfo, units } = property;

    return {
      ...currentStepData,
      unitOwner: {
        ...currentStepData.unitOwner,
        hasMortgage: getFlag(currentStepData.unitOwner?.mortgage),
        hasHoaFee: getFlag(currentStepData.unitOwner?.hoaFee),
      },
      propertyBasics: {
        propertyType: basicInfo.propertyType,
        isEntirePropertyOwnedBySingleOwner: basicInfo.isEntirePropertyOwnedBySingleOwner,
        unitsCount: units.length,
        hasHoa: basicInfo.hasHoa,
      },
    };
  }

  render() {
    const {
      groups: { homes },
      currentStepResources: { property, home, user },
      onBack,
    } = this.props;
    const containsUnits = !!(property.units.length > 1 || !!property.units[0].basicInfo.unitNumber);
    return (
      <>
        {homes.length > 1 && <PropertyRentalLabel property={property} home={home} />}
        <FormLayoutHeader
          pretitle={getString(HOB.subtitle, { firstName: user.firstName })}
          title={getString(HOB.title, {
            streetAddress: home.basicInfo.unitNumber
              ? `<span>Unit ${home.basicInfo.unitNumber}</span>`
              : property.address.streetAddress,
          })}
        />
        <Form
          mutators={{ ...arrayMutators }}
          getFormBottomBar={(_, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: 'Next',
                type: 'submit',
              }}
              nextButtonWrapperProps={nextButtonProps}
              previousStepProps={{
                onClick: onBack,
                to: null,
              }}
            />
          )}
          initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
          initialValues={this.getInitialValues()}
          onSubmit={this.onSubmit}
          getForm={({ form }) => {
            const formValues = form.getState().values;
            return (
              <>
                <Space value={SPACE_TYPES.LG} />
                <FormLayout.Section firstSection>
                  <UnitBasicsForm containsUnits={containsUnits} name="unitBasics" />
                </FormLayout.Section>
                <FormCondition
                  conditions={[
                    [Conditions.IfNotEntirePropertyOwned],
                    [Conditions.IfSingleFamily],
                    [Conditions.IfSingleUnit],
                  ]}
                  formValues={formValues}
                >
                  <FormCondition
                    conditions={[[Conditions.IfSingleFamily], [Conditions.IfSingleUnit]]}
                    formValues={formValues}
                  >
                    <FormLayout.Section sectionTitle={getString(HOB.has_mortgage_section_title)}>
                      <Field name="unitOwner.hasMortgage" component={YesNoFinalFormAdapter} validate={required} />

                      <Condition when="unitOwner.hasMortgage" is>
                        <Row>
                          <Col md={6}>
                            <Field
                              name="unitOwner.mortgage"
                              component={InputFinalFormAdapter}
                              placeholder={HOB.mortgage_fee_placeholder}
                              mask={maskCurrency}
                              unmask={unmaskNumber}
                              validate={required}
                            />
                          </Col>
                        </Row>
                      </Condition>
                    </FormLayout.Section>
                  </FormCondition>
                  <FormCondition
                    conditions={[
                      [Conditions.IfNotEntirePropertyOwned, Conditions.IfNotSingleUnit, Conditions.IfNotSingleFamily],
                    ]}
                    formValues={formValues}
                  >
                    <FormLayout.Section sectionTitle={HOB.has_mortgage_section_title}>
                      <Field name="unitOwner.hasMortgage" component={YesNoFinalFormAdapter} validate={required} />

                      <Condition when="unitOwner.hasMortgage" is>
                        <Row>
                          <Col md={6}>
                            <Field
                              name="unitOwner.mortgage"
                              component={InputFinalFormAdapter}
                              placeholder={HOB.mortgage_fee_placeholder}
                              mask={maskCurrency}
                              unmask={unmaskNumber}
                              validate={required}
                            />
                          </Col>
                        </Row>
                      </Condition>
                      <FormCondition conditions={[[Conditions.HasHOAFee]]} formValues={formValues}>
                        <FormLayout.Section large>
                          <Row>
                            <Col md={6}>
                              <Field
                                name="unitOwner.hoaFee"
                                component={InputFinalFormAdapter}
                                placeholder={HOB.hoa_fee_placeholder}
                                validate={required}
                                mask={maskCurrency}
                                unmask={unmaskNumber}
                              />
                            </Col>
                          </Row>
                        </FormLayout.Section>
                      </FormCondition>
                    </FormLayout.Section>
                  </FormCondition>
                </FormCondition>

                <FormCondition
                  conditions={[[Conditions.IfSingleUnit], [Conditions.IfSingleFamily]]}
                  formValues={formValues}
                >
                  <FormLayout.Section
                    sectionTitle={HOB.has_hoa_section_title}
                    sectionSubTitle={HOB.has_hoa_section_subtitle}
                  >
                    <Field name="unitOwner.hasHoaFee" component={YesNoFinalFormAdapter} validate={required} />
                    <Condition when="unitOwner.hasHoaFee" is>
                      <Row>
                        <Col md={6}>
                          <Field
                            name="unitOwner.hoaFee"
                            validate={required}
                            mask={maskCurrency}
                            unmask={unmaskNumber}
                            component={InputFinalFormAdapter}
                            placeholder={HOB.hoa_fee_placeholder}
                          />
                        </Col>
                      </Row>
                    </Condition>
                  </FormLayout.Section>
                </FormCondition>
              </>
            );
          }}
        />
      </>
    );
  }
}

export default UnitBasics;
