import { DATE_FORMATS } from '@belong/common';
import { parseDateTimeInputString } from 'utils/dateTimeUtils';
import { getApiActionTypesValues } from '../redux_utils';

export const fetchAvailableCitiesAndStates = () => async (dispatch) => {
  const data = await dispatch({
    types: getApiActionTypesValues('FETCH_CITIES_STATES'),
    promise: ({ client }) => client.get('/homes/available-locations'),
  });
  return data;
};

export const fetchQueriesForListingPage = (filters) => async (dispatch) => {
  if (filters.availableOnOrBefore) {
    filters.availableOnOrBefore = parseDateTimeInputString(filters.availableOnOrBefore, DATE_FORMATS.STRIPPED_ISO);
  }

  const data = await dispatch({
    types: getApiActionTypesValues('FETCH_LISTING_QUERIES'),
    promise: ({ client }) => {
      return client.get('/homes', {
        params: {
          pageIndex: 1,
          pageSize: 1500,
          ...filters,
        },
      });
    },
  });

  return data;
};
