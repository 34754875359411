import { useState } from 'react';
import { Text, Toast } from '@belong/ui';
import { HomeNotInspectionReadyBanner } from 'accounts/components/home-not-inspection-ready-banner/home-not-inspection-ready-banner';
import { OccupiedHomeBanner } from 'accounts/components/occupied-home-banner/occupied-home-banner';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { HOME_INSPECTION_STRINGS } from 'accounts/constants/strings/home-inspection.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import { required } from 'utils/validation';

export function HomeInspectionPrep() {
  const [doneLabel, setDoneLabel] = useState('Done');
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const { currentStep, currentStepFormData, goPreviousStep, handleSaveAndNext } = useSetupFlowStep();

  function getInitialValues() {
    if (!currentStep) {
      return {};
    }

    const initialValues = {
      specificRequests: currentStepFormData.specificRequests,
      isFurnished: currentStepFormData.furnishing?.isFurnished ?? null,
      areUtilitiesTurnedOn: currentStepFormData.inspectionInformation?.areUtilitiesTurnedOn,
      isConstructionActive: currentStepFormData.inspectionInformation?.isConstructionActive,
      isSomeoneLivingInTheHome: currentStepFormData.inspectionInformation?.isSomeoneLivingInTheHome,
    };

    return initialValues;
  }

  async function handleSubmit(values) {
    const { areUtilitiesTurnedOn, isConstructionActive, isSomeoneLivingInTheHome, specificRequests, isFurnished } =
      values;

    try {
      if (!areUtilitiesTurnedOn || isConstructionActive) {
        await handleSaveAndNext({
          areUtilitiesTurnedOn,
          isConstructionActive,
        });

        return;
      }

      await handleSaveAndNext({
        areUtilitiesTurnedOn,
        isConstructionActive,
        specificRequests,
        isSomeoneLivingInTheHome,
        isFurnished,
      });

    } catch (err) {
      setToastMessage('An error ocurred. Please, try again later');
      setIsToastOpen(true);
    }
  }

  return (
    <>
      <StepFormLayout
        title={HOME_INSPECTION_STRINGS.title}
        subTitle={HOME_INSPECTION_STRINGS.sub_title}
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        handleBackStep={goPreviousStep}
        disableProgressBar
        doneLabel={doneLabel}
        getForm={({ values }) => {
          const { areUtilitiesTurnedOn, isConstructionActive, isSomeoneLivingInTheHome, isFurnished } = values;

          setDoneLabel(areUtilitiesTurnedOn && isConstructionActive === false ? 'Confirm' : 'Done');

          return (
            <>
              <div className="mt-xl w-full">
                <Text as="span" variant="h3" fontWeight="semibold">
                  {HOME_INSPECTION_STRINGS.utilities.title}
                </Text>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2">
                <Field
                  name="areUtilitiesTurnedOn"
                  component={SelectorFinalFormAdapter}
                  fluid
                  type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                  validate={required}
                  buttons={[
                    {
                      key: true,
                      label: HOME_INSPECTION_STRINGS.utilities.yes,
                    },
                    {
                      key: false,
                      label: HOME_INSPECTION_STRINGS.utilities.no,
                    },
                  ]}
                />
              </div>
              <div className="mt-xl w-full">
                <Text as="span" variant="h3" fontWeight="semibold">
                  {HOME_INSPECTION_STRINGS.constructions.title}
                </Text>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2">
                <Field
                  name="isConstructionActive"
                  component={SelectorFinalFormAdapter}
                  fluid
                  type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                  validate={required}
                  buttons={[
                    {
                      key: true,
                      label: HOME_INSPECTION_STRINGS.constructions.yes,
                    },
                    {
                      key: false,
                      label: HOME_INSPECTION_STRINGS.constructions.no,
                    },
                  ]}
                />
              </div>
              {(areUtilitiesTurnedOn === false || isConstructionActive) && (
                <div className="my-sm">
                  <HomeNotInspectionReadyBanner />
                </div>
              )}
              {areUtilitiesTurnedOn && isConstructionActive === false && (
                <>
                  <div className="mt-xl w-full">
                    <Text as="span" variant="h3" fontWeight="semibold">
                      {HOME_INSPECTION_STRINGS.coliving.title}
                    </Text>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                    <Field
                      name="isSomeoneLivingInTheHome"
                      component={SelectorFinalFormAdapter}
                      fluid
                      type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                      validate={required}
                      buttons={[
                        {
                          key: true,
                          label: HOME_INSPECTION_STRINGS.coliving.yes,
                        },
                        {
                          key: false,
                          label: HOME_INSPECTION_STRINGS.coliving.no,
                        },
                      ]}
                    />
                  </div>
                  <div className="mt-xl w-full">
                    <Text as="span" variant="h3" fontWeight="semibold">
                      {HOME_INSPECTION_STRINGS.furniture.title}
                    </Text>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                    <Field
                      name="isFurnished"
                      component={SelectorFinalFormAdapter}
                      fluid
                      type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                      validate={required}
                      buttons={[
                        {
                          key: true,
                          label: HOME_INSPECTION_STRINGS.furniture.yes,
                        },
                        {
                          key: false,
                          label: HOME_INSPECTION_STRINGS.furniture.no,
                        },
                      ]}
                    />
                  </div>
                  {(isSomeoneLivingInTheHome || isFurnished) && (
                    <div className="my-sm">
                      <OccupiedHomeBanner />
                    </div>
                  )}
                </>
              )}
              {((areUtilitiesTurnedOn !== undefined && areUtilitiesTurnedOn !== null && !areUtilitiesTurnedOn) ||
                (isConstructionActive !== undefined && isConstructionActive !== null && isConstructionActive)) && (
                <>
                  <div className="mt-2xl w-full">
                    <Text as="span" variant="h3" fontWeight="semibold">
                      {HOME_INSPECTION_STRINGS['specific.request.title']}
                    </Text>
                  </div>
                  <Field
                    name="specificRequests"
                    placeholder={HOME_INSPECTION_STRINGS['specific_requests.placeholder']}
                    textarea
                    component={InputFinalFormAdapter}
                  />
                </>
              )}
            </>
          );
        }}
      />
      <Toast
        isVisible={isToastOpen}
        autocloseDuration={5000}
        variant='danger'
        onClose={() => {
          setIsToastOpen(false);
        }}
      >
        {toastMessage}
      </Toast>
    </>
  );
}
