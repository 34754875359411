import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import ButtonBase from '../../corecomponents/ButtonBase/ButtonBase';
import GeneralIcon, { GENERAL_ICONS } from '../GeneralIcon/GeneralIcon';
import styles from './InputTag.module.css';

const cx = classNames.bind(styles);

class InputTag extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onRemoveTag: PropTypes.func.isRequired,
    multiline: PropTypes.bool,
    nameClassName: PropTypes.string,
  };

  static defaultProps = {
    multiline: false,
  };

  render() {
    const { name, onRemoveTag, multiline, nameClassName } = this.props;
    return (
      <div className={cx('input-tag', { 'multiline-input-tag': multiline })}>
        <div className={cx('name', nameClassName)}>{name}</div>
        <div className={cx('line')} />
        <div className={cx('close')}>
          <ButtonBase onClick={onRemoveTag}>
            <GeneralIcon icon={GENERAL_ICONS.CLOSE} scale={0.75} />
          </ButtonBase>
        </div>
      </div>
    );
  }
}

export default InputTag;
