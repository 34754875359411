import { IconClose } from '@belong/icons';
import { ButtonBase } from '@belong/ui';

export const MaintenanceRecurrenceNavBar = ({ onClosePress }) => {
  return (
    <div className="flex py-xs px-xs justify-end sm:py-xs sm:px-sm">
      <ButtonBase className="p-xs sm:p-sm" onClick={onClosePress}>
        <IconClose height={16} />
      </ButtonBase>
    </div>
  );
};
