export const HOME_PETS_STRINGS = {
  title: 'May your residents have furry roommates?',
  petTypes: {
    ALL: {
      lowerCaseType: 'pets',
      upperCaseType: 'Pets',
    },
    DOGS: {
      lowerCaseType: 'dogs',
      upperCaseType: 'Dogs',
    },
    CATS: {
      lowerCaseType: 'cats',
      upperCaseType: 'Cats',
    },
  },
  preferences: {
    petsAllowed: {
      "maxAllowedPets.placeholder": "How many pets?",
      anyPets: 'Any (Legal) Pets',
      dogsOnly: 'Dogs Only',
      catsOnly: 'Cats Only',
      noPets: {
        text: 'No Pets',
        confirmationTitle: 'Three Reasons to Reconsider',
        confirmationBold: [
          'Increase the market for your home.',
          'Worried about wear and tear?',
          'Happier Residents.',
        ],
        confirmationText: [
          'The majority of residents have at least one pet. Opening up to pets increases competition for your home.',
          'Residents pay an additional fee for each pet, on top of the security deposit. Should scuffs or stains occur, we’ll handle any repairs ourselves. Plus, we’ll ask about pets when we do a reference check with your Residents’ previous landlord to make sure they were on their best behavior.',
          'Last but not least, pets make people happy, generating good will that will translate to better care for your home.',
        ],
      },
    },
    howManyPets: {
      sectionTitle: 'Up to how many {lowerCaseType} may <span>you renters have?</span>',
      allShapesCheckbox: '{upperCaseType} of all shapes and sizes <span>are welcome.</span>',
    },
    selectThatApplyTitle: 'Any other restrictions?',
    weightRestrictionsLabel: 'Only {lowerCaseType} under a <span>certain weight.</span>',
    weightLabel: 'Weight Limit (lb)',
    breedRestrictionsLabel: 'My HOA or insurance restricts <span>certain breeds.</span>',
    hoaRestrictionsLabel: 'Restricted Breed(s)',
    nextButton: 'Done',
  },
};
