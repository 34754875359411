import React, { useState, useEffect } from 'react';
import { COOKIES_CONFIG } from '@belong/common';
import clsx from 'clsx';
import { Text } from 'design-system';
import { parseCookies } from 'nookies';
import { formatString } from 'strings';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import css from './ReferralMessage.module.css';

const { REFERRAL_USER } = COOKIES_CONFIG;

const fadeTime = 10000;

const ReferralMessage = () => {
  const cookies = parseCookies();
  const userName = cookies[REFERRAL_USER.name];
  const [fadeClass, setFadeClass] = useState('');

  useEffect(() => {
    setFadeClass(css.fadeIn);
    const timer1 = setTimeout(() => setFadeClass(css.fadeOut), fadeTime);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  // This flag '-1' is setted when the HO came from a referral link, but the referral user is no longer in Belong.
  // This cookie es needed every time a user uses a referral link, regardless of the response.
  if (!userName) return null;

  return (
    <div className={clsx('flex justify-center items-end fixed', css.mainBox, fadeClass)}>
      <div className={clsx('fixed', css.subBox)}>
        <div className={clsx('p-sm rounded flex justify-center items-center w-full', css.messageBox)}>
          <Text fontWeight="semibold" className={clsx('text-white', css.mainText)}>
            {userName !== '-1'
              ? formatString(HOMEOWNER_ONBOARDING_STRINGS['referral-message.success'], { userName })
              : HOMEOWNER_ONBOARDING_STRINGS['referral-message.notfound']}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ReferralMessage;
