import React from 'react';
import PropTypes from 'prop-types';
import Condition from 'formcomponents/Condition/Condition';
import { AvailableForRentSection } from '../AvailableForRentSection/AvailableForRentSection';
import { VacantMoveOutSection } from '../VacantMoveOutSection/VacantMoveOutSection';
import { VacantAvailabilitySection } from '../VacantAvailabilitySection/VacantAvailabilitySection';
import { RentedBeforeSection } from '../RentedBeforeSection/RentedBeforeSection';
import { RentingPlansSection } from '../RentingPlansSection/RentingPlansSection';

export const VacantForm = ({ changeFormValue, form, name }) => (
  <>
    <AvailableForRentSection changeFormValue={changeFormValue} form={form} name={name} />
    <Condition is={false} when={`${name}.availableToRent`}>
      <VacantMoveOutSection name={name} />
      <VacantAvailabilitySection name={name} />
    </Condition>
    <RentedBeforeSection changeFormValue={changeFormValue} name={name} />
    <RentingPlansSection name={name} />
  </>
);

VacantForm.propTypes = {
  changeFormValue: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
