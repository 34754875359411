import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Money from '../../../components/Money/Money';
import styles from './PriceSection.module.css';

const cx = classNames.bind(styles);
export const PRICE_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  DEFAULT: '',
};

class PriceSection extends Component {
  static propTypes = {
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    regularText: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(PRICE_SIZES)),
  };

  static defaultProps = {
    regularText: false,
    size: PRICE_SIZES.DEFAULT,
  };

  render() {
    const { price, regularText, size } = this.props;
    return (
      <span className={cx('price-section', size, { regularText })}>
        <span className={cx('price')}>
          <Money cash={parseInt(price, 10)} />
        </span>
        <span className={cx('divider')}>/</span>
        <span className={cx('month')}>mo</span>
      </span>
    );
  }
}

export default PriceSection;
