import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RescheduleAppointmentContext } from 'accounts/contexts/reschedule-appointment';
import type { RescheduleAppointmentContextSchema } from 'accounts/types';
import { rescheduleAppointment as rescheduleAppointmentAction } from 'store/redux/accounts/actions';
import { fetchPropertyTimelines, fetchUpcomingAppointments } from 'store/redux/homeowner-accounts/actions';

export function useRescheduleAppointmentContext(): RescheduleAppointmentContextSchema {
  const context = useContext(RescheduleAppointmentContext);

  if (!context) {
    throw new Error(
      'useRescheduleAppointmentContext consumer must be wrapped by RescheduleAppointmentContextProvider at some level'
    );
  }

  return context;
}

type RescheduleAppointmentParams = {
  unitId: string;
  start: string;
};

export function useRescheduleAppointment() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const rescheduleAppointment = async ({ unitId, start }: RescheduleAppointmentParams) => {
    try {
      setIsLoading(true);

      await dispatch(
        rescheduleAppointmentAction(unitId, {
          start,
        })
      );

      await dispatch(fetchUpcomingAppointments(unitId));
      await dispatch(fetchPropertyTimelines([unitId]));
    } catch (error) {
      console.error(error);

      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, rescheduleAppointment };
}
