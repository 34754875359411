import { formatMoney } from '@belong/common';
import { Text } from '@belong/ui';
import { Option } from '@belong/ui/src/ButtonSelector/ButtonSelector';
import { MaintenanceGroupBundleItemModel } from 'api/models';
import { twMerge } from 'tailwind-merge';

export const useMaintenanceProposalOptions = (
  items: MaintenanceGroupBundleItemModel[],
  selectedProposalId?: string
) => {
  const options: Option<string>[] = items?.map((proposal, index) => {
    const isSelected = proposal.maintenance.uniqueId === selectedProposalId;
    const purposeLabel = proposal.estimatedCostTypeOfWork ? ` | ${proposal.estimatedCostTypeOfWork}` : '';
    const isApproved = !proposal.maintenancePayments.some((payment) => payment.consentStatus !== 'Approved');
    const isRejected = !proposal.maintenancePayments.some((payment) => payment.consentStatus !== 'Rejected');
    const tag = isApproved
      ? 'Approved'
      : !isRejected && proposal.isEstimatedCostRecommended
      ? 'Recommended'
      : undefined;
    return {
      label: `Option ${index + 1}${purposeLabel}`,
      content:
        proposal.totalEstimatedCost > 0 ? (
          <div className={twMerge('grow h-full py-2xs', isSelected ? 'bg-navy' : 'bg-white')}>
            <Text className={isSelected ? 'text-white' : 'text-navy'} fontWeight="semibold">{`Option ${
              index + 1
            }${purposeLabel}`}</Text>
            <Text className={isSelected ? 'text-white' : 'text-navy'} variant="p1">
              {formatMoney(proposal.totalEstimatedCost)}
            </Text>
          </div>
        ) : undefined,
      value: proposal.maintenance.uniqueId,
      tag,
    };
  });

  return options ?? [];
};
