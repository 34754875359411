import SuccessPage from '../SuccessPage/SuccessPage';
import AddCosigner from './AddCosigner/AddCosigner';
import ApplicationFee from './ApplicationFee/ApplicationFee';
import BackgroundCheck from './BackgroundCheck/BackgroundCheck';
import CreditCheck from './CreditCheck/CreditCheck';
import EmploymentInformation from './EmploymentInformation/EmploymentInformation';
import IncomeVerification from './IncomeVerification/IncomeVerification';
import MoveInDate from './MoveInDate/MoveInDate';
import PeopleAndPets from './PeopleAndPets/PeopleAndPets';
import PrimaryApplicantLandingPage from './PrimaryApplicantLandingPage/PrimaryApplicantLandingPage';
import ReferenceCheck from './ReferenceCheck/ReferenceCheck';
// import IncomeVerification from './IncomeVerification/IncomeVerification';
import ResidentIdentityVerification from './ResidentIdentityVerification/ResidentIdentityVerification';
import { STEPS_CONFIG } from './steps';

export const ResidentApplicationRoutes = [
  {
    path: STEPS_CONFIG.PA_LANDING_PAGE.path,
    component: PrimaryApplicantLandingPage,
  },
  {
    path: STEPS_CONFIG.MOVE_IN_DATE.path,
    component: MoveInDate,
  },
  {
    path: STEPS_CONFIG.PEOPLE_AND_PETS.path,
    component: PeopleAndPets,
  },
  {
    path: STEPS_CONFIG.CREDIT_CHECK.path,
    component: CreditCheck,
  },
  {
    path: STEPS_CONFIG.APPLICATION_FEE.path,
    component: ApplicationFee,
  },
  {
    path: STEPS_CONFIG.EMPLOYMENT_INFO.path,
    component: EmploymentInformation,
  },
  {
    path: STEPS_CONFIG.INCOME_VERIFICATION.path,
    component: IncomeVerification,
  },
  {
    path: STEPS_CONFIG.REFERENCE_CHECK.path,
    component: ReferenceCheck,
  },
  {
    path: STEPS_CONFIG.BACKGROUND_CHECK.path,
    component: BackgroundCheck,
  },
  {
    path: STEPS_CONFIG.ADD_COSIGNER.path,
    component: AddCosigner,
  },
  {
    path: STEPS_CONFIG.IDENTITY_VERIFICATION.path,
    component: ResidentIdentityVerification,
  },
  {
    path: STEPS_CONFIG.SUCCESS_PAGE.path,
    component: SuccessPage,
  },
];
