import { useCallback, useEffect, useState } from 'react';
import { DELIVERY_METHODS } from 'accounts/constants/home-keys';
import { HOME_KEYS_STRINGS } from 'accounts/constants/strings/home-keys.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
// TODO: Regions: Fix it.
import { REGION_TYPES } from 'consts/address';
import { KeysDeliveredBy, PropertyType } from 'models/enums';

export function useHomeKeys({ currentStep, isAdoptedResident }) {
  const { properties, fetchProperties } = useSetupFlowStep();
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const isSingleFamilyTypeProperty = currentStep?.property?.basicInfo?.propertyType === PropertyType.SingleFamily;
  const isUnitInspectionCompleted = !!currentStep?.unit?.basicInfo?.isInspectionCompleted;
  const region = currentStep?.property?.coverageRegion?.region;
  const hasHoa = currentStep?.property?.basicInfo.hasHoa;

  const init = useCallback(() => {
    if (properties) {
      let isHomeownerAttendingInspection = false;

      for (const property of properties) {
        const unit = property.units.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (currentUnit: any) => currentUnit.basicInfo.unitId === currentStep?.unit?.basicInfo?.unitId
        );

        if (unit) {
          isHomeownerAttendingInspection = unit.unitAdditional?.inspectionPreferences?.presencePreference;
        }
      }

      // TODO: Regions: Check it
      const newDeliveryMethod = DELIVERY_METHODS.filter((method) => {
        if (region === REGION_TYPES.MIAMI.name && method.key === KeysDeliveredBy.DropOff) {
          return false;
        }

        return true;
      }).map((method) => {
        if (method.key === KeysDeliveredBy.Lockbox) {
          return { ...method, label: HOME_KEYS_STRINGS['delivery.homeownerLockbox'] };
        }

        return method;
      });

      setDeliveryMethods(newDeliveryMethod);
    }
  }, [currentStep, isAdoptedResident, properties, region]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    deliveryMethods,
    region,
    hasHoa,
    isSingleFamilyTypeProperty,
    isUnitInspectionCompleted,
  };
}
