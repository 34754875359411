const getCityOrNeighborhood = (addressModel) => {
  const showNeighborhood = addressModel.displayNeighborhood && addressModel.neighborhood;
  return showNeighborhood ? addressModel.neighborhood : addressModel.city;
};

export const addressAndUnit = (addressModel, unitNumber) => {
  const isNumeric = !Number.isNaN(Number(unitNumber));
  let unitString = '';

  if (!addressModel.streetAddress) {
    return `${getCityOrNeighborhood(addressModel)}, ${addressModel.state}`;
  }

  if (unitNumber && isNumeric) {
    unitString = ` #${unitNumber}`;
  } else if (unitNumber && !isNumeric) {
    unitString = ` Unit\u00a0${unitNumber}`;
  }

  return `${addressModel.streetAddress}${unitString}`;
};

export const formatAddress = (addressModel, unitNumber) => {
  if (!addressModel.streetAddress) {
    return `${getCityOrNeighborhood(addressModel)}, ${addressModel.state}`;
  }

  return `${addressModel.streetAddress}${unitNumber ? ` Unit\u00a0${unitNumber}` : ''},
    <span>${getCityOrNeighborhood(addressModel)}, ${addressModel.state} ${addressModel.zipcode}</span>`;
};

export const formatFirstLine = (addressModel, unitNumber) =>
  `${addressModel.streetAddress}${unitNumber ? ` Unit\u00a0${unitNumber}` : ''}`;

export const formatSecondLine = (addressModel) => `${addressModel.city}, ${addressModel.state} ${addressModel.zipcode}`;

export const formatAddressWithCity = (addressModel, unitNumber) => {
  return `${addressModel.streetAddress}${unitNumber ? ` Unit\u00a0${unitNumber}` : ''}, ${getCityOrNeighborhood(
    addressModel
  )}`;
};
