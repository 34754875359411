import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { PATHS } from '@belong/common';
import { Loading } from 'components/Loading/Loading';
import { MaintenanceRecurrence } from 'pages/Accounts/Components/maintenance-recurrence/maintenance-recurrence';
import { useMaintenanceRecurrenceTaskDetails } from 'pages/Accounts/Components/maintenance-recurrence/maintenance-recurrence.hooks';
import { fetchRecurrentMaintenanceByTaskId } from 'store/redux/maintenance/actions';

type MaintenanceRecurrentRouteParams = {
  homeUniqueId: 'string';
  recurrenceType: 'string';
};

type MaintenanceRecurrentComponentProps = {
  fetchTask: () => void;
  history: RouteComponentProps['history'];
};

const MaintenanceRecurrentComponent = ({ fetchTask, history }: MaintenanceRecurrentComponentProps) => {
  const { homeUniqueId, recurrenceType } = useParams<MaintenanceRecurrentRouteParams>();
  const { taskDetails, isLoading } = useMaintenanceRecurrenceTaskDetails({
    homeUniqueId,
    recurrenceType,
    fetchTask,
  });

  const navigateToSubtask = useCallback(
    (subtaskId) => {
      const subtask = subtaskId ?? 'default';

      history.push(`${PATHS.RESIDENTS_ACCOUNT_RECURRING_MAINTENANCE}/${homeUniqueId}/${recurrenceType}/${subtask}`);
    },
    [history, homeUniqueId, recurrenceType]
  );

  const navigateBack = useCallback(() => {
    history.push(PATHS.RESIDENTS_ACCOUNT_MAINTENANCE);
  }, [history]);

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <MaintenanceRecurrence
      onSubtaskClick={navigateToSubtask}
      onClosePress={navigateBack}
      task={taskDetails}
      isResident
    />
  );
};

const mapDispatchToProps = {
  fetchTask: fetchRecurrentMaintenanceByTaskId,
};

export default connect(null, mapDispatchToProps)(MaintenanceRecurrentComponent);
