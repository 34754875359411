import { Text } from '@belong/ui';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { STRINGS } from 'post-inspection/constants/strings';

export const FlaggedImprovementsWarning = () => (
  <div className="pb-lg">
    <div className="flex items-center justify-start sm:justify-center">
      <div className="self-start pt-[6px] sm:self-center sm:pt-0">
        <GeneralIcon icon={GENERAL_ICONS.ICON_FLAG} />
      </div>
      <div className="ml-2sm max-w-[290px] sm:max-w-none">
        <Text variant="p1">{STRINGS['flagged-items-warning']}</Text>
      </div>
    </div>
  </div>
);
