// React imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import RelationshipFieldAdapter from 'pages/ResidentApplication/Steps/fields/RelationshipField/RelationshipField';
// Utils, Strings & Const imports
import { composeValidators, email, required } from 'utils/validation';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
// SCSS imports

const RAS = RESIDENT_APPLICATION_STRINGS.people_and_pets.other_adults;

export default class MultipleResidentDetails extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired,
    primaryApplicantEmail: PropTypes.string.isRequired,
  };

  state = {
    errorMessage: null,
  };

  validateEmail = (emailValue) => {
    const { values, index, primaryApplicantEmail } = this.props;
    let errorOccured = false;
    let errorMessage = null;
    this.setState({ errorMessage });

    if (emailValue?.toLowerCase() === primaryApplicantEmail?.toLowerCase()) {
      errorOccured = true;
      errorMessage = RAS.primary_applicant_email_error;
    }

    values.adults.forEach((adultFormData, adultIndex) => {
      if (adultIndex !== index && adultFormData?.email?.toLowerCase() === emailValue?.toLowerCase()) {
        errorOccured = true;
        errorMessage = RAS.coapplicant_email_error;
      }
    });

    if (errorOccured) {
      this.setState({ errorMessage });
      return errorMessage;
    }
  };

  render() {
    const { name } = this.props;
    const { errorMessage } = this.state;

    return (
      <>
        <Row>
          <Col md={6}>
            <Field
              name={`${name}.firstName`}
              component={InputFinalFormAdapter}
              validate={required}
              placeholder={RAS.first_name}
            />
          </Col>
          <Col md={6}>
            <Field
              name={`${name}.lastName`}
              component={InputFinalFormAdapter}
              validate={required}
              placeholder={RAS.last_name}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              name={`${name}.email`}
              component={InputFinalFormAdapter}
              validate={composeValidators(required, email, this.validateEmail)}
              placeholder={RAS.email}
              errorMessage={errorMessage}
            />
          </Col>
        </Row>
        <Field namePrefix={name} name={`${name}.relationship`} component={RelationshipFieldAdapter} />
      </>
    );
  }
}
