import {
  HomeAccountListingDetailsModel,
  InspectionModel,
  MaintenanceModel,
  PropertySummary,
  ReportDetailModel,
} from 'api/models';
import UserActionMaintenance from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance';
import { USERACTIONMAINTENANCE_TYPES } from 'containercomponents/MaintenanceStatus/UserActionMaintenance/userActionMaintenanceTypes';
import { Text } from 'design-system';
import { find } from 'lodash-es';
import { ConsentStatus, EmployeeAssignmentTypes, MaintenanceRequester } from 'models/enums';
import { EMPLOYEE_TITLES } from 'strings/common.strings';

type GroupEmployeeAssignmentsProps = {
  report?: ReportDetailModel;
  task?: MaintenanceModel;
  listingsData: HomeAccountListingDetailsModel[];
  propertiesData: PropertySummary[];
  inspectionData: InspectionModel;
};

export const GroupEmployeeAssignments = ({
  report,
  task,
  listingsData,
  propertiesData,
  inspectionData,
}: GroupEmployeeAssignmentsProps) => {
  // const assessor = report?.employeeAssignments?.find(
  //   (ea) => ea.employeeAssignment?.assignmentType === EmployeeAssignmentTypes.ImprovementsAssessor
  // );
  const reportDetails = report?.report || {};
  const maintenancePayment = report.details?.group?.payments?.find((p) => p.paidBy === MaintenanceRequester.Homeowner);
  const homeowner = task?.homeowner;
  const approved =
    maintenancePayment?.consentStatus === ConsentStatus.Approved && reportDetails.completedOn && homeowner;

  const listing = find(
    listingsData,
    (listingDetail) => listingDetail.homeListingDetailsModel.basicInfo.unitId === report?.report?.homeId
  );
  const inspectedBy = inspectionData?.inspectedBy || listing?.homeListingDetailsModel?.inspectedBy;

  let foundUnit;
  // eslint-disable-next-line no-unused-expressions
  propertiesData?.properties?.forEach((p) => {
    const unit = p.units.find((u) => u.basicInfo.unitId === report?.report?.homeId);
    if (unit) {
      foundUnit = unit;
    }
  });

  const concierge = foundUnit?.employeeAssignments?.find(
    (ea) => ea.employeeAssignment.assignmentType === EmployeeAssignmentTypes.Concierge
  );

  const homeownerGrowth = foundUnit?.employeeAssignments?.find(
    (ea) => ea.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const assessor = concierge ?? homeownerGrowth;
  const assessed = assessor && reportDetails.publishedOn;

  return (
    <>
      <h3>Summary</h3>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <Text className="mt-sm">"{task.description}"</Text>
      {inspectedBy?.userId && (
        <UserActionMaintenance
          userId={inspectedBy.userId}
          leftPadding
          withSpace={false}
          taskType={USERACTIONMAINTENANCE_TYPES.REPORTED}
          time={task.createdOn}
          name={`${inspectedBy.firstName} ${inspectedBy.lastName}`}
          profileImage={inspectedBy.profileImageUrl}
          reportedBy="Inspector Extraordinaire"
          belongEmployee
        />
      )}

      {assessed && (
        <UserActionMaintenance
          userId={assessor.user.userId}
          leftPadding
          withSpace={false}
          taskType={USERACTIONMAINTENANCE_TYPES.ASSESSED}
          time={reportDetails.publishedOn}
          name={assessor.user.name}
          profileImage={assessor.user.profileImageUrl}
          reportedBy={EMPLOYEE_TITLES[assessor.employeeAssignment.assignmentType]}
          belongEmployee
        />
      )}

      {approved && (
        <UserActionMaintenance
          userId={homeowner.userId}
          leftPadding
          reportedBy="Homeowner"
          withSpace={false}
          taskType={USERACTIONMAINTENANCE_TYPES.APPROVED}
          time={reportDetails.completedOn}
          profileImage={homeowner.profileImageUrl}
          name="You"
        />
      )}
    </>
  );
};
