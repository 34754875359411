import RouteParser from 'route-parser';
import { SAVE_AND_EXIT_BUTTON } from '../SaveAndExitButton/SaveAndExitButton';

export const STEPS_CONFIG = {
  PA_LANDING_PAGE: {
    name: 'pa_landing_page',
    key: 'get-started',
    path: '/resident/:houseId/applications/get-started',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 0,
    },
  },

  MOVE_IN_DATE: {
    name: 'move_in_date',
    key: 'move-in-date',
    path: '/resident/:houseId/applications/move-in-date',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 1,
    },
  },

  PEOPLE_AND_PETS: {
    name: 'people_and_pets',
    key: 'people-and-pets',
    path: '/resident/:houseId/applications/:applicationId/people-and-pets',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 2,
    },
  },

  CREDIT_CHECK: {
    name: 'credit_check',
    key: 'credit-check',
    path: '/resident/:houseId/applications/:applicationId/credit-check',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 3,
    },
  },

  EMPLOYMENT_INFO: {
    name: 'employment_info',
    key: 'employment-information',
    path: '/resident/:houseId/applications/:applicationId/employment-information',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 4,
    },
  },

  // Reason for removing: https://belonghome.slack.com/archives/C03SR4TQMHT/p1660686048423039
  INCOME_VERIFICATION: {
    name: 'income_verification',
    key: 'income-verification',
    path: '/resident/:houseId/applications/:applicationId/income-verification',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 5,
    },
  },

  REFERENCE_CHECK: {
    name: 'reference_check',
    key: 'reference-check',
    path: '/resident/:houseId/applications/:applicationId/reference-check',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 6,
    },
  },

  BACKGROUND_CHECK: {
    name: 'background_check',
    key: 'background-check',
    path: '/resident/:houseId/applications/:applicationId/background-check',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 7,
    },
  },
  APPLICATION_FEE: {
    name: 'application_fee',
    key: 'application-fee',
    path: '/resident/:houseId/applications/:applicationId/application-fee',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 8,
    },
  },

  ADD_COSIGNER: {
    name: 'add_cosigner',
    key: 'add-cosigner',
    path: '/resident/:houseId/applications/:applicationId/add-cosigner',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 9,
    },
  },

  IDENTITY_VERIFICATION: {
    name: 'identity_verification',
    key: 'identity-verification',
    path: '/resident/:houseId/applications/:applicationId/identity-verification',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 10,
    },
  },

  SUCCESS_PAGE: {
    name: 'success_page',
    key: 'success',
    path: '/resident/:houseId/applications/success',
    saveAndExitButton: SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME,
    state: {
      step: 11,
    },
  },
};

export const STEPS = [
  STEPS_CONFIG.PA_LANDING_PAGE,
  STEPS_CONFIG.MOVE_IN_DATE,
  STEPS_CONFIG.PEOPLE_AND_PETS,
  STEPS_CONFIG.CREDIT_CHECK,
  STEPS_CONFIG.EMPLOYMENT_INFO,
  STEPS_CONFIG.INCOME_VERIFICATION,
  STEPS_CONFIG.REFERENCE_CHECK,
  STEPS_CONFIG.BACKGROUND_CHECK,
  STEPS_CONFIG.APPLICATION_FEE,
  STEPS_CONFIG.ADD_COSIGNER,
  STEPS_CONFIG.IDENTITY_VERIFICATION,
  STEPS_CONFIG.SUCCESS_PAGE,
];

export const getResidentApplicationStepPath = (step, params) => {
  const route = new RouteParser(step.path);
  let path = route.reverse(params);
  path = path.replace('?', '');
  return {
    pathname: path,
    state: {
      ...step.state,
      verified: params?.verified,
    },
  };
};
