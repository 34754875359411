import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import Icon, { ICONS } from 'components/Icon/Icon';
import { SelectorFinalFormAdapter } from 'components/Selector/Selector';
import PropTypes from 'prop-types';
import { required } from 'utils/validation';
import styles from './satisfaction.module.css';

const cx = classNames.bind(styles);

export const SATISFACTION_TYPES = {
  GOOD: 'Good',
  BAD: 'Bad',
  NEUTRAL: 'Neutral',
};

export const Satisfaction = ({ validate, name, ...rest }) => {
  return (
    <Field
      name={name}
      component={SelectorFinalFormAdapter}
      className={cx('satisfaction-selector')}
      validate={validate}
      buttons={[
        {
          label: SATISFACTION_TYPES.GOOD,
          key: SATISFACTION_TYPES.GOOD,
          icon: <Icon icon={ICONS.HAPPY_FACE_BALLOON.DEFAULT} responsive />,
          iconSelected: <Icon icon={ICONS.HAPPY_FACE_BALLOON.INVERSE} responsive />,
        },
        {
          label: SATISFACTION_TYPES.NEUTRAL,
          key: SATISFACTION_TYPES.NEUTRAL,
          icon: <Icon icon={ICONS.NEUTRAL_FACE_BALLOON.DEFAULT} responsive />,
          iconSelected: <Icon icon={ICONS.NEUTRAL_FACE_BALLOON.INVERSE} responsive />,
        },
        {
          label: SATISFACTION_TYPES.BAD,
          key: SATISFACTION_TYPES.BAD,
          icon: <Icon icon={ICONS.UNHAPPY_FACE_BALLOON.DEFAULT} responsive />,
          iconSelected: <Icon icon={ICONS.UNHAPPY_FACE_BALLOON.INVERSE} responsive />,
        },
      ]}
      {...rest}
    />
  );
};

Satisfaction.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

Satisfaction.defaultProps = {
  validate: required,
};
