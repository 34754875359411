import { palette, fontSize } from '@belong/common';

var THEME = {
  textColor: palette.navy,
  fontSize: parseInt(fontSize.p1),
  fontFamily: 'Montserrat',
  grid: {
    line: {
      stroke: '#ededed',
      strokeWidth: 1,
      strokeDasharray: '6 6'
    }
  }
};
var DEFAULT_MARGIN = {
  top: 50,
  right: 110,
  bottom: 50,
  left: 60
};
var DEFAULT_Y_SCALE = {
  type: 'linear',
  min: 'auto'
};
var DEFAULT_LAYERS = ['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends'];
var DEFAULT_AXIS_BOTTOM = {
  legendOffset: 36,
  legendPosition: 'middle'
};
var DEFAULT_AXIS_LEFT = {
  legendOffset: -50,
  legendPosition: 'middle'
};

export { DEFAULT_AXIS_BOTTOM, DEFAULT_AXIS_LEFT, DEFAULT_LAYERS, DEFAULT_MARGIN, DEFAULT_Y_SCALE, THEME };
