import numeral from 'numeral';

export const NUMBER_FORMATS = {
  DEFAULT: '0,0',
};

export const formatNumber = (number, format = NUMBER_FORMATS.DEFAULT) => {
  if (!number) {
    return '';
  }

  return numeral(number).format(format);
};
