import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSetupFlow } from 'accounts/hooks/homeowners/use-setup-flow';
import { fetchPropertyTimelines } from 'store/redux/homeowner-accounts/actions';
import { useSelectedProperty } from './use-selected-property';

export function useTimeline() {
  const dispatch = useDispatch();
  const { selectedProperty } = useSelectedProperty();
  const { unitIds, units } = useSetupFlow();
  const [selectedUnit, setSelectedUnit] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      if (selectedProperty && units) {
        await dispatch(fetchPropertyTimelines(unitIds));

        const selectedUnitId = selectedProperty.getUnitsWithAgreements()?.[0]?.basicInfo?.unitId;
        const newSelectedUnit = units?.find((unit) => unit.basicInfo.unitId === selectedUnitId);

        if (newSelectedUnit?.basicInfo) {
          newSelectedUnit.basicInfo.timeZone = selectedProperty.getUnitsWithAgreements()?.[0]?.basicInfo?.timeZone;
        }

        setSelectedUnit(newSelectedUnit);
        setLoading(false);
      }
    };

    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty, units]);

  return { selectedUnit, loading };
}
