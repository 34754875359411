import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Text } from '@belong/ui';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { closeFinancePlan } from 'store/redux/accounts/actions';

function FinanceCloseModal({ financeUniqueId, onHide, closeFinancePlan: closeFinancePlanAction, ...rest }) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  function handleClose() {
    if (onHide) {
      setErrorMessage('');
      setHasError(false);
      onHide();
    }
  }

  async function handleCloseFinancePlan() {
    setLoading(true);

    try {
      await closeFinancePlanAction(financeUniqueId);

      handleClose();
      setLoading(false);
      setHasError(false);
    } catch (err) {
      console.error(err);

      setHasError(true);
      setErrorMessage(err?.[0]?.message ?? 'An error has ocurred. Please try again later.');
      setLoading(false);
    }
  }

  return (
    <ModalV2 {...rest} noPadding showSpinner={loading} onHide={handleClose} className="">
      <ModalLayout fixedBottom>
        <ModalLayout.Content className="p-0">
          <ModalLayout.Section>
            <ModalLayout.Title>
              <Text variant="h3" fontWeight="semibold">
                Would you like to close this plan?
              </Text>
            </ModalLayout.Title>
            <ModalLayout.SubTitle>
              <div className="px-sm md:px-3xl">
                <Text>In doing so, remaining balances will be added to your next bill, to be paid in full.</Text>
              </div>
            </ModalLayout.SubTitle>
            <div className="mt-xl">
              <div className="flex  flex-col">
                <div className="flex justify-center">
                  <Button variant="solid" buttonType="primary" onClick={handleCloseFinancePlan}>
                    <Text variant="body" fontWeight="semibold" className="px-2sm">
                      Close Plan
                    </Text>
                  </Button>
                </div>
                {hasError && errorMessage && (
                  <div className="flex justify-center mt-xs">
                    <Text variant="p1" fontWeight="semibold" className="px-2sm text-red">
                      {errorMessage}
                    </Text>
                  </div>
                )}
                <div className="my-lg flex justify-center">
                  <Button size="fluid-long-responsive" variant="text" buttonType="primary" onClick={handleClose}>
                    <Text variant="body" fontWeight="semibold" className="px-2sm">
                      I WANT TO KEEP IT
                    </Text>
                  </Button>
                </div>
              </div>
            </div>
          </ModalLayout.Section>
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
}

FinanceCloseModal.propTypes = {
  financeUniqueId: PropTypes.string,
  onHide: PropTypes.func,
  closeFinancePlan: PropTypes.func,
};

const mapDispatchToProps = {
  closeFinancePlan,
};

export default connect(null, mapDispatchToProps)(FinanceCloseModal);
