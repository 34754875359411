import { Fragment } from 'react';
import { formatUtcDateTimeToTimeZone } from '@belong/common';
import { Text } from '@belong/ui';
import { HOME_LISTING_STRINGS } from 'accounts/constants/strings/home-listing.strings';
import { LazyLottieAnimation } from 'animations/LazyLottieAnimation/LazyLottieAnimation';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { formatString } from 'strings';

type HomeListingBannerStepProps = {
  inspectionScheduledOn?: string;
  inspectionFinishOn?: string;
  isAdoptedResident: boolean;
  timeZone: string;
};

const lottieAnimationStyles = { width: '75px', height: '75px' };
const lottieAnimationOptions = { loop: true };

export function HomeListingBannerStep({
  inspectionScheduledOn = '',
  inspectionFinishOn = '',
  isAdoptedResident,
  timeZone = 'America/Los_Angeles',
}: HomeListingBannerStepProps) {
  const formattedDate = `${formatUtcDateTimeToTimeZone({
    dateTime: inspectionScheduledOn,
    format: 'LLLL do, Y',
    timeZone,
  })} ${formatUtcDateTimeToTimeZone({
    dateTime: inspectionScheduledOn,
    format: 'ha',
    timeZone,
  })} - ${formatUtcDateTimeToTimeZone({
    dateTime: inspectionFinishOn,
    format: 'ha',
    timeZone,
  })}`;

  return (
    <GreenInfoBox>
      <div className="flex">
        <div className="mr-sm">
          <LazyLottieAnimation name="lock_unlock" options={lottieAnimationOptions} style={lottieAnimationStyles} />
        </div>
        <div className="flex flex-col justify-center">
          {isAdoptedResident ? (
            <Fragment>
              <Text as="span" fontWeight="semibold">
                {formatString(HOME_LISTING_STRINGS['banner_step.adopted_resident'])}
              </Text>
              <Text variant="p1" fontWeight="semibold">
                {formatString(HOME_LISTING_STRINGS['banner_step.adopted_resident.next_up'])}
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              {inspectionScheduledOn ? (
                <>
                  <div className="block sm:inline-block">
                    <div className="block sm:contents">
                      <Text as="span" fontWeight="semibold">
                        {formatString(HOME_LISTING_STRINGS.banner_step_scheduled_inspection)}
                      </Text>
                    </div>
                    <div className="hidden sm:inline-block">&nbsp;</div>
                    <div className="block sm:contents">
                      <Text as="span" fontWeight="semibold">
                        {formattedDate}
                      </Text>
                    </div>
                  </div>
                  <Text variant="p1" fontWeight="semibold">
                    {formatString(HOME_LISTING_STRINGS.banner_step_scheduled_inspection_subtitle)}
                  </Text>
                </>
              ) : (
                <div>
                  <Text as="span" fontWeight="semibold">
                    Great! Your Homeowner Advisor will follow-up shortly to get your inspection&nbsp;scheduled.
                  </Text>
                  <Text variant="p1" fontWeight="semibold">
                    To save time, let&apos;s get started by unlocking your professional&nbsp;listing.
                  </Text>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </GreenInfoBox>
  );
}
