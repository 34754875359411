import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import styles from './DropDownItem.module.css';

const cx = classNames.bind(styles);

export default class DropDownItem extends React.PureComponent {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    allowDeselection: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { isActive, isSelected, allowDeselection, children, ...other } = this.props;
    let liChildren = children;

    if (allowDeselection && isSelected) {
      liChildren = (
        <>
          <div>{children}</div>
          <GeneralIcon icon={GENERAL_ICONS.CHECKMARK} color={COLORS.GREEN} scale="0-75" />
        </>
      );
    }

    return (
      <li {...other} className={cx('li', { isActive, isSelected })}>
        {liChildren}
      </li>
    );
  }
}
