import { useEffect } from 'react';
import config from 'config/config';

export function GoogleAnalytics() {
  const { gaId, ga4Id } = config;

  useEffect(() => {
    if (gaId && ga4Id) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      script.async = true;
      document.body.appendChild(script);

      window.gtag('config', gaId);
      window.gtag('config', ga4Id);
    }
  }, []);

  return null;
}
