import { ReactNode } from 'react';
import { Text, ButtonBase } from '@belong/ui';
import { AddressModel, BillInfoModel } from 'api/models';
import classnames from 'classnames/bind';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import PaymentStatusLabel from 'components/Payments/PaymentStatusLabel/PaymentStatusLabel';
import String from 'components/String/String';
import { formatAddress } from 'utils/formatAddress';
import { BillStatusIcon } from '../bill-status-icon/bill-status-icon';
import styles from './bill-item.module.css';

export type BillItemProps = {
  isResident: boolean;
  disableHover: boolean;
  lastUpdateDate: string | ReactNode;
  status: BillInfoModel['status'];
  address: AddressModel | string;
  description: string;
  amount: number;
  remainingAmount: number;
  paidAmount: number;
  onClick: () => void;
  type: BillInfoModel['type'];
  label: string;
  showAddress: boolean;
};

const cx = classnames.bind(styles);

export function BillItem(props: BillItemProps) {
  const {
    // remainingAmount,
    // paidAmount,
    amount,
    isResident,
    type,
    status,
    address,
    lastUpdateDate,
    disableHover,
    description,
    label,
    showAddress,
    onClick,
  } = props;

  function renderMoney() {
    const { displayText, showMinus } = getResident(isResident, type, status);

    // if (
    //   showRemainer({
    //     remainingAmount,
    //     total: amount,
    //     paidAmount,
    //   })
    // ) {
    //   return (
    //     <div className="text-body font-semibold flex flex-col items-end text-right lg:items-baseline">
    //       <div className={cx(displayText)}>
    //         Remaining {showMinus && '-'}
    //         <Money cash={remainingAmount} format={MONEY_FORMAT.BASIC} />
    //       </div>
    //       <div className={`${cx(displayText)} ${cx('small')}`}>
    //         Total {showMinus && '-'}
    //         <Money cash={amount} format={MONEY_FORMAT.BASIC} />
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <div className="text-body font-semibold">
        <div className={cx(displayText)}>
          {showMinus && '-'}
          <Money cash={amount} format={MONEY_FORMAT.DOLLARS_CENTS} />
        </div>
      </div>
    );
  }

  return (
    <ButtonBase
      className={cx('button', 'border-solid border-gray ease-out transition-colors p-sm w-full mb-xs', {
        'hover:border-navy': !disableHover,
        'hover:border-gray': disableHover,
      })}
      disabled={disableHover}
      onClick={onClick}
    >
      <div className="flex justify-between text-left flex-row items-center">
        <div className="overflow-hidden">
          <Text fontWeight="semibold">{description}</Text>
          {showAddress && (
            <Text variant="p1">
              <String string={typeof address === 'string' ? address : formatAddress(address, address.unitNumber)} />
            </Text>
          )}
          {lastUpdateDate && (
            <Text variant="p1" className={status === 'Paid' ? 'text-green' : ''}>
              {label} {lastUpdateDate}
            </Text>
          )}
        </div>
        <div className="w-auto flex items-center">
          <div className=" mr-xs">{renderMoney()}</div>
          <div className="text-right ">
            <BillStatusIcon status={status} />
          </div>
          {/* <div className="block w-full lg:hidden">
            <div className="pt-sm flex justify-between">
              <PaymentStatusLabel status={status} />
              {renderMoney()}
            </div>
          </div> */}
        </div>
      </div>
    </ButtonBase>
  );
}

const PAYMENT_TEXT_COLORS = {
  GREEN: 'text-green',
  DEFAULT: 'text-navy',
  DARK_GRAY: 'text-darkgray',
};

// const showRemainer = ({ remainingAmount, total, paidAmount }) => {
//   if (!remainingAmount) {
//     return false;
//   }
//   if (remainingAmount === total) {
//     return false;
//   }

//   if (paidAmount === total) {
//     return false;
//   }

//   return true;
// };

const getResident = (resident: boolean, billType: BillInfoModel['type'], billStatus: BillInfoModel['status']) => {
  if (billStatus === 'Refunded') {
    return {
      displayText: PAYMENT_TEXT_COLORS.DARK_GRAY,
    };
  }

  if (resident) {
    if (billType === 'Payout') {
      return {
        displayText: PAYMENT_TEXT_COLORS.DEFAULT,
        showMinus: true,
      };
    }
    return {
      displayText: PAYMENT_TEXT_COLORS.DEFAULT,
    };
  }

  if (billType === 'Payout') {
    return {
      displayText: PAYMENT_TEXT_COLORS.DEFAULT,
    };
  }

  return {
    displayText: PAYMENT_TEXT_COLORS.DEFAULT,
    showMinus: true,
  };
};
