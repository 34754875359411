import { Fragment } from 'react';
import { UTILITIES_BUTTONS } from 'accounts/constants/home-utilities';
import { HOME_UTILITIES_STRINGS } from 'accounts/constants/strings/home-utilities.strings';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
// Final form imports
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCurrency, unmaskNumber } from 'components/Input/masks';
import { SelectorFinalFormAdapter, SELECTOR_TYPES, GRID_SIZE } from 'components/Selector/Selector';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormCondition, { FIELD_CONDITION_COMPARISON_TYPES } from 'formcomponents/FormCondition/FormCondition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import {
  UtilityPaymentFrequency,
  BillingHoaTypes,
  UtilityResponsibility,
  SupportedUtilities,
  BillingManagedBy,
  Weekdays,
  GarbageDayFrequency,
} from 'models/enums';
import PropTypes from 'prop-types';
import { required } from 'utils/validation';

export function UtilityBasicForm({ name, hasHoa, form, bottomLeftNode, CustomBottomBar, ctaProps }) {
  function getBillingManagedByButtons() {
    const buttons = [
      {
        label: 'Provider',
        key: BillingManagedBy.Provider,
      },
      {
        label: 'Me',
        key: BillingManagedBy.Homeowner,
      },
    ];

    if (hasHoa) {
      buttons.push({
        label: 'My HOA',
        key: BillingManagedBy.Hoa,
      });
    }

    return buttons;
  }

  function getButtons() {
    const buttons = [];
    const featureUtilities = [SupportedUtilities.Storage, SupportedUtilities.Parking, SupportedUtilities.PoolServicing];

    if (!featureUtilities.includes(name)) {
      buttons.push(UTILITIES_BUTTONS.RESIDENT);
    }

    buttons.push(UTILITIES_BUTTONS.RENT);

    if (hasHoa && [SupportedUtilities.Storage].includes(name)) {
      buttons.push(UTILITIES_BUTTONS.PURCHASE);
    }

    if (hasHoa && ![SupportedUtilities.Storage].includes(name)) {
      buttons.push(UTILITIES_BUTTONS.HOA);
    }

    if (!hasHoa && name === SupportedUtilities.Storage) {
      buttons.push(UTILITIES_BUTTONS.FEE);
    }

    return buttons;
  }

  function isValid() {
    const { errors } = form.getState();

    return !errors[name];
  }

  return (
    <Fragment>
      <FormLayout.Section>
        <Field
          grid
          gridSize={GRID_SIZE.MEDIUM}
          name={`${name}.responsibility`}
          component={SelectorFinalFormAdapter}
          type={SELECTOR_TYPES.SMALLTEXTBUTTON}
          validate={required}
          onChangeCustom={(value) => {
            if (value === UtilityResponsibility.ProvidedByHoa) {
              form.change(`${name}.billingHoaType`, BillingHoaTypes.IncludedInHoa);
            }
            if (value === UtilityResponsibility.ResidentsResponsibility) {
              form.change(`${name}.utilityBillingManagedBy`, BillingManagedBy.Provider);
            }
          }}
          buttons={getButtons()}
        />
        <FormCondition
          conditions={[
            [
              {
                fieldName: `${name}.responsibility`,
                compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
                fieldValue: UtilityResponsibility.ProvidedByHoa,
              },
            ],
          ]}
          formValues={form.getState().values}
          name={`${name}.billingHoaType`}
        >
          <Field
            name={`${name}.billingHoaType`}
            component={SelectorFinalFormAdapter}
            type={SELECTOR_TYPES.SMALLTEXTBUTTON}
            validate={required}
            buttons={[
              {
                label: HOME_UTILITIES_STRINGS.billingHoaType.included,
                key: BillingHoaTypes.IncludedInHoa,
              },
              {
                label: HOME_UTILITIES_STRINGS.billingHoaType.separately,
                key: BillingHoaTypes.BilledSeparately,
              },
            ]}
          />
        </FormCondition>
        <Row>
          {name !== SupportedUtilities.Storage && (
            <Col md={4}>
              <Field
                name={`${name}.provider`}
                component={InputFinalFormAdapter}
                placeholder={HOME_UTILITIES_STRINGS.providerPlaceholder}
              />
            </Col>
          )}
          <FormCondition
            conditions={[
              [
                {
                  fieldName: `${name}.responsibility`,
                  compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
                  fieldValue: UtilityResponsibility.AdditionalRequiredFee,
                },
              ],
              [
                {
                  fieldName: `${name}.responsibility`,
                  compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
                  fieldValue: UtilityResponsibility.AvailableForPurchase,
                },
              ],
            ]}
            formValues={form.getState().values}
          >
            <Col md={4}>
              <Field
                name={`${name}.fee`}
                component={InputFinalFormAdapter}
                placeholder={HOME_UTILITIES_STRINGS['fee.placeholder']}
                mask={maskCurrency}
                unmask={unmaskNumber}
              />
            </Col>
            <Col md={4}>
              <Field
                name={`${name}.billingCycle`}
                component={DropdownFinalFormAdapter}
                placeholder={HOME_UTILITIES_STRINGS['frequency.placeholder']}
                items={[
                  {
                    key: UtilityPaymentFrequency.Monthly,
                    value: HOME_UTILITIES_STRINGS.billingCycle.monthly,
                  },
                  {
                    key: UtilityPaymentFrequency.OneTime,
                    value: HOME_UTILITIES_STRINGS.billingCycle.oneTime,
                  },
                  {
                    key: UtilityPaymentFrequency.Quarterly,
                    value: HOME_UTILITIES_STRINGS.billingCycle.quarterly,
                  },
                  {
                    key: UtilityPaymentFrequency.Annually,
                    value: HOME_UTILITIES_STRINGS.billingCycle.annually,
                  },
                ]}
              />
            </Col>
          </FormCondition>
        </Row>
      </FormLayout.Section>
      <FormCondition
        conditions={[
          [
            {
              fieldName: `${name}.responsibility`,
              compare: FIELD_CONDITION_COMPARISON_TYPES.IN,
              fieldValue: [
                UtilityResponsibility.ResidentsResponsibility,
                UtilityResponsibility.AvailableForPurchase,
                UtilityResponsibility.ProvidedByHoa,
                UtilityResponsibility.AdditionalRequiredFee,
              ],
            },
          ],
        ]}
        formValues={form.getState().values}
        name={`${name}.utilityBillingManagedBy`}
      >
        <FormLayout.Section sectionTitle={HOME_UTILITIES_STRINGS.billingManagedBy}>
          <Field
            grid
            gridSize={GRID_SIZE.SMALL}
            name={`${name}.utilityBillingManagedBy`}
            component={SelectorFinalFormAdapter}
            type={SELECTOR_TYPES.SMALLTEXTBUTTON}
            validate={required}
            buttons={getBillingManagedByButtons()}
          />
        </FormLayout.Section>
      </FormCondition>
      {name === SupportedUtilities.Trash && (
        <>
          <FormLayout.Section sectionTitle={HOME_UTILITIES_STRINGS.trashTitle}>
            <Row>
              <Col md={4}>
                <Field
                  name={`${name}.trashFrequency`}
                  component={DropdownFinalFormAdapter}
                  placeholder={HOME_UTILITIES_STRINGS['frequency.placeholder']}
                  items={[
                    {
                      key: GarbageDayFrequency.Weekly,
                      value: GarbageDayFrequency.Weekly,
                    },
                    {
                      key: GarbageDayFrequency.Biweekly,
                      // Last minute change, backend name enum name was already Biweekly.
                      value: HOME_UTILITIES_STRINGS.every2Weeks,
                    },
                    {
                      key: GarbageDayFrequency.Monthly,
                      value: GarbageDayFrequency.Monthly,
                    },
                  ]}
                  disabled={!!form.getState().values?.[SupportedUtilities.Trash]?.trashPickupDetailsUnknown}
                />
              </Col>
              <Col md={4}>
                <Field
                  name={`${name}.trashPickupDays`}
                  component={DropdownFinalFormAdapter}
                  placeholder={HOME_UTILITIES_STRINGS['trash_pickup_days.placeholder']}
                  items={Object.values(Weekdays).map((day) => ({
                    key: day,
                    value: day,
                  }))}
                  disabled={!!form.getState().values?.[SupportedUtilities.Trash]?.trashPickupDetailsUnknown}
                />
              </Col>
              <Col md={4}>
                <Field
                  name={`${name}.trashPickupDetailsUnknown`}
                  onClickCustom={(value) => {
                    if (value) {
                      form.change(`${name}.trashFrequency`, null);
                      form.change(`${name}.trashPickupDays`, null);
                    }
                  }}
                  component={CheckboxFinalFormAdapter}
                  marginOnDesktop
                  label={HOME_UTILITIES_STRINGS['trash_pickup_details_unknown.placeholder']}
                />
              </Col>
            </Row>
          </FormLayout.Section>
          <FormLayout.Section sectionTitle={HOME_UTILITIES_STRINGS.trashLocationTitle}>
            <Row>
              <Col xs={12}>
                <Field
                  name={`${name}.trashLocation`}
                  placeholder={HOME_UTILITIES_STRINGS['trash_location.placeholder']}
                  component={InputFinalFormAdapter}
                  textarea
                />
              </Col>
            </Row>
          </FormLayout.Section>
        </>
      )}

      {name === SupportedUtilities.Landscaping && (
        <FormLayout.Section sectionTitle={HOME_UTILITIES_STRINGS.landscapingTitle}>
          <Row>
            <Col md={4}>
              <Field
                name={`${name}.landscapingFrequency`}
                component={DropdownFinalFormAdapter}
                placeholder={HOME_UTILITIES_STRINGS['frequency.placeholder']}
                items={[
                  {
                    key: GarbageDayFrequency.Weekly,
                    value: GarbageDayFrequency.Weekly,
                  },
                  {
                    key: GarbageDayFrequency.Biweekly,
                    // Last minute change, backend name enum name was already Biweekly.
                    value: HOME_UTILITIES_STRINGS.every2Weeks,
                  },
                  {
                    key: GarbageDayFrequency.Monthly,
                    value: GarbageDayFrequency.Monthly,
                  },
                ]}
                disabled={!!form.getState().values?.[SupportedUtilities.Landscaping]?.landscapingDetailsUnknown}
              />
            </Col>
            <Col md={4}>
              <Field
                name={`${name}.landscapingPickupDays`}
                component={DropdownFinalFormAdapter}
                placeholder={HOME_UTILITIES_STRINGS['landscaping_pickup_days.placeholder']}
                items={Object.values(Weekdays).map((day) => ({
                  key: day,
                  value: day,
                }))}
                disabled={!!form.getState().values?.[SupportedUtilities.Landscaping]?.landscapingDetailsUnknown}
              />
            </Col>
            <Col md={4}>
              <Field
                name={`${name}.landscapingDetailsUnknown`}
                onClickCustom={(value) => {
                  if (value) {
                    form.change(`${name}.landscapingFrequency`, null);
                    form.change(`${name}.landscapingPickupDays`, null);
                  }
                }}
                component={CheckboxFinalFormAdapter}
                marginOnDesktop
                label={HOME_UTILITIES_STRINGS['landscaping_details_unknown.placeholder']}
              />
            </Col>
          </Row>
        </FormLayout.Section>
      )}
      <CustomBottomBar fixedBottom leftSideNode={bottomLeftNode} ctaProps={{ ...ctaProps, disabled: !isValid() }} />
    </Fragment>
  );
}

UtilityBasicForm.propTypes = {
  name: PropTypes.string.isRequired,
  hasHoa: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  bottomLeftNode: PropTypes.node.isRequired,
  CustomBottomBar: PropTypes.node.isRequired,
  ctaProps: PropTypes.object.isRequired,
};
