import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { Grid, Row, Col } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import styles from './DisplayPublishedEverywhere.module.css';

const cx = classNames.bind(styles);

const DisplayPublishedEverywhere = ({ publications }) => {
  const links = publications?.externalPublishing?.[0]?.externalListingDetailModels.reduce((linkAccum, currentLink) => {
    if (currentLink.error) {
      return linkAccum;
    }

    return {
      ...linkAccum,
      [currentLink.destination.toLowerCase()]: currentLink.url,
    };
  }, {});

  return (
    <Grid fluid className={cx('published-everywhere')}>
      <Row>
        <Col sm={4} xs={6}>
          <LinkBase target="_blank" to={links?.zillow}>
            <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.ZILLOW} />
          </LinkBase>
        </Col>
        <Col sm={4} xs={6}>
          <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.ZUMPER} />
        </Col>
        <Col sm={4} xs={6}>
          <LinkBase target="_blank" to={links?.trulia}>
            <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.TRULIA} />
          </LinkBase>
        </Col>
        <Col sm={4} xs={6}>
          <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.FACEBOOK_MARKETPLACE} />
        </Col>
        <Col sm={4} xs={6}>
          <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.APARTMENTS_DOT_COM} />
        </Col>
        <Col sm={4} xs={6}>
          <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.MLS_LISTINGS} />
        </Col>
        <Col sm={4} xs={6}>
          <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.CRAIGSLIST} />
        </Col>
        <Col sm={4} xs={6}>
          <LinkBase target="_blank" to={links?.hotpads}>
            <GeneralIcon className={cx('listing-icon')} icon={GENERAL_ICONS.HOTPADS} />
          </LinkBase>
        </Col>
      </Row>
    </Grid>
  );
};

DisplayPublishedEverywhere.propTypes = {
  publications: PropTypes.array,
};

export default DisplayPublishedEverywhere;
