import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './DownshiftMenu.module.css';

const cx = classNames.bind(styles);

class DownshiftMenuClass extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    maxHeightSmallMenu: PropTypes.bool,
    innerRef: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    maxHeightSmallMenu: false,
  };

  render() {
    const { isOpen, maxHeightSmallMenu, innerRef, ...other } = this.props;
    return <ul {...other} ref={innerRef} className={cx('menu', { isOpen }, { maxHeightSmallMenu })} />;
  }
}

const DownshiftMenu = React.forwardRef((props, ref) => <DownshiftMenuClass innerRef={ref} {...props} />);

export default DownshiftMenu;
