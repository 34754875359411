import { UtilityResponsibility } from 'models/enums';
import { HOME_UTILITIES_STRINGS } from './strings/home-utilities.strings';

export const UTILITIES_BUTTONS = {
  RESIDENT: {
    label: HOME_UTILITIES_STRINGS.responsibility.resident,
    key: UtilityResponsibility.ResidentsResponsibility,
  },
  RENT: {
    label: HOME_UTILITIES_STRINGS.responsibility.rent,
    key: UtilityResponsibility.IncludedInRent,
  },
  PURCHASE: {
    label: HOME_UTILITIES_STRINGS.responsibility.purchase,
    key: UtilityResponsibility.AvailableForPurchase,
  },
  HOA: {
    label: HOME_UTILITIES_STRINGS.responsibility.hoa,
    key: UtilityResponsibility.ProvidedByHoa,
  },
  FEE: {
    label: HOME_UTILITIES_STRINGS.responsibility.fee,
    key: UtilityResponsibility.AdditionalRequiredFee,
  },
};
