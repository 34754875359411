import React from 'react';
import { Button, Image, Text as TextBase } from '@belong/ui';
import clsx from 'clsx';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';

type IdentityMethodsProps = {
  identified: boolean;
};

const Text = TextBase as any;

const strings = RESIDENT_APPLICATION_STRINGS.id_methods;
export function IdentityMethods({ identified = false }: IdentityMethodsProps) {
  return (
    <div className="relative w-full">
      {identified && (
        <div className="absolute top-0 left-0 z-10 w-full h-full flex justify-center items-center">
          <Button onClick={() => {}} className="cursor-none rotate-[-9deg]">
            {strings.completed}
          </Button>
        </div>
      )}
      <div
        className={clsx(
          'flex flex-col border border-solid bg-green-light-bg border-green rounded p-sm mt-xl',
          identified && 'opacity-50'
        )}
      >
        <Text fontWeight="semibold">{strings.title}</Text>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-xs mt-lg justify-between">
          <div className="flex items-center">
            <div style={{ width: 50 }} className="h-xl mr-2sm">
              <Image alt="passport" src="/residents/id_passport.svg" />
            </div>
            <Text fontWeight="semibold" variant="p1">
              {strings.passport}
            </Text>
          </div>
          <div className="flex items-center">
            <div style={{ width: 50 }} className="h-xl mr-2sm">
              <Image alt="passport" src="/residents/id_greencard.svg" />
            </div>
            <Text fontWeight="semibold" variant="p1">
              {strings.permanent}
            </Text>
          </div>
          <div className="flex items-center">
            <div style={{ width: 50 }} className="h-xl mr-2sm">
              <Image alt="passport" src="/residents/id_driver.svg" />
            </div>
            <Text fontWeight="semibold" variant="p1">
              {strings.license}
            </Text>
          </div>
          <div className="flex items-center">
            <div style={{ width: 50 }} className="h-xl mr-2sm">
              <Image alt="passport" src="/residents/id_state.svg" />
            </div>
            <Text fontWeight="semibold" variant="p1">
              {strings.state}
            </Text>
          </div>
          <div className="flex items-center">
            <div style={{ width: 50 }} className="h-xl mr-2sm">
              <Image alt="passport" src="/residents/id_visa.svg" />
            </div>
            <Text fontWeight="semibold" variant="p1">
              {strings.visa}
            </Text>
          </div>
          <div className="flex items-center">
            <div style={{ width: 50 }} className="h-xl mr-2sm">
              <Image alt="passport" src="/residents/id_ead.svg" />
            </div>
            <Text fontWeight="semibold" variant="p1">
              {strings.ead}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
