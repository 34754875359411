export default class PropertyBasicInfo {
  constructor(data = {}) {
    this.hasElevator = data.hasElevator;
    this.propertyId = data.propertyId;
    this.id = data.id;
    this.propertyType = data.propertyType;
    this.hasHoa = data.hasHoa;
    this.inCoverageArea = data.inCoverageArea;
    this.isEntirePropertyOwnedBySingleOwner = data.isEntirePropertyOwnedBySingleOwner;
    this.bannerImageUrl = data.bannerImageUrl;
    this.numberOfFloors = data.numberOfFloors;
    this.numberOfUnits = data.numberOfUnits;
    this.subType = data.subType;
    this.isInspectionPassed = data.isInspectionPassed;
    this.coverageRegionId = data.coverageRegionId;
  }
}
