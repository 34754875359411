import { createSelector } from 'reselect';
import PaymentMethods from '../../../models/common/PaymentMethods';
import UserBasicInfo from '../../../models/common/UserBasicInfo';
import FullUser from '../../../models/composed/FullUser';
import { USER_REDUCER } from './reducer';

export const _selectUser = (state) => state[USER_REDUCER].user;
export const selectUserId = (state) => _selectUser(state)?.userId || _selectUser(state)?.uniqueId;
export const selectIsUserLoggedIn = (state) => !!selectUserId(state);

export const selectDwollaToken = (state) => _selectUser(state).dwollaToken;

export const selectOtherUserById = (state, userId) => state[USER_REDUCER].users?.[userId];

const _selectPaymentMethods = (state) => state[USER_REDUCER].paymentMethods;

export const selectUser = createSelector(_selectUser, (user) => (user ? new UserBasicInfo(user) : null));

export const selectPaymentMethods = createSelector(_selectPaymentMethods, (paymentMethods) =>
  paymentMethods ? new PaymentMethods(paymentMethods).paymentMethods : []
);

export const selectPaymentMethodsFullObject = createSelector(_selectPaymentMethods, (paymentMethods) =>
  paymentMethods ? new PaymentMethods(paymentMethods) : null
);

export const selectFullUser = createSelector(
  selectUser,
  selectPaymentMethodsFullObject,
  (user, paymentMethods) =>
    new FullUser({
      user,
      paymentMethods,
    })
);

export const selectIdentityVerificationInfo = (state) => {
  const { identityVerificationInfo } = state[USER_REDUCER];
  return identityVerificationInfo;
};

export const selectIfUserFullDetailsApiWasCalled = (state) => state[USER_REDUCER].userDetailsCalled;

export const selectUserClaims = (state) => state[USER_REDUCER].claims;

export const selectUserToDos = (state) => state[USER_REDUCER].todos;

export const selectUserReports = (state) => state[USER_REDUCER].reports;

export const selectUserFlows = (state) => state[USER_REDUCER].flows;

export const selectUserDevices = (state) => state[USER_REDUCER].devices;

export const selectUserAutoSplit = (state) => state[USER_REDUCER].autoSplitEnabled;

export const selectUserPaymentMethodConfigurations = (state) => state[USER_REDUCER].paymentMethodConfigurations;
