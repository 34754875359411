import { format, isToday, isTomorrow } from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { legacyParse, convertTokens } from 'forkedlibraries/date-fns-upgrade';

const { format: formatToTimeZone, utcToZonedTime } = dateFnsTz;

// https://date-fns.org/v1.9.0/docs/format
export const DATE_FORMATS = {
  LONG: 'MMMM Do dddd, YYYY h:mm A',
  LONG_WITHOUT_MINUTES: 'MMMM Do dddd, YYYY h A',
  STRIPPED_ISO: 'YYYY-MM-DD',
  DEFAULT_DATE_TIME: 'MMM Do dddd, h:mm A',
  DEFAULT_DATE_TIME_WITHOUT_MINUTES: 'MMM Do dddd, h A',
  MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE: 'ddd, MMM D, YYYY [at] h:mm A z',
  MAINTENANCE_DEFAULT_DATE: 'ddd, MMM D, YYYY',
  MAINTENANCE_DEFAULT_TIME: '[at] h:mm A z',
  MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE_WITHOUT_MINUTES: 'ddd, MMM D, YYYY [at] h A z',
  STANDARD: 'MM/DD/YYYY',
  STANDARD_SHORT: 'MM/DD/YY',
  STANDARD_NO_YEAR: 'MM/DD',
  DOTTEDSHORT: 'MM D, YYYY',
  DOTTEDMEDIUM: 'MMM D, YYYY',
  DOTTED_MEDIUM: 'MMM D, YYYY',
  DOTTEDLONG: 'MMM D, YYYY h:mm A',
  DOTTEDLONG_WITHOUT_MINUTES: 'MMM D, YYYY h A',
  DOTTEDLONG_WITHSPACE: 'MMM D, YYYY h:mm A',
  DOTTEDLONG_WITHSPACE_WITHOUT_MINUTES: 'MMM D, YYYY h A',
  FULL_MONTH_DAY_YEAR: 'MMMM D, YYYY',
  // Refactor previous shorts to this one
  SHORT_MONTH_YEAR: 'MMM YYYY',
  MONTH_YEAR: 'MMMM YYYY',
  SHORT: 'MM/YY',
  TIME: 'h:mm A',
  TIME_WITHOUT_MINUTES: 'h A',
  TIME_TIMEZONE: 'h:mm A z',
  TIME_TIMEZONE_WITHOUT_MINUTES: 'h A z',
  LONG_DATE: 'dddd, MMM Do',
  SHORT_MONTH: 'MMM',
  MONTH: 'MMMM',
  YEAR: 'YYYY',
  DAY: 'dddd',
  DESCRIPTIVE_DAY: 'dddd, MMMM D',
  DAY_OF_MONTH: 'Do',
  DAY_MONTH: 'MMM D',
  DAY_MONTH_YEAR: 'MMM D, YYYY',
  DAY_MONTH_YEAR_TIME_TIMEZONE: 'MMM D, YYYY h:mm A z',
  DAY_MONTH_YEAR_TIME: 'MMM D, YYYY h:mm A',
  DAY_MONTH_YEAR_TIME_WITHOUT_MINUTES: 'MMM D, YYYY h A',
  MONTH_DOTTED_DAY_HOUR_MINUTE: 'MMM D, h:mm a',
  MONTH_DOTTED_DAY_HOUR_MINUTE_WITHOUT_MINUTES: 'MMM D, h a',
  MONTH_FULLNAME_DAY_SHORT_YEAR_SEPERATED_WITH_COMMA_SPACE: 'MMMM Do, YYYY',
};

const containsMinutes = [
  DATE_FORMATS.LONG,
  DATE_FORMATS.DEFAULT_DATE_TIME,
  DATE_FORMATS.MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE,
  DATE_FORMATS.DOTTEDLONG,
  DATE_FORMATS.DOTTEDLONG_WITHSPACE,
  DATE_FORMATS.TIME,
  DATE_FORMATS.TIME_TIMEZONE,
  DATE_FORMATS.DAY_MONTH_YEAR_TIME,
  DATE_FORMATS.MONTH_DOTTED_DAY_HOUR_MINUTE,
];

export const formatDate = (dateTime, dateFormat = DATE_FORMATS.DEFAULT_DATE_TIME) => {
  if (!dateTime) {
    return '';
  }

  if (containsMinutes.indexOf(dateFormat) > -1) {
    let dateFormatKey;

    for (const key in DATE_FORMATS) {
      if (DATE_FORMATS[key] === dateFormat) {
        dateFormatKey = key;
      }
    }

    const minutes = format(legacyParse(dateTime), convertTokens('mm'));
    const time =
      minutes === '00'
        ? format(legacyParse(dateTime), convertTokens(DATE_FORMATS[`${dateFormatKey}_WITHOUT_MINUTES`]))
        : format(legacyParse(dateTime), convertTokens(dateFormat));
    return time;
  }

  return format(legacyParse(dateTime), convertTokens(dateFormat));
};

export const formatDateInPST = (dateTime, dateFormat = DATE_FORMATS.DEFAULT_DATE_TIME, withMinutes = false) => {
  if (!dateTime) {
    return '';
  }

  const dateTimeObj = new Date(dateTime);
  const UtcDatetime = dateTimeObj.toISOString();

  // This step is required by the library
  const UtcToPst = utcToZonedTime(UtcDatetime, 'America/Los_Angeles');

  if (containsMinutes.indexOf(dateFormat) > -1 && !withMinutes) {
    let dateFormatKey;

    for (const key in DATE_FORMATS) {
      if (DATE_FORMATS[key] === dateFormat) {
        dateFormatKey = key;
      }
    }

    const minutes = format(UtcToPst, convertTokens('mm'));
    const time =
      minutes === '00'
        ? formatToTimeZone(UtcToPst, convertTokens(DATE_FORMATS[`${dateFormatKey}_WITHOUT_MINUTES`]), {
            timeZone: 'America/Los_Angeles',
          })
        : formatToTimeZone(UtcToPst, convertTokens(dateFormat), { timeZone: 'America/Los_Angeles' });
    return time;
  }

  return formatToTimeZone(UtcToPst, convertTokens(dateFormat), { timeZone: 'America/Los_Angeles' });
};

export const getDateInUTC = (dateString) => {
  const date = new Date(dateString);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
};

export const getDescriptiveDay = (dateString) => {
  const date = new Date(dateString);
  let descriptiveDay = '';

  if (isToday(legacyParse(date))) {
    descriptiveDay += 'Today, ';
  }

  if (isTomorrow(legacyParse(date))) {
    descriptiveDay += 'Tomorrow, ';
  }

  descriptiveDay += formatDate(date, DATE_FORMATS.DESCRIPTIVE_DAY);
  return descriptiveDay;
};
