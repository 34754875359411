import { MaintenanceModel, MaintenancePaymentModel, MaintenanceRecurrenceModel } from 'api/models';
import MaintenanceTasks from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceTasks';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { RequestType } from 'models/enums';
import { getImage } from 'utils/formatMaintenance';

interface MaintenanceTaskComponentProps {
  showMaintenanceModal: (task: MaintenanceModel | MaintenanceRecurrenceModel) => () => void;
  tasks: MaintenanceModel[];
  isConsentPendingList?: boolean;
  isRenter: boolean;
}

const MaintenanceTaskComponent = ({
  showMaintenanceModal,
  tasks = [],
  isConsentPendingList = false,
  isRenter,
}: MaintenanceTaskComponentProps) => {
  return (
    <>
      {tasks.map((task) => {
        const image = getImage(task.requestType);

        let displayEstimatedResponsibleCost: '' | 'Pricing TBD' | MaintenancePaymentModel['estimatedAdjustmentApplied'];
        let displayResponsibleCost: '' | 'Free' | MaintenancePaymentModel['actualAdjustmentApplied'];

        if ('involvedRole' in task && task.involvedRole === 'Resident') {
          const renterResponsibility = task.payments.find((payment) => payment.paidBy === task.involvedRole);

          if (renterResponsibility?.percentage > 0) {
            displayEstimatedResponsibleCost = renterResponsibility?.estimatedAdjustmentApplied || 'Pricing TBD';
            displayResponsibleCost = renterResponsibility.actualAdjustmentApplied;
          } else if (task.actualCost?.totalCost) {
            displayResponsibleCost = 'Free';
          } else {
            displayEstimatedResponsibleCost = '';
            displayResponsibleCost = '';
          }
        }

        if ('involvedRole' in task && task.involvedRole === 'Homeowner') {
          const homeOwnerResponsibility = task.payments.find((payment) => payment.paidBy === task.involvedRole);
          if (task.requestType === RequestType.Group) {
            displayEstimatedResponsibleCost = homeOwnerResponsibility?.estimatedNetTotal || 'Pricing TBD';
            if (task.requestTypeDetails?.hasPublishedInvoices) {
              displayResponsibleCost = homeOwnerResponsibility?.actualNetTotal;
            }
          } else {
            if (homeOwnerResponsibility?.percentage > 0) {
              displayEstimatedResponsibleCost = homeOwnerResponsibility?.estimatedAdjustmentApplied || 'Pricing TBD';
              displayResponsibleCost = homeOwnerResponsibility.actualAdjustmentApplied;
            } else if (task.actualCost?.totalCost) {
              displayResponsibleCost = 'Free';
            } else {
              displayEstimatedResponsibleCost = '';
              displayResponsibleCost = '';
            }
          }
        }

        return (
          <ButtonBase onClick={showMaintenanceModal(task)} className="w-full mb-xs last-of-type:mb-0" key={task.id}>
            <MaintenanceTasks
              maintenanceDate={task.inspection?.completedOn || task.createdOn}
              maintenanceTask={task.summary}
              maintenanceTaskImage={image}
              estimatedCost={displayEstimatedResponsibleCost}
              actualCost={displayResponsibleCost}
              priceRange={'priceRange' in task ? task.priceRange : undefined}
              numberOfOptions={'numberOfOptions' in task ? task.numberOfOptions : undefined}
              requestCategory={'requestCategory' in task ? task.requestCategory : undefined}
              requestType={'requestType' in task ? task.requestType : undefined}
              maintenanceTaskStatus={'status' in task ? task?.status : undefined}
              isConsentPendingList={isConsentPendingList}
              isRenter={isRenter}
              task={task}
            />
          </ButtonBase>
        );
      })}
    </>
  );
};

export default MaintenanceTaskComponent;
