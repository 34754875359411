import { useState, useEffect } from 'react';
import scriptLoader from 'react-async-script-loader';
import { useDispatch } from 'react-redux';
import { COOKIES_CONFIG, GOOGLE_IDENTITY_SERVICES_EXPERIMENT } from '@belong/common';
import * as FullStory from '@fullstory/browser';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import String from 'components/String/String';
import config from 'config/config';
import { useModal } from 'hooks/useModal';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { LoginProvider } from 'models/enums/index';
import { parseCookies, destroyCookie } from 'nookies';
import PropTypes from 'prop-types';
import { BASE_PATHS, PATHS } from 'routes/paths';
import {
  fetchUser,
  forgotPassword,
  login,
  loginExternal,
  register,
  resetPassword,
  setPassword,
  updateUserPhone,
  logOut,
} from 'store/redux/user/actions';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import AgreeToTermsFooter from './AgreeToTermsFooter/AgreeToTermsFooter';
import EnterEmailToSave from './EnterEmailToSave/EnterEmailToSave';
import EnterUserToSave from './EnterUserToSave/EnterUserToSave';
import ExternalLoginEnterContact from './ExternalLoginEnterContact/ExternalLoginEnterContact';
import ForgotPasswordEmailSentConfirmation from './ForgotPasswordEmailSentConfirmation/ForgotPasswordEmailSentConfirmation';
import ForgotPasswordEnterEmail from './ForgotPasswordEnterEmail/ForgotPasswordEnterEmail';
import ForgotPasswordPasswordUpdateConfirmation from './ForgotPasswordPasswordUpdateConfirmation/ForgotPasswordPasswordUpdateConfirmation';
import ForgotPasswordResetPassword from './ForgotPasswordResetPassword/ForgotPasswordResetPassword';
import styles from './LoginModal.module.css';
import SignInForm from './SignInForm/SignInForm';
import SignUpForm from './SignUpForm/SignUpForm';
import { SCREENS } from './login-modal.consts';

const cx = classNames.bind(styles);

const PHONE_IN_USE_ERROR_FRAGMENT = 'is already related to a another user account';

const { googleRecaptchaSitekey } = config;

const LOGIN_ERROR_CODES = {
  TOKEN_EMAIL_MISMATCH: 'TOKEN_EMAIL_MISMATCH',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
};

const { REFERRAL_USER, REFERRAL_ID } = COOKIES_CONFIG;

function LoginModal({
  show,
  onHide,
  currentScreen: currentScreenFromProps,
  resetToken,
  email,
  screenProps,
  onSucessfulLogin,
  afterSucessfulLogin,
  redirectToHomeOnHide,
  history,
  closable,
  closeButton,
  isNewUser: isNewUserProp,
  customTitle,
  user,
  isScriptLoadSucceed,
}) {
  const isNewUser = isNewUserProp === true || isNewUserProp === 'true';
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [currentScreen, setCurrentScreen] = useState(currentScreenFromProps);
  const [showSpinner, startSpinner, stopSpinner] = useModal();

  useEffect(() => {
    if (!currentScreen && currentScreenFromProps) {
      setCurrentScreen(currentScreenFromProps);
    }

    if (!currentScreen && !currentScreenFromProps) {
      const { HAS_ACCOUNT } = COOKIES_CONFIG;
      const cookies = parseCookies();
      const hasAccount = cookies[HAS_ACCOUNT.name];
      if (hasAccount) {
        return setCurrentScreen(SCREENS.LOGIN_SCREEN);
      }
    }
  }, [currentScreenFromProps, currentScreen]);

  function setScreen(screen) {
    setCurrentScreen(screen);
    setError('');
  }

  const showRegisterScreen = () => setScreen(SCREENS.REGISTER_SCREEN);
  const showForgotPasswordEnterEmailScreen = () => setScreen(SCREENS.FORGOT_PASSWORD_ENTER_EMAIL_SCREEN);
  const showLoginScreen = () => setScreen(SCREENS.LOGIN_SCREEN);

  function handleHide() {
    onHide();
    setCurrentScreen(null);
    setError('');
    stopSpinner();
  }

  function handleClose() {
    handleHide();

    if (redirectToHomeOnHide) {
      history.push(PATHS.HOME_PAGE);
    }
  }

  const handleClickResetYourPasswordAgainButton = () => {
    showForgotPasswordEnterEmailScreen();
    setError('');
  };

  const handleForgotPasswordEnterEmailSubmit = async (userEmail) => {
    try {
      await dispatch(forgotPassword(userEmail));
    } catch (err) {
      console.error(err);
    }
    setCurrentScreen(SCREENS.FORGOT_PASSWORD_EMAIL_SENT_CONFIRMATION_SCREEN);
  };

  const handleForgotPasswordEmailSentConfirmationSubmit = () => {
    setCurrentScreen(SCREENS.LOGIN_SCREEN);
    handleClose();
  };

  const handleForgotPasswordPasswordUpdateConfirmationSubmit = async () => {
    handleHide();
    // A hack to prevent the screen on getting stuck on the reset password modal
    setTimeout(() => {
      history.push(BASE_PATHS.ACCOUNTS);
    }, 1);
  };

  const handleLoginSubmit = async (values) => {
    if (!isScriptLoadSucceed) {
      setError(FEEDBACK_STRINGS.general_error.title);
    }

    startSpinner();
    setError('');

    try {
      const recaptchaToken = await window.grecaptcha.execute(googleRecaptchaSitekey, { action: 'submit' });
      await dispatch(
        login({
          ...values,
          recaptchaToken,
        })
      );
      const userObject = await dispatch(fetchUser());

      if (onSucessfulLogin) {
        return await onSucessfulLogin(userObject);
      }

      if (afterSucessfulLogin) {
        afterSucessfulLogin();
      }

      onHide();
    } catch (_error) {
      // Send custom login error when throwing from onSucessfulLogin
      setError(_error.loginError || FEEDBACK_STRINGS.login);

      try {
        // When throwing from afterSucessfulLogin, we want to log out the user.
        destroyCookie(null, REFERRAL_USER.name, { path: '/' });
        destroyCookie(null, REFERRAL_ID.name, { path: '/' });

        await dispatch(logOut());
      } catch (e) {
        console.error(e);
      }
    } finally {
      /**
       * Doing this to be able to hide the modal when the user logs in successfully.
       * Note that we cannot remove the return statement when calling onSucessfulLogin since it
       * prevents showing a flash of the login modal after the homeowner sets a password in
       * the invitation flow.
       */
      if (typeof onSucessfulLogin !== 'undefined') {
        onHide();
      }

      stopSpinner();
    }
  };

  const handleFacebookLoginSubmit = async (facebookResponse) => {
    if (!facebookResponse.accessToken) {
      return;
    }

    const facebookLoginRequest = {
      loginProvider: LoginProvider.Facebook,
      accessToken: facebookResponse.accessToken,
      email: screenProps.emailAddress,
    };

    startSpinner();

    try {
      await dispatch(loginExternal(facebookLoginRequest));
      const userObject = await dispatch(fetchUser());

      if (!userObject.phone || userObject.phone === '') {
        stopSpinner();
        setCurrentScreen(SCREENS.EXTERNAL_LOGIN_ENTER_CONTACT_SCREEN);
        return;
      }

      if (onSucessfulLogin) {
        await onSucessfulLogin(userObject);
      }

      handleHide();
      if (afterSucessfulLogin) {
        afterSucessfulLogin();
      }
    } catch (_error) {
      if (Array.isArray(_error) && _error[0]?.errorCode === LOGIN_ERROR_CODES.TOKEN_EMAIL_MISMATCH) {
        const errorNode = (
          <>
            <div>
              <String string={FEEDBACK_STRINGS.fb_login_email_mismatch_line_1} />
            </div>
            <div>
              <String string={FEEDBACK_STRINGS.fb_login_email_mismatch_line_2} />
            </div>
          </>
        );
        stopSpinner();
        setError(errorNode);
      } else {
        // Send custom login error when throwing from onSucessfulLogin
        stopSpinner();
        setError(_error.loginError || FEEDBACK_STRINGS.login);

        try {
          // When throwing from afterSucessfulLogin, we want to log out the user.
          await dispatch(logOut());
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleGoogleLoginSuccess = async (googleResponse) => {
    try {
      startSpinner();

      const cookies = parseCookies();
      const experimentsCookie = JSON.parse(cookies[COOKIES_CONFIG.EXPERIMENTS.name] ?? '{}');
      const googleIdentityServicesExperiment = experimentsCookie[GOOGLE_IDENTITY_SERVICES_EXPERIMENT.name] ?? 'control';

      let googleLoginRequest;

      if (googleIdentityServicesExperiment === 'test') {
        const { code } = googleResponse;

        googleLoginRequest = {
          loginProvider: 'Google',
          accessToken: code,
          loginExperiment: 'GoogleAuthFlow',
        };
      } else {
        googleLoginRequest = {
          loginProvider: 'Google',
          accessToken: googleResponse.tokenId,
          email: googleResponse.getBasicProfile().getEmail(),
        };
      }

      await dispatch(loginExternal(googleLoginRequest));

      const userObject = await dispatch(fetchUser());

      if (!userObject.phone || userObject.phone === '') {
        stopSpinner();
        setCurrentScreen(SCREENS.EXTERNAL_LOGIN_ENTER_CONTACT_SCREEN);

        return;
      }

      if (onSucessfulLogin) {
        await onSucessfulLogin(userObject);
      }

      handleHide();

      if (afterSucessfulLogin) {
        afterSucessfulLogin();
      }
    } catch {
      stopSpinner();

      setError(FEEDBACK_STRINGS.generic_something_went_wrong);

      try {
        // When throwing from afterSucessfulLogin, we want to log out the user.
        await dispatch(logOut());
      } catch (e) {
        console.error(e);
      }
    }
  };

  function handleGoogleLoginFailure(googleError) {
    /**
     * When the user closes the popup, we don't want to show an error message.
     */
    if (error?.type === 'popup_closed') {
      return;
    }

    if (googleError === 'INIT_FAILED') {
      setError(FEEDBACK_STRINGS.google_login_third_party_error);
    }

    setError(FEEDBACK_STRINGS.generic_something_went_wrong);
  }

  async function handleAppleCallback(res) {
    FullStory.event('APPLE_LOGIN', {});

    if (res.authorization) {
      const appleLoginRequest = {
        loginProvider: 'Apple',
        accessToken: res.authorization.id_token,
        ...(res.user && {
          email: res.user.email,
          firstName: res.user.name.firstName,
          lastName: res.user.name.lastName,
        }),
      };
      startSpinner();

      try {
        await dispatch(loginExternal(appleLoginRequest));
        const userObject = await dispatch(fetchUser());

        if (!userObject.phone || userObject.phone === '') {
          stopSpinner();
          setCurrentScreen(SCREENS.EXTERNAL_LOGIN_ENTER_CONTACT_SCREEN);
          return;
        }

        if (onSucessfulLogin) {
          await onSucessfulLogin(userObject);
        }

        handleHide();
        if (afterSucessfulLogin) {
          afterSucessfulLogin();
        }
      } catch (_error) {
        console.error(_error);

        // Send custom login error when throwing from onSucessfulLogin
        stopSpinner();
        setError(FEEDBACK_STRINGS.generic_something_went_wrong);

        try {
          // When throwing from afterSucessfulLogin, we want to log out the user.
          await dispatch(logOut());
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      console.error(res);
      // res.error has additional information
      setError(FEEDBACK_STRINGS.generic_something_went_wrong);
    }
  }

  const handleForgotPasswordResetPasswordSubmit = async (resetPasswordObj) => {
    startSpinner();

    if (isNewUser) {
      try {
        await dispatch(setPassword(resetPasswordObj));
        await dispatch(fetchUser());
      } catch (err) {
        console.error(err);
      }
      setCurrentScreen(SCREENS.FORGOT_PASSWORD_PASSWORD_UPDATE_CONFIRMATION_SCREEN);
      stopSpinner();
      return;
    }

    try {
      await dispatch(resetPassword(resetPasswordObj));
      stopSpinner();
      setCurrentScreen(SCREENS.FORGOT_PASSWORD_PASSWORD_UPDATE_CONFIRMATION_SCREEN);
    } catch (_error) {
      if (Array.isArray(_error) && _error[0]?.errorCode === LOGIN_ERROR_CODES.TOKEN_EXPIRED) {
        const errorNode = (
          <div className={cx('reset-password-token-error')}>
            {`${FEEDBACK_STRINGS.reset_password_link_expired_line_1} `}
            <Button
              label={FEEDBACK_STRINGS.reset_password_link_expired_button}
              buttonType={BUTTON_TYPES.NOSTYLE}
              onClick={handleClickResetYourPasswordAgainButton}
              className={cx('reset-password-token-error-button')}
            />
            {` ${FEEDBACK_STRINGS.reset_password_link_expired_line_2}`}
          </div>
        );
        stopSpinner();
        setError(errorNode);
      } else {
        stopSpinner();
        setError(FEEDBACK_STRINGS.general_error.title);
      }
    }
  };

  const handleSubmitExternalLoginEnterContact = async (userPhone) => {
    startSpinner();

    try {
      await dispatch(updateUserPhone(userPhone));
      await dispatch(fetchUser());

      if (onSucessfulLogin) {
        await onSucessfulLogin();
      }

      handleHide();
    } catch (_error) {
      stopSpinner();
      const phoneError = Array.isArray(_error) ? _error[0] : {};
      if (phoneError?.message.includes(PHONE_IN_USE_ERROR_FRAGMENT)) {
        setError(FEEDBACK_STRINGS.phone_already_exists);
      } else {
        setError(FEEDBACK_STRINGS.phone_update_failed);
      }
    }

    if (afterSucessfulLogin) {
      afterSucessfulLogin();
    }
  };

  const handleRegisterSubmit = async (values) => {
    startSpinner();

    try {
      await dispatch(register(values));

      try {
        await handleLoginSubmit(values);
      } catch (err) {
        console.error(err);
      }
    } catch (_error) {
      setError(FEEDBACK_STRINGS.create_account);
    }
    stopSpinner();
  };

  const handleSetPasswordSubmit = async ({ password }) => {
    try {
      await handleRegisterSubmit({ ...user, password });
    } catch (err) {
      console.error(err);
    }
  };

  function getModalConfig() {
    switch (currentScreen) {
      case SCREENS.LOGIN_SCREEN:
        return {
          title: SignInForm.title,
          closeButton: SignInForm.closeButton,
          footer: AgreeToTermsFooter,
          body: (
            <SignInForm
              handleLoginSubmit={handleLoginSubmit}
              showRegisterScreen={showRegisterScreen}
              handleFacebookLoginSubmit={handleFacebookLoginSubmit}
              handleGoogleLoginSuccess={handleGoogleLoginSuccess}
              handleGoogleLoginFailure={handleGoogleLoginFailure}
              handleAppleCallback={handleAppleCallback}
              showForgotPasswordEnterEmailScreen={showForgotPasswordEnterEmailScreen}
              email={email}
            />
          ),
        };
      case SCREENS.FORGOT_PASSWORD_ENTER_EMAIL_SCREEN:
        return {
          title: ForgotPasswordEnterEmail.title,
          description: ForgotPasswordEnterEmail.description,
          closeButton: ForgotPasswordEnterEmail.closeButton,
          footer: AgreeToTermsFooter,
          body: (
            <ForgotPasswordEnterEmail handleForgotPasswordEnterEmailSubmit={handleForgotPasswordEnterEmailSubmit} />
          ),
        };
      case SCREENS.FORGOT_PASSWORD_EMAIL_SENT_CONFIRMATION_SCREEN:
        return {
          title: ForgotPasswordEmailSentConfirmation.title,
          description: ForgotPasswordEmailSentConfirmation.description,
          closeButton: ForgotPasswordEmailSentConfirmation.closeButton,
          body: (
            <ForgotPasswordEmailSentConfirmation
              handleForgotPasswordEmailSentConfirmationSubmit={handleForgotPasswordEmailSentConfirmationSubmit}
            />
          ),
        };
      case SCREENS.SET_PASSWORD_PASSWORDLESS: {
        const { title, description } = ForgotPasswordResetPassword;
        const configProps = { title, description, closeButton: ForgotPasswordResetPassword.closeButton };
        const modalProps = { handleForgotPasswordResetPasswordSubmit, resetToken, email, isNewUser };

        configProps.title = ForgotPasswordResetPassword.newUserTitle;
        configProps.description = ForgotPasswordResetPassword.newUserDescription;
        configProps.footer = AgreeToTermsFooter;
        modalProps.handleForgotPasswordResetPasswordSubmit = handleSetPasswordSubmit;
        modalProps.isNewUser = true;

        return {
          ...configProps,
          body: <ForgotPasswordResetPassword {...modalProps} />,
        };
      }
      case SCREENS.SET_PASSWORD_AFTER_AGREEMENT:
      case SCREENS.FORGOT_PASSWORD_RESET_PASSWORD_SCREEN: {
        const { title, description } = ForgotPasswordResetPassword;
        const configProps = { title, description, closeButton: ForgotPasswordResetPassword.closeButton };
        const modalProps = { handleForgotPasswordResetPasswordSubmit, resetToken, email, isNewUser };

        if (isNewUser) {
          configProps.title = ForgotPasswordResetPassword.newUserTitle;
          configProps.description = ForgotPasswordResetPassword.newUserDescription;
          configProps.footer = AgreeToTermsFooter;
        } else if (
          currentScreen === SCREENS.SET_PASSWORD_AFTER_AGREEMENT ||
          currentScreen === SCREENS.SET_PASSWORD_PASSWORDLESS
        ) {
          configProps.title = ForgotPasswordResetPassword.setPasswordAfterAgreementTitle;
          configProps.description = ForgotPasswordResetPassword.setPasswordAfterAgreementDescription;
          configProps.footer = AgreeToTermsFooter;
          modalProps.handleForgotPasswordResetPasswordSubmit = handleSetPasswordSubmit;
        }

        return {
          ...configProps,
          body: <ForgotPasswordResetPassword {...modalProps} />,
        };
      }
      case SCREENS.FORGOT_PASSWORD_PASSWORD_UPDATE_CONFIRMATION_SCREEN:
        return {
          title: ForgotPasswordPasswordUpdateConfirmation.title,
          closeButton: ForgotPasswordResetPassword.closeButton,
          body: (
            <ForgotPasswordPasswordUpdateConfirmation
              handleForgotPasswordPasswordUpdateConfirmationSubmit={
                handleForgotPasswordPasswordUpdateConfirmationSubmit
              }
            />
          ),
        };
      case SCREENS.ENTER_USER_TO_SAVE_SCREEN:
      case SCREENS.ENTER_EMAIL_TO_SAVE_SCREEN: {
        const EnterToSave = currentScreen === SCREENS.ENTER_EMAIL_TO_SAVE_SCREEN ? EnterEmailToSave : EnterUserToSave;
        return {
          title: EnterEmailToSave.title,
          closeButton: EnterEmailToSave.closeButton,
          footer: AgreeToTermsFooter,
          body: (
            <EnterToSave
              handleEnterEmailToSaveScreenSubmit={handleHide}
              showRegisterScreen={showRegisterScreen}
              {...screenProps}
            />
          ),
        };
      }
      case SCREENS.EXTERNAL_LOGIN_ENTER_CONTACT_SCREEN:
        return {
          title: ExternalLoginEnterContact.title,
          closeButton: true,
          footer: AgreeToTermsFooter,
          body: <ExternalLoginEnterContact onSubmit={handleSubmitExternalLoginEnterContact} />,
        };
      case SCREENS.REGISTER_SCREEN:
      default: {
        return {
          title: SignUpForm.title,
          closeButton: SignUpForm.closeButton,
          body: (
            <SignUpForm
              handleRegisterSubmit={handleRegisterSubmit}
              showLoginScreen={showLoginScreen}
              handleFacebookLoginSubmit={handleFacebookLoginSubmit}
              handleGoogleLoginSuccess={handleGoogleLoginSuccess}
              handleGoogleLoginFailure={handleGoogleLoginFailure}
              handleAppleCallback={handleAppleCallback}
              {...screenProps}
            />
          ),
        };
      }
    }
  }

  const modalPropsAndBody = getModalConfig();

  return (
    <ModalV2
      name={ANALYTICS_MODAL_NAMES.LOGIN}
      screen={currentScreen}
      type={!currentScreen || currentScreen === SCREENS.REGISTER_SCREEN ? MODAL_TYPES.LARGE : MODAL_TYPES.STANDARD}
      show={show}
      onHide={handleClose}
      title={customTitle || modalPropsAndBody.title}
      subTitle={modalPropsAndBody.description || null}
      error={error}
      closeButton={closeButton || modalPropsAndBody.closeButton || null}
      showSpinner={showSpinner}
      closable={closable}
      className="z-20"
    >
      <FormLayout>{modalPropsAndBody.body}</FormLayout>
      {modalPropsAndBody.footer && <div className={cx('footer')}>{modalPropsAndBody.footer}</div>}
    </ModalV2>
  );
}

LoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  currentScreen: PropTypes.string,
  resetToken: PropTypes.string, // Optional property - required for reset password screen
  email: PropTypes.string, // Optional property - required for reset password screen
  screenProps: PropTypes.object, // Optional property - required for enter email to save screen
  onSucessfulLogin: PropTypes.func,
  afterSucessfulLogin: PropTypes.func,
  redirectToHomeOnHide: PropTypes.bool,
  history: PropTypes.object.isRequired,
  closable: PropTypes.bool,
  closeButton: PropTypes.bool,
  isNewUser: PropTypes.any,
  customTitle: PropTypes.string,
  user: PropTypes.object,
  isScriptLoadSucceed: PropTypes.bool.isRequired,
};

LoginModal.defaultProps = {
  currentScreen: null,
  resetToken: '',
  email: '',
  screenProps: {},
  onSucessfulLogin: null,
  afterSucessfulLogin: null,
  redirectToHomeOnHide: false,
  closable: true,
  isNewUser: false,
  closeButton: null,
  user: null,
};

export default scriptLoader([`https://www.google.com/recaptcha/api.js?render=${googleRecaptchaSitekey}`])(LoginModal);
