import { useState } from 'react';
import { connect } from 'react-redux';
import FavoriteHeart from 'animations/FavoriteHeart/FavoriteHeart';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import UserBasicInfo from 'models/common/UserBasicInfo';
import ListingPageUnit from 'models/listingPage/ListingPageUnit';
import PropTypes from 'prop-types';
import { updateFavoriteHome } from 'store/redux/renter-accounts/actions';
import { selectFavoriteHomes } from 'store/redux/renter-accounts/selectors';
import { fetchUserBookedTours } from 'store/redux/user/actions';
import { selectUser } from 'store/redux/user/selectors';

const FavoriteButton = ({
  homeId,
  favoriteHomes,
  updateFavoriteHomeAction,
  fetchUserBookedToursAction,
  user,
  stopPropagation,
  ...rest
}) => {
  const [throttleHandleClick, setThrottleHandleClick] = useState(false);

  const handleClick = async (value) => {
    if (user?.userId) {
      setThrottleHandleClick(true);
    }
    try {
      if (!user?.userId) {
        // only run animation if a user is logged in. https://belonghome.atlassian.net/browse/BLN-3516
        const fetchedHomes = await updateFavoriteHomeAction(homeId, value);
        if (fetchedHomes) {
          setThrottleHandleClick(true);
        }
      } else {
        await updateFavoriteHomeAction(homeId, value);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const hasFavorite = favoriteHomes?.filter((favoriteHome) => {
    return favoriteHome.basicInfo?.unitId === homeId;
  });

  const isFavorite = !!hasFavorite?.length;

  return (
    <ButtonBase
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        e.preventDefault();
        if (!throttleHandleClick) {
          handleClick(!isFavorite);
        }
      }}
    >
      <FavoriteHeart {...rest} value={isFavorite} setThrottleHandleClick={setThrottleHandleClick} />
    </ButtonBase>
  );
};

FavoriteButton.propTypes = {
  homeId: PropTypes.string.isRequired,
  updateFavoriteHomeAction: PropTypes.func.isRequired,
  favoriteHomes: PropTypes.arrayOf(PropTypes.instanceOf(ListingPageUnit)),
  fetchUserBookedToursAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
  stopPropagation: PropTypes.bool,
  user: PropTypes.instanceOf(UserBasicInfo).isRequired,
};

FavoriteButton.defaultProps = {
  favoriteHomes: [],
  stopPropagation: false,
};

const mapStateToProps = (state) => ({
  favoriteHomes: selectFavoriteHomes(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  updateFavoriteHomeAction: updateFavoriteHome,
  fetchUserBookedToursAction: fetchUserBookedTours,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteButton);
