import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ClickTracker, Analytics } from 'analytics';
import { getStringFromJSX } from 'analytics/utils';
import classNames from 'classnames/bind';
import { includes, isObject, isString, snakeCase } from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './LinkBase.module.css';

const cx = classNames.bind(styles);

class LinkBase extends Component {
  static propTypes = {
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }),
    ]),
    a: PropTypes.string, // Deprecated
    children: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    a: '',
    children: '',
    className: '',
    disabled: false,
    to: null,
  };

  isExternal = (to) => isString(to) && includes(to, '://');

  render() {
    const { to, children, a, className: classNameProp, disabled, ...other } = this.props;
    const className = cx('linkBase', { disabled }, classNameProp);

    let renderLink;

    if (to && !this.isExternal(to)) {
      renderLink = (
        <Link to={to} className={className} {...other}>
          {children}
        </Link>
      );
    } else {
      renderLink = (
        <a href={to || a} className={className} {...other}>
          {children}
        </a>
      );
    }

    return renderLink;
  }
}

export default Analytics((props) => {
  const { children, to, a } = props;
  const childrenString = getStringFromJSX(children);
  const path = isObject(to) ? `${to.pathname}${to.search ? `?${to.search}` : ''}}` : to || a;

  return {
    click_action: props.action || 'unknown',
    text: snakeCase(childrenString),
    to: path,
    click_component: 'link',
  };
})(ClickTracker(LinkBase));
