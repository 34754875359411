import { PaymentAgreement } from '../models/enums/index';

export const renderNameToBeFirstInitial = (name) => name.slice(0, 1);

export const fixUserTypeEnums = (name) => {
  if (name === 'HomeOwner') {
    return 'Homeowner';
  }

  return name;
};

export const changeWhoPaysEnums = (name) => {
  if (name === PaymentAgreement.OwnerPays) {
    return 'Owner Pays';
  } else if (name === PaymentAgreement.SplitPayment) {
    return 'Split Payment';
  } else if (name === PaymentAgreement.ResidentPays) {
    return 'Resident Pays';
  }

  return name;
};
