import { OTHER, featuresList, communalAmenitiesFeature, SupportHomeKeys } from 'accounts/constants/home-keys';
import { HOME_KEYS_STRINGS } from 'accounts/constants/strings/home-keys.strings';

export function getKeyFormName(serverName) {
  switch (serverName) {
    case HOME_KEYS_STRINGS['features.houseKey']:
      return SupportHomeKeys.HouseKey;
    case HOME_KEYS_STRINGS['features.frontDoor']:
      return SupportHomeKeys.FrontDoor;
    case HOME_KEYS_STRINGS['features.backDoor']:
      return SupportHomeKeys.BackDoor;
    case HOME_KEYS_STRINGS['features.sideDoor']:
      return SupportHomeKeys.SideDoor;
    case HOME_KEYS_STRINGS['features.lobbyMainEntrance']:
      return SupportHomeKeys.Lobby;
    case HOME_KEYS_STRINGS['features.gate']:
      return SupportHomeKeys.Gate;
    case HOME_KEYS_STRINGS['features.garage']:
      return SupportHomeKeys.Garage;
    case HOME_KEYS_STRINGS['features.mailbox']:
      return SupportHomeKeys.Mailbox;
    case HOME_KEYS_STRINGS['features.mailroom']:
      return SupportHomeKeys.Mailroom;
    case HOME_KEYS_STRINGS['features.storage']:
      return SupportHomeKeys.Storage;
    case HOME_KEYS_STRINGS['features.communalAmenities']:
      return SupportHomeKeys.CommunalAmenities;
    default:
      return OTHER;
  }
}

export function getKeyFeaturesList({ hasHoa }) {
  const newFeaturesList = [...featuresList];

  if (hasHoa) {
    newFeaturesList.splice(newFeaturesList.length - 1, 0, communalAmenitiesFeature);
  }

  return newFeaturesList;
}

export function extractUnit(address: string): string | null {
  const unitRegex = /Unit[^,]+/;
  const match = address.match(unitRegex);

  return match && match[0] ? match[0] : null;
}
