import { MoneyRange, Money } from '@belong/ui';
import { MaintenanceGroupPaymentResourceModel } from 'api/models';
import { sumBy } from 'lodash-es';
import { MaintenanceResponsibility } from 'models/enums';

type Props = {
  groupPayment?: MaintenanceGroupPaymentResourceModel[];
  homeownerPayment?: any;
  priceRange?: any;
};

export function PreMoveoutSubtotalSection({ groupPayment, homeownerPayment, priceRange }: Props) {
  const residentPayment = groupPayment.find((payment) => {
    return payment.paidBy === MaintenanceResponsibility.Resident;
  });
  const belongPayment = groupPayment.find((payment) => {
    return payment.paidBy === MaintenanceResponsibility.Belong;
  });

  const subTotal = sumBy(groupPayment, 'estimatedTotalAmount');

  return (
    <div className="flex flex-col">
      <div className="flex justify-between mb-xs">
        <span>Subtotal:</span>
        {priceRange ? (
          <MoneyRange
            lower={priceRange.subtotalLowerBoundForAllParties}
            upper={priceRange.subtotalUpperBoundForAllParties}
            className="font-semibold"
            format="DOLLARS_NO_CENTS"
          />
        ) : (
          <>
            <Money value={subTotal} format="DOLLARS_NO_CENTS" />
          </>
        )}
      </div>

      {residentPayment?.estimatedTotalAmount && (
        <div className="flex justify-between mb-xs">
          <span>Resident Responsibility:</span>
          <span className="font-semibold text-green">
            {priceRange ? (
              <MoneyRange
                lower={priceRange.totalLowerBoundForResident}
                upper={priceRange.totalUpperBoundForResident}
                className="font-semibold"
                format="DOLLARS_NO_CENTS"
              />
            ) : (
              <>
                -
                <Money
                  value={residentPayment?.estimatedTotalAmount}
                  format="DOLLARS_NO_CENTS"
                  className="font-semibold"
                />
              </>
            )}
          </span>
        </div>
      )}

      {belongPayment?.estimatedTotalAmount && (
        <div className="flex justify-between mb-xs">
          <span>Belong Responsibility:</span>
          -
          <Money
            value={belongPayment?.estimatedTotalAmount}
            className="font-semibold text-green"
            format="DOLLARS_NO_CENTS"
          />
        </div>
      )}

      {homeownerPayment?.estimatedDiscountAmount > 0 && (
        <div className="flex justify-between mb-xs">
          <span className="font-semibold">Savings:</span>
          -
          <Money
            value={homeownerPayment.estimatedDiscountAmount}
            className="font-semibold text-green"
            format="DOLLARS_NO_CENTS"
          />
        </div>
      )}

      <div className="flex justify-between mb-xs">
        <span className="font-semibold">Est. Total:</span>
        <Money value={homeownerPayment.estimatedNetTotal} className="font-semibold" format="DOLLARS_NO_CENTS" />
      </div>

      <div className="flex justify-between">
        <span className="font-semibold">Due Now:</span>
        <Money value={homeownerPayment.groupDueNow} className="font-semibold" format="DOLLARS_NO_CENTS" />
      </div>
    </div>
  );
}
