import { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { MONEY_TYPES } from 'components/Money/moneyTypes';
import Spinner from 'components/Spinner/Spinner';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEmpty } from 'lodash-es';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { fetchApplicantInfo, fetchCosigners, updateCosigners } from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationCosigners,
  selectResidentApplicationId,
  selectResidentApplyForHouseId,
  selectResidentApplicationCosignerMinimumIncome,
} from 'store/redux/resident-application/selectors';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { composeValidators, email, required } from 'utils/validation';
import { selectHomeById } from '../../../../store/redux/homes/selectors';
import { getString } from '../../../../strings';
import RelationshipFieldAdapter, { isOtherRelationShip } from '../fields/RelationshipField/RelationshipField';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './AddCosigner.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.add_cosigner;
const { FormLayoutHeader } = FormLayout;

class AddCosigner extends Component {
  state = {
    awaitingFetchComplete: true,
    formData: {},
  };

  async componentDidMount() {
    const { cosigners, applicationId, fetchCosigners: fetchCosignersAction } = this.props;

    let _cosigners = cosigners;
    try {
      if (isEmpty(_cosigners)) {
        _cosigners = await fetchCosignersAction(applicationId);
      }
    } catch (err) {
      console.error(err);
    }

    this.setupInitialValues(_cosigners);
  }

  setupInitialValues(cosigners) {
    const newState = {
      formData: cosigners?.cosigners?.length
        ? {
            ...cosigners.cosigners[0],
            relationship: {
              relationship_selected: isOtherRelationShip ? 'Other' : cosigners.cosigners[0].relationship,
              relationship_other: isOtherRelationShip ? cosigners.cosigners[0].relationship : '',
            },
          }
        : {},
    };

    this.setState({
      awaitingFetchComplete: false,
      ...newState,
    });
  }

  handleSubmit = async (values) => {
    const {
      history: { push },
      applicationId,
      houseId,
      updateCosigners: updateCosignersAction,
    } = this.props;

    this.setState({
      awaitingFetchComplete: true,
    });

    const data = {
      ...values,
      relationship:
        values.relationship?.relationship_selected === 'Other'
          ? values.relationship?.relationship_other
          : values.relationship?.relationship_selected,
    };

    try {
      await updateCosignersAction(applicationId, data);
    } catch (err) {
      console.error(err);
    }
    push(getResidentApplicationStepPath(STEPS_CONFIG.IDENTITY_VERIFICATION, { houseId, applicationId }));
  };

  handleSkip = () => {
    const {
      history: { push },
      applicationId,
      houseId,
    } = this.props;

    push(getResidentApplicationStepPath(STEPS_CONFIG.IDENTITY_VERIFICATION, { houseId, applicationId }));
  };

  render() {
    const { cosignerMinimumIncome } = this.props;
    const { awaitingFetchComplete, formData } = this.state;

    if (awaitingFetchComplete) {
      return (
        <Row>
          <Col md={12}>
            <div className={cx('spinner')}>
              <Spinner />
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <FormLayout>
        <FormLayoutHeader
          title={RAS.title}
          subtitle={getString(RAS.subTitle, {
            minIncome: numeral(cosignerMinimumIncome).format(MONEY_TYPES.DOLLARS),
          })}
        />
        <Form
          initialValues={formData}
          onSubmit={this.handleSubmit}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: RAS.next_button_text,
                // disabled: formProps.invalid || this.validateSsn(formProps),
              }}
              skip={this.handleSkip}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          getForm={() => (
            <div className={cx('add-cosigner-form-wrapper')}>
              <Row>
                <Col md={6}>
                  <Field
                    name="firstName"
                    validate={required}
                    component={InputFinalFormAdapter}
                    placeholder={RAS.first_name}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name="lastName"
                    validate={required}
                    component={InputFinalFormAdapter}
                    placeholder={RAS.last_name}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Field
                    name="email"
                    validate={composeValidators(required, email)}
                    component={InputFinalFormAdapter}
                    placeholder={RAS.email}
                  />
                </Col>
              </Row>
              <Field name="relationship" component={RelationshipFieldAdapter} hidden />
            </div>
          )}
        />
      </FormLayout>
    );
  }
}

AddCosigner.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  cosigners: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  houseId: PropTypes.string.isRequired,
  fetchCosigners: PropTypes.func.isRequired,
  updateCosigners: PropTypes.func.isRequired,
  cosignerMinimumIncome: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    cosigners: selectResidentApplicationCosigners(state),
    cosignerMinimumIncome: selectResidentApplicationCosignerMinimumIncome(state),
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    home: selectHomeById(state, selectResidentApplyForHouseId(state)),
  }),
  {
    fetchCosigners,
    updateCosigners,
    fetchApplicantInfo,
  }
)(AddCosigner);
