import { PaymentAgreement } from '../models/enums/index';

export const MAINTENACE_STATUS_MODAL_STRINGS = {
  responsibilityonhomeownerside: {
    title: 'Should we do it?',
    homeownerpays: {
      text: 'You got it! We will charge your default payment method.',
    },
    homeownerpaysWithUpfront: {
      text: 'You got it! <b>We will charge ${upfront} now</b> to your default payment method, and the rest deducted from rent upon completion.',
    },
    renterpays: {
      title: 'Great! Looking to strengthen the relationship further?',
    },
    renterpayshalf: {
      text: 'Nice! We’ll check in with your resident to see what they want to do and report back.',
    },
    renterpaysfull: {
      text: 'No problem! We’ll ask your resident what they want to do and report back.',
    },
    // hasNoRenterButDeclined: {
    //   text: 'Lorem Ipsum'
    // },
    denied: {
      text: 'No problem! May we ask what the primary reason was for making this decision?',
    },
    // reasonwhynoupgrade: {
    //   // CAN'T BE SPANNED
    //   text: 'Make sense! We’ll let your Renter, {firstName}, know we won’t be making this change at this time.',
    // },
  },

  'maintenance.scheduled.title': 'Visits',

  'maintenance.scheduled.status.scheduled': 'Scheduled Visit',
  'maintenance.scheduled.status.cancelled': 'Cancelled Visit',
  'maintenance.scheduled.status.completed': 'Completed Visit',
  'maintenance.scheduled.status.completed.notresolved': 'Follow-up Required',

  'maintenance.inHomeRepairs.scheduled.status.scheduled': 'Visit Scheduled',
  'maintenance.inHomeRepairs.scheduled.status.cancelled': 'Visit Cancelled',
  'maintenance.inHomeRepairs.scheduled.status.completed': 'Visit Completed',
  'maintenance.inHomeRepairs.scheduled.status.completed.notresolved': 'Follow-up Required',
  responsibilityonrenterside: {
    renterpayshalf: {
      title: 'Would you like to move forward and split the cost with {homeownername}?',
    },
    renterpaysfull: {
      title: 'Would you like to move forward with this upgrade?',
    },
  },

  maintenancecancelmodal: {
    title: 'Why do you want to cancel this request?',
    inputplaceholder: 'Cancellation reason',
    successfultitle: 'No problem!',
    successfultext: "We've gone ahead and cancelled this request.",
  },

  // DO NOT CHANGE THE KEYS WITHOUT A DEV
  shouldDoIt: [
    {
      label: "Yes, I'll pay <span>for it.</span>",
      key: PaymentAgreement.OwnerPays,
    },
    {
      label: 'Sure, if they pay <span>for it.</span>',
      key: PaymentAgreement.ResidentPays,
    },
    {
      label: 'No, not at <span>this time.</span>',
      key: PaymentAgreement.Declined,
    },
  ],

  // Refers to when HO is the same as the requester.
  shouldDoItHOisRequester: [
    {
      label: "Yes, I'll pay <span>for it.</span>",
      key: PaymentAgreement.OwnerPays,
    },
    {
      label: 'No, not at <span>this time.</span>',
      key: PaymentAgreement.Declined,
    },
  ],

  // Refers to when Resident is the same as the requester
  shouldDoItNoResident: [
    {
      label: "Yes, I'll pay for it.",
      key: PaymentAgreement.OwnerPays,
    },
    {
      label: 'Not at this time.',
      key: PaymentAgreement.Declined,
    },
  ],

  renterSplitText: [
    {
      label: "Nope, they're on <span>their own.</span>",
      key: PaymentAgreement.ResidentPays,
    },
    {
      label: "Sure, I'll split it <span>with them.</span>",
      key: PaymentAgreement.SplitPayment,
    },
  ],
  estimatedCost: {
    pricingGuarantee: {
      title: 'Belong Price Guarantee',
      paragraph_1: `When work needs to be done,
      we here at Belong believe you should receive a fair price for the best quality work;
      work that lasts,
      providing you the most long-term value on your home.`,
      paragraph_2: `Accordingly, Belong always charges market rates on maintenance and upgrades.
      Period. No hidden fees. Rest assured, you're getting the most bang for your buck.`,
    },
    delightfulGuarantee: {
      title: "Backed By Belong's <span>Quality Guarantee</span>",
      paragraph_1:
        "If you're not completely delighted with the quality of our work, we'll do it again. <span>On us.</span>",
    },
  },

  appointmentScheduledModal: {
    title: 'Visits',
  },

  'approvalAndResponsibility.title': 'Approval',
  lookingToSplit: {
    paragraph_1: 'Looking to <b>split</b> these into multiple\u00a0payments?\n',
    not_completed: 'You’ll be able to do so upon completion.',
    completed: 'You’ll be able to do so on your next bill.',
  },
  'included-in-plan': 'This is included in Split It Plan {financePlanId}.',
};
