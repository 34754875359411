import React from 'react';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';

export const returnToHomeButton = (
  <div className="navigation-save-exit-button-wrapper">
    <RouterHeaderNavLink label="SAVE & EXIT" buttonType={BUTTON_TYPES.TEXT_SMALL} to={{ pathname: '/' }} />
  </div>
);

export const SAVE_AND_EXIT_BUTTON = {};

SAVE_AND_EXIT_BUTTON.EXIT_TO_HOME = returnToHomeButton;
