import { USER_PAYMENT_W9_STRINGS } from 'accounts/constants/strings/user-payment-w9.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import type { HomeownerSetupDocumentationSignatureModel } from 'api/models';
import String from 'components/String/String';
import DocumentSignModal from 'containercomponents/Modals/DocumentSignModal/DocumentSignModal';
import { getString } from 'strings';

type Props = {
  showModal: boolean | (() => void);
  onHide: boolean | (() => void);
  unsignedDocument: HomeownerSetupDocumentationSignatureModel;
};

export function UserPaymentW9({ showModal, onHide, unsignedDocument }: Props) {
  const { loading, currentStepFormData, handleSaveAndNext } = useSetupFlowStep();

  async function onSign() {
    await handleSaveAndNext({
      w9: {
        entities: [
          {
            ...unsignedDocument,
            isSigned: true,
          },
        ],
      },
    });
  }

  if (!unsignedDocument || !currentStepFormData) {
    return null;
  }

  return (
    <DocumentSignModal
      show={showModal as boolean}
      onSign={onSign}
      onHide={onHide as () => void}
      loading={loading}
      title={
        <String
          string={
            unsignedDocument.name
              ? getString(USER_PAYMENT_W9_STRINGS.business_title, { businessName: unsignedDocument.name })
              : getString(USER_PAYMENT_W9_STRINGS.individual_title)
          }
        />
      }
      subTitle={<String string={getString(USER_PAYMENT_W9_STRINGS.subtitle)} />}
      file={unsignedDocument.documentUrl}
    />
  );
}
