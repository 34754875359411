import { useState } from 'react';
import Measure from 'react-measure';
import { useHistory } from 'react-router';
import { template, PATHS, BASE_PATHS } from '@belong/common';
import { Text, ButtonBase } from '@belong/ui';
import classNames from 'classnames/bind';
import { Box } from 'design-system';
import { useModal } from 'hooks/useModal';
import { filter, find, maxBy, flatten, sumBy } from 'lodash-es';
import { MaintenanceBundleRewardType, MaintenanceResponsibility, MaintenanceBundleType } from 'models/enums';
import { GuarantedTimeToListModal } from 'pages/PostInspectionFlow/GTTLModal/GTTLModal';
import ImprovementModal, { IMPROVEMENT_FROM } from 'pages/PostInspectionFlow/ImprovementModal/ImprovementModal';
import { HomeownerReceiptPriceTable } from 'pages/PostInspectionFlow/steps/Improvements/Approval/PriceTable/PriceTable';
import { getInspectionType, QUERY_PARAMS } from 'pages/PostInspectionFlow/utils';
import { ImprovementsTotalCost } from 'post-inspection/components/improvements-total-cost/improvements-total-cost';
import { PaymentModal } from 'post-inspection/components/payment-modal/payment-modal';
import { PreMoveoutSubtotalSection } from 'post-inspection/components/pre-moveout-subtotal-section/pre-moveout-subtotal-section';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { arrayInsertIf } from 'utils/insertIf';
import Money, { MONEY_FORMAT } from '../../../../../components/Money/Money';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { TodayTimeCountDown, GTTLDate } from '../Components/DateTimeCountDown/DateTimeCountDown';
import { BUNDLE_CONFIG, getCalculatedBundles, getPaymentBreakdown, getImprovementsAssignedEmployee } from '../utils';
import styles from './Approval.module.css';
import { ApprovalContext } from './ApprovalContext';
import { COST_TYPES } from './Bundle/costs.utils';
import BundlesList from './BundlesList/BundlesList';
import EstimatedStateDate, { MODES } from './EstimatedStateDate/EstimatedStateDate';
import PriceTable from './PriceTable/PriceTable';
import { Recommended } from './Recommended/Recommended';

const cx = classNames.bind(styles);

const propTypes = {
  stepConfig: PropTypes.object.isRequired,
  stepResource: PropTypes.object.isRequired,
  stepData: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  flow: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.array.isRequired,
};

const ResidentResponsibility = ({ lease, bundles }) => {
  const securityDeposit = lease.basicInfo.depositAmount;

  // const residentEstimatedCost = 'estimatedCost'
  // TODO: CHANGE THIS AFTER TURNOVERS PHASE 2
  // Take the totalEstimatedCost for the resident since they cannot approve
  // (we want to include the repairs the homeowner is fixing)
  const residentEstimatedCost = 'totalEstimatedCost';

  const totalResidentResponsibility = sumBy(bundles, (bundle) => {
    const residentPaymentBreakdown = getPaymentBreakdown(bundle).resident;

    return residentPaymentBreakdown?.[residentEstimatedCost] || 0;
  });

  if (!totalResidentResponsibility) {
    return null;
  }

  const remainingDeposit = securityDeposit - totalResidentResponsibility;

  const sections = [
    {
      items: [
        {
          name: {
            text: 'Security Deposit Paid',
            bold: true,
          },
          value: {
            text: <Money.DOLLAR_CENTS cash={securityDeposit} format={MONEY_FORMAT.DOLLARS_CENTS} />,
            bold: true,
          },
        },
        {
          name: {
            text: 'Deductions',
            bold: true,
          },
        },
        ...bundles
          .filter((x) => {
            const residentPaymentBreakdown = getPaymentBreakdown(x).resident;
            const bundleCost = residentPaymentBreakdown?.[residentEstimatedCost] || 0;
            return bundleCost > 0;
          })
          .map((bundle) => {
            const { type } = bundle;
            const residentPaymentBreakdown = getPaymentBreakdown(bundle).resident;
            const bundleConfig = BUNDLE_CONFIG[type];
            const bundleCost = residentPaymentBreakdown?.[residentEstimatedCost] || 0;
            return {
              name: {
                text: <Box ml="xs">{bundleConfig?.displayName}</Box>,
              },
              value: {
                text: (
                  <>
                    -
                    <Money.DOLLAR_CENTS cash={bundleCost} format={MONEY_FORMAT.DOLLARS_CENTS} />
                  </>
                ),
              },
            };
          }),
        {
          name: {
            text: 'Total Resident Responsibility',
            bold: true,
          },
          value: {
            text: (
              <>
                -<Money.DOLLAR_CENTS cash={totalResidentResponsibility} format={MONEY_FORMAT.DOLLARS_CENTS} />
              </>
            ),
            bold: true,
          },
        },
      ],
    },
    {
      items: [
        {
          name: {
            text: 'Total Deposit Refund',
            bold: true,
          },
          value: {
            text: <Money.DOLLAR_CENTS cash={Math.max(remainingDeposit, 0)} format={MONEY_FORMAT.DOLLARS_CENTS} />,
            bold: true,
          },
        },
        ...arrayInsertIf(remainingDeposit < 0, {
          name: {
            text: 'Additional To Collect',
            bold: true,
          },
          value: {
            text: <Money.DOLLAR_CENTS cash={Math.abs(remainingDeposit)} format={MONEY_FORMAT.DOLLARS_CENTS} />,
            bold: true,
          },
        }),
      ],
    },
  ];

  return (
    <div className="md:mb-xl">
      <Text variant="h3" fontWeight="semibold" className="mb-xs">
        {POST_INSPECTION_FLOW_STRINGS['approvals.resident_responsibility.title']}
      </Text>

      <Text className="mb-sm">{POST_INSPECTION_FLOW_STRINGS['approvals.resident_responsibility.subtext']}</Text>
      <PriceTable sections={sections} />
    </div>
  );
};

ResidentResponsibility.propTypes = {
  lease: PropTypes.object.isRequired,
  bundles: PropTypes.array.isRequired,
};

const Approval = ({ stepResource, stepData, stepConfig, onSave, onNext, flow, stepConfigs }) => {
  if (!stepData || !flow) {
    return null;
  }

  const {
    configuration: { LeaseId: leaseUniqueId },
  } = flow;

  const [loader, setLoader] = useState({ loading: false });
  const [initialBundleHeight, setInitialBundleHeight] = useState(0);
  const [currentBundleHeight, setCurrentBundleHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setModalOpen, setModalClose] = useModal(false);
  const [isGTTLModalOpen, openGTTLModal, closeGTTLModal] = useModal(false);
  const [isPPModalOpen, setPPModalOpen, setPPModalClose] = useModal(false);
  const [isLoading, startLoading, stopLoading] = useModal(false);
  const [currentImprovementId, setCurrentImprovementId] = useState(null);
  // Leaving isMonthly definition here to avoid a bigger refactor, this logic is now legacy.
  const isMonthly = false;
  const [isApprovingReport, setIsApprovingReport] = useState(false);
  const history = useHistory();
  const {
    bundles: bundlesData,
    groupPayment,
    group,
    rewards,
    homeownerReportPaymentModel,
    displayAsPriceRange,
    priceRange,
    guaranteedTimeToList,
    isDoNotExceedGroup,
  } = stepData || {};

  if (!bundlesData) {
    return null;
  }

  /**
   * The homeowner cannot go back to this step once the report is approved.
   */
  if (stepConfig.status === 'Completed' && !isApprovingReport) {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${stepConfig.home.propertyId}/setup-flow?${QUERY_PARAMS.REPORT_APPROVED}=true`
    );

    return null;
  }

  const homeownerPayment = find(groupPayment, { paidBy: MaintenanceResponsibility.Homeowner });

  const {
    homeListingDetailsModel: { employeeAssignments, leases },
  } = stepResource;
  const flowLease = leaseUniqueId ? find(leases, (lease) => lease.basicInfo.leaseId === leaseUniqueId) : null;

  const inspectionType = getInspectionType(flow);
  const bundles = getCalculatedBundles(bundlesData, stepResource.homeListingDetailsModel);
  const maximumMonthlyPaymentPlan =
    maxBy(
      filter(rewards, (reward) => reward.type === MaintenanceBundleRewardType.MonthlyInstallment && !reward.locked),
      'value'
    )?.value || 0;

  const improvements = flatten(bundles.map((bundle) => bundle.items));

  const mainPointOfContactEmployee = getImprovementsAssignedEmployee({
    employeeAssignments,
    isMoveOut: inspectionType.isMoveOut,
  });

  const nonRequiredBundles = filter(
    bundles,
    (bundle) => bundle.type !== MaintenanceBundleType.Inspirational && bundle.type !== MaintenanceBundleType.Recommended
  );

  const optionalBundleTypes = [MaintenanceBundleType.Inspirational, MaintenanceBundleType.Recommended];

  const optionalBundles = filter(bundles, (bundle) => optionalBundleTypes.includes(bundle.type));

  const handleChange = async (value, items) => {
    try {
      setLoader({
        value,
        loading: true,
        items,
        loadingKey: new Date().getTime().toString(),
      });

      await onSave({
        bundles: [
          {
            items: items.map((item) => {
              const itemHomeownerPayment =
                find(item.maintenancePayments, (mp) => mp.paidBy === MaintenanceResponsibility.Homeowner) || {};
              const itemNonHomeownerPayments = filter(
                item.maintenancePayments,
                (mp) => mp.paidBy !== MaintenanceResponsibility.Homeowner
              );

              return {
                ...item,
                maintenancePayments: [
                  ...itemNonHomeownerPayments.map((mp) => ({
                    ...mp,
                    flagged: false,
                  })),
                  {
                    ...itemHomeownerPayment,
                    paidBy: MaintenanceResponsibility.Homeowner,
                    consentStatus: value ? 'Approved' : 'Rejected',
                    flagged: !!item.maintenancePayments[0].flagged,
                  },
                ],
              };
            }),
          },
        ],
      });
    } catch (error) {
      console.error('Error occurred on the HO aproval flow:', error);
      history.push(PATHS.SUPPORT);
    } finally {
      setLoader((state) => ({
        ...state,
        loading: false,
        items: null,
        value: null,
      }));
    }
  };

  const handleGTTLModalConfirmation = async () => {
    try {
      startLoading();

      const maintenancewithHomeownerProServiceResponsibility = improvements.filter(
        (improvement) => improvement.maintenance.proServiceResponsibility === MaintenanceResponsibility.Homeowner
      );

      const payload = maintenancewithHomeownerProServiceResponsibility.map((improvement) => ({
        ...improvement,
        maintenance: {
          ...improvement.maintenance,
          proServiceResponsibility: MaintenanceResponsibility.Belong,
        },
      }));

      await handleChange(true, payload);
    } catch (e) {
      console.error(e);
    } finally {
      closeGTTLModal();
      stopLoading();
    }
  };

  const handleModalSubmit = async (values) => {
    const updatedBundles = {
      bundles: [
        {
          items: [values],
        },
      ],
    };

    try {
      startLoading();
      await onSave(updatedBundles);
    } catch (e) {
      console.error(e);
    }
    stopLoading();
    setModalClose();
  };

  const handleApprovalStepSubmit = async (paymentData) => {
    try {
      setIsApprovingReport(true);

      await onSave(paymentData);

      return true;
    } catch (error) {
      console.error(error);

      setIsApprovingReport(false);

      return false;
    }
  };

  const showTimer = !!group?.earlyEstimatedWorkStartDate;

  let title = POST_INSPECTION_FLOW_STRINGS['approvals.title'];
  let optionalBundleTitle = POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.title'];
  let optionalBundleSubtitle = POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.subtext'];

  if (inspectionType.isPreMoveOut) {
    title = POST_INSPECTION_FLOW_STRINGS['approvals.title_pre_move_out'];
    optionalBundleTitle = POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.title_move_out'];
    optionalBundleSubtitle = POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.subtext_move_out'];
  } else if (inspectionType.isMoveOut) {
    title = POST_INSPECTION_FLOW_STRINGS['approvals.title_move_out'];
    optionalBundleTitle = POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.title_move_out'];
    optionalBundleSubtitle = POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.subtext_move_out'];
  }

  const lineItemCosts = [COST_TYPES.Parts, COST_TYPES.Other, COST_TYPES.Labor];
  const bundleItems = flatten(bundles.map((b) => b.items));
  const isNewProjectManagementFeeInspection = flatten(bundleItems.map((i) => i.estimatedCostBreakdown))
    .filter((costs) => !lineItemCosts.includes(costs.type))
    .filter((costs) => costs.cost > 0)
    .every((cost) => cost.type === COST_TYPES.ProjectCoordination);

  const shouldShowProjectManagementFee =
    !isDoNotExceedGroup &&
    (inspectionType.isMoveOut || inspectionType.isOnboarding) &&
    isNewProjectManagementFeeInspection;

  const bundleList = shouldShowProjectManagementFee
    ? [...nonRequiredBundles, { type: 'ProjectManagement', total: stepData.projectManagementFeeToPay }]
    : nonRequiredBundles;

  /** NOTE: Checking first if the main reason is the discualified by "ApprovedTBDSubtask"
   * but whenever there are TBD items and "HomeownerFixing" as diqualified reason this reason will the main reason
   * That's why we still need to check if there are TBD items in the bundles.
   **/
  const isGTTLDisqualifiedDueTBDItems =
    guaranteedTimeToList?.disqualifiedReason === 'ApprovedTBDSubtask' ||
    bundlesData
      ?.filter((bundle) => !optionalBundleTypes.includes(bundle.type))
      ?.map((bundle) => bundle?.items?.map((item) => !!item?.isTBD))
      ?.flat()
      ?.includes(true);

  const isGTTLVisible = !guaranteedTimeToList?.disqualifiedReason && inspectionType.isOnboarding;

  const isGTTLDisqualifiedDueHomeownerFixingItems =
    !isGTTLVisible && guaranteedTimeToList?.disqualifiedReason === 'HomeownerFixing' && !isGTTLDisqualifiedDueTBDItems;

  const handleBundleResize = ({ bounds }) => {
    setCurrentBundleHeight(bounds.height);

    if (!initialBundleHeight) {
      setInitialBundleHeight(bounds.height);
    }
  };

  /**
   * NOTE: Subtracting the initial closed bundle height from the total height of all the bundles
   * since we're recalculating the height of the bundle list after the bundle is opened.
   */
  const floatingButtonExtraSpace = currentBundleHeight - initialBundleHeight;

  const showDefaultDisclaimer = isGTTLDisqualifiedDueTBDItems;
  const shouldShowSubtotalsDivider = !inspectionType?.isOnboarding || homeownerPayment?.estimatedDiscountAmount > 0;

  const { isLegacyInvoicing } = group;
  return (
    <ApprovalContext.Provider
      value={{
        guaranteedTimeToList,
        maximumMonthlyPaymentPlan,
        groupPayment,
        homeownerPayment,
        group,
        showTimer,
        flow,
        loader,
        bundles,
        rewards,
        onNext,
        onSave,
        onImprovementToggle: handleChange,
        homeownerReportPayment: homeownerReportPaymentModel,
        onApprovalStepSubmit: handleApprovalStepSubmit,
        inspectionType,
        isSubmitting,
        setIsSubmitting,
        mainPointOfContactEmployee,
        isNewProjectManagementFee: shouldShowProjectManagementFee,
        priceRange: displayAsPriceRange ? priceRange : null,
        isDoNotExceedGroup,
        isLegacyInvoicing,
      }}
    >
      <PostInspectionContentLayout
        showCityBackground
        headerBorder={false}
        isLogoVisible={false}
        title={
          isGTTLVisible ? (
            <p>
              {template(POST_INSPECTION_FLOW_STRINGS['approvals.title_with_gttl'], {
                date: <span className="text-green">{` ${guaranteedTimeToList?.days}\u00a0days`}</span>,
              })}
            </p>
          ) : (
            title
          )
        }
        employee={mainPointOfContactEmployee}
        actions={
          isGTTLVisible
            ? [
              <div className="text-white mr-2xl" key="gttl">
                Submit your deposit in the next
                <TodayTimeCountDown />
                and
                <br className="hidden md:inline" />
                we&apos;ll get your home professionally listed by
                <GTTLDate date={guaranteedTimeToList?.date} />.
              </div>,
              ]
            : inspectionType.isMoveOut
            ? [
              <div className="mr-5xl" key="edate">
                <EstimatedStateDate mode={MODES.DARK} />
              </div>,
              ]
            : []
        }
        stepConfig={stepConfig}
        stepConfigs={stepConfigs}
      >
        <div className={cx('approval')}>
          {isGTTLVisible && (
            <p className="body mb-lg text-center">
              Confirm these improvements in the next
              <TodayTimeCountDown />
              to
              <br className="hidden md:inline" />
              <span className="ml-2xs">{`lock in your guaranteed listing\u00a0time.`}</span>
            </p>
          )}

          {isGTTLDisqualifiedDueHomeownerFixingItems && (
            <div className="mb-lg text-center mx-auto leading-p2">
              <p className="body">{`We cannot guarantee a list date when you chose to do your own improvement\u00a0work.`}</p>
              <span className="body mb-xs">We recommend letting Belong handle these improvements.</span>
              <ButtonBase onClick={openGTTLModal} className="ml-2xs font-semibold">
                LEARN MORE
              </ButtonBase>
            </div>
          )}

          {showDefaultDisclaimer && (
            <div className="flex items-center mb-xl md:items-start">
              <img
                className="mb-xl md:mb-0"
                style={{ width: '54px', height: '48px' }}
                src="/image_assets/inspection_report_approval.svg"
                alt="transparent-pricing"
              />
              <p className="p1 ml-xs">
                We believe in transparent pricing, which means that all costs are directly passed through to our
                community members. We’re constantly working with our contractors and vendors to provide you the lowest
                prices.
              </p>
            </div>
          )}

          <Measure bounds onResize={handleBundleResize}>
            {({ measureRef }) => (
              <div className="mb-xl" ref={measureRef}>
                <BundlesList
                  bundles={bundleList}
                  projectManagementFeeApplicable={shouldShowProjectManagementFee}
                  onChange={handleChange}
                  isMonthly={isMonthly}
                  displayPrice={!isDoNotExceedGroup}
                  onClick={(item) => {
                    setModalOpen();
                    setCurrentImprovementId(item.maintenance.uniqueId);
                  }}
                  priceRange={displayAsPriceRange ? priceRange : null}
                  isOnboarding={inspectionType.isOnboarding}
                  isGTTLDisqualifiedDueHomeownerFixingItems={isGTTLDisqualifiedDueHomeownerFixingItems}
                />
              </div>
            )}
          </Measure>

          {inspectionType.isPreMoveOut && (
            <div className="mb-sm">
              <PreMoveoutSubtotalSection
                groupPayment={groupPayment}
                homeownerPayment={homeownerPayment}
                priceRange={displayAsPriceRange ? priceRange : null}
              />
            </div>
          )}
          <div className="mb-sm">
            <HomeownerReceiptPriceTable
              inspectionType={inspectionType}
              groupPayment={groupPayment}
              bundles={bundles}
              homeownerPayment={homeownerPayment}
              maximumMonthlyPaymentPlan={maximumMonthlyPaymentPlan}
              onChange={() => {}}
              homeownerReportPayment={homeownerReportPaymentModel}
              priceRange={displayAsPriceRange ? priceRange : null}
              hideBundlesBreakdown
              showSubtotal={false}
              showEstTotal={false}
            />
          </div>
          <div className={shouldShowSubtotalsDivider ? 'my-xl' : '-mt-sm mb-xl'}>
            <div className="border-b border-dashed border-gray border-t-0 border-l-0 border-r-0" />
          </div>
          <div className="mb-xl">
            <ImprovementsTotalCost
              isPreMoveOut={inspectionType.isPreMoveOut}
              homeownerPayment={homeownerPayment}
              isMonthly={isMonthly}
              maximumMonthlyPaymentPlanInstallmentCount={maximumMonthlyPaymentPlan}
              homeownerReportPayment={homeownerReportPaymentModel}
              priceRange={displayAsPriceRange ? priceRange : null}
              displayNeverExceed={isDoNotExceedGroup}
            />
          </div>
          <div
            className={`hidden md:flex justify-center ${
              optionalBundles.length ? 'mb-2xl md:mb-3xl' : '-mb-3xl md:mb-xl'
            }`}
          >
            <PostInspectionContentLayout.CtaButton onClick={setPPModalOpen} extraSpace={floatingButtonExtraSpace}>
              {inspectionType.isPreMoveOut
                ? POST_INSPECTION_FLOW_STRINGS['approvals.cta_pre_move_out']
                : POST_INSPECTION_FLOW_STRINGS['approvals.cta']}
            </PostInspectionContentLayout.CtaButton>
          </div>

          <div className="md:hidden overflow-hidden w-0 h-0">
            <PostInspectionContentLayout.CtaButton onClick={setPPModalOpen} displayfixed>
              {inspectionType.isPreMoveOut
                ? POST_INSPECTION_FLOW_STRINGS['approvals.cta_pre_move_out']
                : POST_INSPECTION_FLOW_STRINGS['approvals.cta']}
            </PostInspectionContentLayout.CtaButton>
          </div>

          {flowLease && <ResidentResponsibility bundles={nonRequiredBundles} lease={flowLease} />}
          {!!optionalBundles.length && (
            <div className={cx('inspirational-list-container')}>
              <Text variant="h3" fontWeight="semibold" className="mb-xs pt-lg md:pt-0">
                {optionalBundleTitle}
              </Text>

              <Text className="mb-sm">{optionalBundleSubtitle}</Text>

              <div className="mt-xl">
                <Recommended
                  bundles={optionalBundles}
                  onChange={handleChange}
                  onClick={(item) => {
                    setModalOpen();
                    setCurrentImprovementId(item.maintenance.uniqueId);
                  }}
                  showPrice={!isDoNotExceedGroup}
                />
              </div>
            </div>
          )}
        </div>
        {currentImprovementId && isModalOpen && (
          <ImprovementModal
            loading={isLoading}
            inspectionType={inspectionType}
            show={isModalOpen}
            onHide={setModalClose}
            rewards={rewards}
            groupPayment={groupPayment}
            improvement={find(improvements, (item) => item.maintenance.uniqueId === currentImprovementId)}
            onSubmit={handleModalSubmit}
            onChange={handleChange}
            showPriceBreakdown={!isDoNotExceedGroup}
            showResponsibilityPercentage={group.showResponsibilityPercentage}
            from={IMPROVEMENT_FROM.IMPROVEMENT_REPORT}
            onSave={onSave}
          />
        )}
        <PaymentModal isVisible={isPPModalOpen} onHide={setPPModalClose} inspectionType={inspectionType} />
        <GuarantedTimeToListModal
          isVisible={isGTTLModalOpen}
          onDismiss={closeGTTLModal}
          handleSuccessCallback={handleGTTLModalConfirmation}
          isLoading={isLoading}
        />
      </PostInspectionContentLayout>
    </ApprovalContext.Provider>
  );
};

Approval.propTypes = propTypes;

export default Approval;
