import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import styles from './AccountPropertyBannerCard.module.css';

const cx = classNames.bind(styles);

class AccountPropertyBannerCard extends Component {
  static propTypes = {
    imageUrl: PropTypes.string,
    coordinates: PropTypes.object.isRequired,
  };

  static defaultProps = {
    imageUrl: '',
  };

  render() {
    const { imageUrl, coordinates } = this.props;

    return (
      <div className={cx('banner-card')}>
        <div className={cx('banner-images')}>
          {imageUrl && <div className={cx('image')} style={{ backgroundImage: `url('${imageUrl}')` }} />}
          {coordinates?.latitude && coordinates?.longitude && (
            <div className={cx('image')}>
              <GoogleMap
                streetView={false}
                lat={parseFloat(coordinates.latitude)}
                lng={parseFloat(coordinates.longitude)}
                zoom={13}
                controls={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AccountPropertyBannerCard;
