import React, { Component } from 'react';
import Measure from 'react-measure';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Image.module.css';

classNames.bind(styles);

// const snakeToCamelCaseKeys = (obj) =>
//   Object.keys(obj)
//     .map(k => ({
//       [k.replace(/(-\w)/g, m => m[1].toUpperCase())]: obj[k],
//     }))
//     .reduce((a, b) => ({ ...a, ...b }), {});

class Image extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
    fixDimensions: PropTypes.objectOf(PropTypes.string),
    propStyles: PropTypes.object,
  };

  static defaultProps = {
    alt: '',
    className: '',
    fixDimensions: null,
    propStyles: {},
  };

  state = {
    width: '100%',
    height: '100%',
    // orientation: null,
  };

  handleResize = (contentRect) => {
    const { width, height } = contentRect.bounds;
    this.setState({ width, height });
  };

  // handleImageLoaded = (event) => {
  //   const imageElement = event.target;
  //
  //   try {
  //     EXIF.getData(imageElement, () => {
  //       this.setState({
  //         orientation: EXIF.getTag(imageElement, 'Orientation'),
  //       });
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  render() {
    const { src, alt, className, fixDimensions, propStyles, ...rest } = this.props;
    const { width, height } = this.state;
    const style = {
      ...propStyles,
    };

    if (fixDimensions) {
      style.width = width;
      style.height = height;
    }

    return (
      <Measure bounds onResize={this.handleResize}>
        {({ measureRef }) => (
          <img
            className={`${className}`}
            ref={measureRef}
            src={src}
            alt={alt}
            aria-hidden={alt ? 'false' : 'true'}
            style={style}
            {...rest}
          />
        )}
      </Measure>
    );
  }
}

Image.propTypes = {};

export default Image;
