export const forbes1000 = [
  { key: `Belong`, value: `Belong` },
  { key: `Walmart`, value: `Walmart` },
  { key: `Exxon Mobil Corporation`, value: `Exxon Mobil Corporation` },
  { key: `Berkshire Hathaway`, value: `Berkshire Hathaway` },
  { key: `Apple`, value: `Apple` },
  { key: `UnitedHealth Group Incorporated`, value: `UnitedHealth Group Incorporated` },
  { key: `McKesson Corporation`, value: `McKesson Corporation` },
  { key: `CVS Health Corporation`, value: `CVS Health Corporation` },
  { key: `Amazon`, value: `Amazon` },
  { key: `AT&T`, value: `AT&T` },
  { key: `General Motors Company`, value: `General Motors Company` },
  { key: `Ford Motor Company`, value: `Ford Motor Company` },
  { key: `AmerisourceBergen Corp.`, value: `AmerisourceBergen Corp.` },
  { key: `Chevron Corporation`, value: `Chevron Corporation` },
  { key: `Cardinal Health`, value: `Cardinal Health` },
  { key: `Costco Wholesale Corporation`, value: `Costco Wholesale Corporation` },
  { key: `Verizon Communications`, value: `Verizon Communications` },
  { key: `The Kroger Company`, value: `The Kroger Company` },
  { key: `General Electric Company`, value: `General Electric Company` },
  { key: `Walgreens Boots Alliance`, value: `Walgreens Boots Alliance` },
  { key: `JPMorgan Chase & Co.`, value: `JPMorgan Chase & Co.` },
  { key: `Federal National Mortgage Association`, value: `Federal National Mortgage Association` },
  { key: `Alphabet`, value: `Alphabet` },
  { key: `The Home Depot`, value: `The Home Depot` },
  { key: `Bank of America Corporation`, value: `Bank of America Corporation` },
  { key: `Express Scripts Holding Company`, value: `Express Scripts Holding Company` },
  { key: `Wells Fargo & Company`, value: `Wells Fargo & Company` },
  { key: `The Boeing Company`, value: `The Boeing Company` },
  { key: `Phillips 66`, value: `Phillips 66` },
  { key: `Anthem`, value: `Anthem` },
  { key: `Microsoft Corporation`, value: `Microsoft Corporation` },
  { key: `Valero Energy Corporation`, value: `Valero Energy Corporation` },
  { key: `Citigroup`, value: `Citigroup` },
  { key: `Comcast Corporation`, value: `Comcast Corporation` },
  { key: `International Business Machines Corporation`, value: `International Business Machines Corporation` },
  { key: `Dell Technologies`, value: `Dell Technologies` },
  { key: `State Farm Insurance Cos.`, value: `State Farm Insurance Cos.` },
  { key: `Johnson & Johnson`, value: `Johnson & Johnson` },
  { key: `Federal Home Loan Mortgage Corporation`, value: `Federal Home Loan Mortgage Corporation` },
  { key: `Target Corporation`, value: `Target Corporation` },
  { key: `Lowe's Companies`, value: `Lowe's Companies` },
  { key: `Marathon Petroleum Corporation`, value: `Marathon Petroleum Corporation` },
  { key: `The Procter & Gamble Company`, value: `The Procter & Gamble Company` },
  { key: `MetLife`, value: `MetLife` },
  { key: `United Parcel Service`, value: `United Parcel Service` },
  { key: `PepsiCo`, value: `PepsiCo` },
  { key: `Intel Corporation`, value: `Intel Corporation` },
  { key: `DowDuPont`, value: `DowDuPont` },
  { key: `Archer-Daniels-Midland Company`, value: `Archer-Daniels-Midland Company` },
  { key: `Aetna`, value: `Aetna` },
  { key: `FedEx Corporation`, value: `FedEx Corporation` },
  { key: `United Technologies Corporation`, value: `United Technologies Corporation` },
  { key: `Prudential Financial`, value: `Prudential Financial` },
  { key: `Albertsons Companies`, value: `Albertsons Companies` },
  { key: `Sysco Corporation`, value: `Sysco Corporation` },
  { key: `The Walt Disney Company`, value: `The Walt Disney Company` },
  { key: `Humana`, value: `Humana` },
  { key: `Pfizer`, value: `Pfizer` },
  { key: `HP`, value: `HP` },
  { key: `Lockheed Martin Corporation`, value: `Lockheed Martin Corporation` },
  { key: `American International Group`, value: `American International Group` },
  { key: `Centene Corporation`, value: `Centene Corporation` },
  { key: `Cisco Systems`, value: `Cisco Systems` },
  { key: `HCA Healthcare`, value: `HCA Healthcare` },
  { key: `Energy Transfer Equity, L.P.`, value: `Energy Transfer Equity, L.P.` },
  { key: `Caterpillar`, value: `Caterpillar` },
  { key: `Nationwide Mutual Insurance Co.`, value: `Nationwide Mutual Insurance Co.` },
  { key: `Morgan Stanley`, value: `Morgan Stanley` },
  { key: `Liberty Mutual Holding Company`, value: `Liberty Mutual Holding Company` },
  { key: `New York Life Insurance Company`, value: `New York Life Insurance Company` },
  { key: `The Goldman Sachs Group`, value: `The Goldman Sachs Group` },
  { key: `American Airlines Group`, value: `American Airlines Group` },
  { key: `Best Buy Co.`, value: `Best Buy Co.` },
  { key: `CIGNA Corporation`, value: `CIGNA Corporation` },
  { key: `Charter Communications`, value: `Charter Communications` },
  { key: `Delta Air Lines`, value: `Delta Air Lines` },
  { key: `Facebook`, value: `Facebook` },
  { key: `Honeywell International`, value: `Honeywell International` },
  { key: `Merck & Co.`, value: `Merck & Co.` },
  { key: `The Allstate Corporation`, value: `The Allstate Corporation` },
  { key: `Tyson Foods`, value: `Tyson Foods` },
  { key: `United Continental Holdings`, value: `United Continental Holdings` },
  { key: `Oracle Corporation`, value: `Oracle Corporation` },
  { key: `Tech Data Corporation`, value: `Tech Data Corporation` },
  { key: `Teachers Insurance and Annuity Association`, value: `Teachers Insurance and Annuity Association` },
  { key: `The TJX Companies`, value: `The TJX Companies` },
  { key: `American Express Company`, value: `American Express Company` },
  { key: `The Coca-Cola Company`, value: `The Coca-Cola Company` },
  { key: `Publix Super Markets`, value: `Publix Super Markets` },
  { key: `Nike`, value: `Nike` },
  { key: `Andeavor`, value: `Andeavor` },
  { key: `World Fuel Services Corporation`, value: `World Fuel Services Corporation` },
  { key: `Exelon Corporation`, value: `Exelon Corporation` },
  { key: `Massachusetts Mutual Life Insurance Company`, value: `Massachusetts Mutual Life Insurance Company` },
  { key: `Rite Aid Corporation`, value: `Rite Aid Corporation` },
  { key: `ConocoPhillips`, value: `ConocoPhillips` },
  { key: `CHS`, value: `CHS` },
  { key: `3M Company`, value: `3M Company` },
  { key: `Time Warner`, value: `Time Warner` },
  { key: `General Dynamics Corporation`, value: `General Dynamics Corporation` },
  { key: `United Services Automobile Association`, value: `United Services Automobile Association` },
  { key: `Capital One Financial Corporation`, value: `Capital One Financial Corporation` },
  { key: `Deere & Company`, value: `Deere & Company` },
  { key: `INTL FCStone`, value: `INTL FCStone` },
  {
    key: `Northwestern Mutual Life Insurance Company`,
    value: `Northwestern Mutual Life Insurance Company`,
  },
  { key: `Enterprise Products Partners L.P.`, value: `Enterprise Products Partners L.P.` },
  { key: `The Travelers Companies`, value: `The Travelers Companies` },
  { key: `Hewlett Packard Enterprise Company`, value: `Hewlett Packard Enterprise Company` },
  { key: `Philip Morris International`, value: `Philip Morris International` },
  { key: `Twenty-First Century Fox`, value: `Twenty-First Century Fox` },
  { key: `AbbVie`, value: `AbbVie` },
  { key: `Abbott Laboratories`, value: `Abbott Laboratories` },
  { key: `The Progressive Corporation`, value: `The Progressive Corporation` },
  { key: `Arrow Electronics`, value: `Arrow Electronics` },
  { key: `The Kraft Heinz Company`, value: `The Kraft Heinz Company` },
  { key: `Plains GP Holdings, L.P.`, value: `Plains GP Holdings, L.P.` },
  { key: `Gilead Sciences`, value: `Gilead Sciences` },
  { key: `Mondelez International`, value: `Mondelez International` },
  { key: `Northrop Grumman Corporation`, value: `Northrop Grumman Corporation` },
  { key: `Raytheon Company`, value: `Raytheon Company` },
  { key: `Macy's`, value: `Macy's` },
  { key: `US Foods Holding Corp.`, value: `US Foods Holding Corp.` },
  { key: `U.S. Bancorp`, value: `U.S. Bancorp` },
  { key: `Dollar General Corporation`, value: `Dollar General Corporation` },
  { key: `International Paper Company`, value: `International Paper Company` },
  { key: `Duke Energy Corporation`, value: `Duke Energy Corporation` },
  { key: `The Southern Company`, value: `The Southern Company` },
  { key: `Marriott International`, value: `Marriott International` },
  { key: `Avnet`, value: `Avnet` },
  { key: `Eli Lilly and Company`, value: `Eli Lilly and Company` },
  { key: `Amgen`, value: `Amgen` },
  { key: `McDonald's Corporation`, value: `McDonald's Corporation` },
  { key: `Starbucks Corporation`, value: `Starbucks Corporation` },
  { key: `Qualcomm Incorporated`, value: `Qualcomm Incorporated` },
  { key: `Dollar Tree`, value: `Dollar Tree` },
  { key: `PBF Energy`, value: `PBF Energy` },
  { key: `Icahn Enterprises L.P.`, value: `Icahn Enterprises L.P.` },
  { key: `Aflac Incorporated`, value: `Aflac Incorporated` },
  { key: `AutoNation`, value: `AutoNation` },
  { key: `Penske Automotive Group`, value: `Penske Automotive Group` },
  { key: `Whirlpool Corporation`, value: `Whirlpool Corporation` },
  { key: `Union Pacific Corporation`, value: `Union Pacific Corporation` },
  { key: `Southwest Airlines Co.`, value: `Southwest Airlines Co.` },
  { key: `ManpowerGroup`, value: `ManpowerGroup` },
  { key: `Thermo Fisher Scientific`, value: `Thermo Fisher Scientific` },
  { key: `Bristol-Myers Squibb Company`, value: `Bristol-Myers Squibb Company` },
  { key: `Halliburton Company`, value: `Halliburton Company` },
  { key: `Tenet Healthcare Corporation`, value: `Tenet Healthcare Corporation` },
  { key: `Lear Corporation`, value: `Lear Corporation` },
  { key: `Cummins`, value: `Cummins` },
  { key: `Micron Technology`, value: `Micron Technology` },
  { key: `Nucor Corporation`, value: `Nucor Corporation` },
  { key: `Molina Healthcare`, value: `Molina Healthcare` },
  { key: `Fluor Corporation`, value: `Fluor Corporation` },
  { key: `Altria Group`, value: `Altria Group` },
  { key: `PACCAR Inc`, value: `PACCAR Inc` },
  { key: `The Hartford Financial Services Group`, value: `The Hartford Financial Services Group` },
  { key: `Kohl's Corporation`, value: `Kohl's Corporation` },
  { key: `Western Digital Corporation`, value: `Western Digital Corporation` },
  { key: `Jabil Inc`, value: `Jabil Inc` },
  { key: `Community Health Systems`, value: `Community Health Systems` },
  { key: `Visa`, value: `Visa` },
  { key: `Danaher Corporation`, value: `Danaher Corporation` },
  { key: `Kimberly-Clark Corporation`, value: `Kimberly-Clark Corporation` },
  { key: `AECOM`, value: `AECOM` },
  { key: `The PNC Financial Services Group`, value: `The PNC Financial Services Group` },
  { key: `CenturyLink`, value: `CenturyLink` },
  { key: `NextEra Energy`, value: `NextEra Energy` },
  { key: `PG&E Corporation`, value: `PG&E Corporation` },
  { key: `Synnex Corporation`, value: `Synnex Corporation` },
  { key: `WellCare Health Plans`, value: `WellCare Health Plans` },
  { key: `Performance Food Group Company`, value: `Performance Food Group Company` },
  { key: `Sears Holdings Corporation`, value: `Sears Holdings Corporation` },
  { key: `Synchrony Financial`, value: `Synchrony Financial` },
  { key: `CarMax`, value: `CarMax` },
  { key: `The Bank of New York Mellon Corporation`, value: `The Bank of New York Mellon Corporation` },
  { key: `Freeport-McMoRan`, value: `Freeport-McMoRan` },
  { key: `Genuine Parts Company`, value: `Genuine Parts Company` },
  { key: `Emerson Electric Co.`, value: `Emerson Electric Co.` },
  { key: `DaVita`, value: `DaVita` },
  { key: `Supervalu`, value: `Supervalu` },
  { key: `The Gap`, value: `The Gap` },
  { key: `General Mills`, value: `General Mills` },
  { key: `Nordstrom`, value: `Nordstrom` },
  { key: `Colgate-Palmolive Company`, value: `Colgate-Palmolive Company` },
  { key: `American Electric Power Company`, value: `American Electric Power Company` },
  { key: `XPO Logistics`, value: `XPO Logistics` },
  { key: `The Goodyear Tire & Rubber Company`, value: `The Goodyear Tire & Rubber Company` },
  { key: `Omnicom Group`, value: `Omnicom Group` },
  { key: `CDW Corporation`, value: `CDW Corporation` },
  { key: `The Sherwin-Williams Company`, value: `The Sherwin-Williams Company` },
  { key: `PPG Industries`, value: `PPG Industries` },
  { key: `Texas Instruments Incorporated`, value: `Texas Instruments Incorporated` },
  { key: `C. H. Robinson Worldwide`, value: `C. H. Robinson Worldwide` },
  { key: `WestRock Company`, value: `WestRock Company` },
  { key: `Cognizant Technology Solutions Corporation`, value: `Cognizant Technology Solutions Corporation` },
  { key: `Newell Brands`, value: `Newell Brands` },
  { key: `CBS Corporation`, value: `CBS Corporation` },
  { key: `Envision Healthcare Corporation`, value: `Envision Healthcare Corporation` },
  { key: `Monsanto Company`, value: `Monsanto Company` },
  { key: `Aramark`, value: `Aramark` },
  { key: `Applied Materials`, value: `Applied Materials` },
  { key: `Waste Management`, value: `Waste Management` },
  { key: `DISH Network Corporation`, value: `DISH Network Corporation` },
  { key: `Illinois Tool Works`, value: `Illinois Tool Works` },
  { key: `Lincoln National Corporation`, value: `Lincoln National Corporation` },
  { key: `HollyFrontier Corporation`, value: `HollyFrontier Corporation` },
  { key: `CBRE Group`, value: `CBRE Group` },
  { key: `Textron`, value: `Textron` },
  { key: `Ross Stores`, value: `Ross Stores` },
  { key: `Principal Financial Group`, value: `Principal Financial Group` },
  { key: `D.R. Horton`, value: `D.R. Horton` },
  { key: `Marsh & McLennan Companies`, value: `Marsh & McLennan Companies` },
  { key: `Devon Energy Corporation`, value: `Devon Energy Corporation` },
  { key: `The AES Corporation`, value: `The AES Corporation` },
  { key: `Ecolab`, value: `Ecolab` },
  { key: `Land O'Lakes`, value: `Land O'Lakes` },
  { key: `Loews Corporation`, value: `Loews Corporation` },
  { key: `Kinder Morgan`, value: `Kinder Morgan` },
  { key: `FirstEnergy Corp.`, value: `FirstEnergy Corp.` },
  { key: `Occidental Petroleum Corporation`, value: `Occidental Petroleum Corporation` },
  { key: `Viacom`, value: `Viacom` },
  { key: `PayPal Holdings`, value: `PayPal Holdings` },
  { key: `NGL Energy Partners LP`, value: `NGL Energy Partners LP` },
  { key: `Celgene Corporation`, value: `Celgene Corporation` },
  { key: `Arconic`, value: `Arconic` },
  { key: `Kellogg Company`, value: `Kellogg Company` },
  { key: `Las Vegas Sands Corp.`, value: `Las Vegas Sands Corp.` },
  { key: `Stanley Black & Decker`, value: `Stanley Black & Decker` },
  { key: `Booking Holdings Inc`, value: `Booking Holdings Inc` },
  { key: `Lennar Corporation`, value: `Lennar Corporation` },
  { key: `L Brands`, value: `L Brands` },
  { key: `DTE Energy Company`, value: `DTE Energy Company` },
  { key: `Dominion Energy`, value: `Dominion Energy` },
  { key: `Reinsurance Group of Americaorporated`, value: `Reinsurance Group of Americaorporated` },
  { key: `J.C. Penney Company`, value: `J.C. Penney Company` },
  { key: `Mastercard Incorporated`, value: `Mastercard Incorporated` },
  { key: `BlackRock`, value: `BlackRock` },
  { key: `Henry Schein`, value: `Henry Schein` },
  { key: `Guardian Life Ins. Co. of America`, value: `Guardian Life Ins. Co. of America` },
  { key: `Stryker Corporation`, value: `Stryker Corporation` },
  { key: `Leucadia National Corporation`, value: `Leucadia National Corporation` },
  { key: `V.F. Corporation`, value: `V.F. Corporation` },
  { key: `Automatic Data Processing`, value: `Automatic Data Processing` },
  { key: `Edison International`, value: `Edison International` },
  { key: `Biogen`, value: `Biogen` },
  { key: `United States Steel Corporation`, value: `United States Steel Corporation` },
  { key: `Core-Mark Holding Company`, value: `Core-Mark Holding Company` },
  { key: `Bed Bath & Beyond`, value: `Bed Bath & Beyond` },
  { key: `ONEOK`, value: `ONEOK` },
  { key: `BB&T Corporation`, value: `BB&T Corporation` },
  { key: `Becton, Dickinson and Company`, value: `Becton, Dickinson and Company` },
  { key: `Ameriprise Financial`, value: `Ameriprise Financial` },
  { key: `Farmers Insurance Exchange`, value: `Farmers Insurance Exchange` },
  { key: `First Data Corporation`, value: `First Data Corporation` },
  { key: `Consolidated Edison`, value: `Consolidated Edison` },
  { key: `Parker-Hannifin Corporation`, value: `Parker-Hannifin Corporation` },
  { key: `Anadarko Petroleum Corporation`, value: `Anadarko Petroleum Corporation` },
  { key: `State Street Corporation`, value: `State Street Corporation` },
  { key: `Tesla`, value: `Tesla` },
  { key: `Netflix`, value: `Netflix` },
  { key: `Alcoa Corporation`, value: `Alcoa Corporation` },
  { key: `Discover Financial Services`, value: `Discover Financial Services` },
  { key: `Praxair`, value: `Praxair` },
  { key: `CSX Corporation`, value: `CSX Corporation` },
  { key: `Xcel Energy`, value: `Xcel Energy` },
  { key: `Unum Group`, value: `Unum Group` },
  { key: `Universal Health Services`, value: `Universal Health Services` },
  { key: `NRG Energy`, value: `NRG Energy` },
  { key: `EOG Resources`, value: `EOG Resources` },
  { key: `Sempra Energy`, value: `Sempra Energy` },
  { key: `Toys "R" Us`, value: `Toys "R" Us` },
  { key: `Group 1 Automotive`, value: `Group 1 Automotive` },
  { key: `Entergy Corporation`, value: `Entergy Corporation` },
  { key: `Molson Coors Brewing Company`, value: `Molson Coors Brewing Company` },
  { key: `L3 Technologies`, value: `L3 Technologies` },
  { key: `Ball Corporation`, value: `Ball Corporation` },
  { key: `AutoZone`, value: `AutoZone` },
  { key: `Murphy USA`, value: `Murphy USA` },
  { key: `MGM Resorts International`, value: `MGM Resorts International` },
  { key: `Office Depot`, value: `Office Depot` },
  { key: `Huntsman Corporation`, value: `Huntsman Corporation` },
  { key: `Baxter International`, value: `Baxter International` },
  { key: `Norfolk Southern Corporation`, value: `Norfolk Southern Corporation` },
  { key: `salesforce.com`, value: `salesforce.com` },
  { key: `Laboratory Corporation of America Holdings`, value: `Laboratory Corporation of America Holdings` },
  { key: `W.W. Grainger`, value: `W.W. Grainger` },
  { key: `Qurate Retail`, value: `Qurate Retail` },
  { key: `Autoliv`, value: `Autoliv` },
  { key: `Live Nation Entertainment`, value: `Live Nation Entertainment` },
  { key: `Xerox Corporation`, value: `Xerox Corporation` },
  { key: `Leidos Holdings`, value: `Leidos Holdings` },
  { key: `Corning Incorporated`, value: `Corning Incorporated` },
  { key: `Lithia Motors`, value: `Lithia Motors` },
  { key: `Expedia Group`, value: `Expedia Group` },
  { key: `Republic Services`, value: `Republic Services` },
  { key: `Jacobs Engineering Group`, value: `Jacobs Engineering Group` },
  { key: `Sonic Automotive`, value: `Sonic Automotive` },
  { key: `Ally Financial`, value: `Ally Financial` },
  { key: `LKQ Corporation`, value: `LKQ Corporation` },
  { key: `BorgWarner`, value: `BorgWarner` },
  { key: `Fidelity National Financial`, value: `Fidelity National Financial` },
  { key: `SunTrust Banks`, value: `SunTrust Banks` },
  { key: `IQVIA Holdings`, value: `IQVIA Holdings` },
  { key: `Reliance Steel & Aluminum Co.`, value: `Reliance Steel & Aluminum Co.` },
  { key: `NVIDIA Corporation`, value: `NVIDIA Corporation` },
  { key: `Voya Financial`, value: `Voya Financial` },
  { key: `CenterPoint Energy`, value: `CenterPoint Energy` },
  { key: `eBay`, value: `eBay` },
  { key: `Eastman Chemical Company`, value: `Eastman Chemical Company` },
  { key: `American Family Insurance Group`, value: `American Family Insurance Group` },
  { key: `Steel Dynamics`, value: `Steel Dynamics` },
  { key: `Pacific Life`, value: `Pacific Life` },
  { key: `Chesapeake Energy Corporation`, value: `Chesapeake Energy Corporation` },
  { key: `Mohawk Industries`, value: `Mohawk Industries` },
  { key: `Quanta Services`, value: `Quanta Services` },
  { key: `Advance Auto Parts`, value: `Advance Auto Parts` },
  { key: `Owens & Minor`, value: `Owens & Minor` },
  { key: `United Natural Foods`, value: `United Natural Foods` },
  { key: `Tenneco`, value: `Tenneco` },
  { key: `Conagra Brands`, value: `Conagra Brands` },
  { key: `GameStop Corp.`, value: `GameStop Corp.` },
  { key: `Hormel Foods Corporation`, value: `Hormel Foods Corporation` },
  { key: `Hilton Worldwide Holdings`, value: `Hilton Worldwide Holdings` },
  { key: `Frontier Communications Corporation`, value: `Frontier Communications Corporation` },
  { key: `Fidelity National Information Services`, value: `Fidelity National Information Services` },
  { key: `Public Service Enterprise Group Incorporated`, value: `Public Service Enterprise Group Incorporated` },
  { key: `Boston Scientific Corporation`, value: `Boston Scientific Corporation` },
  { key: `O'Reilly Automotive`, value: `O'Reilly Automotive` },
  { key: `The Charles Schwab Corporation`, value: `The Charles Schwab Corporation` },
  { key: `Global Partners LP`, value: `Global Partners LP` },
  { key: `PVH Corp.`, value: `PVH Corp.` },
  { key: `Avis Budget Group`, value: `Avis Budget Group` },
  { key: `Targa Resources Corp.`, value: `Targa Resources Corp.` },
  { key: `Hertz Global Holdings`, value: `Hertz Global Holdings` },
  { key: `Calpine Corporation`, value: `Calpine Corporation` },
  { key: `Mutual of Omaha Insurance Company`, value: `Mutual of Omaha Insurance Company` },
  { key: `Crown Holdings`, value: `Crown Holdings` },
  { key: `Peter Kiewit Sons'`, value: `Peter Kiewit Sons'` },
  { key: `Dick's Sporting Goods`, value: `Dick's Sporting Goods` },
  { key: `PulteGroup`, value: `PulteGroup` },
  { key: `Navistar International Corporation`, value: `Navistar International Corporation` },
  { key: `Thrivent Financial for Lutherans`, value: `Thrivent Financial for Lutherans` },
  { key: `DCP Midstream, LP`, value: `DCP Midstream, LP` },
  { key: `Air Products & Chemicals`, value: `Air Products & Chemicals` },
  { key: `Veritiv Corp`, value: `Veritiv Corp` },
  { key: `AGCO Corporation`, value: `AGCO Corporation` },
  { key: `Genworth Financial`, value: `Genworth Financial` },
  { key: `Univar`, value: `Univar` },
  { key: `News Corporation`, value: `News Corporation` },
  { key: `SpartanNash Company`, value: `SpartanNash Company` },
  { key: `Westlake Chemical Corporation`, value: `Westlake Chemical Corporation` },
  { key: `The Williams Companies`, value: `The Williams Companies` },
  { key: `Lam Research Corporation`, value: `Lam Research Corporation` },
  { key: `Alaska Air Group`, value: `Alaska Air Group` },
  { key: `Jones Lang LaSalle Incorporated`, value: `Jones Lang LaSalle Incorporated` },
  { key: `Anixter International`, value: `Anixter International` },
  { key: `Campbell Soup Company`, value: `Campbell Soup Company` },
  { key: `The Interpublic Group of Companies`, value: `The Interpublic Group of Companies` },
  { key: `Dover Corporation`, value: `Dover Corporation` },
  { key: `Zimmer Biomet Holdings`, value: `Zimmer Biomet Holdings` },
  { key: `Dean Foods Company`, value: `Dean Foods Company` },
  { key: `Foot Locker`, value: `Foot Locker` },
  { key: `Eversource Energy`, value: `Eversource Energy` },
  { key: `Alliance Data Systems Corporation`, value: `Alliance Data Systems Corporation` },
  { key: `Fifth Third Bancorp`, value: `Fifth Third Bancorp` },
  { key: `Quest Diagnostics Incorporated`, value: `Quest Diagnostics Incorporated` },
  { key: `EMCOR Group`, value: `EMCOR Group` },
  { key: `W.R. Berkley Corporation`, value: `W.R. Berkley Corporation` },
  { key: `WESCO International`, value: `WESCO International` },
  { key: `Coty`, value: `Coty` },
  { key: `WEC Energy Group`, value: `WEC Energy Group` },
  { key: `Masco Corporation`, value: `Masco Corporation` },
  { key: `DXC Technology Company`, value: `DXC Technology Company` },
  { key: `Auto-Owners Insurance Group`, value: `Auto-Owners Insurance Group` },
  { key: `The Jones Financial Companies, L.L.L.P.`, value: `The Jones Financial Companies, L.L.L.P.` },
  { key: `Liberty Media Corporation`, value: `Liberty Media Corporation` },
  { key: `Erie Insurance Group`, value: `Erie Insurance Group` },
  { key: `The Hershey Company`, value: `The Hershey Company` },
  { key: `PPL Corporation`, value: `PPL Corporation` },
  { key: `Huntington Ingalls Industries`, value: `Huntington Ingalls Industries` },
  { key: `The Mosaic Company`, value: `The Mosaic Company` },
  { key: `The J.M. Smucker Company`, value: `The J.M. Smucker Company` },
  { key: `Delek US Holdings`, value: `Delek US Holdings` },
  { key: `Newmont Mining Corporation`, value: `Newmont Mining Corporation` },
  { key: `Constellation Brands`, value: `Constellation Brands` },
  { key: `Ryder System`, value: `Ryder System` },
  { key: `National Oilwell Varco`, value: `National Oilwell Varco` },
  { key: `Adobe Systems Incorporated`, value: `Adobe Systems Incorporated` },
  { key: `LifePoint Health`, value: `LifePoint Health` },
  { key: `Tractor Supply Company`, value: `Tractor Supply Company` },
  { key: `Thor Industries`, value: `Thor Industries` },
  { key: `Dana Incorporated`, value: `Dana Incorporated` },
  { key: `Weyerhaeuser Company`, value: `Weyerhaeuser Company` },
  { key: `J.B. Hunt Transport Services`, value: `J.B. Hunt Transport Services` },
  { key: `Darden Restaurants`, value: `Darden Restaurants` },
  { key: `Yum China Holdings`, value: `Yum China Holdings` },
  { key: `The Blackstone Group L.P.`, value: `The Blackstone Group L.P.` },
  { key: `Berry Global Group`, value: `Berry Global Group` },
  { key: `Builders FirstSource`, value: `Builders FirstSource` },
  { key: `Activision Blizzard`, value: `Activision Blizzard` },
  { key: `JetBlue Airways Corporation`, value: `JetBlue Airways Corporation` },
  { key: `Amphenol Corporation`, value: `Amphenol Corporation` },
  { key: `A-Mark Precious Metals`, value: `A-Mark Precious Metals` },
  { key: `Spirit AeroSystems Holdings`, value: `Spirit AeroSystems Holdings` },
  { key: `R.R. Donnelley & Sons Company`, value: `R.R. Donnelley & Sons Company` },
  { key: `Harris Corporation`, value: `Harris Corporation` },
  { key: `Expeditors International of Washington`, value: `Expeditors International of Washington` },
  { key: `Discovery`, value: `Discovery` },
  { key: `Owens-Illinois`, value: `Owens-Illinois` },
  { key: `Sanmina Corporation`, value: `Sanmina Corporation` },
  { key: `KeyCorp`, value: `KeyCorp` },
  { key: `American Financial Group`, value: `American Financial Group` },
  { key: `Oshkosh Corporation`, value: `Oshkosh Corporation` },
  { key: `Rockwell Collins`, value: `Rockwell Collins` },
  { key: `Kindred Healthcare`, value: `Kindred Healthcare` },
  { key: `Insight Enterprises`, value: `Insight Enterprises` },
  { key: `Dr Pepper Snapple Group`, value: `Dr Pepper Snapple Group` },
  { key: `American Tower Corporation`, value: `American Tower Corporation` },
  { key: `Fortive Corporation`, value: `Fortive Corporation` },
  { key: `Ralph Lauren Corporation`, value: `Ralph Lauren Corporation` },
  { key: `HRG Group`, value: `HRG Group` },
  { key: `Ascena Retail Group`, value: `Ascena Retail Group` },
  { key: `United Rentals`, value: `United Rentals` },
  { key: `Casey's General Stores`, value: `Casey's General Stores` },
  { key: `Graybar Electric Company`, value: `Graybar Electric Company` },
  { key: `Avery Dennison Corporation`, value: `Avery Dennison Corporation` },
  { key: `MasTec`, value: `MasTec` },
  { key: `CMS Energy Corporation`, value: `CMS Energy Corporation` },
  { key: `HD Supply Holdings`, value: `HD Supply Holdings` },
  { key: `Raymond James Financial`, value: `Raymond James Financial` },
  { key: `NCR Corporation`, value: `NCR Corporation` },
  { key: `Hanesbrands`, value: `Hanesbrands` },
  { key: `Asbury Automotive Group`, value: `Asbury Automotive Group` },
  { key: `Citizens Financial Group`, value: `Citizens Financial Group` },
  { key: `Packaging Corporation of America`, value: `Packaging Corporation of America` },
  { key: `Alleghany Corporation`, value: `Alleghany Corporation` },
  { key: `Apache Corporation`, value: `Apache Corporation` },
  { key: `Dillard's`, value: `Dillard's` },
  { key: `Assurant`, value: `Assurant` },
  { key: `Franklin Resources`, value: `Franklin Resources` },
  { key: `Owens Corning`, value: `Owens Corning` },
  { key: `Motorola Solutions`, value: `Motorola Solutions` },
  { key: `NVR`, value: `NVR` },
  { key: `Rockwell Automation`, value: `Rockwell Automation` },
  { key: `TreeHouse Foods`, value: `TreeHouse Foods` },
  { key: `Wynn Resorts, Limited`, value: `Wynn Resorts, Limited` },
  { key: `Olin Corporation`, value: `Olin Corporation` },
  { key: `American Axle & Manufacturing Holdings`, value: `American Axle & Manufacturing Holdings` },
  { key: `Old Republic International Corporation`, value: `Old Republic International Corporation` },
  { key: `The Chemours Company`, value: `The Chemours Company` },
  { key: `iHeartMedia`, value: `iHeartMedia` },
  { key: `Ameren Corporation`, value: `Ameren Corporation` },
  { key: `Arthur J. Gallagher & Co.`, value: `Arthur J. Gallagher & Co.` },
  { key: `Celanese Corporation`, value: `Celanese Corporation` },
  { key: `Sealed Air Corporation`, value: `Sealed Air Corporation` },
  { key: `UGI Corporation`, value: `UGI Corporation` },
  { key: `Realogy Holdings Corp.`, value: `Realogy Holdings Corp.` },
  { key: `Burlington Stores`, value: `Burlington Stores` },
  { key: `Regions Financial Corporation`, value: `Regions Financial Corporation` },
  { key: `AK Steel Holding Corporation`, value: `AK Steel Holding Corporation` },
  { key: `Securian Financial Group`, value: `Securian Financial Group` },
  { key: `S&P Global`, value: `S&P Global` },
  { key: `Markel Corporation`, value: `Markel Corporation` },
  { key: `TravelCenters of America LLC`, value: `TravelCenters of America LLC` },
  { key: `Conduent Incorporated`, value: `Conduent Incorporated` },
  { key: `M&T Bank Corporation`, value: `M&T Bank Corporation` },
  { key: `The Clorox Company`, value: `The Clorox Company` },
  { key: `AmTrust Financial Services`, value: `AmTrust Financial Services` },
  { key: `KKR & Co. L.P.`, value: `KKR & Co. L.P.` },
  { key: `Ulta Beauty`, value: `Ulta Beauty` },
  { key: `Yum! Brands`, value: `Yum! Brands` },
  { key: `Regeneron Pharmaceuticals`, value: `Regeneron Pharmaceuticals` },
  { key: `Windstream Holdings`, value: `Windstream Holdings` },
  { key: `Magellan Health`, value: `Magellan Health` },
  { key: `Western & Southern Mutual Holding Company`, value: `Western & Southern Mutual Holding Company` },
  { key: `Intercontinental Exchange`, value: `Intercontinental Exchange` },
  { key: `Ingredion Incorporated`, value: `Ingredion Incorporated` },
  { key: `Wyndham Destinations`, value: `Wyndham Destinations` },
  { key: `Toll Brothers`, value: `Toll Brothers` },
  { key: `Seaboard Corporation`, value: `Seaboard Corporation` },
  { key: `Booz Allen Hamilton Holding Corp.`, value: `Booz Allen Hamilton Holding Corp.` },
  { key: `First American Financial Corporation`, value: `First American Financial Corporation` },
  { key: `Cincinnati Financial Corporation`, value: `Cincinnati Financial Corporation` },
  { key: `Avon Products`, value: `Avon Products` },
  { key: `Northern Trust Corporation`, value: `Northern Trust Corporation` },
  { key: `Fiserv`, value: `Fiserv` },
  { key: `Harley-Davidson`, value: `Harley-Davidson` },
  { key: `Cheniere Energy`, value: `Cheniere Energy` },
  { key: `Patterson Companies`, value: `Patterson Companies` },
  { key: `Peabody Energy Corporation`, value: `Peabody Energy Corporation` },
  { key: `ON Semiconductor Corporation`, value: `ON Semiconductor Corporation` },
  { key: `Simon Property Group`, value: `Simon Property Group` },
  { key: `The Western Union Company`, value: `The Western Union Company` },
  { key: `NetApp`, value: `NetApp` },
  { key: `Polaris Industries`, value: `Polaris Industries` },
  { key: `Pioneer Natural Resources Company`, value: `Pioneer Natural Resources Company` },
  { key: `ABM Industries Incorporated`, value: `ABM Industries Incorporated` },
  { key: `Vistra Energy Corp.`, value: `Vistra Energy Corp.` },
  { key: `Cintas Corporation`, value: `Cintas Corporation` },
  { key: `Hess Corporation`, value: `Hess Corporation` },
  { key: `Host Hotels & Resorts`, value: `Host Hotels & Resorts` },
  { key: `Kelly Services`, value: `Kelly Services` },
  { key: `Genesis Healthcare`, value: `Genesis Healthcare` },
  { key: `The Michaels Companies`, value: `The Michaels Companies` },
  { key: `Advanced Micro Devices`, value: `Advanced Micro Devices` },
  { key: `Zoetis`, value: `Zoetis` },
  { key: `Williams-Sonoma`, value: `Williams-Sonoma` },
  { key: `Fortune Brands Home & Security`, value: `Fortune Brands Home & Security` },
  { key: `Big Lots`, value: `Big Lots` },
  { key: `Robert Half International`, value: `Robert Half International` },
  { key: `Post Holdings`, value: `Post Holdings` },
  { key: `Hasbro`, value: `Hasbro` },
  { key: `The Hanover Insurance Group`, value: `The Hanover Insurance Group` },
  { key: `Navient Corporation`, value: `Navient Corporation` },
  { key: `Intuit`, value: `Intuit` },
  { key: `Domtar Corporation`, value: `Domtar Corporation` },
  { key: `Marathon Oil Corporation`, value: `Marathon Oil Corporation` },
  { key: `Cerner Corporation`, value: `Cerner Corporation` },
  { key: `Analog Devices`, value: `Analog Devices` },
  { key: `Telephone and Data Systems`, value: `Telephone and Data Systems` },
  { key: `Essendant`, value: `Essendant` },
  { key: `Sonoco Products Company`, value: `Sonoco Products Company` },
  { key: `Juniper Networks`, value: `Juniper Networks` },
  { key: `Commercial Metals Company`, value: `Commercial Metals Company` },
  { key: `CSRA`, value: `CSRA` },
  { key: `Under Armour`, value: `Under Armour` },
  { key: `RPM International`, value: `RPM International` },
  { key: `Total System Services`, value: `Total System Services` },
  { key: `Levi Strauss & Co.`, value: `Levi Strauss & Co.` },
  { key: `Brunswick Corporation`, value: `Brunswick Corporation` },
  { key: `YRC Worldwide`, value: `YRC Worldwide` },
  { key: `Mattel`, value: `Mattel` },
  { key: `Factory Mutual Insurance Company`, value: `Factory Mutual Insurance Company` },
  { key: `NiSource`, value: `NiSource` },
  { key: `Caesars Entertainment Corporation`, value: `Caesars Entertainment Corporation` },
  { key: `Electronic Arts`, value: `Electronic Arts` },
  { key: `Dynegy`, value: `Dynegy` },
  { key: `McCormick & Companyorporated`, value: `McCormick & Companyorporated` },
  { key: `T. Rowe Price Group`, value: `T. Rowe Price Group` },
  { key: `Orbital ATK`, value: `Orbital ATK` },
  { key: `Tutor Perini Corporation`, value: `Tutor Perini Corporation` },
  { key: `Brookdale Senior Living`, value: `Brookdale Senior Living` },
  { key: `Huntington Bancshares Incorporated`, value: `Huntington Bancshares Incorporated` },
  { key: `Wayfair`, value: `Wayfair` },
  { key: `Rush Enterprises`, value: `Rush Enterprises` },
  { key: `Xylem`, value: `Xylem` },
  { key: `Neiman Marcus Group LTD LLC`, value: `Neiman Marcus Group LTD LLC` },
  { key: `Hyatt Hotels Corporation`, value: `Hyatt Hotels Corporation` },
  { key: `Sprouts Farmers Market`, value: `Sprouts Farmers Market` },
  { key: `Diebold Nixdorforporated`, value: `Diebold Nixdorforporated` },
  { key: `Roper Technologies`, value: `Roper Technologies` },
  { key: `Smart & Final Stores`, value: `Smart & Final Stores` },
  { key: `CommScope Holding Company`, value: `CommScope Holding Company` },
  { key: `Tapestry`, value: `Tapestry` },
  { key: `Diplomat Pharmacy`, value: `Diplomat Pharmacy` },
  { key: `Chipotle Mexican Grill`, value: `Chipotle Mexican Grill` },
  { key: `Agilent Technologies`, value: `Agilent Technologies` },
  { key: `Science Applications International Corporation`, value: `Science Applications International Corporation` },
  { key: `MDU Resources Group`, value: `MDU Resources Group` },
  { key: `Select Medical Holdings Corporation`, value: `Select Medical Holdings Corporation` },
  { key: `Boise Cascade Company`, value: `Boise Cascade Company` },
  { key: `National General Holdings Corp.`, value: `National General Holdings Corp.` },
  { key: `SCANA Corporation`, value: `SCANA Corporation` },
  { key: `Graphic Packaging Holding Company`, value: `Graphic Packaging Holding Company` },
  { key: `Fastenal Company`, value: `Fastenal Company` },
  { key: `Schneider National`, value: `Schneider National` },
  { key: `Laureate Education`, value: `Laureate Education` },
  { key: `Beacon Roofing Supply`, value: `Beacon Roofing Supply` },
  { key: `KB Home`, value: `KB Home` },
  { key: `Equinix`, value: `Equinix` },
  { key: `Terex Corporation`, value: `Terex Corporation` },
  { key: `Crown Castle International Corp.`, value: `Crown Castle International Corp.` },
  { key: `CACI International`, value: `CACI International` },
  { key: `Watsco`, value: `Watsco` },
  { key: `Coca-Cola Bottling Co. Consolidated`, value: `Coca-Cola Bottling Co. Consolidated` },
  { key: `Welltower`, value: `Welltower` },
  { key: `ADT`, value: `ADT` },
  { key: `AMETEK`, value: `AMETEK` },
  { key: `CNO Financial Group`, value: `CNO Financial Group` },
  { key: `Camping World Holdings`, value: `Camping World Holdings` },
  { key: `LPL Financial Holdings`, value: `LPL Financial Holdings` },
  { key: `Noble Energy`, value: `Noble Energy` },
  { key: `Bloomin' Brands`, value: `Bloomin' Brands` },
  { key: `Moody's Corporation`, value: `Moody's Corporation` },
  { key: `Symantec Corporation`, value: `Symantec Corporation` },
  { key: `Amkor Technology`, value: `Amkor Technology` },
  { key: `Skechers U.S.A.`, value: `Skechers U.S.A.` },
  { key: `KBR`, value: `KBR` },
  { key: `Tiffany & Co.`, value: `Tiffany & Co.` },
  { key: `Torchmark Corporation`, value: `Torchmark Corporation` },
  { key: `Broadridge Financial Solutions`, value: `Broadridge Financial Solutions` },
  { key: `Quad/Graphics`, value: `Quad/Graphics` },
  { key: `CF Industries Holdings`, value: `CF Industries Holdings` },
  { key: `Carlisle Companies Incorporated`, value: `Carlisle Companies Incorporated` },
  { key: `Silgan Holdings`, value: `Silgan Holdings` },
  { key: `Bemis Company`, value: `Bemis Company` },
  { key: `CA`, value: `CA` },
  { key: `Hub Group`, value: `Hub Group` },
  { key: `Worldpay`, value: `Worldpay` },
  { key: `Ingles Marketsorporated`, value: `Ingles Marketsorporated` },
  { key: `Snap-on Incorporated`, value: `Snap-on Incorporated` },
  { key: `DENTSPLY SIRONA`, value: `DENTSPLY SIRONA` },
  { key: `Calumet Specialty Products Partners, L.P.`, value: `Calumet Specialty Products Partners, L.P.` },
  { key: `Global Payments`, value: `Global Payments` },
  { key: `Encompass Health Corporation`, value: `Encompass Health Corporation` },
  { key: `Martin Marietta Materials`, value: `Martin Marietta Materials` },
  { key: `Nasdaq`, value: `Nasdaq` },
  { key: `Leggett & Plattorporated`, value: `Leggett & Plattorporated` },
  { key: `Universal Forest Products`, value: `Universal Forest Products` },
  { key: `Sally Beauty Holdings`, value: `Sally Beauty Holdings` },
  { key: `Flowers Foods`, value: `Flowers Foods` },
  { key: `Barnes & Noble`, value: `Barnes & Noble` },
  { key: `American Equity Investment Life Holding Company`, value: `American Equity Investment Life Holding Company` },
  { key: `Vulcan Materials Company`, value: `Vulcan Materials Company` },
  { key: `Taylor Morrison Home Corporation`, value: `Taylor Morrison Home Corporation` },
  { key: `Westinghouse Air Brake Technologies Corporation`, value: `Westinghouse Air Brake Technologies Corporation` },
  { key: `Crestwood Equity Partners LP`, value: `Crestwood Equity Partners LP` },
  { key: `Iron Mountain Incorporated`, value: `Iron Mountain Incorporated` },
  { key: `Lennox International`, value: `Lennox International` },
  { key: `General Cable Corporation`, value: `General Cable Corporation` },
  { key: `American Eagle Outfitters`, value: `American Eagle Outfitters` },
  { key: `Church & Dwight Co.`, value: `Church & Dwight Co.` },
  { key: `Platform Specialty Products Corporation`, value: `Platform Specialty Products Corporation` },
  { key: `JELD-WEN Holding`, value: `JELD-WEN Holding` },
  { key: `OneMain Holdings`, value: `OneMain Holdings` },
  { key: `Colfax Corporation`, value: `Colfax Corporation` },
  { key: `Zebra Technologies Corporation`, value: `Zebra Technologies Corporation` },
  { key: `The Andersons`, value: `The Andersons` },
  { key: `TD Ameritrade Holding Corporation`, value: `TD Ameritrade Holding Corporation` },
  { key: `The Carlyle Group L.P.`, value: `The Carlyle Group L.P.` },
  { key: `Hubbell Incorporated`, value: `Hubbell Incorporated` },
  { key: `Trinity Industries`, value: `Trinity Industries` },
  { key: `Darling Ingredients`, value: `Darling Ingredients` },
  { key: `Flowserve Corporation`, value: `Flowserve Corporation` },
  { key: `Antero Resources Corporation`, value: `Antero Resources Corporation` },
  { key: `Skyworks Solutions`, value: `Skyworks Solutions` },
  { key: `Landstar System`, value: `Landstar System` },
  { key: `Buckeye Partners, L.P.`, value: `Buckeye Partners, L.P.` },
  { key: `MRC Global`, value: `MRC Global` },
  { key: `CME Group`, value: `CME Group` },
  { key: `Greif`, value: `Greif` },
  { key: `Nexeo Solutions`, value: `Nexeo Solutions` },
  { key: `Cooper-Standard Holdings`, value: `Cooper-Standard Holdings` },
  { key: `Urban Outfitters`, value: `Urban Outfitters` },
  { key: `LSC Communications`, value: `LSC Communications` },
  { key: `Sabre Corporation`, value: `Sabre Corporation` },
  { key: `Green Plains`, value: `Green Plains` },
  { key: `Hexion`, value: `Hexion` },
  { key: `Stericycle`, value: `Stericycle` },
  { key: `Warner Music Group Corp.`, value: `Warner Music Group Corp.` },
  { key: `Ventas`, value: `Ventas` },
  { key: `ScanSource`, value: `ScanSource` },
  { key: `Pinnacle West Capital Corporation`, value: `Pinnacle West Capital Corporation` },
  { key: `Scripps Networks Interactive`, value: `Scripps Networks Interactive` },
  { key: `Alexion Pharmaceuticals`, value: `Alexion Pharmaceuticals` },
  { key: `Pitney Bowes`, value: `Pitney Bowes` },
  { key: `CIT Group`, value: `CIT Group` },
  { key: `COUNTRY Financial`, value: `COUNTRY Financial` },
  { key: `CMFG Life Insurance Company`, value: `CMFG Life Insurance Company` },
  { key: `Triumph Group`, value: `Triumph Group` },
  { key: `TransDigm Group Incorporated`, value: `TransDigm Group Incorporated` },
  { key: `Allegheny Technologies Incorporated`, value: `Allegheny Technologies Incorporated` },
  { key: `Resolute Forest Products`, value: `Resolute Forest Products` },
  { key: `Acuity Brands`, value: `Acuity Brands` },
  { key: `Abercrombie & Fitch Co.`, value: `Abercrombie & Fitch Co.` },
  { key: `KLA-Tencor Corporation`, value: `KLA-Tencor Corporation` },
  { key: `Weis Markets`, value: `Weis Markets` },
  { key: `Puget Energy`, value: `Puget Energy` },
  { key: `MEDNAX`, value: `MEDNAX` },
  { key: `KAR Auction Services`, value: `KAR Auction Services` },
  { key: `PolyOne Corporation`, value: `PolyOne Corporation` },
  { key: `FMC Corporation`, value: `FMC Corporation` },
  { key: `Edwards Lifesciences Corporation`, value: `Edwards Lifesciences Corporation` },
  { key: `Microchip Technology Incorporated`, value: `Microchip Technology Incorporated` },
  { key: `Amerco`, value: `Amerco` },
  { key: `Mercury General Corporation`, value: `Mercury General Corporation` },
  { key: `American National Insurance Company`, value: `American National Insurance Company` },
  { key: `Carter's`, value: `Carter's` },
  { key: `International Flavors & Fragrances`, value: `International Flavors & Fragrances` },
  { key: `Aaron's`, value: `Aaron's` },
  { key: `Alliant Energy Corporation`, value: `Alliant Energy Corporation` },
  { key: `EQT Corporation`, value: `EQT Corporation` },
  { key: `Monster Beverage Corporation`, value: `Monster Beverage Corporation` },
  { key: `BMC Stock Holdings`, value: `BMC Stock Holdings` },
  { key: `Ryerson Holding Corporation`, value: `Ryerson Holding Corporation` },
  { key: `Equifax`, value: `Equifax` },
  { key: `Regal Beloit Corporation`, value: `Regal Beloit Corporation` },
  { key: `Old Dominion Freight Line`, value: `Old Dominion Freight Line` },
  { key: `American Water Works Company`, value: `American Water Works Company` },
  { key: `BGC Partners`, value: `BGC Partners` },
  { key: `The Brink's Company`, value: `The Brink's Company` },
  { key: `Meritor`, value: `Meritor` },
  { key: `Sentry Insurance Group`, value: `Sentry Insurance Group` },
  { key: `Sanderson Farms`, value: `Sanderson Farms` },
  { key: `KapStone Paper and Packaging Corporation`, value: `KapStone Paper and Packaging Corporation` },
  { key: `Gartner`, value: `Gartner` },
  { key: `IAC/InterActiveCorp`, value: `IAC/InterActiveCorp` },
  { key: `Tailored Brands`, value: `Tailored Brands` },
  { key: `WABCO Holdings`, value: `WABCO Holdings` },
  { key: `Insperity`, value: `Insperity` },
  { key: `Comerica Incorporated`, value: `Comerica Incorporated` },
  { key: `TriNet Group`, value: `TriNet Group` },
  { key: `Avaya Holdings Corp.`, value: `Avaya Holdings Corp.` },
  { key: `Ashland Global Holdings`, value: `Ashland Global Holdings` },
  { key: `Meritage Homes Corporation`, value: `Meritage Homes Corporation` },
  { key: `SkyWest`, value: `SkyWest` },
  { key: `USG Corporation`, value: `USG Corporation` },
  { key: `Southwestern Energy Company`, value: `Southwestern Energy Company` },
  { key: `Keysight Technologies`, value: `Keysight Technologies` },
  { key: `Regal Entertainment Group`, value: `Regal Entertainment Group` },
  { key: `Mutual of America Life Insurance Company`, value: `Mutual of America Life Insurance Company` },
  { key: `Paychex`, value: `Paychex` },
  { key: `Brinker International`, value: `Brinker International` },
  { key: `Penn National Gaming`, value: `Penn National Gaming` },
  { key: `Gannett Co.`, value: `Gannett Co.` },
  { key: `Visteon Corporation`, value: `Visteon Corporation` },
  { key: `Pinnacle Foods`, value: `Pinnacle Foods` },
  { key: `Intuitive Surgical`, value: `Intuitive Surgical` },
  { key: `Continental Resources`, value: `Continental Resources` },
  { key: `Service Corporation International`, value: `Service Corporation International` },
  { key: `Scientific Games Corporation`, value: `Scientific Games Corporation` },
  { key: `Albemarle Corporation`, value: `Albemarle Corporation` },
  { key: `Atmos Energy Corporation`, value: `Atmos Energy Corporation` },
  { key: `Hologic`, value: `Hologic` },
  { key: `H&R Block`, value: `H&R Block` },
  { key: `Qorvo`, value: `Qorvo` },
  { key: `Steelcase`, value: `Steelcase` },
  { key: `Univision Communications`, value: `Univision Communications` },
  { key: `Worthington Industries`, value: `Worthington Industries` },
  { key: `The Timken Company`, value: `The Timken Company` },
  { key: `A.O. Smith Corporation`, value: `A.O. Smith Corporation` },
  { key: `PriceSmart`, value: `PriceSmart` },
  { key: `Stifel Financial Corp.`, value: `Stifel Financial Corp.` },
  { key: `Brown-Forman Corporation`, value: `Brown-Forman Corporation` },
  { key: `Cinemark Holdings`, value: `Cinemark Holdings` },
  { key: `Granite Construction Incorporated`, value: `Granite Construction Incorporated` },
  { key: `Dycom Industries`, value: `Dycom Industries` },
  { key: `Clean Harbors`, value: `Clean Harbors` },
  { key: `First Solar`, value: `First Solar` },
  { key: `The Scotts Miracle-Gro Company`, value: `The Scotts Miracle-Gro Company` },
  { key: `Cracker Barrel Old Country Store`, value: `Cracker Barrel Old Country Store` },
  { key: `Triple-S Management Corporation`, value: `Triple-S Management Corporation` },
  { key: `First Republic Bank`, value: `First Republic Bank` },
  { key: `ServiceMaster Global Holdings`, value: `ServiceMaster Global Holdings` },
  { key: `PC Connection`, value: `PC Connection` },
  { key: `Genesco`, value: `Genesco` },
  { key: `Medical Mutual of Ohio`, value: `Medical Mutual of Ohio` },
  { key: `MSC Industrial Direct Co.`, value: `MSC Industrial Direct Co.` },
  { key: `Legg Mason`, value: `Legg Mason` },
  { key: `Hyster-Yale Materials Handling`, value: `Hyster-Yale Materials Handling` },
  { key: `Apollo Global Management, LLC`, value: `Apollo Global Management, LLC` },
  { key: `Citrix Systems`, value: `Citrix Systems` },
  { key: `Acadia Healthcare Company`, value: `Acadia Healthcare Company` },
  { key: `Varian Medical Systems`, value: `Varian Medical Systems` },
  { key: `Groupon`, value: `Groupon` },
  { key: `Aleris Corporation`, value: `Aleris Corporation` },
  { key: `Sprague Resources LP`, value: `Sprague Resources LP` },
  { key: `Cooper Tire & Rubber Company`, value: `Cooper Tire & Rubber Company` },
  { key: `The Hain Celestial Group`, value: `The Hain Celestial Group` },
  { key: `Penn Mutual Life Insurance Co.`, value: `Penn Mutual Life Insurance Co.` },
  { key: `Colony NorthStar`, value: `Colony NorthStar` },
  { key: `ArcBest Corporation`, value: `ArcBest Corporation` },
  { key: `Presidio`, value: `Presidio` },
  { key: `TRI Pointe Group`, value: `TRI Pointe Group` },
  { key: `Annaly Capital Management`, value: `Annaly Capital Management` },
  { key: `G-III Apparel Group, Ltd.`, value: `G-III Apparel Group, Ltd.` },
  { key: `AMC Networks`, value: `AMC Networks` },
  { key: `Enable Midstream Partners, LP`, value: `Enable Midstream Partners, LP` },
  { key: `Ciena Corporation`, value: `Ciena Corporation` },
  { key: `DSW`, value: `DSW` },
  { key: `Convergys Corporation`, value: `Convergys Corporation` },
  { key: `Park Hotels & Resorts`, value: `Park Hotels & Resorts` },
  { key: `Pool Corporation`, value: `Pool Corporation` },
  { key: `Fossil Group`, value: `Fossil Group` },
  { key: `Domino's Pizza`, value: `Domino's Pizza` },
  { key: `Crane Co.`, value: `Crane Co.` },
  { key: `Caleres`, value: `Caleres` },
  { key: `Tempur Sealy International`, value: `Tempur Sealy International` },
  { key: `Tetra Tech`, value: `Tetra Tech` },
  { key: `Illumina`, value: `Illumina` },
  { key: `Valmont Industries`, value: `Valmont Industries` },
  { key: `Hill-Rom Holdings`, value: `Hill-Rom Holdings` },
  { key: `Unisys Corporation`, value: `Unisys Corporation` },
  { key: `Zions Bancorporation`, value: `Zions Bancorporation` },
  { key: `Sinclair Broadcast Group`, value: `Sinclair Broadcast Group` },
  { key: `Louisiana-Pacific Corporation`, value: `Louisiana-Pacific Corporation` },
  { key: `Mettler-Toledo International`, value: `Mettler-Toledo International` },
  { key: `Synopsys`, value: `Synopsys` },
  { key: `Kemper Corporation`, value: `Kemper Corporation` },
  { key: `Cabot Corporation`, value: `Cabot Corporation` },
  { key: `Great Plains Energy Incorporated`, value: `Great Plains Energy Incorporated` },
  { key: `Rent-A-Center`, value: `Rent-A-Center` },
  { key: `Hawaiian Holdings`, value: `Hawaiian Holdings` },
  { key: `Revlon`, value: `Revlon` },
  { key: `Syneos Health`, value: `Syneos Health` },
  { key: `Public Storage`, value: `Public Storage` },
  { key: `TTM Technologies`, value: `TTM Technologies` },
  { key: `Vectren Corporation`, value: `Vectren Corporation` },
  { key: `Trimble`, value: `Trimble` },
  { key: `NOW`, value: `NOW` },
  { key: `Spirit Airlines`, value: `Spirit Airlines` },
  { key: `ASGN Incorporated`, value: `ASGN Incorporated` },
  { key: `Lincoln Electric Holdings`, value: `Lincoln Electric Holdings` },
  { key: `Prologis`, value: `Prologis` },
  { key: `Range Resources Corporation`, value: `Range Resources Corporation` },
  { key: `Teledyne Technologies Incorporated`, value: `Teledyne Technologies Incorporated` },
  { key: `Vishay Intertechnology`, value: `Vishay Intertechnology` },
  { key: `Boston Properties`, value: `Boston Properties` },
  { key: `Applied Industrial Technologies`, value: `Applied Industrial Technologies` },
  { key: `Graham Holdings Company`, value: `Graham Holdings Company` },
  { key: `Amica Mutual Insurance Co.`, value: `Amica Mutual Insurance Co.` },
  { key: `Concho Resources`, value: `Concho Resources` },
  { key: `ITT`, value: `ITT` },
  { key: `Kansas City Southern`, value: `Kansas City Southern` },
  { key: `M.D.C. Holdings`, value: `M.D.C. Holdings` },
  { key: `Evergy`, value: `Evergy` },
  { key: `Pinnacle Entertainment`, value: `Pinnacle Entertainment` },
  { key: `Hawaiian Electric Industries`, value: `Hawaiian Electric Industries` },
  { key: `TEGNA`, value: `TEGNA` },
  { key: `Southwest Gas Holdings`, value: `Southwest Gas Holdings` },
  { key: `Vista Outdoor`, value: `Vista Outdoor` },
  { key: `The Bon-Ton Stores`, value: `The Bon-Ton Stores` },
  { key: `Super Micro Computer`, value: `Super Micro Computer` },
  { key: `Plexus Corp.`, value: `Plexus Corp.` },
  { key: `TrueBlue`, value: `TrueBlue` },
  { key: `Magellan Midstream Partners, L.P.`, value: `Magellan Midstream Partners, L.P.` },
  { key: `The Toro Company`, value: `The Toro Company` },
  { key: `Akamai Technologies`, value: `Akamai Technologies` },
  { key: `Moog`, value: `Moog` },
  { key: `Vertex Pharmaceuticals Incorporated`, value: `Vertex Pharmaceuticals Incorporated` },
  { key: `Equity Residential`, value: `Equity Residential` },
  { key: `Selective Insurance Group`, value: `Selective Insurance Group` },
  { key: `AptarGroup`, value: `AptarGroup` },
  { key: `Benchmark Electronics`, value: `Benchmark Electronics` },
  { key: `Columbia Sportswear Company`, value: `Columbia Sportswear Company` },
  { key: `A. Schulman`, value: `A. Schulman` },
  { key: `Verso Corporation`, value: `Verso Corporation` },
  { key: `Digital Realty Trust`, value: `Digital Realty Trust` },
  { key: `GNC Holdings`, value: `GNC Holdings` },
  { key: `E*Trade Financial Corporation`, value: `E*Trade Financial Corporation` },
  { key: `Hovnanian Enterprises`, value: `Hovnanian Enterprises` },
  { key: `MAXIMUS`, value: `MAXIMUS` },
  { key: `Twitter`, value: `Twitter` },
  { key: `Par Pacific Holdings`, value: `Par Pacific Holdings` },
  { key: `PAREXEL International Corporation`, value: `PAREXEL International Corporation` },
  { key: `RH`, value: `RH` },
  { key: `Nexstar Media Group`, value: `Nexstar Media Group` },
  { key: `Knight-Swift Transportation Holdings`, value: `Knight-Swift Transportation Holdings` },
  { key: `Red Hat`, value: `Red Hat` },
  { key: `Belden`, value: `Belden` },
  { key: `Boyd Gaming Corporation`, value: `Boyd Gaming Corporation` },
  { key: `Primoris Services Corporation`, value: `Primoris Services Corporation` },
  { key: `Gardner Denver Holdings`, value: `Gardner Denver Holdings` },
  { key: `Donaldson Company`, value: `Donaldson Company` },
  { key: `Party City Holdco`, value: `Party City Holdco` },
  { key: `J.Crew Group`, value: `J.Crew Group` },
  { key: `EnerSys`, value: `EnerSys` },
  { key: `Guess?`, value: `Guess?` },
  { key: `Patterson-UTI Energy`, value: `Patterson-UTI Energy` },
  { key: `WGL Holdings`, value: `WGL Holdings` },
  { key: `Wolverine World Wide`, value: `Wolverine World Wide` },
  { key: `Xilinx`, value: `Xilinx` },
  { key: `Vornado Realty Trust`, value: `Vornado Realty Trust` },
  { key: `The Middleby Corporation`, value: `The Middleby Corporation` },
  { key: `MPM Holdings`, value: `MPM Holdings` },
  { key: `Cleveland-Cliffs`, value: `Cleveland-Cliffs` },
  { key: `GGP`, value: `GGP` },
  { key: `Cypress Semiconductor Corporation`, value: `Cypress Semiconductor Corporation` },
  { key: `Arch Coal`, value: `Arch Coal` },
  { key: `GMS`, value: `GMS` },
  { key: `Waters Corporation`, value: `Waters Corporation` },
  { key: `H.B. Fuller Company`, value: `H.B. Fuller Company` },
  { key: `Affiliated Managers Group`, value: `Affiliated Managers Group` },
  { key: `PerkinElmer`, value: `PerkinElmer` },
  { key: `Edgewell Personal Care Company`, value: `Edgewell Personal Care Company` },
  { key: `Maxim Integrated Products`, value: `Maxim Integrated Products` },
  { key: `Knights of Columbus`, value: `Knights of Columbus` },
  { key: `IDEX Corporation`, value: `IDEX Corporation` },
  { key: `DST Systems`, value: `DST Systems` },
  { key: `Chico's FAS`, value: `Chico's FAS` },
  { key: `Nu Skin Enterprises`, value: `Nu Skin Enterprises` },
  { key: `Herman Miller`, value: `Herman Miller` },
  { key: `NLV Financial Corporation`, value: `NLV Financial Corporation` },
  { key: `Curtiss-Wright Corporation`, value: `Curtiss-Wright Corporation` },
  { key: `New Jersey Resources Corporation`, value: `New Jersey Resources Corporation` },
  { key: `REV Group`, value: `REV Group` },
  { key: `Mueller Industries`, value: `Mueller Industries` },
  { key: `The GEO Group`, value: `The GEO Group` },
  { key: `Allison Transmission Holdings`, value: `Allison Transmission Holdings` },
  { key: `OGE Energy Corp.`, value: `OGE Energy Corp.` },
  { key: `The Cheesecake Factory Incorporated`, value: `The Cheesecake Factory Incorporated` },
  { key: `PRA Health Sciences`, value: `PRA Health Sciences` },
  { key: `Tupperware Brands Corporation`, value: `Tupperware Brands Corporation` },
  { key: `Euronet Worldwide`, value: `Euronet Worldwide` },
  { key: `FLEETCOR Technologies`, value: `FLEETCOR Technologies` },
  { key: `Nationstar Mortgage Holdings`, value: `Nationstar Mortgage Holdings` },
  { key: `GoDaddy`, value: `GoDaddy` },
  { key: `Blackhawk Network Holdings`, value: `Blackhawk Network Holdings` },
  { key: `Cboe Global Markets`, value: `Cboe Global Markets` },
  { key: `Snyder's-Lance`, value: `Snyder's-Lance` },
  { key: `Murphy Oil Corporation`, value: `Murphy Oil Corporation` },
  { key: `CDK Global`, value: `CDK Global` },
  { key: `Texas Roadhouse`, value: `Texas Roadhouse` },
  { key: `Kirby Corporation`, value: `Kirby Corporation` },
  { key: `Square`, value: `Square` },
  { key: `Genesee & Wyoming`, value: `Genesee & Wyoming` },
  { key: `Zayo Group Holdings`, value: `Zayo Group Holdings` },
  { key: `NewMarket Corporation`, value: `NewMarket Corporation` },
  { key: `99 Cents Only Stores LLC`, value: `99 Cents Only Stores LLC` },
  { key: `PCM`, value: `PCM` },
  { key: `Federated Mutual Insurance Company`, value: `Federated Mutual Insurance Company` },
  { key: `HNI Corporation`, value: `HNI Corporation` },
  { key: `Hospitality Properties Trust`, value: `Hospitality Properties Trust` },
  { key: `The Greenbrier Companies`, value: `The Greenbrier Companies` },
  { key: `Bio-Rad Laboratories`, value: `Bio-Rad Laboratories` },
  { key: `AvalonBay Communities`, value: `AvalonBay Communities` },
  { key: `Renewable Energy Group`, value: `Renewable Energy Group` },
  { key: `Atlas Air Worldwide Holdings`, value: `Atlas Air Worldwide Holdings` },
  { key: `Teradata Corporation`, value: `Teradata Corporation` },
  { key: `LCI Industries`, value: `LCI Industries` },
  { key: `Teleflex Incorporated`, value: `Teleflex Incorporated` },
  { key: `Verisk Analytics`, value: `Verisk Analytics` },
  { key: `Popular`, value: `Popular` },
  { key: `Workday`, value: `Workday` },
  { key: `The Cooper Companies`, value: `The Cooper Companies` },
  { key: `Express`, value: `Express` },
  { key: `Teradyne`, value: `Teradyne` },
  { key: `Werner Enterprises`, value: `Werner Enterprises` },
  { key: `Oaktree Capital Group, LLC`, value: `Oaktree Capital Group, LLC` },
  { key: `Woodward`, value: `Woodward` },
  { key: `F5 Networks`, value: `F5 Networks` },
  { key: `Valvoline`, value: `Valvoline` },
  { key: `Roadrunner Transportation Systems`, value: `Roadrunner Transportation Systems` },
  { key: `SemGroup Corporation`, value: `SemGroup Corporation` },
  { key: `Catalent`, value: `Catalent` },
  { key: `Quorum Health Corporation`, value: `Quorum Health Corporation` },
  { key: `Universal Corporation`, value: `Universal Corporation` },
  { key: `Nordson Corporation`, value: `Nordson Corporation` },
  { key: `ResMed`, value: `ResMed` },
  { key: `Tower International`, value: `Tower International` },
  { key: `Fred's`, value: `Fred's` },
  { key: `Foundation Building Materials`, value: `Foundation Building Materials` },
  { key: `Kennametal`, value: `Kennametal` },
  { key: `Autodesk`, value: `Autodesk` },
  { key: `Ply Gem Holdings`, value: `Ply Gem Holdings` },
  { key: `Central Garden & Pet Company`, value: `Central Garden & Pet Company` },
  { key: `Matson`, value: `Matson` },
  { key: `EchoStar Corporation`, value: `EchoStar Corporation` },
  { key: `Genesis Energy, L.P.`, value: `Genesis Energy, L.P.` },
  { key: `SVB Financial Group`, value: `SVB Financial Group` },
  { key: `Itron`, value: `Itron` },
  { key: `Portland General Electric Company`, value: `Portland General Electric Company` },
  { key: `California Resources Corporation`, value: `California Resources Corporation` },
  { key: `Esterline Technologies Corporation`, value: `Esterline Technologies Corporation` },
  { key: `Delta Tucker Holdings`, value: `Delta Tucker Holdings` },
  { key: `AMN Healthcare Services`, value: `AMN Healthcare Services` },
  { key: `Griffon Corp.`, value: `Griffon Corp.` },
  { key: `Valhi`, value: `Valhi` },
  { key: `Hexcel Corporation`, value: `Hexcel Corporation` },
  { key: `IDEXX Laboratories`, value: `IDEXX Laboratories` },
  { key: `Deluxe Corporation`, value: `Deluxe Corporation` },
  { key: `M/I Homes`, value: `M/I Homes` },
  { key: `Kraton Corporation`, value: `Kraton Corporation` },
  { key: `Stewart Information Services Corporation`, value: `Stewart Information Services Corporation` },
  { key: `Marriott Vacations Worldwide Corporation`, value: `Marriott Vacations Worldwide Corporation` },
  { key: `SPX FLOW`, value: `SPX FLOW` },
  { key: `ACCO Brands Corporation`, value: `ACCO Brands Corporation` },
  { key: `Echo Global Logistics`, value: `Echo Global Logistics` },
  { key: `Cadence Design Systems`, value: `Cadence Design Systems` },
  { key: `Nuance Communications`, value: `Nuance Communications` },
  { key: `The Finish Line`, value: `The Finish Line` },
  { key: `TransUnion`, value: `TransUnion` },
  { key: `ServiceNow`, value: `ServiceNow` },
  { key: `Summit Materials`, value: `Summit Materials` },
  { key: `Engility Holdings`, value: `Engility Holdings` },
  { key: `Ferrellgas Partners, L.P.`, value: `Ferrellgas Partners, L.P.` },
  { key: `Interactive Brokers Group`, value: `Interactive Brokers Group` },
  { key: `Stepan Company`, value: `Stepan Company` },
  { key: `Oceaneering International`, value: `Oceaneering International` },
  { key: `Cimarex Energy Co.`, value: `Cimarex Energy Co.` },
  { key: `Rexnord Corporation`, value: `Rexnord Corporation` },
  { key: `Beazer Homes USA`, value: `Beazer Homes USA` },
  { key: `MKS Instruments`, value: `MKS Instruments` },
  { key: `Vail Resorts`, value: `Vail Resorts` },
  { key: `Ohio National Mutual Holdings`, value: `Ohio National Mutual Holdings` },
  { key: `TopBuild Corp.`, value: `TopBuild Corp.` },
  { key: `Brown & Brown`, value: `Brown & Brown` },
  { key: `Aerojet Rocketdyne Holdings`, value: `Aerojet Rocketdyne Holdings` },
  { key: `Barnes & Noble Education`, value: `Barnes & Noble Education` },
  { key: `Superior Energy Services`, value: `Superior Energy Services` },
  { key: `VeriFone Systems`, value: `VeriFone Systems` },
  { key: `The Children's Place`, value: `The Children's Place` },
  { key: `Tribune Media Company`, value: `Tribune Media Company` },
  { key: `Healthcare Services Group`, value: `Healthcare Services Group` },
  { key: `SiteOne Landscape Supply`, value: `SiteOne Landscape Supply` },
  { key: `Charles River Laboratories International`, value: `Charles River Laboratories International` },
  { key: `CoreLogic`, value: `CoreLogic` },
  { key: `The Ensign Group`, value: `The Ensign Group` },
  { key: `HCP`, value: `HCP` },
];

export const bayTechCompanies = [
  { key: `3scale`, value: `3scale` },
  { key: `8tracks`, value: `8tracks` },
  { key: `10 by 10`, value: `10 by 10` },
  { key: `15Five`, value: `15Five` },
  { key: `21Tech`, value: `21Tech` },
  { key: `23andMe`, value: `23andMe` },
  { key: `80Twenty`, value: `80Twenty` },
  { key: `99designs`, value: `99designs` },
  { key: `140 Proof`, value: `140 Proof` },
  { key: `2600Hz`, value: `2600Hz` },
  { key: `Abl Schools`, value: `Abl Schools` },
  { key: `Abstract`, value: `Abstract` },
  { key: `Accela`, value: `Accela` },
  { key: `Accenture`, value: `Accenture` },
  { key: `Addepar`, value: `Addepar` },
  { key: `Adecco Group`, value: `Adecco Group` },
  { key: `Admitsee`, value: `Admitsee` },
  { key: `Adobe`, value: `Adobe` },
  { key: `AdRoll`, value: `AdRoll` },
  { key: `AdStage`, value: `AdStage` },
  { key: `Advent Software`, value: `Advent Software` },
  { key: `Adyen`, value: `Adyen` },
  { key: `Aerotek`, value: `Aerotek` },
  { key: `AEye`, value: `AEye` },
  { key: `Affinity`, value: `Affinity` },
  { key: `Affirm`, value: `Affirm` },
  { key: `After College`, value: `After College` },
  { key: `Airbnb`, value: `Airbnb` },
  { key: `Airtable`, value: `Airtable` },
  { key: `Airware`, value: `Airware` },
  { key: `AKQA`, value: `AKQA` },
  { key: `Aktana`, value: `Aktana` },
  { key: `Alation`, value: `Alation` },
  { key: `Algolia`, value: `Algolia` },
  { key: `AlienVault`, value: `AlienVault` },
  { key: `Allbirds`, value: `Allbirds` },
  { key: `Alloy`, value: `Alloy` },
  { key: `Alpha Sense`, value: `Alpha Sense` },
  { key: `AltSchool`, value: `AltSchool` },
  { key: `Always Hired`, value: `Always Hired` },
  { key: `Amazon`, value: `Amazon` },
  { key: `Amazon Web Services`, value: `Amazon Web Services` },
  { key: `Ambient.ai`, value: `Ambient.ai` },
  { key: `AMD`, value: `AMD` },
  { key: `Amplitude`, value: `Amplitude` },
  { key: `Anaplan`, value: `Anaplan` },
  { key: `Angaza`, value: `Angaza` },
  { key: `AngelList`, value: `AngelList` },
  { key: `Anomali`, value: `Anomali` },
  { key: `Apollo`, value: `Apollo` },
  { key: `App Academy`, value: `App Academy` },
  { key: `App Annie`, value: `App Annie` },
  { key: `AppDirect`, value: `AppDirect` },
  { key: `AppDynamics`, value: `AppDynamics` },
  { key: `Apple`, value: `Apple` },
  { key: `AppNexus`, value: `AppNexus` },
  { key: `Appodeal`, value: `Appodeal` },
  { key: `Apptimize`, value: `Apptimize` },
  { key: `Apteligent`, value: `Apteligent` },
  { key: `Aptly`, value: `Aptly` },
  { key: `Apttus`, value: `Apttus` },
  { key: `Armory`, value: `Armory` },
  { key: `Art.com`, value: `Art.com` },
  { key: `Asana`, value: `Asana` },
  { key: `Ask Media Group`, value: `Ask Media Group` },
  { key: `ASUS`, value: `ASUS` },
  { key: `Atrium LTS`, value: `Atrium LTS` },
  { key: `Autodesk`, value: `Autodesk` },
  { key: `Automattic`, value: `Automattic` },
  { key: `Avast`, value: `Avast` },
  { key: `Avatier`, value: `Avatier` },
  { key: `Avenue Code`, value: `Avenue Code` },
  { key: `BabyCenter`, value: `BabyCenter` },
  { key: `Babylist`, value: `Babylist` },
  { key: `Backerkit`, value: `Backerkit` },
  { key: `Barracuda`, value: `Barracuda` },
  { key: `Bebo`, value: `Bebo` },
  { key: `Bedrock Analytics`, value: `Bedrock Analytics` },
  { key: `Beezwax Datatools`, value: `Beezwax Datatools` },
  { key: `Benchmark Capital`, value: `Benchmark Capital` },
  { key: `Benevity`, value: `Benevity` },
  { key: `Betterment`, value: `Betterment` },
  { key: `BetterUp`, value: `BetterUp` },
  { key: `Betterworks`, value: `Betterworks` },
  { key: `Bexi`, value: `Bexi` },
  { key: `Beyond Games`, value: `Beyond Games` },
  { key: `BigCommerce`, value: `BigCommerce` },
  { key: `Binc`, value: `Binc` },
  { key: `Binti`, value: `Binti` },
  { key: `Birst`, value: `Birst` },
  { key: `BitBucket`, value: `BitBucket` },
  { key: `Bitnami`, value: `Bitnami` },
  { key: `Blackhawk Network`, value: `Blackhawk Network` },
  { key: `Bleacher Report`, value: `Bleacher Report` },
  { key: `Blend`, value: `Blend` },
  { key: `Bloc`, value: `Bloc` },
  { key: `BloomThat`, value: `BloomThat` },
  { key: `Blue River Technology`, value: `Blue River Technology` },
  { key: `BlueCrew`, value: `BlueCrew` },
  { key: `BlueJeans`, value: `BlueJeans` },
  { key: `BlueVine`, value: `BlueVine` },
  { key: `Blurb`, value: `Blurb` },
  { key: `Bolt`, value: `Bolt` },
  { key: `Box`, value: `Box` },
  { key: `Boxbot`, value: `Boxbot` },
  { key: `Bradfield School of Computer Science`, value: `Bradfield School of Computer Science` },
  { key: `Braintree`, value: `Braintree` },
  { key: `Branch`, value: `Branch` },
  { key: `Branch International`, value: `Branch International` },
  { key: `Brex`, value: `Brex` },
  { key: `Brigade`, value: `Brigade` },
  { key: `BrightBytes`, value: `BrightBytes` },
  { key: `Brightidea`, value: `Brightidea` },
  { key: `Brightpearl`, value: `Brightpearl` },
  { key: `BrightTALK`, value: `BrightTALK` },
  { key: `Broadcom`, value: `Broadcom` },
  { key: `Broadly`, value: `Broadly` },
  { key: `Bugcrowd`, value: `Bugcrowd` },
  { key: `Bugsnag`, value: `Bugsnag` },
  { key: `BuildingConnected`, value: `BuildingConnected` },
  { key: `BuildZoom`, value: `BuildZoom` },
  { key: `Bukwild`, value: `Bukwild` },
  { key: `C3`, value: `C3` },
  { key: `Cadence Design Systems`, value: `Cadence Design Systems` },
  { key: `Cadre Talent`, value: `Cadre Talent` },
  { key: `CallidusCloud`, value: `CallidusCloud` },
  { key: `Campsyte`, value: `Campsyte` },
  { key: `Canopy Health`, value: `Canopy Health` },
  { key: `Canvas Medical`, value: `Canvas Medical` },
  { key: `Capgemini`, value: `Capgemini` },
  { key: `Capital One`, value: `Capital One` },
  { key: `Carbon`, value: `Carbon` },
  { key: `Carbon Five`, value: `Carbon Five` },
  { key: `Carrot Fertility`, value: `Carrot Fertility` },
  { key: `Carta`, value: `Carta` },
  { key: `CashBet`, value: `CashBet` },
  { key: `Castle`, value: `Castle` },
  { key: `CBS Interactive`, value: `CBS Interactive` },
  { key: `Ceres Imaging`, value: `Ceres Imaging` },
  { key: `Change Healthcare`, value: `Change Healthcare` },
  { key: `Chartboost`, value: `Chartboost` },
  { key: `Chartio`, value: `Chartio` },
  { key: `Checkr`, value: `Checkr` },
  { key: `Chegg`, value: `Chegg` },
  { key: `Chewse`, value: `Chewse` },
  { key: `Chime`, value: `Chime` },
  { key: `Circle Medical`, value: `Circle Medical` },
  { key: `CircleCI`, value: `CircleCI` },
  { key: `Civil Maps`, value: `Civil Maps` },
  { key: `Clara Analytics`, value: `Clara Analytics` },
  { key: `ClassPass`, value: `ClassPass` },
  { key: `Clearbit`, value: `Clearbit` },
  { key: `ClearBrain`, value: `ClearBrain` },
  { key: `ClearMetal`, value: `ClearMetal` },
  { key: `Clever`, value: `Clever` },
  { key: `ClickUp`, value: `ClickUp` },
  { key: `Climate Corporation`, value: `Climate Corporation` },
  { key: `Cloud4Wi`, value: `Cloud4Wi` },
  { key: `Cloudbees`, value: `Cloudbees` },
  { key: `CloudFabrix`, value: `CloudFabrix` },
  { key: `Cloudflare`, value: `Cloudflare` },
  { key: `Clover Health`, value: `Clover Health` },
  { key: `Clover Network`, value: `Clover Network` },
  { key: `Coda`, value: `Coda` },
  { key: `Code Nation`, value: `Code Nation` },
  { key: `Codecademy`, value: `Codecademy` },
  { key: `CodeSignal`, value: `CodeSignal` },
  { key: `Codexis`, value: `Codexis` },
  { key: `Coffee Meets Bagel`, value: `Coffee Meets Bagel` },
  { key: `Coinbase`, value: `Coinbase` },
  { key: `CoInspect`, value: `CoInspect` },
  { key: `Coit Group`, value: `Coit Group` },
  { key: `Collective Health`, value: `Collective Health` },
  { key: `Comfy`, value: `Comfy` },
  { key: `comma.ai`, value: `comma.ai` },
  { key: `Compology`, value: `Compology` },
  { key: `Copper`, value: `Copper` },
  { key: `CoreOS`, value: `CoreOS` },
  { key: `Coursera`, value: `Coursera` },
  { key: `Cradlepoint`, value: `Cradlepoint` },
  { key: `Creative Market`, value: `Creative Market` },
  { key: `Credit Karma`, value: `Credit Karma` },
  { key: `Cricket Health`, value: `Cricket Health` },
  { key: `Criteo`, value: `Criteo` },
  { key: `Crowdcast`, value: `Crowdcast` },
  { key: `Cruise Automation`, value: `Cruise Automation` },
  { key: `Crunchbase`, value: `Crunchbase` },
  { key: `Crunchyroll`, value: `Crunchyroll` },
  { key: `Culture Amp`, value: `Culture Amp` },
  { key: `CyberCube`, value: `CyberCube` },
  { key: `Daily Kos`, value: `Daily Kos` },
  { key: `Databricks`, value: `Databricks` },
  { key: `Datadog`, value: `Datadog` },
  { key: `DataFox`, value: `DataFox` },
  { key: `Dataminr`, value: `Dataminr` },
  { key: `Datavisor`, value: `Datavisor` },
  { key: `Dealpath`, value: `Dealpath` },
  { key: `Deepnote`, value: `Deepnote` },
  { key: `Demandbase`, value: `Demandbase` },
  { key: `Design Action Collective`, value: `Design Action Collective` },
  { key: `DesignMap`, value: `DesignMap` },
  { key: `Dialpad`, value: `Dialpad` },
  { key: `Digitalist Global`, value: `Digitalist Global` },
  { key: `Discord`, value: `Discord` },
  { key: `Disqus`, value: `Disqus` },
  { key: `Divvy Homes`, value: `Divvy Homes` },
  { key: `DIY`, value: `DIY` },
  { key: `DNAnexus`, value: `DNAnexus` },
  { key: `Docker`, value: `Docker` },
  { key: `Docusign`, value: `Docusign` },
  { key: `DoorDash`, value: `DoorDash` },
  { key: `DroneDeploy`, value: `DroneDeploy` },
  { key: `Dropbox`, value: `Dropbox` },
  { key: `Duncan Channon`, value: `Duncan Channon` },
  { key: `Dynata`, value: `Dynata` },
  { key: `Earnest`, value: `Earnest` },
  { key: `EasyPost`, value: `EasyPost` },
  { key: `Eat Club`, value: `Eat Club` },
  { key: `Eatsa`, value: `Eatsa` },
  { key: `Eaze`, value: `Eaze` },
  { key: `eBay`, value: `eBay` },
  { key: `Eero`, value: `Eero` },
  { key: `Ejento`, value: `Ejento` },
  { key: `Elastic.co`, value: `Elastic.co` },
  { key: `Electronic Arts`, value: `Electronic Arts` },
  { key: `Elegant Themes`, value: `Elegant Themes` },
  { key: `Employbl`, value: `Employbl` },
  { key: `Endpoint Clinical`, value: `Endpoint Clinical` },
  { key: `enSilo`, value: `enSilo` },
  { key: `Entelo`, value: `Entelo` },
  { key: `Envoy`, value: `Envoy` },
  { key: `Enwoven`, value: `Enwoven` },
  { key: `EQ Recruiting`, value: `EQ Recruiting` },
  { key: `Equinix`, value: `Equinix` },
  { key: `Even.com`, value: `Even.com` },
  { key: `Eventbrite`, value: `Eventbrite` },
  { key: `Everlane`, value: `Everlane` },
  { key: `Evernote`, value: `Evernote` },
  { key: `Expensify`, value: `Expensify` },
  { key: `Exponential`, value: `Exponential` },
  { key: `Extole`, value: `Extole` },
  { key: `Fabric Genomics`, value: `Fabric Genomics` },
  { key: `Facebook`, value: `Facebook` },
  { key: `Faire`, value: `Faire` },
  { key: `Fanatics`, value: `Fanatics` },
  { key: `Figure Eight`, value: `Figure Eight` },
  { key: `Fin`, value: `Fin` },
  { key: `FinancialForce`, value: `FinancialForce` },
  { key: `Fingerprint Digital`, value: `Fingerprint Digital` },
  { key: `First Round Capital`, value: `First Round Capital` },
  { key: `FitBit`, value: `FitBit` },
  { key: `Five9`, value: `Five9` },
  { key: `Fivestars`, value: `Fivestars` },
  { key: `Fivetran`, value: `Fivetran` },
  { key: `Flexport`, value: `Flexport` },
  { key: `Fond`, value: `Fond` },
  { key: `Forkable`, value: `Forkable` },
  { key: `Formation`, value: `Formation` },
  { key: `Forward`, value: `Forward` },
  { key: `Fossa`, value: `Fossa` },
  { key: `Founders Fund`, value: `Founders Fund` },
  { key: `FreeWire Technologies`, value: `FreeWire Technologies` },
  { key: `Front App`, value: `Front App` },
  { key: `Fundbox`, value: `Fundbox` },
  { key: `FundersClub`, value: `FundersClub` },
  { key: `Gainsight`, value: `Gainsight` },
  { key: `Galvanize`, value: `Galvanize` },
  { key: `GarageScript`, value: `GarageScript` },
  { key: `GE Digital`, value: `GE Digital` },
  { key: `Gemini`, value: `Gemini` },
  { key: `GenapSys`, value: `GenapSys` },
  { key: `General Assembly`, value: `General Assembly` },
  { key: `Genomic Health`, value: `Genomic Health` },
  { key: `Getaround`, value: `Getaround` },
  { key: `Gigster`, value: `Gigster` },
  { key: `GitHub`, value: `GitHub` },
  { key: `GitLab`, value: `GitLab` },
  { key: `Givecampus`, value: `Givecampus` },
  { key: `Gladly`, value: `Gladly` },
  { key: `Glassdoor`, value: `Glassdoor` },
  { key: `Gliffy`, value: `Gliffy` },
  { key: `Glu Mobile`, value: `Glu Mobile` },
  { key: `GoDaddy`, value: `GoDaddy` },
  { key: `Gong.io`, value: `Gong.io` },
  { key: `Good Eggs`, value: `Good Eggs` },
  { key: `Goodby Silverstein & Partners`, value: `Goodby Silverstein & Partners` },
  { key: `GoodData`, value: `GoodData` },
  { key: `Google`, value: `Google` },
  { key: `Gorgias`, value: `Gorgias` },
  { key: `Gracenote`, value: `Gracenote` },
  { key: `Grammarly`, value: `Grammarly` },
  { key: `Greenfield Labs`, value: `Greenfield Labs` },
  { key: `Greenhouse Software`, value: `Greenhouse Software` },
  { key: `Grin`, value: `Grin` },
  { key: `Groove Labs`, value: `Groove Labs` },
  { key: `Groupon`, value: `Groupon` },
  { key: `Grove Collaborative`, value: `Grove Collaborative` },
  { key: `Grovo`, value: `Grovo` },
  { key: `GrowthX`, value: `GrowthX` },
  { key: `Gumroad`, value: `Gumroad` },
  { key: `Gusto`, value: `Gusto` },
  { key: `Gymboree Group`, value: `Gymboree Group` },
  { key: `Hack Reactor`, value: `Hack Reactor` },
  { key: `HackerOne`, value: `HackerOne` },
  { key: `Hammerhead`, value: `Hammerhead` },
  { key: `Handy`, value: `Handy` },
  { key: `Haven`, value: `Haven` },
  { key: `Headspace`, value: `Headspace` },
  { key: `Heap`, value: `Heap` },
  { key: `HeartFlow`, value: `HeartFlow` },
  { key: `HelloSign`, value: `HelloSign` },
  { key: `Helpshift`, value: `Helpshift` },
  { key: `Hims`, value: `Hims` },
  { key: `Hingeto`, value: `Hingeto` },
  { key: `HintMD`, value: `HintMD` },
  { key: `HireClub`, value: `HireClub` },
  { key: `Hired`, value: `Hired` },
  { key: `Hiretual`, value: `Hiretual` },
  { key: `Holberton School`, value: `Holberton School` },
  { key: `Homebase`, value: `Homebase` },
  { key: `Honeybook`, value: `Honeybook` },
  { key: `Hoodline`, value: `Hoodline` },
  { key: `Horizons School of Technology`, value: `Horizons School of Technology` },
  { key: `Hornblower Cruises`, value: `Hornblower Cruises` },
  { key: `HotelTonight`, value: `HotelTonight` },
  { key: `HotPads`, value: `HotPads` },
  { key: `HotSchedules`, value: `HotSchedules` },
  { key: `Hotwire`, value: `Hotwire` },
  { key: `Hover`, value: `Hover` },
  { key: `Hustle`, value: `Hustle` },
  { key: `iCrossing`, value: `iCrossing` },
  { key: `Ideo`, value: `Ideo` },
  { key: `IGN Entertainment`, value: `IGN Entertainment` },
  { key: `iHear Medical`, value: `iHear Medical` },
  { key: `Imgur`, value: `Imgur` },
  { key: `Imperva`, value: `Imperva` },
  { key: `Indeed`, value: `Indeed` },
  { key: `Indiegogo`, value: `Indiegogo` },
  { key: `inDinero`, value: `inDinero` },
  { key: `Industrial Light & Magic`, value: `Industrial Light & Magic` },
  { key: `InfluxData`, value: `InfluxData` },
  { key: `Informatica`, value: `Informatica` },
  { key: `Insight Global`, value: `Insight Global` },
  { key: `Insight Recruiting`, value: `Insight Recruiting` },
  { key: `Insightly`, value: `Insightly` },
  { key: `Insikt`, value: `Insikt` },
  { key: `Instacart`, value: `Instacart` },
  { key: `Intelliswift`, value: `Intelliswift` },
  { key: `Intercom`, value: `Intercom` },
  { key: `interviewing.io`, value: `interviewing.io` },
  { key: `Intuit`, value: `Intuit` },
  { key: `Invitae`, value: `Invitae` },
  { key: `Invoice2go`, value: `Invoice2go` },
  { key: `iRhythm`, value: `iRhythm` },
  { key: `Iris Automation`, value: `Iris Automation` },
  { key: `Ironclad`, value: `Ironclad` },
  { key: `Iterable`, value: `Iterable` },
  { key: `Jobspring Partners`, value: `Jobspring Partners` },
  { key: `Jobvite`, value: `Jobvite` },
  { key: `Joy`, value: `Joy` },
  { key: `Joyent`, value: `Joyent` },
  { key: `Judge Group`, value: `Judge Group` },
  { key: `Jumpshot`, value: `Jumpshot` },
  { key: `June Oven`, value: `June Oven` },
  { key: `Juniper Square`, value: `Juniper Square` },
  { key: `JustAnswer`, value: `JustAnswer` },
  { key: `JUUL Labs`, value: `JUUL Labs` },
  { key: `Kaiser Permanente`, value: `Kaiser Permanente` },
  { key: `Kapor Center`, value: `Kapor Center` },
  { key: `Kapwing`, value: `Kapwing` },
  { key: `Karuna Health`, value: `Karuna Health` },
  { key: `KBC Staffing`, value: `KBC Staffing` },
  { key: `Kentik`, value: `Kentik` },
  { key: `Khan Academy`, value: `Khan Academy` },
  { key: `Kickstart Coding`, value: `Kickstart Coding` },
  { key: `Kiip`, value: `Kiip` },
  { key: `Kissmetrics`, value: `Kissmetrics` },
  { key: `KittyHawk`, value: `KittyHawk` },
  { key: `Kiva`, value: `Kiva` },
  { key: `Knotel`, value: `Knotel` },
  { key: `Kong`, value: `Kong` },
  { key: `KPCB`, value: `KPCB` },
  { key: `Kyokan`, value: `Kyokan` },
  { key: `Landor`, value: `Landor` },
  { key: `Lattice`, value: `Lattice` },
  { key: `Launch Darkly`, value: `Launch Darkly` },
  { key: `Layer`, value: `Layer` },
  { key: `Leadspace`, value: `Leadspace` },
  { key: `Leanplum`, value: `Leanplum` },
  { key: `Leap Motion`, value: `Leap Motion` },
  { key: `LeapFrog`, value: `LeapFrog` },
  { key: `Lending Club`, value: `Lending Club` },
  { key: `LendingHome`, value: `LendingHome` },
  { key: `LendUp`, value: `LendUp` },
  { key: `Lever`, value: `Lever` },
  { key: `Liftopia`, value: `Liftopia` },
  { key: `Lightbend`, value: `Lightbend` },
  { key: `LightStep`, value: `LightStep` },
  { key: `Lime`, value: `Lime` },
  { key: `LinkedIn`, value: `LinkedIn` },
  { key: `Linqia`, value: `Linqia` },
  { key: `Literably`, value: `Literably` },
  { key: `Lithium Technologies`, value: `Lithium Technologies` },
  { key: `LiveRamp`, value: `LiveRamp` },
  { key: `Livongo`, value: `Livongo` },
  { key: `Lob`, value: `Lob` },
  { key: `Logikcull`, value: `Logikcull` },
  { key: `Looker`, value: `Looker` },
  { key: `Luma Health`, value: `Luma Health` },
  { key: `Lyft`, value: `Lyft` },
  { key: `Lyte`, value: `Lyte` },
  { key: `Machine Zone`, value: `Machine Zone` },
  { key: `Macy's`, value: `Macy's` },
  { key: `Magoosh`, value: `Magoosh` },
  { key: `Mailchimp`, value: `Mailchimp` },
  { key: `Mailgun`, value: `Mailgun` },
  { key: `Mainz Brady Group`, value: `Mainz Brady Group` },
  { key: `Mapbox`, value: `Mapbox` },
  { key: `Marketo`, value: `Marketo` },
  { key: `Marqeta`, value: `Marqeta` },
  { key: `Mashery`, value: `Mashery` },
  { key: `Massdrop`, value: `Massdrop` },
  { key: `MasterClass`, value: `MasterClass` },
  { key: `Matter`, value: `Matter` },
  { key: `Mattermark`, value: `Mattermark` },
  { key: `Mattermost`, value: `Mattermost` },
  { key: `Matterport`, value: `Matterport` },
  { key: `Mayvenn`, value: `Mayvenn` },
  { key: `McKesson`, value: `McKesson` },
  { key: `Medium`, value: `Medium` },
  { key: `Medrio`, value: `Medrio` },
  { key: `Meltwater`, value: `Meltwater` },
  { key: `Memebox`, value: `Memebox` },
  { key: `MemSQL`, value: `MemSQL` },
  { key: `Mende Design`, value: `Mende Design` },
  { key: `Mesosphere`, value: `Mesosphere` },
  { key: `MessageBird`, value: `MessageBird` },
  { key: `MetaVision`, value: `MetaVision` },
  { key: `Method`, value: `Method` },
  { key: `Metric Insights`, value: `Metric Insights` },
  { key: `Metromile`, value: `Metromile` },
  { key: `Microsoft`, value: `Microsoft` },
  { key: `Mindsource`, value: `Mindsource` },
  { key: `Minted`, value: `Minted` },
  { key: `Mira`, value: `Mira` },
  { key: `MissionU`, value: `MissionU` },
  { key: `MitchelLake Group`, value: `MitchelLake Group` },
  { key: `MixMax`, value: `MixMax` },
  { key: `Mixpanel`, value: `Mixpanel` },
  { key: `Mode Analytics`, value: `Mode Analytics` },
  { key: `Model N`, value: `Model N` },
  { key: `Modis`, value: `Modis` },
  { key: `Mondo`, value: `Mondo` },
  { key: `MoogSoft`, value: `MoogSoft` },
  { key: `Mosaic`, value: `Mosaic` },
  { key: `Motionloft`, value: `Motionloft` },
  { key: `Mountain View Staffing`, value: `Mountain View Staffing` },
  { key: `MoveWith`, value: `MoveWith` },
  { key: `Mozilla`, value: `Mozilla` },
  { key: `MuleSoft`, value: `MuleSoft` },
  { key: `Mux`, value: `Mux` },
  { key: `MyFitnessPal`, value: `MyFitnessPal` },
  { key: `Mynd`, value: `Mynd` },
  { key: `Namely`, value: `Namely` },
  { key: `Narvar`, value: `Narvar` },
  { key: `Naytev`, value: `Naytev` },
  { key: `Nelson Staffing`, value: `Nelson Staffing` },
  { key: `NepFin`, value: `NepFin` },
  { key: `Nerd Wallet`, value: `Nerd Wallet` },
  { key: `Netflix`, value: `Netflix` },
  { key: `Netlify`, value: `Netlify` },
  { key: `New Relic`, value: `New Relic` },
  { key: `Newfront Insurance`, value: `Newfront Insurance` },
  { key: `Nextdoor`, value: `Nextdoor` },
  { key: `NexTravel`, value: `NexTravel` },
  { key: `Neyborly`, value: `Neyborly` },
  { key: `NinjaRMM`, value: `NinjaRMM` },
  { key: `No Means No Worldwide`, value: `No Means No Worldwide` },
  { key: `NoRedInk`, value: `NoRedInk` },
  { key: `Notable Labs`, value: `Notable Labs` },
  { key: `Notion`, value: `Notion` },
  { key: `Nova Credit`, value: `Nova Credit` },
  { key: `npm Inc`, value: `npm Inc` },
  { key: `Nuna`, value: `Nuna` },
  { key: `Nuro`, value: `Nuro` },
  { key: `Nylas`, value: `Nylas` },
  { key: `Oath`, value: `Oath` },
  { key: `Odopod`, value: `Odopod` },
  { key: `Offset Partners`, value: `Offset Partners` },
  { key: `Okta`, value: `Okta` },
  { key: `OneLogin`, value: `OneLogin` },
  { key: `Onesignal`, value: `Onesignal` },
  { key: `OpenAI`, value: `OpenAI` },
  { key: `OpenDNS`, value: `OpenDNS` },
  { key: `Opendoor`, value: `Opendoor` },
  { key: `Opentable`, value: `Opentable` },
  { key: `Optimizely`, value: `Optimizely` },
  { key: `Oracle`, value: `Oracle` },
  { key: `Oscar Health`, value: `Oscar Health` },
  { key: `OSIsoft`, value: `OSIsoft` },
  { key: `Ouster`, value: `Ouster` },
  { key: `Outlier AI`, value: `Outlier AI` },
  { key: `Outreach`, value: `Outreach` },
  { key: `PagerDuty`, value: `PagerDuty` },
  { key: `Palo Alto Staffing`, value: `Palo Alto Staffing` },
  { key: `Pandora`, value: `Pandora` },
  { key: `Panoply`, value: `Panoply` },
  { key: `Pantheon`, value: `Pantheon` },
  { key: `Parsable`, value: `Parsable` },
  { key: `Patagonia`, value: `Patagonia` },
  { key: `Pathstream`, value: `Pathstream` },
  { key: `PayPal`, value: `PayPal` },
  { key: `PeopleConnect Staffing`, value: `PeopleConnect Staffing` },
  { key: `PeopleGrove`, value: `PeopleGrove` },
  { key: `Percolate`, value: `Percolate` },
  { key: `Perforce`, value: `Perforce` },
  { key: `Performance Horizon`, value: `Performance Horizon` },
  { key: `Periscope Data`, value: `Periscope Data` },
  { key: `Philo`, value: `Philo` },
  { key: `Phone2Action`, value: `Phone2Action` },
  { key: `Pinterest`, value: `Pinterest` },
  { key: `Pique Tea`, value: `Pique Tea` },
  { key: `Pivotal`, value: `Pivotal` },
  { key: `Pixar`, value: `Pixar` },
  { key: `Pixlee`, value: `Pixlee` },
  { key: `Plaid`, value: `Plaid` },
  { key: `Planet`, value: `Planet` },
  { key: `PlanGrid`, value: `PlanGrid` },
  { key: `Portrait Displays`, value: `Portrait Displays` },
  { key: `Poshmark`, value: `Poshmark` },
  { key: `Postman`, value: `Postman` },
  { key: `Postmates`, value: `Postmates` },
  { key: `Powerhouse`, value: `Powerhouse` },
  { key: `Practice Fusion`, value: `Practice Fusion` },
  { key: `Pramp`, value: `Pramp` },
  { key: `Premier Talent Partners`, value: `Premier Talent Partners` },
  { key: `Prezi`, value: `Prezi` },
  { key: `Product School`, value: `Product School` },
  { key: `Proofpoint`, value: `Proofpoint` },
  { key: `Proteus Digital Health`, value: `Proteus Digital Health` },
  { key: `Proto.io`, value: `Proto.io` },
  { key: `Pusher`, value: `Pusher` },
  { key: `Qadium`, value: `Qadium` },
  { key: `Qualia`, value: `Qualia` },
  { key: `Qualys`, value: `Qualys` },
  { key: `Quantcast`, value: `Quantcast` },
  { key: `Quid`, value: `Quid` },
  { key: `Quip`, value: `Quip` },
  { key: `Quora`, value: `Quora` },
  { key: `Qwilt`, value: `Qwilt` },
  { key: `Radical Designs`, value: `Radical Designs` },
  { key: `Radius`, value: `Radius` },
  { key: `RainForest QA`, value: `RainForest QA` },
  { key: `Rainmakers`, value: `Rainmakers` },
  { key: `RaiseMe`, value: `RaiseMe` },
  { key: `Rally Health`, value: `Rally Health` },
  { key: `Randstad`, value: `Randstad` },
  { key: `RapidAPI`, value: `RapidAPI` },
  { key: `RazorFrog`, value: `RazorFrog` },
  { key: `ReadMe`, value: `ReadMe` },
  { key: `Recurly`, value: `Recurly` },
  { key: `Red Bridge Internet`, value: `Red Bridge Internet` },
  { key: `Red Oak Technologies`, value: `Red Oak Technologies` },
  { key: `Redbubble`, value: `Redbubble` },
  { key: `Reddit`, value: `Reddit` },
  { key: `Redfin`, value: `Redfin` },
  { key: `Redpoint Ventures`, value: `Redpoint Ventures` },
  { key: `ReferralExchange`, value: `ReferralExchange` },
  { key: `Reflektive`, value: `Reflektive` },
  { key: `Reforge`, value: `Reforge` },
  { key: `Remind`, value: `Remind` },
  { key: `Remix`, value: `Remix` },
  { key: `Rescale`, value: `Rescale` },
  { key: `Resilio`, value: `Resilio` },
  { key: `Restless Bandit`, value: `Restless Bandit` },
  { key: `Returnly`, value: `Returnly` },
  { key: `RGA Associates`, value: `RGA Associates` },
  { key: `Ridecell`, value: `Ridecell` },
  { key: `Rigetti`, value: `Rigetti` },
  { key: `Rigetti Computing`, value: `Rigetti Computing` },
  { key: `RingCentral`, value: `RingCentral` },
  { key: `Riot Games`, value: `Riot Games` },
  { key: `Rithm School`, value: `Rithm School` },
  { key: `Riviera Partners`, value: `Riviera Partners` },
  { key: `Robert Half`, value: `Robert Half` },
  { key: `Rocket Lawyer`, value: `Rocket Lawyer` },
  { key: `RocketSpace`, value: `RocketSpace` },
  { key: `Rollbar`, value: `Rollbar` },
  { key: `Roofstock`, value: `Roofstock` },
  { key: `Runway Incubator`, value: `Runway Incubator` },
  { key: `Rylo`, value: `Rylo` },
  { key: `Saba Software`, value: `Saba Software` },
  { key: `Saildrone`, value: `Saildrone` },
  { key: `Salesforce`, value: `Salesforce` },
  { key: `Salon Media Group`, value: `Salon Media Group` },
  { key: `Samsara`, value: `Samsara` },
  { key: `Sano`, value: `Sano` },
  { key: `SAP`, value: `SAP` },
  { key: `Sauce Labs`, value: `Sauce Labs` },
  { key: `SavvyMoney`, value: `SavvyMoney` },
  { key: `Say Media`, value: `Say Media` },
  { key: `Scal.io`, value: `Scal.io` },
  { key: `Scalable Press`, value: `Scalable Press` },
  { key: `Scality`, value: `Scality` },
  { key: `Scalyr`, value: `Scalyr` },
  { key: `Scion Staffing`, value: `Scion Staffing` },
  { key: `Scripted`, value: `Scripted` },
  { key: `Securly`, value: `Securly` },
  { key: `Segment`, value: `Segment` },
  { key: `Sendgrid`, value: `Sendgrid` },
  { key: `Sendwithus`, value: `Sendwithus` },
  { key: `Sentry`, value: `Sentry` },
  { key: `Sephora`, value: `Sephora` },
  { key: `Shazam`, value: `Shazam` },
  { key: `Shift.org`, value: `Shift.org` },
  { key: `Shippo`, value: `Shippo` },
  { key: `Shipt`, value: `Shipt` },
  { key: `Shopify`, value: `Shopify` },
  { key: `ShopPad`, value: `ShopPad` },
  { key: `Shutterfly`, value: `Shutterfly` },
  { key: `Sibly`, value: `Sibly` },
  { key: `Sift Science`, value: `Sift Science` },
  { key: `Sight Machine`, value: `Sight Machine` },
  { key: `SignalFx`, value: `SignalFx` },
  { key: `Simple Habit`, value: `Simple Habit` },
  { key: `Sindeo`, value: `Sindeo` },
  { key: `Sizmek`, value: `Sizmek` },
  { key: `Skedulo`, value: `Skedulo` },
  { key: `Skillz`, value: `Skillz` },
  { key: `Slack`, value: `Slack` },
  { key: `Slalom Consulting`, value: `Slalom Consulting` },
  { key: `Slashdot Media`, value: `Slashdot Media` },
  { key: `SlashNext`, value: `SlashNext` },
  { key: `Slow Ventures`, value: `Slow Ventures` },
  { key: `Smarking`, value: `Smarking` },
  { key: `SmartBiz Loans`, value: `SmartBiz Loans` },
  { key: `SmugMug`, value: `SmugMug` },
  { key: `Snapdocs`, value: `Snapdocs` },
  { key: `SnapLogic`, value: `SnapLogic` },
  { key: `Snappr`, value: `Snappr` },
  { key: `Snowflake Computing`, value: `Snowflake Computing` },
  { key: `SoFi`, value: `SoFi` },
  { key: `SoloLearn`, value: `SoloLearn` },
  { key: `Sonder`, value: `Sonder` },
  { key: `Sony PlayStation`, value: `Sony PlayStation` },
  { key: `Sourceress`, value: `Sourceress` },
  { key: `Spark Program`, value: `Spark Program` },
  { key: `Sparkart`, value: `Sparkart` },
  { key: `Sparkcentral`, value: `Sparkcentral` },
  { key: `Splunk`, value: `Splunk` },
  { key: `Spotify`, value: `Spotify` },
  { key: `Spotinst`, value: `Spotinst` },
  { key: `SpotX`, value: `SpotX` },
  { key: `Springboard`, value: `Springboard` },
  { key: `SpringML`, value: `SpringML` },
  { key: `Square`, value: `Square` },
  { key: `Stackshare`, value: `Stackshare` },
  { key: `Stamen Design`, value: `Stamen Design` },
  { key: `Starcity`, value: `Starcity` },
  { key: `Stash Invest`, value: `Stash Invest` },
  { key: `Stitch Fix`, value: `Stitch Fix` },
  { key: `Stitch Labs`, value: `Stitch Labs` },
  { key: `Stitcher`, value: `Stitcher` },
  { key: `Storenvy`, value: `Storenvy` },
  { key: `Stratim`, value: `Stratim` },
  { key: `Strava`, value: `Strava` },
  { key: `Streak CRM`, value: `Streak CRM` },
  { key: `Stripe`, value: `Stripe` },
  { key: `Strive Talent`, value: `Strive Talent` },
  { key: `StubHub`, value: `StubHub` },
  { key: `StumbleUpon`, value: `StumbleUpon` },
  { key: `Sumo Logic`, value: `Sumo Logic` },
  { key: `SV Angel`, value: `SV Angel` },
  { key: `Swift Navigation`, value: `Swift Navigation` },
  { key: `Swiftly`, value: `Swiftly` },
  { key: `SwiftStack`, value: `SwiftStack` },
  { key: `Swing Talent`, value: `Swing Talent` },
  { key: `Swrve`, value: `Swrve` },
  { key: `Syapse`, value: `Syapse` },
  { key: `Syllable.ai`, value: `Syllable.ai` },
  { key: `Symantec`, value: `Symantec` },
  { key: `SyncFab`, value: `SyncFab` },
  { key: `Synchrony`, value: `Synchrony` },
  { key: `Talener`, value: `Talener` },
  { key: `Talentburst`, value: `Talentburst` },
  { key: `Talkable`, value: `Talkable` },
  { key: `Talkdesk`, value: `Talkdesk` },
  { key: `Tanium`, value: `Tanium` },
  { key: `Taos`, value: `Taos` },
  { key: `TaskRabbit`, value: `TaskRabbit` },
  { key: `Teamable`, value: `Teamable` },
  { key: `Tech Liminal`, value: `Tech Liminal` },
  { key: `TechConnexion`, value: `TechConnexion` },
  { key: `Techees Recruiting`, value: `Techees Recruiting` },
  { key: `TechSoup`, value: `TechSoup` },
  { key: `Teespring`, value: `Teespring` },
  { key: `TEKsystems`, value: `TEKsystems` },
  { key: `Teleport`, value: `Teleport` },
  { key: `Tenable`, value: `Tenable` },
  { key: `Terminus Software`, value: `Terminus Software` },
  { key: `Tesla`, value: `Tesla` },
  { key: `Tetra`, value: `Tetra` },
  { key: `Thalmic Labs`, value: `Thalmic Labs` },
  { key: `The Athletic`, value: `The Athletic` },
  { key: `The Hive`, value: `The Hive` },
  { key: `The League`, value: `The League` },
  { key: `The RealReal`, value: `The RealReal` },
  { key: `The Sourcery`, value: `The Sourcery` },
  { key: `The Trade Desk`, value: `The Trade Desk` },
  { key: `Thirdlove`, value: `Thirdlove` },
  { key: `Thoughtworks`, value: `Thoughtworks` },
  { key: `ThousandEyes`, value: `ThousandEyes` },
  { key: `Thrive Global`, value: `Thrive Global` },
  { key: `Thumbtack`, value: `Thumbtack` },
  { key: `Thunder`, value: `Thunder` },
  { key: `TIBCO Software`, value: `TIBCO Software` },
  { key: `Tophatter`, value: `Tophatter` },
  { key: `Townsquared`, value: `Townsquared` },
  { key: `Traction`, value: `Traction` },
  { key: `Tradecraft`, value: `Tradecraft` },
  { key: `Tradeshift`, value: `Tradeshift` },
  { key: `Tray.io`, value: `Tray.io` },
  { key: `trendmedia`, value: `trendmedia` },
  { key: `Trifacata`, value: `Trifacata` },
  { key: `Triplebyte`, value: `Triplebyte` },
  { key: `Trulia`, value: `Trulia` },
  { key: `Truly`, value: `Truly` },
  { key: `TrustArc`, value: `TrustArc` },
  { key: `Turo`, value: `Turo` },
  { key: `TwentyThree`, value: `TwentyThree` },
  { key: `Twilio`, value: `Twilio` },
  { key: `Twitch`, value: `Twitch` },
  { key: `Twitter`, value: `Twitter` },
  { key: `Uber`, value: `Uber` },
  { key: `uBiome`, value: `uBiome` },
  { key: `Udacity`, value: `Udacity` },
  { key: `Udemy`, value: `Udemy` },
  { key: `Upgrade`, value: `Upgrade` },
  { key: `Upsight`, value: `Upsight` },
  { key: `Urban Airship`, value: `Urban Airship` },
  { key: `UrbanSitter`, value: `UrbanSitter` },
  { key: `Vaco`, value: `Vaco` },
  { key: `ValiMail`, value: `ValiMail` },
  { key: `Veeva`, value: `Veeva` },
  { key: `Verbal + Visual`, value: `Verbal + Visual` },
  { key: `Verkada`, value: `Verkada` },
  { key: `Vero`, value: `Vero` },
  { key: `Vevo`, value: `Vevo` },
  { key: `Via Transportation`, value: `Via Transportation` },
  { key: `Vidado`, value: `Vidado` },
  { key: `VigLink`, value: `VigLink` },
  { key: `Vineti`, value: `Vineti` },
  { key: `Virtusa`, value: `Virtusa` },
  { key: `Visby`, value: `Visby` },
  { key: `Vitagene`, value: `Vitagene` },
  { key: `VMWare`, value: `VMWare` },
  { key: `Voiceops`, value: `Voiceops` },
  { key: `Volt Workforce Solutions`, value: `Volt Workforce Solutions` },
  { key: `Vonnda`, value: `Vonnda` },
  { key: `Voxbone`, value: `Voxbone` },
  { key: `VSCO`, value: `VSCO` },
  { key: `Vungle`, value: `Vungle` },
  { key: `Wake`, value: `Wake` },
  { key: `Walmart Labs`, value: `Walmart Labs` },
  { key: `Wanelo`, value: `Wanelo` },
  { key: `Watsi`, value: `Watsi` },
  { key: `WayUp`, value: `WayUp` },
  { key: `Wealthfront`, value: `Wealthfront` },
  { key: `Webflow`, value: `Webflow` },
  { key: `Weebly`, value: `Weebly` },
  { key: `Wellio`, value: `Wellio` },
  { key: `WellnessFX`, value: `WellnessFX` },
  { key: `WePay`, value: `WePay` },
  { key: `WeWork`, value: `WeWork` },
  { key: `Wheelwell`, value: `Wheelwell` },
  { key: `Wikia`, value: `Wikia` },
  { key: `Wish`, value: `Wish` },
  { key: `Wizeline`, value: `Wizeline` },
  { key: `Woebot Labs`, value: `Woebot Labs` },
  { key: `Wollborg Michelson Recruiting`, value: `Wollborg Michelson Recruiting` },
  { key: `Womply`, value: `Womply` },
  { key: `Wonderschool`, value: `Wonderschool` },
  { key: `Wonderware`, value: `Wonderware` },
  { key: `Woopra`, value: `Woopra` },
  { key: `Workbridge Associates`, value: `Workbridge Associates` },
  { key: `Workday`, value: `Workday` },
  { key: `Xero`, value: `Xero` },
  { key: `xMatters`, value: `xMatters` },
  { key: `Xoriant`, value: `Xoriant` },
  { key: `Y Combinator SF`, value: `Y Combinator SF` },
  { key: `Yammer`, value: `Yammer` },
  { key: `Yapstone`, value: `Yapstone` },
  { key: `Yelp`, value: `Yelp` },
  { key: `Yext`, value: `Yext` },
  { key: `Yodlee`, value: `Yodlee` },
  { key: `Zapier`, value: `Zapier` },
  { key: `Zazzle`, value: `Zazzle` },
  { key: `Zedo`, value: `Zedo` },
  { key: `Zendesk`, value: `Zendesk` },
  { key: `Zendrive`, value: `Zendrive` },
  { key: `Zenefits`, value: `Zenefits` },
  { key: `Zenput`, value: `Zenput` },
  { key: `Zenreach`, value: `Zenreach` },
  { key: `Zenysis`, value: `Zenysis` },
  { key: `ZeroCater`, value: `ZeroCater` },
  { key: `Zicasso`, value: `Zicasso` },
  { key: `Zignal Labs`, value: `Zignal Labs` },
  { key: `Zinc`, value: `Zinc` },
  { key: `Zoosk`, value: `Zoosk` },
  { key: `Zume Pizza`, value: `Zume Pizza` },
  { key: `Zumper`, value: `Zumper` },
  { key: `Zuora`, value: `Zuora` },
  { key: `Zymergen`, value: `Zymergen` },
  { key: `Zynga`, value: `Zynga` },
  { key: `Aura Frames`, value: `Aura Frames` },
  { key: `Bayside Solutions`, value: `Bayside Solutions` },
  { key: `Blackbaud`, value: `Blackbaud` },
  { key: `BlackBird`, value: `BlackBird` },
  { key: `Change`, value: `Change` },
  { key: `CommonGenius`, value: `CommonGenius` },
  { key: `Court Buddy`, value: `Court Buddy` },
  { key: `Deliverr`, value: `Deliverr` },
  { key: `Dharma Labs`, value: `Dharma Labs` },
  { key: `DiDi Labs`, value: `DiDi Labs` },
  { key: `Fuzzy`, value: `Fuzzy` },
  { key: `GetThru`, value: `GetThru` },
  { key: `GoodTime`, value: `GoodTime` },
  { key: `Health Gorilla`, value: `Health Gorilla` },
  { key: `LoanSnap`, value: `LoanSnap` },
  { key: `Miles`, value: `Miles` },
  { key: `Modern Health`, value: `Modern Health` },
  { key: `NexGenT`, value: `NexGenT` },
  { key: `Prifina`, value: `Prifina` },
  { key: `Roblox`, value: `Roblox` },
  { key: `Skupos`, value: `Skupos` },
  { key: `Spin`, value: `Spin` },
  { key: `Stellar`, value: `Stellar` },
  { key: `Topology`, value: `Topology` },
  { key: `Tundra`, value: `Tundra` },
  { key: `WellPay`, value: `WellPay` },
  { key: `Zipcar`, value: `Zipcar` },
  { key: `Brillio`, value: `Brillio` },
  { key: `Diffbot`, value: `Diffbot` },
  { key: `FileMaker`, value: `FileMaker` },
  { key: `Katerra`, value: `Katerra` },
  { key: `Mission Lane`, value: `Mission Lane` },
  { key: `NextGen America`, value: `NextGen America` },
  { key: `Nextgen Technologies`, value: `Nextgen Technologies` },
  { key: `Nuance Communications`, value: `Nuance Communications` },
  { key: `Numerator`, value: `Numerator` },
  { key: `Proxy`, value: `Proxy` },
  { key: `Retool`, value: `Retool` },
  { key: `Thorn`, value: `Thorn` },
  { key: `Toole Design`, value: `Toole Design` },
  { key: `Truework`, value: `Truework` },
  { key: `Tynker`, value: `Tynker` },
  { key: `Visa`, value: `Visa` },
  { key: `Wind River`, value: `Wind River` },
  { key: `Google Cloud`, value: `Google Cloud` },
  { key: `Leetcode`, value: `Leetcode` },
  { key: `TalentSeer`, value: `TalentSeer` },
  { key: `Talix`, value: `Talix` },
  { key: `Tipalti`, value: `Tipalti` },
  { key: `WorkBoard`, value: `WorkBoard` },
  { key: `Zipline`, value: `Zipline` },
  { key: `Codility`, value: `Codility` },
  { key: `Incorta`, value: `Incorta` },
  { key: `PARISOMA`, value: `PARISOMA` },
  { key: `AspireIQ`, value: `AspireIQ` },
  { key: `ColorTokens`, value: `ColorTokens` },
  { key: `Contentful`, value: `Contentful` },
  { key: `Crossover Hub`, value: `Crossover Hub` },
  { key: `Datrium`, value: `Datrium` },
  { key: `Directly`, value: `Directly` },
  { key: `Grabr`, value: `Grabr` },
  { key: `Harmonic`, value: `Harmonic` },
  { key: `Hewlett-Packard Enterprise`, value: `Hewlett-Packard Enterprise` },
  { key: `Human Interest`, value: `Human Interest` },
  { key: `Lambda School`, value: `Lambda School` },
  { key: `MediaMonks`, value: `MediaMonks` },
  { key: `Moonlight`, value: `Moonlight` },
  { key: `Oderful`, value: `Oderful` },
  { key: `PubNub`, value: `PubNub` },
  { key: `Riffyn`, value: `Riffyn` },
  { key: `SentinelOne`, value: `SentinelOne` },
  { key: `Sourcegraph`, value: `Sourcegraph` },
  { key: `Tally`, value: `Tally` },
  { key: `Veem`, value: `Veem` },
  { key: `WanderJaunt`, value: `WanderJaunt` },
  { key: `ZEIT`, value: `ZEIT` },
  { key: `Zero Financial`, value: `Zero Financial` },
  { key: `d2iq`, value: `d2iq` },
  { key: `Everlaw`, value: `Everlaw` },
  { key: `Gravitational`, value: `Gravitational` },
  { key: `LocusLabs`, value: `LocusLabs` },
  { key: `Manifold`, value: `Manifold` },
  { key: `Productboard`, value: `Productboard` },
  { key: `Renew Financial`, value: `Renew Financial` },
  { key: `Rockbot`, value: `Rockbot` },
  { key: `Vivun`, value: `Vivun` },
  { key: `Ascend`, value: `Ascend` },
  { key: `Seismic`, value: `Seismic` },
  { key: `Wootric`, value: `Wootric` },
  { key: `turnitin`, value: `turnitin` },
  { key: `Airbase`, value: `Airbase` },
  { key: `Better Mortgage`, value: `Better Mortgage` },
  { key: `Clorox`, value: `Clorox` },
  { key: `Commure`, value: `Commure` },
  { key: `Customer Lobby`, value: `Customer Lobby` },
  { key: `Distru`, value: `Distru` },
  { key: `Dover`, value: `Dover` },
  { key: `GoodRx`, value: `GoodRx` },
  { key: `Jane Technologies`, value: `Jane Technologies` },
  { key: `Mercury`, value: `Mercury` },
  { key: `NetSpring`, value: `NetSpring` },
  { key: `unitQ`, value: `unitQ` },
  { key: `User Testing`, value: `User Testing` },
  { key: `Arista`, value: `Arista` },
  { key: `Brocade`, value: `Brocade` },
  { key: `CipherCloud`, value: `CipherCloud` },
  { key: `Cisco`, value: `Cisco` },
  { key: `Cloudera Inc`, value: `Cloudera Inc` },
  { key: `Craigslist Foundation`, value: `Craigslist Foundation` },
  { key: `DataStax`, value: `DataStax` },
  { key: `Deem`, value: `Deem` },
  { key: `Delphix`, value: `Delphix` },
  { key: `Drawbridge`, value: `Drawbridge` },
  { key: `eBay`, value: `eBay` },
  { key: `Egnyte`, value: `Egnyte` },
  { key: `Elastic`, value: `Elastic` },
  { key: `EMC Data Domain`, value: `EMC Data Domain` },
  { key: `Flipboard`, value: `Flipboard` },
  { key: `Funding Circle`, value: `Funding Circle` },
  { key: `Gigya`, value: `Gigya` },
  { key: `Globalfoundries`, value: `Globalfoundries` },
  { key: `GoPro`, value: `GoPro` },
  { key: `Grand Rounds`, value: `Grand Rounds` },
  { key: `Hewlett Packard`, value: `Hewlett Packard` },
  { key: `Houzz`, value: `Houzz` },
  { key: `Inflection.com`, value: `Inflection.com` },
  { key: `Intel`, value: `Intel` },
  { key: `Jasper Wireless`, value: `Jasper Wireless` },
  { key: `Juniper Networks`, value: `Juniper Networks` },
  { key: `Kabam`, value: `Kabam` },
  { key: `Lab126`, value: `Lab126` },
  { key: `Logitech`, value: `Logitech` },
  { key: `Lookout`, value: `Lookout` },
  { key: `Lucasfilm Ltd`, value: `Lucasfilm Ltd` },
  { key: `MapR Technologies`, value: `MapR Technologies` },
  { key: `MarkLogic`, value: `MarkLogic` },
  { key: `Marvell`, value: `Marvell` },
  { key: `Maverick Capital`, value: `Maverick Capital` },
  { key: `McAfee`, value: `McAfee` },
  { key: `Medallia`, value: `Medallia` },
  { key: `Mentor Graphics Inc`, value: `Mentor Graphics Inc` },
  { key: `Metaswitch Networks`, value: `Metaswitch Networks` },
  { key: `MongoDB`, value: `MongoDB` },
  { key: `NetApp`, value: `NetApp` },
  { key: `Nutanix`, value: `Nutanix` },
  { key: `NVIDIA`, value: `NVIDIA` },
  { key: `One Medical`, value: `One Medical` },
  { key: `Palantir`, value: `Palantir` },
  { key: `POPSUGAR`, value: `POPSUGAR` },
  { key: `Qwil`, value: `Qwil` },
  { key: `reputation.com`, value: `reputation.com` },
  { key: `Robinhood`, value: `Robinhood` },
  { key: `Sandisk`, value: `Sandisk` },
  { key: `Seagate Technology`, value: `Seagate Technology` },
  { key: `Sila Nanotechnologies`, value: `Sila Nanotechnologies` },
  { key: `Space Systems Loral`, value: `Space Systems Loral` },
  { key: `SurveyMonkey`, value: `SurveyMonkey` },
  { key: `Synopsys`, value: `Synopsys` },
  { key: `thredUP`, value: `thredUP` },
  { key: `TuneIn`, value: `TuneIn` },
  { key: `Upwork`, value: `Upwork` },
  { key: `UserTesting`, value: `UserTesting` },
  { key: `WhatsApp`, value: `WhatsApp` },
  { key: `Xilinx`, value: `Xilinx` },
  { key: `Yahoo`, value: `Yahoo` },
  { key: `YouTube`, value: `YouTube` },
  { key: `Zscaler`, value: `Zscaler` },
  { key: `5AM Ventures`, value: `5AM Ventures` },
  { key: `Accel-KKR`, value: `Accel-KKR` },
  { key: `Altimeter Capital`, value: `Altimeter Capital` },
  { key: `Altos Ventures`, value: `Altos Ventures` },
  { key: `Andreessen Horowitz`, value: `Andreessen Horowitz` },
  { key: `Battery Ventures`, value: `Battery Ventures` },
  { key: `Bright Capital`, value: `Bright Capital` },
  { key: `Brilliant`, value: `Brilliant` },
  { key: `Canaan Partners`, value: `Canaan Partners` },
  { key: `Charles River Ventures`, value: `Charles River Ventures` },
  { key: `Cloudera`, value: `Cloudera` },
  { key: `Coderpad`, value: `Coderpad` },
  { key: `Codi`, value: `Codi` },
  { key: `Columbus Nova`, value: `Columbus Nova` },
  { key: `Craigslist`, value: `Craigslist` },
  { key: `DCM Ventures`, value: `DCM Ventures` },
  { key: `DeltaTrak`, value: `DeltaTrak` },
  { key: `Draper Fisher Jurvetson`, value: `Draper Fisher Jurvetson` },
  { key: `Farmstead`, value: `Farmstead` },
  { key: `Front`, value: `Front` },
  { key: `GGV Capital`, value: `GGV Capital` },
  { key: `GI Partners`, value: `GI Partners` },
  { key: `Grand Rounds`, value: `Grand Rounds` },
  { key: `Greylock Partners`, value: `Greylock Partners` },
  { key: `Handshake`, value: `Handshake` },
  { key: `Highland Capital Partners`, value: `Highland Capital Partners` },
  { key: `Institutional Venture Partners`, value: `Institutional Venture Partners` },
  { key: `InterWest Partners`, value: `InterWest Partners` },
  { key: `JetFuel`, value: `JetFuel` },
  { key: `Khosla Ventures`, value: `Khosla Ventures` },
  { key: `Kintone`, value: `Kintone` },
  { key: `Kleiner Perkins`, value: `Kleiner Perkins` },
  { key: `Kohlberg Kravis Roberts`, value: `Kohlberg Kravis Roberts` },
  { key: `LeapYear`, value: `LeapYear` },
  { key: `Lightspeed Venture Partners`, value: `Lightspeed Venture Partners` },
  { key: `Lucasfilm`, value: `Lucasfilm` },
  { key: `Makena Capital Management`, value: `Makena Capital Management` },
  { key: `Matchpoint Solutions`, value: `Matchpoint Solutions` },
  { key: `Mayfield Fund`, value: `Mayfield Fund` },
  { key: `Menlo Ventures`, value: `Menlo Ventures` },
  { key: `Mentor Graphics`, value: `Mentor Graphics` },
  { key: `Mohr Davidow Ventures`, value: `Mohr Davidow Ventures` },
  { key: `Morgenthaler`, value: `Morgenthaler` },
  { key: `New Enterprise Associates`, value: `New Enterprise Associates` },
  { key: `Nexus Venture Partners`, value: `Nexus Venture Partners` },
  { key: `Noyo`, value: `Noyo` },
  { key: `Onset Ventures`, value: `Onset Ventures` },
  { key: `Parabola`, value: `Parabola` },
  { key: `Procore Technologies`, value: `Procore Technologies` },
  { key: `Relay Ventures`, value: `Relay Ventures` },
  { key: `Seagate`, value: `Seagate` },
  { key: `Sequoia Capital`, value: `Sequoia Capital` },
  { key: `ServiceMax`, value: `ServiceMax` },
  { key: `Shasta Ventures`, value: `Shasta Ventures` },
  { key: `Sierra Ventures`, value: `Sierra Ventures` },
  { key: `Silver Lake Partners`, value: `Silver Lake Partners` },
  { key: `SmartZip`, value: `SmartZip` },
  { key: `Sofinnova Ventures`, value: `Sofinnova Ventures` },
  { key: `Storm Ventures`, value: `Storm Ventures` },
  { key: `Tempo Automation`, value: `Tempo Automation` },
  { key: `The Blackstone Group`, value: `The Blackstone Group` },
  { key: `The Westly Group`, value: `The Westly Group` },
  { key: `ThinkHR`, value: `ThinkHR` },
  { key: `Trinity Ventures`, value: `Trinity Ventures` },
  { key: `TriplePoint Capital`, value: `TriplePoint Capital` },
  { key: `U.S. Venture Partners`, value: `U.S. Venture Partners` },
  { key: `WorkPatterns`, value: `WorkPatterns` },
  { key: `Zerodown`, value: `Zerodown` },
  { key: `Quizlet`, value: `Quizlet` },
  { key: `Auth0`, value: `Auth0` },
  { key: `Atlassian`, value: `Atlassian` },
  { key: `1528 Webster`, value: `1528 Webster` },
  { key: `Convex`, value: `Convex` },
  { key: `Descript`, value: `Descript` },
  { key: `Dokkio`, value: `Dokkio` },
  { key: `DuckDuckGo`, value: `DuckDuckGo` },
  { key: `HackerRank`, value: `HackerRank` },
  { key: `Hivemapper`, value: `Hivemapper` },
  { key: `Joro`, value: `Joro` },
  { key: `Klarity Law`, value: `Klarity Law` },
  { key: `Lohika`, value: `Lohika` },
  { key: `Ockam`, value: `Ockam` },
  { key: `Oura`, value: `Oura` },
  { key: `OutreachCircle`, value: `OutreachCircle` },
  { key: `Pared`, value: `Pared` },
  { key: `Planful`, value: `Planful` },
  { key: `Sqreen`, value: `Sqreen` },
  { key: `Wiser Solutions`, value: `Wiser Solutions` },
  { key: `Apollo GraphQL`, value: `Apollo GraphQL` },
  { key: `Roku`, value: `Roku` },
  { key: `Infogain`, value: `Infogain` },
  { key: `280 Group`, value: `280 Group` },
  { key: `SVG Ventures`, value: `SVG Ventures` },
  { key: `Tascent`, value: `Tascent` },
  { key: `Sterling Communications`, value: `Sterling Communications` },
  { key: `Forcepoint`, value: `Forcepoint` },
  { key: `Acquia`, value: `Acquia` },
  { key: `AgilOne`, value: `AgilOne` },
  { key: `MetricStream`, value: `MetricStream` },
  { key: `Switchboard Software`, value: `Switchboard Software` },
  { key: `Tata Consultancy Services`, value: `Tata Consultancy Services` },
  { key: `Cognizant`, value: `Cognizant` },
  { key: `Infosys`, value: `Infosys` },
  { key: `IBM`, value: `IBM` },
];
