import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PATHS } from '@belong/common';
import { Text, Image } from '@belong/ui';
import { useProperties } from 'accounts/hooks/homeowners/use-properties';
import { getUnitsFromProperties } from 'accounts/utils/units';
import type { MaintenanceRecurrenceModel } from 'api/models';
import clsx from 'clsx';
import Grid from 'components/Grid/Grid';
import { Loading } from 'components/Loading/Loading';
import { STRINGS } from 'maintenance/strings';
import { homeOwnerTasks, residentTasks, getRecurringTasksByUnit } from 'maintenance/utils';
import { PropertyType } from 'models/enums';
import { MaintenanceRecurrenceNavBar } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-nav-bar';
import { fetchRecurringTasks } from 'store/redux/maintenance/actions';
import { selectRecurringTasks } from 'store/redux/maintenance/selectors';
import css from './add-task.module.css';

type MaintenanceRecurrentSubtaskProps = {
  history: RouteComponentProps['history'];
};

export const AddTask = ({ history }: MaintenanceRecurrentSubtaskProps) => {
  const [isLoadingTasks, setIsLoadingTasks] = useState(false);
  const dispatch = useDispatch();
  const isHomeowner = history.location.pathname.includes('homeowner');

  const { properties = [], isLoadingProperties } = useProperties(isHomeowner);
  const recurringTasks = useSelector<unknown, MaintenanceRecurrenceModel[]>(selectRecurringTasks);
  const units = getUnitsFromProperties(properties);
  const recurringTasksByUnit = getRecurringTasksByUnit(recurringTasks);
  const tasks = isHomeowner ? homeOwnerTasks : residentTasks;
  const hasMultipleHomes = units?.length > 1;
  const hasSingleHomeFamily = units?.some(
    (unit) => unit?.property.basicInfo.propertyType === PropertyType.SingleFamily
  );
  const home = units.pop();
  const activeTasks = isHomeowner
    ? recurringTasksByUnit[home?.basicInfo.unitId]
    : recurringTasksByUnit[Object.keys(recurringTasksByUnit).pop()];

  const navigateBack = useCallback(() => {
    history.push(isHomeowner ? PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE : PATHS.RESIDENTS_ACCOUNT_MAINTENANCE);
  }, [history, isHomeowner]);

  useEffect(() => {
    async function fetchRecurring() {
      setIsLoadingTasks(true);
      await dispatch(fetchRecurringTasks());
      setIsLoadingTasks(false);
    }

    fetchRecurring();
  }, [dispatch]);

  if (isLoadingProperties || isLoadingTasks) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <MaintenanceRecurrenceNavBar onClosePress={navigateBack} />
      <Grid className={clsx(css.grid)} extraPadding>
        <Text variant="h2" fontWeight="semibold" className="text-center mb-2xl">
          {STRINGS[isHomeowner ? 'add-title-homeowner' : 'add-title-resident']}
        </Text>
        <div className="flex justify-between items-end mb-2xl sm:items-center">
          <div className={clsx(css.description, { [css.resident]: !isHomeowner })}>
            <Text fontWeight="semibold" className="text-body mb-xs sm:text-h3-sm">
              {STRINGS[isHomeowner ? 'add-subtitle-homeowner' : 'add-subtitle-resident']}
            </Text>
            <Text className="text-p1 leading-p2 sm:leading-p1 sm:text-body">
              {STRINGS[isHomeowner ? 'add-body-homeowner' : 'add-body-resident']}
            </Text>
          </div>
          <div className={clsx(isHomeowner ? css.hoHeader : css.resHeader)}>
            <Image
              src={isHomeowner ? '/pro-services/create_ho_header.svg' : '/pro-services/create_res_header.png'}
              alt=""
            />
          </div>
        </div>
        {Object.keys(tasks).map((key) => {
          const group = tasks[key];

          if (isHomeowner && !hasSingleHomeFamily && !group.find((groupTask) => groupTask.multiUnit)) {
            return;
          }

          return (
            <>
              <Text fontWeight="semibold" variant="h3">
                {key}
              </Text>
              <div className={clsx('flex mt-sm mb-2xl last:mb-3xl', css.scrollbar)}>
                {group.map((task) => {
                  if (isHomeowner && !hasSingleHomeFamily && !task.multiUnit) {
                    return;
                  }

                  const isRecurringEnabled = task.recurring
                    ? activeTasks?.find((homeTask) => homeTask.recurringTask === task.key && !homeTask.isCancelled)
                    : false;

                  return (
                    <div
                      key={task.label}
                      className={clsx(
                        'mr-xs last:mr-2sm sm:last:mr-0 flex flex-col relative cursor-pointer hover:text-dark-navy transition-all duration-hover group',
                        css.container
                      )}
                      onClick={() => history.push(`${task.key}`)}
                    >
                      {!hasMultipleHomes && task.recurring && (
                        <div
                          className={clsx(
                            'absolute bg-opacity-60 border-2 border-solid rounded top-xs right-xs flex justify-center items-center z-10',
                            isRecurringEnabled ? 'bg-light-green border-green' : 'bg-red border-red',
                            css.pill
                          )}
                        >
                          <span className="text-white text-p1 font-semibold leading-p2 px-sm">
                            {isRecurringEnabled ? STRINGS.on : STRINGS.off}
                          </span>
                        </div>
                      )}
                      <div className={clsx('mb-xs rounded overflow-hidden', css.image)}>
                        <Image className="group-hover:scale-[1.2] duration-hover" src={task.image} alt={task.label} />
                      </div>
                      <Text className={clsx(css.subtitle)}>{task.label}</Text>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </Grid>
    </>
  );
};
