import React from 'react';
import { ClickTracker, Analytics } from 'analytics';
import { getStringFromJSX } from 'analytics/utils';
import classNames from 'classnames/bind';
import { snakeCase } from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './ButtonBase.module.css';

const cx = classNames.bind(styles);

const analytics = (props) => {
  const { label, children } = props;

  const labelString = getStringFromJSX(label);
  const childrenString = getStringFromJSX(children);

  return {
    click_action: props.action || 'unknown',
    text: snakeCase(labelString) || snakeCase(childrenString),
    click_component: 'button',
  };
};

const ButtonBase = React.forwardRef((props, ref) => {
  const { label, component, children, disabled, tabIndex, className: classNameProp, ...rest } = props;

  delete rest.newfieldcomponent;
  delete rest.newFieldComponent;

  const ComponentProp = component;
  const className = cx('buttonBase', classNameProp);
  return (
    <ComponentProp
      type="button"
      className={className}
      disabled={disabled}
      tabIndex={disabled ? '-1' : tabIndex}
      ref={ref}
      {...rest}
    >
      {children || label}
    </ComponentProp>
  );
});

ButtonBase.propTypes = {
  // Makes the button tabbable in a certain index. Defaults at 0. Change if in it's own component
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // Turns off the button.
  disabled: PropTypes.bool,

  // Component type i.e. submit/function or ...
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),

  // works like children
  label: PropTypes.node,

  // Used to add styles to a button base component
  className: PropTypes.string,

  // inner contents of the button
  children: PropTypes.node,
};

ButtonBase.defaultProps = {
  component: 'button',
  disabled: false,
  tabIndex: 0,
  label: '',
  className: '',
  children: '',
};

export default Analytics(analytics)(ClickTracker(ButtonBase));
