import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { Box, Flex, Text } from 'design-system';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import styles from './PlaceInLine.module.css';

const cx = classNames.bind(styles);

export const PlaceInLine = ({ value, qualifiedApplicants }) => {
  if (!value || !qualifiedApplicants) return null;
  return (
    <Flex flexDirection="column" alignItems="center">
      <Box mb="md">
        <GeneralIcon icon={GENERAL_ICONS.ICON_PLACE_IN_LINE} />
      </Box>
      <Box mb="xs">
        <Text fontWeight="semibold">
          You are <span className={cx('place')}>{numeral(value).format('0o')}</span> in line.
        </Text>
      </Box>
      {qualifiedApplicants === 1 ? (
        <Text textAlign="center" fontSize="p1">
          {`We’ll get right to work reviewing <span>your application.</span>`}
        </Text>
      ) : (
        <Text fontSize="p1">Out of {qualifiedApplicants} qualified applications.</Text>
      )}
    </Flex>
  );
};

PlaceInLine.propTypes = {
  value: PropTypes.number.isRequired,
  qualifiedApplicants: PropTypes.number.isRequired,
};
