import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export default class MetaDescription extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
  };

  render() {
    const { description } = this.props;

    return (
      <Helmet>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
      </Helmet>
    );
  }
}
