import { useCallback, useState } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { IconForward } from '@belong/icons';
import { Button, Text } from '@belong/ui';
import clsx from 'clsx';
import Collapsible from 'components/Collapsible/Collapsible';
import ItemDetail from 'components/ItemDetail/ItemDetail';
import { startCase } from 'es-toolkit';
import { DATE_FORMATS, formatDate } from 'utils/formatDate';

const COLLAPSED_MAX_ITEMS_VISIBLE = 3;

export const MaintenanceRecurrenceSubtasksList = ({ subtasks, onSubtaskClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowSize();

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  const renderItem = useCallback(
    (subtask, index) => {
      if (!isExpanded && index > COLLAPSED_MAX_ITEMS_VISIBLE - 1) {
        return null;
      }

      return (
        <Button onClick={() => onSubtaskClick(subtask.uniqueId)} variant="text">
          <ItemDetail
            className={clsx('group', index === subtasks?.length - 1 && 'mb-0')}
            key={subtask.uniqueId}
            firstItem={
              <Text className="pr-xs text-left capitalize" variant={isMobile ? 'p1' : 'body'}>
                {startCase(subtask.summary)}
              </Text>
            }
            secondItem={
              <div className="flex items-center">
                <Text
                  as="span"
                  className={clsx('pr-xs text-right capitalize', {
                    'text-green group-hover:text-dark-navy group-active:text-dark-navy group-focus:text-dark-navy transition-colors duration-hover':
                      subtask.subTaskStatus === 'Completed',
                    'text-dark-gray': subtask.subTaskStatus === 'Cancelled',
                  })}
                  fontWeight="semibold"
                  variant={isMobile ? 'p1' : 'body'}
                >
                  {getCompletionText(subtask)}
                </Text>
                <IconForward height={13} onClick={() => onSubtaskClick(subtask.uniqueId)} />
              </div>
            }
          />
        </Button>
      );
    },
    [isExpanded, onSubtaskClick]
  );

  return (
    <Collapsible
      defaultClassName="block"
      title={`Subtasks (${subtasks.length})`}
      collapsed={false}
      inner={
        <div className="flex flex-col items-stretch mt-xs">
          {subtasks.map(renderItem)}
          {subtasks.length > COLLAPSED_MAX_ITEMS_VISIBLE && (
            <div className="self-end pt-sm">
              <Button onClick={() => setIsExpanded((state) => !state)} variant="text">
                {isExpanded ? 'SHOW LESS' : 'SHOW MORE'}
              </Button>
            </div>
          )}
        </div>
      }
    />
  );
};

const getCompletionText = (subtask) => {
  if (subtask.subTaskStatus === 'OccurringAround') {
    return `Estimated Date: ${formatDate(subtask.requestNextOn, DATE_FORMATS.STANDARD_SHORT)}`;
  }

  if (subtask.subTaskStatus === 'Completed') {
    return `Completed On ${formatDate(subtask.appointmentNextOn, DATE_FORMATS.STANDARD_SHORT)}`;
  }

  if (subtask.subTaskStatus === 'Cancelled') {
    return `Cancelled On ${formatDate(subtask.closedOn, DATE_FORMATS.STANDARD_SHORT)}`;
  }

  if (subtask.subTaskStatus === 'Scheduled') {
    return `Scheduled ${formatDate(subtask.appointmentNextOn, DATE_FORMATS.STANDARD_SHORT)}`;
  }

  if (subtask.subTaskStatus === 'TurnedOff') {
    return `Turned Off ${formatDate(subtask.cancelledOn, DATE_FORMATS.STANDARD_SHORT)}`;
  }

  return '';
};
