import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PATHS } from '@belong/common';
import { IconCopy, IconFb, IconTwitter } from '@belong/icons';
import { Button, ButtonBase, Link, LinkBase, Text, Image } from '@belong/ui';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import { clearToasts, showToast } from 'store/redux/toast';
import { fetchCurrentUserDetails } from 'store/redux/user/actions';
import { selectUser } from 'store/redux/user/selectors';
import styles from './ShareableBanner.module.css';
import { SHAREABLE_STRINGS } from './constants';

const cx = classNames.bind(styles);
const toastTime = 4000;

type Props = {
  title?: string;
  body: string;
  shareableMessage: string;
  onShareSuccessful?: () => void;
  onShareFailure?: (error: Error) => void;
};

export function ShareableBanner({
  title,
  body,
  shareableMessage,
  onShareFailure = () => ({}),
  onShareSuccessful = () => ({}),
}: Props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!user?.referralUrl) {
      dispatch(fetchCurrentUserDetails());
    }
  }, [dispatch, user.referralUrl]);

  const referralLink = user.referralUrl;

  const shareLinks = [
    { url: `https://www.facebook.com/sharer.php?u=${referralLink}`, icon: IconFb },
    { url: `https://www.twitter.com/intent/tweet?url=${referralLink}&text=${shareableMessage}`, icon: IconTwitter },
  ];

  const updateClipboard = (newClip) => {
    if (navigator) {
      navigator.clipboard.writeText(newClip);
      dispatch(
        showToast({ message: 'Copied to clipboard', success: true }, { autoDelete: true, autoDeleteTime: toastTime })
      );
    }
  };

  const copyText = () => {
    dispatch(clearToasts());
    updateClipboard(user.referralUrl);
  };

  const onShare = async () => {
    if (navigator?.share) {
      await navigator
        .share({
          title: 'Belong Home',
          text: shareableMessage,
          url: referralLink,
        })
        .then(onShareSuccessful)
        .catch(onShareFailure);
    }
  };

  return (
    <div className={clsx('relative w-full flex flex-col items-start border border-solid border-green rounded')}>
      <div className="absolute top-0 md:left-0 md:top-auto md:right-0 z-0 h-full">
        <Image src="/referral/referral_money_rain_img.svg" alt="" />
      </div>
      <div className="w-full p-lg z-10">
        {title ? <Text>{title}</Text> : null}
        <Text variant="h3" fontWeight="semibold" className="text-white">
          <span className="text-navy">{body}</span>
        </Text>

        <div className="w-full flex justify-between p-sm bg-white my-sm border-solid border-1 border-gray rounded">
          <div className={cx('referralLinkContainer')}>
            <Text className="break-words md:break-normal">{referralLink}</Text>
          </div>
          <div className="flex items-center md:hidden">
            <ButtonBase type="button" onClick={copyText}>
              <IconCopy className="text-green" width={24} />
            </ButtonBase>
          </div>
          <div className="hidden md:contents">
            <ButtonBase type="button" onClick={copyText}>
              <Text fontWeight="semibold">{SHAREABLE_STRINGS.copyLink}</Text>
            </ButtonBase>
          </div>
        </div>

        <div className="flex w-full flex-col md:flex-row md:justify-between">
          <div className="flex items-center">
            <Text className="mr-2sm" fontWeight="semibold">
              Share to
            </Text>
            {shareLinks.map(({ url, icon: Icon }, idx) => (
              <LinkBase href={url} key={idx} target="_blank" className="mr-lg last:mr-0">
                <Icon width={27} height={24} />
              </LinkBase>
            ))}
          </div>
          <div className="mt-sm md:mt-0">
            <div className="hidden md:contents">
              <Link
                href={PATHS.REFERRAL_LANDING}
                variant="solid"
                target="_blank"
                className="flex justify-center items-center flex-1"
              >
                <Text className="mx-xl" fontWeight="semibold">
                  {SHAREABLE_STRINGS.learnMore}
                </Text>
              </Link>
            </div>
            <div className="md:hidden">
              <Button variant="solid" onClick={onShare}>
                <Text fontWeight="semibold">{SHAREABLE_STRINGS.referNow}</Text>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
