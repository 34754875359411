import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { MoveOutOptions } from 'models/enums';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { composeValidators, dateValidationMMDDYY, required } from 'utils/validation';
import { DateInputFinalFormAdapter } from 'components/DateInput/DateInput';
import Field from 'components/Field/Field';
import Condition from 'formcomponents/Condition/Condition';

export const MoveOutSpecificDateFields = ({
  extraMoveOutOptions = [],
  name,
  placeholder = RENTAL_STATUS_TEXT.moveOutQuestion,
  extraValidation = () => {},
}) => (
  <Condition isIncluded={[MoveOutOptions.OnSpecificDate, ...extraMoveOutOptions]} when={`${name}.moveoutOption`}>
    <Row>
      <Col xs={12} sm={6}>
        <Field
          component={DateInputFinalFormAdapter}
          name={`${name}.moveoutOn`}
          placeholder={placeholder}
          validate={composeValidators(required, dateValidationMMDDYY, extraValidation)}
        />
      </Col>
    </Row>
  </Condition>
);

MoveOutSpecificDateFields.propTypes = {
  extraMoveOutOptions: PropTypes.array,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  extraValidation: PropTypes.func,
};
