export default class PaymentTransaction {
  constructor(data = {}) {
    this.transactionId = data.transactionId;
    this.amount = data.amount;
    this.paymentMethod = data.paymentMethod;
    this.status = data.status;
    this.bankName = data.bankName;
    this.accountLastDigits = data.accountLastDigits;
    this.accountType = data.accountType;
    this.processedOn = data.processedOn;
    this.referenceNumber = data.referenceNumber;
    this.otherPaymentType = data.otherPaymentType;
  }
}
