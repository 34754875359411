export const downloadURL = 'https://dl.bln.hm/6BG2';

export const appStoreDownloadURL = 'https://apps.apple.com/us/app/belong/id1607471774';

export const playStoreDownloadURL = 'https://play.google.com/store/apps/details?id=com.belong.consumer.app.android';

export const stores = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
};
