import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PaymentIconDisplay from 'components/Payments/PaymentIconDisplay/PaymentIconDisplay';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import PropTypes from 'prop-types';
import { BUTTON_TYPES } from '../Button/buttonTypes';
import GeneralIcon, { GENERAL_ICONS } from '../GeneralIcon/GeneralIcon';
import MobileOverlay from '../MobileOverlay/MobileOverlay';
import styles from './DottedMenu.module.css';

const cx = classNames.bind(styles);

export const STYLED_MENUS = {
  NORMAL: 'normal',
  NO_BORDER_BETWEEN: 'noborderbetween',
};

class DottedMenu extends Component {
  static propTypes = {
    menuItems: PropTypes.array.isRequired,
    onMenuClick: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
    menuButtonLabel: PropTypes.node,
    menuActions: PropTypes.array,
    topMenuActions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        text: PropTypes.string,
        key: PropTypes.string.isRequired,
        icon: PropTypes.node.isRequired,
        children: PropTypes.node,
      })
    ),
    fitToButton: PropTypes.bool,
    onMenuActionClick: PropTypes.func.isRequired,
    hasNoPaymentMethod: PropTypes.bool,
    style: PropTypes.string,
    className: PropTypes.string,
    onTop: PropTypes.bool,
    manuallyShowMenu: PropTypes.bool,
  };

  static defaultProps = {
    onMenuOpen: () => {},
    onMenuClose: () => {},
    menuButtonLabel: null,
    menuActions: [],
    topMenuActions: [],
    fitToButton: false,
    hasNoPaymentMethod: false,
    style: STYLED_MENUS.NORMAL,
    className: '',
    manuallyShowMenu: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      menuHeight: 0,
    };
  }

  handleClick = (e) => {
    if (this.menuNode?.contains(e.target) || this.desktopNode?.contains(e.target)) {
      return;
    }

    this.handleMenuToggle();
  };

  handleMenuToggle = () => {
    const { onMenuOpen, onMenuClose } = this.props;
    const { showMenu } = this.state;

    this.setState({ showMenu: !showMenu }, () => {
      if (this.state.showMenu) {
        onMenuOpen();
        document.addEventListener('mousedown', this.handleClick, false);
      } else {
        onMenuClose();
        document.removeEventListener('mousedown', this.handleClick, false);
      }
    });
  };

  renderMenu() {
    const {
      menuItems,
      onMenuClick,
      menuActions,
      topMenuActions,
      onMenuActionClick,
      fitToButton,
      style,
      className,
      onTop = false,
      manuallyShowMenu = true,
    } = this.props;
    const { showMenu, menuHeight } = this.state;

    return (
      <MobileOverlay show={showMenu && manuallyShowMenu}>
        <div
          ref={(menuNode) => {
            this.menuNode = menuNode;
            if (menuNode?.offsetHeight > 0 && menuHeight === 0 && onTop) {
              this.setState({ menuHeight: menuNode?.offsetHeight });
            }
          }}
          {...(onTop
            ? {
                style: {
                  top: `-${menuHeight + 15}px`,
                },
              }
            : {})}
          className={cx('menu', { showMenu }, { fitToButton }, { [style]: style }, className)}
        >
          {!!topMenuActions.length && (
            <div className={cx('actions')}>
              {topMenuActions.map((action, index) => (
                <ButtonBase
                  key={index}
                  className={cx('top-action')}
                  onClick={() => {
                    onMenuActionClick(action.key);
                  }}
                >
                  <PaymentIconDisplay
                    buttonType={BUTTON_TYPES.TEXT}
                    title={action.name}
                    icon={action.icon}
                    noCustomWidth
                    text={action.text}
                  >
                    {action.children}
                  </PaymentIconDisplay>
                </ButtonBase>
              ))}
            </div>
          )}
          {menuItems.map((item, index) => (
            <ButtonBase
              key={index}
              className={cx('item', { disabled: item.disabled }, { [style]: style })}
              onClick={() => {
                onMenuClick(item.key, item.paymentMethod);
              }}
              disabled={item.disabled}
            >
              {item.name}
            </ButtonBase>
          ))}
          {!!menuActions.length && (
            <div className={cx('actions')}>
              {menuActions.map((action, index) => (
                <ButtonBase
                  key={index}
                  className={cx('action')}
                  onClick={() => {
                    onMenuActionClick(action.key);
                  }}
                >
                  <PaymentIconDisplay
                    buttonType={BUTTON_TYPES.TEXT}
                    title={action.name}
                    icon={action.icon}
                    noCustomWidth
                  />
                </ButtonBase>
              ))}
            </div>
          )}
        </div>
      </MobileOverlay>
    );
  }

  renderButton() {
    const { menuButtonLabel, fitToButton, hasNoPaymentMethod } = this.props;
    return (
      <ButtonBase
        className={cx('menu-button', { fitToButton }, { hasNoPaymentMethod })}
        onClick={this.handleMenuToggle}
      >
        {menuButtonLabel || <GeneralIcon icon={GENERAL_ICONS.DOTTED_MENU} />}
        {this.renderMenu()}
      </ButtonBase>
    );
  }

  render() {
    return (
      <>
        <div
          className={cx('desktop')}
          ref={(desktopNode) => {
            this.desktopNode = desktopNode;
          }}
        >
          {this.renderButton()}
        </div>
        <div className={cx('mobile')}>{this.renderButton()}</div>
      </>
    );
  }
}

export default DottedMenu;
