import { AutoCompleteFinalFormAdapter } from 'components/AutoComplete/AutoComplete';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCurrency, maskMMDDYYYY, unmaskMMDDYYYY, unmaskNumber } from 'components/Input/masks';
import { MEDIA_LABEL, NewMediaUploadAdapter, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { bayTechCompanies, forbes1000 } from 'consts/companies';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormCondition, { FIELD_CONDITION_COMPARISON_TYPES } from 'formcomponents/FormCondition/FormCondition';
import PropTypes from 'prop-types';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { composeValidators, dateValidation, isFutureDateMMDDYY, required } from 'utils/validation';

const JobFields = ({ name, form }) => (
  <>
    <Space value={SPACE_TYPES.LG} />
    <Row>
      <Col md={12}>
        <Text fontWeight="semibold">{RESIDENT_APPLICATION_STRINGS.employment_info.employments.job.company_title}</Text>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field
          name={`${name}.source`}
          component={AutoCompleteFinalFormAdapter}
          placeholder="Employer"
          min={3}
          customFilter={(value, items) =>
            items.filter((i) => i.value.toLowerCase().includes(value ? value.toLowerCase() : ''))
          }
          alwaysFilter
          items={forbes1000.concat(bayTechCompanies)}
          allowUserInput
          hideAutoCompleteIcon
          hideAutoCompleteIconOnMobile
          validate={required}
        />
      </Col>
      <Col md={6}>
        <Field name={`${name}.position`} component={InputFinalFormAdapter} placeholder="Position" validate={required} />
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <Field
          name={`${name}.estimatedAnnualIncome`}
          component={InputFinalFormAdapter}
          placeholder="Est. Annual Income ($ Pre-Tax)"
          validate={required}
          mask={maskCurrency}
          unmask={unmaskNumber}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <Space value={SPACE_TYPES.SM} />
        <Field
          name={`${name}.isCurrent`}
          component={SelectorFinalFormAdapter}
          validate={required}
          placeholder="Employer"
          buttons={[
            {
              key: true,
              label: 'Current',
            },
            {
              key: false,
              label: 'Future',
            },
          ]}
          type={SELECTOR_TYPES.SMALLTEXTBUTTONWIDTH}
        />
      </Col>
    </Row>
    <Space value={SPACE_TYPES.XS} />
    <FormCondition
      conditions={[
        [
          {
            fieldName: `${name}.isCurrent`,
            compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
            fieldValue: true,
          },
        ],
      ]}
      formValues={form.getState().values}
    >
      <Row>
        <Col md={12}>
          <Field
            name={`${name}.isLeavingSoon`}
            component={CheckboxFinalFormAdapter}
            useOnChangeFormToHandle
            alignWithField={false}
            label={RESIDENT_APPLICATION_STRINGS.employment_info.employments.job.ending_soon}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            name={`${name}.hasRecentlyStarted`}
            component={CheckboxFinalFormAdapter}
            useOnChangeFormToHandle
            alignWithField={false}
            label={RESIDENT_APPLICATION_STRINGS.employment_info.employments.job.future_start}
          />
        </Col>
      </Row>
    </FormCondition>
    <Row>
      <FormCondition
        conditions={[
          [
            {
              fieldName: `${name}.isCurrent`,
              compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
              fieldValue: false,
            },
          ],
        ]}
        formValues={form.getState().values}
      >
        <Col xs={12} md={6}>
          <Field
            name={`${name}.startedOn`}
            component={InputFinalFormAdapter}
            placeholder="Start Date (MM/DD/YYYY)"
            validate={composeValidators(required, dateValidation, isFutureDateMMDDYY)}
            mask={maskMMDDYYYY}
            unmask={unmaskMMDDYYYY}
          />
        </Col>
      </FormCondition>
      <FormCondition
        conditions={[
          [
            {
              fieldName: `${name}.isCurrent`,
              compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
              fieldValue: true,
            },
            {
              fieldName: `${name}.isLeavingSoon`,
              compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
              fieldValue: true,
            },
          ],
        ]}
        formValues={form.getState().values}
      >
        <Col xs={12} md={6}>
          <Field
            name={`${name}.endedOn`}
            component={InputFinalFormAdapter}
            placeholder="End Date (MM/DD/YYYY)"
            validate={composeValidators(required, dateValidation)}
            mask={maskMMDDYYYY}
            unmask={unmaskMMDDYYYY}
          />
        </Col>
      </FormCondition>
    </Row>
    <FormCondition
      conditions={[
        [
          {
            fieldName: `${name}.isCurrent`,
            compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
            fieldValue: false,
          },
        ],
        [
          {
            fieldName: `${name}.hasRecentlyStarted`,
            compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
            fieldValue: true,
          },
        ],
      ]}
      formValues={form.getState().values}
    >
      <Row>
        <Col md={12}>
          <Space />
          <Field
            name={`${name}.offerLetterFile`}
            validate={validateMedia}
            component={NewMediaUploadAdapter}
            mediaLabel={MEDIA_LABEL.OFFER_LETTER}
            defaultFileName="OfferLetter"
            isSingleMedia
            disableWebcam
          />
        </Col>
      </Row>
    </FormCondition>
  </>
);

JobFields.propTypes = {
  name: PropTypes.string,
  form: PropTypes.object.isRequired,
};

JobFields.defaultProps = {
  name: '',
};

export default JobFields;
