import { formatDateTime, DATE_FORMATS } from '@belong/common';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { AvailiabilityTimes } from 'models/enums';

// Format returned by API
// Eg: 2018-12-01T00:00:00 "yyyy-MM-dd'T'HH:mm:ssxxx"

// Helper function to convert user input to api-readable
// date-time string.
// @param
//   'mm/dd/yyyy'
// @return
//   'yyyy-MM-ddTHH:MM:SS<Offset If Applicable>
//   Eg. 2018-10-18T10:00:00
export const parseDateTimeInputString = (userInputDate, formatProp) => {
  if (userInputDate) {
    const [mm, dd, yyyy] = userInputDate.split('/');
    const dateTime = legacyParse(`${yyyy}/${mm}/${dd}`);
    const format = formatProp || DATE_FORMATS.STRIPPED_ISO;

    return formatDateTime({ dateTime, format });
  }
};

export const parseMMYYDateToFirstDayOfMonth = (userInputDate) => {
  const [mm, yy] = userInputDate.split('/');

  return `20${yy}-${mm}-01`;
};

export const parseDateTimeInputStringMMDDYY = (userInputDate, formatProp) => {
  if (userInputDate) {
    const [mm, dd, yy] = userInputDate.split('/');

    if (mm && dd && yy) {
      const dateTime = legacyParse(`20${yy}/${mm}/${dd}`);
      const format = formatProp || DATE_FORMATS.STRIPPED_ISO;

      return formatDateTime({ dateTime, format });
    }

    const dateTime = legacyParse(userInputDate);

    if (!Number.isNaN(dateTime)) {
      const format = formatProp || DATE_FORMATS.STRIPPED_ISO;

      return formatDateTime({ dateTime, format });
    }
  }
};

// Helper function to convert server response into
// a JavaScript date-time object
// @param
//   'YYYY-MM-DDTHH:mm:ss'
// @return
//   Date object (similar to new Date())
export const converDateTimeServerResponseToDateTimeObject = (dateString) => legacyParse(dateString);

// Helper function to only compare year, month and day
// for two date objects (ignores HH:MM:SS values)
// @param
//   dateTimeObjectOne: instanceOf(Date)
//   dateTimeObjectTwo: instanceOf(Date)
// @return
//   true // if equal
export const shallowCompareDateTimeObjects = (dateTimeObjectOne, dateTimeObjectTwo) => {
  const dateObjectOne = formatDateTime({ dateTime: legacyParse(dateTimeObjectOne), format: DATE_FORMATS.STRIPPED_ISO });
  const dateObjectTwo = formatDateTime({ dateTime: legacyParse(dateTimeObjectTwo), format: DATE_FORMATS.STRIPPED_ISO });

  return dateObjectOne === dateObjectTwo;
};

export const convertMMYYtoDate = (mmyy) => {
  if (mmyy) {
    const dd = '01';
    const [mm, yy] = mmyy.split('/');

    return new Date(`20${yy}`, parseInt(mm, 10) - 1, dd);
  }
};

export const timeFrame2timeSlot = (timeFrames = []) => {
  if (!timeFrames?.length) {
    return null;
  }

  return timeFrames.map((frame) => {
    switch (frame) {
      case AvailiabilityTimes.AllDay:
        return {
          startOnHour: 0,
          startOnMinute: 0,
          endOnHour: 23,
          endOnMinute: 59,
        };
      case AvailiabilityTimes.Morning:
        return {
          startOnHour: 8,
          startOnMinute: 0,
          endOnHour: 11,
          endOnMinute: 59,
        };
      case AvailiabilityTimes.Afternoon:
        return {
          startOnHour: 12,
          startOnMinute: 0,
          endOnHour: 15,
          endOnMinute: 59,
        };
      case AvailiabilityTimes.Evening:
        return {
          startOnHour: 16,
          startOnMinute: 0,
          endOnHour: 23,
          endOnMinute: 59,
        };
      default:
        return null;
    }
  });
};

export const timeSlot2timeFrame = (slots) => {
  if (!slots?.length) {
    return null;
  }

  return slots.map((timeSlot) => {
    if (timeSlot.startOnHour === 0) {
      return AvailiabilityTimes.AllDay;
    }

    if (timeSlot.startOnHour === 8) {
      return AvailiabilityTimes.Morning;
    }

    if (timeSlot.startOnHour === 12) {
      return AvailiabilityTimes.Afternoon;
    }

    if (timeSlot.startOnHour === 16) {
      return AvailiabilityTimes.Evening;
    }
    return null;
  });
};
