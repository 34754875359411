export const belongStandardsItems = [
  {
    imageUrl: '/improvement-reports/belong_standard_02.png',
    title: 'Safety & Security',
    body: 'We will always flag items we deem unsafe to minimize liability and keep your home and your residents safe.',
  },
  {
    imageUrl: '/improvement-reports/belong_secret_sauce.jpg',
    title: 'Belong’s Secret Sauce',
    body: 'These are items we are confident that increase the likelihood of your home getting loved quicker, reduce maintenance costs, and your residents staying longer in your home.',
  },
  {
    imageUrl: '/improvement-reports/belong_standard_03.png',
    title: 'Recommendations',
    body: 'These are optional repairs that have direct impact on your resident’s experience and will make your home shine. These are additional investments but provide strong return on investment.',
  },
];

export const wearAndTearItems = [
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_wall.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_wall_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_wall_excessive.jpg',
    beforeText: 'A few small tack or nail holes in the wall',
    afterText: 'Two-inch diameter hole in wall or ceiling',
    title: 'Wall Damage',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_floor.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_floor_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_floor_excessive.jpg',
    beforeText: 'Minor scuff marks or small chips in floor',
    afterText: 'Large scuff marks, gouges or tears in floor',
    title: 'Floor Damage',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_clutter.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_clutter_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_clutter_excessive.jpg',
    beforeText: 'Less than a few small items left behind',
    afterText: 'More than a few items or large items that require hauling',
    title: 'Clutter',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_pet.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_pet_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_pet_excessive.jpg',
    beforeText: 'Carpets or furniture worn thin by normal use',
    afterText: 'Urine stains or tears in carpets or furniture from pets',
    title: 'Pet Damage',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_lighting.jpg',
    // Image names are backwards here, don't wanna change the name here because drive is gonna be wrong.
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_lighting_excessive.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_lighting_normal.jpg',
    beforeText: 'Wiring or underlying electrical issues, resecuring fixtures',
    afterText: 'Missing or non-functional light bulbs',
    title: 'Lighting',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_bathroom.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_bathroom_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_bathroom_excessive.jpg',
    beforeText: 'Discoloration or deterioration of existing caulking or grout',
    afterText: 'Broken tiles or structural damage to tub or toilet',
    title: 'Bathroom Damage',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_blinds.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_blinds_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_blinds_excessive.jpg',
    beforeText: 'Dusty blinds or pull string mechanism doesn’t work',
    afterText: 'Missing or broken blind slats, shading rod broken or missing',
    title: 'Blinds',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_electrical.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_electrical_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_electrical_excessive.jpg',
    beforeText: 'Outlets not working, open ground, or wiring erosion',
    afterText: 'Broken or damaged receptacle cover, broken toggle switches',
    title: 'Electrical',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_appliances.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_appliances_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_appliances_excessive.jpg',
    beforeText: 'Cosmetic damages such as minor scratches or blemishes',
    afterText: 'Functional damage such as missing handles or broken components',
    title: 'Appliances',
  },
  {
    imageUrl: '/post_inspection/responsibility/wear_tear_banner_landscaping.jpg',
    beforeUrl: '/post_inspection/responsibility/wear_tear_compare_landscaping_normal.jpg',
    afterUrl: '/post_inspection/responsibility/wear_tear_compare_landscaping_excessive.jpg',
    beforeText: 'Overgrown weeds, dry spots in grass, or under watered plants',
    afterText: 'Torn up lawn, missing or damaged plants and flowers',
    title: 'Landscaping',
  },
];
