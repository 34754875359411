import React from 'react';
import classNames from 'classnames/bind';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Image from 'components/Image/Image';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import Money from 'components/Money/Money';
import Tooltip from 'components/Tooltip/Tooltip';
import HomeInfo from 'components/display/Home/HomeInfo/HomeInfo';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { formatSecondLine, formatFirstLine } from 'utils/formatAddress';
import styles from './Card.module.css';

const cx = classNames.bind(styles);

const Card = ({ home, onHide }) => {
  const DisplayLastPublishedColumn = () => (
    <div className={cx('column-wrapper')}>
      <div className={cx('tooltip-wrapper')}>
        <Text fontSize="p1">{POST_INSPECTION_FLOW_STRINGS['map.card.last_published']}</Text>
        <Tooltip className={cx('margin-left-wrapper', 'tooltip-side')}>
          <div>
            <Text fontWeight="semibold">{POST_INSPECTION_FLOW_STRINGS['map.card.tooltip_title_1']}</Text>
          </div>
          <div>
            <Text>{POST_INSPECTION_FLOW_STRINGS['map.card.tooltip_subtitle_1']}</Text>
          </div>
          <Space />
          <Text>
            {home.recentlyOffMarket
              ? POST_INSPECTION_FLOW_STRINGS['map.card.tooltip_body_rental_2']
              : POST_INSPECTION_FLOW_STRINGS['map.card.tooltip_body_rental_1']}
          </Text>
        </Tooltip>
      </div>
      <Space value={SPACE_TYPES.XXS} />
      <div>
        <Text fontWeight="semibold">
          <Money cash={home.rentAmount} />
          /mo
        </Text>
      </div>
      {home.recentlyOffMarket && (
        <>
          <Space value={SPACE_TYPES.XS} />
          <Label color={LABEL_COLOR_TYPES.RED} text="Inflated" />
        </>
      )}
    </div>
  );

  const DisplayDaysListedColumn = () => (
    <div className={cx('column-wrapper')}>
      <div className={cx('tooltip-wrapper')}>
        <Text fontSize="p1">Days Listed</Text>
        <Tooltip className={cx('margin-left-wrapper')}>
          <div>
            <Text fontWeight="semibold">{POST_INSPECTION_FLOW_STRINGS['map.card.tooltip_title_2']}</Text>
          </div>
          <div>
            <Text>{POST_INSPECTION_FLOW_STRINGS['map.card.tooltip_subtitle_2']}</Text>
          </div>
          <Space />
          <Text>{POST_INSPECTION_FLOW_STRINGS['map.card.tooltip_body_2']}</Text>
        </Tooltip>
      </div>
      <Space value={SPACE_TYPES.XXS} />
      <div>
        <div className={cx('days-listed-wrapper')}>
          <Text fontWeight="semibold">{home.daysListed}</Text>
        </div>
      </div>

      {home.overpriced && (
        <>
          <Space value={SPACE_TYPES.XS} />
          <Label color={LABEL_COLOR_TYPES.RED} text="Overpriced" />
        </>
      )}
    </div>
  );

  return (
    <>
      <div className={cx('card')}>
        {/*  */}
        <div className={cx('left-side')}>
          <div className={cx('format-address')}>
            <Text>{`${formatFirstLine(home.address, home.address.unitNumber)}`}</Text>
            <div>
              <Text>{formatSecondLine(home.address)}</Text>
            </div>
          </div>
          <Space />
          <div className={cx('home-address')}>
            {/* should we use objects instead of instance ofs? */}
            <HomeInfo small unitBasic={home} />
          </div>
        </div>
        <div className={cx('gray-line')} />

        <div className={cx('right-side')}>
          {home.current ? (
            <div className={cx('column-wrapper')}>
              <Text fontWeight="semibold">{POST_INSPECTION_FLOW_STRINGS['map.not_listed_1']}</Text>
              <div>
                <Text fontWeight="semibold">
                  {formatString(POST_INSPECTION_FLOW_STRINGS['map.not_listed_2'], {
                    heart: (
                      <Image
                        // Don't know where to put the heart at the moment
                        src="/icons/graphic_heart.svg"
                        className={cx('icon')}
                        alt="Heart"
                      />
                    ),
                  })}
                </Text>
              </div>
            </div>
          ) : (
            <>
              <DisplayLastPublishedColumn />
              <DisplayDaysListedColumn />
            </>
          )}
        </div>

        <div className={cx('icon-button-wrapper')}>
          <IconButton
            paddingCustomClassName={cx('icon-wrapper')}
            action="close"
            onClick={onHide}
            icon={GENERAL_ICONS.CLOSE}
            align={ALIGN_TYPES.NONE}
          />
        </div>
      </div>

      <div className={cx('mobile-card')}>
        <div className={cx('top-side')}>
          <div className={cx('format-address')}>
            <Text>{`${formatFirstLine(home.address, home.address.unitNumber)}`}</Text>
            <div>
              <Text>{formatSecondLine(home.address)}</Text>
            </div>
          </div>
          <Space value={SPACE_TYPES.XS} />
          <div className={cx('home-address')}>
            {/* should we use objects instead of instance ofs? */}
            <HomeInfo small unitBasic={home} />
          </div>
        </div>

        <Space />

        <div className={cx('bottom-side')}>
          {home.current ? (
            <div className={cx('column-wrapper')}>
              <Text fontWeight="semibold">{POST_INSPECTION_FLOW_STRINGS['map.not_listed_1']}</Text>
              <div>
                <Text fontWeight="semibold">
                  {formatString(POST_INSPECTION_FLOW_STRINGS['map.not_listed_2'], {
                    heart: (
                      <Image
                        // Don't know where to put the heart at the moment
                        src="/icons/graphic_heart.svg"
                        className={cx('icon')}
                        alt="Heart"
                      />
                    ),
                  })}
                </Text>
              </div>
            </div>
          ) : (
            <>
              <div className={cx('flex-display')}>
                <DisplayLastPublishedColumn />
                <div className={cx('gray-line')} />
                <DisplayDaysListedColumn />
              </div>
            </>
          )}
        </div>

        <div className={cx('icon-button-wrapper')}>
          <IconButton
            paddingCustomClassName={cx('icon-wrapper')}
            action="close"
            onClick={onHide}
            icon={GENERAL_ICONS.CLOSE}
            align={ALIGN_TYPES.NONE}
          />
        </div>
      </div>
    </>
  );
};

Card.propTypes = {
  home: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default Card;
