import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAccountUnitListingsWithPros } from 'store/redux/homeowner-accounts/actions';

export function useListings() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState<any>();

  const fetchListings = useCallback(async () => {
    setLoading(true);

    const listingsResponse = await dispatch(fetchAccountUnitListingsWithPros());

    setListings(listingsResponse);
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  return {
    loading,
    listings,
  };
}
