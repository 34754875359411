import React, { Component } from 'react';
import { PATHS } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import PropTypes from 'prop-types';
import ModalButton from '../ModalButton';
import styles from './FormatBottomBar.module.css';

const cx = classNames.bind(styles);

export default class FormatBottomBar extends Component {
  static propTypes = {
    noButton: PropTypes.bool,
    cancelRequest: PropTypes.bool,
    leftSideNode: PropTypes.node,
    rightNode: PropTypes.node.isRequired,
    makeNode: PropTypes.node,
    ctaProps: PropTypes.object,
    selected: PropTypes.bool.isRequired,
    noMarginOrPaddingOnMobileForButton: PropTypes.bool.isRequired,
    // Used for passing down form props into FormatBottomBar
    nextButtonWrapperProps: PropTypes.object,
  };

  static defaultProps = {
    ctaProps: null,
    makeNode: null,
    leftSideNode: null,
    cancelRequest: false,
    noButton: false,
    // Used for passing down form props into FormatBottomBar
    nextButtonWrapperProps: {},
  };

  handleClick = () => {};

  renderRightSide = () => {
    const {
      rightNode,
      noButton,
      cancelRequest,
      noMarginOrPaddingOnMobileForButton,
      ctaProps,
      // Used to handle next button from form logic.
      nextButtonWrapperProps,
    } = this.props;

    if (rightNode) {
      return rightNode;
    }

    return !noButton ? (
      <ModalButton
        noMarginOrPaddingOnMobileForButton={noMarginOrPaddingOnMobileForButton}
        nextButtonWrapperProps={nextButtonWrapperProps}
        {...ctaProps}
      />
    ) : (
      cancelRequest && <ButtonBase className={cx('cancel')} label="CANCEL REQUEST" {...ctaProps} />
    );
  };

  render() {
    const { makeNode } = this.props;
    let { leftSideNode } = this.props;

    if (leftSideNode === null) {
      leftSideNode = (
        <div className={cx('left-side-node')}>
          Questions or concerns?&nbsp;
          <LinkBase href={PATHS.SUPPORT}>
            <Text fontWeight="semibold" className="hover:text-green">
              LET'S TALK
            </Text>
          </LinkBase>
        </div>
      );
    }

    return (
      <>
        {leftSideNode}
        {makeNode}
        {this.renderRightSide()}
      </>
    );
  }
}
