import { connect } from 'react-redux';
import { template } from '@belong/common';
import { Button } from '@belong/ui';
import { Confetti } from 'animations/Confetti/Confetti';
import classNames from 'classnames/bind';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { ShareableBanner } from 'components/Referral/ShareableBanner';
import String from 'components/String/String';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import { getDefaultHomeownerGrowth } from 'consts/employee-assignments';
import { BASE_PATHS } from 'routes/paths';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import styles from './SuccessPage.module.css';

const cx = classNames.bind(styles);

interface SuccessPageProps {
  employeeAssignment: {
    employee: Record<string, unknown>;
    user: Record<string, unknown>;
    employeeAssignment: {
      assignmentType: string;
    };
  };
  isPrimaryUser: boolean;
  history: {
    push: (url: string) => void;
  };
  propertyURL?: string;
  loading: boolean;
}

const SuccessPage: React.FC<SuccessPageProps> = ({
  employeeAssignment,
  history,
  isPrimaryUser,
  loading,
  propertyURL,
}) => {
  const primaryUserName = new URLSearchParams(window.location.search).get('primaryOwnerName');
  const employee = employeeAssignment
    ? {
        ...employeeAssignment?.employee,
        ...employeeAssignment?.user,
        ...employeeAssignment?.employeeAssignment,
        jobTitle: EMPLOYEE_TITLES[employeeAssignment?.employeeAssignment?.assignmentType],
      }
    : {
        ...getDefaultHomeownerGrowth().employee,
        ...getDefaultHomeownerGrowth().user,
        ...getDefaultHomeownerGrowth().employeeAssignment,
        jobTitle: EMPLOYEE_TITLES[getDefaultHomeownerGrowth().employeeAssignment?.assignmentType],
      };

  if (loading) {
    return <FullPageSpinner />;
  }

  const profileBubbleText = isPrimaryUser
    ? AGREEMENT_FLOW_STRINGS['success.default']
    : template(AGREEMENT_FLOW_STRINGS['success.co-signer.signatures-left'], {
        primaryHOName: primaryUserName,
      });

  return (
    <>
      <Confetti />
      <div className={cx('success-page', 'w-full xl:w-container-xl mt-4xl')}>
        <div className={cx('content')}>
          <TextBubbleWithUserProfile employee={employee} text={profileBubbleText} />
          {!isPrimaryUser && (
            <div className={cx('green-info-wrapper')}>
              <GreenInfoBox>
                <p className={cx('semibold')}>{AGREEMENT_FLOW_STRINGS['success.non-signer.info-title']}</p>
                <String string={AGREEMENT_FLOW_STRINGS['success.non-signer.info']} />
              </GreenInfoBox>
            </div>
          )}
          <div className={cx('button-section')}>
            <Button onClick={() => history.push(propertyURL || BASE_PATHS.ACCOUNTS)} className={cx('button')}>
              {AGREEMENT_FLOW_STRINGS['success.cta']}
            </Button>
          </div>
          {isPrimaryUser && (
            <div className="mt-2xl mb-5xl">
              <ShareableBanner
                title={AGREEMENT_FLOW_STRINGS.shareableInfo.bannerTitle}
                body={AGREEMENT_FLOW_STRINGS.shareableInfo.bannerMessage}
                shareableMessage={AGREEMENT_FLOW_STRINGS.shareableInfo.shareableMessage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  accountProperties: selectAccountProperties(state),
});

export default connect(mapStateToProps)(SuccessPage);
