import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { MaintenancePaymentMethod } from 'models/enums';

type PaymentMethodContextType = {
  paymentMethod: keyof typeof MaintenancePaymentMethod;
  setPaymentMethod: Dispatch<SetStateAction<keyof typeof MaintenancePaymentMethod>>;
};

export const PaymentMethodContext = createContext<PaymentMethodContextType | null>(null);

type Props = {
  children: ReactNode;
};

export const PaymentMethodProvider = ({ children }: Props) => {
  const [paymentMethod, setPaymentMethod] = useState(null);

  return (
    <PaymentMethodContext.Provider value={{ paymentMethod, setPaymentMethod }}>
      {children}
    </PaymentMethodContext.Provider>
  );
};
