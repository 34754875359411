import { useContext, useState } from 'react';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { PaymentStepperBackButton } from 'post-inspection/components/payment-stepper-back-button/payment-stepper-back-button';
import { PaymentMethodProvider } from 'post-inspection/contexts/payment-method';
import { useImprovementReportPaymentSteps } from 'post-inspection/hooks/use-improvements-report-payment-steps';

type Props = {
  onHide: () => void;
  isVisible: boolean;
  inspectionType: { isPreMoveOut: boolean; isOnboarding: boolean };
};

export const PaymentModal = ({ onHide, isVisible, inspectionType }: Props) => {
  const { isSubmitting, setIsSubmitting, homeownerReportPayment, isLegacyInvoicing, isDoNotExceedGroup } =
    useContext(ApprovalContext);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const { steps } = useImprovementReportPaymentSteps({ isLegacyInvoicing, inspectionType, isDoNotExceedGroup });

  const handleBack = () => setCurrentStepIndex((prevCurrentStepIndex) => prevCurrentStepIndex - 1);
  const handleNext = () => setCurrentStepIndex((prevCurrentStepIndex) => prevCurrentStepIndex + 1);

  const showBackButton = !(homeownerReportPayment.groupDueNow === 0 && currentStepIndex === 2);

  const { Component } = steps[currentStepIndex];

  return (
    <ModalV2
      closeButton
      name={ANALYTICS_MODAL_NAMES.PAYMENT_PLAN}
      noPadding
      onHide={onHide}
      show={isVisible}
      showSpinner={isSubmitting}
    >
      <div className="relative">
        {Boolean(currentStepIndex) && showBackButton && <PaymentStepperBackButton onClick={handleBack} />}
        <PaymentMethodProvider>
          <Component isLegacyInvoicing={isLegacyInvoicing} onNextStepClick={handleNext} setLoading={setIsSubmitting} />
        </PaymentMethodProvider>
      </div>
    </ModalV2>
  );
};
