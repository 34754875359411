import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PATHS } from '@belong/common';
import { Toast } from '@belong/ui';
import String from 'components/String/String';
import { BookAProButton } from 'maintenance/components/book-a-pro-button/book-a-pro-button';
import { PropertyTaskSectionList } from 'maintenance/components/property-task-section-list/property-task-section-list';
import { URL_QUERY_PARAMS } from 'maintenance/constants';
import { STRINGS } from 'maintenance/strings';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import PropTypes from 'prop-types';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { fetchHomeownerInspections } from 'store/redux/inspections';
import { fetchHomeOwnersMaintenance, fetchRecurringTasks } from 'store/redux/maintenance/actions';
import { HOMEOWNER_ACCOUNTS_STRINGS } from 'strings/homeowner-accounts.strings';
import { AddMaintenanceButton } from 'components/AddMaintenanceButton/AddMaintenanceButton';

class Maintenance extends Component {
  state = {
    loading: true,
    isToastVisible: false,
    isSuccessToast: true,
    toastText: '',
  };

  async componentDidMount() {
    try {
      await Promise.all([
        this.props.fetchHomeownerInspections(),
        this.props.fetchHomeOwnersMaintenance(),
        this.props.fetchRecurringTasks(),
      ]);

      const parsedURL = new URLSearchParams(this.props.location.search);

      const toastVisibleURLParam = parsedURL.get(URL_QUERY_PARAMS.TOAST_VISIBLE);
      const successURLParam = parsedURL.get(URL_QUERY_PARAMS.SUCCESS);
      const creationURLParam = parsedURL.get(URL_QUERY_PARAMS.CREATION);
      const turnOffURLParam = parsedURL.get(URL_QUERY_PARAMS.TURN_OFF);
      const updateFrequencyURLParam = parsedURL.get(URL_QUERY_PARAMS.UPDATE_FREQUENCY);
      const updateURLParam = parsedURL.get(URL_QUERY_PARAMS.UPDATE);

      const isSuccessToast = successURLParam === 'true';

      let toastText = '';

      if (isSuccessToast) {
        if (creationURLParam) {
          toastText = STRINGS['submitted-pro-service'];
        }

        if (turnOffURLParam) {
          toastText = STRINGS['turn-off-recurring'];
        }

        if (updateFrequencyURLParam) {
          toastText = STRINGS['change-frequency'];
        }

        if (updateURLParam) {
          toastText = STRINGS['updated-pro-service'];
        }
      }

      this.setState({
        loading: false,
        isToastVisible: toastVisibleURLParam === 'true',
        isSuccessToast,
        toastText,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  closeToast = () => {
    const { history, location } = this.props;

    this.setState(
      {
        isToastVisible: false,
      },
      () => {
        history.replace(location.pathname);
      }
    );
  };

  navigateToMaintenanceDetails = (task) => () => {
    const { history } = this.props;

    if (task.repeatFrequencyDays) {
      history.push(`${PATHS.HOMEOWNER_ACCOUNT_RECURRING_MAINTENANCE}/${task.homeUniqueId}/${task.recurringTask}`);
    } else {
      history.push(`${PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE}/${task.uniqueId}`);
    }
  };

  render() {
    const { history } = this.props;
    const { loading, isToastVisible, isSuccessToast, toastText } = this.state;

    return (
      <>
        <AccountPage
          loading={loading}
          headerProps={{
            headerText: HOMEOWNER_ACCOUNTS_STRINGS.maintenance.title,
            subtitle: <String string={HOMEOWNER_ACCOUNTS_STRINGS.maintenance.subtitle} />,
          }}
          cta={
            <AddMaintenanceButton label={STRINGS['submit-request']} onPress={() => history.push('/homeowner/pro-services/Repair')} />
          }
        >
          <PropertyTaskSectionList showMaintenanceModal={this.navigateToMaintenanceDetails} />
          <BookAProButton />
        </AccountPage>
        <Toast isVisible={isToastVisible} onClose={this.closeToast} variant={isSuccessToast ? 'default' : 'danger'}>
          {toastText}
        </Toast>
      </>
    );
  }
}

Maintenance.propTypes = {
  fetchHomeOwnersMaintenance: PropTypes.func.isRequired,
  fetchHomeownerInspections: PropTypes.func.isRequired,
  fetchRecurringTasks: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

function mapStateProps(state) {
  return {
    accountProperties: selectAccountProperties(state),
  };
}
const mapDispatchToProps = {
  fetchHomeOwnersMaintenance,
  fetchRecurringTasks,
  fetchHomeownerInspections,
};

export default connect(mapStateProps, mapDispatchToProps)(Maintenance);
