export const EARNINGS_STRINGS = {
  'page.title': 'Earnings',
  'section.bank.title': 'Accounts',
  'section.past_earnings.title': 'Receipts',
  'section.past_months.title': 'Past Months',
  'modal.past_month.title': 'Past Month',
  'section.pending_earnings.title': 'Pending Earnings',
  'section.upcoming.title': 'Upcoming',
  'section.transaction.no_history': 'There are no receipts currently.',
  'section.split.title': (cashback) => `Need an extra <strong>${cashback}</strong> this\u00a0month?`,
  'section.split.subtitle': () => `Split eligible expenses.`,
  'section.split.action': 'Split It',
  'section.split.actionPay': 'Pay It',
  all_homes: 'All Homes',
  'last_update.label': 'Last Update',
  'paid.label': 'Paid',
  'due.label': 'Due',
  'paid_on.label': 'Paid On',
  'failed.label': 'Failed On',
  'split_by.label': 'Split by',
  'splitting_on.label': 'Splitting On',
  'processing.label': 'Processing',
  'refunded.label': 'Refunded',
  'partially_refunded.label': 'Partially Refunded',
  'charging.label': 'Charging On',
  'paying_on.label': 'Paying On',
  'initiated.label': 'Initiated',
  'expected_by.label': 'Expected By',
  'delayed.label': 'Delayed',
  'due_date_delayed.tooltip':
    "We haven't been able to successfully charge your resident yet. Your member success lead is working on resolving it as soon as possible.",
  'due_date_estimation.tooltip':
    'Payments typically take 5-7 business days to complete, from the moment the charge was initiated. If the payment is unsuccessful, it will be retried automatically.',
  'scheduled.label': 'Scheduled for',
  'show_more.label': 'SHOW MORE',
  'show_less.label': 'SHOW LESS',
  'processed.label': 'Processed',
  'item_details.label': 'Payment Details',
  'id.label': 'ID',
  'paid_from.label': 'Paid From',
  'no_transfers.label': 'No Transfers Yet',
  'plan_starting.label': 'Plan Starting',
  'amount_financed.label': 'Amount Financed',
  'number_of_installments.label': 'Number of Installments',
  'monthly_convenience_fee.label': 'Interest',
  'total_monthly_payments.label': 'Total Monthly Payments',
  'view_truth_lending_disclosures.label': 'VIEW TRUTH IN LENDING DISCLOSURES',
  'finance_success_modal.title': 'Consider it split!',
  'finance_success_modal.sub_title':
    'This will be split into monthly installments, which\u00a0you’ll see on your monthly bills. You can view terms of\u00a0this plan in your account as well.',
  'finance_success_modal.done_cta': 'Done',
  balance_new_charge: 'New Charge',
  'monthly_statement.total': 'Total',
  'monthly_statement.total_paid': 'Total Paid',
  'monthly_statement.homes_total': 'Homes Total',
  'monthly_statement.installment': 'Installment',
  'monthly_statement.total_financed': 'Total Financed',
  'monthly_statement.download_pdf': 'DOWNLOAD PDF',
};
