import { Avatar, ButtonBase, Text } from '@belong/ui';
import { EmployeeAssignmentDetailModel } from 'api/models';

type MaintenanceHomecareSpecialistProps = {
  homecareSpecialist: EmployeeAssignmentDetailModel;
  onScheduleACallPress: () => void;
};

export const MaintenanceHomecareSpecialist = ({
  homecareSpecialist,
  onScheduleACallPress,
}: MaintenanceHomecareSpecialistProps) => {
  return (
    <div className="w-full py-lg mb-xl border border-dashed border-gray border-l-0 border-r-0 flex flex-col md:flex-row md:items-center md:justify-between">
      <div className="flex items-center">
        <Avatar size={40} src={homecareSpecialist.user?.profileImageUrl} />
        <div className="ml-sm">
          <Text fontWeight="semibold">{homecareSpecialist.user.name}</Text>
          <Text variant="p1">Your Homecare Specialist</Text>
        </div>
      </div>

      <ButtonBase className="mt-lg md:mt-0 self-end md:self-center" onClick={onScheduleACallPress}>
        <Text className="text-green hover:text-dark-green transition-colors duration-hover" fontWeight="semibold">
          SCHEDULE A CALL WITH ME
        </Text>
      </ButtonBase>
    </div>
  );
};
