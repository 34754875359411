import * as React from 'react';

// TODO: Not conviced about name, ask around
function useIsClientReady() {
  var _a = React.useState(false),
    isClientReady = _a[0],
    setIsClientReady = _a[1];
  React.useEffect(function () {
    setIsClientReady(true);
  }, []);
  return isClientReady;
}

export { useIsClientReady };
