import { US_STATES } from '@belong/common';

export const AGREEMENT_FLOW_STRINGS = {
  // 'UserPersonal.tips.title': 'Change of Address?',
  'UserPersonal.tips.description': [
    'We take the security of your personal information seriously. It stays between us; we’ll never give <span>it out.</span>',
  ],
  personal_info: {
    title: 'You are a few steps away from joining\u00a0Belong.',
    subTitle:
      'Please confirm your <span>personal information</span> so that we can generate <span>tax forms</span> and send you documents <span>in the future.</span>',
    is_name_legal_question: "Is '{firstName} {lastName}' your legal name?",
    is_name_legal_yes: "Yes, that's correct.",
    is_name_legal_no: "No, it's different.",
    legal_first_name: 'Legal first name',
    legal_middle_name: 'Legal middle name',
    legal_last_name: 'Legal last name',
    date_of_birth: 'Date of Birth (MM/DD/YYYY)',
    current_address: 'Current address',
    unit_number: 'Unit',
    same_mailing_address: 'THIS IS ALSO MY MAILING ADDRESS.',
    where_to_send_title: 'Where can we send you important documents?',
    mailing_address: 'Mailing address',
    next_button_text: 'Next',
  },

  //Plan page
  'Plan.tips.description': [
    'Great! Once we match you with your perfect Belong residents, we’ll deposit your rent on the first every\u00a0month,\u00a0guaranteed.',
  ],
  'plan.tips.guaranteed.title':
    "Great! Once we find someone to love your home, we'll deposit your rent on the 1st of each month, no matter what.",
  'plan.tips.upfront.title':
    "Ok! The moment we find someone to love your home, we'll pay you the entire year's worth of income. In the meantime, if you currently have residents, we'll deduct 8% monthly from the time we start managing.",
  'plan.tips.lets-discuss.title': "Ok! I'll reach out ASAP, or feel free to contact me now.",
  'plan.title': 'Let’s get your <span>home loved.</span>',
  'plan.title-plural': 'Let’s get your <span>homes loved.</span>',
  'plan.which-plan.section-title':
    'When we find someone to love your home, how would you like to <span>be paid?</span>',
  'plan.which-plan.section-subtitle': 'You can always change this later, and again each time your home is vacant.',
  'plan.which-plan.section-subtitle.plural':
    'You can always change this later, and again each time one of your homes become vacant.',
  'plan.selector.guaranteed': 'Guaranteed Monthly',
  'plan.selector.guaranteed.subLabel': '{guaranteedManagementFee}% Management Fee',
  'plan.selector.upfront': 'Annual Upfront',
  'plan.selector.upfront.subLabel': '{upfrontManagementFee}% Management Fee',
  'plan.lets-discuss.label': "Not sure! Let's discuss it.",
  'plan.tax-deductible.text': 'Both Tax Deductible',
  'plan.tax-deductible.compare': 'Compare Plans',

  // Ownership page
  // 'Ownership.tips.title': 'Change of Address?',
  'Ownership.tips.description':
    'A growing form of identity theft involves impersonating a homeowner to collect rental income. To help combat this, later, we’ll verify the identity of each individual on title we’re <span>working with.</span>',
  'ownership.title': 'Of those who own the home, who will we primarily be working with?',
  'ownership.title.plural': 'Of those who own the homes, who will we primarily be working with?',
  'ownership.subtitle': 'Add a party on title who will be making decisions with Belong.',
  'ownership.another-individual.section-title': 'Let’s meet them!',
  'ownership.another-individual.section-subtitle':
    "We'll reach out to get some information and have them sign an agreement.",
  'ownership.selector.me': 'Me',
  'ownership.selector.me.sublabel': '(as an individual)',
  'ownership.selector.another-individual': 'Another Individual',
  'ownership.selector.entity': 'Entity or Trust',
  'ownership.first-name': 'First Name',
  'ownership.last-name': 'Last Name',
  'ownership.email': 'Email',
  'ownership.phone': 'Phone',
  'ownership.entity.business-name': 'Business Name',
  'ownership.entity.self-managed': 'I manage this entity',
  'ownership.entity.who-is.title': 'Who is?',
  'ownership.email.error': 'Cannot be same as your email',

  // Payment page
  // 'Payment.tips.title': 'Change of Address?',
  'Payment.tips.description':
    'Later, we’ll ask some additional information about this individual or entity to prepare tax forms come <span>tax season.</span>',
  'payment.title': 'One last thing... who should we make the checks out to?',
  'payment.subtitle': 'This will also be the primary point of contact when it comes to decision-making.',
  'payment.selector.me': 'Me',
  'payment.selector.me.sublabel': '(as an individual)',
  'payment.selector.another-individual': 'Another Individual',

  //Sign step
  // 'Sign.tips.title': '',
  // TODO: Regions: Check it
  'Sign.tips.description': {
    [US_STATES.CA
      .code]: `Belong is licensed by the California Department of Real Estate (DRE License #02082345) and in every state where we operate, managing thousands of homes, just like yours.`,
    [US_STATES.TX
      .code]: `Belong is licensed by the Texas Department of Real Estate (DRE License #9013886) and in every state where we operate, managing thousands of homes, just like yours.`,
    [US_STATES.UT
      .code]: `Belong is licensed by the Utah Department of Real Estate (DRE License #13538686-CN00) and in every state where we operate, managing thousands of homes, just like yours.`,
    [US_STATES.WA
      .code]: `Belong is licensed by the Washington State Department of Licensing (License #21028494) and in every state where we operate, managing thousands of homes, just like yours.`,
    [US_STATES.FL
      .code]: `Belong is licensed by the Florida Department of Licensing (License #CQ1065529) and in every state where we operate, managing thousands of homes, just like yours.`,
    [US_STATES.NC
      .code]: `Belong is licensed by the North Carolina Real Estate Commission (License #C38631) and in every state where we operate, managing thousands of homes, just like yours.`,
    [US_STATES.GA
      .code]: `Belong is licensed by the Georgia Real Estate Commission (License #81255) and in every state where we operate, managing thousands of homes, just like yours.`,
    [US_STATES.SC
      .code]: `Belong is licensed by the South Carolina Real Estate Commission (License #27097) and in every state where we operate, managing thousands of homes, just like yours.`,
    'WA-notarize':
      'Leases longer than 12 months must be notarized in the state of Washington. We partner with Notarize to make this seamless. Every adult needs to sign in a timely manner to ensure you get the home.',
  },
  'sign.title': `Here's everything you need to know about joining\u00a0Belong.`,
  'sign.title.updated': `As discussed, we've updated your Belong\u00a0Plan.`,
  'sign.subtitle': `We just need to get some legal stuff out of the way first...`,
  'sign.summary.first':
    'You may cancel anytime, free of charge, until we agree on and perform repairs or manage residents in your\u00a0home.',
  'sign.summary.second':
    'Once we place residents in your home, you get paid on the first of each month,\u00a0guaranteed.',
  'sign.summary.third': 'You are covered up to $15,000 for eviction\u00a0protection.',
  'sign.summary.fourth': 'You approve home maintenance requests by residents up\u00a0to\u00a0${threshold}.',
  'sign.continueReading': 'Continue Reading',
  'sign.download': 'Download PDF',
  'sign.download.addendums': 'Download All',
  'sign.download.fullDocument': 'VIEW FULL DOCUMENT',
  'sign.checkbox': 'I AGREE TO SIGN THESE TERMS AND\u00a0CONDITIONS',
  'sign.CTA': 'Continue Onboarding',
  'sign.checkbox.addendums': 'I agree to electronically sign this agreement and its\u00a0addendums.',
  'sign.cancelAnytime': 'Cancel Anytime',
  'sign.cancelAnytime.description':
    'We stand by our service. Joining is free, and you can cancel anytime pre-lease without\u00a0fees.',
  'sign.guaranteedRent': 'Guaranteed Rent',
  'sign.guaranteedRent.description':
    'Complete peace of mind. Once we place residents, you get paid on the first, no matter\u00a0what.',
  'sign.evictionProtection': 'Eviction Protection',
  'sign.evictionProtection.description':
    'We offer coverage of up to $15,000 against legal fees in the event of an\u00a0eviction.',
  'sign.personalConcierge': 'Personal Concierge',
  'sign.personalConcierge.description':
    'Enjoy a dedicated professional who cares about you, your home, and your\u00a0residents.',
  'sign.trustedVendors': 'Trusted Vendors',
  'sign.trustedVendors.description':
    'We ensure top-notch maintenance to maximize the long-term value of your\u00a0home.',
  'sign.allDaySupport': '24/7 Support',
  'sign.allDaySupport.description':
    'Our service never sleeps. In the event of an emergency, our team is there to handle any\u00a0situation.',
  'sign.error':
    "Oh, no! Something has failed on our end. Please contact {email} - {phoneNumber} and we'll help\u00a0immediately.",

  // Success Page
  'success.home.string': 'home',
  'success.default': `Congrats on joining Belong's network! Let's start by onboarding your home.`,
  'success.co-signer.signatures-left':
    "You're all Set! We'll send an invite to {primaryHOName} to complete the onboarding process.",
  'success.co-signer.all-signed': 'We look forward to giving your {homeString} a whole lot of love.',
  'success.primary.far-off.occupied':
    'Alright, you’re a Belong Homeowner! As we get closer to your move out, we’ll reach out to get you set up. In the meantime, please let us know if anything changes on your end.',
  'success.primary.far-off.vacant':
    'Alright, you’re a Belong Homeowner! As we get closer to your {homeOrHomes} becoming available, we’ll reach out to get you set up. In the meantime, please let us know if anything changes on your end.',
  'success.primary.far-off.mixed':
    'Alright, you’re a Belong Homeowner! As we get closer to your homes becoming available, we’ll reach out to get you set up. In the meantime, please let us know if anything changes on your end.',
  'success.primary.signatures-left.one': "We'll send an invite to {nameYetToSign} to complete the onboarding process.",
  'success.primary.signatures-left.multiple':
    "We'll send invites to {namesYetToSign} to complete the onboarding process.",
  'success.non-signer.info-title': 'Note',
  'success.non-signer.info':
    "As you are not on title, you will no longer see this home in your account. But that doesn't mean we don't look forward to working with you!",
  'success.cta': 'Go to Main Page',
  shareableInfo: {
    bannerTitle: 'Love working with Belong so far?',
    bannerMessage: 'Refer your favorite homeowner. Earn $1,000.',
    shareableMessage:
      'I just started working with Belong, and am loving my experience. Belong takes care of everything, from guaranteeing my rent to providing 24/7 support to me and my resident. If you own a rental home, check them out!',
  },
};
