import { PATHS } from '@belong/common';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { PAGES_CONFIG } from '../pages/Accounts/Pages/pages';

export const getPortfolioPath = (properties) => {
  if (properties.length > 1) {
    return PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES;
  }

  const property = properties[0];

  if (!property.getUnitsWithAgreements().length) {
    return PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES;
  }

  return `${PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES}/${property.basicInfo.propertyId}`;
};

export const getHomePath = (leases) =>
  leases.length > 1
    ? PATHS.RESIDENTS_ACCOUNT_PROPERTIES
    : `${PATHS.RESIDENTS_ACCOUNT_PROPERTIES}/${leases[0].unitInfo.unitId}/${leases[0].leaseInfo.basicInfo.leaseId}`;

export const MY_HOME_MENU_ITEM = {
  header: 'My Home',
  key: 'properties',
  toFunction: getHomePath,
  to: PATHS.RESIDENTS_ACCOUNT_PROPERTIES,
  icon: GENERAL_ICONS.MY_ACCOUNTS,
};

export const RESIDENT_PAYMENTS_MENU_ITEM = {
  header: 'Payments',
  key: 'payments',
  to: PATHS.RESIDENTS_ACCOUNT_PAYMENTS,
  icon: GENERAL_ICONS.EARNINGS_AND_PAYMENTS,
};

export const NO_CLAIMS_PAYMENTS_MENU_ITEM = {
  header: 'Payments',
  key: 'payments',
  to: PATHS.ACCOUNTS_PAYMENTS,
  icon: GENERAL_ICONS.EARNINGS_AND_PAYMENTS,
};

export const RESIDENT_DOCUMENTS_MENU_ITEM = {
  header: 'Documents',
  key: 'documents',
  to: PAGES_CONFIG.YOUR_HOME_DOCUMENTS.path,
  icon: GENERAL_ICONS.DOCUMENTS_ACCOUNTS_PAGE,
};

export const RESIDENT_INBOX_MENU_ITEM = {
  header: 'Inbox',
  key: 'inbox',
  to: PAGES_CONFIG.RESIDENT_INBOX.path,
};

export const RESIDENT_ACCOUNT_MENU_ITEMS = [
  {
    header: 'Pro Services',
    key: 'maintenance',
    to: PATHS.RESIDENTS_ACCOUNT_MAINTENANCE,
    icon: GENERAL_ICONS.MAINTENANCE,
  },
  RESIDENT_PAYMENTS_MENU_ITEM,
];

export const FAVORITE_MENU_ITEM = {
  header: 'Favorites',
  key: 'favorites',
  to: PAGES_CONFIG.FAVORITES.path,
  icon: GENERAL_ICONS.FAVORITES,
};

export const TOUR_AND_APPS_MENU_ITEM = {
  header: 'Tours & Apps',
  key: 'tours-and-apps',
  to: PAGES_CONFIG.TOURS_AND_APPS.path,
  icon: GENERAL_ICONS.TOURS_AND_APPS,
};

export const TO_HOMEOWNERS_ACCOUNT_MENU_ITEM = {
  header: 'View As Homeowner',
  key: 'homeowners-account',
  to: PATHS.HOMEOWNER_REDIRECTS,
  iconNormal: '/accounts/switch-account-icon_m.svg',
};

export const ALL_USER_MENU = [
  {
    header: 'My Profile',
    key: 'profile',
    to: PAGES_CONFIG.PROFILE.path,
    icon: GENERAL_ICONS.PROFILE,
  },
  {
    header: 'Refer for $1,000',
    key: 'referral',
    to: PAGES_CONFIG.REFERRAL.path,
    iconNormal: '/referral/icons/mobile_bottombar_referral_normal.svg',
    iconActive: '/referral/icons/mobile_bottombar_referral_active.svg',
  },
];

export const HOMEOWNER_ACCOUNT_MENU_ITEMS = [
  {
    header: 'My Homes',
    key: 'properties',
    toFunction: getPortfolioPath,
    to: PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES,
    icon: GENERAL_ICONS.MY_ACCOUNTS,
  },
  {
    header: 'Listing Performance',
    key: 'listing-performance',
    to: PAGES_CONFIG.LISTING_PERFORMANCE.path,
    icon: GENERAL_ICONS.MAINTENANCE,
  },
  {
    header: 'Pro Services',
    key: 'maintenance',
    to: PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE,
    icon: GENERAL_ICONS.MAINTENANCE,
  },
  {
    header: 'Earnings',
    key: 'earnings',
    to: PAGES_CONFIG.EARNINGS.path,
    icon: GENERAL_ICONS.EARNINGS_AND_PAYMENTS,
  },
  {
    header: 'My Wealth',
    key: 'wealth',
    to: PAGES_CONFIG.WEALTH.path,
    icon: GENERAL_ICONS.EARNINGS_AND_PAYMENTS,
    new: true,
  },
  {
    header: 'Insurance',
    key: 'insurance',
    to: PAGES_CONFIG.INSURANCE.path,
    iconNormal: 'accounts/insurance-icon.svg',
    iconActive: 'accounts/insurance-icon-selected.svg',
  },
  {
    header: 'Documents',
    key: 'documents',
    to: PAGES_CONFIG.PORTFOLIO_DOCUMENTS.path,
    icon: GENERAL_ICONS.DOCUMENTS_ACCOUNTS_PAGE,
  },
  {
    header: 'Inbox',
    key: 'inbox',
    to: PAGES_CONFIG.INBOX.path,
  },
  {
    header: 'Sell My Home',
    key: 'sell',
    to: PAGES_CONFIG.SELL_HOME.path,
    icon: GENERAL_ICONS.SELL_HOME,
  },
  {
    header: 'View As Resident',
    key: 'resident-account',
    to: PATHS.RESIDENTS_REDIRECTS,
    iconNormal: '/accounts/switch-account-icon_m.svg',
  },
];
