import React, { Component } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import String from 'components/String/String';
import PropTypes from 'prop-types';
import styles from './ListItem.module.css';

const cx = classNames.bind(styles);

export default class ListItem extends Component {
  static propTypes = {
    selectedTrue: PropTypes.bool,
    label: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    selectedTrue: false,
    label: '',
    children: '',
  };

  render() {
    let Listcomponent;
    const { selectedTrue, label, children } = this.props;

    if (selectedTrue) {
      Listcomponent = (
        <div className={cx('listItem', { selectedTrue })}>
          <GeneralIcon icon={GENERAL_ICONS.HAND_CHECKBOX} />
          <span className={cx('spacing-small')}>
            <String string={children || label} />
          </span>
        </div>
      );
    } else {
      Listcomponent = (
        <div className={cx('listItem')}>
          <GeneralIcon icon={GENERAL_ICONS.CIRCLE} color={COLORS.GRAY} />
          <span className={cx('spacing-small')}>
            <String string={children || label} />
          </span>
        </div>
      );
    }

    return Listcomponent;
  }
}
