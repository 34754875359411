import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { ThemeContext } from 'themes/themes';
import BulletList from '../BulletList/BulletList';
import Image from '../Image/Image';
import styles from './ImageCard.module.css';

const cx = classNames.bind(styles);

export default class ImageCard extends Component {
  static propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.node,
    subTitle: PropTypes.node,
    subTitles: PropTypes.arrayOf(PropTypes.node),
    imageAlt: PropTypes.string,
    imageClassName: PropTypes.string,
    hasHover: PropTypes.bool,
    tag: PropTypes.node,
    imageBottomText: PropTypes.node,
    centerText: PropTypes.bool,
    setMaxWidth: PropTypes.bool,
    showTag: PropTypes.bool,
    outsideHover: PropTypes.bool,
    setShowHover: PropTypes.func,
    id: PropTypes.number.isRequired,
    className: PropTypes.string,
    fullHeight: PropTypes.bool,
  };

  static defaultProps = {
    imageUrl: '',
    imageClassName: '',
    title: '',
    subTitle: null,
    subTitles: null,
    imageAlt: '',
    hasHover: false,
    tag: '',
    imageBottomText: null,
    centerText: false,
    setMaxWidth: false,
    showTag: true,
    outsideHover: false,
    className: '',
    setShowHover: () => {},
    fullHeight: false,
  };

  render() {
    const {
      imageUrl,
      hasHover,
      title,
      subTitles,
      subTitle,
      imageAlt,
      imageClassName,
      tag,
      imageBottomText,
      centerText,
      setMaxWidth,
      showTag,
      outsideHover,
      setShowHover,
      id,
      fullHeight,
      className,
    } = this.props;

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={cx('imageCard', { setMaxWidth }, { hasHover }, className)}>
            {showTag && tag && <div className={cx('tag')}>{tag}</div>}

            <Image src={imageUrl} className={cx('image', theme, imageClassName, { fullHeight })} alt={imageAlt} />
            <div
              onMouseEnter={() => {
                setShowHover(id);
              }}
              onMouseLeave={() => {
                setShowHover(null);
              }}
              className={cx('hover-wrapper', { outsideHover })}
            />
            {imageBottomText && (
              <>
                <div className={cx('text-backdrop-gradient-top')} />
                <div className={cx('text-backdrop-gradient')} />
                <div className={cx('image-bottom-text')}>{imageBottomText}</div>
              </>
            )}
            <div className={cx('subtitle', { centerText })}>
              <div className={cx('flex')}>
                {subTitle && subTitle}
                {subTitles && <BulletList listItems={subTitles} small />}
              </div>
            </div>
            <div className={cx('title', { centerText })}>{title}</div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
