import Media from '../common/Media';
import UnitBasicInfo from '../common/UnitBasicInfo';
import LeaseDetailBasicInfo from './LeaseDetailBasicInfo';
import LeaseResident from '../common/LeaseResident';

export default class RenterLeaseDetail {
  constructor(data = {}) {
    this.basicInfo = new LeaseDetailBasicInfo(data.leaseDetail?.basicInfo);
    this.unitInfo = new UnitBasicInfo(data.basicInfo);
    this.owners = data.owners;
    this.media = data.media?.map((media) => new Media(media));
    this.residents = data.leaseDetail?.residents?.map((resident) => new LeaseResident(resident));
  }
}
