import type { RescheduleAppointmentOptionsFormSchema } from 'accounts/types';

type GetSubmitButtonDisabledParams = Pick<RescheduleAppointmentOptionsFormSchema, 'date' | 'time'> & {
  isInspectionAfterNineDays?: boolean;
};

export function getSubmitButtonDisabled({ date, time, isInspectionAfterNineDays }: GetSubmitButtonDisabledParams) {
  if (!date) {
    return true;
  }

  if (date && !time && !isInspectionAfterNineDays) {
    return true;
  }

  return false;
}
