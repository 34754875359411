import { twMerge } from '@belong/common';
import { Image, Text } from '@belong/ui';

type Props = {
  description: string;
  descriptionClassname?: string;
  src: string;
  title: string;
};

export const DepositFAQSItem = ({ description, descriptionClassname, src, title }: Props) => (
  <div className="flex flex-col sm:flex-row items-center justify-around">
    <Image alt={title} className="h-[230px] object-contain w-[400px]" src={src} />
    <div className="pt-xs sm:pt-0 mr-0 sm:mr-xs w-full sm:w-auto">
      <Text className={twMerge('max-w-[375px]', descriptionClassname)}>{description}</Text>
    </div>
  </div>
);
