// React imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AgreementFlowFormLayout from 'pages/AgreementFlow/AgreementFlowFormLayout/AgreementFlowFormLayout';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import Icon, { ICONS } from 'components/Icon/Icon';
import Field from 'components/Field/Field';
import { required } from 'utils/validation';
import { SelectorFinalFormAdapter } from 'components/Selector/Selector';

const Payment = ({ currentStepFormData, currentMappedStep, onSubmit, ...rest }) => {
  const handleSubmit = (values) => {
    const { agreementUsers } = currentStepFormData;

    agreementUsers.forEach((au) => {
      au.isPrimaryOwner = false;
      if (au.uniqueId === values.primaryOwner) au.isPrimaryOwner = true;
    });

    onSubmit({ agreementUsers });
  };

  const getInitialValues = () => {
    if (!currentStepFormData) return;

    const { agreementUsers } = currentStepFormData;
    const selectedUser = agreementUsers?.find((au) => au.isPrimaryOwner === true);

    return { primaryOwner: selectedUser.uniqueId };
  };

  useEffect(() => {
    if (currentStepFormData) {
      const { agreementUsers } = currentStepFormData;
      if (agreementUsers?.length === 1) {
        agreementUsers[0].isPrimaryOwner = true;
        onSubmit({ agreementUsers });
      }
    }
  }, [currentStepFormData]);

  const currentUserEmail = currentMappedStep?.data?.email;
  const buttons =
    currentStepFormData?.agreementUsers?.map((au) => {
      const button = {};
      if (au?.businessEntity) {
        button.label = au?.businessEntity?.name;
        button.subLabel = `(${au?.user?.firstName} ${au?.user?.lastName})`;
        button.icon = <Icon icon={ICONS.ENTITY_TRUST.DEFAULT} responsive />;
        button.iconSelected = <Icon icon={ICONS.ENTITY_TRUST.INVERSE} responsive />;
        button.key = au.uniqueId;
      } else if (!au?.businessEntity && au?.user?.email === currentUserEmail) {
        button.label = AGREEMENT_FLOW_STRINGS['payment.selector.me'];
        button.subLabel = AGREEMENT_FLOW_STRINGS['payment.selector.me.sublabel'];
        button.icon = <Icon icon={ICONS.ME.DEFAULT} responsive />;
        button.iconSelected = <Icon icon={ICONS.ME.INVERSE} responsive />;
        button.key = au.uniqueId;
      } else {
        button.label = `${au?.user?.firstName} ${au?.user?.lastName}`;
        button.icon = <Icon icon={ICONS.ANOTHER_INDIVIDUAL.DEFAULT} responsive />;
        button.iconSelected = <Icon icon={ICONS.ANOTHER_INDIVIDUAL.INVERSE} responsive />;
        button.key = au.uniqueId;
      }

      return button;
    }) || [];

  return (
    <AgreementFlowFormLayout
      {...rest}
      currentMappedStep={currentMappedStep}
      onSubmit={handleSubmit}
      title={AGREEMENT_FLOW_STRINGS['payment.title']}
      subTitle={AGREEMENT_FLOW_STRINGS['payment.subtitle']}
      initialValues={getInitialValues()}
      getForm={() => (
        <>
          <FormLayout.Section>
            <Field
              name="primaryOwner"
              component={SelectorFinalFormAdapter}
              fluid
              buttons={buttons}
              validate={required}
            />
          </FormLayout.Section>
        </>
      )}
    />
  );
};

Payment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currentStepFormData: PropTypes.object.isRequired,
  currentMappedStep: PropTypes.object.isRequired,
};

Payment.defaultProps = {};

export default Payment;
