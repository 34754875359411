import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './FeeLabel.module.css';

const cx = classNames.bind(styles);

class FeeLabel extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  render() {
    const { label } = this.props;
    return <div className={cx('label')}>{label}</div>;
  }
}

export default FeeLabel;
