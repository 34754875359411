import React, { Component } from 'react';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import PropTypes from 'prop-types';
import { DEFAULT_LINKS } from '../../../../consts/links';
import styles from './HeaderMenuNavLink.module.css';

const cx = classNames.bind(styles);

export default class HeaderMenuNavLink extends Component {
  static propTypes = {
    link: PropTypes.oneOf(Object.values(DEFAULT_LINKS)),
    className: PropTypes.string,
    to: PropTypes.string,
    label: PropTypes.node,
    selected: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    link: null,
    className: '',
    to: '',
    label: '',
  };

  render() {
    const { className, to, label, selected, ...other } = this.props;
    return (
      <li
        className={clsx(
          'hover:border-l-4 border-l-4 box-border px-xs hover:border-navy border-solid border-0 mb-2sm last:mb-0',
          selected ? 'border-l-4 border-navy' : ' border-transparent'
        )}
        {...other}
      >
        <LinkBase to={to}>
          <div className={cx('menu-label')}>{label}</div>
        </LinkBase>
      </li>
    );
  }
}
