// Final Form imports
import { useLocation } from 'react-router';
import { NextUnitBannerStep } from 'accounts/components/next-unit-banner-step';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { STEP_NAMES, STEP_STATUS } from 'accounts/constants/steps';
import { PROPERTY_FEATURES_STRINGS } from 'accounts/constants/strings/property-features.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import {
  getPropertyFeatureAttributes,
  getPropertyFeatureObject,
  getPropertyFeaturesList,
} from 'accounts/utils/property-features';
import { FeaturesComponentFinalFormAdapter } from 'components/FeaturesComponent/FeaturesComponent';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
// Component imports
import FormLayout from 'layouts/FormLayout/FormLayout';

export function PropertyFeatures() {
  const {
    currentStep,
    currentStepFormData,
    steps,
    selectedProperty,
    selectedUnit,
    handleSaveAndNext,
    goPreviousStep,
    unitId,
  } = useSetupFlowStep();
  const { search } = useLocation();

  const isInspectionPassed = currentStep?.property?.basicInfo?.isInspectionPassed;
  const isFirstTimeSaving = currentStep?.status === STEP_STATUS.Created;
  const occupancyStep = steps?.find((step) => step.dataRelId === unitId && step.name === STEP_NAMES.UnitOccupancy);
  const featuresList = getPropertyFeaturesList(isInspectionPassed);

  async function handleSubmit(values) {
    const submitValues = { ...values };
    delete submitValues.features;

    const featuresArray = [];

    Object.keys(values.features).forEach((featureName) => {
      const featureFormObject = values.features[featureName];
      featureFormObject.name = featureName;

      if (featureFormObject.selected) {
        const featureServerObject = getPropertyFeatureAttributes(featureFormObject);
        featuresArray.push(featureServerObject);
      }
    });

    submitValues.features = [...featuresArray.filter(Boolean)];

    handleSaveAndNext(submitValues);
  }

  function getInitialValues() {
    const initialValues = { ...currentStepFormData };

    if (!initialValues?.features?.length) {
      initialValues.features = {};
    } else {
      const features = {};

      initialValues.features.forEach((feature) => {
        const featureFormObject = getPropertyFeatureObject(feature);
        features[feature.name] = featureFormObject;
      });

      initialValues.features = features;
    }

    return { ...initialValues };
  }

  function getBannerStep() {
    if (search !== '') {
      const unitAddress = `${selectedProperty?.address.streetAddress} Unit ${selectedUnit?.basicInfo.unitNumber}`;

      return <NextUnitBannerStep unitAddress={unitAddress} />;
    }

    return undefined;
  }

  return (
    <StepFormLayout
      onSubmit={handleSubmit}
      {...(!occupancyStep?.isHidden && {
        handleBackStep: goPreviousStep,
      })}
      title={
        isFirstTimeSaving ? PROPERTY_FEATURES_STRINGS.title : PROPERTY_FEATURES_STRINGS['not_first_time_saving.title']
      }
      initialValues={getInitialValues()}
      bannerStep={getBannerStep()}
      getForm={({ form }) => (
        <>
          <FormLayout.Section>
            <Field
              name="features"
              component={FeaturesComponentFinalFormAdapter}
              featuresList={featuresList}
              placeholder="Property Features"
              form={form}
              showTrashButton={false}
              showCheckmark
            />
          </FormLayout.Section>
          <FormLayout.Section sectionTitle={PROPERTY_FEATURES_STRINGS['other_features.title']}>
            <Field
              name="communalDetails.communalFeaturesComments"
              placeholder={PROPERTY_FEATURES_STRINGS['missing_features.placeholder']}
              component={InputFinalFormAdapter}
              textarea
              multiLineLabel
            />
          </FormLayout.Section>
        </>
      )}
    />
  );
}
