export const PAYMENT_LIST_MODAL = {
  fold: {
    title: 'Thank you for Paying {month} Rent!',
    duetext: 'Due {date}',
    deposit: {
      success_title: 'Thank you for paying your deposit!',
      initiated_title: 'Thank you for paying your deposit!',
      failure_title: "Let's resolve your payment!",
      pending_title: 'Make a Payment',
      pending_homeowner_title: 'Upcoming Payment',
    },
    rent: {
      success_title: 'Thank you for paying rent!',
      initiated_title: 'Thank you for paying rent!',
      failure_title: "Let's resolve your rent!",
      pending_title: 'Make a Payment',
      pending_homeowner_title: 'Upcoming Payment',
    },
    default: {
      success_title: 'Thank you for your payment!',
      initiated_title: 'Thank you for your payment!',
      failure_title: "Let's resolve your payment!",
      pending_title: 'Make a Payment',
      pending_homeowner_title: 'Upcoming Payment',
    },

    // titlefailure: 'Let\'s resolve your rent for {month}.',
    // subtitlefailure: 'Looks like your default payment did not go through this time. \n We\'re here to help!',
    duetextfailure: 'Due {date} (Late)',
  },
  paymentdetails: {
    title: 'Payment Details',
    total: 'Total',
    historyTitle: 'History',
    total_paid_so_fair: 'Paid',
    total_remaining: 'Remaining',

    // Only in fail
    attempt: '{method}',
  },

  paymentmethod: {
    // in success
    titlesuccess: 'Payment Method',
    carddescription: '{method}',
    // date format is currently not here. Should it be?
    titlefailure: 'Your Payment Method',
    retrybutton: 'Retry',
    changemethodbutton: 'Change Payment Method',
    nodefault: 'No Default Payment Method Found',
  },
};
