import { getApiActionTypes } from '../redux_utils';

export const HOMEOWNER_SET_UP_FLOW = {
  CREATE_HOMEOWNER_SETUP: 'CREATE_HOMEOWNER_SETUP',
  FETCH_HOMEOWNER_SETUP_ID: 'FETCH_HOMEOWNER_SETUP_ID',
  FETCH_STEP_DATA: 'FETCH_STEP_DATA',
  UPDATE_STEP_DATA: 'UPDATE_STEP_DATA',
  FETCH_AVAILABILITY_DATES: 'FETCH_AVAILABILITY_DATES',
};

export const ACTIONS = {
  ...getApiActionTypes(HOMEOWNER_SET_UP_FLOW.FETCH_HOMEOWNER_SETUP_ID),
  ...getApiActionTypes(HOMEOWNER_SET_UP_FLOW.CREATE_HOMEOWNER_SETUP),
  ...getApiActionTypes(HOMEOWNER_SET_UP_FLOW.FETCH_STEP_DATA),
  ...getApiActionTypes(HOMEOWNER_SET_UP_FLOW.UPDATE_STEP_DATA),
  ...getApiActionTypes(HOMEOWNER_SET_UP_FLOW.FETCH_AVAILABILITY_DATES),
};
