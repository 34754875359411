import React from 'react';
import { DATE_FORMATS, formatUtcDateTimeToTimeZone } from '@belong/common';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ProfileImageLarge from 'components/ProfileImageLarge/ProfileImageLarge';
import String from 'components/String/String';
import PropTypes from 'prop-types';
import styles from './DisplayAppointment.module.css';

const cx = classNames.bind(styles);

const DisplayAppointment = ({ date, employeeName, employeeImageUrl, timeZone }) => {
  const formattedDate = formatUtcDateTimeToTimeZone({
    dateTime: new Date(date),
    format: DATE_FORMATS.MAINTENANCE_DEFAULT_DATE,
    timeZone,
  });
  const formattedTime = formatUtcDateTimeToTimeZone({
    dateTime: new Date(date),
    format: DATE_FORMATS.TIME_TIMEZONE,
    timeZone,
  });

  return (
    <div className={cx('appointment')}>
      <div className={cx('items-container')}>
        <div className={cx('item')}>
          <GeneralIcon className={cx('icon')} icon={GENERAL_ICONS.CALENDAR} />
          <String string={`${formattedDate} <span>${formattedTime}</span>`} />
        </div>
        <div className={cx('item')}>
          <GeneralIcon className={cx('icon')} icon={GENERAL_ICONS.BELONG_EMPLOYEE} />
          {employeeName}
        </div>
      </div>
      <div className={cx('image-wrapper')}>
        <ProfileImageLarge imageContainer={cx('image-container')} profileImage={employeeImageUrl} />
      </div>
    </div>
  );
};

DisplayAppointment.propTypes = {
  date: PropTypes.string.isRequired,
  employeeName: PropTypes.string.isRequired,
  employeeImageUrl: PropTypes.string.isRequired,
  timeZone: PropTypes.string,
};

export default DisplayAppointment;
