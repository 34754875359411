import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Spinner from '../Spinner/Spinner';
import styles from './FullPageSpinner.module.css';

const cx = classNames.bind(styles);

class FullPageSpinner extends Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <div className={cx('full-page')}>
        <Spinner />
      </div>
    );
  }
}

export default FullPageSpinner;
