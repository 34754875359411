import { useHistory } from 'react-router';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Button } from '@belong/ui';
import { Confetti } from 'animations/Confetti/Confetti';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import { EmployeeAssignmentTypes } from 'models/enums';
import { ReportType } from 'models/enums/typings';
import { STRINGS } from 'post-inspection/constants/strings';
import { useEmployee } from 'post-inspection/hooks/use-employee';
import { BASE_PATHS } from 'routes/paths';
import { EMPLOYEE_TITLES } from 'strings/common.strings';

type Props = {
  listings: unknown[];
  flow: {
    configuration: {
      homeUniqueId: string;
    };
  };
  isPreMoveOut: boolean;
};

export const ImprovementsReportSuccess = ({ listings, flow, isPreMoveOut }: Props) => {
  const { push, location } = useHistory();
  const { width } = useWindowSize();
  const propertyId = flow?.configuration?.homeUniqueId;
  const { employee } = useEmployee({
    listings,
    flow,
    reportType: ReportType.ImprovementsSpecialist,
  });

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  const handleClick = () => {
    push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}`);
  };

  const onboardingSuccessCopy =
    (location as { state: { from: string } })?.state?.from === 'ho-fixes-calendar'
      ? STRINGS['improvements-report-success.ho-fixes']
      : STRINGS['improvements-report-success'];

  return (
    <div className="flex h-screen justify-center w-screen">
      <Confetti />
      <div className="flex flex-col items-center h-full w-full px-sm pt-sm sm:px-0 sm:pt-7xl sm:max-w-[730px]">
        <TextBubbleWithUserProfile
          employee={{ ...employee, jobTitle: EMPLOYEE_TITLES[EmployeeAssignmentTypes.HomeImprovementsSpecialist] }}
          text={isPreMoveOut ? STRINGS['improvements-report-success.pre_move_out'] : onboardingSuccessCopy}
        />
        <div className="w-full mt-2xl sm:w-auto">
          <Button onClick={handleClick} size={isMobile ? 'fluid' : 'large'}>
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
