import { Button, Image } from '@belong/ui';
import { downloadURL } from 'lease-signing-flow/constants/app';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

export const DownloadAppButton = () => {
  const handleClick = () => window.open(downloadURL, '_blank');

  return (
    <Button onClick={handleClick}>
      <div className="flex mr-xs">
        <Image alt="app-store-icon" className="h-[16px] object-contain w-[16px]" src="icons/app-store.svg" />
      </div>
      <div className="flex mr-xs">
        <Image alt="play-store-icon" className="h-[16px] object-contain w-[16px]" src="icons/play-store.svg" />
      </div>
      {LEASE_SIGNING_FLOW_STRINGS['success.cta.download']}
    </Button>
  );
};
