import React, { useState } from 'react';
import { IconDots } from '@belong/icons';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import { Tag } from 'components/HomeTag/HomeTag';
import PaymentIconDisplay from 'components/Payments/PaymentIconDisplay/PaymentIconDisplay';
import PaymentMethodNamingDisplay from 'components/Payments/PaymentMethodDisplay/PaymentMethodNamingDisplay/PaymentMethodNamingDisplay';
import ManualAchVerificationModal from 'containercomponents/Modals/ManualAchVerificationModal/ManualAchVerificationModal';
import PaymentMenuSelectionContainer from 'containers/PaymentSelectionMenuContainer/PaymentMenuSelectionContainer';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import PropTypes from 'prop-types';
import { PaymentPriority, PaymentPreference, MicroDepositStatus } from '../../models/enums';
import GeneralIcon from '../GeneralIcon/GeneralIcon';
import Spinner from '../Spinner/Spinner';
import styles from './PaymentTile.module.css';

const cx = classNames.bind(styles);

function PaymentTile(props) {
  const {
    showPaymentTileMarginOnMobile,
    paymentMethod,
    menuProps,
    showSpinner,
    highlight,
    paymentType,
    paymentModalProps,
    showPriority,
    noCustomWidth,
    fullUser,
    isRenter = false,
    hasLabels,
    isReferral,
  } = props;

  const [showManualModal, setShowManualModal] = useState(false);

  const hasFee = !(paymentType === PaymentPreference.Payout);

  const handleOnHide = () => {
    setShowManualModal(false);
  };

  const handleShow = () => {
    setShowManualModal(true);
  };

  function getTileLabel() {
    if (isReferral) {
      return 'Referral';
    }
    if (paymentType === PaymentPreference.RentalHomeInsurance) {
      return 'Insurance';
    }
    if (paymentType === PaymentPreference.Maintenance) {
      return 'Pro Services';
    }

    if (isRenter && paymentType === PaymentPreference.Rent) {
      return 'Primary for Rent';
    } else if (!isRenter && paymentType === PaymentPreference.Payout) {
      return 'Rent';
    }
  }

  const displayVerifyButtonStatuses = [MicroDepositStatus.Initiated, MicroDepositStatus.Ready];
  const microDepositStatus = paymentMethod?.achDetails?.microDepositStatus;
  const displayVerifyButton = !paymentMethod.isVerified && displayVerifyButtonStatuses.includes(microDepositStatus);

  return (
    <>
      {paymentMethod?.achDetails?.bankName && (
        <ManualAchVerificationModal show={showManualModal} paymentMethod={paymentMethod} onHide={handleOnHide} />
      )}
      <PaymentMenuSelectionContainer
        fitToButton
        fullUser={fullUser}
        paymentType={paymentType}
        paymentModalProps={paymentModalProps}
        shouldShowAutoSplitPaymentMethod={menuProps.shouldShowAutoSplitPaymentMethod}
        isAutoSplitEnabled={menuProps.isAutoSplitEnabled}
        menuButtonLabel={
          <>
            <div className={cx('payment-tile-wrapper', { highlight, spinner: showSpinner })}>
              <div className={cx('payment-tile', { showPaymentTileMarginOnMobile })}>
                {showSpinner && <Spinner />}
                <div className={cx('text')}>
                  <PaymentIconDisplay
                    noCustomWidth={noCustomWidth}
                    icon={<GeneralIcon icon={paymentMethod.getIcon().DEFAULT} />}
                    // isNotVerified={!paymentMethod.isVerified}
                    title={
                      <PaymentMethodNamingDisplay
                        accountType={paymentMethod?.achDetails?.accountType}
                        paymentMethod={paymentMethod?.paymentMethod}
                        accountLastDigits={paymentMethod?.lastDigits}
                        referenceNumber={paymentMethod?.referenceNumber}
                        bankName={paymentMethod?.achDetails?.bankName || paymentMethod?.creditCardDetails?.cardType}
                        otherPaymentType={paymentMethod?.otherPaymentType}
                        hasFee={hasFee}
                        showFee
                      />
                    }
                    text={!paymentMethod.isVerified && <div className={cx('not-verified-payment')}>NOT VERIFIED</div>}
                  />
                  {showPriority && (
                    <div className={cx('menu')}>
                      {hasLabels && (
                        <Tag
                          className={cx(
                            paymentType === PaymentPreference.Rent || paymentType === PaymentPreference.Payout
                              ? 'tag-rent'
                              : 'tag-maintenance'
                          )}
                        >
                          {getTileLabel()}
                        </Tag>
                      )}
                      {hasLabels ? (
                        <div className={cx('dots-container')}>
                          <IconDots width={5} height={24} />
                        </div>
                      ) : (
                        <div className={cx('change')}>CHANGE</div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {displayVerifyButton && (
                <div className={cx('isVerifiedMobileAndTabletWrapper')}>
                  <Button
                    buttonType={BUTTON_TYPES.TEXT}
                    className={cx('full-width')}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShow();
                    }}
                    disabled={microDepositStatus === MicroDepositStatus.Initiated}
                  >
                    VERIFY NOW
                  </Button>
                </div>
              )}
            </div>
          </>
        }
        {...menuProps}
      />
      {displayVerifyButton && (
        <div className={cx('verify-now-desktop', { showManualModal })}>
          <Space value={SPACE_TYPES.XS} />
          <Button
            buttonType={BUTTON_TYPES.TEXT}
            className={cx('full-width')}
            onClick={(e) => {
              e.stopPropagation();
              handleShow();
            }}
            disabled={microDepositStatus === MicroDepositStatus.Initiated}
          >
            VERIFY NOW
          </Button>
        </div>
      )}
    </>
  );
}

PaymentTile.propTypes = {
  priority: PropTypes.string,
  showPaymentTileMarginOnMobile: PropTypes.bool,
  paymentType: PropTypes.oneOf(Object.values(PaymentPreference)).isRequired,
  paymentMethod: PropTypes.object.isRequired,
  paymentModalProps: PropTypes.object,
  menuProps: PropTypes.object,
  showSpinner: PropTypes.bool,
  highlight: PropTypes.bool,
  showPriority: PropTypes.bool,
  noCustomWidth: PropTypes.bool,
  isRenter: PropTypes.bool,
  fullUser: PropTypes.object,
  hasLabels: PropTypes.bool,
  isReferral: PropTypes.bool,
};

PaymentTile.defaultProps = {
  priority: PaymentPriority.Primary,
  paymentModalProps: {},
  menuProps: {},
  showPaymentTileMarginOnMobile: false,
  showSpinner: false,
  highlight: false,
  showPriority: true,
  noCustomWidth: false,
  hasLabels: true,
  isReferral: false,
};

export default PaymentTile;
