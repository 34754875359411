import React, { useState } from 'react';
import { Form as ReactFinalForm, FormSpy } from 'react-final-form';
import ErrorMessageBubble, { ERROR_STATES } from 'components/ErrorMessageBubble/ErrorMessageBubble';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

export const FormContext = React.createContext();

const Form = ({
  getForm,
  getFormBottomBar,
  bottomPosition,
  rightPosition,
  errorProps,
  id,
  bottomPositionMobile,
  allowSubmitErrors,
  onSubmit,
  customError,
  ...rest
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [showErrorMessageBubble, setShowErrorMessageBubble] = useState(ERROR_STATES.NO_ERROR_MESSAGE);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (values, form, callback) => {
    try {
      const submissionErrors = await onSubmit(values, form, callback);

      // React final form expects you to return undefined if everything went well or an object with submission errors.
      if (allowSubmitErrors && submissionErrors) {
        setErrorMessage(submissionErrors?.[0]?.message);
        setShowErrorMessageBubble(ERROR_STATES.SHOW_ERROR_MESSAGE);
        setShowErrors(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const clearError = () => {
    setShowErrors(false);
    setShowErrorMessageBubble(ERROR_STATES.NO_ERROR_MESSAGE);
    setErrorMessage();
  };

  return (
    <FormContext.Provider value={showErrors}>
      <ReactFinalForm
        initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
        {...rest}
        onSubmit={handleSubmit}
        render={(formProps) => {
          const { invalid, errors } = formProps;

          const nextButtonProps = {
            onClick: (e) => {
              if (invalid) {
                e.preventDefault();

                // If we have a custom error for one field and that's the only field left, will display a custom error
                // more context here: https://belonghome.atlassian.net/browse/HG-1235
                if (customError && Object.keys(errors.keys ?? {}).length === 1) {
                  const errorKeys = customError.split('.');
                  const hasChild = errorKeys.length > 0;

                  if (typeof errors.keys[errorKeys[0]] !== 'undefined') {
                    if (hasChild) {
                      setErrorMessage(errors.keys[errorKeys[0]][errorKeys[1]]);
                    } else {
                      setErrorMessage(errors.keys[errorKeys[0]]);
                    }
                  }
                }

                setShowErrorMessageBubble(ERROR_STATES.SHOW_ERROR_MESSAGE);
                setShowErrors(true);
              }
            },
          };

          return (
            <form id={id} onSubmit={formProps.handleSubmit} style={{ position: 'relative' }}>
              <FormSpy subscription={{ values: true }} onChange={clearError} />
              {getForm({ ...formProps })}
              <ErrorMessageBubble
                showErrorMessageBubble={showErrorMessageBubble}
                bottomPosition={bottomPosition}
                bottomPositionMobile={bottomPositionMobile}
                errorMessage={errorMessage}
                mobileFixed={bottomPositionMobile === undefined}
                rightPosition={rightPosition}
                {...errorProps}
              />
              {getFormBottomBar(
                formProps,
                // Spread nextButtonProps on the form submit button.
                // Ex: Advanced.js and IdentityVerification.js
                nextButtonProps,
                () => {
                  setShowErrors(false);
                }
              )}
            </form>
          );
        }}
      />
    </FormContext.Provider>
  );
};

Form.propTypes = {
  getForm: PropTypes.func.isRequired,
  showErrorsState: PropTypes.bool,
  getFormBottomBar: PropTypes.func,
  bottomPosition: PropTypes.string,
  rightPosition: PropTypes.string,
  errorProps: PropTypes.object,
  bottomPositionMobile: PropTypes.number,
  allowSubmitErrors: PropTypes.bool,
  onSubmit: PropTypes.func,
  customError: PropTypes.string,
  id: PropTypes.string,
};

Form.defaultProps = {
  showErrorsState: false,
  getFormBottomBar: () => {},
  errorProps: {},
  rightPosition: null,
};

export default Form;
