import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Image } from '@belong/ui';
import { STEP_GATE_NAMES, STEP_NAMES } from 'accounts/constants/steps';
import { getStepPath, sortStepsByGate } from 'accounts/utils/flow-steps';
import { ANALYTICS_CLICK_ACTIONS } from 'analytics';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import { SCREENS } from 'containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from 'containercomponents/Modals/modal.consts';
import { Text } from 'design-system';
import FormLayout from 'layouts/FormLayout/FormLayout';
import Address from 'models/common/Address';
import UnitLease from 'models/common/UnitLeaseDetail';
import { StepStatus } from 'models/enums';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { showModal } from 'store/redux/modals/actions';
import { selectUser } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import styles from './collapse-flow-section.module.css';

type CollapseFlowSectionProps = {
  properties: [HomeownerAccountProperty];
  section: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  shouldShowCommonSteps?: boolean;
  openInitialModal: (unit: UnitLease, address: Address) => void;
};

const cx = classNames.bind(styles);

export function CollapseFlowSection({
  properties,
  section,
  shouldShowCommonSteps,
  openInitialModal,
}: CollapseFlowSectionProps) {
  const history = useHistory();
  const { propertyId } = useParams<Record<'propertyId', string>>();
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const unitProperty = properties?.find((property) =>
    property.units.some((unit) => unit?.basicInfo?.unitId === section?.unitId)
  );
  const selectedUnit = unitProperty?.units?.find((unit) => unit?.basicInfo?.unitId === section?.unitId);

  function handleCollapseClick() {
    setCollapsed((prevValue) => !prevValue);
  }

  function handleSubSectionClick(subSection) {
    const { steps, reportLink, name } = subSection;

    /**
     * The Landlord Insurance flow is on belong-next and we do not have the passwordless modal
     * implemented there. Instead of implementing it, we show the modal right before performing the navigation.
     * Not only because it is more convenient but also because the UX of showing a blank page with such a
     * small modal on top of it is definitely not the best.
     */
    if (['LandlordInsurance', 'W9'].includes(name) && user?.userMustSetPassword) {
      dispatch(
        showModal(MODALS.LOGIN, {
          user,
          currentScreen: SCREENS.SET_PASSWORD_AFTER_AGREEMENT,
          closable: true,
          onSucessfulLogin: async () => {
            history.push(subSection.reportLink);
          },
        })
      );

      return;
    }

    if (steps?.length) {
      const sortedStepsByGroup = sortStepsByGate(steps);
      const currentStep = sortedStepsByGroup?.find(
        (step) => step.status === StepStatus.Incomplete || step.status === StepStatus.Created
      );
      const stepWithUnit = sortedStepsByGroup.find((step) => Boolean(step.unit));
      const defaultUnitId = stepWithUnit?.unit?.basicInfo.unitId || 'NA';
      const nextStep = currentStep || sortedStepsByGroup?.[0];

      history.push(getStepPath(nextStep, propertyId, section.unitId || defaultUnitId));
    }

    if (reportLink) {
      history.push(subSection.reportLink);
    }
  }

  const isCollapsed = section.subSections?.every((subSection) => subSection.progressPercentage === 100);
  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  if (!section.subSections?.length) {
    return null;
  }

  return (
    <section className="mb-2xl">
      <header className="flex justify-between items-center mb-sm">
        <FormLayout.Title title={section.sectionTitle} />
        <div
          className={cx('collapse-control', {
            expanded: !collapsed,
          })}
        >
          <IconButton align={ALIGN_TYPES.NONE} icon={GENERAL_ICONS.DOWN_CARET} onClick={handleCollapseClick} />
        </div>
      </header>
      <main
        className={cx('content', 'relative', {
          show: !collapsed,
        })}
      >
        {section.unitId && selectedUnit?.isInitialPriceRangePublished && (
          <Button
            onClick={() => openInitialModal(selectedUnit, unitProperty?.address)}
            buttonType={BUTTON_TYPES.TEXT}
            className="mb-sm flex items-center"
            action={ANALYTICS_CLICK_ACTIONS.INITIAL_ESTIMATE_OPEN}
          >
            <Image className="w-lg mr-xs" src="/icons/icon_graph_up.svg" />
            <Text className="uppercase" fontWeight="semibold">
              Estimated rent price
            </Text>
          </Button>
        )}
        {section.subSections?.map((subSection) => {
          const isLegacyFlow = !subSection.steps?.find(
            (step) =>
              step.stepName === STEP_NAMES.UnitInspectionSchedule || step.stepName === STEP_NAMES.UnitKeysEnhanced
          );
          const hideArrow =
            !isLegacyFlow &&
            subSection.name === STEP_GATE_NAMES.InspectionSchedule &&
            subSection.progressPercentage === 100;

          if (
            !shouldShowCommonSteps &&
            [STEP_GATE_NAMES.BuildListing, STEP_GATE_NAMES.InspectionSchedule].includes(subSection.name)
          ) {
            return null;
          }

          return (
            <div
              className={cx('flow-card', {
                'bg-green-light-bg': subSection.progressPercentage === 100,
                'cursor-pointer': !subSection.disabled,
                disabled: subSection.disabled,
              })}
              key={subSection.displayName}
              {...(!subSection.disabled && {
                tabIndex: 0,
                role: 'button',
                onClick: () => handleSubSectionClick(subSection),
              })}
            >
              <div
                className={cx('font-semibold text-body flex items-center mr-sm', {
                  'text-dark-gray': subSection.disabled,
                  'text-navy': !subSection.disabled,
                })}
              >
                <h4 className="mr-xs">{formatString(subSection.displayName)}</h4>
                {subSection.additionalContentProps && (
                  <Tooltip
                    contentStyle={{ width: 325 }}
                    className="absolute right-[14px] z-10 md:relative md:right-auto"
                  >
                    {subSection.additionalContentProps.title ? (
                      <Text>{subSection.additionalContentProps.title}</Text>
                    ) : (
                      <>
                        <Text fontWeight="semibold">{subSection.additionalContentProps.subtitle}</Text>
                        <ul>
                          {subSection.additionalContentProps.items?.map((item) => (
                            <li key={item.text} className="font-regular text-body leading-p1">
                              · {item.text}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </Tooltip>
                )}
              </div>
              <div className="flex items-center mt-sm w-full md:w-auto md:mt-0">
                {subSection.disabled ? (
                  <>
                    {subSection.progressPercentage === 100 ? (
                      <>
                        <h4 className="text-green">100%&nbsp;</h4>
                        <h4 className="text-dark-gray">Complete</h4>
                      </>
                    ) : (
                      <h4 className="text-dark-gray">Not Ready</h4>
                    )}
                  </>
                ) : (
                  <Fragment>
                    <h4
                      className={cx('mr-2xs', {
                        'text-green': subSection.progressPercentage === 100,
                        'text-red': subSection.progressPercentage < 100,
                      })}
                    >
                      {subSection.progressPercentage}%
                    </h4>
                    <h4>Complete</h4>
                  </Fragment>
                )}
                {!hideArrow && (
                  <IconButton
                    disabled={subSection.disabled}
                    align={ALIGN_TYPES.LEFT}
                    icon={GENERAL_ICONS.FRONT_ARROW}
                  />
                )}
              </div>
            </div>
          );
        })}
      </main>
    </section>
  );
}
