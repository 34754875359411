/* eslint-disable */
import { useState, useContext } from 'react';
import { FocusVisibleContext } from 'hooks/useFocus/useFocusManager';
import hocify from 'hooks/hocify';

// Not a third party library. Stolen from here: https://github.com/WICG/focus-visible/issues/205
// Final Form handles its own onFocus and onBlur and I believe it is conflicting with the normal polyfill in some way.
// This seperates the concerns a bit better and also means we don't have a listener everywhere in the app.

// A React hook based on: https://github.com/WICG/focus-visible

export const HocFocusVisible = () => {
  const [isFocused, setIsFocused] = useState(false);
  const { hadKeyboardEvent, isInitialized } = useContext(FocusVisibleContext);

  function onFocusVisible() {
    setIsFocused(true);
  }

  function onFocusBlur() {
    setIsFocused(false);
  }

  let focusVisible;
  if (isInitialized) {
    focusVisible = hadKeyboardEvent && isFocused;
  } else {
    // Fallback to focused when the `FocusVisibleManager` is not used.
    focusVisible = isFocused;
  }

  return {
    focusVisible,
    onFocusVisible,
    onFocusBlur,
  };
};

export default hocify(HocFocusVisible);
