import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import Label, { LABEL_COLOR_TYPES } from 'components/Label/Label';
import ReactCompareImage from 'forkedlibraries/ReactCompareImage/ReactCompareImage';
import PropTypes from 'prop-types';
import styles from './ImageCompare.module.css';

const cx = classNames.bind(styles);

// This component is using a forked version of ReactCompareImage where the event handlers are attached to the
// handle only to support use inside a carousel. Don't expect things to work the same as in the library docs.
const ImageCompare = ({
  containerClassName,
  leftImage,
  rightImage,
  leftImageCss,
  rightImageCss,
  leftLabel,
  rightLabel,
  bottomShadow,
  bottomLeftText,
  bottomRightText,
}) => {
  return (
    <div className={cx('image-compare', containerClassName, { wrapContainer: Boolean(containerClassName) })}>
      <ReactCompareImage
        leftImage={leftImage}
        rightImage={rightImage}
        leftImageCss={leftImageCss}
        rightImageCss={rightImageCss}
        moveSliderOnClick={false}
        bottomShadow={bottomShadow}
        bottomLeftText={bottomLeftText}
        bottomRightText={bottomRightText}
        sliderLineWidth={4}
        leftImageLabel={
          leftLabel && (
            <div className={cx('left-label')}>
              <Label className={cx('left-label-text')}>{leftLabel}</Label>
            </div>
          )
        }
        rightImageLabel={
          rightLabel && (
            <div className={cx('right-label')}>
              <Label className={cx('right-label-text')} color={LABEL_COLOR_TYPES.GREEN}>
                {rightLabel}
              </Label>
            </div>
          )
        }
        handle={
          <div className={cx('handle')}>
            <div className={cx('left')}>
              <GeneralIcon color={COLORS.WHITE} icon={GENERAL_ICONS.FULL_CARET_LEFT} />
            </div>
            <div className={cx('right')}>
              <GeneralIcon color={COLORS.WHITE} icon={GENERAL_ICONS.FULL_CARET_RIGHT} />
            </div>
          </div>
        }
      />
    </div>
  );
};

ImageCompare.propTypes = {
  containerClassName: PropTypes.string,
  leftImage: PropTypes.string.isRequired,
  rightImage: PropTypes.string.isRequired,
  leftImageCss: PropTypes.object,
  rightImageCss: PropTypes.object,
  leftLabel: PropTypes.node,
  rightLabel: PropTypes.node,
  bottomShadow: PropTypes.node,
  bottomLeftText: PropTypes.node,
  bottomRightText: PropTypes.node,
};

ImageCompare.defaultProps = {
  containerClassName: '',
  leftImageCss: null,
  rightImageCss: null,
  leftLabel: null,
  rightLabel: null,
};

export default ImageCompare;
