import { useRef, useState } from 'react';
import { DATE_FORMATS, formatDateTime, formatMoney } from '@belong/common';
import { IconLeftCaret, IconRightCaret } from '@belong/icons';
import { Text } from '@belong/ui';
import clsx from 'classnames';
import { formatString } from 'strings';
import styles from './comparables-step-list.module.css';

/* eslint-disable @typescript-eslint/no-explicit-any */
type ComparablesStepListProps = {
  initialEstimate?: any;
  lowerEstimates?: any;
  higherEstimates?: any;
  homeMediaValue?: any;
  address?: any;
  unit?: any;
  formatAddress: any;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export function ComparablesStepList({
  initialEstimate,
  higherEstimates,
  lowerEstimates,
  homeMediaValue,
  address,
  unit,
  formatAddress,
}: ComparablesStepListProps) {
  const tableRef = useRef(null);
  const [refPosition, setRefPosition] = useState(0);

  const scroll = (e, scrollOffset) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.cancelable) {
      tableRef.current.scrollLeft += scrollOffset;
      setRefPosition(tableRef.current.scrollLeft);
    }
  };

  return (
    <div className="relative w-full flex justify-center items-center">
      <div className={clsx('absolute flex justify-between md:hidden z-10', styles.arrowsBox)}>
        <div
          className={clsx(
            'bg-navy w-xl h-xl rounded-full flex justify-center items-center',
            refPosition === 0 ? 'bg-dark-gray' : 'bg-navy'
          )}
        >
          <IconLeftCaret onClick={(event) => scroll(event, -80)} className="text-white" height={20} />
        </div>
        <div
          className={clsx(
            'bg-navy w-xl h-xl rounded-full flex justify-center items-center',
            refPosition >= 666 ? 'bg-dark-gray' : 'bg-navy'
          )}
        >
          <IconRightCaret onClick={(event) => scroll(event, 80)} className="text-white" height={20} />
        </div>
      </div>
      <div
        ref={tableRef}
        className={clsx(
          'w-full flex justify-left md:justify-center md:px-md overflow-scroll md:overflow-visible',
          styles.tableContainer
        )}
      >
        <table className="w-full border-collapse border border-solid border-gray">
          <thead>
            <tr className="bg-light-gray">
              <th className="border border-solid border-gray text-left px-lg">
                Price on
                <br />
                {formatDateTime({
                  dateTime: initialEstimate?.pricingEstimate?.publishedOn,
                  format: DATE_FORMATS.STANDARD_NO_YEAR,
                })}
              </th>
              <th className="border border-solid border-gray text-left px-lg">Sqft</th>
              <th className="border border-solid border-gray text-left px-lg">
                Price/
                <br />
                Sqft
              </th>
              <th className="border border-solid border-gray text-left px-lg">Bed</th>
              <th className="border border-solid border-gray text-left px-lg">Bath</th>
              <th className="border border-solid border-gray text-left px-lg">
                Price/
                <br />
                Bedroom
              </th>
              <th className="border border-solid border-gray w-1/3 text-left px-lg">Address</th>
              <th className="border border-solid border-gray text-left px-lg">
                Days
                <br />
                Listed
              </th>
            </tr>
          </thead>
          <tbody>
            {lowerEstimates.map((comparable) => (
              <TableRow key={comparable.id} comparable={comparable} formatAddress={formatAddress} />
            ))}
            <tr className={clsx('mainRow')}>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">{formatMoney(homeMediaValue)}</Text>
              </td>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">{initialEstimate?.home?.areaInSquareFeet || '-'}</Text>
              </td>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">
                  {formatMoney(homeMediaValue / (initialEstimate?.home?.areaInSquareFeet || 1))}
                </Text>
              </td>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">{initialEstimate?.home?.bedroomCount || '-'}</Text>
              </td>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">{initialEstimate?.home?.fullBathroomCount || '-'}</Text>
              </td>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">
                  {formatMoney(homeMediaValue / (initialEstimate?.home?.bedroomCount || 1))}
                </Text>
              </td>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">{formatString(address) || formatAddress(unit?.basicInfo?.address)}</Text>
              </td>
              <td className="border border-solid border-gray px-lg">
                <Text fontWeight="semibold">Your Home</Text>
              </td>
            </tr>
            {higherEstimates.map((comparable) => (
              <TableRow key={comparable.id} comparable={comparable} formatAddress={formatAddress} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type HigherEstimatesRowProps = {
  comparable: any;
  formatAddress: any;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

function TableRow({ comparable, formatAddress }: HigherEstimatesRowProps) {
  const externalListing = comparable.relatedExternalListing;

  return (
    <tr key={externalListing.id}>
      <td className="border border-solid border-gray px-lg">
        <Text>{formatMoney(externalListing.rentAmount)}</Text>
      </td>
      <td className="border border-solid border-gray px-lg">{externalListing.areaInSquareFeet || '-'}</td>
      <td className="border border-solid border-gray px-lg">
        <Text>{formatMoney(externalListing.rentAmount / externalListing.areaInSquareFeet) || '-'}</Text>
      </td>
      <td className="border border-solid border-gray px-lg">{externalListing.bedroomCount || '-'}</td>
      <td className="border border-solid border-gray px-lg">{externalListing.fullBathroomCount || '-'}</td>
      <td className="border border-solid border-gray px-lg">
        <Text>{formatMoney(externalListing.rentAmount / externalListing.bedroomCount) || '-'}</Text>
      </td>
      <td className="border border-solid border-gray px-lg">{formatAddress(externalListing.address)}</td>
      <td className="border border-solid border-gray px-lg">{externalListing.daysListed}</td>
    </tr>
  );
}
