import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import Spinner from 'components/Spinner/Spinner';
import arrayMutators from 'final-form-arrays';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { filter, find, flatten, isEmpty, groupBy } from 'lodash-es';
import PropTypes from 'prop-types';
import { fetchEmploymentInformation, updateEmploymentInformation } from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationApplicantInfo,
  selectResidentApplicationEmploymentInformation,
  selectResidentApplicationId,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import { createVerification, getUserIncomeLink } from 'store/redux/user/actions';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { CheckboxFinalFormAdapter } from '../../../../components/Checkbox/Checkbox';
import { FeaturesComponentFinalFormAdapter } from '../../../../components/FeaturesComponent/FeaturesComponent';
import Space, { SPACE_TYPES } from '../../../../corecomponents/Space/Space';
import { ApplicantType, IMAGE_FILE_TYPE, IncomeSourceType } from '../../../../models/enums';
import { parseDateTimeInputString } from '../../../../utils/dateTimeUtils';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './EmploymentInformation.module.css';
import AdditionalSourceFields from './IncomeSourceSelector/IncomeSourceFields/AdditionalSourceFields/AdditionalSourceFields';
import ContractFields from './IncomeSourceSelector/IncomeSourceFields/ContractingFields/ContractFields';
import JobFields from './IncomeSourceSelector/IncomeSourceFields/JobFields/JobFields';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.employment_info;

const incomeSourcesList = [
  {
    name: IncomeSourceType.Job,
    title: RESIDENT_APPLICATION_STRINGS.employment_info.employments.job.title,
    allowMultiple: true,
    showPlus: true,
    formFields: <JobFields />,
  },
  {
    name: IncomeSourceType.Contract,
    title: RESIDENT_APPLICATION_STRINGS.employment_info.employments.contract.title,
    allowMultiple: true,
    showPlus: true,
    formFields: <ContractFields />,
  },
  {
    name: IncomeSourceType.Additional,
    title: RESIDENT_APPLICATION_STRINGS.employment_info.employments.additional.title,
    allowMultiple: true,
    showPlus: true,
    formFields: <AdditionalSourceFields />,
  },
];

const { FormLayoutHeader } = FormLayout;

const EmploymentInformation = ({
  employmentInformation,
  applicationId,
  houseId,
  fetchEmploymentInformation: fetchEmploymentInformationAction,
  updateEmploymentInformation: updateEmploymentInformationAction,
  applicantInfo,
  createVerification: createVerificationAction,
  getUserIncomeLink: getUserIncomeLinkAction,
}) => {
  const [awaitingFetchComplete, setAwaitingFetchComplete] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const history = useHistory();

  const setupInitialValues = useCallback(() => {
    const grouped = groupBy(employmentInformation.incomeSources, 'type');
    const initial = { ...employmentInformation };
    initial.incomeSources = {};

    incomeSourcesList.forEach((incomeSource) => {
      const { name } = incomeSource;
      initial.incomeSources[name] = grouped[name]
        ? grouped[name].map((job) => {
            const offerLetter = find(job.incomeSourceFiles, { fileType: IMAGE_FILE_TYPE.OfferLetter });
            return {
              ...job,
              startedOn: job.startedOn
                ? formatDateTime({ dateTime: legacyParse(job.startedOn), format: DATE_FORMATS.STANDARD })
                : null,
              endedOn: job.endedOn
                ? formatDateTime({ dateTime: legacyParse(job.endedOn), format: DATE_FORMATS.STANDARD })
                : null,
              offerLetterFile: {
                availableMedia: offerLetter?.files?.length ? [...offerLetter.files] : [],
                stagingMedia: [],
                deletedMedia: [],
                mediaToDisplay: offerLetter?.files?.length ? [...offerLetter.files] : [],
              },
              selected: true,
            };
          })
        : [
            {
              name: [name],
              selected: false,
            },
          ];
    });

    setInitialValues(initial);
    setAwaitingFetchComplete(false);
  }, [employmentInformation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEmpty(employmentInformation)) {
          await fetchEmploymentInformationAction(applicationId);
        }
      } catch (err) {
        console.error(err);
      }

      setupInitialValues();
    };

    fetchData();
  }, [applicationId, fetchEmploymentInformationAction, employmentInformation, setupInitialValues]);

  const handleSubmit = async (values) => {
    const { applicantType } = applicantInfo;
    const selectedIncomeSources = [];

    Object.keys(values.incomeSources).forEach((type) => {
      const list = values.incomeSources[type];
      const selectedList = filter(list, { selected: true });
      selectedList.forEach((item) => {
        const {
          uniqueId,
          source,
          position,
          isCurrent,
          isLeavingSoon,
          hasRecentlyStarted,
          startedOn,
          endedOn,
          estimatedAnnualIncome,
          offerLetterFile,
        } = item;

        selectedIncomeSources.push({
          uniqueId,
          type,
          source,
          position,
          isCurrent,
          isLeavingSoon,
          hasRecentlyStarted,
          startedOn: startedOn ? parseDateTimeInputString(startedOn) : null,
          endedOn: endedOn ? parseDateTimeInputString(endedOn) : null,
          estimatedAnnualIncome,
          offerLetterFile,
        });
      });
    });

    const data = {
      hasNoIncomeSource: values.hasNoIncomeSource,
      incomeSources: !values.hasNoIncomeSource ? selectedIncomeSources : [],
    };

    const currentJobs = filter(selectedIncomeSources, (incomeSource) => {
      if (incomeSource.type === IncomeSourceType.Job) {
        return incomeSource.isCurrent;
      }
      return true;
    });

    try {
      await updateEmploymentInformationAction(applicationId, data);
    } catch (err) {
      console.error(err);
    }

    if (currentJobs?.length) {
      const reportStatus = ['ReportReadyForPulling', 'ReportPulled', 'FlowComplete'];
      const incomes = await getUserIncomeLinkAction();
      const isPlaidVerified = reportStatus.includes(incomes.status);

      if (isPlaidVerified) {
        if (applicantType === ApplicantType.CoSigner) {
          history.push(
            getResidentApplicationStepPath(STEPS_CONFIG.BACKGROUND_CHECK, { houseId, applicationId, verified: true })
          );
        } else {
          history.push(
            getResidentApplicationStepPath(STEPS_CONFIG.REFERENCE_CHECK, { houseId, applicationId, verified: true })
          );
        }
      } else {
        history.push(getResidentApplicationStepPath(STEPS_CONFIG.INCOME_VERIFICATION, { houseId, applicationId }));
      }
    } else if (applicantType === ApplicantType.CoSigner) {
      history.push(getResidentApplicationStepPath(STEPS_CONFIG.BACKGROUND_CHECK, { houseId, applicationId }));
    } else {
      await createVerificationAction('Income/PlaidAssets');
      history.push(getResidentApplicationStepPath(STEPS_CONFIG.REFERENCE_CHECK, { houseId, applicationId }));
    }
  };

  if (awaitingFetchComplete) {
    return (
      <Row>
        <Col md={12}>
          <div className={cx('spinner')}>
            <Spinner />
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <FormLayout>
      <FormLayoutHeader title={RAS.title} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
        validate={(values) => {
          const { incomeSources: incomeSourcesField, hasNoIncomeSource } = values;

          if (hasNoIncomeSource) {
            return {
              incomeSources: undefined,
              hasNoIncomeSource: undefined,
            };
          } else {
            if (hasNoIncomeSource) {
              return {
                incomeSources: undefined,
                hasNoIncomeSource: undefined,
              };
            }
            const hasSelected = Object.keys(incomeSourcesField).find((key) => {
              if (Array.isArray(incomeSourcesField[key])) {
                // Other is an array.
                return Boolean(incomeSourcesField[key].find((multiKey) => multiKey.selected));
              }
              return incomeSourcesField[key]?.selected;
            });

            if (hasSelected) {
              return undefined;
            } else {
              const allFields = Object.values(incomeSourcesList).reduce(
                (a, b) => ({ ...a, [b.name]: [{ type: 'Required' }] }),
                {}
              );
              return {
                incomeSources: allFields,
                hasNoIncomeSource: undefined,
              };
            }
          }
        }}
        getFormBottomBar={(formProps, nextButtonProps) => (
          <FormLayout.BottomBar
            ctaProps={{
              label: RAS.next_button_text,
            }}
            nextButtonWrapperProps={nextButtonProps}
          />
        )}
        getForm={({ form }) => {
          const formValues = form.getState().values;
          const incomeSourcesByType = formValues.incomeSources;
          const incomeSources = flatten(Object.values(incomeSourcesByType));
          const hasSelectedIncomeSource = !!find(incomeSources, { selected: true });
          const { hasNoIncomeSource } = formValues;
          return (
            <div className={cx('form-wrapper')}>
              <FormLayout.Section firstSection sectionTitle={RAS.employment_status_section_title}>
                <div className={cx('employment-status-selector-buttons-wrapper')}>
                  <Row>
                    <Col md={12}>
                      <Field
                        name="incomeSources"
                        disabled={hasNoIncomeSource}
                        component={FeaturesComponentFinalFormAdapter}
                        featuresList={incomeSourcesList}
                        form={form}
                      />
                    </Col>
                  </Row>
                  <Space value={SPACE_TYPES.XS} />
                  <Row>
                    <Col md={12}>
                      <Field
                        key="hasNoIncomeSource"
                        name="hasNoIncomeSource"
                        disabled={hasSelectedIncomeSource}
                        component={CheckboxFinalFormAdapter}
                        alignWithField={false}
                        label={RESIDENT_APPLICATION_STRINGS.employment_info.employments.unemployed}
                      />
                    </Col>
                  </Row>
                </div>
              </FormLayout.Section>
            </div>
          );
        }}
      />
    </FormLayout>
  );
};

EmploymentInformation.propTypes = {
  employmentInformation: PropTypes.object,
  applicationId: PropTypes.string.isRequired,
  houseId: PropTypes.string.isRequired,
  fetchEmploymentInformation: PropTypes.func.isRequired,
  updateEmploymentInformation: PropTypes.func.isRequired,
  applicantInfo: PropTypes.object.isRequired,
  createVerification: PropTypes.func,
  getUserIncomeLink: PropTypes.func,
};

EmploymentInformation.defaultProps = {
  employmentInformation: {},
};

export default connect(
  (state) => ({
    employmentInformation: selectResidentApplicationEmploymentInformation(state),
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    applicantInfo: selectResidentApplicationApplicantInfo(state),
  }),
  {
    fetchEmploymentInformation,
    updateEmploymentInformation,
    createVerification,
    getUserIncomeLink,
  }
)(EmploymentInformation);
