import { Fragment } from 'react';

// Component imports
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
// Bootstrap imports
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
// Util, Enum and Const imports
import { required } from 'utils/validation';
import { STORAGE_FEATURE_STRINGS } from 'accounts/constants/strings/storage.strings';

export function StorageFormFields({ name, disabled }) {
  return (
    <Fragment>
      <div className="mt-sm mb-sm">
        <Field
          name={`${name}.attributes.heatLocation`}
          component={InputFinalFormAdapter}
          validate={required}
          placeholder={STORAGE_FEATURE_STRINGS.inputPlaceholder}
          disabled={disabled}
          applyDisabledStyle={disabled}
        />
      </div>
      <FormLayout.Section
        firstSection
        sectionTitle={STORAGE_FEATURE_STRINGS.canResidentsUseIt}
        separatorType="none"
        sectionTitleClassName="text-body"
      >
        <Row>
          <Col md={6}>
            <Field
              fluid
              name={`${name}.attributes.canResidentsUseIt`}
              component={SelectorFinalFormAdapter}
              validate={required}
              disabled={disabled}
              type={SELECTOR_TYPES.SMALLTEXTBUTTON}
              buttons={[
                  {
                    label: STORAGE_FEATURE_STRINGS.yes,
                    key: true,
                  },
                  {
                    label: STORAGE_FEATURE_STRINGS.no,
                    key: false,
                  },
                ]}
            />
          </Col>
        </Row>
      </FormLayout.Section>
    </Fragment>
  );
}

StorageFormFields.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

StorageFormFields.defaultProps = {
  name: '',
  disabled: false,
};
