import { MaintenanceGroupBundleModel, MaintenanceModel } from 'api/models';

const getMaintenanceSubtaskHasApprovedQuote = (
  userRole: 'Resident' | 'Homeowner',
  bundle: MaintenanceGroupBundleModel
) => {
  return bundle.items?.some((item) =>
    item.maintenancePayments.some((payment) => payment.consentStatus === 'Approved' && payment.paidBy === userRole)
  );
};

export const useMaintenanceHasApprovedSubtask = (
  userRole: 'Resident' | 'Homeowner',
  maintenance?: MaintenanceModel
) => {
  if (!maintenance) {
    return false;
  }

  const hasApprovedSubtask = maintenance.subtasks?.every((subtask) =>
    getMaintenanceSubtaskHasApprovedQuote(userRole, subtask)
  );

  return hasApprovedSubtask;
};
