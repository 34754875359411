import { LEASE_SIGNING_FLOW } from 'lease-signing-flow/store/key';
import { getApiActionTypes, getApiActionTypesValues } from 'store/redux/redux_utils';

const FETCH_LEASE_DURATION = 'FETCH_LEASE_DURATION';
const UPDATE_LEASE_DURATION = 'UPDATE_LEASE_DURATION';

const actions = {
  ...getApiActionTypes(FETCH_LEASE_DURATION),
};

export const fetchLeaseDuration = (leaseId) => ({
  promise: ({ client }) => client.get(`/residents/extend-lease/${leaseId}`),
  types: getApiActionTypesValues(FETCH_LEASE_DURATION),
});

// eslint-disable-next-line
export const updateLeaseDuration = (leaseId, body: any) => ({
  promise: ({ client }) => client.put(`/residents/extend-lease/${leaseId}`, body),
  types: getApiActionTypesValues(UPDATE_LEASE_DURATION),
});

export const LEASE_SIGNING_FLOW_DURATION_REDUCER = 'duration';

const initialState = {
  duration: {},
};

// eslint-disable-next-line
const reducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case actions[`${FETCH_LEASE_DURATION}_SUCCESS`]:
      return {
        ...state,
        duration: {
          ...action.result,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const selectLeaseDuration = (state) => state[LEASE_SIGNING_FLOW][LEASE_SIGNING_FLOW_DURATION_REDUCER].duration;
