import { GroupedBalancesPerCategoryInfoModel } from 'api/models.v2';

type BalancesByTypeProps = {
  expenses: GroupedBalancesPerCategoryInfoModel;
  revenues: GroupedBalancesPerCategoryInfoModel;
  all: GroupedBalancesPerCategoryInfoModel;
  isResident?: boolean;
};

type BalanceProps = GroupedBalancesPerCategoryInfoModel & {
  name: string;
  hideSplitIt?: boolean;
};

export function getBalancesByType({ expenses, revenues, all, isResident }: BalancesByTypeProps): BalanceProps[] {
  const balances = [];
  if (isResident && !!all) {
    balances.push(all);
  }

  if (revenues) {
    balances.push({ ...revenues, name: 'Revenue', hideSplitIt: true });
  }

  if (expenses) {
    balances.push({ ...expenses, name: 'Expenses' });
  }

  return balances;
}
