const qualificationFlowStepsName = ['Basics', 'Availability', 'Recondition', 'Media', 'Package'];

type QualificationFlowStepName = typeof qualificationFlowStepsName[number];

type QualificationFlowStepWebPathGetter = {
  [key in QualificationFlowStepName]: (flowId: string, stepId: string) => string;
};

const QualificationFlowStepWebPathGetter: QualificationFlowStepWebPathGetter = {
  Basics: (flowId, stepId) => `/homeowners/qualification/${flowId}/property/basics/${stepId}`,
  Availability: (flowId, stepId) => `/homeowners/qualification/${flowId}/property/availability/${stepId}`,
  Recondition: (flowId, stepId) => `/homeowners/qualification/${flowId}/property/recondition/${stepId}`,
  Media: (flowId, stepId) => `/homeowners/qualification/${flowId}/property/media/${stepId}`,
  Package: (flowId, stepId) => `/homeowners/qualification/${flowId}/property/package/${stepId}`,
};

export const getHomeownerQualificationFlowPath = (homeownerQualificationFlow) => {
  const firstNotCompletedStep = homeownerQualificationFlow?.steps?.find((step) => step.status !== 'Completed');
  if (
    !homeownerQualificationFlow ||
    !firstNotCompletedStep ||
    !firstNotCompletedStep.stepName ||
    !qualificationFlowStepsName.includes(firstNotCompletedStep.stepName)
  ) {
    return;
  }

  return QualificationFlowStepWebPathGetter[firstNotCompletedStep.stepName](
    homeownerQualificationFlow?.uniqueId,
    firstNotCompletedStep?.uniqueId
  );
};
