import { DateInputFinalFormAdapter } from 'components/DateInput/DateInput';
import Field from 'components/Field/Field';
import { maskMMYY, unmaskMMYY } from 'components/Input/masks';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import { AvailableForRentOptions } from 'models/enums';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { composeValidators, dateValidationMMYY, required } from 'utils/validation';

export const AvailableForRentInAMonth = ({ extraValidation = () => {}, name }) => (
  <Condition is={AvailableForRentOptions.InCertainMonth} when={`${name}.availableForRentOption`}>
    <Row>
      <Col xs={12} sm={6}>
        <Field
          component={DateInputFinalFormAdapter}
          name={`${name}.availableInAMonth`}
          placeholder={RENTAL_STATUS_TEXT.moveOutInAMonth}
          validate={composeValidators(required, dateValidationMMYY, extraValidation)}
          mask={maskMMYY}
          unmask={unmaskMMYY}
        />
      </Col>
    </Row>
  </Condition>
);

AvailableForRentInAMonth.propTypes = {
  extraValidation: PropTypes.func,
  name: PropTypes.string.isRequired,
};
