import { twMerge } from '@belong/common';
import { Image, Text } from '@belong/ui';
import Tooltip from 'components/Tooltip/Tooltip';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

type Props = {
  selected?: boolean;
};

export const KarmaTooltip = ({ selected }: Props) => (
  <Tooltip
    arrowStyle={{
      backgroundColor: 'transparent',
      color: '#0f2532',
    }}
    className="text-dark-navy h-full w-full"
    contentStyle={{ width: 311, padding: 18 }}
    position="top right"
    trigger={() => (
      <div
        className={twMerge(
          'flex flex-row items-center bg-navy justify-center rounded-[6px] py-2xs pl-2sm pr-lg',
          selected && 'bg-green'
        )}
      >
        <Image alt="coin" className="h-[24px] w-[24px]" src="icons/coin.png" />
        <Text className="text-white mt-[2px] pl-[2px]" fontWeight="semibold">
          50% Karma
        </Text>
        <Text className="absolute text-white mt-[2px] pl-[2px] right-xs top-0" fontWeight="semibold">
          ...
        </Text>
      </div>
    )}
  >
    <Text className="leading-p1" fontWeight="semibold">
      {LEASE_SIGNING_FLOW_STRINGS['deposit.fee.tooltip']}
    </Text>
  </Tooltip>
);
