import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUpcomingAppointments } from 'store/redux/homeowner-accounts/actions';

export function useUpcomingAppointments(unitId) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);

        if (unitId) {
          await dispatch(fetchUpcomingAppointments(unitId));
        }
      } finally {
        setLoading(false);
      }
    }

    if (unitId) {
      init();
    } else {
      setLoading(false);
    }
  }, [dispatch, unitId]);

  return { loading };
}
