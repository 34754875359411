import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Image } from '@belong/ui';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import String from 'components/String/String';
import { formatString } from 'strings';
import { MAINTENACE_STATUS_MODAL_STRINGS as STRINGS } from 'strings/maintenance-status-modal.string';

interface InfoBoxProps {
  financePlanId?: number;
  hasOpenBalance?: boolean;
  isMaintenanceCompleted?: boolean;
}

export const SplitInfoBox = ({ financePlanId, hasOpenBalance, isMaintenanceCompleted }: InfoBoxProps) => {
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  function getString() {
    if (financePlanId) {
      return formatString(STRINGS['included-in-plan'], { financePlanId });
    }

    if (isMaintenanceCompleted && hasOpenBalance) {
      return `${STRINGS.lookingToSplit.paragraph_1} ${STRINGS.lookingToSplit.completed}`;
    }

    return `${STRINGS.lookingToSplit.paragraph_1} ${STRINGS.lookingToSplit.not_completed}`;
  }

  return (
    <div className="mb-lg">
      <GreenInfoBox cssClassNames={['px-2sm py-2sm sm:px-sm']}>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <div className="mr-sm">
              <Image alt="plan-it" className="h-auto w-[40px]" src="improvement-reports/plan.svg" />
            </div>
            <String className={isMobile ? 'text-p1' : 'text-body whitespace-pre-line'} string={getString()} />
          </div>
        </div>
      </GreenInfoBox>
    </div>
  );
};
