import { EmployeeAssignmentTypes } from 'models/enums';
import { ReportType } from 'models/enums/typings';
import { getImprovementsSpecialist } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import { EMPLOYEE_TITLES } from 'strings/common.strings';

type Params = {
  listings: {
    homeListingDetailsModel: {
      basicInfo: {
        unitId: string;
      };
      employeeAssignments: {
        employeeAssignment: {
          assignmentType: string;
        };
        employee: {
          bannerImageUrl: string;
        };
        user: {
          profileImageUrl: string;
        };
      }[];
    };
  }[];
  reportType: keyof typeof ReportType;
  flow: {
    configuration: {
      flowSubType: string;
      homeUniqueId: string;
    };
  };
};

type Result = {
  employee:
    | {
        bannerImage: string;
        jobTitle: string;
        profileImage: string;
      }
    | undefined;
};

export const useEmployee = ({ listings, flow, reportType }: Params): Result => {
  const { flowSubType, homeUniqueId } = flow.configuration;

  const currentListing = listings.find((listing) => listing.homeListingDetailsModel.basicInfo.unitId === homeUniqueId);

  if (reportType === ReportType.ImprovementsSpecialist) {
    const employee = getImprovementsSpecialist(currentListing?.homeListingDetailsModel);

    return { employee };
  }

  if (reportType === ReportType.Improvements) {
    const {
      homeListingDetailsModel: { employeeAssignments },
    } = currentListing;

    const employeeCharge =
      flowSubType === 'Onboarding' // todo: check if these contant can be fetched from Swagger types
        ? EmployeeAssignmentTypes.HomeownerGrowth
        : EmployeeAssignmentTypes.Concierge;

    const improvementsReportMainPointOfContact = employeeAssignments.find(
      (employeeAssignment) => employeeAssignment.employeeAssignment.assignmentType === employeeCharge
    );

    const employee = {
      ...improvementsReportMainPointOfContact.employee,
      ...improvementsReportMainPointOfContact.user,
      bannerImage: improvementsReportMainPointOfContact.employee.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[improvementsReportMainPointOfContact.employeeAssignment.assignmentType],
      profileImage: improvementsReportMainPointOfContact.user.profileImageUrl,
    };

    return { employee };
  }

  // todo: complete this case once we migrate pricing report to post-inspection folder
  return undefined;
};
