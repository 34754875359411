import { useState, useEffect } from 'react';
import { scroller } from 'react-scroll';
import { ButtonBase, Text, Tooltip } from '@belong/ui';
import { Confetti } from 'animations/Confetti/Confetti';
import classNames from 'classnames/bind';
import AddOrSubtract from 'components/AddOrSubtract/AddOrSubtract';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Satisfaction } from 'formcomponents/satisfaction/satisfaction';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getPricingAssignedEmployee } from '../utils';
import styles from './InitialListing.module.css';

const cx = classNames.bind(styles);

const InitialListing = ({ stepResource, stepConfigs, stepConfig, stepData, onSaveAndNext, onPrevious }) => {
  const assignedEmployee = getPricingAssignedEmployee(stepResource);

  const getInitialValues = () => {
    if (stepData) {
      return {
        ...stepData.initialPricing,
        userRequestedInitialRent: stepData.initialPricing?.initialListingRent,
      };
    }

    return {};
  };

  const [formValues, setFormValues] = useState(getInitialValues());

  useEffect(() => {
    setFormValues(getInitialValues());
  }, [stepData]);

  const isBadSentiment = (sentiment) => sentiment === 'Bad';

  const getAcceptedPriceFeedBack = () => {
    if (
      formValues?.userRequestedInitialRent !== stepResource.details.listing.initialListingRent ||
      isBadSentiment(formValues?.userInitialPricingSentiment)
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = (values) => {
    const getUserRequestedInitialRent = () => {
      if (
        values.userRequestedInitialRent === stepResource.details.listing.initialListingRent &&
        !isBadSentiment(values.userInitialPricingSentiment)
      ) {
        return null;
      }
      return values.userRequestedInitialRent;
    };

    const updatedValues = {
      initialPricing: {
        ...values,
        userRequestedInitialRent: getUserRequestedInitialRent(),
        userInitialPricingSentiment: values.userInitialPricingSentiment,
        userFeedbackInitialPricing:
          isBadSentiment(values.userInitialPricingSentiment) ||
          values.userRequestedInitialRent > stepResource.details.listing.initialListingRent * 1.05
            ? values.userFeedbackInitialPricing
            : null,
        userAcceptedInitialPricing: getAcceptedPriceFeedBack(values),
      },
    };

    console.log('updatedValues:', updatedValues);
    onSaveAndNext(updatedValues);
  };

  return (
    <>
      <PostInspectionContentLayout
        showCityBackground
        stepConfigs={stepConfigs}
        stepConfig={stepConfig}
        title={POST_INSPECTION_FLOW_STRINGS['initial_listing.title']}
        employee={assignedEmployee}
        noOverlay
      >
        <div>
          <Form
            onSubmit={handleSubmit}
            initialValues={getInitialValues()}
            getForm={({ values }) => {
              return (
                <FormLayout>
                  <Confetti containerClassName="top-0 left-0 w-full fixed pointer-events-none" />

                  <Tooltip
                    contentProps={{ side: 'top', sideOffset: 20 }}
                    arrowProps={{ fill: 'fill-black' }}
                    content={
                      <div className="p-2sm rounded bg-black max-w-lg lg:max-w-2xl text-left">
                        <Text className="text-white" variant="p1">
                          {POST_INSPECTION_FLOW_STRINGS['initial_listing.initial_listing_tooltip']}
                        </Text>
                      </div>
                    }
                    open={values.userRequestedInitialRent > stepResource.details.listing.initialListingRent * 1.05}
                  >
                    <div className="flex flex-col w-full items-center justify-center">
                      <Text className={cx('initial-rent')} fontWeight="semibold">
                        <Field
                          size="large"
                          name="userRequestedInitialRent"
                          color={
                            values.userRequestedInitialRent > stepResource.details.listing.initialListingRent
                              ? 'text-red'
                              : 'text-navy'
                          }
                          changeAmount={25}
                          min={Math.ceil(stepResource.details.listing.initialListingRent * 0.9)}
                          component={AddOrSubtract}
                          onChangeCustom={(value) => {
                            setFormValues({
                              ...formValues,
                              userRequestedInitialRent: value,
                            });
                          }}
                        />
                      </Text>
                      <Text fontWeight="semibold" className="mt-2xl">
                        {POST_INSPECTION_FLOW_STRINGS['initial_listing.initial_listing_price']}
                      </Text>
                    </div>
                  </Tooltip>
                  {values.userRequestedInitialRent > stepResource.details.listing.initialListingRent * 1.05 && (
                    <>
                      <Text variant="h3" fontWeight="semibold" className="mt-xl">
                        {POST_INSPECTION_FLOW_STRINGS['initial_listing.why-question']}
                      </Text>
                      <Field placeholder="Note" component={InputFinalFormAdapter} name="userFeedbackInitialPricing" />
                    </>
                  )}
                  {values.userRequestedInitialRent > stepResource.details.listing.initialListingRent && (
                    <>
                      <div
                        className={cx(
                          'rounded border border-solid border-red p-md bg-red-translucent-light w-full mt-md',
                          styles.whyBox
                        )}
                      >
                        <Text as="p" className="mb-md" fontWeight="semibold">
                          {POST_INSPECTION_FLOW_STRINGS['initial_listing.why-title']}
                        </Text>
                        <Text> {POST_INSPECTION_FLOW_STRINGS['initial_listing.why-description']}</Text>
                      </div>
                    </>
                  )}
                  <div className={cx('align-center', 'hidden md:flex mt-xl')}>
                    <PostInspectionContentLayout.CtaButton type="submit">
                      {POST_INSPECTION_FLOW_STRINGS['initial_listing.next']}
                    </PostInspectionContentLayout.CtaButton>
                  </div>
                  <Space value={SPACE_TYPES.XXXL} />
                  <div className={cx('check-in-wrapper')}>
                    <Text fontSize="h3" fontWeight="semibold">
                      {POST_INSPECTION_FLOW_STRINGS['initial_listing.relationship']}
                    </Text>
                  </div>
                  <Satisfaction
                    onChangeCustom={(value) => {
                      setFormValues({
                        ...formValues,
                        userInitialPricingSentiment: value,
                      });
                      setTimeout(() => {
                        scroller.scrollTo('userFeedbackInitialPricing', {
                          duration: 1000,
                          smooth: true,
                        });
                      }, 150);
                    }}
                    fluid
                    validate={() => {}}
                    name="userInitialPricingSentiment"
                  />

                  {values.userInitialPricingSentiment === 'Bad' &&
                    values.userRequestedInitialRent <= stepResource.details.listing.initialListingRent * 1.05 && (
                      <>
                        <Space value={SPACE_TYPES.XXL} />
                        <TextBubbleWithUserProfile
                          text={POST_INSPECTION_FLOW_STRINGS['initial_listing.employee_reminder']}
                          employee={assignedEmployee}
                        />
                        <Space value={SPACE_TYPES.XL} />
                        <Field
                          placeholder={POST_INSPECTION_FLOW_STRINGS['initial_listing.tell-us-how-you-feel']}
                          component={InputFinalFormAdapter}
                          name="userFeedbackInitialPricing"
                        />
                      </>
                    )}

                  <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
                    <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
                      <Text fontWeight="semibold" className="text-white">
                        Previous
                      </Text>
                    </ButtonBase>

                    <ButtonBase type="submit" className="h-2xl flex-1 bg-green disabled:bg-gray">
                      <Text fontWeight="semibold" className="text-white">
                        Next
                      </Text>
                    </ButtonBase>
                  </div>
                </FormLayout>
              );
            }}
          />
        </div>
      </PostInspectionContentLayout>
    </>
  );
};

InitialListing.propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  stepData: PropTypes.object.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};
export default InitialListing;
