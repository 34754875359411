import { ACTIONS, HOMEOWNER_SET_UP_FLOW } from './constants';

export const HOMEOWNER_SET_UP_FLOW_REDUCER = 'homeOwnerSetupFlow';

const initialState = {
  homeOwnerSetUpFlow: null,
};

const homeownerSetUpFlow = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS[`${HOMEOWNER_SET_UP_FLOW.CREATE_HOMEOWNER_SETUP}_SUCCESS`]:
      return {
        ...state,
        homeOwnerSetUpFlow: { ...action.result },
      };
    case ACTIONS[`${HOMEOWNER_SET_UP_FLOW.FETCH_HOMEOWNER_SETUP_ID}_SUCCESS`]:
      return {
        ...state,
        homeOwnerSetUpFlow: { ...action.result },
      };
    default:
      return state;
  }
};

export default homeownerSetUpFlow;
