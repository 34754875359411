import { useEffect, useRef } from 'react';

type DownloaderProps = {
  blob: string;
  filename: string;
  onDownload: () => void;
};

export const Downloader = ({ blob, filename, onDownload }: DownloaderProps) => {
  const link = useRef(null);

  useEffect(() => {
    link.current.click();
    onDownload();

    return () => {
      URL.revokeObjectURL(blob);
    };
  }, [blob, onDownload]);

  return (
    <a ref={link} href={blob} download={filename} className="hidden">
      pdf
    </a>
  );
};
