import { Carousel } from '@belong/ui';
import { WearAndTearFeatureItem } from 'lease-signing-flow/components/wear-and-tear-feature-item/wear-and-tear-feature-item';
import { features } from 'lease-signing-flow/constants/wear-and-tear';

export const WearAndTearFeatureCarousel = () => (
  <div className="mt-xl">
    <Carousel>
      {features.map((feature, index) => (
        <div className="min-w-full flex justify-center" key={index}>
          <div>
            <WearAndTearFeatureItem image={feature.image} title={feature.title} />
          </div>
        </div>
      ))}
    </Carousel>
  </div>
);
