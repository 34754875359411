import React, { useState } from 'react';
import classNames from 'classnames/bind';
import ProfileImageLargeWithLabel from 'components/ProfileImageLargeWithLabel/ProfileImageLargeWithLabel';
import ProfileImageWithLabel, {
  MODES as PROFILE_IMAGE_MODES,
} from 'components/ProfileImageWithLabel/ProfileImageWithLabel';
import BelongEmployeeProfileModal from 'containercomponents/Modals/BelongEmployeeProfileModal/BelongEmployeeProfileModal';
import PropTypes from 'prop-types';
import styles from './BelongEmployeeProfileView.module.css';

const cx = classNames.bind(styles);
export const MODES = {
  REGULAR: 'regular',
  LARGE: 'large',
};

const BelongEmployeeProfileView = ({
  profileImageMode,
  mode,
  employee,
  noLabel,
  showDivider,
  withModal,
  actions,
  bottomTextColor,
  imageContainer,
  textWrapperClassName,
  titleClassName,
  subtitleClassName,
  small,
}) => {
  const [showBelongEmployeeProfileModal, setShowBelongEmployeeProfileModal] = useState(false);

  return (
    <>
      <div
        className={cx({ 'on-click-listener': withModal })}
        onClick={() => {
          if (withModal) {
            setShowBelongEmployeeProfileModal(true);
          }
        }}
      >
        {mode === MODES.LARGE ? (
          <ProfileImageLargeWithLabel
            profileImage={employee?.profileImage || employee?.profileImageUrl}
            title={employee?.firstName}
            subtitle={employee?.jobTitle}
            noLabel
            showDivider={showDivider}
          />
        ) : (
          <ProfileImageWithLabel
            profileImage={employee?.profileImage || employee?.profileImageUrl}
            title={noLabel ? '' : employee?.firstName}
            subtitle={noLabel ? '' : employee?.jobTitle}
            scale={1}
            mode={profileImageMode}
            actions={actions}
            bottomTextColor={bottomTextColor}
            showDivider={showDivider}
            imageContainer={imageContainer}
            textWrapperClassName={textWrapperClassName}
            titleClassName={titleClassName}
            subtitleClassName={subtitleClassName}
            small={small}
          />
        )}
      </div>
      <BelongEmployeeProfileModal
        show={showBelongEmployeeProfileModal}
        onHide={() => setShowBelongEmployeeProfileModal(false)}
        employee={employee}
      />
    </>
  );
};

BelongEmployeeProfileView.propTypes = {
  mode: PropTypes.oneOf(Object.values(MODES)),
  employee: PropTypes.shape({
    college: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    aboutMe: PropTypes.string,
    quote: PropTypes.string,
    specialities: PropTypes.string,
    homeTown: PropTypes.string,
    serviceArea: PropTypes.string,
    profileImage: PropTypes.string,
    profileImageUrl: PropTypes.string,
    bannerImage: PropTypes.string,
    bannerImageUrl: PropTypes.string,
    jobTitle: PropTypes.node,
  }).isRequired,
  noLabel: PropTypes.bool,
  showDivider: PropTypes.bool,
  profileImageMode: PropTypes.oneOf(Object.values(PROFILE_IMAGE_MODES)),
  withModal: PropTypes.bool,
  actions: PropTypes.node,
  bottomTextColor: PropTypes.string,
  imageContainer: PropTypes.string,
  textWrapperClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  small: PropTypes.bool,
};

BelongEmployeeProfileView.defaultProps = {
  mode: MODES.REGULAR,
  noLabel: false,
  showDivider: false,
  profileImageMode: PROFILE_IMAGE_MODES.LABEL_BELOW,
  withModal: true,
  actions: null,
  bottomTextColor: '',
  imageContainer: '',
  textWrapperClassName: '',
  titleClassName: '',
  subtitleClassName: '',
};

export default BelongEmployeeProfileView;
