import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { Text } from '@belong/ui';
import { FlowFlowModel } from 'api/models';
import cx from 'clsx';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import { formatString } from 'strings';
import styles from './placemat.module.css';
import { PLACEMAT_STRINGS } from './strings';

type PlacematProps = {
  flows: FlowFlowModel[];
};

export function Placemat({ flows }: PlacematProps) {
  const history = useHistory();

  const improvementFlow = flows.find((flow) => flow.flowType === 'ResidentImprovements' && flow.status !== 'Completed');
  const noHiddenSteps = improvementFlow?.steps.filter((step) => !step.isHidden);
  const completedSteps = noHiddenSteps?.filter((step) => step.status === 'Completed') || [];
  const progressPercentage = Math.round((completedSteps?.length * 100) / noHiddenSteps?.length || 0);
  const firstNonCompletedStep = improvementFlow?.steps.find((step) => step.status !== 'Completed');

  const section = {
    subSections: firstNonCompletedStep
      ? [
          {
            name: 'ResidentImprovements',
            displayName: PLACEMAT_STRINGS.improvementsTitle,
            progressPercentage,
            isCompleted: improvementFlow.status === 'Completed',
            disabled: false,
            reportLink: `/accounts/home/post-move-out?flowId=${improvementFlow.uniqueId}&stepId=${firstNonCompletedStep.uniqueId}`,
          },
        ]
      : [],
  };

  if (section.subSections.length === 0) {
    return null;
  }

  return (
    <div className="mb-2xl">
      <Text variant="h3" fontWeight="semibold" className="mb-sm">
        {PLACEMAT_STRINGS.title}
      </Text>
      {section.subSections?.map((subSection) => {
        return (
          <div
            className={cx(styles.flowCard, {
              'bg-green-light-bg': subSection.progressPercentage === 100,
              'cursor-pointer': !subSection.disabled,
              disabled: subSection.disabled,
            })}
            key={subSection.displayName}
            {...(!subSection.disabled && {
              tabIndex: 0,
              role: 'button',
              onClick: () => history.push(subSection.reportLink),
            })}
          >
            <div
              className={cx('font-semibold text-body flex items-center mr-sm', {
                'text-dark-gray': subSection.disabled,
                'text-navy': !subSection.disabled,
              })}
            >
              <h4 className="mr-xs">{formatString(subSection.displayName)}</h4>
            </div>
            <div className="flex items-center mt-sm w-full md:w-auto md:mt-0">
              {subSection.disabled ? (
                <>
                  {subSection.progressPercentage === 100 ? (
                    <>
                      <h4 className="text-green">100%&nbsp;</h4>
                      <h4 className="text-dark-gray">Complete</h4>
                    </>
                  ) : (
                    <h4 className="text-dark-gray">Not Ready</h4>
                  )}
                </>
              ) : (
                <Fragment>
                  <h4
                    className={cx('mr-2xs', {
                      'text-green': subSection.progressPercentage === 100,
                      'text-red': subSection.progressPercentage < 100,
                    })}
                  >
                    {subSection.progressPercentage}%
                  </h4>
                  <h4>Complete</h4>
                </Fragment>
              )}
              <IconButton disabled={subSection.disabled} align={ALIGN_TYPES.LEFT} icon={GENERAL_ICONS.FRONT_ARROW} />
            </div>
          </div>
        );
      })}
    </div>
  );
}
