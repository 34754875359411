export const HOME_FEATURES_STRINGS = {
  title: 'Do you have any of these features in your home <span>to inspect?</span>',
  features: {
    title: "When we inspect, we'll add every feature we find. We just want to make sure we don’t miss anything hard to find.",
    missing: {
      title: 'Something else? Let us know!',
      placeholder: 'Missing Features (OPTIONAL)'
    },
    parking: {
      addMoreTitle: 'Add Another Parking Type'
    }
  }
}
