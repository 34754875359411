import { MaintenancePaymentAgreementModel } from 'api/models';

export const maintenanceAccountResponsibilityStrings = {
  title: 'Let’s get your home taken care of!',
  rejectedSubtitle:
    "We're committed to solving this as quickly as possible to reduce headaches for you down the road and maintain the relationship with your Residents. How can we help?",
  formSubmit: 'Submit',
  formSubmitRejection: 'Submit & Schedule A Call',
  consentAccept: 'YES, PROCEED',
  consentReject: 'I NEED MORE INFORMATION',
  consentShare: 'SURE, IF THEY WANT TO PAY',
  bannerAction: 'Action Required',
};

export const rejectionReasons: { title: string; value: MaintenancePaymentAgreementModel['rejectionReason'] }[] = [
  { title: 'Questions About Pricing', value: 'TooExpensive' },
  { title: 'Questions About The Work To Be Done', value: 'WantSecondOpinionFromAnotherPro' },
  { title: 'Other Questions', value: 'Other' },
];
