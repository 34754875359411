import Pet from 'models/common/Pet';
import { propertyStaticMapImageUrl } from 'utils/staticMapUrlGenerator';
import Address from '../common/Address';
import Kid from '../common/Kid';
import LeaseModel from '../common/LeaseModel';
import LeaseResident from '../common/LeaseResident';
import UnitBasicInfo from '../common/UnitBasicInfo';

export default class RenterAccountLease {
  constructor(data = {}) {
    this.address = new Address(data.address);
    this.leaseInfo = new LeaseModel(data.leaseInfo);
    this.unitInfo = new UnitBasicInfo(data.unitInfo);
    this.residentIncentive = data.residentIncentive;
    this.residents = data.leaseInfo?.residents?.map((resident) => new LeaseResident(resident));
    this.kids = data.leaseInfo?.kids?.map((kid) => new Kid(kid));
    this.pets = data.leaseInfo?.pets?.map((pet) => new Pet(pet));

    this.staticMapImageUrl = propertyStaticMapImageUrl({
      streetAddress: this.address.streetAddress,
      city: this.address.city,
      state: this.address.state,
    });
  }

  getFullAddressForUnit() {
    const { unitNumber } = this.unitInfo;
    return this.address.getFormattedAddress(unitNumber);
  }
}
