import { getAssignedFormattedEmployee } from 'pages/PostInspectionFlow/utils';
import { EMPLOYEE_TITLES } from 'strings/common.strings';

export const getPricingAssignedEmployee = (stepResource) => {
  const { report, homeListingDetailsModel } = stepResource;

  const isMoveOutPricingReport = report?.subType === 'PricingPostMoveOut';

  const assignedEmployee = getAssignedFormattedEmployee({
    employeeAssignments: homeListingDetailsModel.employeeAssignments,
    assignmentTypes: [...(isMoveOutPricingReport ? ['HomeOccupancySpecialist' as const] : []), 'ResidentGrowth'],
  });

  return {
    ...assignedEmployee,
    jobTitle:
      assignedEmployee.assignment.assignmentType === 'HomeOccupancySpecialist'
        ? EMPLOYEE_TITLES.HomeOccupancySpecialist
        : EMPLOYEE_TITLES.ResidentGrowth,
  };
};
