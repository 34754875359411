import classNames from 'classnames/bind';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import PaymentTransaction from 'models/payments/PaymentTransaction';
import { EARNINGS_STRINGS } from '../../../Pages/YourPortfolio/Earnings/earnings.strings';
import TransactionDeposits from '../../TransactionDeposits/TransactionDeposits';
import styles from './payment-bill-paid-detail.module.css';

type PaymentBillPaidDetailProps = {
  transfers: PaymentTransaction[];
};

const cx = classNames.bind(styles);

export function PaymentBillPaidDetail({ transfers }: PaymentBillPaidDetailProps) {
  return (
    <>
      <div className={cx('history-title')}>{EARNINGS_STRINGS['paid_from.label']}</div>
      <Space value={SPACE_TYPES.SM} />
      <TransactionDeposits transactions={transfers} />
    </>
  );
}
