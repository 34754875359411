import { getApiActionTypes } from '../redux_utils';

export const ACTIONS = {
  ...getApiActionTypes('FETCH_LISTING_QUERIES'),
  ...getApiActionTypes('FETCH_CITIES_STATES'),
  ...getApiActionTypes('FETCH_LISTING_MAP_QUERIES'),
  ...getApiActionTypes('FETCH_ALL_HOMES_BY_REGION'),
  CLEAR_LISTING_HOMES: 'CLEAR_LISTING_HOMES',
  SET_HOVERED_VALUE: 'SET_HOVERED_VALUE',
  UPDATE_NEXT_PAGE_INDEX: 'UPDATE_NEXT_PAGE_INDEX',
  LOAD_LISTING_PAGE_DATA: 'LOAD_LISTING_PAGE_DATA',
};
