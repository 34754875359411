export const USER_PROFILE_STRINGS = {
  title: 'Give your listing a human touch by sharing a little more about YOU!',
  'title.populated': 'Last time we asked, you shared these details about yourself. Any updates?',
  'hometown': 'Hometown',
  'about_me': 'Describe Yourself',
  'banner_step.adopted_resident': "Congrats! You're ready to list when your residents move out.",
  'banner_step.not_adopted_resident': 'Congrats! Your professional listing is',
  'banner_step.not_adopted_resident.unlocked': ' unlocked!',
  'banner_step.next_up': "Next up, let's enhance your profile."
}
