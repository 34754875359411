import * as React from 'react';
import { useSelector } from 'react-redux';
import { formatUtcDateTimeToTimeZone, useModal, formatMoney } from '@belong/common';
import { Text, Button, Modal, Spinner } from '@belong/ui';
import { TourModel } from 'api/models';
import { TourHostTile } from 'components/tour-host-tile/tour-host-tile';
import { differenceInHours } from 'date-fns';
import { ResidentType } from 'models/enums';
import RenterAccountLease from 'models/renterAccounts/RenterAccountLease';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import { selectUser } from 'store/redux/user/selectors';

const HOURS_TOLERANCE_TO_DISABLE_CANCELLATION = 2;
const HOURS_TOLERANCE_TO_SHOW_CANCELATION_WARNING = 24;

type OccupiedToursSectionProps = {
  occupiedTours: any[];
  timeZone: string;
  openHomeIncentive: number;
  onCancelOccupiedTour: (id: TourModel['uniqueId']) => void;
  lease: RenterAccountLease;
};

export function OccupiedToursSection({
  occupiedTours,
  timeZone,
  openHomeIncentive,
  onCancelOccupiedTour,
  lease,
}: OccupiedToursSectionProps) {
  const [currentTour, setCurrentTour] = React.useState(null);
  const [isModalOpen, openModal, closeModal] = useModal();
  const [isLoading, startLoader, stopLoader] = useModal();
  const user = useSelector(selectUser);

  const { residents } = lease?.leaseInfo || {};
  const currentResident = residents?.find((resident) => resident.basicInfo.userInfo.userId === user.userId);
  const isPrimaryResident = currentResident?.leaseInfo?.residentType === ResidentType.Primary;

  let isTourWithin24Hours = false;

  if (currentTour) {
    const now = new Date();
    const tourStart = new Date(currentTour.startOn);
    isTourWithin24Hours = differenceInHours(tourStart, now) < HOURS_TOLERANCE_TO_SHOW_CANCELATION_WARNING;
  }

  async function handleCancelTour(tourId) {
    startLoader();
    try {
      await onCancelOccupiedTour(tourId);
    } catch (e) {
      console.error(e);
    }
    stopLoader();
    closeModal();
  }

  return (
    <>
      <AccountPageSection
        title="Upcoming Appointments"
        content={occupiedTours.map(({ tour, host, bookedAttendees }) => {
          return (
            <div
              key={tour.uniqueId}
              className="w-full flex flex-col md:flex-row mb-xl last:mb-0 border-solid border-0 border-l-3 border-blue pl-sm"
            >
              <div className="flex-1">
                <div className="mb-xs">
                  Occupied Group Tour
                  <span className="hidden md:inline-block md:mx-2xs">·</span>
                  <span className="contents md:hidden">
                    <br />
                  </span>
                  {bookedAttendees} attendees confirmed
                </div>

                <Text as="span" fontWeight="semibold" className="mb-lg">
                  {formatUtcDateTimeToTimeZone({ dateTime: tour.startOn, format: 'MMM do', timeZone })}{' '}
                  {formatUtcDateTimeToTimeZone({ dateTime: tour.startOn, format: 'h:mma', timeZone })}-
                  {formatUtcDateTimeToTimeZone({ dateTime: tour.endOn, format: 'h:mma z', timeZone })}
                </Text>

                {host && <TourHostTile {...host} />}
              </div>
              {isPrimaryResident && (
                <div className="mt-xl md:mt-0">
                  <Button
                    variant="text"
                    onClick={() => {
                      openModal();
                      setCurrentTour({ ...tour, bookedAttendees, host });
                    }}
                  >
                    CANCEL TOUR
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      />

      {currentTour && (
        <Modal name="cancel_occupied_tour" isOpen={isModalOpen} onDismiss={closeModal}>
          {isLoading && <Spinner />}
          <div className="py-2xl px-xl flex flex-col items-center mt-xl lg:mt-0">
            <Text variant="h2" fontWeight="semibold" className="mb-xs text-center">
              {isTourWithin24Hours
                ? 'Are you sure? This tour is starting in less than 24 hours.'
                : 'Do you want to cancel this tour?'}
            </Text>

            <Text
              className="mb-xl text-center"
              dangerouslySetInnerHTML={{
                __html: isTourWithin24Hours
                  ? currentTour.bookedAttendees > 0
                    ? `Please know ${currentTour.bookedAttendees} potential residents reserved a spot on this tour. By cancelling, we’ll reach out to them individually to reschedule.`
                    : ''
                  : `By doing so, we’ll reschedule it for a later time. Please note that this will make it more difficult to place residents and <b>ensure you earn ${formatMoney(
                      openHomeIncentive
                    )}.</b>`,
              }}
            />

            {isTourWithin24Hours && (
              <div className="mb-xl">
                <Text as="span" fontWeight="semibold" className="mb-lg">
                  {formatUtcDateTimeToTimeZone({ dateTime: currentTour.startOn, format: 'MMM do', timeZone })}{' '}
                  {formatUtcDateTimeToTimeZone({ dateTime: currentTour.startOn, format: 'h:mma', timeZone })}-
                  {formatUtcDateTimeToTimeZone({ dateTime: currentTour.endOn, format: 'h:mma z', timeZone })}
                </Text>

                {currentTour.host && (
                  <div className="flex justify-center">
                    <TourHostTile {...currentTour.host} />
                  </div>
                )}
              </div>
            )}

            <div className="mb-lg">
              <Button size="large" onClick={closeModal}>
                No, nevermind.
              </Button>
            </div>
            <Button variant="text" buttonType="danger" onClick={() => handleCancelTour(currentTour.uniqueId)}>
              Yes, Cancel THIS TOUR
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
