import classNames from 'classnames/bind';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Home from 'models/home/Home';
import PropTypes from 'prop-types';
import Sqft from '../../../components/display/General/Sqft/Sqft';
import { getBathRoomString } from '../../../models/common/UnitBasicInfo';
import styles from './HomeFeatures.module.css';

const cx = classNames.bind(styles);

const HomeFeatures = ({ home, onSectionClick, showMedia }) => {
  const { mediaGroup, featuresMediaSectionNames, basicInfo } = home;

  const sortFeatures = (a, b) => {
    const aGroupMedia = mediaGroup[a]?.length;
    const bGroupMedia = mediaGroup[b]?.length;
    if (!aGroupMedia && !bGroupMedia) return 0;
    else if (aGroupMedia && !bGroupMedia) return -1;
    else if (!aGroupMedia && bGroupMedia) return 1;
    else if (aGroupMedia < bGroupMedia) return -1;
    else if (aGroupMedia > bGroupMedia) return 1;
    else return 0;
  };

  const byCategory = featuresMediaSectionNames.reduce((arr, item) => {
    const category = item.split('|')[0];
    if (arr[category]) {
      arr[category].push(item);
    } else {
      arr[category] = [item];
    }
    return arr;
  }, {});

  const getMediaSections = (categoryName) => {
    return byCategory[categoryName].sort(sortFeatures).filter((section) => !!mediaGroup[section].length);
  };

  const getNonMediaSections = (categoryName) => {
    return byCategory[categoryName].sort(sortFeatures).filter((section) => !mediaGroup[section].length || !showMedia);
  };

  return (
    <div className={cx('home-tour')}>
      <div className={cx('category-label')}>Square Footage</div>
      <div className=" inline-block border border-solid px-xs py-2xs mb-md rounded-small">
        <Sqft sqft={basicInfo?.areaInSquareFeet} />
      </div>

      <div className={cx('category-label')}>Bed & Bathroom Count</div>
      <div className=" inline-block border border-solid px-xs py-2xs mb-md rounded-small">
        {basicInfo?.formattedBeds}
      </div>
      <div className=" inline-block border border-solid px-xs py-2xs ml-xs rounded-small">
        {getBathRoomString(basicInfo)}
      </div>

      {Object.keys(byCategory).map((categoryName, index) => {
        return (
          <div key={index}>
            <div className={cx('category-label')}>{categoryName}</div>
            {showMedia && (
              <Row>
                {getMediaSections(categoryName).map((section) => {
                  const sectionMediaGroup = mediaGroup[section];

                  if (!sectionMediaGroup) {
                    return null;
                  }

                  const hasMedia = sectionMediaGroup.length;
                  const isCommunal = section.split('|')[2] === 'communal';

                  return (
                    <Col sm={3} xs={6} key={section}>
                      {isCommunal && <div className={cx('communal-label')}>Communal</div>}
                      <ButtonBase
                        className={cx('photo', { noMedia: !hasMedia })}
                        onClick={hasMedia ? () => onSectionClick(categoryName) : () => {}}
                      >
                        <div
                          className={cx('image')}
                          style={{
                            backgroundImage: `url('${sectionMediaGroup[0].url}')`,
                          }}
                        />
                        <div className={cx('name')}>{section.split('|')[1]}</div>
                      </ButtonBase>
                    </Col>
                  );
                })}
              </Row>
            )}
            <div
              className={cx('features-without-photos', {
                noLengthNoImageSection: !getNonMediaSections(categoryName)?.length,
              })}
            >
              {getNonMediaSections(categoryName).map((section, innerIndex) => {
                let featureName = section.split('|')[1];
                const isCommunal = section.split('|')[2] === 'communal';

                if (isCommunal && !featureName.includes('Communal')) featureName += ' (Communal)';

                return (
                  <div key={innerIndex} className={cx('no-image')}>
                    {featureName}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
HomeFeatures.propTypes = {
  home: PropTypes.instanceOf(Home).isRequired,
  onSectionClick: PropTypes.func.isRequired,
  showMedia: PropTypes.bool,
};

HomeFeatures.defaultProps = {
  showMedia: true,
};

export default HomeFeatures;
