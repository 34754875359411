import { Fragment } from 'react';
import { NO_LIMIT_ITEM, PET_TYPES } from 'accounts/constants/home-pets';
import { HOME_PETS_STRINGS } from 'accounts/constants/strings/home-pets.strings';
import { useHomePets } from 'accounts/hooks/homeowners/use-home-pets';
import classNames from 'classnames/bind';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import { NumberDropdownInfinityFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import Icon from 'components/Icon/Icon';
import { ICONS } from 'components/Icon/constants';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskNumber, unmaskNumber } from 'components/Input/masks';
import { MultiSelectAutoCompleteFormAdapter } from 'components/MultiSelectAutoComplete/MultiSelectAutoComplete';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import String from 'components/String/String';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { getString } from 'strings';
import { composeValidators, integer, required } from 'utils/validation';
import styles from './unit-pet-preferences.module.css';

const cx = classNames.bind(styles);

export function UnitPetPreferences({ form, initialValues }) {
  const {
    petTypeStrings,
    hideAnimationGreenInfoBox,
    hideGreenInfoBox,
    inputValue,
    breedOptions,
    selectedBreeds,
    breedTagsToShow,
    handleRemoveTag,
    setHideAnimateGreenInfoBox,
    setHideGreenInfoBox,
    changeFormValue,
    closeGreenBoxMenu,
    handleChange,
    handleChangeInput,
    handleChangePetsAllowed,
  } = useHomePets({
    form,
    initialValues,
  });

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Field
            name="petsAllowed"
            component={SelectorFinalFormAdapter}
            validate={required}
            fluid
            buttons={[
              {
                label: HOME_PETS_STRINGS.preferences.petsAllowed.anyPets,
                icon: <Icon icon={ICONS.ANY_PET.DEFAULT} responsive />,
                iconSelected: <Icon icon={ICONS.ANY_PET.INVERSE} responsive />,
                key: PET_TYPES.ALL,
              },
              {
                label: HOME_PETS_STRINGS.preferences.petsAllowed.dogsOnly,
                icon: <Icon icon={ICONS.PET_DOG.DEFAULT} responsive />,
                iconSelected: <Icon icon={ICONS.PET_DOG.INVERSE} responsive />,
                key: PET_TYPES.DOGS,
              },
              {
                label: HOME_PETS_STRINGS.preferences.petsAllowed.catsOnly,
                icon: <Icon icon={ICONS.PET_CAT.DEFAULT} responsive />,
                iconSelected: <Icon icon={ICONS.PET_CAT.INVERSE} responsive />,
                key: PET_TYPES.CATS,
              },
              {
                label: HOME_PETS_STRINGS.preferences.petsAllowed.noPets.text,
                icon: <Icon icon={ICONS.NO_PET.DEFAULT} responsive />,
                iconSelected: <Icon icon={ICONS.NO_PET.INVERSE} responsive />,
                key: PET_TYPES.NONE,
              },
            ]}
            type={SELECTOR_TYPES.SELECTOR}
            onChangeCustom={handleChangePetsAllowed}
          />
          <Condition when="petsAllowed" is={PET_TYPES.NONE}>
            <GreenInfoBox
              onAnimationEnd={() => {
                setHideAnimateGreenInfoBox(false);
                setHideGreenInfoBox(true);
              }}
              cssClassNames={[cx({ hideGreenInfoBox }, { hideAnimationGreenInfoBox })]}
            >
              <GreenInfoBox.Header handleClose={closeGreenBoxMenu} />
              <div className={cx('green-box-title')}>
                <String string={HOME_PETS_STRINGS.preferences.petsAllowed.noPets.confirmationTitle} />
              </div>
              <Space value={SPACE_TYPES.XS} />
              <div className={cx('green-box-text')}>
                {HOME_PETS_STRINGS.preferences.petsAllowed.noPets.confirmationBold.map((_value, index) => (
                  <div key={index}>
                    <div className={cx('bold')}>
                      <String string={HOME_PETS_STRINGS.preferences.petsAllowed.noPets.confirmationBold[index]} />
                    </div>
                    <String string={HOME_PETS_STRINGS.preferences.petsAllowed.noPets.confirmationText[index]} />
                  </div>
                ))}
              </div>
            </GreenInfoBox>
          </Condition>
        </Col>
      </Row>
      <Condition when="petsAllowed" hasValue ignore={PET_TYPES.NONE}>
        <div className="mt-lg">
          <FormLayout.Section
            firstSection
            sectionTitle={getString(HOME_PETS_STRINGS.preferences.howManyPets.sectionTitle, {
              lowerCaseType: petTypeStrings.lowerCaseType,
            })}
          >
            <Row>
              <Col md={6}>
                <Field
                  placeholder={HOME_PETS_STRINGS.preferences.petsAllowed['maxAllowedPets.placeholder']}
                  name="maxAllowedPets"
                  component={NumberDropdownInfinityFinalFormAdapter}
                  validate={required}
                  max={5}
                  infinityObject={NO_LIMIT_ITEM}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Field
                  name="allPetsAllowed"
                  alignWithField={false}
                  component={CheckboxFinalFormAdapter}
                  label={getString(HOME_PETS_STRINGS.preferences.howManyPets.allShapesCheckbox, {
                    upperCaseType: petTypeStrings.upperCaseType,
                  })}
                  onClickCustom={() =>
                    changeFormValue({
                      restrictedBreeds: 'restrictedBreeds',
                      hasWeightRestriction: 'hasWeightRestriction',
                      hasRestrictedBreeds: 'hasRestrictedBreeds',
                    })
                  }
                />
              </Col>
            </Row>
          </FormLayout.Section>
          <Condition when="allPetsAllowed" isNot>
            <FormLayout.Section sectionTitle={HOME_PETS_STRINGS.preferences.selectThatApplyTitle}>
              <div className={cx('weight-restriction', { checked: true })}>
                <Row>
                  <Col md={12}>
                    <Field
                      useOnChangeFormToHandle
                      alignCenter
                      name="hasWeightRestriction"
                      alignWithField={false}
                      component={CheckboxFinalFormAdapter}
                      label={getString(HOME_PETS_STRINGS.preferences.weightRestrictionsLabel, {
                        lowerCaseType: petTypeStrings.lowerCaseType,
                      })}
                    />
                  </Col>
                </Row>
              </div>
              <Condition when="hasWeightRestriction" is>
                <Row>
                  <Col md={6}>
                    <Field
                      name="maxWeightPerPet"
                      component={InputFinalFormAdapter}
                      mask={maskNumber}
                      unmask={unmaskNumber}
                      validate={composeValidators(required, integer)}
                      placeholder={HOME_PETS_STRINGS.preferences.weightLabel}
                    />
                  </Col>
                </Row>
              </Condition>

              <div className={cx('breed-restriction', { weightChecked: true })}>
                <Row>
                  <Col md={12}>
                    <Field
                      useOnChangeFormToHandle
                      alignCenter
                      name="hasRestrictedBreeds"
                      alignWithField={false}
                      component={CheckboxFinalFormAdapter}
                      label={HOME_PETS_STRINGS.preferences.breedRestrictionsLabel}
                    />
                  </Col>
                </Row>
              </div>
              <Condition when="hasRestrictedBreeds" is>
                <Field
                  name="restrictedBreeds"
                  component={MultiSelectAutoCompleteFormAdapter}
                  placeholder={HOME_PETS_STRINGS.preferences.hoaRestrictionsLabel}
                  availableItems={breedOptions}
                  tagsToShow={breedTagsToShow}
                  selectedItem={selectedBreeds}
                  onInputValueChange={handleChangeInput}
                  inputValue={inputValue}
                  onChangeCustom={handleChange}
                  onRemoveItem={handleRemoveTag}
                  validate={(value) => (value.length ? null : 'Error')}
                />
              </Condition>
            </FormLayout.Section>
          </Condition>
        </div>
      </Condition>
    </Fragment>
  );
}
