import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { NumberDropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskNumber, unmaskNumber, maskYYYY, unmaskYYYY } from 'components/Input/masks';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { required, validPastYYYYString } from 'utils/validation';
import styles from './UnitBasicsForm.module.css';

const cx = classNames.bind(styles);

export default class UnitBasicsForm extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    homeownerSetupView: PropTypes.bool,
    disabled: PropTypes.bool,
    containsUnits: PropTypes.bool,
  };

  static defaultProps = {
    homeownerSetupView: false,
    disabled: false,
    containsUnits: false,
  };

  render() {
    const { name, homeownerSetupView, containsUnits, disabled } = this.props;
    return (
      <>
        <Row>
          <div className={cx('first-section', { wrapper: !containsUnits })}>
            <Col md={3}>
              <Field
                name={`${name}.bedroomCount`}
                component={NumberDropdownFinalFormAdapter}
                validate={required}
                placeholder="Bedrooms"
                max={9}
                disabled={disabled}
                applyDisabledStyle={disabled}
              />
            </Col>
          </div>
          <div className={cx({ wrapper: !containsUnits })}>
            <Col md={3}>
              <Field
                name={`${name}.fullBathroomCount`}
                component={NumberDropdownFinalFormAdapter}
                validate={required}
                placeholder="Full Bathrooms"
                max={9}
                disabled={disabled}
                applyDisabledStyle={disabled}
              />
            </Col>
          </div>
          <div className={cx({ wrapper: !containsUnits })}>
            <Col md={3}>
              <Field
                name={`${name}.halfBathroomCount`}
                component={NumberDropdownFinalFormAdapter}
                placeholder="Half Bathrooms"
                max={10}
                allowZero
                disabled={disabled}
                applyDisabledStyle={disabled}
              />
            </Col>
          </div>
          <div className={cx({ wrapper: !containsUnits })}>
            <Col md={3}>
              <Field
                name={`${name}.areaInSquareFeet`}
                component={InputFinalFormAdapter}
                validate={required}
                placeholder="Square Footage"
                mask={maskNumber}
                unmask={unmaskNumber}
                disabled={disabled}
                applyDisabledStyle={disabled}
              />
            </Col>
          </div>
        </Row>
        {!homeownerSetupView && (
          <Row>
            <Col md={6}>
              <Field
                name={`${name}.yearPurchased`}
                component={InputFinalFormAdapter}
                placeholder="Year Purchased (Optional)"
                validate={validPastYYYYString}
                mask={maskYYYY}
                unmask={unmaskYYYY}
              />
            </Col>
          </Row>
        )}
        {!homeownerSetupView && (
          <>
            <Space value={null} mobileValue={SPACE_TYPES.LG} />
            <Field hidden name={`${name}.unitId`} component={InputFinalFormAdapter} />
          </>
        )}
      </>
    );
  }
}
