import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import FadeIn from 'animations/FadeIn/FadeIn';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import { getDefaultResidentGrowth } from 'consts/employee-assignments';
import { Text, Flex, Box, LoadingCircle } from 'design-system';
import { useInterval } from 'hooks/useInterval';
import PropTypes from 'prop-types';
import { selectHomeById } from 'store/redux/homes/selectors';
import { updateApplicationFlowComplete } from 'store/redux/resident-application/actions';
import { selectResidentApplicationId } from 'store/redux/resident-application/selectors';
import { fetchAllVerificationsAction } from 'store/redux/user/actions';
import { selectUser } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../Steps/steps';

const verificationsMessages = [
  'Running Credit Reports',
  // Reason for removing: https://belonghome.slack.com/archives/C03SR4TQMHT/p1660686048423039
  // 'Verifying income',
  'Checking Background',
  'Verifying ID Documents',
  'Researching Weather Patterns',
];

const interval = 4 * 1000; // Fetch every 4 seconds
const minDuration = 20 * 1000; // Animation will last a min of 16 seconds.
const stopAt = 60 * 1000; // Stop at 60 seconds
const { processing_verification_modal: strings } = RESIDENT_APPLICATION_STRINGS;

const RowElement = ({ value }) => {
  return (
    <Flex alignItems="center" mb="2sm">
      <GeneralIcon scale={0.75} color={COLORS.GREEN} icon={GENERAL_ICONS.CHECKMARK} />{' '}
      <Box ml="xs">
        <Text fontSize="p1">{value}</Text>
      </Box>
    </Flex>
  );
};

RowElement.propTypes = {
  value: PropTypes.string.isRequired,
};

const AnimatedRowElement = FadeIn(RowElement);

const ProcessingVerificationsModalComponent = ({
  show,
  history,
  applicationId,
  home,
  user,
  // fetchAllVerifications,
  updateApplicationFlowCompleteAction,
  onClose,
}) => {
  const [timer, setTimer] = useState(0);
  // const [verifications, setVerifications] = useState();

  const allVerificationsCompleted = true;

  // We are done if we waited more than 60 seconds,
  // or if all verifications are in a non-processing state and at least 'minDuration' seconds have passed
  const finished = timer >= stopAt || (allVerificationsCompleted && timer >= minDuration);

  useEffect(() => {
    const handleApplicationSubmit = async () => {
      try {
        await updateApplicationFlowCompleteAction(applicationId);
      } catch (e) {
        console.error(e);
      }
    };
    handleApplicationSubmit();
  }, []);

  useInterval(
    () => {
      // const fetchData = async () => {
      //   const currentVerifications = await fetchAllVerifications();
      //   setVerifications(currentVerifications?.[0]?.verifications);
      // };

      setTimer((value) => value + interval);

      // if (!allVerificationsCompleted) {
      //   fetchData();
      // }
    },
    finished ? null : interval
  );

  useEffect(() => {
    const handleFinished = async () => {
      history.push(getResidentApplicationStepPath(STEPS_CONFIG.SUCCESS_PAGE, { houseId: home.basicInfo.unitId }));
      onClose();
    };

    if (finished) {
      handleFinished();
    }
  }, [finished]);

  const defaultResidentGrowth = getDefaultResidentGrowth(home?.propertyInfo?.coverageRegionId);
  const employee = home?.employees?.residentGrowth
    ? {
        ...home.employees?.residentGrowth,
        jobTitle: 'Your Leasing Advisor',
      }
    : {
        ...defaultResidentGrowth.employeeAssignment,
        ...defaultResidentGrowth.employee,
        ...defaultResidentGrowth.user,
        jobTitle: 'Your Leasing Advisor',
      };

  const currentMessagesInDisplay = verificationsMessages.slice(0, timer / interval + 1);

  return (
    <ModalV2
      show={show}
      name={ANALYTICS_MODAL_NAMES.PROCESSING_VERIFICATIONS}
      noFullPageHeader
      closeButton={false}
      type={MODAL_TYPES.FULL_PAGE}
    >
      <Box maxWidth={730} mx="auto" px={['2sm', '2sm', 0]} pb="3xl">
        <TextBubbleWithUserProfile
          employee={employee}
          text={formatString(strings.title, { firstName: user.firstName })}
          showContact
        />
        <Flex justifyContent="center" mt="3xl">
          <LoadingCircle numberOfPoints={30}>
            <Flex height="100%" flexDirection="column" justifyContent="center" pl="2xl">
              <Box width="100%" height={verificationsMessages.length * 36}>
                {currentMessagesInDisplay.map((value) => {
                  return <AnimatedRowElement key={value} value={value} />;
                })}
              </Box>
            </Flex>
          </LoadingCircle>
        </Flex>
      </Box>
    </ModalV2>
  );
};

ProcessingVerificationsModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  home: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  // fetchAllVerifications: PropTypes.func.isRequired,
  updateApplicationFlowCompleteAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  home: selectHomeById(state, props.match?.params?.houseId),
  user: selectUser(state),
  applicationId: selectResidentApplicationId(state),
});

const mapDispatchToProps = {
  fetchAllVerifications: fetchAllVerificationsAction,
  updateApplicationFlowCompleteAction: updateApplicationFlowComplete,
};

export const ProcessingVerificationsModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProcessingVerificationsModalComponent)
);
