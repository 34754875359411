import { useState } from 'react';
import { formatUtcDateTimeToTimeZone } from '@belong/common';
import { Calendar, Spinner, Text } from '@belong/ui';
import { InspectionDemandBanner } from 'accounts/components/inspection-demand-banner/inspection-demand-banner';
import { useDailyPotentialLoss, useInspectionAvailabilityDates } from 'accounts/hooks/hooks';
import clsx from 'clsx';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import { isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { required } from 'utils/validation';

export function InspectionDatePicker({
  input,
  meta,
  ignoreError,
  onChangeCustom,
  form,
  timeZone = 'America/Los_Angeles',
  unit,
  timePickerWrapperClassName = '',
  sideInformationWrapperClassName = '',
  wrapperClassName = '',
  calendarRootClassName = '',
  demandWrapperClassName = '',
  ...rest
}) {
  const [startDate, setStartDate] = useState(new Date());
  const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  const [endDate, setEndDate] = useState(lastDayOfMonth);

  const { dailyPotentialLoss, isLoadingDailyPotentialLoss } = useDailyPotentialLoss({ unit });

  const { availabilityDates, disabledDates, preferredDates, isLoadingDates } = useInspectionAvailabilityDates({
    unit,
    startDate,
    endDate,
    timeZone,
  });

  const selectedDate: Date = input.value;

  const isInspectionAfterNineDays = selectedDate
    ? isAfter(utcToZonedTime(new Date(selectedDate), timeZone), preferredDates[preferredDates.length - 1])
    : false;

  return (
    <div className="relative">
      <div className={clsx('flex flex-wrap lg:flex-wrap xl:flex-nowrap ml-0 relative mt-2xl', wrapperClassName)}>
        {!isLoadingDailyPotentialLoss && !isLoadingDates && (
          <>
            <Calendar
              {...input}
              {...rest}
              error={!ignoreError ? meta.error : false}
              selected={selectedDate}
              onSelect={(key, object) => {
                input.onChange(key, object);

                if (onChangeCustom) {
                  onChangeCustom(key, object);
                }
              }}
              onMonthChange={(newMonth) => {
                const lastDayOfCurrentMonth = new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 0);

                input.onChange(null);
                form.change('time', undefined);
                setStartDate(newMonth);
                setEndDate(lastDayOfCurrentMonth);
              }}
              disabledDays={disabledDates}
              preferredDays={preferredDates}
              rootClassName={calendarRootClassName}
              month={startDate}
            />
            <div
              className={clsx(
                'flex flex-col mt-sm ml-2xs sm:ml-lg md:ml-0 border-0 md:border-l lg:border-0 xl:border-l border-solid border-gray w-full',
                sideInformationWrapperClassName
              )}
            >
              <div className={clsx('contents sm:block', timePickerWrapperClassName)}>
                <div className="-mt-sm">
                  <Field
                    component={DropdownFinalFormAdapter}
                    items={
                      availabilityDates && selectedDate
                        ? availabilityDates[selectedDate.toISOString().split('T')[0]]
                        : []
                    }
                    name="time"
                    placeholder="Select Time"
                    disabled={!selectedDate}
                    validate={required}
                  />
                </div>
                <Text className="m-0 mt-xs sm:mt-2sm text-dark-gray">
                  {`All timezones in ${formatUtcDateTimeToTimeZone({
                    dateTime: new Date(),
                    format: 'z',
                    timeZone,
                  })}.`}
                </Text>
              </div>
            </div>
          </>
        )}
      </div>
      {isInspectionAfterNineDays && (
        <div className={clsx('mt-xl', demandWrapperClassName)}>
          <InspectionDemandBanner dailyPotentialLoss={dailyPotentialLoss} />
        </div>
      )}
      {(isLoadingDailyPotentialLoss || isLoadingDates) && (
        <div className="mt-2xl min-h-[100px]">
          <Spinner />
        </div>
      )}
    </div>
  );
}
