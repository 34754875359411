import { useContext } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Image, Money, Text } from '@belong/ui';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { STRINGS } from 'post-inspection/constants/strings';

export const FinancingInfobox = () => {
  const { homeownerReportPayment } = useContext<any>(ApprovalContext);
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  const { monthlyPayment } = homeownerReportPayment;

  const fontVariant = isMobile ? 'p1' : 'body';

  return (
    <div className="mb-lg">
      <GreenInfoBox cssClassNames={['px-2sm py-2sm sm:px-sm']}>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <div className="mr-sm">
              <Image alt="plan-it" className="h-auto w-[40px]" src="improvement-reports/plan.svg" />
            </div>
            <div className="max-w-[130px] sm:max-w-none">
              <Text fontWeight="semibold" variant={fontVariant}>
                {STRINGS['up-to-12-month-financing-available']}
              </Text>
            </div>
          </div>

          <div className="flex items-center justify-end">
            <Text as="span" fontWeight="semibold" variant={fontVariant}>
              {STRINGS.est}
            </Text>
            &nbsp;
            <Money
              as="span"
              fontWeight="semibold"
              format="DOLLARS_NO_CENTS"
              variant={fontVariant}
              value={monthlyPayment}
            />
            <Text as="span" fontWeight="semibold" variant={fontVariant}>
              {STRINGS.monthly}
            </Text>
          </div>
        </div>
      </GreenInfoBox>
    </div>
  );
};
