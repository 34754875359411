import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { COOKIES_CONFIG } from '@belong/common';
import { Text, Button } from '@belong/ui';
import { ANALYTICS_CLICK_ACTIONS } from 'analytics';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import HeaderDesktopMenuComponent from 'components/HeaderMain/HeaderDesktopMenuComponent/HeaderDesktopMenuComponent';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import { DEFAULT_LINKS } from 'consts/links';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import RenterAccountBookmark from 'models/renterAccounts/RenterAccountBookmark';
import { destroyCookie } from 'nookies';
import PropTypes from 'prop-types';
import { PATHS } from 'routes/paths';
import {
  selectAccountAllLeases,
  selectBookmarks,
  selectFavoriteHomes,
  selectActiveTourRequests,
} from 'store/redux/renter-accounts/selectors';
import { logOut } from 'store/redux/user/actions';
import { selectUserClaims } from 'store/redux/user/selectors';
import { RouterHeaderNavLink } from '../HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import styles from './AccountHeader.module.css';

const cx = classNames.bind(styles);

const { REFERRAL_USER, REFERRAL_ID } = COOKIES_CONFIG;

const getListOfLinks = ({ userClaims, accountAllLeases, isLoggedIn, favorites, bookmarks, tourRequests }) => {
  const listOfLinks = [];

  if (userClaims && userClaims.isHomeOwner) {
    listOfLinks.push(DEFAULT_LINKS.YOUR_HOMES);
  } else if (userClaims && userClaims.isResident) {
    if (accountAllLeases.length) {
      listOfLinks.push(DEFAULT_LINKS.YOUR_HOME);
    }
  }

  if (isLoggedIn) {
    if (favorites.length) {
      listOfLinks.push(DEFAULT_LINKS.FAVORITES_EXIST);
    } else {
      listOfLinks.push(DEFAULT_LINKS.FAVORITES_NOT_EXISTING_YET);
    }
  }
  if (bookmarks?.length || tourRequests?.length) {
    listOfLinks.push(DEFAULT_LINKS.TOURS_AND_APPS);
  }

  listOfLinks.push(DEFAULT_LINKS.YOUR_PROFILE);

  return listOfLinks;
};

class AccountHeader extends Component {
  childNode = React.createRef();

  state = { showAccountMenu: false };

  // Refactor to use a hash
  setRef = React.createRef();

  handleSignOut = async () => {
    const { logOut: logOutAction, history } = this.props;
    try {
      destroyCookie(null, REFERRAL_USER.name, { path: '/' });
      destroyCookie(null, REFERRAL_ID.name, { path: '/' });

      logOutAction();
    } catch (err) {
      console.error(err);
    }
    history.push(PATHS.HOME_PAGE);
  };

  handleShowAccountMenuTrue = (e) => {
    e.stopPropagation();
    this.setState(
      (state) => ({
        showAccountMenu: !state.showAccountMenu,
      }),
      () => {
        if (this.state.showAccountMenu) {
          return document.addEventListener('click', this.handleShowAccountMenuFalse);
        }
        return document.removeEventListener('click', this.handleShowAccountMenuFalse);
      }
    );
  };

  handleShowAccountMenuFalse = () => {
    this.setState({ showAccountMenu: false }, () => {
      document.removeEventListener('click', this.handleShowAccountMenuFalse);
    });
  };

  isSelected = (to) => {
    const {
      location: { pathname },
    } = this.props;

    if (to === DEFAULT_LINKS.YOUR_PROFILE.to) {
      return to === pathname;
    }

    if (to === DEFAULT_LINKS.YOUR_HOME.to) {
      if (pathname.includes('accounts') && pathname !== DEFAULT_LINKS.YOUR_PROFILE.to) {
        return true;
      }
    }
    return false;
  };

  render() {
    const {
      isLoggedIn,
      profileImage,
      inAccountsSection,
      firstName,
      onLogin,
      userClaims,
      accountAllLeases,
      bookmarks,
      favorites,
      theme,
      tourRequests,
    } = this.props;
    const { showAccountMenu } = this.state;

    const listOfLinks = getListOfLinks({
      userClaims,
      accountAllLeases,
      favorites,
      isLoggedIn,
      bookmarks,
      tourRequests,
    });

    if (!isLoggedIn) {
      return <RouterHeaderNavLink label={DEFAULT_LINKS.ACCOUNT.label} onClick={onLogin} />;
    }

    return (
      <>
        <div className={cx('button-container')}>
          <ButtonBase
            id="menubutton1"
            aria-haspopup="true"
            aria-controls="menu"
            onClick={this.handleShowAccountMenuTrue}
            onMouseEnter={this.handleShowAccountMenuTrue}
            className={cx('account-button', theme)}
            ref={this.setRef}
            action={ANALYTICS_CLICK_ACTIONS.MY_ACCOUNT_VIEW_OPTIONS}
            tabIndex="0"
          >
            <div className="flex items-center">
              <Text variant="p1" fontWeight="semibold" className={cx(showAccountMenu && 'text-green')}>
                {firstName}
              </Text>
              <ProfileImage
                profileImage={profileImage}
                classNameToResizeImage={cx('thumbnail-image', theme)}
                emptyImageContainer={cx('empty-icon')}
              />
            </div>
          </ButtonBase>
          <HeaderDesktopMenuComponent
            menuClassName={cx(`${showAccountMenu ? 'display-true' : 'display-none'}`)}
            listOfLinks={[...listOfLinks]}
            firstName={firstName}
            showSignOut
          />
          <div className={cx('show-on-mobile')}>
            <LinkBase
              id="menubutton1"
              className={cx('mobile-account-button')}
              tabIndex="0"
              to={DEFAULT_LINKS.ACCOUNT.to}
            >
              <div className={cx('hover')}>
                <Text
                  className={clsx(inAccountsSection && 'underline decoration-2 underline-offset-2')}
                  variant="h3"
                  fontWeight="semibold"
                >
                  {firstName ? `HI ${firstName}` : 'MY ACCOUNT'}
                </Text>
              </div>
              <div className={cx('position-profile-image-on-header')}>
                <ProfileImage
                  profileImage={profileImage}
                  classNameToResizeImage={cx('thumbnail-image', theme)}
                  emptyImageContainer={cx('empty-icon')}
                />
              </div>
            </LinkBase>
            <Button onClick={this.handleSignOut} variant="text">
              <Text variant="h3" fontWeight="semibold">
                SIGN OUT
              </Text>
            </Button>
          </div>
        </div>
      </>
    );
  }
}

AccountHeader.propTypes = {
  profileImage: PropTypes.string,
  // Will Refactor Later To Use Jwt auth.
  inAccountsSection: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  firstName: PropTypes.string,
  onLogin: PropTypes.func,
  userClaims: PropTypes.object,
  logOut: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  accountAllLeases: PropTypes.array,
  bookmarks: PropTypes.arrayOf(PropTypes.instanceOf(RenterAccountBookmark)),
  tourRequests: PropTypes.arrayOf(PropTypes.object),
  favorites: PropTypes.array,
  theme: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

AccountHeader.defaultProps = {
  profileImage: '',
  isLoggedIn: false,
  firstName: null,
  onLogin: () => {},
  inAccountsSection: false,
  userClaims: null,
  accountAllLeases: [],
  favorites: [],
  bookmarks: null,
};

export default connect(
  (state) => ({
    userClaims: selectUserClaims(state),
    accountAllLeases: selectAccountAllLeases(state),
    bookmarks: selectBookmarks(state),
    favorites: selectFavoriteHomes(state),
    tourRequests: selectActiveTourRequests(state),
  }),
  {
    logOut,
  }
)(withRouter(AccountHeader));
