import React from 'react';
import classNames from 'classnames/bind';
import Matterport from 'components/Matterport/Matterport';
import { SPACE_TYPES } from 'corecomponents/Space/Space';
import Page from 'layouts/Page/Page';
import styles from './ThreeDTourPreview.module.css';

const cx = classNames.bind(styles);

const MATTERPOR_ID = 'yXQe1p6WVDs';

const ThreeDTourPreview = () => {
  return (
    <Page.OffsetGrid ignoreOffset={[SPACE_TYPES.SM, SPACE_TYPES.MD, SPACE_TYPES.LG, SPACE_TYPES.XL]}>
      <div className={cx('matterport-wrapper')}>
        <Matterport matterportId={MATTERPOR_ID} />
      </div>
    </Page.OffsetGrid>
  );
};

export default ThreeDTourPreview;
