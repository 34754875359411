export const NOT_THERE_YET_STRINGS = {
  preTitle: 'We belong in {cityName}.',
  preTitleMultipleCities: "We'd love <span>your homes.</span>",
  title: "But we're not there yet.",
  title_x: 'But Belong PRO is not there yet.',
  'no_zip_code.preTitle': 'We need to verify a couple of',
  'no_zip_code.title': 'points before we continue.',
  section_title: "We'll notify you when we open in {cityName}.",
  'no_zip_code.section_title': 'Someone at Belong will get back to shortly.',
  section_title_multiple_cities: "We'll notify you when we open in <span>your cities.</span>",
  tab_email: 'Email',
  tab_phone: 'Phone',
  email_label: 'Email address',
  phone_label: 'Phone',
  cta_label: 'Notify me',
  change_address: 'CHANGE ADDRESS',
  primary_cta: 'Learn More',
  phone_cta: 'QUESTIONS?',
  thanks: 'Thanks so much for your interest',
};
