import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './ColoredText.module.css';

const cx = classNames.bind(styles);

export const COLORS = {
  NAVY: 'navy',
  GREEN: 'green',
  RED: 'red',
  GRAY: 'gray',
  DARKGRAY: 'darkgray',
  ORANGE: 'text-orange',
};

class ColoredText extends Component {
  static propTypes = {
    text: PropTypes.node.isRequired,
    color: PropTypes.oneOf(Object.values(COLORS)),
  };

  static defaultProps = {
    color: COLORS.NAVY,
  };

  render() {
    const { text, color } = this.props;
    return <span className={cx(color)}>{text}</span>;
  }
}

export default ColoredText;
