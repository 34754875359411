// React imports
import React, { Component } from 'react';
// Redux imports
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import ApplicationSelector from 'components/ApplicationSelector/ApplicationSelector';
// Component imports
import Button from 'components/Button/Button';
import { BUTTON_SIZES, BUTTON_TYPES } from 'components/Button/buttonTypes';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import String from 'components/String/String';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { updateApplicationIdAppState } from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationsForUser,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
// Constant, Util & String imports
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
// import { converDateTimeServerResponseToDateTimeObject } from 'utils/dateTimeUtils';
// SCSS imports
import styles from './ListOfApplicationsModal.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.pa_landing_page;

class ListOfApplicationsModal extends Component {
  static propTypes = {
    updateApplicationIdAppState: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    existingApplications: PropTypes.arrayOf(PropTypes.object),
    show: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    existingApplications: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedApplicationId: '',
    };
  }

  disableUseExistingButton() {
    const { selectedApplicationId } = this.state;

    if (selectedApplicationId && selectedApplicationId !== '') {
      return false;
    }

    return true;
  }

  handleSelectPreviousApplication = (isChecked, application) => {
    if (isChecked) {
      this.setState({
        selectedApplicationId: application.basicInfo.applicationId,
      });
    } else {
      this.setState({
        selectedApplicationId: '',
      });
    }
  };

  handleClickStartNew = () => {
    const { onClose, updateApplicationIdAppState: updateApplicationIdAppStateAction } = this.props;
    updateApplicationIdAppStateAction('');
    onClose('');
  };

  handleClickUseExisting = async () => {
    const { onClose, updateApplicationIdAppState: updateApplicationIdAppStateAction } = this.props;
    const { selectedApplicationId } = this.state;

    updateApplicationIdAppStateAction(selectedApplicationId);
    onClose(selectedApplicationId);
  };

  isApplicationChecked(application) {
    const { selectedApplicationId } = this.state;

    if (application.basicInfo.applicationId === selectedApplicationId) {
      return true;
    }

    return false;
  }

  render() {
    const { existingApplications, onClose, show } = this.props;

    return (
      <ModalV2 onHide={() => onClose('')} show={show} closeButton noPadding>
        <ModalLayout>
          <ModalLayout.Title>
            <String
              string={
                existingApplications.length === 1
                  ? RAS.previous_applications_section.title_one
                  : RAS.previous_applications_section.title_multiple
              }
            />
          </ModalLayout.Title>
          <ModalLayout.Content>
            <div className={cx('modal-content-wrapper')}>
              <div className={cx('previous-applications-section-wrapper')}>
                {existingApplications.map((application, index) => (
                  <ApplicationSelector
                    key={index}
                    application={application}
                    onSelect={(isChecked) => this.handleSelectPreviousApplication(isChecked, application)}
                    checked={this.isApplicationChecked(application)}
                  />
                ))}
              </div>
            </div>
            <div className={cx('bottom-section')}>
              <div className={cx('use-existing-wrapper')}>
                <Button
                  disabled={this.disableUseExistingButton()}
                  size={BUTTON_SIZES.FLUID}
                  onClick={this.handleClickUseExisting}
                >
                  <div className={cx('use-existing-button-label')}>{RAS.use_existing}</div>
                </Button>
              </div>
              <div className={cx('start-new-wrapper')}>
                <Button
                  buttonType={BUTTON_TYPES.TEXT}
                  className={cx('start-new-button')}
                  onClick={this.handleClickStartNew}
                >
                  {RAS.start_new}
                </Button>
              </div>
            </div>
          </ModalLayout.Content>
        </ModalLayout>
      </ModalV2>
    );
  }
}

const mapStateToProps = (state) => ({
  applyForHouseId: selectResidentApplyForHouseId(state),
  existingApplications: selectResidentApplicationsForUser(state),
});

const mapDispatchToProps = {
  updateApplicationIdAppState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListOfApplicationsModal);
