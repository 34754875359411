import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { BASE_PATHS } from 'routes/paths';
import { fetchAccountProperties } from 'store/redux/homeowner-accounts/actions';

export function useProperties(enabled = true) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [properties, setProperties] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchProperties = useCallback(async () => {
    setLoading(true);
    let propertiesResponse = await dispatch(fetchAccountProperties());

    if (!propertiesResponse?.properties?.length) {
      history.push(BASE_PATHS.ACCOUNTS);
    }

    propertiesResponse = propertiesResponse.properties?.map((property) => new HomeownerAccountProperty(property));

    setProperties(propertiesResponse);
    setLoading(false);

    return propertiesResponse;
  }, [dispatch, history]);

  useEffect(() => {
    if (enabled) {
      fetchProperties();
    }
  }, [fetchProperties, enabled]);

  return { properties, fetchProperties, isLoadingProperties: loading };
}
