import { useEffect, useState } from 'react';

import { StepStatus } from 'models/enums';
import { PROPERTY_HOA_STRINGS } from 'accounts/constants/strings/property-hoa.strings';
import { useSetupFlowStep } from './use-setup-flow-step-context';

export function usePropertyHoa() {
  const { currentStepFormData, currentStep } = useSetupFlowStep();
  const [initialValues, setInitialValues] = useState({
    propertyDetails: {
      hoaContact: {
        address: {},
      },
    },
  });

  const isHoaLocked = currentStep?.configuration?.isHoaDisabled;
  const propertyStreetAddress = currentStep?.property?.address?.streetAddress || '';
  const title =
    currentStep?.status === StepStatus.Created
      ? PROPERTY_HOA_STRINGS.title
      : PROPERTY_HOA_STRINGS['title.in_progress'];

  useEffect(() => {
    if (!currentStepFormData) {
      return;
    }

    const propertyHoaData = currentStepFormData;

    if (propertyHoaData.propertyOwnerDetails?.hoaAgreementFiles?.length) {
      if (!propertyHoaData.propertyOwnerDetails) {
        propertyHoaData.propertyOwnerDetails = {};
      }

      propertyHoaData.propertyOwnerDetails.hoaDocuments = {
        availableMedia: [...propertyHoaData.propertyOwnerDetails.hoaAgreementFiles],
        stagingMedia: [],
        deletedMedia: [],
        mediaToDisplay: [...propertyHoaData.propertyOwnerDetails.hoaAgreementFiles],
        later: false,
      };
      propertyHoaData.propertyOwnerDetails.hoaAgreementFiles = null;
    } else if (
      !propertyHoaData.propertyOwnerDetails?.hoaAgreementFiles?.length &&
      currentStep?.status === StepStatus.Incomplete
    ) {
      if (!propertyHoaData.propertyOwnerDetails) {
        propertyHoaData.propertyOwnerDetails = {};
      }

      propertyHoaData.propertyOwnerDetails.hoaDocuments = {
        availableMedia: [],
        stagingMedia: [],
        deletedMedia: [],
        mediaToDisplay: [],
        later: true,
      };
    }

    setInitialValues(propertyHoaData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepFormData]);

  return { title, isHoaLocked, propertyStreetAddress, initialValues };
}
