export function getFormattedEmployeeTitle(title: string) {
  if (title === 'Homeowner Success Lead') {
    return (
      <span>
        Homeowner
        <br />
        Success Lead
      </span>
    );
  }

  const splittedTitle = title?.split(' ');

  if (splittedTitle.length === 2) {
    return (
      <span>
        {splittedTitle[0]} {splittedTitle[1]}
      </span>
    );
  }

  if (splittedTitle.length === 3) {
    return (
      <span>
        {splittedTitle[0]} {splittedTitle[1]}
        <br />
        {splittedTitle[2]}
      </span>
    );
  }

  if (splittedTitle.length === 4) {
    return (
      <span>
        {splittedTitle[0]} {splittedTitle[1]}
        <br />
        {splittedTitle[2]} {splittedTitle[3]}
      </span>
    );
  }

  return title;
}
