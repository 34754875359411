import { HOME_FEATURES_STRINGS } from 'accounts/constants/strings/home-features.strings';
import { ParkingFormFields } from 'accounts/components/form-fields/parking';
import { SprinklerFormFields } from 'accounts/components/form-fields/sprinkler';
import { SecurityFormFields } from 'accounts/components/form-fields/security';
import LaundryFormFields from 'fields/Features/Home/LaundryFormFields';
import AcFormFields from 'fields/Features/Home/AcFormFields';
import { HeatFormFields } from 'accounts/components/form-fields/heat';
import { StorageFormFields } from 'accounts/components/form-fields/storage';

export const SUPPORTED_HOME_FEATURE_NAMES = {
  Parking: {
    name: 'Parking',
    title: 'Parking',
  },
  Storage: {
    name: 'Storage',
    title: 'Detached Storage',
  },
  Heat: {
    name: 'Heat',
    title: 'Heating',
  },
  Ac: {
    name: 'A/C',
    title: 'AC',
  },
  Laundry: {
    name: 'Laundry',
    title: 'Laundry',
  },
  Pool: {
    name: 'Pool',
    title: 'Private Pool',
  },
  Jacuzzi: {
    name: 'Hot Tub/ Jacuzzi',
    title: 'Private Hot Tub',
  },
  SecuritySystem: {
    name: 'Security System',
    title: 'Security System',
  },
  "Trees/ Landscaping/ Garden": {
    name: 'Trees/ Landscaping/ Garden',
    title: 'Garden, Trees or Landscaping',
  },
  AutomaticSprinklers: {
    name: 'Automatic Sprinklers',
    title: 'Sprinkler System',
  },
};

export const HOME_FEATURES_LIST = [
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.Parking.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.Parking.title,
    allowMultiple: true,
    addMoreTitle: HOME_FEATURES_STRINGS.features.parking.addMoreTitle,
    formFields: <ParkingFormFields />,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.Storage.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.Storage.title,
    formFields: <StorageFormFields />,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.Heat.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.Heat.title,
    formFields: <HeatFormFields />,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.Ac.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.Ac.title,
    formFields: <AcFormFields />,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.Laundry.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.Laundry.title,
    formFields: <LaundryFormFields />,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.Pool.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.Pool.title,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.Jacuzzi.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.Jacuzzi.title,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.SecuritySystem.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.SecuritySystem.title,
    formFields: <SecurityFormFields />,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES['Trees/ Landscaping/ Garden'].name,
    title: SUPPORTED_HOME_FEATURE_NAMES['Trees/ Landscaping/ Garden'].title,
  },
  {
    name: SUPPORTED_HOME_FEATURE_NAMES.AutomaticSprinklers.name,
    title: SUPPORTED_HOME_FEATURE_NAMES.AutomaticSprinklers.title,
    formFields: <SprinklerFormFields />,
  },
]

export const HOME_FEATURE_CATEGORIES = {
  'Climate Control': 'Climate Control',
  Laundry: 'Laundry',
  Parking: 'Parking',
  Pool: 'Pool',
  Yard: 'Yard',
  Storage: 'Storage',
  'Safety & Security': 'Safety & Security'
};
