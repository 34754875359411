import { __awaiter, __generator } from '../node_modules/tslib/tslib.es6.mjs.js';
import { useState, useRef, useCallback, useEffect } from 'react';

function usePagination(_a) {
  var _this = this;
  var _b = _a.pageIndex,
    initialPageIndex = _b === void 0 ? 1 : _b,
    _c = _a.pageSize,
    initialPageSize = _c === void 0 ? 20 : _c,
    _d = _a.initialItems,
    initialItems = _d === void 0 ? [] : _d,
    restParams = _a.restParams,
    fetch = _a.fetch;
  var _e = useState(initialItems),
    items = _e[0],
    setItems = _e[1];
  var pageIndex = useRef(initialPageIndex);
  var pageSize = useRef(initialPageSize);
  var _f = useState(false),
    isLoading = _f[0],
    setIsLoading = _f[1];
  var _g = useState(false),
    hasMorePages = _g[0],
    setHasMorePage = _g[1];
  var fetchData = useCallback(function () {
    return __awaiter(_this, void 0, void 0, function () {
      var queryParams, _i, _a, _b, key, value, data;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            setIsLoading(true);
            queryParams = new URLSearchParams();
            queryParams.set('pageIndex', String(pageIndex.current));
            queryParams.set('pageSize', String(pageSize.current));
            for (_i = 0, _a = Object.entries(restParams || {}); _i < _a.length; _i++) {
              _b = _a[_i], key = _b[0], value = _b[1];
              queryParams.set(key, String(value));
            }
            return [4 /*yield*/, fetch(queryParams.toString())];
          case 1:
            data = _c.sent();
            setItems(function (prevState) {
              return data.items ? prevState === null || prevState === void 0 ? void 0 : prevState.concat(data.items) : prevState;
            });
            setHasMorePage(Number(data.pageIndex) < Number(data === null || data === void 0 ? void 0 : data.totalPages));
            pageIndex.current = pageIndex.current + 1;
            setIsLoading(false);
            return [2 /*return*/];
        }
      });
    });
  }, [fetch, restParams]);
  function loadMore() {
    fetchData();
  }
  function reset() {
    setIsLoading(true);
    setItems([]);
    pageIndex.current = initialPageIndex;
    pageSize.current = initialPageSize;
    fetchData();
  }
  useEffect(function () {
    reset();
  }, [restParams]);
  return {
    isLoading: isLoading,
    items: items,
    hasMorePages: hasMorePages,
    loadMore: loadMore,
    reset: reset
  };
}

export { usePagination };
