import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { COLORS, IconValues } from '../GeneralIcon/GeneralIcon';
import styles from './Label.module.css';
import { LABEL_COLOR_TYPES, LabelColorType } from './labelTypes';

export { LABEL_COLOR_TYPES };

const cx = classNames.bind(styles);

interface LabelProps {
  text?: string;
  color?: LabelColorType;
  children?: React.ReactNode;
  className?: string;
  icon?: IconValues;
}

const Label: React.FC<LabelProps> = ({
  text = '',
  color = LABEL_COLOR_TYPES.NAVY,
  children = '',
  className = '',
  icon,
}) => {
  if (icon) {
    return (
      <span className={cx('label', `${color}`, className, { icon })}>
        <GeneralIcon icon={icon} color={COLORS.WHITE} scale={0.5} />
        <span className={cx('text')}>{children || text}</span>
      </span>
    );
  }

  return <span className={cx('label', `${color}`, className)}>{children || text}</span>;
};

export default Label;
