import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { BUTTON_TYPES as constantTypes } from 'components/Button/buttonTypes';
import String from 'components/String/String';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import PropTypes from 'prop-types';
import styles from './Button.module.css';
import { BUTTON_SIZES as moreConstants } from './buttonTypes';

const cx = classNames.bind(styles);

export const BUTTON_TYPES = constantTypes;
export const BUTTON_SIZES = moreConstants;

/* Alt is currently icon until we have a sprite guide */
class Button extends Component {
  static propTypes = {
    // Button's label text that the user sees
    label: PropTypes.string,

    // Makes the button tabbable in a certain index. Defaults at 0. Change if in it's own component
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    // Turns off the button.
    disabled: PropTypes.bool,

    // Chooses the button type
    buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES)),

    // Chooses the Button Size
    size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),

    // Maybe make an object instead of a string.
    icon: PropTypes.string,

    smallOnMobile: PropTypes.bool,
    // Bool string that states if the selector is selected.
    selected: PropTypes.bool,

    // content of the component
    children: PropTypes.node,

    // If it is a link
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

    className: PropTypes.string,
    sublabel: PropTypes.string,
    href: PropTypes.string,
    noTransform: PropTypes.bool,
    innerRef: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    disabled: false,
    buttonType: BUTTON_TYPES.DEFAULT,
    size: BUTTON_SIZES.DEFAULT,
    smallOnMobile: false,
    tabIndex: 0,
    selected: false,
    icon: '',
    children: '',
    to: null,
    className: '',
    sublabel: null,
    href: '',
    noTransform: false,
  };

  // If text, render the text. Else render the children.
  render() {
    const {
      label,
      sublabel,
      buttonType,
      size,
      icon,
      selected,
      children,
      to,
      disabled,
      className,
      smallOnMobile,
      href,
      noTransform,
      innerRef,
      ...other
    } = this.props;

    const ButtonComponent = to || href ? LinkBase : ButtonBase;
    const linkProps = {};

    if (to) {
      linkProps.to = to;
    }

    if (href) {
      linkProps.href = href;
    }

    return (
      <ButtonComponent
        ref={innerRef}
        disabled={disabled}
        label={label}
        className={
          buttonType !== BUTTON_TYPES.NOSTYLE
            ? cx(
                'button',
                `${buttonType}`,
                { noTransform: buttonType === BUTTON_TYPES.TEXT && noTransform },
                { smallOnMobile },
                { selected, [size]: size !== BUTTON_SIZES.DEFAULT },
                { disabled },
                className
              )
            : cx(className)
        }
        icon={icon}
        {...linkProps}
        {...other}
      >
        {icon && (
          <div>
            <img src={icon} className={cx('icon')} alt={icon} />
          </div>
        )}
        {children || <String string={label} />}
        {buttonType === BUTTON_TYPES.MULTI_TEXT && <div className={cx('sublabel', { disabled })}>{sublabel}</div>}
      </ButtonComponent>
    );
  }
}

export default React.forwardRef((props, ref) => <Button innerRef={ref} {...props} />);
