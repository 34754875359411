import { Dispatch, SetStateAction } from 'react';
import { DATE_FORMATS, formatDateTime } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import Divider from 'components/Divider/Divider';
import Money from 'components/Money/Money';
import MultipleBelongEmployeeProfileView from 'components/MultipleBelongEmployeeProfileView/MultipleBelongEmployeeProfileView';
import TextBubble from 'components/TextBubble/TextBubble';
import Address from 'models/common/Address';
import { EmployeeAssignmentTypes } from 'models/enums';
import { formatString } from 'strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { STRINGS } from '../../InitialEstimates.strings';
import styles from './InitialPricingStep.module.css';

const cx = classNames.bind(styles);

type InitialPricingStepProps = {
  address: string;
  unit: any;
  initialEstimate: any;
  setShowComparables: Dispatch<SetStateAction<boolean>>;
};

export function InitialPricingStep({
  address,
  unit,
  initialEstimate,
  setShowComparables,
}: InitialPricingStepProps): JSX.Element {
  const employeeAssignmentToEmployee = (employeeAssignment: any) => {
    return {
      ...employeeAssignment?.employee,
      ...employeeAssignment?.user,
      profileImage: employeeAssignment?.user.profileImageUrl,
      bannerImage: employeeAssignment?.employee.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[employeeAssignment?.employeeAssignment.assignmentType],
    };
  };
  const employees = [];

  let assignedEmployee = initialEstimate?.employeeAssignments?.find(
    (employee) => employee.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );
  if (!assignedEmployee) {
    assignedEmployee = initialEstimate?.employeeAssignments?.find(
      (employee) =>
        employee.employeeAssignment.assignmentType === EmployeeAssignmentTypes.SalesDevelopmentRepresentative
    );
  }

  employees.push(employeeAssignmentToEmployee(assignedEmployee));

  const addressValue = unit?.basicInfo?.address;

  const formatAddress = (addressData: Address) => {
    return `${addressData.streetAddress}, ${addressData.city}, ${addressData.state} ${addressData.zipcode}`;
  };

  const adjetiveString =
    initialEstimate?.priceDifference?.priceDifferenceValue > 0.05
      ? STRINGS['initial-pricing.difference-higher']
      : STRINGS['initial-pricing.difference-lower'];

  return (
    <div className="flex flex-col items-center mb-3xl">
      <div className="flex flex-col items-center px-sm md:px-0">
        <Text variant="h2" className="text-center mb-xs mt-2xl md:mt-0" fontWeight="semibold">
          {STRINGS['initial-pricing.title']}
        </Text>
        <Text className="max-w-[225px] md:max-w-none mb-md text-center" fontWeight="semibold">
          {formatString(address) || formatAddress(addressValue)}
        </Text>
        <Text className="mb-2xl md:max-w-[534px] text-center">
          {formatString(STRINGS['initial-pricing.based'], { cityname: addressValue?.city || '' })}
        </Text>
        <Text className="mb-xs text-center" fontWeight="semibold">
          {STRINGS['initial-pricing.estimated']}
        </Text>
        <Text variant="h2" className="text-center mb-xs text-green" fontWeight="semibold">
          <Money cash={initialEstimate?.pricingEstimate?.minimumRentPrice} />
          {' - '}
          <Money cash={initialEstimate?.pricingEstimate?.maximumRentPrice} />
        </Text>
        <Text variant="p1" className="mb-md text-center text-dark-gray" fontWeight="semibold">
          Updated{' '}
          {formatDateTime({
            dateTime: initialEstimate?.pricingEstimate?.publishedOn,
            format: DATE_FORMATS.FULL_MONTH_DAY_YEAR,
          })}
        </Text>
        <div className="w-full md:w-[412px] mb-xl">
          <Divider.Dashed />
        </div>
        {(initialEstimate?.priceDifference?.priceDifferenceValue > 0.05 ||
          initialEstimate?.priceDifference?.priceDifferenceValue < -0.05) && (
          <div className={cx('mb-xs', styles.bubbleContainer)}>
            <TextBubble fontWeight="semibold" pointingDown text={adjetiveString} />
          </div>
        )}
        <div className="flex justify-center mb-xl w-full">
          {employees?.length > 0 && <MultipleBelongEmployeeProfileView employees={employees} />}
        </div>
        <div className={cx('rounded border border-green border-solid p-md mb-3xl md:mb-xl', styles.importantBox)}>
          <Text className="mb-md" fontWeight="semibold">
            {STRINGS['initial-pricing.important-title']}
          </Text>
          <ol className="pl-md">
            <li className="mb-xs">
              <Text>{STRINGS['initial-pricing.important-one']}</Text>
            </li>
            <li>
              <Text>{STRINGS['initial-pricing.important-two']}</Text>
            </li>
          </ol>
        </div>
      </div>
      <div className="fixed bottom-0 md:relative w-full flex justify-center">
        <Button className={cx('next-button')} onClick={setShowComparables}>
          {STRINGS['initial-pricing.cta']}
        </Button>
      </div>
    </div>
  );
}
