import { forwardRef, createElement, useContext } from 'react';
import { AnalyticsContext } from './AnalyticsContext';
import { AnalyticsProviderContext } from './AnalyticsProviderContext';
import { ANALYTICS_EVENT_NAMES } from './analytics_event_names';

const ClickTracker = (wrappedComponent) => {
  const ClickTrackerWrapper = forwardRef((props, ref) => {
    const analytics = useContext(AnalyticsProviderContext);
    const analyticsData = useContext(AnalyticsContext);

    const handleClick = (...args) => {
      // eslint-disable-next-line react/prop-types
      const { onClick } = props;
      analytics.track(ANALYTICS_EVENT_NAMES.CLICK, analyticsData);
      return onClick?.(...args);
    };

    return createElement(wrappedComponent, { ...props, ref, onClick: handleClick });
  });

  return ClickTrackerWrapper;
};

export default ClickTracker;
