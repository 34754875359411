import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import HeaderContainer from 'components/HeaderContainer/HeaderContainer';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import String from 'components/String/String';
import { BREAKPOINTS } from 'consts/breakpoints';
import ModalBase from 'corecomponents/ModalBase/ModalBase';
import { isString } from 'lodash-es';
import PropTypes from 'prop-types';
import { THEME_CONSTS } from 'themes/themes';
import Spinner from '../../Spinner/Spinner';
import styles from './ModalV2.module.css';

const cx = classNames.bind(styles);

export const MODAL_TYPES = {
  SMALL: 'small-modal',
  STANDARD: 'standard-modal',
  LARGE: 'large-modal',
  FULL_PAGE: 'full-page',
  MAINTENANCE_MODAL: 'maintenance',
  TIPS_MODAL: 'tips',
  // Right now, only makes sense in PdfModal when used together with backdropThemeDark
  BLACK_BACKGROUND: 'black-background',
};

class ModalV2 extends Component {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(Object.values(MODAL_TYPES)),
    smallTitle: PropTypes.node, // Render title inline with the close button
    title: PropTypes.node,
    subTitle: PropTypes.node,
    error: PropTypes.node,
    closeButton: PropTypes.bool,
    showSpinner: PropTypes.bool,
    closable: PropTypes.bool,
    noPadding: PropTypes.bool,
    spinnerText: PropTypes.string,
    noDialogSidePadding: PropTypes.bool,
    onHide: PropTypes.func,
    makeTransparent: PropTypes.bool,
    noBorder: PropTypes.bool,
    overflow: PropTypes.bool,
    className: PropTypes.string,
    galleryModalCustomStyling: PropTypes.bool,
    ignoreModalExitAndEnterFunctions: PropTypes.bool,
    mobileTopImportant: PropTypes.bool,
    setTopBreakpoint: PropTypes.any,
    editButton: PropTypes.node,
    onEdit: PropTypes.func,
    noFullPageHeader: PropTypes.bool,
    overflowHidden: PropTypes.bool,
    shadow: PropTypes.bool,
    backdropTheme: PropTypes.oneOf(Object.values(THEME_CONSTS)),
    contentClassName: PropTypes.string,
    defaultHeader: PropTypes.bool,
    closeButtonClassName: PropTypes.string,
    backButton: PropTypes.bool,
    onBackButtonClick: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    type: MODAL_TYPES.STANDARD,
    title: null,
    subTitle: null,
    error: null,
    closeButton: true,
    noPadding: false,
    shadow: false,
    closable: true,
    showSpinner: false,
    makeTransparent: false,
    noBorder: false,
    overflow: false,
    className: '',
    noDialogSidePadding: false,
    ignoreModalExitAndEnterFunctions: false,
    galleryModalCustomStyling: false,
    smallTitle: '',
    spinnerText: '',
    noFullPageHeader: false,
    overflowHidden: false,
    setTopBreakpoint: null,
    editButton: null,
    mobileTopImportant: true,
    onEdit: () => {},
    backdropTheme: THEME_CONSTS.DEFAULT,
    contentClassName: '',
    closeButtonClassName: '',
    backButton: false,
    onBackButtonClick: () => {},
  };

  getBreakpoint = () => {
    const { type } = this.props;

    if (type === MODAL_TYPES.TIPS_MODAL) {
      return BREAKPOINTS.XL;
    }

    return null;
  };

  handleModalHide = () => {
    const { closable, onHide, showSpinner } = this.props;
    document.body.style.position = 'static';

    if (closable && !showSpinner) {
      onHide();
    }
  };

  renderBackButton() {
    const { backButton, backdropTheme, onBackButtonClick, showSpinner } = this.props;

    if (!backButton || showSpinner) {
      return null;
    }

    return (
      <div className={cx('modal-back-button')}>
        <IconButton
          action="back"
          paddingCustomClassName={cx('closeButtonPadding')}
          onClick={onBackButtonClick}
          generalIconProps={
            backdropTheme === THEME_CONSTS.DARK ? { color: COLORS.WHITE, hoverColor: COLORS.GREEN } : {}
          }
          icon={GENERAL_ICONS.BACK_ARROW}
          align={ALIGN_TYPES.NONE}
        />
      </div>
    );
  }

  renderCloseButton() {
    const { shadow, closeButton, onHide, showSpinner, type, noFullPageHeader, backdropTheme, closeButtonClassName } =
      this.props;

    if (!closeButton || showSpinner) {
      return null;
    }

    return (
      <div
        className={cx(
          'modal-close-button',
          closeButtonClassName,
          { maintenance: type === MODAL_TYPES.MAINTENANCE_MODAL },
          { 'full-page': type === MODAL_TYPES.FULL_PAGE },
          { noFullPageHeader },
          { tips: type === MODAL_TYPES.TIPS_MODAL },
          { removeBackground: shadow }
        )}
      >
        <IconButton
          action="close"
          paddingCustomClassName={cx('closeButtonPadding')}
          onClick={onHide}
          generalIconProps={
            backdropTheme === THEME_CONSTS.DARK ? { color: COLORS.WHITE, hoverColor: COLORS.GREEN } : {}
          }
          icon={GENERAL_ICONS.CLOSE}
          align={ALIGN_TYPES.NONE}
        />
      </div>
    );
  }

  renderSmallTitle() {
    const { smallTitle } = this.props;

    if (!smallTitle) {
      return null;
    }

    return <div className={cx('small-title')}>{smallTitle}</div>;
  }

  renderEditButton() {
    const { editButton, onEdit, showSpinner, type } = this.props;

    if (!editButton || showSpinner) {
      return null;
    }

    return (
      <div
        className={cx(
          'modal-edit-button',
          { maintenance: type === MODAL_TYPES.MAINTENANCE_MODAL },
          { 'full-page': type === MODAL_TYPES.FULL_PAGE }
        )}
      >
        <Button onClick={onEdit} buttonType={BUTTON_TYPES.TEXT} label="EDIT" />
      </div>
    );
  }

  renderTitle() {
    const { title } = this.props;

    if (typeof title === 'string') {
      return (
        <div className={cx('modal-title')}>
          <String string={this.props.title} />
        </div>
      );
    } else if (title) {
      return <div className={cx('modal-title')}>{title}</div>;
    }

    return null;
  }

  renderSubTitle() {
    const { subTitle } = this.props;

    if (subTitle) {
      return (
        <div className={cx('modal-sub-title')}>
          {isString(this.props.subTitle) ? <String string={this.props.subTitle} /> : this.props.subTitle}
        </div>
      );
    }

    return null;
  }

  renderError() {
    const { error } = this.props;

    if (error) {
      return <div className={cx('modal-error')}>{isString(error) ? <String string={error} /> : error}</div>;
    }

    return null;
  }

  renderFullpageHeader() {
    const { type, noFullPageHeader, defaultHeader } = this.props;

    if (type === MODAL_TYPES.FULL_PAGE) {
      if (noFullPageHeader) {
        return this.renderCloseButton();
      }

      let headerMainProps = {
        hasborder: true,
        disableLogo: true,
        navigationComponents: [this.renderCloseButton()],
        customMobileMenu: this.renderCloseButton(),
      };

      if (defaultHeader) {
        headerMainProps = {
          hasborder: true,
          disableLogo: true,
        };
      }

      return <HeaderContainer headerMainProps={headerMainProps} />;
    }
  }

  renderHeader() {
    const { showSpinner, shadow } = this.props;

    if (showSpinner) {
      return null;
    }

    return (
      <div className={cx({ 'fixed-header': shadow })}>
        {this.renderSmallTitle()}
        {this.renderEditButton()}
        {this.renderCloseButton()}
        {this.renderBackButton()}
      </div>
    );
  }

  render() {
    const {
      className,
      children,
      type,
      noPadding,
      showSpinner,
      noBorder,
      overflow,
      makeTransparent,
      mobileTopImportant,
      setTopBreakpoint,
      galleryModalCustomStyling,
      spinnerText,
      noDialogSidePadding,
      noFullPageHeader,
      overflowHidden,
      shadow,
      backdropTheme,
      contentClassName,
      // Destructure to not spread to modal base
      editButton,
      onEdit,
      ...rest
    } = this.props;

    // Removing the title prop from rest to avoid showing the title on hover
    if (rest.title) {
      delete rest.title;
    }

    return (
      <ModalBase
        Shadow={shadow ? <div className={cx('white-shadow')} /> : null}
        dialogClassName={cx('modal-dialog', { noDialogSidePadding }, className, type, {
          noFullPageHeader,
        })}
        fullPage={type === MODAL_TYPES.FULL_PAGE}
        {...rest}
        onHide={this.handleModalHide}
        mobileTopImportant={mobileTopImportant}
        setTopBreakpoint={this.getBreakpoint()}
        backdropClassName={cx(backdropTheme)}
      >
        {this.renderFullpageHeader()}
        <div
          className={cx(
            { galleryModalCustomStyling },
            'content',
            { noPadding },
            { makeTransparent, noBorder, showSpinner, overflow },
            { overflowHidden },
            contentClassName
          )}
        >
          {showSpinner && <Spinner string={spinnerText} />}
          {type !== MODAL_TYPES.FULL_PAGE && this.renderHeader()}
          <div className={cx('inner-content', type)}>
            {this.renderTitle()}
            {this.renderSubTitle()}
            {this.renderError()}
            {children}
          </div>
        </div>
      </ModalBase>
    );
  }
}

export default ModalV2;
