import { useState } from 'react';
import type { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { isChecked, template, formatPhoneNumber, EMAIL, DEFAULT_PHONE } from '@belong/common';
import { IconClose, IconDownload, IconSpinner } from '@belong/icons';
import { Text, Button, ButtonBase, Form, CheckboxField, Image, Modal, Toast } from '@belong/ui';
import type { SignModel, EmployeeAssignmentDetailModel } from 'api/models';
import { clsx } from 'clsx';
import SidebarWithTextBubble from 'components/SidebarWithTextBubble/SidebarWithTextBubble';
import type { FormikBag } from 'formik';
import { AgreementSummary } from 'pages/AgreementFlow/AgreementFlowSteps/join/components/agreement-summary/agreement-summary';
import { fetchAgreementPdfUrl } from 'store/redux/agreement-flow/actions';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import AgreementRenderer from '../../AgreementRenderer/AgreementRenderer';
import css from './join.module.css';

type Props = {
  onSubmit: () => Promise<void>;
  currentStepFormData: SignModel;
  setLoading: (loading: boolean) => void;
  setSidebarTip: (tip: unknown) => void;
  openTipModal: () => void;
  employeeAssignment?: EmployeeAssignmentDetailModel;
};

export function Join({
  currentStepFormData,
  onSubmit,
  setLoading,
  openTipModal,
  setSidebarTip,
  employeeAssignment,
}: Props) {
  const [isAgreementModalVisible, setIsAgreementModalVisible] = useState(false);
  const [agreementTip, setAgreementTip] = useState<Record<'descriptions', string[]>>();
  const [isAgreementTipOverlayVisible, setIsAgreementTipOverlayVisible] = useState(false);
  const [isDownloadingAgreementPDF, setIsDownloadingAgreementPDF] = useState(false);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const dispatch = useDispatch();

  async function handleDownloadClick(event: MouseEvent<HTMLButtonElement>) {
    try {
      setIsErrorToastVisible(false);

      const isMobileDownload = event.currentTarget.dataset.mobile === 'true';

      if (isMobileDownload) {
        /**
         * Since there is a layer conflict with the spinner shown via setLoading, we need to show create another
         * spinner that can be controlled independently from the other.
         * This can be for sure avoided but it would require making adjustements to core components like
         * FlowLayoutV2 and it is not worth the effort on this legacy stack.
         */
        setIsDownloadingAgreementPDF(true);
      } else {
        setLoading(true);
      }

      const response = await dispatch(fetchAgreementPdfUrl(currentStepFormData?.documentInstance?.uniqueId));

      if (response.url) {
        window.open(response.url, '_blank');
      }
    } catch {
      setIsErrorToastVisible(true);
    } finally {
      setLoading(false);
      setIsDownloadingAgreementPDF(false);
    }
  }

  function getInitialValues(): Record<'signed', boolean> {
    return {
      signed: currentStepFormData?.documentSignature?.status === 'Signed',
    };
  }

  async function handleSubmit(
    _: Record<'signed', boolean>,
    { setSubmitting }: FormikBag<void, Record<'signed', boolean>>
  ) {
    try {
      setIsErrorToastVisible(false);

      await onSubmit();
    } catch {
      setIsErrorToastVisible(true);
    } finally {
      setSubmitting(false);
    }
  }

  function handleViewFullDocumentClick() {
    setIsAgreementModalVisible(true);
  }

  function handleAgreementModalDismiss() {
    setIsAgreementModalVisible(false);
  }

  function handleAgreementTipModalOpen() {
    setIsAgreementTipOverlayVisible(true);
  }

  function handleAgreementTipModalDismiss() {
    setIsAgreementTipOverlayVisible(false);
  }

  function handleErrorToastClose() {
    setIsErrorToastVisible(false);
  }

  function handleAddendumDownload(event: MouseEvent<HTMLButtonElement>) {
    const addendumURL = event.currentTarget.dataset.addendum;

    window.open(addendumURL, '_blank');
  }

  return (
    <>
      <div className="flex flex-col mb-3xl">
        <Text as="h1" variant="h2" fontWeight="semibold">
          {AGREEMENT_FLOW_STRINGS['sign.title']}
        </Text>
        <div className="my-xl sm:mb-2xl">
          <AgreementSummary />
        </div>
        <div className="hidden sm:flex sm:justify-end sm:items-center sm:mb-sm">
          <Button onClick={handleDownloadClick} variant="text">
            {AGREEMENT_FLOW_STRINGS['sign.download']}
          </Button>
        </div>
        <div
          className={clsx(
            'h-4xl rounded border-2 border-solid border-gray sm:h-5xl overflow-scroll sm:p-lg p-2sm relative',
            currentStepFormData?.addendums?.length > 0 ? 'mb-sm' : 'mb-0 sm:mb-xl'
          )}
        >
          <ButtonBase
            className="xl:hidden absolute inset-0 h-full w-full bg-white/80 flex flex-col items-center justify-center gap-2xs"
            onClick={handleViewFullDocumentClick}
          >
            <Image
              alt={AGREEMENT_FLOW_STRINGS['sign.download']}
              className="w-lg h-lg mb-xs"
              src="/agreement-flow/open_document.svg"
            />
            <Text fontWeight="semibold">{AGREEMENT_FLOW_STRINGS['sign.download.fullDocument']}</Text>
          </ButtonBase>
          {!isAgreementModalVisible && (
            <AgreementRenderer
              agreement={currentStepFormData?.documentInstance?.html}
              setTip={setSidebarTip}
              openTipModal={openTipModal}
            />
          )}
        </div>
        {currentStepFormData?.addendums?.length > 0 && (
          <div className="flex items-end gap-xs flex-col mb-xl">
            {currentStepFormData?.addendums?.map((addendum) => {
              return (
                <Button onClick={handleAddendumDownload} variant="text" key={addendum.id} data-addendum={addendum.url}>
                  <Text className="text-right" variant="p2" fontWeight="semibold">
                    {addendum.documentType}
                  </Text>
                </Button>
              );
            })}
          </div>
        )}
        <Form<Record<'signed', boolean>> initialValues={getInitialValues()} onSubmit={handleSubmit}>
          {({ values: { signed }, errors }) => (
            <>
              <div className="sm:hidden fixed bottom-0 left-0 bg-navy flex flex-col px-2sm py-lg gap-lg w-full">
                <CheckboxField
                  fontWeight={signed ? 'semibold' : 'regular'}
                  labelTextClassName={clsx(signed ? 'text-green' : 'text-white')}
                  unselectedClassName={clsx(!errors.signed && 'text-white')}
                  size="small"
                  name="signed"
                  label={AGREEMENT_FLOW_STRINGS['sign.checkbox']}
                  variant="tertiary"
                  inputContainerClass="self-start"
                  validate={isChecked}
                />
                <Form.SubmitButton size="fluid">{AGREEMENT_FLOW_STRINGS['sign.CTA']}</Form.SubmitButton>
              </div>
              <div className="hidden sm:flex flex-col items-center justify-center gap-2xl">
                <CheckboxField
                  fontWeight={signed ? 'semibold' : 'regular'}
                  labelClassName={css.signCheckbox}
                  labelTextClassName="text-navy"
                  unselectedClassName="text-navy"
                  size="small"
                  name="signed"
                  label={AGREEMENT_FLOW_STRINGS['sign.checkbox']}
                  inputContainerClass="self-start"
                  validate={isChecked}
                />
                <div className={css.signButton}>
                  <Form.SubmitButton className="h-full" size="fluid">
                    <Text fontWeight="semibold" className={css.signButtonText}>
                      {AGREEMENT_FLOW_STRINGS['sign.CTA']}
                    </Text>
                  </Form.SubmitButton>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
      <div className="contents xl:hidden">
        <Modal
          isOpen={isAgreementModalVisible}
          name="agreement-modal"
          onDismiss={handleAgreementModalDismiss}
          size="fullScreen"
          closeButton={({ onDismiss }) => {
            return (
              <div className="p-xs w-full bg-white flex top-0 fixed z-20 justify-end gap-xs">
                <ButtonBase
                  className="h-xl w-xl flex items-center justify-center"
                  onClick={handleDownloadClick}
                  data-mobile="true"
                >
                  <IconDownload width={20} />
                </ButtonBase>
                <ButtonBase className="h-xl w-xl flex items-center justify-center" onClick={onDismiss}>
                  <IconClose width={16} />
                </ButtonBase>
              </div>
            );
          }}
        >
          <div className="my-lg md:my-0 px-2sm relative">
            <AgreementRenderer
              agreement={currentStepFormData?.documentInstance?.html}
              setTip={setAgreementTip}
              openTipModal={handleAgreementTipModalOpen}
              className="h-full"
              isIntersectionDisabled
            />
          </div>
        </Modal>
        {isDownloadingAgreementPDF && (
          <div className="z-toast fixed left-0 top-0 bg-navy h-screen w-screen flex items-center justify-center bg-white-translucent">
            <IconSpinner width={40} />
          </div>
        )}
        <Modal
          name="agreement-tip-modal"
          isOpen={isAgreementTipOverlayVisible}
          size="fullScreen"
          onDismiss={handleAgreementTipModalDismiss}
          contentClassName="md:h-min"
          closeButton={({ onDismiss }) => {
            return (
              <div className={clsx('p-xs top-xs right-xs absolute z-20 md:top-sm md:right-lg')}>
                <Button onClick={onDismiss} variant="text">
                  {AGREEMENT_FLOW_STRINGS['sign.continueReading']}
                </Button>
              </div>
            );
          }}
        >
          <div className="mt-2xl sm:mt-0 px-2sm">
            <SidebarWithTextBubble
              title=""
              description={agreementTip?.descriptions}
              employeeAssignment={employeeAssignment}
              makeGreen={false}
              showContact
              animateTextChange={false}
            />
          </div>
        </Modal>
      </div>
      <Toast isVisible={isErrorToastVisible} variant="danger" onClose={handleErrorToastClose}>
        {template(AGREEMENT_FLOW_STRINGS['sign.error'], {
          email: employeeAssignment?.employee?.workEmail ?? EMAIL,
          phoneNumber: formatPhoneNumber(employeeAssignment?.employee?.workPhone ?? DEFAULT_PHONE),
        })}
      </Toast>
    </>
  );
}
