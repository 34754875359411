import AccountPaymentInfo from './paymentinfo/AccountPaymentInfo';
import AccountPaymentItemDescription from './paymentinfo/AccountPaymentItemDescription';
import Address from '../common/Address';

export default class AccountPayment {
  constructor(data = {}) {
    this.items = data.items?.map((item) => new AccountPaymentItemDescription(item));
    this.paymentInfo = new AccountPaymentInfo(data.paymentInfo);
    this.unitInfo = new Address(data.unitInfo);
  }
}
