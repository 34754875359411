import { useState, ReactNode, ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Measure from 'react-measure';
import { Route } from 'react-router-dom';
import WindowSizeListener from 'react-window-size-listener';
import { COOKIES_CONFIG } from '@belong/common';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import classNames from 'classnames/bind';
import { MobileTipButton } from 'components/MobileTipButton/MobileTipButton';
import Spinner from 'components/Spinner/Spinner';
import { TipBox } from 'components/TipBox/TipBox';
import { Grid } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import { MobileTipModal } from 'layouts/FlowLayout/MobileTipModal/MobileTipModal';
import Page from 'layouts/Page/Page';
import { parseCookies } from 'nookies';
import { Fallback } from '../fallback';
import styles from './setup-flow-steps-layout.module.css';

const cx = classNames.bind(styles);

const sidebarWidth = 350;

type SetupStepsFlowLayoutProps = {
  children?: ReactNode;
  navigationComponents?: ReactElement[];
  loading?: boolean;
};

export function SetupFlowStepsLayout({ loading, navigationComponents, children }: SetupStepsFlowLayoutProps) {
  const [measure, setMeasure] = useState({ containerWidth: 0, windowWidth: 0 });
  const [isTipModalOpen, openTipModal, closeTipModal] = useModal();
  const {
    stepsConfig: steps,
    getSidebarTitleText,
    getSidebarDescriptions,
    getSidebarDescriptionImages,
  } = useSetupFlowStep();

  const tipBoxTitle = getSidebarTitleText();
  const tipBoxDescription = getSidebarDescriptions();
  const tipBoxDescriptionImages = getSidebarDescriptionImages();

  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');
  const showTipBox = Boolean(tipBoxTitle.length > 1);

  const sidebar = showTipBox ? (
    <TipBox title={tipBoxTitle} descriptionImages={tipBoxDescriptionImages} description={tipBoxDescription} />
  ) : null;

  return (
    <Page
      headerMainProps={{
        fixed: true,
        disableLogo: false,
        navigationComponents,
        transparent: false,
        additionalNavigationComponents: () => <>{showTipBox ? <MobileTipButton onClick={openTipModal} /> : null}</>,
      }}
      header={!comeFromTheMobileApp}
      footer={false}
    >
      {steps?.map((stepConfig) => {
        const Banner = stepConfig.banner;

        if (!Banner) {
          return null;
        }

        return (
          <Route
            key={stepConfig.path}
            path={stepConfig.path}
            exact
            render={(routeProps) => (
              <div className={cx('banner-container')}>
                <Banner openTipModal={openTipModal} {...routeProps} />
              </div>
            )}
          />
        );
      })}

      <Grid>
        <ErrorBoundary FallbackComponent={Fallback}>
          <WindowSizeListener
            onResize={(windowSize) => setMeasure((state) => ({ ...state, windowWidth: windowSize.windowWidth }))}
          />
          <Measure
            bounds
            onResize={({ bounds }) => {
              setMeasure((state) => ({ ...state, containerWidth: bounds.width }));
            }}
          >
            {({ measureRef }) => {
              const { containerWidth, windowWidth } = measure;
              return (
                <div ref={measureRef} className={cx('setup-flow-steps-layout')}>
                  <div className={cx('form')}>
                    {loading && (
                      <div className={cx('spinner')} style={{ width: containerWidth - sidebarWidth }}>
                        <Spinner fixed />
                      </div>
                    )}
                    {steps?.map((stepConfig) => {
                      const StepComponent = stepConfig.component || stepConfig.form;

                      return <Route key={stepConfig.path} path={stepConfig.path} render={() => <StepComponent />} />;
                    })}
                    {!steps && children?.(openTipModal)}
                  </div>
                  {sidebar && (
                    <div className={cx('sidebar')} style={{ right: (windowWidth - containerWidth) / 2 }}>
                      <div className={cx('overlay')} />
                      <div className={cx('content-sidebar-with-text')}>{sidebar}</div>
                    </div>
                  )}
                </div>
              );
            }}
          </Measure>
        </ErrorBoundary>
      </Grid>
      <MobileTipModal show={isTipModalOpen} onHide={closeTipModal}>
        {sidebar}
      </MobileTipModal>
    </Page>
  );
}
