import Label from 'components/Label/Label';
import String from 'components/String/String';

type MaintenanceRecurrentHeaderProps = { title: string; homeAddress?: string; frequency: string };

export const MaintenanceRecurrenceHeader = ({ title, homeAddress, frequency }: MaintenanceRecurrentHeaderProps) => {
  return (
    <div className="flex flex-col items-center w-full">
      <h2 className="text-center mb-2sm">{title}</h2>
      {homeAddress && (
        <div className="text-center font-semibold mb-xs">
          <String string={homeAddress} />
        </div>
      )}
      <div className="text-center">
        <Label text={frequency} />
      </div>
    </div>
  );
};
