export function getLeaseDurationOptions(leaseMaxMonths: number) {
  const options = [
    {
      label: '1 Year',
      key: 12,
    },
    {
      label: getLabel(leaseMaxMonths),
      key: leaseMaxMonths,
      tag: 'Recommended',
    },
  ];

  if (leaseMaxMonths > 13) {
    options.push({
      label: 'Custom',
      key: -1,
    });
  }

  return options;
}

function getLabel(leaseMaxMonths: number) {
  if (leaseMaxMonths % 12 === 0) {
    return `${leaseMaxMonths / 12} Years`;
  }

  return `${leaseMaxMonths} Months`;
}
