import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { COLORS } from 'components/ColoredText/ColoredText';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import styles from './SuccessMessage.module.css';

const cx = classNames.bind(styles);

// todo: make this visible for screen readers.
export const SuccessMessage = ({ message, onHide, show, hideErrorMessage }) => {
  useEffect(() => {
    if (hideErrorMessage) {
      onHide();
    }
  }, [hideErrorMessage]);

  return (
    <div role="alert" aria-hidden={show} className={cx({ green: show }, 'successText')}>
      <GeneralIcon color={COLORS.GREEN} icon={GENERAL_ICONS.RADIO_SELECTED} />
      <div className={cx('updated')}>{message}</div>
    </div>
  );
};

SuccessMessage.defaultProps = {
  onHide: () => {},
  hideErrorMessage: false,
  show: false,
};

SuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  hideErrorMessage: PropTypes.bool,
  show: PropTypes.bool,
};

export default SuccessMessage;
