import React from 'react';
import { Divider } from '@belong/ui';
import classNames from 'classnames/bind';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import PropTypes from 'prop-types';
import styles from './ProfileImageWithLabel.module.css';

const cx = classNames.bind(styles);
export const MODES = {
  LABEL_RIGHT: 'label-right',
  LABEL_BELOW: 'label-below',
  LABEL_BELOW_COLUMN: 'label-below-column',
};

const ProfileImageWithLabel = ({
  scale,
  profileImage,
  mode,
  title,
  subtitle,
  actions,
  bottomTextColor,
  showDivider,
  imageContainer,
  textWrapperClassName,
  titleClassName,
  subtitleClassName,
  small,
}) => {
  const labelRight = mode === MODES.LABEL_RIGHT;

  return (
    <div className={cx('component-wrapper', { labelRight })}>
      <ProfileImage
        imageContainer={imageContainer}
        scale={scale}
        makeRound
        small={small}
        profileImage={profileImage}
        withHover
      />
      {showDivider && (
        <div className="w-full md:w-[412px]">
          <Divider />
        </div>
      )}
      {(title || subtitle) && (
        <div className={cx('text-wrapper', mode, { labelRight }, textWrapperClassName)}>
          <div className={cx('profile-details', bottomTextColor)}>
            <div className={cx(titleClassName || 'title')}>{title}</div>
            <div className={cx(subtitleClassName || 'subtitle')}>{subtitle}</div>
          </div>
          <div className={cx('actions')}>{actions}</div>
        </div>
      )}
    </div>
  );
};

ProfileImageWithLabel.propTypes = {
  profileImage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  scale: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(MODES)),
  actions: PropTypes.node,
  bottomTextColor: PropTypes.string,
  showDivider: PropTypes.bool,
  imageContainer: PropTypes.string,
  textWrapperClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  small: PropTypes.bool,
};

ProfileImageWithLabel.defaultProps = {
  profileImage: null,
  title: '',
  subtitle: '',
  scale: 1,
  mode: MODES.LABEL_BELOW,
  actions: null,
  bottomTextColor: '',
  showDivider: false,
  imageContainer: '',
  textWrapperClassName: '',
  titleClassName: '',
  subtitleClassName: '',
  small: false,
};

export default ProfileImageWithLabel;
