import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalV2 from '../../../components/Modal/ModalV2/ModalV2';
import ModalLayoutErrorScreen from '../../../layouts/ModalLayout/ModalLayoutErrorScreen/ModalLayoutErrorScreen';
import { FEEDBACK_STRINGS } from '../../../strings/errors.string';

export default class GenericErrorModal extends Component {
  static propTypes = {
    onCtaClick: PropTypes.func,
  };

  static defaultProps = {
    onCtaClick: () => {},
  };

  render() {
    const { onCtaClick, ...rest } = this.props;
    return (
      <ModalV2 closable={false} closeButton={false} noPadding {...rest}>
        <ModalLayoutErrorScreen
          title={FEEDBACK_STRINGS.general_error.title}
          subtitle={FEEDBACK_STRINGS.general_error.subtitle}
          ctaLabel={FEEDBACK_STRINGS.general_error.ctaLabel}
          onCtaClick={onCtaClick}
        />
      </ModalV2>
    );
  }
}
