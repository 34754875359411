import { useState } from 'react';
import { Image } from '@belong/ui';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import { Tag } from 'components/HomeTag/HomeTag';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { includes } from 'lodash-es';
import PropTypes from 'prop-types';
import { getDocumentScrollTop, setDocumentScrollTop } from 'utils/scrollTop';
import LinkBase from '../../corecomponents/LinkBase/LinkBase';
import styles from './FixedMenu.module.css';

const cx = classNames.bind(styles);

export const FixedMenu = ({ menuItems, params }) => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const getItems = menuItems.length > 4 ? 3 : menuItems.length;
  const showMenuItems = menuItems.slice(0, getItems);
  let showMoreMenu;
  let getNumberOfItems = '';

  if (menuItems.length === 3) {
    getNumberOfItems = 'threeItemsInMenu';
  } else if (menuItems.length === 2) {
    getNumberOfItems = 'twoItemsInMenu';
  } else if (menuItems.length === 1) {
    getNumberOfItems = 'oneItemsInMenu';
  }

  if (menuItems.length > 4) {
    showMoreMenu = menuItems.slice(3);
  }

  const arrayOfItem = [params.section, params.subSection];

  const getHighlightedItems = (menuIsOpened, selected) => {
    if (menuIsOpened && selected) {
      return false;
    } else if (selected) {
      return true;
    }

    return false;
  };

  const MenuItemWithImages = (menuItem) => {
    return (
      <div className="w-lg h-lg">
        <Image src={menuItem.selected ? menuItem.iconActive : menuItem.iconNormal} alt="" />
      </div>
    );
  };

  return (
    <div className={cx('mobile-menu')}>
      <div className={cx('fixedMenu', { visibleMenu })}>
        <div className={cx('gridContainer')}>
          <div className={cx('noMarginRow')}>
            {showMenuItems.map((menuItem, index) => {
              const selected = includes(arrayOfItem, menuItem.key);
              return (
                <div className={cx('noPadding', getNumberOfItems)} key={index}>
                  <LinkBase
                    onClick={() => {
                      setVisibleMenu(false);
                      document.body.style.position = 'static';

                      menuItem.onClick?.();
                    }}
                    to={menuItem.to}
                    key={menuItem.key}
                    className={cx('fixedMenuItem', { visibleMenu }, { selected })}
                  >
                    {menuItem.iconNormal ? (
                      <MenuItemWithImages {...menuItem} selected={selected} />
                    ) : (
                      <GeneralIcon
                        icon={menuItem.icon}
                        color={getHighlightedItems(visibleMenu, selected) ? COLORS.GREEN : COLORS.DEFAULT}
                      />
                    )}
                    <div className={cx('menu-item-text')}>{menuItem.header}</div>
                  </LinkBase>
                </div>
              );
            })}
            {!!showMoreMenu?.length && (
              <div className={cx('noPadding')}>
                <ButtonBase
                  onClick={() => {
                    const scrollWhenCreateMenu = getDocumentScrollTop();

                    if (!visibleMenu) {
                      setScrollPosition(scrollWhenCreateMenu);
                      document.body.style.cssText += `;top: -${scrollWhenCreateMenu}px; position: fixed;`;
                    } else {
                      document.body.style.position = 'static';
                      setDocumentScrollTop(scrollPosition);
                    }

                    setVisibleMenu(!visibleMenu);
                  }}
                  className={cx('fixedMenuItem', { selected: visibleMenu })}
                >
                  <GeneralIcon
                    icon={GENERAL_ICONS.FIXED_MENU_MORE}
                    color={visibleMenu ? COLORS.GREEN : COLORS.DEFAULT}
                  />
                  <div className={cx('menu-item-text')}>See More</div>
                </ButtonBase>
              </div>
            )}
          </div>
        </div>
      </div>
      {visibleMenu && (
        <div className={cx('VISIBLEMENU')}>
          <ButtonBase
            onClick={() => {
              setVisibleMenu(false);
              document.body.style.position = 'static';
              setDocumentScrollTop(scrollPosition);
            }}
            className={cx('close-button')}
          >
            <GeneralIcon icon={GENERAL_ICONS.CLOSE} />
          </ButtonBase>
          <div className={cx('showMoreMenu')}>
            {showMoreMenu.map((moreMenu) => {
              const selected = includes(arrayOfItem, moreMenu.key);
              return (
                <LinkBase
                  onClick={() => {
                    // Reload the page if the user is in the inbox and is clicking inbox, so we open the Intercom widget again
                    if (moreMenu.key === 'inbox' && window.location.pathname.includes('inbox')) {
                      window.location.reload();
                    }
                    setVisibleMenu(false);
                    document.body.style.position = 'static';
                    moreMenu.onClick?.();
                  }}
                  to={moreMenu.to}
                  key={moreMenu.key}
                  className={cx('fixedmoreMenu')}
                >
                  {/* selected={selected === item.key} */}
                  {moreMenu.new && (
                    <Tag relative className={cx('tag-new')}>
                      NEW
                    </Tag>
                  )}
                  <div className={cx('menu-item-text', { selected })}>{moreMenu.header}</div>
                </LinkBase>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

FixedMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
};
