import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalLayout from '../ModalLayout';
import String from '../../../components/String/String';

export default class ModalLayoutSuccessScreen extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    ctaLabel: PropTypes.string.isRequired,
    onCtaClick: PropTypes.func.isRequired,
  };

  render() {
    const { title, subtitle, ctaLabel, onCtaClick } = this.props;

    return (
      <ModalLayout>
        <ModalLayout.Content>
          <ModalLayout.Title>
            <String string={title} />
          </ModalLayout.Title>
          <ModalLayout.SubTitle>
            <String string={subtitle} />
          </ModalLayout.SubTitle>
          <ModalLayout.BottomBar
            fixedBottom
            leftSideNode={<div />}
            ctaProps={{
              label: ctaLabel,
              onClick: onCtaClick,
            }}
          />
        </ModalLayout.Content>
      </ModalLayout>
    );
  }
}
