import { useState } from 'react';

function useModal(initialMode) {
  if (initialMode === void 0) {
    initialMode = false;
  }
  var _a = useState(initialMode),
    isModalOpen = _a[0],
    setModalState = _a[1];
  var setModalOpen = function () {
    return setModalState(true);
  };
  var setModalClose = function () {
    return setModalState(false);
  };
  var toggle = function () {
    return setModalState(!isModalOpen);
  };
  return [isModalOpen, setModalOpen, setModalClose, toggle];
}

export { useModal };
