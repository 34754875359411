import { MaintenanceModel } from 'api/models';
import { LabelItem } from 'components/Label/labelTypes';
import { getMaintenanceLabels } from 'maintenance/utils';
import { useRequestAccountResponsibility } from './useRequestAccountResponsibility';

export const useMaintenanceLabels = (isRenter: boolean, maintenance?: MaintenanceModel): LabelItem[] => {
  const shouldRequestAccountResponsibility = useRequestAccountResponsibility(isRenter, maintenance);

  if (!maintenance) return [];

  return getMaintenanceLabels(maintenance, shouldRequestAccountResponsibility);
};
