import { Image } from '@belong/ui';

export const AppGIF = () => (
  <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justifiy-start relative">
    <Image alt="phone-frame" className="h-[390px] overflow-hidden w-[190px] z-[1]" src="app/phone-frame.png" />
    <div className="absolute sm:left-[9px] top-[9px] w-[172px] sm:w-auto">
      <Image alt="app-demo" className="h-[372px] w-[172px]" src="app/demo.gif" />
    </div>
  </div>
);
