import SuccessPage from '../SuccessPage/SuccessPage';
import IsUnitCurrentlyRented from './IsUnitCurrentlyRented/IsUnitCurrentlyRented';
import MaintenanceInvolvement from './MaintenanceInvolvement/MaintenanceInvolvement';
import MaintenancePreferences from './MaintenancePreferences/MaintenancePreferences';
import MultiplePropertiesCheck from './MultiplePropertiesCheck/MultiplePropertiesCheck';
import PreviousMaintenanceSpend from './PreviousMaintenanceSpend/PreviousMaintenanceSpend';
import PropertyAddress from './PropertyAddress/PropertyAddress';
import PropertyBasics from './PropertyBasics/PropertyBasics';
import ScheduleChat from './ScheduleChat/ScheduleChat';
import UnitBasics from './UnitBasics/UnitBasics';
import UnitCondition from './UnitCondition/UnitCondition';
import { STEPS_CONFIG } from './steps';

export const HomeownerOnboardingRoutes = [
  {
    path: STEPS_CONFIG.PROPERTY_ADDRESS.path,
    name: STEPS_CONFIG.PROPERTY_ADDRESS.name,
    component: PropertyAddress,
  },
  {
    path: STEPS_CONFIG.MULTIPLE_PROPERTIES.path,
    name: STEPS_CONFIG.MULTIPLE_PROPERTIES.name,
    component: MultiplePropertiesCheck,
  },
  {
    path: STEPS_CONFIG.PROPERTY_BASICS.path,
    name: STEPS_CONFIG.PROPERTY_BASICS.name,
    component: PropertyBasics,
  },
  {
    path: STEPS_CONFIG.UNIT_BASICS.path,
    name: STEPS_CONFIG.UNIT_BASICS.name,
    component: UnitBasics,
  },
  {
    path: STEPS_CONFIG.UNIT_CONDITION.path,
    name: STEPS_CONFIG.UNIT_CONDITION.name,
    component: UnitCondition,
  },
  {
    path: STEPS_CONFIG.IS_UNIT_CURRENTLY_RENTED.path,
    name: STEPS_CONFIG.IS_UNIT_CURRENTLY_RENTED.name,
    component: IsUnitCurrentlyRented,
  },
  {
    path: STEPS_CONFIG.SPENDING_PREFERENCE.path,
    name: STEPS_CONFIG.SPENDING_PREFERENCE.name,
    component: PreviousMaintenanceSpend,
  },
  {
    path: STEPS_CONFIG.MAINTENANCE_PREFERENCES.path,
    name: STEPS_CONFIG.MAINTENANCE_PREFERENCES.name,
    component: MaintenancePreferences,
  },
  {
    path: STEPS_CONFIG.MAINTENANCE_INVOLVEMENT.path,
    name: STEPS_CONFIG.MAINTENANCE_INVOLVEMENT.name,
    component: MaintenanceInvolvement,
  },
  {
    path: STEPS_CONFIG.SCHEDULE_CHAT.path,
    name: STEPS_CONFIG.SCHEDULE_CHAT.name,
    component: ScheduleChat,
  },
  {
    path: STEPS_CONFIG.SUCCESS_PAGE.path,
    name: STEPS_CONFIG.SUCCESS_PAGE.name,
    component: SuccessPage,
  },
];
