import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PATHS } from '@belong/common';
import MaintenanceStatus from 'containercomponents/MaintenanceStatus/MaintenanceStatus';
import { selectInspections } from 'store/redux/inspections';

type HomeownerMaintenanceDetailsRouteParams = {
  taskId: string;
};

const ResidentMaintenanceDetailsComponent = ({ history }) => {
  const { taskId } = useParams<HomeownerMaintenanceDetailsRouteParams>();
  const onClosePress = useCallback(() => history.push(PATHS.RESIDENTS_ACCOUNT_MAINTENANCE), [history]);
  const inspections = useSelector(selectInspections);

  return (
    <MaintenanceStatus
      taskId={taskId}
      inspection={inspections?.find((x) => x.groupUniqueId === taskId)}
      onClosePress={onClosePress}
      history={history}
      isRenter
    />
  );
};

export default ResidentMaintenanceDetailsComponent;
