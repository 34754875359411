import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MaintenanceModel } from 'api/models';
import { fetchTaskByTaskId } from 'store/redux/maintenance/actions';

export const usePreloadGroupMaintenanceTasks = (maintenance: MaintenanceModel) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (maintenance.requestType === 'Group' && maintenance.subtasks) {
      maintenance.subtasks.forEach((task) => {
        task.items.forEach((item) => {
          try {
            dispatch(fetchTaskByTaskId(item.maintenance.uniqueId));
          } catch (error) {
            console.log(error);
          }
        });
      });
    }
  }, [maintenance]);
};
