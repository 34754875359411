import BillDetailModel from 'models/payments/BillDetailModel';
import { createSelector } from 'reselect';
import { ACCOUNTS_REDUCER } from 'store/redux/accounts/reducer';

export const _selectUserBills = (state) => state[ACCOUNTS_REDUCER].bills;
export const _selectUserPendingBills = (state) => state[ACCOUNTS_REDUCER].pendingBills;
export const _selectCurrentBill = (state) => state[ACCOUNTS_REDUCER].currentBill;
export const _selectUserBalances = (state) => state[ACCOUNTS_REDUCER].balances;
export const _selectUserBalancesByMonth = (state) => state[ACCOUNTS_REDUCER].balancesByMonth;
export const _selectUserBalancesUnderReview = (state) => state[ACCOUNTS_REDUCER].balancesUnderReview;
export const _selectUserBalancesPastMonths = (state) => state[ACCOUNTS_REDUCER].balancesPastMonths;
export const _selectSimulatePayments = (state) => state[ACCOUNTS_REDUCER].splitPayments;
export const _selectFinancePlanDetails = (state) => state[ACCOUNTS_REDUCER].financePlan;
export const _selectFinancePlanPayments = (state) => state[ACCOUNTS_REDUCER].financePlanPayments;

export const selectBills = createSelector(_selectUserBills, (bills) =>
  bills?.items ? bills?.items.map((bill) => new BillDetailModel(bill)) : []
);
export const selectBaseBills = createSelector(_selectUserBills, (bills) => bills);
export const selectCurrentBill = createSelector(_selectCurrentBill, (payment) => new BillDetailModel(payment));
export const selectPendingBills = createSelector(_selectUserPendingBills, (bills) => bills);
export const selectBalances = createSelector(_selectUserBalances, (balances) => balances);
export const selectBalancesUnderReview = createSelector(_selectUserBalancesUnderReview, (balances) => balances);
export const selectBalancesByMonth = createSelector(_selectUserBalancesByMonth, (balances) => balances?.reverse());
export const selectBalancesPastMonths = createSelector(_selectUserBalancesPastMonths, (balances) => balances);

export const selectSimulatePayments = createSelector(_selectSimulatePayments, (simulation) => simulation || {});

export const selectFinancePlanDetails = createSelector(_selectFinancePlanDetails, (financePlan) => financePlan || {});
export const selectFinancePlanPayments = createSelector(
  _selectFinancePlanPayments,
  (financePlanPayments) => financePlanPayments || {}
);
