import React from 'react';
import PropTypes from 'prop-types';
import { HOME_OWNER_PAPER_WORK_STRINGS } from 'strings/homeowner-paperwork.strings';
import FormLayout from 'layouts/FormLayout/FormLayout';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';

export const ResidentRelationshipCommentsSection = ({ name }) => (
  <FormLayout.Section sectionTitle={HOME_OWNER_PAPER_WORK_STRINGS['homeOccupancy.otherInfo.title']}>
    <Field
      component={InputFinalFormAdapter}
      name={`${name}.residentsRelationshipComments`}
      placeholder={HOME_OWNER_PAPER_WORK_STRINGS['homeOccupancy.otherInfo.placeholder']}
    />
  </FormLayout.Section>
);

ResidentRelationshipCommentsSection.propTypes = {
  name: PropTypes.string.isRequired,
};
