import React from 'react';
import classNames from 'classnames/bind';
import Tooltip from 'components/Tooltip/Tooltip';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { Flex, Text } from 'design-system';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import styles from './VacancyReductionBundleProgress.module.css';

const cx = classNames.bind(styles);

const VacancyReductionBundleProgress = ({ approvedItems, bundle }) => {
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const tooltipMobileProps = isMobile
    ? {
        contentStyle: {
          width: 200,
        },
        position: 'bottom center',
      }
    : {};

  const improvementTotalCost = bundle.items.reduce((totalCost, currentItem) => {
    const currentItemCost = currentItem.isTBD ? 250 : currentItem.estimatedDisplayCost;

    return totalCost + currentItemCost;
  }, 0);

  const improvementCurrentCost = approvedItems.reduce((totalCost, currentApprovedItem) => {
    const currentApprovedItemCost = currentApprovedItem.isTBD ? 250 : currentApprovedItem.estimatedDisplayCost;

    return totalCost + currentApprovedItemCost;
  }, 0);

  const improvementPercentageCost = (improvementCurrentCost / improvementTotalCost) * 100;

  return (
    <Flex flexDirection="column" marginTop="sm">
      <div className={cx('dashed-separator')} />
      <Flex marginBottom={['xs', 0]} marginTop={['xs', 'xs']} paddingY="xs">
        <Text fontSize="p1" fontWeight="semibold">
          {POST_INSPECTION_FLOW_STRINGS['approvals.vacancyReduction.tooltip.title']}
        </Text>
        <Tooltip
          className={cx('tooltip-icon-wrapper')}
          iconProps={{ className: cx('tooltip-icon') }}
          position="right center"
          {...tooltipMobileProps}
        >
          <p>{POST_INSPECTION_FLOW_STRINGS['approvals.vacancyReduction.tooltip.detail']}</p>
        </Tooltip>
      </Flex>
      <Flex alignItems="center">
        <Flex alignItems="center" flexShrink="0" justifyContent="flex-start" marginRight="xs" maxWidth="72px">
          <Text fontSize="p1">0%</Text>
        </Flex>
        <div className={cx('bar')}>
          <div
            className={cx('bar-filling', {
              'danger-bar-filling': improvementPercentageCost < 40,
              'warning-bar-filling': improvementPercentageCost >= 40 && improvementPercentageCost < 70,
              'success-bar-filling': improvementPercentageCost >= 70,
            })}
            style={{ width: `${improvementPercentageCost}%` }}
          />
        </div>
        <Flex alignItems="center" flexShrink="0" justifyContent="flex-end" marginLeft="xs" maxWidth="72px">
          <Text fontSize="p1">21%</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

VacancyReductionBundleProgress.propTypes = {
  approvedItems: PropTypes.object.isRequired,
  bundle: PropTypes.object.isRequired,
};

export { VacancyReductionBundleProgress };
