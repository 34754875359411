import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { RentalPlan } from 'models/enums/index';
import { RENTAL_STATUS_TEXT, rentalPlanItems } from 'strings/rental-status';
import { required } from 'utils/validation';
import FormLayout from 'layouts/FormLayout/FormLayout';
import Condition from 'formcomponents/Condition/Condition';
import Space from 'corecomponents/Space/Space';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import String from 'components/String/String';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import Field from 'components/Field/Field';

export const RentingPlansSection = ({ name }) => (
  <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.rentingPlans}>
    <Row>
      <Col sm={6} xs={12}>
        <Field
          component={DropdownFinalFormAdapter}
          items={rentalPlanItems}
          name={`${name}.rentalPlan`}
          placeholder={RENTAL_STATUS_TEXT.rentingPlansPlaceholder}
          validate={required}
        />
      </Col>
    </Row>
    <Condition is={RentalPlan.LessThanYear} when={`${name}.rentalPlan`}>
      <Space />
      <GreenInfoBox>
        <String string={RENTAL_STATUS_TEXT.shortTermRentalNote} />
      </GreenInfoBox>
    </Condition>
  </FormLayout.Section>
);

RentingPlansSection.propTypes = {
  name: PropTypes.string.isRequired,
};
