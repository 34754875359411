import { Image } from '@belong/ui';
import classNames from 'classnames/bind';
import styles from './app-down-phone.module.css';

const cx = classNames.bind(styles);

export const AppDownPhone = () => (
  <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justifiy-start relative">
    <Image
      alt="phone-down"
      className={cx('hidden md:block overflow-hidden', styles.phoneDown)}
      src="app/ho_app_down.png"
    />
    <Image
      alt="phone-down-mobile"
      className={cx('md:hidden w-auto overflow-hidden', styles.phoneDownMobile)}
      src="app/ho_app_down_mobile.png"
    />
  </div>
);
