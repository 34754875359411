export const PROPERTY_TYPES = {
  SINGLE_FAMILY: 'SingleFamily',
  MULTI_UNIT: 'MultiUnit',
  TOWN_HOUSE: 'Townhouse',
  OTHER: 'Other',
};

export const HOMEOWNER_ONBOARDING_MODES = { RESUME: 'resume' };

export const STEPS_LABEL = {
  PropertyBasics: 'Property Type',
  UnitBasics: 'Property Details',
  UnitConditions: 'Condition',
  UnitOccupancy: 'Occupancy',
  UnitMaintenanceSpend: 'Maintenance',
  HomeownerMaintenanceInvolvement: 'Involvement',
  HomeownerMaintenancePreferences: 'How Feel...',
  schedule_chat: 'Get In Touch',
};

export const SCHEDULE_STEP_ITEM = {
  stepName: 'schedule_chat',
};
