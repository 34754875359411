import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '@belong/ui';
import UnitLease from 'models/common/UnitLeaseDetail';
import { fetchInitialPrice } from 'store/redux/initial-price/actions';
import { ComparablesStep } from './InitialEstimateSteps/ComparablesStep/ComparablesStep';
import { InitialPricingStep } from './InitialEstimateSteps/InitialPricingStep/InitialPricingStep';

type InitialEstimateModalProps = {
  todos: any[];
  unit: UnitLease;
  address: string;
  isOpen: boolean;
  addressModel: any;
  onDismiss: () => void;
};

export default function InitialEstimateModal({
  todos,
  unit,
  address,
  isOpen,
  addressModel,
  onDismiss,
}: InitialEstimateModalProps): JSX.Element {
  const [showComparables, setShowComparables] = useState(false);
  const [initialEstimate, setInitialEstimate] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const getInitialPrice = async () => {
      try {
        const initialEstimateData = await dispatch(fetchInitialPrice(unit?.basicInfo?.id));

        setInitialEstimate(initialEstimateData);
      } catch (err) {
        console.log(err);
      }
    };

    if (unit?.basicInfo?.id) {
      getInitialPrice();
    }
  }, [dispatch, unit]);

  function onModalDismiss() {
    setShowComparables(false);
    onDismiss();
  }

  if (!initialEstimate) return null;

  return (
    <Modal
      contentClassName="flex"
      isOpen={isOpen}
      name="APP_MODAL"
      onDismiss={onModalDismiss}
      overlayClassName="z-[99999]"
      size="fullScreen"
    >
      <div className="w-full flex justify-center">
        {showComparables ? (
          <ComparablesStep
            todos={todos}
            address={address}
            onDismiss={onModalDismiss}
            unit={unit}
            initialEstimate={initialEstimate}
            addressModel={addressModel}
          />
        ) : (
          <InitialPricingStep
            address={address}
            unit={unit}
            initialEstimate={initialEstimate}
            setShowComparables={setShowComparables}
          />
        )}
      </div>
    </Modal>
  );
}
