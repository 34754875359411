import { useState, useEffect } from 'react';

var useOnScreen = function (ref, rootMargin, threshold) {
  if (rootMargin === void 0) {
    rootMargin = '0px';
  }
  if (threshold === void 0) {
    threshold = 0;
  }
  var _a = useState(false),
    isIntersecting = _a[0],
    setIsIntersecting = _a[1];
  useEffect(function () {
    var observer = new IntersectionObserver(function (_a) {
      var entry = _a[0];
      setIsIntersecting(entry.isIntersecting);
    }, {
      rootMargin: rootMargin,
      threshold: threshold
    });
    var current = (ref || {}).current;
    if (current) {
      observer.observe(current);
    }
    return function () {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [ref]);
  return isIntersecting;
};

export { useOnScreen };
