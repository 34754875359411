import { useHistory } from 'react-router-dom';
import { PATHS } from '@belong/common';
import { IconBack, IconClose } from '@belong/icons';
import { Button, Text } from '@belong/ui';
import { STRINGS } from 'maintenance/strings';

type Props = {
  isHomeOwnerView?: boolean;
};

export function NavBar({ isHomeOwnerView }: Props) {
  const { goBack, push } = useHistory();

  const handleCloseClick = () =>
    push(isHomeOwnerView ? PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE : PATHS.RESIDENTS_ACCOUNT_MAINTENANCE);

  return (
    <div className="w-full flex justify-between items-center py-xs px-0 sm:px-xs">
      <Button onClick={goBack} variant="text">
        <div className="flex items-center p-sm">
          <div className="flex mr-xs">
            <IconBack className="h-2sm w-2sm" />
          </div>
          <Text fontWeight="semibold">{STRINGS.back}</Text>
        </div>
      </Button>
      <Button onClick={handleCloseClick} variant="text">
        <div className="p-sm">
          <IconClose className="h-2sm w-2sm" />
        </div>
      </Button>
    </div>
  );
}
