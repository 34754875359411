import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { palette } from 'styles/palette';
import ProfileTile, { PROFILE_TILE_SPACING } from '../../components/ProfileTile/ProfileTile';
import styles from './ResidentTileWithStatus.module.css';

const cx = classNames.bind(styles);

const statusColorMap = (status) => {
  switch (status) {
    case 'Pending':
      return palette.red;
    case 'Signed':
      return palette.green;
    default:
      return palette['dark-gray'];
  }
};

const displayStatusMap = (status) => {
  switch (status) {
    case 'Pending':
      return 'Signature Pending';
    default:
      return status;
  }
};

type Props = {
  profileProps: Record<string, unknown>;
  ctaLabel?: string;
  onCTAClick?: () => void;
  residentInfo?: Record<string, unknown>[];
  alignProfileItems?: string;
};

const ResidentTileWithStatus = ({
  profileProps,
  ctaLabel = '',
  onCTAClick = () => {},
  alignProfileItems = null,
  residentInfo = [],
}: Props) => {
  return (
    <div className={cx('renter')}>
      <div className={cx('tile-with-status-container')}>
        <div className={cx('tile-container')}>
          <ProfileTile
            relative
            alignProfileItems={alignProfileItems}
            spacing={PROFILE_TILE_SPACING.NO_SPACING}
            text={residentInfo}
            {...profileProps}
          />
        </div>
        <div
          className={cx('signature-status-container')}
          style={{ color: statusColorMap(profileProps.signatureStatus) }}
        >
          {displayStatusMap(profileProps.signatureStatus)}
        </div>
      </div>

      {ctaLabel && <Button label={ctaLabel} onClick={onCTAClick} />}
    </div>
  );
};

ResidentTileWithStatus.MODES = ProfileTile.MODES;

export default ResidentTileWithStatus;
