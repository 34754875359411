export const BUTTON_TYPES = {
  DEFAULT: 'default',
  DEFAULT_SMALL: 'default small',
  DANGER: 'danger',
  OUTLINE: 'outline',
  MULTI_TEXT: 'multitext',
  TEXT: 'text',
  TEXT_DANGER: 'text-danger',
  TEXT_LIGHT: 'text-light',
  TEXT_SMALL: 'text-small',
  LONG: 'long',
  NOSTYLE: 'nostyle',
  HIGHLIGHT: 'highlight',
  TRANSPARENT_OUTLINE_GREEN: 'transparent-outline-green',
  TRANSPARENT_OUTLINE_GREEN_NAVY_TEXT: 'transparent-outline-green-navy-text',
};

export const BUTTON_SIZES = {
  DEFAULT: 'default',
  FLUID: 'fluid',
  SMALL: 'small',
  LONG: 'long',
  FIT: 'fit',
  FLUID_DEFAULT_RESPONSIVE: 'fluid-default-responsive',
  FLUID_LONG_RESPONSIVE: 'fluid-long-responsive',
};
