import { Text } from '@belong/ui';
import { STRINGS } from 'maintenance/strings';

type Props = {
  type: 'recurring' | 'non-recurring';
};

export function EmptyTaskList({ type }: Props) {
  return (
    <div className="p-lg flex justify-center items-center rounded border-dashed border border-gray">
      <Text className="text-dark-gray">
        {type === 'recurring' ? STRINGS['no-recurring-services'] : STRINGS['no-tasks']}
      </Text>
    </div>
  );
}
