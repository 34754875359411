import React from 'react';
import classNames from 'classnames/bind';
import Image from 'components/Image/Image';
// import {Flex} from 'design-system';
import styles from './SoldComponent.module.css';

const cx = classNames.bind(styles);

// TODO: remove
export function SoldComponent() {
  return (
    <div className={cx('soldComponent')}>
      <Image src="/sell_home/career_landing_balloon_01.svg" alt="Sell Home Balloon" className={cx('balloon')} />
      <Image src="/sell_home/sell_sold_banner.svg" alt="Sell Home Banner" className={cx('soldSign')} />
    </div>
  );
}
