import { createContext, useState } from 'react';
import type { ReactNode } from 'react';
import type { RescheduleAppointmentContextSchema } from 'accounts/types';

export const RescheduleAppointmentContext = createContext<RescheduleAppointmentContextSchema>({
  coverageRegionId: 1,
  formattedAppointmentDate: '',
  onDismiss: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  setFormattedAppointmentDate: () => '',
  unitId: '',
  unit: {
    inspectionCompletedOn: new Date().toISOString(),
    isPublished: false,
    isInitialPriceRangePublished: false,
    isPrimaryOwner: false,
    isSetupFlowCompleted: false,
    isFarOffMoveout: false,
    isAdoptedAgreement: false,
    hasImprovementsFlowOpen: false,
    hasLandlordFlowOpen: false,
    hasPricingFlowOpen: false,
    hasHomeownerPreMoveOutFlowOpen: false,
    homeStatus: 'Active',
  },
});

type Props = Omit<RescheduleAppointmentContextSchema, 'formattedAppointmentDate' | 'setFormattedAppointmentDate'> & {
  children: ReactNode;
};

export function RescheduleAppointmentContextProvider({
  children,
  coverageRegionId,
  onDismiss,
  timeZone = 'America/Los_Angeles',
  unitId,
  unit,
}: Props) {
  const [formattedAppointmentDate, setFormattedAppointmentDate] = useState('');

  return (
    <RescheduleAppointmentContext.Provider
      value={{
        coverageRegionId,
        formattedAppointmentDate,
        onDismiss,
        setFormattedAppointmentDate,
        timeZone,
        unitId,
        unit,
      }}
    >
      {children}
    </RescheduleAppointmentContext.Provider>
  );
}
