import { ComponentProps } from 'react';
import { Accordion } from '@belong/ui';
import { DepositFAQSItem } from 'lease-signing-flow/components/deposit-faqs-item/deposit-faqs-item';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

export const DEPOSIT_TYPES = {
  FEE: 'Fee',
  REFUNDABLE: 'Refundable',
};

export const defaultItems: ComponentProps<typeof Accordion>['items'] = [
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.waiting.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.waiting.description']}
        src="lease-signing-flow/waiting.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.waiting.title']}
      />
    ),
  },
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.invest.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.invest.description']}
        src="lease-signing-flow/invest.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.invest.title']}
      />
    ),
  },
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.expense.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.expense.description']}
        descriptionClassname="max-w-[360px]"
        src="lease-signing-flow/expense.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.expense.title']}
      />
    ),
  },
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.savings.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.savings.description']}
        src="lease-signing-flow/savings.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.savings.title']}
      />
    ),
  },
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.none.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.none.description']}
        src="lease-signing-flow/none.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.none.title']}
      />
    ),
  },
];

export const renewalItems: ComponentProps<typeof Accordion>['items'] = [
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.invest.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.invest.description']}
        src="lease-signing-flow/invest.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.invest.title']}
      />
    ),
  },
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.expense.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.renewal.accordion.expense.description']}
        descriptionClassname="max-w-[360px]"
        src="lease-signing-flow/expense.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.expense.title']}
      />
    ),
  },
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.savings.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.savings.description']}
        src="lease-signing-flow/savings.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.savings.title']}
      />
    ),
  },
  {
    title: LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.none.title'],
    content: (
      <DepositFAQSItem
        description={LEASE_SIGNING_FLOW_STRINGS['deposit.renewal.accordion.none.description']}
        src="lease-signing-flow/none.png"
        title={LEASE_SIGNING_FLOW_STRINGS['deposit.accordion.none.title']}
      />
    ),
  },
];
