import { RefObject } from 'react';
import { useOnScreen } from '@belong/common';
import { IconAlert, IconArrowDown } from '@belong/icons';
import { ButtonBase, Text } from '@belong/ui';
import clsx from 'clsx';
import { customStyles } from 'containercomponents/MaintenanceStatus/MaintenanceAccountResponsibility/maintenance-account-responsibility.style';
import { maintenanceAccountResponsibilityStrings } from './maintenance-account-responsibility.constants';

const APPROVAL_COMPONENT_VISIBILITY_THRESHOLD = '-100px';

export const MaintenanceAccountResponsibilityBanner = ({
  componentRef,
}: {
  componentRef: RefObject<HTMLDivElement>;
}) => {
  const isApprovalComponentOnScreen = useOnScreen(componentRef, APPROVAL_COMPONENT_VISIBILITY_THRESHOLD);

  const onBannerPress = () => componentRef.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <div
      className={clsx(
        'fixed bottom-2xl z-10 left-0 right-0 flex justify-center transition-transform duration-hover ease-in-out translate-y-0',
        isApprovalComponentOnScreen ? 'translate-y-7xl' : 'translate-y-0'
      )}
    >
      <ButtonBase
        onClick={onBannerPress}
        className="w-full rounded-full py-2sm px-sm flex items-center bg-darker-navy shadow-2xl"
        style={customStyles.bannerContainer}
      >
        <div className="flex items-center justify-start flex-1">
          <IconAlert className="text-red mr-xs" height={24} />
          <Text fontWeight="semibold" className="text-white">
            {maintenanceAccountResponsibilityStrings.bannerAction}
          </Text>
        </div>

        <IconArrowDown height={20} />
      </ButtonBase>
    </div>
  );
};
