import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import styles from './DisplayPriceAdjustment.module.css';

const cx = classNames.bind(styles);

const DisplayPriceAdjustment = ({ metadata }) => {
  const { previousPrice, newPrice } = metadata;
  return (
    <div className={cx('price-adjustment')}>
      <Text className={cx('slashed-text')}>
        <Money format={MONEY_FORMAT.DOLLARS} cash={previousPrice} />
      </Text>
      <span className={cx('sm-spacing-left')}>
        <Money format={MONEY_FORMAT.DOLLARS} cash={newPrice} />
      </span>
      <div className={cx('price-change-container')}>
        {newPrice > previousPrice ? (
          <GeneralIcon icon={GENERAL_ICONS.ICON_GREEN_UP_ARROW} />
        ) : (
          <GeneralIcon icon={GENERAL_ICONS.ICON_RED_DOWN_ARROW} />
        )}
        <Text fontSize="p1" color={newPrice > previousPrice ? 'green' : 'red'} ml="2xs">
          {previousPrice === 0 ? 0 : Math.round((100 * Math.abs(newPrice - previousPrice)) / previousPrice)}%
        </Text>
      </div>
    </div>
  );
};

DisplayPriceAdjustment.propTypes = {
  metadata: PropTypes.object.isRequired,
};

export default DisplayPriceAdjustment;
