import classNames from 'classnames/bind';
import { Flex, Text } from 'design-system';
import { BundleItemOption } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/BundleItemOption/BundleItemOption';
import PropTypes from 'prop-types';
import styles from './BundleItemOptionList.module.css';

const cx = classNames.bind(styles);

const BundleItemOptionList = ({ currentOptionId, isImprovementSelected = false, onSelect, options, showPrice }) => {
  const sortedOptions = [...options].sort((optionA, optionB) =>
    optionA.optionDisplayDiscountedCost < optionB.optionDisplayDiscountedCost ? 1 : -1
  );

  return (
    <Flex flexDirection="column" marginTop="sm">
      <Text className={cx([!isImprovementSelected && 'disabled-options'])} fontSize="h3" fontWeight="semibold">
        Available Options
      </Text>
      <Flex flexDirection="column" marginTop="sm">
        {sortedOptions.map((option) => (
          <BundleItemOption
            isImprovementSelected={isImprovementSelected}
            isSelected={currentOptionId === option.costId}
            key={option.costId}
            onSelect={onSelect}
            option={option}
            showPrice={showPrice}
          />
        ))}
      </Flex>
    </Flex>
  );
};

BundleItemOptionList.propTypes = {
  currentOptionId: PropTypes.number,
  isImprovementSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  showPrice: PropTypes.bool,
};

export { BundleItemOptionList };
