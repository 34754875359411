import { ReactNode } from 'react';
import Form from 'components/Form/Form';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEqual } from 'lodash-es';

const { FormLayoutHeader } = FormLayout;

type StepFormLayoutProps = {
  ctaProps?: Record<string, any>;
  currentStep: any;
  getForm?: any;
  initialValues?: Record<string, any>;
  onSubmit: any;
  preTitle?: ReactNode;
  steps: any;
  subTitle?: string;
  title: string;
  previousContent: ReactNode;
};

export const StepFormLayout = ({
  ctaProps = {},
  currentStep,
  getForm,
  initialValues = {},
  onSubmit,
  preTitle,
  steps,
  subTitle = '',
  title,
  previousContent,
}: StepFormLayoutProps) => {
  const progressItems = steps.map((step) => ({
    active: step.index === currentStep.index,
    label: step.label ?? step.name,
    incomplete: step.index >= currentStep.index,
    to: step.path,
    disabled: step.index >= currentStep.index,
  }));

  return (
    <FormLayout>
      <div className="mb-lg">
        <ProgressBar items={progressItems} />
      </div>

      <FormLayoutHeader title={title} subtitle={subTitle} pretitle={preTitle} />
      <FormLayout>
        <Form
          mutators={{
            ...arrayMutators,
          }}
          initialValuesEqual={isEqual}
          initialValues={initialValues}
          onSubmit={onSubmit}
          getForm={getForm}
          getFormBottomBar={(_formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              previousContent={previousContent}
              ctaProps={{
                label: 'Next',
                ...ctaProps,
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
        />
      </FormLayout>
      <Space value={SPACE_TYPES.XXXL} />
    </FormLayout>
  );
};
