import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BASE_PATHS } from '@belong/common';
import { IconForward } from '@belong/icons';
import { Link } from '@belong/ui';
import { DATE_FORMATS, formatDate } from 'utils/formatDate';
import { fetchHomeownerImprovementFixing } from './services';

type Props = {
  section: {
    sectionTitle: string;
    unitId: string;
  };
};

type ImprovementData = {
  estimatedHomeownerFixingCompletionDate: string;
  maintenanceId: string;
  reportId: string;
  status: string;
};

export function HomeImprovementsTracking({ section }: Props) {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const [improvementData, setImprovementData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const onboardingImprovementStatusResponse = await dispatch<ImprovementData>(
          fetchHomeownerImprovementFixing(section?.unitId) as unknown as ImprovementData
        );

        const { estimatedHomeownerFixingCompletionDate, maintenanceId, reportId, status } =
          onboardingImprovementStatusResponse || {};

        setImprovementData({
          estimatedHomeownerFixingCompletionDate,
          maintenanceId,
          reportId,
          status,
        });

        setShowComponent(!!onboardingImprovementStatusResponse);
      } catch (error) {
        setShowComponent(false);
      }
    };

    fetchData();
  }, [section?.unitId]);

  const address = section?.sectionTitle;

  if (!showComponent) {
    return null;
  }

  return (
    <div className="mt-xl sm:mt-0">
      <p className="text-h3-sm md:text-h3-xl font-semibold mb-xs md:mb-2xs">Work you&apos;re tackling</p>
      <div className="w-full flex flex-col sm:flex-row md:gap-xl md:justify-between">
        <div className="pl-xs mt-xs sm:mt-sm mb-xl sm:mb-2xl border-0 border-l-3 border-solid  flex flex-col justify-between items-start border-blue">
          <p className="mb-2xs">{address}</p>
          <p className="font-semibold text-h3-sm mb-2xs">
            Finishing{' '}
            {formatDate(
              improvementData?.estimatedHomeownerFixingCompletionDate,
              DATE_FORMATS.MONTH_FULLNAME_DAY_SHORT_YEAR_SEPERATED_WITH_COMMA_SPACE
            )}
          </p>

          <Link
            href={`${BASE_PATHS.ACCOUNTS}/portfolio/maintenance/${improvementData?.maintenanceId}`}
            className="font-semibold"
          >
            VIEW HOME IMPROVEMENT PLAN
            <IconForward height={15} className="ml-xs" />
          </Link>
        </div>
        <div className="flex justify-end mb-2xl sm:mt-sm shrink-0 items-center">
          <a
            className="font-semibold"
            href={`${BASE_PATHS.ACCOUNTS}/home/post-move-out/improvements/homeowner-fixes-tracking?home-id=${section?.unitId}`}
          >
            UPDATE OR CONFIRM
          </a>
        </div>
      </div>
    </div>
  );
}
