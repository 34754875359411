import { connect } from 'react-redux';
import { COOKIES_CONFIG } from '@belong/common';
import config from 'config/config';
import ENV from 'config/environments';
import UserBasicInfo from 'models/common/UserBasicInfo';
import { parseCookies } from 'nookies';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { selectExperiments } from '../store/redux/experiments';
import { selectUser, selectUserClaims } from '../store/redux/user/selectors';
import { AnalyticsProviderContext } from './AnalyticsProviderContext';
import { getRouteProperties } from './utils';

const trackingIdCookieConfig = COOKIES_CONFIG.TRACKING_ID;

const AnalyticsProvider = ({ user, userClaims, children, experiments }) => {
  const cookies = parseCookies();
  const trackingId = cookies[trackingIdCookieConfig.name];

  const analytics = {
    track: (eventType, analyticsData) => {
      const properties = {
        ...analyticsData,
        trackingId,
        userId: user?.userId,
        ...(userClaims || {}),
        ...experiments,
        ...getRouteProperties(location.search), // eslint-disable-line no-restricted-globals
      };
      if (properties.page) {
        if (config.env === ENV.STAGING || config.env === ENV.PRODUCTION) {
          // track only where we have enabled tracking for a page
          window.mixpanel?.track(eventType, properties);
        }
      }
    },
  };

  return <AnalyticsProviderContext.Provider value={analytics}>{children}</AnalyticsProviderContext.Provider>;
};

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.instanceOf(UserBasicInfo),
  userClaims: PropTypes.object,
  experiments: PropTypes.object.isRequired,
};

AnalyticsProvider.defaultProps = {
  user: {},
  userClaims: {},
};

export default compose(
  connect((state) => ({
    user: selectUser(state),
    userClaims: selectUserClaims(state),
    experiments: selectExperiments(state),
  }))
)(AnalyticsProvider);
