import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Fallback } from 'accounts/components/fallback';
import classNames from 'classnames/bind';
import ModalManager from 'containercomponents/Modals/ModalManager';
import Toast from 'corecomponents/Toast/Toast';
import PropTypes from 'prop-types';
import getRoutesConfig from 'routes/routes';
import { fetchSettings, fetchPayoutPlans } from 'store/redux/settings';
import styles from './App.module.css';

const cx = classNames.bind(styles);

const App = ({ children }) => {
  const routes = getRoutesConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchPayoutPlans());
  }, []);

  return (
    <div className={cx('app')}>
      {children}
      <ErrorBoundary FallbackComponent={Fallback}>
        <div className={cx('appContent')}>{renderRoutes(routes)}</div>
      </ErrorBoundary>

      {/* Global components */}
      <ModalManager />
      <Toast />
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
