import { Text } from '@belong/ui';

import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

export const TermsAndConditions = () => (
  <>
    <Text className="normal-case mr-2xs">{LEASE_SIGNING_FLOW_STRINGS['wallet.terms-and-conditions']}</Text>
    <LinkBase target="_blank" a="/terms-and-conditions">
      <Text fontWeight="semibold">{LEASE_SIGNING_FLOW_STRINGS['wallet.see-terms']}</Text>
    </LinkBase>
  </>
);
