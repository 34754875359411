import { ACTIONS_ERROR } from './constants';

export const ERROR_REDUCER = 'errorProps';

export const ERROR_SCREENS = {
  PROPERTY_ADDRESS_PROPS: 'PROPERTY_ADDRESS_PROPS',
};

const initialState = {
  [ERROR_SCREENS.PROPERTY_ADDRESS_PROPS]: {},
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS_ERROR.ADD_PROPERTY_ERROR_PROPS: {
      return {
        ...state,
        [ERROR_SCREENS.PROPERTY_ADDRESS_PROPS]: action.props,
      };
    }
    case ACTIONS_ERROR.REMOVE_ALL_ERRORS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
