import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { MoveOutOptions, RentalStatus } from 'models/enums';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import Space from 'corecomponents/Space/Space';
import Condition from 'formcomponents/Condition/Condition';
import String from 'components/String/String';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';

export const MoveOutResidentsFoundFields = ({ name, rentalStatus }) => (
  <Condition is={MoveOutOptions.WhenResidentsAreFound} when={`${name}.moveoutOption`}>
    <Row>
      <Col xs={12}>
        <Space />
        <GreenInfoBox>
          {rentalStatus === RentalStatus.OwnerOccupied && (
            <String string={RENTAL_STATUS_TEXT.moveOutWhenResidentsFoundNote} />
          )}
          {rentalStatus === RentalStatus.FamilyOccupied && (
            <String string={RENTAL_STATUS_TEXT.friendsMoveOutWhenResidentsFoundNote} />
          )}
        </GreenInfoBox>
      </Col>
    </Row>
  </Condition>
);

MoveOutResidentsFoundFields.propTypes = {
  name: PropTypes.string.isRequired,
  rentalStatus: PropTypes.oneOf([RentalStatus.FamilyOccupied, RentalStatus.OwnerOccupied]),
};
