import { Image } from '@belong/ui';
import LinkBase from 'corecomponents/LinkBase/LinkBase';

const Logo = ({ disableLogo = false }) => {
  return (
    <div style={{ height: 27, width: 77 }}>
      {disableLogo ? (
        <Image src="/logos/belong_logo_web.svg" alt="Belong logo" aria-hidden="true" className="block" />
      ) : (
        <LinkBase to="/">
          <Image src="/logos/belong_logo_web.svg" alt="Belong logo" aria-hidden="true" className="block" />
        </LinkBase>
      )}
    </div>
  );
};

export default Logo;
