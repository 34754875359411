import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { Text } from 'design-system';
import { selectHomeById } from 'store/redux/homes/selectors';
import { padNumber } from 'utils/padNumber';
import styles from './countdown-banner.module.css';

const cx = classNames.bind(styles);

type Props = {
  // eslint-disable-next-line
  home?: any;
};

export const CountdownBanner = ({ home }: Props) => {
  const currentHome = useSelector((state) => selectHomeById(state, home?.basicInfo?.unitId));

  return (
    <div className={cx('countdown-banner')}>
      <div className={cx('text-container')}>
        <Text fontWeight="semibold">Holding your place for </Text>
        <Countdown
          date={new Date(currentHome?.leaseOffered?.offerExpiresOn)}
          renderer={({ hours, minutes, seconds }) => (
            <Text fontWeight="semibold" color="green" ml="2xs">
              {padNumber(hours)}:{padNumber(minutes)}:{padNumber(seconds)}
            </Text>
          )}
        />
      </div>
    </div>
  );
};
