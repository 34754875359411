export const PET_TYPES = {
  ALL: 'any_pets',
  DOGS: 'dogs_only',
  CATS: 'cats_only',
  NONE: 'null',
}

export const NO_LIMIT_ITEM = {
  value: 'No limit (within reason)',
  key: 'no_limit',
};
