import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Text, Tabs } from '@belong/ui';
import { TrackingNewModel } from 'api/models';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import Image from 'components/Image/Image';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { Row, Grid } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import UnitLease from 'models/common/UnitLeaseDetail';
import { AgreementStatus, UserToDoTypes } from 'models/enums';
import { HOMEOWNER_ONBOARDING_MODES } from 'pages/HomeOwnerOnboarding/constants';
import { BASE_PATHS } from 'routes/paths';
import { selectAccountAgreements } from 'store/redux/homeowner-accounts/selectors';
import { updateInitialPriceFeedback } from 'store/redux/initial-price/actions';
import { showToast, clearToasts } from 'store/redux/toast';
import { trackEvent } from 'store/redux/tracking/actions';
import { _selectUser } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import { TRACKING_STRINGS } from 'strings/tracking.strings';
import { STRINGS } from '../../InitialEstimates.strings';
import styles from './ComparablesStep.module.css';
import { ComparablesStepList } from './list/comparables-step-list';
import { ComparablesStepMap } from './map/comparables-step-map';

const cx = classNames.bind(styles);

const toastTime = 4000;

/* eslint-disable @typescript-eslint/no-explicit-any */
type ComparablesStepProps = {
  todos: any[];
  address: any;
  unit: UnitLease;
  initialEstimate: any;
  addressModel: any;
  onDismiss: () => void;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export function ComparablesStep({
  todos,
  address,
  unit,
  initialEstimate,
  addressModel,
  onDismiss,
}: ComparablesStepProps): JSX.Element {
  const agreements = useSelector((state) => selectAccountAgreements(state));
  const user = useSelector(_selectUser);

  const dispatch = useDispatch();
  const history = useHistory();

  const getInitialValues = () => {
    const initialData = structuredClone(initialEstimate);

    return initialData;
  };

  const handleFormSubmit = async (values) => {
    dispatch(clearToasts());

    const feedback = {
      feedback: values.pricingEstimate.homeownerFeedback,
    };
    const trackingObject: TrackingNewModel = {
      eventName: TRACKING_STRINGS.eventNames['initial-pricing-estimate'],
      metadata: {
        initialPricingId: values.pricingEstimate.id,
        userId: user?.id,
      },
    };

    await dispatch(updateInitialPriceFeedback(initialEstimate.pricingEstimate.id, feedback));
    await dispatch(trackEvent(trackingObject));

    dispatch(
      showToast({ message: 'Successfully Updated', success: true }, { autoDelete: true, autoDeleteTime: toastTime })
    );
  };

  const homeMediaValue =
    ((initialEstimate?.pricingEstimate?.minimumRentPrice || 0) +
      (initialEstimate?.pricingEstimate?.maximumRentPrice || 0)) /
    2;

  const lowerEstimates = initialEstimate?.comparableHomes
    .filter((home) => home?.relatedExternalListing?.rentAmount <= homeMediaValue)
    .sort((a, b) => a.rentAmount - b.rentAmount);
  const higherEstimates = initialEstimate?.comparableHomes
    .filter((home) => home?.relatedExternalListing?.rentAmount > homeMediaValue)
    .sort((a, b) => a.rentAmount - b.rentAmount);

  const saveAndRedirect = async (values, to) => {
    await handleFormSubmit(values);

    history.push(to);
  };

  const saveAndClose = async (values) => {
    await handleFormSubmit(values);
    onDismiss();
  };

  const renderAction = (values) => {
    const notSignedAgreement = agreements?.find(
      (agreement) => agreement?.agreement?.status === AgreementStatus.NotSigned
    );

    if (!unit?.basicInfo?.isOnboardingComplete) {
      return (
        <Button
          className={cx('fixed bottom-0 md:relative md:mb-lg', styles.ctaBtn)}
          onClick={() => saveAndRedirect(values, `/homeowner/onboard?mode=${HOMEOWNER_ONBOARDING_MODES.RESUME}`)}
        >
          {STRINGS['comparables.not-qualified-cta']}
        </Button>
      );
    }
    if (unit?.basicInfo?.isOnboardingComplete && agreements?.length > 0 && notSignedAgreement) {
      const todo = todos?.find((action) => action.type === UserToDoTypes.AgreementFlowOpen);
      const linkPath = `${BASE_PATHS.AGREEMENT_FLOW}/plans/${todo?.metadata?.flowId}/${todo?.metadata?.flowStepId}`;
      return (
        <Button
          className={cx('fixed bottom-0 md:relative md:mb-lg', styles.ctaBtn)}
          onClick={() => saveAndRedirect(values, linkPath)}
        >
          {STRINGS['comparables.not-signed-cta']}
        </Button>
      );
    }

    return (
      <Button onClick={() => saveAndClose(values)} className={cx('fixed bottom-0 md:relative md:mb-lg', styles.ctaBtn)}>
        {STRINGS['comparables.signed-cta']}
      </Button>
    );
  };

  const formatAddress = (addressData) => {
    return `${addressData.streetAddress}, ${addressData.city}, ${addressData.state} ${addressData.zipcode}`;
  };

  function getHomes() {
    return [
      { address: addressModel, current: true },
      ...(initialEstimate?.comparableHomes ?? []).map((comparableHome) => comparableHome.relatedExternalListing),
    ];
  }

  return (
    <div className="w-full flex flex-col items-center">
      <Text variant="h2" className="text-center mb-xs mt-2xl md:mt-0 px-sm md:px-0" fontWeight="semibold">
        {STRINGS['comparables.title']}
      </Text>
      <Text className="max-w-[225px] md:max-w-none mb-sm text-center" fontWeight="semibold">
        {formatString(address) || formatAddress(unit?.basicInfo?.address)}
      </Text>
      <Text className="mb-xl text-center px-sm md:px-0">{STRINGS['comparables.description']}</Text>
      <div className={cx('mt-sm mb-xl', styles.formContainer)}>
        <Tabs>
          <Tabs.TabList className="text-center">
            <Tabs.TabItem index={0}>Map</Tabs.TabItem>
            <Tabs.TabItem index={1}>List</Tabs.TabItem>
          </Tabs.TabList>
          <Tabs.PanelList>
            <Tabs.PanelItem>
              <ComparablesStepMap homes={getHomes()} lastUpdatedOn={initialEstimate?.pricingEstimate?.publishedOn} />
            </Tabs.PanelItem>
            <Tabs.PanelItem>
              <ComparablesStepList
                initialEstimate={initialEstimate}
                higherEstimates={higherEstimates}
                lowerEstimates={lowerEstimates}
                homeMediaValue={homeMediaValue}
                address={address}
                unit={unit}
                formatAddress={formatAddress}
              />
            </Tabs.PanelItem>
          </Tabs.PanelList>
        </Tabs>
      </div>
      <div className="px-sm md:px-0">
        <div className={cx('rounded border border-green border-solid p-md mb-2xl', styles.tipBox)}>
          <div className="flex mb-md items-center">
            <div className="w-[16px] mr-xs">
              <Image src="/common/light.svg" alt="Tip Light" />
            </div>
            <Text fontWeight="semibold">{STRINGS['comparables.tip-title']}</Text>
          </div>
          <Text>{STRINGS['comparables.tip-description']}</Text>
        </div>
      </div>
      <div className={cx('mb-3xl md:mb-2xl px-sm md:px-0', styles.formContainer)}>
        <Form
          initialValues={getInitialValues()}
          onSubmit={handleFormSubmit}
          getForm={({ form }) => (
            <FormLayout>
              <Grid>
                <Row className="mb-2xl">
                  <Field
                    component={InputFinalFormAdapter}
                    name="pricingEstimate.homeownerFeedback"
                    placeholder={STRINGS['comparables.placeholder']}
                  />
                </Row>
                <Row className="flex justify-center">{renderAction(form.getState().values)}</Row>
                <Row className="flex justify-center">
                  <Button className="text-green" buttonType={BUTTON_TYPES.TEXT} type="submit">
                    {STRINGS['comparables.feedback-cta']}
                  </Button>
                </Row>
              </Grid>
            </FormLayout>
          )}
        />
      </div>
    </div>
  );
}
