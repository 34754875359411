import { __spreadArray, __assign } from '../node_modules/tslib/tslib.es6.mjs.js';
import * as React from 'react';
import { useAnalytics } from './analytics-context.js';
import { useTrackingData } from './tracking-context.js';

function useTracking() {
  var parentData = useTrackingData();
  var rootTrack = useAnalytics().track;
  var track = React.useCallback(function (eventType, trackingData) {
    var component = [];
    if (trackingData.component) {
      component = [trackingData.component];
      if (parentData.component) {
        component = __spreadArray(__spreadArray([], parentData.component, true), [trackingData.component], false);
      }
      trackingData.component = component.flat();
    }
    return rootTrack === null || rootTrack === void 0 ? void 0 : rootTrack(eventType, __assign(__assign({}, parentData), trackingData));
  }, [parentData, rootTrack]);
  return track;
}

export { useTracking };
