import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { palette } from 'styles/palette';

const mobileBreakpoint = `@media screen and (max-width: ${BREAKPOINTS_WIDTHS.SM}px)`;

const fontSizes = {
  p3: 10,
  p2: 10,
  p1: 12,
  body: 16,
  h3: 24,
  h2: 36,
  h1: 54,
};

const texts = {
  p3: {
    fontSize: fontSizes.p3,
    lineHeight: '14px',
  },
  p2: {
    fontSize: fontSizes.p2,
    lineHeight: '14px',
  },
  p1: {
    fontSize: fontSizes.p1,
    lineHeight: '20px',
  },
  body: {
    fontSize: fontSizes.body,
    lineHeight: '24px',
  },
  h3: {
    fontSize: fontSizes.h3,
    lineHeight: '32px',
  },
  h2: {
    fontSize: fontSizes.h2,
    lineHeight: '44px',
  },
  h1: {
    fontSize: fontSizes.h1,
    lineHeight: '62px',
  },
};
const breakpoints = ['576px', '768px', '992px', '1200px'];
const theme = {
  useBodyStyles: false,
  breakpoints,
  colors: palette,
  text: {
    ...texts,
    'body-responsive': {
      ...texts.body,
      [mobileBreakpoint]: {
        fontSize: `${texts.p1.fontSize}px !important`,
        lineHeight: `${texts.p1.lineHeight} !important`,
      },
    },
    'p1-responsive': {
      ...texts.p1,
      [mobileBreakpoint]: {
        fontSize: `${texts.p2.fontSize}px !important`,
        lineHeight: `${texts.p2.lineHeight} !important`,
      },
    },
    none: {},
  },
  space: {
    '2xs': 4,
    '-2xs': -4,
    xs: 10,
    '-xs': -10,
    '2sm': 15,
    '-2sm': -15,
    sm: 20,
    '-sm': -20,
    md: 24,
    '-md': -24,
    lg: 30,
    '-lg': -30,
    xl: 40,
    '-xl': -40,
    '2xl': 60,
    '-2xl': -60,
    '3xl': 100,
    '-3xl': -100,
    '4xl': 130,
    '-4xl': -130,
    '5xl': 160,
    '-5xl': -160,
    '6xl': 200,
    '-6xl': -200,
    '7xl': 250,
    '-7xl': -250,
  },
};

export default theme;
