import { ACTIONS_ERROR } from 'store/redux/errorHandling/constants';

export const addPropertyErrorProps = (props) => ({
  type: ACTIONS_ERROR.ADD_PROPERTY_ERROR_PROPS,
  props,
});

export const removeErrorProps = () => ({
  type: ACTIONS_ERROR.REMOVE_ALL_ERRORS,
});
