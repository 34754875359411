import React, { Component } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import styles from './PaymentIconDisplay.module.css';

const cx = classNames.bind(styles);

export default class PaymentIconDisplay extends Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    text: PropTypes.node,
    isNotVerified: PropTypes.bool,
    noCustomWidth: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    text: '',
    isNotVerified: false,
    noCustomWidth: false,
    children: null,
  };

  render() {
    const { className, icon, text, title, isNotVerified, noCustomWidth, children } = this.props;

    return (
      <div className={cx('payment-icon-container', { noCustomWidth })}>
        <span className={cx('icon')}>
          {isNotVerified && (
            <div className={cx('warning-button')}>
              <GeneralIcon icon={GENERAL_ICONS.WARNING} />
            </div>
          )}
          {icon}
        </span>
        <div className={`${cx('payment-icon-display')} ${className}`}>
          <div className={cx('custom-width')}>
            <div className={cx('title')}>{title}</div>
            {text && <div className={cx('fee')}>{text}</div>}
            {children}
          </div>
        </div>
      </div>
    );
  }
}
