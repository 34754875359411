import { PATHS, BELONG_X_STORES, BASE_PATHS } from '@belong/common';

var WHO_WE_ARE_COLUMN_LINKS = [{
  to: PATHS.ABOUT_US,
  label: 'ABOUT'
}, {
  to: PATHS.BLOG,
  label: 'BLOG'
}, {
  to: PATHS.CAREERS,
  label: 'CAREERS'
}, {
  to: PATHS.TERMS,
  label: 'TERMS'
}, {
  to: PATHS.PRIVACY_POLICY,
  label: 'PRIVACY'
}];
var SUPPORT_COLUMN_LINKS = [{
  to: PATHS.SUPPORT,
  label: 'GET HELP'
}, {
  to: PATHS.FAQ,
  label: 'FAQ'
}, {
  to: PATHS.SITEMAP,
  label: 'SITEMAP'
}];
[{
  to: PATHS.BELONG_X,
  label: 'FOR HOMEOWNERS'
}, {
  to: PATHS.BELONG_X_RENTERS,
  label: 'FOR RESIDENTS'
}, {
  to: BELONG_X_STORES.APP_STORE,
  label: 'APPLE APP STORE LINK'
}, {
  to: BELONG_X_STORES.GOOGLE_PLAY,
  label: 'GOOGLE PLAY STORE LINK'
}];
var BELONG_PRO_LINKS = [{
  to: BASE_PATHS.HOMEOWNER,
  label: 'FOR HOMEOWNERS'
}, {
  to: BASE_PATHS.HOME,
  label: 'FOR RESIDENTS'
}, {
  to: "".concat(PATHS.SITEMAP, "/homeowners-coverage-regions"),
  label: 'COVERAGE REGIONS'
}, {
  to: "".concat(PATHS.SITEMAP, "/homeowners-coverage-cities"),
  label: 'COVERAGE CITIES'
}, {
  to: "".concat(PATHS.SITEMAP, "/homeowners-coverage-neighborhoods"),
  label: 'COVERAGE NEIGHBORHOODS'
}, {
  to: PATHS.SITEMAP_HOMES_TO_LOVE,
  label: 'AVAILABLE HOMES'
}, {
  to: PATHS.AFFILIATE,
  label: 'AFFILIATE PROGRAM'
}, {
  to: PATHS.REFERRAL_LANDING,
  label: 'REFER FOR'
}];

export { BELONG_PRO_LINKS, SUPPORT_COLUMN_LINKS, WHO_WE_ARE_COLUMN_LINKS };
