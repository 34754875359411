import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AccountHeader from 'components/AccountHeader/AccountHeader';
import UserBasicInfo from 'models/common/UserBasicInfo';
import PropTypes from 'prop-types';
import { showModal } from 'store/redux/modals/actions';
import { fetchResidentBookmarks, fetchResidentLeases } from 'store/redux/renter-accounts/actions';
import { fetchCurrentUserDetails } from 'store/redux/user/actions';
import {
  selectIfUserFullDetailsApiWasCalled,
  selectUser,
  selectUserClaims,
  selectIsUserLoggedIn,
} from 'store/redux/user/selectors';
import { THEME_CONSTS } from 'themes/themes';
import { DEFAULT_LINKS } from '../../consts/links';
import { SCREENS } from '../../containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from '../../containercomponents/Modals/modal.consts';

class AccountHeaderContainer extends Component {
  async componentDidMount() {
    const {
      fetchCurrentUserDetails: fetchCurrentUserDetailsAction,
      calledApiPreviously,
      userClaims,
      isUserLoggedIn,
      fetchResidentLeases: fetchResidentLeasesAction,
      fetchResidentBookmarks: fetchResidentBookmarksAction,
    } = this.props;

    const requests = [];

    if (!calledApiPreviously && isUserLoggedIn) {
      requests.push(fetchCurrentUserDetailsAction());
    }

    if (userClaims?.isResident) {
      requests.push(fetchResidentLeasesAction({ includeAllResidents: true }));
      requests.push(fetchResidentBookmarksAction());
    }

    try {
      await Promise.all(requests);
    } catch (e) {
      console.error(e);
    }
  }

  async componentDidUpdate(prevProps) {
    const { fetchResidentLeases: fetchResidentLeasesAction, fetchResidentBookmarks: fetchResidentBookmarksAction } =
      this.props;

    if (!prevProps.userClaims?.isResident && this.props.userClaims?.isResident) {
      try {
        await Promise.all([fetchResidentLeasesAction(), fetchResidentBookmarksAction()]);
      } catch (e) {
        console.error(e);
      }
    }
  }

  handleLogin = () => {
    const { showModal: showModalAction, history, user } = this.props;

    showModalAction(MODALS.LOGIN, {
      currentScreen: SCREENS.LOGIN_SCREEN,
      screenProps: {
        ...user,
        emailAddress: user?.email,
      },
      onSucessfulLogin: async () => {
        history.push(DEFAULT_LINKS.ACCOUNT.to);
      },
    });
  };

  render() {
    const { user, history, isUserLoggedIn, theme } = this.props;
    let inAccountsSection = false;

    if (history.location.pathname.includes('accounts')) {
      inAccountsSection = true;
    }

    return (
      <>
        {isUserLoggedIn ? (
          <AccountHeader
            firstName={user.firstName}
            profileImage={user.profileImageUrl}
            isLoggedIn
            inAccountsSection={inAccountsSection}
            theme={theme}
          />
        ) : (
          <AccountHeader onLogin={this.handleLogin} theme={theme} />
        )}
      </>
    );
  }
}

AccountHeaderContainer.propTypes = {
  user: PropTypes.instanceOf(UserBasicInfo),
  showModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchCurrentUserDetails: PropTypes.func.isRequired,
  calledApiPreviously: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  theme: PropTypes.string,
  userClaims: PropTypes.object,
  fetchResidentLeases: PropTypes.func.isRequired,
  fetchResidentBookmarks: PropTypes.func.isRequired,
};

AccountHeaderContainer.defaultProps = {
  user: null,
  calledApiPreviously: false,
  theme: THEME_CONSTS.DEFAULT,
  userClaims: {},
};

export default withRouter(
  connect(
    (state) => ({
      user: selectUser(state),
      calledApiPreviously: selectIfUserFullDetailsApiWasCalled(state),
      userClaims: selectUserClaims(state),
      isUserLoggedIn: selectIsUserLoggedIn(state),
    }),
    {
      showModal,
      fetchCurrentUserDetails,
      fetchResidentBookmarks,
      fetchResidentLeases,
    }
  )(AccountHeaderContainer)
);
