import config from 'config/config';

export const uploadDocumentToS3 = async (dispatch, file, headers, uploadUrl) => {
  const requestHeaders = {};
  headers.forEach((header) => {
    requestHeaders[header.key] = header.value;
  });
  await dispatch({
    promise: ({ client }) =>
      client.put(uploadUrl, file, {
        headers: requestHeaders,
      }),
  });
};

// Using fetch instead of client because we want this to be keepalive: true, which axios doesn't support
export const trackSiteVisit = (trackingId, jwtToken, partnerKey) => {
  const headers = { 'content-type': 'application/json' };

  if (jwtToken) {
    headers.authorization = `Bearer ${jwtToken}`;
  }

  if (trackingId) {
    headers.trackingId = trackingId;
  }

  const _config = {
    method: 'POST',
    keepalive: true,
    body: JSON.stringify({
      trackingId,
      url: window.location.href,
      referrer: document.referrer,
      encodedPartnerKey: partnerKey,
    }),
    headers,
  };

  return window.fetch(`${config.apiUrl}/sitevisit`, _config);
};
