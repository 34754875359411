import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/redux/user/selectors';

function loadScript(url, attributes: { id: string }, onLoadCallback) {
  if (document.getElementById(attributes.id)) {
    return onLoadCallback();
  }

  const s = document.createElement('script');
  s.setAttribute('src', url);

  Object.entries(attributes).forEach(([key, val]: [string, string]) => {
    s.setAttribute(key, val);
  });

  if (onLoadCallback) {
    s.addEventListener('load', onLoadCallback);
  }
  document.body.appendChild(s);
}

export function useIntercom(intercomId: string, initializeOpen = false) {
  const user = useSelector(selectUser);

  useEffect(() => {
    loadScript(`https://widget.intercom.io/widget/${intercomId}`, { id: 'icom-snippet' }, () => {
      const userData = user
        ? {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            user_id: user.userId,
            user_hash: user.webIntercomUserHmac,
          }
        : {};

      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: intercomId,
          ...userData,
        });

        if (initializeOpen) {
          window.Intercom('showMessages');
        }
      }
    });
  }, [intercomId, user, initializeOpen]);
}
