import { getApiActionTypes } from '../redux_utils';

export const ACTIONS = {
  UPDATE_APPLICATION_ID_APP_STATE: 'UPDATE_APPLICATION_ID_APP_STATE',
  UPDATE_APPLY_FOR_UNIT_ID: 'UPDATE_APPLY_FOR_UNIT_ID',
  UPDATE_MUST_CALL_COMPLETE_ENDPOINT: 'UPDATE_MUST_CALL_COMPLETE_ENDPOINT',
  // get : '/applicants/{email}/applications/{applicationId}/units-applied'
  ...getApiActionTypes('GET_HOUSE_IDS'),
  // get : '/residents/applications'
  ...getApiActionTypes('FETCH_ALL_RESIDENT_APPLICATIONS'),
  // get : '/residents/applications/{applicationId}'
  ...getApiActionTypes('FETCH_MOVE_IN_DATE'),
  // : '/residents/applications/{applicationId}/complete'
  ...getApiActionTypes('UPDATE_APPLICATION_FLOW_COMPLETE'),
  // : 'resident/applications/move-in-date'
  ...getApiActionTypes('CREATE_MOVE_IN_DATE'),
  // get : 'resident/applications/{applicationId}/residents'
  ...getApiActionTypes('FETCH_PEOPLE_AND_PETS'),
  // : 'resident/applications/{applicationId}/residents'
  ...getApiActionTypes('UPDATE_PEOPLE_AND_PETS'),
  // get : 'resident/applications/{applicationId}/applicant-info'
  ...getApiActionTypes('FETCH_APPLICANT_INFO'),
  // : 'resident/applications/{applicationId}/background-check-info'
  ...getApiActionTypes('UPDATE_LEGAL_IDENTITY'),
  // : 'resident/applications/{applicationId}/application-fee'
  ...getApiActionTypes('CREATE_APPLICATION_FEE'),
  // : 'resident/employment-status'
  ...getApiActionTypes('FETCH_EMPLOYMENT_INFORMATION'),
  ...getApiActionTypes('UPDATE_EMPLOYMENT_INFORMATION'),
  ...getApiActionTypes('FETCH_COSIGNER_INFORMATION'),
  ...getApiActionTypes('UPDATE_COSIGNER_INFORMATION'),
  // : 'resident/applications/{applicationId}/income-verification'
  ...getApiActionTypes('UPDATE_INCOME_VERIFICATION'),
  // get : 'residents/income-verification'
  ...getApiActionTypes('FETCH_APPLICANT_INCOME_VERIFICATION'),
  // get : 'residents/reference'
  ...getApiActionTypes('FETCH_APPLICANT_REFERENCE_INFO'),
  // : 'resident/applications/{applicationId}/reference-check'
  ...getApiActionTypes('UPDATE_REFERENCE_CHECK'),
  // : 'resident/leads'
  ...getApiActionTypes('CREATE_MOVE_IN_DATE_LEAD'),
  // 'residents/applications/self-serve-lease-details'
  ...getApiActionTypes('FETCH_APPLICATION_SELF_SERVE_DETAILS'),
  // 'residents/applications/{applicationId}/listing-applications/{listingApplicationId}/self-serve'
  ...getApiActionTypes('CREATE_SELF_SERVE_LEASE'),
  ...getApiActionTypes('FETCH_LEASE_LENGTH'),
};
