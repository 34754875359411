export default class UploadedMedia {
  constructor(data = {}) {
    this.id = data.id;
    this.uniqueId = data.uniqueId;
    this.contentType = data.contentType;
    this.name = data.name;
    this.url = data.url;
    this.mediaType = data.mediaType;
  }
}
