export const meta = {
  homepage: {
    title: 'Renting People Love | For Homeowners and Residents',
    description: "Renting is the new American lifestyle. We didn't create it. But we're making it sing.",
  },
  housepage: {
    title: '',
    description: '',
  },
  howitworks: {
    title: 'Meet Jane. | How Belong Works',
    description:
      "Jane's got a lot on her plate. She moved to a new city. She’s working like crazy. And she’s got this home she needs to rent out.",
  },
  'pro-services': {
    title: 'Pro Services | In-House Construction & Maintenance',
    description:
      'Appliances • Interior Plumbing • Walls • Electrical • Heating, Cooling & Ventilation • Pest Control • Doors & Windows • Security • Fire & Gas Prevention • Gardens & Landscaping • Flooring • Exterior Guttering & Drainage • Decks, Balconies & Exterior Staircases • Roofs • Interior Storage & Decor • Hauling & Disposal',
  },
  'lease-signing': {
    title: '{address} | Love',
    description: "We can't wait to welcome you to the Belong community!",
  },
};
