import React from 'react';
import { Analytics, ANALYTICS_CLICK_ACTIONS } from 'analytics';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES } from 'components/Button/Button';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Image from 'components/Image/Image';
import Carousel from 'components/v2/Carousel/Carousel';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Text, Flex, Box } from 'design-system';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import { HOMEOWNER_SELL_STRINGS } from 'strings/homeowner-sell.strings';
import styles from './SellHome.module.css';
import { SoldComponent } from './SoldComponent/SoldComponent';

const cx = classNames.bind(styles);

const informationImages = [
  '/sell_home/sell_account_image_01.png',
  '/sell_home/sell_account_image_02.png',
  '/sell_home/sell_account_image_03.png',
  '/sell_home/sell_account_image_05.png',
  '/sell_home/sell_account_image_04.png',
  '/sell_home/sell_account_image_06.png',
];

const HOS = HOMEOWNER_SELL_STRINGS;
function InformationBlock({ index, thumbnail, text }) {
  return (
    <Box width={[1, 1 / 3]} mb={0} padding={['sm', 'md']} pt="xs">
      <div className={cx('informationBlock', `block${index}`)}>
        <Image src={thumbnail} alt={text} className={cx('informationImage')} />
        <Text textAlign="center" as="p" fontSize="body" fontWeight="semibold">
          {text}
        </Text>
      </div>
    </Box>
  );
}

InformationBlock.propTypes = {
  thumbnail: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number,
};

function SellHomeComponent({ homeownerProperties }) {
  const { basicInfo, units } = homeownerProperties[0];
  const bannerImageUrl = basicInfo.bannerImageUrl || units[0].basicInfo.bannerImageUrl;

  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;
  return (
    <div className={cx('sellHome')}>
      <div className={cx('title')}>
        <Text as="h2" fontSize="h2" fontWeight="semibold">
          {HOS.title}
        </Text>
        <Text as="p" fontSize="body">
          {HOS.brokerage_fee}
        </Text>
      </div>
      <div className={cx('bannerContainer')}>
        <div className={cx('soldIcon')}>
          <SoldComponent />
        </div>

        <Image src={bannerImageUrl ?? '/home/ho_account_sell_bg.svg'} alt="Sell Home" className={cx('banner')} />
      </div>
      <div className={cx('contact')}>
        <Flex flexDirection="row" flexWrap="wrap">
          <Box as="div" width={[1, 2 / 3]} mb={['md', 0]}>
            <Text as="p" fontSize="body">
              {HOS.contact}
            </Text>
            <Text as="p" fontSize="body" fontWeight="semibold">
              {HOS.email}
            </Text>
          </Box>
          <Box as="div" width={[1, 1 / 3]} className={cx('buttonContainer')}>
            <Button
              action={ANALYTICS_CLICK_ACTIONS.INQUIRE_NOW}
              size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
              label={HOS.inquire_now}
              href={`mailto:${HOS.email}?subject=${HOS.subject}`}
            />
          </Box>
        </Flex>
      </div>
      <div className={cx('sellInformation')}>
        <Text as="h3">{HOS.subtitle}</Text>
        {!isMobile && (
          <Flex flexDirection="row" flexWrap="wrap">
            {informationImages.map((thumbnail, index) => (
              <InformationBlock index={index} thumbnail={thumbnail} text={HOS.information[index]} key={thumbnail} />
            ))}
          </Flex>
        )}

        {isMobile && (
          <Carousel
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderBottomCenterControls={(something) => {
              const children = [];

              for (let i = 0; i < something.slideCount; i += 1) {
                const childListComponent = (
                  <li key={i} className={cx('position-list-item', { currentSlide: something.currentSlide === i })}>
                    <ButtonBase onClick={() => something.goToSlide(i)}>&#183;</ButtonBase>
                  </li>
                );
                children.push(childListComponent);
              }

              return <ul className={cx('position-list-wrapper')}>{children.map((child) => child)}</ul>;
            }}
          >
            {informationImages.map((thumbnail, index) => (
              <InformationBlock thumbnail={thumbnail} text={HOS.information[index]} key={thumbnail} index={index} />
            ))}
          </Carousel>
        )}
      </div>
      <Flex className={cx('footer')} mt={['lg', 'sm']} pt="sm">
        <Box className={cx('right')}>
          <div className={cx('translateDown')}>
            <GeneralIcon scale={0.5} icon={GENERAL_ICONS.EQUAL_HOUSING} />
          </div>
          <div className={cx('padding-left')}>
            <Text as="p" fontSize="p1">
              CA DRE License #02082345
            </Text>
          </div>
        </Box>
      </Flex>
    </div>
  );
}

SellHomeComponent.propTypes = {
  homeownerProperties: PropTypes.array,
};

export const SellHome = Analytics(() => ({
  page: 'sell_home',
}))(SellHomeComponent);
