import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { PATHS } from '@belong/common';
import classNames from 'classnames/bind';
import RenterLeaseSummaryContainer from 'containers/RenterLeaseSummaryContainer/RenterLeaseSummaryContainer';
import RenterAccountProperty from 'models/renterAccounts/RenterAccountLease';
import RenterLeaseDetail from 'models/renterAccounts/RenterLeaseDetail';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import PropTypes from 'prop-types';
import { selectAccountAllLeases } from 'store/redux/renter-accounts/selectors';
import { getLeaseStatus } from 'utils/formateDate/getTimeForLeasingStatus';
import { LEASE_STATUS } from '../../../../../utils/formateDate/getTimeForLeasingStatus';
import Lease from './Lease/Lease';
import styles from './Leases.module.css';

const cx = classNames.bind(styles);

class Leases extends Component {
  render() {
    const {
      selectAccountAllLeases: allLeases,
      match: {
        params: { propertyId },
      },
    } = this.props;

    if (propertyId) {
      return (
        <Route
          path={`${PATHS.RESIDENTS_ACCOUNT_PROPERTIES}/:unitId/:leaseId`}
          component={() => <Lease {...this.props} />}
        />
      );
    }

    return (
      <AccountPage
        headerProps={{
          headerText: 'My Home',
        }}
      >
        <AccountPageSection
          content={
            <>
              {allLeases.map((lease) => {
                if (getLeaseStatus(lease) === LEASE_STATUS.CURRENT) {
                  return (
                    <div className={cx('lease')}>
                      <RenterLeaseSummaryContainer status="Current" lease={lease} key={lease.leaseInfo?.leaseId} />
                    </div>
                  );
                }

                return null;
              })}
              {allLeases.map((lease) => {
                if (getLeaseStatus(lease) === LEASE_STATUS.FUTURE) {
                  return (
                    <div className={cx('lease')}>
                      <RenterLeaseSummaryContainer status="Future" lease={lease} key={lease.leaseInfo?.leaseId} />
                    </div>
                  );
                }

                return null;
              })}

              {allLeases.map((lease) => {
                if (getLeaseStatus(lease) === LEASE_STATUS.PREVIOUS) {
                  return (
                    <div className={cx('lease')}>
                      <RenterLeaseSummaryContainer status="Previous" lease={lease} key={lease.leaseInfo.leaseId} />
                    </div>
                  );
                }

                return null;
              })}
            </>
          }
        />
      </AccountPage>
    );
  }
}

Leases.propTypes = {
  findUnitAddressWithLeaseDetails: PropTypes.instanceOf(RenterAccountProperty),
  currentUnitWithLeaseDetails: PropTypes.instanceOf(RenterLeaseDetail),
  selectAccountAllLeases: PropTypes.arrayOf(PropTypes.instanceOf(RenterAccountProperty)),
  match: PropTypes.object.isRequired,
};

Leases.defaultProps = {
  currentUnitWithLeaseDetails: null,
  findUnitAddressWithLeaseDetails: null,
  selectAccountAllLeases: [],
};

function mapStateProps(state) {
  return {
    selectAccountAllLeases: selectAccountAllLeases(state),
  };
}

export default connect(mapStateProps)(Leases);
