import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Measure from 'react-measure';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { getDocumentScrollTop, setDocumentScrollTop } from '../../utils/scrollTop';
import { GENERAL_ICONS } from '../GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from '../IconButton/IconButton';
import styles from './MobileOverlay.module.css';

const cx = classNames.bind(styles);

const OVERLAY_WIDTH = 992;
const overflowThreshold = 120;

class MobileOverlay extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onHide: PropTypes.func,
    relative: PropTypes.bool.isRequired,
    absoluteMobileOverlay: PropTypes.bool,
  };

  static defaultProps = {
    onHide: () => {},
    absoluteMobileOverlay: false,
  };

  state = {
    modalHeight: 0,
  };

  componentDidUpdate() {
    const { show } = this.props;

    if (window.innerWidth <= OVERLAY_WIDTH) {
      if (show) {
        if (!this.scrollPosition) {
          this.scrollPosition = getDocumentScrollTop();
          document.body.style.cssText += `;top: -${this.scrollPosition}px; position: fixed;`;
        }
      } else {
        document.body.style.position = 'static';

        if (this.scrollPosition) {
          setDocumentScrollTop(this.scrollPosition);
          this.scrollPosition = 0;
        }
      }
    }
  }

  renderCloseButton() {
    const { onHide } = this.props;

    return (
      <div className={cx('close-button')}>
        <IconButton onClick={onHide} icon={GENERAL_ICONS.CLOSE} align={ALIGN_TYPES.NONE} />
      </div>
    );
  }

  renderOverlay() {
    const { children, relative, absoluteMobileOverlay } = this.props;
    const { modalHeight } = this.state;
    return (
      <div className={cx('overlay', { absoluteMobileOverlay }, { relative })}>
        {this.renderCloseButton()}
        <Measure
          bounds
          onResize={({ bounds }) => {
            this.setState({ modalHeight: bounds.height });
          }}
        >
          {({ measureRef }) => {
            const windowHeight = window.innerHeight;
            let overflow = false;

            if (modalHeight >= windowHeight - overflowThreshold) {
              overflow = true;
            }

            const style = overflow ? {} : { top: (windowHeight - modalHeight) / 2 };

            return (
              <div className={cx('content', { overflow })} style={style}>
                <div ref={measureRef} className={cx('content-wrapper')}>
                  {children}
                </div>
              </div>
            );
          }}
        </Measure>
      </div>
    );
  }

  render() {
    const { show } = this.props;

    if (!show) {
      return null;
    }

    if (window.innerWidth <= OVERLAY_WIDTH) {
      return ReactDOM.createPortal(this.renderOverlay(), document.body);
    }

    return this.renderOverlay();
  }
}

export default MobileOverlay;
