import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { IconRightCaret, IconInfo } from '@belong/icons';
import { Money, Text, Tooltip } from '@belong/ui';
import { GroupedBalancesInfoModel, BillStatusInfo, BillingInfoModel } from 'api/models.v2';
import cx from 'classnames';
import { getBalanceSubtitle, getBalanceSubtitleStyles } from 'pages/Accounts/utils/get-balance-subtitle';
import { EARNINGS_STRINGS } from '../../Pages/YourPortfolio/Earnings/earnings.strings';
import { BalanceItemIcon } from '../balance-item-icon/balance-item-icon';

type BalanceItemProps = {
  address: string;
  billStatusInfo?: BillStatusInfo;
  billingInfo?: BillingInfoModel;
  isResident?: boolean;
  navigateToFinanceDetails: (id: string | number, balance: GroupedBalancesInfoModel, home: string) => void;
  handleShowBalanceDetailsModal?: (
    balance: GroupedBalancesInfoModel,
    home: string,
    billingInfo: BillingInfoModel,
    standalone?: boolean,
    isAutoSplittable?: boolean
  ) => void;
  noBorder?: boolean;
  hideSplitIt?: boolean;
  isFastMaintenanceEnabled: boolean;
  isAutoSplittable?: boolean;
  balanceItem: GroupedBalancesInfoModel;
};

export function BalanceItem({
  navigateToFinanceDetails,
  handleShowBalanceDetailsModal,
  address,
  isAutoSplittable,
  billingInfo,
  billStatusInfo,
  isFastMaintenanceEnabled,
  hideSplitIt = false,
  isResident = false,
  noBorder = false,
  balanceItem,
}: BalanceItemProps) {
  const { sourceType, sourceKey, description, netBalance, isFinanceable, balances, isDelayed } = balanceItem;
  const { width } = useWindowSize();

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  function handleFinanceItem(id: string, balance: GroupedBalancesInfoModel) {
    if (sourceType === 'Finance') {
      navigateToFinanceDetails(id, balance, address);
    }

    if (sourceType === 'GroupKey' || sourceType === 'Balance') {
      if (handleShowBalanceDetailsModal) {
        handleShowBalanceDetailsModal(balance, address, billingInfo, false, isAutoSplittable);
      }
    }
  }

  return (
    <button
      className={cx(
        'w-full flex items-center justify-between mb-2xs p-2xs pl-0 lg:pl-xs hover:bg-[#F3FDFA] rounded-[10px] cursor-pointer',
        noBorder && 'pl-0 ml-0'
      )}
      onClick={() =>
        handleFinanceItem(sourceKey, { ...balanceItem, balances: sourceType === 'Balance' ? [balanceItem] : balances })
      }
      type="button"
    >
      <div>
        <div className="flex items-center">
          <Text className="text-left">{description}</Text>
          {!isResident && !hideSplitIt && <BalanceItemIcon isFinanceable={isFinanceable} className="ml-xs p-2xs" />}
          {isDelayed && (
            <Text
              variant="p1"
              className="text-white ml-xs px-xs bg-red rounded-small"
            >{`${EARNINGS_STRINGS['delayed.label']}`}</Text>
          )}
        </div>
        {isFastMaintenanceEnabled && (
          <div className="flex gap-xs">
            <Text variant="p1" className={cx('text-left', getBalanceSubtitleStyles(billStatusInfo, isDelayed))}>
              {getBalanceSubtitle(balanceItem)}
            </Text>
            {balanceItem.isDueDateAnEstimation && (
              <Tooltip
                arrowProps={{ className: 'fill-dark-navy' }}
                contentProps={{ side: isMobile ? 'top' : 'right' }}
                content={
                  <div className="p-2sm rounded bg-dark-navy max-w-md">
                    <Text className="text-white text-left" variant="p1" fontWeight="semibold">
                      {balanceItem.isDelayed
                        ? EARNINGS_STRINGS['due_date_delayed.tooltip']
                        : EARNINGS_STRINGS['due_date_estimation.tooltip']}
                    </Text>
                  </div>
                }
              >
                <button className="cursor-pointer fill-navy" type="button" onClick={(e) => e.stopPropagation()}>
                  <IconInfo width={20} />
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className="flex items-center justify-end ml-2xs md:ml-0">
        <Money className="text-body font-semibold" value={netBalance} format="DOLLARS_WITH_CENTS" />
        <IconRightCaret height={15} className="ml-xs" />
      </div>
    </button>
  );
}
