export const ACCOUNTS_EARNINGS = {
  'page.title': 'Earnings',
  'section.bank.title': 'Account',
  'section.transaction.title': 'Transactions',
  'section.transaction.no_history': 'No Payments Yet',
  'section.split.title': (cashback) => `Need an extra <span>${cashback}</span> this\u00a0month?`,
  'section.split.subtitle': () => `Split eligible expenses.`,
  'section.split.action': 'Split It',
  'section.split.actionPay': 'Pay It',
  'section.split.zero': `Looking to <span>split</span> these into multiple payments? `,
  'section.split.zeroSub': (date) => `You can do so by <span>${date}</span>`,
  'section.split.auto': 'We will automatically split eligible expenses for you or you can create your own custom plan.',
};
