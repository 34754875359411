var TESTIMONIALS = [{
  imgSrc: '/testimonials/testimonials_video_01.jpg',
  videoSrc: 'https://belong-web-assets.s3.us-west-2.amazonaws.com/home_page/belong_testimonial_natalie_clean_final_small.mp4',
  name: 'Natalee C.',
  description: 'Belong Resident',
  body: 'One of the things that frankly really sold me on Belong was the concierge level services… They were just top-notch, first-class service. They just take care of things. It’s like having a second husband to just do the work for you!'
}, {
  imgSrc: '/testimonials/testimonials_video_04.jpg',
  videoSrc: 'https://belong-consumer-assets.s3.us-west-1.amazonaws.com/testimonials/Belong_Testimonial_David_Final_H.264_small.mp4',
  name: 'David P.',
  description: 'Belong Homeowner',
  body: "My relationship with Belong is that of a true partnership. You don’t have to really worry about anything. Knowing that Belong looks out for my best interests, as well as the resident's best interest really provides a comfort for me."
}, {
  imgSrc: '/testimonials/testimonials_video_02.jpg',
  videoSrc: 'https://belong-web-assets.s3.us-west-2.amazonaws.com/home_page/belong_testimonial_annette_final_clean_small.mp4',
  name: 'Annette P.',
  description: 'Belong Resident',
  body: 'I realized belong was unique when I saw the care and attention that they gave. They were always following up, making sure our experience was going well.'
}, {
  imgSrc: '/testimonials/testimonials_video_03.jpg',
  videoSrc: 'https://belong-consumer-assets.s3.us-west-1.amazonaws.com/testimonials/Belong_Testimonial_Sandra_Final_H.264_small.mp4',
  name: 'Sandra G.',
  description: 'Belong Homeowner',
  body: "Knowing my home is loved and cared for by my residents is something that I really appreciate because I love my home and I love sharing it with somebody else…. As a homeowner, when I say that Belong handles everything, Belong really handles everything for me. So I just contact my concierge, and that's all I have to do."
}, {
  imgSrc: '/testimonials/testimonials_41.jpg',
  name: 'Frank',
  description: 'Belong Homeowner',
  body: "I can't believe how seamless you make it for the landlords.  Why would anyone want a different management company?  Belong is amazing, and I can see how you guys will be extremely successful now and in the future.  I will always recommend Belong to my family and friends :)"
}, {
  imgSrc: '/testimonials/testimonials_21.jpg',
  name: 'Peter M. & Joana B.',
  description: "Move-In Day Jul '20",
  body: 'We are so happy we went with Belong. Being Canadian and trying to rent a place in California was a big headache before working with Belong. They were professional, courteous and took care of us every step of the way. Communication was clear and prompt. Their website is also a breeze to use. So glad we went with Belong.'
}, {
  imgSrc: '/testimonials/testimonials_11.jpg',
  name: 'Justin H. & Jessica A.',
  description: "Move-In Day Mar '19",
  body: 'Fast responses, friendly conversations both over the phone and in person, overall a really nice experience. We spoke with Erin for our interview and she was both friendly and extremely well informed. I had some hesitations about sharing my personal info early in the process, but interacting with the Belong staff helped me get more comfortable.'
}, {
  imgSrc: '/testimonials/testimonials_14.jpg',
  name: 'Jeff S.',
  description: 'Belong Resident',
  body: 'We are always more than satisfied with the customer service that Belong very clearly holds as a gold standard!  Responsive in every way.  Belong most definitely takes customer service to the next level.  The Pro Team is top-notch.  They make you feel like you are family.  Thanks Belong & the absolute best Pro Team out there!'
}, {
  imgSrc: '/testimonials/testimonials_34.jpg',
  name: 'Ann R.',
  description: "Working with Belong since Aug '20",
  body: "Jordan was exceptional in expediting phone calls/emails to me.  He answered all my questions without any hesitation and seems to know what he's talking about.  He has a very calm demeanor in addressing my concerns which is one of the reasons why I decided to go with Belong."
}, {
  imgSrc: '/testimonials/testimonials_25.jpg',
  name: 'Huiyuan Z.',
  description: "Move-In Day Dec '20",
  body: 'The service from Belong is really professional. They answered and solved all of our questions and concerns. Before moving in, their strict checking is responsible for both the homeowner and resident.'
}, {
  imgSrc: '/testimonials/testimonials_07.jpg',
  name: 'Lauren S.',
  description: "Move-In Day Sep '19",
  body: 'Thank you for all of these details (regarding move in) - everything sounds so great and the level of responsiveness from you and the team is incredible. :)'
}, {
  imgSrc: '/testimonials/testimonials_09.jpg',
  name: 'Jennifer W.',
  description: "Move-In Day Nov '19",
  body: 'Our encounters with everyone at Belong thus far have been great! The team has been responsive and helpful as well as incredibly nice.'
}, {
  imgSrc: '/testimonials/testimonials_12.jpg',
  name: 'Thibault H.',
  description: "Move-In Day Sep '19",
  body: 'It was a pleasure to live in a Belong house. We were very well accompanied during our journey in the Bay Area. Thank you and all your team for that.'
}, {
  imgSrc: '/testimonials/testimonials_13.jpg',
  name: 'Amy M.',
  description: "Move-In Day May '20",
  body: 'Always a fast response to a question or an issue. Super friendly and polite people through email and on fix-it visits. Great, user-friendly payments and requests.'
}, {
  imgSrc: '/testimonials/testimonials_17.jpg',
  name: 'Sand N. & Amani F.',
  description: "Move-In Day Aug '20",
  body: 'I raised an issue with the fridge service which was promptly responded to. A Pro was dispatched and within a week the parts arrived and the refrigerator was back up running.'
}, {
  imgSrc: '/testimonials/testimonials_03.jpg',
  name: 'Katie M. & Brad S.',
  description: "Move-In Day Jan '20",
  body: 'We have absolutely loved every second in this home... This is a very special property and the next tenants will be lucky to have found it!'
}, {
  imgSrc: '/testimonials/testimonials_27.jpg',
  name: 'Victor R.',
  description: "Handed over the keys Nov '20",
  body: 'Francisco was extremely personable and took the time to overlook everything while also building positive rapport. Great experience.'
}, {
  imgSrc: '/testimonials/testimonials_36.jpg',
  name: 'Rick W.',
  description: 'Belong Homeowner',
  body: 'Incredible team effort!  From the initial inspection, repairs, marketing, to the resident moving in. We found the entire process amazing!'
}, {
  imgSrc: '/testimonials/testimonials_29.jpg',
  name: 'Ricardo B.',
  description: "Working with Belong since Oct '20",
  body: 'Francisco was very friendly. He explained what he was doing in detail and gave us an idea of his recommendations.'
}, {
  imgSrc: '/testimonials/testimonials_06.jpg',
  name: 'Britney & Sam H.',
  description: "Move-In Day Sep '19",
  body: 'Everyone was helpful, super on top of things, and really polite! It’s rare that you get great customer service like this.'
}, {
  imgSrc: '/testimonials/testimonials_08.jpg',
  name: 'Carlo C. & Erica F.',
  description: "Move-In Day Nov '19",
  body: 'Francisco was awesome. Very discreet and professional, he got everything fixed in a couple of hours.'
}, {
  imgSrc: '/testimonials/testimonials_18.jpg',
  name: 'Bob D.',
  description: "Move-In Day Aug '20",
  body: 'Francesca worked with me and the homeowner to arrive at a wonderful resolution to a potential pet issue.'
}, {
  imgSrc: '/testimonials/testimonials_26.jpg',
  name: 'Travis H.',
  description: "Move-In Day Sep '20",
  body: "Belong was very helpful and communicative during the leasing process and I'm excited to move-in!"
}, {
  imgSrc: '/testimonials/testimonials_30.jpg',
  name: 'Karen G.',
  description: "Working with Belong since Dec '20",
  body: 'John is very thorough, and thanks to him there should be no issues for the renters. '
}, {
  imgSrc: '/testimonials/testimonials_29.jpg',
  name: 'Joanna R.',
  description: 'Belong Homeowner',
  body: 'Everyone cares and does more than you expect... you feel like a family member, not a customer!'
}, {
  imgSrc: '/testimonials/testimonials_24.jpg',
  name: 'Imaru W.',
  description: "Move-In Day Dec '20",
  body: 'The maintenance man came and took care of his job in a timely manner!!'
}, {
  imgSrc: '/testimonials/testimonials_10.jpg',
  name: 'Jukka K.',
  description: "Move-In Day Feb '19",
  body: 'You reacted immediately and the issue was fixed very quickly. Thank you.'
}, {
  imgSrc: '/testimonials/testimonials_33.jpg',
  name: 'Paul C.',
  description: "Working with Belong since Nov '20",
  body: 'Doug was very friendly, did a great job with the inspection, and finished everything in a timely manner!'
}, {
  imgSrc: '/testimonials/testimonials_35.jpg',
  name: 'Silvia O.',
  description: "Working with Belong since Nov '20",
  body: 'Jordan has been super helpful guiding me through the process.'
}, {
  imgSrc: '/testimonials/testimonials_37.jpg',
  name: 'Jeanette G.',
  description: "Working with Belong since Oct '20",
  body: 'Dion was very helpful in answering all of my questions.'
}, {
  imgSrc: '/testimonials/testimonials_39.jpg',
  name: 'Neeraj M.',
  description: "Working with Belong since Nov '20",
  body: 'Douglas was very professional and made me very comfortable.'
}, {
  imgSrc: '/testimonials/testimonials_28.jpg',
  name: 'Soham P.',
  description: "Working with Belong since Nov '20",
  body: 'Great experience. David is very personable and articulate.'
}, {
  imgSrc: '/testimonials/testimonials_20.jpg',
  name: 'Ankit A.',
  description: "Move-In Day Sep '20",
  body: 'Very friendly, responded quickly to all of my queries.'
}, {
  imgSrc: '/testimonials/testimonials_22.jpg',
  name: 'Elisa A.',
  description: "Move-In Day Nov '20",
  body: 'All my questions were answered clearly.'
}, {
  imgSrc: '/testimonials/testimonials_23.jpg',
  name: 'Mary K.',
  description: "Move-In Day Nov '20",
  body: 'My Belong Pro was great - went above and beyond.'
}, {
  imgSrc: '/testimonials/testimonials_38.jpg',
  name: 'Claudio D.',
  description: "Working with Belong since Nov '20",
  body: 'Douglas was great'
}, {
  imgSrc: '/testimonials/testimonials_40.jpg',
  name: 'Qiang Y.',
  description: "Working with Belong since Nov '20",
  body: 'Professional and friendly'
}, {
  imgSrc: '/testimonials/testimonials_04.jpg',
  name: 'Tim S.',
  description: "Move-In Day Oct '19",
  body: 'Trustworthy, convenient, reliable, helpful'
}, {
  imgSrc: '/testimonials/testimonials_05.jpg',
  name: 'Kim H.',
  description: "Move-In Day June '19",
  body: 'Extremely responsive and flexible'
}, {
  imgSrc: '/testimonials/testimonials_15.jpg',
  name: 'Yangwei L.',
  description: "Move-In Day June '20",
  body: 'Fast responses, professional.'
}, {
  imgSrc: '/testimonials/testimonials_19.jpg',
  name: 'Kartik V.',
  description: "Move-In Day Sep '20",
  body: 'Fast responses to inquiries'
}];
var VIDEO_TESTIMONIALS = TESTIMONIALS.filter(function (testimonial) {
  return testimonial.videoSrc;
});
var TEXT_TESTIMONIALS = TESTIMONIALS.filter(function (testimonial) {
  return !testimonial.videoSrc;
});

export { TESTIMONIALS, TEXT_TESTIMONIALS, VIDEO_TESTIMONIALS };
