import { twMerge } from '@belong/common';
import { ButtonBase, Image, Text } from '@belong/ui';
import type { MaintenanceRecurrenceModel } from 'api/models';
import clsx from 'clsx';
import Money from 'components/Money/Money';
import { startCase } from 'es-toolkit';
import { TEXT_COLOR_BY_RECURRING_TASK_STATUS } from 'maintenance/constants';
import { STRINGS } from 'maintenance/strings';
import type { ProServiceView } from 'maintenance/types';
import {
  getFormattedFrequency,
  getRecurringTaskDateByStatus,
  getRecurringTaskImageByRecurringTask,
  getRecurringTaskPricing,
  getRecurringTaskStatus,
} from 'maintenance/utils';
import styles from './recurring-task-item.module.css';

type Props = {
  onClick: (task: MaintenanceRecurrenceModel) => () => void;
  task: MaintenanceRecurrenceModel;
  view?: ProServiceView;
};

export function RecurringTaskItem({ onClick, task, view = 'homeOwner' }: Props) {
  const imageSrc = getRecurringTaskImageByRecurringTask(task.recurringTask);

  const pricing = getRecurringTaskPricing({ task, view });

  return (
    <ButtonBase
      className={twMerge(
        'rounded border border-gray border-solid flex items-center p-xs sm:px-sm sm:py-xs hover:bg-light-gray hover:border-navy w-full transition-colors duration-hover',
        styles.wrapper
      )}
      onClick={onClick(task)}
    >
      <div className="flex flex-col items-stretch lg:flex-row lg:gap-sm lg:items-center w-full">
        <div className="flex items-center w-full lg:w-1/3 shrink-0">
          <div className="mr-sm">
            {imageSrc && (
              <div className={styles.image}>
                <Image alt="" src={imageSrc} />
              </div>
            )}
          </div>
          <div className="w-11/12 lg:w-8/12 text-left">
            <Text className="line-clamp-2" fontWeight="semibold">
              {startCase(task.summary)}
            </Text>
            <div className="flex flex-col lg:hidden">
              <Text variant="p1">{getRecurringTaskDateByStatus({ task })}</Text>
              <Text
                className={TEXT_COLOR_BY_RECURRING_TASK_STATUS[task.recurringServiceStatus]}
                fontWeight="semibold"
                variant="p1"
              >
                {getRecurringTaskStatus({ task })}
              </Text>
            </div>
          </div>
        </div>
        <div className="pt-xs lg:pt-0 flex justify-between lg:justify-start w-full lg:w-1/5 items-center shrink-0">
          <div className="bg-navy px-xs rounded-small">
            <Text className="text-white" fontWeight="semibold" variant="p1">
              {getFormattedFrequency(task.repeatFrequencyDays)}
            </Text>
          </div>
          <Text className={clsx('text-right lg:hidden', styles.taskPricing)}>
            {typeof pricing === 'number' ? (
              <>
                {STRINGS.est}
                &nbsp;
                <Money cash={pricing} />
              </>
            ) : (
              <>{pricing}</>
            )}
          </Text>
        </div>
        <div className="hidden lg:flex lg:flex-col lg:justify-start lg:text-left lg:w-1/4 lg:shrink-0">
          <Text className={TEXT_COLOR_BY_RECURRING_TASK_STATUS[task.recurringServiceStatus]} fontWeight="semibold">
            {getRecurringTaskStatus({ task })}
          </Text>
          <Text variant="p1">{getRecurringTaskDateByStatus({ task })}</Text>
        </div>
        <div className="hidden lg:flex lg:items-center lg:justify-end lg:flex-1">
          <Text className="text-right">
            {typeof pricing === 'number' ? (
              <>
                {STRINGS.est}
                &nbsp;
                <Money cash={pricing} />
              </>
            ) : (
              <>{pricing}</>
            )}
          </Text>
        </div>
      </div>
    </ButtonBase>
  );
}
