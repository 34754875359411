import { APPLICATION_REQUIREMENTS } from '@belong/common';
import { filter, isEmpty, isNil } from 'lodash-es';
import { createSelector } from 'reselect';
import { ApplicantStatus } from '../../../models/enums';
import { selectHomeById } from '../homes/selectors';
import { RESIDENT_APPLICATION_REDUCER } from './reducer';

export const _selectResidentApplication = (state) => state[RESIDENT_APPLICATION_REDUCER];

export const selectMustCallCompleteEndpoint = (state) => _selectResidentApplication(state).mustCallCompleteEndpoint;

export const selectResidentApplicationId = (state) => _selectResidentApplication(state).applicationId;

export const selectResidentApplication = (state) => {
  const applicationId = selectResidentApplicationId(state);

  if (!applicationId || applicationId === '') {
    return '';
  }

  const { existingApplications } = _selectResidentApplication(state);

  if (existingApplications && existingApplications.length) {
    const [selectedApplication] = existingApplications.filter(
      (existingApplication) => existingApplication.basicInfo.applicationId === applicationId
    );

    if (selectedApplication) {
      return selectedApplication;
    }
  }

  return '';
};

export const selectResidentApplicationStatus = (state) => {
  const application = selectResidentApplication(state);

  if (application) {
    return application.basicInfo.status;
  }

  return '';
};

export const _selectResidentApplicationsForUser = (state) => _selectResidentApplication(state).existingApplications;
export const selectResidentApplicationsForUser = createSelector(
  _selectResidentApplicationsForUser,
  (existingApplications) => {
    existingApplications.sort((a, b) => new Date(b.basicInfo.createdOn) - new Date(a.basicInfo.createdOn));
    return existingApplications;
  }
);

export const selectResidentApplyForHouseId = (state) => _selectResidentApplication(state).applyForHouseId;

export const selectResidentApplicationMoveInDate = (state) => _selectResidentApplication(state).moveInDate;

export const selectResidentApplicationApplicants = (state) => {
  const application = selectResidentApplication(state);
  if (!application) {
    return false;
  }
  const { applicants } = application;
  const { coApplicants, coSigners, mainApplicant } = applicants;
  return [mainApplicant, ...coSigners, ...coApplicants];
};

export const selectResidentApplicationApplicantInfo = (state) => {
  const { applicantInfo } = _selectResidentApplication(state);

  if (isNil(applicantInfo) || isEmpty(applicantInfo)) {
    return {};
  }

  return applicantInfo;
};

export const selectResidentApplicationApplicantType = (state) => {
  const { applicantInfo } = _selectResidentApplication(state);

  if (isNil(applicantInfo) || isEmpty(applicantInfo)) {
    return '';
  }

  const { applicantType } = applicantInfo;
  return applicantType;
};

export const selectResidentApplicationBasicInfo = (state) => {
  const { applicantInfo } = _selectResidentApplication(state);

  if (isNil(applicantInfo) || isEmpty(applicantInfo)) {
    return {};
  }

  const { basicInfo } = applicantInfo;
  return basicInfo;
};

export const selectResidentApplicationFeeInfo = (state) => {
  const { applicantInfo } = _selectResidentApplication(state);

  if (isNil(applicantInfo) || isEmpty(applicantInfo)) {
    return {};
  }

  const { applicationFee } = applicantInfo;
  return applicationFee;
};

export const selectResidentApplicationCreditCheck = (state) => {
  const { applicantInfo } = _selectResidentApplication(state);

  if (isNil(applicantInfo) || isEmpty(applicantInfo)) {
    return {};
  }

  const { legalInfo, currentAddress } = applicantInfo;
  const creditCheckObject = {
    currentAddress,
    ...legalInfo,
  };
  delete creditCheckObject.ssnLastDigits;
  delete creditCheckObject.hasNoSsn;

  return creditCheckObject;
};

export const selectResidentApplicationBackgroundCheck = (state) => {
  const { applicantInfo } = _selectResidentApplication(state);

  if (isNil(applicantInfo) || isEmpty(applicantInfo)) {
    return {};
  }

  const { legalInfo } = applicantInfo;

  if (isNil(legalInfo)) {
    return {};
  }

  return {
    ssnLastDigits: legalInfo.ssnLastDigits,
    hasNoSsn: legalInfo.hasNoSsn,
  };
};

export const selectResidentApplicationEmploymentInformation = (state) => {
  const { incomeSources } = _selectResidentApplication(state);

  return incomeSources;
};

export const selectResidentApplicationCosigners = (state) => {
  const { cosigners } = _selectResidentApplication(state);

  return cosigners;
};

export const selectResidentApplicationCosignerMinimumIncome = (state) => {
  const houseId = selectResidentApplyForHouseId(state);
  const home = selectHomeById(state, houseId);
  const application = selectResidentApplication(state);

  if (!application || !home?.listingInfo) {
    return 0;
  }

  const {
    basicInfo: { totalReportedEstimatedIncome },
  } = application;
  const {
    listingInfo: { rentAmount },
  } = home;
  const qualifiedRent = totalReportedEstimatedIncome / APPLICATION_REQUIREMENTS.MONTHS_RENT;
  const insufficient = Math.ceil(rentAmount - qualifiedRent);
  return insufficient * APPLICATION_REQUIREMENTS.COSIGNER_MINIUM_MONTHS_RENT;
};

export const selectResidentApplicationDisplayCosignerPage = (state) => {
  const application = selectResidentApplication(state);
  const { applicants } = application;
  if (!application) {
    return false;
  }
  const minimumCosigneRequirement = selectResidentApplicationCosignerMinimumIncome(state);
  const { coApplicants, mainApplicant } = applicants;
  const allApplicants = [...coApplicants, mainApplicant];

  const incompleteApplicants = filter(
    allApplicants,
    (applicant) => applicant.status === ApplicantStatus.Started || applicant.status === ApplicantStatus.Invited
  );
  if (incompleteApplicants?.length === 1 && minimumCosigneRequirement > 0) {
    return true;
  }

  return false;
};

export const selectResidentApplicationIncomeDocuments = (state) => _selectResidentApplication(state).incomeDocuments;

export const selectResidentApplicationIncomeVerification = (state) =>
  _selectResidentApplication(state).incomeVerification;

export const selectResidentApplicationSelfServeDetails = (state) => _selectResidentApplication(state).selfServeDetails;

export const selectResidentApplicationCurrentAddress = (state) => {
  const { applicantInfo } = _selectResidentApplication(state);

  if (isNil(applicantInfo) || isEmpty(applicantInfo)) {
    return {};
  }

  const { currentAddress } = applicantInfo;

  if (!currentAddress || isEmpty(currentAddress)) {
    return {};
  }

  return { ...currentAddress };
};

export const selectResidentApplicationReferenceCheck = (state) => _selectResidentApplication(state).referenceCheck;
