export const COST_TYPES = {
  Labor: 'Labor',
  Procurement: 'Procurement',
  Dispatch: 'Dispatch',
  ProjectCoordination: 'ProjectCoordination',
  Parts: 'Parts',
  WasteDisposal: 'WasteDisposal',
  Other: 'Other',
};

export const AUTOCOST_TYPES = [
  COST_TYPES.Procurement,
  COST_TYPES.WasteDisposal,
  COST_TYPES.Dispatch,
  COST_TYPES.ProjectCoordination,
];

export const getCostLabel = (type) => {
  switch (type) {
    case COST_TYPES.Dispatch:
      return 'Dispatch Labor';
    case COST_TYPES.WasteDisposal:
      return 'Waste Disposal Fee';
    case COST_TYPES.Procurement:
      return 'Procurement Labor';
    case COST_TYPES.ProjectCoordination:
      return 'Project Management';
    case COST_TYPES.Labor:
      return 'On-Site Labor';
    case COST_TYPES.Parts:
      return 'Parts';
    case COST_TYPES.Other:
      return 'Other';
    default:
      return type;
  }
};

export const COSTS_CONFIG = [
  {
    type: COST_TYPES.Labor,
    displayName: 'Labor',
    autoCosts: [
      {
        type: COST_TYPES.Labor,
        displayName: 'On-Site Labor',
      },
      {
        type: COST_TYPES.Procurement,
        displayName: 'Procurement',
      },
      {
        type: COST_TYPES.ProjectCoordination,
        displayName: 'Project Management',
      },
      {
        type: COST_TYPES.Dispatch,
        displayName: 'Dispatching',
      },
    ],
  },
  {
    type: COST_TYPES.Parts,
    displayName: 'Parts',
    showCost: true,
    autoCosts: [],
  },
  {
    type: COST_TYPES.WasteDisposal,
    displayName: 'Waste Disposal',
    showCost: true,
    autoCosts: [],
  },
  {
    type: COST_TYPES.Other,
    autoCosts: [],
    displayName: 'Other',
    showCost: true,
  },
];
