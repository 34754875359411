import { Button, Modal, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import { AppDownPhone } from 'lease-signing-flow/components/app-down-phone/app-down-phone';
import { AppQR } from 'lease-signing-flow/components/app-qr/app-qr';
import { AppStoreBadges } from 'lease-signing-flow/components/app-store-badges/app-store-badges';
import { STRINGS } from './SplashScreen.strings';
import styles from './SplashScreenModal.module.css';

const cx = classNames.bind(styles);

type SplashScreenModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

export function SplashScreenModal({ isOpen, onDismiss }: SplashScreenModalProps): JSX.Element {
  return (
    <Modal
      contentClassName="flex"
      isOpen={isOpen}
      name="SPLASH_SCREEN_MODAL"
      onDismiss={onDismiss}
      overlayClassName="z-[99999]"
      size="fullScreen"
    >
      <div className="w-full flex flex-col items-center pt-2xl px-xs md:px-0">
        <Text variant="h3" className={cx('text-center mb-xs', styles.textWidth)} fontWeight="semibold">
          {STRINGS.title}
        </Text>
        <Text className={cx('text-center mb-xl md:mb-2xl', styles.textWidth)}>{STRINGS.subtitle}</Text>
        <div className="flex flex-col md:flex-row md:pl-4xl w-full md:w-auto">
          <div className="md:mr-2xl">
            <AppDownPhone />
          </div>
          <div className="md:pt-2xl relative">
            <div className="hidden md:block">
              <AppQR />
            </div>
            <div
              className="pt-xl md:pt-0 mx-[-10px]
                md:mx-0 flex flex-col items-center md:items-start justify-items-start
                md:mt-xl border-solid border-0 border-t border-gray md:border-0 z-10 md:z-auto bg-white"
            >
              <div className="mb-lg">
                <AppStoreBadges />
              </div>
              <Button buttonType="primary" onClick={onDismiss} variant="text">
                {STRINGS.cta}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
