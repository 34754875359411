/* eslint-disable padding-line-between-statements */
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import PaymentStatusLabel from 'components/Payments/PaymentStatusLabel/PaymentStatusLabel';
import String from 'components/String/String';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { BillType, BillStatus } from 'models/enums/index';
import PropTypes from 'prop-types';
import styles from './PaymentListTile.module.css';

const showRemainer = ({ remainingAmount, total, paidAmount }) => {
  if (!remainingAmount) {
    return false;
  }
  if (remainingAmount === total) {
    return false;
  }

  if (paidAmount === total) {
    return false;
  }

  return true;
};

const cx = classNames.bind(styles);

const PAYMENT_TEXT_COLORS = {
  GREEN: 'green',
  DEFAULT: 'navy',
  DARK_GRAY: 'darkgray',
};
class PaymentListTile extends Component {
  static propTypes = {
    isResident: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disableHover: PropTypes.bool,
    lastUpdateDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    address: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    remainingAmount: PropTypes.number.isRequired,
    paidAmount: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.values(BillType)).isRequired,
  };

  static defaultProps = {
    disableHover: false,
    isResident: false,
  };

  renderMoney() {
    const { remainingAmount, paidAmount, amount, isResident, type, status } = this.props;

    const getResident = (resident, billType, billStatus) => {
      if (billStatus === BillStatus.Refunded) {
        return {
          displayText: PAYMENT_TEXT_COLORS.DARK_GRAY,
          showMinus: false,
        };
      }
      if (resident) {
        if (billType === BillType.Payout) {
          return {
            displayText: PAYMENT_TEXT_COLORS.GREEN,
            showMinus: true,
          };
        }
        return {
          displayText: PAYMENT_TEXT_COLORS.DEFAULT,
          showMinus: false,
        };
      }
      if (billType === BillType.Payout) {
        return {
          displayText: PAYMENT_TEXT_COLORS.GREEN,
          showMinus: false,
        };
      }

      return {
        displayText: PAYMENT_TEXT_COLORS.DEFAULT,
        showMinus: true,
      };
    };
    const { displayText, showMinus } = getResident(isResident, type, status);

    if (
      showRemainer({
        remainingAmount,
        total: amount,
        paidAmount,
      })
    ) {
      return (
        <div className={cx('remaning-amount-wrapper')}>
          <div className={cx(displayText)}>
            Remaining {showMinus && '-'}
            <Money cash={remainingAmount} format={MONEY_FORMAT.DOLLARS_CENTS} />
          </div>
          <div className={`${cx(displayText)} ${cx('small')}`}>
            Total {showMinus && '-'}
            <Money cash={amount} format={MONEY_FORMAT.DOLLARS_CENTS} />
          </div>
        </div>
      );
    }

    return (
      <div className={cx('amount')}>
        <div className={cx(displayText)}>
          {showMinus && '-'}
          <Money cash={amount} format={MONEY_FORMAT.DOLLARS_CENTS} />
        </div>
      </div>
    );
  }

  render() {
    const { lastUpdateDate, status, address, description, onClick, disableHover } = this.props;
    return (
      <ButtonBase className={cx('payment-list-tile', { disableHover })} disabled={disableHover} onClick={onClick}>
        <Row className={cx('row')}>
          <Col lg={4} className={cx('overflow-hidden')}>
            <div className={cx('title')}>
              <div>{description}</div>
              {address?.streetAddress && (
                <div className={cx('address-wrapper')}>
                  <String string={address.getFormattedAddress(address.unitNumber)} />
                </div>
              )}
            </div>
          </Col>
          <Col lg={4} className={cx('hide-on-mobile')}>
            {this.renderMoney()}
          </Col>
          <Col lg={3}>
            <>
              {lastUpdateDate && (
                <div className={cx('last-update-wrapper')}>
                  <div>Last Update:&nbsp;</div>
                  {lastUpdateDate}
                </div>
              )}
              <div className={cx('show-mobile')}>
                <div className={cx('mobile-bottom-wrapper')}>
                  <PaymentStatusLabel status={status} />
                  {this.renderMoney()}
                </div>
              </div>
            </>
          </Col>
          <Col lg={2}>
            <div className={cx('status')}>
              <PaymentStatusLabel status={status} />
            </div>
          </Col>
        </Row>
      </ButtonBase>
    );
  }
}

export default PaymentListTile;
