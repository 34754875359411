import Address from '../common/Address';
import UnitBasicInfo from '../common/UnitBasicInfo';
import ListingDataInfo from './ListingDataInfo';

export default class ListingPageUnit {
  constructor(data = {}) {
    this.address = new Address(data.address);
    this.listInfo = new ListingDataInfo(data.listingInfo);
    this.basicInfo = new UnitBasicInfo(data.basicInfo);
    this.isAvailableForRent = data.isAvailableForRent;
    this.isDemoUnit = data.isDemoUnit;
    this.isPhotoReady = data.isPhotoReady;
    this.is3DTourReady = data.is3DTourReady;
    this.isGuidedTourReady = data.isGuidedTourReady;
    this.isFavorite = data.isFavorite;
    /* Custom */
    this.disablePrice = this.isDemoUnit || !this.isAvailableForRent;
  }

  getHousePageUrl() {
    const {
      address,
      basicInfo: { unitId },
    } = this;
    const cityStateString = `${address.city.split(' ').join('-').toLowerCase()}-${address.state.toLowerCase()}`;
    return `/homes/${cityStateString}/${unitId}`;
  }
}
