import { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import classNames from 'classnames/bind';
import Image from 'components/Image/Image';
import Spinner from 'components/Spinner/Spinner';
import { Grid } from 'forkedlibraries/react-bootstrap';
import Page from 'layouts/Page/Page';
import PropTypes from 'prop-types';
import { showModal } from 'store/redux/modals/actions';
import { fetchUserClaims } from 'store/redux/user/actions';
import { selectIsUserLoggedIn } from 'store/redux/user/selectors';
import { SCREENS } from '../../containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from '../../containercomponents/Modals/modal.consts';
import styles from './ResidentOnboarding.module.css';
import { getResidentOnboardingStepPath } from './Steps/common';
import { STEPS, STEPS_CONFIG } from './Steps/steps';

const cx = classNames.bind(styles);

class ResidentOnboarding extends Component {
  static propTypes = {
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      isExact: PropTypes.bool.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    showModal: PropTypes.func.isRequired,
    fetchUserClaims: PropTypes.func.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
  };

  state = {
    initialized: false,
  };

  componentDidMount() {
    const { isUserLoggedIn, showModal: showModalAction } = this.props;

    if (!isUserLoggedIn) {
      return showModalAction(MODALS.LOGIN, {
        redirectToHomeOnHide: true,
        currentScreen: SCREENS.LOGIN_SCREEN,
        afterSucessfulLogin: async () => {
          this.redirectToForm();
        },
      });
    }

    this.redirectToForm();
  }

  redirectToForm = async () => {
    const { history, fetchUserClaims: fetchUserClaimsAction } = this.props;
    try {
      const userClaims = await fetchUserClaimsAction();
      const { isResident } = userClaims;

      if (!isResident) {
        history.replace('/404');
        return;
      }

      history.replace(getResidentOnboardingStepPath(STEPS_CONFIG.PERSONAL_INFORMATION));
    } catch (err) {
      console.error(err);
    }
    this.setState({ initialized: true });
  };

  render() {
    const { initialized } = this.state;

    if (typeof window === 'undefined') {
      return null;
    }

    if (!initialized) {
      return (
        <Page headerMainProps={{ disableLogo: true }}>
          <Spinner />
        </Page>
      );
    }

    return (
      <Page headerMainProps={{ disableLogo: true }}>
        <Grid className={cx('multi-form-layout')}>
          <div className={cx('form')}>
            {STEPS.map((stepConfig) => (
              <Route key={stepConfig.path} path={stepConfig.path} component={stepConfig.form} />
            ))}
          </div>
        </Grid>
        <Image className={cx('cityImage')} src="/city_images/belong_city_illustration_03.svg" />
      </Page>
    );
  }
}

export default connect(
  (state) => ({
    isUserLoggedIn: selectIsUserLoggedIn(state),
  }),
  {
    showModal,
    fetchUserClaims,
  }
)(ResidentOnboarding);
