import { LEASE_SIGNING_FLOW } from 'lease-signing-flow/store/key';
import { getApiActionTypes, getApiActionTypesValues } from 'store/redux/redux_utils';

const FETCH_LEASE_DEPOSIT_TYPES = 'FETCH_LEASE_DEPOSIT_TYPES';
const UPDATE_LEASE_DEPOSIT_TYPE = 'UPDATE_LEASE_DEPOSIT_TYPE';

const actions = {
  ...getApiActionTypes(FETCH_LEASE_DEPOSIT_TYPES),
};

export const fetchLeaseDepositTypes = () => ({
  promise: ({ client }) => client.get(`/residents/deposit-type`),
  types: getApiActionTypesValues(FETCH_LEASE_DEPOSIT_TYPES),
});

// eslint-disable-next-line
export const updateLeaseDepositType = (body: any) => ({
  promise: ({ client }) => client.put(`/residents/deposit-type`, body),
  types: getApiActionTypesValues(UPDATE_LEASE_DEPOSIT_TYPE),
});

export const LEASE_SIGNING_FLOW_DEPOSIT_REDUCER = 'deposit';

const initialState = {
  depositType: {
    options: [],
    selected: null,
  },
};

// eslint-disable-next-line
const reducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case actions[`${FETCH_LEASE_DEPOSIT_TYPES}_SUCCESS`]:
      return {
        ...state,
        depositType: {
          options: [...action.result.options],
          selected: action.result.selectedDepositType,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const selectLeaseDepositTypeOptions = (state) =>
  state[LEASE_SIGNING_FLOW][LEASE_SIGNING_FLOW_DEPOSIT_REDUCER].depositType.options;

export const selectSelectedLeaseDepositType = (state) =>
  state[LEASE_SIGNING_FLOW][LEASE_SIGNING_FLOW_DEPOSIT_REDUCER].depositType.selected;
