export const PROPERTY_TYPES = {
  SINGLE_FAMILY: 'SingleFamily',
  MULTI_UNIT: 'MultiUnit',
  TOWN_HOUSE: 'Townhouse',
};

export const DATE_SELECTOR_KEYS = {
  ASAP_DATE_STRING: 'asap',
  NEXT_DATE_STRING: 'nextWeek',
  NEXT_NEXT_DATE_STRING: 'nextTwoWeeks',
  OTHER_DATE_STRING: 'other',
};

export const PEOPLE_AND_PETS = {
  KIDS_AND_PETS_SELECTOR: {
    // key corresponding to the default value in VALUES array
    DEFAULT_KEY: '0',

    VALUES: [
      { value: '0', key: '0' },
      { value: '1', key: '1' },
      { value: '2', key: '2' },
      { value: '3', key: '3' },
      { value: '4', key: '4' },
      { value: '5', key: '5' },
    ],
  },
  KIDS_AGE_SELECTOR: {
    // key corresponding to the default value in VALUES array
    DEFAULT_KEY: '0',

    VALUES: [
      { value: '0', key: '0' },
      { value: '1', key: '1' },
      { value: '2', key: '2' },
      { value: '3', key: '3' },
      { value: '4', key: '4' },
      { value: '5', key: '5' },
    ],
  },
  PETS_TYPE: {
    // key corresponding to the default value in VALUES array
    DEFAULT_KEY: 'Dog',

    VALUES: [
      { value: 'Dog', key: 'dog' },
      { value: 'Cat', key: 'cat' },
      { value: 'Other', key: 'other' },
    ],
  },

  PET_TYPES_DISPLAY_TO_SERVER_MAP: {
    Dog: 'dog',
    Cat: 'cat',
    Other: 'Other',
  },
};
