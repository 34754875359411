import { useParams } from 'react-router-dom';
import { useSelectedProperty } from './use-selected-property';

export function useSelectedUnit() {
  const { unitId } = useParams<any>();
  const { selectedProperty } = useSelectedProperty();
  const unit = selectedProperty?.units.find((currentUnit) => currentUnit.basicInfo.unitId === unitId);

  return { selectedUnit: unit };
}
