import { Text } from '@belong/ui';

export function HomeNotInspectionReadyBanner() {
  return (
    <div className="p-2sm rounded border border-solid border-green bg-green-translucent-light flex flex-col justify-between w-full mt-lg sm:mt-0 h-auto">
      <Text>
        No worries! To set you up for success, we will have your Homeowner Advisor reach out to confirm scheduling
        details.
      </Text>
      <br />
      <div>
        <Text as="span">
          We need utilities turned on to properly inspect, without active construction in the home. Doing so ensure you
          avoid being charged a
        </Text>
        &nbsp;
        <Text as="span" fontWeight="semibold">
          $220 fee if an inspector arrives and cannot complete the job.
        </Text>
      </div>
    </div>
  );
}
