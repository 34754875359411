import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Button, Spinner } from '@belong/ui';
import { MaintenanceRecurrenceDetailModel, MaintenanceModel } from 'api/models';
import clsx from 'clsx';
import { useNotes } from 'common/hooks/useNotes';
import Collapsible from 'components/Collapsible/Collapsible';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { NewMediaUploadAdapter, MEDIA_LABEL } from 'components/NewMediaUploader/NewMediaUploader';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { STRINGS } from 'maintenance/strings';
import { createNote, deleteNote, updateNote } from 'store/redux/common/notes';
import { fetchCurrentUserDetails } from 'store/redux/user/actions';
import { _selectUser } from 'store/redux/user/selectors';

type NoteProps = {
  title: string;
  sourceType: string;
  sourceIds: number[];
};

function Note({ title, sourceType, sourceIds }: NoteProps) {
  const [edit, setEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(_selectUser);

  const { notes, isLoadingNotes, refetch } = useNotes({ sourceIds, sourceType });

  function getInitialValues() {
    if (notes && notes.length > 0) {
      const note = { ...notes[0] };

      return { media: { availableMedia: note.notes[0]?.media }, ...note.notes[0]?.note, isEdit: true };
    }

    return {};
  }

  async function handleSubmit({ isEdit, id, body, media }) {
    const params = {
      sourceId: sourceIds[0],
      sourceType,
      body: body || '',
      media,
    };

    setIsSubmitting(true);
    try {
      if (isEdit) {
        await dispatch(updateNote(id, params));
      } else {
        await dispatch(createNote(params));
      }

      setTimeout(async () => {
        await refetch();
        setIsSubmitting(false);
      }, 1000);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  }

  async function handleDelete(noteId) {
    setIsSubmitting(true);
    await dispatch(deleteNote(noteId));

    setTimeout(async () => {
      await refetch();
      setIsSubmitting(false);
    }, 1000);
  }

  function disableSaveButton(values) {
    if (!user?.id || notes?.length === 0) {
      return false;
    }

    return user?.id !== values?.creatorUserId;
  }

  const values = getInitialValues();

  useEffect(() => {
    if (!user?.id) {
      dispatch(fetchCurrentUserDetails());
    }
  }, [dispatch, user.id]);

  return (
    <>
      {(isLoadingNotes || isSubmitting) && <Spinner />}
      <div className={clsx('contents', notes?.length ? 'mb-md' : 'mb-0')}>
        <div className={clsx('flex justify-between', notes?.length ? 'mb-xs' : 'mb-0')}>
          <Text fontWeight="semibold">{title}</Text>
          {!edit && (
            <Button
              variant="text"
              buttonType="secondary"
              onClick={() => setEdit(true)}
              disabled={disableSaveButton(values)}
            >
              {notes?.length > 0 ? STRINGS.edit : STRINGS.add}
            </Button>
          )}
        </div>
        <Form
          initialValues={values}
          onSubmit={async (val) => {
            await handleSubmit(val);
            setEdit(false);
          }}
          getForm={({ handleSubmit: formSubmit, initialValues }) => {
            return (
              <FormLayout>
                <div className="w-full">
                  {edit ? (
                    <Field name="body" placeholder="Enter Note" component={InputFinalFormAdapter} textarea />
                  ) : (
                    <Text>{initialValues.body}</Text>
                  )}
                  {(edit || Boolean(initialValues.media?.availableMedia?.length)) && (
                    <div className="mt-md">
                      <Field
                        name="media"
                        component={NewMediaUploadAdapter}
                        mediaLabel={MEDIA_LABEL.PHOTO_OR_VIDEO}
                        viewOnly={!edit}
                        cleanOnMediaChange
                      />
                    </div>
                  )}
                </div>
                {edit && (
                  <div className="flex justify-end mt-sm gap-md">
                    {initialValues.body && (
                      <Button
                        variant="text"
                        buttonType="danger"
                        onClick={async () => {
                          await handleDelete(initialValues.id);
                          setEdit(false);
                        }}
                      >
                        {STRINGS.delete}
                      </Button>
                    )}
                    <Button variant="text" buttonType="secondary" onClick={formSubmit}>
                      {STRINGS.save}
                    </Button>
                  </div>
                )}
              </FormLayout>
            );
          }}
        />
      </div>
    </>
  );
}

type MaintenanceRecurrenceNotesProps = {
  task?: MaintenanceRecurrenceDetailModel;
  subtask?: MaintenanceModel;
  isResident?: boolean;
};

export function MaintenanceRecurrenceNotes({ isResident, task, subtask }: MaintenanceRecurrenceNotesProps) {
  const isMasterTicket = !!task;
  const requestedBy = isMasterTicket ? task.maintenanceRecurrence.requestedBy : subtask.requestedBy;
  const currentUserType = isResident ? 'Resident' : 'Homeowner';
  const sourceIds = useMemo(
    () => (isMasterTicket ? [task.maintenanceRecurrence.id] : [subtask.id]),
    [task, subtask, isMasterTicket]
  );

  if (isMasterTicket && currentUserType !== requestedBy) {
    return null;
  }

  return (
    <Collapsible
      defaultClassName="block"
      title="Notes"
      collapsed
      inner={
        <div className="flex flex-col items-stretch mt-sm">
          {currentUserType === requestedBy && (
            <Note
              title={STRINGS['all-visits']}
              sourceType="MaintenanceRecurrence"
              sourceIds={isMasterTicket ? sourceIds : [subtask.recurrence.id]}
            />
          )}
          {!isMasterTicket && (
            <div className="mt-sm">
              <Note title={STRINGS['special-requests']} sourceType="Maintenance" sourceIds={sourceIds} />
            </div>
          )}
        </div>
      }
    />
  );
}
