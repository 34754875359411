import React from 'react';
import classNames from 'classnames/bind';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import styles from './DisplayDefaultMetadata.module.css';

const cx = classNames.bind(styles);

const DisplayDefaultMetadata = ({ title, metadata }) => {
  const metadataArray = Object.entries(metadata);
  const firstKeyValuePair = metadataArray[0] || [];
  const firstKey = firstKeyValuePair[0];
  const firstValue = firstKeyValuePair[1];
  return (
    <div className={cx('default-metadata-container')}>
      {metadataArray.length === 1 ? (
        <>
          <Text fontWeight="semibold" mb="xs">
            {firstKey}
          </Text>
          {firstValue}
        </>
      ) : (
        <>
          <Text fontWeight="semibold" mb="xs">
            {title}
          </Text>
          {metadataArray.map(([key, value], index) => {
            return (
              <span key={index} className={cx('key-value-pair')}>
                <Text mb="xs">{key}</Text>: <span className={cx('mobile-value')}>{value}</span>
              </span>
            );
          })}
        </>
      )}
    </div>
  );
};

DisplayDefaultMetadata.propTypes = {
  title: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default DisplayDefaultMetadata;
