import React, { Component } from 'react';
import { filter, compact } from 'lodash-es';
import PropTypes from 'prop-types';
import { GENERAL_ICONS } from '../GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from '../IconButton/IconButton';
import Input from '../Input/Input';

export default class TaggableInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    ctaLabel: PropTypes.node,
    // Not sure the object shape.
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  };

  static defaultProps = {
    ctaLabel: null,
  };

  inputRef = React.createRef();

  state = {
    inputText: this.props.value?.inputText || '',
    tags: this.props.value?.tags || [],
  };

  static getDerivedStateFromProps(nextProps) {
    const newState = {};

    if (!nextProps.value) {
      newState.tags = [];
    } else if (nextProps?.value?.tags) {
      newState.tags = nextProps.value.tags;
    } else if (nextProps?.value?.inputText) {
      newState.inputText = nextProps.value.inputText;
    }

    return newState;
  }

  handleInputChange = (e) => {
    const inputValue = e.target.value;
    const { tags } = this.state;
    const { onChange } = this.props;
    let delimiter = '';

    if (inputValue.includes(' ')) {
      delimiter = ' ';
    }

    if (inputValue.includes(',')) {
      delimiter = ',';
    }

    let newState = { inputText: inputValue };

    if (delimiter) {
      const tagName = inputValue.split(delimiter)[0];
      const inputText = inputValue.split(delimiter)[1];
      const newTags = [...tags];

      if (tagName) {
        newTags.push({ name: tagName.toUpperCase() });
      }

      newState = { inputText, tags: newTags };
    } else {
      newState = { inputText: inputValue };
    }

    onChange(newState);
    this.setState(newState);
  };

  handleInputBlur = (e) => {
    const inputValue = e.target.value;
    const { tags } = this.state;
    const { onChange } = this.props;

    if (inputValue) {
      let newTags = [...tags];
      newTags.push({ name: inputValue.toUpperCase() });
      newTags = compact(newTags);
      const newState = { inputText: '', tags: newTags };

      onChange(newState);
      this.setState(newState);
    }
  };

  handleRemoveTag = (removedTag) => {
    const { tags } = this.state;
    const { onChange } = this.props;
    const remainingTags = filter(tags, (tag) => tag.name !== removedTag.name);
    const newState = { tags: compact(remainingTags) };

    onChange(newState);
    this.setState(newState);
  };

  handleCtaClick = () => {
    this.inputRef.current.focus();
  };

  render() {
    const { inputText, tags } = this.state;
    const { ctaLabel, ...rest } = this.props;

    delete rest.value;
    delete rest.onChange;
    delete rest.onBlur;

    return (
      <Input
        onChange={this.handleInputChange}
        onBlur={this.handleInputBlur}
        value={inputText}
        tags={tags}
        inputRef={this.inputRef}
        onRemoveTag={this.handleRemoveTag}
        ctaButton={
          !!inputText || !!tags?.length ? (
            <IconButton onClick={this.handleCtaClick} align={ALIGN_TYPES.RIGHT} icon={GENERAL_ICONS.PLUS}>
              {ctaLabel}
            </IconButton>
          ) : null
        }
        {...rest}
      />
    );
  }
}

export const TaggableInputFinalFormAdapter = ({ ignoreError, input, meta, onChangeCustom, ...rest }) => (
  <TaggableInput
    {...input}
    {...rest}
    onChange={(value) => {
      input.onChange(value);

      if (onChangeCustom) {
        onChangeCustom(value);
      }
    }}
  />
);

TaggableInputFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func,
  ignoreError: PropTypes.bool,
};

TaggableInputFinalFormAdapter.defaultProps = {
  onChangeCustom: () => {},
  ignoreError: false,
};
