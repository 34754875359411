import { HomePlanStatus } from 'api/models';
import HomeownerAccountUnit from 'models/homeownerAccounts/HomeownerAccountUnit';
import { useFullManagementHomes } from './useFullManagementHomes';

export const useActiveFullManagementHomes = () => {
  const fullManagementHomes = useFullManagementHomes();
  const activeFullManagementHomes = fullManagementHomes.filter((property) => {
    return property.units.some((unit: HomeownerAccountUnit) => {
      const plans = unit.homePlans as HomePlanStatus[];
      return plans.some((plan) => plan.planType === 'FullManagement' && plan.isActive);
    });
  });

  return activeFullManagementHomes;
};
