import { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { uniq } from 'lodash-es';
import PropTypes from 'prop-types';
import { hideModal } from 'store/redux/modals/actions';
import Button from '../../../components/Button/Button';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../components/Button/buttonTypes';
import ModalV2 from '../../../components/Modal/ModalV2/ModalV2';
import { deleteOnboardingProperties } from '../../../store/redux/homeowner-onboarding/actions';
import { filterApplicationPendingProperties } from '../../../store/redux/homeowner-onboarding/filters';
import styles from './ResumeModal.module.css';

const cx = classNames.bind(styles);

class ResumeModal extends Component {
  state = {
    showSpinner: false,
  };

  handleKeepWorkingClick = async () => {
    const { onKeepWorkingClick, hideModal: hideModalAction } = this.props;
    onKeepWorkingClick();
    hideModalAction();
  };

  handleNewPropertyClick = async () => {
    const {
      hideModal: hideModalAction,
      properties,
      deleteOnboardingProperties: deleteOnboardingPropertiesAction,
      onNewProperty,
    } = this.props;

    this.setState({ showSpinner: true });
    hideModalAction();
    try {
      await deleteOnboardingPropertiesAction(properties);
    } catch (err) {
      console.error(err);
    }
    this.setState({ showSpinner: false });
    onNewProperty();
  };

  render() {
    const { properties, ...rest } = this.props;
    const { showSpinner } = this.state;
    const applicationPendingProperties = filterApplicationPendingProperties(properties);
    const cities = uniq(applicationPendingProperties?.map((property) => property.address.city) || []);
    const cityName = cities.length === 1 ? cities[0] : null;

    return (
      <ModalV2 {...rest} showSpinner={showSpinner}>
        <div className={cx('resume-modal')}>
          <div className={cx('subtitle')}>Welcome back!</div>
          <div className={cx('title')}>
            {`Would you like to keep working on adding your ${cityName ? `property in ${cityName}?` : 'properties?'}`}
          </div>
          <div className={cx('actions')}>
            <Button buttonType={BUTTON_TYPES.TEXT} label="START FRESH" onClick={this.handleNewPropertyClick} />
            <div className={cx('fluid-default-responsive-resize-for-mobile')}>
              <Button size={BUTTON_SIZES.FLUID} label="Keep working" onClick={this.handleKeepWorkingClick} />
            </div>
          </div>
        </div>
      </ModalV2>
    );
  }
}

ResumeModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  deleteOnboardingProperties: PropTypes.func.isRequired,
  properties: PropTypes.array,
  history: PropTypes.object.isRequired,
  onNewProperty: PropTypes.func,
  onKeepWorkingClick: PropTypes.func,
};

ResumeModal.defaultProps = {
  properties: [],
  onNewProperty: () => {},
  onKeepWorkingClick: () => {},
};

export default connect(null, {
  hideModal,
  deleteOnboardingProperties,
})(ResumeModal);
