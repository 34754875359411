import React from 'react';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import PropTypes from 'prop-types';
import { THEME_CONSTS } from 'themes/themes';
import styles from './RouterHeaderNavLink.module.css';

const cx = classNames.bind(styles);

export function RouterHeaderNavLink({ className, selected, label, theme, ...rest }) {
  return (
    <li className={cx('header-nav-link', theme, className, { selected })}>
      <Button className={cx('headerLink')} buttonType={BUTTON_TYPES.NOSTYLE} {...rest}>
        {label}
      </Button>
    </li>
  );
}

RouterHeaderNavLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  selected: PropTypes.bool,
  theme: PropTypes.string,
};

RouterHeaderNavLink.defaultProps = {
  to: '',
  label: '',
  className: '',
  theme: THEME_CONSTS.DEFAULT,
  selected: false,
};

export default RouterHeaderNavLink;
