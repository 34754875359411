import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatMoney } from '@belong/common';
import { PlaidUserIncomePlatformModel } from 'api/models';
import { AxiosError } from 'axios';
import { fetchUserVerification, getUserIncomeLink } from 'store/redux/user/actions';

export type ApiErrorDataItem = {
  ErrorCode: string;
  errorCode?: string;
  Message: string;
  message?: string;
};

export function hasErrorCode(response: AxiosError<ApiErrorDataItem[]>, errorCode: string) {
  return response.response?.data?.some((error) => error.errorCode === errorCode || error.ErrorCode === errorCode);
}

export function usePlaidIncomeVerification(userIncomes?: PlaidUserIncomePlatformModel) {
  const dispatch = useDispatch();

  const [userVerificationsDetail, setUserVerificationsDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const verifications = await dispatch(
          fetchUserVerification({
            verificationType: 'Income',
            verificationVendorProduct: 'PlaidIncome',
          })
        );

        if (verifications) {
          setUserVerificationsDetail(verifications as any);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return useMemo(() => {
    const incomeVerifications = userVerificationsDetail?.find((verification) =>
      verification.verifications?.find((v) => v.type === 'Income')
    );
    const incomeVerification = incomeVerifications?.verifications?.find((v) => v.type === 'Income');

    const passed = incomeVerification?.detail?.plaidIncome?.result === 'Passed';

    if (!userIncomes && !passed) {
      return undefined;
    }

    if (!incomeVerification) {
      return undefined;
    }

    const accounts = incomeVerification.detail?.plaidIncome?.bankAccounts
      ?.map((account) =>
        account.bankIncomeAccounts?.map((bankAccount) => {
          let incomeSourceAmount = 0;
          account.bankIncomeSources?.forEach((bankIncomeSource) => {
            if (bankIncomeSource.accountId === bankAccount.accountId) {
              incomeSourceAmount += bankIncomeSource.totalAmount;
            }
          });

          return {
            incomeSourceName: `${bankAccount.officialName} ending ${bankAccount.mask}`,
            incomeSourceFormattedAmount: formatMoney(incomeSourceAmount),
          };
        })
      )
      .flat();

    return {
      passed: incomeVerification.detail?.plaidIncome?.result,
      payroll: incomeVerification.detail?.plaidIncome?.payrolls,
      accounts,
      isLoading: loading,
    };
  }, [loading, userIncomes, userVerificationsDetail]);
}

export function useUserIncomes() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [userIncomes, setUserIncomes] = useState({});

  async function fetchData() {
    try {
      setLoading(false);
      const incomes = await dispatch(getUserIncomeLink());
      setUserIncomes(incomes as any);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return {
    userIncomes,
    isRetrievingUserIncomes: loading,
    refetch: fetchData,
  };
}
