/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import String from 'components/String/String';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import HocFocusVisible from 'hooks/useFocus/HocFocusVisible';
import FocusVisibleManager from 'hooks/useFocus/useFocusManager';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.css';

const cx = classNames.bind(styles);

class BaseCheckbox extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onSelect: PropTypes.func,
    value: PropTypes.bool,
    alignCenter: PropTypes.bool,
    scale: PropTypes.number,
    disabled: PropTypes.bool,
    alignWithField: PropTypes.bool,
    supressHandleClick: PropTypes.bool,
    noIconMode: PropTypes.bool,
    maskCheckedState: PropTypes.bool,
  };

  static defaultProps = {
    children: '',
    className: '',
    label: '',
    checked: false,
    alignCenter: false,
    onSelect: () => {},
    value: false,
    scale: 1,
    disabled: false,
    supressHandleClick: false,
    noIconMode: false,
    alignWithField: false,
    maskCheckedState: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: props.value,
      focused: false,
      hover: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // We should think of a good way of handling FinalForm Adapters and statefulness without getDerivedStateFromProps.
    // It's not a pattern I want to be repeating everywhere.
    // TECH DEBT TICKET: BEL-3026
    if (nextProps.useOnChangeFormToHandle) {
      return {
        checked: nextProps.value,
      };
    }

    if (nextProps.ignoreClick) {
      return {
        checked: nextProps.value,
      };
    }

    return {
      ...prevState,
    };
  }

  getUncheckedColor = () => {
    const { error, focusVisible, dark } = this.props;
    const { hover } = this.state;

    if (dark) {
      return COLORS.WHITE;
    }

    if (focusVisible) {
      return COLORS.GREEN;
    }

    if (error) {
      return COLORS.RED;
    }

    if (hover) {
      return COLORS.NAVY;
    }

    return COLORS.GRAY;
  };

  getCheckedColor = () => {
    const { error, focusVisible, dark, disabled } = this.props;
    const { hover } = this.state;

    if (dark) {
      return COLORS.WHITE;
    }

    if (disabled) {
      return COLORS.GRAY;
    }

    if (focusVisible) {
      return COLORS.GREEN;
    }

    if (error) {
      return COLORS.RED;
    }

    if (hover) {
      return COLORS.DARK_NAVY;
    }

    return COLORS.DEFAULT;
  };

  handleClick = () => {
    const { checked } = this.state;
    const { onSelect, ignoreClick } = this.props;

    if (!ignoreClick) {
      onSelect(!checked);
      this.setState({
        checked: !checked,
      });
    }
  };

  handleBlur = () => {
    const { onFocusBlur } = this.props;
    onFocusBlur();
  };

  handleFocus = () => {
    const { onFocusVisible } = this.props;
    onFocusVisible();
  };

  handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  render() {
    let icon;
    const {
      children,
      label,
      className,
      alignCenter,
      scale,
      alignWithField,
      disabled,
      supressHandleClick,
      noIconMode,
      marginOnDesktop,
      error,
      onFocusVisible,
      onFocusBlur,
      focusVisible,
      dark,
      maskCheckedState,
      // Destructure to not pass to checkbox
      useOnChangeFormToHandle,
      ...other
    } = this.props;
    const { checked } = this.state;

    if (!checked || maskCheckedState) {
      icon = (
        <GeneralIcon
          className={cx({ 'unchecked-dark': dark })}
          icon={GENERAL_ICONS.CHECKBOX_UNSELECTED}
          color={this.getUncheckedColor()}
          scale={scale}
        />
      );
    } else {
      icon = (
        <GeneralIcon
          className={cx({ 'checked-dark': dark })}
          icon={GENERAL_ICONS.CHECKBOX_SELECTED}
          scale={scale}
          color={this.getCheckedColor()}
          hasEffects={!disabled}
        />
      );
    }

    return (
      <ButtonBase
        onClick={
          supressHandleClick
            ? () => {}
            : (e) => {
                e.stopPropagation();
                this.handleClick();
              }
        }
        {...other}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        disabled={disabled}
        className={cx({
          'checkbox-wrapper-center': alignCenter,
          'checked-hover': checked && !disabled,
          'unchecked-hover': !checked && !disabled,
        })}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          className={cx(
            'iconButton',
            { marginOnDesktop },
            { alignWithField },
            { noIconMode },
            { disabled },
            { dark },
            { alignCenter }
          )}
        >
          {!noIconMode && <div className={cx('icon-wrapper', { alignCenter })}>{icon}</div>}
          <span
            className={cx(
              'text',
              { error },
              { focused: focusVisible },
              { checked },
              { dark },
              { noIconMode },
              { disabled }
            )}
          >
            {children || <String className={className} string={label} />}
          </span>
        </div>
      </ButtonBase>
    );
  }
}

const HocCheckbox = HocFocusVisible(BaseCheckbox);

const Checkbox = ({ ...props }) => (
  <FocusVisibleManager>
    <HocCheckbox {...props} />
  </FocusVisibleManager>
);

export default Checkbox;

export const CheckboxFinalFormAdapter = ({
  alignWithField,
  useOnChangeFormToHandle,
  input,
  meta,
  value,
  onClickCustom,
  ignoreError,
  checkboxChildren,
  ...rest
}) => {
  const controlledProps = {};

  if (!ignoreError) {
    controlledProps.error = !!meta?.error;
  }

  let formattedValue = input.value;
  if (formattedValue === '') {
    formattedValue = value || false;
  }

  return (
    <Checkbox
      alignWithField={alignWithField}
      {...input}
      {...rest}
      value={formattedValue}
      useOnChangeFormToHandle={useOnChangeFormToHandle}
      checked={input.value}
      onSelect={(inputValue) => {
        input.onChange(inputValue);

        if (onClickCustom) {
          onClickCustom(inputValue);
        }
      }}
      {...controlledProps}
    >
      {checkboxChildren}
    </Checkbox>
  );
};

CheckboxFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onClickCustom: PropTypes.func,
  alignWithField: PropTypes.bool,
  value: PropTypes.bool,
  checkboxChildren: PropTypes.node,
};

CheckboxFinalFormAdapter.defaultProps = {
  onClickCustom: () => {},
  value: false,
  alignWithField: true,
  checkboxChildren: null,
};
