import { PAYMENTS_FEE_CONST } from '@belong/common';
import { FeeOptions } from 'models/enums/index';
import { GENERAL_ICONS } from '../../components/GeneralIcon/GeneralIcon';
import { CardType, PaymentMethod as PaymentMethodEnum } from '../enums';

export const PAYMENT_METHOD_MAX_LENGTH = {
  SHORT: 'SHORT',
  MEDIUM: 'MEDIUM',
  FULL: 'FULL',
};

export const paymentMethodSubStringConverter = (string, length) => {
  if (!string) {
    return null;
  }

  // Difference of 3 characters to only use ... when necessary.
  if (length === PAYMENT_METHOD_MAX_LENGTH.SHORT) {
    if (string.length < 15) {
      return string;
    }

    return string.substring(0, 12).concat('...');
  } else if (length === PAYMENT_METHOD_MAX_LENGTH.MEDIUM) {
    if (string.length < 21) {
      return string;
    }

    return string.substring(0, 17).concat('...');
  }

  return string;
};

export const getPaymentMethodName = (
  { paymentMethod, accountLastDigits, referenceNumber, bankName, otherPaymentType },
  lengthOfAccountName = PAYMENT_METHOD_MAX_LENGTH.MEDIUM
) => {
  if (paymentMethod === PaymentMethodEnum.Ach) {
    return accountLastDigits
      ? `${paymentMethodSubStringConverter(bankName, lengthOfAccountName) || 'Account'} Ending ${accountLastDigits}`
      : 'Add Bank Account';
  } else if (paymentMethod === PaymentMethodEnum.Check) {
    return `Check #${referenceNumber}`;
  } else if (paymentMethod === PaymentMethodEnum.CreditCard) {
    return `Card Ending ${accountLastDigits}`;
  } else if (paymentMethod === PaymentMethodEnum.WireTransfer) {
    return `Wire Transfer #${referenceNumber}`;
  } else if (paymentMethod === PaymentMethodEnum.Paypal) {
    return `Paypal Transfer #${referenceNumber}`;
  } else if (paymentMethod === PaymentMethodEnum.Other) {
    return `${otherPaymentType || paymentMethod} ${referenceNumber ? ` #${referenceNumber}` : ''}`;
  }
};

export const getPaymentMethodIcon = (paymentMethod = PaymentMethodEnum.Ach, paymentMethodType) => {
  if (paymentMethod === PaymentMethodEnum.Ach) {
    return GENERAL_ICONS.ACH_ACCOUNT;
  } else if (paymentMethod === PaymentMethodEnum.CreditCard) {
    switch (paymentMethodType) {
      case CardType.Visa:
        return GENERAL_ICONS.VISA;
      case CardType.MasterCard:
        return GENERAL_ICONS.MASTER_CARD;
      case CardType.Maestro:
        return GENERAL_ICONS.MAESTRO;
      case CardType.Cirrus:
        return GENERAL_ICONS.CIRRUS;
      case CardType.AmericanExpress:
        return GENERAL_ICONS.AMERICAN_EXPRESS;
      case CardType.Discover:
        return GENERAL_ICONS.DISCOVER;
      case CardType.JCB:
        return GENERAL_ICONS.JCB;
      case CardType.UnionPay:
        return GENERAL_ICONS.UNION_PAY;
      case CardType.DinersClub:
        return GENERAL_ICONS.DINERS_CLUB;
      default:
        return GENERAL_ICONS.CREDIT_CARD;
    }
  } else if (paymentMethod === PaymentMethodEnum.WireTransfer) {
    return GENERAL_ICONS.WIRE_TRANSFER;
  } else if (paymentMethod === PaymentMethodEnum.Paypal) {
    return GENERAL_ICONS.PAYPAL;
  } else if (paymentMethod === PaymentMethodEnum.Check) {
    return GENERAL_ICONS.CHECK;
  } else {
    return GENERAL_ICONS.OTHER_PAYMENTS;
  }
};

export const getFeeFromPaymentMethod = (paymentDetails, hasFee) => {
  if (!hasFee) {
    return 'Free';
  }

  if (typeof paymentDetails === 'object') {
    if (paymentDetails.paymentMethod === PaymentMethodEnum.CreditCard) {
      if (paymentDetails.isDomestic) {
        return `${PAYMENTS_FEE_CONST.CREDIT_CARD_FEE} Fee`;
      }
      return `${PAYMENTS_FEE_CONST.INTERNATIONAL_CARD_FEE} Fee`;
    }
  }
  if (typeof paymentDetails === 'string') {
    if (paymentDetails === PaymentMethodEnum.CreditCard) {
      return `${PAYMENTS_FEE_CONST.CREDIT_CARD_FEE} Fee`;
    }
  }

  return 'Free';
};

export const getTypeofFeeSymbol = (typeOfFee) => {
  if (typeOfFee === FeeOptions.Percentage) {
    return '%';
  } else if (typeOfFee === FeeOptions.FlatFee) {
    return '$';
  }

  return '';
};

export const getFeeFromPaymentInfo = (fee, typeOfFee) => {
  if (fee) {
    return `${fee}${getTypeofFeeSymbol(typeOfFee)}`;
  }

  return '';
};
