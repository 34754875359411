export const ANALYTICS_PAGE_NAMES = {
  HOME_PAGE: 'home_page',
  HOUSE_PAGE: 'house_page',
  LISTINGS_PAGE: 'listings_page',
  HOMEOWNERS_LANDING_PAGE: 'homeowners_landing_page',
  HOMEOWNERS_SIGNUP_FLOW: 'homeowners_signup_flow',
  HOMEOWNERS_SETUP_FLOW: 'homeowners_setup_flow',
  HOMEOWNERS_SETUP_FLOW_STEPS: 'homeowners_setup_flow_steps',
  HOMEOWNERS_SETUP_FLOW_SUCCESS: 'homeowners_setup_flow_success',
  HOMEOWNERS_CITY_LANDING_PAGE: 'homeowners_city_landing_page',
  RESIDENTS_LANDING_PAGE: 'residents_landing_page',
  POST_INSPECTION_FLOW: 'post_inspection_flow',
  FAQ: 'faq',
  ABOUT_US: 'about_us',
  HOW_IT_WORKS: 'how_it_works',
  TERMS: 'terms',
  PRIVACY_POLICY: 'privacy_policy',
  CAREERS: 'careers',
  PAGE_NOT_FOUND: 'page_not_found',
  RECESSION_LANDING_PAGE: 'recession_landing_page',
  AGREEMENT_FLOW: 'agreement_flow',
  PRO_SERVICES: 'pro-services',
  BLOG: 'blog',
  LANDING_PAGE: 'landing_page',
  FILE_DOWNLOAD: 'file_download',
  ACCOUNT: 'account',
  LANDLORD_INSURANCE: 'landlord_insurance'
};
