import React from 'react';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import PropTypes from 'prop-types';
import { extractAndSortStepsInGroup } from '../step.consts';

const PostInspectionFlowProgressBar = ({ stepConfig, stepConfigs }) => {
  if (stepConfigs) {
    const filteredStepConfigs = stepConfigs.filter((config) => {
      return config.home.homeUniqueId === stepConfig.home.homeUniqueId;
    });

    const items = extractAndSortStepsInGroup(filteredStepConfigs, stepConfig.group.key).map((item) => {
      item.label = item.displayName;
      if (item.key === stepConfig.key) {
        item.active = true;
      }
      return item;
    });

    return <ProgressBar items={items} />;
  }
  return null;
};

PostInspectionFlowProgressBar.propTypes = {
  stepConfig: PropTypes.object.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.obect).isRequired,
};

export default PostInspectionFlowProgressBar;
