import { BillType } from 'models/enums/index';
import { ACTIONS, ACCOUNTS_CONST } from 'store/redux/accounts/constants';
import { getApiActionTypesValues } from '../redux_utils';

export const fetchUserBills = (pageIndex = 1, pageSize = 20) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_BILLS),
  promise: ({ client }) => client.get(`/users/bills?pageIndex=${pageIndex}&pageSize=${pageSize}`),
});

export const fetchUserPendingBills = (queryParam = '') => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_PENDING_BILLS),
  promise: ({ client }) => client.get(`/users/bills/pending?${queryParam}`),
});

export const fetchUserPastBills = (queryParam = '') => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_PAST_BILLS),
  promise: ({ client }) => client.get(`/users/bills/past?${queryParam}`),
});

export const fetchUserGroupBalances = (queryParam = '') => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_GROUP_BALANCES),
  promise: ({ client }) => client.get(`/users/balances/group?${queryParam}`),
});

export const fetchUserGroupBalancesByMonth = (queryParam = '') => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_GROUP_BALANCES_BY_MONTH),
  promise: ({ client }) => client.get(`/v2/users/balances/monthly-statements?${queryParam}`),
});

export const fetchUserBalancesPastMonths = (queryParam = '') => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_BALANCES_PAST_MONTHS),
  promise: ({ client }) => client.get(`/v2/users/balances/monthly-statements?${queryParam}`),
});

export const fetchUserBalancesUnderReview = (queryParam = '') => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_BALANCES_UNDER_REVIEW),
  promise: ({ client }) => client.get(`/v2/users/balances/under-review?${queryParam}`),
});

export const fetchUsersBalancesPdf = ({ homeUniqueId, from, until, groupBalanceType }) => ({
  promise: async ({ client }) => {
    const response = await client({
      method: 'get',
      url: '/v2/users/balances/monthly-statements/pdf',
      responseType: 'arraybuffer',
      params: {
        homeUniqueId,
        from,
        until,
        groupBalanceType,
      },
    });

    const blob = new Blob([response], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    return url;
  },
  auth: true,
});

export const simulateSplitPayments = (data) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.SIMULATE_SPLIT_PAYMENT),
  promise: ({ client }) => client.post(`/users/finances/simulation`, data),
});

export const createFinancePlan = (data) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.CREATE_FINANCE_PLAN),
  promise: ({ client }) => client.post(`/users/finances`, data),
});

export const closeFinancePlan = (financeUniqueId) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.CLOSE_FINANCE_PLAN),
  promise: ({ client }) => client.put(`/users/finances/${financeUniqueId}/close`),
});

export const fetchFinancePlan = (uniqueId) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_FINANCE_PLAN),
  promise: ({ client }) => client.get(`/users/finances/${uniqueId}`),
});

export const fetchFinancePlanPayments = (uniqueId) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_FINANCE_PLAN_PAYMENTS),
  promise: ({ client }) => client.get(`/users/finances/${uniqueId}/payments`),
});

export const fetchUserFeesById = (id, amount) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_FEES_BY_ID),
  auth: true,
  promise: ({ client }) =>
    client.get(`users/bills/${id}/fees`, {
      params: {
        amount,
      },
    }),
});

export const _fetchUserBillsById = (id) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_USER_BILLS_BY_ID),
  auth: true,
  promise: ({ client }) => client.get(`/users/bills/${id}`),
});

export const fetchUserBillsById = (id) => async (dispatch) => {
  const bill = await dispatch(_fetchUserBillsById(id));

  if (bill.billInfo.type === BillType.Payment) {
    await dispatch(fetchUserFeesById(id, bill.billInfo.remainingAmount || bill.billInfo.total));
  }

  return bill;
};

export const createBillPaymentWithPaymentId = (id, model) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.PAY_BILL),
  promise: ({ client }) => client.post(`/users/bills/${id}`, model),
});

export const createBillsPayment = (model) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.PAY_BILLS),
  promise: ({ client }) => client.post(`/users/bills`, model),
});

export const billCurrentClear = () => ({
  type: ACTIONS.CURRENT_BILL_CLEAR,
});

export const fetchFinancePlanDisclosure = (data) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.FETCH_FINANCE_PLAN_DISCLOSURE),
  promise: ({ client }) => client.post(`/users/finances/simulation/html`, data),
});

export const rescheduleAppointment = (homeUniqueId, data) => ({
  types: getApiActionTypesValues(ACCOUNTS_CONST.RESCHEDULE_APPOINTMENT),
  promise: ({ client }) => client.put(`/homeowners/units/${homeUniqueId}/onboarding-appointment/reschedule`, data),
});
