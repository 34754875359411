import { RentalManagement, RentalStatus, RentalPlan, MoveOutOptions, AvailableForRentOptions } from 'models/enums';

export const RENTAL_STATUS_TEXT = {
  managingQuestion: 'Who is currently managing the rental?',
  rentingPlans: 'Lastly, how long do you plan to rent out this home?',
  rentingPlansPlaceholder: 'Renting Plans',
  ownerOccupiedMoveout: 'When are you moving out?',
  familyOccupiedMoveout: 'When are they moving out?',
  vacantMoveout: 'When do you think it will be?',
  moveoutAdditionalInfo: 'To enable the move-out, is there anything else that needs to be taken care of?',
  rentedQuestion: 'Have you ever rented {address} out before?',
  availableQuestion: 'Is it currently available to rent out?',
  currentRentPlaceHolder: 'Current Rent ($/Month)',
  previousRentPlaceholder: 'Previous Rent ($/Month)',
  moveOutQuestion: 'Move-out Date (MM/DD/YY)',
  moveOutInAMonth: 'Move-out Month (MM/YY)',
  moveoutAdditionalInfoPlaceholder: 'Outstanding Item(s) (OPTIONAL)',
  moveInDate: 'Move-In Date (MM/DD/YY)',
  checkboxText: 'Month-To-Month',
  leaseEndingMMYYPlaceholder: 'Lease Ending (MM/DD/YY)',
  leaseEndedMMYYPlaceholder: 'Lease Ended (MM/DD/YY)',
  rentAvailableMMYYPlaceholder: 'Available (MM/DD/YY)',
  shortTermRentalNote:
    'Note that Belong specializes in long-term rentals. We find Residents to love your home for a minimum of 1 year each.',
  moveOutWhenResidentsFoundNote:
    'We understand the sentiment! While it may be tempting to stay in your home as long as possible, it discourages higher quality residents from applying. Ultimately, your home will rent for 10-20% less, after months of unnecessary vacancy, costing you tens of thousands in annual income. For this reason, our process for finding someone to love your home begins when your home is completely vacant.',
  friendsMoveOutWhenResidentsFoundNote:
    'We understand the sentiment! While it may be tempting to let them stay in your home as long as possible, it discourages higher quality residents from applying. Ultimately, your home will rent for 10-20% less, after months of unnecessary vacancy, costing you tens of thousands in annual income. For this reason, our process for finding someone to love your home begins when your home is completely vacant.',
  notSureNote:
    'There are lots of moving pieces here. We get it! Note that you’ll need to at least provide a rough time frame in order for us to hold your spot.',
  notCurrentlyVacantQuestion: 'What needs to happen in order for it to become available?',
  notCurrentlyVacantPlaceholder: 'Outstanding Item(s)',
  vacantMoveOutDatePlaceholder: 'Availability Date (MM/DD/YY)',
  nextHomeQuestion: 'Are you planning to rent or own your next home?',
  nextHomeMoveoutNotSure: 'Not Sure Yet',
  nextHomeMoveoutDestinationPlaceholder: 'Destination City',
  nextHomeMoveoutDestinationNotSure: 'Not Sure Yet',
  nextHomeProcessOwningStatusQuestion: 'Where are you in the process?',
  nextHomeMoveoutRentingStatusNote:
    'We have homes for rent in {city}! Later, we’ll show you what we have available, in case there’s a fit.',
  otherOptions: 'What other options are you considering besides long-term renting?',
  otherOptionsPlaceholder: 'Alternatives',
  primaryConcerns: 'What are your primary concerns with picking someone to manage your home?',
  primaryConcernsPlaceholder: 'Primary Concerns',
};

export const rentalManagingSelectors = [
  {
    label: 'Me <span>(Self-Managing)</span>',
    key: RentalManagement.SelfManaging,
  },
  {
    label: 'A Property Manager',
    key: RentalManagement.PropertyManager,
  },
  {
    label: 'A Friend/ <span>Family Member</span>',
    key: RentalManagement.FriendFamilyMember,
  },
];

export const moveOutOptionSelectors = [
  {
    label: 'On a <br />specific date',
    key: MoveOutOptions.OnSpecificDate,
  },
  {
    label: 'In a <br />certain month',
    key: MoveOutOptions.InDateRange,
  },
  {
    label: 'When Belong <br />finds my Residents',
    key: MoveOutOptions.WhenResidentsAreFound,
  },
  {
    label: "I'm really <br />not sure",
    key: MoveOutOptions.NotSure,
  },
];

export const availableForRentOptionSelectors = [
  {
    label: 'On a specific date',
    key: AvailableForRentOptions.OnSpecificDate,
  },
  {
    label: 'In a certain month',
    key: AvailableForRentOptions.InCertainMonth,
  },
  {
    label: "I'm really not sure",
    key: AvailableForRentOptions.NotSure,
  },
];

export const rentalStatusSelectors = [
  {
    label: 'Renter Occupied',
    key: RentalStatus.RenterOccupied,
  },
  {
    label: 'Owner Occupied',
    key: RentalStatus.OwnerOccupied,
  },
  {
    label: 'Friends / Family Occupied',
    key: RentalStatus.FamilyOccupied,
  },
  {
    label: 'Vacant',
    key: RentalStatus.Vacant,
  },
];

export const rentalPlanItems = [
  {
    value: 'Less than a year',
    key: RentalPlan.LessThanYear,
  },
  {
    value: 'A year',
    key: RentalPlan.Year,
  },
  {
    value: 'A few years',
    key: RentalPlan.FewYears,
  },
  {
    value: 'The foreseeable future',
    key: RentalPlan.ForeseeableFuture,
  },
];
