import RouteParser from 'route-parser';

export const STEPS_DATA = {
  PERSONAL_INFORMATION: {
    path: '/resident/onboarding/personal-information',
    state: {
      step: 0,
    },
  },

  IDENTITY_CHECK: {
    path: '/resident/onboarding/identity-check',
    state: {
      step: 1,
    },
  },

  SUCCESS_PAGE: {
    path: '/resident/onboarding/success',
    state: {
      step: 2,
    },
  },
};

export const getResidentOnboardingStepPath = (step, params) => {
  const route = new RouteParser(step.path);
  let path = route.reverse(params);
  path = path.replace('?', '');
  return {
    pathname: path,
    state: step.state,
  };
};
