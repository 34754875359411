import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BillStatus, TransactionStatus } from 'models/enums/index';
import Label from '../../Label/Label';
import { LABEL_COLOR_TYPES } from '../../Label/labelTypes';

class PaymentStatusLabel extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    const { status } = this.props;

    const hasTransfers = '';

    switch (status) {
      case BillStatus.Refunded:
        return <Label text="Refunded" color={LABEL_COLOR_TYPES.GRAY} />;
      case BillStatus.Paid:
      case TransactionStatus.Completed:
        return <Label text="Paid" color={LABEL_COLOR_TYPES.GREEN} />;
      case BillStatus.Failed:
      case TransactionStatus.Failed:
        return <Label text="Failed" color={LABEL_COLOR_TYPES.RED} />;

      case BillStatus.PartialPayment: {
        if (!hasTransfers) {
          return <Label color={LABEL_COLOR_TYPES.WHITE} text="Incomplete" />;
        }
      }

      case BillStatus.Unpaid: {
        if (!hasTransfers) {
          return <Label text="Unpaid" color={LABEL_COLOR_TYPES.WHITE} />;
        }

        return <Label text="Processing" color={LABEL_COLOR_TYPES.BLUE} />;
      }

      case TransactionStatus.Canceled:
        return <Label text="Canceled" color={LABEL_COLOR_TYPES.GRAY} />;
      case TransactionStatus.Initiated:
      default:
        return <Label text="Processing" color={LABEL_COLOR_TYPES.BLUE} />;
    }
  }
}

export default PaymentStatusLabel;
