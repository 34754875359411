import { Text } from '@belong/ui';

export function OccupiedHomeBanner() {
  return (
    <div className="p-2sm rounded border border-solid border-green bg-green-translucent-light flex flex-col justify-between w-full mt-lg sm:mt-0 h-auto">
      <Text>
        Sounds good! In our experience, inspectors are likely to miss key issues when visiting a home that is occupied
        or&nbsp;furnished.
      </Text>
      <br />
      <div>
        <Text as="span">In that case, we require a second inspection once the home is vacant.</Text>
        &nbsp;
        <Text as="span" fontWeight="semibold">
          This second inspection costs $220, and may result in an increase in your home&apos;s&nbsp;vacancy.
        </Text>
      </div>
    </div>
  );
}
