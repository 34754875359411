import React, { Component } from 'react';
import PriceSection, { PRICE_SIZES } from 'pages/HousePage/PriceSection/PriceSection';
import PropTypes from 'prop-types';
import { getBathRoomString, getFormattedBeds } from '../../../../models/common/UnitBasicInfo';
import BulletList from '../../../BulletList/BulletList';
import Sqft from '../../General/Sqft/Sqft';

class HomeInfo extends Component {
  static propTypes = {
    unitBasic: PropTypes.object.isRequired,
    unitListingInfo: PropTypes.object,
    showUnitLevel: PropTypes.bool,
    showPrice: PropTypes.bool,
  };

  static defaultProps = {
    unitListingInfo: null,
    showUnitLevel: false,
    showPrice: false,
  };

  render() {
    const { unitBasic, showUnitLevel, showPrice, unitListingInfo, ...rest } = this.props;
    const listItems = [];

    if (showPrice) {
      listItems.push(<PriceSection price={unitListingInfo?.rentAmount} size={PRICE_SIZES.SMALL} />);
    }

    return (
      <BulletList
        {...rest}
        listItems={[
          ...listItems,
          getFormattedBeds(unitBasic, showUnitLevel),
          getBathRoomString(unitBasic),
          <Sqft key="2" sqft={unitBasic.areaInSquareFeet} />,
        ]}
      />
    );
  }
}

export default HomeInfo;
