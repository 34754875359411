import { useState, useEffect } from 'react';

function useIsMobileDevice() {
  var _a = useState(false),
    isMobile = _a[0],
    setIsMobile = _a[1];
  useEffect(function () {
    var checkIfMobileDevice = function () {
      var userAgent = navigator.userAgent;
      if (/android/i.test(userAgent)) {
        return true;
      }
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return true;
      }
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };
    setIsMobile(checkIfMobileDevice());
  }, []);
  return isMobile;
}

export { useIsMobileDevice };
