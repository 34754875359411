import { MouseEvent, useState } from 'react';
import { twMerge } from '@belong/common';
import { Image, Link } from '@belong/ui';
import { appStoreDownloadURL, playStoreDownloadURL, stores } from 'lease-signing-flow/constants/app';

export const AppStoreBadges = () => {
  const [isAppStoreBadgeHovered, setIsAppStoreBadgeHovered] = useState(false);
  const [isPlayStoreBadgeHovered, setIsPlayStoreBadgeHovered] = useState(false);

  const handleMouseOver = (event: MouseEvent<HTMLAnchorElement>) => {
    const { store } = event.currentTarget.dataset;

    if (store === stores.APPLE) {
      setIsAppStoreBadgeHovered(true);
    }

    if (store === stores.GOOGLE) {
      setIsPlayStoreBadgeHovered(true);
    }
  };

  const handleMouseOut = (event: MouseEvent<HTMLAnchorElement>) => {
    const { store } = event.currentTarget.dataset;

    if (store === stores.APPLE) {
      setIsAppStoreBadgeHovered(false);
    }

    if (store === stores.GOOGLE) {
      setIsPlayStoreBadgeHovered(false);
    }
  };

  return (
    <div className="flex flex-row items-start">
      <div className="mr-sm">
        <Link
          className="relative"
          data-store={stores.APPLE}
          href={appStoreDownloadURL}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
        >
          <Image
            alt="Download on the App Store"
            className={twMerge(
              'h-[50px] w-[160px] transition-all duration-[400ms] ease-in-out',
              isAppStoreBadgeHovered && 'opacity-0',
              !isAppStoreBadgeHovered && 'opacity-100'
            )}
            src="app/apple-store-badge.svg"
          />
          <Image
            alt="Download on the App Store"
            className={twMerge(
              'absolute h-[50px] w-[160px] top-0 transition-all duration-[400ms] ease-in-out',
              isAppStoreBadgeHovered && 'opacity-100',
              !isAppStoreBadgeHovered && 'opacity-0'
            )}
            src="app/apple-store-badge-hover.svg"
          />
        </Link>
      </div>
      <Link
        className="relative"
        data-store={stores.GOOGLE}
        href={playStoreDownloadURL}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
      >
        <Image
          alt="Get It On Google Play"
          className={twMerge(
            'h-[50px] w-[160px] transition-all duration-[400ms] ease-in-out',
            isPlayStoreBadgeHovered && 'opacity-0',
            !isPlayStoreBadgeHovered && 'opacity-100'
          )}
          src="app/play-store-badge.svg"
        />
        <Image
          alt="Get It On Google Play"
          className={twMerge(
            'absolute h-[50px] w-[160px] top-0 transition-all duration-[400ms] ease-in-out',
            isPlayStoreBadgeHovered && 'opacity-100',
            !isPlayStoreBadgeHovered && 'opacity-0'
          )}
          src="app/play-store-badge-hover.svg"
        />
      </Link>
    </div>
  );
};
