import { Join } from 'pages/AgreementFlow/AgreementFlowSteps/join/join';
import { BASE_PATHS } from 'routes/paths';
import { getStepByName } from '../consts';
import Ownership from './Ownership/Ownership';
import Payment from './Payment/Payment';
import Plan from './Plan/Plan';
import SuccessPage from './SuccessPage/SuccessPage';
import UserPersonal from './UserPersonal/UserPersonal';

const getStepPath = (stepName) => {
  const stepConfig = getStepByName(stepName);
  return `${BASE_PATHS.AGREEMENT_FLOW}/${stepConfig.key}`;
};

export const STEPS_ROUTE_MAP = {
  PLAN: {
    path: getStepPath('Plan'),
    form: Plan,
  },
  USER_PERSONAL: {
    path: getStepPath('UserPersonal'),
    form: UserPersonal,
  },
  OWNERSHIP: {
    path: getStepPath('Ownership'),
    form: Ownership,
  },
  PAYMENT: {
    path: getStepPath('Payment'),
    form: Payment,
  },
  SIGN: {
    path: getStepPath('Sign'),
    form: Join,
  },
  SUCCESS: {
    path: `${BASE_PATHS.AGREEMENT_FLOW}/success`,
    form: SuccessPage,
  },
};
