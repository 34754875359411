import React, { Component } from 'react';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskYYYY, unmaskYYYY } from 'components/Input/masks';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import String from 'components/String/String';
import Space from 'corecomponents/Space/Space';
import arrayMutators from 'final-form-arrays';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEqual, isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { composeValidators, required, validPastYYYYString } from 'utils/validation';
import GreenInfoBox from '../../../../components/GreenInfoBox/GreenInfoBox';
import PropertyRentalLabel from '../components/PropertyRentalLabel/PropertyRentalLabel';

const { FormLayoutHeader } = FormLayout;
const HOB = HOMEOWNER_ONBOARDING_STRINGS.UnitConditions;

class UnitCondition extends Component {
  static propTypes = {
    groups: PropTypes.object.isRequired,
    currentStepData: PropTypes.object.isRequired,
    currentStepResources: PropTypes.object.isRequired,
    onSaveAndNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  onSubmit = async (values) => {
    const { onSaveAndNext } = this.props;

    if (values.renovatedHomeBefore === false) {
      values.lastRenovation = 0;
    }

    onSaveAndNext(values);
  };

  getInitialValues() {
    const { currentStepData } = this.props;

    return {
      ...currentStepData,
      unitConditions: {
        ...currentStepData.unitConditions,
        renovatedHomeBefore: !isNil(currentStepData.unitConditions?.condition)
          ? !!currentStepData.unitConditions?.lastRenovation
          : null,
      },
    };
  }

  render() {
    const {
      groups: { homes },
      currentStepResources: { property, home },
      onBack,
    } = this.props;

    const buttonLabels = [
      {
        name: 'Quite Rundown',
        enum: 'QuiteRundown',
      },
      {
        name: 'Needs Work',
        enum: 'NeedsWork',
      },
      {
        name: 'Quite Average',
        enum: 'QuiteAverage',
      },
      {
        name: 'Pretty Good',
        enum: 'PrettyGood',
      },
      {
        name: 'Shiny Palace',
        enum: 'ShinyPalace',
      },
    ];
    return (
      <>
        {homes.length > 1 && <PropertyRentalLabel property={property} home={home} />}
        <FormLayoutHeader pretitle={HOB.subtitle} title={HOB.title} />

        <Form
          mutators={{
            ...arrayMutators,
          }}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: 'Next',
                type: 'submit',
              }}
              nextButtonWrapperProps={nextButtonProps}
              previousStepProps={{
                onClick: onBack,
                to: null,
              }}
            />
          )}
          initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
          initialValues={this.getInitialValues()}
          onSubmit={this.onSubmit}
          getForm={() => (
            <>
              <FormLayout.Section>
                {/* <UnitInfo info={home.basicInfo} /> */}
                <Field
                  name="unitConditions.condition"
                  component={SelectorFinalFormAdapter}
                  validate={required}
                  buttons={[
                    {
                      label: buttonLabels[0].name,
                      key: buttonLabels[0].enum,
                    },
                    {
                      label: buttonLabels[1].name,
                      key: buttonLabels[1].enum,
                    },
                    {
                      label: buttonLabels[2].name,
                      key: buttonLabels[2].enum,
                    },
                    {
                      label: buttonLabels[3].name,
                      key: buttonLabels[3].enum,
                    },
                    {
                      label: buttonLabels[4].name,
                      key: buttonLabels[4].enum,
                    },
                  ]}
                  type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                  fluid
                />
                <Row>
                  <Col md={6}>
                    <Field
                      name="unitConditions.appliancesAge"
                      component={DropdownFinalFormAdapter}
                      placeholder={HOB.applianceAverageAge}
                      items={[
                        { value: 'Older', key: 'Older' },
                        { value: 'Recently Updated', key: 'RecentlyUpdated' },
                        { value: 'Brand New', key: 'BrandNew' },
                      ]}
                      validate={required}
                    />
                  </Col>
                </Row>
              </FormLayout.Section>
              <FormLayout.Section sectionTitle={HOB.renovatedThisHome}>
                <Field
                  name="unitConditions.renovatedHomeBefore"
                  component={YesNoFinalFormAdapter}
                  validate={required}
                />
                <Condition when="unitConditions.renovatedHomeBefore" is>
                  <Row>
                    <Col md={6}>
                      <Field
                        placeholder={HOB.lastRenovationDate}
                        name="unitConditions.lastRenovation"
                        component={InputFinalFormAdapter}
                        mask={maskYYYY}
                        unmask={unmaskYYYY}
                        validate={composeValidators(required, validPastYYYYString)}
                      />
                    </Col>
                  </Row>
                </Condition>
              </FormLayout.Section>
              <FormLayout.Section sectionTitle={HOB.isHomeCurrentlyFurnished}>
                <Field
                  fluid
                  type={SELECTOR_TYPES.SMALLTEXTBUTTONWIDTH}
                  name="unitFurnishing.isFurnished"
                  component={SelectorFinalFormAdapter}
                  buttons={HOB.isHomeCurrentlyFurnishedButtons}
                  validate={required}
                />
                <Condition when="unitFurnishing.isFurnished" is>
                  <Space />

                  <GreenInfoBox>
                    <String string={HOB.homeCurrentlyFinishedText} />
                  </GreenInfoBox>
                </Condition>
              </FormLayout.Section>
              <Condition when="unitFurnishing.isFurnished" is>
                <FormLayout.Section sectionTitle={HOB.rentingYourHomeUnfurnished}>
                  <Field
                    name="unitFurnishing.isUserWillingToRentHomeUnfurnished"
                    component={YesNoFinalFormAdapter}
                    validate={required}
                  />
                </FormLayout.Section>
              </Condition>
            </>
          )}
        />
      </>
    );
  }
}

export default UnitCondition;
