var SIZE_MAP = {
  default: 'h-checkbox w-checkbox',
  small: 'h-2sm w-2sm'
};
var UNCHECKED_CLASS_NAMES = {
  primary: 'text-dark-gray group-hover:text-navy transition-colors duration-hover',
  secondary: 'text-dark-gray group-hover:text-white transition-colors duration-hover',
  tertiary: 'text-gray group-hover:text-white transition-colors duration-hover'
};
var CHECKED_CLASS_NAMES = {
  primary: 'text-navy group-hover:text-dark-navy transition-colors duration-hover',
  secondary: 'text-white group-hover:text-green transition-colors duration-hover',
  tertiary: 'text-green group-hover:text-green transition-colors duration-hover'
};

export { CHECKED_CLASS_NAMES, SIZE_MAP, UNCHECKED_CLASS_NAMES };
