import React, { Component } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon from 'components/GeneralIcon/GeneralIcon';
import PaymentIconDisplay from 'components/Payments/PaymentIconDisplay/PaymentIconDisplay';
import PaymentMethod from 'models/common/PaymentMethod';
import { getPaymentMethodIcon } from 'models/utilities/paymentMethods';
import PropTypes from 'prop-types';
import BDate, { DATE_TYPES } from '../../BDate/BDate';
import styles from './PaymentMethodDisplay.module.css';
import PaymentMethodNamingDisplay from './PaymentMethodNamingDisplay/PaymentMethodNamingDisplay';

const cx = classNames.bind(styles);

class PaymentMethodDisplay extends Component {
  static propTypes = {
    paymentMethod: PropTypes.instanceOf(PaymentMethod),
    transactionMethod: PropTypes.object,
    paymentMethodType: PropTypes.string.isRequired,
    hasFee: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    time: PropTypes.string,
    hasNoPaymentMethod: PropTypes.bool,

    // custom prop to handle hiding is verified on Pay deposit page:
    ignoreIsNotVerified: PropTypes.bool,
  };

  static defaultProps = {
    paymentMethod: null,
    // Temporary until we get TransactionsDeposits working.
    transactionMethod: null,
    hasFee: false,
    className: '',
    disabled: false,
    time: null,
    hasNoPaymentMethod: false,
    ignoreIsNotVerified: false,
  };

  render() {
    const {
      hasFee,
      className,
      paymentMethod,
      disabled,
      paymentMethodType,
      transactionMethod,
      time,
      hasNoPaymentMethod,
      ignoreIsNotVerified,
    } = this.props;

    let displayItems;

    if (hasNoPaymentMethod) {
      displayItems = <div>No payment method selected </div>;
    } else if (paymentMethod) {
      displayItems = (
        <PaymentIconDisplay
          className={className}
          disabled={disabled}
          isNotVerified={!paymentMethod.isVerified}
          noCustomWidth
          icon={<GeneralIcon icon={getPaymentMethodIcon(paymentMethod.paymentMethod, paymentMethodType).DEFAULT} />}
          title={
            <>
              <PaymentMethodNamingDisplay
                accountType={paymentMethod?.achDetails?.accountType}
                paymentMethod={paymentMethod?.paymentMethod}
                accountLastDigits={paymentMethod?.lastDigits}
                referenceNumber={paymentMethod?.referenceNumber}
                bankName={paymentMethod?.achDetails?.bankName || paymentMethod?.creditCardDetails?.cardType}
                otherPaymentType={paymentMethod?.otherPaymentType}
                hasFee={hasFee}
                showFee
              />
              {!ignoreIsNotVerified && !paymentMethod.isVerified && (
                <div className={cx('not-verified-payment')}>NOT VERIFIED</div>
              )}
            </>
          }
        />
      );
    } else {
      displayItems = (
        <PaymentIconDisplay
          className={className}
          noCustomWidth
          disabled={disabled}
          icon={
            <GeneralIcon icon={getPaymentMethodIcon(transactionMethod?.paymentMethod, paymentMethodType).DEFAULT} />
          }
          title={
            <span>
              <PaymentMethodNamingDisplay
                accountType={transactionMethod?.accountType}
                paymentMethod={transactionMethod?.paymentMethod}
                accountLastDigits={transactionMethod?.accountLastDigits}
                referenceNumber={transactionMethod?.referenceNumber}
                bankName={transactionMethod?.bankName}
                otherPaymentType={transactionMethod?.otherPaymentType}
                hasFee={hasFee}
                showFee
              />
              {time && (
                <span className="md:hidden text-p1 font-regular">
                  {' '}
                  <BDate time={time} formatType={DATE_TYPES.DAY_MONTH_YEAR_TIME_TIMEZONE} />
                </span>
              )}
            </span>
          }
        />
      );
    }

    return displayItems;
  }
}

export default PaymentMethodDisplay;
