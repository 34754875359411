export const MILESTONE_MAP = {
  InspectionScheduled: 'Scheduling Your Inspection',
  InspectionCompleted: 'Completing Your Inspection',
  PostInspectionProposalSent: 'Sending Your Report',
  PostInspectionProposalApproved: 'Approving Your Report',
  ShowReady: 'Readying Your Home To Be Shown',
  FullListingPublished: 'Publishing Your Listing',
  LeaseSigned: 'Finding Your Home Love',
  MoveInReady: 'Readying Your Home To Be Moved Into',
  ResidentMovedIn: 'Moving Your Residents In',
  ManagingAdoptedResidents: 'Starting To Manage Your Residents',
  ListingRepublished: 'Publishing Your Listing',
};

export const NEXT_REQUIRED_EVENTS_BLACK_LIST = {
  ResidentGaveNotice: 'ResidentGaveNotice',
  PricingAdjusted: 'PricingAdjusted',
  SignageCompleted: 'SignageCompleted',
};

export const TAB_ITEMS = [
  {
    key: 'timeline',
    text: 'My Home Timeline',
  },
  {
    key: 'residents',
    text: 'My Wonderful Residents',
  },
];
