export const ANALYTICS_MODAL_NAMES = {
  COVID_CAROUSEL: 'covid_carousel',
  CALCULATOR: 'calculator',
  LOGIN: 'login',
  EXTRAORDINARY_GUARANTEES: 'extraordinary_guarantees',
  IMPROVEMENTS: 'improvements',
  IMPROVEMENTS_IMPORTANCE: 'improvements_importance',
  PAYMENT_PLAN: 'payment-plan',
  IMPROVEMENTS_SUMMARY: 'improvements-summary',
  LIGHT_ACCOUNT_CREATION: 'light-account-creation',
  SELF_SERVE: 'self-serve',
  PROCESSING_VERIFICATIONS: 'processing_verifications',
  HOW_IT_WORKS: 'how_it_works',
  WEAR_AND_TEAR: 'wear_and_tear',
};
