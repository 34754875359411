import React from 'react';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import classNames from 'classnames/bind';
import Image from 'components/Image/Image';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import { Box, Flex, Text } from 'design-system';
import ModalButton from 'layouts/ModalLayout/ModalButton';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import styles from './ImprovementImportanceModal.module.css';

const cx = classNames.bind(styles);

const ImprovementImportanceModal = ({ onHide, show }) => (
  <ModalV2
    className={cx('no-padding')}
    closable={false}
    closeButton
    name={ANALYTICS_MODAL_NAMES.IMPROVEMENTS_IMPORTANCE}
    noPadding
    show={show}
    onHide={onHide}
    overflowHidden
  >
    <ModalLayout>
      <Box paddingTop="xs">
        <Box paddingX={['sm', 'xl']}>
          <Flex alignItems="center" justifyContent="center" marginTop={['2xl', 0]} paddingX={['sm', 0]}>
            <Flex alignItems="center" justifyContent="center" width={[290, 290, 500, 500, 620]}>
              <ModalLayout.Title>{POST_INSPECTION_FLOW_STRINGS['approvals.importance.title']}</ModalLayout.Title>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Flex alignItems="center" justifyContent="center" paddingTop="xs" width={['unset', 540]}>
              <ModalLayout.SubTitle className={cx('subtitle')} noPadding>
                <Text>{POST_INSPECTION_FLOW_STRINGS['approvals.importance.subtitle']}</Text>
              </ModalLayout.SubTitle>
            </Flex>
          </Flex>
        </Box>
        <ModalLayout.Content>
          <Flex
            alignItems="center"
            flexDirection={['column', 'row']}
            justifyContent="space-around"
            marginTop={['xl', 0]}
          >
            <Image
              className={cx('image')}
              src="/image_assets/inspection_report_modal_01.png"
              alt="lower-vacancy-upfront"
            />
            <Flex
              alignItems="flex-start"
              flexDirection="column"
              justifyContent="center"
              marginLeft={[0, 'lg']}
              marginTop={['sm', 0]}
              paddingX={['sm', 0]}
            >
              <Box marginBottom="xs">
                <Text fontSize="h3" fontWeight="semibold">
                  {POST_INSPECTION_FLOW_STRINGS['approvals.importance.vacancy.title']}
                </Text>
              </Box>
              <Box width={['unset', 324]}>
                <Text fontWeight="300">{POST_INSPECTION_FLOW_STRINGS['approvals.importance.vacancy.detail']}</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            flexDirection={['column', 'row']}
            justifyContent="space-around"
            marginTop={['xl', 'lg']}
          >
            <Flex
              alignItems="flex-start"
              flexDirection="column"
              justifyContent="center"
              marginRight={[0, 'lg']}
              marginTop={['sm', 0]}
              order={['2', 'unset']}
              paddingX={['sm', 0]}
            >
              <Box marginBottom="xs">
                <Text fontSize="h3" fontWeight="semibold">
                  {POST_INSPECTION_FLOW_STRINGS['approvals.importance.headaches.title']}
                </Text>
              </Box>
              <Box width={['unset', 324]}>
                <Text>{POST_INSPECTION_FLOW_STRINGS['approvals.importance.headaches.detail']}</Text>
              </Box>
            </Flex>
            <Image
              className={cx('image')}
              src="/image_assets/inspection_report_modal_02.png"
              alt="save-yourself-headaches"
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            marginY={[0, 'sm']}
            paddingX={['sm', 0]}
            paddingY={['sm', 'lg']}
          >
            <ModalButton label={POST_INSPECTION_FLOW_STRINGS['approvals.importance.cta']} onClick={onHide} />
          </Flex>
        </ModalLayout.Content>
      </Box>
    </ModalLayout>
  </ModalV2>
);

ImprovementImportanceModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export { ImprovementImportanceModal };
