import * as React from 'react';
import { Text, Image } from '@belong/ui';

type TourHostTileProps = { profileImageUrl: string; firstName: string; lastName: string };

export function TourHostTile({ profileImageUrl, firstName, lastName }: TourHostTileProps) {
  return (
    <div className="flex mt-lg">
      <div className="w-xl h-xl mr-xs rounded-full border-2 border-solid border-navy overflow-hidden bg-green relative">
        <div className="absolute w-2xl h-2xl left-1/2 -translate-x-1/2" style={{ top: 2 }}>
          <Image src={profileImageUrl} className="object-cover" alt="" />
        </div>
      </div>

      <div>
        <Text variant="p1">Hosted By</Text>
        <Text fontWeight="semibold">
          {firstName} {lastName}
        </Text>
      </div>
    </div>
  );
}
