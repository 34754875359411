export const ANALYTICS_CLICK_ACTIONS = {
  MY_ACCOUNT_VIEW_OPTIONS: 'my_account_view_options',
  HOMEOWNER_SIGN_UP: 'homeowner_sign_up',
  CALCULATOR_OPEN: 'calculator_open',
  CALCULATOR_SEE_MORE_MATH: 'calculator_see_more_math',
  COVID_LEARN_MORE: 'covid_learn_more',
  MODAL_CLOSE: 'modal_close',
  START_HOME_TOUR_VIDEO: 'home_tour_video',
  EXTRAORDINARY_GUARANTEES: 'extraordinary_guarantees',
  HOUSE_PAGE_DETAIL: 'house_page_detail',
  LISTING_MAP: 'listing_map',
  SELF_SERVE: 'self-serve',
  NON_SELF_SERVE: 'non-self-serve',
  INQUIRE_NOW: 'inquire-now',
  INITIAL_ESTIMATE_OPEN: 'initial_estimate_open',
};
