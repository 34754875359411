import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PAYMENTS_FEE_CONST } from '@belong/common';
import classNames from 'classnames/bind';
import Form from 'components/Form/Form';
import Spinner from 'components/Spinner/Spinner';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import CreditCardPayment from 'formcomponents/CreditCardPayment/CreditCardPayment';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import {
  createApplicationFeeAccount,
  fetchAllApplicationsForUser,
  fetchApplicantInfo,
} from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationApplicantInfo,
  selectResidentApplicationBasicInfo,
  selectResidentApplicationDisplayCosignerPage,
  selectResidentApplicationFeeInfo,
  selectResidentApplicationId,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import { addCreditCardPaymentMethod } from 'store/redux/user/actions';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { ApplicantType } from '../../../../models/enums';
import { getString } from '../../../../strings';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './ApplicationFee.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.application_fee;

const { FormLayoutHeader } = FormLayout;

class ApplicationFee extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    applicationId: PropTypes.string.isRequired,
    houseId: PropTypes.string.isRequired,
    fetchApplicantInfo: PropTypes.func.isRequired,
    fetchAllApplicationsForUser: PropTypes.func.isRequired,
    applicationFeeStatus: PropTypes.object.isRequired,
    createApplicationFeeAccount: PropTypes.func.isRequired,
    addCreditCardPaymentMethod: PropTypes.func.isRequired,
    displayCosignerPage: PropTypes.bool.isRequired,
    applicantInfo: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      awaitFetchComplete: true,
      applicationFeeSuccessfullyPaid: false,
      formData: {
        paymentMethod: null,
      },
    };
  }

  async componentDidMount() {
    const {
      fetchApplicantInfo: fetchApplicantInfoAction,
      fetchAllApplicationsForUser: fetchAllApplicationsForUserAction,
    } = this.props;

    // Always fetch here because we don't call fetchAllApplicationsForUser
    // after updating the data on people and pets screen
    try {
      await Promise.all([fetchApplicantInfoAction(), fetchAllApplicationsForUserAction()]);
    } catch (err) {
      console.error(err);
    }

    this.setInitialValues();
  }

  setInitialValues() {
    const { applicationFeeStatus } = this.props;
    let applicationFeeSuccessfullyPaid = false;

    if (applicationFeeStatus.isPaid) {
      applicationFeeSuccessfullyPaid = true;
    }

    this.setState({
      awaitFetchComplete: false,
      applicationFeeSuccessfullyPaid,
    });
  }

  handleSubmit = async (values) => {
    const {
      applicationId,
      houseId,
      history: { push },
      applicantInfo,
      displayCosignerPage,
      addCreditCardPaymentMethod: addCreditCardPaymentMethodAction,
      createApplicationFeeAccount: createApplicationFeeAccountAction,
    } = this.props;
    const { applicationFeeSuccessfullyPaid } = this.state;
    const { applicantType } = applicantInfo;

    if (!applicationFeeSuccessfullyPaid) {
      this.setState({
        awaitFetchComplete: true,
      });

      try {
        const [lastAddedPaymentMethod] = await addCreditCardPaymentMethodAction({
          creditCardNumber: values.creditCardNumber,
          expirationDate: values.expirationDate,
          billingZipCode: values.billingZipCode,
          cvc: values.cvc,
        });

        if (lastAddedPaymentMethod) {
          const serverObject = {
            accountId: lastAddedPaymentMethod.accountId,
          };

          await createApplicationFeeAccountAction(serverObject);
        }
      } catch (e) {
        this.setState({
          awaitFetchComplete: false,
          errorMessage: e[0].message,
          formData: { ...values },
        });
        return;
      }
    }

    if (displayCosignerPage && applicantType !== ApplicantType.CoSigner) {
      push(getResidentApplicationStepPath(STEPS_CONFIG.ADD_COSIGNER, { houseId, applicationId }));
    } else {
      push(getResidentApplicationStepPath(STEPS_CONFIG.IDENTITY_VERIFICATION, { houseId, applicationId }));
    }
  };

  render() {
    const { awaitFetchComplete, applicationFeeSuccessfullyPaid, formData, errorMessage } = this.state;

    if (awaitFetchComplete) {
      return (
        <Row>
          <Col md={12}>
            <div className={cx('spinner')}>
              <Spinner />
            </div>
          </Col>
        </Row>
      );
    }

    let ctaLabel = RAS.next_button_text;

    if (applicationFeeSuccessfullyPaid) {
      ctaLabel = RAS.next_button_text_paid;
    } else if (!applicationFeeSuccessfullyPaid) {
      ctaLabel = getString(RAS.next_button_credit_card, {
        fee: PAYMENTS_FEE_CONST.APPLICATION_FEE_CC,
      });
    }

    return (
      <FormLayout>
        <FormLayoutHeader
          title={RAS.title}
          subtitle={getString(RAS.subTitle, {
            fee: PAYMENTS_FEE_CONST.APPLICATION_FEE_CC,
          })}
        />
        <Form
          initialValues={formData}
          onSubmit={this.handleSubmit}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: ctaLabel,
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          getForm={({ handleSubmit, values }) => (
            <div className={cx('form-wrapper')}>
              <form onSubmit={handleSubmit}>
                {errorMessage && (
                  <div className={cx('error-wrapper')}>
                    <div className={cx('error-message')}>{errorMessage}</div>
                  </div>
                )}
                <CreditCardPayment formValues={values} disableFields={applicationFeeSuccessfullyPaid} />
              </form>
            </div>
          )}
        />
      </FormLayout>
    );
  }
}

export default connect(
  (state) => ({
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    basicInfo: selectResidentApplicationBasicInfo(state),
    applicationFeeStatus: selectResidentApplicationFeeInfo(state),
    displayCosignerPage: selectResidentApplicationDisplayCosignerPage(state),
    applicantInfo: selectResidentApplicationApplicantInfo(state),
  }),
  {
    fetchApplicantInfo,
    createApplicationFeeAccount,
    addCreditCardPaymentMethod,
    fetchAllApplicationsForUser,
  }
)(ApplicationFee);
