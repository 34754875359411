import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Text } from '@belong/ui';
import clsx from 'clsx';
import { PAYMENT_SCREENS } from 'containercomponents/Modals/PaymentsModal/PaymentsModal';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import {
  fetchPaymentMethods,
  fetchUserPaymentMethodConfigurations,
  fetchUserAutoSplitPaymentMethod,
  updatePaymentMethod,
  updateUserAutoSplitPaymentMethod,
} from 'store/redux/user/actions';
import { selectFullUser, selectUserAutoSplit, selectUserPaymentMethodConfigurations } from 'store/redux/user/selectors';
import { PaymentPreference, PaymentPriority } from '../../models/enums';
import { getString } from '../../strings';
import { FEEDBACK_STRINGS } from '../../strings/errors.string';
import PaymentTileContainer from '../PaymentTileContainer/PaymentTileContainer';

function PaymentMethodContainer(props) {
  const {
    isSelector,
    primaryOnly,
    isRenter = false,
    isReferral = false,
    maintenanceOnly,
    hasLabels,
    updateUserAutoSplitPaymentMethod: updateAutoSplit,
    shouldShowAutoSplitPaymentMethod,
    isAutoSplitEnabled,
    defaultPriority,
  } = props;

  const [autoSplitError, setAutoSplitError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const {
        fetchPaymentMethods: fetchPaymentMethodsAction,
        fetchUserPaymentMethodConfigurations: fetchUserPaymentMethodConfigurationsAction,
        fetchUserAutoSplitPaymentMethod: fetchUserAutoSplitAction,
      } = props;

      try {
        await Promise.all([
          fetchPaymentMethodsAction(),
          fetchUserAutoSplitAction(),
          fetchUserPaymentMethodConfigurationsAction(),
        ]);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const handlePaymentSelection = async (priority, accountId) => {
    const { updatePaymentMethod: updatePaymentMethodAction } = props;
    try {
      await updatePaymentMethodAction(accountId, priority, defaultPriority);
    } catch (err) {
      console.error(err);
    }
  };

  const onAutoSplitError = (error) => {
    if (error[0].message.includes('Yearly Payout Plan')) {
      setAutoSplitError('Split It payment method can not be enabled because you are in a Yearly Payout Plan.');
    }
  };

  function getForcePaymentMethod(paymentType) {
    const { paymentMethodConfigurations } = props;

    const allowedTypes = paymentMethodConfigurations?.[paymentType]?.allowedPaymentMethods || {};
    const allowedTypesArray = Object.keys(allowedTypes).filter((allowedType) => allowedTypes[allowedType]);

    if (allowedTypesArray.length === 1) {
      return allowedTypesArray[0];
    }

    return null;
  }

  useEffect(() => {
    if (typeof autoSplitError !== 'undefined') {
      setTimeout(() => {
        setAutoSplitError(undefined);
      }, 6000);
    }
  }, [autoSplitError]);

  const renderPaymentTile = (preference, showPaymentTileMarginOnMobile, showAutoSplit = false, priority = PaymentPriority.Primary) => {
    const { showPriority, noCustomWidth } = props;
    const paymentType = preference;
    return (
      <PaymentTileContainer
        isRenter={isRenter}
        isReferral={isReferral}
        showPriority={showPriority}
        isSelector={isSelector}
        showPaymentTileMarginOnMobile={showPaymentTileMarginOnMobile}
        paymentType={paymentType}
        priority={priority}
        noCustomWidth={noCustomWidth}
        forcePaymentMethod={getForcePaymentMethod(paymentType)}
        onPaymentSelection={handlePaymentSelection}
        onAutoSplitSelection={updateAutoSplit}
        onAutoSplitError={onAutoSplitError}
        shouldShowAutoSplitPaymentMethod={
          shouldShowAutoSplitPaymentMethod && preference === PaymentPreference.Maintenance
        }
        isAutoSplitEnabled={isAutoSplitEnabled && showAutoSplit}
        ignorePaymentMethodId={null}
        hasLabels={hasLabels}
        paymentModalProps={{
          screen: PAYMENT_SCREENS.PAYMENT_SELECTION,
          forcePaymentMethod: getForcePaymentMethod(paymentType),
          ctaLabel: `Set ${preference}`,
          errorScreenProps: {
            title: FEEDBACK_STRINGS.set_payment_method.error.title,
            subtitle: getString(FEEDBACK_STRINGS.set_payment_method.error.subtitle, {
              priority: preference ? preference.toLowerCase() : '',
            }),
            ctaLabel: FEEDBACK_STRINGS.set_payment_method.error.ctaLabel,
          },
          successScreenProps: {
            title: FEEDBACK_STRINGS.set_payment_method.success.title,
            subtitle: getString(FEEDBACK_STRINGS.set_payment_method.success.subtitle, {
              priority: preference ? preference.toLowerCase() : '',
            }),
            ctaLabel: FEEDBACK_STRINGS.set_payment_method.success.ctaLabel,
          },
        }}
      />
    );
  };

  if (isSelector || primaryOnly) {
    return (
      <div className="mt-sm">
        <Row>
          <Col xs={12} lg={12}>
            {renderPaymentTile(isRenter ? PaymentPreference.Rent : PaymentPreference.Payout, true)}
          </Col>
        </Row>
      </div>
    );
  }

  if (isReferral) {
    return (
      <div className="mt-sm">
        <Row>
          <Col xs={12} lg={12}>
            {renderPaymentTile(PaymentPreference.Payout, true)}
          </Col>
        </Row>
      </div>
    );
  }

  if (maintenanceOnly) {
    return (
      <div className="mt-sm">
        <Row>
          <Col xs={12} lg={12}>
            {renderPaymentTile(PaymentPreference.Maintenance, true, false, defaultPriority)}
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <div className={clsx('mt-sm grid grid-cols-1 gap-sm lg:grid-cols-2', autoSplitError ? 'mb-sm' : 'mb-2xl')}>
        {renderPaymentTile(isRenter ? PaymentPreference.Rent : PaymentPreference.Payout, true)}
        {renderPaymentTile(PaymentPriority.Maintenance, false, isAutoSplitEnabled)}
        {!isRenter && !isReferral && renderPaymentTile(PaymentPreference.RentalHomeInsurance, false, false)}
      </div>
      {autoSplitError && <Text className="text-red">{autoSplitError}</Text>}
    </>
  );
}

PaymentMethodContainer.propTypes = {
  fetchPaymentMethods: PropTypes.func.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired,
  fetchUserAutoSplitPaymentMethod: PropTypes.func.isRequired,
  fetchUserPaymentMethodConfigurations: PropTypes.func.isRequired,
  updateUserAutoSplitPaymentMethod: PropTypes.func.isRequired,
  isSelector: PropTypes.bool,
  primaryOnly: PropTypes.bool,
  maintenanceOnly: PropTypes.bool,
  showPriority: PropTypes.bool,
  noCustomWidth: PropTypes.bool,
  isRenter: PropTypes.bool,
  hasLabels: PropTypes.bool,
  isAutoSplitEnabled: PropTypes.bool,
  shouldShowAutoSplitPaymentMethod: PropTypes.bool,
  isReferral: PropTypes.bool,
  paymentMethodConfigurations: PropTypes.object,
  defaultPriority: PropTypes.string
};

PaymentMethodContainer.defaultProps = {
  isSelector: false,
  primaryOnly: false,
  maintenanceOnly: false,
  showPriority: true,
  noCustomWidth: false,
  hasLabels: true,
  shouldShowAutoSplitPaymentMethod: false,
  isReferral: false,
  defaultPriority: PaymentPriority.Primary
};

export default connect(
  (state) => ({
    fullUser: selectFullUser(state),
    isAutoSplitEnabled: selectUserAutoSplit(state),
    paymentMethodConfigurations: selectUserPaymentMethodConfigurations(state),
  }),
  {
    fetchPaymentMethods,
    fetchUserPaymentMethodConfigurations,
    updatePaymentMethod,
    fetchUserAutoSplitPaymentMethod,
    updateUserAutoSplitPaymentMethod,
  }
)(PaymentMethodContainer);
