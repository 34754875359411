import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import Icon, { ICONS } from 'components/Icon/Icon';
import { NewMediaUploadAdapter, MEDIA_LABEL, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { GRID_SIZE, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import Space from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { integer, required } from 'utils/validation';
import { AutoCompleteFinalFormAdapter } from '../../components/AutoComplete/AutoComplete';
import { InputFinalFormAdapter } from '../../components/Input/Input';
import styles from './MultiplePetDetails.module.css';
import { CAT_BREEDS, DOG_BREEDS, OTHER_SPECIES, PET_TYPES } from './constants.js';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.people_and_pets;

const validateNumericRequired = (value) => required(value) || integer(value);

export default class MultiplePetDetails extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    form: PropTypes.object.isRequired,
    getPetNotes: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  render() {
    const { name, values, index, form, getPetNotes } = this.props;
    const { pet } = get(values, name) || {};
    const [note] = getPetNotes(pet);

    return (
      <>
        {index > 0 && <Space />}
        <Row>
          <Col md={12}>
            <Field
              name={`${name}.pet.petType`}
              component={SelectorFinalFormAdapter}
              onChangeCustom={(value) => {
                // reset necessary fields
                form.change(`${name}.pet`, { ...pet, petType: value, subType: null, livesInCageOrAquarium: null });
              }}
              grid
              gridSize={GRID_SIZE.SMALL}
              responsive
              buttons={[
                {
                  label: PET_TYPES.DOG,
                  icon: <Icon icon={ICONS.PET_DOG.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.PET_DOG.INVERSE} responsive />,
                  key: PET_TYPES.DOG,
                },
                {
                  label: PET_TYPES.CAT,
                  icon: <Icon icon={ICONS.PET_CAT.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.PET_CAT.INVERSE} responsive />,
                  key: PET_TYPES.CAT,
                },
                {
                  label: PET_TYPES.OTHER,
                  icon: <Icon icon={ICONS.PET_OTHER.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.PET_OTHER.INVERSE} responsive />,
                  key: PET_TYPES.OTHER,
                },
              ]}
              validate={required}
            />
          </Col>
        </Row>
        <Condition when={`${name}.pet.petType`} hasValue>
          <Row>
            <Col md={4}>
              <Field
                name={`${name}.pet.name`}
                component={InputFinalFormAdapter}
                placeholder={RAS.pets_name_label}
                validate={required}
              />
            </Col>
            <Condition when={`${name}.pet.petType`} is={PET_TYPES.OTHER}>
              <Col md={4}>
                <Field
                  name={`${name}.pet.subType`}
                  component={AutoCompleteFinalFormAdapter}
                  placeholder="Species"
                  validate={required}
                  items={OTHER_SPECIES}
                  hideAutoCompleteIcon
                  allowUserInput
                />
              </Col>
            </Condition>
            <Condition when={`${name}.pet.petType`} is={PET_TYPES.DOG}>
              <Col md={4}>
                <Field
                  name={`${name}.pet.subType`}
                  component={AutoCompleteFinalFormAdapter}
                  placeholder="Breed"
                  validate={required}
                  items={DOG_BREEDS}
                  hideAutoCompleteIcon
                  allowUserInput
                />
              </Col>
            </Condition>
            <Condition when={`${name}.pet.petType`} is={PET_TYPES.CAT}>
              <Col md={4}>
                <Field
                  name={`${name}.pet.subType`}
                  component={AutoCompleteFinalFormAdapter}
                  placeholder="Breed"
                  validate={required}
                  items={CAT_BREEDS}
                  hideAutoCompleteIcon
                  allowUserInput
                />
              </Col>
            </Condition>
            <Col md={4}>
              <Field
                name={`${name}.pet.weight`}
                component={InputFinalFormAdapter}
                placeholder={RAS.pets_weight_label}
                validate={validateNumericRequired}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Field
                name={`${name}.pet.isServiceAnimal`}
                alignWithField={false}
                component={CheckboxFinalFormAdapter}
                label={RAS.pets_is_service_animal}
              />
            </Col>
          </Row>
          <Condition when={`${name}.pet.petType`} is={PET_TYPES.OTHER}>
            <Row>
              <Col md={12}>
                <Field
                  name={`${name}.pet.livesInCageOrAquarium`}
                  alignWithField={false}
                  component={CheckboxFinalFormAdapter}
                  label={RAS.pets_lives_in_cage}
                />
              </Col>
            </Row>
          </Condition>

          <Condition when={`${name}.pet.isServiceAnimal`} is>
            <Space.XXL />
            <FormLayout.Title title={RAS.pets_document} />
            <Row>
              <Col md={12}>
                <Field
                  name={`${name}.pet.files`}
                  validate={validateMedia}
                  component={NewMediaUploadAdapter}
                  disableWebcam
                  mediaLabel={MEDIA_LABEL.FILE_UPLOAD}
                  isSingleMedia
                />
              </Col>
            </Row>
          </Condition>

          {note && (
            <Row>
              <Col md={12}>
                <div className={cx('animal-note')}>
                  <Text>{note}</Text>
                </div>
              </Col>
            </Row>
          )}
        </Condition>
      </>
    );
  }
}
