import React from 'react';
import classNames from 'classnames/bind';
import Spinner from 'components/Spinner/Spinner';
import HeaderList from '../../../../components/HeaderList/HeaderList';
import styles from './AccountPage.module.css';

const cx = classNames.bind(styles);

// eslint-disable-next-line react/prop-types
export default function AccountPage({ headerProps, sections = [], loading = false, cta = null, children }) {
  return (
    <>
      <div className={cx('page')}>
        <div className={cx('header')}>
          <HeaderList {...headerProps} cta={cta} />
        </div>
        {loading && <Spinner />}
        <div className={cx('content')}>
          {sections.map((section, index) => (
            <div key={index} className={cx('section')}>
              {section.title && <h3 className={cx('title')}>{section.title}</h3>}
              {section.subtitle && <div className={cx('subtitle')}>{section.subtitle}</div>}
              <div className={cx('section-content')}>{section.content}</div>
            </div>
          ))}
          {children}
        </div>
      </div>
    </>
  );
}
