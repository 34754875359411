import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { composeValidators, dateValidationMMDDYY, required, validPastOrTodayMMDDYYDate } from 'utils/validation';
import FormLayout from 'layouts/FormLayout/FormLayout';
import Condition from 'formcomponents/Condition/Condition';
import { formatString } from 'strings';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import { DateInputFinalFormAdapter } from 'components/DateInput/DateInput';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCurrency, unmaskNumber } from 'components/Input/masks';
import Field from 'components/Field/Field';

// todo: check if rentedBefore is being sent => not in API
export const RentedBeforeSection = ({ changeFormValue, name }) => {
  const form = useForm();
  const {
    initialValues: { streetAddress: address },
  } = useFormState();

  return (
    <FormLayout.Section sectionTitle={formatString(RENTAL_STATUS_TEXT.rentedQuestion, { address })}>
      <Field
        component={YesNoFinalFormAdapter}
        name={`${name}.rentedBefore`}
        onChangeCustom={() => {
          changeFormValue(
            {
              [`${name}.previousLeaseEndOn`]: `${name}.previousLeaseEndOn`,
              [`${name}.previousRentAmount`]: `${name}.previousRentAmount`,
            },
            form
          );
        }}
        validate={required}
      />
      <Condition is when={`${name}.rentedBefore`}>
        <Row>
          <Col xs={12} sm={6}>
            <Field
              component={DateInputFinalFormAdapter}
              name={`${name}.previousLeaseEndOn`}
              placeholder={RENTAL_STATUS_TEXT.leaseEndedMMYYPlaceholder}
              validate={composeValidators(required, dateValidationMMDDYY, validPastOrTodayMMDDYYDate)}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Field
              component={InputFinalFormAdapter}
              mask={maskCurrency}
              name={`${name}.previousRentAmount`}
              placeholder={RENTAL_STATUS_TEXT.previousRentPlaceholder}
              unmask={unmaskNumber}
              validate={required}
            />
          </Col>
        </Row>
      </Condition>
    </FormLayout.Section>
  );
};

RentedBeforeSection.propTypes = {
  changeFormValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
