import { getApiActionTypesValues } from '../redux_utils';

// GET : /residents/background-check-info
export const fetchPersonalInformation = () => ({
  types: getApiActionTypesValues('FETCH_RESIDENT_PERSONAL_INFO'),
  promise: ({ client }) => client.get('/residents/background-check-info'),
  auth: true,
});

// Call api endpoint to update user's legal name and current-address in the backend
// PUT : /residents/background-check-info
export const updateLegalIdentityInformation = (userLegalIdentityInfo) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('UPDATE_LEGAL_IDENTITY'),
    promise: ({ client }) => client.put('/residents/background-check-info', userLegalIdentityInfo),
    auth: true,
  });
  await dispatch(fetchPersonalInformation());
};

export const fetchOfferedLeases = () => ({
  promise: ({ client }) => client.get('/residents/offered-leases'),
  auth: true,
});

export const signOfferedLease = () => ({
  promise: ({ client }) => client.put('/residents/offered-leases'),
  auth: true,
});
