import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { COOKIES_CONFIG } from '@belong/common';
import { Text } from '@belong/ui';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from 'components/Button/Button';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import AgreeToTermsFooter from 'containercomponents/Modals/LoginModal/AgreeToTermsFooter/AgreeToTermsFooter';
import Space from 'corecomponents/Space/Space';
import LeadForm from 'formcomponents/LeadForm/LeadForm';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { setCookie } from 'nookies';
import PropTypes from 'prop-types';
import { showModal } from 'store/redux/modals/actions';
import { sessionRegister, fetchUser } from 'store/redux/user/actions';
import { formatString } from 'strings';
import { STRINGS } from 'strings/account-creation.string';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import Form from '../../../components/Form/Form';
import { SCREENS } from '../LoginModal/login-modal.consts';
import { MODALS } from '../modal.consts';
import styles from './LightAccountCreationModal.module.css';

const { JWT_TOKEN } = COOKIES_CONFIG;

const cx = classNames.bind(styles);

const ERROR_API_CODE = {
  EMAIL_TAKEN_API_CODE: 'EMAIL_TAKEN',
};

const LightAccountCreationModal = ({ show, onHide, afterSucessfulLogin }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const initialValues = {};

  const handleLoginClick = (email) => {
    dispatch(
      showModal(MODALS.LOGIN, {
        afterSucessfulLogin,
        currentScreen: SCREENS.LOGIN_SCREEN,
        email,
      })
    );
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    setError('');

    try {
      const response = await dispatch(sessionRegister(values, true));
      setCookie(null, JWT_TOKEN.name, response.token, JWT_TOKEN.options);
      await dispatch(fetchUser());
      setLoading(false);
      onHide();
      if (afterSucessfulLogin) {
        await afterSucessfulLogin();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      const { errorCode } = err?.[0] || {};

      // todo later. Parse error code and show messages accordingly.
      setError(FEEDBACK_STRINGS.something_went_wrong);

      if (errorCode === ERROR_API_CODE.EMAIL_TAKEN_API_CODE) {
        setError(
          <Text variant="p1" fontWeight="semibold" className="text-red text-center mt-sm">
            {STRINGS['sign_up.email_taken_line1']}
            <Button
              buttonType={BUTTON_TYPES.NOSTYLE}
              className="text-red"
              onClick={() => handleLoginClick(values.email)}
            >
              {STRINGS['sign_up.email_taken_button']}
            </Button>
            {STRINGS['sign_up.email_taken_line2']}
          </Text>
        );
      }
    }
  };

  return (
    <ModalV2
      name={ANALYTICS_MODAL_NAMES.LIGHT_ACCOUNT_CREATION}
      show={show}
      onHide={onHide}
      showSpinner={loading}
      closeButton
      noPadding
      type={MODAL_TYPES.SMALL}
      closable={false}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        getForm={({ invalid, values }) => (
          <FormLayout>
            <div className={cx('lead-form-container')}>
              <h2 className={cx('center')}>{formatString('Enter your details <span>and feel the love.</span>')}</h2>
              {typeof error !== 'string' ? error : <p className={cx('error')}>{formatString(error)}</p>}
              <Space />
              <LeadForm />
              <Space.XL />
              <Button type="submit" size={BUTTON_SIZES.FLUID} disabled={invalid}>
                {formatString('Continue')}
              </Button>
              <div className={cx('login-container')}>
                <p>{formatString('Already a Member?')}</p>
                <Button buttonType={BUTTON_TYPES.TEXT} onClick={() => handleLoginClick(values.email)}>
                  {formatString('Sign In')}
                </Button>
              </div>
              <Space />
              {AgreeToTermsFooter}
            </div>
          </FormLayout>
        )}
      />
    </ModalV2>
  );
};

LightAccountCreationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  afterSucessfulLogin: PropTypes.func,
};

LightAccountCreationModal.defaultProps = {
  afterSucessfulLogin: null,
};

export default LightAccountCreationModal;
