import { ACTIONS } from './constants';

export const HOME_REDUCER = 'homes';

const initialState = {
  homes: {},
  relatedUnits: {},
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_HOME_SUCCESS:
      // case when home is unpublished.
      if (!action.result?.basicInfo) {
        return state;
      }
      action.result.basicInfo.unitId = action.result.basicInfo.unitId.toLowerCase();
      return {
        ...state,
        homes: {
          ...state.homes,
          [action.result.basicInfo.unitId]: action.result,
        },
      };
    case ACTIONS.FETCH_HOME_RELATED_UNITS_SUCCESS:
      return {
        ...state,
        relatedUnits: {
          ...state.relatedUnits,
          [action.homeId]: action.result.units,
        },
      };
    default:
      return state;
  }
}
