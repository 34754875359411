import { FeatureCategories } from 'models/enums/index';

export const COLORS = {
  DEFAULT: 'default',
  GRAY: 'gray',
  WHITE: 'white',
  GREEN: 'green',
  DARK_NAVY: 'dark-navy',
  NAVY: 'navy',
  RED: 'red',
} as const;

export const ICONS = {
  FRONT_ARROW: 'icon-front-arrow',
  BACK_ARROW: 'icon-back-arrow',
  CLOSE: 'icon-close',
  LEFT_CARET: 'icon-left-caret',
  RIGHT_CARET: 'icon-right-caret',
  PHOTO: 'icon-photo',
  TRASH: 'icon-trash',
  DOWN_CARET: 'icon-down-caret',
  PLUS: 'icon-plus',
  CHECKBOX_UNSELECTED: 'icon-checkbox-unselected',
  CHECKBOX_SELECTED: 'icon-checkbox-selected',
  RADIO_SELECTED: 'icon-radio-selected',
  SEARCH: 'icon-search',
  CHECKMARK: 'icon-checkmark',
  RADIO_CLOSE: 'icon-radio-selected-x',
  QUOTATIONS: 'icon-quotations',
  MENU: 'icon-menu',
  FACEBOOK: 'icon-facebook',
  TWITTER: 'icon-twitter',
  EQUAL_HOUSING: 'icon-equal-housing',
  LINKEDIN: 'icon-linkedin',
  LOCK: 'icon-lock',
  HAND_CHECKBOX: 'icon-hand-checkbox',
  HAND_ARROW_RIGHT: 'icon-hand-arrow-right',
  TOOLTIP: 'icon-tooltip',
  MINUS_CIRCLE: 'icon-minus-circle',
  PLUS_CIRCLE: 'icon-plus-circle',
  CIRCLE: 'icon-circle',
  FULL_CARET_LEFT: 'icon-full-caret-left',
  FULL_CARET_RIGHT: 'icon-full-caret-right',
  CALENDAR: 'icon-calendar',
  BELONG_EMPLOYEE: 'icon-belong-employee',
  DOWNLOAD: 'icon-download',
  NO_DOCUMENT_ICON: 'icon-no-document',
  PLUS_RESIZABLE: 'icon-plus-resizable',
  VIDEO_PLAY_NEW: 'icon-play-new',
  BANK: 'icon-bank',
  WARNING: 'icon-warning-small',
  WARNING_LARGE: 'icon-warning-large',
  PROFILE_CARD: 'icon-profile-card',
  PROFILE_CARD_SCALED: 'icon-profile-card-scaled',
  HOME_BALLOONS: 'icon-home-with-balloons',
  CAMERA: 'icon-camera',
  US_FLAG: 'icon-us-flag',
  TERMOMETER: 'icon-termometer',
  BEACH: 'icon-beach',
  SCROLL_DOWN: 'icon-scroll-down',

  SUMMARY: 'icon-summary',

  PENCIL: 'icon-pencil',
  FIRE: 'icon-fire',
  CHECKED_CIRCLE: 'icon-checked-circle',

  /* DARK TRANSPARENT */
  DARK_PROFILE_ID_CARD: 'icon-profile-card-dark-small',
  DARK_DOCUMENT: 'icon-document-card-dark',
  DARK_PHONE_CARD: 'icon-document-phone-dark',
  DARK_SHIELD: 'icon-document-shield-dark',

  /* New Line */
  EMPTY_PROFILE: 'icon-empty-profile-icon',
  UPLOAD_DUMMY_PROFILE: 'icon-upload-image-icon',
  MALE_PROFILE: 'icon-person-0',
  KIDS_PROFILE: 'icon-person-1',
  DOG_PROFILE: 'icon-person-2',
  CAT_PROFILE: 'icon-person-3',
  OTHER_PET_PROFILE: 'icon-person-4',

  BELONG_PROFILE: 'icon-belong-profile',
  MY_ACCOUNTS: 'icon-accounts-0',
  MAINTENANCE: 'icon-accounts-1',
  EARNINGS_AND_PAYMENTS: 'icon-accounts-2',
  DOCUMENTS_ACCOUNTS_PAGE: 'icon-accounts-3',
  FIXED_MENU_MORE: 'icon-accounts-4',
  TOURS_AND_APPS: 'icon-accounts-5',
  PROFILE: 'icon-accounts-6',
  FAVORITES: 'icon-accounts-8',
  SELL_HOME: 'icon-accounts-9',

  /* BADGE */
  BADGE_BALLOON: 'icon-balloon-belong-badge',
  BADGE: 'icon-belong-badge',
  BELONG_PRICE: 'icon-belong-price-shield',
  PRICE_GUARANTEE: 'icon-price-guarantee',
  BIG_GOLD_BADGE: 'icon-big-gold-badge',
  /* BALLOONS */
  BALLOON_PLAIN: 'icon-balloon-0',
  BALLOON_SMILEY: 'icon-balloon-1',
  BALLOON_SHOCKED: 'icon-balloon-2',
  BALLOON_CRYING: 'icon-balloon-3',
  BALLOON_HEART_EYE: 'icon-balloon-4',
  BALLOON_JOY: 'icon-balloon-5',
  BALLOON_GRUMPY: 'icon-balloon-6',
  BALLOON_SHIFTY_EYES: 'icon-balloon-7',
  BALLOON_BLUSH: 'icon-balloon-8',
  BALLOON_NERVOUS: 'icon-balloon-9',
  BALLOON_GORILLA: 'icon-balloon-10',
  BALLOON_TRAIN: 'icon-special-balloon-1',
  BALLOON_BUS: 'icon-special-balloon-2',
  BALLOON_BIKE: 'icon-special-balloon-3',
  BALLOON_ALARM: 'icon-special-balloon-4',
  BALLOON_COFFEE: 'icon-special-balloon-5',
  BALLOON_ALCOHOL: 'icon-special-balloon-6',
  BALLOON_SHOPPING: 'icon-special-balloon-7',
  BALLOON_SCHOOL: 'icon-special-balloon-8',
  BALLOON_LONG_SCHOOL: 'icon-balloon-long-school',
  BALLOON_HOUSE: 'icon-house-balloon',
  BALLOON_BELONG: 'icon-belong-balloon',
  BALLOON_SIGN: 'icon-belong-white-sign-balloon',
  SHIELD_BADGE: 'icon-shield-badge',

  /* Background Images */
  CLOUDS_AND_SPARKLES: 'icon-clouds-sparkles',
  COMPUTER: 'icon-computer',
  // OTHER ICONS
  HEART: 'icon-heart',
  // Ratings ICONS
  ZILLOW_RATING: 'icon-zillow-rating',
  THUMBTACK_RATING: 'icon-thumbtack-rating',
  /* CARDS */
  ACH_ACCOUNT: {
    DEFAULT: 'icon-card-0-0',
    INVERSE: 'icon-card-0-1',
  },
  VISA: {
    DEFAULT: 'icon-card-1-0',
    INVERSE: 'icon-card-1-1',
  },
  MASTER_CARD: {
    DEFAULT: 'icon-card-2-0',
    INVERSE: 'icon-card-2-1',
  },
  MAESTRO: {
    DEFAULT: 'icon-card-3-0',
    INVERSE: 'icon-card-3-1',
  },
  CIRRUS: {
    DEFAULT: 'icon-card-4-0',
    INVERSE: 'icon-card-4-1',
  },
  AMERICAN_EXPRESS: {
    DEFAULT: 'icon-card-5-0',
    INVERSE: 'icon-card-5-1',
  },
  DISCOVER: {
    DEFAULT: 'icon-card-6-0',
    INVERSE: 'icon-card-6-1',
  },
  CREDIT_CARD: {
    DEFAULT: 'icon-card-7-0',
    INVERSE: 'icon-card-7-1',
  },
  ADD_CARD: {
    DEFAULT: 'icon-card-8-0',
    INVERSE: 'icon-card-8-1',
  },
  JCB: {
    DEFAULT: 'icon-card-9-0',
    INVERSE: 'icon-card-9-1',
  },
  DINERS_CLUB: {
    DEFAULT: 'icon-card-10-0',
    INVERSE: 'icon-card-10-1',
  },
  UNION_PAY: {
    DEFAULT: 'icon-card-11-0',
    INVERSE: 'icon-card-11-1',
  },
  CHECK: {
    DEFAULT: 'icon-card-12-0',
    INVERSE: 'icon-card-12-1',
  },
  PAYPAL: {
    DEFAULT: 'icon-card-13-0',
    INVERSE: 'icon-card-13-1',
  },
  WIRE_TRANSFER: {
    DEFAULT: 'icon-card-14-0',
    INVERSE: 'icon-card-14-1',
  },
  OTHER_PAYMENTS: {
    DEFAULT: 'icon-card-15-0',
    INVERSE: 'icon-card-15-1',
  },
  PAYMENT_WARNING: {
    DEFAULT: 'icon-card-16-0',
    INVERSE: 'icon-card-16-1',
  },

  // External Listing Sites
  ZILLOW: 'icon-zillow',
  ZUMPER: 'icon-zumper',
  TRULIA: 'icon-trulia',
  FACEBOOK_MARKETPLACE: 'icon-facebook-marketplace',
  APARTMENTS_DOT_COM: 'icon-apartments-dot-com',
  MLS_LISTINGS: 'icon-mls-listings',
  CRAIGSLIST: 'icon-craigslist',
  HOTPADS: 'icon-hotpads',

  // SCRIBBLES
  ICON_DIAGONAL_STRIPES: 'icon-diagonal-stripes',
  ICON_WIGGLY_LINE: 'icon-wiggly-line',
  ICON_SCRIBBLE_BALLOONS: 'icon-scribble-balloons',
  ICON_TOP_LEFT_CORNER_SCRIBBLE: 'icon-top-left-corner-scribble',
  ICON_BOTTOM_RIGHT_CORNER_SCRIBBLE: 'icon-bottom-right-corner-scribble',
  ICON_LEFT_ARROW_SCRIBBLE: 'icon-left-arrow-scribble',
  ICON_LEFT_ARROW_SCRIBBLE_SMALL: 'icon-left-arrow-scribble-small',
  ICON_DOUBLE_WIGGLY_LINE: 'icon-double-wiggly-line ',
  ICON_SCRIBBLE_DOTS: 'icon-scribble-dots',
  ICON_SCRIBBLE_HEART: 'icon-scribble-heart',
  ICON_SCRIBBLE_HEART_MOBILE: 'icon-scribble-heart-mobile',

  ICON_COVID_HOME: 'icon-covid-home',
  ICON_CLOCK: 'icon-clock',
  ICON_DARK_CLOCK: 'icon-dark-clock',
  ICON_STARS: 'icon-stars',
  ICON_QUOTE_LIGHT_GRAY: 'icon-quote-light-gray',

  ICON_HOME: 'icon-home',
  ICON_HOME_MOBILE: 'icon-home-mobile',
  ICON_PRO_TRUCK: 'icon-pro-truck',
  ICON_RED_CHECKMARK: 'icon-red-checkmark',
  ICON_RED_DOWN_ARROW: 'icon-red-down-arrow',
  ICON_GREEN_UP_ARROW: 'icon-green-up-arrow',
  ICON_WHITE_SEARCH: 'icon-white-search',

  ICON_BROKEN_HEART: 'icon-broken-heart',
  ICON_HEART_EMPTY: 'icon-heart-empty',
  ICON_HEART_FULL: 'icon-heart-full',
  ICON_CIRCLE_BIG: 'icon-circle-big',
  ICON_FLAG: 'icon-flag',
  ICON_UNLOCKED: 'icon-unlocked',
  ICON_LOCKED: 'icon-locked',
  ICON_LOCKED_GREEN: 'icon-locked-green',
  ICON_LOCKED_TOOLTIP: 'icon-locked-tooltip',
  [`ICON_FEATURE_${FeatureCategories.Accessibility}`]: 'icon-categories-0',
  [`ICON_FEATURE_${FeatureCategories.Fitness}`]: 'icon-categories-1',
  [`ICON_FEATURE_${FeatureCategories.Aesthetics}`]: 'icon-categories-2',
  [`ICON_FEATURE_${FeatureCategories['Audio & Video']}`]: 'icon-categories-3',
  [`ICON_FEATURE_${FeatureCategories['Climate Control']}`]: 'icon-categories-4',
  [`ICON_FEATURE_${FeatureCategories.Cooking}`]: 'icon-categories-5',
  [`ICON_FEATURE_${FeatureCategories.Yard}`]: 'icon-categories-6',
  [`ICON_FEATURE_${FeatureCategories.Laundry}`]: 'icon-categories-7',
  [`ICON_FEATURE_${FeatureCategories.Lighting}`]: 'icon-categories-8',
  [`ICON_FEATURE_${FeatureCategories.Parking}`]: 'icon-categories-9',
  [`ICON_FEATURE_${FeatureCategories.Pool}`]: 'icon-categories-10',
  [`ICON_FEATURE_${FeatureCategories['Safety & Security']}`]: 'icon-categories-11',
  [`ICON_FEATURE_${FeatureCategories.Storage}`]: 'icon-categories-12',
  [`ICON_FEATURE_${FeatureCategories.Surroundings}`]: 'icon-categories-13',
  [`ICON_FEATURE_${FeatureCategories.Utilities}`]: 'icon-categories-14',
  [`ICON_FEATURE_${FeatureCategories.Other}`]: 'icon-categories-15',

  ICON_PLACE_IN_LINE: 'icon-place-in-line',
  ICON_APPLICATION_IN_PROCESS: 'icon-application-in-process',
  ICON_SAVINGS_UNLOCK: 'icon-savings-unlock',
} as const;
