import numeral from 'numeral';

var MONEY_TYPES = {
  DEFAULT: '$0,0[.]00',
  DEFAULT_SIGNED: '+$0,0[.]00',
  DOLLARS_WITH_CENTS: '$0,0.00',
  DOLLARS_WITH_CENTS_SIGNED: '+$0,0.00',
  DOLLARS_NO_CENTS: '$0,0',
  DOLLARS_NO_CENTS_SIGNED: '+$0,0',
  THOUSANDS: '$0a',
  THOUSANDS_SIGNED: '+$0a',
  THOUSANDS_WITH_DECIMALS: '$0.[0]a',
  THOUSANDS_WITH_DECIMALS_SIGNED: '+$0.[0]a',
  THOUSANDS_OR_MILLIONS_WITH_DECIMALS: 'THOUSANDS_OR_MILLIONS_WITH_DECIMALS',
  THOUSANDS_OR_MILLIONS_WITH_DECIMALS_SIGNED: 'THOUSANDS_OR_MILLIONS_WITH_DECIMALS_SIGNED'
};
function formatMoney(value, format) {
  if (format === void 0) {
    format = 'DEFAULT';
  }
  if (value === null || value === undefined) return value;
  if (format === MONEY_TYPES.THOUSANDS_OR_MILLIONS_WITH_DECIMALS || format === MONEY_TYPES.THOUSANDS_OR_MILLIONS_WITH_DECIMALS_SIGNED) {
    if (value > 1000000) {
      return numeral(value).format(format === MONEY_TYPES.THOUSANDS_OR_MILLIONS_WITH_DECIMALS ? MONEY_TYPES.THOUSANDS_WITH_DECIMALS : MONEY_TYPES.THOUSANDS_WITH_DECIMALS_SIGNED);
    }
    return numeral(value).format(format === MONEY_TYPES.THOUSANDS_OR_MILLIONS_WITH_DECIMALS ? MONEY_TYPES.THOUSANDS : MONEY_TYPES.THOUSANDS_SIGNED);
  }
  return numeral(value).format(MONEY_TYPES[format]);
}

export { MONEY_TYPES, formatMoney };
