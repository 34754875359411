export default class ProfileInfo {
  constructor(data = {}) {
    this.userId = data.userId;
    this.name = data.name;
    this.profileImageUrl = data.profileImageUrl;
    this.memberSince = data.memberSince;
    this.homeTown = data.homeTown;
    this.aboutMe = data.aboutMe;
  }
}
