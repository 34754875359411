import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import { Box } from '../Box/Box';
import { Flex } from '../Flex/Flex';
import styles from './Timeline.module.css';

const cx = classNames.bind(styles);

export const Timeline = ({
  checkedNode = <GeneralIcon icon={GENERAL_ICONS.CHECKMARK} color={COLORS.WHITE} scale={0.5} />,
  items,
  uncheckedNode = null,
  showUncheckedAsDisabled = false,
  alternate = false,
}) => (
  <div className={cx('timeline', { alternate })}>
    {items.map((item, index) => (
      <Flex
        className={cx({
          'alternate-odd-row': alternate && index % 2 === 1,
          'alternate-even-row': alternate && index % 2 === 0,
        })}
        key={index}
      >
        {/* Dot */}
        <Flex sx={{ minWidth: '14px' }} flexDirection="column" alignItems="center">
          {item.active ? (
            <Flex className={cx(['node', 'node-active'])}>
              <div className="bg-[#1890FF] rounded h-full w-full" />
            </Flex>
          ) : (
            <Flex className={cx('node', { checked: item.checked })}>{item.checked ? checkedNode : uncheckedNode}</Flex>
          )}

          {/* Tail */}
          <Box width="1px" sx={{ height: '100%', minHeight: '26px' }} bg="navy" />
        </Flex>

        {/* Content on the right or alternate */}
        <Box
          className={cx('content', {
            'inactive-content': !item.checked && showUncheckedAsDisabled && !item.active,
            'alternate-odd-content': alternate && index % 2 === 1,
            'alternate-even-content': alternate && index % 2 === 0,
          })}
        >
          {item.value}
        </Box>
      </Flex>
    ))}

    {/* Last item */}
    <Box className={cx({ 'alternate-last': alternate })} width="14px" height="8px" px="3px">
      <Box
        bg="navy"
        width="100%"
        height="100%"
        sx={{
          borderRadius: '50%',
        }}
      />
    </Box>
  </div>
);

Timeline.propTypes = {
  checkedNode: PropTypes.node,
  uncheckedNode: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  showUncheckedAsDisabled: PropTypes.bool,
  alternate: PropTypes.bool,
};
