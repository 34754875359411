import { Text, Button, Image, LinkBase } from '@belong/ui';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
import AgreementFlowFormLayout from 'pages/AgreementFlow/AgreementFlowFormLayout/AgreementFlowFormLayout';
import { getString } from 'strings';

export function SeattleSignStep({ handleSubmit, handleDownload, residents, ...rest }) {
  return (
    <AgreementFlowFormLayout
      {...rest}
      onSubmit={handleSubmit}
      title={LEASE_SIGNING_FLOW_STRINGS['sign.notarize']}
      subTitle={LEASE_SIGNING_FLOW_STRINGS['sign.notarizeSubtitle']}
      initialValues={{}}
      ctaProps={{ label: LEASE_SIGNING_FLOW_STRINGS['sign.soundsGood'] }}
      getForm={() => {
        return (
          <div className="border border-solid border-gray bg-light-gray p-sm rounded mt-xl">
            <Text fontWeight="semibold" variant="h3">
              {LEASE_SIGNING_FLOW_STRINGS['sign.whatsNext']}
            </Text>
            <ol className="pl-sm my-sm">
              <li>{LEASE_SIGNING_FLOW_STRINGS['sign.notarize.step1']}</li>
              <li>{LEASE_SIGNING_FLOW_STRINGS['sign.notarize.step2']}</li>
              <li>
                {getString(LEASE_SIGNING_FLOW_STRINGS['sign.notarize.step3'], {
                  count: residents === 1 ? 'you' : `all ${residents} of you`,
                })}
              </li>
            </ol>
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
              <div className="flex flex-col lg:flex-row items-start gap-2xs">
                <Text>{LEASE_SIGNING_FLOW_STRINGS['sign.forReference']}</Text>
                <Button variant="text" onClick={handleDownload}>
                  {LEASE_SIGNING_FLOW_STRINGS['sign.downloadLease']}
                </Button>
              </div>
              <LinkBase
                className="self-end mt-sm lg:mt-0"
                style={{ width: 100 }}
                href="https://www.notarize.com"
                target="_blank"
              >
                <Image src="/lease-signing-flow/powered-by-notarize.svg" alt="notarize" />
              </LinkBase>
            </div>
          </div>
        );
      }}
    />
  );
}
