import { filter, find } from 'lodash-es';
import FavoritePageUnit from 'models/listingPage/FavoritePageUnit';
import AccountPayment from 'models/payments/AccountPayment';
import RenterAccountBookmark from 'models/renterAccounts/RenterAccountBookmark';
import RenterAccountLease from 'models/renterAccounts/RenterAccountLease';
import RenterLeaseDetail from 'models/renterAccounts/RenterLeaseDetail';
import { createSelector } from 'reselect';
import { getLeaseStatus, LEASE_STATUS } from 'utils/formateDate/getTimeForLeasingStatus';
import { LeaseStatus } from '../../../models/enums';
import { RENTAL_ACCOUNT_REDUCER } from './reducer';

export const _selectRentalAccountReducer = (state) => state[RENTAL_ACCOUNT_REDUCER];

const _selectLeases = (state) => _selectRentalAccountReducer(state).leases;
const _selectBookmarks = (state) => _selectRentalAccountReducer(state).bookmarks;

const _selectAccountUnits = (state) => _selectRentalAccountReducer(state).units;
const _selectAccountUnitById = (state, unitId) => _selectAccountUnits(state)[unitId];
const _selectPayments = (state) => _selectRentalAccountReducer(state).payments;
const _selectFavoriteHomes = (state) => _selectRentalAccountReducer(state).favoriteHomes;

const _selectFavoriteHomesAccounts = (state) => _selectRentalAccountReducer(state).favoriteAccountHomes;

export const selectResidentCurrentTask = (state) => _selectRentalAccountReducer(state).currentTask;
export const selectResidentOccupiedTours = (state) => _selectRentalAccountReducer(state).occupiedTours;

// export const selectResidentCurrentTask = createSelector(
//   _selectResidentCurrentTask,
//   task => new MaintenanceTaskModel(task)
// );

export const selectAccountUnitById = createSelector(_selectAccountUnitById, (unit) => new RenterLeaseDetail(unit));
export const selectAccountDocuments = (state) => _selectRentalAccountReducer(state).documents;

const _selectAccountLeaseById = (state, leaseId) => {
  const leases = _selectLeases(state);
  return find(leases, (lease) => lease.leaseInfo.basicInfo.leaseId === leaseId);
};

export const selectAccountAllLeases = createSelector(_selectLeases, (leases) => {
  const leaseModels = leases
    .filter(
      (l) =>
        l?.leaseInfo?.basicInfo?.status !== LeaseStatus.Offered &&
        l?.leaseInfo?.basicInfo?.status !== LeaseStatus.ResidentsSigned
    )
    .map((lease) => new RenterAccountLease(lease));
  const newestLeaseForUnits = leaseModels.reduce((acc, lease) => {
    const { unitId } = lease.unitInfo;
    const existingLease = acc[unitId];
    if (!existingLease || existingLease.leaseInfo.basicInfo.leaseEndOn < lease.leaseInfo.basicInfo.leaseEndOn) {
      acc[unitId] = lease;
    }
    return acc;
  }, {});
  return Object.values(newestLeaseForUnits);
});

export const selectCurrentAndFutureLeases = createSelector(_selectLeases, (leases) => {
  if (!leases) {
    return null;
  }

  const currentLeases = filter(
    leases,
    (lease) => getLeaseStatus(lease) === LEASE_STATUS.FUTURE || getLeaseStatus(lease) === LEASE_STATUS.CURRENT
  );
  return currentLeases.map((lease) => new RenterAccountLease(lease));
});

export const selectLeases = createSelector(_selectLeases, (leases) => {
  return leases.map((lease) => new RenterAccountLease(lease));
});

export const selectOfferedAndSignedLeases = createSelector(_selectLeases, (leases) => {
  if (!leases) {
    return null;
  }

  const offeredAndSignedLeases = filter(
    leases,
    (lease) =>
      lease?.leaseInfo?.basicInfo?.status !== LeaseStatus.Offered ||
      lease?.leaseInfo?.basicInfo?.status !== LeaseStatus.ResidentsSigned
  );
  return offeredAndSignedLeases.map((lease) => new RenterAccountLease(lease));
});

export const selectBookmarks = createSelector(_selectBookmarks, (bookmarks) =>
  bookmarks ? bookmarks.map((bookmark) => new RenterAccountBookmark(bookmark)) : null
);

export const selectTourBookmarks = createSelector(_selectBookmarks, (bookmarks) =>
  bookmarks
    ? bookmarks.filter((bookmark) => !!bookmark.tourType).map((bookmark) => new RenterAccountBookmark(bookmark))
    : null
);

export const selectActiveTourRequests = createSelector(
  (state) => _selectRentalAccountReducer(state),
  (reducerState) =>
    reducerState ? reducerState.tourRequests?.filter((tourRequest) => tourRequest.status === 'Requested') : null
);

export const selectListingApplicationBookmarks = createSelector(_selectBookmarks, (bookmarks) =>
  bookmarks
    ? bookmarks.filter((bookmark) => !!bookmark.applicationId).map((bookmark) => new RenterAccountBookmark(bookmark))
    : null
);

export const selectAccountLeaseById = createSelector(_selectAccountLeaseById, (lease) =>
  lease ? new RenterAccountLease(lease) : null
);

export const selectPayments = createSelector(_selectPayments, (payments) =>
  payments ? payments.map((payment) => new AccountPayment(payment)) : []
);

export const selectPaymentsReverse = createSelector(_selectPayments, (payments) =>
  payments ? payments.map((payment) => new AccountPayment(payment)).reverse() : []
);

export const _selectTasks = (state) => _selectRentalAccountReducer(state).tasks;

export const selectFavoriteHomes = createSelector(
  _selectFavoriteHomes,
  (favoriteHomes) => new FavoritePageUnit(favoriteHomes).favoriteHomes
);

export const selectFavoriteHomesAccounts = createSelector(
  _selectFavoriteHomesAccounts,
  (favoriteHomes) => new FavoritePageUnit(favoriteHomes).favoriteHomes
);

export const selectResidentFlows = (state) => _selectRentalAccountReducer(state).flows;
