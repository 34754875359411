import Address from '../common/Address';
import Unit from '../common/Unit';

export default class RenterAccountBookmark {
  constructor(data = {}) {
    this.bookmarkId = data.bookmarkId;
    this.address = new Address(data.address);
    this.applicationId = data.applicationId;
    this.applicationStatus = data.applicationStatus;
    this.displayStatus = data.displayStatus;
    this.tourType = data.tourType;
    this.rentAmount = data.rentAmount;
    this.unit = new Unit(data.unit);
    this.visitStartOn = data.visitStartOn;
    this.visitEndOn = data.visitEndOn;
    this.visitType = data.visitType;
    this.hasPaymentHold = data.hasPaymentHold;
    this.tourId = data.tourId;
    this.attendeeId = data.attendeeId;
    this.attendeeStatus = data.attendeeStatus;
    this.listing = data.listing;
    this.listingApplication = data.listingApplication;
    this.applicants = data.applicants;
    this.applicationInterview = data.applicationInterview;
    this.applicationGroupHasTouredHome = data.applicationGroupHasTouredHome;
    this.isOccupied = data.isOccupied;
    this.bookedAttendeesAmount = data.bookedAttendeesAmount;
    this.host = data.host;
  }
}
