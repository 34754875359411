import { compact, filter, flatten } from 'lodash-es';
import { IMAGE_FILE_TYPE, IncomeSourceType, VerificationType } from '../../../models/enums';
import { uploadFiles } from '../images/actions';
import { getApiActionTypesValues } from '../redux_utils';
import { ACTIONS } from './constants';
import { selectResidentApplicationId, selectResidentApplyForHouseId } from './selectors';

export const setHouseIdInStore = (houseId) => ({
  type: ACTIONS.UPDATE_APPLY_FOR_UNIT_ID,
  houseId,
});

export const updateMustCallCompleteEndpoint = (mustCallCompleteEndpoint) => ({
  type: ACTIONS.UPDATE_MUST_CALL_COMPLETE_ENDPOINT,
  mustCallCompleteEndpoint,
});

// Fetch list of houses associated with the co-applicant
export const fetchHomeIds = (email, applicationId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('GET_HOUSE_IDS'),
    promise: ({ client }) =>
      client.get(`/applicants/applications/${applicationId}/units-applied?email=${encodeURIComponent(email)}`),
  });

export const updateBookmark = (bookmarkId, status) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_BOOKMARK'),
    promise: ({ client }) => client.put(`/residents/bookmarks/${bookmarkId}`, status),
    auth: true,
  });

export const createBookmark = (bookmarkDescription) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('CREATE_BOOKMARK'),
    promise: ({ client }) => client.post('/residents/bookmarks', bookmarkDescription),
    auth: true,
  });

// Fetch a list (array) of applications for a user
export const fetchAllApplicationsForUser = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_ALL_RESIDENT_APPLICATIONS'),
    promise: ({ client }) => client.get('/residents/applications/'),
    auth: true,
  });

// Update the currently selected applicationId in Redux (eg. use: Landing page)
export const updateApplicationIdAppState = (applicationId) => (dispatch) =>
  dispatch({
    type: ACTIONS.UPDATE_APPLICATION_ID_APP_STATE,
    applicationId,
  });

// Fetch the moveInDate details for an application from the backend
export const fetchMoveInDate = (applicationId) => async (dispatch, getState) => {
  const applyForHouseId = selectResidentApplyForHouseId(getState());
  return dispatch({
    types: getApiActionTypesValues('FETCH_MOVE_IN_DATE'),
    promise: ({ client }) => client.get(`/residents/applications/${applicationId}/units/${applyForHouseId}`),
    auth: true,
  });
};

export const updateApplicationStatus = (applicationId, unitId, application) => ({
  types: getApiActionTypesValues('UPDATE_APPLICATION'),
  promise: ({ client }) => client.put(`/residents/applications/${applicationId}/units/${unitId}`, application),
});

// Call api to create a resident lead with the move in date
export const createMoveInDateLead = (moveInDateLeadObject) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('CREATE_MOVE_IN_DATE_LEAD'),
    promise: ({ client }) => client.post('residents/leads', moveInDateLeadObject),
  });

// Associate unitId with the resident application by calling the api
export const assignApplyForHouseId = (moveInOn, leaseLength) => (dispatch, getState) => {
  const applicationId = selectResidentApplicationId(getState());
  const applyForHouseId = selectResidentApplyForHouseId(getState());

  if (!applicationId || !applyForHouseId) {
    return Promise.resolve();
  }

  return dispatch({
    promise: ({ client }) =>
      client.post(`/residents/applications/${applicationId}/units`, {
        units: [
          {
            unitId: applyForHouseId,
            moveInOn,
            leaseLength,
          },
        ],
      }),
    auth: true,
  });
};

// Call api endpoint to create a new application. Returns application ID
export const createMoveInDate = (moveInDateObject, moveInOn, leaseLength) => async (dispatch, getState) => {
  const response = await dispatch({
    types: getApiActionTypesValues('CREATE_MOVE_IN_DATE'),
    promise: ({ client }) => client.post('/residents/applications', moveInDateObject),
    auth: true,
  });

  await dispatch(assignApplyForHouseId(moveInOn, leaseLength));
  await Promise.all([
    dispatch(fetchMoveInDate(selectResidentApplicationId(getState()))),
    dispatch(fetchAllApplicationsForUser()),
  ]);

  return response;
};

// Call api endpoint to update leaseInfo for an application
export const updateMoveInDate = (moveInDate, leaseLength) => async (dispatch, getState) => {
  await dispatch(assignApplyForHouseId(moveInDate, leaseLength));
  await dispatch(fetchMoveInDate(selectResidentApplicationId(getState())));
};

// Call api endpoint to fetch co-applicants, kids and pets section
export const fetchPeopleAndPets = () => (dispatch, getState) => {
  const applicationId = selectResidentApplicationId(getState());
  return dispatch({
    types: getApiActionTypesValues('FETCH_PEOPLE_AND_PETS'),
    promise: ({ client }) => client.get(`/residents/applications/${applicationId}/residents`),
    auth: true,
  });
};

// Call api endpoint to update co-applicants, kids and pets section
export const updatePeopleAndPets = (peopleAndPets) => async (dispatch, getState) => {
  const fileUploadPromises = peopleAndPets.pets.map((petElement) => {
    const { pet } = petElement;
    return pet.files ? dispatch(uploadFiles(IMAGE_FILE_TYPE.PetCertification, pet.files)) : Promise.resolve();
  });

  const uploadedFiles = await Promise.all(fileUploadPromises);

  peopleAndPets.pets.forEach((petElement, index) => {
    const { pet } = petElement;
    if (pet.files) {
      pet.files = uploadedFiles[index];
    }
  });

  const applicationId = selectResidentApplicationId(getState());
  await dispatch({
    types: getApiActionTypesValues('UPDATE_PEOPLE_AND_PETS'),
    promise: ({ client }) => client.put(`/residents/applications/${applicationId}/residents`, peopleAndPets),
    auth: true,
  });
  await dispatch(fetchPeopleAndPets(selectResidentApplicationId(getState())));
};

// Call api endpoint to fetch co-applicants, kids and pets section
export const fetchApplicantInfo = () => (dispatch, getState) => {
  const applicationId = selectResidentApplicationId(getState());
  return dispatch({
    types: getApiActionTypesValues('FETCH_APPLICANT_INFO'),
    promise: ({ client }) => client.get(`/residents/applications/${applicationId}/applicant-info`),
    auth: true,
  });
};

// Call api endpoint with account-id to charge application fee
export const createApplicationFeeAccount = (appFeePaymentObject) => (dispatch, getState) => {
  const applicationId = selectResidentApplicationId(getState());
  return dispatch({
    types: getApiActionTypesValues('CREATE_APPLICATION_FEE'),
    promise: ({ client }) =>
      client.post(`/residents/applications/${applicationId}/application-fee`, appFeePaymentObject),
    auth: true,
  });
};

export const updateLegalIdentityInformation = (userLegalIdentityInfo) => async (dispatch, getState) => {
  await dispatch({
    types: getApiActionTypesValues('UPDATE_LEGAL_IDENTITY'),
    promise: ({ client }) => client.put('/residents/background-check-info', userLegalIdentityInfo),
    auth: true,
  });
  await dispatch(fetchApplicantInfo(selectResidentApplicationId(getState())));
};

export const fetchEmploymentInformation = (applicationId) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('FETCH_EMPLOYMENT_INFORMATION'),
    promise: ({ client }) => client.get(`/residents/applications/${applicationId}/income-source`),
    auth: true,
  });
};

export const updateEmploymentInformation = (applicationId, incomeSources) => async (dispatch) => {
  const jobIncomeSources = filter(incomeSources.incomeSources, { type: IncomeSourceType.Job });
  const otherIncomeSources = filter(incomeSources.incomeSources, (source) => source.type !== IncomeSourceType.Job);
  const jobIncomeSourcesFiles = jobIncomeSources.map((incomeSource) => incomeSource.offerLetterFile);
  const fileUploadPromises = [];
  jobIncomeSourcesFiles.forEach((fileData) => {
    fileUploadPromises.push(dispatch(uploadFiles(IMAGE_FILE_TYPE.OfferLetter, fileData)));
  });

  const uploadedFiles = await Promise.all(fileUploadPromises);
  jobIncomeSources.forEach((jobIncomeSource, index) => {
    jobIncomeSource.incomeSourceFiles = [
      {
        fileType: IMAGE_FILE_TYPE.OfferLetter,
        files: uploadedFiles[index],
      },
    ];
  });

  const data = {
    ...incomeSources,
    incomeSources: [...jobIncomeSources, ...otherIncomeSources],
  };

  await dispatch({
    types: getApiActionTypesValues('UPDATE_EMPLOYMENT_INFORMATION'),
    promise: ({ client }) => client.put(`/residents/applications/${applicationId}/income-source`, data),
    auth: true,
  });
};

export const fetchCosigners = (applicationId) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('FETCH_COSIGNER_INFORMATION'),
    promise: ({ client }) => client.get(`/residents/applications/${applicationId}/cosigners`),
    auth: true,
  });
};

export const updateCosigners = (applicationId, cosigner) => async (dispatch) => {
  const data = {
    cosigners: cosigner ? [cosigner] : [],
  };

  await dispatch({
    types: getApiActionTypesValues('UPDATE_COSIGNER_INFORMATION'),
    promise: ({ client }) => client.put(`/residents/applications/${applicationId}/cosigners`, data),
    auth: true,
  });
};

// Call api endpoint to fetch applicant's income verification info
// that includes cosigners, documents and bank accounts
export const fetchIncomeVerification = () => (dispatch, getState) => {
  const applicationId = selectResidentApplicationId(getState());
  return dispatch({
    types: getApiActionTypesValues('FETCH_APPLICANT_INCOME_VERIFICATION'),
    promise: ({ client }) => client.get(`/residents/applications/${applicationId}/income-verification`),
    auth: true,
  });
};

// Call api endpoint to update income verification data in the backend
export const updateIncomeVerification = (selectedMethod, incomeVerification) => async (dispatch, getState) => {
  const applicationId = selectResidentApplicationId(getState());
  const { incomeSources, incomeAccounts } = incomeVerification;
  const updatedIncomeSources = [];

  for (const incomeSource of incomeSources) {
    if (incomeSource.files?.length) {
      const untouchedFiles = filter(incomeSource.incomeSourceFiles, { fileType: IMAGE_FILE_TYPE.OfferLetter });
      const groupedFiles = {
        availableMedia: flatten(compact(incomeSource.files.map((file) => file.availableMedia))),
        stagingMedia: flatten(compact(incomeSource.files.map((file) => file.stagingMedia))),
        deletedMedia: flatten(compact(incomeSource.files.map((file) => file.deletedMedia))),
      };

      const newFiles = await dispatch(uploadFiles(IMAGE_FILE_TYPE.PAYSTUB, groupedFiles));
      updatedIncomeSources.push({
        ...incomeSource,
        incomeSourceFiles: [
          ...untouchedFiles,
          {
            fileType: IMAGE_FILE_TYPE.PAYSTUB,
            files: newFiles,
          },
        ],
      });
    } else {
      updatedIncomeSources.push({ ...incomeSource });
    }
  }

  const instantIncomeAccounts = filter(incomeAccounts, { verificationType: VerificationType.Instant });
  const manualIncomeAccounts = filter(incomeAccounts, { verificationType: VerificationType.Manual });
  const updatedIncomeAccounts = [...instantIncomeAccounts];
  for (const incomeAccount of manualIncomeAccounts) {
    if (incomeAccount.files?.length) {
      const groupedFiles = {
        availableMedia: flatten(compact(incomeAccount.files.map((file) => file.availableMedia))),
        stagingMedia: flatten(compact(incomeAccount.files.map((file) => file.stagingMedia))),
        deletedMedia: flatten(compact(incomeAccount.files.map((file) => file.deletedMedia))),
      };

      const labels = compact(incomeAccount.files.map((file) => (file.stagingMedia?.length ? file.label : '')));
      const newFiles = await dispatch(uploadFiles(IMAGE_FILE_TYPE.BANK_STATEMENT, groupedFiles));
      const filesWithLabel = filter(newFiles, (file) => !!file.notes);
      const filesWithoutLabel = filter(newFiles, (file) => !file.notes);
      updatedIncomeAccounts.push({
        ...incomeAccount,
        incomeAccountFiles: [
          {
            fileType: IMAGE_FILE_TYPE.BANK_STATEMENT,
            files: [
              ...filesWithLabel,
              ...filesWithoutLabel.map((newFile, index) => ({
                ...newFile,
                notes: !newFile.notes ? labels[index].replace(' Bank Statement', '') : newFile.notes,
              })),
            ],
          },
        ],
      });
    } else {
      updatedIncomeAccounts.push({ ...incomeAccount });
    }
  }

  const data = {
    primaryIncomeVerificationType: selectedMethod,
    incomeSources: updatedIncomeSources,
    incomeAccounts: updatedIncomeAccounts,
  };

  await dispatch({
    types: getApiActionTypesValues('UPDATE_INCOME_VERIFICATION'),
    promise: ({ client }) => client.put(`/residents/applications/${applicationId}/income-verification`, data),
    auth: true,
  });

  await dispatch(fetchIncomeVerification(applicationId));
};

// Call api endpoint to get the document download url based on the document ID
export const getDocumentUrl = (documentId) => ({
  promise: ({ client }) => client.get(`/residents/documents/${documentId}/download-url`),
  auth: true,
});

// Call api endpoint to fetch applicant's reference info
export const fetchReferenceCheck = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_APPLICANT_REFERENCE_INFO'),
    promise: ({ client }) => client.get('/residents/reference'),
    auth: true,
  });

// Call api endpoint to update reference check data in backend
export const updateReferenceCheck = (referenceCheck) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('UPDATE_REFERENCE_CHECK'),
    promise: ({ client }) => client.put('/residents/reference', referenceCheck),
    auth: true,
  });
  await dispatch(fetchReferenceCheck());
};

export const updateApplicationFlowStarted = (applicationId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_APPLICATION_FLOW_COMPLETE'),
    promise: ({ client }) =>
      client.put(`/residents/applications/${applicationId}/status`, {
        applicantStatus: 'Started',
      }),
    auth: true,
  });

// Call api endpoint to indicate end of resident application flow
export const updateApplicationFlowComplete = (applicationId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_APPLICATION_FLOW_COMPLETE'),
    promise: ({ client }) =>
      client.put(`/residents/applications/${applicationId}/status`, {
        applicantStatus: 'Submitted',
      }),
    auth: true,
  });

export const fetchApplicationSelfServeDetailsAction = (homeId) => ({
  types: getApiActionTypesValues('FETCH_APPLICATION_SELF_SERVE_DETAILS'),
  promise: ({ client }) => client.get(`/residents/applications/self-serve-lease-details?homeId=${homeId}`),
  auth: true,
});

export const createSelfServeLeaseAction = (applicationId, listingApplicationId) => ({
  types: getApiActionTypesValues('CREATE_SELF_SERVE_LEASE'),
  promise: ({ client }) =>
    client.post(`/residents/applications/${applicationId}/listing-applications/${listingApplicationId}/self-serve`),
  auth: true,
});

export const updateSelfServeShownAction = (applicationId, listingApplicationId) => ({
  types: getApiActionTypesValues('UPDATE_SELF_SERVE_SHOWN'),
  promise: ({ client }) =>
    client.put(
      `/residents/applications/${applicationId}/listing-applications/${listingApplicationId}/self-serve-shown`,
      {
        selfServeOffered: true,
      }
    ),
  auth: true,
});

export const fetchLeaseLength = (homeId) => ({
  types: getApiActionTypesValues('FETCH_LEASE_LENGTH'),
  promise: ({ client }) => client.get(`/homes/${homeId}/lease-length`),
});
