export const LABEL_COLOR_TYPES = {
  TRANSPARENT: 'transparent',
  BLUE: 'blue',
  NAVY: 'navy',
  GREEN: 'green',
  DARK_GREEN: 'dark-green',
  RED: 'red',
  RED_BORDER: 'red-border',
  GRAY: 'gray',
  DARK_GRAY: 'dark-gray',
  YELLOW: 'yellow',
  WHITE: 'white',
  GRAY_BORDER: 'gray-border',
} as const;

export type LabelColorType = typeof LABEL_COLOR_TYPES[keyof typeof LABEL_COLOR_TYPES];

export type LabelItem = {
  text: string;
  color?: LabelColorType;
};
