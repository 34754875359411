import { Image, Text } from '@belong/ui';

type Props = {
  image: string;
  title: string;
};

export const WearAndTearFeatureItem = ({ image, title }: Props) => (
  <div className="flex flex-1 flex-col items-center justify-center">
    <Image alt={title} className="mb-xs" src={image} />
    <Text fontWeight="semibold">{title}</Text>
  </div>
);
