import React, { useEffect } from 'react';
import Page from 'layouts/Page/Page';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { privateFileDownload } from 'store/redux/user/actions';

const FileDownload = ({ privateFileDownloadAction, match }) => {
  useEffect(() => {
    const fetchFile = async () => {
      const { fileType, ownerId } = match?.params || {};
      if (!fileType || !ownerId) {
        return;
      }

      try {
        const url = await privateFileDownloadAction(fileType, ownerId);
        if (url) {
          window.location.href = url;
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchFile();
  }, []);

  return <Page footer={false} header={false} />;
};

FileDownload.propTypes = {
  privateFileDownloadAction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const ConnectedFileDownload = connect(null, { privateFileDownloadAction: privateFileDownload })(FileDownload);

export { ConnectedFileDownload as FileDownload };
