import React, { Component } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import PropTypes from 'prop-types';
import styles from './BorderBoxCheckbox.module.css';

const cx = classNames.bind(styles);

export default class BorderBoxCheckbox extends Component {
  static propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    children: '',
    label: '',
    checked: false,
    onSelect: () => {},
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      checked: nextProps.checked,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
    };
  }

  handleClick = () => {
    const { checked } = this.state;
    const { onSelect } = this.props;
    onSelect(!checked);
    this.setState({
      checked: !checked,
    });
  };

  render() {
    let icon;
    const { children, label, ...other } = this.props;
    const { checked } = this.state;

    if (!checked) {
      icon = <GeneralIcon icon={GENERAL_ICONS.CHECKBOX_UNSELECTED} color={COLORS.GRAY} />;
    } else {
      icon = <GeneralIcon icon={GENERAL_ICONS.CHECKBOX_SELECTED} />;
    }

    return (
      <div className={cx('component-wrapper', { checked })} onClick={this.handleClick}>
        <div className={cx('checkbox-wrapper')}>
          <ButtonBase onClick={this.handleClick} {...other}>
            <div className={cx('iconButton')}>
              <div className={cx('icon-wrapper')}>{icon}</div>
            </div>
          </ButtonBase>
        </div>
        <div className={cx('content-wrapper')}>{children}</div>
      </div>
    );
  }
}
