import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '@belong/ui';
import { GroupBalanceInfoModel, ResidentAccountSummary } from 'api/models';
import { MonthlyStatementDetailInfoModel, BillingInfoModel, UnderReviewInfoModel } from 'api/models.v2';
import { format, endOfMonth, startOfMonth, addMonths, subMonths } from 'date-fns';
import { sortBy } from 'lodash-es';
import { PastMonthsSection } from 'pages/Accounts/Components/past-months-section';
import { UnderReviewSection } from 'pages/Accounts/Components/under-review-section';
import { UpcomingSection } from 'pages/Accounts/Components/upcoming-section';
import { balancesDataMapper, underReviewDataMapper } from 'pages/Accounts/utils/balances-data-mapper';
import queryString from 'query-string';
import {
  fetchUserGroupBalancesByMonth,
  fetchUserBalancesPastMonths,
  fetchUserBalancesUnderReview,
} from 'store/redux/accounts/actions';
import {
  selectBalancesByMonth,
  selectBalancesPastMonths,
  selectBalancesUnderReview,
} from 'store/redux/accounts/selectors';
import { fetchResidentLeases } from 'store/redux/user/actions';
import BalanceDetailsModal from '../../YourPortfolio/Earnings/BalanceDetailsModal/BalanceDetailsModal';
import { PastMonthModal } from '../../YourPortfolio/Earnings/past-month-modal/past-month-modal';

type EarningsProps = {
  balances: MonthlyStatementDetailInfoModel[] | null;
  pastMonthsBalances: MonthlyStatementDetailInfoModel[] | null;
  balancesUnderReview: UnderReviewInfoModel[] | null;

  fetchUserGroupBalancesByMonth: (queryParams?: string) => void;
  fetchUserBalancesPastMonths: (queryParams?: string) => void;
  fetchResidentLeases: () => ResidentAccountSummary;
  fetchUserBalancesUnderReview: (queryParams?: string) => void;
};

function Earnings({
  balances,
  pastMonthsBalances,
  balancesUnderReview,
  fetchResidentLeases: fetchResidentLeasesAction,
  fetchUserGroupBalancesByMonth: fetchUserGroupBalancesByMonthAction,
  fetchUserBalancesPastMonths: fetchUserBalancesPastMonthsAction,
  fetchUserBalancesUnderReview: fetchUserBalancesUnderReviewAction,
}: EarningsProps) {
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const [balanceDetailsModal, setBalanceDetailsModal] = useState(false);
  const [selectedBalance, setSelectedBalance] = useState<
    | {
        balance: GroupBalanceInfoModel;
        address: string;
        billingInfo?: BillingInfoModel;
      }
    | undefined
  >();
  const [balanceStandalone, setBalanceStandalone] = useState(false);
  const [selectedPastMonth, setSelectedPastMonth] = useState(null);

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const from = startOfMonth(new Date());
      const until = endOfMonth(addMonths(from, 1));

      const balancesQueryParams = queryString.stringify({
        from: `${format(from, 'yyyy-MM-dd')}T00:00:00.000Z`,
        until: `${format(until, 'yyyy-MM-dd')}T23:59:59.999Z`,
        groupBalanceType: 'Flat',
      });

      const pastBalancesQueryParams = queryString.stringify({
        from: `${format(subMonths(from, 12), 'yyyy-MM-dd')}T00:00:00.000Z`,
        until: `${format(subMonths(until, 2), 'yyyy-MM-dd')}T23:59:59.999Z`,
        groupBalanceType: 'Flat',
      });

      await fetchUserGroupBalancesByMonthAction(balancesQueryParams);
      await fetchUserBalancesPastMonthsAction(pastBalancesQueryParams);
      await fetchUserBalancesUnderReviewAction();

      const leases = await fetchResidentLeasesAction();

      if (leases.leases.length > 0) {
        const { address: streetAddress } = leases.leases[0];
        setAddress(streetAddress.streetAddress);
      }

      setLoading(false);
    },
    [
      fetchUserGroupBalancesByMonthAction,
      fetchUserBalancesPastMonthsAction,
      fetchResidentLeasesAction,
      fetchUserBalancesUnderReviewAction,
    ]
  );

  useEffect(() => {
    fetchData();
  }, [fetchUserGroupBalancesByMonthAction, fetchData]);

  function handleShowBalanceDetailsModal(balance, streetAddress, billingInfo, standalone = false) {
    setBalanceDetailsModal(true);
    setSelectedBalance({ balance, address: streetAddress, billingInfo });
    setBalanceStandalone(standalone);
  }

  function handleBalanceDetailsModalHide() {
    setBalanceDetailsModal(false);
  }

  function handleShowPastMonthModal(detail) {
    setSelectedPastMonth(detail);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <UpcomingSection
        balances={balances}
        isResident
        selectedHome="all"
        address={address}
        handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
      />
      <UnderReviewSection
        homesBalances={balancesUnderReview}
        selectedHome="all"
        handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
        hasManyHomes={false}
        isResident
      />
      <PastMonthsSection
        address={address}
        balances={pastMonthsBalances}
        handleShowPastMonthModal={handleShowPastMonthModal}
        isResident={false}
        selectedHome="all"
        hasManyHomes={false}
      />
      <BalanceDetailsModal
        show={balanceDetailsModal}
        balance={selectedBalance}
        onHide={handleBalanceDetailsModalHide}
        standalone={balanceStandalone}
        isResident
      />
      {!!selectedPastMonth && (
        <PastMonthModal
          show
          address={address}
          homeDetail={selectedPastMonth}
          onHide={() => setSelectedPastMonth(null)}
          handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
          canDownload={false}
        />
      )}
    </div>
  );
}

function mapStateProps(state) {
  return {
    balances: balancesDataMapper(sortBy(selectBalancesByMonth(state), 'monthSummaryInfo.month')),
    pastMonthsBalances: balancesDataMapper(selectBalancesPastMonths(state)),
    balancesUnderReview: underReviewDataMapper(selectBalancesUnderReview(state)),
  };
}
const mapDispatchToProps = {
  fetchUserGroupBalancesByMonth,
  fetchUserBalancesPastMonths,
  fetchResidentLeases,
  fetchUserBalancesUnderReview,
};

export default connect(mapStateProps, mapDispatchToProps)(Earnings);
