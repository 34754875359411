import { addMonths, differenceInMonths } from 'date-fns';
import type { Location } from 'history';
import { CountdownBanner } from 'lease-signing-flow/components/countdown-banner/countdown-banner';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
import { DepositStep } from 'lease-signing-flow/pages/deposit-step/deposit-step';
import { DurationStep } from 'lease-signing-flow/pages/duration-step/duration-step';
import { SignStep } from 'lease-signing-flow/pages/sign-step/sign-step';
import { WalletStep } from 'lease-signing-flow/pages/wallet-step/wallet-step';
import { WearAndTearStep } from 'lease-signing-flow/pages/wear-and-tear-step/wear-and-tear-step';
import { LeaseSigningFlowStepNames, ResidentType } from 'models/enums';

export const steps = {
  [LeaseSigningFlowStepNames.LandingPage]: {
    name: LeaseSigningFlowStepNames.LandingPage,
    key: '',
  },
  [LeaseSigningFlowStepNames.Wallet]: {
    name: LeaseSigningFlowStepNames.Wallet,
    key: 'wallet',
  },
  [LeaseSigningFlowStepNames.WearAndTear]: {
    name: LeaseSigningFlowStepNames.WearAndTear,
    key: 'wear', // todo: check key with dashes
  },
  [LeaseSigningFlowStepNames.Deposit]: {
    name: LeaseSigningFlowStepNames.Deposit,
    key: 'deposit',
  },
  [LeaseSigningFlowStepNames.Duration]: {
    name: LeaseSigningFlowStepNames.Duration,
    key: 'duration',
  },
  [LeaseSigningFlowStepNames.Sign]: {
    name: LeaseSigningFlowStepNames.Sign,
    key: 'sign',
  },
  [LeaseSigningFlowStepNames.Success]: {
    name: LeaseSigningFlowStepNames.Success,
    key: 'success',
  },
};

export const getStepPath = (stepName) => {
  const stepConfig = steps[stepName];

  return `/${stepConfig.key}`;
};

const stepRoutes = [
  {
    index: 0,
    name: LeaseSigningFlowStepNames.Wallet,
    path: getStepPath(LeaseSigningFlowStepNames.Wallet),
    component: WalletStep,
    primaryResidentOnly: true,
    showOnRenewal: false,
  },
  {
    index: 1,
    name: LeaseSigningFlowStepNames.WearAndTear,
    path: getStepPath(LeaseSigningFlowStepNames.WearAndTear),
    component: WearAndTearStep,
    label: LEASE_SIGNING_FLOW_STRINGS['wearAndTear.label'],
    primaryResidentOnly: true,
    showOnRenewal: false,
  },
  {
    index: 2,
    name: LeaseSigningFlowStepNames.Deposit,
    path: getStepPath(LeaseSigningFlowStepNames.Deposit),
    component: DepositStep,
    label: LEASE_SIGNING_FLOW_STRINGS['deposit.label'],
    primaryResidentOnly: true,
    showOnRenewal: true,
  },
  {
    index: 3,
    name: LeaseSigningFlowStepNames.Duration,
    path: getStepPath(LeaseSigningFlowStepNames.Duration),
    component: DurationStep,
    label: LEASE_SIGNING_FLOW_STRINGS['duration.label'],
    primaryResidentOnly: true,
    showOnRenewal: true,
  },
  {
    index: 3,
    name: LeaseSigningFlowStepNames.Sign,
    path: getStepPath(LeaseSigningFlowStepNames.Sign),
    component: SignStep,
    label: LEASE_SIGNING_FLOW_STRINGS['sign.label'],
    banner: CountdownBanner,
    primaryResidentOnly: false,
    showOnRenewal: true,
  },
];

export const getAvailableSteps = (resident, lease, depositTypeOptions, durationOptions) => {
  let routesToShow = stepRoutes;
  if (!resident || !lease) {
    return routesToShow;
  }

  const isRenewal = lease.leaseInfo?.basicInfo?.renewalNumber > 0;
  const isPrimaryResident = resident?.leaseInfo?.residentType === ResidentType.Primary;
  const shouldHideDepositStep = depositTypeOptions?.length === 1;
  const isLeaseMonthToMonth = lease.leaseInfo?.basicInfo.extendsMonthToMonth;
  const isRentGuaranteed = lease.leaseInfo?.basicInfo.payoutPlan !== 'NoGuarantee';
  const isOneMonthDeposit = lease.leaseInfo?.basicInfo.depositAmount === lease.leaseInfo?.basicInfo.rentAmount;

  if (!isPrimaryResident) {
    routesToShow = routesToShow.filter((stepRoute) => !stepRoute.primaryResidentOnly);
  }

  if (shouldHideDepositStep) {
    routesToShow = routesToShow.filter((route) => route.name !== LeaseSigningFlowStepNames.Deposit);
  }

  if (isRenewal) {
    routesToShow = routesToShow.filter((stepRoute) => stepRoute.showOnRenewal);
  }

  if (durationOptions) {
    if (Object.keys(durationOptions).length === 0) {
      routesToShow = routesToShow.filter((route) => route.name !== LeaseSigningFlowStepNames.Duration);
    }
    const { listingLeaseMaxLength, leaseCurrentStartDate, leaseOfferedEndDate } = durationOptions;
    const leaseTerm = differenceInMonths(
      addMonths(new Date(leaseCurrentStartDate), listingLeaseMaxLength),
      new Date(leaseOfferedEndDate)
    );

    if (
      listingLeaseMaxLength === 12 ||
      leaseTerm <= 0 ||
      isLeaseMonthToMonth ||
      !isRentGuaranteed ||
      (isRentGuaranteed && !isOneMonthDeposit)
    ) {
      routesToShow = routesToShow.filter((route) => route.name !== LeaseSigningFlowStepNames.Duration);
    }
  }

  return routesToShow.map((stepRoute, index) => ({
    ...stepRoute,
    index,
  }));
};

// Improvised function to get current step, need to think more about how to separete stepsConfig from currentStep
export const getCurrentStep = (location: Location, availableSteps) => {
  const locationArray = location.pathname.split('/');
  const stepKey = locationArray[3];

  const stepName = Object.values(steps).find((step) => step.key === stepKey);

  return availableSteps.find((step) => step.name === stepName?.name) || {};
};
