import { Text } from "@belong/ui";
import GreenInfoBox from "components/GreenInfoBox/GreenInfoBox";
import Image from "components/Image/Image";
import { USER_IDENTITY_STRINGS } from 'accounts/constants/strings/user-identity.strings';

export function UserIdentityBannerStep() {
  return (
    <GreenInfoBox>
      <div className="flex">
        <div className="mr-sm">
          <Image src="https://belong-web-assets.s3.us-west-2.amazonaws.com/common_assets/setup_confetti_icon.svg" />
        </div>
        <div className="flex flex-col justify-center">
          <Text as="span" fontWeight="semibold">
            {USER_IDENTITY_STRINGS.banner_step}
          </Text>
          <Text variant="p1" fontWeight="semibold">
            {USER_IDENTITY_STRINGS["banner_step.next_up"]}
          </Text>
        </div>
      </div>
    </GreenInfoBox>
  )
}
