import { MaintenanceModel, MaintenancePaymentModel, MaintenancePriceRangeAggregateModel } from 'api/models';
import classNames from 'classnames/bind';
import { ICONS } from 'components/Icon/Icon.js';
import { MAINTENANCE_TASK_MOBILE_PROPS } from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceProps';
import { Grid, Row, Col } from 'forkedlibraries/react-bootstrap';
import { useMaintenanceLabels } from 'maintenance/hooks/useMaintenanceLabels';
import MaintenanceTaskCost from './MaintenanceTaskCost/MaintenanceTaskCost';
import MaintenanceTaskIconLabel from './MaintenanceTaskIconLabel/MaintenanceTaskIconLabel';
import MaintenanceTaskLabels from './MaintenanceTaskLabels/MaintenanceTaskLabels';
import styles from './MaintenanceTasks.module.css';

const cx = classNames.bind(styles);

type MaintenanceTasksProps = {
  maintenanceDate?: string;
  maintenanceTaskImage?: string;
  maintenanceTask?: string;
  estimatedCost: '' | 'Pricing TBD' | MaintenancePaymentModel['estimatedAdjustmentApplied'];
  actualCost: '' | 'Free' | MaintenancePaymentModel['actualAdjustmentApplied'];
  maintenanceTaskStatus: string;
  isConsentPendingList?: boolean;
  priceRange?: MaintenancePriceRangeAggregateModel;
  numberOfOptions?: number;
  requestCategory?: MaintenanceModel['requestCategory'];
  requestType?: MaintenanceModel['requestType'];
  isRenter: boolean;
  task: MaintenanceModel;
};

const MaintenanceTasks = ({
  maintenanceDate,
  maintenanceTaskImage = ICONS.COMMUNICATION.DEFAULT,
  maintenanceTask = 'Please Enter A Task',
  estimatedCost,
  actualCost,
  priceRange,
  numberOfOptions,
  maintenanceTaskStatus,
  isConsentPendingList = false,
  requestCategory,
  requestType,
  isRenter,
  task,
}: MaintenanceTasksProps) => {
  const maintenanceLabels = useMaintenanceLabels(isRenter, task);

  return (
    <div
      className={cx(
        'maintenance-tasks-list-flex',
        'transition-colors duration-hover hover:border-navy hover:bg-light-gray',
        isConsentPendingList && 'border-red bg-red-translucent-light'
      )}
    >
      <Grid className="px-sm py-sm">
        <Row className={cx('max-width', 'mx-0')}>
          <div>
            <div className="flex flex-col md:flex-row md:justify-between">
              <MaintenanceTaskIconLabel
                maintenanceTaskImage={maintenanceTaskImage}
                maintenanceTask={maintenanceTask}
                maintenanceDate={maintenanceDate}
              />
              <MaintenanceTaskLabels maintenanceLabels={maintenanceLabels} />
            </div>
          </div>
        </Row>
        <Row className={cx('max-width', 'mx-0', 'maintenanceEstimatedPriceContent')}>
          <div className={cx('flexItemsForMaintenance')}>
            <Col md={6} className={cx('noRightPadding', 'pl-0')}>
              <MaintenanceTaskCost
                maintenanceTaskStatus={maintenanceTaskStatus}
                estimatedCost={estimatedCost}
                actualCost={actualCost}
                priceRange={priceRange}
                numberOfOptions={numberOfOptions}
                requestCategory={requestCategory}
                requestType={requestType}
                mobileDisplay={MAINTENANCE_TASK_MOBILE_PROPS.HAS_MOBILE}
              />
            </Col>
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default MaintenanceTasks;
