// TODO: Regions: Delete it.
import { RegionStatus } from 'models/enums';

export const OFFICE_ADDRESS = {
  'Bay Area': '<span>100 S Ellsworth Ave Ste 400,</span> <span>San Mateo, CA 94401</span>',
  'Los Angeles': '<span>815 Moraga Drive,</span> <span>Los Angeles, CA 90049</span>',
  'Orange County': '<span>2211 Michelson Drive,</span> <span>Irvine, CA 92612</span>',
  // TODO: MISSING SD AND SEATTLE
  // TODO: Fix Miami Address
  Miami: '<span>100 S Ellsworth Ave Ste 400,</span> <span>San Mateo, CA 94401</span>',
};

const MAIN_OFFICE_REGION = 'Bay Area';

export const STATES = {
  CA: 'ca',
  WA: 'wa',
  FL: 'fl',
};

export const REGION_TYPES = {
  ALL: {
    name: 'All Cities',
    status: RegionStatus.Active,
    id: 0,
    lat: 35.774455858003854,
    lng: -120.08102520000001,
    zoom: 6,
  },
  BAY_AREA: {
    name: 'Bay Area',
    slug: 'bay-area',
    status: RegionStatus.Active,
    id: 1,
    lat: 37.617869178994134,
    lng: -122.14861549999998,
    zoom: 9,
  },
  LOS_ANGELES: {
    name: 'LA Area',
    slug: 'los-angeles',
    status: RegionStatus.Active,
    id: 2,
    lat: 33.87247697628222,
    lng: -118.20228505,
    zoom: 9,
  },
  ORANGE_COUNTY: {
    name: 'Orange County',
    slug: 'orange-county',
    status: RegionStatus.Active,
    id: 3,
    lat: 33.727283,
    lng: -117.8691637,
    zoom: 9,
  },
  SAN_DIEGO: {
    name: 'San Diego Area',
    slug: 'san-diego',
    status: RegionStatus.Active,
    id: 4,
    lat: 32.8242404,
    lng: -117.3891576,
    zoom: 9,
  },
  SEATTLE: {
    name: 'Seattle Area',
    slug: 'seattle',
    status: RegionStatus.Active,
    id: 5,
    lat: 47.6129432,
    lng: -122.4821456,
    zoom: 9,
  },
  MIAMI: {
    name: 'Miami',
    slug: 'miami',
    status: RegionStatus.Active,
    id: 6,
    lat: 25.761681,
    lng: -80.191788,
    zoom: 9,
  },
};

export const REGIONS = [
  REGION_TYPES.BAY_AREA,
  REGION_TYPES.LOS_ANGELES,
  REGION_TYPES.MIAMI,
  REGION_TYPES.ORANGE_COUNTY,
  REGION_TYPES.SAN_DIEGO,
  REGION_TYPES.SEATTLE,
];

export const getRegionById = (id) => {
  if (REGIONS.length > id - 1) {
    return REGIONS[id - 1];
  }
  return null;
};

export const getInitialCenter = (regionId) => {
  if (regionId === REGION_TYPES.BAY_AREA.id) {
    return { lat: REGION_TYPES.BAY_AREA.lat, lng: REGION_TYPES.BAY_AREA.lng };
  }
  if (regionId === REGION_TYPES.LOS_ANGELES.id) {
    return { lat: REGION_TYPES.LOS_ANGELES.lat, lng: REGION_TYPES.LOS_ANGELES.lng };
  }
  if (regionId === REGION_TYPES.ORANGE_COUNTY.id) {
    return { lat: REGION_TYPES.ORANGE_COUNTY.lat, lng: REGION_TYPES.ORANGE_COUNTY.lng };
  }
  if (regionId === REGION_TYPES.SAN_DIEGO.id) {
    return { lat: REGION_TYPES.SAN_DIEGO.lat, lng: REGION_TYPES.SAN_DIEGO.lng };
  }
  if (regionId === REGION_TYPES.SEATTLE.id) {
    return { lat: REGION_TYPES.SEATTLE.lat, lng: REGION_TYPES.SEATTLE.lng };
  }
  if (regionId === REGION_TYPES.MIAMI.id) {
    return { lat: REGION_TYPES.MIAMI.lat, lng: REGION_TYPES.MIAMI.lng };
  }

  return { lat: REGION_TYPES.ALL.lat, lng: REGION_TYPES.ALL.lng };
};

export const getInitialZoom = (regionId) => {
  if (regionId === REGION_TYPES.BAY_AREA.id) {
    return REGION_TYPES.BAY_AREA.zoom;
  }
  if (regionId === REGION_TYPES.LOS_ANGELES.id) {
    return REGION_TYPES.LOS_ANGELES.zoom;
  }
  if (regionId === REGION_TYPES.ORANGE_COUNTY.id) {
    return REGION_TYPES.ORANGE_COUNTY.zoom;
  }
  if (regionId === REGION_TYPES.SAN_DIEGO.id) {
    return REGION_TYPES.SAN_DIEGO.zoom;
  }
  if (regionId === REGION_TYPES.SEATTLE.id) {
    return REGION_TYPES.SEATTLE.zoom;
  }
  if (regionId === REGION_TYPES.MIAMI.id) {
    return REGION_TYPES.MIAMI.zoom;
  }

  return REGION_TYPES.ALL.zoom;
};

export const getOfficeAddress = (region) => OFFICE_ADDRESS?.[region] || OFFICE_ADDRESS[MAIN_OFFICE_REGION];

export const INVALID_ZIPCODE = '00000';
