import { Image, Text } from '@belong/ui';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

export const AppQR = () => (
  <div className="flex flex-row">
    <Image alt="qr" className="h-[110px] w-auto" src="app/qr-code.svg" />
    <div className="ml-lg mt-lg">
      <div className="relative">
        <div className="scale-[2]">
          <Image alt="green-arrow" src="lease-signing-flow/green_arrow.svg" />
        </div>
        <div className="absolute left-[2px] rotate-[15deg] text-left top-[-48px] w-[158px]">
          <Text fontWeight="semibold">{LEASE_SIGNING_FLOW_STRINGS['success.qr']}</Text>
        </div>
      </div>
    </div>
  </div>
);
