import React from 'react';
import { range } from '@belong/common';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './LoadingCircle.module.css';

const cx = classNames.bind(styles);

export const LoadingCircle = ({ numberOfPoints, radius, children }) => {
  const numberOfPointsArray = range(numberOfPoints);
  const radiansInACirlce = 2 * Math.PI;
  const angleStep = radiansInACirlce / numberOfPoints;
  const diameter = 2 * radius;
  return (
    <div className={cx('loading-circle-container')}>
      <div className={cx('content-container')}>{children}</div>
      <div style={{ width: diameter, height: diameter }} className={cx('loading-circle')}>
        {numberOfPointsArray.map((val) => (
          <div
            className={cx('dots')}
            key={val}
            style={{
              // Since css coords start from top/left corner, we add a radius to center the circle.
              top: radius * Math.sin(val * angleStep) + radius,
              left: radius * Math.cos(val * angleStep) + radius,
            }}
          />
        ))}
      </div>
    </div>
  );
};

LoadingCircle.propTypes = {
  children: PropTypes.node,
  numberOfPoints: PropTypes.number,
  radius: PropTypes.number,
};

LoadingCircle.defaultProps = {
  numberOfPoints: 17,
  radius: 170,
};
