import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './themes.module.css';

const cx = classNames.bind(styles);

export const THEME_CONSTS = {
  DARK: 'dark',
  DEFAULT: 'default',
};
export const ThemeContext = React.createContext(THEME_CONSTS.DEFAULT);

export const ThemeProvider = ({ value, children }) => {
  return (
    <ThemeContext.Provider value={value}>
      <div className={cx(`${value}`)}>{children}</div>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOf(Object.values(THEME_CONSTS)),
};

ThemeProvider.defaultProps = {
  value: THEME_CONSTS.DEFAULT,
};
