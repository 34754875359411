// TODO: Remove when the 'belong-web' project already was migrated
var DATE_FORMATS = {
  LONG: 'MMMM do iiii, yyyy h:mm a',
  LONG_WITHOUT_MINUTES: 'MMMM do iiii, yyyy h a',
  STRIPPED_ISO: 'yyyy-MM-dd',
  DEFAULT_DATE_TIME: 'MMM do iiii, h:mm a',
  DEFAULT_DATE_TIME_WITHOUT_MINUTES: 'MMM do iiii, h a',
  MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE: "iii, MMM d, yyyy 'at' h:mm a z",
  MAINTENANCE_DEFAULT_DATE: 'iii, MMM d, yyyy',
  MAINTENANCE_DEFAULT_TIME: "'at' h:mm a z",
  MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE_WITHOUT_MINUTES: "iii, MMM d, yyyy 'at' h a z",
  STANDARD: 'MM/dd/yyyy',
  STANDARD_SHORT: 'MM/dd/yy',
  STANDARD_NO_YEAR: 'MM/dd',
  DOTTED_SHORT: 'MM d, yyyy',
  DOTTED_MEDIUM: 'MMM d, yyyy',
  DOTTED_LONG: 'MMM d, yyyy h:mm a',
  DOTTED_LONG_WITHOUT_MINUTES: 'MMM d, yyyy h a',
  DOTTED_LONG_WITH_SPACE: 'MMM d h:mm\u00a0a',
  DOTTED_LONG_WITH_SPACE_WITHOUT_MINUTES: 'MMM d, yyyy h a',
  FULL_MONTH_DAY_YEAR: 'MMMM d, yyyy',
  // Refactor previous shorts to this one
  SHORT_MONTH_YEAR: 'MMM yyyy',
  MONTH_YEAR: 'MMMM yyyy',
  MONTH_DAY: 'MMMM do',
  MONTH_DAY_SHORT: 'MMM do',
  MONTH_DAY_YEAR: 'MMMM do yyyy',
  SHORT: 'MM/yy',
  TIME: 'h:mm\u00a0a',
  TIME_WITHOUT_MINUTES: 'h a',
  TIME_TIMEZONE: 'h:mm a z',
  TIME_TIMEZONE_WITHOUT_MINUTES: 'h a z',
  LONG_DATE: 'iiii, MMM do',
  SHORT_MONTH: 'MMM',
  MONTH: 'MMMM',
  YEAR: 'yyyy',
  DAY: 'iiii',
  DESCRIPTIVE_DAY: 'iiii, MMMM d',
  DAY_OF_MONTH: 'do',
  DAY_MONTH: 'MMM d',
  DAY_MONTH_YEAR: 'MMM d, yyyy',
  DAY_MONTH_YEAR_TIME_TIMEZONE: 'MMM d, yyyy h:mm a z',
  DAY_MONTH_YEAR_TIME: 'MMM d, yyyy h:mm a',
  DAY_MONTH_YEAR_TIME_WITHOUT_MINUTES: 'MMM d, yyyy h a',
  MONTH_DOTTED_DAY_HOUR_MINUTE: 'MMM d, h:mm a',
  MONTH_DOTTED_DAY_HOUR_MINUTE_WITHOUT_MINUTES: 'MMM d, h a',
  MONTH_FULL_NAME_DAY_SHORT_YEAR_SEPARATED_WITH_COMMA_SPACE: 'MMMM do, yyyy',
  TIMEZONE_FULL_NAME: 'zzzz',
  INTERNAL: "yyyy-MM-dd'T'HH:mm:ssxxx"
};

export { DATE_FORMATS };
