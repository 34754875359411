export const HOME_RESIDENT_EXPERIENCE_STRINGS = {
  title: 'Help us create a 5-star <span>resident experience.</span>',
  done_label: 'Next Section',
  'quirks.section_title': 'What might take some getting used to about living in your home? <span>Any quirks?</span>',
  'quirks.placeholder': "Describe your home's quirks",
  'neighborhood.section_title': 'What do you love about <span>your neighborhood?</span>',
  'neighborhood.placeholder': 'Neighborhood qualities and highlights',
  'about_neighbors.section_title': "What's your relationship like with <span>your neighbors?</span>",
  'about_neighbors.placeholder': 'Anything we should know? (OPTIONAL)',
  'introduction.title': 'Have you introduced us to your current resident yet?',
  'email_template.title':
    'Please do! To make it easy, here is a template you can copy and paste into <span>an email.</span>',
  'email_template.mail': 'hello@belonghome.com',
  'email_template.subject': 'Let me introduce Belong! They’ll manage your rental going forward.',
  'email_template.body': `
    {resident},<br />

    Great news - your rental home is getting an upgrade! Going forward, Belong (belonghome.com) will manage this home. Belong provides you with a 24/7 Member Success Lead, a Pro team for easy home maintenance, and a convenient way to pay rent online.<br />

    You should receive an email from Belong on how to set up an account and online rent payments. If you would like to continue your current payment method for the time being, the instructions are below. Note that all future payments should go directly to them.<br />

    Your Belong Member Success Lead will also reach out shortly. They will be your main point of contact moving forward.<br />

    Thanks, {homeowner}<br />
    <br />
    Sending Rent Checks<br />
    - Make checks payable to Belong<br />
    - Include your name and home address in the memo<br />
    - {direction}<br />
    <br />
    Wire Transfers for Rent<br />
    - Send to this account. Include your name and home address in the memo.<br />
    {account}
  `,
  'email_template.direction_ca': 'Send to: Belong  PO BOX 743700, Los Angeles, CA 90074-3700',
  'email_template.direction_wa': 'Send to: Belong  PO BOX 743249, Los Angeles, CA 90074-3249',
  'email_template.account_ca': `- Account Holder: Belong<br />
    - Address: 100 S Ellsworth Ave Suite 400, San Mateo, CA 94401<br />
    - Bank: Bank of America<br />
    - Bank Address: 3000 El Camino Real Suite 130, Palo Alto, CA 94306<br />
    - Routing Number: 026009593<br />
    - Account Number: 325077117546<br />
  `,
  'email_template.account_wa': `- Account Holder: Belong<br />
    - Address: 100 S Ellsworth Ave Suite 400, San Mateo, CA 94401<br />
    - Bank: Bank of America<br />
    - Bank Address: 3000 El Camino Real Suite 130, Palo Alto, CA 94306<br />
    - Routing Number: 026009593<br />
    - Account Number: 325134099536<br />
  `,
};
