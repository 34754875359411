import React, { Component } from 'react';
import Measure from 'react-measure';
import ReactPlayer from 'react-player/lazy';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import Image from 'components/Image/Image';
import Spinner from 'components/Spinner/Spinner';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import Carousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import styles from './GalleryCarousel.module.css';

const cx = classNames.bind(styles);

class GalleryCarousel extends Component {
  static propTypes = {
    // TODO: Change to file
    images: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    index: PropTypes.number,
    fit: PropTypes.bool,
    carouselV2: PropTypes.bool,
    // Nuka Carousel requires the gallery to be in focus for the keyboard listeners to work.
    focusOnEntering: PropTypes.bool,
    fullPage: PropTypes.bool,
    autoplay: PropTypes.bool,
  };

  static defaultProps = {
    index: 0,
    fit: true,
    carouselV2: false,
    focusOnEntering: false,
    fullPage: false,
    autoplay: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      container: {},
      currentSlide: props.index,
      isReady: !props.carouselV2,
    };
  }

  componentDidMount() {
    const { focusOnEntering } = this.props;
    document.addEventListener('keydown', this.handleEscape, false);
    setTimeout(() => {
      if (focusOnEntering) {
        this.carousel.handleFocus();
      }
      this.carousel.setDimensions();
    }, 0);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscape, false);
  }

  changeLeftCaretToHover = () => {
    this.setState({
      hoveredLeftCaret: true,
    });
  };

  changeLeftCaretToNotHover = () => {
    this.setState({
      hoveredLeftCaret: false,
    });
  };

  changeRightCaretToHover = () => {
    this.setState({
      hoveredRightCaret: true,
    });
  };

  changeRightCaretToNotHover = () => {
    this.setState({
      hoveredRightCaret: false,
    });
  };

  handleEscape = (event) => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  renderCarouselV2 = ({ file, imageIndex }) => {
    const { currentSlide, container } = this.state;
    const { fullPage, autoplay } = this.props;
    return file.mediaType === 'Image' ? (
      <div className={cx('image', { fullPage })} key={imageIndex}>
        {imageIndex === currentSlide && (
          <Image
            className={cx({ contain: fullPage })}
            src={file.url}
            onLoad={() => {
              this.carousel.setDimensions();
              this.setState({
                isReady: true,
              });
            }}
          />
        )}
      </div>
    ) : (
      <Measure
        bounds
        onResize={({ bounds }) => {
          this.setState({ container: bounds });
        }}
      >
        {({ measureRef }) => {
          let playerHeight = '';
          if (fullPage) {
            playerHeight = '100vh';
          } else if (container.height >= 500) {
            playerHeight = '500px';
          }
          return (
            <div ref={measureRef} style={{ display: 'flex' }}>
              {imageIndex === currentSlide && (
                <ReactPlayer
                  onReady={() => {
                    this.carousel.setDimensions();
                    this.setState({
                      isReady: true,
                    });
                  }}
                  url={file.url}
                  controls
                  muted
                  width="100%"
                  height={playerHeight}
                  playing={autoplay}
                />
              )}
            </div>
          );
        }}
      </Measure>
    );
  };

  render() {
    const { carouselV2, images, onClose, index, fit } = this.props;
    const { hoveredLeftCaret, hoveredRightCaret, isReady } = this.state;

    return (
      <>
        {!isReady && <Spinner />}
        <div className={cx('carousel-container', { hideText: !isReady }, { fit })}>
          {onClose && (
            <ButtonBase className={cx('control', 'close')} onClick={onClose}>
              <div className={cx('icon')}>
                <GeneralIcon icon={GENERAL_ICONS.CLOSE} />
              </div>
            </ButtonBase>
          )}
          <div className={cx('carousel', { fit })}>
            <Carousel
              enableKeyboardControls
              slideIndex={index || 0}
              speed={0}
              beforeSlide={(slideIndex, nextSlide) => {
                if (slideIndex !== nextSlide && carouselV2) {
                  this.setState(() => ({
                    currentSlide: nextSlide,
                    isReady: false,
                  }));
                }
              }}
              ref={(carousel) => {
                this.carousel = carousel;
              }}
              heightMode="current"
              renderBottomCenterControls={() => null}
              renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                currentSlide !== 0 && (
                  <ButtonBase
                    className={cx('control', 'previous')}
                    onMouseEnter={this.changeLeftCaretToHover}
                    onMouseLeave={this.changeLeftCaretToNotHover}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      previousSlide();
                    }}
                  >
                    <div className={cx('icon')}>
                      {hoveredLeftCaret ? (
                        <GeneralIcon icon={GENERAL_ICONS.LEFT_CARET} scale={1.5} color={COLORS.DARK_NAVY} />
                      ) : (
                        <GeneralIcon icon={GENERAL_ICONS.LEFT_CARET} scale={1.5} />
                      )}
                    </div>
                  </ButtonBase>
                )
              }
              renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) =>
                currentSlide !== slideCount - 1 && (
                  <ButtonBase
                    className={cx('control', 'next')}
                    onMouseEnter={this.changeRightCaretToHover}
                    onMouseLeave={this.changeRightCaretToNotHover}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      nextSlide();
                    }}
                  >
                    <div className={cx('icon')}>
                      {hoveredRightCaret ? (
                        <GeneralIcon icon={GENERAL_ICONS.RIGHT_CARET} scale={1.5} color={COLORS.DARK_NAVY} />
                      ) : (
                        <GeneralIcon icon={GENERAL_ICONS.RIGHT_CARET} scale={1.5} />
                      )}
                    </div>
                  </ButtonBase>
                )
              }
            >
              {images.map((file, imageIndex) =>
                !carouselV2 ? (
                  <div className={cx('image')} key={imageIndex}>
                    <Image
                      src={file}
                      onLoad={() => {
                        this.carousel.setDimensions();
                      }}
                    />
                  </div>
                ) : (
                  this.renderCarouselV2({ file, imageIndex })
                )
              )}
            </Carousel>
          </div>
        </div>
      </>
    );
  }
}

export default GalleryCarousel;
