import { ENVIRONMENTS } from '@belong/common';
import commonConfig from './common.config';
import dev1Config from './customdev1.config';
import dev2Config from './customdev2.config';
import dev3Config from './customdev3.config';
import dev4Config from './dev4.config';
import dev5Config from './dev5.config';
import dev6Config from './dev6.config';
import dev7Config from './dev7.config';
import dev8Config from './dev8.config';
import developmentConfig from './local.config';
import productionConfig from './production.config';
import qaConfig from './qa.config';
import sandboxConfig from './sandbox.config';
import stagingConfig from './staging.config';

function prepareConfig() {
  let envConfig = {};
  const environment = process.env.CONFIG;

  switch (environment) {
    case ENVIRONMENTS.PRODUCTION:
      envConfig = productionConfig;
      break;
    case ENVIRONMENTS.SANDBOX:
      envConfig = sandboxConfig;
      break;
    case ENVIRONMENTS.STAGING:
      envConfig = stagingConfig;
      break;
    case ENVIRONMENTS.QA:
      envConfig = qaConfig;
      break;
    case ENVIRONMENTS.DEV1:
      envConfig = dev1Config;
      break;
    case ENVIRONMENTS.DEV2:
      envConfig = dev2Config;
      break;
    case ENVIRONMENTS.DEV3:
      envConfig = dev3Config;
      break;
    case ENVIRONMENTS.DEV4:
      envConfig = dev4Config;
      break;
    case ENVIRONMENTS.DEV5:
      envConfig = dev5Config;
      break;
    case ENVIRONMENTS.DEV6:
      envConfig = dev6Config;
      break;
    case ENVIRONMENTS.DEV7:
      envConfig = dev7Config;
      break;
    case ENVIRONMENTS.DEV8:
      envConfig = dev8Config;
      break;
    case ENVIRONMENTS.DEVELOPMENT:
    default:
      envConfig = developmentConfig;
      try {
        envConfig = { ...envConfig, ...require('./local.config.override').default };
      } catch (err) {
        // eslint-disable no-empty
      }
  }

  console.log('Environment', environment);

  return { env: environment, ...commonConfig, ...envConfig };
}

export default prepareConfig();
