import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Button } from '@belong/ui';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import Money, { MONEY_FORMAT, formatMoney } from 'components/Money/Money';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { useModal } from 'hooks/useModal';
import { DepositFAQs } from 'lease-signing-flow/components/deposit-faqs/deposit-faqs';
import { KarmaTooltip } from 'lease-signing-flow/components/karma-tooltip/karma-tooltip';
import { StepFormLayout } from 'lease-signing-flow/components/step-form-layout/step-form-layout';
import { DEPOSIT_TYPES } from 'lease-signing-flow/constants/deposit';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
import {
  fetchLeaseDepositTypes,
  selectLeaseDepositTypeOptions,
  selectSelectedLeaseDepositType,
  updateLeaseDepositType,
} from 'lease-signing-flow/store/deposit';
import { WearAndTearModal } from 'pages/PostInspectionFlow/steps/Improvements/Responsiblity/WearAndTearModal/WearAndTearModal';
import { formatString } from 'strings';
import { required } from 'utils/validation';

type DepositTypeOptionProps = {
  title: ReactNode;
  children: ReactNode;
};

function DepositTypeOption({ title, children }: DepositTypeOptionProps) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex items-baseline">
        <Text variant="h3" fontWeight="semibold">
          {title}
        </Text>
      </div>
      {children}
    </div>
  );
}

type PetDepositProps = {
  amount: number;
  type: typeof DEPOSIT_TYPES[keyof typeof DEPOSIT_TYPES];
};

function PetDeposit({ amount, type }: PetDepositProps) {
  if (!amount) return null;

  const petDepositText = `(Including ${formatMoney(amount, MONEY_FORMAT.DOLLARS_ONLYIF_CENTS)} Pet Deposit)`;
  const petFeeText = `(Including ${formatMoney(amount, MONEY_FORMAT.DOLLARS_ONLYIF_CENTS)}/mo Pet Fee)`;

  return (
    <Text variant="p1" className="mt-2xs">
      {type === DEPOSIT_TYPES.REFUNDABLE && petDepositText}
      {type === DEPOSIT_TYPES.FEE && petFeeText}
    </Text>
  );
}

type Props = {
  currentStep: any;
  lease?: any;
  onSubmit: () => void;
  setLoading: (isLoading: boolean) => void;
  steps: any;
};

export const DepositStep = ({ currentStep, lease, onSubmit, setLoading, steps }: Props) => {
  const [isModalOpen, openModal, closeModal] = useModal();
  const dispatch = useDispatch();
  const depositTypeOptions = useSelector(selectLeaseDepositTypeOptions);
  const selectedDepositType = useSelector(selectSelectedLeaseDepositType);
  const isRenewal = lease?.leaseInfo?.basicInfo?.renewalNumber > 0;

  const getInitialValues = () => {
    return {
      depositType: selectedDepositType,
    };
  };

  const getDepositTypeOptionButtons = () => {
    const depositTypeOptionButtons = [];
    if (!depositTypeOptions) {
      return depositTypeOptionButtons;
    }

    const refundableOption = depositTypeOptions.find((option) => option.depositType === DEPOSIT_TYPES.REFUNDABLE);
    const feeOption = depositTypeOptions.find((option) => option.depositType === DEPOSIT_TYPES.FEE);

    if (refundableOption) {
      depositTypeOptionButtons.push({
        label: (
          <DepositTypeOption title={formatMoney(refundableOption.amount, MONEY_FORMAT.DOLLARS_ONLYIF_CENTS)}>
            <Text className="mt-2xs">{LEASE_SIGNING_FLOW_STRINGS['deposit.refundable']}</Text>
            <PetDeposit amount={refundableOption.petAmount} type={DEPOSIT_TYPES.REFUNDABLE} />
          </DepositTypeOption>
        ),
        key: refundableOption.depositType,
      });
    }

    if (feeOption) {
      depositTypeOptionButtons.push({
        label: (
          <DepositTypeOption title={`${formatMoney(feeOption.amount, MONEY_FORMAT.DOLLARS_ONLYIF_CENTS)}/mo.`}>
            <Text className="mt-2xs">{LEASE_SIGNING_FLOW_STRINGS['deposit.fee']}</Text>
            <PetDeposit amount={feeOption.petAmount} type={DEPOSIT_TYPES.FEE} />
          </DepositTypeOption>
        ),
        key: feeOption.depositType,
      });
    }

    return depositTypeOptionButtons;
  };

  const handleSubmit = async (values: any) => {
    await dispatch(updateLeaseDepositType(values));

    onSubmit();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (lease) {
        await dispatch(fetchLeaseDepositTypes());

        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, lease]);

  const depositFeeDescription = isRenewal
    ? LEASE_SIGNING_FLOW_STRINGS['deposit.renewal.fee.description']
    : LEASE_SIGNING_FLOW_STRINGS['deposit.fee.description'];
  const refundableDepositDescription = isRenewal
    ? LEASE_SIGNING_FLOW_STRINGS['deposit.renewal.refundable.description']
    : LEASE_SIGNING_FLOW_STRINGS['deposit.refundable.description'];

  return (
    <StepFormLayout
      currentStep={currentStep}
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      steps={steps}
      title={
        isRenewal ? LEASE_SIGNING_FLOW_STRINGS['deposit.renewal.title'] : LEASE_SIGNING_FLOW_STRINGS['deposit.title']
      }
      subTitle={
        isRenewal
          ? LEASE_SIGNING_FLOW_STRINGS['deposit.renewal.subtitle']
          : LEASE_SIGNING_FLOW_STRINGS['deposit.subtitle']
      }
      getForm={({ values }) => {
        const { depositType } = values;

        const isFeeDeposit = depositType === DEPOSIT_TYPES.FEE;

        return (
          <>
            <div className="my-lg">
              <DepositFAQs isRenewal={isRenewal} />
            </div>
            <div className="relative">
              <Field
                buttons={getDepositTypeOptionButtons()}
                component={SelectorFinalFormAdapter}
                fluid
                name="depositType"
                type={SELECTOR_TYPES.SELECTOR}
                validate={required}
                verticalSpaceBetween={30}
              />
              <div className="absolute right-lg top-[144px] sm:top-2xs">
                <KarmaTooltip selected={isFeeDeposit} />
              </div>
            </div>
            {depositType && (
              <GreenInfoBox cssClassNames={['-mt-xs']}>
                <Text>
                  {formatString(isFeeDeposit ? depositFeeDescription : refundableDepositDescription, {
                    wearAndTear: (
                      <Button variant="text" onClick={openModal}>
                        wear and tear
                      </Button>
                    ),
                  })}
                </Text>
              </GreenInfoBox>
            )}
            <WearAndTearModal initialValueIndex={0} onHide={closeModal} show={isModalOpen} />
          </>
        );
      }}
    />
  );
};
