import { Text } from '@belong/ui';
import { GroupBalanceInfoModel, BillingInfoModel } from 'api/models';
import classNames from 'classnames/bind';
import FeeLabel from 'components/FeeLabel/FeeLabel';
import ItemDetail from 'components/ItemDetail/ItemDetail';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PaymentMethod from 'models/common/PaymentMethod';
import { PaymentMethod as PaymentMethodEnums, BillType, BillStatus } from 'models/enums/index';
import BillDetailModel from 'models/payments/BillDetailModel';
import { getFeeFromPaymentInfo } from 'models/utilities/paymentMethods';
import { PAYMENT_LIST_MODAL } from 'strings/payment-list-modal';
import { EARNINGS_STRINGS } from '../../../Pages/YourPortfolio/Earnings/earnings.strings';
import styles from './payment-bill-detail.module.css';

type BalanceItems = {
  financePlans: BillDetailModel['finances'];
  groupedItems: BillDetailModel['items'];
  ungroupedItems: BillDetailModel['items'];
};

type PaymentBillDetailProps = {
  items: BalanceItems | BillDetailModel['items'];
  billId: string;
  task: BillDetailModel;
  isHomeowner?: boolean;
  currentFeeToUse?: PaymentMethod;
  showPayment?: boolean;
  finances: BillDetailModel['finances'];
  home: string;
  handleFinancingModal: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowBalanceDetailsModal: (
    balance: GroupBalanceInfoModel,
    home: string,
    billingInfo?: BillingInfoModel,
    standalone?: boolean
  ) => void;
};

const cx = classNames.bind(styles);
const PLM = PAYMENT_LIST_MODAL.paymentdetails;

export function PaymentBillDetail({
  items,
  isHomeowner = false,
  billId,
  currentFeeToUse,
  task,
  showPayment,
  finances,
  home,
  handleFinancingModal,
  handleShowBalanceDetailsModal,
}: PaymentBillDetailProps) {
  function handleShowPaymentAmount(bill, currentFeeToUseParam, isHomeownerParam) {
    if (!isHomeownerParam) {
      if (bill.billInfo.type === BillType.Payment) {
        if (bill.billInfo?.status === BillStatus.Unpaid || bill.billInfo?.status === BillStatus.Failed) {
          return bill.getFeeFromItem(currentFeeToUseParam || '')?.totalWithFee || bill.billInfo.total;
        }
      }
    }

    return bill.billInfo.total;
  }

  function handleShowPartialPaid(bill, currentFeeToUseParam) {
    if (!bill?.billInfo?.hasRemainingAmount) {
      return null;
    }

    return (
      <>
        <ItemDetail
          firstItem={<div>{PLM.total_paid_so_fair}</div>}
          secondItem={<Money format={MONEY_FORMAT.DOLLARS_CENTS} cash={`-${bill.billInfo?.paidAmount}`} />}
        />
        <ItemDetail
          firstItem={<div>{PLM.total_remaining}</div>}
          secondItem={
            <Money
              format={MONEY_FORMAT.DOLLARS_CENTS}
              cash={
                bill.getFeeFromItem(currentFeeToUseParam || '')?.remainingAmountWithFee ||
                bill.billInfo?.remainingAmount
              }
            />
          }
          className="font-semibold text-body"
        />
      </>
    );
  }

  function handleBalanceDetails(item) {
    handleShowBalanceDetailsModal({ ...item, remaining: item.amount }, home, null, true);
  }

  return (
    <ModalLayout.Section className={cx('payment-bill-detail')}>
      <ItemDetail
        className="mb-sm"
        firstItem={<div className="font-semibold text-h3-xl">{EARNINGS_STRINGS['item_details.label']}</div>}
        secondItem={
          <div className="font-semibold text-body">
            {EARNINGS_STRINGS['id.label']} #{billId}
          </div>
        }
      />

      {typeof items?.ungroupedItems === 'undefined' ? (
        <>
          {items?.map((item) => [
            <ItemDetail
              alignTop
              key={item.itemId}
              firstItem={
                <div className={cx('two-third-width')} onClick={() => handleBalanceDetails(item)}>
                  <div className="cursor-pointer flex">
                    <Text className="max-w-[220px] md:max-w-full truncate">{item.description} </Text>
                    <div>
                      <svg
                        width="14"
                        height="13"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-xs"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14 6.49793C13.991 6.26565 13.8983 5.99629 13.7423 5.82775L8.65158 0.302087C8.30686 -0.0138488 7.64503 -0.144391 7.25921 0.220882C6.87969 0.582171 6.89109 1.28146 7.26881 1.64275L10.8489 5.52254L0.955254 5.52224C0.428124 5.52254 0.000301023 5.95952 5.68429e-07 6.49793C0.000300929 7.03634 0.428124 7.47332 0.955254 7.47362L10.8489 7.47332L7.26881 11.3531C6.94299 11.6859 6.88328 12.41 7.2592 12.775C7.63543 13.1403 8.31976 13.0229 8.65158 12.6938L13.7423 7.16811C13.916 6.97904 14.0015 6.75748 14 6.49793Z"
                          fill="#325D77"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              }
              secondItem={
                <div className="ml-lg">
                  <Money format={MONEY_FORMAT.DOLLARS_CENTS} cash={item.amount} />
                </div>
              }
            />,
          ])}
        </>
      ) : null}

      {items?.ungroupedItems?.map((item) => [
        <ItemDetail
          alignTop
          key={item.itemId}
          firstItem={
            <div className={cx('two-third-width')} onClick={() => handleBalanceDetails(item)}>
              <div className="cursor-pointer flex">
                <Text className="max-w-[220px] md:max-w-full truncate">{item.description} </Text>
                <div>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-xs"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 6.49793C13.991 6.26565 13.8983 5.99629 13.7423 5.82775L8.65158 0.302087C8.30686 -0.0138488 7.64503 -0.144391 7.25921 0.220882C6.87969 0.582171 6.89109 1.28146 7.26881 1.64275L10.8489 5.52254L0.955254 5.52224C0.428124 5.52254 0.000301023 5.95952 5.68429e-07 6.49793C0.000300929 7.03634 0.428124 7.47332 0.955254 7.47362L10.8489 7.47332L7.26881 11.3531C6.94299 11.6859 6.88328 12.41 7.2592 12.775C7.63543 13.1403 8.31976 13.0229 8.65158 12.6938L13.7423 7.16811C13.916 6.97904 14.0015 6.75748 14 6.49793Z"
                      fill="#325D77"
                    />
                  </svg>
                </div>
              </div>
            </div>
          }
          secondItem={
            <div className="ml-lg">
              <Money format={MONEY_FORMAT.DOLLARS_CENTS} cash={item.amount} />
            </div>
          }
        />,
      ])}
      {finances?.map((financePlan) => {
        return (
          <div key={financePlan.id}>
            <ItemDetail
              alignTop
              key={financePlan.uniqueId}
              firstItem={
                <div
                  className={cx('two-third-width')}
                  onClick={() => handleFinancingModal(financePlan.uniqueId, {}, 'Home')}
                >
                  <div className="cursor-pointer flex">
                    <Text className="max-w-[220px] md:max-w-full truncate">
                      {financePlan.description} {`(${financePlan.completedPayments} of ${financePlan.totalPayments})`}
                    </Text>
                    <div>
                      <svg
                        width="14"
                        height="13"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-xs"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14 6.49793C13.991 6.26565 13.8983 5.99629 13.7423 5.82775L8.65158 0.302087C8.30686 -0.0138488 7.64503 -0.144391 7.25921 0.220882C6.87969 0.582171 6.89109 1.28146 7.26881 1.64275L10.8489 5.52254L0.955254 5.52224C0.428124 5.52254 0.000301023 5.95952 5.68429e-07 6.49793C0.000300929 7.03634 0.428124 7.47332 0.955254 7.47362L10.8489 7.47332L7.26881 11.3531C6.94299 11.6859 6.88328 12.41 7.2592 12.775C7.63543 13.1403 8.31976 13.0229 8.65158 12.6938L13.7423 7.16811C13.916 6.97904 14.0015 6.75748 14 6.49793Z"
                          fill="#325D77"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              }
              secondItem={
                <div className="ml-lg">
                  -
                  <Money
                    format={MONEY_FORMAT.DOLLARS_CENTS}
                    cash={financePlan.totalAmount / financePlan.installments}
                  />
                </div>
              }
            />
            <div className="pl-sm">
              {items?.groupedItems?.map((item) => {
                //TODO: wait for backend to change financeId to integer, already asked Matias P
                // eslint-disable-next-line eqeqeq
                if (item.financeId != financePlan.id) return null;
                return (
                  <ItemDetail
                    alignTop
                    key={item.itemId}
                    firstItem={
                      <div className={cx('two-third-width')} onClick={() => handleBalanceDetails(item)}>
                        <div className="cursor-pointer flex">
                          <Text className="max-w-[220px] md:max-w-full truncate">{item.description} </Text>
                          <div>
                            <svg
                              width="14"
                              height="13"
                              viewBox="0 0 14 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-xs"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14 6.49793C13.991 6.26565 13.8983 5.99629 13.7423 5.82775L8.65158 0.302087C8.30686 -0.0138488 7.64503 -0.144391 7.25921 0.220882C6.87969 0.582171 6.89109 1.28146 7.26881 1.64275L10.8489 5.52254L0.955254 5.52224C0.428124 5.52254 0.000301023 5.95952 5.68429e-07 6.49793C0.000300929 7.03634 0.428124 7.47332 0.955254 7.47362L10.8489 7.47332L7.26881 11.3531C6.94299 11.6859 6.88328 12.41 7.2592 12.775C7.63543 13.1403 8.31976 13.0229 8.65158 12.6938L13.7423 7.16811C13.916 6.97904 14.0015 6.75748 14 6.49793Z"
                                fill="#325D77"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                    secondItem={
                      <div className="ml-lg">
                        <Money format={MONEY_FORMAT.DOLLARS_CENTS} cash={item.amount} />
                      </div>
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      {!isHomeowner &&
        showPayment &&
        task.getFeeFromItem(currentFeeToUse || '')?.feeAmount > 0 &&
        currentFeeToUse.paymentMethod === PaymentMethodEnums.CreditCard && (
          <ItemDetail
            animate
            key="PaymentDetails"
            firstItem={
              <div className="flex items-center">
                <div>
                  Credit Card Fee (
                  {getFeeFromPaymentInfo(
                    task?.getFeeFromItem(currentFeeToUse)?.fee,
                    task?.getFeeFromItem(currentFeeToUse)?.feeType
                  )}
                  )
                </div>
                <FeeLabel label="Added" />
              </div>
            }
            secondItem={
              <>
                <Money cash={task.getFeeFromItem(currentFeeToUse)?.feeAmount} format={MONEY_FORMAT.DOLLARS_CENTS} />
              </>
            }
          />
        )}
      <ItemDetail
        firstItem={<div>{PLM.total}</div>}
        secondItem={
          <span className="font-semibold">
            <Money
              format={MONEY_FORMAT.DOLLARS_CENTS}
              cash={handleShowPaymentAmount(task, currentFeeToUse, isHomeowner)}
            />
          </span>
        }
        className="mt-sm font-semibold"
      />
      {handleShowPartialPaid(task, currentFeeToUse)}
    </ModalLayout.Section>
  );
}
