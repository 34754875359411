import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { required } from 'utils/validation';
import { maskNumber, unmaskNumber } from 'components/Input/masks';

const LaundryFormFields = ({ name, disabled }) => (
  <Row>
    <Col md={6}>
      <Field
        name={`${name}.attributes.numberOfWashers`}
        component={InputFinalFormAdapter}
        validate={required}
        placeholder="Number of Washers"
        mask={maskNumber}
        unmask={unmaskNumber}
        disabled={disabled}
        applyDisabledStyle={disabled}
      />
    </Col>
    <Col md={6}>
      <Field
        name={`${name}.attributes.numberOfDryers`}
        component={InputFinalFormAdapter}
        validate={required}
        placeholder="Number of Dryers"
        mask={maskNumber}
        unmask={unmaskNumber}
        disabled={disabled}
        applyDisabledStyle={disabled}
      />
    </Col>
  </Row>
);

LaundryFormFields.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

LaundryFormFields.defaultProps = {
  name: '',
  disabled: false,
};

export default LaundryFormFields;
