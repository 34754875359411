import { HOME_DISCLOSURES_STRINGS } from 'accounts/constants/strings/home-disclosures.strings';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { Col } from 'forkedlibraries/react-bootstrap';
import { Disclosures } from 'models/enums';
import { composeValidators, integer, required } from 'utils/validation';

export function DisclosureFormField({ item }) {
  const showHasSepticSystem = item.name === Disclosures.HasSepticSystem;

  return (
    <Col md={24}>
      {showHasSepticSystem && (
        <Field
          validate={composeValidators(required, integer)}
          placeholder={HOME_DISCLOSURES_STRINGS.permitted_bedrooms}
          component={InputFinalFormAdapter}
          name={`disclosures.types.${item.name}.bedroomsCountPermitted`}
        />
      )}

      <Field
        validate={required}
        placeholder={HOME_DISCLOSURES_STRINGS.describe}
        component={InputFinalFormAdapter}
        name={`disclosures.types.${item.name}.comment`}
      />
    </Col>
  );
}
