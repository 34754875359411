import { groupRegionByState, groupActiveRegionByState } from '@belong/common';
import { groupBy } from 'lodash-es';
import { getApiActionTypes, getApiActionTypesValues } from '../redux_utils';

export const ACTIONS = {
  ...getApiActionTypes('FETCH_SETTINGS'),
  ...getApiActionTypes('FETCH_PAYOUT_PLANS'),
  ...getApiActionTypes('FETCH_REGIONS'),
};

const initialState = {
  applicationFee: null,
  payoutPlanFees: null,
  regions: null,
};

export const SETTINGS_REDUCER = 'settings';
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        applicationFee: action.result?.feeSettings?.applicationFee,
        proServiceSettings: action.result?.proServiceSettings,
        featureFlags: action.result?.commerceSettings?.featureFlagsSettings,
        commerceSettings: action.result?.commerceSettings,
      };
    case ACTIONS.FETCH_PAYOUT_PLANS_SUCCESS:
      return {
        ...state,
        payoutPlanFees: action.result,
      };
    case ACTIONS.FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.result,
      };
    default:
      return state;
  }
}

export const fetchSettings = () => ({
  types: getApiActionTypesValues('FETCH_SETTINGS'),
  promise: ({ client }) => client.get('/settings'),
});

export const fetchPayoutPlans = () => ({
  types: getApiActionTypesValues('FETCH_PAYOUT_PLANS'),
  promise: ({ client }) => client.get('/payout-plans'),
});

export const fetchRegions = () => ({
  types: getApiActionTypesValues('FETCH_REGIONS'),
  promise: ({ client }) => client.get('/regions'),
});

export const selectSettings = (state) => state[SETTINGS_REDUCER];
export const selectResidentApplicationFee = (state) => {
  const settings = selectSettings(state);

  return settings?.applicationFee?.feeAmount;
};

export const selectNoApprovalMaximumThresholdAmount = (state) =>
  selectSettings(state).proServiceSettings?.noApprovalMaximumThresholdAmount;

export const selectPayoutPlans = (state) => selectSettings(state).payoutPlanFees;
export const selectPayoutPlansGroupedByRegion = (state) => {
  const payoutPlans = selectPayoutPlans(state);

  // Group array by region Id.
  const payoutPlansGroupedById = groupBy(payoutPlans, 'regionId');
  const payoutPlansGroupedByName = {};

  // Create an object of the shape { [regionId]: [name]: val }, ex: { 2: { DEFAULT: {...}, SOME_EXPERIMENT: {...}}}
  Object.entries(payoutPlansGroupedById).forEach(([key, val]) => {
    payoutPlansGroupedByName[key] = groupBy(val, 'name');
  });

  return payoutPlansGroupedByName;
};

export const selectRegions = (state) => {
  const { regions } = selectSettings(state);
  const dataWithoutOtherRegions = regions?.filter((region) => region.status !== 'Other') || [];
  const regionsByState = groupRegionByState(dataWithoutOtherRegions);
  const activeRegionsByState = groupActiveRegionByState(dataWithoutOtherRegions) || {};
  const states = Object.keys(regionsByState).sort((a, b) => a.localeCompare(b));
  const activeStates = Object.keys(activeRegionsByState).sort((a, b) => a.localeCompare(b));

  const isLoading = regions === null;

  return { regions: dataWithoutOtherRegions, regionsByState, activeRegionsByState, states, activeStates, isLoading };
};

export const selectFeatureFlags = (state) => {
  return selectSettings(state).featureFlags;
};
