import React from 'react';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import PropTypes from 'prop-types';
import styles from './PDFViewer.module.css';

const cx = classNames.bind(styles);

export const DownloadLink = ({ file, onClick }) => {
  const downloadFile = () => {
    window.open(file);
  };
  return (
    <div className={cx('download-container')}>
      <Button onClick={onClick || downloadFile} buttonType={BUTTON_TYPES.TEXT}>
        DOWNLOAD PDF
      </Button>
    </div>
  );
};

DownloadLink.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
};
