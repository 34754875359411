import React, { Component } from 'react';
import { PATHS } from '@belong/common';
import PropTypes from 'prop-types';
import BulletList from '../../components/BulletList/BulletList';
import ColoredText, { COLORS } from '../../components/ColoredText/ColoredText';
import Money from '../../components/Money/Money';
import HomeInfo from '../../components/display/Home/HomeInfo/HomeInfo';
import AccountProperty from '../../containercomponents/AccountProperty/AccountProperty';
import RenterAccountLease from '../../models/renterAccounts/RenterAccountLease';

class RenterLeaseSummaryContainer extends Component {
  static propTypes = {
    lease: PropTypes.instanceOf(RenterAccountLease).isRequired,
    status: PropTypes.string.isRequired,
  };

  renderTag() {
    const { status } = this.props;

    if (status === 'Current') {
      return 'Currently Renting';
    } else if (status === 'Future') {
      return 'Upcoming Lease';
    }

    return <ColoredText text="Previously Rented" color={COLORS.GRAY} />;
  }

  renderTitle() {
    const {
      status,
      lease: { address },
    } = this.props;
    let color = COLORS.NAVY;

    if (status === 'Previous') {
      color = COLORS.GRAY;
    }

    return <ColoredText text={address.streetAddress} color={color} />;
  }

  renderSubtitles() {
    const {
      status,
      lease,
      lease: { unitInfo },
    } = this.props;
    const subtitles = [];
    let color = COLORS.NAVY;

    if (status === 'Previous') {
      color = COLORS.GRAY;
    }

    subtitles.push(
      <ColoredText
        text={
          <BulletList
            flexWrap
            listItems={[
              <HomeInfo key="0" unitBasic={unitInfo} />,
              <span key="1">
                <Money cash={lease.leaseInfo.basicInfo.rentAmount} />
                /m
              </span>,
            ]}
          />
        }
        color={color}
      />
    );

    return subtitles;
  }

  renderProfiles() {
    const {
      lease: { unitInfo, residents },
    } = this.props;
    const profilesProps = [];

    residents.forEach((resident) => {
      profilesProps.push({
        labels: [{ text: resident.leaseInfo.residentType }],
        title: resident.basicInfo.userInfo.firstName,
        subtitles: [`Unit ${unitInfo.unitNumber}`],
        imageUrl: resident.basicInfo.userInfo.profileImageThumbnailUrl,
      });
    });

    return profilesProps;
  }

  render() {
    const {
      lease: {
        unitInfo: { unitId, bannerImageUrl },
        leaseInfo: {
          basicInfo: { leaseId },
        },
      },
    } = this.props;

    return (
      <AccountProperty
        to={`${PATHS.RESIDENTS_ACCOUNT_PROPERTIES}/${unitId}/${leaseId}`}
        miniImageCardProps={{
          tag: this.renderTag(),
          title: this.renderTitle(),
          subtitles: this.renderSubtitles(),
          imageUrl: bannerImageUrl,
        }}
        profilesProps={this.renderProfiles()}
      />
    );
  }
}

export default RenterLeaseSummaryContainer;
