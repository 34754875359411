import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'design-system';
import { pluralizeWithoutValue } from 'utils/pluralize';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';

export const ApplicationsInProcess = ({ value }) => {
  if (!value) return null;
  // Use current minus 1 since the copy says "other applications being procceced"
  const substractCurrent = value - 1;
  return (
    <Flex width="280px" flexDirection="column" alignItems="center">
      <Box mb="md">
        <GeneralIcon icon={GENERAL_ICONS.ICON_APPLICATION_IN_PROCESS} />
      </Box>
      <Box mb="xs">
        <Text fontWeight="semibold">Your application is processing.</Text>
      </Box>

      {value === 1 ? (
        <Text textAlign="center" fontSize="p1">
          {`We’ll get right to work reviewing <span>your application.</span>`}
        </Text>
      ) : (
        <Text textAlign="center" fontSize="p1">
          There {pluralizeWithoutValue(substractCurrent, 'is')} currently {substractCurrent} other applications being
          processed by our team.
        </Text>
      )}
    </Flex>
  );
};

ApplicationsInProcess.propTypes = {
  value: PropTypes.number.isRequired,
};
