export default class OnboardingInfo {
  constructor(data = {}) {
    this.rentalManagement = data.rentalManagement;
    this.rentalPlan = data.rentalPlan;
    this.payoutPlan = data.payoutPlan;
    this.yearPurchased = data.yearPurchased;
    this.lastRenovation = data.lastRenovation;
    this.appliancesAge = data.appliancesAge;
    this.isLookingResident = data.isLookingResident;
    this.condition = data.condition;
    this.rentalStatus = data.rentalStatus;
    this.previousRentAmount = data.previousRentAmount;
    this.previousLeaseEndOn = data.previousLeaseEndOn;
    this.currentRentAmount = data.currentRentAmount;
    this.currentLeaseEndOn = data.currentLeaseEndOn;
    this.moveinOn = data.moveinOn;
    this.moveoutOption = data.moveoutOption;
    this.moveoutOn = data.moveoutOn;
    this.moveoutStartOn = data.moveoutStartOn;
    this.moveoutEndOn = data.moveoutEndOn;
    this.moveoutComments = data.moveoutComments;
    this.moveoutPlan = data.moveoutPlan;
    this.moveoutDestination = data.moveoutDestination;
    this.moveoutDestinationNotSure = data.moveoutDestinationNotSure;
    this.moveoutOwningStatus = data.moveoutOwningStatus;
    this.moveoutRentingStatus = data.moveoutRentingStatus;
    this.availabilityDate = data.availabilityDate;
    this.availableForRentOn = data.availableForRentOn;
    this.availableForRentOption = data.availableForRentOption;
    this.availableForRentComments = data.availableForRentComments;
    this.hasWarrantyPolicy = data.hasWarrantyPolicy;
    this.warrantyPolicyExpiresOn = data.warrantyPolicyExpiresOn;
    this.hoaFee = data.hoaFee;
    this.mortgage = data.mortgage;
    this.estimatedAnnualSpend = data.estimatedAnnualSpend;
    this.doesOwnerKnowCost = data.doesOwnerKnowCost;
    this.isLeaseMonthToMonth = data.isLeaseMonthToMonth;
    this.existingResidents = data.existingResidents;
    this.residentsRelationship = data.residentsRelationship;
    this.residentsRelationshipComments = data.residentsRelationshipComments;
    this.notInterestedInBelongServices = data.notInterestedInBelongServices;
    this.notInterestedInBelongServicesReasons = data.notInterestedInBelongServicesReasons;
    this.notInterestedInBelongServicesOtherReason = data.notInterestedInBelongServicesOtherReason;
    this.plansHaveChangedComments = data.plansHaveChangedComments;
  }
}
