import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import styles from './HomeAlreadyExistsModal.module.css';

const cx = classNames.bind(styles);

const HomeAlreadyExistsModal = ({ history, userOwnsDuplicateHome, onRightNodeButtonClick, show, onHide }) => {
  let leftNode = null;
  let modalTitle = HOMEOWNER_ONBOARDING_STRINGS['existing_modal.default_title'];
  let rightNode = (
    <Button
      label={HOMEOWNER_ONBOARDING_STRINGS['existing_modal.default_button']}
      onClick={() => {
        onRightNodeButtonClick();
        onHide();
      }}
    />
  );

  if (userOwnsDuplicateHome) {
    modalTitle = HOMEOWNER_ONBOARDING_STRINGS['existing_modal.homeowner_title'];
    leftNode = (
      <div className={cx('left-side-wrapper')}>
        <Button
          onClick={() => {
            history.push(BASE_PATHS.ACCOUNTS);
          }}
          label={HOMEOWNER_ONBOARDING_STRINGS['existing_modal.homeowner_go_to_account']}
          buttonType={BUTTON_TYPES.TEXT}
        />
      </div>
    );
    rightNode = (
      <Button
        label={HOMEOWNER_ONBOARDING_STRINGS['existing_modal.default_button']}
        onClick={() => {
          onRightNodeButtonClick();
          onHide();
        }}
      />
    );
  }

  return (
    <ModalV2 show={show} onHide={onHide} closeButton noPadding>
      <ModalLayout>
        <ModalLayout.Content>
          <ModalLayout.Title label={modalTitle} />
          <ModalLayout.BottomBar fixedBottom leftSideNode={leftNode} rightNode={rightNode} />
        </ModalLayout.Content>
      </ModalLayout>
    </ModalV2>
  );
};

HomeAlreadyExistsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  userOwnsDuplicateHome: PropTypes.bool,
  history: PropTypes.object.isRequired,
  onRightNodeButtonClick: PropTypes.func,
};

HomeAlreadyExistsModal.defaultProps = {
  show: false,
  onRightNodeButtonClick: () => {},
  userOwnsDuplicateHome: false,
};

export default withRouter(HomeAlreadyExistsModal);
