import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useModal, PATHS } from '@belong/common';
import { Text, Link as LinkUI, Image, Divider, Button, LinkBase } from '@belong/ui';
import classNames from 'classnames/bind';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Label from 'components/Label/Label';
import MiniImageCard from 'components/MiniImageCard/MiniImageCard';
import ResidentListContainer from 'containers/ResidentListContainer/ResidentListContainer';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import InitialEstimateModal from 'pages/Accounts/Components/InitialEstimateModal/InitialEstimateModal';
import PropTypes from 'prop-types';
import { selectWealth } from 'store/redux/homeowner-accounts/selectors';
import { selectUserToDos } from 'store/redux/user/selectors';
import styles from './AccountProperty.module.css';

const cx = classNames.bind(styles);

const AccountProperty = ({ to = '', miniImageCardProps, unitProps, propertyAddress, hover = true, footerLink }) => {
  const [selectedUnit, setSelectedUnit] = useState();
  const [isInitialModalOpen, openInitialModal, closeInitialModal] = useModal();
  const todos = useSelector((state) => selectUserToDos(state));
  const wealth = useSelector(selectWealth);

  const renderProperty = () => {
    return (
      <>
        <MiniImageCard {...miniImageCardProps} />
        <div className={cx('account-profiles')}>
          {unitProps?.unitProps?.length > 0 && unitProps?.hasResidents && (
            <>
              <Space value={SPACE_TYPES.XL} mobileValue={SPACE_TYPES.LG} />
              <div className={cx('divider')} />
              <Row>
                <Col sm={12}>
                  <Space />
                  <h3>Residents</h3>
                </Col>
                {unitProps?.unitProps?.map((units, index) => {
                  const { residents = [], kids = [], pets = [] } = units || {};
                  const allResidents = {
                    residents,
                    kids,
                    pets,
                  };
                  return (
                    <div key={index}>
                      {(units.residents?.length > 0 || index > 0) && (
                        <Col sm={12}>
                          <Space />
                        </Col>
                      )}
                      {units.label && (
                        <Col sm={12}>
                          <div className={cx('labels')}>
                            <Label text={`Unit ${units.label}`} />
                            {units.residents?.length === 0 && (
                              <div className={cx('residents-text')}>Looking For Residents</div>
                            )}
                          </div>
                        </Col>
                      )}
                      <Col xs={12}>
                        <ResidentListContainer residents={allResidents} />
                      </Col>
                    </div>
                  );
                })}
              </Row>
            </>
          )}

          {footerLink?.label && footerLink?.href && (
            <div className="flex flex-col items-stretch">
              <Space value={SPACE_TYPES.LG} mobileValue={SPACE_TYPES.LG} />
              <div className={cx('divider')} />
              <LinkUI href={footerLink.href} className="mt-xl mb-xs self-end">
                {footerLink.label}
              </LinkUI>
            </div>
          )}
        </div>
      </>
    );
  };

  const openPricingModal = () => {
    if (unitProps?.unitProps?.[0]) {
      setSelectedUnit(unitProps?.unitProps?.[0]);
      openInitialModal();
    }
  };

  const renderInitialEstimateLink = () => {
    if (unitProps?.unitProps[0]?.isInitialPriceRangePublished) {
      return (
        <div>
          <Space value={SPACE_TYPES.SM} mobileValue={SPACE_TYPES.MD} />
          <div className={cx('divider')} />
          <Button onClick={openPricingModal} className="flex w-full items-center mt-sm" variant="text">
            <Text fontWeight="semibold">ESTIMATED RENT PRICE</Text>
            <div className="flex flex-1 justify-end">
              <IconButton align={ALIGN_TYPES.RIGHT} icon={GENERAL_ICONS.FRONT_ARROW} />
            </div>
          </Button>
        </div>
      );
    }

    return null;
  };

  const renderWealthReportButton = () => {
    const unitId = unitProps?.unitProps?.[0]?.basicInfo?.id;
    const unitWealth = wealth?.find((wealthItem) => wealthItem.home.id === unitId);

    if (unitWealth) {
      return (
        <div className="flex flex-col mt-lg">
          <Divider />
          <LinkBase href={PATHS.HOMEOWNER_ACCOUNT_WEALTH} className="flex gap-xs items-center pt-sm">
            <Image src="/homeowners-wealth/wealth.svg" alt="" className="w-xl h-xl" />
            <Text fontWeight="semibold">VIEW WEALTH REPORT</Text>
          </LinkBase>
        </div>
      );
    }

    return null;
  };

  if (to) {
    return (
      <div className={cx('account-property', { hover })}>
        <Link to={to}> {renderProperty()}</Link>
        {renderInitialEstimateLink()}
        {renderWealthReportButton()}
        <InitialEstimateModal
          unit={selectedUnit}
          address={propertyAddress}
          isOpen={isInitialModalOpen}
          onDismiss={closeInitialModal}
          addressModel={propertyAddress}
        />
      </div>
    );
  }

  return (
    <div className={cx('account-property', { hover })}>
      {renderProperty()}
      {renderInitialEstimateLink()}
      {renderWealthReportButton()}
      <InitialEstimateModal
        unit={selectedUnit}
        address={propertyAddress}
        isOpen={isInitialModalOpen}
        onDismiss={closeInitialModal}
        todos={todos}
        addressModel={propertyAddress}
      />
    </div>
  );
};

AccountProperty.propTypes = {
  to: PropTypes.string,
  miniImageCardProps: PropTypes.object.isRequired,
  unitProps: PropTypes.array,
  propertyAddress: PropTypes.object,
  hover: PropTypes.bool,
  footerLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
};

AccountProperty.defaultProps = {
  footerLink: null,
};

export default AccountProperty;
