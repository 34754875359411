export const TYPES = {
  NONE: 'none',
  DEFAULT: 'default',
  SELFIE: 'selfie',
  DOCUMENT: 'document',
  ID: 'id',
  PASSPORT: 'passport',
  HOME: 'home',
};

export const SIZE = {
  SMALL: {
    width: 325,
  },
  MEDIUM: {
    width: 650,
  },
};

export const SIZE_STRINGS = {
  MEDIUM: 'MEDIUM',
};
