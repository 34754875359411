import { useContext } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Button, Money, Text } from '@belong/ui';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { STRINGS } from 'post-inspection/constants/strings';

type Props = {
  onNextStepClick: () => void;
};

export const PaymentSummaryFooter = ({ onNextStepClick }: Props) => {
  const { homeownerReportPayment, inspectionType } = useContext<any>(ApprovalContext);
  const { isPreMoveOut } = inspectionType;

  const { width } = useWindowSize();

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  const { groupDueNow } = homeownerReportPayment;

  const handleClick = () => {
    onNextStepClick();
  };

  let cta = groupDueNow ? STRINGS['approve-pay-deposit'] : STRINGS.approve;
  if (isPreMoveOut) {
    cta = STRINGS['approve-pay-deposit.pre_move_out'];
  }

  return (
    <div
      className="
        flex flex-col md:flex-row items-center bg-dark-navy px-sm py-md md:py-lg md:rounded-b-small fixed bottom-0 left-0 md:relative -left-2sm md:left-0 w-screen md:w-full justify-between
      "
    >
      <div className="items-center justify-end self-end hidden md:flex">
        <Button onClick={handleClick} size="auto">
          <div className="px-sm">{cta}</div>
        </Button>
      </div>
      {!isPreMoveOut && (
        <div className="flex items-end mb-sm md:mb-0 md:flex-col md:justify-start">
          <Text as="span" className="text-white mr-[6px] md:mr-0">
            {STRINGS['due-upfront']}
          </Text>
          <Money
            as="span"
            className="text-white"
            fontWeight="semibold"
            format="DOLLARS_WITH_CENTS"
            value={groupDueNow}
            variant="h3"
          />
        </div>
      )}
      <div className="flex items-center w-full md:hidden">
        <Button onClick={handleClick} size="fluid">
          <div className="px-sm">{cta}</div>
        </Button>
      </div>
    </div>
  );
};
