import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Page from '../Page/Page';
import styles from './SingleColumnFlowLayout.module.css';

const cx = classNames.bind(styles);

const propTypes = {
  children: PropTypes.node.isRequired,
};

const SingleColumnFlowLayout = (props) => {
  return (
    <div className={cx('single-column-flow-layout')}>
      <Page footer={false} {...props} />
    </div>
  );
};

SingleColumnFlowLayout.propTypes = propTypes;

export default SingleColumnFlowLayout;
