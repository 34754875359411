import React, { Component } from 'react';
import { Form } from 'react-final-form';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { unmaskRemoveWhiteSpace, maskRemoveWhiteSpace } from 'components/Input/masks';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { email, required } from 'utils/validation';
import styles from './ForgotPasswordEnterEmail.module.css';

const cx = classNames.bind(styles);

class ForgotPasswordEnterEmail extends Component {
  static title = 'Forgot your password?';

  static description =
    "Enter your email address and we'll send you an email containing a link to <span>reset your password.</span>";

  static closeButton = true;

  static propTypes = {
    handleForgotPasswordEnterEmailSubmit: PropTypes.func.isRequired,
  };

  validateEmail = (emailString) => required(emailString) || email(emailString);

  render() {
    return (
      <div className={cx('fp-enter-email-content-wrapper')}>
        <Form
          onSubmit={this.props.handleForgotPasswordEnterEmailSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <Field
                    name="email"
                    component={InputFinalFormAdapter}
                    placeholder="Email address"
                    mask={maskRemoveWhiteSpace}
                    unmask={unmaskRemoveWhiteSpace}
                    validate={this.validateEmail}
                  />
                </Col>
              </Row>
              <div className={cx('send-reset-link-button')}>
                <Button type="submit" label="Reset Password" disabled={invalid} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default ForgotPasswordEnterEmail;
