import classNames from 'classnames/bind';
import BelongEmployeeProfileView from 'components/BelongEmployeeProfileView/BelongEmployeeProfileView';
import { MODES } from 'components/ProfileImageWithLabel/ProfileImageWithLabel';
import TextBubble from 'components/TextBubble/TextBubble';
import PropTypes from 'prop-types';
import styles from './TextBubbleWithUserProfile.module.css';

const cx = classNames.bind(styles);

const ACTIONS_FORMAT = {
  STACKED: 'STACKED',
  PIPED: 'PIPED',
};

const TextBubbleWithUserProfile = ({
  employee,
  text,
  subText,
  profileImageMode,
  withModal,
  textBelowProfile,
  showContact,
  showCalendarLink,
  makeGreen,
  textBubbleProps,
  bottomTextColor,
}) => {
  const calendarLink = employee.calendarLink
    ? employee.calendarLink.includes('https')
      ? employee.calendarLink
      : `https://${employee.calendarLink}`
    : null;
  const getSecondaryText = (format = ACTIONS_FORMAT.STACKED) => {
    if (showContact) {
      const email =
        employee.workEmail ||
        employee.email ||
        `${employee.firstName[0].toLowerCase()}${employee.lastName.toLowerCase()}@belonghome.com`;
      const emailComponent = (
        <a className={cx('contact', bottomTextColor)} href={`mailto:${email}`} onClick={(e) => e.stopPropagation()}>
          {email}
        </a>
      );

      const { phone } = employee;

      const phoneComponent = (
        <a className={cx('contact', bottomTextColor)} href={`tel:${phone?.link}`}>
          {phone?.display}
        </a>
      );

      const calendarComponent = (
        <a
          className={cx('contact font-semibold hover:text-green', bottomTextColor)}
          href={calendarLink}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
        >
          SCHEDULE A CALL WITH ME
        </a>
      );

      if (format === ACTIONS_FORMAT.STACKED) {
        if (calendarLink && showCalendarLink) {
          return calendarComponent;
        }
        return (
          <>
            <div>{phone && phoneComponent}</div>
            <div>{emailComponent}</div>
          </>
        );
      } else {
        if (calendarLink && showCalendarLink) {
          return calendarComponent;
        }
        return (
          <div>
            {phone ? (
              <span className={cx('contact', bottomTextColor)}>
                {phoneComponent} <span className={cx('desktop-pipe')}>|</span> {emailComponent}
              </span>
            ) : (
              emailComponent
            )}
          </div>
        );
      }
    }

    return null;
  };

  if (textBelowProfile) {
    return (
      <>
        <div className={cx('component-wrapper-text-below-profile')}>
          <div className={cx('profile-wrapper-top')}>
            <BelongEmployeeProfileView
              employee={employee}
              textBelowProfile={textBelowProfile}
              withModal={withModal}
              profileImageMode={MODES.LABEL_RIGHT}
            />
          </div>
          <div className={cx('text-bubble-wrapper', { textBelowProfile })}>
            <TextBubble
              text={text}
              subText={subText}
              secondaryText={getSecondaryText()}
              makeGreen={makeGreen}
              pointingTop
              {...textBubbleProps}
            />
          </div>
        </div>
        <div className={cx('desktop-height-maker')} />
      </>
    );
  }

  return (
    <>
      <div className={cx('component-wrapper')}>
        <div className={cx('profile-wrapper-desktop')}>
          <BelongEmployeeProfileView
            employee={employee}
            profileImageMode={profileImageMode}
            withModal={withModal}
            actions={getSecondaryText(ACTIONS_FORMAT.PIPED)}
            bottomTextColor={bottomTextColor}
          />
        </div>
        <div className={cx('text-bubble-wrapper')}>
          <TextBubble text={text} subText={subText} {...textBubbleProps} />
        </div>
        <div className={cx('profile-wrapper-mobile')}>
          <BelongEmployeeProfileView
            employee={employee}
            profileImageMode={profileImageMode}
            withModal={withModal}
            actions={getSecondaryText(ACTIONS_FORMAT.PIPED)}
            bottomTextColor={bottomTextColor}
          />
        </div>
      </div>
      <div className={cx('desktop-height-maker')} />
    </>
  );
};

TextBubbleWithUserProfile.propTypes = {
  text: PropTypes.node,
  subText: PropTypes.node,
  employee: PropTypes.object.isRequired,
  profileImageMode: PropTypes.oneOf(Object.values(MODES)),
  withModal: PropTypes.bool,
  textBelowProfile: PropTypes.bool,
  makeGreen: PropTypes.bool,
  showContact: PropTypes.bool,
  textBubbleProps: PropTypes.object,
  // When using this component on a dark background, we need every text not on the bubble to be white.
  // Couldn't think on another way of doing it.
  bottomTextColor: PropTypes.string,
  showCalendarLink: PropTypes.bool,
};

TextBubbleWithUserProfile.defaultProps = {
  text: '',
  profileImageMode: MODES.LABEL_BELOW,
  withModal: true,
  textBelowProfile: false,
  makeGreen: false,
  showContact: false,
  textBubbleProps: {},
  bottomTextColor: '',
  showCalendarLink: false,
};

export default TextBubbleWithUserProfile;
