import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserProfilePopout from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserProfilePopout/UserProfilePopout';
import PropTypes from 'prop-types';
import { fetchProDetailsById } from 'store/redux/pros/actions';
import { selectOtherProById } from 'store/redux/pros/selectors';
import { fetchUserDetailsById } from 'store/redux/user/actions';
import { selectOtherUserById } from 'store/redux/user/selectors';

class UserProfilePopoutContainer extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    proProfile: PropTypes.object,
    memberProfile: PropTypes.object,
    belongPro: PropTypes.bool,
    belongEmployee: PropTypes.bool,
    hideProfileModal: PropTypes.func.isRequired,
    profileImage: PropTypes.string.isRequired,
    fetchUserDetailsById: PropTypes.func.isRequired,
    fetchProDetailsById: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    alignProfileItems: PropTypes.string,
    relative: PropTypes.bool,
    absoluteMobileOverlay: PropTypes.bool,
  };

  static defaultProps = {
    profile: null,
    proProfile: null,
    memberProfile: null,
    belongPro: false,
    belongEmployee: false,
    relative: false,
    alignProfileItems: null,
    absoluteMobileOverlay: false,
  };

  fetchUser = async () => {
    const {
      fetchUserDetailsById: fetchUserDetailsByIdAction,
      fetchProDetailsById: fetchProDetailsByIdAction,
      userId,
      belongPro,
      proProfile,
      memberProfile,
      profile,
    } = this.props;

    if (!memberProfile && !proProfile && !profile) {
      try {
        if (belongPro) {
          await fetchProDetailsByIdAction(userId);
        } else {
          await fetchUserDetailsByIdAction(userId);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  async componentDidMount() {
    await this.fetchUser();
  }

  async componentDidUpdate(prevProps) {
    const { userId } = this.props;
    if (userId !== prevProps.userId) {
      await this.fetchUser();
    }
  }

  getUserProfile = () => {
    const { memberProfile, profile, proProfile } = this.props;

    if (memberProfile) {
      return memberProfile;
    } else if (proProfile) {
      return proProfile;
    } else if (profile) {
      return profile;
    }

    return null;
  };

  render() {
    const {
      profileImage,
      belongPro,
      show,
      hideProfileModal,
      alignProfileItems,
      relative,
      absoluteMobileOverlay,
      belongEmployee,
    } = this.props;

    return (
      <UserProfilePopout
        absoluteMobileOverlay={absoluteMobileOverlay}
        show={show}
        relative={relative}
        alignProfileItems={alignProfileItems}
        profile={this.getUserProfile()}
        belongPro={belongPro}
        profileImage={profileImage}
        hideProfileModal={hideProfileModal}
        belongEmployee={belongEmployee}
      />
    );
  }
}

export default connect(
  (state, { userId }) => ({
    memberProfile: selectOtherUserById(state, userId),
    proProfile: selectOtherProById(state, userId),
  }),
  {
    fetchUserDetailsById,
    fetchProDetailsById,
  }
)(UserProfilePopoutContainer);
