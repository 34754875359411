import React from 'react';
import { Satisfaction } from 'formcomponents/satisfaction/satisfaction';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { HOME_OWNER_PAPER_WORK_STRINGS } from 'strings/homeowner-paperwork.strings';

export const ResidentRelationshipSection = ({ name }) => (
  <FormLayout.Section sectionTitle={HOME_OWNER_PAPER_WORK_STRINGS['homeOccupancy.relationship.title']}>
    <Satisfaction name={`${name}.residentsRelationship`} />
  </FormLayout.Section>
);

ResidentRelationshipSection.propTypes = {
  name: PropTypes.string.isRequired,
};
