import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { Col } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import ProfileTile, { PROFILE_TILE_SPACING } from '../../components/ProfileTile/ProfileTile';
import styles from './ResidentTile.module.css';

const cx = classNames.bind(styles);

class ResidentTile extends Component {
  static propTypes = {
    profileProps: PropTypes.object.isRequired,
    ctaLabel: PropTypes.string,
    onCTAClick: PropTypes.func,
    residentInfo: PropTypes.array,
    alignProfileItems: PropTypes.string,
  };

  static defaultProps = {
    ctaLabel: '',
    onCTAClick: () => {},
    residentInfo: [],
    alignProfileItems: null,
  };

  render() {
    const { profileProps, ctaLabel, onCTAClick, alignProfileItems, residentInfo } = this.props;

    return (
      <Col md={6} xs={12} className={cx('renter')}>
        <ProfileTile
          relative
          alignProfileItems={alignProfileItems}
          spacing={PROFILE_TILE_SPACING.NO_SPACING}
          text={residentInfo}
          {...profileProps}
        />
        {ctaLabel && <Button label={ctaLabel} onClick={onCTAClick} />}
      </Col>
    );
  }
}

export default ResidentTile;
