import { useHistory } from 'react-router';
import { BASE_PATHS } from '@belong/common';
import { Text, Image } from '@belong/ui';
import classNames from 'classnames/bind';
import CircledNumber from 'components/CircledNumber/CircledNumber';
import DividerRow from 'components/DividerRow/DividerRow';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import Icon, { ICONS } from 'components/Icon/Icon';
import Carousel from 'components/v2/Carousel/Carousel';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import Page from 'layouts/Page/Page';
import { getInspectionType, QUERY_PARAMS } from 'pages/PostInspectionFlow/utils';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getImprovementsAssignedEmployee } from '../utils';
import styles from './Quality.module.css';
import { ImprovementsBenefits } from './components/improvements-benefits/improvements-benefits';

const cx = classNames.bind(styles);

const qualityDividerRowItems = [
  {
    icon: <Icon icon={ICONS.INVESTIGATION.DEFAULT} responsive />,
    title: 'Optimal',
    subtitle: formatString(`The right solution to the problem. No more, no\u00a0less.`),
  },
  {
    icon: <Icon icon={ICONS.PREVENTATIVE.DEFAULT} responsive />,
    title: 'Enduring',
    subtitle: formatString(`Built to last, maximizing your home's\u00a0value.`),
  },
  {
    icon: <Icon icon={ICONS.NOTEPAD.DEFAULT} responsive />,
    title: 'Compliant',
    subtitle: formatString(`Meet all codes, standards, and\u00a0regulations.`),
  },
  {
    icon: <Icon icon={ICONS.RECURRING_MAINTENANCE.DEFAULT} responsive />,
    title: 'Expedited',
    subtitle: formatString(`All work starts within {br}5 days of approval.`, { br: <br /> }),
  },
  {
    icon: <Icon icon={ICONS.OPEN_HOME.DEFAULT} responsive />,
    title: 'Delightful',
    subtitle: formatString(`If you’re not delighted by the work we do, we’ll do it again. On us.`),
  },
];

const diyDividerRowItems = [
  {
    top: <CircledNumber>1</CircledNumber>,
    icon: <Image className={cx('divider-image')} src="/pro-services/quality_section_img_01.png" />,
    title: 'Reduced Liability',
    subtitle: formatString(
      `We partner with licensed, bonded & insured vendors, significantly reducing your\u00a0liability.`
    ),
  },
  {
    top: <CircledNumber>2</CircledNumber>,
    icon: <Image className={cx('divider-image')} src="/pro-services/quality_section_img_02.png" />,
    title: 'Reduced Vacancy Time',
    subtitle: formatString(`Work performed by outside contractors typically takes longer, increasing\u00a0vacancy.`),
  },
  {
    top: <CircledNumber>3</CircledNumber>,
    icon: <Image className={cx('divider-image')} src="/pro-services/quality_section_img_03.png" />,
    title: 'Reduced Redo Costs',
    subtitle: formatString(`Work performed by outside contractors often needs redoing, increasing\u00a0costs.`),
  },
];

const dataSpeakItems = [
  {
    title: '1 Week',
    subtitle: 'Avg. Time Saved Listing Homes With Belong Vendors Network',
  },
  {
    title: '$800 - $1,200',
    subtitle: 'Typical Vacancy Saved Using Belong Vendors',
  },
  {
    title: '20%',
    subtitle: 'Avg. Repairs Redone Due To Poor Quality Outside Work',
  },
  {
    title: '12 Hours',
    subtitle: 'Avg. Time Spent By Homeowners Managing Repairs Themselves',
  },
];

export const testimonialItems = [
  {
    title: `Don’s Bathroom Remodel`,
    subtitle: 'Belong Homeowner <span>Since December 2019</span>',
    text: `First I just have to acknowledge how great it was working with Milan and his crew. They are all working their tails off to get the work done in what are certainly <span>challenging times.</span>`,
    profileImage: '/post_inspection/testimonials/don_profile.jpg',
    beforeUrl: '/post_inspection/testimonials/postinspection_testm_01_before.jpg',
    afterUrl: '/post_inspection/testimonials/postinspection_testm_01_after.jpg',
  },
  {
    title: 'Margit’s Climate Control Upgrade',
    subtitle: 'Belong Homeowner <span>Since January 2019</span>',
    text: `Francesca has navigated through a complicated furnace installation, and a re-signing of the lease... I'd say she pretty <span>much rocks.</span>`,
    profileImage: '/post_inspection/testimonials/profile_margit.jpg',
    beforeUrl: '/post_inspection/testimonials/postinspection_testm_02_before.jpg',
    afterUrl: '/post_inspection/testimonials/postinspection_testm_02_after.jpg',
  },
];

export const DelightfulBadge = () => (
  <div className={cx('badge-with-sparkles-container')}>
    <GeneralIcon className={cx('sparkles')} color={COLORS.GREEN} icon={GENERAL_ICONS.CLOUDS_AND_SPARKLES} />
    <GeneralIcon className={cx('badge')} color={COLORS.GREEN} icon={GENERAL_ICONS.BIG_GOLD_BADGE} />
  </div>
);

export const QualityStaticContent = () => {
  return (
    <>
      <Page.Section
        sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.diy_section.title'])}
        removeBottomSpacing
      />
      <Space value={SPACE_TYPES.XL} />
      {/* Desktop DIY divider row */}
      <div className={cx('desktop-divider-row-container')}>
        <DividerRow desktopColumns={3} items={diyDividerRowItems} />
      </div>

      {/* Mobile DIY divider row */}
      <div className={cx('mobile-divider-row-container')}>
        <Carousel pagingDotsClassName="green">
          {diyDividerRowItems.map((item, index) => (
            <DividerRow.Item key={index} item={item} defaultMobileIconSize={false} />
          ))}
        </Carousel>
      </div>
      <Space value={SPACE_TYPES.XXXL} />

      <Page.Section
        sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.data_speak'])}
        removeBottomSpacing
      />

      <Space value={SPACE_TYPES.XL} />
      <div className="grid grid-cols-2 gap-2sm lg:gap-md">
        {dataSpeakItems.map((item) => (
          <div key={item.title} className="bg-light-gray rounded pr-xs p-xs lg:p-sm">
            <Text fontWeight="semibold" className="text-h3-sm lg:text-h2-xl mb-xs">
              {item.title}
            </Text>
            <Text className="text-p1 lg:text-body leading-p2 lg:leading-p1">{item.subtitle}</Text>
          </div>
        ))}
      </div>

      <Space value={SPACE_TYPES.XXXL} />

      <div className="flex gap-sm lg:gap-3xl items-center flex-col lg:flex-row">
        <Page.Section
          sectionPretitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.pro_hero.pretitle'])}
          sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.pro_hero.title'])}
          removeBottomSpacing
        />
        <div className={styles['quality-heros-image']}>
          <Image src="/pro-services/post_inspection_qulity_hero_01.svg" />
        </div>
      </div>

      <Space value={SPACE_TYPES.XXXL} />

      <Page.Section
        sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.maintenance_talent.title'])}
        removeBottomSpacing
      />
      <Space value={SPACE_TYPES.XL} />

      {/* Desktop qualities divider row */}
      <div className={cx('desktop-divider-row-container')}>
        <DividerRow desktopColumns={3} items={qualityDividerRowItems} />
      </div>

      {/* Mobile qualities divider row */}
      <div className={cx('mobile-divider-row-container')}>
        <Carousel pagingDotsClassName="green">
          {qualityDividerRowItems.map((item, index) => (
            <DividerRow.Item key={index} item={item} />
          ))}
        </Carousel>
      </div>
      <Space value={SPACE_TYPES.XXXL} />
    </>
  );
};

const propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
  flow: PropTypes.object.isRequired,
};

const Quality = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, flow }) => {
  const history = useHistory();

  const areNonHomeowerFixesStepsCompleted = stepConfigs
    ?.filter((stepConfigItem) => stepConfigItem.key !== 'homeowner-fixes' && !stepConfigItem.isHidden)
    .every((stepConfigItem) => stepConfigItem.status === 'Completed');

  /**
   * The homeowner cannot go back to this step once the report is approved.
   */
  if (areNonHomeowerFixesStepsCompleted) {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${stepConfig.home.propertyId}/setup-flow?${QUERY_PARAMS.REPORT_APPROVED}=true`
    );

    return null;
  }

  const inspectionType = getInspectionType(flow);
  const assessedBy = getImprovementsAssignedEmployee({
    employeeAssignments: stepResource?.homeListingDetailsModel?.employeeAssignments,
    isMoveOut: inspectionType.isMoveOut,
  });

  const isApprovalStepHidden = stepConfigs?.find((x) => x.name === 'ImprovementsApproval')?.isHidden === true;

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={
        isApprovalStepHidden
          ? POST_INSPECTION_FLOW_STRINGS['quality.title.noImprovements']
          : POST_INSPECTION_FLOW_STRINGS['quality.title']
      }
      employee={assessedBy}
      showCityBackground
      isLogoVisible={false}
    >
      <div className="justify-center mb-3xl hidden md:flex flex-col items-center pt-sm">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()} className="w-button-fluid mb-xs">
          {isApprovalStepHidden
            ? POST_INSPECTION_FLOW_STRINGS['quality.cta.noImprovements']
            : POST_INSPECTION_FLOW_STRINGS['quality.cta']}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <div className="md:hidden overflow-hidden w-0 h-0">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()} displayfixed>
          {isApprovalStepHidden
            ? POST_INSPECTION_FLOW_STRINGS['quality.cta.noImprovements']
            : POST_INSPECTION_FLOW_STRINGS['quality.cta']}
        </PostInspectionContentLayout.CtaButton>
      </div>

      {isApprovalStepHidden ? null : (
        <>
          <div className="mb-lg sm:mb-2xl">
            <Text fontWeight="semibold" variant="h3">
              {POST_INSPECTION_FLOW_STRINGS['quality.subtitle']}
            </Text>
          </div>
          <ImprovementsBenefits />
        </>
      )}
    </PostInspectionContentLayout>
  );
};

Quality.propTypes = propTypes;

export default Quality;
