export const PROPERTY_HOA_STRINGS = {
  title: "Let's make sure we play by the rules.",
  'title.in_progress': 'Last time we asked about HOA, you told us the following. Anything to change?',
  done_label: "Next Section",
  "done_label.units": "Next Unit",
  'has_hoa.title': 'Do you have an HOA at {propertyStreetAddress}?',
  'has_hoa.subtitle':
    'Or something similar? If so, we’ll make sure we comply with any and all rules set by your homeowner or neighborhood association.',
  'who_is_hoa_contact.title': 'Who is your HOA’s primary contact?',
  'who_is_hoa_contact.dont_have': "I don't know. Remind me later!",
  'notified_hoa.title': 'Have you notified your HOA that Belong will be managing your home?',
  'notified_hoa.false_text':
    'Ok, please do so ASAP and cc us on that email if possible so we can take over the relationship. They may require written authorization as well for us to manage your home.',
  'upload_hoa.title': 'Upload your HOA Agreement (CC&Rs).',
  'upload_hoa.subtitle': 'CC&Rs stands for Convenience, Conditions, and Restrictions. Email your HOA to ask for it.',
  'upload_hoa.dont_have': "I don't have it. Remind me later!",
  'upload_hoa.relationship': 'What’s your relationship like with your HOA?',

}
