import Field from 'components/Field/Field';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { availableForRentOptionSelectors } from 'strings/rental-status';
import { required } from 'utils/validation';

export const AvailableForRentOptionsFields = ({
  name,
  buttons = availableForRentOptionSelectors,
  type = SELECTOR_TYPES.MEDIUMTEXTBUTTON,
}) => (
  <Row>
    <Col xs={12}>
      <Field
        buttons={buttons}
        component={SelectorFinalFormAdapter}
        fluid
        name={`${name}.availableForRentOption`}
        type={type}
        validate={required}
      />
    </Col>
  </Row>
);

AvailableForRentOptionsFields.propTypes = {
  name: PropTypes.string.isRequired,
  buttons: PropTypes.array,
  type: PropTypes.oneOf(SELECTOR_TYPES),
};
