import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import styles from './AccountPageSection.module.css';

const cx = classNames.bind(styles);

class AccountPageSection extends Component {
  static propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    content: PropTypes.node,
    pretitle: PropTypes.node,
    noPaddingTop: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    subtitle: '',
    content: '',
    pretitle: '',
    noPaddingTop: false,
  };

  static NoItems = ({ children }) => <div className={cx('no-items')}>{children}</div>;

  static Pretext = ({ children }) => <div className={cx('pretext')}>{children}</div>;

  static Text = ({ ...rest }) => (
    <div className={cx('text-wrapper')}>
      <Text {...rest} />
    </div>
  );

  render() {
    const { title, pretitle, subtitle, content, noPaddingTop } = this.props;

    return (
      <div className={cx('section')}>
        {pretitle && <div className={cx('pretitle')}>{pretitle}</div>}
        {title && <h3 className={cx('title')}>{title}</h3>}
        {subtitle && <div className={cx('subtitle')}>{subtitle}</div>}
        {content && <div className={cx('section-content', noPaddingTop && 'no-padding-top')}>{content}</div>}
      </div>
    );
  }
}

export default AccountPageSection;
