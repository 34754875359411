import React, { useState } from 'react';
import classNames from 'classnames/bind';
import DownshiftButton from 'components/DownshiftComponents/DownshiftButton/DownshiftButton';
import DownshiftItem from 'components/DownshiftComponents/DownshiftItem/DownshiftItem';
import DownshiftMenu from 'components/DownshiftComponents/DownshiftMenu/DownshiftMenu';
import { downShiftItemProptypes } from 'components/DownshiftComponents/downShiftItemProptypes';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import InputTag from 'components/TaggableInput/InputTag';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { useSelect, useMultipleSelection } from 'downshift';
import { findIndex } from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './MultiDropdown.module.css';

const cx = classNames.bind(styles);

const itemToString = (i) => (i ? i.value : '');

export const MultiDropdownComponent = ({
  placeholder,
  error,
  items,
  disabled,
  onSelectedItemChange,
  defaultSelectedItems,
  value,
}) => {
  const [highlight, setHighlight] = useState(false);
  const [menuBlurCleanUp, setMenuBlurCleanUp] = useState(false);
  // const [useIndex, setUseIndex] = useState(defaultHighlightedIndex);

  // There is a weird bug in downshift where useSelect.stateChangeTypes.MenuBlur is always called even though you are clicking on the button state and have the refs pointed there.
  // This is to allow you to focus again after 25 ms.
  const removeMenuBlur = () =>
    setTimeout(() => {
      setMenuBlurCleanUp(false);
    }, 0);

  const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } =
    useMultipleSelection({ initialSelectedItems: defaultSelectedItems });

  const getFilteredItems = () => items.filter((item) => selectedItems.indexOf(item) < 0);

  const { reset, isOpen, highlightedIndex, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: getFilteredItems(),
    itemToString,
    defaultSelectedItem: null,
    defaultHighlightedIndex: 0,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;

      if (type === useSelect.stateChangeTypes.MenuBlur) {
        setMenuBlurCleanUp(true);
      }

      const index = findIndex(items, (e) => {
        return e.key === state.selectedItem?.key;
      });
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonClick:
          // https://github.com/downshift-js/downshift/issues/645
          // This is an incredibly dumb bug
          if (state.previousType === useSelect.stateChangeTypes.MenuBlur && menuBlurCleanUp) {
            return { ...changes, isOpen: false, previousType: type, highlightedIndex: null };
          }

          // From official docs to handle changes.
          // eslint-disable-next-line no-prototype-builtins
          if (changes.hasOwnProperty('isOpen') && changes.isOpen)
            return {
              ...changes,
              previousType: type,
              highlightedIndex: index,
            };
          return { ...changes, previousType: type, highlightedIndex: null };

        case useSelect.stateChangeTypes.FunctionReset:
          return { ...changes, previousType: type, highlightedIndex: null };
        default:
          return { ...changes, previousType: type };
      }
    },
    onStateChange: ({ type, selectedItem: _selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (_selectedItem) {
            addSelectedItem(_selectedItem);
            onSelectedItemChange([...value, _selectedItem.key]);
          }
          break;
        default:
          break;
      }
    },
  });

  const displayIcon = (_isOpen) => {
    return (
      <div className={cx('format-icon', { 'rotate-icon': _isOpen })}>
        <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
      </div>
    );
  };

  // sets clean up for MenuBlur
  React.useEffect(() => {
    if (menuBlurCleanUp) {
      removeMenuBlur();
    }
  }, [menuBlurCleanUp]);

  // https://github.com/downshift-js/downshift/issues/977
  React.useEffect(() => {
    reset();
  }, [defaultSelectedItems?.length]);

  return (
    <div className={cx('dropdown-wrapper')}>
      <div>
        <DownshiftButton
          {...getToggleButtonProps({
            placeholder,
            onBlur: () => {
              setHighlight(false);
            },
            onFocus: () => {
              setHighlight(true);
            },
          })}
          highlight={highlight || isOpen}
          error={error}
          disabled={disabled}
          value={!!selectedItems.length}
          multi
        >
          {selectedItems.map((_selectedItem, index) => {
            return (
              <div
                className={cx('tag-container')}
                key={`selected-item-${index}`}
                {...getSelectedItemProps({ _selectedItem, index })}
              >
                <InputTag
                  key={_selectedItem.value}
                  name={_selectedItem.value}
                  nameClassName={cx('ellipsis-tag')}
                  onRemoveTag={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(_selectedItem);
                    const selectedItemIndex = value.indexOf(_selectedItem.key);
                    const _value = structuredClone(value);
                    _value.splice(selectedItemIndex, 1);
                    onSelectedItemChange(_value);
                  }}
                />
              </div>
            );
          })}
        </DownshiftButton>
        {displayIcon && (
          <ButtonBase
            className={cx('icon-button-wrapper')}
            tabIndex="-1"
            {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}
            disabled={disabled}
          >
            {displayIcon(isOpen)}
          </ButtonBase>
        )}
      </div>
      <DownshiftMenu isOpen={isOpen} {...getMenuProps()}>
        {isOpen &&
          getFilteredItems(items).map((item, index) => {
            return (
              <DownshiftItem
                isActive={highlightedIndex === index}
                key={`${item.value}${index}`}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                {itemToString(item)}
              </DownshiftItem>
            );
          })}
      </DownshiftMenu>
    </div>
  );
};

MultiDropdownComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(downShiftItemProptypes)).isRequired,
  displayIcon: PropTypes.func.isRequired,
  onSelectedItemChange: PropTypes.func.isRequired,
  defaultSelectedItems: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.array,
};

MultiDropdownComponent.defaultProps = {
  defaultSelectedItems: [],
};

export const MultiDropdownFinalFormAdapter = ({
  input,
  meta,
  onChangeCustom,
  ignoreError,
  newFieldComponent,
  items,
  ...rest
}) => {
  const controlledProps = {};

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = !!meta.error;
    } else {
      controlledProps.error = meta.touched ? !!meta.error : false;
    }
  }

  // to normalize data from input.value into downshift
  let defaultSelectedItem = input.value;
  if (input.value === '') {
    defaultSelectedItem = [];
  } else if (Array.isArray(input.value) && typeof input.value[0] === 'string') {
    defaultSelectedItem = input.value.map((value) => {
      const itemValue = items.find((item) => item.key === value);
      return {
        key: itemValue.key,
        value: itemValue.value,
      };
    });
  }

  return (
    <MultiDropdownComponent
      {...input}
      {...rest}
      items={items}
      onSelectedItemChange={(value) => {
        input.onChange(value);

        if (onChangeCustom) {
          onChangeCustom(value);
        }
      }}
      defaultSelectedItem={defaultSelectedItem}
      {...controlledProps}
    />
  );
};

MultiDropdownFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func,
  selectedKey: PropTypes.string,
  allowUserInput: PropTypes.bool,
  ignoreError: PropTypes.bool,
  newFieldComponent: PropTypes.bool,
  items: PropTypes.array,
};

MultiDropdownFinalFormAdapter.defaultProps = {
  onChangeCustom: () => {},
  selectedKey: null,
  allowUserInput: false,
  ignoreError: false,
  newFieldComponent: false,
};
