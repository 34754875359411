export default class BillBasicInfo {
  constructor(data = {}) {
    this.id = data.id;
    this.displayId = data.displayId;
    this.description = data.description;
    this.dueDate = data.dueDate;
    this.isAutoProcess = data.isAutoProcess;
    this.postedOn = data.postedOn;
    this.status = data.status;
    this.type = data.type;
    this.total = data.total;
    this.paidAmount = data.paidAmount;
    this.remainingAmount = data.remainingAmount;
    this.lastUpdatedOn = data.lastUpdatedOn;
    this.hasRemainingAmount = this.hasRemainingAmount();
  }

  hasRemainingAmount() {
    if (this.remainingAmount === 0) {
      return false;
    }

    if (this.remainingAmount === this.total) {
      return false;
    }

    if (this.paidAmount === this.total) {
      return false;
    }

    return true;
  }
}
