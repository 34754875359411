export const PARKING_FEATURE_STRINGS = {
  garage: 'Garage',
  lot: 'Lot',
  carport: 'Carport',
  driveway: 'Driveway',
  onStreetParking: 'On-Street Permit',
  offStreetParking: 'Off-Street Permit',
  canResidentsUseIt: 'Can residents use it?',
  yes: 'Yes',
  no: 'No',
  numberOfSpots: "Number of Spots",
  unlimited: "Unlimited",
  assignedSpot: "This spot have an assigned number",
  spotNumber: "Spot Number"
};
