import formatStringByPattern from 'format-string-by-pattern';
import { isNil, includes } from 'lodash-es';

export const maskPhoneNumber = (value) => {
  if (!value) {
    return value;
  }

  const onlyNumbers = value.replace(/[^\d]/g, '');

  if (!onlyNumbers) {
    return onlyNumbers;
  }

  return formatStringByPattern('(999) 999 - 9999', onlyNumbers);
};

export const unmaskPhoneNumber = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/\D+/g, '');
};

export const maskCreditCardNumber = (value) => {
  if (!value) {
    return value;
  }

  const onlyNumbers = value.replace(/[^\d]/g, '');

  if (!onlyNumbers) {
    return onlyNumbers;
  }

  const isAmex = /^34/.test(onlyNumbers) || /^37/.test(onlyNumbers);

  if (isAmex) {
    return formatStringByPattern('9999 999999 99999', onlyNumbers);
  }

  return formatStringByPattern('9999 9999 9999 9999', onlyNumbers);
};

export const unmaskCreditCardNumber = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/\s/g, '');
};

export const maskMMYY = (value) => {
  if (!value || (includes(value, '/') && value.length < 5) || typeof value === 'object') {
    return value;
  }

  const onlyNumbers = value?.replace(/[^\d]/g, '');

  if (!onlyNumbers) {
    return onlyNumbers;
  }

  return formatStringByPattern('99/99', onlyNumbers);
};

export const unmaskMMYY = (value) => {
  if (typeof value === 'object') {
    return value;
  }

  if (value.length > 5) {
    return value.substr(0, 4);
  }

  return value;
};

export const unmaskNumber = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/[^\d]/g, '');
};

export const maskNumber = (value) => {
  if (!value) {
    return value;
  }

  const onlyNumbers = value.toString().replace(/[^\d]/g, '');

  return onlyNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const maskCurrency = (value, allowZero) => {
  if (isNil(value) || value === '') {
    return value;
  }

  let onlyNumbers = value.toString().replace(/[^\d]/g, '');

  if (!allowZero) {
    onlyNumbers = onlyNumbers.replace(/^0*/, '');
  }

  return `$${onlyNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const maskMMDDYYYY = (value) => {
  if (!value || (includes(value, '/') && value.length < 9)) {
    return value;
  }

  const onlyNumbers = value.toString().replace(/[^\d]/g, '');

  if (!onlyNumbers) {
    return onlyNumbers;
  }

  return formatStringByPattern('99/99/9999', onlyNumbers);
};

export const unmaskMMDDYYYY = (value) => {
  if (value.length > 10) {
    return value.substr(0, 9);
  }

  return value;
};

export const maskMMDDYY = (value) => {
  if (!value || (includes(value, '/') && value.length < 7)) {
    return value;
  }

  const onlyNumbers = value.toString().replace(/[^\d]/g, '');

  if (!onlyNumbers) {
    return onlyNumbers;
  }

  return formatStringByPattern('99/99/99', onlyNumbers);
};

export const unmaskMMDDYY = (value) => {
  if (value.length > 8) {
    return value.substr(0, 8);
  }

  return value;
};

export const maskYYYY = (value) => {
  if (!value) {
    return value;
  }

  const onlyNumbers = value.toString().replace(/[^\d]/g, '');

  return onlyNumbers;
};

export const unmaskYYYY = (value) => {
  if (typeof value === 'string' && value.length > 4) {
    return value.substr(0, 4);
  }

  return value;
};

export const maskSSN = (value) => {
  if (!value || (includes(value, '-') && value.length < 8)) {
    return value;
  }

  return formatStringByPattern('999-99-9999', value);
};

export const unmaskSSN = (value) => {
  if (value.length > 11) {
    return value.substr(0, 10);
  }

  return value;
};

export const maskEIN = (value) => {
  if (!value || (includes(value, '-') && value.length < 3)) {
    return value;
  }

  return formatStringByPattern('99-9999999', value);
};

export const unmaskEIN = (value) => {
  if (value.length > 10) {
    return value.substr(0, 9);
  }

  return value;
};

export const maskRemoveWhiteSpace = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/ /g, '');
};

export const unmaskRemoveWhiteSpace = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/ /g, '');
};
