import { useSelector } from 'react-redux';
import { ButtonBase, Text, Money } from '@belong/ui';
import cx from 'classnames';
import { getMonthName } from 'pages/Accounts/utils/get-month-name';
import { selectAccountPropertyByUnitId, selectAccountUnitByUnitId } from 'store/redux/homeowner-accounts/selectors';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';

export function PastMonth({ detail, handleShowPastMonthModal, address = '', showAddress = true }) {
  const property = useSelector(selectAccountPropertyByUnitId(detail.homeId));
  const unit = useSelector(selectAccountUnitByUnitId(detail.homeId));
  const homeAddress = address || formatString(addressAndUnit(property?.address, unit?.basicInfo.unitNumber));

  return (
    <ButtonBase
      className={cx(
        'border-solid rounded border-gray border ease-out transition-colors p-sm w-full mb-xs hover:border-navy'
      )}
      onClick={() => handleShowPastMonthModal(detail)}
    >
      <div className="flex items-baseline flex-row justify-between text-left lg:items-center">
        <div className="overflow-hidden">
          <Text fontWeight="semibold">{`${detail.monthSummaryInfo.year} ${getMonthName(
            detail.monthSummaryInfo.month
          )}`}</Text>
          {showAddress && <Text variant="p1">{homeAddress}</Text>}
        </div>
        <Money className="font-semibold text-body" value={detail.total} format="DOLLARS_WITH_CENTS" />
      </div>
    </ButtonBase>
  );
}
