import Coordinates from 'models/common/Coordinates';
import { addressAndUnit, formatAddress } from 'utils/formatAddress';

export default class Address {
  constructor(data = {}) {
    this.addressId = data.addressId;
    this.streetAddress = data.streetAddress;
    this.city = data.city;
    this.zipcode = data.zipcode;
    this.state = data.state;
    this.country = data.country;
    this.completeAddress = data.completeAddress;
    this.unitNumber = data.unitNumber;
    this.outsideUS = data.outsideUS;
    this.displayNeighborhood = data.displayNeighborhood;
    this.neighborhood = data.neighborhood;
    this.coordinates = new Coordinates(data.coordinates);
  }

  getFormattedAddress(unitNumber) {
    return formatAddress(this, unitNumber);
  }

  getStreetAddress(unitNumber) {
    if (!this.streetAddress) {
      return '';
    }

    return `${this.streetAddress}${unitNumber ? ` Unit ${unitNumber}` : ''}`;
  }

  getAddressAndUnitNumber(unitNumber) {
    return addressAndUnit(this, unitNumber);
  }

  // Used on listing page
  getAddressStringCompact(unitNumber) {
    const isNumeric = !Number.isNaN(Number(unitNumber));
    let unitString = '';

    if (!this.streetAddress) {
      return `${this.city}, ${this.state}`;
    }

    if (unitNumber && isNumeric) {
      unitString = ` #${unitNumber}`;
    } else if (unitNumber && !isNumeric) {
      unitString = ` ${unitNumber}`;
    }

    return `${this.streetAddress}${unitString}, ${this.city}`;
  }
}
