import React from 'react';
import classNames from 'classnames/bind';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import styles from './HeaderItem.module.css';

const cx = classNames.bind(styles);

const HeaderItem = ({ string, icon }) => (
  <div className={cx('header-item-wrapper')}>
    <Text>{string}</Text>
    {icon && <div className={cx('header-icon-wrapper')}>{icon}</div>}
  </div>
);

HeaderItem.propTypes = {
  icon: PropTypes.node,
  string: PropTypes.string.isRequired,
};

HeaderItem.defaultProps = {
  icon: null,
};

export default HeaderItem;
