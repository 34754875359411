import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentPreference, PaymentPriority } from 'models/enums/typings';
import { selectFullUser } from 'store/redux/user/selectors';

export function useSplitPaymentModal({ items, simulateSplitPayments, createBillsPayment, enabled = true }) {
  const [splitPaymentModal, setSplitPaymentModal] = useState(false);
  const [maxCashBack, setMaxCashBack] = useState<number | undefined>();
  const [paymentDone, setPaymentDone] = useState<boolean | undefined>();
  const dispatch = useDispatch();

  const fullUser = useSelector(selectFullUser);
  const userPrimaryPaymentMethod = fullUser?.getPaymentMethodFor(PaymentPreference.Payout, PaymentPriority.Primary);

  async function handleBillsPayment() {
    const billsPayBody = {
      paymentAccountId: userPrimaryPaymentMethod.accountId,
      balanceUniqueIds: items
        .filter((bal) => bal.isFinanceable)
        .map((bal) => bal.balances)
        .flat()
        .map((bal) => bal.sourceKey),
    };
    try {
      const payment = await dispatch(createBillsPayment(billsPayBody));
      if (payment) {
        setPaymentDone(true);
      }
    } catch (err) {
      console.error(`Payment was not possible: ${err}`);

      setPaymentDone(false);
    }
  }

  useEffect(() => {
    const doSimulation = async () => {
      const buildSimulation = {
        installments: 12,
        balanceUniqueIds: items
          .filter((bal) => bal.isFinanceable && !bal.isAutoSplittable)
          .map((bal) => bal.balances)
          .flat()
          .filter((bal) => bal.isFinanceable)
          .map((bal) => bal.sourceKey),
      };
      try {
        if (buildSimulation.balanceUniqueIds.length > 0) {
          const simulationResponse = await dispatch(simulateSplitPayments(buildSimulation));
          setMaxCashBack(simulationResponse.maxCashBack);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (typeof maxCashBack === 'undefined' && enabled) {
      doSimulation();
    }
  }, [maxCashBack, enabled, items, simulateSplitPayments, dispatch]);

  return { maxCashBack, splitPaymentModal, paymentDone, setPaymentDone, setSplitPaymentModal, handleBillsPayment };
}
