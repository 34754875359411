import { useEffect } from 'react';
import { COOKIES_CONFIG, PATHS } from '@belong/common';
import { Link, Text } from '@belong/ui';
import { Confetti } from 'animations/Confetti/Confetti';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import { ShareableBanner } from 'components/Referral/ShareableBanner';
import AccountHeaderContainer from 'containers/AccountHeaderContainer/AccountHeaderContainer';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { Timeline } from 'design-system';
import { useModal } from 'hooks/useModal';
import Page from 'layouts/Page/Page';
import { AppGIF } from 'lease-signing-flow/components/app-gif/app-gif';
import { AppQR } from 'lease-signing-flow/components/app-qr/app-qr';
import { AppStoreBadges } from 'lease-signing-flow/components/app-store-badges/app-store-badges';
import { DownloadAppButton } from 'lease-signing-flow/components/download-app-button/download-app-button';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
import { timelineSteps } from 'lease-signing-flow/constants/success';
import { parseCookies } from 'nookies';
import { BASE_PATHS } from 'routes/paths';

export const Success = () => {
  const [loading, , stopLoader] = useModal(true);
  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  useEffect(() => {
    setTimeout(() => {
      stopLoader();
    }, 1000);
  }, [stopLoader]);

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Page
      footer={false}
      header={!comeFromTheMobileApp}
      headerMainProps={{
        fixed: true,
        navigationComponents: [
          <>
            <LinkBase href={PATHS.SUPPORT}>
              <Text fontWeight="semibold" className="hover:text-green uppercase">
                GET HELP 24/7
              </Text>
            </LinkBase>
          </>,
          <AccountHeaderContainer key="AccountHeaderContainer" />,
        ],
      }}
    >
      <>
        <Confetti />
        <div className="flex justify-center mt-lg sm:mt-xl w-full">
          <div className="pr-2sm pl-2sm sm:pr-0 sm:pr-0 sm:max-w-[730px]">
            <div className="flex flex-col items-center text-center">
              <div className="max-w-[232px] md:max-w-[595px]">
                <Text variant="h3" fontWeight="semibold">
                  {LEASE_SIGNING_FLOW_STRINGS['success.welcome']}
                </Text>
                <Text variant="h3" fontWeight="semibold">
                  {LEASE_SIGNING_FLOW_STRINGS['success.download']}
                </Text>
              </div>
              <div className="max-w-[345px] md:max-w-[632px] mt-xs">
                <Text dangerouslySetInnerHTML={{ __html: LEASE_SIGNING_FLOW_STRINGS['success.subtitle'] }} />
              </div>
              <div className="mt-2xl w-full">
                <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justifiy-start md:ml-3xl">
                  <AppGIF />
                  <div className="mt-xl sm:hidden w-full">
                    <DownloadAppButton />
                    <div className="mt-xl">
                      <Link href={BASE_PATHS.ACCOUNTS}>{LEASE_SIGNING_FLOW_STRINGS['success.cta']}</Link>
                    </div>
                  </div>
                  <div className="hidden md:block ml-2xl">
                    <div className="flex flex-col justify-items-start pt-2xl">
                      <AppQR />
                      <div className="flex flex-col items-start justify-items-start mt-xl">
                        <div className="mb-xl">
                          <AppStoreBadges />
                        </div>
                        <Link href={BASE_PATHS.ACCOUNTS}>{LEASE_SIGNING_FLOW_STRINGS['success.cta']}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2xl mb-2xl w-full">
                <Text variant="h3" fontWeight="semibold">
                  {LEASE_SIGNING_FLOW_STRINGS['success.timeline.title']}
                </Text>
                <div className="flex justify-center mt-xl w-full">
                  <Timeline
                    alternate
                    checkedNode={<div className="bg-green border-2 border-navy h-[8px] rounded w-[8px]" />}
                    items={timelineSteps}
                    showUncheckedAsDisabled
                    uncheckedNode={
                      <div className="absolute border-solid border-2 border-dark-gray h-[14px] rounded w-[14px]" />
                    }
                  />
                </div>
              </div>
              <div className="flex w-full mb-5xl">
                <ShareableBanner
                  body={LEASE_SIGNING_FLOW_STRINGS.shareableInfo.bannerMessage}
                  shareableMessage={LEASE_SIGNING_FLOW_STRINGS.shareableInfo.shareableMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Page>
  );
};
