import React, { useEffect, useState } from 'react';
import { HomeownersLayout } from './homeowners-layout';
import { NoClaimsLayout } from './no-claims-layout';
import { ResidentsLayout } from './residents-layout';

type UserClaim = 'homeowner' | 'resident';

function getInitialClaim(isHomeOwner?: boolean, isResident?: boolean): UserClaim {
  if (isHomeOwner) return 'homeowner';
  if (isResident) return 'resident';
}

const HOMEOWNERS_SECTIONS = ['portfolio', 'homeowners'];
const RESIDENTS_SECTIONS = ['home', 'tours-and-apps', 'favorites', 'residents'];

export function AccountsLayout(props) {
  const { userClaims, section } = props;
  const { isHomeOwner, isResident } = userClaims || {};
  const [currentClaim, setCurrentClaim] = useState(getInitialClaim(isHomeOwner, isResident));

  // This allows us to keep the previous claim when changing to tabs not tied to any claim, like profile.
  useEffect(() => {
    if (HOMEOWNERS_SECTIONS.includes(section)) {
      setCurrentClaim('homeowner');
    } else if (RESIDENTS_SECTIONS.includes(section)) {
      setCurrentClaim('resident');
    }
  }, [section, setCurrentClaim]);

  if (currentClaim === 'homeowner') {
    return <HomeownersLayout {...props} isResident={isResident} />;
  } else if (currentClaim === 'resident') {
    return <ResidentsLayout {...props} isHomeOwner={isHomeOwner} />;
  }

  return <NoClaimsLayout {...props} />;
}
