import { Fragment } from 'react';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { InputFinalFormAdapter } from 'components/Input/Input';
import {
  maskRemoveWhiteSpace,
  unmaskRemoveWhiteSpace,
  maskPhoneNumber,
  unmaskPhoneNumber,
} from 'components/Input/masks';
import Space from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { InputCheckboxAdapter } from 'formcomponents/checkbox-input';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { required, email, phoneValidation, composeValidators } from 'utils/validation';
import { HOME_OCCUPANCY_STRINGS } from '../../constants/strings/home-occupancy.strings';

export function CurrentResidentFields({ name, form, values }) {
  return (
    <div>
      <Row>
        <Col md={6}>
          <Field
            name={`${name}.firstName`}
            validate={required}
            component={InputFinalFormAdapter}
            placeholder="First Name"
          />
        </Col>
        <Col md={6}>
          <Field
            name={`${name}.lastName`}
            validate={required}
            component={InputFinalFormAdapter}
            placeholder="Last Name"
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            name={`${name}.email`}
            component={InputCheckboxAdapter}
            form={form}
            inputProps={{
              mask: maskRemoveWhiteSpace,
              unmask: unmaskRemoveWhiteSpace,
              placeholder: 'Email',
              validate: composeValidators(required, email),
            }}
            checkboxProps={{
              label: HOME_OCCUPANCY_STRINGS['relationship.email_unknown'],
            }}
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputCheckboxAdapter}
            name={`${name}.phone`}
            form={form}
            inputProps={{
              mask: maskPhoneNumber,
              unmask: unmaskPhoneNumber,
              placeholder: 'Phone',
              validate: composeValidators(required, phoneValidation),
            }}
            checkboxProps={{
              label: HOME_OCCUPANCY_STRINGS['relationship.phone_unknown'],
            }}
          />
        </Col>
      </Row>
      {get(values, name)?.phone?.checkboxValue && get(values, name)?.email?.checkboxValue && (
        <Fragment>
          <Space />
          <GreenInfoBox>
            <Text as="span" fontWeight="semibold">
              Note
            </Text>
            {formatString(HOME_OCCUPANCY_STRINGS['relationship.email_or_phone_required'])}
          </GreenInfoBox>
        </Fragment>
      )}
    </div>
  );
}

CurrentResidentFields.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
