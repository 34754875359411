import ReactPlayer from 'react-player/lazy';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import ImagePreDownloader from '../../pages/PostInspectionFlow/steps/Improvements/Approval/ImagePreDownloader/ImagePreDownloader';
import styles from './ShadowMediaCard.module.css';

const cx = classNames.bind(styles);

const ShadowMediaCard = ({
  height,
  file,
  imageUrl,
  autoplay,
  containerClassName,
  mediaContainerClassName,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  onClick,
  borderRadius,
  increaseZindexOnHover,
  avoidShadow,
}) => {
  const asset = imageUrl || file?.url;
  return (
    <div
      onClick={onClick}
      className={cx('card', containerClassName, {
        hasHover: onClick,
        backgroundImage: !asset,
        increaseZindexOnHover,
      })}
      style={{
        height,
      }}
    >
      <div className={cx('media-container', mediaContainerClassName, { borderRadius })}>
        <ImagePreDownloader
          className={cx('base-image', { borderRadius, overlay: !asset, hasHover: onClick })}
          url={imageUrl}
        />
        <div className={cx('bg-black', 'opacity-40', { 'full-shadow': !avoidShadow, borderRadius })} />
      </div>

      {file && (
        <div className={cx('media-container', mediaContainerClassName, { isVideo: file.mediaType === 'Video' })}>
          {file.mediaType === 'Image' ? (
            <ImagePreDownloader className={cx('base-image', borderRadius, { overlay: !asset })} url={file.url} />
          ) : (
            <ReactPlayer
              url={[
                {
                  src: file.url,
                  type: 'video/mp4',
                },
              ]}
              muted
              playing={autoplay}
              controls
              width="100%"
            />
          )}
        </div>
      )}

      <div className={cx('corners-container')}>
        <div className={cx('top-left-container')}>{topLeft}</div>
        <div className={cx('top-right-container')}>{topRight}</div>
        <div className={cx('bottom-left-container')}>{bottomLeft}</div>
        <div className={cx('bottom-right-container')}>{bottomRight}</div>
      </div>
    </div>
  );
};

ShadowMediaCard.propTypes = {
  height: PropTypes.number,
  imageUrl: PropTypes.string,
  file: PropTypes.object,
  autoplay: PropTypes.bool,
  containerClassName: PropTypes.string,
  mediaContainerClassName: PropTypes.string,
  topLeft: PropTypes.node,
  topRight: PropTypes.node,
  bottomLeft: PropTypes.node,
  bottomRight: PropTypes.node,
  onClick: PropTypes.func,
  borderRadius: PropTypes.bool,
  increaseZindexOnHover: PropTypes.bool,
  avoidShadow: PropTypes.bool,
};

ShadowMediaCard.defaultProps = {
  height: 300,
  imageUrl: '',
  file: null,
  autoplay: false,
  containerClassName: '',
  topLeft: null,
  topRight: null,
  bottomLeft: null,
  bottomRight: null,
  onClick: null,
  borderRadius: true,
  // When using tooltips inside card, we need higher Z index to show tooltip over card on top.
  increaseZindexOnHover: false,
  avoidShadow: false,
};

export default ShadowMediaCard;
