import React, { Component } from 'react';
import { Analytics, ANALYTICS_TYPE, ANALYTICS_PROPERTIES } from 'analytics';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { ThemeContext } from 'themes/themes';
import ButtonBase from '../../corecomponents/ButtonBase/ButtonBase';
import LinkBase from '../../corecomponents/LinkBase/LinkBase';
import styles from './TabBar.module.css';

const cx = classNames.bind(styles);

export const TABBAR_TYPES = {
  DEFAULT: 'default',
  OVERLAY: 'overlay',
};

export const TABBAR_SPACING = {
  DEFAULT: 'default',
  LARGE: 'large',
};

export const TAB_LABEL_STYLES = {
  DEFAULT: 'tabLabelStyle',
  BORDERED: 'bordered',
};

class TabBar extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        component: PropTypes.node,
        text: PropTypes.string.isRequired,
        to: PropTypes.string,
        onClick: PropTypes.func,
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ).isRequired,
    type: PropTypes.oneOf(Object.values(TABBAR_TYPES)),
    spacing: PropTypes.oneOf(Object.values(TABBAR_SPACING)),
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func,
    tabClassName: PropTypes.string,
    activeTabClassName: PropTypes.string,
    tabLabelStyle: PropTypes.string,
    fullWidth: PropTypes.bool,
    keepBorderRadiusOnMobile: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withWhiteSpaceWrap: PropTypes.bool,
  };

  static defaultProps = {
    selected: '',
    type: TABBAR_TYPES.DEFAULT,
    spacing: TABBAR_SPACING.DEFAULT,
    onSelect: () => {},
    tabClassName: '',
    tabLabelStyle: TAB_LABEL_STYLES.DEFAULT,
    fullWidth: false,
    keepBorderRadiusOnMobile: false,
    width: null,
    withWhiteSpaceWrap: false,
  };

  constructor(props) {
    super(props);
    const { selected, items } = props;
    this.state = {
      selected: selected || items[0].key,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      selected: nextProps.selected,
    };
  }

  onClick = (item) => {
    this.setState({ selected: item.key });
    this.props.onSelect(item);
  };

  render() {
    const {
      items,
      type,
      tabLabelStyle,
      spacing,
      tabClassName,
      activeTabClassName,
      width,
      fullWidth,
      keepBorderRadiusOnMobile,
      withWhiteSpaceWrap,
    } = this.props;
    const { selected } = this.state;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div
            className={cx([
              'tabBarStyle',
              { overlay: type === TABBAR_TYPES.OVERLAY },
              { large: spacing === TABBAR_SPACING.LARGE },
              { fullWidth },
            ])}
            style={{ width }}
          >
            {items.map((item) => {
              const TabComponent = item.to ? LinkBase : ButtonBase;
              return (
                <TabComponent
                  action={item.key}
                  to={item.to}
                  className={cx(
                    'tab-button',
                    `${`${theme}-theme`}`,
                    tabLabelStyle,
                    { keepBorderRadiusOnMobile },
                    { [tabClassName]: tabClassName },
                    { selected: selected === item.key },
                    { [activeTabClassName]: activeTabClassName && selected === item.key },
                    { disabled: item.disabled },
                    { withWhiteSpaceWrap }
                  )}
                  disabled={item.disabled}
                  key={item.key}
                  onClick={() => {
                    this.onClick(item);
                    if (item.onClick) item.onClick();
                  }}
                  id={item.key}
                >
                  <div>{item.text || item.component}</div>
                </TabComponent>
              );
            })}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Analytics((props) => ({
  [ANALYTICS_TYPE.Component]: 'tab_bar',
  [ANALYTICS_PROPERTIES.name]: props.name || 'unknown',
}))(TabBar);
