import React from 'react';
import { Image } from '@belong/ui';
import css from './MajorListingSitesPreview.module.css';

const MajorListingSitesPreview = () => {
  return (
    <div className="py-sm lg:py-xl lg:px-xs grid grid-cols-2 justify-items-center items-center lg:grid-cols-3 gap-y-xl gap-x-sm lg:gap-2xl">
      <div className={css.zillowLogo}>
        <Image src="/logos/zillow_logo.svg" alt="zillow logo" className="object-contain" />
      </div>

      <div className={css.zumperLogo}>
        <Image src="/logos/zumper_logo.svg" alt="zumper logo" className="object-contain" />
      </div>

      <div className={css.truliaLogo}>
        <Image src="/logos/trulia_logo.svg" alt="trulia logo" className="object-contain" />
      </div>

      <div className={css.appartmentsLogo}>
        <Image src="/logos/appartments_logo.svg" alt="appartments logo" className="object-contain" />
      </div>

      <div className={css.craigslistLogo}>
        <Image src="/logos/craigslist_logo.svg" alt="craigslist logo" className="object-contain" />
      </div>

      <div className={css.padmapperLogo}>
        <Image src="/logos/padmapper_logo.svg" alt="padmapper logo" className="object-contain" />
      </div>

      <div className={css.hotpadsLogo}>
        <Image src="/logos/hotpads_logo.svg" alt="hotpads logo" className="object-contain" />
      </div>

      <div className={css.belongLogo}>
        <Image src="/logos/belong_logo_web.svg" alt="belong logo" className="object-contain" />
      </div>
    </div>
  );
};

export default MajorListingSitesPreview;
