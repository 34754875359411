/* eslint-disable @typescript-eslint/no-empty-function */
import { MouseEvent, ReactNode } from 'react';
import { OnChange } from 'react-final-form-listeners';
import classNames from 'classnames/bind';
import { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import { formatString } from 'strings';
import styles from './CheckboxCard.module.css';

export type CheckboxCardProps = {
  name: string;
  title: string;
  selected?: boolean;
  showTrashButton?: boolean;
  disabled?: boolean;
  plus?: boolean;
  showCheckmark?: boolean;
  noBorder?: boolean;
  error?: boolean;
  multiple?: boolean;
  cursorDisabled?: boolean;
  children?: ReactNode;
  size?: 'small' | 'medium';
  onClick?: () => void;
  onTrashClick?: () => void;
  className?: string;
  titleClassName?: string;
};

const cx = classNames.bind(styles);

export function CheckboxCard({
  name,
  title,
  selected,
  error,
  multiple,
  cursorDisabled,
  showTrashButton,
  showCheckmark,
  disabled,
  plus,
  children,
  size,
  noBorder,
  onClick,
  onTrashClick,
  className,
  titleClassName,
}: CheckboxCardProps): JSX.Element {
  function handleTrashIconClick(e: MouseEvent<unknown>) {
    e.stopPropagation();

    if (selected) {
      onTrashClick();
    } else {
      onClick();
    }
  }

  return (
    <div
      className={cx('checkbox-card', className, {
        selected,
        disabled,
        error,
        multiple,
        size,
        'no-border': noBorder,
        'disable-cursor': cursorDisabled,
      })}
      react-testid={name}
      onClick={onClick}
    >
      {name && <OnChange name={`${name}.selected`}>{() => {}}</OnChange>}
      <div className="flex justify-between">
        <div className="">
          <div
            className={cx(
              'title',
              {
                disabled,
                selected,
                showPlus: plus,
                'text-dark-gray': !selected,
                'text-navy': selected,
              },
              titleClassName
            )}
          >
            {showCheckmark && (
              <IconButton
                icon={selected ? GENERAL_ICONS.CHECKBOX_SELECTED : GENERAL_ICONS.CHECKBOX_UNSELECTED}
                generalIconProps={{
                  color: selected ? COLORS.NAVY : COLORS.GRAY,
                  hoverColor: COLORS.NAVY,
                }}
                onClick={handleTrashIconClick}
              />
            )}
            {plus && <span className={cx('show-icon')} />}
            <div className={cx('text', { hasIcon: showCheckmark || plus })}>
              <span>{formatString(title)}</span>
            </div>
          </div>
        </div>

        {showTrashButton && (
          <IconButton align={ALIGN_TYPES.LEFT} icon={GENERAL_ICONS.TRASH} onClick={handleTrashIconClick} />
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}
