import { getApiActionTypes } from '../redux_utils';

export const ACTIONS = {
  UPDATE_PROPERTY_ADDRESSES: 'homeowner-onboarding/update/property-addresses',
  DELETE_PROPERTY_ADDRESSES: 'homeowner-onboarding/delete/property-addresses',
  ...getApiActionTypes('FETCH_ONBOARDING_INFO'),
  ...getApiActionTypes('CREATE_PROPERTIES'),
  ...getApiActionTypes('DELETE_PROPERTY'),
  ...getApiActionTypes('FETCH_ONBOARDING_PROPERTIES'),
  ...getApiActionTypes('UPDATE_PROPERTY_BASICS'),
  ...getApiActionTypes('UPDATE_CONDITION_OF_UNITS'),
  ...getApiActionTypes('UPDATE_LEASE_INFO'),
  ...getApiActionTypes('FETCH_CALL_TIMES'),
  ...getApiActionTypes('UPDATE_SCHEDULE_CHAT'),
  ...getApiActionTypes('UPDATE_ONBOARDING_INFO'),
  ...getApiActionTypes('CHECK_COVERAGE_AREA'),
};
