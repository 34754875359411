import React from 'react';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import String from 'components/String/String';
import MaintenanceModalAttachmentFields from 'containercomponents/Modals/AddTaskModal/MaintenanceModalAttachmentFields';
import { urgency } from 'containercomponents/Modals/ModalListOfItems/ApplianceList/listOfFormObjects';
import FurnituresList from 'fields/FurnituresList/FurnituresList';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { HaulingTypes } from 'models/enums/index';
import PropTypes from 'prop-types';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { required } from 'utils/validation';

const HaulingForm = ({ form }) => (
  <>
    <Condition when="requestTypeDetails.type" hasValue>
      <FormLayout.Section
        sectionTitle={
          form.getState().values.requestTypeDetails?.type === HaulingTypes.Haul
            ? ADD_TASK_MODAL_STRINGS.haul_removal
            : ADD_TASK_MODAL_STRINGS.haul_storage
        }
      >
        <FurnituresList
          form={form}
          name="requestTypeDetails.items"
          inputComponent={{
            placeholder: 'Item',
          }}
          componentCheckbox={{
            label: 'This item is highly valuable',
          }}
        />
      </FormLayout.Section>

      <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.haul_description}>
        <Field
          name="description"
          component={InputFinalFormAdapter}
          placeholder={ADD_TASK_MODAL_STRINGS.haul_description_placeholder}
          textarea
        />
      </FormLayout.Section>

      <FormLayout.Section
        sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
        sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.recurring']} />}
      >
        <MaintenanceModalAttachmentFields form={form} />
      </FormLayout.Section>

      <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.urgency}>
        <Field
          name="urgency"
          component={SelectorFinalFormAdapter}
          placeholder={ADD_TASK_MODAL_STRINGS.urgency_placeholder}
          buttons={urgency}
          fluid
          validate={required}
          type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
        />
      </FormLayout.Section>
    </Condition>
  </>
);

HaulingForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default HaulingForm;
