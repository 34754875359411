import { useEffect } from 'react';
import { connect } from 'react-redux';
import { COOKIES_CONFIG } from '@belong/common';
import { Confetti } from 'animations/Confetti/Confetti';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from 'components/Button/Button';
import Image from 'components/Image/Image';
import { ShareableBanner } from 'components/Referral/ShareableBanner';
import Spinner from 'components/Spinner/Spinner';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import AccountHeaderContainer from 'containers/AccountHeaderContainer/AccountHeaderContainer';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { Box, Flex, Text, Timeline } from 'design-system';
import { useModal } from 'hooks/useModal';
import Page from 'layouts/Page/Page';
import { ApplicantStatus, ApplicantType } from 'models/enums';
import { parseCookies } from 'nookies';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { fetchResidentBookmarks } from 'store/redux/renter-accounts/actions';
import { selectBookmarks } from 'store/redux/renter-accounts/selectors';
import {
  selectResidentApplicationApplicantInfo,
  selectResidentApplicationApplicants,
  selectResidentApplicationId,
  selectResidentApplicationSelfServeDetails,
} from 'store/redux/resident-application/selectors';
import { formatString } from 'strings';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { arrayInsertIf } from 'utils/insertIf';
import { ApplicationsInProcess } from '../ApplicationsInProcess/ApplicationsInProcess';
import { PlaceInLine } from '../PlaceInLine/PlaceInLine';
import styles from './SuccessPage.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS;

const HeaderLinks = [
  <ContactUs key="ContactUs" disableChat />,
  <AccountHeaderContainer key="AccountHeaderContainer" />,
];

const getTimelineSteps = (currentResidentId, applicants, isCosigner, applicationGroupHasTouredHome) => {
  const otherResidents = applicants.filter((applicant) => applicant.residentId !== currentResidentId);
  return [
    {
      value: 'Submit your application',
      checked: true,
    },
    ...otherResidents.map((applicant) => {
      const { firstName, status } = applicant;
      const checked = status === ApplicantStatus.Submitted || status === ApplicantStatus.VerificationsCompleted;
      return {
        value: `Receive ${firstName}'s application`,
        checked,
      };
    }),
    ...arrayInsertIf(!isCosigner, { value: 'Tour the home', checked: applicationGroupHasTouredHome }),
    {
      value: 'Get approved',
      checked: false,
    },
    {
      value: 'Become a Belong Resident',
      checked: false,
    },
  ];
};

const getIsPrequalified = (selfServeDetails) => {
  const { applicationStatus, listingApplicationStatus } = selfServeDetails || {};
  return applicationStatus === 'VerificationsCompleted' && listingApplicationStatus === 'Qualified';
};

const getBubbleText = (selfServeDetails, firstName, hasSomeoneTouredOrIsCosigner, placeInLine) => {
  // Pre qualified branch
  if (getIsPrequalified(selfServeDetails)) {
    if (hasSomeoneTouredOrIsCosigner) {
      return formatString(RAS['success_page.title_qualified_someone_toured_or_cosigner'], {
        firstName,
        placeInLine: numeral(placeInLine).format('0o'),
      });
    }

    return formatString(RAS['success_page.title_qualified_not_toured'], { firstName });
  }

  // Not qualified branch
  if (hasSomeoneTouredOrIsCosigner) {
    return formatString(RAS['success_page.title_not_qualified_someone_toured_or_cosigner'], { firstName });
  }

  return formatString(RAS['success_page.title_not_qualified_not_toured'], { firstName });
};

const SuccessPage = ({
  home,
  employee,
  applicantInfo,
  applicationId,
  bookmarks,
  applicants,
  selfServeDetails,
  fetchResidentBookmarksAction,
}) => {
  const [loading, startLoading, stopLoading] = useModal();
  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        await fetchResidentBookmarksAction();
      } catch (e) {
        console.error(e);
      }
      stopLoading();
    };
    fetchData();
  }, []);

  const currentBookmark = bookmarks?.find((bookmark) => bookmark.applicationId === applicationId);
  const { listing, listingApplication } = currentBookmark || {};

  const { applicationGroupHasTouredHome } = selfServeDetails || {};

  const placeInLine = listingApplication?.waitlistRanking;
  const isPrequalified = getIsPrequalified(selfServeDetails);
  const isCosigner = applicantInfo.applicantType === ApplicantType.CoSigner;
  const hasSomeoneTouredOrIsCosigner = applicationGroupHasTouredHome || isCosigner;
  return (
    <Page
      headerMainProps={{
        fixed: true,
        navigationComponents: HeaderLinks,
      }}
      footer={false}
      header={!comeFromTheMobileApp}
    >
      <Confetti />

      {loading ? (
        <Spinner />
      ) : (
        <Box pt={['lg', 'lg', '3xl']} mx="auto" className={cx('success-page')}>
          <Box pb="2xl">
            <TextBubbleWithUserProfile
              showContact
              employee={employee}
              text={getBubbleText(
                selfServeDetails,
                applicantInfo?.basicInfo.firstName,
                hasSomeoneTouredOrIsCosigner,
                placeInLine
              )}
            />
          </Box>

          {/* Don't like adding margin on both sides of the container, but the contact info is outside of the Bubble height. */}
          {hasSomeoneTouredOrIsCosigner ? (
            <Flex justifyContent="center" mt="sm" mb="2xl">
              <Button
                to={BASE_PATHS.ACCOUNTS}
                label={formatString(RAS['success_page.done_cta'])}
                size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
              />
            </Flex>
          ) : (
            <>
              <Flex justifyContent="center" mt="sm" mb="lg">
                <Button
                  to={{ pathname: `${BASE_PATHS.HOME}/${home?.basicInfo?.unitId}`, search: 'view=tours' }}
                  label={formatString(RAS['success_page.tour_cta'])}
                  size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
                />
              </Flex>
              <Flex justifyContent="center" mb="2xl">
                <Button
                  to={BASE_PATHS.ACCOUNTS}
                  label={formatString(RAS['success_page.accounts_cta'])}
                  size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
                  buttonType={BUTTON_TYPES.TEXT}
                />
              </Flex>
            </>
          )}

          <Flex className="w-full" mb="3xl">
            <ShareableBanner
              title={RAS.shareableInfo.bannerTitle}
              body={RAS.shareableInfo.bannerMessage}
              shareableMessage={RAS.shareableInfo.shareableMessage}
            />
          </Flex>

          <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} mb="5xl">
            <Box flex={1} my={['2xl', '2xl', 0]} className={cx('timeline-container')}>
              <Box mb="sm">
                <Text fontWeight="semibold">{RAS['success_page.timeline_title']}</Text>
              </Box>
              <Timeline
                items={getTimelineSteps(
                  applicantInfo?.basicInfo?.residentId,
                  applicants,
                  isCosigner,
                  applicationGroupHasTouredHome
                )}
              />
            </Box>
            <Flex flex={1} pt="lg" justifyContent="center">
              {isPrequalified ? (
                <PlaceInLine value={placeInLine} qualifiedApplicants={listing?.waitlistRankingTotal} />
              ) : (
                <ApplicationsInProcess value={listing?.processingApplications} />
              )}
            </Flex>
          </Flex>
        </Box>
      )}

      <div className={cx('background-container-desktop')}>
        <Image src="/city_images/success_page_city_bg.svg" alt="" />
      </div>
      <div className={cx('background-container-mobile')}>
        <Image src="/city_images/success_page_city_bg_mobile.svg" alt="" />
      </div>
    </Page>
  );
};

SuccessPage.propTypes = {
  applicationId: PropTypes.string.isRequired,
  employee: PropTypes.object.isRequired,
  bookmarks: PropTypes.array,
  applicants: PropTypes.array,
  applicantInfo: PropTypes.object,
  home: PropTypes.object.isRequired,
  selfServeDetails: PropTypes.object.isRequired,
  fetchResidentBookmarksAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  applicationId: selectResidentApplicationId(state),
  applicantInfo: selectResidentApplicationApplicantInfo(state),
  bookmarks: selectBookmarks(state),
  selfServeDetails: selectResidentApplicationSelfServeDetails(state),
  applicants: selectResidentApplicationApplicants(state),
});

const mapDispatchToProps = {
  fetchResidentBookmarksAction: fetchResidentBookmarks,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
