import { filter } from 'lodash-es';

export const filterApplicationPendingProperties = (properties) =>
  properties
    ? filter(properties, (property) => {
        const { units } = property;
        const isApplicationPending = !units[0].basicInfo.isOnboardingComplete;
        return isApplicationPending;
      })
    : null;
