import { HomeownerSetupStepNames, SetupDataTypes } from 'models/enums';
import { HOME_OWNER_PAPER_WORK_STRINGS as STRINGS } from 'strings/homeowner-paperwork.strings';

export const STEP_STATUS = {
  Created: 'Created',
  Started: 'Started',
  Completed: 'Completed',
  Incomplete: 'Incomplete',
};

export const STEP_NAMES = {
  UserProfile: 'UserProfile',
  UserIdentity: 'UserIdentity',
  UserTaxes: 'UserTaxes',
  UserSsn: 'UserSsn',
  UserPaymentMethods: 'UserPaymentMethods',
  PropertyFeatures: 'PropertyFeatures',
  PropertyHoa: 'PropertyHoa',
  PropertyInsurance: 'PropertyInsurance',
  UnitOccupancy: 'UnitOccupancy',
  UnitFeatures: 'UnitFeatures',
  UnitPreferencesPets: 'UnitPreferencesPets',
  UnitPreferencesFurnishing: 'UnitPreferencesFurnishing',
  UnitPreferencesUtilities: 'UnitPreferencesUtilities',
  UnitKeys: 'UnitKeys',
  UnitInspectionPreferences: 'UnitInspectionPreferences',
  UnitDisclosures: 'UnitDisclosures',
  UnitResidentExperience: 'UnitResidentExperience',
  UnitListing: 'UnitListing',
  UnitKeysEnhanced: 'UnitKeysEnhanced',
  UnitInspectionPrep: 'UnitInspectionPrep',
  UnitInspectionSchedule: 'UnitInspectionSchedule',
};

export const STEP_GATE_NAMES = {
  InspectionSchedule: 'InspectionSchedule',
  BuildListing: 'BuildListing',
  NextLevelProfile: 'NextLevelProfile',
  GetPaid: 'GetPaid',
  HomeImprovements: 'HomeImprovements',
  HomePricing: 'HomePricing',
  LandlordInsurance: 'LandlordInsurance',
  PreMoveOut: 'PreMoveOut',
  W9: 'W9',
};

export const STEP_GROUPS = [
  {
    name: SetupDataTypes.User,
    displayName: STRINGS['landing_page.all_about_you.title'],
    key: 'user',
  },
  {
    name: SetupDataTypes.UserPayment,
    displayName: STRINGS['landing_page.get_paid.title'],
    key: 'user-payment',
  },
  {
    name: SetupDataTypes.Property,
    displayName: STRINGS['landing_page.around_the_property.title'],
    key: 'property',
  },
  {
    name: SetupDataTypes.Home,
    displayName: STRINGS['landing_page.on_the_inside.title_for_single_family'],
    key: 'unit',
  },
];

export const STEP_TIPS = {
  [SetupDataTypes.User]: {
    [HomeownerSetupStepNames.UserIdentity]: {
      title: '',
      descriptions: [],
    },
    [HomeownerSetupStepNames.UserProfile]: {
      title: '',
      descriptions: [],
    },
  },
  [SetupDataTypes.UserPayment]: {
    [HomeownerSetupStepNames.UserTaxes]: {
      title: "We'll send you a 1099!",
      descriptions: [
        "Each year, we'll send you a 1099 form and an earnings statement, so you can easily prepare your taxes and claim all allowed deductions.",
      ],
    },
    [HomeownerSetupStepNames.UserPaymentMethods]: {
      title: 'We Take Security Seriously',
      descriptions: [
        'To ensure the security of your financial information, we’ve integrated with Plaid, the industry-leading payment provider. Your banking and login information is completely encrypted.',
      ],
    },
  },
  [SetupDataTypes.Property]: {
    [HomeownerSetupStepNames.PropertyHoa]: {
      title: 'Playing by the Rules',
      descriptions: [
        'We will comply with any HOA rules and ensure your new residents are filly in the loop when they move into the community.',
      ],
    },
    [HomeownerSetupStepNames.PropertyInsurance]: {
      title: 'Why Buy Landlord Insurance',
      descriptions: [
        "Homeowner's insurance doesn't protect against damages when renters are living in the home.",
        'Switching to landlord insurance solves this problem. It also provides liability coverage, in the event that someone is injured on property.',
      ],
    },
    [HomeownerSetupStepNames.PropertyFeatures]: {
      title: 'Make Your Inspection a Success!',
      descriptions: [
        'These features around the property increase the rental value of your home! Let us know ahead of time so we can inspect, photograph and market them properly.',
      ],
    },
  },
  [SetupDataTypes.Home]: {
    [HomeownerSetupStepNames.UnitOccupancy]: {
      title: "We'll Stop By",
      descriptions: [
        "After we get in touch with your residents, we'll visit the home for an inspection. This will give us an understanding of the state of the home and help us manage it better in the future. Note that this does not replace the need for move in documentation, which we still need to get from you.",
      ],
    },
    [HomeownerSetupStepNames.UnitFeatures]: {
      title: 'Make Your Listing Shine',
      descriptions: [
        "We'll use this and our findings during the inspection to build a complete and attractive listing to market your home.",
      ],
    },
    [HomeownerSetupStepNames.UnitPreferencesUtilities]: {
      title: 'Transferring Utilities',
      descriptions: [
        'If your home is vacant, please keep all utilities active and in your name.',
        "Once we find someone to love your home, we'll make sure all the correct utilities are transferred to their name.",
      ],
      'descriptions.adopted_resident': [
        'Belong does not manage the billing of any utilities. All utilities need to be in your name or the resident name and match the responsibility outline in the lease.',
      ],
    },
    [HomeownerSetupStepNames.UnitKeys]: {
      title: 'Your Keys Are Safe With Us',
      descriptions: [
        "Once we receive your keys, we'll place a lockbox at your home (if possible) and store duplicates securely in our office.",
      ],
    },
    [STEP_NAMES.UnitKeysEnhanced]: {
      title: 'Your Keys Are Safe With Us',
      descriptions: [
        "Once we receive your keys, we'll place a lockbox at your home (if possible) and store duplicates securely in our office.",
      ],
    },
    [STEP_NAMES.UnitInspectionPrep]: {
      title: 'Pros can only inspect if:',
      descriptions: [
        'All utilities are turned on',
        'Home is completely vacant ',
        'The home can be accessed',
        'The home is not under construction',
      ],
      descriptionImages: [
        '/icons/tip-switch.svg',
        '/icons/tip-box.svg',
        '/icons/tip-lock.svg',
        '/icons/tip-home-building.svg',
      ],
    },
    [HomeownerSetupStepNames.UnitPreferencesPets]: {
      title: 'Being Fur Friendly',
      descriptions: [
        'Homes that allow pets attract significantly more applications.',
        'Plus, we automatically collect a $500 pet deposit from Residents to cover any additional wear and tear.',
      ],
    },
    [HomeownerSetupStepNames.UnitPreferencesUtilities]: {
      title: 'Transferring Utilities',
      descriptions: [
        'If your home is vacant, please keep all utilities active and in your name.',
        "Once we find someone to love your home, we'll make sure all the correct utilities are transferred to their name.",
      ],
    },
    [HomeownerSetupStepNames.UnitListing]: {
      title: 'Longer Leases Yield Higher\u00a0Returns',
      descriptions: [
        'Vacancy is the number one driver of losses when renting a home. By offering longer lease terms, you’ll attract more stable residents, reducing your vacancy and turnover costs. This, in turn, may create more interest for your home as well while on the\u00a0market.',
      ],
    },
    [HomeownerSetupStepNames.UnitDisclosures]: {
      title: 'Cover Your Bases',
      descriptions: [
        "These disclosures are legally required, and will be included in your future resident's leases. Failure to disclose material facts may expose you to legal liability, so please share with us everything you know.",
      ],
    },
  },
};
