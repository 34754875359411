import { IdentityVerification } from 'accounts/components/form-fields/identity-verification/identity-verification';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { UserIdentityBannerStep } from 'accounts/components/user-identity-banner-step';
import { STEP_GATE_NAMES } from 'accounts/constants/steps';
import { USER_IDENTITY_STRINGS } from 'accounts/constants/strings/user-identity.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { isStepCompleted, isStepUnHidden } from 'accounts/utils/flow-steps';

export function UserIdentity() {
  const { currentStepFormData, loading, setLoading, stepsByGate, handleSaveAndNext } = useSetupFlowStep();

  const initialValues = {};

  async function handleSubmit() {
    setLoading(true);

    try {
      await handleSaveAndNext({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  function getBannerStep() {
    const nextLevelProfileIsCompleted = stepsByGate?.[STEP_GATE_NAMES.NextLevelProfile]
      ?.filter(isStepUnHidden)
      ?.every(isStepCompleted);

    if (nextLevelProfileIsCompleted) {
      return <UserIdentityBannerStep />;
    }

    return undefined;
  }

  if (!currentStepFormData && !loading) {
    return null;
  }

  return (
    <StepFormLayout
      onSubmit={handleSubmit}
      title={USER_IDENTITY_STRINGS.title}
      subTitle={USER_IDENTITY_STRINGS.subtitle}
      initialValues={initialValues}
      bannerStep={getBannerStep()}
      showUnitAddress={false}
      form={<IdentityVerification onSubmit={handleSubmit} />}
    />
  );
}
