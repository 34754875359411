import { useSelector } from 'react-redux';
import { MaintenanceModel } from 'api/models';
import { selectUser } from 'store/redux/user/selectors';

export const useRequestAccountResponsibility = (isRenter: boolean, task?: MaintenanceModel) => {
  const user = useSelector(selectUser);

  if (!task && !user) {
    return false;
  }

  // responsibility currently requires both the resident / homeowner  user to display the mock.
  // Also there can be no actual cost object. Once there is a real cost there is no account responsibility.
  if (task.actualCost || !task.estimatedCost) {
    return false;
  }

  if (!task.payments?.length) {
    return false;
  }

  const renterResponsibility = task.payments?.find((payment) => payment.paidBy === 'Resident');
  const homeOwnerResponsibility = task.payments?.find((payment) => payment.paidBy === 'Homeowner');

  if (renterResponsibility?.consentStatus === 'Required' && isRenter) {
    if (renterResponsibility.paidByUser) {
      return renterResponsibility.paidByUser.userId === user.userId;
    }
    return true;
  } else if (homeOwnerResponsibility?.consentStatus === 'Required' && !isRenter) {
    return true;
  }

  return false;
};
