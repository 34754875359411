import { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router';
import { pageview } from 'analytics/ga.utils';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import { hideModal } from 'store/redux/modals/actions';

class ReduxAsyncConnect extends Component {
  previousLocation = null;

  componentDidMount() {
    NProgress.configure({ trickleSpeed: 200 });
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, hideModal: hideModalAction } = this.props;
    const navigated = nextProps.location !== location;

    if (navigated) {
      pageview(nextProps.location.pathname + nextProps.location.search);

      if (typeof window !== 'undefined') {
        window.mixpanel?.register({
          'Previous URL': location.pathname + location.search,
        });

        window.Intercom?.('hide');
        window.Intercom?.('update', {
          hide_default_launcher: true,
        });
      }

      hideModalAction();
    }
  }

  componentWillUnmount() {
    console.log('unmount');
    window.Intercom?.('hide');
    window.Intercom?.('update', {
      hide_default_launcher: true,
    });
  }

  idx = () => {
    return this.props.children;
  };

  render() {
    const { location } = this.props;
    return <Route location={location} render={this.idx} />;
  }
}

ReduxAsyncConnect.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, {
    hideModal,
  })(ReduxAsyncConnect)
);
