import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Text, Money, Button } from '@belong/ui';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { simulateSplitPayments } from 'store/redux/accounts/actions';
import { selectSimulatePayments } from 'store/redux/accounts/selectors';
import { formatString } from 'strings';
import { ACCOUNTS_EARNINGS } from 'strings/accounts/earnings';
import SplitPaymentModal from '../SplitPaymentModal/SplitPaymentModal';
import { SplitPaymentsBanner } from '../SplitPaymentsBanner/SplitPaymentsBanner';

function BalanceDetailsModal({
  balance: selectedBalance,
  onHide,
  standalone = false,
  handleShowFinanceSuccessModal,
  simulateSplitPayments: simulateSplitPaymentsAction,
  isResident = false,
  ...rest
}) {
  const [splitPaymentModal, setSplitPaymentModal] = useState(false);
  const [maxCashBack, setMaxCashBack] = useState(0);
  const balance = selectedBalance?.balance;
  const home = selectedBalance?.address;
  const isAutosplittable = selectedBalance?.autosplittable;

  const showPlanIt =
    balance?.isFinanceable &&
    selectedBalance.billingInfo &&
    new Date() < new Date(selectedBalance.billingInfo.billGenerationOn);

  function handleHide() {
    if (onHide) onHide();
  }

  function handleSplitPaymentsModal() {
    setSplitPaymentModal(false);
  }

  function navigateToSplitPayments() {
    setSplitPaymentModal(true);
  }

  function onShowFinanceSuccessModal() {
    handleHide();
    handleShowFinanceSuccessModal();
  }

  useEffect(() => {
    const doSimulation = async () => {
      const buildSimulation = {
        installments: 12,
        balanceUniqueIds: balance.balances.flat().map((bal) => bal.sourceKey),
      };
      try {
        const simulationResponse = await simulateSplitPaymentsAction(buildSimulation);
        setMaxCashBack(simulationResponse.maxCashBack);
      } catch (err) {
        console.error(err);
      }
    };

    if (typeof balance !== 'undefined' && typeof balance.balances !== 'undefined' && rest.show) doSimulation();
  }, [balance]);

  if (!rest.show && typeof balance === 'undefined') {
    return null;
  }

  if (standalone) {
    const standaloneBalances = balance.sourceType === 'GroupKey' ? balance.balances : [balance];
    const { remaining } = balance;

    return (
      <ModalV2 {...rest} noPadding showSpinner={false} onHide={handleHide} className="p-0">
        <ModalLayout fixedBottom className="px-2sm pt-lg pb-lg">
          <ModalLayout.Content className="p-0">
            <ModalLayout.Section>
              <ModalLayout.Title>{balance?.description}</ModalLayout.Title>
              <ModalLayout.SubTitle>
                <Text className="text-navy">{formatString(home)}</Text>
              </ModalLayout.SubTitle>
              <div className="mt-xl">
                <div className="flex p-0 md:p-lg ">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text variant="h3" fontWeight="semibold">
                              Details
                            </Text>
                          </div>
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {standaloneBalances?.map((item) => (
                        <React.Fragment key={item.id}>
                          <tr className="h-sm" colSpan="2" key={item.id} />
                          <tr>
                            <th>
                              <div className="text-left">
                                <Text className="mt-xs">{item.description}</Text>
                              </div>
                            </th>
                            <td>
                              <div className="text-right">
                                <Money
                                  format="DOLLARS_WITH_CENTS"
                                  value={Math.abs(item.remaining)}
                                />
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                      <tr className="h-2sm border-0 border-b border-gray border-dashed" colSpan="2" />
                      <tr className="h-2sm " colSpan="2" />
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text fontWeight="semibold">Total</Text>
                          </div>
                        </th>
                        <td>
                          <div className="text-right">
                            <Money format="DOLLARS_WITH_CENTS" fontWeight="semibold" value={remaining} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ModalLayout.Section>
          </ModalLayout.Content>

          {!isResident && showPlanIt && (
            <ModalLayout.BottomBar
              className="w-full m-0"
              leftSideNode={
                <SplitPaymentsBanner
                  maxCashBack={maxCashBack}
                  withPlanItButton={false}
                  showAutoSplitLabel={isAutosplittable}
                  billGenerationOn={selectedBalance.billingInfo?.billGenerationOn}
                />
              }
              rightNode={
                <Button variant="solid" buttonType="primary" onClick={navigateToSplitPayments}>
                  <div className="flex items-center">
                    <Text variant="body" fontWeight="semibold" className="mr-xs" tex>
                      {ACCOUNTS_EARNINGS['section.split.action']}
                    </Text>
                  </div>
                </Button>
              }
              fixedBottom
            />
          )}
        </ModalLayout>
      </ModalV2>
    );
  }

  const balances = balance?.balances?.length > 0 ? balance?.balances : [];
  const payableBalances =
    balance?.balances?.length > 0 ? balance?.balances?.filter((bal) => bal.balanceType === 'Payable') : [];

  return (
    <>
      <ModalV2 {...rest} noPadding showSpinner={false} onHide={handleHide} className="">
        <ModalLayout fixedBottom>
          <ModalLayout.Content className="p-0">
            <ModalLayout.Section>
              <ModalLayout.Title>{balance?.description}</ModalLayout.Title>
              <ModalLayout.SubTitle>
                <Text className="text-navy">{home}</Text>
              </ModalLayout.SubTitle>
              <div className="mt-xl">
                <div className="flex p-0 md:p-lg ">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text variant="h3" fontWeight="semibold">
                              Details
                            </Text>
                          </div>
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="h-sm" colSpan="2" />
                      {balances.length > 0
                        ? balances.map((bal) => {
                            if (bal.balanceType === 'Payable') return null;
                            return (
                              <tr key={bal.sourceKey}>
                                <th>
                                  <div className="text-left">
                                    <Text className="mt-xs">{bal.description}</Text>
                                  </div>
                                </th>
                                <td>
                                  <div className="text-right">
                                    <Money format="DOLLARS_WITH_CENTS" value={bal.netBalance} />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                      {/* {balances?.some((bal) => bal.balanceType !== 'Payable') && (
                        <>
                          <tr className="h-2sm border-0 border-b border-gray border-dashed" colSpan="2" />
                          <tr className="h-2sm " colSpan="2" />
                          <tr>
                            <td>
                              <div className="text-left">
                                <Text>Subtotal</Text>
                              </div>
                            </td>
                            <td>
                              <div className="text-right">
                                <Money format="DOLLARS_WITH_CENTS" value={balance.subTotalBalance} />
                              </div>
                            </td>
                          </tr>
                        </>
                      )} */}
                      {payableBalances.length > 0
                        ? payableBalances.map((payableBal) => {
                            return (
                              <tr key={payableBal.id}>
                                <th>
                                  <div className="text-left">
                                    <Text className="mt-xs">{payableBal.description}</Text>
                                  </div>
                                </th>
                                <td>
                                  <div className="text-right">
                                    <Money
                                      format="DOLLARS_WITH_CENTS"
                                      value={payableBal.netBalance}
                                      fontWeight="semibold"
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}

                      <tr className="h-2sm border-0 border-b border-gray border-dashed" colSpan="2" />
                      <tr className="h-2sm " colSpan="2" />
                      <tr>
                        <th>
                          <div className="text-left">
                            <Text fontWeight="semibold">Total</Text>
                          </div>
                        </th>
                        <td>
                          <div className="text-right">
                            <Money format="DOLLARS_WITH_CENTS" fontWeight="semibold" value={balance?.netBalance} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ModalLayout.Section>
          </ModalLayout.Content>
          {!isResident && showPlanIt && (
            <ModalLayout.BottomBar
              className="w-full m-0"
              noPadding
              leftSideNode={
                <SplitPaymentsBanner
                  maxCashBack={maxCashBack}
                  withPlanItButton={false}
                  showAutoSplitLabel={isAutosplittable}
                  billGenerationOn={selectedBalance.billingInfo?.billGenerationOn}
                />
              }
              rightNode={
                <Button variant="solid" buttonType="primary" onClick={navigateToSplitPayments}>
                  <div className="flex items-center">
                    <Text variant="body" fontWeight="semibold" className="mr-xs" tex>
                      {ACCOUNTS_EARNINGS['section.split.action']}
                    </Text>
                  </div>
                </Button>
              }
              fixedBottom
            />
          )}
        </ModalLayout>
      </ModalV2>
      {!isResident && splitPaymentModal && (
        <SplitPaymentModal
          show={splitPaymentModal}
          onHide={handleSplitPaymentsModal}
          loading={false}
          items={[balance]}
          amount={balance.netBalance}
          handleShowFinanceSuccessModal={onShowFinanceSuccessModal}
          billingInfo={selectedBalance.billingInfo}
        />
      )}
    </>
  );
}

BalanceDetailsModal.propTypes = {
  balance: PropTypes.object,
  onHide: PropTypes.func,
  standalone: PropTypes.bool,
  handleShowFinanceSuccessModal: PropTypes.func,
  balanceSummary: PropTypes.object,
  simulateSplitPayments: PropTypes.func,
  isResident: PropTypes.bool,
};

function mapStateProps(state) {
  return {
    simulation: selectSimulatePayments(state),
  };
}
const mapDispatchToProps = {
  simulateSplitPayments,
};

export default connect(mapStateProps, mapDispatchToProps)(BalanceDetailsModal);
