/* Do not modify this. Use local.config.override for dev testing. */
export const configs = {
  test: 'https://api.test.bln.hm',
  master: 'https://api-staging.bln.hm',
  staging: 'https://api-staging.bln.hm',
  qa: 'https://api-qa.bln.hm',
  sandbox: 'https://api-sandbox.bln.hm',
  dev1: 'https://api-dev1.bln.hm',
  dev2: 'https://api-dev2.bln.hm',
  dev3: 'https://api-dev3.bln.hm',
  dev4: 'https://api-dev4.bln.hm',
  dev5: 'https://api-dev5.bln.hm',
  dev6: 'https://api-dev6.bln.hm',
  dev7: 'https://api-dev7.bln.hm',
  dev8: 'https://api-dev8.bln.hm',
  local: 'http://localhost:8000',
};

export default {
  host: 'http://localhost:3001',
  port: 3001,
  apiUrl: configs.dev1,
  gaId: 'UA-122475191-2',
  configs,
};
