import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Button, Text } from '@belong/ui';
import { STRINGS } from 'accounts/constants/strings/reschedule-appointment';
import { useRescheduleAppointmentContext } from 'accounts/hooks/reschedule-appointment';

export function RescheduleAppointmentSuccessStep() {
  const { width } = useWindowSize();
  const { formattedAppointmentDate, onDismiss } = useRescheduleAppointmentContext();

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  return (
    <div className="pt-3xl w-full px-sm sm:pt-0">
      <div className="mb-sm text-center">
        <Text variant="h2" fontWeight="semibold">
          {STRINGS['step.success.title']}
        </Text>
      </div>
      <div className="mb-2xl sm:mb-xl flex justify-center">
        <div className="max-w-[320px] md:max-w-[420px] text-center">
          <Text>
            {STRINGS['step.success.description']} {formattedAppointmentDate}
          </Text>
        </div>
      </div>
      <div className="sm:mb-2xl w-full flex justify-center">
        <Button onClick={onDismiss} size={isMobile ? 'fluid' : 'large'}>
          <Text fontWeight="semibold">{STRINGS['step.success.confirmation']}</Text>
        </Button>
      </div>
    </div>
  );
}
