import { EmployeeAssignmentTypes } from 'models/enums';

export const EMPLOYEE_STRINGS = {
  [EmployeeAssignmentTypes.Concierge]: 'Your Member Success\u00a0Lead',
  [EmployeeAssignmentTypes.HeadOfExperience]: 'Head Of Experience',
  [EmployeeAssignmentTypes.HeadOfOperations]: 'Head Of Operations',
  [EmployeeAssignmentTypes.HomeownerGrowth]: 'Homeowner Advisor',
  [EmployeeAssignmentTypes.ImprovementsAssessor]: 'Homeowner Advisor',
  [EmployeeAssignmentTypes.ImprovementsSpecialist]: 'Improvements Specialist',
  [EmployeeAssignmentTypes.PricingAnalyst]: 'Pricing Lead',
  [EmployeeAssignmentTypes.Pro]: 'Inspector Extraordinaire',
  [EmployeeAssignmentTypes.ResidentGrowth]: 'Leasing Advisor',
  [EmployeeAssignmentTypes.SalesOperation]: 'Onboarding Ops',
  [EmployeeAssignmentTypes.PricingTeam]: 'Pricing Team',
};
