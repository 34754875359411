import { MaintenanceAppointmentModel, MaintenanceModel } from 'api/models';
import classNames from 'classnames/bind';
import Collapsible from 'components/Collapsible/Collapsible';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import { useMaintenanceAppointmentTask } from 'maintenance/hooks/useMaintenanceAppointmentTask';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';
import UserActionMaintenance from '../UserActionMaintenance/UserActionMaintenance';
import styles from './MaintenanceScheduleComponent.module.css';

const cx = classNames.bind(styles);

const getLabelFromOutcome = (outcome) =>
  outcome === 'Resolved' ? <Label color={LABEL_COLOR_TYPES.GREEN} text="Resolved" /> : null;

const getEnding = (status, outcome) => {
  if (status?.toLowerCase() !== 'completed') {
    return `${status.toLowerCase()}`;
  }

  return outcome === 'NotResolved' ? 'completed.notresolved' : 'completed';
};

type MaintenanceScheduledComponentProps = {
  appointments: MaintenanceAppointmentModel[];
  maintenance: MaintenanceModel;
  showMaintenanceInfo?: boolean;
};

const MaintenanceAppointment = ({
  appointment,
  maintenance,
  showMaintenanceInfo,
}: {
  appointment: MaintenanceAppointmentModel;
  maintenance: MaintenanceModel;
  showMaintenanceInfo?: boolean;
}) => {
  const user = appointment.pro.name ? { ...appointment.pro } : { ...appointment.partner };
  const task = useMaintenanceAppointmentTask(appointment, maintenance);

  let appointmentStatusLabel =
    MAINTENACE_STATUS_MODAL_STRINGS[
      `maintenance.scheduled.status.${getEnding(appointment.status, appointment.outcome)}`
    ];

  if (task && showMaintenanceInfo) {
    appointmentStatusLabel = `${'summary' in task ? task.summary : task.maintenance.summary} ${
      MAINTENACE_STATUS_MODAL_STRINGS[
        `maintenance.inHomeRepairs.scheduled.status.${getEnding(appointment.status, appointment.outcome)}`
      ]
    }`;
  }

  return (
    <UserActionMaintenance
      belongPro={!!appointment.pro?.name}
      partner={!!appointment.partner?.name}
      name={user.name}
      time={appointment.scheduledStartOn}
      allDay={appointment.isAllDay}
      userId={user.userId}
      profileImage={user.profileImageUrl || user.logoImageUrl}
      location={appointment.location}
      taskType={
        <div className={cx('task-type-wrapper', { 'handle-tags': appointment.outcome === 'Resolved' })}>
          <div>{appointmentStatusLabel}</div>
          {appointment.status === 'Completed' && (
            <div className={cx('label-wrapper')}>{getLabelFromOutcome(appointment.outcome)}</div>
          )}
        </div>
      }
    />
  );
};

export const MaintenanceScheduleComponent = ({
  appointments = [],
  maintenance,
  showMaintenanceInfo,
}: MaintenanceScheduledComponentProps) => (
  <Collapsible
    title={MAINTENACE_STATUS_MODAL_STRINGS.appointmentScheduledModal.title}
    collapsed={false}
    disabled
    inner={
      <>
        {appointments.map((appointment) => {
          return (
            <MaintenanceAppointment
              key={appointment.uniqueId}
              appointment={appointment}
              maintenance={maintenance}
              showMaintenanceInfo={showMaintenanceInfo}
            />
          );
        })}
      </>
    }
  />
);
