import React from 'react';
import classNames from 'classnames/bind';
import Image from 'components/Image/Image';
import Carousel from 'components/v2/Carousel/Carousel';
import { SPACE_TYPES } from 'corecomponents/Space/Space';
import Page from 'layouts/Page/Page';
import { GALLERY_MEDIA } from './HomeGalleryPreview.constants';
import styles from './HomeGalleryPreview.module.css';

const cx = classNames.bind(styles);

const HomeGalleryPreview = () => {
  return (
    <Page.OffsetGrid ignoreOffset={[SPACE_TYPES.SM, SPACE_TYPES.MD, SPACE_TYPES.LG, SPACE_TYPES.XL]}>
      <div className={cx('gallery-wrapper')}>
        <Carousel circularControlButtons hideOnMobile={false} renderBottomCenterControls={null}>
          {GALLERY_MEDIA.map((media, imageIndex) => (
            <Image className={cx('image')} key={imageIndex} src={media.url} />
          ))}
        </Carousel>
      </div>
    </Page.OffsetGrid>
  );
};

export default HomeGalleryPreview;
