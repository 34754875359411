import React from 'react';
import { PageViewTracker } from 'analytics';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_SIZES } from 'components/Button/buttonTypes';
import Image from 'components/Image/Image';
import { Status } from 'components/Status/Status';
import String from 'components/String/String';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { Grid } from 'forkedlibraries/react-bootstrap';
import Page from 'layouts/Page/Page';
import { PATHS } from 'routes/paths';
import { PAGE_404 } from 'strings/page-404';
import styles from './Page404.module.css';
import { PAGE_404_IMAGES } from './page404Types';

const cx = classNames.bind(styles);

const Page404 = () => {
  return (
    <Status code={404}>
      <Page>
        <div>
          <Grid>
            <div className={cx('page404')}>
              <Space value={SPACE_TYPES.XXXL} mobileValue={SPACE_TYPES.LG} />
              <h1>
                <String string={PAGE_404.title} />
              </h1>
              <Space value={SPACE_TYPES.XS} />
              <div>{PAGE_404.subtitle}</div>
              <Space value={SPACE_TYPES.XL} />

              <Button label={PAGE_404.button_label} to={PATHS.HOME_PAGE} size={BUTTON_SIZES.FLUID_DEFAULT_RESPONSIVE} />
              <Space value={SPACE_TYPES.XXL} mobileValue={SPACE_TYPES.XL} />
              <Image src={PAGE_404_IMAGES.DINOSAUR} />
            </div>
          </Grid>
        </div>
      </Page>
    </Status>
  );
};

export default PageViewTracker(Page404);
