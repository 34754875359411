import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames/bind';
import Divider from 'components/Divider/Divider';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Space from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './StandardFieldArray.module.css';

const cx = classNames.bind(styles);

const StandardFieldArray = ({
  fieldArrayName,
  singleLine,
  mutators,
  FieldsComponent,
  values,
  showTrashButton,
  iconButtonProps,
  form,
  ...rest
}) => (
  <div className={cx('field-array-wrapper')}>
    <FieldArray name={fieldArrayName}>
      {({ fields }) =>
        fields.map((name, index) => (
          <div key={index} className={cx(rest.fieldClassName)}>
            {showTrashButton && index > 0 && (
              <>
                <Space.MD />
                <Divider.Dashed />
                <Space.MD />
                <div className={cx('header-controls')}>
                  <IconButton
                    align={ALIGN_TYPES.RIGHT}
                    icon={GENERAL_ICONS.TRASH}
                    onClick={() => mutators.remove(fieldArrayName, index)}
                  />
                </div>
              </>
            )}
            <FieldsComponent
              name={name}
              index={index}
              values={values}
              mutators={mutators}
              fieldArrayName={fieldArrayName}
              form={form}
              {...rest}
            />
            <Row>
              <Col md={12}>
                <div
                  className={cx('bottom-controls-wrapper', { removePaddingOnLastItem: index === fields.length - 1 })}
                >
                  <div />
                  {index === get(values, fieldArrayName)?.length - 1 ? (
                    <IconButton
                      icon={GENERAL_ICONS.PLUS}
                      align={ALIGN_TYPES.RIGHT}
                      onClick={() => mutators.push(fieldArrayName, {})}
                      {...iconButtonProps}
                    />
                  ) : (
                    <div />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ))
      }
    </FieldArray>
  </div>
);

StandardFieldArray.propTypes = {
  fieldArrayName: PropTypes.string.isRequired,
  mutators: PropTypes.object.isRequired,
  FieldsComponent: PropTypes.node.isRequired,
  iconButtonProps: PropTypes.object,
  singleLine: PropTypes.bool,
  showTrashButton: PropTypes.bool,
  values: PropTypes.object,
  form: PropTypes.object.isRequired,
};

StandardFieldArray.defaultProps = {
  singleLine: false,
  showTrashButton: true,
  iconButtonProps: {
    label: 'ADD ANOTHER',
  },
  values: {},
};
export default StandardFieldArray;
