import React, { Component } from 'react';
import scriptLoader from 'react-async-script-loader';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Matterport.module.css';

const cx = classNames.bind(styles);

class Matterport extends Component {
  static propTypes = {
    isScriptLoaded: PropTypes.bool.isRequired,
    matterportId: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { isScriptLoaded, matterportId, className } = this.props;

    if (!isScriptLoaded) {
      return null;
    }

    return (
      <div className={cx('container', className)}>
        <iframe
          title="matterport"
          className={cx('iframe')}
          src={`https://my.matterport.com/show/?m=${matterportId}&hhl=0&play=1&tiles=1&brand=0&dh=0&help=0`}
          allowFullScreen
          allow="vr"
          id="showcase_iframe"
        />
      </div>
    );
  }
}

export default scriptLoader(['https://static.matterport.com/showcase-sdk/1.2.0-0-g1d0799d/sdk.js'])(Matterport);
