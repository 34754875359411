import React, { Component } from 'react';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import PaymentMethodDisplay from 'components/Payments/PaymentMethodDisplay/PaymentMethodDisplay';
import PaymentStatusLabel from 'components/Payments/PaymentStatusLabel/PaymentStatusLabel';
import { compareDesc } from 'date-fns';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { uniqBy } from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './TransactionDeposits.module.css';

const cx = classNames.bind(styles);

class TransactionDeposits extends Component {
  render() {
    const { transactions } = this.props;
    transactions.sort((t1, t2) =>
      compareDesc(legacyParse(new Date(t1.processedOn)), legacyParse(new Date(t2.processedOn)))
    );
    const uniqTransactions = uniqBy(
      transactions,
      ({ paymentMethod, accountLastDigits }) => `${paymentMethod}${accountLastDigits}`
    );
    return (
      <div>
        {uniqTransactions.map((transaction, index) => (
          <React.Fragment key={index}>
            <div className={cx('deposit-account', 'desktop')}>
              <div className={cx('account')}>
                <PaymentMethodDisplay
                  // temporary way of handling transactions.
                  // We don't convert it to a PayMent Method instance because
                  // we aren't calling redux.
                  transactionMethod={transaction}
                  paymentMethodType={transaction.accountType}
                />
                <div className={cx('label')}>
                  <PaymentStatusLabel status={transaction.status} />
                </div>
              </div>
              <BDate time={transaction.processedOn} formatType={DATE_TYPES.DAY_MONTH_YEAR_TIME} />
            </div>
            <div className={cx('deposit-account', 'mobile')}>
              <div className={cx('account')}>
                <PaymentMethodDisplay
                  time={transaction.processedOn}
                  transactionMethod={transaction}
                  paymentMethodType={transaction.accountType}
                />
              </div>
              <div className={cx('label')}>
                <PaymentStatusLabel status={transaction.status} />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

TransactionDeposits.propTypes = {
  transactions: PropTypes.shape({
    account: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    sort: PropTypes.func.isRequired,
  }).isRequired,
};

TransactionDeposits.defaultProps = {};

export default TransactionDeposits;
