import type { ComponentProps } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Modal, Text } from '@belong/ui';
import { HomeAccessInstructionsForm } from 'accounts/components/home-access-instructions-form/home-access-instructions-form';
import { HOME_KEYS_STRINGS } from 'accounts/constants/strings/home-keys.strings';
import type { AccessInstructionsModel } from 'api/models';

type Props = Pick<ComponentProps<typeof Modal>, 'onDismiss' | 'isOpen'> & {
  homeAccessInstructions: AccessInstructionsModel;
  unitId: string;
};

export function HomeAccessInstructionsModal({ homeAccessInstructions, isOpen, onDismiss, unitId }: Props) {
  const { width } = useWindowSize();

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  async function handleHomeAccessInstructionsFormSubmit() {
    onDismiss();
  }

  return (
    <Modal
      isOpen={isOpen}
      name="home_access_instructions_modal"
      onDismiss={onDismiss}
      overlayClassName="z-fullscreen"
      size={isMobile ? 'fullScreen' : 'default'}
    >
      <div className="pt-2xl w-full">
        <div className="mb-2xl text-center">
          <Text variant="h2" fontWeight="semibold">
            {HOME_KEYS_STRINGS['delivery.homeAccess.title']}
          </Text>
        </div>
        <HomeAccessInstructionsForm
          homeAccessInstructions={homeAccessInstructions}
          isMobile={isMobile}
          onSubmit={handleHomeAccessInstructionsFormSubmit}
          unitId={unitId}
        />
      </div>
    </Modal>
  );
}
