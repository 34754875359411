import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchHomeownerInspections } from 'store/redux/inspections';

export function useHomeownerInspections() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inspections, setInspections] = useState<any>();

  const fetchInspections = useCallback(async () => {
    setLoading(true);

    const inspectionsResponse = await dispatch(fetchHomeownerInspections());

    setInspections(inspectionsResponse);
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchInspections();
  }, [fetchInspections]);

  return {
    isLoadingInspections: loading,
    inspections,
  };
}
