import { Image } from '@belong/ui';
import clsx from 'clsx';
import { Text } from 'design-system';

export type TipBoxProps = {
  title: string;
  description: string | string[];
  descriptionImages?: string[];
};

export function TipBox({ title, description, descriptionImages = [] }: TipBoxProps) {
  const localDescription = Array.isArray(description) ? description : [description];

  return (
    <div className="border border-gray rounded-[8px] lg:border-solid pt-sm pr-sm pb-xl pl-sm lg:max-w-[350px]">
      <div className={clsx('flex items-center', descriptionImages.length > 0 ? 'mb-lg' : 'mb-sm')}>
        <Image src="/icons/icon-tip.svg" className="h-md w-2sm object-contain mr-xs" alt="" />
        <Text as="h3" fontWeight="semibold">
          {title}
        </Text>
      </div>
      {localDescription.map((text, index) => {
        if (descriptionImages[index]) {
          return (
            <div className="mt-xs flex gap-sm items-center" key={text}>
              <Image alt="" src={descriptionImages[index]} className="h-xl w-xl" />
              <Text as="p">{text}</Text>
            </div>
          );
        }

        return (
          <Text as="p" key={text} className="mt-xs">
            {text}
          </Text>
        );
      })}
    </div>
  );
}
