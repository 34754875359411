import React, { Component } from 'react';
import scriptLoader from 'react-async-script-loader';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
  addAchInstantPaymentMethod,
  fetchDwollaToken,
  fetchPlaidToken,
  fetchPaymentMethods,
} from 'store/redux/user/actions';
import styles from './CreateAchAccount.module.css';

const cx = classNames.bind(styles);

class CreateAchAccount extends Component {
  async componentDidUpdate() {
    const {
      isScriptLoaded,
      onSuccess,
      addAchInstantPaymentMethod: addAchInstantPaymentMethodAction,
      onExit,
      onFail,
      doNotFetchPaymentMethodsOnSuccess,
      showSpinner,
      hideSpinner,
      onBankNotFound,
      permissions,
      fetchPlaidTokenAction,
    } = this.props;

    if (!isScriptLoaded) {
      return;
    }

    if (this.plaid) {
      return;
    }

    this.plaid = true;

    try {
      const plaidKeyToken = await fetchPlaidTokenAction(['auth', 'identity'].concat(permissions));
      const handler = window.Plaid.create({
        token: plaidKeyToken,
        onSuccess: async (publicToken, metadata) => {
          const paymentMethodData = [
            {
              accountId: metadata.account_id,
              institution: metadata.institution.name,
            },
          ];

          // metadata.accountId is the Plaid account token which is different from the accountId
          // in the belong platform
          try {
            showSpinner();
            const paymentMethod = await addAchInstantPaymentMethodAction(
              publicToken,
              paymentMethodData,
              true,
              doNotFetchPaymentMethodsOnSuccess
            );
            onSuccess(metadata, paymentMethod.accountId, paymentMethod);
            hideSpinner();
          } catch (error) {
            console.error('Error onSuccess: ', error);
            onFail();
            hideSpinner();
          }
        },
        onExit: (err, metadata) => {
          this.plaid = false;

          if (metadata?.status === 'institution_not_found') {
            onBankNotFound();
          } else {
            onExit(metadata);

            if (err !== null) {
              console.error('Error onExit: ', err);
              datadogRum.addError(err, {
                validation_error: 'true',
              });

              onFail();
            }
          }
        },
        onEvent: (eventName, metadata) => {
          if (eventName === 'ERROR') {
            datadogRum.addError(new Error(`Plaid Error ${metadata.error_code}: ${metadata.error_message}`), {
              validation_error: 'true',
            });
          }
        },
      });

      this.handler = handler;

      handler.open();
    } catch {}
  }

  componentWillUnmount() {
    if (this.plaid && this.handler) {
      this.handler.exit();
    }
  }

  render() {
    return <div className={cx('create-ach')} />;
  }
}

CreateAchAccount.propTypes = {
  isScriptLoaded: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  addAchInstantPaymentMethod: PropTypes.func.isRequired,
  doNotFetchPaymentMethodsOnSuccess: PropTypes.bool,
  onFail: PropTypes.func,
  onExit: PropTypes.func,
  hideSpinner: PropTypes.func,
  showSpinner: PropTypes.func,
  onBankNotFound: PropTypes.func.isRequired,
  fetchPlaidTokenAction: PropTypes.func.isRequired,
  permissions: PropTypes.array,
};

CreateAchAccount.defaultProps = {
  doNotFetchPaymentMethodsOnSuccess: false,
  onFail: (err) => {
    console.log(err);
  },
  onExit: () => {},
  showSpinner: () => {},
  hideSpinner: () => {},
  permissions: [],
};

export default scriptLoader(['https://cdn.plaid.com/link/v2/stable/link-initialize.js'])(
  connect(null, {
    fetchDwollaToken,
    addAchInstantPaymentMethod,
    fetchPaymentMethods,
    fetchPlaidTokenAction: fetchPlaidToken,
  })(CreateAchAccount)
);
