// Use getstring function in index.js to communicate with items in {}

export const HOMEOWNER_ONBOARDING_STRINGS = {
  property_address: {
    title: 'So, where is this much-loved home?',
    subtitle: 'One home? Great!',
    tip: {
      description: ["Are we there yet? Belong is currently serving select markets, but we're expanding quicky!"],
    },
  },
  PropertyBasics: {
    title: 'What type of property is {streetAddress}?',
    subtitle: 'Ok {firstName}, first things first!',
    own_whole_building_section_title: 'Do you own the whole building?',
    has_mortgage_section_title: 'Do you have a mortgage?',
    has_mortgage_section_title_multi_units: 'Do you have a mortgage for Unit {unitNumber}?',
    mortgage_fee_placeholder: 'Mortgage Payments ($/month)',
    has_hoa_section_title: "How about a homeowner's association?",
    has_hoa_section_title_multi_units: "Is there a homeowner's association?",
    has_hoa_section_subtitle: 'Do you have an HOA or equivalent?',
    hoa_fee_placeholder: 'HOA Fees ($/month)',
    year_built_placeholder: 'Year Built (Optional)',
    sections: {
      hoa_agreement: {
        title: 'Do you have an HOA <span>agreement here?</span>',
        subtitle:
          "Or something similar? If so, we'll make sure we comply with any and all rules set by your homeowner's, neighborhood association, etc.",
      },
    },
    tip: {
      description: [
        "We're here to find a Resident that will love your home. " +
          "By focusing on the entire rental experience, we're attracting the best renters. " +
          'They pay on time, stay longer, and respect your home, which in turn yields better returns for homeowners like you. Better Residents. <span>Better Returns.</span>',
      ],
    },
  },

  UnitBasics: {
    title: "Let's get to know {streetAddress}.",
    subtitle: 'Mind taking us on a tour?',
    own_whole_building_section_title: 'Do you own the whole building?',
    has_mortgage_section_title: 'Do you have a mortgage?',
    has_mortgage_section_title_multi_units: 'Do you have a mortgage for Unit {unitNumber}?',
    mortgage_fee_placeholder: 'Mortgage Payments ($/month)',
    has_hoa_section_title: "How about a homeowner's association?",
    has_hoa_section_title_multi_units: "Is there a homeowner's association?",
    has_hoa_section_subtitle: 'Do you have an HOA or equivalent?',
    hoa_fee_placeholder: 'HOA Fees ($/month)',
    year_built_placeholder: 'Year Built (Optional)',
    sections: {
      property_type: {
        title: 'What type of property <span>is it?</span>',
      },
      hoa_agreement: {
        title: 'Do you have an HOA <span>agreement here?</span>',
        subtitle:
          "Or something similar? If so, we'll make sure we comply with any and all rules set by your homeowner's, neighborhood association, etc.",
      },
    },
    tip: {
      description: [
        "We're here to find a Resident that will love your home. " +
          "By focusing on the entire rental experience, we're attracting the best renters. " +
          'They pay on time, stay longer, and respect your home, which in turn yields better returns for homeowners like you. Better Residents. <span>Better Returns.</span>',
      ],
    },
  },

  UnitConditions: {
    title: 'Tell us about the condition <span>of your home.</span>',
    title_multiple: 'Tell us about the condition <span>of your homes.</span>',
    subtitle: "Belong's residential network has admittedly-high standards.",
    renovatedThisHome: 'Have you ever renovated <span>this home?</span>',
    isHomeCurrentlyFurnished: 'Is your home currently furnished?',
    rentingYourHomeUnfurnished: 'Given our recommendation above, will you consider renting your home unfurnished?',

    applianceAverageAge: 'Avg. Age of Appliances',
    lastRenovationDate: 'Last Major Renovation (YYYY)',

    isHomeCurrentlyFurnishedButtons: [
      { label: "Yes, it's furnished.", key: true },
      { label: "No, it's empty.", key: false },
    ],

    homeCurrentlyFinishedText:
      "After thousands of experiences, we found that unfurnished homes rent for more and lead residents to stay longer, as they are able to make the space truly theirs. Accordingly, we encourage our Homeowners to list their home unfurnished, maximizing their income. We even have a dedicated Member Success Team waiting to help arrange your move, so you don't have to lift a\u00a0finger.",

    tip: {
      description: [
        "Every home is unique. We'll send a background-checked inspector to your home to check the condition of all appliances, paint and carpeting. We'll even do an amazing photo shoot and 3D tour, showcasing to our Members what makes your home great.",
      ],
    },
  },

  UnitOccupancy: {
    multiple_homes_title: 'Are you currently renting out <span>each unit?</span>',
    title: 'Are you currently renting out <span>your home?</span>',
    subtitle: 'Now that we know a bit more...',
    tip: {
      description: [
        "Worried about vacancy? With both Belong Monthly and Upfront, you'll start getting paid rent immediately. " +
          'Plus, our average vacancy is less than 2 weeks, ' +
          'and we only receive a management fee once your home has a Resident.',
        'The way it should be.',
      ],
    },
  },

  UnitMaintenanceSpend: {
    title: 'About how much do you typically spend on upkeep here each year?',
    subtitle: 'Maintenance matters.',
    maintenancetext: 'On average, homes rented for this much require {maintenanceAmount} in maintenance annually.',
    hasWarrantySingleUnit: 'Do you have a home warranty or shield policy?',
    hasWarrantySingleMultiUnit: 'Do you have any home warranty or shield policies?',
    warrantyDisclaimer:
      'Home warranties may seem like cost savers. On the contrary, warranty companies charge a premium to limit your downside, while incentivized to delay and avoid work. In the end, even critical repairs take weeks or even months to complete. As a result, residents often resort to withholding rent, breaking their lease, or taking legal action, costing your more money and headache. No need to worry though, our team is experienced with these policies and can guide you to a better\u00a0option.',
    hasWarrantyIndividual: 'Do you have a home warranty policy at Unit {unitNumber}?',
    policyExpiry: 'When does you policy expire at Unit {unitNumber}?',
    policyExpiryPlaceholder: 'Policy Expiration Date (MM/DD/YY)',
    tip: {
      description: [
        'All repairs and upgrades are performed by our maintenance pro partners. Belong has partnered with thousands of licensed, bonded & insured maintenance pros to ensure your home gets the very best quality of work, all at market rates.',
      ],
    },
  },

  HomeownerMaintenancePreferences: {
    title: 'How do you feel about including <span>the following?</span>',
    subtitle: 'If/ when you sign a new lease...',
    items: [
      {
        title: 'A fresh coat of paint?',
        maintenanceType: 'Paint',
      },
      {
        title: 'A deep clean, including carpeting?',
        maintenanceType: 'DeepClean',
      },
      {
        title: 'Replacements for damaged carpeting?',
        maintenanceType: 'CarpetReplacement',
      },
      {
        title: 'Unique requests from <span>your renters?</span>',
        maintenanceType: 'CustomRequest',
      },
    ],
    // options for this are are stock and stored in component.
    tip: {
      description: [
        'Our goal is to minimize your long-term cost of maintenance. ' +
          'Upfront investments in preventative maintenance and upgrades ' +
          'will foster the best possible Resident experience, ' +
          'which in turn yield higher rents and longer lease occupancies.',
      ],
    },
  },
  HomeownerMaintenanceInvolvement: {
    pretitle: 'As we begin managing your home...',
    title: 'How involved would you <span>like to be?</span>',
    responses: {
      checks:
        'Great! We’ll handle everything for you, so you don’t need to lift a finger. And we’ll always act in the best interest of you and your home.',
      intheloop:
        'Will do! You’ll receive updates on progress, as well as any issues that may arise along the way. And we’ll always act in the best interest of you and your home.',
      alldecision:
        'We love that you want to be involved. It shows you really care about your home. Along the rental journey, there will be times when we simply need to act in the best interest of you, your Residents, and your home. Your Homeowner Advisor will discuss this with you, so we make sure we’re on the same page.',
    },
    tip: {
      description: [
        "Belong is a truly hands-free experience for homeowners like yourself. Every step of the way, we'll do what's best for you and your home. At the same time, you'll never be in the dark. You'll receive updates every step of the way, and your Member Success Lead is always only a phone call, text or email away.",
      ],
    },
  },

  schedule_chat: {
    additional_comments_title: 'Anything else you’d like us to know? Anything you <span>want to cover?</span>',
    additional_information_placeholder: 'Additional Information',
    titleSelfServeElegible: "Let's do it! How should we get in touch?",
    titleNotSelfServeElegible: 'Ok! Lastly, how should we get in touch?',
    subtitle:
      "We'll follow up to go over Belong's Homeowner Standards, and answer any questions <span>you may have.</span>",
    timezone: 'All times in PST',
    sectionTitle: 'What time works best for a 30-minute chat this week?',
    sectionSubtitle: "Select an available time, and we'll send you a confirmation email.",
    tip: {
      description: [
        "We're here to serve! We know this is a big decision. " +
          "If there's anything we can do to help you assess whether Belong is the right choice for you, " +
          "don't hesitate to ask.",
      ],
    },
    button_label: 'Talk soon!',
  },

  multiple_properties: {
    zero_properties_completed: {
      subtitle: 'Hi {firstName}! So we can best serve you...',
      title: 'Please add some basic details on each of your\u00a0homes.',
    },
    atleast_one_property_completed: {
      subtitle: 'Keep going!',
      title: 'Add some basic details on your <span>other homes.</span>',
    },
    all_properties_completed: {
      subtitle: 'Looks good!',
      title: "Next we'll talk about your <span>maintenance preferences.</span>",
    },
    tip: {
      description: [
        "More than one home? We'll assign you a Member Success Lead to be your personal, single point of contact. " +
          "They'll take care of everything from scheduling maintenance, to remembering your residents' kids' birthdays.",
      ],
    },
  },

  success_page: {
    title: 'Great! We look forward to speaking with you.',
    sections: {
      whats_next: {
        title: "What's next",
        listitem0_home: 'Tell us about your home.',
        listitem0_homes: 'Tell us about your homes.',
        listitem_secundary_zipcode: 'Get approved for coverage in your area.',
        listitem1: 'Get to know us. Help us get to know you.',
        listitem2: "Hand us the keys. You're in good hands.",
      },
      cant_wait_that_long: {
        title: "Can't wait that long?",
        subtitle: "We're available to chat anytime between <span>9am-6pm PST.</span> We'd love to hear from you!",
        chatcta: 'CHAT WITH US',
      },
      do_the_math: {
        title: 'In the meantime...',
        subtitle: "Let's estimate how much you could earn <span>using Belong.</span>",
        cta: 'Get Rent Estimates',
      },
    },
    buttonLabel: 'Done',
  },
  'existing_modal.default_button': 'Change Address',
  'existing_modal.default_title': "Looks like this beautiful home is already part of Belong's Network!",

  'existing_modal.homeowner_title': "Looks like your beautiful home is already part of Belong's Network!",

  'existing_modal.homeowner_go_to_account': 'Go To Your Account',

  'self-serve-modal.great-fit':
    "Looks like you'd be a perfect fit for Belong, {firstName}! We can get started now, or you're welcome to speak with someone on my team first.",
  'self-serve-modal.upkeep-concern':
    'Hi {firstName}, looks like there are a few things we should discuss regarding upkeep in your home. Feel free to keep going. Someone will reach out shortly.',
  'self-serve-modal.get-started': "Let's get started!",
  'self-serve-modal.talk-first': 'TALK TO SOMEONE FIRST',
  'referral-message.success':
    "🎉 You're using {userName}'s referral link! This gives you <span>expedited onboarding and support.</span>",
  'referral-message.notfound': '⚠️ Welcome to Belong! Unfortunately, this referral link is no longer valid.',
};
