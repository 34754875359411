import { Image, Text } from '@belong/ui';
import { KeyFormFields } from 'accounts/components/form-fields/key';
import { KeysDeliveredBy } from 'models/enums';
import { HOME_KEYS_STRINGS } from './strings/home-keys.strings';

export const OTHER = 'Other';

export const SupportHomeKeys = {
  HouseKey: 'HouseKey',
  FrontDoor: 'FrontDoor',
  BackDoor: 'BackDoor',
  SideDoor: 'SideDoor',
  Lobby: 'LobbyMainEntrance',
  Gate: 'Gate',
  Garage: 'Garage',
  Mailbox: 'Mailbox',
  Mailroom: 'Mailroom',
  Storage: 'Storage',
  CommunalAmenities: 'CommunalAmenities',
};

export const featuresList = [
  {
    name: SupportHomeKeys.HouseKey,
    title: HOME_KEYS_STRINGS['features.houseKey'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.FrontDoor,
    title: HOME_KEYS_STRINGS['features.frontDoor'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.BackDoor,
    title: HOME_KEYS_STRINGS['features.backDoor'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.SideDoor,
    title: HOME_KEYS_STRINGS['features.sideDoor'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.Lobby,
    title: HOME_KEYS_STRINGS['features.lobbyMainEntrance'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.Gate,
    title: HOME_KEYS_STRINGS['features.gate'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.Garage,
    title: HOME_KEYS_STRINGS['features.garage'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.Mailbox,
    title: HOME_KEYS_STRINGS['features.mailbox'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.Mailroom,
    title: HOME_KEYS_STRINGS['features.mailroom'],
    formFields: <KeyFormFields />,
  },
  {
    name: SupportHomeKeys.Storage,
    title: HOME_KEYS_STRINGS['features.storage'],
    formFields: <KeyFormFields />,
  },
  {
    name: OTHER,
    title: HOME_KEYS_STRINGS['features.other'],
    allowMultiple: true,
    formFields: <KeyFormFields isOther />,
  },
];

export const communalAmenitiesFeature = {
  name: SupportHomeKeys.CommunalAmenities,
  title: HOME_KEYS_STRINGS['features.communalAmenities'],
  formFields: <KeyFormFields />,
};

export const DELIVERY_METHODS = [
  {
    label: HOME_KEYS_STRINGS['delivery.lockbox'],
    text: HOME_KEYS_STRINGS['delivery.lockbox'],
    icon: <Image src="icons/homeowner-lock.svg" alt="" className="w-[72px] h-[72px] inline md:w-3xl md:h-3xl" />,
    iconSelected: (
      <Image src="icons/homeowner-lock-selected.svg" alt="" className="w-[72px] h-[72px] inline md:w-3xl md:h-3xl" />
    ),
    key: KeysDeliveredBy.Lockbox,
  },
  {
    label: (
      <>
        <Text className="inline sm:block">{HOME_KEYS_STRINGS['delivery.belong']}</Text>
        <div className="contents sm:hidden">&nbsp;</div>
        <Text className="inline sm:block">{HOME_KEYS_STRINGS['delivery.lockbox']}</Text>
      </>
    ),
    text: `${HOME_KEYS_STRINGS['delivery.belong']} ${HOME_KEYS_STRINGS['delivery.lockbox']}`,
    icon: <Image src="/icons/belong-lock.svg" alt="" className="w-[72px] h-[72px] inline md:w-3xl md:h-3xl" />,
    iconSelected: (
      <Image src="/icons/belong-lock-selected.svg" alt="" className="w-[72px] h-[72px] inline md:w-3xl md:h-3xl" />
    ),
    key: KeysDeliveredBy.BelongLockbox,
  },
  {
    label: (
      <div className="min-h-[48px] m-auto flex items-center sm:w-max">
        <Text>{HOME_KEYS_STRINGS['delivery.other']}</Text>
      </div>
    ),
    text: HOME_KEYS_STRINGS['delivery.other'],
    icon: <Image src="icons/lock-other.svg" alt="" className="w-[72px] h-[72px] inline md:w-3xl md:h-3xl" />,
    iconSelected: (
      <Image src="icons/lock-other-selected.svg" alt="" className="w-[72px] h-[72px] inline md:w-3xl md:h-3xl" />
    ),
    key: 'Other',
  },
];
