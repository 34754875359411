import React, { useState } from 'react';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ImageCompare from 'components/ImageCompare/ImageCompare';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import Carousel from 'components/v2/Carousel/Carousel';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Flex, Box, Text } from 'design-system';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { wearAndTearItems } from '../constants';
import styles from './WearAndTearModal.module.css';

const cx = classNames.bind(styles);

export const WearAndTearModal = ({ initialValueIndex, show, onHide }) => {
  const { width } = useWindowSize();
  const [valueIndex, setValueIndex] = useState(initialValueIndex);

  const isMobile = width < BREAKPOINTS_WIDTHS.LG;
  const currentValue = wearAndTearItems[valueIndex];
  const previousValue = valueIndex === 0 ? null : wearAndTearItems[valueIndex - 1];
  const nextValue = valueIndex === wearAndTearItems.length - 1 ? null : wearAndTearItems[valueIndex + 1];
  return (
    <ModalV2
      name={ANALYTICS_MODAL_NAMES.WEAR_AND_TEAR}
      show={show}
      onHide={onHide}
      closeButton
      noPadding
      closable={false}
      className={cx('no-padding')}
    >
      <Flex pt="2xl" pb="4xl" flexDirection="column">
        <Box>
          <Carousel
            slideIndex={initialValueIndex}
            pagingDotsClassName="green"
            className={cx('image-compare-carousel')}
            dragging={false}
            beforeSlide={(_, nextIndex) => {
              setValueIndex(nextIndex);
            }}
            renderBottomCenterControls={isMobile ? undefined : null}
            renderCenterLeftControls={
              isMobile
                ? null
                : ({ previousSlide, currentSlide }) => {
                    return currentSlide !== 0 ? (
                      <ButtonBase
                        style={{ position: 'absolute', top: 350, left: 20, textTransform: 'uppercase' }}
                        onClick={previousSlide}
                      >
                        <Flex alignItems="center">
                          <GeneralIcon icon={GENERAL_ICONS.BACK_ARROW} />
                          <Text fontWeight="semibold" style={{ whiteSpace: 'nowrap' }} ml="xs">
                            {previousValue?.title}
                          </Text>
                        </Flex>
                      </ButtonBase>
                    ) : null;
                  }
            }
            renderCenterRightControls={
              isMobile
                ? null
                : ({ nextSlide, currentSlide }) => {
                    return currentSlide !== wearAndTearItems.length - 1 ? (
                      <ButtonBase
                        style={{ position: 'absolute', top: 350, right: 20, textTransform: 'uppercase' }}
                        onClick={nextSlide}
                      >
                        <Flex alignItems="center">
                          <Text fontWeight="semibold" style={{ whiteSpace: 'nowrap' }} mr="xs">
                            {nextValue?.title}
                          </Text>
                          <GeneralIcon icon={GENERAL_ICONS.FRONT_ARROW} />
                        </Flex>
                      </ButtonBase>
                    ) : null;
                  }
            }
          >
            {wearAndTearItems.map((item, index) => {
              return (
                <Box key={index}>
                  <Flex mb="xl" justifyContent="center">
                    <Text fontSize="h2" fontWeight="semibold" textAlign="center">
                      {/* Change title to name */}
                      {currentValue.title}
                    </Text>
                  </Flex>
                  <ImageCompare
                    key={index}
                    containerClassName={cx('compare-container')}
                    leftImage={item.beforeUrl}
                    rightImage={item.afterUrl}
                    bottomShadow
                    leftLabel={
                      <Box>
                        <Text fontWeight="semibold">Normal Wear and Tear</Text>
                        <Box>
                          <Text fontSize="p1">(no deduction typically taken)</Text>
                        </Box>
                      </Box>
                    }
                    bottomLeftText={formatString(item.beforeText)}
                    rightLabel={
                      <Box>
                        <Text>Excessive Damage</Text>
                        <Box>
                          <Text fontSize="p1">(deduction typically taken)</Text>
                        </Box>
                      </Box>
                    }
                    bottomRightText={formatString(item.afterText)}
                  />
                </Box>
              );
            })}
          </Carousel>
        </Box>
      </Flex>
    </ModalV2>
  );
};

WearAndTearModal.propTypes = {
  initialValueIndex: PropTypes.number.isRequired,
  show: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
};
