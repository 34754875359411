import { MoneyRange, Money } from '@belong/ui';
import classNames from 'classnames/bind';
import { Text } from 'design-system';
import { MaintenancePaymentPlan } from 'models/enums';
import { getPaymentBreakdown, isTBD, hasHomeownerApproved } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import PropTypes from 'prop-types';
import { palette } from 'styles/palette';
import { pluralize } from 'utils/pluralize';
import BulletList from '../../../../../../../components/BulletList/BulletList';
import styles from './BundlePrice.module.css';

const cx = classNames.bind(styles);

export const BUNDLE_PRICE_DISPLAY_TYPES = {
  DEFAULT: 'DEFAULT',
  SMALL: 'SMALL',
};

export const BUNDLE_PRICE_TYPES = {
  ESTIMATED: 'ESTIMATED',
  TOTAL: 'TOTAL',
};

const propTypes = {
  maximumMonthlyPaymentPlan: PropTypes.number,
  homeownerPayment: PropTypes.object.isRequired,
  bundle: PropTypes.object.isRequired,
  displayType: PropTypes.oneOf(Object.values(BUNDLE_PRICE_DISPLAY_TYPES)),
  priceType: PropTypes.oneOf(Object.values(BUNDLE_PRICE_TYPES)),
  bullet: PropTypes.bool,
  textClassname: PropTypes.string,
  primaryTextProps: PropTypes.object,
  secondaryTextProps: PropTypes.object,
  selected: PropTypes.bool,
  isMonthly: PropTypes.bool,
};

const defaultProps = {
  maximumMonthlyPaymentPlan: 0,
  displayType: BUNDLE_PRICE_DISPLAY_TYPES.DEFAULT,
  priceType: BUNDLE_PRICE_TYPES.DEFAULT,
  bullet: false,
  isMonthly: false,
};

const BundlePrice = ({
  displayType,
  priceType,
  maximumMonthlyPaymentPlan,
  homeownerPayment,
  bundle,
  bullet,
  textClassname = '',
  primaryTextProps,
  secondaryTextProps,
  selected,
  isMonthly,
}) => {
  let monthlyCost = 0;
  // TODO: PLAY WITH THESE VAUES FOR MOVE OUT REPORTS
  const breakdown = getPaymentBreakdown(bundle);
  const estimatedDisplayCost = bundle.priceRange?.upperBoundSubtotal
    ? bundle.priceRange?.upperBoundSubtotal
    : breakdown.homeowner?.estimatedDisplayCost;
  const estimatedInstallmentBreakdown = breakdown.homeowner?.estimatedInstallmentBreakdown;
  const totalEstimatedInstallmentBreakdown = breakdown.homeowner?.totalEstimatedInstallmentBreakdown;

  let months = maximumMonthlyPaymentPlan;

  if (homeownerPayment?.paymentPlan === MaintenancePaymentPlan.Monthly) {
    months = homeownerPayment.installmentCount;
  } else if (homeownerPayment?.paymentPlan === MaintenancePaymentPlan.OneTime) {
    months = 0;
  }

  if (months) {
    const installmentCostDetail =
      priceType !== BUNDLE_PRICE_TYPES.TOTAL
        ? estimatedInstallmentBreakdown?.find((b) => b.installmentCount === months)
        : totalEstimatedInstallmentBreakdown?.find((b) => b.installmentCount === months);
    if (installmentCostDetail) {
      monthlyCost = installmentCostDetail.totalCost;
    }
  }

  const TBDItems = bundle.items.filter((x) => isTBD(x)).length;
  const allTBDItems = TBDItems > 0 && TBDItems === bundle.items.length;

  const approvedItems = bundle.items.filter((x) => hasHomeownerApproved(x.maintenancePayments));
  const approvedTBDItems = approvedItems.filter((x) => isTBD(x)).length;
  const allApprovedTBDItems = approvedTBDItems > 0 && approvedTBDItems === approvedItems.length;

  if (allTBDItems || allApprovedTBDItems) {
    return (
      <div className={cx('bundle-price', { small: displayType === BUNDLE_PRICE_DISPLAY_TYPES.SMALL })}>
        <div className={cx(['highlight', textClassname])}>TBD</div>
      </div>
    );
  }

  const costItem =
    priceType === BUNDLE_PRICE_TYPES.TOTAL ? (
      <StrikedPrice
        item={bundle.items[0]}
        selected={selected ?? bundle.estimatedCost > 0}
        primaryTextProps={primaryTextProps}
        secondaryTextProps={secondaryTextProps}
        isMonthly={isMonthly}
        months={months}
      />
    ) : (
      <Text
        primaryTextProps={primaryTextProps}
        secondaryTextProps={secondaryTextProps}
        width="max-content"
        className="flex"
      >
        <Money format="DOLLARS_NO_CENTS" value={estimatedDisplayCost} className="font-semibold" />
        {approvedTBDItems > 0 && <> + {pluralize(approvedTBDItems, 'TBD Item')}</>}
      </Text>
    );

  if (bullet) {
    return (
      <div className={cx('bundle-price', { small: displayType === BUNDLE_PRICE_DISPLAY_TYPES.SMALL })}>
        <BulletList
          listItems={[
            <Text fontSize="body" key="bundle-price-item" fontWeight="semibold">
              {isMonthly
                ? `${(<Money format="DOLLARS_NO_CENTS" value={monthlyCost} className="font-semibold" />)}/mo.`
                : costItem}
            </Text>,
          ]}
        />
      </div>
    );
  }

  return (
    <div className={cx('bundle-price', { small: displayType === BUNDLE_PRICE_DISPLAY_TYPES.SMALL })}>
      {isMonthly ? (
        <Text fontSize="body" fontWeight="semibold">
          <Money format="DOLLARS_NO_CENTS" value={monthlyCost} className="font-semibold" />
          /mo.
        </Text>
      ) : (
        <Text fontSize="body" fontWeight="semibold">
          {costItem}
        </Text>
      )}
    </div>
  );
};

BundlePrice.propTypes = propTypes;
BundlePrice.defaultProps = defaultProps;

export default BundlePrice;

export const StrikedPrice = ({
  item,
  selected,
  textProps,
  primaryTextProps,
  secondaryTextProps,
  costOption = {},
  isMonthly,
  months,
  range,
  showFullPrice = false,
}) => {
  const hasCostOption = !!Object.keys(costOption).length;
  let originalPrice = hasCostOption ? costOption.optionDisplayCost : item.estimatedDisplayCost;

  if (isMonthly) {
    originalPrice =
      (item.totalEstimatedCost * 0.75) / months + (item.totalEstimatedCost * 0.75 * months) / 100 / months;
  }
  if (showFullPrice) {
    originalPrice = item.estimatedDisplayCostForAllParties;
  }

  return (
    <Text
      fontSize="body"
      color={selected ? 'green' : palette['dark-gray']}
      sx={{
        ...{ fontWeight: '600 !important' },
      }}
      {...textProps}
      {...primaryTextProps}
      {...secondaryTextProps}
    >
      {isTBD(item) ? (
        'TBD'
      ) : range ? (
        <MoneyRange lower={range.lowerBound} upper={range.upperBound} format="DOLLARS_NO_CENTS" />
      ) : (
        <Money format="DOLLARS_NO_CENTS" value={originalPrice} className="font-semibold" />
      )}
    </Text>
  );
};

StrikedPrice.propTypes = {
  costOption: PropTypes.object,
  isMonthly: PropTypes.bool,
  item: PropTypes.object,
  months: PropTypes.number,
  primaryTextProps: PropTypes.object,
  range: PropTypes.object,
  secondaryTextProps: PropTypes.object,
  selected: PropTypes.bool,
  showFullPrice: PropTypes.bool,
  textProps: PropTypes.object,
};
