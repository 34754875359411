import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import PropTypes from 'prop-types';
import { BUTTON_SIZES } from '../../../components/Button/buttonTypes';
import ModalV2 from '../../../components/Modal/ModalV2/ModalV2';
import Space from '../../../corecomponents/Space/Space';
import styles from './GetInfoModal.module.css';

const cx = classNames.bind(styles);

export default class GetInfoModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.node,
    buttonLabel: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    closebutton: PropTypes.bool,
    closable: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    description: <div className={cx('removetextalign')}>Hello World</div>,
    buttonLabel: 'Sounds Good!',
    closebutton: false,
    closable: true,
  };

  render() {
    const { title, description, show, closable, onHide, buttonLabel, closebutton, ...rest } = this.props;
    return (
      <ModalV2
        closeButton={closebutton}
        title={title}
        subTitle={description}
        noPadding
        closable={closable}
        show={show}
        onHide={onHide}
        noBorder
        animation={false}
        makeTransparent
        {...rest}
      >
        <div className={cx('center')}>
          <Button onClick={onHide} label={buttonLabel} size={BUTTON_SIZES.FLUID_DEFAULT_RESPONSIVE} />
        </div>
        {/*
            This is different because we are not using ModalLayout here
          */}
        <Space mobileValue={SPACE_TYPES.XXXL} />
      </ModalV2>
    );
  }
}
