import { Image, Text } from '@belong/ui';

type Props = {
  description: string;
  image: string;
  title: string;
};

export const WalletFeatureItem = ({ description, image, title }: Props) => (
  <div className="flex flex-col justify-center items-center">
    <Image alt={title} className="h-[260px] mb-xs object-contain" src={image} />
    <Text fontWeight="semibold">{title}</Text>
    <div className="mt-xs text-p1 text-center max-w-[370px] sm:max-w-[495px]">
      <Text dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </div>
);
