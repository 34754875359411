import { useCallback, useEffect, useRef, useState } from 'react';
import { Text } from '@belong/ui';
import { ChatWidget } from 'components/ChatWidget/ChatWidget';
import { MobileBanner } from '../YourPortfolio/Earnings/MobileBanner/MobileBanner';
import { INBOX_STRINGS } from './inbox.strings';
import { Team } from './team';

export function Inbox() {
  const bannerRef = useRef<HTMLDivElement>(null);
  const isHomeowner = window.location.pathname.includes('portfolio');
  const [shouldHideHelp, setShouldHideHelp] = useState(false);

  const isBelowViewport = useCallback(() => {
    if (bannerRef?.current) {
      const bannerRect = bannerRef.current?.getBoundingClientRect();
      const overlapsWithHelp =
        bannerRect?.bottom + 120 > window.innerHeight && bannerRect?.right + 200 > window.innerWidth;

      setShouldHideHelp(overlapsWithHelp);
    }
  }, [bannerRef]);

  useEffect(() => {
    window.addEventListener('scroll', isBelowViewport);
    return () => {
      window.removeEventListener('scroll', isBelowViewport);
    };
  }, [bannerRef, isBelowViewport]);

  useEffect(() => {
    isBelowViewport();
  }, [isBelowViewport]);

  return (
    <div className="mb-5xl">
      <Text variant="h3" fontWeight="semibold">
        {INBOX_STRINGS.title}
      </Text>
      <Text className="mt-xs">{INBOX_STRINGS.description}</Text>
      {isHomeowner && <Team />}
      <div className="mt-4xl lg:mt-3xl" ref={bannerRef}>
        <MobileBanner title="Download Belong App" isInbox />
      </div>
      <ChatWidget isEnabled initializeOpen shouldHideLabels={shouldHideHelp} shouldShowInboxLabel />
    </div>
  );
}
