import React, { Fragment } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames/bind';
import { FixedMenu } from 'components/FixedMenu/FixedMenu';
import Menu from 'components/Menu/Menu';
import { MENU_TYPES } from 'components/Menu/menuTypes';
import { ALL_USER_MENU, NO_CLAIMS_PAYMENTS_MENU_ITEM, RESIDENT_DOCUMENTS_MENU_ITEM } from 'consts/account-menu-items';
import styles from './accounts-layout.module.css';

const cx = classNames.bind(styles);

export function NoClaimsLayout({ comeFromTheMobileApp, children }) {
  // TODO: Change to /accounts/payments instead!!
  const menuItems = [NO_CLAIMS_PAYMENTS_MENU_ITEM, RESIDENT_DOCUMENTS_MENU_ITEM, ...ALL_USER_MENU];

  const params = useParams<Record<string, string>>();
  const { section } = params;

  return (
    <Fragment>
      {!comeFromTheMobileApp && <FixedMenu menuItems={menuItems} params={params} />}
      <div className="container mt-sm lg:mt-lg">
        <div className={cx('sidebar-wrapper')}>
          <div className={cx('sidebar')}>
            <Menu controlled menuType={MENU_TYPES.BACKGROUND} selected={section} menuItems={menuItems} />
          </div>
        </div>
        {children}
      </div>
    </Fragment>
  );
}
