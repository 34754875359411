export default class Pet {
  constructor(data = {}) {
    this.uniqueId = data.uniqueId;
    this.name = data.name;
    this.petType = data.petType;
    this.subType = data.subType;
    this.weight = data.weight;
    this.isServiceAnimal = data.isServiceAnimal;
  }
}
