import React, { useState } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Carousel } from '@belong/ui';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import Image from 'components/Image/Image';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import String from 'components/String/String';
// import Carousel from 'components/v2/Carousel/Carousel';
import { STATIC_IMAGES } from 'consts/static-images';
import { Box } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { PATHS, BASE_PATHS } from 'routes/paths';
import styles from './HowItWorksModal.module.css';
import ImageLinkCard from './ImageLinkCard/ImageLinkCard';

const cx = classNames.bind(styles);

const Screens = {
  Selector: 'Selector',
  Homeowner: 'Homeowner',
  Resident: 'Resident',
};

const ResidentsCarousel = ({ slides, isMobile }) => {
  return (
    <Carousel startIndex={0} arrowsOnDots={!isMobile}>
      {slides.map(({ src, title, content }) => (
        <div key={title} className="flex flex-col items-center min-w-full">
          <div className={cx('image-container')}>
            <Image src={src} />
          </div>
          <div className={cx('covid-content-container')}>
            <String string={content} />
          </div>
        </div>
      ))}
    </Carousel>
  );
};

ResidentsCarousel.propTypes = {
  slides: PropTypes.array,
  isMobile: PropTypes.bool,
};

const HomeownersCarousel = ({ slides, isMobile }) => {
  return (
    <Carousel startIndex={0} arrowsOnDots={!isMobile}>
      {slides.map(({ src, title, content }) => (
        <div key={title} className="flex flex-col items-center min-w-full">
          <div className={cx('image-container')}>
            <Image src={src} />
          </div>
          <div className={cx('covid-content-container')}>
            <String string={content} />
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export const HowItWorksModal = ({ show, onHide }) => {
  const { width } = useWindowSize();

  const isMobile = width < BREAKPOINTS_WIDTHS.MD;
  const [currentScreen, setCurrentScreen] = useState(Screens.Selector);
  const homeownerCarouselData = [
    {
      src: STATIC_IMAGES.HIW_HO_01,
      content: 'First, introduce us to your<br />beloved home.',
    },
    {
      src: STATIC_IMAGES.HIW_HO_02,
      content: "Still living in the home?<br />We'll handle <span>your move.</span>",
    },
    {
      src: STATIC_IMAGES.HIW_HO_03,
      content: 'Belong takes care of<br />all reconditioning <span>and renovations.</span>',
    },
    {
      src: STATIC_IMAGES.HIW_HO_04,
      content: 'We’ll make your home<br />instagrammable.',
    },
    {
      src: STATIC_IMAGES.HIW_HO_05,
      content: 'And handle<br />all tours <span>and showings.</span>',
    },
    {
      src: STATIC_IMAGES.HIW_HO_06,
      content: "We'll find Residents<br />to love <span>your home.</span>",
    },
    {
      src: STATIC_IMAGES.HIW_HO_07,
      content: "They'll be fully vetted<br />and 100% guaranteed.",
    },
    {
      src: STATIC_IMAGES.HIW_HO_08,
      content: 'Meet your Member Success Team,<br />available 24/7.',
    },
    {
      src: STATIC_IMAGES.HIW_HO_09,
      content: 'Communicate, <span>review estimates,</span><br />and approve work <span>on your phone.</span>',
    },
    {
      src: STATIC_IMAGES.HIW_HO_10,
      content: 'Receive Smooth Payments™<br />and never worry <span>about rent again.</span>',
    },
  ];
  const residentCarouselData = [
    {
      src: STATIC_IMAGES.HIW_RE_01,
      content: 'Love a Belong home?<br />Just apply online.',
    },
    {
      src: STATIC_IMAGES.HIW_RE_02,
      content: "Need movers?<br />We'll take care of that.",
    },
    {
      src: STATIC_IMAGES.HIW_RE_03,
      content: "Welcome to your home!<br />It's all ready for you.",
    },
    {
      src: STATIC_IMAGES.HIW_RE_05,
      content: 'Meet your Member Success Team,<br />available 24/7.',
    },
    {
      src: STATIC_IMAGES.HIW_RE_06,
      content: 'Pay rent online.<br />Finally!',
    },
    {
      src: STATIC_IMAGES.HIW_RE_07,
      content: 'Our Belong Pros are<br />always at your service.',
    },
    {
      src: STATIC_IMAGES.HIW_RE_08,
      content: 'You and your home<br />will be treated exceptionally.',
    },
    {
      src: STATIC_IMAGES.HIW_RE_09,
      content: "Time to move again?<br />We'll make that <span>seamless too.</span>",
    },
  ];

  HomeownersCarousel.propTypes = {
    slides: PropTypes.array,
    isMobile: PropTypes.bool,
  };

  return (
    <ModalV2
      name={ANALYTICS_MODAL_NAMES.HOW_IT_WORKS}
      show={show}
      onHide={() => {
        onHide();
        setCurrentScreen(Screens.Selector);
      }}
      closeButton
      noPadding
      closable={false}
      className={cx('modal')}
    >
      {currentScreen === Screens.Selector && (
        <div className={cx('selector')}>
          <div className={cx('title')}>How It Works</div>
          <Box pt="xl" px="sm">
            <Row>
              <Col xs={12} sm={6}>
                <ImageLinkCard
                  linkLabel="For Homeowners"
                  imageSrc={STATIC_IMAGES.HIW_PLACEMAT_HOMEOWNER}
                  onClick={() => {
                    setCurrentScreen(Screens.Homeowner);
                  }}
                />
              </Col>
              <Col xs={12} sm={6}>
                <ImageLinkCard
                  linkLabel="For Residents"
                  imageSrc={STATIC_IMAGES.HIW_PLACEMAT_RESIDENT}
                  onClick={() => setCurrentScreen(Screens.Resident)}
                />
              </Col>
            </Row>
          </Box>
          <div className={cx('city-image-wrapper')}>
            <Image className={cx('city-image')} src="/city_images/modal_city.svg" />
          </div>
        </div>
      )}
      {(currentScreen === Screens.Homeowner || currentScreen === Screens.Resident) && (
        <div className={cx('covid-carousel-container')}>
          <Box p="sm">
            <div className={cx('switch-button')}>
              <Button
                buttonType={BUTTON_TYPES.TEXT}
                onClick={() => {
                  setCurrentScreen(currentScreen === Screens.Homeowner ? Screens.Resident : Screens.Homeowner);
                }}
              >
                {currentScreen === Screens.Homeowner ? 'Switch to Residents' : 'Switch to Homeowners'}
              </Button>
            </div>
            <div className={cx('title')}>
              {currentScreen === Screens.Homeowner ? 'Belong Homeowners' : 'Belong Residents'}
            </div>
          </Box>

          {currentScreen === Screens.Homeowner ? (
            <HomeownersCarousel isMobile={isMobile} slides={homeownerCarouselData} />
          ) : (
            <ResidentsCarousel isMobile={isMobile} slides={residentCarouselData} />
          )}

          <div className={cx('cta')}>
            <Button to={currentScreen === Screens.Homeowner ? BASE_PATHS.HOMEOWNER : PATHS.RESIDENT_LANDING}>
              Learn More
            </Button>
          </div>
        </div>
      )}
    </ModalV2>
  );
};

HowItWorksModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

HowItWorksModal.defaultProps = {
  show: false,
};
