import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { required, phoneValidation, email, composeValidators } from 'utils/validation';
import { maskPhoneNumber, unmaskPhoneNumber } from 'components/Input/masks';

const LeadForm = ({ className }) => (
  <div className={classNames(className)}>
    <Row>
      <Col md={6}>
        <Field name="firstName" validate={required} component={InputFinalFormAdapter} placeholder="First name" />
      </Col>
      <Col md={6}>
        <Field name="lastName" validate={required} component={InputFinalFormAdapter} placeholder="Last name" />
      </Col>
    </Row>
    <Field
      name="email"
      validate={composeValidators(required, email)}
      component={InputFinalFormAdapter}
      placeholder="Email address"
    />
    <Field
      name="phone"
      component={InputFinalFormAdapter}
      validate={composeValidators(required, phoneValidation)}
      placeholder="Phone number"
      mask={maskPhoneNumber}
      unmask={unmaskPhoneNumber}
    />
  </div>
);

LeadForm.propTypes = {
  className: PropTypes.string,
};

LeadForm.defaultProps = {
  className: '',
};

export default LeadForm;
