import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchNotes } from 'store/redux/common/notes';

export function useNotes({ sourceType, sourceIds }) {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState<any>();
  const [loading, setLoading] = useState(false);

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const response = await dispatch(fetchNotes({ sourceType, sourceIds }));

      setNotes(response);
    } finally {
      setLoading(false);
    }
  }, [dispatch, sourceIds, sourceType]);

  useEffect(() => {
    if (sourceIds) {
      init();
    }
  }, [init, sourceIds]);

  return { notes, isLoadingNotes: loading, refetch: init };
}
