import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import String from 'components/String/String';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { addAchMicrodepositPaymentMethod } from 'store/redux/user/actions';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import { required } from 'utils/validation';

class AchManualScreen extends Component {
  static propTypes = {
    addAchMicrodepositPaymentMethod: PropTypes.func.isRequired,
    showSpinner: PropTypes.func.isRequired,
    hideSpinner: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  handleSubmit = async (values) => {
    const metadata = {
      ...values,
    };

    const {
      showSpinner,
      hideSpinner,
      onSuccess,
      addAchMicrodepositPaymentMethod: addAchMicrodepositPaymentMethodAction,
    } = this.props;

    showSpinner();

    try {
      const accounts = await addAchMicrodepositPaymentMethodAction(metadata);
      hideSpinner();
      onSuccess(accounts[0].accountId, accounts[0]);
    } catch (error) {
      hideSpinner();
      this.setState({ error: FEEDBACK_STRINGS.manual_ach_fail });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <ModalLayout>
        <ModalLayout.Title>Connect bank manually</ModalLayout.Title>
        {error && (
          <ModalLayout.Error>
            <String string={error} />
          </ModalLayout.Error>
        )}
        <ModalLayout.Content>
          <Form
            onSubmit={this.handleSubmit}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit}>
                <FormLayout>
                  <Row>
                    <Col sm={6}>
                      <Field
                        name="accountType"
                        placeholder="Account Type"
                        component={DropdownFinalFormAdapter}
                        validate={required}
                        items={[
                          {
                            key: 'checking',
                            value: 'Checking',
                          },
                          {
                            key: 'savings',
                            value: 'Savings',
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Field
                        name="accountNumber"
                        placeholder="Account Number"
                        component={InputFinalFormAdapter}
                        validate={required}
                      />
                    </Col>
                    <Col sm={6}>
                      <Field
                        name="routingNumber"
                        placeholder="Routing Number"
                        component={InputFinalFormAdapter}
                        validate={required}
                      />
                    </Col>
                  </Row>
                  <ModalLayout.BottomBar
                    fixedBottom
                    ctaProps={{
                      label: 'Save',
                      disabled: invalid,
                    }}
                  />
                </FormLayout>
              </form>
            )}
          />
        </ModalLayout.Content>
      </ModalLayout>
    );
  }
}

export default connect(null, {
  addAchMicrodepositPaymentMethod,
})(AchManualScreen);
