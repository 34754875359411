import { Image, Text, Carousel } from '@belong/ui';
import { IMPROVEMENTS_BENEFITS } from 'pages/PostInspectionFlow/constants';

export function ImprovementsBenefits() {
  return (
    <>
      <div className="contents sm:hidden">
        <Carousel>
          {IMPROVEMENTS_BENEFITS.map((improvementBenefit) => (
            <div className="flex flex-col items-center justify-center gap-xs min-w-full" key={improvementBenefit.title}>
              <Image alt={improvementBenefit.title} src={improvementBenefit.imageSrc} className="w-3xl h-3xl" />
              <div className="flex flex-col gap-2xs text-center">
                <Text fontWeight="semibold">{improvementBenefit.title}</Text>
                <Text variant="p1">{improvementBenefit.description}</Text>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="hidden sm:grid sm:grid-cols-2 sm:gap-y-2xl sm:gap-x-sm">
        {IMPROVEMENTS_BENEFITS.map((improvementBenefit) => (
          <div className="flex flex-col gap-xs" key={improvementBenefit.title}>
            <Image alt={improvementBenefit.title} src={improvementBenefit.imageSrc} className="w-2xl h-2xl" />
            <div className="flex flex-col gap-2xs">
              <Text fontWeight="semibold">{improvementBenefit.title}</Text>
              <Text variant="p1">{improvementBenefit.description}</Text>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
