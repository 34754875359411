import React, { Component } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import PropTypes from 'prop-types';
import FormatBottomBar from './FormatBottomBar/FormatBottomBar';
import ModalButton from './ModalButton';
import styles from './ModalLayout.module.css';

const cx = classNames.bind(styles);

export default class ModalLayout extends Component {
  // To-Do: Remove this. Use closeButton flag on Modal component
  // to display the correct close button
  static Header = ({ handleClose }) => (
    <div className={cx('header')}>
      <ButtonBase onClick={handleClose}>
        <GeneralIcon icon={GENERAL_ICONS.CLOSE} />
      </ButtonBase>
    </div>
  );

  static PreTitle = ({ children, label, className }) => (
    <div className={cx('pre-title', className)}>{children || label}</div>
  );

  static Title = ({ children, label, noMarginTop, className, noPadding }) => (
    <div className={cx('title', className, { noMarginTop }, { noPadding })}>{children || label}</div>
  );

  // Modal level error message
  static Error = ({ children, label }) => <div className={cx('error-message')}>{children || label}</div>;

  static SubTitle = ({ className, children, noPaddingBottom, withMarginTop, label, whiteSpaceNormal, noPadding }) => (
    <div
      className={cx(
        'sub-title',
        className,
        { noPaddingBottom },
        { withMarginTop },
        { whiteSpaceNormal },
        { noPadding }
      )}
    >
      {children || label}
    </div>
  );

  // Used to wrap a form
  static Content = ({ children, noMargin, className }) => (
    <div className={cx('content', { noMargin }, className)}>{children}</div>
  );

  // Used to dictate sections of the form.
  static Section = ({ children, className, marginXl }) => (
    <div className={cx('section', className, { marginXl })}>{children}</div>
  );

  static SectionTitle = ({ children, mobileSupportInput, noMargin, label }) => (
    <div className={cx('section-title', { mobileSupportInput }, { noMargin })}>{children || label}</div>
  );

  static Button = (props) => <ModalButton {...props} />;

  static FullScreen = ({ children, style }) => (
    <div className={cx('full-screen')} style={style}>
      {children}
    </div>
  );

  static BottomBar = ({
    ctaProps,
    noButton,
    fixedBottom,
    cancelRequest,
    leftSideNode,
    rightNode,
    removeMarginTopAndBorderBottomBar,
    noMarginOrPaddingOnMobileForButton,
    nextButtonWrapperProps,
    reverseOrderOnMobile,
    className = '',
    makeNode,
    noPadding,
  }) => (
    // eslint-disable-next-line react/jsx-indent
    <>
      {!fixedBottom ? (
        <div className={cx('bottom-bar', { noButton, noPadding }, 'no-bottom-margin', { reverseOrderOnMobile })}>
          <FormatBottomBar
            noButton={noButton}
            cancelRequest={cancelRequest}
            ctaProps={ctaProps}
            rightNode={rightNode}
            leftSideNode={leftSideNode}
            nextButtonWrapperProps={nextButtonWrapperProps}
          />
        </div>
      ) : (
        <div
          className={cx(
            'bottom-bar',
            { removeMarginTopAndBorderBottomBar },
            { fixedBottom },
            { noButton },
            { reverseOrderOnMobile },
            { noPadding },
            className
          )}
        >
          <FormatBottomBar
            noButton={noButton}
            cancelRequest={cancelRequest}
            ctaProps={ctaProps}
            rightNode={rightNode}
            leftSideNode={leftSideNode}
            noMarginOrPaddingOnMobileForButton={noMarginOrPaddingOnMobileForButton}
            nextButtonWrapperProps={nextButtonWrapperProps}
            makeNode={makeNode}
          />
        </div>
      )}
      <div className={cx('spacing-on-mobile-buttons')} />
    </>
  );

  // This should pass in the route to the previous page
  // and the Previous Step Icon should be here

  static propTypes = {
    children: PropTypes.node.isRequired,
    // Not sure if this should be here.
  };

  render() {
    const { children, className } = this.props;
    return <div className={cx('modal-layout', className)}>{children}</div>;
  }
}

ModalLayout.propTypes = {
  className: PropTypes.string,
};
