import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import config from 'config/config';
import { objectInsertIf } from 'utils/insertIf';

const { gaId } = config;

export const pageview = (url) => {
  if (window.gtag) {
    window.gtag('config', gaId, {
      page_path: url,
    });
  }
};

export const gaEvent = ({ action, category, label, value }) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};

export const GA_ACTIONS = {
  ADDRESS_ENTERED_CALCULATOR: 'AddressEnteredCalculator',
  ADDRESS_ENTERED_LANDING_PAGE_COVER: 'AddressEnteredLandingPageCover',
  ADDRESS_ENTERED_SIGNUP_FLOW: 'AddressEnteredSignUpFlow',
  LEAD_CREATED_CALCULATOR: 'LeadCreatedCalculator',
  LEAD_CREATED_SIGNUP_FLOW: 'LeadCreatedSignUpFlow',
  LEAD_CREATED_TRY_THE_PROS: 'LeadCreatedTryThePros',
};

const ACTIONS_MAP = {
  LeadCreatedCalculator: 'calculator',
  LeadCreatedSignUpFlow: 'sign_up_flow',
  LeadCreatedTryThePros: 'ask_a_question',
};

export const setEnhancedConvertionData = (user = {}) => {
  const enhancedConversionUser = {};
  if (user.email) enhancedConversionUser.email = user.email;
  if (user.phone) enhancedConversionUser.phone_number = user.phone;
  if (user.firstName) enhancedConversionUser.first_name = user.firstName;
  if (user.lastName) enhancedConversionUser.last_name = user.lastName;
  if (user.zipcode) enhancedConversionUser.postal_code = user.zipcode;
  if (user.country) enhancedConversionUser.country = user.country;

  window.dataLayer?.push?.(enhancedConversionUser);
};

export const trackHomeownerAddressEntered = (action, address, rent) => {
  if (!action || !address?.city) {
    return;
  }

  gaEvent({
    category: 'Homeowner',
    action,
    label: `Rent|${address.city.replace(/ /g, '')}-${address.state.toUpperCase()}|${address.zipcode}`,
    ...objectInsertIf(rent?.estimate, {
      value: parseInt(rent?.estimate, 10),
    }),
  });
};

export const trackHomeownerLeadCreated = ({ action, address, rent, coverageType, region }) => {
  if (!action) {
    return;
  }

  const leadCreatedEvent = { event_category: 'Homeowner' };
  const datadogAndMixPanelLeadEvent = { type: ACTIONS_MAP[action] };
  const fullstoryLeadEvent = { type: ACTIONS_MAP[action] };

  if (address?.streetAddress) {
    datadogAndMixPanelLeadEvent.streetAddress = address.streetAddress;
    fullstoryLeadEvent.street_address_str = address.streetAddress;
  }

  if (address?.city) {
    leadCreatedEvent.event_label = `Rent|${address.city.replace(/ /g, '')}-${address.state.toUpperCase()}|${
      address.zipcode
    }`;

    datadogAndMixPanelLeadEvent.city = address.city;
    fullstoryLeadEvent.city_str = address.city;
  }

  if (rent?.estimate) {
    leadCreatedEvent.value = parseInt(rent.estimate, 10);

    datadogAndMixPanelLeadEvent.rentEstimate = rent?.estimate;
    fullstoryLeadEvent.rent_estimate_int = parseInt(rent.estimate, 10);
  }

  if (coverageType) {
    leadCreatedEvent.coverage_type = coverageType;
    datadogAndMixPanelLeadEvent.coverage_type = coverageType;
    fullstoryLeadEvent.coverage_type_str = coverageType;
  }

  if (region) {
    datadogAndMixPanelLeadEvent.region = region;
  }

  try {
    // TODO: Find a better way to structure this.
    if (window.mixpanel) {
      window.mixpanel.track('lead_created', datadogAndMixPanelLeadEvent);
    }

    datadogRum.addAction('lead_created', datadogAndMixPanelLeadEvent);
    FullStory.event('lead_created', fullstoryLeadEvent);

    gaEvent({
      category: 'Homeowner',
      action,
      ...objectInsertIf(address?.city, {
        label: `Rent|${address?.city.replace(/ /g, '')}-${address?.state.toUpperCase()}|${address?.zipcode}`,
      }),
      ...objectInsertIf(rent?.estimate, {
        value: parseInt(rent?.estimate, 10),
      }),
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: action,
    });
  } catch (e) {
    console.log(e);
  }
};
