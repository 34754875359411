import React from 'react';
import { useInView } from 'react-intersection-observer';

import { useSpring, animated } from 'react-spring';

const THRESHOLD = [0.25, 0.5, 0.75];

const FadeIn = (WrappedComponent) => ({ ...rest }) => {
  const [domRef, , entry] = useInView({
    threshold: THRESHOLD,
  });

  const getIntersectionRatio = () => {
    if (entry) {
      if (entry.intersectionRatio > 0.5) return 1;
      return entry.intersectionRatio;
    }
    return 0;
  };
  const springProps = useSpring({
    opacity: getIntersectionRatio(),
    from: { opacity: 0 },
  });

  return (
    <animated.div style={springProps} ref={domRef}>
      <WrappedComponent {...rest} />
    </animated.div>
  );
};

export default FadeIn;
