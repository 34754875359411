import { useState } from 'react';

export const useModal = (initialMode = false) => {
  const [isModalOpen, setModalState] = useState(initialMode);
  const setModalOpen = () => setModalState(true);
  const setModalClose = () => setModalState(false);
  const toggle = () => setModalState(!isModalOpen);

  return [isModalOpen, setModalOpen, setModalClose, toggle];
};
