import { useState, useEffect } from 'react';

function useDelayUnmount(isMounted, delayTime) {
  var _a = useState(false),
    shouldRender = _a[0],
    setShouldRender = _a[1];
  useEffect(function () {
    var timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = window.setTimeout(function () {
        return setShouldRender(false);
      }, delayTime);
    }
    return function () {
      return clearTimeout(timeoutId);
    };
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}

export { useDelayUnmount };
