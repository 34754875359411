import ListingPageUnit from 'models/listingPage/ListingPageUnit';

export default class FavoritePageUnit {
  constructor(data = []) {
    this.favoriteHomes = data.map(
      (item) =>
        new ListingPageUnit({
          isDemoUnit: item.listingInfo?.isDemoUnit,
          isAvailableForRent: item.listingInfo?.isAvailableForRent,
          address: item.basicInfo.address,
          listInfo: item.listingInfo,
          ...item,
        })
    );
  }
}
