import { getMediaTypeFromFile } from '../../../utils/file';
import { getApiActionTypesValues } from '../redux_utils';
import { uploadDocumentToS3 } from './uploadDocumentToS3';

export const fetchNotes = ({ sourceType, sourceIds, onlyOwner = false }) => ({
  promise: ({ client }) =>
    client.get('/notes', {
      params: {
        onlyOwner,
        sourceType,
        sourceIds,
      },
    }),
});

export const getNotesMediaUrl = (mediaType) => ({
  promise: ({ client }) => client.get(`/notes/media/upload-url/${mediaType}?rand=${Math.random()}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const uploadNoteMedia = (file) => async (dispatch) => {
  const mediaType = getMediaTypeFromFile(file);
  const response = await dispatch(getNotesMediaUrl(mediaType));
  const { headers, uploadUrl, uniqueId } = response[0];
  await uploadDocumentToS3(dispatch, file, headers, uploadUrl);
  return {
    name: file.name,
    uniqueId,
    mediaType,
  };
};

export const createMediaToNote = (noteId, media) => ({
  types: getApiActionTypesValues('CREATE_MEDIA_TO_NOTE'),
  promise: ({ client }) => client.post(`/notes/${noteId}/media`, [...media]),
  auth: true,
});

export const deleteMediaToNote = (noteId, mediaIds) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('DELETE_MEDIA_TO_NOTE'),
    promise: ({ client }) => client.delete(`/notes/${noteId}/media`, { data: mediaIds }),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const updateNoteMedia = (note, noteId) => async (dispatch) => {
  if (note.media.stagingMedia?.length) {
    const uploadPromises = [];
    note.media.stagingMedia.forEach(async (file) => {
      uploadPromises.push(dispatch(uploadNoteMedia(file)));
    });

    const attachments = await Promise.all(uploadPromises);

    dispatch(createMediaToNote(noteId, attachments));
  }

  if (note.media.deletedMedia?.length) {
    const deletedIds = note.media.deletedMedia.map((media) => media.id);
    await dispatch(deleteMediaToNote(noteId, deletedIds));
  }
};

export const _createNote = (noteModel) => ({
  types: getApiActionTypesValues('CREATE_NOTE'),
  promise: ({ client }) => client.post('/notes', noteModel),
});

export const _updateNote = (noteId, noteModel) => ({
  types: getApiActionTypesValues('UPDATE_NOTE'),
  promise: ({ client }) => client.put(`/notes/${noteId}`, noteModel),
});

export const createNote = (noteModel, sourceModel) => async (dispatch) => {
  const newNote = await dispatch(_createNote(noteModel));
  if (noteModel.media) {
    await dispatch(updateNoteMedia(noteModel, newNote.id));
  }
  if (sourceModel) {
    const notes = await dispatch(fetchNotes(sourceModel));

    return notes;
  }
};

export const updateNote = (id, noteModel, sourceModel) => async (dispatch) => {
  const note = await dispatch(_updateNote(id, noteModel));
  if (noteModel.media) {
    await dispatch(updateNoteMedia(noteModel, note.id));
  }
  if (sourceModel) {
    const notes = await dispatch(fetchNotes(sourceModel));

    return notes;
  }
};

export const _deleteNote = (noteId, dataVersion) => ({
  type: getApiActionTypesValues('DELETE_NOTE'),
  promise: ({ client }) => client.delete(`/notes/${noteId}`, { data: { auditInfo: dataVersion } }),
});

export const deleteNote = (noteId, dataVersion, sourceModel) => async (dispatch) => {
  await dispatch(_deleteNote(noteId, dataVersion));
  if (sourceModel) {
    const notes = await dispatch(fetchNotes(sourceModel));

    return notes;
  }
};
