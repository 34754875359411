/* eslint-disable react/prop-types */
import Field from 'components/Field/Field';
import { SelectableFormPanelFinalFormAdapter } from 'components/SelectableFormPanel/SelectableFormPanel';
import PropTypes from 'prop-types';

export function FeaturesComponent({
  name,
  featuresList,
  form,
  meta,
  newFieldComponent,
  ignoreError,
  disabled,
  ...rest
}) {
  const controlledProps = {};

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = meta.error;
    } else {
      controlledProps.error = meta.touched ? meta.error : {};
    }
  }

  return (
    <>
      {featuresList.map((feature, index) => {
        const error = controlledProps.error && controlledProps.error[feature.name];

        return (
          <div key={index}>
            <Field
              nestedField
              component={SelectableFormPanelFinalFormAdapter}
              {...feature}
              {...rest}
              panelName={feature.name}
              name={`${name}.${feature.name}`}
              form={form}
              error={!!error}
              disabled={disabled}
            />
          </div>
        );
      })}
    </>
  );
}

FeaturesComponent.propTypes = {
  featuresList: PropTypes.array,
};

FeaturesComponent.defaultProps = {
  featuresList: [],
};

export const FeaturesComponentFinalFormAdapter = ({ input, featuresList, ...rest }) => (
  <FeaturesComponent {...input} featuresList={featuresList} {...rest} />
);
