import { useState } from 'react';
import { Button, Text } from '@belong/ui';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import { StepFormLayout } from 'lease-signing-flow/components/step-form-layout/step-form-layout';
import { WearAndTearFeatureCarousel } from 'lease-signing-flow/components/wear-and-tear-feature-carousel/wear-and-tear-feature-carousel';
import { WearAndTearFeatureList } from 'lease-signing-flow/components/wear-and-tear-feature-list/wear-and-tear-feature-list';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
import { WearAndTearModal } from 'pages/PostInspectionFlow/steps/Improvements/Responsiblity/WearAndTearModal/WearAndTearModal';

const initialValues = {};

export const WearAndTearStep = ({ currentStep, onSubmit, steps }) => {
  const [isImprovementImportanceModalVisible, setIsImprovementImportanceModalVisible] = useState(false);

  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const handleWearAndTearClick = () => setIsImprovementImportanceModalVisible(true);

  const handleImprovementImportanceModalHide = () => setIsImprovementImportanceModalVisible(false);

  return (
    <>
      <StepFormLayout
        currentStep={currentStep}
        initialValues={initialValues}
        onSubmit={onSubmit}
        steps={steps}
        title={LEASE_SIGNING_FLOW_STRINGS['wearAndTear.title']}
        getForm={() => (
          <>
            {isMobile ? <WearAndTearFeatureCarousel /> : <WearAndTearFeatureList />}
            <div className="mt-sm sm:mt-2xl">
              <Text>{LEASE_SIGNING_FLOW_STRINGS['wearAndTear.description.pro']}</Text>
              <br />
              <Text
                as="span"
                dangerouslySetInnerHTML={{ __html: LEASE_SIGNING_FLOW_STRINGS['wearAndTear.description'] }}
              />
              <Button buttonType="primary" onClick={handleWearAndTearClick} variant="text">
                <div className="uppercase">{LEASE_SIGNING_FLOW_STRINGS['wearAndTear.label']}</div>
              </Button>
              <Text as="span">.</Text>
            </div>
          </>
        )}
      />
      {isImprovementImportanceModalVisible && (
        <WearAndTearModal
          initialValueIndex={0}
          onHide={handleImprovementImportanceModalHide}
          show={isImprovementImportanceModalVisible}
        />
      )}
    </>
  );
};
