import Countdown from 'react-countdown';
import { formatUtcDateTimeToTimeZone } from '@belong/common';
import { endOfDay } from 'date-fns';
import { padNumber } from 'utils/padNumber';

interface GGTLDateProps {
  date: string;
}

export const TodayTimeCountDown = () => {
  const endOfToday = endOfDay(new Date());

  return (
    <Countdown
      daysInHours
      key="gttl"
      date={endOfToday}
      renderer={({ hours, minutes, seconds }) => (
        <span className="font-semibold text-green mx-2xs">
          {`${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`}
        </span>
      )}
    />
  );
};

export const GTTLDate = ({ date }: GGTLDateProps) => {
  const parsedDate = formatUtcDateTimeToTimeZone({ dateTime: date, format: 'EEEE, LLL do' });

  return <span className="font-semibold text-green ml-2xs">{parsedDate}</span>;
};
