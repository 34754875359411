export const SCREENS = {
  REGISTER_SCREEN: 'register_screen',
  LOGIN_SCREEN: 'login_screen',
  FORGOT_PASSWORD_ENTER_EMAIL_SCREEN: 'forgot_password_screen',
  FORGOT_PASSWORD_EMAIL_SENT_CONFIRMATION_SCREEN: 'forgot_password_success_screen',
  FORGOT_PASSWORD_RESET_PASSWORD_SCREEN: 'reset_password_screen',
  FORGOT_PASSWORD_PASSWORD_UPDATE_CONFIRMATION_SCREEN: 'reset_password_success_screen',
  ENTER_EMAIL_TO_SAVE_SCREEN: 'enter_email_to_save_screen',
  ENTER_USER_TO_SAVE_SCREEN: 'enter_user_to_save_screen',
  EXTERNAL_LOGIN_ENTER_CONTACT_SCREEN: 'external_login_enter_contact_screen',
  SET_PASSWORD_AFTER_AGREEMENT: 'set_password_for_password_less_users',
  SET_PASSWORD_PASSWORDLESS: 'set_password_for_password_less_accounts',
};
