import axios from 'axios';
import config from 'config/config';

export default function apiClient() {
  const instance = axios.create({
    baseURL: config.apiUrl,
  });

  let token;
  let trackingId;

  instance.setJwtToken = (newToken) => {
    token = newToken;
  };

  instance.getJwtToken = () => token;

  instance.clearJwtToken = () => {
    token = null;
  };

  instance.setTrackingId = (newTrackingId) => {
    trackingId = newTrackingId;
  };

  instance.interceptors.request.use(
    (conf) => {
      if (token) {
        conf.headers.authorization = `Bearer ${token}`;
      }

      if (trackingId) {
        conf.headers.trackingId = trackingId;
      }

      /* Going to S3 */
      if (conf.url.includes('amazonaws.com')) {
        delete conf.headers.authorization;
      }

      conf.headers.sourceType = 'Consumer';

      return conf;
    },
    (error) => {
      // https://github.com/axios/axios/issues/383
      const isNetworkError = !error?.status;
      if (!isNetworkError) {
        return Promise.reject(error);
      }
    }
  );

  instance.interceptors.response.use((response) => response.data);

  return instance;
}
