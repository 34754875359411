import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PATHS } from '@belong/common';
import { Modal, Form, TextField, Text, Spinner } from '@belong/ui';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import { updateMaintenanceWithCancellation } from 'store/redux/maintenance/actions';
import styles from './MaintenanceCancelModal.module.css';

interface MaintenanceCancelModalProps {
  show: boolean;
  taskId: string;
  close: () => void;
  isCancelRecurrence?: boolean;
  isRenter?: boolean;
}

const initialValues = {
  resultReason: '',
};

type MaintenanceCancelValues = {
  resultReason: string;
};

const MaintenanceCancelModal: React.FC<MaintenanceCancelModalProps> = ({
  show,
  taskId,
  close,
  isCancelRecurrence = false,
  isRenter,
}) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async (values: MaintenanceCancelValues) => {
    const sendToBackend = { ...values, cancelRecurrence: !!isCancelRecurrence };

    try {
      setIsCancelling(true);
      await dispatch(updateMaintenanceWithCancellation(taskId, sendToBackend));
      history.push(isRenter ? PATHS.RESIDENTS_ACCOUNT_MAINTENANCE : PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE);
    } catch (e) {
      console.error(e);
    } finally {
      setIsCancelling(false);
    }
  };

  const validateCancelThisVisitOnly = (values: MaintenanceCancelValues) => {
    const errors: { resultReason?: string } = {};
    if (!values.resultReason) {
      errors.resultReason = 'Required';
    }
    return errors;
  };

  return (
    <>
      {isCancelling && <Spinner />}
      <Modal
        isOpen={show}
        name="cancel_pro_service"
        onDismiss={close}
        size="auto"
        overlayClassName="z-fullscreen"
        contentClassName="sm:w-[730px] md:w-[730px]"
      >
        <div className="flex flex-col px-2sm pt-3xl sm:pt-2xl sm:px-lg">
          <div className="max-w-[264px] sm:max-w-none mb-2xl self-center text-center">
            <Text variant="h2" fontWeight="semibold">
              Why do you want to cancel?
            </Text>
          </div>
          <Form initialValues={initialValues} onSubmit={onSubmit} validate={validateCancelThisVisitOnly}>
            {() => (
              <div className="flex flex-col">
                <div className="mb-2xl">
                  <TextField label="Cancellation Reason" name="resultReason" />
                </div>
                <div className="sm:hidden mb-sm">
                  <Form.SubmitButton>Submit</Form.SubmitButton>
                </div>
                <div className={styles.footer}>
                  <QuestionsOrConcernsModalFooter>
                    <div className="hidden sm:block">
                      <Form.SubmitButton>Submit</Form.SubmitButton>
                    </div>
                  </QuestionsOrConcernsModalFooter>
                </div>
              </div>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default MaintenanceCancelModal;
