import { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { GroupBalanceInfoModel } from 'api/models';
import { BillingInfoModel } from 'api/models.v2';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import Money from 'components/Money/Money';
import Spinner from 'components/Spinner/Spinner';
import { PAYMENT_SCREENS } from 'containercomponents/Modals/PaymentsModal/PaymentsModal';
import PaymentMethodSelectorContainer from 'containers/PaymentMethodSelectorContainer/PaymentMethodSelectorContainer';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import ModalLayoutErrorScreen from 'layouts/ModalLayout/ModalLayoutErrorScreen/ModalLayoutErrorScreen';
import ModalLayoutSuccessScreen from 'layouts/ModalLayout/ModalLayoutSuccessScreen/ModalLayoutSuccessScreen';
import PaymentMethod from 'models/common/PaymentMethod';
import { BillStatus } from 'models/enums/index';
import { EARNINGS_STRINGS } from 'pages/Accounts/Pages/YourPortfolio/Earnings/earnings.strings';
import { createBillPaymentWithPaymentId } from 'store/redux/accounts/actions';
import { selectCurrentBill } from 'store/redux/accounts/selectors';
import { selectPaymentMethodsFullObject } from 'store/redux/user/selectors';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import { PAYMENT_LIST_MODAL } from 'strings/payment-list-modal';
import { PAYMENT_LIST } from 'strings/payments/payments.strings';
import styles from './payment-bill-detail-modal.module.css';
import { PaymentBillDetail } from './payment-bill-detail/payment-bill-detail';
import { PaymentBillPaidDetail } from './payment-bill-paid-detail/payment-bill-paid-detail';

type PaymentBillDetailModalProps = {
  show: boolean;
  billId: string;
  isHomeowner?: boolean;
  home: string;
  onHide?: () => void;
  onManualPayment?: () => void;
  onSuccessHide?: () => void;
  handleFinancingModal: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowBalanceDetailsModal: (balance: GroupBalanceInfoModel, home: string, billingInfo?: BillingInfoModel) => void;
};

const cx = classNames.bind(styles);
const PLM = PAYMENT_LIST_MODAL.paymentmethod;

export function PaymentBillDetailModal({
  show,
  isHomeowner = false,
  billId,
  home,
  onHide,
  onManualPayment,
  onSuccessHide,
  handleFinancingModal,
  handleShowBalanceDetailsModal,
}: PaymentBillDetailModalProps) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [currentFeeToUse, setCurrentFeeToUse] = useState<PaymentMethod | undefined>();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [errors, setErrors] = useState(null);
  const bill = useSelector(selectCurrentBill);
  const finances = bill?.finances ?? [];
  const methods = useSelector(selectPaymentMethodsFullObject);
  const dispatch = useDispatch();

  const showPaymentPendingForm =
    !isHomeowner && bill.billInfo.remainingAmount > 0 && bill.billInfo.status !== BillStatus.Failed;
  const total = bill.billInfo.remainingAmount ? bill.billInfo.remainingAmount : bill.billInfo.total;

  function handleHide() {
    onHide?.();
  }

  async function handlePaymentWithDefaultMethod() {
    setLoading(true);

    try {
      await dispatch(
        createBillPaymentWithPaymentId(billId, {
          accountId: selectedPaymentMethodId,
          amount: bill.billInfo.remainingAmount,
        })
      );

      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setFailed(true);
      setErrors(error);
    }
  }

  function handlePaymentWithoutDefaultMethod() {
    onManualPayment?.();
  }

  function handleSelectPaymentMethod(id, paymentObject) {
    const currentFee = methods?.findObjectBasedOnAccountId(id) || paymentObject;
    const verified = methods?.findObjectBasedOnAccountId(id)?.isVerified || paymentObject?.isVerified;

    setSelectedPaymentMethodId(id);
    setCurrentFeeToUse(currentFee);
    setIsVerified(verified);
  }

  function renderErrorModal() {
    let errorModalProps = PAYMENT_LIST.MODAL_ERROR_PAYMENT;

    if (errors) {
      const error = errors[0];
      const { errorCode } = error;

      if (errorCode === 'ACH_INSUFFICIENT_FUNDS') {
        errorModalProps = FEEDBACK_STRINGS.payments_errors.ACH_INSUFFICIENT_FUNDS;
      }

      if (errorCode === 'UNVERIFIED_USER_LIMIT_EXCEEDS') {
        errorModalProps = FEEDBACK_STRINGS.payments_errors.UNVERIFIED_USER_LIMIT_EXCEEDS;
      }
    }

    return (
      <ModalV2 onHide={handleHide} noPadding show={show}>
        <ModalLayoutErrorScreen {...errorModalProps} onCtaClick={handleHide} />
      </ModalV2>
    );
  }

  useEffect(() => {
    if (methods) {
      setIsVerified(methods.rent?.Primary?.isVerified);

      if (methods.rent?.Primary?.accountId) {
        setSelectedPaymentMethodId(methods.rent?.Primary?.accountId);
        setCurrentFeeToUse(methods.findObjectBasedOnAccountId(methods.rent?.Primary?.accountId));
      }
    }
  }, [methods, setIsVerified, setSelectedPaymentMethodId, setCurrentFeeToUse]);

  function buildItems() {
    if (bill.items.length === 0) return {};
    const financingPlans = bill?.finances?.map((fin) => fin.id);
    const groupedItems = [];

    if (financingPlans) {
      financingPlans.forEach((plan) => {
        const filteredItems = bill.items.filter(
          //TODO: wait for backend to change financeId to integer, already asked Matias P
          // eslint-disable-next-line eqeqeq
          (item) => plan == item.financeId
        );
        groupedItems.push(filteredItems);
      });
    }

    const ungroupedItems = bill.items.filter((item) => typeof item.financeId === 'undefined');

    const financePlans = bill.items.filter((item) => item.sourceType === 'Finance');

    return {
      ungroupedItems,
      financePlans,
      groupedItems: groupedItems.flat(),
    };
  }

  return (
    <>
      {
        // Should Refactor into a function
        !success && !failed && (
          <ModalV2 onHide={handleHide} noPadding show={show} overflow showSpinner={loading}>
            {bill.billInfo.status ? (
              <ModalLayout fixedBottom>
                <ModalLayout.Content>
                  <div className="text-center">
                    <ModalLayout.Title label={bill.billInfo.description} />
                    <Space value={SPACE_TYPES.XS} />
                    <h1>
                      <Money cash={total} />
                    </h1>
                  </div>

                  {bill.billInfo.postedOn && bill.billInfo.status === BillStatus.Failed ? (
                    <div className="flex items-center justify-center mt-sm font-semibold">
                      {EARNINGS_STRINGS['processed.label']}&nbsp;
                      <BDate time={bill.billInfo.postedOn} formatType={DATE_TYPES.DAY_MONTH_YEAR} />
                    </div>
                  ) : null}

                  <ModalLayout.Section>
                    <PaymentBillDetail
                      items={buildItems()}
                      task={bill}
                      billId={bill.billInfo.displayId}
                      isHomeowner={isHomeowner}
                      currentFeeToUse={currentFeeToUse}
                      finances={finances}
                      handleFinancingModal={handleFinancingModal}
                      handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
                      home={home}
                    />

                    {!(isHomeowner || bill.billInfo.remainingAmount <= 0) && (
                      <div>
                        <ModalLayout.SectionTitle label={PLM.titlesuccess} />
                        <PaymentMethodSelectorContainer
                          needsPaymentMethod
                          selectedPaymentMethodId={selectedPaymentMethodId}
                          onSelectPaymentMethod={handleSelectPaymentMethod}
                          paymentModalProps={{
                            screen: PAYMENT_SCREENS.PAYMENT_SELECTION,
                            errorScreenProps: {
                              title: FEEDBACK_STRINGS.set_payment_method.error.title,
                              ctaLabel: FEEDBACK_STRINGS.set_payment_method.error.ctaLabel,
                            },
                            successScreenProps: {
                              title: FEEDBACK_STRINGS.set_payment_method.success.title,
                              ctaLabel: FEEDBACK_STRINGS.set_payment_method.success.ctaLabel,
                            },
                          }}
                        />
                      </div>
                    )}

                    {bill.transfers.length > 0 && (
                      <>
                        <Space value={SPACE_TYPES.XL} />
                        <PaymentBillPaidDetail billInfo={bill.billInfo} transfers={bill.transfers} />
                      </>
                    )}

                    {showPaymentPendingForm && (
                      <Form
                        onSubmit={
                          selectedPaymentMethodId ? handlePaymentWithDefaultMethod : handlePaymentWithoutDefaultMethod
                        }
                        render={({ handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <ModalLayout.BottomBar
                              ctaProps={{
                                children: (
                                  <div>
                                    {' '}
                                    {PAYMENT_LIST.MODAL_PAYMENT_PENDING.ctaLabel}&nbsp;
                                    <Money
                                      cash={
                                        bill.getFeeFromItem(currentFeeToUse || '')?.remainingAmountWithFee ||
                                        bill.billInfo.remainingAmount
                                      }
                                    />
                                  </div>
                                ),
                                disabled: !isVerified,
                              }}
                              fixedBottom
                            />
                          </form>
                        )}
                      />
                    )}
                    {!isHomeowner && bill.billInfo.status === BillStatus.Failed && (
                      <ModalLayout.BottomBar noButton fixedBottom />
                    )}
                    {(isHomeowner || bill.billInfo.remainingAmount <= 0) && (
                      <ModalLayout.BottomBar noButton fixedBottom />
                    )}
                  </ModalLayout.Section>
                </ModalLayout.Content>
              </ModalLayout>
            ) : (
              <div className={cx('height')}>
                <Spinner />
              </div>
            )}
          </ModalV2>
        )
      }
      {success && (
        <ModalV2 onHide={onSuccessHide} noPadding show={show} showSpinner={loading}>
          <ModalLayoutSuccessScreen
            title={PAYMENT_LIST.MODAL_SUCCESS_PAYMENT.title}
            subtitle={PAYMENT_LIST.MODAL_SUCCESS_PAYMENT.subtitle}
            ctaLabel={PAYMENT_LIST.MODAL_SUCCESS_PAYMENT.ctaLabel}
            onCtaClick={onSuccessHide}
          />
        </ModalV2>
      )}
      {failed && renderErrorModal()}
    </>
  );
}
