export const ASSETS_LOCATION = {
  confetti: {
    DESKTOP: 'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/post_inspetion_confetti.json',
    MOBILE: 'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/post_inspetion_confetti_mobile.json',
  },
  agreement_plan_heart: {
    DESKTOP: 'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/agreement_plan_heart.json',
    MOBILE: 'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/agreement_plan_heart_mobile.json',
  },
  ho_calculator: 'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/ho_calculator.json',
  success_page_animation:
    'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/success_page_animation.json.gz',
  pricing_animation_adjustment:
    'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/pricing_animation_adjustment.json',
  pricing_animation_01:
    'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/pricing_animation_01.json.gz',
  pricing_animation_02:
    'https://belong-web-assets.s3-us-west-2.amazonaws.com/animation_assets/pricing_animation_02.json.gz',
  lock_unlock: 'https://belong-web-assets.s3.us-west-2.amazonaws.com/animation_assets/lock_unlock_animation.json',
};
