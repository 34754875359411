import { EmployeeAssignmentDetailModel } from 'api/models';
import { getAssignedFormattedEmployee } from 'pages/PostInspectionFlow/utils';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import {
  FeatureOwner,
  MaintenanceBundleType,
  MaintenanceResponsibility,
  ConsentStatus,
} from '../../../../models/enums';

export const BUNDLE_CONFIG = {
  CodeCompliance: {
    displayName: 'Code Compliance',
  },
  AppliancesAndEquipment: {
    displayName: 'Home Functionality',
  },
  VacancyReduction: {
    displayName: 'Vacancy Reduction',
  },
  Habitability: {
    displayName: 'Habitability & Comfort',
  },
  SafetyAndSecurity: {
    displayName: 'Safety & Security',
  },
  Preventative: {
    displayName: 'Preventative',
  },
  Recommended: {
    displayName: 'Recommended',
  },
  ProjectManagement: {
    displayName: 'Project Management',
  },
};

export const bundleTypeDescription = {
  [MaintenanceBundleType.CodeCompliance]:
    'Satisfy all requirements to legally rent your home in your state. Doing so helps you avoid fees, lawsuits, and other\u00a0complications.',
  [MaintenanceBundleType.AppliancesAndEquipment]:
    'Residents expect all major appliances and features to operate on move-in. Let’s meet those expectations! And if they work on move-in, we can deduct from the security deposit to repair anything not functioning on\u00a0move-out.',
  [MaintenanceBundleType.VacancyReduction]:
    'Based on our experience with over 1,000 homes, these improvements attract residents and get them to stay longer. The secret is in the details. Small things that make a home more aesthetic and comfortable help it stand\u00a0out.',
  [MaintenanceBundleType.SafetyAndSecurity]:
    'By providing a safe and secure home for your residents, you build trust with\u00a0them.',
  [MaintenanceBundleType.Preventative]:
    'Preventative maintenance saves you money in the long run. Let’s avoid headaches by fixing small issues now before they become major\u00a0repairs.',
};

export const getFlaggedItems = (items, role) =>
  items.filter((item) => item.maintenancePayments.find((payment) => payment.paidBy === role)?.flagged);

export const getCalculatedBundles = (bundles, homeListing) => {
  const { spaces = [] } = homeListing;

  return bundles.map((bundle) => {
    return {
      ...bundle,
      items: bundle.items.map((item) => {
        const { maintenance } = item || {};
        let roomOrSpace;
        if (maintenance?.ownerType === FeatureOwner.Space) {
          roomOrSpace = spaces.find((space) => space.id === maintenance.ownerId);
        }

        const { type } = roomOrSpace || { type: 'Around The Home' };
        return {
          ...item,
          roomOrSpace: type,
        };
      }),
    };
  });
};

export const getPayments = (payments) => {
  return {
    homeowner: payments?.find((x) => x.paidBy === MaintenanceResponsibility.Homeowner),
    resident: payments?.find((x) => x.paidBy === MaintenanceResponsibility.Resident),
    belong: payments?.find((x) => x.paidBy === MaintenanceResponsibility.Belong),
  };
};

export const getPaymentBreakdown = (bundle) => {
  return getPayments(bundle.paymentResponsibilityBreakdown);
};

export const isItemResidentResponsible = (item) => {
  const { maintenancePayments } = item;
  const homeOwnerPayment = maintenancePayments.find(
    (breakdown) => breakdown.paidBy === MaintenanceResponsibility.Homeowner
  );

  return !homeOwnerPayment || homeOwnerPayment?.percentage === 0;
};

export const isTBD = (item) => {
  return item.isTBD;
};

export const hasHomeownerApproved = (payments) => {
  const breakdown = getPayments(payments);
  return breakdown.homeowner?.consentStatus === ConsentStatus.Approved;
};

export const getImprovementsSpecialist = (model) => {
  const employee = Object.values(model?.employeeAssignments)?.find(
    (employeeAssignmentItem) => employeeAssignmentItem.employeeAssignment.assignmentType === 'ImprovementsSpecialist'
  );

  return {
    ...employee.employee,
    ...employee.user,
    profileImage: employee.user.profileImageUrl,
    bannerImage: employee.employee.bannerImageUrl,
    jobTitle: 'Home Improvements Specialist',
  };
};

export const getImprovementsAssignedEmployee = ({
  employeeAssignments,
  isMoveOut,
}: {
  employeeAssignments: EmployeeAssignmentDetailModel[];
  isMoveOut: boolean;
}) => {
  const assignedEmployee = getAssignedFormattedEmployee({
    employeeAssignments,
    assignmentTypes: [...(isMoveOut ? ['HomeOccupancySpecialist' as const] : []), 'ImprovementsSpecialist'],
  });

  return {
    ...assignedEmployee,
    jobTitle:
      assignedEmployee.assignment.assignmentType === 'HomeOccupancySpecialist'
        ? EMPLOYEE_TITLES.HomeOccupancySpecialist
        : EMPLOYEE_TITLES.HomeImprovementsSpecialist,
  };
};
