import React, { useRef, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { useWindowSize } from 'hooks/useWindowSize';
import { findLastIndex } from 'lodash-es';
import styles from './ProgressBar.module.css';

export type ProgressBarItems = {
  label: string;
  to: string;
  active?: boolean;
  uncompleted?: boolean;
  disabled?: boolean;
};

type ProgressBarProps = {
  items: ProgressBarItems[];
  onlyActiveLabel?: boolean;
};

const cx = classNames.bind(styles);

export function ProgressBar({ items, onlyActiveLabel }: ProgressBarProps): JSX.Element {
  const { width } = useWindowSize();
  const [breakpoints, setBreakPoints] = useState([]);
  const [totalRefCount, setTotalRefCount] = useState(0);
  const widthRef = useRef(null);
  const refs = useRef<any>(Array.from({ length: items.length }, () => React.createRef()));

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const currentProgress = findLastIndex(items, (e) => {
    return e.active;
  });

  function getWidth(index) {
    if (isMobile) {
      return (100 / items.length) * index + 100 / (items.length * 2);
    }

    const number = (widthRef.current?.offsetWidth - totalRefCount) / items.length;

    return index ? breakpoints[index - 1] + number * (index + 0.5) : number / 2;
  }

  function getTranslatedWidth(index) {
    return isMobile ? `translate(-50%, -5px)` : `translate(${refs.current?.[index]?.current?.offsetWidth / 2}px, -5px)`;
  }

  function getLeft(index: number, isLabel?: boolean) {
    if (!isLabel) return isMobile ? `${getWidth(index)}%` : `calc(${getWidth(index)}px - 2px)`;

    if (isMobile) {
      if (index === items.length - 1) {
        return `calc(${getWidth(index)}% - ${
          refs.current?.[currentProgress].current?.offsetWidth / 2 -
          refs.current?.[currentProgress].current?.offsetWidth / 4
        }px)`;
      }

      return `${getWidth(index)}%`;
    }
    return `calc(${getWidth(index)}px - 2px)`;
  }

  function getFilledWidth() {
    return isMobile
      ? `${getWidth(currentProgress)}%`
      : `${getWidth(currentProgress) + refs.current?.[currentProgress]?.current?.offsetWidth / 2}px`;
  }

  function getWordWrapperWidth() {
    return isMobile ? 'translate(-50%, -38px)' : 'translate(0, -38px)';
  }

  useLayoutEffect(() => {
    let totalWidth = 0;
    const array = [];

    if (refs.current?.[0]?.current?.offsetWidth) {
      refs.current?.forEach((ref) => {
        const number = ref.current?.offsetWidth;

        array.push((totalWidth += number));
      });
    }

    setBreakPoints(array);
    setTotalRefCount(totalWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <div className={cx('progress-bar-wrapper')}>
      <div className={cx('progress-bar')} ref={widthRef}>
        <div
          className={cx('fill-to-progress')}
          style={{
            width: getFilledWidth(),
          }}
        />
        {items.map((item, index) => {
          return (
            <Link className={item.disabled && 'pointer-events-none'} to={item.to} key={`${item.label}-${index}`}>
              <div
                className={cx('bullet', { currentProgress, active: item.active })}
                style={{
                  left: getLeft(index),
                  transform: getTranslatedWidth(index),
                }}
              />
              <div
                ref={refs.current[index]}
                style={{
                  transform: getWordWrapperWidth(),
                  left: getLeft(index, true),
                }}
                className={cx('word-wrapper', { active: item.active, uncompleted: item.uncompleted })}
              >
                {item.label.split('<br />').map((label) => (
                  <div className={cx({ hiddenLabel: onlyActiveLabel && !item.active })} key={label}>
                    {onlyActiveLabel && !item.active ? '.' : label}
                  </div>
                ))}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default ProgressBar;
