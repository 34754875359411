import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Space.module.css';
import { SPACE_TYPES as TYPES } from './spaceTypes';

export const SPACE_TYPES = TYPES;

const cx = classNames.bind(styles);
class Space extends Component {
  static propTypes = {
    value: PropTypes.oneOf(Object.values(TYPES)),
    mobileValue: PropTypes.oneOf(Object.values(TYPES)),
  };

  static defaultProps = {
    value: 'sm',
    mobileValue: null,
  };

  render() {
    const { value, mobileValue } = this.props;

    if (mobileValue && !value) {
      return <div className={cx(`mobile-spacing-${mobileValue}`)} />;
    }

    if (mobileValue) {
      return <div className={cx(`spacing-${value}`, `mobile-spacing-${mobileValue}`)} />;
    }

    return <div className={cx(`spacing-${value}`)} />;
  }
}

Space.XXS = (props) => <Space value={SPACE_TYPES.XXS} {...props} />;
Space.XS = (props) => <Space value={SPACE_TYPES.XS} {...props} />;
Space.SM = (props) => <Space value={SPACE_TYPES.SM} {...props} />;
Space.MD = (props) => <Space value={SPACE_TYPES.MD} {...props} />;
Space.LG = (props) => <Space value={SPACE_TYPES.LG} {...props} />;
Space.XL = (props) => <Space value={SPACE_TYPES.XL} {...props} />;
Space.XXL = (props) => <Space value={SPACE_TYPES.XXL} {...props} />;
Space.XXXL = (props) => <Space value={SPACE_TYPES.XXXL} {...props} />;
Space.XXXXL = (props) => <Space value={SPACE_TYPES.XXXXL} {...props} />;
Space.XXXXXL = (props) => <Space value={SPACE_TYPES.XXXXXL} {...props} />;
Space.XXXXXXL = (props) => <Space value={SPACE_TYPES.XXXXXXL} {...props} />;
Space.XXXXXXXL = (props) => <Space value={SPACE_TYPES.XXXXXXXL} {...props} />;
Space.SPACE_TYPES = SPACE_TYPES;

export default Space;
