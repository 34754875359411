import React from 'react';

import BulletList from 'components/BulletList/BulletList';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Thumbnails from 'components/Thumbnails/Thumbnails';
import Space from 'corecomponents/Space/Space';
import PropTypes from 'prop-types';

import { BASE_PATHS } from '../../routes/paths';

function AccountUnitDetail({ unitId, infoList, thumbnailsItems, doNotShowListing }) {
  return (
    <div>
      <BulletList flexWrap listItems={infoList} />
      {!!thumbnailsItems?.length && (
        // Empty className To find easier in the future.
        <div className="accountUnitDetail">
          <Space />
          <Thumbnails unitId={doNotShowListing ? '' : unitId} items={thumbnailsItems} />
          <Space />
        </div>
      )}
      <div className="flex justify-end">
        {!doNotShowListing && (
          <IconButton
            align={ALIGN_TYPES.LEFT}
            label="VIEW YOUR HOME'S LISTING"
            icon={GENERAL_ICONS.FRONT_ARROW}
            to={`${BASE_PATHS.HOME}/${unitId}`}
          />
        )}
      </div>
    </div>
  );
}

AccountUnitDetail.propTypes = {
  unitId: PropTypes.string.isRequired,
  infoList: PropTypes.arrayOf(PropTypes.node).isRequired,
  thumbnailsItems: PropTypes.arrayOf(PropTypes.object),
  doNotShowListing: PropTypes.bool,
};

AccountUnitDetail.defaultProps = {
  doNotShowListing: false,
  thumbnailsItems: [],
};

export default AccountUnitDetail;
