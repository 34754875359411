import { Text } from '@belong/ui';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getHomeListPrice } from 'utils/mapUtils';
import css from './ComparableMarker.module.css';

const ComparableMarker = ({ isHover, isActive, home }) => (
  <div
    className={clsx(
      'absolute flex flex-col items-center rounded-small cursor-pointer -translate-x-1/2',
      css.markerMap,
      {
        'z-10 bg-green': isHover || isActive,
        [css.hover]: isHover || isActive,
        'bg-navy': !isHover && !isActive,
        'bg-red': !isHover && home.overpriced,
        [css.overpriced]: !isHover && home.overpriced,
      }
    )}
  >
    {home.overpriced && (
      <Text variant="p2" fontWeight="semibold" className="text-white">
        Overpriced
      </Text>
    )}
    <Text fontWeight="semibold" className="text-white">
      {getHomeListPrice(home.rentAmount)}
    </Text>
  </div>
);

ComparableMarker.propTypes = {
  isHover: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  home: PropTypes.object.isRequired,
};

export default ComparableMarker;
