// React imports
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
// Component imports
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskPhoneNumber, unmaskPhoneNumber } from 'components/Input/masks';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
// String, Constant and Util imports
import { EXTERNAL_LOGIN_ENTER_CONTACT } from 'strings/external-login-enter-contact';
import { phoneValidation, required, composeValidators } from 'utils/validation';
import { BUTTON_SIZES } from '../../../../components/Button/buttonTypes';
// SCSS imports
import styles from './ExternalLoginEnterContact.module.css';

const cx = classNames.bind(styles);

export default class ExternalLoginEnterContact extends Component {
  static title = EXTERNAL_LOGIN_ENTER_CONTACT.title;

  static closeButton = true;

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  handleSubmit = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  render() {
    return (
      <div className={cx('external-login-enter-contact-wrapper')}>
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <div className={cx('connected-to-fb-text-wrapper')}>
                    <GeneralIcon icon={GENERAL_ICONS.RADIO_SELECTED} />
                    <span className={cx('connected-to-fb-text')}>{EXTERNAL_LOGIN_ENTER_CONTACT.connected_to_fb}</span>
                  </div>
                </Col>
              </Row>
              <div className={cx('form-wrapper')}>
                <Row>
                  <Col md={12}>
                    <Field
                      name="phone"
                      component={InputFinalFormAdapter}
                      placeholder={EXTERNAL_LOGIN_ENTER_CONTACT.phone_input}
                      validate={composeValidators(required, phoneValidation)}
                      mask={maskPhoneNumber}
                      unmask={unmaskPhoneNumber}
                    />
                  </Col>
                </Row>
                <div className={cx('save-button')}>
                  <Button
                    type="submit"
                    label={EXTERNAL_LOGIN_ENTER_CONTACT.save_button}
                    size={BUTTON_SIZES.FLUID}
                    disabled={invalid}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
