import { ACTIONS } from './constants';

export const RESIDENT_ONBOARDING_REDUCER = 'residentOnboarding';

const initialState = {
  residentPersonalInfo: {},
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_RESIDENT_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        residentPersonalInfo: { ...action.result },
      };
    default:
      return state;
  }
}
