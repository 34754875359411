import React, { useState, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Text, Money, Button } from '@belong/ui';
import Checkbox from 'components/Checkbox/Checkbox';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import Slider from 'components/v2/Slider/Slider';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { simulateSplitPayments, createFinancePlan } from 'store/redux/accounts/actions';
import { selectSimulatePayments, selectBalances } from 'store/redux/accounts/selectors';
import { selectSettings } from 'store/redux/settings';
import { DisclosureModal } from '../disclosure-modal/disclosure-modal';

const SLIDER_STEP = 1;

function SplitPaymentModal({
  loading,
  onHide,
  balance,
  simulateSplitPayments: simulateSplitPaymentsAction,
  simulation,
  createFinancePlan: createFinancePlanAction,
  balances,
  items,
  handleShowFinanceSuccessModal,
  billingInfo,
  ...rest
}) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [simulatorLoaded, setSimulatorLoaded] = useState(false);
  const [monthlyInstallments, setMonthlyInstallments] = useState(2);
  const [sliderTimeout, setSliderTimeout] = useState(null);
  const [selectedItems, setSelectedItems] = useState(() =>
    items.reduce((obj, item) => Object.assign(obj, { [item.sourceKey]: { ...item, selected: true } }), {})
  );
  const [allSelected, setAllSelected] = useState(true);
  const [showDisclosure, setShowDisclosure] = useState(false);

  const settings = useSelector(selectSettings);
  const interestRateValue = settings?.commerceSettings?.splitItSettings?.interestRateValue;

  const balancesIds = useMemo(
    () =>
      Object.keys(selectedItems)
        .filter((key) => {
          return selectedItems[key].selected;
        })
        .map((key) => {
          const filteredBalances =
            selectedItems[key].sourceType === 'Balance' ? [selectedItems[key]] : selectedItems[key]?.balances || [];
          return [...filteredBalances];
        })
        .flat()
        .map((bal) => bal.sourceKey),
    [selectedItems]
  );

  function handleHide() {
    setSimulatorLoaded(false);
    setSelectedItems({});
    if (onHide) onHide();
  }

  function handleToggleAll(value) {
    setAllSelected(value);
    setSelectedItems((prevState) => {
      const newState = { ...prevState };
      Object.keys(prevState).forEach((key) => {
        newState[key].selected = value;
      });

      return newState;
    });
  }

  function handleToggle(value, item) {
    setSelectedItems((prevState) => {
      return {
        ...prevState,
        ...(value ? { [item.sourceKey]: { ...item, selected: true } } : { [item.sourceKey]: { selected: false } }),
      };
    });
  }

  function getTotalAmountSelected() {
    let total = 0;
    Object.values(selectedItems).forEach((item) => {
      if (item.selected) total += item.netBalance;
    });
    return Math.abs(total);
  }

  function getMonthlyTotal() {
    if (simulation.finance) {
      return simulation.finance.monthlyAmount + simulation.finance.monthlyInterestAmount;
    }

    return '';
  }

  function getTotalSelected() {
    const selected = Object.values(selectedItems).filter((item) => {
      return item.selected;
    });

    if (selected) return selected.length;

    return 0;
  }

  async function handleSimulation(installments) {
    const buildSimulation = {
      installments,
      startDate: billingInfo?.billGenerationOn,
      balanceUniqueIds: balancesIds,
    };

    if (buildSimulation.balanceUniqueIds.length === 0) {
      setIsLoading(false);
      setSimulatorLoaded(true);
      return;
    }
    setError(null);
    try {
      await simulateSplitPaymentsAction(buildSimulation);
      setIsLoading(false);
    } catch (err) {
      setError(err[0].message);
      setIsLoading(false);
      console.error(err);
    }
  }

  async function handleCreateFinancePlan() {
    setIsLoading(true);
    try {
      const financePlan = {
        installments: monthlyInstallments,
        startDate: billingInfo.billGenerationOn,
        balanceUniqueIds: balancesIds,
      };
      setError(null);
      const response = await createFinancePlanAction(financePlan);
      if (response.finance) {
        onHide();
        if (handleShowFinanceSuccessModal) {
          handleShowFinanceSuccessModal();
        }
      }
      setIsLoading(false);
    } catch (err) {
      setError(err[0].message);
      setIsLoading(false);
      console.error(err);
    }
  }

  function handleSliderchange(value) {
    setMonthlyInstallments(value);

    if (sliderTimeout) clearTimeout(sliderTimeout);
    setSliderTimeout(
      setTimeout(async () => {
        if (getTotalSelected() === 0) return;
        setIsLoading(true);
        handleSimulation(value);
      }, 300)
    );
  }

  // const hideDisclosure = useCallback(() => setShowDisclosure(false), [])

  useEffect(() => () => clearTimeout(sliderTimeout), [sliderTimeout]);

  useEffect(() => {
    const simulatePayment = async () => {
      const ids = Object.keys(selectedItems)
        .map((key) => {
          return selectedItems[key].sourceType === 'Balance' ? selectedItems[key] : [...selectedItems[key].balances];
        })
        .flat()
        .map((bal) => bal.sourceKey);

      const buildSimulation = {
        installments: monthlyInstallments,
        startDate: billingInfo.billGenerationOn,
        balanceUniqueIds: ids,
      };

      if (buildSimulation.balanceUniqueIds.length === 0) {
        setIsLoading(false);
        setSimulatorLoaded(true);
        return;
      }

      try {
        await simulateSplitPaymentsAction(buildSimulation);
        setSimulatorLoaded(true);
        setIsLoading(false);
      } catch (err) {
        setSimulatorLoaded(true);
        setIsLoading(false);
        console.error(err);
      }
    };
    if (rest.show && selectedItems && !simulatorLoaded && !isLoading) {
      simulatePayment();
      setIsLoading(true);
    }
  }, [rest, selectedItems]);

  useEffect(() => {
    if (simulatorLoaded) {
      handleSimulation(monthlyInstallments);
      if (getTotalSelected() === Object.keys(selectedItems).length) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }

      if (getTotalSelected() > 0) handleSimulation(monthlyInstallments);
    }
  }, [selectedItems, simulatorLoaded]);

  return (
    <>
      <ModalV2 {...rest} noPadding showSpinner={loading || isLoading} onHide={handleHide} className="">
        <ModalLayout fixedBottom>
          <ModalLayout.Content className="p-0">
            <ModalLayout.Section>
              <ModalLayout.Title className="mb-0">
                Split Payments Into <br /> Monthly Installments
              </ModalLayout.Title>
            </ModalLayout.Section>
            <ModalLayout.Section className="mt-0">
              <div className="flex p-xs pt-xl md:p-xl ">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th width="60%">
                        <div className="flex">
                          <Checkbox
                            alignCenter
                            value={allSelected}
                            useOnChangeFormToHandle
                            label="All Items"
                            onSelect={(val) => handleToggleAll(val)}
                            className="text-h3-sm md:text-h3-xl"
                          />
                        </div>
                      </th>
                      <th className="flex justify-end ">
                        <Text className="text-body self-end absolute md:static top-[105px]" fontWeight="semibold">
                          Remaining Balance ($)
                        </Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="h-sm" colSpan="2" />
                    {items.map((item) => {
                      return (
                        <tr key={item.sourceKey} className={item.selected ? 'selected' : ''}>
                          <th scope="row" width="60%">
                            <Checkbox
                              alignCenter
                              value={selectedItems[item.sourceKey]?.selected}
                              useOnChangeFormToHandle
                              label={item.description}
                              onSelect={(val) => handleToggle(val, item)}
                            />
                          </th>
                          <td className="flex justify-end">
                            <Money
                              format="DOLLARS_WITH_CENTS"
                              variant="body"
                              fontWeight="semibold"
                              value={item.netBalance}
                            />
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="h-sm" colSpan="2" />
                    <tr>
                      <th className="text-left">
                        <Text className="text-h3-sm md:text-h3-xl ml-2xs" fontWeight="semibold">
                          {getTotalSelected()} Selected
                        </Text>
                      </th>
                      <td className="flex justify-end">
                        <Money
                          format="DOLLARS_WITH_CENTS"
                          className="text-h3-sm md:text-h3-xl"
                          fontWeight="semibold"
                          value={getTotalAmountSelected()}
                        />{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-xl md:mt-0 border border-solid border-green w-full bg-light-green/5 min-h-[164px] px-xs pt-lg md:px-2xl py-lg">
                <div>
                  <div className="flex flex-col items-center text-center mb-xs">
                    <div className="flex mb-xs">
                      <Money
                        className=""
                        format="DOLLARS_WITH_CENTS"
                        variant="h3"
                        fontWeight="semibold"
                        value={getTotalSelected() === 0 ? 0 : getMonthlyTotal()}
                      />
                      <Text variant="body" fontWeight="semibold" className="mr-xs relative top-[4px] md:top-[7px]">
                        /mo.
                      </Text>
                    </div>
                    <div className="flex">
                      <Text variant="p1" className="mr-xs text-center">
                        Including {interestRateValue}% APR ($
                        {getTotalSelected() === 0
                          ? 0
                          : simulation?.finance?.monthlyInterestAmount.toLocaleString('en', {
                              useGrouping: false,
                              minimumFractionDigits: 2,
                            })}
                        /mo.) • No Additional Fees <br />
                        Due On The 1st Of Each Month{' '}
                      </Text>
                    </div>
                  </div>
                  <Slider
                    max={12}
                    min={2}
                    onChange={handleSliderchange}
                    step={SLIDER_STEP}
                    value={monthlyInstallments}
                    renderBottom={(val) => (
                      <div className="flex md:hidden justify-center mb-sm mt-sm">
                        <Text variant="p" fontWeight="semibold">
                          {val} Monthly Installments
                        </Text>
                      </div>
                    )}
                    renderTop={(val) => (
                      <>
                        <div className="hidden md:flex  justify-center mb-sm mt-sm">
                          <Text variant="p" fontWeight="semibold">
                            {val} Monthly Installments
                          </Text>
                        </div>
                        <div className="flex md:hidden mb-xs">
                          <div>
                            <Text variant="p1">2 Months</Text>
                          </div>
                          <div className="ml-auto">
                            <Text variant="p1">12 Months</Text>
                          </div>
                        </div>
                      </>
                    )}
                    renderLeft={() => (
                      <div className="mr-xs hidden md:flex">
                        <Text variant="p1">2 Months</Text>
                      </div>
                    )}
                    renderRight={() => (
                      <div className="ml-xs hidden md:flex">
                        <Text variant="p1">12 Months</Text>
                      </div>
                    )}
                  />
                </div>
              </div>
            </ModalLayout.Section>
            <ModalLayout.BottomBar
              className="w-full mt-xl flex-col md:m-0 md:flex-row"
              ctaProps={{
                onClick: () => {
                  handleCreateFinancePlan();
                },
                children: <div>Split It!</div>,
                disabled: getTotalSelected() === 0,
              }}
              noMarginOrPaddingOnMobileForButton
              leftSideNode={
                <div className="mb-xl md:mb-0">
                  <Text className="mr-xs">By clicking ‘Split It’, I agree to the</Text>
                  <Button onClick={() => setShowDisclosure(true)} variant="text">
                    TRUTH IN LENDING DISCLOSURES
                  </Button>
                </div>
              }
              fixedBottom
            />
            {error && (
              <div className="flex pl-sm">
                <Text variant="body" className="text-red ml-auto mr-[16px] relative top-[-16px]">
                  {error}
                </Text>
              </div>
            )}
          </ModalLayout.Content>
        </ModalLayout>
      </ModalV2>
      {showDisclosure && (
        <DisclosureModal
          show={showDisclosure}
          setShow={setShowDisclosure}
          installments={monthlyInstallments}
          balanceIds={balancesIds}
        />
      )}
    </>
  );
}

SplitPaymentModal.propTypes = {
  loading: PropTypes.bool,
  onHide: PropTypes.func,
  balance: PropTypes.object,
  balances: PropTypes.object,
  simulateSplitPayments: PropTypes.func,
  simulation: PropTypes.object,
  items: PropTypes.array,
  handleShowFinanceSuccessModal: PropTypes.func,
  createFinancePlan: PropTypes.func,
  billingInfo: PropTypes.object,
};

function mapStateProps(state) {
  return {
    balances: selectBalances(state),
    simulation: selectSimulatePayments(state),
  };
}
const mapDispatchToProps = {
  simulateSplitPayments,
  createFinancePlan,
};

export default connect(mapStateProps, mapDispatchToProps)(SplitPaymentModal);
