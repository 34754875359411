export default class AccountPaymentInfo {
  constructor(data = {}) {
    this.paymentId = data.paymentId;
    this.createdOn = data.createdOn;
    this.amount = data.amount;
    this.displayId = data.displayId;
    this.postedOn = data.postedOn;
    this.status = data.status;
  }
}
