export const YOUR_PROFILE = {
  title: 'My Profile',
  subtitle: 'At Belong, we believe in the importance of fostering human connection. Let other Members get to know you!',
  firstname: 'First Name',
  lastname: 'Last Name',
  hometown: 'Hometown',
  tellusaboutyourself: 'Tell us about yourself',
  email: 'Email (Private)',
  phone: 'Phone (Private)',
  success: 'Successfully Updated',
  supportSuccess: "We received your message. We'll get you back as soon as possible.",
};
