import { MediaType } from '../models/enums';

export const getFileType = (fileType) => {
  if (fileType.includes('video/')) {
    return MediaType.Video;
  }

  if (fileType.includes('image/')) {
    return MediaType.Image;
  }

  return MediaType.PDF;
};

export const getMediaTypeFromFile = (file) => getFileType(file.type);

// Used with the newest component
export const getMediaTypeAndNameFromFile = (file) => {
  const { name, type } = file;

  return {
    fileName: name,
    contentType: type,
  };
};
