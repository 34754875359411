import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IconCaretDown } from '@belong/icons';
import { Money, Text, ButtonBase } from '@belong/ui';
import { GroupBalanceInfoModel } from 'api/models';
import { UnderReviewInfoModel, BillingInfoModel } from 'api/models.v2';
import cx from 'classnames';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import { selectAccountPropertyByUnitId, selectAccountUnitByUnitId } from 'store/redux/homeowner-accounts/selectors';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';
import Balance from './balance';

type UnderReviewSectionProps = {
  homesBalances: UnderReviewInfoModel[];
  selectedHome?: string;
  isResident?: boolean;
  handleFinancingModal?: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowFinanceSuccessModal?: () => void;
  handleShowBalanceDetailsModal?: (
    balance: GroupBalanceInfoModel,
    home: string,
    billingInfo?: BillingInfoModel
  ) => void;
  hasManyHomes: boolean;
};

type HomeTileProps = {
  homeDetail: UnderReviewInfoModel;
  isResident?: boolean;
  onlyHome: boolean;
  handleFinancingModal?: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowFinanceSuccessModal?: () => void;
  handleShowBalanceDetailsModal?: (balance: GroupBalanceInfoModel, home: string) => void;
};

function HomeTile({
  homeDetail,
  isResident = false,
  onlyHome = false,
  handleFinancingModal,
  handleShowFinanceSuccessModal,
  handleShowBalanceDetailsModal,
}: HomeTileProps) {
  const [showItems, setShowItems] = useState(!!onlyHome);
  const property = useSelector(selectAccountPropertyByUnitId(homeDetail.homeId));
  const unit = useSelector(selectAccountUnitByUnitId(homeDetail.homeId));
  const address =
    !!property && !!unit ? formatString(addressAndUnit(property?.address, unit?.basicInfo.unitNumber)) : '';

  useEffect(() => {
    setShowItems(!!onlyHome);
  }, [onlyHome]);

  return (
    <>
      <article key={homeDetail.homeId} className={showItems ? '' : 'mb-sm'}>
        {!onlyHome && (
          <ButtonBase onClick={() => setShowItems((prevValue) => !prevValue)} className="block w-full">
            <header
              className={cx('flex justify-between items-center', {
                'pb-sm': showItems,
              })}
            >
              <div className="flex flex-col">
                <Text fontWeight={showItems ? 'semibold' : 'regular'}>{address}</Text>
              </div>
              <div className="flex items-center justify-end">
                <Money
                  className="text-body font-semibold mr-xs"
                  value={homeDetail.totalAmount}
                  format="DOLLARS_WITH_CENTS"
                />
                <IconCaretDown
                  width={15}
                  className={cx('transition-transform duration-hover ease-linear', {
                    'rotate-180': showItems,
                  })}
                />
              </div>
            </header>
          </ButtonBase>
        )}
      </article>
      {showItems && (
        <section className="mb-md">
          <Balance
            handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
            handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
            groupedBalancesInfo={homeDetail.groupedBalancesInfo}
            key={`balance-${homeDetail.homeId}`}
            index={0}
            homeId={homeDetail.homeId}
            showAddress={false}
            handleFinancingModal={handleFinancingModal}
            isResident={isResident}
            totalAmount={homeDetail.totalAmount}
            noBorder={onlyHome}
            hideSplitIt
          />
        </section>
      )}
    </>
  );
}

export function UnderReviewSection({
  selectedHome,
  homesBalances,
  hasManyHomes,
  isResident = false,
  handleFinancingModal,
  handleShowFinanceSuccessModal,
  handleShowBalanceDetailsModal,
}: UnderReviewSectionProps) {
  const homes = selectedHome === 'all' ? homesBalances : homesBalances?.filter((home) => home.homeId === selectedHome);
  const hasBalances = homes?.some((home) => home.groupedBalancesInfo.length > 0);

  if (!homes?.length || !hasBalances) {
    return null;
  }

  return (
    <>
      <AccountPageSection
        title={
          <div className="flex flex-col justify-between pb-sm border-0 border-b-[1px] border-dashed border-gray">
            <Text variant="h3" fontWeight="semibold">
              Under Review
            </Text>
            <Text variant="body" className="text-dark-gray mt-xs">
              These items have been flagged by you or your Member Success Lead to review. Once resolved, these items can
              be found in your monthly view to be charged.
            </Text>
          </div>
        }
        content={homes.map((home) => (
          <HomeTile
            key={home.homeId}
            homeDetail={home}
            handleFinancingModal={handleFinancingModal}
            handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
            handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
            onlyHome={homes.length === 1 && (!hasManyHomes || selectedHome !== 'all')}
            isResident={isResident}
          />
        ))}
      />
    </>
  );
}
