import { Image, Carousel, Text } from '@belong/ui';
import css from './weekly-and-self-tours.module.css';

export function WeeklyAndSelfTours() {
  return (
    <>
      <div className="hidden lg:flex my-2xl">
        <div className="flex flex-col items-center flex-1">
          <div className={css.iconContainer}>
            <Image src="/pricing-flow/icon_private_tour.svg" alt="" />
          </div>
          <Text fontWeight="semibold" className="mt-sm">
            Private Tours
          </Text>
        </div>
        <div className="flex flex-col items-center flex-1">
          <div className={css.iconContainer}>
            <Image src="/pricing-flow/Icon_group_tour.svg" alt="" />
          </div>
          <Text fontWeight="semibold" className="mt-sm">
            Group Tours
          </Text>
        </div>
      </div>

      <div className="contents lg:hidden">
        <Carousel>
          <div className="flex flex-col items-center min-w-full">
            <Image src="/pricing-flow/icon_private_tour.svg" alt="" />
            <Text fontWeight="semibold" className="mt-sm">
              Private Tours
            </Text>
          </div>
          <div className="flex flex-col items-center min-w-full">
            <Image src="/pricing-flow/Icon_group_tour.svg" alt="" />
            <Text fontWeight="semibold" className="mt-sm">
              Group Tours
            </Text>
          </div>
        </Carousel>
      </div>
    </>
  );
}
