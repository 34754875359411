import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore as _createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import clientMiddleware from './middleware/clientMiddleware';

export default function createStore({ rootReducer, history, data, helpers }) {
  const middleware = [clientMiddleware(helpers), routerMiddleware(history)];

  const middlewareEnhancer = applyMiddleware(...middleware);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  return _createStore(rootReducer, data, composedEnhancers);
}
