import React from 'react';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { validFutureOrTodayMMDDYYDate, validFutureOrTodayMMYYDate } from 'utils/validation';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { SELECTOR_TYPES } from 'components/Selector/Selector';
import Condition from 'formcomponents/Condition/Condition';
import { AvailableForRentOptions } from 'models/enums';
import { AvailableForRentOptionsFields } from '../AvailableForRentOptionsFields/AvailableForRentOptionsFields';
import { AvailableForRentOnASpecificDate } from '../AvailableForRentOnASpecificDate/AvailableForRentOnASpecificDate';
import { AvailableForRentInAMonth } from '../AvailableForRentInAMonth/AvailableForRentInAMonth';
import { AvailableForRentNotSureFields } from '../AvailableForRentNotSureFields/AvailableForRentNotSureFields';

export const VacantMoveOutSection = ({ name }) => (
  <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.vacantMoveout}>
    <AvailableForRentOptionsFields name={name} type={SELECTOR_TYPES.SMALLTEXTBUTTON} />
    <Condition is={AvailableForRentOptions.OnSpecificDate} when={`${name}.availableForRentOption`}>
      <AvailableForRentOnASpecificDate
        extraValidation={validFutureOrTodayMMDDYYDate}
        name={name}
        placeholder={RENTAL_STATUS_TEXT.vacantMoveOutDatePlaceholder}
      />
    </Condition>
    <AvailableForRentInAMonth extraValidation={validFutureOrTodayMMYYDate} name={name} />
    <AvailableForRentNotSureFields name={name} />
  </FormLayout.Section>
);

VacantMoveOutSection.propTypes = {
  name: PropTypes.string.isRequired,
};
