const AssetsBaseUrl = 'https://belong-web-assets.s3-us-west-2.amazonaws.com';

const AssestFolderUrls = {
  CommonAssets: 'common_assets',
  ImageAssets: 'image_assets',
  HowItWorks: 'landing_pages/how_it_works_v2',
};

const getAssetUrl = (assetFolder, assetFileName) => {
  return `${AssetsBaseUrl}/${assetFolder}/${assetFileName}`;
};

export const STATIC_IMAGES = {
  HIW_PLACEMAT_HOMEOWNER: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_placemat_homeowner.png'),
  HIW_PLACEMAT_RESIDENT: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_placemat_resident.png'),
  HIW_HO_01: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_01.png'),
  HIW_HO_02: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_02.png'),
  HIW_HO_03: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_03.png'),
  HIW_HO_04: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_04.png'),
  HIW_HO_05: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_05.png'),
  HIW_HO_06: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_06.png'),
  HIW_HO_07: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_07.png'),
  HIW_HO_08: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_08.png'),
  HIW_HO_09: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_09.png'),
  HIW_HO_10: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_ho_modal_10.png'),
  HIW_RE_01: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_01.png'),
  HIW_RE_02: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_02.png'),
  HIW_RE_03: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_03.png'),
  HIW_RE_04: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_04.png'),
  HIW_RE_05: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_05.png'),
  HIW_RE_06: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_06.png'),
  HIW_RE_07: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_07.png'),
  HIW_RE_08: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_08.png'),
  HIW_RE_09: getAssetUrl(AssestFolderUrls.HowItWorks, 'hiw_v2_re_modal_09.png'),
};
