import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { bsClass, getClassSet, splitBsProps } from './utils/bootstrapUtils';

const propTypes = {
  componentClass: PropTypes.node,
  className: PropTypes.string,
};

const defaultProps = {
  componentClass: 'div',
};

class Row extends React.Component {
  render() {
    const { componentClass: Component, className, ...props } = this.props;
    const [bsProps, elementProps] = splitBsProps(props);

    const classes = getClassSet(bsProps);

    return <Component {...elementProps} className={classNames(className, classes)} />;
  }
}

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default bsClass('row', Row);
