import React, { useState } from 'react';
import Measure from 'react-measure';
import ReactSlider from 'react-slider';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Slider.module.css';

const cx = classNames.bind(styles);

export const TRACK_TYPES = {
  DEFAULT: 'DEFAULT',
  GRADIENT: 'GRADIENT',
};

const TRACK_TYPE_MAP = {
  DEFAULT: (trackProps, trackState) => (
    <div {...trackProps} className={cx('track', trackState.index === 0 ? 'green' : 'gray')} />
  ),
  GRADIENT: (trackProps, trackState) => {
    return (
      <div
        {...trackProps}
        style={{
          ...trackProps.style,
          // Hacky way to make gradients work. Otherwise you will get 2 gradients
          // One from [min, value] and other from the track from [value, max]
          left: trackState.index === 0 ? 0 : '100%',
          right: 0,
        }}
        className={cx('track', 'gradient')}
      />
    );
  },
};

// All the rest of the react-slider props are available and passed thru the rest operator.
const Slider = ({
  containerClassName,
  className,
  thumbContainerClassName,
  trackType,
  defaultValue,
  gradient,
  onChange,
  renderTop,
  renderBottom,
  renderLeft,
  renderRight,
  renderThumbDetail,
  min,
  max,
  trackProps,
  thumbProps,
  value,
  ...rest
}) => {
  const [size, setSize] = useState({ width: undefined, height: undefined });

  const handleResize = (contentRect) => {
    const { width, height } = contentRect.bounds;
    setSize({ width, height });
  };

  return (
    // Slider doesn't seem to support SSR out of the box.
    // This is the only way i could think of to hard-rerender on parent resize,
    // since Slider library only listen to window resize.
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <div className={cx(containerClassName)}>
          {renderTop && renderTop(value)}
          <div className={cx('slider-row')}>
            {renderLeft && renderLeft(min)}
            <div ref={measureRef} className={cx('slider-container')}>
              <ReactSlider
                key={size.width}
                {...rest}
                value={value}
                min={min}
                max={max}
                onChange={onChange}
                className={cx('slider', className)}
                renderTrack={(defaultTrackProps, trackState) => {
                  // Provides a way to restrict and pass props to slider track variations
                  return TRACK_TYPE_MAP[trackType]({ ...defaultTrackProps, ...trackProps }, trackState);
                }}
                renderThumb={(defaultThumbProps, { value: thumbValue }) => (
                  <div {...defaultThumbProps} {...thumbProps} className={cx('thumb')}>
                    <div className={cx('thumb-stripes-wrapper')}>
                      <div className={cx('thumb-white-stripe')} />
                      <div className={cx('thumb-white-stripe')} />
                    </div>
                    {renderThumbDetail && (
                      <div className={cx('thumb-detail-wrapper', thumbContainerClassName)}>
                        {renderThumbDetail(thumbValue)}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            {renderRight && renderRight(max)}
          </div>
          {renderBottom && renderBottom(value)}
        </div>
      )}
    </Measure>
  );
};

Slider.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  trackType: PropTypes.oneOf(Object.keys(TRACK_TYPES)),
  defaultValue: PropTypes.number,
  gradient: PropTypes.bool,
  onChange: PropTypes.func,
  renderTop: PropTypes.func,
  renderBottom: PropTypes.func,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
  renderThumbDetail: PropTypes.func,
  trackProps: PropTypes.object.isRequired,
  thumbProps: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  showMinMax: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  thumbContainerClassName: PropTypes.string,
};

Slider.defaultProps = {
  containerClassName: '',
  className: '',
  thumbContainerClassName: '',
  trackType: 'DEFAULT',
  defaultValue: 0,
  gradient: false,
  onChange: null,
  renderTop: null,
  renderBottom: null,
  renderLeft: null,
  renderRight: null,
  showMinMax: true,
  min: 0,
  max: 100,
};

export const SliderFinalFormAdapter = ({ input, onChangeCustom, ...rest }) => (
  <Slider
    {...input}
    {...rest}
    onChange={(value) => {
      input.onChange(value);
      if (onChangeCustom) {
        onChangeCustom(value);
      }
    }}
  />
);

SliderFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func.isRequired,
};

export default Slider;
