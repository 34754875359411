// React final form imports
import { Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
// Component imports
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskNumber, unmaskNumber } from 'components/Input/masks';
import { SelectorFinalFormAdapter, SELECTOR_TYPES, GRID_SIZE } from 'components/Selector/Selector';
// Bootstrap imports
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { ParkingTypes } from 'models/enums';
import PropTypes from 'prop-types';
// Util, Enum and Const imports
import { required } from 'utils/validation';
import { PARKING_FEATURE_STRINGS } from 'accounts/constants/strings/parking.strings';


export function ParkingFormFields({ name, form, arrayFieldName, index, isMultiUnit }) {
  function showNumberOfSpots() {
    const parkingValues = form.getFieldState(arrayFieldName)?.value[index] || {};
    const { type } = parkingValues;

    return [
      ParkingTypes.GarageParking,
      ParkingTypes.LotParking,
      ParkingTypes.CarportParking,
      ParkingTypes.DrivewayParking,
    ].includes(type);
  }

  function showSpotsFields() {
    const parkingValues = form.getFieldState(arrayFieldName)?.value[index] || {};
    const { type, attributes } = parkingValues;

    if (type === ParkingTypes.DrivewayParking || attributes?.hasNoLimit || !isMultiUnit) {
      return false;
    }

    return true;
  }

  function handleChangeNumberOfSpots(value) {
    if (Number.isNaN(Number(value))) {
      console.error(new Error('value should be a number'));

      return;
    }

    if (value === '') {
      form.change(`${name}.attributes.spots`, []);

      return;
    }

    if (Number(value) === 0) {
      form.change(`${name}.attributes.numberOfSpots`, null);

      return;
    }

    const spotsArrayName = `${name}.attributes.spots`

    const numberOfExistingSpots = form.getFieldState(spotsArrayName)?.length;
    const numberOfSpots = Number(value);
    let spotsModified = 0;

    if (numberOfExistingSpots) {
      if (numberOfExistingSpots < numberOfSpots) {
        while (spotsModified < numberOfSpots - numberOfExistingSpots) {
          form.mutators.push(spotsArrayName, {});
          spotsModified += 1;
        }
      } else if (numberOfSpots < numberOfExistingSpots) {
        while (spotsModified < numberOfExistingSpots - numberOfSpots) {
          form.mutators.remove(spotsArrayName);
          spotsModified += 1;
        }
      }
    } else {
      while (spotsModified < numberOfSpots) {
        form.mutators.push(spotsArrayName);
        spotsModified += 1;
      }
    }
  }

  function resetSpotsFieldArray() {
    const shouldShowNumberOfSpots = showNumberOfSpots();

    if (!shouldShowNumberOfSpots) {
      form.change(`${name}.attributes.spots`, []);
      form.change(`${name}.attributes.numberOfSpots`, null);
      form.change(`${name}.attributes.hasNoLimit`, null);
    }
  }

  function shouldNumberOfSpotsBeDisabled() {
    const parkingValues = form.getFieldState(arrayFieldName)?.value[index] || {};
    const { attributes } = parkingValues;

    return Boolean(attributes?.hasNoLimit);
  }

  function handleChangeUnlimitedSpots(value) {
    if (value) {
      form.change(`${name}.attributes.spots`, []);
      form.change(`${name}.attributes.numberOfSpots`, null);
    }
  }

  return (
    <Fragment>
      <Field
        grid
        gridSize={GRID_SIZE.SMALL}
        name={`${name}.type`}
        component={SelectorFinalFormAdapter}
        validate={required}
        verticalSpaceBetween="1rem"
        type={SELECTOR_TYPES.SMALLTEXTBUTTON}
        buttons={[
          {
            label: PARKING_FEATURE_STRINGS.garage,
            key: ParkingTypes.GarageParking,
          },
          {
            label: PARKING_FEATURE_STRINGS.lot,
            key: ParkingTypes.LotParking,
          },
          {
            label: PARKING_FEATURE_STRINGS.carport,
            key: ParkingTypes.CarportParking,
          },
          {
            label: PARKING_FEATURE_STRINGS.driveway,
            key: ParkingTypes.DrivewayParking,
          },
          {
            label: PARKING_FEATURE_STRINGS.onStreetParking,
            key: ParkingTypes.OnStreetParking,
          },
          {
            label: PARKING_FEATURE_STRINGS.offStreetParking,
            key: ParkingTypes.OffStreetParking,
          },
        ]}
        onChangeCustom={resetSpotsFieldArray}
      />
      {showNumberOfSpots() && (
        <Fragment>
          <div className="mt-sm">
            <FormLayout.Section
              firstSection
              sectionTitle={PARKING_FEATURE_STRINGS.canResidentsUseIt}
              separatorType="normal"
              sectionTitleClassName="text-body"
            >
              <Row>
                <Col md={6}>
                  <Field
                    fluid
                    name={`${name}.attributes.canResidentsUseIt`}
                    component={SelectorFinalFormAdapter}
                    validate={required}
                    type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                    buttons={[
                      {
                        label: PARKING_FEATURE_STRINGS.yes,
                        key: true,
                      },
                      {
                        label: PARKING_FEATURE_STRINGS.no,
                        key: false,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </FormLayout.Section>
          </div>
          <Row className="mt-sm">
            <Col md={6}>
              <Field
                name={`${name}.attributes.numberOfSpots`}
                component={InputFinalFormAdapter}
                placeholder={PARKING_FEATURE_STRINGS.numberOfSpots}
                mask={maskNumber}
                unmask={unmaskNumber}
                onChangeCustom={handleChangeNumberOfSpots}
                disabled={shouldNumberOfSpotsBeDisabled()}
              />
            </Col>
            <Col md={6}>
              <Field
                name={`${name}.attributes.hasNoLimit`}
                component={CheckboxFinalFormAdapter}
                marginTop
                label={PARKING_FEATURE_STRINGS.unlimited}
                onClickCustom={handleChangeUnlimitedSpots}
              />
            </Col>
          </Row>
        </Fragment>
      )}
      {showSpotsFields() && (
        <FieldArray name={`${name}.attributes.spots`}>
          {({ fields }) =>
            fields.map((fieldArrayName, fieldIndex) => (
              <FormLayout.Section
                sectionTitle={`Spot #${fieldIndex + 1}`}
                key={fieldIndex}
                sectionTitleClassName="text-body"
                className="mt-xl"
              >
                <Row key={`${fieldArrayName}-assignedNumber`}>
                  <Col md={6}>
                    <Field
                      name={`${fieldArrayName}.hasAssignedNumber`}
                      component={CheckboxFinalFormAdapter}
                      label={PARKING_FEATURE_STRINGS.assignedSpot}
                      alignWithField={false}
                    />
                  </Col>
                </Row>
                <Condition when={`${fieldArrayName}.hasAssignedNumber`} is>
                  <Row key={`${fieldArrayName}-spotNumber`}>
                    <Col md={6}>
                      <Field
                        name={`${fieldArrayName}.spotNumber`}
                        component={InputFinalFormAdapter}
                        validate={required}
                        placeholder={PARKING_FEATURE_STRINGS.spotNumber}
                      />
                    </Col>
                  </Row>
                </Condition>
              </FormLayout.Section>
            ))
          }
        </FieldArray>
      )}
    </Fragment>
  );
}

ParkingFormFields.propTypes = {
  name: PropTypes.string,
  form: PropTypes.object.isRequired,
  arrayFieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isMultiUnit: PropTypes.bool,
};

ParkingFormFields.defaultProps = {
  name: '',
};