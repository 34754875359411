import { PET_TYPES } from "accounts/constants/home-pets";

export function getPetPreference (value) {
  if (value?.anyPets) {
    return PET_TYPES.ALL;
  } else if (value?.dogs) {
    return PET_TYPES.DOGS;
  } else if (value?.cats) {
    return PET_TYPES?.CATS;
  } else if (value?.noPets) {
    return PET_TYPES.NONE;
  }

  return null;
}

export function getPetsKey(petsAccepted) {
  if (petsAccepted === PET_TYPES.DOGS) {
    return 'dogs';
  } else if (petsAccepted === PET_TYPES.CATS) {
    return 'cats';
  } else if (petsAccepted === PET_TYPES.ALL) {
    return 'anyPets';
  } else if (petsAccepted === PET_TYPES.NONE) {
    return 'noPets';
  }

  return '';
}

export function getHasWeight(value) {
  return value.maxWeightPerPet > 0;
}

export function getHasRestrictedBreeds(value) {
  return value.restrictedBreeds?.length > 0;
}

export function allPetsAllowed(currentUnit) {
  return !(getHasWeight(currentUnit) || getHasRestrictedBreeds(currentUnit));
}
