export const SECTIONS_STRINGS = {
  'inspection_schedule.displayName': 'Unlock Your Complimentary\u00a0Inspection',
  'inspection_schedule.adopted_resident.displayName': 'Introduce Us To Your Residents\u00a0&\u00a0Home',
  'build_listing.displayName': 'Unlock Your Professional\u00a0Listing',
  'build_listing.adopted_resident.displayName': 'Be Ready To\u00a0List',
  'home_improvements.displayName': 'View Inspection\u00a0Results',
  'home_improvements_move_out.displayName': 'Confirm Final Inspection\u00a0Results',
  'home_improvements_pre_move_out.displayName': 'Confirm Final Inspection\u00a0Results',
  'pre_move_out.displayName': 'Prepare Your Home For\u00a0Re-Listing',
  'home_pricing.displayName': 'Set Rent\u00a0Price',
  'home_pricing_move_out.displayName': 'Set Listing Price To Maximize\u00a0Earnings',
  'next_level_profile.displayName': 'Take Your Profile To The\u00a0Next\u00a0Level',
  'get_paid.displayName': 'Unlock Payments',
  'account.title': 'Account',
  'what_you_need.sub_title': 'What You Need',
  hoa_agreement_and_info: 'To complete this, you’ll need your HOA Agreement, and your HOA contact information.',
  current_lease: 'Current Lease',
  resident_contact_info: 'Resident Contact Info',
  hoa_agreement: 'HOA Agreement',
  hoa_contact_info: 'HOA Contact Info',
  tax_info: 'Tax Info',
  insurance: "Proof Of Homeowner's Insurance",
  valid_id: 'Valid ID',
  'upcoming_appointments.title': 'Upcoming Appointments',
  'upcoming_appointments.inspection': 'Inspection',
  'upcoming_appointments.reschedule': 'Reschedule',
  add_insurance: 'Add Insurance',
  w9: 'Be Ready For Tax\u00a0Season',
};
