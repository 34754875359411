import React from 'react';
import classNames from 'classnames/bind';
import InputLabel from 'components/InputLabel/InputLabel';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import PropTypes from 'prop-types';
import styles from './DownshiftButton.module.css';

const cx = classNames.bind(styles);

const DownshiftButton = React.forwardRef((props, ref) => {
  const { error, value, highlight, errorMessage, placeholder, disabled, multi, ...other } = props;

  return (
    <>
      <div className={cx('downshift-button-wrapper', { disabled }, { highlight }, { error })}>
        {placeholder && <InputLabel unAnimateText={!!value} highlight={highlight} label={placeholder} error={error} />}
        <ButtonBase
          ref={ref}
          disabled={disabled}
          className={cx('downshift-button', { multi: multi && value }, error)}
          {...other}
        />
      </div>
      {error && errorMessage && !highlight && <div className={cx('error-message')}>{errorMessage}</div>}
    </>
  );
});

DownshiftButton.propTypes = {
  placeholder: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  highlight: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  value: PropTypes.string,
};

DownshiftButton.defaultProps = {
  highlight: false,
  errorMessage: '',
  error: false,
  value: null,
  disabled: false,
};

export default DownshiftButton;
