import cx from 'classnames';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import { NewMediaUploadAdapter } from 'components/NewMediaUploader/NewMediaUploader';
import PropTypes from 'prop-types';
import { HOME_OWNER_PAPER_WORK_STRINGS as STRINGS } from 'strings/homeowner-paperwork.strings';

function showCheckbox(hideCheckboxWithValue, length) {
  if (hideCheckboxWithValue && length) {
    return false;
  }

  return true;
}

export function validateSkippableMedia(value) {
  if (value?.later === true) {
    return undefined;
  }

  if (value?.mediaToDisplay?.length) {
    return undefined;
  }

  return true;
}

export function SkippableMediaUploader({
  input,
  toolTip,
  customCheckboxClassname,
  checkboxProps,
  hideCheckboxWithValue,
  ...rest
}) {
  const { value, onChange } = input;
  const onCheckboxChange = (checkboxValue) => {
    const newValue = {
      ...value,
      later: checkboxValue,
    };
    onChange(newValue);
  };

  return (
    <div>
      <NewMediaUploadAdapter {...(rest as any)} input={input} />
      {showCheckbox(hideCheckboxWithValue, value?.mediaToDisplay?.length) && (
        <div
          className={cx(
            'pt-sm text-dark-gray flex items-center justify-end font-semibold text-body',
            customCheckboxClassname
          )}
        >
          <CheckboxFinalFormAdapter
            useOnChangeFormToHandle
            alignWithField={false}
            input={{
              value: value.later,
              onChange: onCheckboxChange,
            }}
            {...checkboxProps}
          />
          {toolTip}
        </div>
      )}
    </div>
  );
}

SkippableMediaUploader.propTypes = {
  fileType: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  hideCheckboxWithValue: PropTypes.bool,
  checkboxProps: PropTypes.object,
  customCheckboxClassname: PropTypes.string,
  toolTip: PropTypes.node,
  input: PropTypes.object.isRequired,
};

SkippableMediaUploader.defaultProps = {
  value: {},
  hideCheckboxWithValue: true,
  checkboxProps: {
    label: STRINGS['around_the_property.uploadHoa.donthave'],
  },
  toolTip: null,
};

export function SkippableMediaUploaderAdapter({ input, meta, ...rest }) {
  return <SkippableMediaUploader {...(rest as any)} meta={meta} input={input} />;
}

SkippableMediaUploaderAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};
