import clsx from 'clsx';
import Collapsible from 'components/Collapsible/Collapsible';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import UserActionMaintenance from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';

export const MaintenanceRecurrenceAppointments = ({ appointments, disabled = false }) => {
  const isCollapsibleDisabled = appointments?.length === 0 || disabled;

  return (
    <Collapsible
      title="Visits"
      collapsed={isCollapsibleDisabled}
      defaultClassName="block"
      className={clsx(isCollapsibleDisabled && 'opacity-50')}
      disabled={isCollapsibleDisabled}
      inner={
        <>
          {appointments?.map((appointment) => {
            const user = appointment.pro?.name ? { ...appointment.pro } : { ...appointment.partner };

            return (
              <UserActionMaintenance
                key={user.userId}
                belongPro={!!appointment.pro?.name}
                partner={!!appointment.partner?.name}
                name={user.name}
                time={appointment.scheduledStartOn}
                allDay={appointment.isAllDay}
                userId={user.userId}
                profileImage={user.profileImageUrl || user.logoImageUrl}
                location={appointment.location}
                withSpace={false}
                taskType={
                  <div className="items-center sm:flex">
                    <div className="mr-xs">
                      {
                        MAINTENACE_STATUS_MODAL_STRINGS[
                          `maintenance.scheduled.status.${getEnding(appointment.status, appointment.outcome)}`
                        ]
                      }
                    </div>
                    {appointment.status === 'Completed' && <div>{getLabelFromOutcome(appointment.outcome)}</div>}
                  </div>
                }
              />
            );
          })}
        </>
      }
    />
  );
};

const getLabelFromOutcome = (outcome) =>
  outcome === 'Resolved' ? <Label color={LABEL_COLOR_TYPES.GREEN} text="Resolved" /> : null;

const getEnding = (status, outcome) => {
  if (status?.toLowerCase() !== 'completed') {
    return `${status.toLowerCase()}`;
  }

  return outcome === 'NotResolved' ? 'completed.notresolved' : 'completed';
};
