import { IconCaretDown, IconCheck } from '@belong/icons';
import { Button, Text } from '@belong/ui';
import { Menu, MenuButton, MenuItems, MenuItem, MenuPopover } from '@reach/menu-button';
import { positionRight } from '@reach/popover';
import classnames from 'classnames/bind';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HomeownerAccountUnit from 'models/homeownerAccounts/HomeownerAccountUnit';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';
import styles from './home-menu.module.css';

type HomeMenuProps = {
  allHomesLabel: string;
  selectedHomeId: string;
  onChange?: (homeId: string) => void;
  properties: HomeownerAccountProperty[];
};

const cx = classnames.bind(styles);

export function HomeMenu({ allHomesLabel, selectedHomeId, properties, onChange }: HomeMenuProps) {
  const propertySelected = properties.find((property) =>
    property.units.some((unit) => unit.basicInfo.unitId === selectedHomeId)
  );
  const homeSelected = properties.reduce((acc, curr) => {
    const homeFound = curr.units.find((unit) => unit.basicInfo.unitId === selectedHomeId);

    if (homeFound) {
      acc = homeFound;
    }

    return acc;
  }, null as HomeownerAccountUnit);
  const selectedHomeTitle =
    selectedHomeId === 'all'
      ? allHomesLabel
      : propertySelected
      ? addressAndUnit(propertySelected?.address, homeSelected?.basicInfo.unitNumber)
      : '';

  function handleHomeSelect(homeId: string) {
    return () => {
      onChange?.(homeId);
    };
  }

  return (
    <Menu>
      {({ isExpanded }) => (
        <>
          <MenuButton as={Button} variant="text">
            <div className="flex items-center">
              <Text variant="body" fontWeight="semibold" className="mr-xs normal-case">
                {formatString(selectedHomeTitle)}
              </Text>
              <IconCaretDown
                width={15}
                height={8}
                className={cx('ml-xs transition-transform duration-hover ease-linear', {
                  'rotate-180': isExpanded,
                })}
              />
            </div>
          </MenuButton>
          <MenuPopover position={positionRight}>
            <MenuItems
              className={cx(
                'flex flex-col p-sm rounded mt-xs border-solid bg-white border-gray transition-all ease-linear duration-hover',
                'menu-items',
                {
                  'opacity-100 visible h-full': isExpanded,
                  'opacity-0 invisible h-0': !isExpanded,
                }
              )}
            >
              <MenuItem as={Button} variant="text" onSelect={handleHomeSelect('all')}>
                <div className="flex items-center justify-between">
                  <Text
                    variant="body"
                    fontWeight={selectedHomeId === 'all' ? 'semibold' : 'regular'}
                    className="text-left normal-case mr-xl"
                  >
                    {allHomesLabel}
                  </Text>
                  {selectedHomeId === 'all' ? <IconCheck width={15} height={11} className="text-green" /> : null}
                </div>
              </MenuItem>
              {properties.map((property) => (
                <>
                  {property.units.map((unit) => (
                    <MenuItem
                      key={unit.basicInfo.unitId}
                      as={Button}
                      variant="text"
                      onSelect={handleHomeSelect(unit.basicInfo.unitId)}
                    >
                      <div className="flex items-center justify-between mt-sm">
                        <Text
                          variant="body"
                          fontWeight={selectedHomeId === unit.basicInfo.unitId ? 'semibold' : 'regular'}
                          className="normal-case mr-xl"
                        >
                          {formatString(addressAndUnit(property.address, unit.basicInfo.unitNumber))}
                        </Text>
                        {selectedHomeId === unit.basicInfo.unitId ? (
                          <IconCheck width={15} height={11} className="text-green " />
                        ) : null}
                      </div>
                    </MenuItem>
                  ))}
                </>
              ))}
            </MenuItems>
          </MenuPopover>
        </>
      )}
    </Menu>
  );
}
