export const RELATIONSHIP_ITEMS = [
  { value: 'Friend/Roommate', key: 'Friend/Roommate' },
  { value: 'Relationship', key: 'Relationship' },
  { value: 'Parent/Grandparent', key: 'Parent/Grandparent' },
  { value: 'Spouse/Partner/Significant Other', key: 'Spouse/Partner/SignificantOther' },
  { value: 'Sibling', key: 'Sibling' },
  { value: 'Child', key: 'Child' },
  { value: 'Extended Family', key: 'ExtendedFamily' },
  { value: 'Other', key: 'Other' },
];
