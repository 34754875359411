import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Image from 'components/Image/Image';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import PropTypes from 'prop-types';
import styles from './ImageLinkCard.module.css';

const cx = classNames.bind(styles);

const ImageLinkCard = ({ imageSrc, linkLabel, onClick }) => {
  return (
    <ButtonBase className={cx('image-link-card')} onClick={onClick}>
      <div className={cx('image-wrapper')}>
        <Image src={imageSrc} className={cx('image')} alt="" />
      </div>
      <div className={cx('footer')}>
        {linkLabel}
        <GeneralIcon icon={GENERAL_ICONS.FRONT_ARROW} />
      </div>
    </ButtonBase>
  );
};

ImageLinkCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ImageLinkCard;
