import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './MiniImageCard.module.css';

const cx = classNames.bind(styles);

class MiniImageCard extends Component {
  static propTypes = {
    imageUrl: PropTypes.string,
    image: PropTypes.node,
    tag: PropTypes.node,
    title: PropTypes.node.isRequired,
    subtitles: PropTypes.arrayOf(PropTypes.node),
  };

  static defaultProps = {
    subtitles: [],
    tag: '',
    imageUrl: '',
    image: null,
  };

  render() {
    const { tag, title, image, imageUrl, subtitles } = this.props;
    return (
      <div className={cx('mini-image-card')}>
        <div className={cx('image-container')}>
          {imageUrl && <div className={cx('image')} style={{ backgroundImage: `url('${imageUrl}')` }} />}
          {image && <div className={cx('image')}>{image}</div>}
        </div>
        <div className={cx('info-container')}>
          <div className={cx('tag')}>{tag}</div>
          <div className={cx('title')}>{title}</div>
          {subtitles.map((subtitle, index) => (
            <div key={index} className={cx('subtitle')}>
              {subtitle}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MiniImageCard;
