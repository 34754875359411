import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { composeValidators, dateValidationMMDDYY, required } from 'utils/validation';
import { DateInputFinalFormAdapter } from 'components/DateInput/DateInput';
import Field from 'components/Field/Field';

export const AvailableForRentOnASpecificDate = ({
  name,
  placeholder = RENTAL_STATUS_TEXT.moveOutQuestion,
  extraValidation = () => {},
}) => (
  <Row>
    <Col xs={12} sm={6}>
      <Field
        component={DateInputFinalFormAdapter}
        name={`${name}.availableForRentOn`}
        placeholder={placeholder}
        validate={composeValidators(required, dateValidationMMDDYY, extraValidation)}
      />
    </Col>
  </Row>
);

AvailableForRentOnASpecificDate.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  extraValidation: PropTypes.func,
};
