import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer';
import { HeaderLogo } from 'components/HeaderMain/HeaderMain';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { fetchFinancePlanDisclosure } from 'store/redux/accounts/actions';
import styles from './disclosure-modal.module.css';

const cx = classNames.bind(styles);

export function DisclosureModal({ setShow, show, installments, balanceIds }) {
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDisclosure = async () => {
      setLoading(true);
      try {
        const document = await dispatch(
          fetchFinancePlanDisclosure({
            installments,
            balanceUniqueIds: balanceIds,
          })
        );

        setHtml(document);
        setLoading(false);
      } catch (err) {
        console.error(err);
        if (setShow) setShow(false);
        setLoading(false);
      }
    };

    fetchDisclosure();
  }, [dispatch, balanceIds, installments, setShow]);

  function handleClose() {
    if (setShow) setShow(false);
  }

  return (
    <ModalV2 noBorder noPadding showSpinner={loading} onHide={handleClose} show={show}>
      <div className="absolute -top-xl md:top-sm">
        <HeaderLogo />
      </div>
      <ModalLayout>{html && <HTMLRenderer className={cx('disclosure')} html={html} />}</ModalLayout>
    </ModalV2>
  );
}
