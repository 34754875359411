import { LEASE_SIGNING_FLOW } from './key';

const SHOW_APP_MODAL = 'SHOW_APP_MODAL';
const RESET_APP_MODAL = 'RESET_APP_MODAL';

const actions = {
  SHOW_APP_MODAL,
  RESET_APP_MODAL,
};

export const showAppModal = () => ({
  type: SHOW_APP_MODAL,
});

export const resetAppModal = () => ({
  type: RESET_APP_MODAL,
});

export const LEASE_SIGNING_FLOW_APP_REDUCER = 'app';

const initialState = {
  wasAppModalShown: false,
};

const reducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case actions.SHOW_APP_MODAL:
      return {
        ...state,
        wasAppModalShown: true,
      };
    case actions.RESET_APP_MODAL:
      return {
        ...state,
        wasAppModalShown: false,
      };
    default:
      return state;
  }
};

export default reducer;

export const selectWasAppModalShown = (state) =>
  state[LEASE_SIGNING_FLOW][LEASE_SIGNING_FLOW_APP_REDUCER].wasAppModalShown;
