import React, { Component } from 'react';
import classNames from 'classnames/bind';
import DownshiftInput from 'components/DownshiftComponents/DownshiftInput/DownshiftInput';
import DownshiftItem from 'components/DownshiftComponents/DownshiftItem/DownshiftItem';
import DownshiftMenu from 'components/DownshiftComponents/DownshiftMenu/DownshiftMenu';
import { downShiftItemProptypes } from 'components/DownshiftComponents/downShiftItemProptypes';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import Downshift from 'downshift';
import { find, findIndex, isNil } from 'lodash-es';
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types';
import styles from './AutoComplete.module.css';

const cx = classNames.bind(styles);

const itemToString = (i) => (i ? i.value : '');

export default class AutoComplete extends Component {
  static propTypes = {
    // array of items that contains a value element
    items: PropTypes.arrayOf(PropTypes.shape(downShiftItemProptypes)).isRequired,

    // Placeholder for the input value
    placeholder: PropTypes.string,

    // bool to not show the icon in input field
    hideAutoCompleteIcon: PropTypes.bool,

    // bool to allow user input that don't belong to items
    allowUserInput: PropTypes.bool,

    onInputValueChange: PropTypes.func,

    onChange: PropTypes.func,

    // Unsure where used?
    value: PropTypes.string,
    // TODO: refactor into a object
    maxHeightSmallMenu: PropTypes.bool,

    hideAutoCompleteIconOnMobile: PropTypes.bool,
    // Open the menu on focus
    openMenuOnFocus: PropTypes.bool,
    error: PropTypes.bool,

    displayIcon: PropTypes.func,

    onStateChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    defaultHighlightedIndex: PropTypes.number,
    selectedItem: PropTypes.shape(downShiftItemProptypes),
    disabled: PropTypes.bool,
    // Min number of chars before filtering
    min: PropTypes.number,
    alwaysFilter: PropTypes.bool,
    customFilter: PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'Press down or start typing',
    hideAutoCompleteIcon: false,
    allowUserInput: false,
    hideAutoCompleteIconOnMobile: false,
    onInputValueChange: () => {},
    onChange: () => {},
    onSelect: () => {},
    maxHeightSmallMenu: false,
    value: '',
    openMenuOnFocus: false,
    error: false,
    defaultHighlightedIndex: null,
    displayIcon: null,
    selectedItem: null,
    disabled: false,
    min: 0,
    alwaysFilter: false,
    customFilter: null,
  };

  state = {
    displayAllItems: false,
  };

  // Used to auto complete and sort via match.
  getItems = (filter) => {
    const { min, customFilter } = this.props;
    const { displayAllItems } = this.state;

    if (min && filter?.length < min) {
      return [];
    }
    if (displayAllItems) {
      return this.props.items;
    }
    // eslint-disable-next-line
    const filteredArray = customFilter
      ? customFilter(filter, this.props.items)
      : !isNil(filter)
      ? matchSorter(this.props.items, filter, {
          keys: ['value'],
        })
      : this.props.items;

    // Remove duplicates
    return filteredArray.filter((i, index) => filteredArray.findIndex((j) => j.value === i.value) === index);
  };

  displayIcon = (isOpen, getToggleButtonProps) => {
    const { displayIcon, hideAutoCompleteIconOnMobile, disabled } = this.props;

    if (disabled) {
      return null;
    }

    if (displayIcon)
      return (
        <ButtonBase tabIndex="-1" {...getToggleButtonProps()} className={cx('format-icon')}>
          {displayIcon(isOpen, getToggleButtonProps)}
        </ButtonBase>
      );
    return (
      <ButtonBase
        {...getToggleButtonProps()}
        className={cx('format-icon', 'format-search-icon', { hideAutoCompleteIconOnMobile })}
      >
        <GeneralIcon icon={GENERAL_ICONS.SEARCH} />
      </ButtonBase>
    );
  };

  displayAllItems = (openMenu) => {
    openMenu();
    if (this.props.alwaysFilter) {
      return;
    }
    this.setState({ displayAllItems: true });
  };

  filterItems = () => {
    this.setState({ displayAllItems: false });
  };

  render() {
    const {
      placeholder,
      onInputValueChange,
      hideAutoCompleteIcon,
      value: valueFinalFormInput,
      allowUserInput,
      maxHeightSmallMenu,
      selectedItem: selectedItemKeyProp,
      error,
      onFocus,
      onBlur,
      defaultHighlightedIndex,
      disabled,
      onStateChange,
      onSelect,
    } = this.props;

    const downshiftProps = {};
    let displayValue = valueFinalFormInput;

    if (!isNil(selectedItemKeyProp)) {
      const [selectedItemObject] = this.getItems().filter((item) => item.key === selectedItemKeyProp.key);

      if (!isNil(selectedItemObject)) {
        displayValue = selectedItemObject?.value;
      }
    }
    return (
      <Downshift
        itemToString={itemToString}
        onInputValueChange={onInputValueChange}
        onStateChange={onStateChange}
        onSelect={onSelect}
        defaultInputValue={valueFinalFormInput}
        inputValue={displayValue || ''}
        defaultHighlightedIndex={defaultHighlightedIndex}
        stateReducer={(state, changes) => {
          const index = findIndex(this.props.items, (e) => {
            return e.key === state.selectedItem?.key;
          });

          // eslint-disable-next-line no-prototype-builtins
          if (changes.hasOwnProperty('isOpen') && changes.isOpen) {
            return {
              ...changes,
              highlightedIndex: index,
            };
          }

          return changes;
        }}
        {...downshiftProps}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getMenuProps,
          openMenu,
          closeMenu,
          isOpen,
          selectHighlightedItem,
          inputValue,
          selectedItem,
          selectItem,
          highlightedIndex,
          clearSelection,
          setState,
        }) => (
          <div className={cx('autocomplete')}>
            <div className={cx('relative')}>
              <DownshiftInput
                {...getInputProps({
                  placeholder,

                  onChange: () => {
                    if (allowUserInput) {
                      selectItem({ value: inputValue, key: inputValue });
                    }
                    this.filterItems();
                  },
                  onKeyDown: (event) => {
                    if (!allowUserInput && event.key === 'Tab') {
                      if (highlightedIndex === -1) {
                        const items = this.getItems(inputValue);
                        if (items && items.length > 0) {
                          selectItem(items[0]);
                        }
                      } else {
                        selectHighlightedItem();
                      }
                      return;
                    }

                    if (event.key === 'Enter') {
                      if (highlightedIndex === -1) {
                        const items = this.getItems(inputValue);
                        if (items && items.length > 0) {
                          selectItem(items[0]);
                        }
                      }
                    }
                  },
                  onClick: () => {
                    this.displayAllItems(openMenu);
                  },
                  onFocus: () => {
                    this.displayAllItems(openMenu);
                    onFocus();
                  },
                  onBlur: () => {
                    if (allowUserInput) {
                      selectItem({ value: inputValue, key: inputValue });
                    } else if (
                      !allowUserInput &&
                      !find(this.props.items, (item) => {
                        return item.value?.toString() === inputValue.toString();
                      })
                    ) {
                      clearSelection();
                    }
                    this.filterItems();
                    setState({
                      blur: true,
                    });
                    closeMenu();
                    onBlur();
                  },
                })}
                error={error}
                disabled={disabled}
                applyDisabledStyle={disabled}
              />

              {hideAutoCompleteIcon ? null : this.displayIcon(isOpen, getToggleButtonProps)}
            </div>
            <DownshiftMenu maxHeightSmallMenu={maxHeightSmallMenu} {...getMenuProps({ isOpen })}>
              {isOpen ? (
                <>
                  {this.getItems(inputValue).map((item, index) => (
                    <DownshiftItem
                      key={item.value}
                      {...getItemProps({
                        item,
                        index,
                        isActive: highlightedIndex === index,
                        isSelected: selectedItem?.value === item?.value,
                      })}
                    >
                      {itemToString(item)}
                    </DownshiftItem>
                  ))}
                </>
              ) : null}
            </DownshiftMenu>
          </div>
        )}
      </Downshift>
    );
  }
}

export const AutoCompleteFinalFormAdapter = ({
  input,
  meta,
  onChangeCustom,
  allowUserInput,
  ignoreError,
  newFieldComponent,
  ...rest
}) => {
  const controlledProps = {};

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = !!meta.error;
    } else {
      controlledProps.error = meta.touched ? !!meta.error : false;
    }
  }

  return (
    <AutoComplete
      {...input}
      {...rest}
      allowUserInput={allowUserInput}
      onInputValueChange={(inputValue) => {
        const changedValue = inputValue?.toString();
        input.onChange(changedValue);
        if (onChangeCustom) {
          onChangeCustom(changedValue);
        }
      }}
      selectedItem={typeof input.value === 'object' ? input.value : { key: input.value, value: input.value }}
      {...controlledProps}
    />
  );
};

AutoCompleteFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func,
  allowUserInput: PropTypes.bool.isRequired,
  ignoreError: PropTypes.bool,
  newFieldComponent: PropTypes.bool,
  onStateChange: PropTypes.func,
};

AutoCompleteFinalFormAdapter.defaultProps = {
  onChangeCustom: () => {},
  newFieldComponent: false,
  ignoreError: false,
  onStateChange: () => {},
};
