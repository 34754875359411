import React, { forwardRef, createElement, useContext } from 'react';
import { AnalyticsContext } from './AnalyticsContext';

const Analytics = (getProperties) => (wrappedComponent) => {
  const AnalyticsWrapper = forwardRef((props, ref) => {
    const contextAnalyticsData = useContext(AnalyticsContext);

    return (
      <AnalyticsContext.Provider value={{ ...contextAnalyticsData, ...getProperties(props) }}>
        {createElement(wrappedComponent, { ref, ...props })}
      </AnalyticsContext.Provider>
    );
  });

  return AnalyticsWrapper;
};

export default Analytics;
