import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';

export function useSelectedProperty() {
  const { propertyId } = useParams<any>();
  const properties = useSelector(selectAccountProperties);
  const property = properties?.find((currProperty) => currProperty.basicInfo.propertyId === propertyId);

  return { selectedProperty: property };
}
