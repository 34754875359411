import { useEffect, useRef, useState } from 'react';
import { MoneyRange, Money } from '@belong/ui';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Box, Flex, Text } from 'design-system';
import { BUNDLE_CONFIG } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import styles from './Bundle.module.css';

const cx = classNames.bind(styles);

const propTypes = {
  bundle: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  priceRange: PropTypes.object,
};

const defaultProps = {};

const ProjectManagementBundle = ({ bundle, onToggle, open, priceRange }) => {
  const content = useRef(null);
  const { type, total } = bundle;
  const { projectManagementFeeLowerBoundTotal, projectManagementFeeUpperBoundTotal } = priceRange || {};

  const bundleConfig = BUNDLE_CONFIG[type];

  const [overflowAuto, setOverflowAuto] = useState(!open);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOverflowAuto(false);
      }, [100]);

      return;
    }

    setOverflowAuto(true);
  }, [open]);

  return (
    <div
      className={cx('bundle', {
        'open-borders': open,
        open,
      })}
    >
      <ButtonBase className={cx('bundle-header')} onClick={() => onToggle(type)}>
        <div className={cx('top-section')}>
          <div className={cx('left-section')}>
            <div className={cx('bundle-details')}>
              <Flex
                alignItems="flex-start"
                flexDirection="column"
                justifyContent={['unset', 'space-between']}
                marginRight="xs"
              >
                <Text className={cx('initial')} fontWeight="semibold">
                  {bundleConfig.displayName}
                </Text>
              </Flex>
            </div>
          </div>
          <div className={cx('right-section')}>
            <div className={cx('price')}>
              <div className="body font-semibold flex">
                <span className="mr-xs">Est.</span>
                {priceRange ? (
                  <MoneyRange
                    lower={projectManagementFeeLowerBoundTotal}
                    upper={projectManagementFeeUpperBoundTotal}
                    format="DOLLARS_NO_CENTS"
                  />
                ) : (
                  <Money value={total} format="DOLLARS_NO_CENTS" className="font-semibold" />
                )}
              </div>
            </div>
            <div className={cx('caret-icon')}>
              <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
            </div>
          </div>
        </div>
        <div className={cx('bottom-section')}>
          <div className={cx('price')}>
            <div className="body font-semibold flex">
              <span className="mr-xs">Est.</span>
              {priceRange ? (
                <MoneyRange
                  lower={projectManagementFeeLowerBoundTotal}
                  upper={projectManagementFeeUpperBoundTotal}
                  format="DOLLARS_NO_CENTS"
                />
              ) : (
                <Money value={total} format="DOLLARS_NO_CENTS" className="font-semibold" />
              )}
            </div>
          </div>
        </div>
      </ButtonBase>

      <div
        ref={content}
        className={cx('content', { overflowAuto: !open || overflowAuto })}
        style={{ maxHeight: open ? content?.current?.scrollHeight : '0px' }}
      >
        <Flex className={cx('why-reason')} flexDirection="column">
          <p className="p1 text-dark-gray pb-lg md:-mt-md -mt-xs">
            Based on the estimated costs of all repairs included in the improvement report. The ultimate fee will be
            based on the final invoice after all repairs are completed.
          </p>
          <Box style={{ margin: '0 0 0.25rem 0' }}>
            <Text fontWeight="semibold">{POST_INSPECTION_FLOW_STRINGS['approvals.projectManagementFee.title']}</Text>
          </Box>
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            justifyContent={['unset', 'space-between']}
            marginRight="xs"
          >
            <Text as="li" variant="p1" className="list-none pl-xs">
              • Vendor sourcing and price negotiation.
            </Text>
            <Text as="li" variant="p1" className="list-none pl-xs">
              • Expert coordination with technicians ensuring seamless collaboration and efficient task allocation.
            </Text>
            <Text as="li" variant="p1" className="list-none pl-xs">
              • Quality guarantee. If the work is not properly done, we will do the work again until it's done right.
            </Text>
          </Flex>
          <Box style={{ margin: '2rem 0 0.25rem 0' }}>
            <Text fontWeight="semibold">
              {POST_INSPECTION_FLOW_STRINGS['approvals.projectManagementFee.benefits.title']}
            </Text>
          </Box>
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            justifyContent={['unset', 'space-between']}
            marginRight="xs"
          >
            <Text as="li" variant="p1" className="list-none pl-xs">
              • We get you the most competitive prices given the scale of our network and negotiating leverage.
            </Text>
            <Text as="li" variant="p1" className="list-none pl-xs">
              • We make your home market-ready 50% faster on average, significantly reducing your vacancy cost.
            </Text>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

ProjectManagementBundle.propTypes = propTypes;
ProjectManagementBundle.defaultProps = defaultProps;

export default ProjectManagementBundle;
