import { MaintenanceModel, MaintenanceWorkOrderModel } from 'api/models';
import { LabelColorType } from 'components/Label/labelTypes';

type MaintenanceStatusValue =
  | MaintenanceModel['status']
  | MaintenanceModel['result']
  | MaintenanceModel['urgency']
  | MaintenanceWorkOrderModel['status'];

export const MaintenanceStatus: Record<MaintenanceStatusValue, string> = {
  // MaintenanceModel['status']
  New: 'New',
  InProgress: 'In Progress',
  Closed: 'Closed',
  // MaintenanceModel['result']
  Cancelled: 'Cancelled',
  Completed: 'Completed',
  Duplicate: 'Duplicate',
  Postponed: 'Postponed',
  WorkingAsDesigned: 'Working As Designed',
  NotRecommended: 'Not Recommended',
  NotApproved: 'Not Approved',
  NotReproducible: 'Not Reproducible',
  RevertNotice: 'Revert Notice',
  // MaintenanceModel['urgency']
  NotSpecified: 'NotSpecified',
  Emergency: 'Emergency',
  Urgent: 'Urgent',
  Routine: 'Routine',
  AssigningPro: 'Assigning Pro',
  AppointmentScheduled: 'Scheduled',
  WorkInProgress: 'Work In Progress',
  PreparingQuote: 'Preparing Quote',
  WaitingForQuoteApproval: 'Approval Required',
  PreparingInvoice: 'PreparingInvoice',
  Canceled: 'Cancelled',
} as const;

type WorkOrderStatusItem = {
  color: string;
  label: string;
};

export const workOrderStatus: Record<MaintenanceWorkOrderModel['status'], WorkOrderStatusItem> = {
  AppointmentScheduled: {
    color: 'text-blue',
    label: 'Appointment Scheduled',
  },
  AssigningPro: {
    color: 'text-blue',
    label: 'Assigning Pro',
  },
  Canceled: {
    color: 'text-red',
    label: 'Cancelled',
  },
  Completed: {
    color: 'text-green',
    label: 'Completed',
  },
  New: {
    color: 'text-navy',
    label: 'New',
  },
  PreparingInvoice: {
    color: 'text-blue',
    label: 'Preparing Invoice',
  },
  PreparingQuote: {
    color: 'text-blue',
    label: 'Preparing Quote',
  },
  WaitingForQuoteApproval: {
    color: 'text-red',
    label: 'Approval Required',
  },
  WorkInProgress: {
    color: 'text-blue',
    label: 'In Progress',
  },
};

export const workOrderStatusLabel: Record<MaintenanceWorkOrderModel['status'], LabelColorType> = {
  AppointmentScheduled: 'blue',
  AssigningPro: 'blue',
  Canceled: 'red-border',
  Completed: 'green',
  New: 'navy',
  PreparingInvoice: 'blue',
  PreparingQuote: 'blue',
  WaitingForQuoteApproval: 'red-border',
  WorkInProgress: 'blue',
};
