import HomeownerOnboardingInfoModel from 'models/homeownerOnboarding/HomeownerOnboardingInfoModel';
import { createSelector } from 'reselect';
import { HOMEOWNER_ONBARDING_LEADRS_REDUCER } from './leads-reducer';
import { HOMEOWNER_ONBARDING_REDUCER } from './reducer';

const selectHomeownerOnboardingLeads = (state) => state[HOMEOWNER_ONBARDING_LEADRS_REDUCER];
const selectHomeownerOnboarding = (state) => state[HOMEOWNER_ONBARDING_REDUCER];
const _selectOnboardingInfo = (state) => selectHomeownerOnboarding(state).onboardingInfo;

export const selectAddresses = (state) => selectHomeownerOnboardingLeads(state).addresses;

export const selectOnboardingProperties = (state) => selectHomeownerOnboarding(state).properties;

export const selectOnboardingInfo = createSelector(
  _selectOnboardingInfo,
  (info) => new HomeownerOnboardingInfoModel(info)
);
