import { PATHS } from '@belong/common';
import { Text } from '@belong/ui';
import { DEFAULT_LINKS } from 'consts/links';
import LinkBase from 'corecomponents/LinkBase/LinkBase';

export function ContactUs() {
  return (
    <>
      <div className="flex md:hidden mb-xl">
        <LinkBase href={PATHS.SUPPORT}>
          <Text className="text-navy" variant="h3" fontWeight="semibold">
            {DEFAULT_LINKS.GET_HELP_NEW.label}
          </Text>
        </LinkBase>
      </div>
      <div className="hidden md:flex lg:ml-sm">
        <LinkBase href={PATHS.SUPPORT}>
          <Text variant="p1" fontWeight="semibold">
            GET HELP 24/7
          </Text>
        </LinkBase>
      </div>
    </>
  );
}
