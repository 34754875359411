import Image from 'components/Image/Image';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';

type MobileTipButtonProps = {
  onClick?: () => void;
}

export const MobileTipButton = ({ onClick }: MobileTipButtonProps): JSX.Element => {
  return (
    <ButtonBase onClick={onClick} className="flex justify-end relative cursor-pointer z-10 lg:hidden">
      <Image src="/common/light.svg" alt="Tip Light" />
    </ButtonBase>
  );
};
