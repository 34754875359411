import { Component } from 'react';
import classNames from 'classnames/bind';
import Checkbox from 'components/Checkbox/Checkbox';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import String from 'components/String/String';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import HocFocusVisible from 'hooks/useFocus/HocFocusVisible';
import FocusVisibleManager from 'hooks/useFocus/useFocusManager';
import { xor, includes } from 'lodash-es';
import PropTypes from 'prop-types';
import LinkBase from '../../corecomponents/LinkBase/LinkBase';
import styles from './Selector.module.css';

const cx = classNames.bind(styles);

export const SELECTOR_TYPES = {
  SELECTOR: 'selector',
  BUTTONS: 'buttons',
  MEDIUMTEXTBUTTON: 'mediumtextbutton',
  SMALLTEXTBUTTON: 'smalltextbutton',
  VERTICAL: 'vertical',
  SMALLTEXTBUTTONWIDTH: 'smalltextbuttonwidth',
  THIN_BORDER_BUTTON: 'thinborderbutton',
  THIN_BORDER_BUTTON_FULL_WIDTH: 'thinborderbuttonfullwidth',
};

export const GRID_SIZE = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  SMALL: 'small',
  EXTRA_SMALL: 'extra-small',
};

export const RESPONSIVE_TYPES = {
  RESPONSIVE: 'responsive',

  // only works for mobile
  REVERSE_MOBILE_RESPONSIVE: 'reverse-responsive',
};

class BaseSelector extends Component {
  static propTypes = {
    // Button is an object of strings that have values of a label and icon
    buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
    // type of selector used. For now it's going to default to selectorImage,
    // in the future I want to replace this with an icon and size and make this obsolete.
    type: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onSelect: PropTypes.func,

    // Fluid determines if the boxes are meant to hit the total width or not
    fluid: PropTypes.bool,
    grid: PropTypes.bool,
    gridSize: PropTypes.oneOf(Object.values(GRID_SIZE)),
    // Disable selector buttons
    disabled: PropTypes.bool,
    responsive: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(Object.values(RESPONSIVE_TYPES))]),
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    tagClassName: PropTypes.string,
    label: PropTypes.node,
    verticalSpaceBetween: PropTypes.string,
    error: PropTypes.bool,
    flexWrap: PropTypes.bool,
    deselectable: PropTypes.bool,
    multiSelect: PropTypes.bool,
    tabIndex: PropTypes.string,
    onFocusVisible: PropTypes.func.isRequired,
    onFocusBlur: PropTypes.func.isRequired,
    focusVisible: PropTypes.bool.isRequired,
    autoFocus: PropTypes.number,
    horizontalOnDesktop: PropTypes.bool,
    mobileTag: PropTypes.bool,
  };

  static defaultProps = {
    type: 'selector',
    selected: null,
    onSelect: () => {},
    fluid: false,
    grid: false,
    gridSize: GRID_SIZE.DEFAULT,
    disabled: false,
    responsive: true,
    className: '',
    buttonClassName: '',
    iconClassName: '',
    labelClassName: '',
    tagClassName: '',
    label: null,
    verticalSpaceBetween: '',
    error: false,
    flexWrap: false,
    deselectable: false,
    tabIndex: '0',
    autoFocus: null,
    multiSelect: false,
    mobileTag: false,
  };

  constructor(props) {
    super(props);

    let { selected } = props;

    if (!selected && props.multiSelect) {
      selected = [];
    }

    this.state = {
      selectedKey: selected,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ('selected' in nextProps) {
      let { selected } = nextProps;

      if (!selected && nextProps.multiSelect) {
        selected = [];
      }
      return {
        selectedKey: selected,
      };
    }

    return {
      ...prevState,
    };
  }

  // Selects new portions of array
  onClick = (button) => {
    const { selectedKey } = this.state;
    const { onSelect, deselectable, multiSelect } = this.props;

    if (multiSelect) {
      const selected = xor(selectedKey, [button.key]);
      this.setState({ selectedKey: selected });
      onSelect(selected);
    } else if (deselectable && selectedKey === button.key) {
      this.setState({ selectedKey: null });
      onSelect();
    } else {
      this.setState({
        selectedKey: button.key,
      });
      onSelect(button.key, button);
    }
  };

  render() {
    const {
      buttons,
      type,
      fluid,
      grid,
      gridSize,
      disabled,
      responsive,
      className,
      buttonClassName,
      iconClassName,
      labelClassName,
      tagClassName,
      label,
      verticalSpaceBetween,
      error,
      tabIndex,
      flexWrap,
      onFocusVisible,
      onFocusBlur,
      focusVisible,
      autoFocus,
      multiSelect,
      horizontalOnDesktop,
      mobileTag,
    } = this.props;

    let responsiveString = 'responsive';

    if (typeof responsive === 'string') {
      responsiveString = responsive;
    }

    const { selectedKey } = this.state;

    let arraysShouldStartAtOneSoIDontHaveToCodeThis;

    if (selectedKey !== null) {
      arraysShouldStartAtOneSoIDontHaveToCodeThis = true;
    }

    return (
      <div className={cx('selector-detail-wrapper')}>
        {label && <div className={cx('selector-label')}>{label}</div>}
        <div
          style={{
            marginBottom: `-${verticalSpaceBetween}`,
          }}
          className={`${cx(
            'selector-wrapper',
            { fluid },
            { flexWrap },
            { grid, [gridSize]: grid, [responsiveString]: responsive }
          )} ${className}`}
        >
          {buttons.map((n, index) => {
            let selectorLabel = typeof n.label === 'string' ? <String string={n.label} /> : n.label;
            const isSelected = !multiSelect ? n.key === selectedKey : includes(selectedKey, n.key);
            if (n.checkbox)
              selectorLabel = (
                <div className={cx('checkbox-wrapper')}>
                  {selectorLabel}
                  <div className={cx('checkbox')}>
                    <Checkbox useOnChangeFormToHandle value={isSelected} onSelect={() => this.onClick(n)} />
                  </div>
                </div>
              );
            return [
              n.a ? (
                <LinkBase
                  a={n.a}
                  label={selectorLabel}
                  style={{
                    marginBottom: verticalSpaceBetween,
                  }}
                  autoFocus={!!(!selectedKey && autoFocus === index)}
                  tabIndex={tabIndex}
                  className={`${cx(
                    'selector',
                    { horizontalOnDesktop },
                    { focusVisible },
                    { [type]: type !== SELECTOR_TYPES.SELECTOR },
                    { selected: isSelected },
                    { flexGrow: fluid },
                    { flexWrap },
                    { makeGrayIfNotSelected: arraysShouldStartAtOneSoIDontHaveToCodeThis },
                    { disabled: disabled || n.disabled },
                    { [responsiveString]: responsive },
                    { error },
                    'make-flex',
                    { hasSublabel: !!n.subLabel }
                  )} ${buttonClassName}`}
                  selected={n.selected}
                  onClick={() => this.onClick(n)}
                  key={`${n.key}`}
                  disabled={disabled || n.disabled}
                >
                  {n.icon && (
                    <span className={cx('icon', iconClassName, { horizontalOnDesktop })}>
                      {isSelected ? n.iconSelected : n.icon}
                    </span>
                  )}
                  {n.subLabel ? (
                    <div className={cx('label-sub-label-wrapper', { centerText: !!n.centerText })}>
                      <div className={`${cx('padding', 'label', { iconlabel: n.icon })} ${labelClassName}`}>
                        {selectorLabel || n.children}
                      </div>
                      <div className={cx(['padding', 'sub-label', { withIcon: !!n.icon }])}>{n.subLabel}</div>
                    </div>
                  ) : (
                    <div className={`${cx('padding', 'label', { iconlabel: n.icon })} ${labelClassName}`}>
                      {selectorLabel || n.children}
                    </div>
                  )}
                </LinkBase>
              ) : (
                <ButtonBase
                  label={selectorLabel}
                  tabIndex={tabIndex}
                  autoFocus={!!(!selectedKey && autoFocus === index)}
                  action={n.action}
                  className={`${cx(
                    'selector',
                    { horizontalOnDesktop },
                    { locked: n.locked },
                    { focusVisible },
                    { [type]: type !== SELECTOR_TYPES.SELECTOR },
                    { selected: isSelected },
                    { flexGrow: fluid },
                    { flexWrap },
                    { makeGrayIfNotSelected: arraysShouldStartAtOneSoIDontHaveToCodeThis },
                    { disabled: disabled || n.disabled },
                    { [responsiveString]: responsive },
                    { error },
                    { hasSublabel: !!n.subLabel }
                  )} ${buttonClassName}`}
                  selected={n.selected}
                  onFocus={() => {
                    if (isSelected) {
                      onFocusVisible();
                    }
                  }}
                  onBlur={() => {
                    if (isSelected) {
                      onFocusBlur();
                    }
                  }}
                  onClick={() => this.onClick(n)}
                  key={`${n.key}`}
                  disabled={disabled || n.disabled}
                  style={{
                    marginBottom: verticalSpaceBetween,
                  }}
                >
                  {n.tag && (
                    <span className={cx('tag', mobileTag && 'mobile-tag', tagClassName, n.tagClassName)}>{n.tag}</span>
                  )}
                  {n.icon && (
                    <span className={cx('icon', iconClassName, { horizontalOnDesktop })}>
                      {isSelected ? n.iconSelected : n.icon}
                      {n.iconHorizontalLabel && n.iconHorizontalLabel}
                    </span>
                  )}
                  {n.subLabel ? (
                    <div className={cx('label-sub-label-wrapper', { centerText: !!n.centerText })}>
                      <div className={`${cx('padding', 'label', { iconlabel: n.icon })} ${labelClassName}`}>
                        {selectorLabel || n.children}
                      </div>
                      <div className={cx(['padding', 'sub-label', { withIcon: !!n.icon }])}>{n.subLabel}</div>
                    </div>
                  ) : (
                    <div className={`${cx('padding', 'label', { iconlabel: n.icon })} ${labelClassName}`}>
                      {selectorLabel || n.children}
                    </div>
                  )}
                  {n.locked && (
                    <div className={cx('lock')}>
                      <GeneralIcon color={COLORS.GRAY} icon={GENERAL_ICONS.ICON_LOCKED} />
                    </div>
                  )}
                </ButtonBase>
              ),
            ];
          })}
        </div>
      </div>
    );
  }
}

const HocSelector = HocFocusVisible(BaseSelector);

const Selector = ({ ...props }) => (
  <FocusVisibleManager>
    <HocSelector {...props} />
  </FocusVisibleManager>
);

export default Selector;

export const SelectorFinalFormAdapter = ({ input, meta, ignoreError, onChangeCustom, customCallback, ...rest }) => {
  const controlledProps = {};

  if (!ignoreError) {
    controlledProps.error = !!meta.error;
  }

  if (rest.disabled) {
    controlledProps.error = undefined;
  }

  return (
    <Selector
      {...input}
      {...rest}
      {...controlledProps}
      selected={input.value}
      onSelect={(key, object) => {
        input.onChange(key, object);

        if (onChangeCustom) {
          onChangeCustom(key, object);
        }
      }}
    />
  );
};

SelectorFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func,
  customCallback: PropTypes.func,
  ignoreError: PropTypes.bool,
};

SelectorFinalFormAdapter.defaultProps = {
  onChangeCustom: () => {},
  customCallback: () => {},
  ignoreError: true,
};
