import React, { Component } from 'react';
import { Form } from 'react-final-form';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import SetPasswordField from 'formcomponents/SetPasswordField/SetPasswordField';
import PropTypes from 'prop-types';
import styles from './ForgotPasswordResetPassword.module.css';

const cx = classNames.bind(styles);

class ForgotPasswordResetPassword extends Component {
  static title = "Let's reset <span>your password!</span>";

  static description = 'What should your new <span>password be?</span>';

  static newUserTitle = 'Become a <span>Belong Member.</span>';

  static newUserDescription = "Let's set up your account, starting with <span>a password.</span>";

  static setPasswordAfterAgreementTitle = `Congrats! You're a <span>Belong Member!</span>`;

  static setPasswordAfterAgreementDescription =
    "Now, let's set up your account, starting with <span>a password.</span>";

  static closeButton = false;

  static propTypes = {
    handleForgotPasswordResetPasswordSubmit: PropTypes.func.isRequired,
    resetToken: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isNewUser: PropTypes.bool.isRequired,
  };

  handleSubmit = (newPasswordObject) => {
    const { resetToken, email } = this.props;
    const { password } = newPasswordObject;

    const resetPasswordObject = {
      code: resetToken,
      email,
      password,
    };

    this.props.handleForgotPasswordResetPasswordSubmit(resetPasswordObject);
  };

  render() {
    const { isNewUser } = this.props;

    return (
      <div className={cx('fp-reset-password-content-wrapper')}>
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit} className={cx('fp-reset-password-screen')}>
              <Row>
                <Col md={12}>
                  <SetPasswordField />
                </Col>
              </Row>
              <div className={cx('reset-password-button')}>
                <Button type="submit" label={isNewUser ? 'Create Account' : 'Update Password'} disabled={invalid} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default ForgotPasswordResetPassword;
