import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import ReactLottie from 'forkedlibraries/ReactLottie/ReactLottie';
import PropTypes from 'prop-types';
import { animationsCache } from '../animationsCache';
import { ASSETS_LOCATION } from '../consts';
import styles from './LazyLottieAnimation.module.css';

const cx = classNames.bind(styles);

const getDefaultLottieOptions = (animationData) => ({
  loop: false,
  autoplay: true,
  animationData,
});

const lottieDefaultStyles = {
  height: 'auto',
};

export const LazyLottieAnimation = ({
  name,
  breakpoint,
  options,
  optionsMobile,
  style,
  styleMobile,
  containerClassName,
  containerClassNameMobile,
  ...rest // Other lottie props like getDuration and goToAndStopValue, see react lottie docs.
}) => {
  const [animation, setAnimation] = useState(null);
  const [mobileAnimation, setMobileAnimation] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _animation = await animationsCache.read(name);
        const animationSrc = ASSETS_LOCATION[name];

        if (typeof animationSrc === 'string') {
          setAnimation(_animation);
        } else {
          setAnimation(_animation.DESKTOP);
          setMobileAnimation(_animation.MOBILE);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  // When there are two animations, one for mobile and one for desktop, we switch at breakpoint
  if (animation && mobileAnimation) {
    return (
      <>
        <div className={cx('container-desktop', containerClassName, breakpoint)}>
          {animation ? (
            <ReactLottie
              options={{ ...getDefaultLottieOptions(animation), ...options }}
              style={{ ...lottieDefaultStyles, ...style }}
              {...rest}
            />
          ) : null}
        </div>

        <div className={cx('container-mobile', containerClassNameMobile, breakpoint)}>
          {mobileAnimation ? (
            <ReactLottie
              options={{ ...getDefaultLottieOptions(mobileAnimation), ...optionsMobile }}
              style={{ ...lottieDefaultStyles, ...styleMobile }}
              {...rest}
            />
          ) : null}
        </div>
      </>
    );
  }

  // When only one animation asset, show for every screen
  return animation ? (
    <div className={cx(containerClassName)}>
      <ReactLottie
        options={{ ...getDefaultLottieOptions(animation), ...options }}
        style={{ ...lottieDefaultStyles, ...style }}
        {...rest}
      />
    </div>
  ) : null;
};

LazyLottieAnimation.propTypes = {
  name: PropTypes.oneOf(Object.keys(ASSETS_LOCATION)).isRequired,
  breakpoint: PropTypes.oneOf(['sm', 'md', 'lg']),
  options: PropTypes.object,
  optionsMobile: PropTypes.object,
  style: PropTypes.object,
  styleMobile: PropTypes.object,
  containerClassName: PropTypes.string,
  containerClassNameMobile: PropTypes.string,
};

LazyLottieAnimation.defaultProps = {
  breakpoint: 'sm',
  options: {},
  optionsMobile: {},
  style: {},
  styleMobile: {},
  containerClassName: '',
  containerClassNameMobile: '',
};
