import { find } from 'lodash-es';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';

export const STEPS_CONFIGURATION = [
  {
    name: 'Plan',
    displayName: 'Plan',
    key: 'plans',
  },
  {
    name: 'UserPersonal',
    displayName: 'Personal',
    key: 'personal',
  },

  {
    name: 'Ownership',
    displayName: 'Ownership',
    key: 'ownership',
  },
  {
    name: 'Payment',
    displayName: 'Payments',
    key: 'payment',
  },
  {
    name: 'Sign',
    displayName: 'Sign',
    key: 'sign',
  },
];

export const getStepByName = (name) => find(STEPS_CONFIGURATION, { name });

export const AGREEMENT_SUMARY = [
  {
    image: '/agreement-flow/cancel-anytime.svg',
    title: AGREEMENT_FLOW_STRINGS['sign.cancelAnytime'],
    description: AGREEMENT_FLOW_STRINGS['sign.cancelAnytime.description'],
  },
  {
    image: '/agreement-flow/guaranteed-rent.svg',
    title: AGREEMENT_FLOW_STRINGS['sign.guaranteedRent'],
    description: AGREEMENT_FLOW_STRINGS['sign.guaranteedRent.description'],
  },
  {
    image: '/agreement-flow/eviction-protection.svg',
    title: AGREEMENT_FLOW_STRINGS['sign.evictionProtection'],
    description: AGREEMENT_FLOW_STRINGS['sign.evictionProtection.description'],
  },
  {
    image: '/agreement-flow/personal-concierge.svg',
    title: AGREEMENT_FLOW_STRINGS['sign.personalConcierge'],
    description: AGREEMENT_FLOW_STRINGS['sign.personalConcierge.description'],
  },
  {
    image: '/agreement-flow/trusted-vendors.svg',
    title: AGREEMENT_FLOW_STRINGS['sign.trustedVendors'],
    description: AGREEMENT_FLOW_STRINGS['sign.trustedVendors.description'],
  },
  {
    image: '/agreement-flow/support.svg',
    title: AGREEMENT_FLOW_STRINGS['sign.allDaySupport'],
    description: AGREEMENT_FLOW_STRINGS['sign.allDaySupport.description'],
  },
];
