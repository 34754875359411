import { Fragment } from 'react';
import { DisclosureFormField } from 'accounts/components/form-fields/disclosure';
import { HomeDisclosuresDocumentation } from 'accounts/components/home-disclosures-documentation';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { DISCLOSURES_LIST, IGNORE_COMMENT_FIELDS } from 'accounts/constants/home-disclosures';
import { HOME_DISCLOSURES_STRINGS } from 'accounts/constants/strings/home-disclosures.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import { MultiSelectableFinalFormAdapter } from 'components/MultiSelectable/MultiSelectable';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { findIndex } from 'lodash-es';
import { Disclosures } from 'models/enums';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';

export function HomeDisclosures() {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const { currentStep, currentStepFormData, selectedProperty, unitId, goPreviousStep, goNextStep, handleSave } =
    useSetupFlowStep();

  const address = currentStep?.property?.address || {};
  const { units } = selectedProperty || {};
  const currentUnitIndex = findIndex(units, (unit: any) => unit.basicInfo.unitId === unitId);
  const availableDisclosures = DISCLOSURES_LIST.filter((d) =>
    currentStepFormData?.availableDisclosures?.includes(d?.name)
  );
  const filteredDisclosuresList =
    currentStepFormData?.hasMortgage === false &&
    !currentStepFormData?.disclosures?.types?.[Disclosures.MortgageRequiresFloodInsurance]
      ? availableDisclosures.filter((d) => d.name !== Disclosures.MortgageRequiresFloodInsurance)
      : [...availableDisclosures];

  const doneLabel =
    units?.length > 1 && currentUnitIndex !== units?.length - 1 && !units?.[currentUnitIndex + 1]?.isFarOffMoveout
      ? HOME_DISCLOSURES_STRINGS['done_label.units']
      : HOME_DISCLOSURES_STRINGS.done_label;

  function getInitialValues() {
    if (!currentStepFormData) {
      return {};
    }

    const { disclosures = {} } = currentStepFormData;

    if (disclosures.types) {
      Object.keys(disclosures.types).forEach((type) => {
        disclosures.types[type].selected = true;
      });
    } else {
      disclosures.types = {};
    }

    return { disclosures };
  }

  async function handleSubmit(values) {
    Object.keys(values.disclosures.types).forEach((type) => {
      if (values.disclosures.types[type].selected === false) {
        delete values.disclosures.types[type];
      }
    });

    const fetchedData = await handleSave(values);

    if (fetchedData?.document && fetchedData.document.isSigned === false) {
      setModalOpen(true);
    } else {
      goNextStep();
    }
  }

  return (
    <Fragment>
      <StepFormLayout
        handleBackStep={goPreviousStep}
        onSubmit={handleSubmit}
        title={HOME_DISCLOSURES_STRINGS.title}
        subTitle={formatString(HOME_DISCLOSURES_STRINGS.subtitle, {
          address: addressAndUnit(address, address?.unitNumber),
        })}
        doneLabel={doneLabel}
        initialValues={getInitialValues()}
        getForm={({ form }) => (
          <FormLayout.Section>
            <Row>
              <Col md={12}>
                <Field
                  component={MultiSelectableFinalFormAdapter}
                  name="disclosures.types"
                  showTrashButton={false}
                  showCheckmark
                  options={filteredDisclosuresList.map((item) => ({
                    ...item,
                    formFields: !IGNORE_COMMENT_FIELDS.includes(item.name) ? <DisclosureFormField item={item} /> : null,
                  }))}
                  form={form}
                />
              </Col>
            </Row>
          </FormLayout.Section>
        )}
      />
      <HomeDisclosuresDocumentation showModal={isModalOpen} onHide={setModalClose} />
    </Fragment>
  );
}
