import React, { FunctionComponent } from 'react';
import { IconRoundCheck, IconAlert, IconCalendar } from '@belong/icons';
import { BillInfoModel, TransferInfoModel } from 'api/models';
import classnames from 'classnames/bind';
import styles from './bill-status-icon.module.css';

type BillStatusIconProps = {
  status: BillInfoModel['status'] | TransferInfoModel['status'];
};

const cx = classnames.bind(styles);

export function BillStatusIcon({ status }: BillStatusIconProps) {
  const Icon = ICONS[status] || IconCalendar;
  const bgColor = BG_COLORS[status] || 'bg-blue';

  return (
    <div className={cx('wrapper', 'flex items-center justify-center rounded-full', bgColor)}>
      <Icon height={15} className="text-white" />
    </div>
  );
}

const BG_COLORS: Record<BillInfoModel['status'] | TransferInfoModel['status'], string> = {
  Failed: 'bg-red',
  Paid: 'bg-green',
  PartiallyRefunded: 'bg-blue',
  Published: 'bg-blue',
  Refunded: 'bg-gray',
  Unpublished: 'bg-blue',
  Void: 'bg-blue',
  Initiated: 'bg-blue',
  Canceled: 'bg-red',
  Completed: 'bg-green',
};

const ICONS: Record<
  BillInfoModel['status'] | TransferInfoModel['status'],
  FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  Failed: IconAlert,
  Paid: IconRoundCheck,
  PartiallyRefunded: IconCalendar,
  Published: IconCalendar,
  Refunded: IconCalendar,
  Unpublished: IconCalendar,
  Void: IconCalendar,
  Initiated: IconCalendar,
  Canceled: IconCalendar,
  Completed: IconCalendar,
};
