import { Text } from "@belong/ui";
import { USER_STEPS_STRINGS } from "accounts/constants/strings/steps.strings";
import GreenInfoBox from "components/GreenInfoBox/GreenInfoBox";
import Image from "components/Image/Image";

type NextUnitBannerStepProps = {
  unitAddress: string;
}

export function NextUnitBannerStep({ unitAddress }: NextUnitBannerStepProps) {
  return (
    <GreenInfoBox>
      <div className="flex">
        <div className="mr-sm">
          <Image src="https://belong-web-assets.s3.us-west-2.amazonaws.com/common_assets/setup_confetti_icon.svg" />
        </div>
        <div className="flex flex-col justify-center">
          <Text as="span" fontWeight="semibold">
            {USER_STEPS_STRINGS["next_unit_banner.title"]}
          </Text>
          <Text variant="p1" fontWeight="semibold">
            {unitAddress}
          </Text>
        </div>
      </div>
    </GreenInfoBox>
  )
}
