import type { ComponentProps } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Modal, Wizard } from '@belong/ui';
import { STEPS } from 'accounts/constants/reschedule-appointment';
import { RescheduleAppointmentContextProvider } from 'accounts/contexts/reschedule-appointment';
import type { UnitSummaryModel } from 'api/models';
import styles from './reschedule-appointment-modal.module.css';

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onDismiss'> & {
  coverageRegionId: number;
  timeZone?: string;
  unitId: string;
  unit: UnitSummaryModel;
};

export function RescheduleAppointmentModal({
  coverageRegionId,
  isOpen,
  onDismiss,
  timeZone = 'America/Los_Angeles',
  unitId,
  unit,
}: Props) {
  const { width } = useWindowSize();

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  return (
    <Modal
      isOpen={isOpen}
      name="reschedule_appointment_modal"
      onDismiss={onDismiss}
      overlayClassName={styles.overlay}
      size={isMobile ? 'fullScreen' : 'default'}
      contentClassName={styles.modal}
    >
      <div className="pt-2xl">
        <RescheduleAppointmentContextProvider
          coverageRegionId={coverageRegionId}
          onDismiss={onDismiss}
          timeZone={timeZone}
          unit={unit}
          unitId={unitId}
        >
          <Wizard>
            {STEPS.map(({ Component, id }) => (
              <Wizard.Step hideNavigator hideStepper id={id} key={id}>
                <Component />
              </Wizard.Step>
            ))}
          </Wizard>
        </RescheduleAppointmentContextProvider>
      </div>
    </Modal>
  );
}
