import React, { Component, Fragment } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { Tag } from 'components/HomeTag/HomeTag';
import { MENU_TYPES, menuShapeItem, menuShape } from 'components/Menu/menuTypes';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import styles from './Menu.module.css';

const cx = classNames.bind(styles);

const MENU_ITEMS_LIMIT = 5;

const MenuItem = ({ item, menuType, menuItems, onClick, onSelect, selected, menuControlled }) => {
  let removePadding;

  if (item.header === '') {
    removePadding = true;
  }

  const inStyleChanges = `${menuType}Style`;
  const MenuItemComponent = item.to ? LinkBase : ButtonBase;

  let hideTransparentBackground = false;

  if (menuType === MENU_TYPES.BORDER || menuType === MENU_TYPES.BORDER_NESTED) {
    hideTransparentBackground = true;
  }
  return (
    // It might be better to use a react fragment if the header is blank?
    <li className={cx('default-background relative', { hideTransparentBackground })}>
      {item.header && (
        <Fragment>
          {item.redIndicator && <div className={cx('redIndicator', { selected })} />}
          <MenuItemComponent
            to={item.to}
            onClick={() => {
              onClick(item);
              onSelect(item);
              item.onClick?.();
            }}
            className={cx('menuItem', inStyleChanges, { selected }, { removePadding })}
          >
            {item.header}
            {item.new && <Tag className={cx('tag-new')}>NEW</Tag>}
          </MenuItemComponent>
        </Fragment>
      )}
      {menuItems.length > 0 && (
        <Menu
          onSelect={onSelect}
          controlled={menuControlled}
          selected={item.selected}
          alwaysOpen={item.alwaysOpen}
          menuItems={menuItems}
          menuType={item.menuType}
          menuHideOverFive={item.menuHideOverFive}
        />
      )}
    </li>
  );
};
MenuItem.propTypes = menuShapeItem;
MenuItem.defaultProps = {
  callKeyOnSelect: () => {},
  onClick: () => {},
  selectKey: -1,
  fetchSelect: false,
  menuHideOverFive: false,
  menuItems: [],
  menuType: MENU_TYPES.BACKGROUND,
  new: false,
};

export default class Menu extends Component {
  static propTypes = menuShape;

  static defaultProps = {
    menuType: MENU_TYPES.BACKGROUND,
    menuHideOverFive: false,
    onSelect: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected,
      hideOverFive: props.menuHideOverFive,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (!nextProps.controlled) {
      return null;
    }

    return {
      selected: nextProps.selected,
    };
  }

  handleClick = (item) => {
    this.setState({ selected: item.key });
  };

  showMoreThanFive = () => {
    this.setState({
      hideOverFive: false,
    });
  };

  render() {
    const { menuItems, menuType, onSelect, controlled } = this.props;
    const { selected, hideOverFive } = this.state;
    const limitedMenuItems = !hideOverFive ? menuItems : menuItems.slice(0, MENU_ITEMS_LIMIT);
    return (
      <ul className={cx('menu', menuType)}>
        {limitedMenuItems.map((item, index) => {
          return (
            <MenuItem
              key={`${item.key} ${index}`}
              item={item}
              menuItems={selected === item.key || item.alwaysOpen ? item.menuItems : []}
              menuType={menuType}
              menuControlled={controlled}
              onClick={this.handleClick}
              onSelect={onSelect}
              selected={selected === item.key}
              new={item.new}
            />
          );
        })}
        {hideOverFive && menuItems.length - limitedMenuItems.length > 0 && (
          <button className={cx('showMoreThanFive')} onClick={this.showMoreThanFive} type="button">
            SHOW MORE (+{menuItems.length - limitedMenuItems.length})
            <span className={cx('icon')}>
              <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
            </span>
          </button>
        )}
      </ul>
    );
  }
}
