export const fetchBaseResources = () => async (dispatch) => {
  return dispatch(() => {});
};

export const generateAgreement = () => ({
  promise: ({ client }) => client.post(`/homeowners/agreements/generate`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const fetchAgreementFlowById = (flowId) => ({
  promise: ({ client }) => client.get(`/homeowners/agreements/${flowId}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const fetchAgreementFlowStep = (flowId, stepKey, stepId) => ({
  promise: ({ client }) => client.get(`/homeowners/agreements/${flowId}/${stepKey}/${stepId}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const updateAgreementFlowStep = (flowId, stepKey, stepId, stepData = {}) => ({
  promise: ({ client }) =>
    client.put(`/homeowners/agreements/${flowId}/${stepKey}/${stepId}`, {
      ...stepData,
    }),
  auth: true,
  redirectToHomeOnHide: true,
});

export const fetchAgreementPdfUrl = (documentId) => ({
  promise: ({ client }) => client.get(`document-instances/${documentId}/download`),
});
