import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './GrayBorderedBox.module.css';

const cx = classNames.bind(styles);

export default class GrayBorderedBox extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return <div className={cx('grayBorderedBox')}>{children}</div>;
  }
}
