import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconSplitMultiple, IconCheck } from '@belong/icons';
import { Text, Button } from '@belong/ui';
import classNames from 'classnames/bind';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import PropTypes from 'prop-types';
import { selectSettings } from 'store/redux/settings';
import 'store/redux/settings/';
import { updateUserAutoSplitPaymentMethod } from 'store/redux/user/actions';
import styles from './SplitItLearnMore.module.css';

const cx = classNames.bind(styles);

const TEXTS = (value) => [
  'Deducted From Rent Over The Course Of Your Lease',
  'No Credit Check Required',
  'Simpler Than A Home Equity Line Of Credit',
  // eslint-disable-next-line no-irregular-whitespace
  `Lower Interest Than Your Credit Card (${value}% APR)`,
  'Close Out At Anytime With No Additional Fees',
  'Smoother Monthly Earnings',
];

export const SplitItLearnMore = ({ show, onHide, setShowMenu }) => {
  const [autoSplitError, setAutoSplitError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const settings = useSelector(selectSettings);

  const interestRateValue = settings?.commerceSettings?.splitItSettings?.interestRateValue;

  console.log('interestRateValue', { interestRateValue, settings });
  const dispatch = useDispatch();
  const closeModal = () => {
    onHide();
    setShowMenu(true);
  };

  const handleSetUserAutosplit = async () => {
    setIsLoading(true);
    try {
      await dispatch(updateUserAutoSplitPaymentMethod(true));
      setIsLoading(false);
      closeModal();
    } catch (err) {
      console.error(err);
      if (err[0].message.includes('Yearly Payout Plan')) {
        setAutoSplitError('Split It payment method can not be enabled because you are in a Yearly Payout Plan.');
        setTimeout(() => {
          setAutoSplitError(null);
        }, 6000);
      }
      setIsLoading(false);
      return false;
    }
  };

  return (
    <ModalV2 show={show} onHide={closeModal} backButton onBackButtonClick={closeModal}>
      <div className={cx('success-submit-modal-container')}>
        <IconSplitMultiple width={87} height={94} className="mt-lg" />
        <Text fontWeight="semibold" variant="h2" className="mt-xl mb-2sm">
          Split it
        </Text>
        <Text className="text-navy" variant="body">
          Split purchases instantly into monthly installments
        </Text>

        <div className="my-lg">
          {TEXTS(interestRateValue).map((text) => (
            <div key={text} className="flex flex-nowrap items-start mb-[18px]">
              <IconCheck width={20} height={24} className="text-green mr-[8px] w-[20px] h-[24px]" />
              <Text fontWeight="semibold" variant="body" className="flex-1">
                {text}
              </Text>
            </div>
          ))}
          {autoSplitError && <Text className="text-red text-[14px] mb-sm">{autoSplitError}</Text>}
        </div>

        <Button onClick={handleSetUserAutosplit}>Set It!</Button>
      </div>
    </ModalV2>
  );
};

SplitItLearnMore.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.bool,
};

export default SplitItLearnMore;
