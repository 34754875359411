import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IconCaretDown } from '@belong/icons';
import { Text, ButtonBase, Money } from '@belong/ui';
import { GroupBalanceInfoModel } from 'api/models';
import { HomeDetailInfoModel, BillingInfoModel } from 'api/models.v2';
import cx from 'classnames';
import SplitPaymentModal from 'pages/Accounts/Pages/YourPortfolio/Earnings/SplitPaymentModal/SplitPaymentModal';
import { SplitPaymentsBanner } from 'pages/Accounts/Pages/YourPortfolio/Earnings/SplitPaymentsBanner/SplitPaymentsBanner';
import { EARNINGS_STRINGS } from 'pages/Accounts/Pages/YourPortfolio/Earnings/earnings.strings';
import { getBalancesByType } from 'pages/Accounts/utils/get-balances-by-type';
import { simulateSplitPayments, createBillsPayment } from 'store/redux/accounts/actions';
import { selectAccountPropertyByUnitId, selectAccountUnitByUnitId } from 'store/redux/homeowner-accounts/selectors';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';
import Balance from '../balance';
import { useSplitPaymentModal } from '../use-split-payment-modal';

type MonthSummaryProps = {
  address?: string;
  homeDetail: HomeDetailInfoModel;
  onlyHome?: boolean;
  noBorder?: boolean;
  hideSplitIt?: boolean;
  handleFinancingModal?: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowFinanceSuccessModal?: () => void;
  handleShowBalanceDetailsModal?: (
    balance: GroupBalanceInfoModel,
    home: string,
    billingInfo?: BillingInfoModel
  ) => void;
};

export function MonthSummary({
  address,
  homeDetail,
  handleFinancingModal,
  handleShowFinanceSuccessModal,
  handleShowBalanceDetailsModal,
  onlyHome = false,
  noBorder = false,
  hideSplitIt = false,
}: MonthSummaryProps) {
  const isResident = !!address;
  const [showItems, setShowItems] = useState(!!onlyHome);

  const property = useSelector(selectAccountPropertyByUnitId(homeDetail.homeId));
  const unit = useSelector(selectAccountUnitByUnitId(homeDetail.homeId));
  const formattedAddress = address || formatString(addressAndUnit(property?.address, unit?.basicInfo.unitNumber));

  const { expenses, revenues, all } = homeDetail.groupedBalancesPerCategory;

  const { maxCashBack, splitPaymentModal, paymentDone, setSplitPaymentModal, handleBillsPayment } =
    useSplitPaymentModal({
      items:
        expenses?.groupedBalancesInfo
          .filter((item) => Boolean(item))
          .map((balance) => ({ ...balance, balances: balance.balances || [balance] })) || [],
      enabled: !hideSplitIt,
      simulateSplitPayments,
      createBillsPayment,
    });

  const showSplitPaymentBanner = !hideSplitIt && expenses?.groupedBalancesInfo.some((item) => item.isFinanceable);
  const showAutoSplitLabel =
    !hideSplitIt &&
    expenses?.groupedBalancesInfo.filter((item) => item.isFinanceable && item.isAutoSplittable).length ===
      expenses?.groupedBalancesInfo.filter((item) => item.isFinanceable).length;

  const isBetween = (date = new Date(), from = new Date(), to = new Date()) => {
    if (from <= date && to >= date) {
      return true;
    } else {
      return false;
    }
  };
  const balances = useMemo(
    () => getBalancesByType({ expenses, revenues, all, isResident }),
    [expenses, revenues, all, isResident]
  );

  const date = useMemo(() => new Date(new Date().toISOString()), []);
  date.setDate(date.getDate() + 1);

  const shouldShowNewCharge = useMemo(
    () =>
      (expenses &&
        expenses?.groupedBalancesInfo.some(
          (b) => b.dueOn && isBetween(new Date(b.dueOn), new Date(new Date().toISOString()), date)
        )) ||
      (all &&
        all.groupedBalancesInfo.some(
          (b) => b.dueOn && isBetween(new Date(b.dueOn), new Date(new Date().toISOString()), date)
        )),
    [expenses, date, all]
  );

  useEffect(() => {
    setShowItems(!!onlyHome);
  }, [onlyHome]);

  return (
    <>
      <article key={homeDetail.homeId} className="mb-sm">
        {!onlyHome && (
          <ButtonBase onClick={() => setShowItems((prevValue) => !prevValue)} className="block w-full">
            <header
              className={cx(
                'h-[41px] flex justify-between items-start outline outline-transparent rounded-small hover:bg-green-translucent-light hover:outline-4 hover:outline-green-translucent-light'
              )}
            >
              <div className="flex flex-col">
                <Text fontWeight={showItems ? 'semibold' : 'regular'}>{formattedAddress}</Text>
                {shouldShowNewCharge && (
                  <Text variant="p1" className="text-green text-left">
                    {EARNINGS_STRINGS.balance_new_charge}
                  </Text>
                )}
              </div>
              <div className="flex items-start justify-end h-[41px]">
                {!showItems && (
                  <Money
                    className="text-body font-semibold mr-xs"
                    value={homeDetail.total}
                    format="DOLLARS_WITH_CENTS"
                  />
                )}
                <IconCaretDown
                  width={15}
                  className={cx('transition-transform duration-hover ease-linear mt-[7px]', {
                    'rotate-180': showItems,
                  })}
                />
              </div>
            </header>
          </ButtonBase>
        )}
        {showItems && (
          <section
            className={cx(
              onlyHome ? '' : 'border-gray border-solid bg-light-gray p-sm border-2 rounded mb-2xl',
              shouldShowNewCharge && 'mt-sm'
            )}
          >
            {balances.map((type) => {
              if (type.groupedBalancesInfo.length > 0) {
                return (
                  <div className="mb-lg pl-xs lg:pl-sm border-0 border-l-3 border-solid border-gray" key={type.name}>
                    {!isResident && (
                      <div className="flex justify-between">
                        <Text fontWeight="semibold" className="text-h3-sm mb-xs">
                          {`${type.name}`}
                        </Text>
                        <Money
                          className="font-semibold text-h3-sm"
                          value={type.name === 'Expenses' ? `-${type.totalAmount}` : type.totalAmount}
                          format="DOLLARS_WITH_CENTS"
                        />
                      </div>
                    )}
                    <Balance
                      handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
                      handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
                      groupedBalancesInfo={type.groupedBalancesInfo}
                      key={`balance-${homeDetail.homeId}-${type.name}`}
                      index={0}
                      homeId={homeDetail.homeId}
                      billingInfo={homeDetail.billingInfo}
                      showAddress={false}
                      handleFinancingModal={handleFinancingModal}
                      isResident={isResident}
                      noBorder={noBorder}
                      hideSplitIt={hideSplitIt || type.hideSplitIt}
                      totalAmount={type.totalAmount}
                    />
                  </div>
                );
              }

              return null;
            })}

            {!isResident && showSplitPaymentBanner && (
              <div className="mt-sm">
                <SplitPaymentsBanner
                  navigateToSplitPayments={() => setSplitPaymentModal(true)}
                  billGenerationOn={homeDetail.billingInfo?.billGenerationOn}
                  maxCashBack={maxCashBack}
                  showPayButton
                  handleBillsPayment={handleBillsPayment}
                  showAutoSplitLabel={showAutoSplitLabel}
                  message={
                    typeof paymentDone === 'undefined'
                      ? undefined
                      : paymentDone
                      ? 'Payment was successfully done'
                      : 'There was an error processing your payment'
                  }
                />
              </div>
            )}

            <hr className="border-t-[1] border-gray border-dashed" />

            <div className="flex flex-col">
              <div className="flex justify-between pt-2sm">
                <Text fontWeight="semibold" className="text-h3-sm ">
                  {isResident
                    ? EARNINGS_STRINGS['monthly_statement.total_paid']
                    : EARNINGS_STRINGS['monthly_statement.total']}
                </Text>
                <Money className="font-semibold text-h3-sm" value={homeDetail.total} format="DOLLARS_WITH_CENTS" />
              </div>
            </div>
          </section>
        )}
      </article>
      {!isResident && splitPaymentModal && (
        <SplitPaymentModal
          show={splitPaymentModal}
          onHide={() => setSplitPaymentModal(false)}
          loading={false}
          items={expenses.groupedBalancesInfo?.filter((item) => item.isFinanceable)}
          amount={expenses.totalAmount}
          homeId={homeDetail.homeId}
          handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
          billingInfo={homeDetail.billingInfo}
        />
      )}
    </>
  );
}
