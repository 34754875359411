/* eslint-disable react/prop-types */
import React from 'react';
import Input from 'components/Input/Input';
import { maskRemoveWhiteSpace, unmaskRemoveWhiteSpace } from 'components/Input/masks';

export const EmailFinalFormAdapter = ({ ignoreError, input, meta, onChangeCustom, newFieldComponent, ...rest }) => {
  // Used to gray out Input when left.
  const controlledProps = {};
  const { unmask = unmaskRemoveWhiteSpace, mask = maskRemoveWhiteSpace } = rest;

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = !!meta.error;
    } else {
      controlledProps.error = meta.touched ? !!meta.error : false;
    }
  }
  delete rest.unmask;

  return (
    <Input
      name="email"
      placeholder="Email"
      mask={maskRemoveWhiteSpace}
      {...input}
      {...rest}
      onChange={(event) => {
        let { value } = event.target;

        if (unmask) {
          value = unmask(mask(value));
        }

        input.onChange(value);

        if (onChangeCustom) {
          onChangeCustom(value);
        }
      }}
      {...controlledProps}
    />
  );
};
