import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { HomePlanStatus } from 'api/models';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HomeownerAccountUnit from 'models/homeownerAccounts/HomeownerAccountUnit';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';

export const useFullManagementHomes = () => {
  const properties = useSelector(selectAccountProperties) as HomeownerAccountProperty[] | null;

  return useMemo(() => {
    if (properties) {
      return properties.filter((property) =>
        property.units.some((unit: HomeownerAccountUnit) => {
          const plans = unit.homePlans as HomePlanStatus[];
          return plans.some((plan) => plan.planType === 'FullManagement');
        })
      );
    }

    return [];
  }, [properties]);
};
