import { isRequired } from '@belong/common';
import { Form, OptionsRowField, Spinner, Text, TextField } from '@belong/ui';
import { MaintenancePaymentAgreementModel } from 'api/models';
import clsx from 'clsx';
import { ResponsibilityField } from './ResponsibilityField';
import {
  maintenanceAccountResponsibilityStrings,
  rejectionReasons,
} from './maintenance-account-responsibility.constants';
import { FormValues, initialValues, percentages, result } from './maintenance-account-responsibility.form';

type MaintenanceAccountResponsibilityProps = {
  onSubmit: (values: MaintenancePaymentAgreementModel) => Promise<void>;
  showScheduleCall?: boolean;
  shouldShowSharedExpenseOption?: boolean;
  taskOptionNumber?: number;
  roundedTop: boolean;
};

export const MaintenanceAccountResponsibility = ({
  onSubmit,
  showScheduleCall,
  shouldShowSharedExpenseOption = false,
  taskOptionNumber,
  roundedTop,
}: MaintenanceAccountResponsibilityProps) => {
  const handleSubmit = (values: FormValues) => {
    const isApproved = values.result === result.approved || values.result === result.shared;
    const submitValues = {
      isApproved,
      percentage: isApproved
        ? values.result === 'shared'
          ? percentages.fifty
          : percentages.oneHundred
        : percentages.zero,
      notes: isApproved ? null : values.notes,
      rejectionReason: isApproved ? null : values.rejectionReason,
    };
    return onSubmit(submitValues);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ values, isSubmitting }) => {
        const isApproved = values.result === result.approved || values.result === result.shared;
        return (
          <div
            className={clsx(
              'w-full bg-dark-navy pt-sm flex flex-col rounded-b p-sm',
              !isApproved && 'md:items-stretch',
              roundedTop && 'rounded-t'
            )}
          >
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <div className="flex flex-col w-full">
                <Text fontWeight="semibold" className="text-white mb-sm">
                  {maintenanceAccountResponsibilityStrings.title}
                </Text>

                <div>
                  <ResponsibilityField
                    name="result"
                    validate={isRequired}
                    showSharedExpenseOption={shouldShowSharedExpenseOption}
                    taskOptionNumber={taskOptionNumber}
                  />
                  {!isApproved && (
                    <div className="mt-xl md:mb-xl">
                      <Text fontWeight="semibold" className="text-white my-sm">
                        {maintenanceAccountResponsibilityStrings.rejectedSubtitle}
                      </Text>
                      <OptionsRowField<MaintenancePaymentAgreementModel['rejectionReason']>
                        name="rejectionReason"
                        options={rejectionReasons}
                        validate={isRequired}
                      />
                      {values.rejectionReason === 'Other' && (
                        <div className="mt-xl">
                          <TextField
                            label="Reason"
                            name="notes"
                            validate={isRequired}
                            maxLength={200}
                            minLength={10}
                            variant="secondary"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col items-end mt-xl md:mt-xs">
              <Form.SubmitButton size={{ _: 'fluid', md: isApproved ? 'default' : 'large' }}>
                {isApproved
                  ? maintenanceAccountResponsibilityStrings.formSubmit
                  : showScheduleCall
                  ? maintenanceAccountResponsibilityStrings.formSubmitRejection
                  : maintenanceAccountResponsibilityStrings.formSubmit}
              </Form.SubmitButton>
            </div>

            {isSubmitting && <Spinner />}
          </div>
        );
      }}
    </Form>
  );
};
