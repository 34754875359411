import React from 'react';
import classNames from 'classnames/bind';
import { Grid as GridBase } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import styles from './Grid.module.css';

const cx = classNames.bind(styles);

const Grid = ({ extraPadding, ...rest }) => <GridBase className={cx({ extraPadding })} {...rest} />;

export const gridPropsShape = {
  extraPadding: PropTypes.bool,
  fluid: PropTypes.bool,
};

Grid.propTypes = {
  ...gridPropsShape,
};

Grid.defaultProps = {
  extraPadding: false,
  fluid: false,
};

export default Grid;
