import { createSelector } from 'reselect';
import ListingPageUnit from 'models/listingPage/ListingPageUnit';
import { LISTING_PAGE_REDUCER } from './reducer';

const initialUnits = [];

export const _selectListingPageReducer = (state) => state[LISTING_PAGE_REDUCER];

const _selectListingUnits = (state) => _selectListingPageReducer(state).units;

export const selectListingUnits = createSelector(_selectListingUnits, (listingunits) =>
  listingunits ? listingunits.map((listingunit) => new ListingPageUnit(listingunit)) : initialUnits
);

// Listing page specific data.
export const selectValueHovered = (state) => _selectListingPageReducer(state).valueHovered;

const _selectListingDataUnits = (state) => _selectListingPageReducer(state).listingData?.units;

export const selectListingDataUnits = createSelector(_selectListingDataUnits, (listingunits) =>
  listingunits ? listingunits.map((listingunit) => new ListingPageUnit(listingunit)) : initialUnits
);

export const selectAvailableCityStates = (state) => _selectListingPageReducer(state).availableCitiesAndStates;
export const selectListingsPerRegion = (state) => _selectListingPageReducer(state).listingData?.listingsPerRegion;
