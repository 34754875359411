import { StepFormLayout } from 'lease-signing-flow/components/step-form-layout/step-form-layout';
import { TermsAndConditions } from 'lease-signing-flow/components/terms-and-conditions/terms-and-conditions';
import { WalletFeatureCarousel } from 'lease-signing-flow/components/wallet-feature-carousel/wallet-feature-carousel';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';

const initialValues = {};

export const WalletStep = ({ currentStep, onSubmit, steps }) => (
  <StepFormLayout
    currentStep={currentStep}
    initialValues={initialValues}
    onSubmit={onSubmit}
    steps={steps}
    subTitle={LEASE_SIGNING_FLOW_STRINGS['wallet.subtitle']}
    title={LEASE_SIGNING_FLOW_STRINGS['wallet.title']}
    getForm={() => <WalletFeatureCarousel />}
    previousContent={<TermsAndConditions />}
  />
);
