import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PATHS } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import String from 'components/String/String';
import ListItem from 'components/SuccessPageComponents/ListItem/ListItem';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { Col, Grid, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { checkAddressCoverage } from 'store/redux/homeowner-onboarding/actions';
import { selectOnboardingProperties } from 'store/redux/homeowner-onboarding/selectors';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { hasSecondaryZipcode } from 'utils/signUpFlow';
import styles from './SuccessSections.module.css';

// eslint-disable-next-line camelcase
const { whats_next, cant_wait_that_long } = HOMEOWNER_ONBOARDING_STRINGS.success_page.sections;
const cx = classNames.bind(styles);

const SuccessSections = ({ properties, checkAddressCoverageAction }) => {
  const [addressesWithCoverage, setAddressesWithCoverage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // TODO: add spinner
        const coverageResponse = await checkAddressCoverageAction(
          properties?.map((property) => {
            const { city, state, coordinates, streetAddress, zipcode } = property.address;
            const { latitude, longitude } = coordinates;
            return {
              city,
              state,
              latitude,
              longitude,
              streetAddress,
              zipcode,
            };
          })
        );
        setAddressesWithCoverage(coverageResponse);
      } catch (err) {
        console.error(err);
      }
    };

    if (properties?.length) {
      fetchData();
    }
  }, [properties]);

  return (
    <>
      <Grid style={{ marginTop: 18 }}>
        <Row>
          <Col md={6} className={cx('first-section')}>
            <div className={cx('section-title')}>{whats_next.title}</div>
            <div className={cx('spacing-small')} />
            {properties?.length === 1 && properties[0].units.length === 1 ? (
              <ListItem selectedTrue label={whats_next.listitem0_home} />
            ) : (
              <ListItem selectedTrue label={whats_next.listitem0_homes} />
            )}
            <br />
            {/* TODO: should the list item be 'selected' when the secondary zipcode(s) are approved? */}
            {properties?.length && addressesWithCoverage?.length && hasSecondaryZipcode(addressesWithCoverage) && (
              <>
                <ListItem label={whats_next.listitem_secundary_zipcode} />
                <br />
              </>
            )}
            <ListItem label={whats_next.listitem1} />
            <br />
            <ListItem label={whats_next.listitem2} />
          </Col>
          <Col md={6}>
            <div className={cx('second-section')}>
              <div className={cx('section-title')}>
                <String string={cant_wait_that_long.title} />
              </div>
              <div className={cx('spacing-small')} />
              <String string={cant_wait_that_long.subtitle} />
              <div className={cx('spacing-small')} />
              <div className={cx('cta')}>
                <LinkBase href={PATHS.SUPPORT}>
                  <Text fontWeight="semibold" className="uppercase">
                    {cant_wait_that_long.chatcta}
                  </Text>
                </LinkBase>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

SuccessSections.propTypes = {
  properties: PropTypes.array.isRequired,
  checkAddressCoverageAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  properties: selectOnboardingProperties(state),
});

const mapDispatchToProps = {
  checkAddressCoverageAction: checkAddressCoverage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuccessSections));
