import { useModal } from '@belong/common';
import { Modal, Button, Text } from '@belong/ui';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';

export function CreditScoreModal() {
  const RAS = RESIDENT_APPLICATION_STRINGS.pa_landing_page.key_factors_section.credit;
  const [isModalOpen, openModal, closeModal] = useModal();
  return (
    <>
      <Button variant="text" onClick={openModal}>
        {RAS.openModal}
      </Button>
      <Modal name="credit_score" isOpen={isModalOpen} onDismiss={closeModal} size="default" overlayClassName="z-50">
        <div className="h-screen flex flex-col justify-center sm:block sm:h-auto py-2xl px-xl">
          <div className="mb-lg">
            <Text variant="h2" fontWeight="semibold" className="text-center">
              {RAS.title}
            </Text>
          </div>
          <div className="mb-xs">
            <Text fontWeight="semibold" className="text-left">
              {RAS.subtitle}
            </Text>
          </div>

          <ul>
            {RAS.bulletPoints.map((bulletPoint, key) => (
              <li className="list-none flex" key={key}>
                <span className="mr-xs">&bull;</span> <span>{bulletPoint}</span>
              </li>
            ))}
          </ul>
          <div className="flex justify-center mt-xl">
            <Button size="large" onClick={closeModal}>
              {RAS.closeModal}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
