import React from 'react';
import { Image } from '@belong/ui';
import classNames from 'classnames/bind';
import styles from './current-home-marker.module.css';

type CurrentHomeMarkerProps = {
  isHover: boolean;
};

const cx = classNames.bind(styles);
const selectedMarkerSrc = '/icons/map/selected_featured_home_marker.png';
const markerSrc = '/icons/map/featured_home_marker.png';

export function CurrentHomeMarker({ isHover }: CurrentHomeMarkerProps) {
  return (
    <div>
      <div className={cx('current-home-marker', { hover: isHover })}>
        <Image src={isHover ? selectedMarkerSrc : markerSrc} className={cx('listing-icon')} alt="" />
      </div>
    </div>
  );
}
