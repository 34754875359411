export const LEASE_STATUS = {
  CURRENT: 'Current',
  FUTURE: 'Future',
  PREVIOUS: 'Previous',
};

export const getLeaseStatus = (lease) => {
  const {
    leaseInfo: {
      basicInfo: { isCurrent, isConcluded, isFuture },
    },
  } = lease;

  if (isFuture) {
    return LEASE_STATUS.FUTURE;
  } else if (isConcluded) {
    return LEASE_STATUS.PREVIOUS;
  } else if (isCurrent) {
    return LEASE_STATUS.CURRENT;
  }
};

export const getTimeForLeasingStatus = (lease) => {
  const {
    basicInfo: { isCurrent, isFuture },
  } = lease;

  if (isFuture) {
    return 'Lease Confirmed';
  } else if (isCurrent) {
    return 'Currently Renting';
  }

  return 'Previously Rented';
};
