import type { MaintenanceRecurrenceModel } from 'api/models';

export const TEXT_COLOR_BY_RECURRING_TASK_STATUS: Record<MaintenanceRecurrenceModel['recurringServiceStatus'], string> =
  {
    Cancelled: 'text-dark-gray',
    Completed: 'text-green',
    OccurringAround: 'text-navy',
    Scheduled: 'text-navy',
    TurnedOff: 'text-red',
  };

export const URL_QUERY_PARAMS = {
  TOAST_VISIBLE: 'toast-visible',
  SUCCESS: 'success',
  CREATION: 'creation',
  UPDATE: 'update',
  TURN_OFF: 'turn-off',
  UPDATE_FREQUENCY: 'update-frequency',
  NEXT_ON: 'next-on',
  CANCEL: 'cancel',
  TASK: 'task',
};

export const API_ERRORS = {
  APPOINTMENT_TOO_SOON: 'APPOINTMENT_TOO_SOON',
};
