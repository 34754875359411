import { formatDateTime, DATE_FORMATS } from '@belong/common';
import { ButtonBase, Text } from '@belong/ui';
import MultipleBelongEmployeeProfileView from 'components/MultipleBelongEmployeeProfileView/MultipleBelongEmployeeProfileView';
import { getString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getPricingAssignedEmployee } from '../utils';

const GetStarted = ({ stepResource, stepConfig, stepConfigs, onSaveAndNext }) => {
  const { report } = stepResource;

  const isFirstPricingReport = report?.subType === 'PricingPostInspection';

  const assignedEmployee = getPricingAssignedEmployee(stepResource);

  return (
    <PostInspectionContentLayout
      noOverlay
      stepConfig={stepConfig}
      stepConfigs={stepConfigs}
      title={getString(
        isFirstPricingReport
          ? POST_INSPECTION_FLOW_STRINGS['pricing.getStarted.title']
          : POST_INSPECTION_FLOW_STRINGS['pricing.getStarted.title_second'],
        { firstName: stepResource.homeListingDetailsModel?.owner?.firstName }
      )}
      employee={assignedEmployee}
    >
      <div className="rounded p-sm mt-xl mb-2xl border border-solid border-green bg-green-translucent-light">
        <Text className="mb-xs" fontWeight="semibold">
          {POST_INSPECTION_FLOW_STRINGS['pricing.tip.title']}
        </Text>
        <Text className="mb-xs">{POST_INSPECTION_FLOW_STRINGS['pricing.tip.content']}</Text>
      </div>

      <div className="hidden md:flex justify-center mt-2xl mb-3xl">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          {POST_INSPECTION_FLOW_STRINGS['pricing.getStarted.cta']}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <Text fontWeight="semibold" variant="h3" className="text-center mb-xl">
        {getString(POST_INSPECTION_FLOW_STRINGS['pricing.getStarted.pricedOn'], {
          month: formatDateTime({ dateTime: stepResource.report.publishedOn, format: DATE_FORMATS.MONTH }),
          day: formatDateTime({ dateTime: stepResource.report.publishedOn, format: DATE_FORMATS.DAY_OF_MONTH }),
        })}
      </Text>

      <MultipleBelongEmployeeProfileView employees={[assignedEmployee]} />

      <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
        <ButtonBase className="h-2xl w-full bg-green" onClick={() => onSaveAndNext()}>
          <Text fontWeight="semibold" className="text-white">
            {POST_INSPECTION_FLOW_STRINGS['pricing.getStarted.cta']}
          </Text>
        </ButtonBase>
      </div>
    </PostInspectionContentLayout>
  );
};

export default GetStarted;
