import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import PropTypes from 'prop-types';
import styles from './HomeTag.module.css';

const HOME_TAGS = {
  DEMO_UNIT_LABEL: 'Nearby',
  PHOTOS_PENDING_LABEL: 'Photos Coming Soon',
  THREE_D_PENDING_LABEL: '3D Tour Coming Soon',
  GUIDED_TOUR_PENDING_LABEL: 'Virtual Tour Coming Soon',
};

const cx = classNames.bind(styles);

export const TAG_SIZES = {
  default: 'default',
  large: 'large',
};

export const Tag = ({ relative, className, children, size }) => (
  <div className={cx('tag', size, { relative }, className)}>{children}</div>
);

Tag.LG = (props) => <Tag size={TAG_SIZES.large} {...props} />;

Tag.propTypes = {
  relative: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  size: PropTypes.oneOf(Object.values(TAG_SIZES)),
};

Tag.defaultProps = {
  relative: false,
  className: '',
  size: TAG_SIZES.default,
};

const HomeTag = ({ isAvailableForRent, isPhotoReady, is3DTourReady, isDemoUnit, className, relative }) => {
  let tag;

  if (isDemoUnit) {
    tag = HOME_TAGS.DEMO_UNIT_LABEL;
  } else if (isAvailableForRent) {
    if (!isPhotoReady) tag = HOME_TAGS.PHOTOS_PENDING_LABEL;
    else if (!is3DTourReady) tag = HOME_TAGS.THREE_D_PENDING_LABEL;
  } else {
    tag = (
      <>
        <span>Rented with</span>
        <GeneralIcon className={cx('heart-icon')} icon={GENERAL_ICONS.HEART} />
      </>
    );
  }

  return tag ? (
    <Tag relative={relative} className={className}>
      {tag}
    </Tag>
  ) : null;
};

HomeTag.propTypes = {
  isAvailableForRent: PropTypes.bool.isRequired,
  isPhotoReady: PropTypes.bool.isRequired,
  is3DTourReady: PropTypes.bool.isRequired,
  isDemoUnit: PropTypes.bool.isRequired,
  className: PropTypes.string,
  relative: PropTypes.bool,
};

HomeTag.defaultProps = {
  className: '',
  relative: false,
};

export default HomeTag;
