import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './NextRequiredEvents.module.css';

const cx = classNames.bind(styles);

const NextRequiredEvents = ({ nextRequiredEvents }) => (
  <div className={cx('next-required-event-container')}>
    {nextRequiredEvents?.map((event) => {
      return (
        <div key={event.name} className={cx('next-required-event')}>
          {event.name}
        </div>
      );
    })}
  </div>
);

NextRequiredEvents.propTypes = {
  nextRequiredEvents: PropTypes.array.isRequired,
};

export default NextRequiredEvents;
