import { ACTIONS } from './contants';

export const CHAT_WIDGET_REDUCER = 'chatWidget';

const initialState = {
  isChatActive: false,
  isWindowOpen: false,
};

export default function chatWidget(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ACTIVATE_CHAT:
      return {
        ...state,
        isChatActive: true,
      };
    case ACTIONS.DEACTIVATE_CHAT:
      return {
        ...state,
        isChatActive: false,
      };
    case ACTIONS.ACTIVATE_WINDOW:
      return {
        ...state,
        isWindowOpen: true,
      };
    case ACTIONS.DEACTIVATE_WINDOW:
      return {
        ...state,
        isWindowOpen: false,
      };
    default:
      return state;
  }
}
