export const FavoriteStrings = {
  'no-homes.title': 'Favorites',
  'no-homes.pretext': 'Save homes for safe keeping.',

  'no-homes.text': `Select the {heartIcon} to save and view <span>homes you love in one place.</span>`,
  'no-homes.button': 'Go To Listing',
  'homes.title': 'Favorites',

  'cancel-tour-modal.title': 'Home Unfavorited',
  'cancel-tour-modal.text': 'Would you also like to cancel the following {tour}:',
  'cancel-tour-modal.checkbox-label': '{tourType} - {date}',
};
