import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { COLORS } from 'components/ColoredText/ColoredText';
import DocumentCard from 'components/DocumentCard/DocumentCard';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Space from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import PropTypes from 'prop-types';
import { fetchResidentAccountDocuments } from 'store/redux/renter-accounts/actions';
import { selectAccountDocuments } from 'store/redux/renter-accounts/selectors';
import { DOCUMENTS } from 'strings/documents';
import styles from './Documents.module.css';

const cx = classNames.bind(styles);

class Documents extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    const { fetchResidentAccountDocuments: fetchResidentAccountDocumentsAction } = this.props;

    try {
      await fetchResidentAccountDocumentsAction();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleDocumentClick = (document) => {
    const { url } = document;
    window.open(url, '_blank');
  };

  renderDocuments() {
    const { documents } = this.props;

    return (
      <Row className={cx('documents')}>
        {documents.length === 0 && (
          <div className={cx('no-documents')}>
            <GeneralIcon color={COLORS.GRAY} icon={GENERAL_ICONS.NO_DOCUMENT_ICON} />
            <Space />
            <div className={cx('gray')}>{DOCUMENTS.no_documents}</div>
          </div>
        )}
        {documents.map((document) => (
          <Col className={cx('card')} md={3} sm={4} xs={6} key={document.id}>
            <DocumentCard
              onClick={() => this.handleDocumentClick(document)}
              label={document.documentType || document.name}
              type={document.mediaType}
              uploadDate={document.createdOn}
            />
          </Col>
        ))}
      </Row>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <AccountPage
        loading={loading}
        headerProps={{
          headerText: 'Documents',
        }}
      >
        <AccountPageSection content={this.renderDocuments()} />
      </AccountPage>
    );
  }
}

Documents.propTypes = {
  documents: PropTypes.array.isRequired,
  fetchResidentAccountDocuments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  documents: selectAccountDocuments(state),
});

const mapDispatchToProps = {
  fetchResidentAccountDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
