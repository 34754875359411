import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { Text } from 'design-system';
import { maskCurrency, unmaskNumber } from 'components/Input/masks';
import { RESIDENT_APPLICATION_STRINGS } from '../../../../../../../strings/resident-application.strings';
import Space, { SPACE_TYPES } from '../../../../../../../corecomponents/Space/Space';
import { required } from '../../../../../../../utils/validation';

const ContractFields = ({ name }) => (
  <>
    <Space value={SPACE_TYPES.LG} />
    <Row>
      <Col md={12}>
        <Text fontWeight="semibold">
          {RESIDENT_APPLICATION_STRINGS.employment_info.employments.contract.company_title}
        </Text>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field name={`${name}.source`} component={InputFinalFormAdapter} placeholder="Industry" validate={required} />
      </Col>
      <Col md={6}>
        <Field name={`${name}.position`} component={InputFinalFormAdapter} placeholder="Position" validate={required} />
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <Field
          name={`${name}.estimatedAnnualIncome`}
          component={InputFinalFormAdapter}
          placeholder="Est. Annual Income ($ Pre-Tax)"
          validate={required}
          mask={maskCurrency}
          unmask={unmaskNumber}
        />
      </Col>
    </Row>
  </>
);

ContractFields.propTypes = {
  name: PropTypes.string,
};

ContractFields.defaultProps = {
  name: '',
};

export default ContractFields;
