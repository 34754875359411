import React, { Component } from 'react';
import { PATHS } from '@belong/common';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_SIZES } from 'components/Button/buttonTypes';
import SuccessBannerImage from 'components/SuccessBannerImage/SuccessBannerImage';
import SuccessHeader from 'components/SuccessPageComponents/SuccessHeader/SuccessHeader';
import Space from 'corecomponents/Space/Space';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import Page from '../../../layouts/Page/Page';
import styles from './SuccessPage.module.css';
import SuccessSections from './SuccessSections/SuccessSections';

const HOBS = HOMEOWNER_ONBOARDING_STRINGS.success_page;
const cx = classNames.bind(styles);

class SuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animationComplete: false,
    };
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState({ animationComplete: true });
    }, 9000);
  }

  render() {
    return (
      <Page
        headerMainProps={{
          fixed: true,
          disableLogo: true,
          transparent: !this.state.animationComplete,
        }}
      >
        <div className={cx('success_header_wrapper', { showHeader: this.state.animationComplete })}>
          <SuccessHeader>{HOBS.title}</SuccessHeader>
        </div>
        <SuccessBannerImage className={cx('banner-image')} />
        <Space value="xl" />
        <SuccessSections />
        <div className={cx('desktop-margin')} />
        <div className={cx('button')}>
          <Button
            to={{ pathname: PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES }}
            label={HOBS.buttonLabel}
            size={BUTTON_SIZES.FLUID}
            className={cx('view-account-button')}
          />
        </div>
      </Page>
    );
  }
}

export default SuccessPage;
