import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Icon.module.css';
import { ICONS as constants, ICONS_2 as constants2 } from './constants';

export const ICONS = { ...constants, ...constants2 };

const cx = classNames.bind(styles);

class Icon extends Component {
  render() {
    const { icon, scale, transformCenter, responsive, className, originTop } = this.props;
    const style = { transform: `scale(${scale})` };
    if (transformCenter) style.transformOrigin = 'center';
    return <div className={cx(['icon', icon, { responsive }, { originTop }, className])} style={style} />;
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  scale: PropTypes.number,
  responsive: PropTypes.bool,
  originTop: PropTypes.bool,
  transformCenter: PropTypes.bool,
  className: PropTypes.string,
};

Icon.defaultProps = {
  scale: 1,
  responsive: false,
  className: '',
  originTop: false,
  transformCenter: false,
};

export default Icon;
