import { useDispatch } from 'react-redux';
import { isRequired } from '@belong/common';
import { Text, Form, Button, TextField, MediaUpload, Image, Spinner } from '@belong/ui';
import { HOME_KEYS_STRINGS } from 'accounts/constants/strings/home-keys.strings';
import type { AccessInstructionsModel, AccessInstructionsUpdateModel } from 'api/models';
import type { FormikHelpers } from 'formik';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import { fetchHomeAccessInstructions, updateHomeAccessInstructions } from 'store/redux/homeowner-accounts/actions';

type Props = {
  homeAccessInstructions: AccessInstructionsModel;
  isMobile?: boolean;
  onSubmit?: (values: AccessInstructionsUpdateModel) => Promise<void>;
  unitId: string;
};

export function HomeAccessInstructionsForm({ homeAccessInstructions, isMobile, onSubmit, unitId }: Props) {
  const dispatch = useDispatch();

  function getInitialValues() {
    const { accessInstructions, lockBoxCode } = homeAccessInstructions || {};

    return {
      accessInstructions,
      lockBoxCode,
    } as AccessInstructionsUpdateModel;
  }

  async function handleSubmit(
    values: AccessInstructionsUpdateModel,
    formikHelpers: FormikHelpers<AccessInstructionsUpdateModel>
  ) {
    const { setSubmitting } = formikHelpers;

    try {
      await dispatch(updateHomeAccessInstructions(unitId, values));
      await dispatch(fetchHomeAccessInstructions(unitId));

      await onSubmit?.(values);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Form initialValues={getInitialValues()} onSubmit={handleSubmit}>
      {({ isSubmitting }) => {
        return (
          <>
            {isSubmitting && <Spinner />}
            <div className="w-full">
              <div className="px-2sm sm:px-0">
                <div className="px-0 sm:px-2sm mb-2xl flex flex-col md:flex-row gap-sm">
                  <TextField
                    name="accessInstructions"
                    label={HOME_KEYS_STRINGS['delivery.lockboxAccessInstructions']}
                    validate={isRequired}
                  />
                  <TextField name="lockBoxCode" label={HOME_KEYS_STRINGS['delivery.code']} validate={isRequired} />
                </div>
                <QuestionsOrConcernsModalFooter>
                  <Button size={isMobile ? 'fluid' : 'default'} type="submit">
                    <Text fontWeight="semibold">{HOME_KEYS_STRINGS['delivery.save']}</Text>
                  </Button>
                </QuestionsOrConcernsModalFooter>
              </div>
            </div>
          </>
        );
      }}
    </Form>
  );
}
