import React, { PureComponent } from 'react';
import { Analytics, ANALYTICS_CLICK_ACTIONS, ANALYTICS_PROPERTIES, ANALYTICS_TYPE } from 'analytics';
import classNames from 'classnames/bind';
import HomeTag from 'components/HomeTag/HomeTag';
import ImageCard from 'components/ImageCard/ImageCard';
import ListingUnitCardLink from 'components/ListingUnitCard/ListingUnitCardLink/ListingUnitCardLink';
import HomeInfo from 'components/display/Home/HomeInfo/HomeInfo';
import ListingPageUnit from 'models/listingPage/ListingPageUnit';
import PriceSection, { PRICE_SIZES } from 'pages/HousePage/PriceSection/PriceSection';
import PropTypes from 'prop-types';
import { getOptimizedImageSrc } from 'utils/getOptimizedImageSrc';
import styles from './ListingsUnitCard.module.css';

const cx = classNames.bind(styles);

class ListingsUnitCard extends PureComponent {
  static propTypes = {
    unit: PropTypes.instanceOf(ListingPageUnit).isRequired,
    centerText: PropTypes.bool,
    setMaxWidth: PropTypes.bool,
    hasHover: PropTypes.bool,
    showTag: PropTypes.bool,
    isLink: PropTypes.bool,
    outsideHover: PropTypes.bool,
    favoriteButton: PropTypes.node,
    setShowHover: PropTypes.func,
  };

  static defaultProps = {
    centerText: false,
    setMaxWidth: false,
    hasHover: true,
    showTag: true,
    favoriteButton: null,
    isLink: false,
    outsideHover: false,
    setShowHover: () => {},
  };

  render() {
    const {
      unit,
      centerText,
      setMaxWidth,
      showTag,
      hasHover,
      unit: { disablePrice, isAvailableForRent, isDemoUnit, basicInfo, isPhotoReady, is3DTourReady, isGuidedTourReady },
      isLink,
      favoriteButton,
      outsideHover,
      setShowHover,
    } = this.props;
    const priceText = !disablePrice ? (
      <span>
        <PriceSection price={unit?.listInfo?.rentAmount} size={PRICE_SIZES.SMALL} />
      </span>
    ) : null;

    const Wrapper =
      hasHover || isLink
        ? {
            Component: ListingUnitCardLink,
            props: {
              action: ANALYTICS_CLICK_ACTIONS.HOUSE_PAGE_DETAIL,
              to: unit.getHousePageUrl(),
            },
          }
        : {
            Component: 'div',
            props: {},
          };

    return (
      <div className={cx('listing-unit-card-wrapper')}>
        <Wrapper.Component {...Wrapper.props}>
          <ImageCard
            id={unit.basicInfo.id}
            setShowHover={setShowHover}
            outsideHover={outsideHover}
            homeId={unit.basicInfo.unitId}
            imageUrl={getOptimizedImageSrc(unit.basicInfo.bannerImageUrl, true)}
            tag={
              <HomeTag
                isAvailableForRent={isAvailableForRent}
                isPhotoReady={isPhotoReady}
                is3DTourReady={is3DTourReady}
                isGuidedTourReady={isGuidedTourReady}
                isDemoUnit={isDemoUnit}
              />
            }
            showTag={showTag}
            title={unit.address.getAddressStringCompact(unit.address.unitNumber)}
            subTitle={<HomeInfo unitBasic={basicInfo} />}
            imageBottomText={priceText}
            hasHover={hasHover}
            centerText={centerText}
            setMaxWidth={setMaxWidth}
          />
        </Wrapper.Component>

        {favoriteButton && <div className={cx('favorite-icon-wrapper')}>{favoriteButton}</div>}
      </div>
    );
  }
}

export default Analytics((props) => ({
  [ANALYTICS_TYPE.Component]: `home_listing_card`,
  [ANALYTICS_PROPERTIES.homeId]: props.unit?.basicInfo.unitId,
}))(ListingsUnitCard);
