import { combineReducers } from 'redux';
import appReducer, { LEASE_SIGNING_FLOW_APP_REDUCER } from './app';
import depositReducer, { LEASE_SIGNING_FLOW_DEPOSIT_REDUCER } from './deposit';
import durationReducer, { LEASE_SIGNING_FLOW_DURATION_REDUCER } from './duration';

export const leaseSigningFlowReducer = combineReducers({
  [LEASE_SIGNING_FLOW_APP_REDUCER]: appReducer,
  [LEASE_SIGNING_FLOW_DEPOSIT_REDUCER]: depositReducer,
  [LEASE_SIGNING_FLOW_DURATION_REDUCER]: durationReducer,
});
