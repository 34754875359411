import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import String from 'components/String/String';
import CreditCardPayment from 'formcomponents/CreditCardPayment/CreditCardPayment';
import FormLayout from 'layouts/FormLayout/FormLayout';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { addCreditCardPaymentMethod } from 'store/redux/user/actions';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import { selectFullUser } from 'store/redux/user/selectors';

function AddCreditCardScreen(props) {
  const { savePayTemp } = props;
  const [error, setError] = useState('');

  async function handleSubmit(values) {
    const {
      showSpinner,
      hideSpinner,
      onSuccess,
      addCreditCardPaymentMethod: addCreditCardPaymentMethodAction,
      fullUser,
    } = props;

    showSpinner();
    function cardExists() {
      const { paymentMethods } = fullUser.paymentMethods;

      const paymentMethod = paymentMethods.find((card) => {
        const cardNumber = values.creditCardNumber;
        const lastFourDigits = cardNumber.slice(cardNumber.length - 4);
        if (card.lastDigits === lastFourDigits) {
          return true;
        }
        return false;
      });

      if (paymentMethod) return true;
      return false;
    };

    if (cardExists()) {
      hideSpinner();
      return setError(FEEDBACK_STRINGS.credit_card_existing);
    }

    try {
      const accounts = await addCreditCardPaymentMethodAction(values);

      hideSpinner();
      onSuccess(accounts[0].accountId, accounts[0]);
    } catch (err) {
      hideSpinner();
      // onFail(FEEDBACK_STRINGS.credit_card_fail);
      setError(FEEDBACK_STRINGS.credit_card_fail);
    }
  };

  return (
    <ModalLayout>
      <ModalLayout.Title>
        <String string="Adding a <span>Credit Card</span>" />
      </ModalLayout.Title>
      {error && (
        <ModalLayout.Error>
          <String string={error} />
        </ModalLayout.Error>
      )}
      <ModalLayout.Content>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit: formSubmit, invalid, values }) => (
            <form onSubmit={formSubmit}>
              <FormLayout>
                <CreditCardPayment formValues={values} />
                <ModalLayout.BottomBar
                  hasPaddingOnMobile
                  fixedBottom
                  ctaProps={{
                    label: savePayTemp ? 'Save and Pay' : 'Save',
                    disabled: invalid,
                  }}
                />
              </FormLayout>
            </form>
          )}
        />
      </ModalLayout.Content>
    </ModalLayout>
  );
}

AddCreditCardScreen.propTypes = {
  addCreditCardPaymentMethod: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  hideSpinner: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  savePayTemp: PropTypes.bool,
  fullUser: PropTypes.object,
}

AddCreditCardScreen.defaultProps = {
  savePayTemp: false,
}


const mapDispatchToProps = {
  addCreditCardPaymentMethod,
};

export default connect((state) => ({
  fullUser: selectFullUser(state),
}), mapDispatchToProps)(AddCreditCardScreen);
