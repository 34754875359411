import UnitBasicInfo from '../common/UnitBasicInfo';
import LeaseModel from '../common/LeaseModel';
import Media from '../common/Media';

export default class HomeownerAccountUnitDetail {
  constructor(data = {}) {
    this.basicInfo = new UnitBasicInfo(data.basicInfo);
    this.currentLease = data.currentLease ? new LeaseModel(data.currentLease) : null;
    this.nextLease = data.nextLease ? new LeaseModel(data.nextLease) : null;
    this.media = data.media ? data.media.map((media) => new Media(media)) : [];
    this.listingUrl = data.listingUrl;
  }
}
