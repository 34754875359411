import * as React from 'react';
import PropTypes from 'prop-types';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';

import { Flex } from '../Flex/Flex';
import { Box } from '../Box/Box';

export const RightIcon = ({ isOpen }) => {
  const styles = {};
  if (isOpen) {
    styles.transform = 'rotate(180deg)';
    styles.width = 'fit-content';
  }
  return (
    <Box style={styles}>
      <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
    </Box>
  );
};

RightIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

const Collapsible = ({ leftContent, innerContent, rightContent, disabled, showArrow = true }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const styles = {};
  if (!disabled) {
    styles.cursor = 'pointer';
  }
  return (
    <>
      <Flex alignItems="center">
        <Flex
          alignItems="center"
          flex={1}
          style={styles}
          onClick={() => {
            if (disabled) return;
            setIsOpen(!isOpen);
          }}
        >
          <Box flex={1}>{leftContent}</Box>
          {showArrow && (
            <Box ml="xs">
              <RightIcon isOpen={isOpen} />
            </Box>
          )}
        </Flex>

        {rightContent}
      </Flex>
      {isOpen && innerContent}
    </>
  );
};

Collapsible.propTypes = {
  leftContent: PropTypes.node,
  innerContent: PropTypes.node,
  rightContent: PropTypes.node,
  disabled: PropTypes.bool,
  showArrow: PropTypes.bool,
};

export { Collapsible };
