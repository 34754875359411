import { Fragment } from 'react';
import { HOME_KEYS_STRINGS } from 'accounts/constants/strings/home-keys.strings';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
// Component imports
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskNumber, unmaskNumber, maskRemoveWhiteSpace, unmaskRemoveWhiteSpace } from 'components/Input/masks';
// Bootstrap imports
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import { InputCheckboxAdapter } from 'formcomponents/checkbox-input';
import { KeyTypes } from 'models/enums';
import PropTypes from 'prop-types';
import { HOME_OWNER_PAPER_WORK_STRINGS } from 'strings/homeowner-paperwork.strings';
// Util, const, string imports
import { required } from 'utils/validation';
import css from './styles/key.module.css';

export function KeyFormFields({ name, isOther, form }) {
  if (!form) return null;

  return (
    <Row>
      {isOther ? (
        <Col md={12}>
          <Field
            name={`${name}.keyName`}
            component={InputFinalFormAdapter}
            placeholder={HOME_KEYS_STRINGS['form.name']}
            validate={required}
          />
        </Col>
      ) : (
        <Fragment>
          <Col md={4} className={css.mt}>
            <Field
              name={`${name}.type`}
              component={DropdownFinalFormAdapter}
              placeholder={HOME_KEYS_STRINGS['form.type']}
              items={[
                {
                  key: KeyTypes.Key,
                  value: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_types.key'],
                },
                {
                  key: KeyTypes.AccessCode,
                  value: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_types.access_code'],
                },
                {
                  key: KeyTypes.KeyFob,
                  value: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_types.key_fob'],
                },
                {
                  key: KeyTypes.Remote,
                  value: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_types.remote'],
                },
                {
                  key: KeyTypes.OpenAccess,
                  value: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_types.open_access'],
                },
              ]}
              onChangeCustom={() => {
                form.change(`${name}.keyDetail.hasNoKey`, false);
                form.change(`${name}.keyDetail.inputValue`, null);
              }}
              validate={required}
            />
          </Col>
          <Condition when={`${name}.type`} is={KeyTypes.Key}>
            <Col md={8}>
              <Field
                name={`${name}.keyDetail`}
                component={InputCheckboxAdapter}
                form={form}
                inputProps={{
                  mask: maskNumber,
                  unmask: unmaskNumber,
                  placeholder: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.key_details_key'],
                  validate: required,
                }}
                checkboxProps={{
                  label: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.has_key_key'],
                }}
                withMarginTop={false}
              />
            </Col>
          </Condition>
          <Condition when={`${name}.type`} is={KeyTypes.AccessCode}>
            <Col md={8}>
              <Field
                name={`${name}.keyDetail`}
                component={InputCheckboxAdapter}
                form={form}
                inputProps={{
                  placeholder: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.key_details_access'],
                  validate: required,
                  mask: maskRemoveWhiteSpace,
                  unmask: unmaskRemoveWhiteSpace,
                }}
                checkboxProps={{
                  label: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.has_key_access'],
                }}
                withMarginTop={false}
              />
            </Col>
          </Condition>
          <Condition when={`${name}.type`} is={KeyTypes.KeyFob}>
            <Col md={8}>
              <Field
                name={`${name}.keyDetail`}
                component={InputCheckboxAdapter}
                form={form}
                inputProps={{
                  mask: maskNumber,
                  unmask: unmaskNumber,
                  placeholder: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.key_details_fob'],
                  validate: required,
                }}
                checkboxProps={{
                  label: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.has_key_fob'],
                }}
                withMarginTop={false}
              />
            </Col>
          </Condition>
          <Condition when={`${name}.type`} is={KeyTypes.Remote}>
            <Col md={8}>
              <Field
                name={`${name}.keyDetail`}
                component={InputCheckboxAdapter}
                form={form}
                inputProps={{
                  mask: maskNumber,
                  unmask: unmaskNumber,
                  placeholder: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.key_details_remote'],
                  validate: required,
                }}
                checkboxProps={{
                  label: HOME_OWNER_PAPER_WORK_STRINGS['homeKeys.key_form.has_key_remote'],
                }}
                withMarginTop={false}
              />
            </Col>
          </Condition>
        </Fragment>
      )}
    </Row>
  );
}

KeyFormFields.propTypes = {
  name: PropTypes.string,
  isOther: PropTypes.bool,
  form: PropTypes.object,
};

KeyFormFields.defaultProps = {
  name: '',
  isOther: false,
  form: null,
};
