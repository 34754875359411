import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import HeaderItem from 'components/HeaderMain/HeaderItem/HeaderItem';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import config from 'config/config';
import { BASE_PATHS, PATHS } from '../routes/paths';

const { host } = config;

export const DEFAULT_LINKS = {
  ACCOUNT: {
    to: BASE_PATHS.ACCOUNTS,
    label: 'MY ACCOUNT',
  },
  RENT_A_HOME: {
    to: BASE_PATHS.HOME,
    label: 'RENT A HOME',
  },
  LIST_A_HOME: {
    to: BASE_PATHS.HOMEOWNER,
    label: 'FOR HOMEOWNERS',
  },
  OUR_PROS: {
    to: PATHS.PRO_SERVICES,
    label: 'OUR PROS',
  },
  HOW_IT_WORKS: {
    label: 'HOW IT WORKS',
  },
  GET_HELP: {
    to: '',
    label: 'GET HELP',
  },
  GET_HELP_NEW: {
    to: PATHS.SUPPORT,
    label: 'GET HELP 24/7',
  },
  FOR_RENTERS: {
    to: PATHS.RESIDENT_LANDING,
    label: 'FOR RENTERS',
  },
  FOR_HOMEOWNERS: {
    to: BASE_PATHS.HOMEOWNER,
    label: 'FOR HOMEOWNERS',
  },
  ABOUT: {
    to: PATHS.ABOUT_US,
    label: 'ABOUT',
  },
  CAREERS: {
    // Hacky way of doing a hard redirect to always load job data from server because of 3rd party CORS issue.
    to: `${host}${PATHS.CAREERS}`,
    // label: 'CAREERS',
    // If we ever bring the We're hiring! Label again, uncomment snippet below and please make sure to update FooterMain
    // and uncomment the 'fixed-width' classname of the whoWeAre Col and make the md of the col go from 2 -> 3
    //
    label: (
      <>
        <span style={{ marginRight: 8 }}>CAREERS</span> <Label color={LABEL_COLOR_TYPES.GREEN}>We're hiring!</Label>
      </>
    ),
  },
  TERMS: {
    to: PATHS.TERMS,
    label: 'TERMS',
  },
  PRIVACY: {
    to: PATHS.PRIVACY_POLICY,
    label: 'PRIVACY',
  },
  FAQ: {
    to: PATHS.FAQ,
    label: 'FAQ',
  },

  FAVORITES_EXIST: {
    to: `${BASE_PATHS.ACCOUNTS}/favorites`,
    label: <HeaderItem string="FAVORITES" icon={<GeneralIcon icon={GENERAL_ICONS.ICON_HEART_FULL} />} />,
  },
  FAVORITES_NOT_EXISTING_YET: {
    to: `${BASE_PATHS.ACCOUNTS}/favorites`,
    label: 'FAVORITES',
  },

  TOURS_AND_APPS: {
    to: `${BASE_PATHS.ACCOUNTS}/tours-and-apps`,
    label: 'TOURS & APPS',
  },
  YOUR_HOME: {
    to: BASE_PATHS.ACCOUNTS,
    label: 'MY HOME',
  },
  YOUR_HOMES: {
    to: BASE_PATHS.ACCOUNTS,
    label: 'MY HOMES',
  },
  YOUR_PROFILE: {
    to: `${BASE_PATHS.ACCOUNTS}/profile`,
    label: 'MY PROFILE',
  },
  SIGN_OUT: {
    to: '',
    label: 'SIGN OUT',
  },
  LEARN_MORE: {
    to: '',
    label: 'Learn More',
  },
  BLOG: {
    to: '/blog',
    label: 'Blog',
  },
};
