import PropTypes from 'prop-types';

export const MENU_TYPES = {
  BORDER: 'border',
  BACKGROUND: 'background',
  BORDER_NESTED: 'bordernested',
};

export const menuShapeItem = {
  item: PropTypes.object,
  onClick: PropTypes.func,

  // menuType checks what type of menu it is
  menuType: PropTypes.oneOf(Object.values(MENU_TYPES)),

  // hide
  menuHideOverFive: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.object),

  // badge
  new: PropTypes.bool,
};

export const menuShape = {
  menuItems: PropTypes.arrayOf(PropTypes.shape(menuShapeItem)),
  menuHideOverFive: PropTypes.bool,
  hasSuperSelect: PropTypes.bool,
  menuType: PropTypes.oneOf(Object.values(MENU_TYPES)),
};
