import { Component } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import classNames from 'classnames/bind';
import Checkbox from 'components/Checkbox/Checkbox';
import Input from 'components/Input/Input';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { parseGeocodeAddress, parseInternationalGeocodeAddress } from 'utils/geo';
import styles from './AddressAutocomplete.module.css';

const cx = classNames.bind(styles);

const defaultSearchOptions = {
  componentRestrictions: { country: 'us' },
};

export default class AddressAutocomplete extends Component {
  placesAutocompleteRef = null;

  state = {
    address: '',
    outsideUs: this.props.outsideUs,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      address: !isEmpty(nextProps.form) && !isEmpty(nextProps.input) ? nextProps.input?.value : nextProps.value,
    };
  }

  setPlacesRef = (element) => {
    this.placesAutocompleteRef = element;
  };

  handleChange = (address) => {
    const { outsideUs } = this.state;
    const { supportOutsideUsAllFields } = this.props;

    this.setState({ address });

    if (outsideUs && !supportOutsideUsAllFields) {
      this.props.onSelect({
        completeAddress: address,
      });
    }

    this.props.onChange(address);
  };

  handleSelect = (address) => {
    const { addressComponentObj, customHandleSelect, supportOutsideUsAllFields } = this.props;
    const { outsideUs } = this.state;

    this.setState({ address });
    geocodeByAddress(address).then((results) => {
      const addressParsed =
        outsideUs && !customHandleSelect && !supportOutsideUsAllFields
          ? parseInternationalGeocodeAddress(results[0])
          : parseGeocodeAddress(results[0], addressComponentObj);
      this.props.onSelect({ address, ...addressParsed }, true);
    });
  };

  handleSelectOutsideUs = () => {
    const { outsideUsAddressCallback, form } = this.props;
    outsideUsAddressCallback(!this.state.outsideUs, form);
    this.setState({ outsideUs: !this.state.outsideUs });
  };

  handleOnKeyDown = (e, suggestions) => {
    const { form } = this.props;
    const suggestionsOpen = !!suggestions?.length;
    if (!suggestionsOpen && e.keyCode === 13) {
      form.submit();
    }
  };

  formatStringFromTerms = (suggestion) => {
    const { address, outsideUs } = this.state;

    const description = outsideUs
      ? suggestion.description
      : suggestion.description.substr(0, suggestion.description.length - 5);
    const offset = description.toLowerCase().indexOf(address.toLowerCase());
    const { length } = address;

    const pre = description.slice(0, offset);
    const highlight = description.slice(offset, offset + length);
    const post = description.slice(offset + length);

    if (offset === -1) {
      return description;
    }

    return (
      <>
        {pre && <span>{pre}</span>}
        {highlight && <span className={cx('highlight')}>{highlight}</span>}
        {post && <span>{post}</span>}
      </>
    );
  };

  handleBlur = (suggestions) => {
    return () => {
      if (suggestions.length) {
        this.handleSelect(suggestions[0].description);

        this.placesAutocompleteRef.handleInputOnBlur();
      }
    };
  };

  render() {
    const { placeholder, allowOutsideUs, autoFocus, disabled, searchOptions, error } = this.props;
    const { outsideUs } = this.state;

    return (
      <div className={cx('address-autocomplete', { allowOutsideUs })}>
        <PlacesAutocomplete
          highlightFirstSuggestion
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={outsideUs ? {} : { ...defaultSearchOptions, ...searchOptions }}
          ref={this.setPlacesRef}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div className={cx({ 'autocomplete-wrapper': allowOutsideUs })}>
              <Input
                {...getInputProps({
                  placeholder,
                  onKeyDown: (e) => this.handleOnKeyDown(e, suggestions),
                })}
                autoFocus={autoFocus}
                autoComplete="xxxx"
                disabled={disabled}
                applyDisabledStyle={disabled}
                error={error}
                start={this.props.startInput}
                onBlur={this.handleBlur(suggestions)}
              />
              {allowOutsideUs && (
                <div className={cx('outside-us')}>
                  <Checkbox value={outsideUs} label="OUTSIDE U.S." alignCenter onSelect={this.handleSelectOutsideUs} />
                </div>
              )}
              {!!suggestions.length && (
                <div className={cx('suggestions')}>
                  {suggestions.map((suggestion, index) => (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: cx(['item', { active: suggestion.active }]),
                      })}
                      key={index}
                    >
                      <span>{this.formatStringFromTerms(suggestion)}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

AddressAutocomplete.propTypes = {
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  allowOutsideUs: PropTypes.bool,
  outsideUs: PropTypes.bool,
  outsideUsAddressCallback: PropTypes.func,
  addressComponentObj: PropTypes.bool,
  autoFocus: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  newFieldComponent: PropTypes.any,
  ignoreError: PropTypes.bool,
  onChangeCustom: PropTypes.func,
  customHandleSelect: PropTypes.bool,
  searchOptions: PropTypes.object,
  startInput: PropTypes.bool,
  supportOutsideUsAllFields: PropTypes.bool,
};

AddressAutocomplete.defaultProps = {
  onChange: () => {},
  onSelect: () => {},
  allowOutsideUs: false,
  outsideUs: false,
  outsideUsAddressCallback: () => {},
  addressComponentObj: null,
  customHandleSelect: false,
  autoFocus: false,
  disabled: false,
  error: false,
  newFieldComponent: null,
  ignoreError: false,
  onChangeCustom: null,
  searchOptions: {},
  startInput: false,
};

export const AddressAutocompleteFinalFormAdapter = ({
  newFieldComponent,
  input,
  meta,
  ignoreError,
  onChangeCustom,
  ...rest
}) => {
  const controlledProps = {};

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = !!meta.error;
    } else {
      controlledProps.error = meta.touched ? !!meta.error : false;
    }
  }

  if (rest.disabled) {
    controlledProps.error = undefined;
  }

  return (
    <AddressAutocomplete
      {...input}
      {...rest}
      onChange={(address) => {
        input.onChange(address);

        if (onChangeCustom) {
          onChangeCustom(address);
        }
      }}
      form={rest.form}
      {...controlledProps}
    />
  );
};

AddressAutocompleteFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  form: PropTypes.object,
  newFieldComponent: PropTypes.any,
  ignoreError: PropTypes.any,
  onChangeCustom: PropTypes.func,
};

AddressAutocompleteFinalFormAdapter.defaultProps = {
  form: {},
  ignoreError: false,
  newFieldComponent: false,
  onChangeCustom: null,
};
