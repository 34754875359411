export default class UserBasicInfo {
  constructor(data = {}) {
    this.id = data.id;
    this.userId = data.userId;
    this.name = data.name;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phone = data.phone;
    this.profileImageUrl = data.profileImageUrl;
    this.memberSince = data.memberSince;
    this.homeTown = data.homeTown;
    this.aboutMe = data.aboutMe;
    this.referralUrl = data.referralUrl;
    this.userMustSetPassword = data.userMustSetPassword;
    this.webIntercomUserHmac = data.webIntercomUserHmac;
  }
}
