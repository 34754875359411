import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import InputTag from 'components/TaggableInput/InputTag';
import { isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './MultiSelectInput.module.css';

const cx = classNames.bind(styles);

const MultiSelectLabel = ({ label, highlight, unAnimateText, error, other }) => (
  <div {...other} className={cx('headerLabel', { unAnimateText }, { highlight }, { error })}>
    {label}
  </div>
);

MultiSelectLabel.propTypes = {
  label: PropTypes.string.isRequired,
  highlight: PropTypes.bool.isRequired,
  unAnimateText: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  other: PropTypes.object.isRequired,
};

const MultiSelectInput = ({
  mask,
  inputRef,
  error,
  placeholder,
  tags,
  disabled,
  onRemoveTag,
  menuIsOpen,
  ...other
}) => {
  const [handleInput, setHandleInput] = useState(menuIsOpen);
  const inputEl = useRef(null);
  let headerLabel;

  if ((isNil(other.value) || other.value === '') && !tags.length) {
    headerLabel = (
      <MultiSelectLabel label={placeholder} unAnimateText={handleInput} highlight={handleInput} error={error} />
    );
  } else {
    headerLabel = <MultiSelectLabel label={placeholder} unAnimateText highlight={handleInput} error={error} />;
  }

  const onDivClick = () => {
    inputEl.current.focus();
    setHandleInput(true);
  };

  const onDivBlur = () => {
    setHandleInput(false);
  };

  return (
    <div
      className={cx(
        'relative',
        'inputContainer',
        { error },
        { highlight: handleInput },
        { hideBorderBottom: menuIsOpen }
      )}
    >
      {headerLabel}
      <div className={cx('tags')} onClick={onDivClick} onFocus={onDivClick} onBlur={onDivBlur}>
        <>
          {tags.map((tag) =>
            tag.value ? (
              <div key={tag.key} className={cx('tag', 'margin-top')}>
                <InputTag
                  multiline
                  key={tag.key ? tag.key : tag.value}
                  name={tag.value}
                  onRemoveTag={(e) => {
                    e.preventDefault();
                    onRemoveTag(tag.key);
                  }}
                />
              </div>
            ) : null
          )}
          <input
            className={cx('inputText', 'margin-top', error)}
            ref={inputEl}
            {...other}
            value={mask ? mask(other.value) : other.value}
          />
        </>
      </div>
    </div>

    // </div>
  );
};

MultiSelectInput.propTypes = {
  mask: PropTypes.func,
  inputRef: PropTypes.any.isRequired,
  error: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  tags: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
};

MultiSelectInput.defaultProps = {
  mask: null,
  tags: [],
};

export default MultiSelectInput;
