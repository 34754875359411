import React, { Component } from 'react';
import { twMerge } from '@belong/common';
import classNames from 'classnames/bind';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import PropTypes from 'prop-types';
import styles from './HeaderList.module.css';

const cx = classNames.bind(styles);

export default class HeaderList extends Component {
  static propTypes = {
    // children and header text control header
    children: PropTypes.node,
    subtitle: PropTypes.node,
    headerText: PropTypes.node,
    // Control IconButton Text
    iconLabelText: PropTypes.string,
    // Controls Icon used for the IconButton
    icon: PropTypes.string,
    // aligns the text for the IconButton
    align: PropTypes.string,
    onClick: PropTypes.func,
    pretitle: PropTypes.node,
    rowFlex: PropTypes.bool,
    cta: PropTypes.node,
  };

  static defaultProps = {
    icon: null,
    subtitle: null,
    rowFlex: false,
    align: ALIGN_TYPES.LEFT,
    children: '',
    headerText: '',
    iconLabelText: '',
    pretitle: '',
    onClick: () => {},
    cta: null,
  };

  render() {
    const { pretitle, rowFlex, children, iconLabelText, icon, align, headerText, onClick, subtitle, cta } = this.props;

    return (
      <header className={cx('headerList', { rowFlex })}>
        <div className={twMerge(cta ? 'contents sm:flex' : 'contents')}>
          <div className={twMerge(cta ? 'contents sm:flex sm:flex-col-reverse sm:flex-1' : 'contents')}>
            {subtitle && <div className={cx('subtitle')}>{subtitle}</div>}
            <div className={cx('headerText', { hasSubtitle: subtitle })}> {children || headerText} </div>
            {pretitle && <div className={cx('pretitle')}>{pretitle}</div>}
            {(iconLabelText || icon) && (
              <div className={cx('iconButton')}>
                <IconButton onClick={onClick} align={align} label={iconLabelText} icon={icon} />
              </div>
            )}
          </div>
          {cta}
        </div>
      </header>
    );
  }
}
