import React from 'react';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import classNames from 'classnames/bind';
import { Text } from 'design-system';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import PropTypes from 'prop-types';
import styles from './DisplayDefaultEvent.module.css';

const cx = classNames.bind(styles);

const DisplayDefaultEvent = ({ name, date }) => {
  const formattedDate = formatDateTime({
    dateTime: legacyParse(new Date(date)),
    format: DATE_FORMATS.FULL_MONTH_DAY_YEAR,
  });

  return (
    <div className={cx('event-container')}>
      <Text>{name}</Text>
      <Text fontSize="p1" color="dark-gray">
        {formattedDate}
      </Text>
    </div>
  );
};

DisplayDefaultEvent.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default DisplayDefaultEvent;
