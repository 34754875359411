export const calculateDays = (numberOfItems, amountOfTime) => {
  const numbers = parseInt(numberOfItems, 10);
  let multiplyByAmountOfTime = 1;

  if (amountOfTime === 'Weeks') {
    multiplyByAmountOfTime = 7;
  } else if (amountOfTime === 'Months') {
    multiplyByAmountOfTime = 30;
  } else if (amountOfTime === 'Years') {
    multiplyByAmountOfTime = 365;
  }

  return numbers * multiplyByAmountOfTime;
};

export const convertValuesToDays = (stringOfMonth) => {
  if (stringOfMonth === 'Yearly') {
    return 365;
  }

  if (stringOfMonth === 'Quarterly') {
    return 90;
  }

  if (stringOfMonth === 'Monthly') {
    return 30;
  }
};

export const convertDaysToValues = (numberOfDaysInput) => {
  const numberOfDays = typeof numberOfDaysInput === 'string' ? parseInt(numberOfDaysInput, 10) : numberOfDaysInput;

  if (numberOfDays === 365) {
    return { frequency: 'Yearly' };
  } else if (numberOfDays === 90) {
    return { frequency: 'Quarterly' };
  } else if (numberOfDays === 30) {
    return { frequency: 'Monthly' };
  }

  if (numberOfDays % 365 === 0) {
    return { frequency: 'Other', otherFrequency: numberOfDays / 365, timeAmount: 'Years' };
  } else if (numberOfDays % 30 === 0) {
    return { frequency: 'Other', otherFrequency: numberOfDays / 30, timeAmount: 'Months' };
  } else if (numberOfDays % 7 === 0) {
    return { frequency: 'Other', otherFrequency: numberOfDays / 7, timeAmount: 'Weeks' };
  }

  return { frequency: 'Other', otherFrequency: numberOfDays, timeAmount: 'Days' };
};
