export const allowedSections = [
  'Yard',
  'Patio',
  'Kitchen',
  'Living Room',
  'Dining Room',
  'Breakfast Nook',
  'Library',
  'Solarium / Atrium',
  'Workshop',
  'Roofdeck',
  'Deck',
  'Rec. Room',
  'Theater/ Home Cinema',
  'Outdoor Theater',
  'Tennis Court',
  'Sun Room',
  'Balcony',
  'Foyer',
  'Boat Dock',
  'BBQ Area',
  'Clubhouse',
  'Garden',
  'Outdoor Kitchen',
  'Outdoor Gym',
  'Outdoor Pool',
  'Doorman/ Reception',
  'Pet Area/ Pet Run',
  "Children's Playground/ Play Area",
  'Courtyard',
  'Landing',
  'Balcony',
  'Foyer',
  'Basketball Court',
  'Golf Course',
  'Gym',
  'Pool',
  'Yoga Studio',
  'Wine Cellar',
  'Bike Room',
];

export const sectionMap = {
  'Solarium / Atrium': 'atrium',
  'Rec. Room': 'recreation room',
  'Theater/ Home Cinema': 'theater',
  "Children's Playground/ Play Area": 'play area',
  'Pet Area/ Pet Run': 'pet area',
  'Doorman/ Reception': 'reception',
};
