import React from 'react';
import PropTypes from 'prop-types';
import Selector, { SELECTOR_TYPES } from 'components/Selector/Selector';

export const YesNoFinalFormAdapter = ({ input, meta, onChangeCustom, customCallback, ignoreError, ...rest }) => {
  const controlledProps = {};

  if (!ignoreError) {
    controlledProps.error = !!meta.error;
  }

  return (
    <Selector
      buttons={[
        { label: 'Yes', key: true },
        {
          label: 'No',
          key: false,
        },
      ]}
      type={SELECTOR_TYPES.SMALLTEXTBUTTONWIDTH}
      {...input}
      {...rest}
      {...controlledProps}
      selected={input.value}
      onSelect={(key, object) => {
        input.onChange(key, object);

        if (onChangeCustom) {
          onChangeCustom(key, object);
        }
      }}
    />
  );
};

YesNoFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func,
  customCallback: PropTypes.func,
  ignoreError: PropTypes.bool,
};

YesNoFinalFormAdapter.defaultProps = {
  onChangeCustom: null,
  customCallback: () => {},
  ignoreError: false,
};
