import { forwardRef, createElement, useContext, useEffect } from 'react';
import { AnalyticsProviderContext } from './AnalyticsProviderContext';
import { AnalyticsContext } from './AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from './analytics_event_names';

const ModalViewTracker = (shouldTrack) => (wrappedComponent) => {
  const ModalViewTrackerWrapper = forwardRef((props, ref) => {
    const analytics = useContext(AnalyticsProviderContext);
    const analyticsData = useContext(AnalyticsContext);
    const { modal_screen: screen } = analyticsData;
    const track = shouldTrack(props);

    useEffect(() => {
      if (track) {
        analytics.track(ANALYTICS_EVENT_NAMES.MODAL_VIEW, analyticsData);
      }
    }, [track, screen]);

    return createElement(wrappedComponent, { ref, ...props });
  });

  return ModalViewTrackerWrapper;
};

ModalViewTracker.propTypes = {};

export default ModalViewTracker;
