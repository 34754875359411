import React, { Component } from 'react';
import classNames from 'classnames/bind';
import FeeLabel from 'components/FeeLabel/FeeLabel';
import ItemDetail from 'components/ItemDetail/ItemDetail';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PaymentMethod from 'models/common/PaymentMethod';
import { PaymentMethod as PaymentMethodEnums, BillType, BillStatus } from 'models/enums/index';
import BillDetailModel from 'models/payments/BillDetailModel';
import { getFeeFromPaymentInfo } from 'models/utilities/paymentMethods';
import PropTypes from 'prop-types';
import { PAYMENT_LIST_MODAL } from 'strings/payment-list-modal';
import styles from './PaymentDetails.module.css';

const cx = classNames.bind(styles);

const PLM = PAYMENT_LIST_MODAL.paymentdetails;

export default class PaymentDetails extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    isHomeowner: PropTypes.bool,
    paymentId: PropTypes.string.isRequired,
    currentFeeToUse: PropTypes.instanceOf(PaymentMethod),
    task: PropTypes.instanceOf(BillDetailModel).isRequired,
  };

  static defaultProps = {
    currentFeeToUse: {},
    isHomeowner: false,
  };

  handleShowPaymentAmount = (payment, currentFeeToUse, isHomeowner) => {
    if (!isHomeowner) {
      if (payment.billInfo.type === BillType.Payment) {
        if (payment.billInfo?.status === BillStatus.Unpaid || payment.billInfo?.status === BillStatus.Failed) {
          return payment.getFeeFromItem(currentFeeToUse || '')?.totalWithFee || payment.billInfo.total;
        }
      }
    }
    return payment.billInfo.total;
  };

  handleShowPartialPaid = (payment, currentFeeToUse) => {
    if (!payment?.billInfo?.hasRemainingAmount) {
      return null;
    }

    return (
      <>
        <ItemDetail
          firstItem={<div>{PLM.total_paid_so_fair}</div>}
          secondItem={<Money format={MONEY_FORMAT.DOLLARS_CENTS} cash={`-${payment.billInfo?.paidAmount}`} />}
        />
        <ItemDetail
          firstItem={<div>{PLM.total_remaining}</div>}
          secondItem={
            <Money
              format={MONEY_FORMAT.DOLLARS_CENTS}
              cash={
                payment.getFeeFromItem(currentFeeToUse || '')?.remainingAmountWithFee ||
                payment.billInfo?.remainingAmount
              }
            />
          }
          className={cx('bold')}
        />
      </>
    );
  };

  render() {
    const { currentFeeToUse, items, task, paymentId, isHomeowner } = this.props;
    const showPayment =
      task.billInfo?.status === BillStatus.Unpaid ||
      task.billInfo?.status === BillStatus.Failed ||
      task.billInfo?.status === BillStatus.PartialPayment;
    return (
      <ModalLayout.Section>
        <ItemDetail
          firstItem={<div className={cx('bold-h3')}>{PLM.title}</div>}
          secondItem={<div className={cx('display-id')}>ID #{paymentId}</div>}
        />

        {items.map((item) => [
          <ItemDetail
            alignTop
            key={item.itemId}
            firstItem={<div className={cx('two-third-width')}>{item.description}</div>}
            secondItem={
              <div className={cx('margin-lg-left')}>
                <Money format={MONEY_FORMAT.DOLLARS_CENTS} cash={item.amount} />
              </div>
            }
          />,
        ])}

        {!isHomeowner &&
          showPayment &&
          task.getFeeFromItem(currentFeeToUse || '')?.feeAmount > 0 &&
          currentFeeToUse.paymentMethod === PaymentMethodEnums.CreditCard && (
            <ItemDetail
              animate
              key="PaymentDetails"
              firstItem={
                <div className={cx('fees')}>
                  <div>
                    Credit Card Fee (
                    {getFeeFromPaymentInfo(
                      task?.getFeeFromItem(currentFeeToUse)?.fee,
                      task?.getFeeFromItem(currentFeeToUse)?.feeType
                    )}
                    )
                  </div>
                  <FeeLabel label="Added" />
                </div>
              }
              secondItem={<Money cash={task.getFeeFromItem(currentFeeToUse)?.feeAmount} />}
            />
          )}
        <div className={cx('dotted-line')} />
        <ItemDetail
          firstItem={<div>{PLM.total}</div>}
          secondItem={
            <Money
              format={MONEY_FORMAT.DOLLARS_CENTS}
              cash={this.handleShowPaymentAmount(task, currentFeeToUse, isHomeowner)}
            />
          }
          className={cx({ bold: !task.billInfo.hasRemainingAmount })}
        />
        {this.handleShowPartialPaid(task, currentFeeToUse)}
      </ModalLayout.Section>
    );
  }
}
