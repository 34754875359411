import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SCREENS } from 'containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from 'containercomponents/Modals/modal.consts';
import queryString from 'query-string';
import { showModal } from 'store/redux/modals/actions';
import { fetchUserSetPasswordStatus } from 'store/redux/user/actions';
import { selectIsUserLoggedIn, selectUser } from 'store/redux/user/selectors';

export function useUserIsLogged() {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const init = useCallback(async () => {
    if (isUserLoggedIn) {
      if (!user?.userMustSetPassword) return;

      return dispatch(
        showModal(MODALS.LOGIN, {
          user,
          currentScreen: SCREENS.SET_PASSWORD_AFTER_AGREEMENT,
          closeButton: false,
          closable: false,
          onSucessfulLogin: async () => {
            history.push(location.pathname);
          },
        })
      );
    }

    // user not logged in case
    let query;
    try {
      const queryParams = queryString.parse(location.search);
      const decodedQueryString = window.atob(queryParams?.data as string);
      query = queryString.parse(decodedQueryString);
    } catch (e) {
      console.error(e);
    }

    let passwordSet = true;
    if (query?.uniqueId) {
      try {
        const userSetPassword = await dispatch(fetchUserSetPasswordStatus(query?.uniqueId));
        passwordSet = userSetPassword?.passwordSet;
      } catch (err) {
        console.error(err);
      }
    }

    if (!passwordSet) {
      dispatch(
        showModal(MODALS.LOGIN, {
          user: query,
          currentScreen: SCREENS.SET_PASSWORD_AFTER_AGREEMENT,
          closeButton: false,
          closable: false,
          onSucessfulLogin: async () => {
            history.push(location.pathname);
          },
        })
      );
    } else {
      dispatch(
        showModal(MODALS.LOGIN, {
          currentScreen: SCREENS.LOGIN_SCREEN,
          redirectToHomeOnHide: true,
          loginScreenProps: {
            email: query?.email,
          },
          onSucessfulLogin: async () => {
            history.push(location.pathname);
            history.go(0);
          },
        })
      );
    }
  }, [dispatch, history, isUserLoggedIn, location.pathname, location.search, user]);

  useEffect(() => {
    init();
  }, [isUserLoggedIn, init]);
}
