/* eslint-disable */
import React, { Component } from 'react';
// Date-fns helper imports
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import classNames from 'classnames/bind';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import AddressField from 'fields/StandardFields/AddressField/AddressField';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEmpty, isNil } from 'lodash-es';
import AgreementFlowFormLayout from 'pages/AgreementFlow/AgreementFlowFormLayout/AgreementFlowFormLayout';
import PropTypes from 'prop-types';
import { getString } from 'strings';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import { converDateTimeServerResponseToDateTimeObject, parseDateTimeInputString } from 'utils/dateTimeUtils';
import { required } from 'utils/validation';
import styles from './UserPersonal.module.css';

const cx = classNames.bind(styles);
const AFS = AGREEMENT_FLOW_STRINGS.personal_info;
const IS_LEGAL_NAME = {
  YES: 'yes',
  NO: 'no',
};

const compareInternationalAddress = (addressOne, addressTwo) => {
  let isSame = true;

  if (addressOne.completeAddress !== addressTwo.completeAddress) {
    isSame = false;
  } else if (addressOne.unitNumber !== addressTwo.unitNumber) {
    isSame = false;
  } else if (addressOne.country !== addressTwo.country) {
    isSame = false;
  }

  return isSame;
};

const compareUsAddress = (addressOne, addressTwo) => {
  let isSame = true;

  if (addressOne.streetAddress !== addressTwo.streetAddress) {
    isSame = false;
  } else if (addressOne.unitNumber !== addressTwo.unitNumber) {
    isSame = false;
  } else if (addressOne.city !== addressTwo.city) {
    isSame = false;
  } else if (addressOne.state !== addressTwo.state) {
    isSame = false;
  } else if (addressOne.zipcode !== addressTwo.zipcode) {
    isSame = false;
  }

  return isSame;
};

const compareAddressObjects = (addressOne = {}, addressTwo = {}) => {
  let isSame = true;

  if (addressOne.outsideUS !== addressTwo.outsideUS) {
    isSame = false;
    return isSame;
  }

  if (addressOne.outsideUS && addressTwo.outsideUS) {
    isSame = compareInternationalAddress(addressOne, addressTwo);
  } else {
    isSame = compareUsAddress(addressOne, addressTwo);
  }

  return isSame;
};

class UserPersonal extends Component {
  static propTypes = {
    currentMappedStep: PropTypes.object.isRequired,
    currentStepFormData: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setAnimateTextChange: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
    };
  }

  async componentDidMount() {
    const { currentStepFormData, setAnimateTextChange } = this.props;
    const { formData } = this.state;

    setAnimateTextChange(false);

    if (!isEmpty(currentStepFormData) && isEmpty(formData)) {
      this.setupInitialValues();
    }
  }

  async componentDidUpdate() {
    const { currentStepFormData, setAnimateTextChange } = this.props;
    const { formData } = this.state;

    setAnimateTextChange(false);

    if (!isEmpty(currentStepFormData) && isEmpty(formData)) {
      this.setupInitialValues();
    }
  }

  setupInitialValues() {
    const {
      currentStepFormData,
      currentStepFormData: { user },
    } = this.props;

    const { homeowner, legalEntity } = currentStepFormData;

    const { legalFirstName, legalLastName, legalMiddleName } = legalEntity || {};

    const updatedState = {
      formData: {
        sameMailingAddress: true,
        completeAddress: {},
        ...currentStepFormData.user,
        legalFirstName,
        legalLastName,
        legalMiddleName,
      },
    };

    if (user.currentAddress && !isEmpty(user.currentAddress)) {
      updatedState.formData = {
        ...updatedState.formData,
        currentAddress: {
          ...user.currentAddress,
        },
      };
    }

    if (legalEntity?.address && !isEmpty(legalEntity?.address)) {
      const isSameAddress = compareAddressObjects(legalEntity.address, user.currentAddress);

      if (isSameAddress) {
        updatedState.formData.sameMailingAddress = true;
      } else {
        updatedState.formData.sameMailingAddress = false;
      }

      updatedState.formData.mailingAddress = { ...legalEntity.address };

      updatedState.formData = {
        ...updatedState.formData,
      };
    }

    if (user.ssnLastDigits && user.ssnLastDigits !== '') {
      updatedState.formData.ssn = `xxx-xx-${user.ssnLastDigits}`;
    }

    const hasLegalInfo = legalEntity.legalFirstName && legalEntity.legalFirstName !== '';

    let isSameUserAndLegalName = false;

    if (hasLegalInfo) {
      isSameUserAndLegalName = this.compareUserAndLegalName();
    }

    if (hasLegalInfo && isSameUserAndLegalName) {
      updatedState.formData.isLegalName = IS_LEGAL_NAME.YES;
    } else if (hasLegalInfo && !isSameUserAndLegalName) {
      updatedState.formData.isLegalName = IS_LEGAL_NAME.NO;
    }

    if (!isNil(user.dateOfBirth) && user.dateOfBirth !== '') {
      const dateOfBirthDateObject = converDateTimeServerResponseToDateTimeObject(user.dateOfBirth);

      // To-Do: Move format to a constant
      updatedState.formData.dateOfBirth = formatDateTime({
        dateTime: legacyParse(dateOfBirthDateObject),
        format: DATE_FORMATS.STANDARD,
      });
    }

    this.setState({
      ...updatedState,
    });
  }

  compareUserAndLegalName() {
    const {
      currentStepFormData: { legalEntity, user },
    } = this.props;

    let isSame = true;

    if (user.firstName !== legalEntity.legalFirstName) {
      isSame = false;
    } else if (user.lastName !== legalEntity.legalLastName) {
      isSame = false;
    } else if (user.legalMiddleName && legalEntity.legalMiddleName !== '') {
      isSame = false;
    }

    return isSame;
  }

  handleSubmit = async (values) => {
    const {
      onSubmit,
      currentStepFormData: { legalEntity, user },
    } = this.props;

    const dateOfBirthUserString = values.dateOfBirth;
    const dateOfBirthServerString = parseDateTimeInputString(dateOfBirthUserString);

    values.dateOfBirth = dateOfBirthServerString;

    const userInfo = {
      ...user,
      dateOfBirth: dateOfBirthServerString,
    };
    const userSsnInformation = {
      ssn: values.ssn,
    };

    if (values.isLegalName === IS_LEGAL_NAME.NO) {
      userInfo.legalFirstName = values.legalFirstName;
      userInfo.legalMiddleName = values.legalMiddleName;
      userInfo.legalLastName = values.legalLastName;
    } else if (values.isLegalName === IS_LEGAL_NAME.YES) {
      userInfo.legalFirstName = user.firstName;
      userInfo.legalLastName = user.lastName;
    }

    if (values.noSsn) {
      userSsnInformation.hasNoSsn = values.noSsn;
      userSsnInformation.ssn = '';
    } else if (/^xxx\-xx\-?[0-9]{4}$/i.test(values.ssn)) {
      delete userSsnInformation.ssn;
    }

    const userCurrentAddress = { ...values.currentAddress };
    userCurrentAddress.outsideUS = values.currentAddress.country !== 'US';

    if (userCurrentAddress.outsideUS && userCurrentAddress.address) {
      userCurrentAddress.completeAddress = userCurrentAddress.address;
    }

    let userMailingAddress = {};

    if (values.sameMailingAddress) {
      userMailingAddress = { ...userCurrentAddress };
    } else {
      userMailingAddress = { ...values.mailingAddress };
      userMailingAddress.outsideUS = values.mailingAddress.country !== 'US';
    }

    if (userMailingAddress.outsideUS && userMailingAddress.address) {
      userMailingAddress.completeAddress = userMailingAddress.address;
    }

    const serverObject = {
      user: {
        ...userInfo,
        ...userSsnInformation,
        currentAddress: { ...userCurrentAddress },
      },
      legalEntity: {
        ...legalEntity,
        ...userInfo,
        address: { ...userMailingAddress },
      },
    };

    delete serverObject.legalEntity.currentAddress;

    onSubmit(serverObject);
  };

  render() {
    const { currentMappedStep, currentStepFormData } = this.props;
    const { formData } = this.state;

    if (isEmpty(currentStepFormData) || isEmpty(formData)) {
      return null;
    }

    const user = currentMappedStep?.rootData;

    return (
      <AgreementFlowFormLayout
        {...this.props}
        title={AFS.title}
        subTitle={getString(AFS.subTitle)}
        initialValues={formData}
        onSubmit={this.handleSubmit}
        getForm={({ form }) => {
          const { currentAddress, mailingAddress } = form.getState().values;

          return (
            <div className={cx('form-wrapper')}>
              <FormLayout.Section
                firstSection
                sectionTitle={getString(AFS.is_name_legal_question, {
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                })}
              >
                <Row>
                  <Col md={8}>
                    <div className={cx('is-legal-name-selector-wrapper')}>
                      <Field
                        name="isLegalName"
                        component={SelectorFinalFormAdapter}
                        validate={required}
                        buttons={[
                          {
                            label: AFS.is_name_legal_yes,
                            key: IS_LEGAL_NAME.YES,
                          },
                          {
                            label: AFS.is_name_legal_no,
                            key: IS_LEGAL_NAME.NO,
                          },
                        ]}
                        fluid
                        type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                      />
                    </div>
                  </Col>
                </Row>
                <Condition when="isLegalName" is={IS_LEGAL_NAME.NO}>
                  <Row className="mt-xl">
                    <Col md={4}>
                      <Field
                        name="legalFirstName"
                        component={InputFinalFormAdapter}
                        validate={required}
                        placeholder={AFS.legal_first_name}
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        name="legalMiddleName"
                        component={InputFinalFormAdapter}
                        placeholder={AFS.legal_middle_name}
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        name="legalLastName"
                        component={InputFinalFormAdapter}
                        validate={required}
                        placeholder={AFS.legal_last_name}
                      />
                    </Col>
                  </Row>
                </Condition>
                <Row className="mt-xl">
                  <Col md={12}>
                    <AddressField
                      form={form}
                      name="currentAddress"
                      outsideUs={currentAddress?.outsideUS}
                      allowOutsideUs
                      supportOutsideUsAllFields
                      outsideUsAddressCallback={() => {
                        form.change('currentAddress', {});
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="mt-sm">
                      <Field
                        name="sameMailingAddress"
                        component={CheckboxFinalFormAdapter}
                        label={AFS.same_mailing_address}
                        alignWithField={false}
                      />
                    </div>
                  </Col>
                </Row>
              </FormLayout.Section>
              <Condition when="sameMailingAddress" is={false}>
                <FormLayout.Section sectionTitle={AFS.where_to_send_title} className="mt-3xl">
                  <Row>
                    <Col md={12}>
                      <AddressField
                        form={form}
                        name="mailingAddress"
                        allowOutsideUs
                        outsideUs={mailingAddress?.outsideUS}
                        supportOutsideUsAllFields
                        outsideUsAddressCallback={() => {
                          form.change('mailingAddress', {});
                        }}
                      />
                    </Col>
                  </Row>
                </FormLayout.Section>
              </Condition>
            </div>
          );
        }}
      />
    );
  }
}

export default UserPersonal;
