import React from 'react';
import classNames from 'classnames/bind';
import String from 'components/String/String';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { TextBubbleRadius } from '../TextBubble/TextBubble';
import styles from './SidebarWithTextBubble.module.css';

const cx = classNames.bind(styles);

const SidebarWithTextBubble = ({ title, description, employeeAssignment, animateTextChange, ...rest }) => {
  const employee = {
    ...employeeAssignment?.employee,
    ...employeeAssignment?.user,
    ...employeeAssignment?.employeeAssignment,
    jobTitle:
      employeeAssignment?.employee?.jobTitle || EMPLOYEE_TITLES[employeeAssignment?.employeeAssignment?.assignmentType],
  };

  const getText = () => {
    return (
      <>
        {!!title && (
          <div className={cx('title')}>
            <Text>
              <String string={title} />
            </Text>
          </div>
        )}
        {!!description && (
          <div className={cx('description')}>
            <Text>
              {Array.isArray(description) ? (
                description.map((d, i) => {
                  if (i === description.length - 1) return <String key={i} string={d} />;
                  return (
                    <React.Fragment key={i}>
                      <String string={d} />
                      <br />
                    </React.Fragment>
                  );
                })
              ) : (
                <String string={description} />
              )}
            </Text>
          </div>
        )}
      </>
    );
  };

  return (
    <TextBubbleWithUserProfile
      employee={employee}
      text={getText()}
      textBelowProfile
      {...rest}
      textBubbleProps={{
        radius: TextBubbleRadius.Small,
        animateTextChange,
        makeWhite: true,
      }}
      showContact
    />
  );
};

SidebarWithTextBubble.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  employeeAssignment: PropTypes.object.isRequired,
  animateTextChange: PropTypes.bool,
};

SidebarWithTextBubble.defaultProps = {
  title: '',
  description: '',
  animateTextChange: false,
};

export default SidebarWithTextBubble;
