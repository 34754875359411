import { useHistory } from 'react-router';
import { twMerge } from '@belong/common';
import { Button } from '@belong/ui';
import { STRINGS } from 'maintenance/strings';
import styles from './book-a-pro-button.module.css';

type BookAProButtonProps = {
  onClick?: () => void;
};

export const BookAProButton = ({ onClick }: BookAProButtonProps) => {
  const history = useHistory();

  const handleBookAProClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.push('/homeowner/pro-services/Repair');
    }
  };

  return (
    <>
      <div className={twMerge('sm:hidden')}>
        <div className={twMerge('fixed flex', styles.buttonWrapper)}>
          <div className="w-full">
            <Button buttonType="primary" onClick={handleBookAProClick}>
              {STRINGS['submit-request']}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
