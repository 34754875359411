import { StepFormLayout } from 'accounts/components/step-form-layout';
import { HOME_PETS_STRINGS } from 'accounts/constants/strings/home-pets.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import {
  allPetsAllowed,
  getHasRestrictedBreeds,
  getHasWeight,
  getPetPreference,
  getPetsKey,
} from 'accounts/utils/home-pets';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { UnitPetPreferences } from 'accounts/components/unit-pet-preferences/unit-pet-preferences';

export function HomePets() {
  const { currentStepFormData, handleSaveAndNext, setLoading, goPreviousStep } = useSetupFlowStep();

  function getInitialValues() {
    if (!currentStepFormData?.petInfo) {
      return {};
    }

    const { petInfo } = currentStepFormData;

    return {
      ...petInfo,
      petsAllowed: getPetPreference(petInfo),
      maxAllowedPets: petInfo.maxAllowedPets || 'no_limit',
      allPetsAllowed: allPetsAllowed(petInfo),
      hasWeightRestriction: getHasWeight(petInfo),
      hasRestrictedBreeds: getHasRestrictedBreeds(petInfo),
    };
  }

  function handleSubmit(values) {
    setLoading(true);

    const keyForPetType = getPetsKey(values.petsAllowed);
    const putPayload = {
      petInfo: {
        [keyForPetType]: true,
        maxAllowedPets: values.maxAllowedPets === 'no_limit' ? null : values.maxAllowedPets,
        maxWeightPerPet: values.hasWeightRestriction ? values.maxWeightPerPet : null,
        restrictedBreeds: values.hasRestrictedBreeds ? values.restrictedBreeds : null,
      },
    };

    handleSaveAndNext(putPayload);
  }

  return (
    <StepFormLayout
      onSubmit={handleSubmit}
      handleBackStep={goPreviousStep}
      title={HOME_PETS_STRINGS.title}
      initialValues={getInitialValues()}
      getForm={({ form }) => (
        <>
          <FormLayout.Section
            firstSection
          >
            <UnitPetPreferences initialValues={getInitialValues()} form={form} />
          </FormLayout.Section>
        </>
      )}
    />
  );
}
