import React from 'react';
import classNames from 'classnames/bind';
import styles from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserProfilePopout/IconSection/IconSection.module.css';
import { Col } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

export const ProfileIconSection = ({ colSize, icon, title, text }) => (
  <Col md={colSize}>
    <div className={cx('flex')}>
      <div className={cx('icon')}>{icon}</div>
      <div className={cx('text-wrapper')}>
        <div>{title}</div>
        <div className={cx('bold')}>{text}</div>
      </div>
    </div>
  </Col>
);

ProfileIconSection.propTypes = {
  colSize: PropTypes.number.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
};
