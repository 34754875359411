// Use instead of Array.push inside an if statement.
// Example:
// [1, 2, ...arrayInsert(1 === 1, 3)] -> [1, 2, 3]
// [1, 2, ...arrayInsert(1 === 2, 3)] -> [1, 2]
export const arrayInsertIf = (condition, element) => (condition ? [element] : []);

// Use instead of merging/adding new key-value inside an if statement.
// Example:
// { a: 1, b: 2, ...objectInsertIf(true, {c: 3, d: 4})} -> { a: 1, b: 2, c: 3, d: 4 }
// { a: 1, b: 2, ...objectInsertIf(false, {e: 5})} -> { a: 1, b: 2 }
export const objectInsertIf = (condition, element) => (condition ? element : {});
