// React final form imports
import { Fragment } from 'react';

// Component imports
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
// Bootstrap imports
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
// Util, Enum and Const imports
import { required } from 'utils/validation';
import { SECURITY_FEATURE_STRINGS } from 'accounts/constants/strings/security.strings';
import Condition from 'formcomponents/Condition/Condition';
import { MultiSelectableFinalFormAdapter } from 'components/MultiSelectable/MultiSelectable';


export function SecurityFormFields({ name, disabled, form }) {
  return (
    <Fragment>
      <div className="mt-sm">
        <FormLayout.Section
          firstSection
          sectionTitle={SECURITY_FEATURE_STRINGS.canResidentsUseIt}
          separatorType="normal"
          sectionTitleClassName="text-body"
        >
          <Row>
            <Col md={6}>
              <Field
                fluid
                name={`${name}.attributes.canResidentsUseIt`}
                component={SelectorFinalFormAdapter}
                validate={required}
                type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                buttons={[
                  {
                    label: SECURITY_FEATURE_STRINGS.yes,
                    key: true,
                  },
                  {
                    label: SECURITY_FEATURE_STRINGS.no,
                    key: false,
                  },
                ]}
              />
            </Col>
          </Row>
          <Condition when={`${name}.attributes.canResidentsUseIt`} is>
            <Row>
              <Col md={12}>
                <Field
                  name={`${name}.attributes.howOperate`}
                  component={InputFinalFormAdapter}
                  validate={required}
                  placeholder={SECURITY_FEATURE_STRINGS.inputPlaceholder}
                  disabled={disabled}
                  applyDisabledStyle={disabled}
                />
              </Col>
            </Row>
          </Condition>
        </FormLayout.Section>
      </div>
      <div className="mt-sm">
        <FormLayout.Section
          firstSection
          sectionTitle={SECURITY_FEATURE_STRINGS.doesHaveAnythingOfTheFollowing}
          separatorType="none"
          sectionTitleClassName="text-body"
        >
          <Row>
            <Col md={12}>
              <Field
                name={`${name}.attributes.doesHaveTheFollowing`}
                component={MultiSelectableFinalFormAdapter}
                alignWithField={false}
                form={form}
                noBorder
                titleClassName='text-body'
                options={[{
                  title: "Motion Detectors",
                  name: "MotionDetectors"
                }, {
                  title: "Cameras",
                  name: "Cameras"
                }, {
                  title: "Alarm Systems",
                  name: "AlarmSystems"
                }]}
              />
            </Col>
          </Row>
        </FormLayout.Section>
      </div>
    </Fragment>
  );
}

SecurityFormFields.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

SecurityFormFields.defaultProps = {
  name: '',
  disabled: false,
};