import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImagePreDownloader = ({ url, ...rest }) => {
  const [imageUrl, setImageUrl] = useState();

  return (
    <>
      <div
        style={{
          backgroundImage: `url('${imageUrl}')`,
        }}
        {...rest}
      />
      <img style={{ display: 'none' }} src={url} onLoad={() => setImageUrl(url)} alt="media" />
    </>
  );
};

ImagePreDownloader.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ImagePreDownloader;
