import React, { useRef, useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const SetHeightAnimation = (WrappedComponent) => ({ ...rest }) => {
  const [height, setHeight] = useState(0);
  const wrapper = useRef('');
  const springProps = useSpring({
    overflow: 'hidden',
    height: rest.show || rest.measureDefaultHeight ? `${height}px` : '0px',
    config: { duration: 100 },
  });
  useEffect(() => {
    setHeight(wrapper.current.children[0].scrollHeight);
  });

  return (
    <animated.div style={springProps} ref={wrapper}>
      <WrappedComponent {...rest} />
    </animated.div>
  );
};

export default SetHeightAnimation;
