/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { RouterProvider } from '@belong/common';
import { Footer } from '@belong/ui';
import classNames from 'classnames/bind';
import HeaderContainer from 'components/HeaderContainer/HeaderContainer';
import MetaTitle from 'components/Metatags/MetaTitle';
import Space from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import qs from 'query-string';
import styles from './Page.module.css';

const cx = classNames.bind(styles);

// Since we want to use router in the same way on every package, we need to match the next/router api
function routerAdapter({ history, location, match }) {
  return {
    // Not supported in next, but for sure we are gonna need somewhere
    match,
    pathname: location.pathname,
    query: qs.parse(location.search),
    push: history.push,
    replace: history.replace,
    back: history.goBack,
  };
}
// Since we want to use Link in the same way on every package, we need to match the next/link api
function LinkAdapter({ href, children, ...rest }) {
  return (
    <Link to={href} {...rest}>
      {children}
    </Link>
  );
}
LinkAdapter.withoutAnchor = true;

function Page({
  children,
  footer,
  header,
  headerMainProps,
  history,
  location,
  match,
  preserveScrollOnLocationChange,
  preserveScrollOnlyOnMount,
}) {
  useEffect(() => {
    if (!preserveScrollOnLocationChange) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!preserveScrollOnlyOnMount && !preserveScrollOnLocationChange) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <RouterProvider link={LinkAdapter} router={routerAdapter({ history, location, match })}>
      <div className={cx('page')}>
        <MetaTitle />
        {header && <HeaderContainer headerMainProps={headerMainProps} />}
        {children}
        {footer && <Footer />}
      </div>
    </RouterProvider>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  headerMainProps: PropTypes.object,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any),
  preserveScrollOnLocationChange: PropTypes.bool,
  preserveScrollOnlyOnMount: PropTypes.bool,
};

Page.defaultProps = {
  headerMainProps: null,
  footer: true,
  header: true,
  preserveScrollOnLocationChange: false,
  preserveScrollOnlyOnMount: false,
};

Page.propTypes = {};

Page.OffsetGrid = ({ children, ignoreOffset }) => {
  const allClassNames = {
    'offset-grid': true,
  };

  if (ignoreOffset?.length) {
    ignoreOffset.forEach((breakpoint) => {
      allClassNames[`ignore-${breakpoint}`] = true;
    });
  }

  return <div className={cx(allClassNames)}>{children}</div>;
};

Page.Section = ({
  firstSection,
  centerSectionTitle,
  sectionPretitle,
  sectionTitle,
  sectionSubtitle,
  separatorType,
  removeBottomSpacing,
  children,
}) => {
  const getSectionTitle = () => {
    if (sectionTitle && sectionSubtitle) {
      return (
        <div className={cx('section-title-wrapper', { centerSectionTitle }, { firstSection })}>
          <div className={cx('section-title')}>
            <Text>{sectionTitle}</Text>
          </div>
          <Space value="xs" />
          <div className={cx('section-subtitle')}>
            <Text>{sectionSubtitle}</Text>
          </div>
        </div>
      );
    }
    if (sectionTitle && sectionPretitle) {
      return (
        <div className={cx('section-title-wrapper', { centerSectionTitle })}>
          <div className={cx('section-pretitle')}>
            <Text>{sectionPretitle}</Text>
          </div>
          <Space value="xs" />
          <div className={cx('section-title')}>
            <Text>{sectionTitle}</Text>
          </div>
        </div>
      );
    }
    if (sectionSubtitle) {
      return (
        <div className={cx('section-title-wrapper', { centerSectionTitle }, { firstSection })}>
          <div className={cx('section-subtitle')}>
            <Text>{sectionSubtitle}</Text>
          </div>
        </div>
      );
    }
    if (sectionTitle) {
      return (
        <div className={cx('section-title-wrapper', { centerSectionTitle }, { firstSection })}>
          <div className={cx('section-title')}>
            <Text>{sectionTitle}</Text>
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <div className={cx('section-wrapper', { removeBottomSpacing }, separatorType)}>
      {getSectionTitle()}
      <div className={cx('section')}>{children}</div>
    </div>
  );
};

export default withRouter(Page);
