import { Money, Text } from '@belong/ui';
import { GroupBalanceInfoModel } from 'api/models';
import { MonthlyStatementDetailInfoModel, BillingInfoModel } from 'api/models.v2';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import { getMonthName } from 'pages/Accounts/utils/get-month-name';
import { EARNINGS_STRINGS } from '../Pages/YourPortfolio/Earnings/earnings.strings';
import { MonthSummary } from './month-summary/month-summary';

type UpcomingSectionProps = {
  balances: MonthlyStatementDetailInfoModel[];
  selectedHome?: string;
  isResident?: boolean;
  handleFinancingModal?: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  handleShowFinanceSuccessModal?: () => void;
  handleShowBalanceDetailsModal?: (
    balance: GroupBalanceInfoModel,
    home: string,
    billingInfo?: BillingInfoModel
  ) => void;
  address?: string;
  hasManyHomes?: boolean;
};

export function UpcomingSection({
  balances,
  selectedHome,
  hasManyHomes = false,
  isResident = false,
  handleFinancingModal,
  handleShowFinanceSuccessModal,
  handleShowBalanceDetailsModal,
  address = '',
}: UpcomingSectionProps) {
  if (!balances?.length && !isResident) {
    return null;
  }

  return (
    <>
      <AccountPageSection
        content={balances?.map((monthBalances, index) => {
          const { homesDetails, monthSummaryInfo } = monthBalances;
          const homes =
            selectedHome === 'all' ? homesDetails : homesDetails?.filter((home) => home.homeId === selectedHome);

          return (
            <div className="mb-3xl last:mb-0" key={index}>
              <div className="flex items-center justify-between pb-sm mb-sm border-0 border-b-[1px] border-dashed border-gray">
                <Text variant="h3" fontWeight="semibold">
                  {getMonthName(monthSummaryInfo.month)}
                </Text>
              </div>
              {homes.map((homeDetail) => (
                <MonthSummary
                  address={address}
                  homeDetail={homeDetail}
                  key={homeDetail.homeId}
                  onlyHome={homes.length === 1 && (!hasManyHomes || selectedHome !== 'all')}
                  handleFinancingModal={handleFinancingModal}
                  handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
                  handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
                  noBorder
                  hideSplitIt={isResident}
                />
              ))}
              {homes.length > 1 && (
                <div className="flex justify-between py-sm border-0 border-t-[1px] border-dashed border-gray">
                  <Text
                    variant="h3"
                    fontWeight="semibold"
                  >{`${homesDetails.length} ${EARNINGS_STRINGS['monthly_statement.homes_total']}`}</Text>
                  <Money
                    className="font-semibold text-h3-sm"
                    value={monthSummaryInfo.amount}
                    format="DOLLARS_WITH_CENTS"
                  />
                </div>
              )}
            </div>
          );
        })}
      />
    </>
  );
}
