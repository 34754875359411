import { Image, Button } from '@belong/ui';
import { STRINGS } from 'post-inspection/constants/strings';

type Props = {
  onClick: () => void;
};

export const PaymentStepperBackButton = ({ onClick }: Props) => (
  <div className="fixed top-[12px] sm:absolute left-xs h-[36px] sm:top-xs">
    <Button onClick={onClick} variant="text">
      <div className="p-xs">
        <Image
          alt={STRINGS['previous-step']}
          className="h-[16px] rotate-180 w-[16px]"
          src="icons/icon_navy_forward.svg"
        />
      </div>
    </Button>
  </div>
);
