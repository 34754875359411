import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentMethodSelector from 'containercomponents/PaymentMethods/PaymentMethodSelector/PaymentMethodSelector';
import { isEmpty } from 'lodash-es';
import FullUser from 'models/composed/FullUser';
import PropTypes from 'prop-types';
import { selectFullUser } from 'store/redux/user/selectors';

class PaymentMethodSelectorContainer extends Component {
  static propTypes = {
    fullUser: PropTypes.instanceOf(FullUser).isRequired,
    selectedPaymentMethodId: PropTypes.string.isRequired,
    onSelectPaymentMethod: PropTypes.func.isRequired,
    paymentModalProps: PropTypes.object,
    showOnlyValidPaymentMethods: PropTypes.bool,
    needsPaymentMethod: PropTypes.bool,
  };

  static defaultProps = {
    paymentModalProps: {},
    showOnlyValidPaymentMethods: false,
    needsPaymentMethod: false,
  };

  render() {
    const {
      showOnlyValidPaymentMethods,
      fullUser,
      selectedPaymentMethodId,
      onSelectPaymentMethod,
      paymentModalProps,
      needsPaymentMethod,
    } = this.props;
    const paymentMethods = fullUser?.paymentMethods.paymentMethods;

    if (!isEmpty(paymentMethods) && !needsPaymentMethod) {
      return null;
    }

    return (
      <PaymentMethodSelector
        selectedPaymentMethodId={selectedPaymentMethodId}
        onSelectPaymentMethod={onSelectPaymentMethod}
        paymentMethods={paymentMethods}
        needsPaymentMethod={needsPaymentMethod}
        paymentModalProps={{
          ...paymentModalProps,
          onPaymentSelection: onSelectPaymentMethod,
        }}
        showOnlyValidPaymentMethods={showOnlyValidPaymentMethods}
      />
    );
  }
}

export default connect((state) => ({
  fullUser: selectFullUser(state),
}))(PaymentMethodSelectorContainer);
