import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { COHERE_CONFIG } from '@belong/common';
import { ChatWidget } from 'components/ChatWidget/ChatWidget';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { find, includes } from 'lodash-es';
import numeral from 'numeral';
import { returnToHomeButton } from 'pages/ResidentApplication/SaveAndExitButton/SaveAndExitButton';
import PropTypes from 'prop-types';
import {
  setHouseIdInStore,
  updateApplicationIdAppState,
  updateMustCallCompleteEndpoint,
} from 'store/redux/resident-application/actions';
import { MONEY_TYPES } from '../../components/Money/moneyTypes';
import { getDefaultResidentGrowth } from '../../consts/employee-assignments';
import { FlowLayoutV2 } from '../../layouts/FlowLayout/FlowLayoutV2/FlowLayoutV2';
import { ApplicantType } from '../../models/enums';
import { fetchHome } from '../../store/redux/homes/actions';
import { selectHomeById } from '../../store/redux/homes/selectors';
import {
  selectResidentApplicationApplicantType,
  selectResidentApplicationCosignerMinimumIncome,
} from '../../store/redux/resident-application/selectors';
import { getString } from '../../strings';
import { RESIDENT_APPLICATION_STRINGS } from '../../strings/resident-application.strings';
import { ResidentApplicationRoutes } from './Steps/routes';
import { getResidentApplicationStepPath, STEPS, STEPS_CONFIG } from './Steps/steps';

const QueryString = require('query-string');

class ResidentApplication extends Component {
  async componentDidMount() {
    const {
      location,
      match: { params },
      fetchHome: fetchHomeAction,
    } = this.props;

    // Query JSON is used to launch the application from Co-applicant landing page
    const queryJson = location.search ? QueryString.parse(location.search) : {};
    const { applicationId, houseId, nextStep, fromCoapplicantPage } = queryJson;

    try {
      if (params.houseId) {
        await fetchHomeAction(params.houseId);
      }
    } catch (err) {
      console.error(err);
    }

    if (applicationId && houseId && nextStep) {
      this.navigateToSpecificStep(applicationId, houseId, nextStep, fromCoapplicantPage);
    } else {
      if (params.houseId) {
        this.setHouseId(params.houseId);
      }
    }
  }

  setHouseId(houseId) {
    const { setHouseIdInStore: setHouseIdInStoreAction } = this.props;

    setHouseIdInStoreAction(houseId);
  }

  setApplicationIdAppState(applicationId) {
    const { updateApplicationIdAppState: updateApplicationIdAppStateAction } = this.props;

    updateApplicationIdAppStateAction(applicationId);
  }

  setMustCallCompleteEndpoint() {
    const { updateMustCallCompleteEndpoint: updateMustCallCompleteEndpointAction } = this.props;

    updateMustCallCompleteEndpointAction(true);
  }

  navigateToSpecificStep(applicationId, houseId, nextStep, fromCoapplicantPage = false) {
    const { history } = this.props;
    this.setApplicationIdAppState(applicationId);
    this.setHouseId(houseId);
    // Some times we create a URL (without fromCoapplicantPage) to a specific page for residents who made
    // a mistake or forgot to provide some data. In that case, we want to call the complete endpoint even
    // if the  applicant status is already complete because we want to capture when the application data
    // was last updated.
    if (!fromCoapplicantPage) this.setMustCallCompleteEndpoint();
    history.replace(getResidentApplicationStepPath(STEPS_CONFIG[nextStep], { houseId, applicationId }));
  }

  renderHeaderMainLinks() {
    const { step } = this.props.location?.state || {};
    return [
      <ContactUs key="ContactUs" />,
      <React.Fragment key="save">{step >= 0 ? STEPS[step].saveAndExitButton : returnToHomeButton}</React.Fragment>,
    ];
  }

  getCurrentStep = () => {
    const {
      location: { pathname },
    } = this.props;

    return find(STEPS, (step) => includes(pathname, step.key));
  };

  getTip = (currentStep) => {
    const { applicantType, cosignerMinimumIncome } = this.props;
    let { tip } = RESIDENT_APPLICATION_STRINGS[currentStep.name];

    if (applicantType === ApplicantType.CoSigner && RESIDENT_APPLICATION_STRINGS[currentStep.name].tip_cosigner) {
      tip = RESIDENT_APPLICATION_STRINGS[currentStep.name].tip_cosigner;
    }

    if (currentStep.name === STEPS_CONFIG.ADD_COSIGNER.name) {
      tip = {
        ...tip,
        description: getString(tip.description, {
          minIncome: numeral(cosignerMinimumIncome).format(MONEY_TYPES.DOLLARS),
        }),
      };
    }

    return tip;
  };

  render() {
    const { match, location, home } = this.props;
    const { params } = match;
    const { houseId } = params;
    const { state: { step = 0 } = {} } = location;
    const { schedule, isEnabled } = COHERE_CONFIG.homeowner;

    if (houseId && !home) {
      return (
        <Redirect
          to={{
            ...location,
            ...getResidentApplicationStepPath(STEPS_CONFIG.PA_LANDING_PAGE, { houseId: params.houseId }),
          }}
        />
      );
    }

    const employeeAssignment = home.employees?.residentGrowth
      ? {
          employee: {
            ...home.employees?.residentGrowth,
            jobTitle: 'Your Leasing Advisor',
          },
        }
      : {
          ...getDefaultResidentGrowth(home?.propertyInfo?.coverageRegionId),
          employee: {
            ...getDefaultResidentGrowth(home?.propertyInfo?.coverageRegionId).employee,
            jobTitle: 'Your Leasing Advisor',
          },
        };

    if (
      location.pathname ===
      getResidentApplicationStepPath(STEPS_CONFIG.SUCCESS_PAGE, { houseId: params.houseId }).pathname
    ) {
      const success = ResidentApplicationRoutes[ResidentApplicationRoutes.length - 1];
      const Success = success.component;
      return (
        <Route key={success.path} path={success.path}>
          <Success home={home} employee={employeeAssignment.employee} />
        </Route>
      );
    }

    const currentStep = this.getCurrentStep();
    if (!currentStep) return null;

    return (
      <>
        <FlowLayoutV2
          location={location}
          sidebarProps={{ step }}
          stepProps={{ home }}
          navigationComponents={this.renderHeaderMainLinks()}
          currentStep={currentStep}
          tip={this.getTip(currentStep)}
          steps={ResidentApplicationRoutes}
          employeeAssignment={employeeAssignment}
        />
        <ChatWidget
          tags={['Page: Resident Application Flow']}
          schedule={schedule}
          shouldShow={step !== 'success'}
          isEnabled={isEnabled}
          containerWidth="w-container-xl"
          paddingRight="pr-2xl"
          showOnNavbar
        />
      </>
    );
  }
}

ResidentApplication.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  setHouseIdInStore: PropTypes.func.isRequired,
  updateApplicationIdAppState: PropTypes.func.isRequired,
  updateMustCallCompleteEndpoint: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  fetchHome: PropTypes.func.isRequired,
  applicantType: PropTypes.bool,
  cosignerMinimumIncome: PropTypes.number.isRequired,
};

ResidentApplication.defaultProps = {
  applicantType: ApplicantType.MainApplicant,
};

export default connect(
  (state, { match }) => ({
    home: selectHomeById(state, match?.params?.houseId),
    applicantType: selectResidentApplicationApplicantType(state),
    cosignerMinimumIncome: selectResidentApplicationCosignerMinimumIncome(state),
  }),
  {
    setHouseIdInStore,
    updateApplicationIdAppState,
    updateMustCallCompleteEndpoint,
    fetchHome,
    // fetchApplicantInfo
  }
)(ResidentApplication);
