import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import ToDoComponents from 'containercomponents/ToDoComponents/ToDoComponents';
import Space from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { UserToDoTypes } from 'models/enums';
import { BASE_PATHS } from 'routes/paths';
import { RESIDENT_ONBOARDING_STRINGS } from 'strings/resident-onboarding.strings';
import styles from './SuccessPage.module.css';

const cx = classNames.bind(styles);
const ROS = RESIDENT_ONBOARDING_STRINGS.success_page;
const { FormLayoutHeader } = FormLayout;

export default class SuccessPage extends Component {
  render() {
    return (
      <>
        <FormLayout>
          <FormLayoutHeader title={ROS.title} subtitle={ROS.subTitle} />
          <div className={cx('content-wrapper')}>
            <Row>
              <Col md={12}>
                <GreenInfoBox cssClassNames={[cx('to-do-section-wrapper')]}>
                  <div className={cx('to-do-section-title')}>{ROS.to_do_title}</div>
                  <div className={cx('to-do-section-list')}>
                    <div className={cx('to-do-section-list-item')}>
                      <ToDoComponents type={UserToDoTypes.SetupRentPaymentMethod} />
                    </div>
                    <div className={cx('to-do-section-list-item')}>
                      <ToDoComponents type={UserToDoTypes.SetupProfile} />
                    </div>
                  </div>
                </GreenInfoBox>
              </Col>
            </Row>
            <div className={cx('form-submit')}>
              <Button
                className={cx('cta-button')}
                type="submit"
                label={ROS.next_button_text}
                to={BASE_PATHS.ACCOUNTS}
              />
            </div>
          </div>
          <Space value="2xl" />
        </FormLayout>
      </>
    );
  }
}
