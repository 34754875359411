import { Children } from 'react';

function getChildByDisplayName(_a) {
  var children = _a.children,
    displayName = _a.displayName;
  return Children.toArray(children).find(function (child) {
    return child.type.displayName === displayName;
  });
}
/**
 * There is an issue on `Storybook` that prevents setting the `displayName` on any of the `children`.
 * As a result of that, using the `name` property is the only way of getting the corresponding child for each case
 * without breaking the documentation.
 * @see {@link https://github.com/storybookjs/storybook/issues/15401}
 * @todo remove this function once the issue mentioned above is solved.
 */
function getChildByName(_a) {
  var children = _a.children,
    name = _a.name;
  return Children.toArray(children).find(function (child) {
    return child.type.name === name;
  });
}

export { getChildByDisplayName, getChildByName };
