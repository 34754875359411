import { useState } from 'react';
import { Button, Text } from '@belong/ui';
import { HomeAccessInstructionsModal } from 'accounts/components/home-access-instructions-modal/home-access-instructions-modal';
import { DELIVERY_METHODS } from 'accounts/constants/home-keys';
import { HOME_KEYS_STRINGS } from 'accounts/constants/strings/home-keys.strings';
import { useHomeAccessInstructions } from 'accounts/hooks/home-access-instructions';
import { useHomeownerInspections } from 'accounts/hooks/homeowners/use-homeowner-inspections';
import type { UnitSummaryModel } from 'api/models';
import clsx from 'clsx';

type Props = {
  unit: UnitSummaryModel;
};

export function HomeAccessInstructionsSection({ unit }: Props) {
  const [isHomeAccessInstructionsModalOpen, setIsHomeAccessInstructionsModalOpen] = useState(false);
  const { isLoadingInspections, inspections } = useHomeownerInspections();
  const { homeAccessInstructions, loading } = useHomeAccessInstructions({ unitId: unit.basicInfo.unitId });

  function handleUpdateClick() {
    setIsHomeAccessInstructionsModalOpen(true);
  }

  function handleHomeAccessInstructionsModalDismiss() {
    setIsHomeAccessInstructionsModalOpen(false);
  }

  if (loading || isLoadingInspections) {
    return null;
  }

  const { hasAccessInstructionConfirmed, deliveredBy: deliveredByValue } = homeAccessInstructions || {};

  const deliveredBy = DELIVERY_METHODS.find((deliveryMethod) => deliveryMethod.key === deliveredByValue)?.text;

  if (!deliveredBy) {
    return null;
  }

  const unitOnboardingInspection = inspections.find(
    (inspection) => inspection.homeUniqueId === unit.basicInfo.unitId && inspection.type === 'Onboarding'
  );

  if (deliveredBy === 'Other' || ['Completed', 'Cancelled'].includes(unitOnboardingInspection?.status)) {
    return null;
  }

  return (
    <>
      <div className="mt-xl sm:mt-0">
        <Text variant="h3" fontWeight="semibold">
          {HOME_KEYS_STRINGS['delivery.homeAccess.title']}
        </Text>
        <div className="w-full flex flex-col sm:flex-row md:gap-xl md:justify-between">
          <div
            className={clsx(
              'pl-xs mt-xs sm:mt-sm mb-xl sm:mb-2xl border-0 border-l-3 border-solid  flex flex-col justify-between items-start',
              hasAccessInstructionConfirmed ? 'border-blue' : 'border-red'
            )}
          >
            {hasAccessInstructionConfirmed ? (
              <>
                <Text>{deliveredBy}</Text>
                <Text fontWeight="semibold" className="mt-xs" variant="h3">
                  {HOME_KEYS_STRINGS['delivery.location']}: {homeAccessInstructions.accessInstructions}
                </Text>
                {Boolean(homeAccessInstructions.lockBoxCode) && (
                  <Text fontWeight="semibold" className="mt-0" variant="h3">
                    {HOME_KEYS_STRINGS['delivery.code']}: {homeAccessInstructions.lockBoxCode}
                  </Text>
                )}
              </>
            ) : (
              <Text className="text-red" fontWeight="semibold">
                {HOME_KEYS_STRINGS['delivery.actionRequired']}
              </Text>
            )}
          </div>
          <div className="flex justify-end mb-2xl sm:mt-sm shrink-0">
            <Button onClick={handleUpdateClick} variant="text">
              {HOME_KEYS_STRINGS['delivery.update']}
            </Button>
          </div>
        </div>
      </div>
      <HomeAccessInstructionsModal
        homeAccessInstructions={homeAccessInstructions}
        isOpen={isHomeAccessInstructionsModalOpen}
        onDismiss={handleHomeAccessInstructionsModalDismiss}
        unitId={unit.basicInfo.unitId}
      />
    </>
  );
}
