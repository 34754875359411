// React imports
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_SIZES, BUTTON_TYPES } from 'components/Button/buttonTypes';
import Field from 'components/Field/Field';
// Component imports
import { InputFinalFormAdapter } from 'components/Input/Input';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
// String, Constant and Util imports
import { ENTER_EMAIL_TO_SAVE_MODAL } from 'strings/enter-email-to-save-modal';
import { email, required } from 'utils/validation';
// SCSS imports
import styles from './EnterEmailToSave.module.css';

const cx = classNames.bind(styles);

export default class EnterEmailToSave extends Component {
  static title = ENTER_EMAIL_TO_SAVE_MODAL.title;

  static closeButton = true;

  static propTypes = {
    showRegisterScreen: PropTypes.func.isRequired,
    handleEnterEmailToSaveScreenSubmit: PropTypes.func.isRequired,
    returnEmailToComponent: PropTypes.func.isRequired,
  };

  handleSubmit = async (value) => {
    const { handleEnterEmailToSaveScreenSubmit, returnEmailToComponent } = this.props;
    const { email: emailString } = value;

    returnEmailToComponent(emailString);
    handleEnterEmailToSaveScreenSubmit();
  };

  validateEmail = (emailString) => required(emailString) || email(emailString);

  render() {
    const { showRegisterScreen } = this.props;
    return (
      <div className={cx('enter-email-to-save-wrapper')}>
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <Field
                    name="email"
                    component={InputFinalFormAdapter}
                    placeholder={ENTER_EMAIL_TO_SAVE_MODAL.email_input}
                    validate={this.validateEmail}
                  />
                </Col>
              </Row>
              <div className={cx('save-button')}>
                <Button
                  type="submit"
                  label={ENTER_EMAIL_TO_SAVE_MODAL.save_button}
                  size={BUTTON_SIZES.FLUID}
                  disabled={invalid}
                />
              </div>
              <div className={cx('dont-save-button')}>
                <Button label="DON'T SAVE" buttonType={BUTTON_TYPES.TEXT} to={BASE_PATHS.HOMEOWNER} />
              </div>
              <div className={cx('sign-up-section')}>
                {ENTER_EMAIL_TO_SAVE_MODAL.become_belong_member}
                <div className={cx('sign-up-button')}>
                  <Button
                    buttonType={BUTTON_TYPES.TEXT}
                    label={ENTER_EMAIL_TO_SAVE_MODAL.sign_up_button}
                    onClick={showRegisterScreen}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
