import { formatMoney } from '@belong/common';
import { Image, Text } from '@belong/ui';

export function InspectionDemandBanner({ dailyPotentialLoss }) {
  return (
    <div className="p-2sm rounded border border-solid border-green bg-green-translucent-light flex flex-col justify-between w-full mt-lg sm:mt-0 h-auto">
      <div className="flex gap-2sm">
        <Image alt="" className="w-md shrink-0" src="icons/alert-red.svg" />
        <div className="flex flex-col">
          <Text as="span">
            Looks like this date is a little far out. Each day that goes by you could be collecting&nbsp;up&nbsp;to{' '}
            {formatMoney(dailyPotentialLoss)}&nbsp;of&nbsp;rent.
          </Text>
          <br />
          <Text as="span" fontWeight="semibold">
            We recommend scheduling your inspection sooner to avoid losing out on rental&nbsp;income.
          </Text>
        </div>
      </div>
    </div>
  );
}
