export const HOMEOWNER_ACCOUNTS_STRINGS = {
  maintenance: {
    title: 'Pro Services',
    subtitle: `Your home, <span>treated as our own.</span> <span>No cutting corners.</span> <span>No duct tape.</span>`,
    completed: 'Hide completed',
    icon_buttons: {
      more_button: 'SHOW MORE',
      add_task: 'BOOK A PRO',
    },
    // Refactor to be an array.
    overview: {
      section_header: 'Overview',
      propertyFilter: 'All Properties & Units',
      timeLineLabel: 'This Month',
    },
    dropdown_tasks: {
      label: 'All Requests',
    },
    // Need to confirm Capitalization
    tasks_section_header: 'Requests',
    // Don't know how to handle.
    earnings: {
      title: 'Earnings',
      subtitle:
        'Your time is just as valuable as your home. We get that.' +
        "That's why we take care of everything, so you can generate income without lifting a finger.",
    },
  },
};
