import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './InputLabel.module.css';

const cx = classNames.bind(styles);

export default class InputLabel extends React.PureComponent {
  static propTypes = {
    // Name of the label
    label: PropTypes.string,

    // if true, text doesn't move down 26 pixels. If false, text moves up 26 pixels.
    unAnimateText: PropTypes.bool,

    // turns the text to $green if true.
    highlight: PropTypes.bool,

    // if highlight is true and error is true, make the text red.
    error: PropTypes.bool,
    multiLineLabel: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    highlight: false,
    unAnimateText: true,
    error: false,
    multiLineLabel: false,
  };

  render() {
    const { unAnimateText, highlight, label, error, multiLineLabel, ...other } = this.props;
    return (
      <div {...other} className={cx('headerLabel', { multiLineLabel }, { unAnimateText }, { highlight }, { error })}>
        {label}
      </div>
    );
  }
}
