import Pet from 'models/common/Pet';
import Kid from 'models/common/Kid';
import LeaseDetailBasicInfo from 'models/renterAccounts/LeaseDetailBasicInfo';
import LeaseResident from './LeaseResident';

export default class LeaseModel {
  constructor(data = {}) {
    this.basicInfo = new LeaseDetailBasicInfo(data.basicInfo);
    this.residents = data.residents?.map((resident) => new LeaseResident(resident));
    this.pets = data.pets ? data.pets.map((pet) => new Pet(pet)) : [];
    this.kids = data.kids ? data.kids.map((kid) => new Kid(kid)) : [];
  }
}
